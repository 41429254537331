import React, { useEffect, useState } from 'react'
import { Button } from "react-bootstrap";
import {isEmpty, trim} from 'lodash'
import Modals from '../../../../../../components/modals';
import { appointmentModalStatus, initialForm } from '../../constants';
import { useAppointmentsContext } from '../../context/Appointments.context';
import FirstPage from './FirstPage';
import '../styles.css';
import SecondPage from './SecondPage';
import axios from '../../../../../../utils/apiClient';
import { setReloadWorklist } from '../../../../../../actions/manageVisitActions';
import { useDispatch } from 'react-redux';

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/appointment";

const Content = ({
  patient,
  setFormFullfilled,
  activePage,
  setActivePage,
  formBody,
  setFormBody,
  fileUploadResetCounter,
  selectedPCP
}) => {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const conditionPage1 = (
      formBody?.name?.length > 0 &&
      formBody?.status &&
      formBody?.appointmentDateTime &&
      formBody?.durationInMinutes !== 0 &&
      formBody?.provider?.firstName?.length > 0 &&
      formBody?.address?.length > 0
    )
    const conditionPage2 = (
      formBody?.shouldSendReminder
        ? (formBody?.notificationDelay > 0 && formBody?.notificationDelayUnit.length > 0)
        : true 
    )
    setFormFullfilled(
      activePage === 1 ? conditionPage1 : conditionPage2
    )
  }, [formBody, activePage])

  return (
    <div id='AppointmentModal'>
      <div style={{
        maxHeight: activePage === 1 ? '100%' : '0px',
        display: activePage === 1 ? 'block' : 'none'
      }}>
        <FirstPage
          setFormBody={setFormBody}
          formBody={formBody}
          appointmentModalStatus={appointmentModalStatus}
          isLoading={isLoading}
          patient={patient}
          setIsLoading={setIsLoading}
          fileUploadResetCounter={fileUploadResetCounter}
          selectedPCP={selectedPCP}
        />
      </div>
      <div style={{
        maxHeight: activePage === 2 ? '100%' : '0px',
        display: activePage === 2 ? 'block' : 'none'
      }}>
        <SecondPage
          setActivePage={setActivePage}
          setFormBody={setFormBody}
          formBody={formBody}
          patient={patient}
        />
      </div>
    </div>
  )
}

function AppointmentModal({ patient, getAppointments, formBody, setFormBody, selectedPCP }) {
  const [formFullfilled, setFormFullfilled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const dispatch = useDispatch();

  const {
    showAddAppointment,
    toggleShowAddAppointment,
    toggleAddNewAppointment,
    setOptions,
    setSelectedDate,
    setAddressList,
    isEdit,
    toggleShowCancelAppointment
  } = useAppointmentsContext();

  const resetStates = async () => {
    await getAppointments();
    setFileUploadResetCounter(fileUploadResetCounter + 1);
    toggleShowAddAppointment(false)
    toggleAddNewAppointment(false)
    setFormBody(initialForm)
    setActivePage(1)
    setOptions([])
    setSelectedDate('')
    setAddressList([])
    dispatch(setReloadWorklist(true))
  }

  const postRequest = () => {
    axios
      .post(baseURL + `/save`, {...formBody, patientId: patient.id}, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async () => await resetStates())
      .catch((error) => {
      });
  }

  const updateRequest = () => {
    const url = `${baseURL}/edit`
    return axios
      .put(url, formBody, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(async () => await resetStates())
      .catch((error) => {
      });
  }

  const onsubmitAppointment = () => {
    isEdit ? updateRequest() : postRequest()
  };

  const handleClose = () => {
    toggleShowAddAppointment(false)
    toggleAddNewAppointment(false)
    setFormBody(initialForm)
    setActivePage(1)
    setOptions([])
    setSelectedDate('')
    setAddressList([])
  }

  const handleComplete = () => {
    if (activePage == 2) {
      onsubmitAppointment()
    } else {
      setActivePage(2)
    }
  }

  return (
    <Modals
      show={showAddAppointment}
      onHide={handleClose}
      title={isEdit ? "Edit Appointment" : "New Appointment"}
      size="md"
      closeBtn="Cancel"
      completeBtn={activePage === 1 ? "Next" : "Submit"}
      hideFooter={false}
      renderFooterActions={() => {
        if (formBody?.status === 'CANCELLED' || isEmpty(trim(formBody?.appointmentId))) {
          return null;
        }
        return <Button variant="danger" onClick={() => {
          toggleShowCancelAppointment(true);
          toggleShowAddAppointment(false)
        }}>Cancel Appointment</Button>
      }}
      body={
        <Content
          patient={patient}
          setFormFullfilled={setFormFullfilled}
          activePage={activePage}
          setActivePage={setActivePage}
          formBody={formBody}
          setFormBody={setFormBody}
          fileUploadResetCounter={fileUploadResetCounter}
          selectedPCP={selectedPCP}
        />
      }
      disabledClickButton={!formFullfilled}
      onCreate={handleComplete}
    />
  )
}

export default AppointmentModal