import React, { useContext } from "react";
import {SectionContext} from "../CareAdmin";
import { Button, Form, Row, Col } from "react-bootstrap";

const componentName = "teamsSections";

const TeamsBodyRow = ({ team, idx }) => {
    const { setSelectedTeam, setIsEditingTeam, setShowTeams} = useContext(SectionContext);

    const handleEdit = (e) => {
        e.preventDefault();
        setSelectedTeam(team);
        setIsEditingTeam(true);
        setShowTeams(true);
    }

    return <>
        <div  id={`${componentName}-teamName-${idx}`} className="d-flex justify-content-between mb-3">
            <div className="d-block w-100">
                <span id={`${componentName}-teamLabel-${idx}`}>{team.name}</span>
                <div className="float-end text-end">
                    <span className="badge rounded-pill bg-light text-dark border me-1">{team.priority}</span>
                    <Button id={`${componentName}-editBtn-${idx}`} variant="link" className="p-0" onClick={handleEdit}>
                        <i className="fa fa-edit"></i>
                    </Button>
                </div>
            </div>
        </div>
    </>
}

const TeamsHeaders = () => {
    return <>
        <div className="d-flex justify-content-between">
            <p className="fw-bold">Team Name</p>
            <p className="fw-bold">Priority</p>
        </div>
    </>
}



export const TeamsBodyList = () => {
    const { teams } = useContext(SectionContext);
    return  <>
        <TeamsHeaders />
        {teams.sort((a,b) => a.priority - b.priority).map((team, index) => {
            return(
                <TeamsBodyRow key={team.teamId} team={team} idx={index} />
            )
        })}
    </>
}

export const TeamsModalBody = () => {
    const { setSelectedTeam, selectedTeam} = useContext(SectionContext);

    return <>
        <div className="d-block mb-3">
            <Row>
                <Col sm={9}>
                    <label
                        for={`${componentName}-teamAddEditLabel`}
                        className="d-block"
                    >Team Name</label>
                    <Form.Control
                        placeholder="Team Name"
                        type="text"
                        name="name"
                        defaultValue={selectedTeam.name}
                        id={`${componentName}-teamAddEditValue`}
                        onChange={e => setSelectedTeam({ ...selectedTeam, name: e.target.value})}
                    />
                </Col>
                <Col sm={3}>
                    <label
                        for={`${componentName}-teamPriorityLabel`}
                        className="d-block"
                    >Priority</label>
                    <Form.Control
                        aria-label="priority"
                        as="select"
                        defaultValue={selectedTeam.priority}
                        id={`${componentName}-teamPriorityValue`}
                        onChange={e => setSelectedTeam({ ...selectedTeam, priority: e.target.value})}
                    >
                        {
                            [...Array(20).keys()]
                                .map(opt => <option value={opt + 1}>{opt + 1}</option>)
                        }
                    </Form.Control>
                </Col>
            </Row>
        </div>
    </>
}


