export const initialState = {
  assessments: [],
  patientId: null,
  mode: '',
  selectedTemplate: {},
  fillAssessment: false,
  individualAssessmentCard: {},
  exportParams: {},
  exportData: [],
  filterParams:[],
  sortParams: [],
  totalSize: 0
};

export const actions = {
  SET_ASSESSMENTS: 'SET_ASSESSMENTS',
  SET_PATIENT_ID: 'SET_PATIENT_ID',
  SET_MODE: 'SET_MODE',
  SET_SELECTED_TEMPLATE: 'SET_SELECTED_TEMPLATE',
  SET_FILL_ASSESSMENT: 'SET_FILL_ASSESSMENT',
  SET_INDIVIDUAL_ASSESSMENT_CARD: 'SET_INDIVIDUAL_ASSESSMENT_CARD',
  SET_EXPORT_PARAMS: 'SET_EXPORT_PARAMS',
  SET_EXPORT_DATA: 'SET_EXPORT_DATA',
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',
  SET_SORT_PARAMS: 'SET_SORT_PARAMS',
  SET_TOTAL_SIZE: 'SET_TOTAL_SIZE'
};