import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "react-bootstrap-typeahead/css/Typeahead.css";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  CustomMenuStyled,
  DropDownButton,
} from "./dropDownLists/sharedDDL";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { getDisplayValue } from "../utils/util";

const componentName = "DropdownComponent";

const DropdownComponent = ({
    name,
    val,
    label,
    list,
    isOpen,
    handleInputChange,
    required,
    disabled
  }) => {
    return (
      <div>
        <Form.Label className="fw-bold" id={`${componentName}-${name}Label`}>
          {label}{required ? <span className="text-danger">*</span> : ""}
        </Form.Label>
        <Dropdown disabled= {disabled} value={val}>
          <Dropdown.Toggle
            as={DropDownButton}
            cssOpen={isOpen}
            cusWidth="100% !important"
            disabled= {disabled}
            id={`${componentName}-${name}Value`}
          >
            {val ? getDisplayValue(list, val) : <span>-Select-</span>}
            <i className="fa fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}  disabled= {disabled} >
            {list.map((type, idx) => (
              <Dropdown.Item
                key={type.value}
                id={`${componentName}-${name}Item-${idx}`}
                onClick={() => handleInputChange(name, type)}
              >
                {type.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  export { DropdownComponent };