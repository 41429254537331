import React, {createContext, useContext, useEffect, useReducer} from 'react';
import {useDispatch} from "react-redux";
import {initialState} from './actions';
import {reducer} from './CustomWorklist.reducer';
import {
  loadCustomFilteredList
} from "../../../actions/navigation/navigationActions";

import {useAuth} from '../../../context/authContext';
import {
  deleteCustomWorklist,
  getCustomWorklist,
  saveCustomWorklist
} from './api';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from './setters';
import {useQuery} from 'react-query';
import {alternativeFilters} from "../../../views/caremgmt/worklist/constants";
import {PatientSearchDTO} from "../../../dtos/PatientSearchDTO";

const CustomWorklistContext = createContext();

const CustomWorklistProvider = ({children}) => {
  const auth = useAuth();
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getters = getGetters(state);
  const toggles = getToggles(dispatch);
  const setters = getSetters(dispatch);

  const {refetch: saveCustomWorklistAsync} = useQuery({
    queryKey: ['saveCustomWorklist'],
    queryFn: () => {
      setCustomWorkListError([]);

      saveCustomWorklist(
        state?.saveAsCustomWorklistRequest?.quickListName,
        state?.saveAsCustomWorklistRequest?.name,
        state?.saveAsCustomWorklistRequest?.notes,
        state?.saveAsCustomWorklistRequest?.patientRequest
      ).then(res => {
        globalDispatch(loadCustomFilteredList());
        toggles.toggleSaveAsCustomWorklist(false);
      })
      .catch(err => {
        if (err?.response?.data?.message
          === 'CUSTOM_WORKLIST_NAME_ALREADY_EXISTS') {
          setCustomWorkListError(
            ["Error", "A worklist with this name already exists"]);
        } else {
          setCustomWorkListError(["Error", err?.response?.data?.message])
        }
      })
    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: false
  })

  const {refetch: getCustomWorklistAsync} = useQuery({
    queryKey: ['getCustomWorklist'],
    queryFn: () => {
      const {worklistId, activeRole} = state?.getCustomWorklistRequest;

      getCustomWorklist(worklistId)
      .then((response) => {
        setters.setCustomWorklist(response.data);

        const {quickList, patientRequestDTO} = response.data;
        const {
          filterParams,
          sortParams,
          pageNumber,
          pageSize,
          previousTotalSize
        } = patientRequestDTO;

        setters.setCustomFilters(filterParams);

        const invert = Object.fromEntries(
          Object.entries(alternativeFilters).map(([k, v]) => [v, k]));
        const newFilters = filterParams.map(f =>
          invert[f.paramName]
            ? {...f, paramName: invert[f.paramName]}
            : f
        )

        const patientSearch = new PatientSearchDTO(
          activeRole?.leadId ? [] : newFilters,
          sortParams || [],
          pageNumber || 1,
          pageSize || 20,
          quickList,
          previousTotalSize
        );
        setters.setPatientSearch({...patientSearch, type: worklistId})
      })

    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: false
  })

  useEffect(() => {
    if (state?.saveAsCustomWorklistRequest?.name) {
      saveCustomWorklistAsync()
    }
  }, [
    state?.saveAsCustomWorklistRequest
  ])

  useEffect(() => {
    if (state?.getCustomWorklistRequest?.worklistId) {
      getCustomWorklistAsync()
    }
  }, [
    state?.getCustomWorklistRequest
  ])

  const setCustomWorkListError = (errorInfo) => {
    setters.setCustomWorkListError(errorInfo);
  }

  const callbacks = {
    setCustomWorkListError: setCustomWorkListError,
    saveCustomWorklist: async (quickListName, name, notes, patientRequest) => {
      setters.saveAsCustomWorklistRequest(
        {quickListName, name, notes, patientRequest})
    },
    deleteCustomWorklist: async (worklistId) => await deleteCustomWorklist(
      worklistId)
    .then(() => {
      toggles.toggleDeleteCustomWorklist(false);
    }),
    getCustomWorklist: async (worklistId, activeRole) => {
      setters.setGetCustomWorklistRequest({worklistId, activeRole})
    }
  }

  const value = {
    ...getters,
    ...toggles,
    ...setters,
    ...callbacks
  }

  return (
    <CustomWorklistContext.Provider value={value}>
      {children}
    </CustomWorklistContext.Provider>
  )
};
const useCustomWorklistContext = () => useContext(CustomWorklistContext);

export {
  CustomWorklistProvider,
  useCustomWorklistContext,
}