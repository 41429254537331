import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

export const Modals = ({
  modalId,
  onHide,
  onCreate,
  show,
  size,
  modalTitleId,
  title,
  body,
  hideFooter,
  hideSecondaryBtn,
  secondaryBtn,
  secondaryBtnId,
  disabledPrimaryBtn,
  hidePrimaryBtn,
  primaryBtn,
  primaryBtnId,
  loading=false
}) => {
  return (
    <Modal id={modalId} onHide={onHide} show={show} size={size} backdropClassName="confirmbackdrop" contentClassName="shadow border" centered scrollable>
      <Modal.Header>
          <Modal.Title id={modalTitleId}>{title}</Modal.Title>
          <button id={`${title}ModalClose`} type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer hidden={hideFooter}>
        <Button id={secondaryBtnId} variant="secondary" hidden={hideSecondaryBtn} onClick={onHide}>{secondaryBtn ? secondaryBtn : "Close"}</Button>
        <Button id={primaryBtnId} variant="primary" disabled={disabledPrimaryBtn} hidden={hidePrimaryBtn} onClick={onCreate}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : primaryBtn }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modals;
