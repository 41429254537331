import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {isEmpty} from 'lodash'
import {useNotesContext} from "../../context/Notes.context";
import PatientInformationSummary
  from "../../../../caremgmt/worklist/components/CMWorklistModals/modalsComponents/PatientInformationSummary";
import {
  useEQHToastContext
} from "../../../../../components/toast/EQHToast.context";
import {FileUpload} from "../../../../../components/FileUpload";

const componentName = 'AddNoteModal';

function AddNoteModal({
  patient
}) {

  const {
    addNote,
    showAddNote,
    toggleShowAddNote,
    isAddingNote,
    categories
  } = useNotesContext();

  const {setNotification} = useEQHToastContext();

  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);

  useEffect(() => {
    if (!showAddNote) {
      resetState()
    }
  }, [showAddNote])

  const areRequiredFieldsValid = () => {
    return isEmpty(getErrorMessage());
  }

  const submitAction = async () => {
    const errorMessage = getErrorMessage();
    if (!isEmpty(errorMessage)) {
      setNotification("Error", errorMessage);
    } else {
      const requestBody = {
        patientId: patient?.id,
        files: uploadedFiles.map(file => {
          return {
            fileName: file.fileName,
            id: file.fileId
          };
        }),
        description: name,
        noteText: note,
        noteType: selectedCategory,
        createdOn: new Date()
      }
      await addNote(patient?.id, requestBody);
    }
  }

  const getErrorMessage = () => {
    const errors = [];
    if (isEmpty(name)) {
      errors.push('You must provide a value for the name field');
    }
    if (isEmpty(selectedCategory)) {
      errors.push('You must provide a value for the category field');
    }
    if (isEmpty(note)) {
      errors.push('You must provide a value for the notes field');
    }
    return errors;
  }

  const resetState = () => {
    setName('');
    setNote('');
    setSelectedCategory('');
    setUploadedFiles([]);
    setFileUploadResetCounter(fileUploadResetCounter + 1);
  }

  const closeAction = async () => {
    resetState()
    toggleShowAddNote(false);
  }

  const handleUploadFileChange = (uploadedFiles) => {
    setUploadedFiles(uploadedFiles);
  };

  return (
    <Modal
      size="md"
      show={showAddNote}
      onHide={() => closeAction()}
      centered
    >
      <Modal.Header>
        <Modal.Title>New Note</Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeAction()}
        />
      </Modal.Header>
      <Modal.Body>
        <PatientInformationSummary rowLayout={1}
                                   fullName={patient?.fullName}
                                   memberId={patient?.memberId}
                                   dateOfBirth={patient?.dateOfBirth}
                                   className='mb-5'/>
        <Row className="mb-3">
          <Col>
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              as="input"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>
                Category <span className="text-danger">*</span>
              </Form.Label>
              <select className={`form-select`}
                      value={selectedCategory}
                      onChange={(event) => {
                        const categoryId = event.target.value;
                        setSelectedCategory(categoryId);
                      }}>
                <option value="">Select</option>
                {categories?.map((category, index) => (
                  <option key={index}
                          value={category.value}>{category.text}</option>
                ))}
              </select>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>
                Notes <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="textarea"
                as="textarea"
                rows="4"
                max="500"
                value={note}
                onChange={e => setNote(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <FileUpload onChange={handleUploadFileChange}
                        clearStateProp={fileUploadResetCounter}/>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-patientAddNoteNoBtn`}
                disabled={isAddingNote}
                variant="secondary" onClick={() => closeAction()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-patientAddNoteYesBtn`}
          variant="primary"
          disabled={!areRequiredFieldsValid() || isAddingNote}
          onClick={() => submitAction()}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddNoteModal;