import {
  LOAD_CUSTOM_FILTERED_LIST,
  SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL
} from "../types";

import axios from "../../utils/apiClient";
import {loadCustomFilteredList} from "../navigation/navigationActions";

const SAVE_QUICKLISTS_ALPHABETICALLY = process.env.REACT_APP_REST_API_BASE_URL + `/v1/quicklist/custom-quicklists-alphabetically`;
const SAVE_QUICKLISTS_SORTED = process.env.REACT_APP_REST_API_BASE_URL + `/v1/quicklist/custom-quicklists-sorted`;

export const setVisibleOrderCustomListModal = (value) => (dispatch) => {
  dispatch({ type: SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL, payload: value });
};

export const saveOrderCustomList = (items) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
  };

  let param = items.map(item => item.title);

  axios
    .post(SAVE_QUICKLISTS_SORTED, param, config)
    .then((res) => {
      dispatch(loadCustomFilteredList());
      dispatch(setVisibleOrderCustomListModal(false));
    }).catch((error) => {

  });
};

export const sortCustomListAlphabetically = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
  };

  axios
    .post(SAVE_QUICKLISTS_ALPHABETICALLY, null, config)
    .then((res) => {
      dispatch(loadCustomFilteredList());
      dispatch(setVisibleOrderCustomListModal(false));
    }).catch((error) => {

  });
};
