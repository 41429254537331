import React, { useEffect, Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import {
  getSystemSettings,
  useGetSystemSettings,
  saveSystemSettingsInfo,
  getTimeZones,
  useGetTimeZones,
  getLandingPages,
  useGetLandingPages,
  saveUserProfileInfo,
} from "../../actions/accountSettingsAction";
import Toast from "react-bootstrap/Toast";
import { useAuth } from "../../context/authContext";
import { ROUTES } from "./../../utils/navigationItems";

const componentName = "SystemSettings";
const isEmpty = require("is-empty");

const CapitalizingInput = styled.input`
  text-transform: capitalize;
`;

const systemSettingsReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const landingPagesReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const timeZonesReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const SystemSettings = () => {
  const auth = useAuth();
  let [userLandingPages, setUserLandingPages] = useState([]);
  const [show, setShow] = useState(false);

  const save = (systemSettingsInfo) => {
    saveSystemSettingsInfo(systemSettingsInfo)
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
      });
  };

  const systemSettingsPkg = useGetSystemSettings({}, systemSettingsReducer);

  const timeZonesPkg = useGetTimeZones({}, timeZonesReducer);

  const [showHistory, setShowHistory] = React.useState(systemSettingsPkg.state.data.showHistoryAutomatically)
  const toggleShowHistory = () => {
    let newValue = !showHistory

    systemSettingsPkg.dispatch({
      type: "Update",
      payload: {
        ...systemSettingsPkg.state.data,
        showHistoryAutomatically: newValue,
      },
    });

    setShowHistory(newValue)
  }

  useEffect(() => {
    setUserLandingPages(getUserLandingPages());
    systemSettingsPkg.fetchData();
    timeZonesPkg.fetchData();
  }, []);

  useEffect(() => {
    setShowHistory(systemSettingsPkg.state.data.showHistoryAutomatically)
  }, [systemSettingsPkg]);

  const getUserLandingPages = () => {
    return Object.keys(ROUTES)
      .filter(
        (key) => ROUTES[key].canAccess == null || ROUTES[key].canAccess(auth)
      )
      .filter(
        (key) =>
          ROUTES[key].landingPage != null && ROUTES[key].landingPage === true
      )
      .map((key) => {
        return {
          to: ROUTES[key].path.replace(/\//g, "").replace(/#!/g, "").replace(/caremgmt/g, "caremgmt/"),
          title: key,
        };
      });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="w-100">
                <Form.Label id={`${componentName}-timeZoneLabel`}>Time Zone</Form.Label>
                <select
                  id={`${componentName}-timeZoneValue`}
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    systemSettingsPkg.dispatch({
                      type: "Update",
                      payload: {
                        ...systemSettingsPkg.state.data,
                        timeZoneId: e.target.value,
                      },
                    });
                  }}
                >
                  {timeZonesPkg.state.data != null &&
                    timeZonesPkg.state.data.length != null &&
                    timeZonesPkg.state.data.map((timeZone, idx) => (
                      <option
                        id={`${componentName}-timeZoneItems-${idx}`}
                        key={timeZone}
                        value={timeZone}
                        selected={
                          timeZone === systemSettingsPkg.state.data.timeZoneId
                        }
                      >
                        {timeZone}
                      </option>
                    ))}
                </select>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label id={`${componentName}-communicateLabel`}>Allow Patients to Communicate With Me</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Yes"
                    name={"communicate"}
                    type={"radio"}
                    id={`${componentName}-communicateYes`}
                    value={
                      systemSettingsPkg.state.data
                        ? systemSettingsPkg.state.data
                            .allowPatientsCommunication === true
                        : false
                    }
                    checked={
                      systemSettingsPkg.state.data
                        ? systemSettingsPkg.state.data
                            .allowPatientsCommunication === true
                        : false
                    }
                    onChange={(e) => {
                      systemSettingsPkg.dispatch({
                        type: "Update",
                        payload: {
                          ...systemSettingsPkg.state.data,
                          allowPatientsCommunication: true,
                        },
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name={"communicate"}
                    type={"radio"}
                    id={`${componentName}-communicateNo`}
                    value={
                      systemSettingsPkg.state.data
                        ? systemSettingsPkg.state.data
                            .allowPatientsCommunication === false
                        : true
                    }
                    checked={
                      systemSettingsPkg.state.data
                        ? systemSettingsPkg.state.data
                            .allowPatientsCommunication === false
                        : true
                    }
                    onChange={(e) => {
                      systemSettingsPkg.dispatch({
                        type: "Update",
                        payload: {
                          ...systemSettingsPkg.state.data,
                          allowPatientsCommunication: false,
                        },
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="w-100">
                <Form.Label id={`${componentName}-landingPageLabel`}>Landing Page</Form.Label>
                <div className="mb-3">
                  <select
                    id={`${componentName}-landingPageValue`}
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      systemSettingsPkg.dispatch({
                        type: "Update",
                        payload: {
                          ...systemSettingsPkg.state.data,
                          landingPage: e.target.value,
                          updateLandingPageOnLogout: false,
                        },
                      });
                    }}
                  >
                    {userLandingPages != null &&
                      userLandingPages.length != null && (
                        <>
                          <option
                            id={`${componentName}-landingPageItem`}
                            key={null}
                            value={null}
                            selected={!systemSettingsPkg.state.data.landingPage}
                          >
                            -- None --
                          </option>
                          {userLandingPages.map((userLandingPage, idx) => (
                            <option
                              id={`${componentName}-landingPageItem-${idx}`}
                              key={userLandingPage.to}
                              value={userLandingPage.to}
                              selected={
                                userLandingPage.to ===
                                systemSettingsPkg.state.data.landingPage
                              }
                            >
                              {userLandingPage.title}
                            </option>
                          ))}
                        </>
                      )}
                  </select>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="w-100 mt-3">
            <Form.Label id={`${componentName}-historyToggle`}>View History Window Behavior</Form.Label>
            <div className="mb-3">
              <Form.Control 
                id={`${componentName}-historyToggleValue`}
                as="select"
                value={showHistory}
                onChange={toggleShowHistory}>

                <option value="false">Window only appears when select the History option</option>
                <option value="true">Window appears when selecting a gap in Manage Visit screen</option>
              </Form.Control>
            </div>
          </Form.Group>
          <Row>
            <Col sm={2}>
              <Button
                id={`${componentName}-saveButton`}
                variant="primary"
                onClick={() => save(systemSettingsPkg.state.data)}
              >
                Save
              </Button>
            </Col>
            <Col sm={10}>
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <i className="fas fa-check-square text-success"></i>
                  <strong className="me-auto">System Settings updated</strong>
                </Toast.Header>
              </Toast>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default SystemSettings;
