import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import ErrorToast from "../common/ErrorToast";
import Upload from "../../components/Upload";
import { updateFile, getFile } from "../../actions/fileUploadActions";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";

import noLogo from "../../img/no-logo.jpg";
import {
  getOrganizations,
  updateBrandOrg,
} from "../../actions/admin/brandingActions";

const componentName = "Branding";

const Logo = ({fileObject}) => {
  const isEmpty = require("is-empty");
  return (  
    <>
      {isEmpty(fileObject) ? (
        <img id={`${componentName}-logo`} src={noLogo} width="300px" />
      ) : (
        <img id={`${componentName}-logo`} src={`data:image/jpeg;base64,${fileObject}`} width="300px" />
      )}
    </>
  )
};

export const Branding = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors);
  const success = useSelector((state) => state.success);
  const { brandOrgs } = useSelector((state) => state.brandOrg);
  const {fileUploadId} =  useSelector((state) => state.brandOrg);
  const { fileObject } = useSelector((state) => state.fileUpload);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [disableInput, setDisableInput] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState();
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    (async function fetchData() {
      await dispatch(getOrganizations());
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(fileObject)) {
      setSelectedImage(fileObject);
      setFile({})
      setFileName('Choose File')
    }
  }, [fileObject]);

  useEffect(() => {
  if (!isEmpty(fileUploadId) && !isEmpty(fileUploadId.fileUploadId) ) {
    dispatch(getFile(encodeURIComponent(fileUploadId.fileUploadId)));
    }
  }, [fileUploadId]);
  const setFileMethod = (file) => {
    if(file && file.name){
      setFileName(file.name)
    }
    const fileReader = new FileReader();
   if (fileReader && file) {
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function () {
          setFile(fileReader.result)
      };
   }
  };
  const handleChangeWithinModal = (e) => {
    const obj = e[0];
    if (obj) {
      setDisableInput(false);
      setSelectedOrg(obj);
      if (obj.logo) {
        dispatch(getFile(encodeURIComponent(obj.logo)));
      }
    } else {
      setDisableInput(true);
      setSelectedImage({});
    }
  };
  return (
    <Row className="container-fluid p-3">
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Row className="mb-3">
                  <Form.Group>
                    <Form.Label id={`${componentName}-organizationLabel`}>
                      Organization
                    </Form.Label>
                    <Typeahead
                      inputProps={{
                        id: `${componentName}-organizationValue}`
                      }}
                      labelKey="name"
                      onChange={(e) => handleChangeWithinModal(e)}
                      options={brandOrgs}
                      placeholder="Choose an Organization"
                      className="w-50"
                    />
                  </Form.Group>
                </Row>
                <Upload
                  id={`${componentName}-uploadFile`}
                  fileName={fileName}
                  setFile={setFileMethod}
                  disabled={disableInput}
                  accept="image/x-png,image/gif,image/jpeg"
                ></Upload>
                {errors && errors.message && <ErrorToast/>}
                <Card className="bg-light my-3">
                  <Card.Body>
                    <Card.Title id={`${componentName}-recommendationsLabel`}>Recommendations</Card.Title>
                    <Card.Text>
                      <span id={`${componentName}-heightLabel`} className="d-block text-muted">Height: 50px - 150 px</span>
                      <span id={`${componentName}-widthLabel`} className="d-block text-muted">Width: 150px - 300 px</span>
                      <span id={`${componentName}-fileSizeLabel`} className="d-block text-muted">File Size:Less than 1 MB</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <div className="d-flex justify-content-end">
                  <Button
                    id={`${componentName}-confirmButton`}
                    variant="primary"
                    disabled={disableInput || isEmpty(file)}
                    onClick={() => dispatch(updateBrandOrg(file, encodeURIComponent(selectedOrg.id), fileName))}
                  >
                    Confirm
                  </Button>
                </div>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Logo fileObject={selectedImage} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Branding;
