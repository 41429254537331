import React from 'react'
import {get, isEmpty} from "lodash";
import {useVisitContext} from "../context/Visit.context";
import ActivityVisitItem from './ActivityVisitItem';
import {useAuth} from "../../../../context/authContext";

const componentName = 'activityVisitGroup';

function ActivityVisitGroup({title, subTitle, activities}) {
  const {
    setUnsavedVisitsMap,
    unsavedVisitsMap,
    showClosedOrInactive
  } = useVisitContext();

  const auth = useAuth();
  const authorizedForWriting = auth.hasAnyAuthority(
    ["AUTH_PD_NEW_MANAGE_VISIT_WRITE_ACCESS"]);

  const isContained = (statusToSearch, statuses) => {
    return !isEmpty(statusToSearch) && !isEmpty(statuses) && statuses.filter(
      currentStatus => statusToSearch.includes(currentStatus)).length > 0;
  }

  const updateUnsavedVisitsMap = (visit, fieldsToUpdate) => {
    const unsavedVisit = unsavedVisitsMap[visit.id] ?? {};
    if (Object.keys(unsavedVisit).length === 0) {
      fieldsToUpdate = {
        id: visit.id,
        date: visit.date,
        status: visit.status,
        statusEnum: visit.statusEnum,
        checked: visit.checked,
        actionType: visit.actionType,
        requiredActivitiesIds: (visit.requiredActivitiesIds ?? []),
        ...fieldsToUpdate
      }
    }
    setUnsavedVisitsMap(visit?.id, fieldsToUpdate);
  }

  /**
   * searches if a specific activity is required by one that has been previously selected by the user
   */
  const isActivityRequired = (activityId) => {
    return Object.keys(unsavedVisitsMap ?? {}).filter(currentActivityId => {
      const requiredActivitiesIds = unsavedVisitsMap[currentActivityId].requiredActivitiesIds
        ?? [];
      return requiredActivitiesIds.includes(activityId);
    }).length > 0;
  }

  return (
    <div className={componentName + "-container"}>
      {title && <div className="title mb-2 fw-bold">
        {title}
      </div>}
      {subTitle && <div className="sub-title mb-2 fw-bold">
        {subTitle}
      </div>}


      {activities?.map((activityVisit) => {
        const unsavedVisit = unsavedVisitsMap[activityVisit.id] ?? {};
        const status = unsavedVisit.status ?? activityVisit.status;
        const isChecked = unsavedVisit.checked ?? activityVisit.checked;
        const attestation = unsavedVisit.attestation
          ?? activityVisit.attestation;
        const isOpen = (status) => {
          return isContained(status, ['Marked', 'Previously']);
        };
        const isA1CEnabled = (status) => {
          return isChecked && isOpen(status) && activityVisit.requireA1C;
        }
        const isBloodPressureEnabled = (status) => {
          return isChecked && isOpen(status)
            && activityVisit.requireBloodPressure;
        }
        const isAttestationEnabled = (status) => {
          return isChecked && isOpen(status)
            && !isEmpty(
              activityVisit?.attestationDDLOption?.gapId);
        }

        let enableA1C = isA1CEnabled(status);
        let enableBloodPressure = isBloodPressureEnabled(status);
        let enableAttestation = isAttestationEnabled(status);
        let isRequired = isActivityRequired(activityVisit.id);

        const handleUpdateActivityVisit = (activityVisit, fieldsToUpdate) => {
          if (fieldsToUpdate === null) {
            updateUnsavedVisitsMap(activityVisit, null);
          } else {
            const newStatus = fieldsToUpdate.status ?? status;

            updateUnsavedVisitsMap(activityVisit, {
              ...fieldsToUpdate,
              enableBloodPressure: isBloodPressureEnabled(newStatus),
              enableA1C: isA1CEnabled(newStatus),
              enableAttestation: isAttestationEnabled(newStatus),
            })
          }
        }

        return <div className="mb-3">
          <ActivityVisitItem
            id={activityVisit.id}
            title={activityVisit.title}
            status={status}
            attestation={attestation}
            validation={activityVisit.validation}
            isDocumentationAndCoding={activityVisit.isDocumentationAndCoding}
            conditionType={activityVisit.conditionType}
            requireFileUpload={activityVisit.requireFileUpload}
            date={unsavedVisit.date ?? activityVisit.date}
            a1cWholes={unsavedVisit.a1cWholes ?? activityVisit.a1cWholes}
            a1cDecimal={unsavedVisit.a1cDecimal ?? activityVisit.a1cDecimal}
            bloodPressureSystolic={unsavedVisit.bloodPressureSystolic
              ?? activityVisit.bloodPressureSystolic}
            bloodPressureDiastolic={unsavedVisit.bloodPressureDiastolic
              ?? activityVisit.bloodPressureDiastolic}
            isRequired={isRequired}
            disabled={showClosedOrInactive || !authorizedForWriting}
            statuses={Object.keys(activityVisit.statusesOptionsMap ?? [])}
            checked={isChecked}
            enableA1C={enableA1C}
            enableBloodPressure={enableBloodPressure}
            enableAttestation={enableAttestation}
            attestationDDLOption={activityVisit.attestationDDLOption}
            onStatusChange={(status) => {
              handleUpdateActivityVisit(activityVisit, {
                status: status,
                statusEnum: get(activityVisit.statusesOptionsMap, status)
              })
            }}
            onCheckedChange={(checked) => {
              if (checked) {
                handleUpdateActivityVisit(activityVisit, {checked: checked})
              } else {
                handleUpdateActivityVisit(activityVisit, null)
              }
            }}
            onDateChange={(date) => {
              handleUpdateActivityVisit(activityVisit, {date: date})
            }}
            onA1cWholesChange={(a1cWholes) => {
              handleUpdateActivityVisit(activityVisit, {a1cWholes: a1cWholes})
            }}
            onA1cDecimalChange={(a1cDecimal) => {
              handleUpdateActivityVisit(activityVisit, {a1cDecimal: a1cDecimal})
            }}
            onBloodPressureSystolicChange={(bloodPressureSystolic) => {
              handleUpdateActivityVisit(activityVisit,
                {bloodPressureSystolic: bloodPressureSystolic})
            }}
            onBloodPressureDiastolicChange={(bloodPressureDiastolic) => {
              handleUpdateActivityVisit(activityVisit,
                {bloodPressureDiastolic: bloodPressureDiastolic})
            }}
            onAttestationChange={(value, gapId, abbreviation) => {
              handleUpdateActivityVisit(activityVisit,
                {attestation: {filterValue: value, gapId, abbreviation}})
            }}/>
        </div>
      })}
    </div>
  )
}

export default ActivityVisitGroup