import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/authContext";
import { Button } from "../../components/buttons";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import ErrorToast from "../common/ErrorToast";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import axios from "../../utils/apiClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { formatDateTime, carePathValues } from "../../utils/util";
import EQHErrorToast from "../../components/EQHErrorToast";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const componentName = "AssignCarePath";

const baseURLCP = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-paths";
const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card";
const StyledTypeAhead = styled(AsyncTypeahead)``;

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;
const StyledRow = styled(Row)`
  height: 100%;
  margin-top: -16px;
`;

const ToastOverlay = styled.div`
  z-index: 999;
  position: absolute;
  right: 30px;
  bottom: 90px;
  width: 100%;

  > * {
    margin-left: auto;
  }
`;
const AssignCarePath = ({
  onHideClicked,
  patientsSelected,
  updateSelectedCarePath,
  showAssignCarePathModal,
  carePathsArray,
}) => {
  const isEmpty = require("is-empty");
  const [isLoading, setIsLoading] = useState(false);
  const [makePrimary, setMakePrimary] = useState(false);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedPCP, setSelectedPCP] = useState({});
  const [selectedCarePath, setSelectedCarePath] = useState({});
  const [carePaths, setCarePaths] = useState([]);
  const [carePathSteps, setCarePathSteps] = useState([]);
  const [newSteps, setNewSteps] = useState([]);
  const [currentError, setCurrentError] = useState(false);
  const [errorMessageForCoord, setErrorMessageForCoord] = useState([]);

  const [show, setShow] = useState(false);

  const mkPrimary = useRef();
  const handleChange = (e) => {
    if (e && e[0]) {
      setSelectedPCP(e[0]);
    }
  };

  useEffect(() => {
    (async function fetchData() {
      if (!carePaths.length && !isEmpty(patientsSelected)) {
        setIsLoadingComp(true);
        axios
          .get(
            process.env.REACT_APP_REST_API_BASE_URL +
              `/v1/care-paths/care-path-templates?patientId=${encodeURIComponent(
                patientsSelected.id
              )}`,
            {
              headers: {},
            }
          )
          .then((resp) => {
            let options = resp.data;
            options.unshift({});
            setCarePaths(options);
            setIsLoadingComp(false);
          })
          .catch(() => {
            setErrorMessage("An error occurred while processing your request.");
            setCurrentError(true);
          });
      }
    })();
  }, []);

  const onCreate = () => {
    const mkPrim = mkPrimary.current.checked;
    const payLoad = {
      carePathTemplateId: selectedCarePath,
      patientIds: [patientsSelected.id],
      steps: carePathSteps,
      coordinatorId: selectedPCP.value,
      primary: mkPrim,
    };

    const url = baseURLCP + "/assign";
    setIsLoading(true);
    axios
      .put(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.fail && response.data.type === "FAIL") {
          setErrorMessageForCoord([response.data.error]);
          setIsLoading(false);
        } else {
          patientsSelected.carePaths.push(response.data.result[0]);
          setIsLoading(false);
          updateSelectedCarePath(response.data.result[0]);
          onHideClicked(null, true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("An error occurred while processing your request.");
        setCurrentError(true);
      });
  };

  const handleChangeCarePath = (e, error) => {
    const { value } = e.target;

    if (value) {
      setSelectedCarePath(value);
      axios
        .get(
          process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/care-paths/care-path-steps?carePathTemplateId=${encodeURIComponent(
              value
            )}`,
          {
            headers: {},
          }
        )
        .then((resp) => {
          let options = addSteps(resp.data);
          setCarePathSteps(options);
          setIsLoadingComp(false);
        })
        .catch(() => {
          setErrorMessage("An error occurred while processing your request.");
          setCurrentError(true);
        });
    }
  };
  const addSteps = (list) => {
    let subStep = 0;
    let mainStep = 0;
    let finalList = [];
    list.forEach((cp, index) => {
      if (!cp.subStep) {
        mainStep++;
        cp.stepNumber = mainStep;
        subStep = 1;
      } else {
        const subSTepNum = mainStep + "." + subStep;
        cp.stepNumber = Number(subSTepNum);
        subStep++;
      }
      finalList.push(cp);
    });
    return finalList;
  };

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const clientId =
        patientsSelected && patientsSelected.clientId
          ? patientsSelected.clientId
          : "";
      const url =
        baseURL +
        "/search-coordinators" +
        (query ? "?name=" + query : "") +
        (clientId ? "&clientId=" + clientId : "") +
        ("&patientId=" + encodeURIComponent(patientsSelected.id));
      setIsLoadingComp(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp.data.map((i) => ({
              value: i.value,
              text: i.text,
            }));
          }
          setOptions(options);
          setIsLoadingComp(false);
        })
        .catch(() => {
          setErrorMessage("An error occurred while processing your request.");
          setCurrentError(true);
        });
    }
  };

  const moveItem = (from, to, newState) => {
    var f = newState.splice(from, 1)[0];
    newState.splice(to, 0, f);
    return newState;
  };

  const handleStepMovement = (e, step) => {
    let currentIndex = null;
    let prevStepNum = step.stepNumber;
    let newState = carePathSteps;
    newState.forEach((stp, i) => {
      if (stp.stepNumber === step.stepNumber) {
        currentIndex = i;
      }
    });
    if (e === "up") {
      let newIndex = currentIndex - 1;
      newState[currentIndex].stepNumber = newState[newIndex].stepNumber;
      newState[newIndex].stepNumber = prevStepNum;
      newState = moveItem(currentIndex, newIndex, newState);
    } else if (e === "down") {
      let newIndex = currentIndex + 1;
      newState[currentIndex].stepNumber = newState[newIndex].stepNumber;
      newState[newIndex].stepNumber = prevStepNum;
      newState = moveItem(newIndex, currentIndex, newState);
    } else if (e === "remove") {
      newState[currentIndex].deleted = true;
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > prevStepNum) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber - 1) * 10) / 10;
        }
      }
    }
    setCarePathSteps([...newState]);
  };

  const saveNewStep = (stepObj, index, action, number) => {
    let newState = carePathSteps;
    if (action === "before") {
      if (index === 0) {
        for (let i = 0; i < newState.length; i++) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
        stepObj.stepNumber = number;
        newState.unshift(stepObj);
      } else {
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].stepNumber >= number) {
            newState[i].stepNumber =
              Math.round((newState[i].stepNumber + 1) * 10) / 10;
          }
        }
        stepObj.stepNumber = number;
        newState.splice(index, 0, stepObj);
      }
    } else {
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > number) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
      }
      stepObj.stepNumber = Math.floor(number + 1);
      newState.splice(index + 1, 0, stepObj);
    }
    setCarePathSteps([...newState]);
  };

  const editExistingStep = (step, stepObj, index) => {
    let newState = carePathSteps;
    let oldObj = newState[index];
    let newObj = {};
    Object.keys(oldObj).forEach((key) =>
      key in stepObj
        ? (newObj[key] = stepObj[key])
        : (newObj[key] = oldObj[key])
    );
    newState[index] = newObj;
    setCarePathSteps([...newState]);
  };

  return (
    <StyledRow>
      <Col sm={6} className="pt-3">
        <h5 className="fw-bold">
          <a id={`${componentName}-fullName`} className="text-primary">
            {patientsSelected.lastName}, {patientsSelected.firstName}{" "}
          </a>
        </h5>
        <h6 id={`${componentName}-currentCarePath`} className="mt-3">Current Care Program:</h6>
        <div className="fw-bold">
          {carePathsArray &&
            carePathsArray.map((cp, idx) =>
              cp.carePathStepsAggregatorDto && cp.isActive ? (
                <>
                  <div id={`${componentName}-carePath-${idx}`} className="d-block">
                    <span id={`${componentName}-carePathName-${idx}`}>{cp.name}</span>
                    {cp.primary && (
                      <Badge id={`${componentName}-carePathPrimary-${idx}`} variant="secondary" className="ms-2">
                        Primary
                      </Badge>
                    )}
                  </div>
                </>
              ) : null
            )}

          {!carePathsArray &&
            patientsSelected.carePaths.map((cp, idx) =>
              cp.carePathStepsAggregatorDto ? (
                <>
                  <div id={`${componentName}-carePath-${idx}`} className="d-block">
                    <span id={`${componentName}-carePathName-${idx}`}>{cp.name}</span>
                    {cp.primary && (
                      <Badge id={`${componentName}-carePathPrimary-${idx}`} variant="secondary" className="ms-2">
                        Primary
                      </Badge>
                    )}
                  </div>
                </>
              ) : null
            )}
        </div>
      </Col>
      <Col sm={6} className="border-start bg-light py-3">
        <Form>
          {currentError && (
            <ErrorToast
              errorParam={errorMessage}
              closeToast={setCurrentError}
            />
          )}
          <Form.Group id={`${componentName}-carePathDropdown`}>
            <Form.Label id={`${componentName}-carePathLabel`} className="fw-bold d-block">
              Care Program
            </Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              id={`${componentName}-carePathValue`}
              name="client"
              onChange={(e) => handleChangeCarePath(e)}
            >
              {carePaths.map((cp, idx) => (
                <option id={`${componentName}-carePathItem-${idx}`} key={cp.value} value={cp.value}>
                  {cp.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group id={`${componentName}-carePathCoordinator`} controlId="carepathcoordicator">
            <Form.Label id={`${componentName}-carePathCoordinatorLabel`} className="fw-bold d-block">
              Care Program Coordinator
            </Form.Label>
            <StyledTypeAhead
              disabled={isEmpty(selectedCarePath)}
              inputProps={{
                id: `${componentName}-carePathCoordinatorValue`
              }}
              labelKey="text"
              isLoading={isLoadingComp}
              onSearch={asyncsearch}
              options={options}
              onChange={(e) => handleChange(e)}
              placeholder="Search coordinators by name"
            />
          </Form.Group>
          <Form.Check id={`${componentName}-carePathMakePrimary`} className="d-inline-block mb-3">
          <Form.Check.Input
              name="select make primary care program"
              type="checkbox"
              id={`${componentName}-carePathMakePrimary`}
              ref={mkPrimary}

          />
          <Form.Label id={`${componentName}-carePathMakePrimaryLabel`} >Make Primary Care Program</Form.Label>
          </Form.Check>
        </Form>
        <Row>
          <Col>
            <div id={`${componentName}-carePathSteps`} className="fw-bold">{carePathSteps.length} Steps:</div>
          </Col>
        </Row>
        {carePathSteps &&
          carePathSteps.length &&
          carePathSteps.map((step, index) => (
            <StepComponent
              step={step}
              patientsSelected={patientsSelected}
              newStep={setNewSteps}
              handleStepMovement={handleStepMovement}
              index={index}
              pathsLength={carePathSteps.length}
              saveNewStep={saveNewStep}
              editExistingStep={editExistingStep}
            />
          ))}
      </Col>

      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <StyledFooter>
        <Button id={`${componentName}-closeButton`} onClick={(e) => onHideClicked(e, false)}>Close</Button>{" "}
        {errorMessageForCoord && (
          <ToastOverlay>
            <EQHErrorToast
              errors={errorMessageForCoord}
              handleClose={() => setErrorMessageForCoord([])}
              styleRemove={true}
            />
          </ToastOverlay>
        )}
        <Button id={`${componentName}-assignButton`} disabled={isEmpty(selectedCarePath)} onClick={onCreate}>
          Assign
        </Button>
      </StyledFooter>
    </StyledRow>
  );
};

const StepComponent = ({
  step,
  patientsSelected,
  newSteps,
  handleStepMovement,
  index,
  pathsLength,
  saveNewStep,
  editExistingStep,
}) => {
  const isEmpty = require("is-empty");
  const [selectedPCP, setSelectedPCP] = useState({});
  const [notesAdded, setNotesAdded] = useState([]);
  // const [selectedStep, setSelectedStep] = useState(null);
  const [showBeforeForm, setShowBeforeForm] = useState(false);
  const [showAfterForm, setShowAfterForm] = useState(false);
  const [editStep, setEditStep] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const textBoxRefForNote = useRef();
  const auth = useAuth();
  const onAdd = () => {
    let newName;
    if (
      textBoxRefForNote &&
      textBoxRefForNote.current &&
      textBoxRefForNote.current.value
    ) {
      step.notes = [];
      step.notes.push({ note: textBoxRefForNote.current.value });
      setNotesAdded([...notesAdded, textBoxRefForNote.current.value]);
    }
  };

  const openCreationView = (e, step) => {
    if (e === "before") {
      setShowAfterForm(false);
      setSelectedAction("before");
      setShowBeforeForm(true);
    } else if (e === "after") {
      setShowBeforeForm(false);
      setSelectedAction("after");
      setShowAfterForm(true);
    } else if (e === "edit") {
      setShowBeforeForm(false);
      setSelectedAction("edit");
      setEditStep(true);
      setShowAfterForm(true);
    }
  };

  const closeOpenForm = () => {
    setShowAfterForm(false);
    setShowBeforeForm(false);
  };

  const handleChange = (e) => {
    if (e && e[0]) {
      step.assignedToRef = e[0].value;
    }
  };
  return (
    <Row className="mb-3">
      <Col>
        {showBeforeForm && (
          <>
            <br />
            <PopUpComponent
              toggleShow={setShowBeforeForm}
              step={editStep ? step : null}
              saveNewStep={saveNewStep}
              editExistingStep={editExistingStep}
              index={index}
              action={selectedAction}
              closeOpenForm={closeOpenForm}
              number={step.stepNumber}
            />
            <br />
          </>
        )}
        <Accordion>
          <Card id={`${componentName}-carePaths-${index}`} className="overflow-inherit">
            <Card.Header id={`${componentName}-carePathsHeader-${index}`}>
              <Button
                variant="link"
                eventKey="0"
                id={`${componentName}-carePathStep-${index}`}
                className="align-start text-dark p-0 fw-bold"
              >
                Step {step.stepNumber}: {step.title}
              </Button>
              <Dropdown id={`${componentName}-ActionsDropdown-${index}`} className="float-end">
                <Dropdown.Toggle
                  size="sm"
                  variant="outline-secondary"
                  id={`${componentName}-Actions-${index}`}
                  disabled={showAfterForm || showBeforeForm}
                >
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    id={`${componentName}-ActionsAddStepBefore-${index}`}
                    eventKey="before"
                    onSelect={(e) => openCreationView(e, step)}
                  >
                    Add Step Before
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={`${componentName}-ActionsAddStepAfter-${index}`}
                    eventKey="after"
                    onSelect={(e) => openCreationView(e, step)}
                  >
                    Add Step After
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={`${componentName}-ActionsEditStep-${index}`}
                    eventKey="edit"
                    onSelect={(e) => openCreationView(e, step)}
                  >
                    Edit Step
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={`${componentName}-ActionsMoveUp-${index}`}
                    eventKey="up"
                    onSelect={(e) => handleStepMovement(e, step)}
                    disabled={index === 0}
                  >
                    Move Up
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={`${componentName}-ActionsMoveDown-${index}`}
                    eventKey="down"
                    onSelect={(e) => handleStepMovement(e, step)}
                    disabled={index + 1 === pathsLength}
                  >
                    Move Down
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={`${componentName}-ActionsRemoveStep-${index}`}
                    eventKey="remove"
                    onSelect={(e) => handleStepMovement(e, step)}
                  >
                    Remove Step
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <StepCoordinator
                  idx={index}
                  patientsSelected={patientsSelected}
                  handleChange={handleChange}
                />
                <Form.Group>
                  <Form.Label id={`${componentName}-carePathNotes-${index}`} className="fw-bold d-block">
                    Notes
                  </Form.Label>
                  {!isEmpty(notesAdded) &&
                    notesAdded.map((note, idx) => {
                      return (
                        <Row id={`${componentName}-Note-${idx}`} className="mb-3 px-3">
                          <Col className="border py-2 bg-light">
                            <Row>
                              <Col id={`${componentName}-NotesName-${idx}`}>{auth.getName()}</Col>
                              <Col id={`${componentName}-NotesDate-${idx}`} className="text-end">
                                <small>{formatDateTime(new Date().toISOString())}</small>
                              </Col>
                            </Row>
                            <hr className="my-2" />
                            <Row>
                              <Col id={`${componentName}-NotesText-${idx}`}>{note}</Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                  <Form.Control
                    as="textarea"
                    id={`${componentName}-carePathNotesBox-${index}`}
                    ref={textBoxRefForNote}
                    onFocus={() => setShowAdd(true)}
                    rows="3"
                    className="mb-3"
                  />
                  {showAdd && (
                    <Button
                      onClick={onAdd}
                      variant="primary"
                      id={`${componentName}-carePathAddNotes-${index}`}
                      size="sm"
                      className="me-2"
                    >
                      Add
                    </Button>
                  )}
                  {showAdd && (
                    <Button
                      onClick={() => setShowAdd(false)}
                      variant="secondary"
                      id={`${componentName}-carePathCancelNotes-${index}`}
                      size="sm"
                    >
                      Cancel
                    </Button>
                  )}
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {showAfterForm && (
          <>
            <br />
            <PopUpComponent
              toggleShow={setShowAfterForm}
              step={editStep ? step : null}
              saveNewStep={saveNewStep}
              editExistingStep={editExistingStep}
              index={index}
              action={selectedAction}
              closeOpenForm={closeOpenForm}
              number={step.stepNumber}
            />
            <br />
          </>
        )}
      </Col>
    </Row>
  );
};

const PopUpComponent = ({
  toggleShow,
  step,
  saveNewStep,
  editExistingStep,
  index,
  action,
  closeOpenForm,
  number,
}) => {
  const [formData, setFormData] = useState({
    completeBy: 0,
    description: "",
    dueDate: "",
    stepNumber: null,
    timing: "BEFORE",
    title: "",
    trigger: "",
  });
  const [dateOrTrigger, setDateOrTrigger] = useState(false);
  const [lockSave, setLockSave] = useState(true);

  useEffect(() => {
    if (step) {
      setFormData({
        ...formData,
        completeBy: step.completeBy,
        description: step.description,
        dueDate: step.dueDate ? new Date(step.dueDate) : null,
        stepNumber: step.stepNumber,
        timing: step.timing,
        title: step.title,
        trigger: step.trigger,
        needToScheduleAppointment: step.needToScheduleAppointment,
      });
    }
  }, []);

  const updateFormDateField = (e) => {
    let newState = formData;
    newState.dueDate = e;
    setFormData({
      ...newState,
    });
    updateFormFieldMain(newState);
  };

  const updateFormField = (e) => {
    let newState = formData;
    newState[e.target.name] = e.target.value;
    setFormData({ ...newState });
    updateFormFieldMain(newState);
  };

  const updateFormFieldMain = (newState) => {
    if (step) {
      setLockSave(false);
    } else if (
      newState.title.length &&
      newState.timing.length &&
      newState.trigger.length
    ) {
      setLockSave(false);
    } else if (newState.title.length && newState.dueDate) {
      setLockSave(false);
    } else {
      setLockSave(true);
    }
  };

  const saveStep = () => {
    if (step) {
      editExistingStep(step, formData, index);
    } else {
      saveNewStep(formData, index, action, number);
    }
    closeOpenForm();
  };

  return (
    <Row id={`${componentName}-carePathEditStep-${index}`} className="border bg-white mx-0 pt-3">
      <Col>
        <Form.Group>
          <Form.Label id={`${componentName}-carePathESNameLabel-${index}`} className="fw-bold d-block">
            Step<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            id={`${componentName}-carePathESNameValue-${index}`}
            value={formData.title}
            onChange={(e) => updateFormField(e)}
          />
        </Form.Group>

        <Form>
          {["radio"].map((type) => (
            <div id={`${componentName}-carePathESAction-${index}`} key={`${componentName}-carePathESAction-${index}`} className="mb-2">
              <div className="d-inline-block me-3">
                <Form.Check id={`${componentName}-carePathESActionTE-${index}`} className="d-inline">
                  <Form.Check.Input id={`${componentName}-carePathESActionTE-${index}`} type={type} onClick={() => setDateOrTrigger(false)} checked={!dateOrTrigger} />
                  <Form.Check.Label id={`${componentName}-carePathESActionTELabel-${index}`} className="ms-2 align-middle" onClick={() => setDateOrTrigger(false)}>Trigger Event</Form.Check.Label>
                </Form.Check>
              </div>
              <div className="d-inline-block">
                <Form.Check id={`${componentName}-carePathESActionDD-${index}`} className="d-inline">
                  <Form.Check.Input id={`${componentName}-carePathESActionDD-${index}`} type={type} onClick={() => setDateOrTrigger(true)} checked={dateOrTrigger} />
                  <Form.Check.Label id={`${componentName}-carePathESActionDDLabel-${index}`} className="ms-2 align-middle" onClick={() => setDateOrTrigger(false)}>Due Date</Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          ))}
        </Form>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label id={`${componentName}-carePathESDaysLabel-${index}`}>
                Days<span className="text-danger">*</span>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Control
              disabled={dateOrTrigger}
              name="completeBy"
              id={`${componentName}-carePathESDaysValue-${index}`}
              value={formData.completeBy}
              onChange={(e) => updateFormField(e)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Control
              as="select"
              defaultValue="Before"
              name="timing"
              id={`${componentName}-carePathESDaysOptions-${index}`}
              onChange={(e) => updateFormField(e)}
              disabled={dateOrTrigger}
              value={formData.timing}
            >
              <option id={`${componentName}-carePathESDaysBefore-${index}`} value="BEFORE">Before</option>
              <option id={`${componentName}-carePathESDaysAfter-${index}`} value="AFTER">After</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <DatePickerStyled>
              <DatePicker
                disabled={!dateOrTrigger}
                name="dueDate"
                value={formData.dueDate}
                selected={formData.dueDate}
                onChange={(e) => updateFormDateField(e)}
                id={`${componentName}-carePathESDaysDate-${index}`}
                className="form-control"
                popperPlacement="auto-left"
                autoComplete="off"
              />
            </DatePickerStyled>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Control
              as="select"
              defaultValue=""
              name="trigger"
              id={`${componentName}-carePathESDaysTrigger-${index}`}
              onChange={(e) => updateFormField(e)}
              disabled={dateOrTrigger}
              value={formData.trigger}
            >
              <option selected disabled></option>
              {carePathValues.map((item, idx) => (
                <option id={`${componentName}-carePathESDaysTriggerItems-${index}-${idx}`} value={item.value}>{item.text}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Form.Group id={`${componentName}-carePathESDaysDesc-${index}`}>
          <Form.Label id={`${componentName}-carePathESDaysDescLabel-${index}`} className="fw-bold d-block">
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="description"
            id={`${componentName}-carePathESDaysDescValue-${index}`}
            value={formData.description}
            onChange={(e) => updateFormField(e)}
          />
        </Form.Group>

        <Row className="float-end">
          <Form.Group>
            <Button id={`${componentName}-carePathESCancel-${index}`} onClick={() => toggleShow(false)}>Cancel</Button>
          </Form.Group>
          <Form.Group className="ms-2">
            <Button id={`${componentName}-carePathESSave-${index}`} onClick={() => saveStep()} disabled={lockSave}>
              Save
            </Button>
          </Form.Group>
        </Row>
      </Col>
    </Row>
  );
};

const StepCoordinator = ({ patientsSelected, handleChange, idx }) => {
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [options, setOptions] = useState([]);
  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const clientId =
        patientsSelected && patientsSelected.clientId
          ? patientsSelected.clientId
          : "";
      const url =
        baseURL +
        "/search-coordinators" +
        (query ? "?name=" + query : "") +
        (clientId ? "&clientId=" + clientId : "") +
        ("&patientId=" + encodeURIComponent(patientsSelected.id));

      setIsLoadingComp(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp.data.map((i) => ({
              value: i.value,
              text: i.text,
            }));
          }
          setOptions(options);
          setIsLoadingComp(false);
        });
    }
  };
  return (
    <Form.Group id={`${componentName}-stepCoordinator-${idx}`} controlId="carepathcoordicator">
      <Form.Label id={`${componentName}-stepCoordinatorLabel-${idx}`} className="fw-bold d-block">
        Step Coordinator
      </Form.Label>
      <StyledTypeAhead
        inputProps={{
          id: `${componentName}-stepCoordinatorValue-${idx}`
        }}
        labelKey="text"
        isLoading={isLoadingComp}
        onSearch={asyncsearch}
        options={options}
        onChange={(e) => handleChange(e)}
        placeholder="Search coordinators by name"
      />
    </Form.Group>
  );
};
export default AssignCarePath;
