const getUSHolidays = (year) => {
    const holidays = [
        new Date(year, 0, 1), //New Year's Day
        new Date(year, 6, 4), //Independence Day
        new Date(year, 11, 25) //Christmas Day
    ]

    //Martin Luther King Jr. Day
    const mlkDay = new Date(year, 0, 1);
    mlkDay.setDate(15 + (1 - mlkDay.getDay() + 7) % 7);
    holidays.push(mlkDay);

    //Presidents' Day
    const presidentsDay = new Date(year, 1, 1);
    presidentsDay.setDate(15 + (1 - presidentsDay.getDay() + 7) % 7);
    holidays.push(presidentsDay);

    //Memorial Day
    const memorialDay = new Date(year, 4, 31);
    memorialDay.setDate(memorialDay.getDate() - memorialDay.getDay());
    holidays.push(memorialDay);

    //Labor Day
    const laborDay = new Date(year, 8, 1);
    laborDay.setDate(1 + (1 - laborDay.getDay() + 7) % 7);
    holidays.push(laborDay);

    //Columbus Day
    const columbusDay = new Date(year, 9, 1);
    columbusDay.setDate(8 + (1 - columbusDay.getDay() + 7) % 7);
    holidays.push(columbusDay);

    //Veterans Day
    holidays.push(new Date(year, 10, 11));

    //Thanksgiving Day
    const thanksgivingDay = new Date(year, 10, 1);
    thanksgivingDay.setDate(22 + (4 - thanksgivingDay.getDay() + 7) % 7);
    holidays.push(thanksgivingDay);

    return holidays
}

const isHoliday = (date, holidays) => {
    return holidays.some(holiday => holiday.getTime() === date.getTime());
}

const isBusinessDay = (date, holidays) => {
    const day = date.getDay();
    return day !== 0 && day !== 6 && !isHoliday(date, holidays)
}

export const addBusinessDaysCustom = (startDate, days) => {
    let currentDate = new Date(startDate);
    const year = currentDate.getFullYear();
    const holidays = getUSHolidays(year);
    let addedDays = 0;

    while (addedDays < days) {
        currentDate.setDate(currentDate.getDate() + 1);
        if (isBusinessDay(currentDate, holidays)) {
            addedDays++;
        }
    }

    return currentDate;
}