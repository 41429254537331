import React from "react";

const patientProfilePermissions = {
  Overview: {
    auth: [
      "AUTH_PATIENT_READ_ACCESS",
      "AUTH_PATIENT_WRITE_ACCESS",
    ],
    allowAllAccess: true },
  HospitalEvents: { auth: [
      "HOSPITAL_EVENTS_QUICK_LIST_ACCESS",
    ], allowAllAccess: true },
  PreventiveCare: {
    auth: [
      "AUTH_CARE_GAPS_READ_ACCESS",
      "AUTH_CARE_GAPS_WRITE_ACCESS",
      "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS",
      "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS",
    ],
    allowAllAccess: true },
    MissedOpportunities: {
      auth: [
        "AUTH_CARE_GAPS_READ_ACCESS",
        "AUTH_CARE_GAPS_WRITE_ACCESS",
        "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS",
        "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS",
      ],
      allowAllAccess: true },
  Appointments: {
    auth: [
      "AUTH_APPOINTMENTS_READ_ACCESS",
      "AUTH_APPOINTMENTS_WRITE_ACCESS",
    ],
    allowAllAccess: true },
  Assessments: { auth: [
      "AUTH_ASSESSMENTS_READ_ACCESS",
      "AUTH_ASSESSMENTS_WRITE_ACCESS",
    ], allowAllAccess: true },
  Referrals: {
    auth: [
      "AUTH_REFERRAL_SENT_ACCESS",
      "AUTH_REFERRAL_RECEIVED_ACCESS",
      "AUTH_REFERRAL_MANAGEMENT_CARE_COORDINATION_ACCESS",
    ],
    allowAllAccess: false,
  },
  Notes: { auth: [
      "AUTH_FILES_READ_ACCESS",
      "AUTH_FILES_WRITE_ACCESS",
    ], allowAllAccess: true },
  History: { auth: [], allowAllAccess: true },
  CarePath: {
    auth: [
      "AUTH_CARE_PATHS_READ_ACCESS",
      "AUTH_CARE_PATHS_WRITE_ACCESS",
    ],
    allowAllAccess: true },
};

const patProfHasAccessTo = (key, auth) => {
  const permissions = patientProfilePermissions[key];
  if (!permissions.auth || !permissions.auth.length) {
    return permissions.allowAllAccess;
  }
  return auth.hasAnyAuthority(permissions.auth);
};

export { patProfHasAccessTo };
