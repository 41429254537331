import React, { useEffect } from 'react'
import { ReferralModal } from './modalsComponents'
import { useReferralsContext } from './context/Referrals.context';
import { cancelledReason, declineReason } from '../../../utils/textValueLists';
import DatePicker from "react-datepicker";
import styled from 'styled-components';

const componentName = 'ReferralModal'

function Modals({ patient }) {
  const {
    showAccepted,
    setAccepted,
    showDeclined,
    setDeclined,
    showCompleted,
    setCompleted,
    showCancelled,
    setCancelled,
    showPending,
    setPending,
    showPatientCancelled,
    setPatientCancelled,
    query,
    setQuery
  } = useReferralsContext();

  const DatePickerStyled = styled.div`
    .react-datepicker-wrapper {
      width: 100%;
    }
  `;

  return (
    <>
      <ReferralModal
        title='Referral Accepted'
        patient={patient}
        showModal={showAccepted}
        setModal={setAccepted}
        status={"40_ACCEPTED"}
      />
      <ReferralModal
        title='Referral Declined'
        patient={patient}
        showModal={showDeclined}
        setModal={setDeclined}
        status={"60_DECLINED"}
        disabled={!query.declinedReason}
      >
        <>
          <p className="fw-bold mb-0" style={{ fontSize: '12px' }}>Reason</p>
          <select
            id={`${componentName}-declinedOptions`}
            className="form-select"
            onChange={e => setQuery({ ...query, declinedReason: e.target.value })}
            value={query?.declinedReason}
            style={{ marginBottom: '15px' }}
          >
            <option id="teamOption-select" value="">Select</option>
            {declineReason.map((reason, i) => (
              <option key={`declinedOption-${i}`} id={`declinedOption-${i}`} value={reason.value}>{reason.text}</option>
            ))}
          </select>
        </>
      </ReferralModal>
      <ReferralModal
        title='Referral Cancelled'
        patient={patient}
        showModal={showCancelled}
        setModal={setCancelled}
        status={"10_CANCELLED"}
        disabled={!query?.cancelledReason || query?.cancelledReason?.length <= 0}
      >
        <>
          <p className="fw-bold mb-0" style={{ fontSize: '12px' }}>Reason</p>
          <select
            id={`${componentName}-cancelledOptions`}
            className="form-select"
            onChange={e => setQuery({ ...query, cancelledReason: e.target.value })}
            value={query?.cancelledReason}
            style={{ marginBottom: '15px' }}
          >
            <option id="teamOption-select" value="">Select</option>
            {cancelledReason.map((reason, i) => (
              <option key={`cancelledOptions-${i}`} id={`cancelledOptions-${i}`} value={reason.value}>{reason.text}</option>
            ))}
          </select>
        </>
      </ReferralModal>
      <ReferralModal
        title='Referral Pending'
        patient={patient}
        showModal={showPending}
        setModal={setPending}
        status={"50_PENDING"}
        disabled={!query?.notes || query?.notes?.length <= 0}
      />
      <ReferralModal
        title='Referral Patient Cancelled'
        patient={patient}
        showModal={showPatientCancelled}
        setModal={setPatientCancelled}
        status={"20_PATIENT_CANCELLED"}
        disabled={!query?.notes || query?.notes?.length <= 0}
      />
      <ReferralModal
        title='Referral Completed'
        patient={patient}
        showModal={showCompleted}
        setModal={setCompleted}
        status={"30_COMPLETED"}
        disabled={!query?.notes || query?.notes?.length <= 0 || !query?.dateOfService}
      >
        <>
          <p className="fw-bold mb-0" style={{ fontSize: '12px' }}>Date</p>
          <DatePickerStyled>
            <DatePicker
              id={`${componentName}-DateValue`}
              name="dateOfService"
              value={query?.dateOfService}
              selected={query?.dateOfService}
              onChange={(e) =>
                setQuery({ ...query, dateOfService: e })
              }
              className="form-control"
              popperPlacement="auto-left"
              autoComplete="off"
              maxDate={new Date()}
            />
          </DatePickerStyled>
        </>
      </ReferralModal>
    </>
  )
}

export default Modals