import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { _calculateAge, formatDate, getRiskLevelStyle } from '../../../../utils/util'
import {
  setManageVisitOpenedFromPageTitle,
  setManageVisitPatient
} from "../../../../actions/manageVisitActions";
import { useDispatch } from "react-redux";


const componentName = "FullNameColumn"

const FullName = ({ referral, idx }) => {

  const dispatch = useDispatch();

  return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row>
        <Col className="d-flex align-items-center">
          <a id={`${componentName}-fullName-${idx}`}
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName me-2"
             onClick={() => {
               dispatch(setManageVisitPatient(referral));
               dispatch(setManageVisitOpenedFromPageTitle('NewReferralsReceived'))
             }}
          >
            <span className="forced-capital fs-5 d-inline-block">{referral?.fullName}</span>
          </a>
          <Badge pill id={`${componentName}-patientGender-${idx}`} bg="light" text="dark" className="align-middle border forced-capital me-2">
            {referral?.gender}
          </Badge>
          <Badge pill bg={getRiskLevelStyle(referral?.riskLevel)} id={`${componentName}-patientRiskLevel-${idx}`}>
            {referral?.riskLevel}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col id={`${componentName}-memberID-${idx}`} className="text-muted col-md-auto">
          <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold">ID:</span>
          <span id={`${componentName}-memberIDValue-${idx}`}>{referral.memberId}</span>
        </Col>
        <Col id={`${componentName}-memberDOB-${idx}`} className="d-flex align-items-center text-muted col-md-auto">
          <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold me-2">DOB:</span>
          <span id={`${componentName}-memberIDValue-${idx}`}>{formatDate(referral.dateOfBirth)}</span>
          <small className="badge bg-light border rounded-circle text-dark ms-2 p-1">{_calculateAge(referral?.dateOfBirth)}</small>
        </Col>
      </Row>
    </Col>
  )
}

export default FullName