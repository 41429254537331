import axios from "../../utils/apiClient";

export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/hub-workcenter/";
export const SUMMARY_URL =
    process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
export const REFERRAL_URL = `${URL}referrals`;
export const PATIENTS_URL = `${URL}patients/`;
export const DAILY_TICKER_URL = `${URL}daily-ticker`;
export const EXPORT_URL = `${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-export/export-patients`;
const isEmpty = require("is-empty");

export const MARKETS = [
    {text:"Arizona", value:"Arizona"},
    {text:"Texas", value:"Texas"}
];

export const requestReferrals = () => {
    return axios
        .post(REFERRAL_URL, {
            "filterParams": [],
            "sortParams": [],
            "pageNumber": 1,
            "pageSize": 20,
            "quickListName": "HUB_WORKSPACE"
        }, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const searchPatients = (searchParam) => {
    return axios
        .get(PATIENTS_URL + searchParam.trim(), {
            params: {},
            headers: {},
        });
}

export const addReferral = (patientId) => {
    return axios.put(REFERRAL_URL, {
        "patientId": patientId
    }, {
        headers: {
            "Content-Type": "application/json"
        },
    });
}

export const exportMembers = (selectedReferrals, exportFilterParms) => {
    const criteria = {
        "patientRequest": {
            "filterParams": exportFilterParms || [],
            "sortParams": [],
            "pageNumber": 1,
            "pageSize": 20,
            "quickListName": "HUB_WORKSPACE"
        },
        "selectedItems": Array.from(selectedReferrals),
        "quickList": "HUB_WORKSPACE"
    }

    return axios
        .post(EXPORT_URL, criteria, {
            headers: {
                "Content-Type": "application/json",
            },
        });
}

export const getExportSummary = () => {
    return axios
        .get(SUMMARY_URL, {
            headers: {
                "Content-Type": "application/json",
            },
        });
}

export const getDailyTicker = (market = "") => {
    const dailyTicketUrl = isEmpty(market)? DAILY_TICKER_URL : `${DAILY_TICKER_URL}?market=${market}`;
    return axios
        .get(dailyTicketUrl, {
            headers: {
                "Content-Type": "application/json",
            },
        });
}

export const uploadFile = (file) => {
    return axios
        .post(`${URL}upload-referrals`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
}

export const handleNavigateToCarePath = (patientId)=>{
    const patientUrl = "/patient-profile?" + new URLSearchParams({
        'patientId': patientId,
        'amp;returnTo': '/caremgmt/digitaloutreach',
        'tab': 'CarePath'
    });

    window.open(patientUrl);
}

export const getCareManagementPrograms = () => {
    return axios
        .get(`${URL}programs`);
}

export const searchProgramLead = (name, teamId) => {
    return axios
        .get(`${URL}program-leads?name=${name}&teamId=${teamId || ''}`);
}

export const assignCareManager = (payload) => {
    return axios.put(`${URL}assign`, payload)
}

export const getCareManagementActions = () => {
    return axios.get(`${URL}actions`    )
}