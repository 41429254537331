import React, { useEffect, Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import {
  useGetUserProfileInfo,
  saveUserProfileInfo,
} from "../../actions/accountSettingsAction";
import { Filter } from "../../components/filters/filterGroup";
import { FILTER_DEFINITIONS_MAP } from "../../components/filters/filterDefinition";
import { EQHSpinner } from "../../components/EQHSpinner";
import Toast from "react-bootstrap/Toast";
import { FilterParam } from "../../dtos/PatientSearchDTO";
import {stateAbbrMap} from "../../actions/constantsActions"
import { handlePkgErrors } from "../../utils/util";

const componentName = "PersonalInfomration";
const isEmpty = require("is-empty");

const CapitalizingInput = styled.input`
  text-transform: capitalize;
`;

const userProfileReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const PersonalInformation = () => {
  const [show, setShow] = useState(false);
  const userProfilePkg = useGetUserProfileInfo({}, userProfileReducer);

  const save = (userProfileInfo) => {
    saveUserProfileInfo(userProfileInfo)
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    userProfilePkg.fetchData();
  }, []);

  return (
    <Fragment>
      {userProfilePkg.state.isLoading && <EQHSpinner />}
      {userProfilePkg.state.error ? (
        <Card>
          <Card.Body>{handlePkgErrors(userProfilePkg.state.error)}</Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Form autoComplete="off">
            <Row>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-firstNameLabel`}>First Name</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-firstNameValue`}
                  name="firstName"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.firstName : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch ({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        firstName: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-middleNameLabel`}>Middle Name</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-middleNameValue`}
                  name="middleName"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.middleName : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        middleName: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-lastNameLabel`}>Last Name</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-lastNameValue`}
                  name="lastName"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.lastName : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        lastName: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-addressLabel`}>Address</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-addressValue`}
                  name="address"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.address : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        address: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-primaryPhoneLabel`}>Primary Phone</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-primaryPhoneValue`}
                  name="primaryPhone"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.primaryPhone : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        primaryPhone: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-emailLabel`}>Email</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-emailValue`}
                  name="email"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.email : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: { ...userProfilePkg.state.data, email: e.target.value },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-cityLabel`}>City</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-cityValue`}
                  name="city"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.city : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: { ...userProfilePkg.state.data, city: e.target.value },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-altPhoneLabel`}>Alt Phone</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-altPhoneValue`}
                  name="altPhone"
                  className="form-control"
                  value={
                    userProfilePkg.state.data ? userProfilePkg.state.data.secondaryPhone : ""
                  }
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        secondaryPhone: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-federalDeaLabel`}>Federal Dea #</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-federalDeaValue`}
                  name="federalDea"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.federalDea : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: {
                        ...userProfilePkg.state.data,
                        federalDea: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Row>
                  <Col sm={6} className="mb-3">
                    <Filter
                      filterDefinition={
                        FILTER_DEFINITIONS_MAP["State_1_Select"]
                      }
                      name="state"
                      selected={userProfilePkg.state.data ? 
                        new FilterParam(
                          "State",
                          "eq",
                          [userProfilePkg.state.data.state],
                          (e) => e,
                          (e) => stateAbbrMap[userProfilePkg.state.data.state]
                        ) : 
                        ""
                      }
                      handleChange={(value) =>
                        userProfilePkg.dispatch({
                          type: "Update",
                          payload: {
                            ...userProfilePkg.state.data,
                            state: value.paramValue[0],
                          },
                        })
                      }
                      componentProps={{
                        showClearButton: false,
                        highlightWhenHasValue: false,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <label className="d-block" id={`${componentName}-zipLabel`}>Zip</label>
                    <CapitalizingInput
                      type="text"
                      id={`${componentName}-zipValue`}
                      name="zip"
                      className="form-control"
                      value={userProfilePkg.state.data ? userProfilePkg.state.data.zip : ""}
                      onChange={(e) => {
                        userProfilePkg.dispatch({
                          type: "Update",
                          payload: {
                            ...userProfilePkg.state.data,
                            zip: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-faxLabel`}>Fax</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-faxValue`}
                  name="fax"
                  className="form-control"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.fax : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: { ...userProfilePkg.state.data, fax: e.target.value },
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="d-block" id={`${componentName}-npiIdLabel`}>Npi Id #</label>
                <CapitalizingInput
                  type="text"
                  id={`${componentName}-npiIdValue`}
                  name="npi"
                  className="form-control"
                    autoComplete="off"
                  value={userProfilePkg.state.data ? userProfilePkg.state.data.npi : ""}
                  onChange={(e) => {
                    userProfilePkg.dispatch({
                      type: "Update",
                      payload: { ...userProfilePkg.state.data, npi: e.target.value },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <Button id={`${componentName}-saveButton`} variant="primary" onClick={() => save(userProfilePkg.state.data)}>
                  Save
                </Button>
              </Col>
              <Col sm={10}>
                <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={5000}
                  autohide
                >
                  <Toast.Header>
                    <i className="fas fa-check-square text-success"></i>
                    <strong className="me-auto">
                      Personal information updated
                    </strong>
                  </Toast.Header>
                </Toast>
              </Col>
            </Row>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

export default PersonalInformation;
