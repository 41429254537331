import React from "react";

import { QuickLists } from "./QuickListMO";
import { useGetTransQuickListSettings } from "./quickListSettings";
import { useAuth } from "../../context/authContext";
import { QuickListContext } from "./QuickListContext";

const MissedOpportunities  = () => {
    const auth = useAuth();
    const getSettingsRef = useGetTransQuickListSettings("missedOpportunities", 0, auth);

    return (
        <QuickListContext.Provider value={getSettingsRef()}>
            <QuickLists {...getSettingsRef()} />
        </QuickListContext.Provider>
    );
};

export { MissedOpportunities };