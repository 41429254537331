import React, {useEffect, useState} from 'react'
import { orderBy } from 'lodash'
import {Button, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {Timeline as GenericTimeline} from '../../../../components/Timeline';
import {
  formatDateVanilla,
  formatDateWithTime,
  splitDateTime,
} from '../../../../utils/util';
import NoteCard from './NoteCard';
import {useNotesContext} from "../context/Notes.context";
import {FilterGroupNew} from '../../../../components/filters/filterGroupNew';
import {isEmpty} from "lodash";
import {AuthorLabel} from "../constants";
import {getFilterDef} from "../../../../components/filters/filterDefinition";
import {mapFiltersSelected} from "../mappers/FiltersMapper";
import {useAuth} from "../../../../context/authContext";
import AddNoteModal from "./AddNoteModal/AddNoteModal";

const componentName = 'note';

function NotesTimeline({patient, reachedBottom, setReachedBottom}) {
  const {
    getNotes,
    scrollNotes,
    notesList,
    isLoading,
    totalSize,
    hasMore,
    notesSearch,
    setInitialState,
    userNameListFilter,
    toggleShowAddNote,
    getNoteCategories
  } = useNotesContext();

  const auth = useAuth();
  const authorizedForWriteNotes = auth.hasAnyAuthority(
    ["AUTH_PD_NOTES_WRITE_ACCESS"]);

  const [filterParams, setFilterParams] = useState([]);
  const [filters, setFilters] = useState([]);
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (patient?.memberId) {
      setInitialState();
      getNotes(patient?.id, notesSearch);
      getNoteCategories();
    }
  }, [patient])

  useEffect(() => {
    if (notesList?.length > 0 && reachedBottom && !isLoading && hasMore) {
      scrollNotes(patient?.id, notesSearch)
      .then(() => setReachedBottom(false))
    }
  }, [reachedBottom])

  useEffect(() => {
    const filterNames = [
      'Note Category',
    ]

    userNameListFilter?.length > 0 && filterNames.push('Note Author')
    const userNameList = [...userNameListFilter];
    const newFilters = filterNames.map(f => {
      let filterProps = getFilterDef(f);
      if (f === 'Note Author') {
        filterProps = {
          ...filterProps,
          componentProps: {
            ...filterProps.componentProps,
            refreshDataOnStateChange: userNameListFilter,
            getData: () => Promise.resolve(
              orderBy(userNameList, [item => item.text.toLowerCase()],
                ['asc']))
          }
        }
      }
      return filterProps;
    });

    setFilters(newFilters);
  }, [userNameListFilter])

  const createdAtAlreadySeenSet = new Set();

  const millisecondsRemover = (createdAtFormatted) => {
    if (!createdAtFormatted) {
      return null;
    }

    const date = createdAtFormatted.split(":");
    date.pop();
    return date.join('-')
  }

  useEffect(() => {
    if (notesList) {
      setOpenStates(prevStates => {
        const newStates = new Array(notesList.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [notesList])

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  return (
    <>
      <AddNoteModal patient={patient}/>

      <Row className={componentName + "-notes-timeline-view"}>
        <Row className="stickyscroll bg-light no-padding-right">
          <Col md={6}>
            <FilterGroupNew
              filters={filters}
              handleChange={params => {
                setReachedBottom(false)
                setFilterParams(params)
                getNotes(patient?.id, {...notesSearch, pageNumber: 1, filters: params})
              }}
              defaultFilterParams={[]}
              filterParams={mapFiltersSelected(filterParams)}
              isManageVisit={true}
            />
          </Col>
          <Col md={6} style={{textAlign: 'end'}} className="no-padding-right">
            <Button disabled={!authorizedForWriteNotes} onClick={() => {
              toggleShowAddNote(true);
            }}>Add Note</Button>
          </Col>
          <Row className='align-items-baseline'>
            <Col md={6}>
              <span
                bg="light"
                id={`${componentName}-patientCount`}
                className="border text-dark badge badge-pill mt-2">
                {totalSize}{" "}{"Note"}{totalSize === 1 ? '' : 's'}
              </span>
            </Col>
            <Col md={6} className='text-end'>
              <OverlayTrigger
                placement="auto"
                overlay={
                  <Tooltip>
                    Expand / Collapse All
                  </Tooltip>
                }
              >
                <span
                  bg="light"
                  className="border text-dark badge badge-pill mt-2"
                  onClick={() => toggleAll(!expandAll)}
                  style={{maxWidth: 'fit-content', fontSize: '12px', marginRight: '-30px'}}
                >
                  <i className='fas fa-angle-double-up'/>
                  <i className='fas fa-angle-double-down'/>
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row>
        <GenericTimeline
          items={notesList ?? []}
          titleBuilder={(note, idx) => {
            // by definition, notes with the same date (excluding milliseconds) must be grouped
            const createdAtWithoutMilliseconds = millisecondsRemover(
              formatDateWithTime(note.dateTime)
            );
            const shouldShowCreatedAt = !createdAtAlreadySeenSet.has(
              createdAtWithoutMilliseconds);

            if (shouldShowCreatedAt) {
              createdAtAlreadySeenSet.add(createdAtWithoutMilliseconds)
            }

            const [date, time] = splitDateTime(
              formatDateVanilla(note?.dateTime, "MMM dd, yyyy, hh:mm a"));

            return note.dateTime && shouldShowCreatedAt ? (
              <div>
              <span className="d-block fw-bold">
                {date}
              </span>
                <span className="d-block">
                {time}
              </span>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="auto"
                  overlay={
                    <Tooltip>
                      {note.author ? note.author : "No Author"}
                    </Tooltip>
                  }>
                  <AuthorLabel
                    className="d-block badge badge-pill border-0 text-truncate">
                    {note.author ? note.author : "No Author"}
                  </AuthorLabel>
                </OverlayTrigger>
              </div>
            ) : (
              !isEmpty(createdAtWithoutMilliseconds) ? "" : "-"
            )
          }
          }
          bodyBuilder={(note, idx) => (
            <NoteCard
              section={'all'}
              idx={idx}
              note={note}
              toggleIndividual={() => toggleIndividual(idx)}
              open={openStates[idx]}
            />
          )}
        />
        {isLoading &&
          <div className="d-flex w-100 justify-content-center">
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
        {
          !hasMore &&
          <div className="d-flex w-100 justify-content-center">
            <p>You have reached the bottom</p>
          </div>
        }
      </Row>
    </>
  )
}

export default NotesTimeline