import React from "react";
import styled from "styled-components";
import BootstrapTable from "react-bootstrap/Table";
import AutoSizer from "react-virtualized-auto-sizer";

const Table = props => {
  // Needs to be wrapped inside AutoSizer to make very wide tables not to cut
  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <div style={{ width }} className="table-responsive text-nowrap">
            <BootstrapTable {...props} />
          </div>
        );
      }}
    </AutoSizer>
  );
};

export { Table };
