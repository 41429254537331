import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Accordion, AccordionContext, useAccordionButton, Button, Card, Col, Badge, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { MARKETS, getDailyTicker } from '../../actions/caremgmt/hubWorkCenterAction';
import { DropDownList } from '../../components/dropDownLists/genericDDL';

const DropDownDiv = styled.div`
#GenericDDL--filterValue { margin-bottom: 0!important; }
#GenericDDL--filter { margin-bottom: 0!important; }
`;

const componentName = "PatientListOffCanvas";

function PlusMinusToggle({ children, eventKey, callback, idx }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none w-100 p-0" onClick={decoratedOnClick}>
      <i id={`${componentName}-plusMinusIcon-${idx}`} className={isCurrentEventKey ? "far fa-minus-circle me-2" : "far fa-plus-circle me-2"}></i>{" "}
      <div id={`${componentName}-itemTitle-${idx}`} className="d-flex justify-content-between align-items-center w-100">{children}</div>
    </Button>
  );
}

function PatientListOffCanvas({
  onError,
  teams,
  setTeams,
  top
}) {

  const [count, setCount] = useState(0);
  const [selectedMarkets, setSelectedMarket] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    fetchDailyTicker();
    setTimeout(() => setCount(count + 1), 60000);
  }, [count, selectedMarkets]);

  useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasTeamList');
    offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffCanvas(false));
    offcanvasElement.addEventListener('shown.bs.offcanvas', () => handleOffCanvas(true));
    return () => {
      offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffCanvas);
      offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffCanvas);
    };
  }, []);

  const handleOffCanvas = (state) => {
    setShowOverlay(state)
  }

  const fetchDailyTicker = () => {
    const market = selectedMarkets?.displayValue?.join(",");
    getDailyTicker(market).then((response) => {
      setTeams(response.data || []);
    }).catch((error) => {
      onError(error);
    });
  }

  const handleMarketChanged = (param) => {
    setSelectedMarket(param);
  }

  const filterMarkets = () => {
    return Promise.resolve(MARKETS);
  }

  return (
    <>{showOverlay &&
      <div id='overlay-side-filters' onClick={() => {
        let closeCanvas = document.getElementById('closeTeamList');
        closeCanvas.click()
      }}></div>}
    <div
      className="offcanvas offcanvas-end"
      data-bs-scroll="true"
      data-bs-backdrop="false"
      tabIndex="-1"
      id="offcanvasTeamList"
      aria-labelledby="offcanvasTeamList"
      style={{ width: '425px', top: top ? top : '0' }}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title me-3" id="offcanvasTeamList">Team List</h5>
        <DropDownDiv>
          <DropDownList
            showSearchTextbox={false}
            showClearButton={false}
            placeholder="Filter Market"
            header=""
            handleChange={handleMarketChanged}
            height="5"
            getData={filterMarkets}
            selected={selectedMarkets}
          />
        </DropDownDiv>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" id="closeTeamList"></button>
      </div>
      <div className="offcanvas-body">
        <Row>
          <Col>
            {teams.map((team, i) =>
              <Accordion defaultActiveKey="">
                <Card className="rounded-3 mb-2">
                  <Card.Header eventKey="0" className="d-flex align-items-center bg-white rounded-3 cursor-p border-0">
                    <PlusMinusToggle eventKey="0">
                      <div className="w-50 text-start overflow-hidden text-truncate nowrap me-3">
                        <span id={componentName + "-teamName-" + i}>{team.teamName}</span>
                      </div>
                      <div>
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{team.teamName + "'s Daily Capacity"}</Tooltip>}>
                          <Badge bg="primary" className="me-3" id={componentName + "-teamCapacity-" + i}>{(team.teamCapacitySum)}</Badge>
                        </OverlayTrigger>
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{team.teamName + "'s Daily Assignment"}</Tooltip>}>
                          <Badge bg="" text="dark" className="border me-2" id={componentName + "-teamDailyAssigned-" + i}>{(team.teamDailyAssignment || 0)}</Badge>
                        </OverlayTrigger>
                        <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{team.teamName + "'s Accumulated Assignment"}</Tooltip>}>
                          <Badge bg="" text="dark" className="border" id={componentName + "-teamTotalAssigned-" + i}>{(team.teamHistoricalAssignment || 0)}</Badge>
                        </OverlayTrigger>
                      </div>
                    </PlusMinusToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {team.programLeads?.map((m, index) =>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <div className="w-50 overflow-hidden text-truncate nowrap me-3">
                            <span id={componentName + "-memberName-" + index}>{m.programLeadName}</span>
                            {m.zone.length > 0 &&
                              <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id={`tooltip-${index}-zone`} className="tooltip">{m.zone}</Tooltip>} placement="top-start">
                                <small className="fst-italic ms-2" id={componentName + "-programLeadZone-" + index}>({m.zone})</small>
                              </OverlayTrigger>
                            }
                          </div>
                          <div>
                            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{m.programLeadName + "'s Daily Capacity"}</Tooltip>}>
                              <Badge bg="primary" className="me-3" id={componentName + "-memberCapacity-" + index}>{(m.dailyCapacity).padStart(2, '0')}</Badge>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{m.programLeadName + "'s Daily Assignment"}</Tooltip>}>
                              <Badge bg="" text="dark" className="border me-2" id={componentName + "-memberDailyAssigned-" + index}>{m.dailyAssignment}</Badge>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip">{m.programLeadName + "'s Accumulated Assignment"}</Tooltip>}>
                              <Badge bg="" text="dark" className="border" id={componentName + "-memberTotalAssigned-" + index}>{(m.totalAssignment || 0)}</Badge>
                            </OverlayTrigger>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )}
          </Col>
        </Row>
      </div>
    </div></>
  )
}

export default PatientListOffCanvas