import React, { useState, useContext, useEffect } from "react";
import { theme } from "../../components/themes/theme1";
import { ThemeProvider } from "styled-components";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
  Card,
  Container,
  Col,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import EQHErrorToast from "../../components/EQHErrorToast";
import { _calculateAge } from "../../utils/util";
import { exportDO, getPatientsData, getPerformanceData } from "../../actions/caremgmt/performanceManagerActions";
import { PerformanceSection } from "./performanceManager/components";
import { performanceField, performanceSections } from "./performanceManager/utils";
import { EQHDatePicker } from "../../components/datePicker";
import { subMonths, format } from "date-fns";
import Modals from "../../components/modals";
import axios from "axios";
import ExportSummary from "../quickList/ExportSummary";
import { Helmet } from 'react-helmet';
import CustomPagination from "../common/CustomPaginaton";
import { FiltersResume } from "./reportingAnalytics/components";

const componentName = "PerformanceManager";
const currentDate = new Date();

export const PerformanceManager = () => {
  const [errors, setErrors] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [modalMessage, setModalMessage] = useState();
  const [filters, setFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20, count: 0 });
  const [patientsData, setPatientsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeDate, setRangeDate] = useState({
    fromDate: format(subMonths(currentDate, 1), 'yyyy-MM-dd'),
    toDate: format(currentDate, 'yyyy-MM-dd')
  });

  useEffect(() => {
    getPerformanceData(rangeDate).then(res => setPerformanceData(res.data))
  }, [])

  function PlusMinusToggle({ children, eventKey, callback, idx }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
        <i id={`${componentName}-plusMinusIcon-${idx}`} className={isCurrentEventKey ? "far fa-minus-circle me-1" : "far fa-plus-circle me-1"}></i>{" "}
        <span id={`${componentName}-itemTitle-${idx}`} className="fw-bold">{children}</span>
      </Button>
    );
  }

  function handleExport() {
    setIsLoading(true);
    exportDO(rangeDate).then(() => {
      summary();
    }).catch(() => setIsLoading(false))
  }

  function handleFilter() {
    setIsLoading(true)
    getPerformanceData(rangeDate).then(res => {
      setIsLoading(false);
      setPerformanceData(res?.data);
    })
  }

  function handleDate(e, field) {
    setRangeDate({
      ...rangeDate,
      [field]: e.strDate
    })
  }

  const handleFilterInsights = () => {
    setIsLoading(true);
    getPatientsData(rangeDate, filters, 1)
      .then(res => {
        let data = res?.data;
        let count = data?.results?.length > data?.count
          ? data?.results?.length
          : data?.count;

        setPatientsData(res.data);
        setIsLoading(false);
        setPagination({ ...pagination, count, pageNumber: 1 });
      })
      .catch(() => setIsLoading(false))
  }

  const handleInsightsPagination = (page) => {
    setIsLoading(true)
    getPatientsData(rangeDate, filters, page)
      .then(res => {
        let data = res?.data;
        let count = data?.results?.length > data?.count
          ? data?.results?.length
          : data?.count;
        setPatientsData(data);
        setIsLoading(false);
        setPagination({ ...pagination, count, pageNumber: page });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false))
  }

  const summary = () => {
    const SUMMARY_URL =
      process.env.REACT_APP_REST_API_BASE_URL +
      "/v1/patient-card/export-summary";
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setModalMessage(<ExportSummary exportSummary={response.data} />);
        setIsLoading(false);
      })
      .catch((error) => {
        setModalMessage(error.response.data.message);
        setIsLoading(false);
      });
  };


  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Performance Manager | CareEmpower</title>
      </Helmet>
      <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
      <div className="overflow-inherit w-100">
        <Row className="sticky-top mx-0 mb-0 stickyTitle">
          <Col className="px-0">
            <Container fluid className="page-titles">
              <Row className="p-2">
                <Col sm={12}>                
                  <div className="d-inline-block align-middle fw-bold">
                    {new Date().toDateString()}
                  </div>
                  <div className="d-inline-block mt-1 float-end">
                    <Button
                      id={`${componentName}-exportButton`}
                      variant="secondary"
                      className="me-0"
                      onClick={() => handleExport()}
                      disabled={isLoading}
                    >
                      {
                        isLoading ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> : 'Export'
                      }
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Container fluid className="p-4">
          {
            performanceSections.map((section, idx) => {
              return (
                <>
                  {
                    idx === 1 &&
                    <Row className='mb-4'>
                      <span>Date Range</span>
                      <Col>
                        <EQHDatePicker
                          id={`${componentName}-datePicker-from`}
                          onChange={e => handleDate(e, 'fromDate')}
                          value={rangeDate.fromDate}
                          max={rangeDate.toDate}
                        />
                      </Col>
                      <Col>
                        <EQHDatePicker
                          id={`${componentName}-datePicker-to`}
                          onChange={e => handleDate(e, 'toDate')}
                          value={rangeDate.toDate}
                          max={format(currentDate, 'yyyy-MM-dd')}
                          min={rangeDate.fromDate}
                        />
                      </Col>
                      <Col>
                        <Button onClick={() => handleFilter()} disabled={isLoading}>
                          {
                            isLoading ?
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              /> : 'Filter'
                          }
                        </Button>
                      </Col>
                    </Row>
                  }
                  <PerformanceSection>
                    <Accordion defaultActiveKey={idx === 0 && idx + 1} className="mb-3 border-0">                    <Card>
                      <Card.Header eventKey={idx + 1}>
                        <PlusMinusToggle componentName={componentName} eventKey={idx + 1} idx={idx + 1}>
                          <span id={`${componentName}-programInsightsTitle`}>{section.title}</span>
                        </PlusMinusToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={idx + 1}>
                        <Card.Body>
                          <PerformanceSection.Body
                            field={performanceField?.[section.key]}
                            data={performanceData?.[section.key]}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    </Accordion>
                  </PerformanceSection>
                </>
              )
            })
          }
          <PerformanceSection>
            <Accordion className="mb-3 border-0">
              <Card>
                <Card.Header eventKey="5">
                  <PlusMinusToggle componentName={componentName} eventKey="5" idx={5}>
                    <span id={`${componentName}-interactionInsightsTitle`}>Interaction Insights</span>
                  </PlusMinusToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <PerformanceSection.InsightsFilters
                      filters={filters}
                      setFilters={setFilters}
                      handleFilter={handleFilterInsights}
                      isLoading={isLoading}
                    />
                    <FiltersResume
                      filters={filters}
                      patients={patientsData?.results}
                      pagination={pagination}
                      filtersPerRow={4}
                    />
                    <PerformanceSection.InsightsTable data={patientsData?.results} />
                    <Card.Footer>
                      {
                        patientsData?.results?.length > 0 &&
                        <CustomPagination
                          paginateButClicked={page => {
                            setPagination({ ...pagination, pageNumber: page });
                            handleInsightsPagination(page)
                          }}
                          MAX_PAGE={Math.ceil(pagination?.count / pagination?.pageSize)}
                          pageNumber={Math.ceil(pagination?.pageNumber)}
                        />
                      }
                    </Card.Footer>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </PerformanceSection>
          <PerformanceSection>
            <Accordion>
              <Card className="mb-3">
                <Card.Header eventKey="4">
                  <PlusMinusToggle componentName={componentName} eventKey="4" idx={4}>
                    <span id={`${componentName}-careTeamCapacityTitle`}>Care Team Capacity</span>
                  </PlusMinusToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <PerformanceSection.Table data={performanceData?.capacityInformationList} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </PerformanceSection>
          <Modals
            show={modalMessage != null}
            onHide={() => setModalMessage(null)}
            title={'Export Summary'}
            size={"lg"}
            hideClickButton={true}
            showExtraButton={false}
            hideFooter={false}
            closeBtnId={`${componentName}-closeButton`}
            body={modalMessage}
          />
        </Container>
      </div>
    </ThemeProvider>
  );
};
export default PerformanceManager;