import { GET_FILE, UPLOAD_FILE, CLEAR_STATE } from "../actions/types";

const initialState = {
  fileObject: {},
  updateSuccess: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FILE:
      return {
        ...state,
        fileObject: payload,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        fileObject: payload,
        updateSuccess: true,
      };
    case CLEAR_STATE:
      return {
        ...state,
        fileObject: {},
        updateSuccess: false
      }
    default:
      return state;
  }
}
