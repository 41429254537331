export function getGetters(state) {
  return {
    notesList: state.notesList,
    isLoading: state.isLoading,
    totalSize: state.totalSize,
    notesSearch: state.notesSearch,
    hasMore: state.hasMore,
    userNameListFilter: state.userNameListFilter,
    categories: state.categories,
    showAddNote: state.showAddNote,
    isAddingNote: state.isAddingNote
  }
}