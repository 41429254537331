import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import CalendarPagination from "../appointment/CalendarPagination";
import axios from "../../utils/apiClient";
import {
  formatDateTime,
  formatDateVanilla,
  formatDate,
  formatLocalDate,
  formatDateWithTime,
  formatDateWithHourMinutes,
  formatDateWithPattern,
  formatTime,
  formatTimeOnly,
  getPatientProfileLink,
} from "../../utils/util";
import styled, { css } from "styled-components";
import { getTime } from "date-fns";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router";

const componentName = "CalendarView";
export const CM_WORKSPACE_MODULE = "CM_WORKSPACE"

const CalendarView = ({
  patientList,
  dateObject,
  setDateObject,
  handleDateChange,
  loadingPatients,
  updateAppointment,
  returnToUrl,
  module,
  todayButton = false,
}) => {
  const isEmpty = require("is-empty");
  const location = useLocation();

  const handlePaginationChange = (date) => {
    handleDateChange(date, "CALENDAR");
  };

  const getDate = (date) => {
    let arr;
    let day;
    if (date) {
      arr = date.split("-");
      if (arr && arr.length && arr[2]) {
        day = arr[2];
      }
    }
    return day;
  };

  const getCMAppointmentLabel = (appointment) => {
    const startTime = formatTimeOnly(new Date(appointment.startTime))
    const endTime = formatTimeOnly(new Date(appointment.endTime))
    const patientName = appointment.patientId ? `${appointment.patientLastName}, ${appointment.patientFirstName} ${appointment.patientMiddleName}` : ""
    const appointmentDuration = `From ${startTime} to ${endTime}`
    const label = [appointment.appointmentName, patientName, appointmentDuration]

    return label.filter(Boolean).join(" - ")
  }

  const mapAppointments = (list) => {
    let elements = [];
    const firstDate = new Date(list[0]?.date?.replace("-", "/"));
    for (let i = firstDate?.getDay(); i > 0; i--) {
      const previous = firstDate;
      previous.setDate(firstDate?.getDate() - 1);

      elements.push(<StyledDiv
        id={`${componentName}-calendarDay`}
        className="ps-2"
        disabled="true"
      >
        <span id={`${componentName}-calendarDate`} className="fw-bold">
          {previous?.getDate()}
        </span>
        <div
          id={`${componentName}-calendarInfo`}
          className="overflow-y-auto"
          style={{
            maxHeight: "100px"
          }}
        >
        </div>
      </StyledDiv>);
    }
    elements = elements.reverse();
    list.forEach((e, idx) => {
      elements.push(
        <StyledDiv
          id={`${componentName}-calendarDay`}
          className="ps-2"
          disabled={
            e.appointments == null || !e.appointments.length
          }
        >
          <span id={`${componentName}-calendarDate`} className="fw-bold">
            {getDate(e.date)}
          </span>
          <div
            id={`${componentName}-calendarInfo`}
            className="overflow-y-auto"
            style={{
              maxHeight: "100px"
            }}
          >
            {e.appointments && e.appointments.length
              ? e.appointments.map((patient, indexApp) => {
                return (
                  <Badge
                    variant="light"
                    id={`${componentName}-calendarAppointment-${idx}-${indexApp}`}
                    className="border fs-6 text-wrap"
                  >
                    {module === CM_WORKSPACE_MODULE ? (
                      <a onClick={() => updateAppointment(patient)} className="p-0">
                        {getCMAppointmentLabel(patient)}
                      </a>) :
                      patient.patientFirstName && patient.patientLastName && (
                        <a className="p-0" href={getPatientProfileLink(patient.patientId, location)}>
                          {patient.patientFirstName}{" "}
                          {patient.patientLastName}
                          {patient.startTime
                            ? " - " +
                              formatTimeOnly(
                              new Date(patient.startTime)
                            )
                            : ""}
                        </a>)}
                  </Badge>
                );
              })
              : ""}
          </div>
        </StyledDiv>
      );
    });
    return elements;
  }

  return (
    <>
      <Row className="mb-3">
        <Col>
          <CalendarPagination
            type={"CALENDAR_VIEW"}
            dateObject={dateObject}
            setDateObject={setDateObject}
            paginateButtonClicked={handlePaginationChange}
            todayButton={todayButton}
          />
        </Col>
      </Row>
      {!isEmpty(patientList) && !loadingPatients ? (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="calendar-container border">
                  <div id={`${componentName}-calendar`} className="calendar">
                    <span id={`${componentName}-calendarSunday`} className="day-name">Sunday</span>
                    <span id={`${componentName}-calendarMonday`} className="day-name">Monday</span>
                    <span id={`${componentName}-calendarTuesday`} className="day-name">Tuesday</span>
                    <span id={`${componentName}-calendarWednesday`} className="day-name">Wednesday</span>
                    <span id={`${componentName}-calendarThursday`} className="day-name">Thursday</span>
                    <span id={`${componentName}-calendarFriday`} className="day-name">Friday</span>
                    <span id={`${componentName}-calendarSaturday`} className="day-name">Saturday</span>
                    {/* <div className="day day--disabled">30</div>
                <div className="day day--disabled">31</div> */}

                    {patientList && patientList.length ? mapAppointments(patientList)
                      : ""}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CalendarView;
export const searchPatientMain = (criteria) => {
  return axios.post(`${URL}/v1/patients/myPatients`, criteria, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const StyledDiv = styled.div`
  font-weight: 400;
  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(152, 160, 166, 0.6);
      background-color: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
      cursor: not-allowed;
    `}
`;
