import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../../utils/apiClient";
import styled from "styled-components";
import { formatDateModern, logDebug } from "../../../../utils/util";
import cloneDeep from "lodash/cloneDeep";
import Badge from "react-bootstrap/Badge";
import Alert from 'react-bootstrap/Alert';

import { Row, Col, Modal, ToggleButtonGroup, ToggleButton, Button, ProgressBar, Spinner, Toast, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { EQHDatePicker } from "../../../../components/datePicker";
import { format } from "date-fns";
import EQHErrorToast from "../../../../components/EQHErrorToast";

import { getDefaultData as careOppPkgDefault } from "../../ManageEvidence/CareOpportunitiesAccordion";
import { getDefaultData as validationPkgDefault } from "../../ManageEvidence/ValidationAccordion";
import { useAuth } from "../../../../context/authContext";
import { referralTypes } from "../patientList";

import Form from "react-bootstrap/Form";
import { FileUpload } from "../../../../components/FileUpload";
import { SimpleDDL } from "../../../../components/dropDownLists/simpleDDL";
import { Filter } from "../../../../components/filters/filterGroup";
import { DDLDataMode, DDLMode, GENERIC_DDL_TYPE } from "../../../../components/dropDownLists/genericDDL";
import { PrevisitChart } from "../modals/PrevisitChart";
import { useManageVisitContext } from "../context/ManageVisitContext/ManageVisit.context";
import { useDispatch, useSelector } from "react-redux";
import { setReloadPatientList, setShowFullHistory } from "../../../../actions/manageVisitActions";
import { useRef } from "react";
import { AppPropertiesUtil } from "../../../../utils/localStorageUtil";
import ImmunizationComponent from "../ImmunizationComponent";
import {VisitTab} from "../../Visit/index";

const componentName = "manageVisit";

const RejectedValidationExclamation = styled.span.attrs({ "className": "text-danger fa-regular fa-circle-exclamation pe-1" })`
  display: ${props => props.validation == "REJECTED" && props.status == "OPEN" ? "" : "none !important"};
`;

const DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO = {
  bloodPressureSystolic: null,
  bloodPressureDiastolic: null,
  a1cWholes: null,
  a1cDecimal: null,
  note: "",
  uploadedFiles: [],
  careGapAdditionalInfos: {},
};

const getDefaultPreventiveCareUpdateResultsDto = () => {
  return cloneDeep(DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO);
};

const getDefaultInfo = () => {
  return cloneDeep(DEFAULT_INFO);
};

const DEFAULT_DATA = {
  selectedGaps: [],
  additionalGapIdsSelected: [],
  errors: [],
  confirmationWarning: "",
  finalizeForBackend: () => false,
  info: getDefaultInfo(),
};


const getDefaultUpdateDTO = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_UPDATE_CARE_GAP_DTO);
  //dto.newDateOfService = dateOfService;
  return dto;
};

const DEFAULT_INFO = {
  requireDOS: false,
  requireStatus: false,
  removeDateLimit: false,
  opportunityDosError: null,
  fuhSelected: false,
  fuhMaxDate: null
};

const DEFAULT_UPDATE_CARE_GAP_DTO = {
  ids: [],
  newStatus: null,
  additionalInfos: [],
  additionalInfoCaption: null,
  exclusionCriteria: [],
};

export const ManageVisit = ({
                              patient,
                              handleReferralModal,
                              setPatientDetailsShow,
                              setShowFullHistory,
                              setSelectedGaps,
                              featureFlag
                            }) => {


  let btnRef = useRef();

  const isEmpty = require("is-empty");
  const manageVisitContext = useManageVisitContext();
  const dispatch = useDispatch();
  const [showDocAndSetting, setShowDocAndSetting] = useState(false);
  const [showGaps, setShowGaps] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleClose = () => {
    setShowGaps(false);
    setShowDocAndSetting(false);
  }
  //const handleShow = () => setShow(true);
  const [showCPT, setShowCPT] = useState(false);
  const [immunizationSelected, setImmunizationSelected] = useState(false);
  const handleCloseCPT = () => setShowCPT(false);
  const handleShowCPT = () => setShowCPT(true);
  const [visitCareGap, setVisitCareGap] = useState({});
  const baseURLMV = process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-visit";
  //const baseURLPC = process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";
  const [visitDate, setVisitDate] = useState(null);
  const [dateOfService, setDateOfService] = useState(null);
  const [suspectId, setSuspectId] = useState(null);
  const [showInactiveActivity, setShowInactiveActivity] = useState(false);

  const [showConfirmDelete, setConfirmDeleteShow] = useState(false);
  const handleConfirmDeleteClose = () => setConfirmDeleteShow(false);
  const handleConfirmDeleteShow = (selectedSuspectId) => {
    setSuspectId(selectedSuspectId);
    setConfirmDeleteShow(true);
  }
  const contracted = manageVisitContext?.patient_get?.contracted;

  const [confirmationWarning, setConfirmationWarning] = useState([]);
  // const [attestationDDLOptions, setAttestationDDLOptions] = useState([]);
  const [selectedTilesChildId, setSelectedTilesChildId] = useState([]);
  const [requiredValues, setRequiredValues] = useState({
    requireUpload: false,
    requireA1C: false,
    requireBloodPressure: false,
    attestationDDLOptions: [],
    requiredAttestation: false
  })


  const getImmuList = (careGapId) => {
    const immunDoses = listImmuDTO[listImmuDTO.findIndex(x => x.careGapId == careGapId)]?.careGapImmunizationList;
    const immunDosesNew = immunDoses.filter(doses => doses?.doses > doses?.dosesActual);
    const finalObject = immunDosesNew.filter(doses => {
      if (doses?.immunizationAbbreviation === "RV1" || doses?.immunizationAbbreviation === "RV5") {
        const comparingVal = doses?.immunizationAbbreviation === 'RV1' ? 'RV5' : 'RV1'
        const rv5OrRv1 = immunDoses.find(item => item.immunizationAbbreviation === comparingVal);
        if (rv5OrRv1) {
          const dosesDosesActual = doses?.careGapImmunizationDoseList.filter(e => e.immunizationStatusENUM &&
              (e.immunizationStatusENUM === 'MARKED_FOR_CLOSURE' || e.immunizationStatusENUM === 'CLOSED') && e.dateOfService).length
          const rv5OrRv1DosesActualList = rv5OrRv1?.careGapImmunizationDoseList.filter(e => e.immunizationStatusENUM &&
              (e.immunizationStatusENUM === 'MARKED_FOR_CLOSURE' || e.immunizationStatusENUM === 'CLOSED') && e.dateOfService)
          const rv5OrRv1DosesActual = rv5OrRv1DosesActualList.length
          let whenToDisplay;
          if (doses?.immunizationAbbreviation === "RV1") {
            whenToDisplay = (rv5OrRv1DosesActual < 3) && ((dosesDosesActual + rv5OrRv1DosesActual) <= 3)
            //whenToNotDisplay = (dosesDosesActual + rv5OrRv1DosesActual) < 3 && rv5OrRv1DosesActual < rv5OrRv1.doses;
          }
          if (doses?.immunizationAbbreviation === "RV5") {
            whenToDisplay = (rv5OrRv1DosesActual < 2) && ((dosesDosesActual + rv5OrRv1DosesActual) <= 3)
            //whenToNotDisplay = (dosesDosesActual + rv5OrRv1DosesActual) < 3 && rv5OrRv1DosesActual < rv5OrRv1.doses;
          }

          doses.rv5OrRv1ValCompleted = rv5OrRv1DosesActual
          doses.allDateOfServices = rv5OrRv1DosesActualList.map(f => f.dateOfService)
          return whenToDisplay
        }
      }
      return true;
    });
    return finalObject
  }
  const checkDoses = (subVisitActivity) => {
    const immunDoses = subVisitActivity.immunizationsDTO.careGapImmunizationList.filter(doses => doses?.dosesActual >= doses?.doses);
    if (subVisitActivity?.abbreviation === "CIS") {
      if (immunDoses && immunDoses.length) {
        const immunDoses = subVisitActivity.immunizationsDTO.careGapImmunizationList.filter(doses => (doses.immunizationAbbreviation === "RV5" || doses.immunizationAbbreviation === "RV1") || doses?.dosesActual >= doses?.doses);
        const newArray = immunDoses.filter(doses => {
          if (doses.immunizationAbbreviation === "RV1" || doses.immunizationAbbreviation === "RV5") {
            const comparingVal = doses?.immunizationAbbreviation === 'RV1' ? 'RV5' : 'RV1'
            const rv5OrRv1 = immunDoses.find(item => item.immunizationAbbreviation === comparingVal);
            if (rv5OrRv1) {
              const dosesDosesActual = doses?.careGapImmunizationDoseList.filter(e => e.immunizationStatusENUM &&
                  (e.immunizationStatusENUM === 'MARKED_FOR_CLOSURE' || e.immunizationStatusENUM === 'CLOSED') && e.dateOfService).length
              const rv5OrRv1DosesActual = rv5OrRv1?.careGapImmunizationDoseList.filter(e => e.immunizationStatusENUM &&
                  (e.immunizationStatusENUM === 'MARKED_FOR_CLOSURE' || e.immunizationStatusENUM === 'CLOSED') && e.dateOfService).length

              const rv5OrRv1Val = dosesDosesActual + rv5OrRv1DosesActual >= 3 || dosesDosesActual >= doses?.doses || rv5OrRv1?.dosesActual >= rv5OrRv1?.doses;
              return rv5OrRv1Val
            }
          }
          return true;
        });
        return newArray;
      }
    }
    return immunDoses;
  }
  const handleImmunizationsSave = (immuObject, id, action, subVisitActivity) => {
    const clonelistImmuDTO = cloneDeep(listImmuDTO)
    const comparingCareGapId = subVisitActivity.immunizationsDTO.careGapId
    const itemFromArrayToUpdateIndex = clonelistImmuDTO.findIndex(n => n.careGapId == comparingCareGapId)
    let immuItemToUpdate = clonelistImmuDTO[itemFromArrayToUpdateIndex]
    let newListImu = []
    let localImuObject = {}
    if (action == 'save') {
      let objectWithFlag = immuObject
      objectWithFlag.selected = true
      localImuObject = objectWithFlag
    }
    else {
      let refreshedBackImuObject = subVisitActivity?.immunizationsDTO?.careGapImmunizationList.find(t => t.id === immuObject.id)
      refreshedBackImuObject.selected = false
      localImuObject = refreshedBackImuObject
    }
    newListImu = { ...immuItemToUpdate, careGapImmunizationList: immuItemToUpdate.careGapImmunizationList.map(immuObject => immuObject.id === localImuObject.id ? localImuObject : immuObject) }
    clonelistImmuDTO[itemFromArrayToUpdateIndex] = newListImu
    setListImmuDTO(clonelistImmuDTO)
    let immunizationSelected = clonelistImmuDTO.flatMap(m => m).flatMap(n => n.careGapImmunizationList).map(h => h.selected).some(i => i)
    immunizationSelected = immunizationSelected ? immunizationSelected : false
    if (immunizationSelected) {
      setImmunizationsDTO(newListImu)
    }
    else {
      setImmunizationsDTO({})
    }
    setImmunizationSelected(immunizationSelected)
    handleActionChange({ target: { checked: immunizationSelected } }, subVisitActivity)
  }

  // property to hold the list of selected carevisit Ids
  const [careVisitIds, setCareVisitIds] = useState([]);
  // property to hold the list of selected visit tiles
  const [selectedVisitTiles, setSelectedVisitTiles] = useState([]);
  // property to hold the indexes of the selected tiles, starting from 0
  const [selectedVisitTilesIdx, setSelectedVisitTilesIdx] = useState([]);

  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);

  const auth = useAuth();
  const authorizedForViewNewManageVisit = auth.hasAnyAuthority(["AUTH_PD_NEW_MANAGE_VISIT_READ_ACCESS", "AUTH_PD_NEW_MANAGE_VISIT_WRITE_ACCESS"]);


  const [careOpportunityPkg, setCareOpportunityPkg] = useState(() =>
      careOppPkgDefault(visitDate)
  );
  const [preventiveCareUpdateResultsDto, setPreventiveCareUpdateResultsDto] =
      useState(() => getDefaultPreventiveCareUpdateResultsDto());
  const [selectedSuspects, setSelectedSuspects] = useState({
    ids: [],
    conditions: [],
  });
  const [tocPkg, setTOCPkg] = useState(() => careOppPkgDefault(visitDate));
  const [validationPkg, setValidationPkg] = useState(() =>
      validationPkgDefault(visitDate)
  );
  const [errors, setErrors] = useState([]);
  const [dataResponse, setDataResposne] = useState({});
  const getDefaultData = (dateOfService) => {
    const dto = cloneDeep(DEFAULT_DATA);
    dto.updateCareGapDto = getDefaultUpdateDTO(dateOfService);
    return dto;
  };

  const [disableUpdate, setDisableUpdate] = useState(true);
  const [optionChanged, setOptionChanged] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isVisitDateRequired, setIsVisitDateRequired] = useState(false);
  const showFullHistory = useSelector((state) => state?.manageVisitReducer?.showFullHistory);

  const [currentGapSelected, setCurrentGapSelected] = useState({});
  const [currentSuspectSelected, setCurrentSuspectSelected] = useState({});


  const [gapSelectedStatus, setGapSelectedStatus] = useState({});
  const [conditionSelectedStatus, setConditionSelectedStatus] = useState({});

  const [manageVisitData, setManageVisitData] = useState({});

  const [selectedSubActivityGroup, setSelectedSubActivityGroup] = useState({});
  const [actionChangeCount, setActionChangeCount] = useState({});
  const [selectedSubActivityId, setSelectedSubActivityId] = useState([]);

  const [bloodPressureError, setBloodPressureError] = useState(false);
  const [ac1ValueError, setAc1ValueError] = useState(false);

  const [filterValues, setFilterValues] = useState({
    paramValue: [],
    displayValue: [],
  });
  const resetCurrentErrors = () => {
    setErrors([]);
  };

  const updatePatientEvidence = (careOpportunityPkgArg, selectedSuspectsArg, gapSelectedStatus, conditionSelectedStatus) => {
    //setIsUpdating(true);
    try {
      careOpportunityPkgArg.finalizeForBackend();
      tocPkg.finalizeForBackend();
      validationPkg.finalizeForBackend();
      const dto = {
        ...preventiveCareUpdateResultsDto,
        patientLastUpdated: manageVisitData.patientLastUpdated,
        visitDate: visitDate ? visitDate : null,
        patientId: patient?.id,
        visitIds: careVisitIds,
        updateCareGapActionDto: careOpportunityPkgArg.selectedGaps.length ? careOpportunityPkgArg.updateCareGapDto : null
        ,
        updateTOCCareGapActionDto: tocPkg.selectedGaps.length
            ? tocPkg.updateCareGapDto
            : null,
        updateValidationCareGapActionDto:
            validationPkg.selectedItems.length &&
            validationPkg.validationType === "Opportunity"
                ? validationPkg.updateDto
                : null,
        updateValidationConditionActionDto:
            validationPkg.selectedItems.length &&
            validationPkg.validationType === "Suspect"
                ? validationPkg.updateDto
                : null,
        updateConditionActionDto: selectedSuspectsArg.ids.length
            ? {
              ids: selectedSuspectsArg.ids,
              visitDate: visitDate ? visitDate : null,
              validation: null,
              icd10DiseaseDto: null,
              forPreviousYear: false,
            }
            : null,
        immunizationsDTO: immunizationsDTO ? immunizationsDTO : null
      };
      if (dto.updateCareGapActionDto && gapSelectedStatus.currentStatus) {
        const newStatus = {
          "source": manageVisitData.caregapStatusesDropDownMap[gapSelectedStatus.currentStatus.key],
        }
        dto.updateCareGapActionDto.newStatus = newStatus;
      }
      if (dto.updateConditionActionDto && conditionSelectedStatus.currentStatus) {
        const newStatus = {
          "source": manageVisitData.conditionStatusesDropDownMap[conditionSelectedStatus.currentStatus.key],
        }
        dto.updateConditionActionDto.newStatus = newStatus;
      }
      if (dto.updateCareGapActionDto && gapSelectedStatus.dateOfService) {
        dto.updateCareGapActionDto.newDateOfService = gapSelectedStatus.dateOfService;
      }
      if (dto.updateConditionActionDto && conditionSelectedStatus.dateOfService) {
        dto.updateConditionActionDto.newDateOfService = conditionSelectedStatus.dateOfService;
      }
      logDebug(() => dto);

      axios
          .post(baseURLMV + `/update`, dto, {
            headers: { "Content-Type": "application/json" },
          })
          .then(async (response) => {

            if (response.data.visitDate) {
              setDataResposne(response.data);
              if (response.data.visitDate) {
                setVisitDate(response.data.visitDate);
              }
            }

            if (careOpportunityPkg.selectedGaps.length) {
              visitCareGap["status"] = careOpportunityPkg.updateCareGapDto.newStatus;
              setVisitCareGap({});
            }
            setShowSuccessMsg(true);
            //setDateOfService(null);
            setDisableUpdate(true);
            setOptionChanged(false);
            setImmunizationSelected(false)
            setImmunizationsDTO({});
            handleClose();
            resetState();
            setActionChangeCount({});
            setCareVisitIds([]);
            setSelectedVisitTiles([]);
            setSelectedVisitTilesIdx([]);
            setSelectedTilesChildId([]);
            setVisitDate(null);
            setDateOfService(null);
            setSelectedSubActivityGroup({});
            setManageVisitData({});
            setRequiredValues({
              requireUpload: false,
              requireA1C: false,
              requireBloodPressure: false,
              attestationDDLOptions: [],
              requiredAttestation: false
            })
            resetAfterUpdate();
            setPreventiveCareUpdateResultsDto(() => getDefaultPreventiveCareUpdateResultsDto());
            dispatch(setReloadPatientList(true));
            refreshManageVisit();
            setIsButtonDisabled(false);
          })
          .catch((error) => {
            logDebug(() => error);
            formatErrorMessage(error);
            setDisableUpdate(true);
            setOptionChanged(false);
            checkAndUpdateCareVisitAdditionInfoRequiredValues(selectedVisitTiles);
            setPreventiveCareUpdateResultsDto((old) => ({
              ...getDefaultPreventiveCareUpdateResultsDto(),
              note: old.note,
              bloodPressureSystolic: old.bloodPressureSystolic,
              bloodPressureDiastolic: old.bloodPressureDiastolic,
              a1cWholes: old.a1cWholes,
              a1cDecimal: old.a1cDecimal,
              uploadedFiles: old.uploadedFiles,
              careGapAdditionalInfos: old.careGapAdditionalInfos
            }));
            refreshManageVisit();
            setIsButtonDisabled(false);
          });
    } catch (e) {
      setDisableUpdate(true);
      setOptionChanged(false);
      resetState();
      setIsButtonDisabled(false);
    }
  };

  const formatErrorMessage = (error) => {
    if (error?.response?.data?.message?.includes("\|")) {
      let errors = error?.response?.data?.message.split("\|");
      setErrors(errors);
    } else {
      setErrors([error?.response?.data?.message]);
    }
  };

  const resetState = () => {
    setSelectedSuspects({
      ids: [],
      conditions: [],
    });

    setImmunizationsDTO({});
    setGapSelectedStatus({});
    setConditionSelectedStatus({});
    setCurrentGapSelected({})
    setCurrentSuspectSelected({})
    refreshManageVisit();
    setCareVisitIds([]);

  };

  const [errorFromDateComponent, setErrorFromDateComponent] = useState("")
  const handleVisitChange = (value) => {
    let strDate = value.strDate;
    if (value && value.error) {
      setErrorFromDateComponent(value.error)
      strDate = '';
    }
    else {
      setErrorFromDateComponent('')
    }
    setVisitDate(strDate);
    setDateOfService(strDate);
    setGapSelectedStatus({ ...gapSelectedStatus, dateOfService: strDate });
    setConditionSelectedStatus({ ...conditionSelectedStatus, dateOfService: strDate });
    setCurrentGapSelected({ ...currentGapSelected, dateOfService: strDate });
    setCurrentSuspectSelected({ ...currentSuspectSelected, dateOfService: strDate });

  };

  const checkVisitDateRequired = () => {
    setIsVisitDateRequired(document.getElementsByClassName('visitTile active').length > 0)
  }

  const handleEdit = (groupName, selected) => {
    let newCurrentSelected;
    let selectedMap;
    let newCurrentStatus = { key: "" };
    if (groupName === "CARE_GAP") {
      newCurrentSelected = { ...gapSelectedStatus };
      selectedMap = selectedGapMap;
      if (gapSelectedStatus.currentStatus) {
        newCurrentStatus = { ...gapSelectedStatus.currentStatus };
      }

    } else if (groupName === "SUSPECT") {
      newCurrentSelected = { ...conditionSelectedStatus };
      selectedMap = selectedConditionMap;
      if (conditionSelectedStatus.currentStatus) {
        newCurrentStatus = { ...conditionSelectedStatus.currentStatus };
      }
    } else {
      alert("Action type not supported");
      return;
    }

    //use default value if there is only 1 gap selected or 1+ condition selected and there is no previous status selection
    let keys = Object.keys(selectedMap);
    if (keys.length === 1 && groupName === "CARE_GAP" && !gapSelectedStatus.currentStatus) {
      newCurrentStatus.key = selectedMap[keys[0]];
    } else if (groupName === "SUSPECT" && !conditionSelectedStatus.currentStatus) {
      newCurrentStatus.key = selectedMap[keys[0]];
    } else if (groupName === "SUSPECT" && conditionSelectedStatus.currentStatus) {
      newCurrentStatus.key = conditionSelectedStatus.currentStatus;
    } //else leave as -Selected-

    newCurrentSelected.groupName = groupName;
    newCurrentSelected.selected = selected;
    newCurrentSelected.currentStatus = newCurrentStatus;
    newCurrentSelected.dateOfService = visitDate



    if (groupName === "CARE_GAP") {
      setCurrentGapSelected(newCurrentSelected);
      setShowGaps(true)
    } else if (groupName === "SUSPECT") {
      setCurrentSuspectSelected(newCurrentSelected);
      setShowDocAndSetting(true)
    }

  };

  const loadAlreadyProcessed = (mvData) => {
    //Used to show confirmation warninng message
    if (mvData.manageVisitActivityTypeDtos) {
      mvData.manageVisitActivityTypeDtos.map((visitActivity, idx1) => {
        if (visitActivity.visitActivitySubTypeDtos) {
          visitActivity.visitActivitySubTypeDtos.map((visitActivitySubType, idx2) => {
            if (visitActivitySubType.visitActivities) {
              visitActivitySubType.visitActivities.map((subVisitActivity, idx3) => {
                if (subVisitActivity.toggleStatus) {
                  selectedSubActivityId.push(subVisitActivity.id);
                  setSelectedSubActivityId([...selectedSubActivityId]);
                }
              });
            }
          });
        }
      });
    }
  };

  const [selectedGapMap, setSelectedGapMap] = useState({});
  const [selectedConditionMap, setSelectedConditionMap] = useState({});
  const [immunizationsDTO, setImmunizationsDTO] = useState({});



  function getToggleStatusValueDisplayName(toggleStatusValue, dropDownMap) {
    if (toggleStatusValue === "MARKED_FOR_CLOSURE")
      return "Marked for Closure"
    if (toggleStatusValue === "ORDERED")
      return "Ordered"
    if (toggleStatusValue === "CONFIRMED_DIAGNOSIS")
      return "Confirmed Diagnosis"

    return Object.keys(dropDownMap).find(key => dropDownMap[key] === toggleStatusValue);
  }

  const handleActionChange = (event, subVisitActivity) => {
    let dropDownMap = {};
    let setterMap;
    let valueMap;
    if (subVisitActivity.actionType === "CARE_GAP") {
      dropDownMap = manageVisitData.caregapStatusesDropDownMap;
      setterMap = setSelectedGapMap;
      valueMap = selectedGapMap;
    } else if (subVisitActivity.actionType === "SUSPECT") {
      dropDownMap = manageVisitData.conditionStatusesDropDownMap;
      setterMap = setSelectedConditionMap;
      valueMap = selectedConditionMap;
    }
    let dropDownKey = getToggleStatusValueDisplayName(subVisitActivity.toggleStatusValue, dropDownMap);
    if (event.target.checked) {
      let object = { ...valueMap };
      object[subVisitActivity.id] = dropDownKey
      if (setterMap) {
        setterMap(object);
      }
    } else {
      delete valueMap[subVisitActivity.id];
    }
    if (event.target.checked) {
      selectedSubActivityId.push(subVisitActivity.id);
      setSelectedSubActivityId([...selectedSubActivityId]);
    } else {
      const indexOfActivity = selectedSubActivityId.indexOf(subVisitActivity.id);
      if (indexOfActivity > -1) {
        selectedSubActivityId.splice(indexOfActivity, 1);
        setSelectedSubActivityId([...selectedSubActivityId]);
      }
    }

    if (subVisitActivity.actionType && !selectedSubActivityGroup[subVisitActivity.actionType]) {
      selectedSubActivityGroup[subVisitActivity.actionType] = [];
    }
    if (!actionChangeCount[subVisitActivity.actionType]) {
      actionChangeCount[subVisitActivity.actionType] = 0;
      setActionChangeCount(actionChangeCount);
    }

    const currentGap = currentGapSelected?.currentStatus?.value;

    if (event.target.checked && !subVisitActivity.toggleStatus) {
      const cloneSelectedSubActivityGroup = selectedSubActivityGroup[subVisitActivity.actionType];
      if (!cloneSelectedSubActivityGroup[currentGap]) {
        cloneSelectedSubActivityGroup[currentGap] = [];
      }
      cloneSelectedSubActivityGroup[currentGap].push(subVisitActivity);
      selectedSubActivityGroup[subVisitActivity.actionType] = cloneSelectedSubActivityGroup;
      setSelectedSubActivityGroup(selectedSubActivityGroup);
      //Increment action change counter
      setActionChangeCount({ ...actionChangeCount, [subVisitActivity.actionType]: actionChangeCount[subVisitActivity.actionType] + 1 });
    } else if (!event.target.checked && !subVisitActivity.toggleStatus) {
      const newcloneSelectedSubActivityGroup = selectedSubActivityGroup[subVisitActivity.actionType];
      if (newcloneSelectedSubActivityGroup[currentGap]) {
        const arr = newcloneSelectedSubActivityGroup[currentGap]
        let index = arr.findIndex(e => e.id === subVisitActivity.id);
        if (index > -1) {
          newcloneSelectedSubActivityGroup[currentGap].splice(index, 1);
        }
        let len = newcloneSelectedSubActivityGroup[currentGap].length;
        if (len < 1) {
          delete newcloneSelectedSubActivityGroup[currentGap];
        }

        selectedSubActivityGroup[subVisitActivity.actionType] = newcloneSelectedSubActivityGroup;
        setSelectedSubActivityGroup(selectedSubActivityGroup);
        //Decrement action change counter
        setActionChangeCount({ ...actionChangeCount, [subVisitActivity.actionType]: actionChangeCount[subVisitActivity.actionType] - 1 });
      }
    }

    checkForRequiredField(event, subVisitActivity, null);
  };

  const checkForRequiredField = (event, subVisitActivity, selectedStatus) => {
    let statusToCheck = {}
    if (selectedStatus && selectedStatus.currentStatus && selectedStatus.currentStatus.key) {
      statusToCheck = selectedStatus
    }
    else if (currentGapSelected && currentGapSelected.currentStatus && currentGapSelected.currentStatus.key) {
      statusToCheck = currentGapSelected
    }

    let allRequiredA1C = []
    let allRequiredUpload = []
    let allRequiredBloodPressure = []
    let attestationDDLOptions = []

    //map visits
    requiredValues.attestationDDLOptions.map((option, k) => {
      if (option.visitGap) {
        attestationDDLOptions.push(option);
      }
    });
    Object.keys(selectedSubActivityGroup).map((subActInd, k) => {
      if (selectedSubActivityGroup[subActInd]) {
        const activityGroup = selectedSubActivityGroup[subActInd];
        Object.keys(activityGroup).map((statusIndex, i) => {
          if (activityGroup[statusIndex]) {
            const activityGroupForStatus = activityGroup[statusIndex];
            Object.keys(activityGroupForStatus).map((activityIdx, i) => {
              const activity = activityGroupForStatus[activityIdx];
              if (((isEmpty(statusToCheck) || (!isEmpty(statusToCheck) && isEmpty(statusToCheck.currentStatus))) && activity.toggleStatusValue === 'MARKED_FOR_CLOSURE')
                  || (!isEmpty(statusToCheck) && statusToCheck.currentStatus && (statusToCheck.currentStatus.key.includes('Marked') || statusToCheck.currentStatus.key.includes('Previously')))) {
                allRequiredA1C.push(activity?.requireA1C)
                allRequiredBloodPressure.push(activity?.requireBloodPressure)
              }
              let attDDLVal = {};
              if (activity && activity.additionalInfoCaption && activity.additionalInfoOptions.length) {
                attDDLVal = {
                  gapId: activity.id,
                  visitGap: false,
                  abbreviation: activity.abbreviation,
                  caption:
                      activity.abbreviation +
                      " " +
                      activity.additionalInfoCaption,
                  options: activity.additionalInfoOptions,
                }
              }
              if (attDDLVal && attDDLVal.options && attDDLVal.options.length
                  && (activity?.toggleStatusValue === 'MARKED_FOR_CLOSURE' ||
                      (!isEmpty(statusToCheck) && statusToCheck.currentStatus && (statusToCheck.currentStatus.key.includes('Marked') || statusToCheck.currentStatus.key.includes('Previously'))))) {
                attestationDDLOptions.push(attDDLVal);
              } else if (attestationDDLOptions && attestationDDLOptions.length) {
                const indexOfAttDDL = attestationDDLOptions.findIndex((e) => e.gapId === attDDLVal.gapId);
                if (indexOfAttDDL > -1) {
                  attestationDDLOptions.splice(indexOfAttDDL, 1);
                };
              }
              allRequiredUpload.push(activity?.requireFileUpload)
            });
          }
        });
      }
    });

    var requiredAttestation = attestationDDLOptions.length > 0;
    setRequiredValues({
      ...requiredValues, requireUpload: allRequiredUpload.some(e => e),
      requireA1C: allRequiredA1C.some(e => e),
      requireBloodPressure: allRequiredBloodPressure.some(e => e),
      attestationDDLOptions: attestationDDLOptions,
      requiredAttestation: requiredAttestation
    })
  }

  const updateSelected = () => {
    setIsUpdating(true);
    let { conditionSelectedStatus } = applyStatus(currentSuspectSelected)
    let { gapSelectedStatus } = applyStatus(currentGapSelected)

    try {

      //update care visit tile
      const cloneData = getDefaultData(dateOfService ? dateOfService : visitDate);
      cloneData.selectedGaps = [];
      selectedSuspects.conditions = [];
      //cloneData.updateCareGapDto.newStatus = [];

      if (selectedSubActivityGroup["CARE_GAP"]) {
        cloneData.updateCareGapDto.ids = [];
        const activityGroup = selectedSubActivityGroup["CARE_GAP"];
        Object.keys(activityGroup).map((statusIndex, i) => {
          if (activityGroup[statusIndex]) {
            const activityGroupForStatus = activityGroup[statusIndex];
            Object.keys(activityGroupForStatus).map((activityIdx, i) => {
              const activity = activityGroupForStatus[activityIdx];
              cloneData.selectedGaps.push(activity);
              cloneData.updateCareGapDto.ids.push(activity.id);
            });

          }
        });
      }

      if (selectedSubActivityGroup["SUSPECT"]) {
        selectedSuspects.ids = [];
        const activityGroup = selectedSubActivityGroup["SUSPECT"];
        Object.keys(activityGroup).map((statusIndex, i) => {
          if (activityGroup[statusIndex]) {
            const activityGroupForStatus = activityGroup[statusIndex];
            Object.keys(activityGroupForStatus).map((activityIdx, i) => {
              const activity = activityGroupForStatus[activityIdx];
              selectedSuspects.conditions.push(activity);
              selectedSuspects.ids.push(activity.id);
            });
          }
        });
      }
      updatePatientEvidence(cloneData, selectedSuspects, gapSelectedStatus, conditionSelectedStatus);

    } catch (e) {
      setIsUpdating(false);
    }
    setIsUpdating(false);
    resetAfterUpdate();
  };



  const resetAfterUpdate = () => {
    setFileUploadResetCounter(fileUploadResetCounter + 1);
    setSelectedGapMap({});
    setSelectedConditionMap({});
    checkVisitDateRequired();
  };

  const handleOptionChange = (event, currentSelected) => {
    if (event.target.value && currentSelected?.selected[event.target.value]) {
      let currentStatus = { "name": event.target.name, "value": currentSelected.selected[event.target.value], "key": event.target.value };
      let newCurrentSelected = { ...currentSelected };
      newCurrentSelected.currentStatus = currentStatus;

      if (currentSelected?.groupName === "CARE_GAP") {
        setCurrentGapSelected(newCurrentSelected);
        const crrKey = Object.keys(selectedSubActivityGroup?.['CARE_GAP'])
        if (crrKey.length > 0) {
          const currentValue = selectedSubActivityGroup?.['CARE_GAP']?.[crrKey[0]]
          setSelectedSubActivityGroup({ ...selectedSubActivityGroup, ['CARE_GAP']: { [currentStatus.value]: currentValue } })
        }
      } else if (currentSelected?.groupName === "SUSPECT") {
        setCurrentSuspectSelected(newCurrentSelected);
      }

      setOptionChanged(true);
      checkForRequiredField(null, null, newCurrentSelected);
    } else {
      setOptionChanged(false);
    }
  };

  const handleCareOpportunityChange = (gapId) => {
    setSelectedGaps(gapId);
    if (gapId != null) {
      dispatch(setShowFullHistory(true));
    }
  }

  const applyStatus = (currentSelected) => {
    let selectedStatus = {}
    let gapSelectedStatus = {}
    let conditionSelectedStatus = {}
    if (selectedSubActivityGroup[currentSelected["groupName"]]) {
      let activityGroup = selectedSubActivityGroup[currentSelected["groupName"]];

      if (activityGroup) {
        let ids = [];
        Object.keys(activityGroup).map((statusName, i) => {
          ids.push(...activityGroup[statusName]);
        });

        selectedSubActivityGroup[currentSelected["groupName"]] = [];
        if (currentSelected.currentStatus) {
          selectedSubActivityGroup[currentSelected["groupName"]][currentSelected["currentStatus"].value || currentSelected["currentStatus"].key.toUpperCase().replaceAll(' ', '_')] = ids;
        }
        setSelectedSubActivityGroup(selectedSubActivityGroup);
      }

      if (currentSelected.groupName === "CARE_GAP") {
        if (currentSelected.currentStatus.key !== "") {
          setGapSelectedStatus(currentSelected);
          selectedStatus = currentSelected
          gapSelectedStatus = currentSelected;
        }
      } else if (currentSelected.groupName === "SUSPECT") {
        setConditionSelectedStatus(currentSelected);
        conditionSelectedStatus = currentSelected;
      } else {
        alert("Action type not supported");
        return;
      }
    }
    checkForRequiredField(null, null, selectedStatus);
    return { selectedSubActivityGroup, gapSelectedStatus, conditionSelectedStatus }
  };

  const isDisplayMessage = () => {
    return !auth.getEvidenceRequired()

  }

  const handleDeleteSuspect = async (id, patient) => {
    await manageVisitContext.findSuspectDelete(id, patient);
    setConfirmDeleteShow(false);
    refreshManageVisit();
  }

  useEffect(() => {
    setDateOfService(null);
    setDisableUpdate(true);
    setOptionChanged(false);
  }, [showGaps, showDocAndSetting]);


  useEffect(() => {
    if ((visitDate || dateOfService) && optionChanged) {
      setDisableUpdate(false);
    } else {
      setDisableUpdate(true);
    }
  }, [visitDate, dateOfService, optionChanged]);

  const refreshManageVisit = () => {
    if (patient?.id) {
      manageVisitContext.findPatientGet(patient?.id);
      manageVisitContext.findPatientPost(patient?.id);
    }
  };

  const [listImmuDTO, setListImmuDTO] = useState([])
  useEffect(() => {
    const response = manageVisitContext?.patient_get;
    if (response) {
      setManageVisitData(response);
      loadAlreadyProcessed(response);
      const immuDto = response?.manageVisitActivityTypeDtos?.flatMap((visitActivity) => visitActivity.visitActivitySubTypeDtos
          .filter(e => returnActiveOrInActive(e?.visitActivities || []).length > 0)
          .flatMap((visitActivitySubType) => returnActiveOrInActive(visitActivitySubType.visitActivities).filter((subVisitActivity) => !isEmpty(subVisitActivity) && !isEmpty(subVisitActivity.immunizationsDTO)))
      )
      if (immuDto && immuDto.length) {
        setListImmuDTO(immuDto.flatMap(e => e.immunizationsDTO))
      }
    }
    if (response?.visitDate) {
      setVisitDate(response?.visitDate);
    }
  }, [manageVisitContext.patient_get])



  useEffect(() => {
    resetCurrentErrors();
    setVisitDate(null);
    refreshManageVisit();
    setDataResposne(patient);
  }, [patient?.id]);

  const disableUpdateButtonRequirements = useCallback(() => {

    let isAttachmentRequired = false;
    let additionalValuesIds = [];

    if (selectedSubActivityGroup["CARE_GAP"]) {
      const activityGroup = selectedSubActivityGroup["CARE_GAP"];
      Object.keys(activityGroup).map((statusIndex, i) => {
        if (activityGroup[statusIndex]) {
          const activityGroupForStatus = activityGroup[statusIndex];
          Object.keys(activityGroupForStatus).map((activityIdx, i) => {
            const activity = activityGroupForStatus[activityIdx];

            if (((isEmpty(currentGapSelected) || (!isEmpty(currentGapSelected) && isEmpty(currentGapSelected.currentStatus))) && activity.toggleStatusValue === 'MARKED_FOR_CLOSURE')
                || (!isEmpty(currentGapSelected) && currentGapSelected.currentStatus && (currentGapSelected.currentStatus.key === 'Marked For Closure' || currentGapSelected.currentStatus.key.includes('Marked') || currentGapSelected.currentStatus.key.includes('Previously')))) {
              if (activity.abbreviation != "CDCE"
                  && activity.additionalInfoOptions && activity.additionalInfoOptions.length) {
                additionalValuesIds.push(activity.id);
              }
            }

            if (activity.requireFileUpload && contracted) {
              isAttachmentRequired = activity.requireFileUpload;
            }
          });
        }
      });
    }

    if (selectedSubActivityGroup["SUSPECT"]) {
      let suspectActivityGroup = selectedSubActivityGroup["SUSPECT"];
      let suspectActivityGroupKeys = Object.keys(suspectActivityGroup);

      if (currentSuspectSelected.currentStatus != null) {
        if (auth.getEvidenceRequired()
            && currentSuspectSelected.currentStatus.key === 'Confirmed Diagnosis'
            && suspectActivityGroupKeys.length > 0 && contracted) {
          isAttachmentRequired = true;
        } else {
          isAttachmentRequired = false;
        }
      } else {
        suspectActivityGroupKeys.map((statusIndex, i) => {
          if (suspectActivityGroup[statusIndex]) {
            const activityGroupForStatus = suspectActivityGroup[statusIndex];
            Object.keys(activityGroupForStatus).map((activityIdx, i) => {
              const activity = activityGroupForStatus[activityIdx];
              if (activity.requireFileUpload && contracted) {
                isAttachmentRequired = activity.requireFileUpload;
              }
            });
          }
        });
      }
    }

    if (selectedSubActivityGroup["CHRONIC_CONDITION"]) {
      const activityGroup = selectedSubActivityGroup["CHRONIC_CONDITION"];
      Object.keys(activityGroup).map((statusIndex, i) => {
        if (activityGroup[statusIndex]) {
          const activityGroupForStatus = activityGroup[statusIndex];
          Object.keys(activityGroupForStatus).map((activityIdx, i) => {
            const activity = activityGroupForStatus[activityIdx];
            if (activity.requireFileUpload && contracted) {
              isAttachmentRequired = activity.requireFileUpload;
            }
          });
        }
      });
    }

    for (let visit of selectedVisitTiles) {
      if (visit.abbreviation === "FUH" && visit.additionalInfoOptions && visit.additionalInfoOptions.length) {
        additionalValuesIds.push(visit.visitId);
      }
    }

    let isSelectedVisitRequiredUpload = selectedVisitTiles.filter(visit => visit.requireFileUpload).length > 0;

    return {
      isAttachmentRequired: auth.getEvidenceRequired() && (isAttachmentRequired || isSelectedVisitRequiredUpload),
      additionalValuesIds: additionalValuesIds
    }
  }, [currentGapSelected, selectedVisitTiles, selectedSubActivityGroup, contracted]);

  const checkCareGapAdditionalInfos = () => {
    const selectedGaps = requiredValues?.attestationDDLOptions || [];
    const selectedValues = preventiveCareUpdateResultsDto?.careGapAdditionalInfos || {};

    const fulfilled = selectedGaps.filter(e => selectedValues?.[e.gapId] == undefined || selectedValues?.[e.gapId] == null || selectedValues?.[e.gapId]?.length == 0)
    return fulfilled.length < 1
  }



  const checkVisitDate = () => {
    let result = false
    if ((selectedVisitTiles?.length > 0 && visitDate) || selectedVisitTiles?.length === 0 || immunizationSelected) {
      result = true
    }
    return result
  }

  const disableUpdateButton = () => {
    let { isAttachmentRequired, additionalValuesIds } = disableUpdateButtonRequirements();
    let checkAttachment = (!isAttachmentRequired || (isAttachmentRequired && preventiveCareUpdateResultsDto.uploadedFiles && preventiveCareUpdateResultsDto.uploadedFiles.length > 0));
    let checkAdditionalDetails = checkAdditionalDetailsMethod(additionalValuesIds)
    let checkCareGapAdditional = checkCareGapAdditionalInfos()

    let hasActiveVisitSelected = manageVisitData?.careVisitDtos
        ?.filter(dto => careVisitIds.includes(dto.visitId))
        ?.filter(dto => dto.careGapStatus !== "Marked for Closure" && dto.careGapStatus !== "Closed"
            && dto.careGapStatus !== "Gap Ineligible" && dto.careGapStatus !== "Excluded")
        ?.length > 0;

    const checkDOS = visitDate || (currentGapSelected && currentGapSelected.dateOfService) || (currentSuspectSelected && currentSuspectSelected.dateOfService)

    if (
        (hasActiveVisitSelected && checkAttachment && checkAdditionalDetails && checkDOS)
        || ((checkAdditionalDetails && checkAttachment && checkDOS) && ((actionChangeCount["CARE_GAP"] && actionChangeCount["CARE_GAP"] > 0)
            || (actionChangeCount["SUSPECT"] && actionChangeCount["SUSPECT"] > 0)) || (!checkDOS && immunizationSelected))
        && (checkCareGapAdditional || additionalValuesIds.length == 0)
    ) {
      return false;
    } else {
      return true;
    }
  };
  //, [dateOfService,selectedSubActivityAbbr, actionChangeCount, preventiveCareUpdateResultsDto,selectedSubActivityGroup]);

  const checkAdditionalDetailsMethod = (additionalValuesIds) => {
    if (additionalValuesIds.length == 0) {
      return true
    }
    let selectedCount = 0;
    Object.keys(preventiveCareUpdateResultsDto.careGapAdditionalInfos).map((gapId, k) => {
      if (preventiveCareUpdateResultsDto.careGapAdditionalInfos[gapId].length > 0 && additionalValuesIds.includes(gapId)) {
        selectedCount++;
      }
    });
    return (selectedCount == additionalValuesIds.length);
  }
  const validateAndUpdate = () => {
    resetCurrentErrors();
    if (selectedTilesChildId && selectedTilesChildId.length > 0) {


      let listOfDisplayActivity = []
      // for (const [key, value] of Object.entries(selectedTilesChildId)) {
      //   if (!selectedSubActivityId.includes(key)) {
      //     listOfDisplayActivity.add(value)
      //   }
      // }
      selectedTilesChildId.forEach((e) => {
        if (!selectedSubActivityId.includes(e.id) && !listOfDisplayActivity.includes(e.name)) {
          listOfDisplayActivity.push(e.name)
        }
      });
      if (listOfDisplayActivity.length) {
        setConfirmationWarning(listOfDisplayActivity);
      } else {
        setIsButtonDisabled(true);
        updateSelected();
      }

    } else {
      setIsButtonDisabled(true);
      updateSelected();
    }
  };

  // collecting all the required visit gap ids
  const getSelectedVisitRequiredGapIds = (selectedVisits) => {
    if (!selectedVisits) return [];
    let selectedVisitRequiredGapIds = [];
    for (let visit of selectedVisits) {
      let requiredGapIds = Object.keys(visit.requiredActivitiesMap);

      let requiredGaps = requiredGapIds.map(e => getActivityById(e))
      if (requiredGaps && requiredGaps.length > 0) {
        selectedVisitRequiredGapIds.push(...requiredGaps);
      }
    };
    return selectedVisitRequiredGapIds;
  }

  // collecting the visitId of all selected visits
  const getSelectedVisitId = (selectedVisits) => {
    if (!selectedVisits) return [];
    return selectedVisits.map(visit => visit.visitId);
  }

  //Multiple Tile Selection
  const handleTilesChange = (careVisit, idx) => {
    let selectedVisits = selectedVisitTiles || [];
    let careVisitIndex = selectedVisits.map(sv => sv?.visitId).indexOf(careVisit?.visitId);
    let selectedIndexes = selectedVisitTilesIdx || [];
    if (careVisitIndex == -1) {
      selectedVisits.push(careVisit);
      selectedIndexes.push(idx);
    } else {
      selectedVisits.splice(careVisitIndex, 1);
      selectedIndexes.splice(careVisitIndex, 1);
      const indexOfAttDDL = requiredValues.attestationDDLOptions.findIndex((e) => e.gapId === careVisit?.visitId);
      if (indexOfAttDDL > -1) {
        requiredValues.attestationDDLOptions.splice(indexOfAttDDL, 1);
      };
    }

    setSelectedVisitTiles(selectedVisits);
    setSelectedVisitTilesIdx(selectedIndexes);
    setSelectedTilesChildId(getSelectedVisitRequiredGapIds(selectedVisits));
    setCareVisitIds(getSelectedVisitId(selectedVisits));
    checkAndUpdateCareVisitAdditionInfoRequiredValues(selectedVisits);
  };

  useEffect(() => {
    checkVisitDateRequired();
  }, [selectedVisitTilesIdx, manageVisitContext, selectedVisitTiles, selectedTilesChildId])

  const handleUploadFileChange = (arr) => {
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      "uploadedFiles": arr,
    });
  };

  // Additional Info
  const checkAndUpdateCareVisitAdditionInfoRequiredValues = (selectedVisits) => {
    if (selectedVisits) {
      // Iterate through all the selected visit and construct the requiredValues
      for (let visit of selectedVisits) {

        if (!visit.additionalInfoOptions || !visit.additionalInfoOptions.length) {
          continue;
        }

        // check if the required visitIf already exists in the requiredValues attestationDDLOptions
        // if already exists no need to add it again
        // if not exists add it to the list
        const ind = requiredValues.attestationDDLOptions.findIndex(e => e.gapId === visit.visitId);
        if (ind === -1) {
          requiredValues.attestationDDLOptions.push({
            gapId: visit.visitId,
            visitGap: true,
            abbreviation: visit.abbreviation,
            caption: visit.abbreviation + " " + visit.additionalInfoCaption,
            options: visit.additionalInfoOptions,
          })
        }
      }
    }

    let hasRequiredValues = requiredValues.attestationDDLOptions.length > 0;
    setRequiredValues({ ...requiredValues, requiredAttestation: hasRequiredValues })
  }

  const handlePreventiveCareUpdateResultsDtoChange = (key, value) => {
    switch (key) {
      case "bloodPressureSystolic":
      case "bloodPressureDiastolic":
      case "systolicCode":
      case "diastolicCode":
        setBloodPressureError(value < 0 || value > 400);
        break;
      case "a1cWholes":
      case "a1cDecimal":
        setAc1ValueError(value > 20.9);
        break;
      default:
        break;
    }
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      [key]: value,
    });
  };

  const handleAttestationChangeForFilter = (value, gapId, abb) => {
    setFilterValues(value);
    let finalVal = [];
    if (value && value.paramValue && value.paramValue.length) {
      value.paramValue.forEach((val, index) => {
        finalVal.push({ id: val, description: value.displayValue[index], selected: true });
      })
    }
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      careGapAdditionalInfos: {
        ...preventiveCareUpdateResultsDto.careGapAdditionalInfos,
        [gapId]: finalVal,
      },
    });
  };
  const handleAttestationChange = (value, gapId) => {
    let finalVal = [];
    if (value) {
      finalVal.push({ id: value.id, description: value.description, selected: true });
    }
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      careGapAdditionalInfos: {
        ...preventiveCareUpdateResultsDto.careGapAdditionalInfos,
        [gapId]: finalVal,
      },
    });
  };

  /*
  This method checks if the label should be displayed or not with various scenarios.
  */
  const displaySubLabel = (arrProvided, val) => {
    let bool = false
    let arr = []
    if (val === "visitActivities") {
      arr = arrProvided
    }
    else {
      arr = arrProvided.flatMap(m => m.visitActivities)
    }
    if (showInactiveActivity) {

      bool = arr.some(e => (checkFilter(e.currentStatus) || hasDosesCompleted(e)))
      return bool
    }
    else {
      bool = arr.some(e => (!checkFilter(e.currentStatus)))
      return bool
    }
  }

  const returnActiveOrInActive = (activities) => {
    if (showInactiveActivity) {
      return activities.filter(e => (checkFilter(e.currentStatus) || hasDosesCompleted(e)))
    }
    else {
      return activities.filter(e => (!checkFilter(e.currentStatus)))
    }
  }

  const hasDosesCompleted = (e) => {
    const isCompleted = (e.abbreviation === 'IMA' || e.abbreviation === 'CIS')
        && e.immunizationsDTO.careGapImmunizationList.some(n => n.dosesActual >= n.doses)
    return isCompleted
  }

  const checkFilter = (currentStatus) => {
    const retValue = currentStatus === "MARKED_FOR_CLOSURE" || currentStatus === "GAP_INELIGIBLE" || currentStatus === "EXCLUDED" || currentStatus === "CLOSED" || currentStatus === "CONFIRMED_DIAGNOSIS" || currentStatus === "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS" || currentStatus === "UNABLE_TO_COMPLETE";
    return retValue
  }

  const checkforActivityExists = (activityType) => {
    return manageVisitData?.manageVisitActivityTypeDtos?.flatMap(t => t.visitActivitySubTypeDtos)?.flatMap(m => m.visitActivities)?.filter(n => n.actionType == activityType && !checkFilter(n.currentStatus)).length > 0
  }

  const getActivityById = (id) => {
    const subVisitActivity = manageVisitData?.manageVisitActivityTypeDtos?.
    flatMap(t => t.visitActivitySubTypeDtos)?.
    flatMap(m => m.visitActivities)?.find(n => n.id === id)

    let abbreviation = (subVisitActivity.abbreviation) ? " (" + subVisitActivity.abbreviation + ")" : "";
    let currentStatusDisplayName = " (" + subVisitActivity.currentStatusDisplayName + ")";
    let gapAndYear = " " + subVisitActivity.gapAndYear ? subVisitActivity.gapAndYear : '';
    const lbl = subVisitActivity.displayName + abbreviation + currentStatusDisplayName + " " + gapAndYear;


    return {'id': id, 'name':lbl}
  }


  const verifySubActivity = (subActivity) => {
    return subActivity?.currentStatus != '' && subActivity?.displayName != ''
  }

  const parseLabel = (subVisitActivity) => {
    let abbreviation = (subVisitActivity.abbreviation) ? " (" + subVisitActivity.abbreviation + ")" : "";
    let currentStatusDisplayName = " (" + subVisitActivity.currentStatusDisplayName + ")";
    let gapAndYear = " " + subVisitActivity.gapAndYear ? subVisitActivity.gapAndYear : '';
    let lbl = subVisitActivity.displayName + abbreviation + currentStatusDisplayName + " " + gapAndYear;
    Object.keys(selectedSubActivityGroup).map((subActInd, k) => {
      if (selectedSubActivityGroup[subActInd]) {
        const activityGroup = selectedSubActivityGroup[subActInd];
        Object.keys(activityGroup).map((statusIndex, i) => {
          if (activityGroup[statusIndex]) {
            const activityGroupForStatus = activityGroup[statusIndex];
            Object.keys(activityGroupForStatus).map((activityIdx, i) => {
              const activity = activityGroupForStatus[activityIdx];
              if (activity?.id === subVisitActivity?.id) {
                lbl = `${subVisitActivity.displayName} (${subVisitActivity.currentStatusDisplayName} -> ${subVisitActivity.toggleStatusName})`
              }
            });
          }
        });
      }
    })
    return lbl
  }

  return (
      <>
        <Row>
          <Col className="text-end">
            <Button
                variant="secondary"
                id={`${componentName}-patientHistory`}
                onClick={() => dispatch(setShowFullHistory(true))}
                className="border me-3"
            >
              History
            </Button>
          </Col>
        </Row>

        {!authorizedForViewNewManageVisit ? <>
          <Row>
            <Col>
              <label className="fs-5 fw-bold">Please choose a Visit(s)</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleButtonGroup
                  type="checkbox"
                  name="visit-type"
                  className="visit-type d-flex flex-wrap mb-3"
                  value={selectedVisitTilesIdx}
              >
                {
                  //Add tiles dynamically from manage-visit response
                    manageVisitContext?.patient_get?.careVisitDtos &&
                    manageVisitContext.patient_get.careVisitDtos.map((careVisit, idx) => {
                      return (
                          <ToggleButton
                              id={`${componentName}-visitType-${idx}`}
                              value={idx}
                              onChange={() => { handleTilesChange(careVisit, idx) }}
                              className={`visitTile ${selectedVisitTilesIdx.includes(idx) ? 'active' : ''}`}
                              type="button"
                          >
                            <OverlayTrigger trigger={['hover', 'focus']} placement="top"
                                            overlay={careVisit.abbreviation === "WC15" || careVisit.abbreviation === "WC30" ? (
                                                <Tooltip>{careVisit.eligibleDate && "Gap Ineligible on " + formatDateModern(careVisit.eligibleDate)}</Tooltip>
                                            ) : <span></span>}>
                              <div key={`${componentName}-visitInfo-${idx}`}>
                        <span id={`${componentName}-visitName-${idx}`} className="visit-name mb-2 fw-bold">
                          <RejectedValidationExclamation validation={careVisit?.validation} status={careVisit.careGapStatus?.toUpperCase()} requiredGap="true"></RejectedValidationExclamation>
                          {careVisit.name}
                        </span>
                                {(careVisit.abbreviation === "WC15" || careVisit.abbreviation === "WC30") && (
                                    <Button
                                        id={`${componentName}-Activities`}
                                        variant="link"
                                        onClick={() => { handleCareOpportunityChange(careVisit.visitId); }}
                                    >
                            <span id={`${componentName}-visitCompleted-${idx}`} className="visit-completed fw-bold">
                              {careVisit.visitsLowerBound + "/" + careVisit.visitsUpperBound} Visits Completed
                              <i className="fa-regular fa-arrow-up-right-from-square fa-xs ms-2"></i></span>
                                    </Button>
                                )}
                                {(careVisit.abbreviation === "FUH" || careVisit.abbreviation === "ADT-IP") && (
                                    <span id={`${componentName}-visitDueDate-${idx}`} className="visit-duedate">{careVisit.dueDate}</span>
                                )}
                                <span id={`${componentName}-visitGapStatus-${idx}`} className="visit-status fst-italic">
                          <span>{careVisit.gapAndYear}:</span> {careVisit.careGapStatus}
                        </span>
                                <span className="visit-reqactivities mt-2 fw-bold">Required Activities</span>
                                {careVisit.status === "Completed" && (
                                    <span id={`${componentName}-visitStatus-${idx}`} className="d-block"><i className="fa fa-check-circle"></i>  {careVisit.status}</span>
                                )}
                                {careVisit.status === "In Progress" && (
                                    <span id={`${componentName}-visitStatus-${idx}`} className="d-block"><i className="fa-solid fa-circle-half-stroke"></i> {careVisit.status}</span>
                                )}
                                {careVisit.status === "Not Started" && (
                                    <span id={`${componentName}-visitStatus-${idx}`} className="d-block"><i className="fa-regular fa-circle"></i> {careVisit.status}</span>
                                )}
                                {careVisit.status === "None" && (
                                    <span id={`${componentName}-visitStatus-${idx}`} className="d-block"><i className="fa fa-check-circle"></i> {careVisit.status}</span>
                                )}
                              </div>
                            </OverlayTrigger>
                          </ToggleButton>
                      )
                    })
                }
              </ToggleButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {<div className="bg-white border p-3 rounded-3">
                <Row className="mb-3">
                  <Col md={3}>
                    <div className="d-inline-block">
                      <label className="fs-5 fw-bold d-block">Visit Date {isVisitDateRequired && <span className="text-danger">*</span>}</label>
                      <EQHDatePicker
                          id={`${componentName}-patientVisitDatePicker`}
                          className="d-inline-block"
                          value={visitDate}
                          min={null}
                          max="9999-12-31"
                          onChange={(e) => {
                            handleVisitChange(e);
                          }}
                          style={{ maxWidth: "fit-content" }}
                      />
                      {errorFromDateComponent ?
                          <Alert key="danger" variant="danger">
                            {errorFromDateComponent}
                          </Alert> : ""}
                    </div>
                    {isSearching ? (
                        <Spinner
                            className="me-3"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />

                    ) : ("")}
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-end align-items-center mb-1 position-relative">
                      {!showGaps ?
                          <Badge pill bg="light" text="dark" id={`${componentName}-SuspectsCI`} className="border cursor-p"
                                 onClick={() => {
                                   setShowInactiveActivity(!showInactiveActivity);
                                   setShowGaps(false)
                                   setShowDocAndSetting(false)
                                 }} >
                            <i className={showInactiveActivity ? "fa fa-minus" : "fa fa-plus"}></i> Show Closed/ Inactive
                          </Badge>
                          : ""}
                      {checkforActivityExists("CARE_GAP") &&
                          <div className="d-flex flex-column align-items-end">
                            {!showInactiveActivity &&
                                <Button
                                    disabled={!actionChangeCount["CARE_GAP"] || actionChangeCount["CARE_GAP"] < 1}
                                    id={`${componentName}-editActivities`}
                                    className="btn btn-primary float-end ms-3"
                                    onClick={() => handleEdit("CARE_GAP", manageVisitData.caregapStatusesDropDownMap)}
                                >
                                  <i className="fa-solid fa-pen-to-square me-2"></i> Edit
                                </Button>
                            }
                            {showGaps &&
                                <span id={`${componentName}-editMessage`} className="mt-2">
                          Please select all activities you'd like to edit, designate a status, and a date of service
                        </span>
                            }
                          </div>
                      }
                      <div className="position-absolute top-50 end-0 mt-5" style={{ "zIndex": 1 }}>
                        {showGaps ? <EditComponent show={showGaps} handleClose={() => {
                          setShowGaps(false);
                          setCurrentGapSelected({});
                        }} setCurrentSelected={setCurrentGapSelected} currentSelected={currentGapSelected}

                                                   handleOptionChange={(e) => handleOptionChange(e, currentGapSelected)} /> : ""}
                      </div>
                    </div>
                    {errors?.length > 0 &&
                        <EQHErrorToast
                            className="float-end"
                            errors={errors}
                            isList={true}
                            handleClose={resetCurrentErrors}
                        />
                    }
                    <Modal
                        show={confirmationWarning.length ? true : false}
                        onHide={(e) => setConfirmationWarning([])}
                        size="md"
                        centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Missed Opportunities</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>One or more visits have required activities that have not been completed. Do you wish to continue?</p>
                        <p><strong>Incomplete Activities</strong></p>
                        {confirmationWarning && confirmationWarning.length
                            && confirmationWarning.map((e)=>(
                                <div className="d-flex align-items-start"><i className="fa-solid fa-xmark text-danger me-2 mt-1"></i><div>{e}</div></div>
                            ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                            id={`${componentName}-confirmNoBtn`}
                            variant="secondary"
                            onClick={(e) => {
                              setConfirmationWarning([]);
                            }}
                        >
                          No, Go Back
                        </Button>
                        <Button
                            id={`${componentName}-confirmYesBtn`}
                            variant="primary"
                            onClick={(e) => {
                              setConfirmationWarning([]);
                              updateSelected();
                            }}
                        >
                          Yes, Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>

                  </Col>

                </Row>

                {//load manage visit type, subtype and its activities
                  (manageVisitData.manageVisitActivityTypeDtos) ?
                      manageVisitData.manageVisitActivityTypeDtos.map((visitActivity, idx1) => {
                            return (
                                <Row className="mb-5" key={`${visitActivity.name}-${idx1}`}>
                                  {
                                      visitActivity?.name === "Documentation And Coding" && (
                                          <Col sm={12}>
                                            <div className="d-flex flex-column align-items-end">
                                              {auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS") &&
                                                  <div className="d-block">
                                                    <Button
                                                        id={`${componentName}-addSuspect`}
                                                        className="btn btn-secondary mb-2"
                                                        onClick={() => {
                                                          manageVisitContext.toggleAddModal(true);
                                                          manageVisitContext.setModalType('Suspect')
                                                        }}
                                                    >
                                                      <i className="fa-solid fa-plus-circle me-2"></i> Suspect
                                                    </Button>
                                                    {contracted ? (
                                                        <Button
                                                            id={`${componentName}-addConditions`}
                                                            className="btn btn-secondary ms-3 mb-2"
                                                            onClick={() => {
                                                              manageVisitContext.toggleAddModal(true);
                                                              manageVisitContext.setModalType('Condition')
                                                            }}
                                                        >
                                                          <i className="fa-solid fa-plus-circle me-2" ></i> Condition
                                                        </Button>
                                                    ) : null}
                                                    {contracted ? (
                                                        <Button
                                                            id={`${componentName}-addReject`}
                                                            className="btn btn-secondary ms-3 mb-2"
                                                            onClick={() => {
                                                              manageVisitContext.toggleAddModal(true);
                                                              manageVisitContext.setModalType('Reject')
                                                            }}
                                                        >
                                                          <i className="fa-solid fa-plus-circle me-2" ></i> Reject
                                                        </Button>
                                                    ) : null}
                                                  </div>
                                              }
                                              {!showInactiveActivity && checkforActivityExists("SUSPECT") ? <Button id={`${componentName}-editSuspects`} className="btn btn-primary mb-3" disabled={showInactiveActivity || (!actionChangeCount["SUSPECT"] || actionChangeCount["SUSPECT"] < 1)} onClick={() => handleEdit("SUSPECT", manageVisitData.conditionStatusesDropDownMap)}>
                                                <i className="fa-solid fa-pen-to-square me-2" ></i> Edit</Button> : ""}
                                              {showDocAndSetting ? <span className="text-end mb-2" id={`${componentName}-editMessage`}>Please select all activities you'd like to edit, designate a status, and a date of service</span> : ""}
                                              {showDocAndSetting ? <EditComponent show={showDocAndSetting} handleClose={() => { setShowDocAndSetting(false); setCurrentSuspectSelected({}) }} setCurrentSelected={setCurrentSuspectSelected} currentSelected={currentSuspectSelected} handleOptionChange={(e) => handleOptionChange(e, currentSuspectSelected)} /> : ""}
                                            </div>
                                          </Col>)
                                  }
                                  <Col>
                                    {(displaySubLabel(visitActivity.visitActivitySubTypeDtos)) &&
                                        <div className="d-flex justify-content-between">
                                          <label id={`${componentName}-visitActivityTitle-${idx1}`} className="fs-5 fw-bold">{visitActivity.name}</label>
                                        </div>
                                    }
                                    {visitActivity.visitActivitySubTypeDtos &&
                                        visitActivity.visitActivitySubTypeDtos
                                            .filter(e => returnActiveOrInActive(e?.visitActivities || []).length > 0)
                                            .map((visitActivitySubType, idx2) => {
                                                  return (<>
                                                    <div className="visit-activity-subType">
                                  <span className="d-block visit-action-subtitle">
                                    {displaySubLabel(visitActivitySubType.visitActivities, "visitActivities") && !isEmpty(visitActivitySubType.name) && visitActivitySubType.name !== "None" &&
                                        (
                                            <div className="d-flex justify-content-between">
                                              <span id={`${componentName}-visitActivitySubType-${idx2}`} className="visit-action-subtitle">{visitActivitySubType.name}</span>
                                            </div>
                                        )
                                    }
                                  </span>
                                                      <div id={`${componentName}-visitActions-${idx2}`} className="mb-3">
                                                        <div className={visitActivity.name == "Treatment Planning" ? "treatment-planning-actions px-3" : ""}>
                                                          {/*{visitActivity.name == "Treatment Planning" && <div className="d-block fw-bold mb-1">Actions to consider</div>}*/}
                                                          {visitActivitySubType.visitActivities &&
                                                              returnActiveOrInActive(visitActivitySubType.visitActivities)
                                                                  .map((subVisitActivity, idx3) => {
                                                                        const visitType = (visitActivity.name === 'Documentation And Coding') ? 'DAC' : (visitActivity.name === 'Prevention and Screenings') ? 'CG' : (visitActivity.name === 'Needed Referrals') ? 'CG' : (visitActivity.name === 'Medication Review') ? 'CG' : 'Other';
                                                                        return (
                                                                            verifySubActivity(subVisitActivity) && <>
                                                                              <div className="visit-action">
                                                                                <Row>
                                                                                  <Col>
                                                                                    {
                                                                                      subVisitActivity?.toggleStatusValue === "INFO_ONLY" ?
                                                                                          (<>{subVisitActivity?.currentStatus === "OPEN" ? (<div id={`${componentName}-${visitType}-subVisitActivity-${idx3}`} className="d-flex">
                                                                                                    <i id={`${componentName}-${visitType}-subVisitActivityIcon-${idx3}`} className="far fa-info-circle mt-1 me-2"></i>{" "}
                                                                                                    <label id={`${componentName}-${visitType}-subVisitActivityLabel-${idx3}`} className="form-check-label" for="flexSwitchCheckDefault">
                                                                                                      <RejectedValidationExclamation validation={subVisitActivity?.validation} status={subVisitActivity?.currentStatus?.toUpperCase()}></RejectedValidationExclamation>
                                                                                                      {subVisitActivity.displayName} {selectedTilesChildId.some(e=>e.id===subVisitActivity.id) ? (<span className="text-danger">*</span>) : (null)}
                                                                                                    </label>
                                                                                                  </div>) :
                                                                                                  (<>{subVisitActivity?.currentStatus === "CLOSED" ? (<div id={`${componentName}-${visitType}-subVisitActivity-${idx3}`} className="d-flex text-success">
                                                                                                    <i id={`${componentName}-${visitType}-subVisitActivityIcon-${idx3}`} className="far fa-check-circle mt-1 me-2"></i>{" "}
                                                                                                    <label id={`${componentName}-${visitType}-subVisitActivityLabel-${idx3}`} className="form-check-label" for="flexSwitchCheckDefault">
                                                                                                      {subVisitActivity.displayName} {selectedTilesChildId.some(e=>e.id===subVisitActivity.id) ? (<span className="text-danger">*</span>) : (null)}
                                                                                                    </label>
                                                                                                  </div>) : (null)}</>)}
                                                                                                {" "}</>
                                                                                          ) : (<><div id={`${componentName}-${visitType}-subVisitActivity-${idx3}`} className="form-check form-switch d-flex justify-content-between">
                                                                                            <div className="w-60">
                                                                                              {(((subVisitActivity.abbreviation !== 'CIS' || (subVisitActivity.abbreviation === 'CIS' && checkFilter(subVisitActivity.currentStatus)))
                                                                                                      && (subVisitActivity.abbreviation !== 'IMA' || (subVisitActivity.abbreviation === 'IMA' && checkFilter(subVisitActivity.currentStatus))) && featureFlag) || !featureFlag)
                                                                                                  && (<input id={`${componentName}-${visitType}-subVisitActivityIcon-${idx3}`}
                                                                                                             className="form-check-input me-2" defaultChecked={subVisitActivity.toggleStatus} type="checkbox" disabled={subVisitActivity.disabled}
                                                                                                             onChange={(event) => handleActionChange(event, subVisitActivity)} />)}
                                                                                              <label id={`${componentName}-${visitType}-subVisitActivityLabel-${idx3}`} className="form-check-label d-flex justify-content-between align-items-start" for="flexSwitchCheckDefault">
                                                                                                <div>
                                                                                                  <RejectedValidationExclamation validation={subVisitActivity?.validation} status={subVisitActivity?.currentStatus?.toUpperCase()}></RejectedValidationExclamation>
                                                                                                  {parseLabel(subVisitActivity)}
                                                                                                  {selectedTilesChildId.some(e=>e.id===subVisitActivity.id)
                                                                                                      ? (<span className="text-danger">*</span>)
                                                                                                      : (null)
                                                                                                  }
                                                                                                  {subVisitActivity?.openSource == "UI" && (subVisitActivity.createdBy == auth.getUserId()) && auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS") && auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS") &&
                                                                                                      <OverlayTrigger placement="auto" overlay={<Tooltip>Delete Suspect</Tooltip>}>
                                                                                                        <Button
                                                                                                            id={`${componentName}-deleteSuspectCondition-${idx3}`}
                                                                                                            variant="link"
                                                                                                            onClick={function () { handleConfirmDeleteShow(subVisitActivity.id) }}
                                                                                                            className="text-dark p-0 ms-2"
                                                                                                        >
                                                                                                          <i className="fa-solid fa-trash"></i>
                                                                                                        </Button>
                                                                                                      </OverlayTrigger>
                                                                                                  }
                                                                                                </div>
                                                                                                {featureFlag && (subVisitActivity.abbreviation === 'CIS' || subVisitActivity.abbreviation === 'IMA') && (
                                                                                                    <div className="d-flex flex-nowrap align-items-center">
                                                                                                      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Gap Ineligible
                                                                                                        on {formatDateModern(subVisitActivity?.immunizationsDTO?.eligibilityDate)}</Tooltip>}>
                                                                                                        <ProgressBar id={`${componentName}-visitProgressBar-${idx1}`} className="mvprogress me-2" now={subVisitActivity?.immunizationsDTO?.completionPercent} />
                                                                                                      </OverlayTrigger>
                                                                                                      <span id={`${componentName}-visitProgressText-${idx1}`} className="fw-bold nowrap">{subVisitActivity?.immunizationsDTO?.completionPercent}% Complete</span>
                                                                                                    </div>
                                                                                                )}
                                                                                                <Modal
                                                                                                    id={`${componentName}-deleteSuspectConditionModal`}
                                                                                                    backdropClassName="confirmbackdrop"
                                                                                                    show={showConfirmDelete}
                                                                                                    onHide={handleConfirmDeleteClose}
                                                                                                    size="sm"
                                                                                                    aria-labelledby="contained-modal-title-vcenter"
                                                                                                    centered
                                                                                                >
                                                                                                  <Modal.Header>
                                                                                                    <Modal.Title id={`${componentName}-deleteSuspectConditionTitle`}>Confirm Deletion</Modal.Title>
                                                                                                    <button id={`${componentName}-deleteSuspectConditionClose`} type="button" className="btn-close" onClick={handleConfirmDeleteClose} aria-label="Close"></button>
                                                                                                  </Modal.Header>
                                                                                                  <Modal.Body>Are you sure you would like to delete this Suspect?</Modal.Body>
                                                                                                  <Modal.Footer>
                                                                                                    <Button id={`${componentName}-deleteSuspectConditionCancel`} variant="secondary" onClick={handleConfirmDeleteClose}>
                                                                                                      Cancel
                                                                                                    </Button>
                                                                                                    <Button id={`${componentName}-deleteSuspectConditionConfirm`} variant="primary" onClick={() => handleDeleteSuspect(suspectId, patient)}>
                                                                                                      Delete
                                                                                                    </Button>
                                                                                                  </Modal.Footer>
                                                                                                </Modal>
                                                                                              </label>
                                                                                              {featureFlag && !showInactiveActivity && (subVisitActivity.abbreviation === 'CIS' || subVisitActivity.abbreviation === 'IMA') && (
                                                                                                  <div id={`${componentName}-visitActions-${idx2}`} className="mb-3">


                                                                                                    {getImmuList(subVisitActivity.immunizationsDTO.careGapId).map((immuObject) => (
                                                                                                        (<ImmunizationComponent
                                                                                                            immuObject={immuObject}
                                                                                                            id={immuObject?.id}
                                                                                                            showInactiveActivity={showInactiveActivity}
                                                                                                            handleSave={(...arg) => handleImmunizationsSave(...arg, subVisitActivity)}
                                                                                                        />)
                                                                                                    ))}
                                                                                                  </div>
                                                                                              )}
                                                                                              {featureFlag && showInactiveActivity && (subVisitActivity.abbreviation === 'CIS' || subVisitActivity.abbreviation === 'IMA') && (
                                                                                                  <div id={`${componentName}-visitActions-${idx2}`} className="mb-3">
                                                                                                    {checkDoses(subVisitActivity).map((immuObject) => (
                                                                                                        (<ImmunizationComponentDisabled
                                                                                                            immuObject={immuObject}
                                                                                                            id={immuObject?.id}
                                                                                                        />)
                                                                                                    ))}
                                                                                                  </div>
                                                                                              )}
                                                                                            </div>
                                                                                          </div></>)
                                                                                    }
                                                                                  </Col>
                                                                                </Row>
                                                                              </div>
                                                                            </>
                                                                        )
                                                                      }
                                                                  )
                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>)
                                                }
                                            )
                                    }
                                  </Col>
                                </Row>
                            )
                          }
                      ) : (null)
                }
                {(requiredValues.requireA1C || requiredValues.requireBloodPressure || requiredValues.requiredAttestation) && (!currentGapSelected.currentStatus?.key.includes('Refused') && !currentGapSelected.currentStatus?.key.includes('Ordered') && !currentGapSelected.currentStatus?.key.includes('Excluded')) ? (
                    <Row className="bg-light border pt-3 w-100 mx-auto mb-3">
                      <Col sm={12}>
                        <h5 id={`${componentName}-AddDetails`} className="fw-bold">
                          Additional Details
                        </h5>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={4}>
                            <InputGroup className="border-0">
                              <Form.Label id={`${componentName}-AddDetailsA1CValueText`} className="d-block">
                                A1C Value
                              </Form.Label>
                              <Row className="g-0">
                                <Col sm={5}>
                                  <Form.Control
                                      id={`${componentName}-AddDetailsA1CValue1Value`}
                                      type="number"
                                      name="a1cWholes"
                                      className="border"
                                      value={preventiveCareUpdateResultsDto.a1cWholes}
                                      onChange={(e) =>
                                          handlePreventiveCareUpdateResultsDtoChange(
                                              "a1cWholes",
                                              e.target.value
                                          )
                                      }
                                      disabled={!requiredValues.requireA1C}
                                  />
                                </Col>
                                <Col sm={1} className="pt-3 text-center fw-bold">
                                  .
                                </Col>
                                <Col sm={5}>
                                  <Form.Control
                                      id={`${componentName}-AddDetailsA1CValue2Value`}
                                      type="number"
                                      name="a1cValue2"
                                      className="border"
                                      value={preventiveCareUpdateResultsDto.a1cDecimal}
                                      onChange={(e) =>
                                          handlePreventiveCareUpdateResultsDtoChange(
                                              "a1cDecimal",
                                              e.target.value
                                          )
                                      }
                                      disabled={!requiredValues.requireA1C}
                                  />
                                </Col>
                                {ac1ValueError ? (
                                    <Toast>
                                      <Toast.Header>
                                        <strong className="me-auto fw-bold text-danger">
                                          <i id={`${componentName}-AddDetailsA1CValueErrorIcon`} className="fas fa-exclamation-triangle"></i>{" "}
                                        </strong>
                                        <small id={`${componentName}-AddDetailsA1CValueErrorText`}>
                                          A1C Values must be integers and cannot exceed 20.9
                                        </small>
                                      </Toast.Header>
                                    </Toast>
                                ) : null}
                              </Row>
                            </InputGroup>
                          </Col>
                          <Col sm={4}>
                            <InputGroup className="border-0">
                              <Form.Label id={`${componentName}-AddDetailsBloodPressValueText`} className="d-block">
                                Blood Pressure Value
                              </Form.Label>
                              <Row className="g-0">
                                <Col sm={5}>
                                  <Form.Control
                                      id={`${componentName}-AddDetailsBloodPress1Value`}
                                      type="number"
                                      name="bloodPressureSystolic"
                                      className="border"
                                      value={
                                        preventiveCareUpdateResultsDto.bloodPressureSystolic
                                      }
                                      onChange={(e) =>
                                          handlePreventiveCareUpdateResultsDtoChange(
                                              "bloodPressureSystolic",
                                              e.target.value
                                          )
                                      }
                                      disabled={!requiredValues.requireBloodPressure}
                                  />
                                </Col>
                                <Col sm={1} className="h1 mb-0 text-center fw-bold">
                                  /
                                </Col>
                                <Col sm={5}>
                                  <Form.Control
                                      id={`${componentName}-AddDetailsBloodPress2Value`}
                                      type="number"
                                      name="bloodPreasure2"
                                      className="border"
                                      value={
                                        preventiveCareUpdateResultsDto.bloodPressureDiastolic
                                      }
                                      onChange={(e) =>
                                          handlePreventiveCareUpdateResultsDtoChange(
                                              "bloodPressureDiastolic",
                                              e.target.value
                                          )
                                      }
                                      disabled={!requiredValues.requireBloodPressure}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <small>Systolic / Diastolic</small>
                                  {bloodPressureError ? (
                                      <Toast>
                                        <Toast.Header>
                                          <strong className="me-auto fw-bold text-danger">
                                            <i id={`${componentName}-AddDetailsBloodPressErrorIcon`}></i>{" "}
                                          </strong>
                                          <small id={`${componentName}-AddDetailsBloodPressErrorText`}>
                                            Must be an integer between 0 and 400
                                          </small>
                                        </Toast.Header>
                                      </Toast>
                                  ) : null}
                                </Col>
                              </Row>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <ProvDropdown>
                              <Form.Group>
                                {requiredValues?.attestationDDLOptions?.length && requiredValues.requiredAttestation
                                    ? requiredValues.attestationDDLOptions.map((detail, index) => (
                                        <>
                                          <Form.Label
                                              id={`${componentName}-AddDetailsProvAttestationText`}
                                              className="d-block"
                                          >
                                            {detail.caption}
                                            {
                                              detail.abbreviation !== "CDCE" ? (<span className="text-danger ms-1 fs-5 lh-1">*</span>) : (null)
                                            }

                                          </Form.Label>
                                          {detail.caption.includes("OMW") ?
                                              <Filter
                                                  selected={filterValues}
                                                  handleChange={(e) => handleAttestationChangeForFilter(e, detail.gapId, detail.abbreviation)}
                                                  filterDefinition={{
                                                    type: GENERIC_DDL_TYPE,
                                                    getHeader: () => "",
                                                    key: "OMW",
                                                    componentProps: {
                                                      mode: DDLMode.MultipleSelect,
                                                      dataMode: DDLDataMode.OneAPICallFilterInUI,
                                                      showSearchTextbox: false,
                                                      highlightWhenHasValue: false,
                                                      showClearButton: true,
                                                      getData: (srchTxt) => Promise.resolve([
                                                        {
                                                          text: "All",
                                                          subItems: detail.options.map(
                                                              (m) => {
                                                                return { text: m.description, value: m.id };
                                                              }
                                                          ),
                                                        },
                                                      ]),
                                                    },
                                                  }}
                                              /> :
                                              <SimpleDDL
                                                  id={`${componentName}-AddDetailsProvAttestationValue`}
                                                  list={detail.options}
                                                  textSelector={(x) => x.description}
                                                  valueSelector={(x) => x}
                                                  getKeyFromListItem={(item) =>
                                                      item?.id
                                                  }
                                                  onChange={(x) =>
                                                      handleAttestationChange(x, detail.gapId)
                                                  }
                                                  disabled={!requiredValues.requiredAttestation}
                                                  selectedKey={
                                                    preventiveCareUpdateResultsDto
                                                        .careGapAdditionalInfos[detail.gapId]?.[0]?.id
                                                  }
                                              />}
                                        </>
                                    ))
                                    : null}
                              </Form.Group>
                            </ProvDropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                ) : null}
                <Row className="mb-5">
                  <Col>
                    <span id={`${componentName}-evidenceLabel`} className="fs-5 fw-bold">Evidence</span>{disableUpdateButtonRequirements().isAttachmentRequired && <span id={`${componentName}-evidenceRequired`} className="text-danger">*</span>}
                    {isDisplayMessage() && <p id={`${componentName}-evidenceMessage`}>Thank you for allowing Equality Health to integrate with your EHR! As a result of this partnership with us, you do not need to upload any visit documentation.</p>}
                    <Row className="mb-5">
                      <Col>
                        <FileUpload
                            id={`${componentName}-fileUpload`}
                            onChange={handleUploadFileChange}
                            clearStateProp={fileUploadResetCounter}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <span className="fs-5 fw-bold">Add Notes</span>
                    <textarea id={`${componentName}-addNotesValue`}
                              className="form-control"
                              value={preventiveCareUpdateResultsDto.note}
                              onChange={e => {
                                setPreventiveCareUpdateResultsDto({ ...preventiveCareUpdateResultsDto, note: e.target.value });
                              }}
                              rows="3">
                </textarea>
                  </Col>
                </Row>
                {!disableUpdateButton() && checkVisitDate() && !isButtonDisabled ? (
                    <Row>
                      <Col className="text-end mb-1">
                        <small className="text-black-50 fw-bold">You Have Unsaved Changes.</small>
                      </Col>
                    </Row>
                ) : null}
                <Row>
                  <Col className="text-end">
                    <Button id={`${componentName}-cancelBtn`} variant="secondary" data-bs-dismiss="offcanvas" className="me-3" onClick={() => setPatientDetailsShow(false)} >Cancel</Button>
                    <Button id={`${componentName}-updateBtn`} ref={btnRef} variant="primary" disabled={(!(!disableUpdateButton() && checkVisitDate() && !isButtonDisabled))} onClick={validateAndUpdate}>Update</Button>
                    {isUpdating ? (
                        <Spinner
                            className="me-3"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />

                    ) : ("")}
                  </Col>
                </Row>
              </div>}
            </Col>
          </Row>
        </> : <div className="bg-white border rounded-3 mt-4">
          <VisitTab visitsDetails={manageVisitData ?? {}}
                    patient={patient}
                    visitDate={visitDate}
                    refreshManageVisit={refreshManageVisit}
                    resetAfterUpdate={resetAfterUpdate}
                    handleUploadFileChange={handleUploadFileChange}
                    fileUploadResetCounter={fileUploadResetCounter}
                    visitDocumentationNotRequired={isDisplayMessage()}
                    attachmentRequired={disableUpdateButtonRequirements().isAttachmentRequired}/>
        </div>}

      </>
  );
};
const ProvDropdown = styled.div`
  .dropdown-item {
    white-space: normal;
  }
`;

const EditComponent = ({ show, handleClose, setCurrentSelected, currentSelected, handleOptionChange }) => {
  const [errorFromDateInEditC, setErrorFromDateInEditC] = useState('')
  const validateCurrentDate = (e) => {
    if (e && e.error) {
      setErrorFromDateInEditC(e.error)
      setCurrentSelected({ ...currentSelected, dateOfService: '' });
    }
    else {
      setCurrentSelected({ ...currentSelected, dateOfService: e.strDate });
      setErrorFromDateInEditC('')
    }
  }

  return (
      <Row id={`${componentName}-editStatusModal`} className="bg-light border me-0" size="sm" display={show} centered>
        <Col>
          <Row>
            <Col>
              <Button id={`${componentName}-bulkEditCancelBtn`} variant="link" className="float-end pb-0 px-0" onClick={handleClose} >
                <i className="fa-solid fa-close fa-lg text-dark"></i>
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <label id={`${componentName}-bulkEditDOSLabel`}>
                  Date of Service<span className="text-danger">*</span>
                </label>
                <EQHDatePicker
                    id={`${componentName}-bulkEditDOSValue`}
                    onChange={(e) => {
                      validateCurrentDate(e);
                    }}
                    min={null}
                    max="9999-12-31"
                    value={currentSelected.dateOfService || ""}
                />
                {errorFromDateInEditC ?
                    <Alert key="danger" variant="danger">
                      {errorFromDateInEditC}
                    </Alert> : ""}

              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <label id={`${componentName}-bulkEditStatusLabel`}>Status<span className="text-danger">*</span></label>
              <select id={`${componentName}-bulkEditStatusValue`} className="form-select" aria-label="Status" onChange={(e) => { handleOptionChange(e) }} value={currentSelected.currentStatus?.key || ""}>
                <option value="" disabled>-Select-</option>
                {currentSelected.selected != null && Object.keys(currentSelected.selected).map((statusKey, i) => {
                  return <option
                      key={'conditionStatuses-' + i}
                      id={'conditionStatuses-' + statusKey}
                      value={statusKey}
                  >
                    {statusKey}
                  </option>
                })}
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
  )
}

const ImmunizationComponentDisabled = ({ immuObject, index }) => {
  return (
      <>
        <div id={`${componentName}-subVisitActivity-${index}`} className="d-flex justify-content-between" >
          <div>
            <input className="form-check-input me-2" type="checkbox" disabled={true} />
            <span className="text-black-50">{immuObject?.description ? immuObject?.description : ''} {immuObject?.immunizationAbbreviation ? `(${immuObject?.immunizationAbbreviation})` : ''}</span>
          </div>
          <Badge className={"badge bg-light rounded-pill text-dark border-0 text-dark text-opacity-50"}>{immuObject?.dosesActual}/{immuObject?.doses} Doses</Badge>
        </div>
      </>
  )

}
export default ManageVisit;