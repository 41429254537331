import React from 'react'
import { Timeline } from '../../../../components/Timeline';
import {
  formatDateVanilla,
  splitDateTime
} from '../../../../utils/util';
import ReferralCard from './ReferralCard';

const componentName = 'timeLineReferrals';

function TimelineReferral({ items, patient, openStates, toggleIndividual, toggleIndividualOpen }) {
  return (
    <Timeline
      items={items ?? []}
      titleBuilder={(referral, idx) => {
          if (referral && referral.statusUpdated) {
            const [date, time] = splitDateTime(formatDateVanilla(referral?.statusUpdated, "MMM dd, yyyy, hh:mm a"));

            return <div>
                <span id={`${componentName}-tlDate-${idx}`}
                      className="d-block fw-bold">
                  {date}
                </span>
              <span id={`${componentName}-tlTime-${idx}`} className="d-block">
                  {time}
                </span>
            </div>;
          }
          return "-";
        }
      }
      subTitleBuilder={(referral) => referral.dateTime}
      bodyBuilder={(referral, idx) => (
        <ReferralCard
          section={'all'}
          idx={idx}
          referral={referral}
          refreshReferrals={() => { }}
          patient={patient}
          open={openStates[idx]}
          toggleIndividual={() => toggleIndividual(idx)}
          toggleIndividualOpen={() => toggleIndividualOpen(idx)}
        />
      )}
    />
  )
}

export default TimelineReferral