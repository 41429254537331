import { actions } from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE_SHOW_ADD_APPOINTMENT:
      return {
        ...state,
        showAddAppointment: action.payload,
      };
    case actions.SET_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
      };
    case actions.SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case actions.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case actions.TOGGLE_IS_EDIT:
      return {
        ...state,
        isEdit: action.payload,
      };
    case actions.TOGGLE_SHOW_CANCEL_APPOINTMENT:
      return {
        ...state,
        showCancelAppointment: action.payload,
      };
    case actions.TOGGLE_IS_CANCELING_APPOINTMENT:
      return {
        ...state,
        isCancelingAppointment: action.payload,
      };
    case actions.SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload,
      };
    default:
      return state;
  }
};