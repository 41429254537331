import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { formatDateVanilla } from '../../utils/util';
import differenceInDays from 'date-fns/differenceInDays'

const componentName = 'DateDLL'

function DateDDL({
  onDataChange,
  comparisonValues
}) {
  const [differenceDays, setdifferenceDays] = useState(0);

  const yyyyMMddToMMddyyyy = (strDate) => {
    return strDate
      ? `${strDate.substr(5, 2)}/${strDate.substr(8, 2)}/${strDate.substr(
        0,
        4
      )}`
      : "";
  };

  const handleDateChange = (period) => {
    const today = yyyyMMddToMMddyyyy(formatDateVanilla(new Date(), 'yyyy-MM-dd'));
    let priorDate = comparisonValues[0];
    let operator = 'range';

    if (period == '30') {
      priorDate = new Date(new Date().setDate(new Date().getDate() - 30));
      priorDate = yyyyMMddToMMddyyyy(formatDateVanilla(priorDate, 'yyyy-MM-dd'));
      operator = 'period30';
    }
    if (period == '60') {
      priorDate = new Date(new Date().setDate(new Date().getDate() - 60));
      priorDate = yyyyMMddToMMddyyyy(formatDateVanilla(priorDate, 'yyyy-MM-dd'));
      operator = 'period60'
    }
    if (period == '90') {
      priorDate = new Date(new Date().setDate(new Date().getDate() - 90));
      priorDate = yyyyMMddToMMddyyyy(formatDateVanilla(priorDate, 'yyyy-MM-dd'));
      operator = 'period90'
    }
    setdifferenceDays(differenceInDays(new Date(today), new Date(priorDate)))
    onDataChange([priorDate, today], operator)
  }

  const getDifferenceDays = () => {
    const date0 = comparisonValues?.[0]
    const date1 = comparisonValues?.[1]
    return differenceInDays(new Date(date1), new Date(date0))
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <Form.Check id={`${componentName}-period30`}>
        <Form.Check.Input id={`${componentName}-period30-input`} type="radio" onChange={() => handleDateChange('30')} checked={getDifferenceDays() == 30} />
        <Form.Check.Label id={`${componentName}-period30-label`}>Last 30 Days</Form.Check.Label>
      </Form.Check>
      <Form.Check id={`${componentName}-period60`}>
        <Form.Check.Input id={`${componentName}-period60-input`} type="radio" onChange={() => handleDateChange('60')} checked={getDifferenceDays() == 60} />
        <Form.Check.Label id={`${componentName}-period60-label`}>Last 60 Days</Form.Check.Label>
      </Form.Check>
      <Form.Check id={`${componentName}-period90`}>
        <Form.Check.Input id={`${componentName}-period90-input`} type="radio" onChange={() => handleDateChange('90')} checked={getDifferenceDays() == 90} />
        <Form.Check.Label id={`${componentName}-period90-label`}>Last 90 Days</Form.Check.Label>
      </Form.Check>
    </div>
  )
}

export default DateDDL