import React from "react";
import {
  useCMWorklistContext
} from "./CMWorklistModals/context/CMWorklist.context";
import DropDownMenu from "./DropDownMenu";
import {getGeneralActions} from "../constants";
import { getCustomListName, getQuickListId } from "../../../../utils/util";

import {
  useCustomWorklistContext
} from "../../../../components/CustomWorklist";

const GeneralActions = ({ idx }) => {
  const isEmpty = require("is-empty");

  const {
    exportToCSV,
    selectedPatientsEvents,
    saveWorklistDefaultView,
    patientSearch,
    worklistDefaultView,
    exportToActivator,
    activeRole,
    sendEvent
  } = useCMWorklistContext();

  const {
    toggleSaveAsCustomWorklist,
    toggleDeleteCustomWorklist,
  } = useCustomWorklistContext();

  const { role } = activeRole;

  let customListName = getCustomListName();
  let quickListId = getQuickListId();

  const showDeleteCustomWorklistMenuItem = ((quickListId && !isNaN(quickListId)) && !isEmpty(customListName));

  const onPatientActionClicked = (menuItemClicked) => {
    // eslint-disable-next-line default-case
    switch (menuItemClicked) {
      case "Save as Custom Worklist":
        sendEvent('CLICK_SAVE_WORKLIST');
        toggleSaveAsCustomWorklist(true);
        break;
      case "Export to CSV":
        sendEvent('EXPORT_CSV');
        exportToCSV([], [
          {
            "paramName": 'default sort',
            "direction": "Asc",
            "priority": 1
          }
        ], selectedPatientsEvents);
        break;
      case "Delete Custom Worklist":
        sendEvent('DELETE_WORKLIST');
        toggleDeleteCustomWorklist(true);
        break;
      case "Save as Default View":
        sendEvent('SAVE_DEFAULT');
        saveWorklistDefaultView({
          id: worklistDefaultView?.id,
          quickList: worklistDefaultView?.quickList,
          patientRequestDTO: {
            filterParams: patientSearch.filterParams,
            sortParams: patientSearch.sortParams,
            pageNumber: patientSearch.pageNumber,
            pageSize: patientSearch.pageSize,
            quickListName: worklistDefaultView?.quickList
          }
        });
        break;
      case "Export to Activator":
        sendEvent('SELECT_EXPORT_ACTIVATOR');
        exportToActivator([], [
          {
            "paramName": 'default sort',
            "direction": "Asc",
            "priority": 1
          }
        ], selectedPatientsEvents);
        break;
    }
  }

  let generalActions = getGeneralActions(role, {
    disableExportToCsv: selectedPatientsEvents?.length === 0,
    showDeleteCustomWorklist: showDeleteCustomWorklistMenuItem,
    showSaveAsCustomWorklist: !showDeleteCustomWorklistMenuItem,
    disableExportToActivator: selectedPatientsEvents?.length === 0,
  }).filter(i => i.condition);

  const onItemClick = (menuItemClicked) => {
    onPatientActionClicked(menuItemClicked.text);
  }

  return (
    <DropDownMenu idx={idx} items={generalActions} onItemClick={(item) => {
      onItemClick(item);
    }} />
  )
}

export default GeneralActions;