import React from 'react';
import ExportSummary from '../../../ExportSummary';
import Modals from '../../../../../components/modals';

import { useAssessmentsContext } from '../context/Assessments.context';

const componentName = 'ExportSummary';

function ExportSummaryModal() {
  const {
    exportData,
    clearExportData
  } = useAssessmentsContext();

  return (
    <Modals
      show={exportData.length > 0}
      onHide={() => clearExportData()}
      title='Export Summary'
      size="lg"
      hideClickButton={true}
      showExtraButton={false}
      hideFooter={false}
      closeBtnId={`${componentName}-closeButton`}
      body={<ExportSummary exportSummary={exportData || []} />}
    />
  )
}

export default ExportSummaryModal
