import React, { useContext } from "react";
import { Card, Accordion, AccordionContext, useAccordionButton, Button } from "react-bootstrap";

const componentName = "ItemsAccordion";

function PlusMinusToggle({ children, eventKey, callback, idx }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
      <i id={`${componentName}-plusMinusIcon-${idx}`} className={isCurrentEventKey ? "fa fa-chevron-down me-4" : "fa fa-chevron-right me-4"}></i>{" "}
      <span id={`${componentName}-itemTitle-${idx}`}>{children}</span>
    </Button>
  );
}

const ItemsAccordion = ({
  items,
  headerAndSelectorMap,
  selected,
  bodyBuilder,
  sizeAndSelectorMap,
}) => {
  const itemMap = {};
  const sizeMap = {};

  for (let key in headerAndSelectorMap) {
    itemMap[key] = items.filter((x) => headerAndSelectorMap[key](x));
  }

  if(sizeAndSelectorMap != null){
    for (let key in sizeAndSelectorMap) {
      sizeMap[key] = items.filter((x) => sizeAndSelectorMap[key](x)).length;
    }
  }

  return Object.entries(itemMap).map(([header, list], idx) => (
    <Accordion id={`${componentName}-item-${idx}`} defaultActiveKey={header}>
      <Card>
        <Card.Header id={`${componentName}-itemHead-${idx}`} eventKey={header}>
          <PlusMinusToggle eventKey={header} idx={idx}>
            {header} <span id={`${componentName}-itemCount-${idx}`} className="badge bg-secondary">{sizeAndSelectorMap == null ? list.length : sizeMap[header]}</span>
          </PlusMinusToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={header}>
          <Card.Body id={`${componentName}-itemInfo-${idx}`}>{bodyBuilder(header, list)}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  ));
};

export { ItemsAccordion };
