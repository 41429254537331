import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FILTER_ALL, getTasks } from '../../actions/caremgmt/myWorkspaceActions';
import { Filter } from '../filters/filterGroup';
import { dateRangeError, incompleteFilterError, noFilterError } from './errors';
import { checkRange, formatRangeDate, getFilterDef } from './utils';

const componentName = 'filterModal'

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  min-height: 250px;
`;

const ModalFooter = ({ setShow, handleSubmit }) => {
  return (
    <Modal.Footer>
      <Button
        id={`${componentName}-closeButton`}
        variant="secondary"
        onClick={() => setShow(false)}
      >
        Close
      </Button>
      <Button
        id={`${componentName}-submitButton`}
        variant="primary"
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
    </Modal.Footer>
  )
}

const ModalHeader = () => {
  return (
    <Modal.Header>
      <Modal.Title id={`${componentName}-modalFilter`}>
        Filters
      </Modal.Title>
    </Modal.Header>
  )
}

const FilterModal = ({
  show,
  setShow,
  storage,
  setFiltersCount,
  filters,
  setFilters,
  setError,
  setQuickList,
  setPagination,
  userAccountId = null
}) => {
  const handleSubmit = (filters) => {
    if (filters[0]?.paramValue?.length === 2) {
      const rangeDate = filters[0].paramValue.map(date => formatRangeDate(date));
      if (checkRange(rangeDate)) {
        getTasks(
          1,
          10,
          'TASKS_BETWEEN_DATES',
          "",
          "",
          rangeDate[0],
          rangeDate[1],
          userAccountId
        ).then(res => {
            setFiltersCount(filters.length)
            storage(res?.data?.taskList);
            setShow(false);
            setError([]);
            setQuickList(FILTER_ALL)
            setPagination({ pageNumber: res?.data?.pageNumber, pageSize: res?.data?.pageSize, totalSize: res?.data?.totalSize });
          }).catch(() => setShow(false));
      } else {
        setError([dateRangeError]);
      }
    }

    if (filters[0]?.paramValue?.length === 1) {
      setError([incompleteFilterError]);
    }

    if (filters[0]?.paramValue?.length === 0 || filters.length === 0) {
      setError([noFilterError]);
    }
  }

  return (
    <Modal
      size="lg"
      centered
      show={show}
      height={''}
    >
      <ModalHeader />
      <Modal.Body>
        <FilterGrid>
          <Filter
            selected={filters[0]}
            filterDefinition={getFilterDef("Time Range", { showRequired: true })}
            handleChange={(e) => setFilters(Object.assign([], filters, { [0]: e }))}
            refreshDataOnOpen={true}
          />
        </FilterGrid>
      </Modal.Body>
      <ModalFooter setShow={setShow} handleSubmit={() => handleSubmit(filters)} />
    </Modal>
  )
}

export default FilterModal;