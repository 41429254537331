import React, { useState, useCallback, useReducer } from "react";
import axios from "../../utils/apiClient";
import { AppPropertiesUtil, SessionStorageUtil } from "../../utils/localStorageUtil";
import { getProperties } from "../constantsActions";
import { Spinner } from "react-bootstrap";
const isEmpty = require("is-empty");
const useCboLink = (clientId, patient, referredToName) => {
  const [samlResponse, setSamlResponse] = useState({})
  const [communityUrl, setCommunityUrl] = useState({})

  const getSamlResponse = (thenTask) => {
    getUrls();
    let url = process.env.REACT_APP_REST_API_BASE_URL + "/v1/saml/";

    if (patient && patient.id && clientId) {
      url = `${url}response/?patientId=${patient.id}&clientId=${clientId}&type=REFERRAL&referredTo=${referredToName === 'Find Help' ? 'FindHelp' : 'UniteUs'}`
    } else {
      if (clientId) {
        url = `${url}response/?patientId=&clientId=${clientId}&type=ADMIN&referredTo=${referredToName === 'Find Help' ? 'FindHelp' : 'UniteUs'}`
      } else {
        url = `${url}response/?patientId=&clientId=AZEH&type=ADMIN&referredTo=${referredToName === 'Find Help' ? 'FindHelp' : 'UniteUs'}`
      }
    }

    axios.get(url, {
      headers: {},
    }).then((response) => {
      thenTask(response);
    }).catch(() => {
    });
  }


  const getUrls = () => {
    if (referredToName) {
      const urlForUniteUs = AppPropertiesUtil.get("ce.saml.uniteus.subject-recipient");
      const urlForFindHelp = AppPropertiesUtil.get("ce.saml.findhelp.subject-recipient");
      if (referredToName == 'Uniteus') {
        if (!urlForUniteUs) {
          initProperties("ce.saml.uniteus.subject-recipient")
        } else {
          setCommunityUrl(urlForUniteUs)
        }
      }
      if (referredToName == 'Find Help') {
        if (urlForFindHelp) {
          initProperties("ce.saml.findhelp.subject-recipient")
        } else {
          setCommunityUrl(urlForFindHelp)
        }
      }
    }
  }

  const initProperties = async (org) => {
    let res = await getProperties();
    SessionStorageUtil.setObj("properties", res.data);
    if (res.data) {
      setCommunityUrl(res.data[org])
    }
  }

  const referComponent = useCallback(() => {
    if (isEmpty(samlResponse)) {
      return <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }
    return (
      <form
        method="post"
        action={communityUrl}
        target="_blank">
        <input type="textarea" name="SAMLResponse" hidden id="SAMLResponse" value={samlResponse.samlResponse} readOnly />
        <input type="text" hidden name="RelayState" value={samlResponse.relayState} readOnly />
        <input type="submit" value={referredToName === 'Find Help' ? 'Find Help' : 'UniteUs'} className="bg-transparent text-primary border-0 p-0 cursor-p" />
      </form>
    );

  });

  return [referComponent, getSamlResponse, setSamlResponse]
};

export default useCboLink;