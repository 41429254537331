import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    Form,
    Table
} from "react-bootstrap";
import {getZipCodes, getZones, postZone, putZone} from "./util";
import {handleError} from "../../../../actions/caremgmt/myWorkspaceActions";
import EQHErrorToast from "../../../../components/EQHErrorToast";
import {DropDownList} from "../../../../components/dropDownLists/genericDDL";
import Spinner from "react-bootstrap/Spinner";

const componentName = "ZoneManager";
const isEmpty = require("is-empty");

export const ZoneManager = ({ refresh }) => {
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState({});
    const [isEditingZone, setEditingZone] = useState(false);
    const [showZoneModal, setShowZoneModal] = useState(false);
    const [zipCodeOptions, setZipCodeOptions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const onError = (err) => {
        const error = handleError(err)
        setErrors([error]);
    };

    const handleCloseZoneModal = () => {
        setErrors([]);
        setShowZoneModal(false);
        setSelectedZone({});
        setEditingZone(false);
    }
    const handleShowZoneModal = (editingZone) => {
        if(!isEmpty(editingZone)) {
            setEditingZone(true);
            setSelectedZone({...editingZone, zipCodes: transformToDropdown(editingZone.zipCodes)});
        }
        setShowZoneModal(true);
    };

    const transformToDropdown = (list) => {
        let zipDispArr = [];
        let zipValpArr = [];
        list.forEach((zip)=>{
            zipDispArr.push(zip.value);
            zipValpArr.push(zip.id);
        });
        return {comparison:"eq", displayValue: zipDispArr, paramName: "Zip Codes", paramValue: zipValpArr};
    }

    const handleSaveZone = () => {
        setErrors([]);
        if (isZoneFormValid()){
            setLoading(true);
            const zipCodeValues = selectedZone.zipCodes?.paramValue;
            const param = {...selectedZone, zipCodeIds: zipCodeValues};
            let savePromise;
            if(isEditingZone){
                savePromise = putZone(param);
            } else {
                savePromise = postZone(param);
            }
            savePromise.then(() => {
                fetchZones();
                refresh();
                handleCloseZoneModal();
            }).catch((error) => {
                setLoading(false);
                onError(error);
            });
        }else{
            setErrors(["Some mandatory fields are empty"]);
        }
    };

    const isZoneFormValid = () => {
      return !isEmpty(selectedZone.zoneName)  || !isEmpty(selectedZone.zipCodes);
    };

    const handleSearchZipCodes = () => {
        return Promise.resolve(zipCodeOptions);
    };

    const handleZipCodeChanged = (e) => {
        setSelectedZone({...selectedZone, zipCodes: e});
    }

    const zoneNameChanged = (e) =>{
        const name = e?.target?.value;
        setSelectedZone({...selectedZone, zoneName: name});
    };

    const fetchZipCodes = () => {
        getZipCodes().then((resp) => {
            if (resp?.data) {
                setZipCodeOptions(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });
    }

    const fetchZones = () => {
        setLoading(true);
        getZones().then((resp) => {
            if (resp?.data) {
                setLoading(false);
                setZones(resp.data);
            }
        }).catch((error) => {
            setLoading(false);
            onError(error);
        });
    }

    useEffect(() => {
        fetchZones();
    }, []);

    useEffect(() => {
        fetchZipCodes();
    }, [showZoneModal]);


    return (<>
            <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
            <Row>
                <Col>
                    <Button id={`${componentName}-createZone`} variant="secondary" onClick={() => handleShowZoneModal()} className="mb-3">
                        Create Zone
                    </Button>
                </Col>
            </Row>
            <div className="table-responsive text-nowrap">
                {isLoading && isEmpty(errors) && <Spinner animation="border" variant="secondary" size="lg" />}
                {!isLoading && <Table responsive bordered hover>

                    <thead>
                    <tr>
                        <th scope="col">
                        </th>
                        <th scope="col">
                            Zone
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {zones && zones.map((z, idx) => {
                        return (
                            <tr key={`${z.value}${idx}`}>
                                <th>
                                    <Button id={`${componentName}-zoneEditBtn-${idx}`} variant="link" className="p-0 ms-2" onClick={() => handleShowZoneModal(z)}>
                                        <i className="fa fa-edit"></i>
                                    </Button>
                                </th>
                                <td id={`${componentName}-program-${idx}`}>
                                    {z.zoneName}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>}

            </div>

            <Modal size="lg" centered show={showZoneModal} onHide={handleCloseZoneModal}>

                <Modal.Header closeButton>
                    <Modal.Title>{isEditingZone ? 'Edit' : 'Add'} Zone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="mb-3" md={6}>
                            <label id={`${componentName}-zoneLabel`} className="d-block">Zone Name</label>
                            <Form.Control id={`${componentName}-zoneValue`} type="text"
                                          value={selectedZone.zoneName} disabled={isLoading}
                                          onChange={zoneNameChanged} required={true}
                            />
                        </Col>
                        <Col className="mb-3" md={6}>
                                <DropDownList showClearButton={false} placeholder="Zip Codes" disabled={isLoading}
                                              header="Zip Codes" handleChange={handleZipCodeChanged} showRequired={true}
                                              getData={handleSearchZipCodes} selected={selectedZone.zipCodes}
                                />
                        </Col>
                    </Row>
                </Modal.Body>
                    <Modal.Footer>
                    <Button id={`${componentName}-programLeadsCloseBtn`} variant="secondary" onClick={handleCloseZoneModal}>
                        Close
                    </Button>
                    <Button disabled={isLoading} id={`${componentName}-programLeadsSaveBtn`} variant="primary" onClick={handleSaveZone}>
                        Save Changes {isLoading &&<Spinner animation="border" variant="secondary" size="sm" />}
                    </Button>
                </Modal.Footer>
                    </Modal>
        </>)
}