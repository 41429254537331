import {
    GET_MANAGE_VISIT_PATIENT,
    SET_MANAGE_VISIT_PATIENT,
    SET_RELOAD_PATIENT_LIST,
    SET_SHOW_FULL_HISTORY,
    SET_MANAGE_VISIT_OPENED_FROM,
    SET_PATIENT_LIST_TYPE,
    SET_CARE_PROGRAM_MODAL,
    SET_RELOAD_WORKLIST,
    SET_HIDE_PATIENT_DETAILS_MODAL,
    SET_MODAL_OPEN,
    SET_USER_EVENT_ACTION,
    SET_ADD_NEW_ASSESSMENT,
    SET_ADD_NEW_APPOINTMENT,
    SET_MINIMIZED_PATIENTS,
    REMOVE_MINIMIZED_PATIENT,
    SET_ADD_NEW_NOTE,
    SET_OLD_PATIENT_MODAL,
    GET_MINIMIZED_PATIENTS,
    SET_SNOOZED_MODAL,
    SET_STEP,
    SET_PCP_ID,
    SET_CUSTOM_STATUS
}
    from '../actions/types';
import axios from '../utils/apiClient';

const initialState = {
    patient: null,
    showFullHistory: false,
    reload: false,
    openedFromPageTitle: null,
    reloadWorklist: false,
    hidePatientDetailsModal: false,
    modalOpen: false,
    userEventAction: null,
    minimizedPatients: [],
    isAddingNewNote: false,
    showOldPatientModal: false,
    snoozedModal: false,
    setstep: {},
    listType: '',
    pcpID: {},
    customStatus: {}
};

export default function manageVisitReducer(state = initialState, action) {
    const { type, payload } = action;
    const url = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-profile/open-patient-drawers";

    switch (type) {
        case SET_MANAGE_VISIT_PATIENT:
            return { ...state, patient: payload };
        case GET_MANAGE_VISIT_PATIENT:
            return state.patient;
        case SET_RELOAD_PATIENT_LIST:
            return { ...state, reload: payload }
        case SET_SHOW_FULL_HISTORY:
            return { ...state, showFullHistory: payload }
        case SET_MANAGE_VISIT_OPENED_FROM:
            return { ...state, openedFromPageTitle: payload }
        case SET_PATIENT_LIST_TYPE:
            return { ...state, listType: payload }
        case SET_CARE_PROGRAM_MODAL:
            return { ...state, isCareProgramModalOpen: payload }
        case SET_RELOAD_WORKLIST:
            return { ...state, reloadWorklist: payload }
        case SET_HIDE_PATIENT_DETAILS_MODAL:
            return { ...state, hidePatientDetailsModal: payload }
        case SET_SNOOZED_MODAL:
            return { ...state, snoozedModal: payload }
        case SET_CUSTOM_STATUS:
            return { ...state, customStatus: payload }
        case SET_STEP:
            return { ...state, step: payload }
        case SET_MODAL_OPEN:
            return { ...state, modalOpen: payload}
        case SET_USER_EVENT_ACTION:
            return { ...state, userEventAction: payload}
        case SET_ADD_NEW_ASSESSMENT:
            return { ...state, addNewAssessment: payload}
        case SET_ADD_NEW_APPOINTMENT:
            return { ...state, addNewAppointment: payload}
        case SET_ADD_NEW_NOTE:
            return { ...state, isAddingNewNote: payload}
        case SET_OLD_PATIENT_MODAL:
            return { ...state, showOldPatientModal: payload}
        case SET_OLD_PATIENT_MODAL:
            return { ...state, showOldPatientModal: payload}
        case SET_PCP_ID:
            return { ...state, pcpID: payload}
        case SET_MINIMIZED_PATIENTS:
            const isNewPatient = state.minimizedPatients.filter(p => p.id == payload.id).length === 0;
            var newState = { ...state };
            if (isNewPatient) {
                newState = { ...state, minimizedPatients: [...state.minimizedPatients, {id: payload.id, fullName: payload.fullName }]}
                axios.put(url, newState.minimizedPatients, {
                    headers: {},
                })
            }
            return newState;
        case GET_MINIMIZED_PATIENTS:
            return { ...state, minimizedPatients: payload };
        case REMOVE_MINIMIZED_PATIENT:
            newState = { ...state, minimizedPatients: [...state.minimizedPatients.filter(p => p.id != payload.id)] };
            axios.put(url, newState.minimizedPatients, {
                headers: {},
            })
            return newState;
        default:
            return state;
    }
}