import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, {useEffect, useState, useRef} from "react";
import {searchProgramLead} from "../../actions/caremgmt/hubWorkCenterAction";

const componentName = "AssignCareManagerModal";

const AssignCareManagerModal = ({
    show,
    onClose,
    onAssign,
    onError,
    teams,
    programs,
    selectedName
}) => {
    const [assignment, setAssignment] = useState({action: "ASSIGN", openCarePath: true});
    const [isLoading, setIsLoading] = useState(false);
    const [programLeads, setProgramLeads] = useState([]);
    const [team, setTeam] = useState({});
    const [createCarePath, setCreateCarePath] = useState(true);
    const ref = useRef();

    const asyncSearch = async (name) => {
        const timeout = setTimeout(async () => {
            if (name) {
                setIsLoading(true)
                searchProgramLead(name, team.teamId)
                    .then(response => {
                        setProgramLeads(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        onError(error)
                        setIsLoading(false)
                    })
            }
        }, 1600);

        return () => clearTimeout(timeout);
    };

    const onChangeTeam = (event) => {
        const team = teams.find((team) => team.teamId == event.target?.value);
        setTeam(team);
    }

    const onChangeProgramLead = (event) => {
        if (event && event[0]) {
            const programLead = event[0];
            setAssignment({
                ...assignment,
                programLeadId: programLead.value
            });
        }
    }

    const onChangeProgram = (event) => {
        const program = programs?.find(program => program.value == event.target?.value);
        if (program) {
            setAssignment({
                ...assignment,
                programId: program.value,
                programName: program.text
            });
        }
    }

    const onChangeNotes = (event) => {
        setAssignment({
            ...assignment,
            notes: event.target.value
        });
    }

    const onChangeCarePath = (e) => {
        setAssignment({
            ...assignment,
            openCarePath: e.target.checked
        })
    }

    const assign = () => {
        if (isValidAssignment()) {
            onAssign(assignment)
        }
    }

    const isValidAssignment = () => {
        if (assignment.programLeadId && assignment.programId) {
            return true
        }
        onError("Some mandatory fields are empty")
    }

    const resetAssignment = () => {
        setAssignment({action: "ASSIGN", openCarePath: true})
        setTeam({})
    }

    useEffect(() => {
        resetAssignment()
    },[show])

    return (
        <Modal size="lg" centered show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title id={`${componentName}-assignModalTitle`} className='forced-capital'>Enroll {selectedName === '' ? 'Member' : selectedName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="mb-3">
                        <label id={`${componentName}-assignTeamLabel`} className="d-block">
                            Team<span className="text-danger">*</span></label>
                        <select id={`${componentName}-assignTeamValue`} name="team" value={team.teamId}
                                className="form-select"
                                required={true}
                                onChange={event => onChangeTeam(event)}>
                            <option id={`${componentName}-assignTeamItem`} value=""></option>
                            {teams && teams.length && teams.map((el, i) =>
                                <option id={`${componentName}-assignTeamItem-${i}`} key={`${componentName}-assignTeamItem-${i}-key`}
                                        value={el.teamId}>{el.teamName}</option>
                            )}
                        </select>
                    </Col>
                    <Col className="mb-3" onBlur={() => !assignment.programLeadId && ref.current?.clear()}>
                        <label id={`${componentName}-assignProgramLeadLabel`} className="d-block">
                            Program Lead<span className="text-danger">*</span></label>
                        <AsyncTypeahead
                            id={`${componentName}-assignProgramLeadValue`}
                            labelKey="text"
                            isLoading={isLoading}
                            onSearch={asyncSearch}
                            options={programLeads}
                            onChange={event => onChangeProgramLead(event)}
                            placeholder="Search Members by Name"
                            ref={ref}
                        />
                    </Col>
                    <Col className="mb-3">
                        <label id={`${componentName}-assignProgramLabel`} className="d-block">
                            Program<span className="text-danger">*</span></label>
                        <select id={`${componentName}-assignProgramValue`} name="program" value={assignment.programId}
                                className="form-select"
                                required={true}
                                onChange={event => onChangeProgram(event)}>
                            <option id={`${componentName}-assignProgramItem`} value=""></option>
                            {programs && programs.length && programs.map((el, i) =>
                                <option id={`${componentName}-assignProgramItem-${i}`} value={el.value}>{el.text}</option>
                            )}
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-12">
                        <label id={`${componentName}-assignProgramNotesLabel`} className="d-block">Notes</label>
                        <Form.Control id={`${componentName}-assignProgramNotesValue`} type="text" onChange={onChangeNotes} />
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-12">
                      <Form.Check
                        type="checkbox"
                        id={`${componentName}-createCarePathCheckbox`}
                        onChange={onChangeCarePath}
                        checked={assignment?.openCarePath}
                        label='Open Care Path'
                      />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button id={`${componentName}-assignCancelBtn`} variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button id={`${componentName}-assignAssignBtn`} variant="primary" onClick={assign}>
                    Assign
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignCareManagerModal