import { disPatchError } from "./utils"
import {
   GET_FILE, RESET_ERRORS, UPDATE_SUCCESS, UPLOAD_FILE, CLEAR_STATE
} from "./types";
import axios from "../utils/apiClient";
export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/file-upload";
export const GAP_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/gap-documentation-admin";


export const getFile = (id) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + `/file?id=${id}`, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      dispatch({
        type: GET_FILE,
        payload: Buffer.from(res.data, 'binary').toString('base64'),
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};
export const updateFile = (fileData, fileName) => (dispatch) => {
  // const fileName =fileData.name;
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + `/file`, fileData, {
      headers: {
        "Content-Type": "application/json",
        "X-Filename": fileName
      },
    })
    .then((res) => {
      dispatch({
        type: UPLOAD_FILE,
        payload: res.data,
      });
      dispatch({
        type: UPDATE_SUCCESS,
        payload: "Successfully Updated",
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

export const updatePreventiveFile = (fileData, fileName) => (dispatch) => {
  // const fileName =fileData.name;
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + `/fileUpload`, fileData, {
      headers: {
        "Content-Type": "application/json",
        "X-Filename": fileName
      },
    })
    .then((res) => {
      dispatch({
        type: UPLOAD_FILE,
        payload: res.data,
      });
      dispatch({
        type: UPDATE_SUCCESS,
        payload: "Successfully Updated",
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

export const getFileDownload = (id, filename) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + `/file?id=${id}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      var type = response.headers["content-type"];
      var blob = new Blob([response.data], { type });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      //var endIndex = downLink.length; //Check if '- 1' is necessary
      // var filename = downLink.substring(14, endIndex);
      link.download = filename;
      link.click();
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getFileDownloadGeneric = (url, filename) => {
  axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      var type = response.headers["content-type"];
      var blob = new Blob([response.data], { type });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      //var endIndex = downLink.length; //Check if '- 1' is necessary
      // var filename = downLink.substring(14, endIndex);
      link.download = filename;
      link.click();
    })
    .catch();
};

export const getGapDocuemnt = (id, filename) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
      .get(GAP_URL + `/gapDocument?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        var type = response.headers["content-type"];
        var blob = new Blob([response.data], { type });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //var endIndex = downLink.length; //Check if '- 1' is necessary
        // var filename = downLink.substring(14, endIndex);
        link.download = filename + ".pdf";
        link.click();
      })
      .catch((err) => disPatchError(err, dispatch));
};
export const getPdfFile = (id, filename) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + `/file?id=${id}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      var type = "application/pdf";
      var blob = new Blob([response.data], { type });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "blank";
      link.click();
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const clearFileUploads = () => (dispatch) => {
  dispatch({ type: CLEAR_STATE });
}
