import React, { useEffect, useState } from "react";
import {
  deleteAssessmentTemplate,
  saveAssessmentTemplate,
  useGetAssessmentFormTemplates,
} from "../../actions/assessmentTemplate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { Button as OurButton } from "../../components/buttons";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { MultiComponent } from "../../components/MultiComponent";
import { Button as EQHButton } from "../../components/buttons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import cloneDeep from "lodash/cloneDeep";
import { eqhUUID, getKeyAndId, isObjEmpty, uuid, getDisplayValue } from "../../utils/util";
import { Filter } from "../../components/filters/filterGroup";
import { GENERIC_DDL_TYPE } from "../../components/dropDownLists/genericDDL";
import EQHErrorToast from "../../components/EQHErrorToast";
import ReactAlert from "../../components/ReactAlert";
import { FilterParam, simpleOneFilterParam } from "../../dtos/PatientSearchDTO";
import { useAuth } from "../../context/authContext";
import {stateFullName} from "../../utils/textValueLists";

const isEmpty = require("is-empty");
const componentName = "AssessmentTemplate";

const assessmentTemplateReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const MOVE_TYPES = {
  MoveBefore: "Move Before",
  MoveAfter: "Move After",
  Swap: "Swap",
};

const QUESTION_TYPES = {
  MCSingleAnswer: "SINGLE_ANSWER",
  MCMultipleAnswer: "MULTIPLE_ANSWER",
  FreeForm: "FREE_FORM",
};

const DEFAULT_ANSWERS = [
  {
    text: "",
    score: 0,
  },
  {
    text: "",
    score: 0,
  },
];

const getDefaultAnswers = () => {
  return cloneDeep(DEFAULT_ANSWERS);
};

const DEFAULT_QUESTION = {
  questionType: QUESTION_TYPES.MCSingleAnswer,
  answerType: "NUMERICAL",
  questionText: "",
  description: "",
  conditions: [],
  applyModificator: false,
  required: false,
};

const getDefaultQuestion = () => {
  return cloneDeep({
    ...DEFAULT_QUESTION,
    id: eqhUUID(),
    answers: getDefaultAnswers(),
  });
};

const DEFAULT_CONDITION = { rule: { type: "BLANK" } };

const getDefaultCondition = (type = "AND") => {
  return cloneDeep({ ...DEFAULT_CONDITION, type: type });
};

const DEFAULT_SCORE = { operation: "SUM", questionsIds: [] };

const getDefaultScore = () => {
  return cloneDeep({ ...DEFAULT_SCORE });
};

const NEW_TEMPLATE_ID = "new";

const DEFAULT_TEMPLATE = {
  id: NEW_TEMPLATE_ID,
  conditionalLogicAllowed: true,
  questions: [],
  scores: [],
  title: null,
  activeForm: false,
  publicForm: false,
};

const getDefaultTemplate = (name, clientID) => {
  const defaultTemp = cloneDeep({
    ...DEFAULT_TEMPLATE,
    name: name,
    clientID: clientID,
    newTemplate: true,
  });
  return defaultTemp;
};

const SCORE_OPERATIONS = ["SUM", "MIN", "MAX", "COUNT", "AVERAGE"];

const QuestionCard = ({
  question,
  questionIdx,
  questionsFilterValues,
  onChange,
  setErrors,
}) => {
  const getAvailableMoveTypes = () => {
    const availableMoveTypes = {};
    if (questionIdx > 0) {
      availableMoveTypes[MOVE_TYPES.MoveBefore] = MOVE_TYPES.MoveBefore;
    }
    if (questionIdx + 1 < questionsFilterValues.length) {
      availableMoveTypes[MOVE_TYPES.MoveAfter] = MOVE_TYPES.MoveAfter;
    }
    if (questionsFilterValues.length > 1) {
      availableMoveTypes[MOVE_TYPES.Swap] = MOVE_TYPES.Swap;
    }
    return availableMoveTypes;
  };
  const [moveType, setMoveType] = useState(
    () =>
      getAvailableMoveTypes()[MOVE_TYPES.MoveBefore] ??
      getAvailableMoveTypes()[MOVE_TYPES.MoveAfter] ??
      getAvailableMoveTypes()[MOVE_TYPES.Swap] ??
      ""
  );
  const [movePosition, setMovePosition] = useState(0);
  const [condLogicShowModal, setCondLogicShowModal] = useState();
  const [queueConditions, setQueueConditions] = useState([]);
  const [queueCondShowHide, setQueueCondShowHide] = useState(
    question?.applyModificator ?? false
  );

  const availableMoveTypes = getAvailableMoveTypes();

  const handleConditionChange = (idx, key, value) => {
    const queueConditionsClone = [...queueConditions];
    switch (key) {
      case "conditionQuestionId":
        queueConditionsClone[idx][key] = value;
        break;
      case "rule-type":
      case "rule-value":
        queueConditionsClone[idx].rule[key.split("-")[1]] = value;
        break;
      case "deleteCondition":
        queueConditionsClone.splice(idx, 1);
        break;
      case "addCondition":
        queueConditionsClone.push(getDefaultCondition(value));
        break;
      default:
        break;
    }
    setQueueConditions(queueConditionsClone);
  };

  const openConditionalLogic = () => {
    const conditionsClone = cloneDeep(question.conditions ?? []);
    if (!conditionsClone.length) {
      conditionsClone.push(getDefaultCondition());
    }
    setQueueConditions(conditionsClone);
    setQueueCondShowHide(question.applyModificator);
    setCondLogicShowModal(true);
  };

  const submitConditionalLogic = () => {
    const errors = [];
    queueConditions.forEach((x, idx) => {
      if (x.rule.type === "VALUE" && !x.rule.value) {
        errors.push(
          `Condition ${
            idx + 1
          } is incomplete. Please fill in a value in the textbox.`
        );
      }
      if (!x.conditionQuestionId) {
        errors.push(
          `Condition ${idx + 1} is incomplete. Please select a question.`
        );
      }
    });
    // if (!newTemplateName) {
    //   errors.name =
    //     "Name must have a value. Please fill in a value for the Name field.";
    // }

    if (isObjEmpty(errors)) {
      const conditionsClone = [...queueConditions];
      onChange("conditions", {
        conditions: conditionsClone,
        applyModificator: queueCondShowHide,
      });
      setQueueConditions([]);
      setQueueCondShowHide(false);
      setCondLogicShowModal(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card.Body {...getKeyAndId(`${componentName}-${question.id}-cardBody`)}>
      <Row className="mb-3">
        <Col>
          <Form.Check
            id={`${componentName}-${question.id}-required-ckbox`}
            type="checkbox"
            label="Required"
            checked={question.required}
            onChange={(e) => onChange("required", e.target.checked)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="9">
          <Form.Control
            id={`${componentName}-${question.id}-questionText-ta`}
            as="textarea"
            value={question.questionText}
            onChange={(e) => onChange("questionText", e.target.value)}
            rows={5}
          ></Form.Control>
        </Col>
        <Col>
          <ButtonGroup aria-label="Basic example" vertical className="w-100">
            <div
              id={`${componentName}-${question.id}-singleAnswer-div`}
              className="w-100 mb-2"
              onClick={(e) =>
                onChange("questionType", QUESTION_TYPES.MCSingleAnswer)
              }
            >
              <Button
                id={`${componentName}-${question.id}-singleAnswer-btn`}
                variant="secondary"
                className={
                  question.questionType === QUESTION_TYPES.MCSingleAnswer
                    ? "text-dark w-100"
                    : "text-muted w-100"
                }
              >
                <i className="fas fa-dot-circle"></i> Multiple Choice
              </Button>
            </div>
            <div
              id={`${componentName}-${question.id}-multipleAnswer-div`}
              className="w-100 mb-2"
              onClick={(e) =>
                onChange("questionType", QUESTION_TYPES.MCMultipleAnswer)
              }
            >
              <Button
                id={`${componentName}-${question.id}-multipleAnswer-btn`}
                variant="secondary"
                className={
                  question.questionType === QUESTION_TYPES.MCMultipleAnswer
                    ? "text-dark w-100"
                    : "text-muted w-100"
                }
              >
                <i className="far fa-check-square"></i> Multiple Answer
              </Button>
            </div>
            <div
              id={`${componentName}v-freeForm-div`}
              className="w-100 mb-2"
              onClick={(e) => onChange("questionType", QUESTION_TYPES.FreeForm)}
            >
              <Button
                id={`${componentName}-${question.id}-freeForm-btn`}
                variant="secondary"
                className={
                  question.questionType === QUESTION_TYPES.FreeForm
                    ? "text-dark w-100"
                    : "text-muted w-100"
                }
              >
                <i className="far fa-align-justify"></i> Free-form Answer
              </Button>
            </div>
          </ButtonGroup>
        </Col>
      </Row>
      {(question.questionType === QUESTION_TYPES.MCSingleAnswer ||
        question.questionType === QUESTION_TYPES.MCMultipleAnswer) && (
        <Row>
          <MultiComponent
            defaultAddValue={{ text: "", score: 0 }}
            onChange={(values) => onChange("answers", values)}
            addLabel="Add Answer"
            value={
              isEmpty(question.answers) ? getDefaultAnswers() : question.answers
            }
            showDeleteButtonOnSide={true}
            idPrefix={`${componentName}-${question.id}`}
            itemBuilder={(value, handleChange, index) => {
              return (
                <Row
                  {...getKeyAndId(
                    `${componentName}-${question.id}-${index}-answer-row`
                  )}
                >
                  <Col>
                    <Form.Control
                      {...getKeyAndId(
                        `${componentName}-${question.id}-${index}-answer-text`
                      )}
                      type="text"
                      className="mb-3"
                      value={value.text}
                      placeholder={value.text ? "" : "Answer"}
                      onChange={(e) => {
                        value.text = e.target.value;
                        handleChange(value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      {...getKeyAndId(
                        `${componentName}-${question.id}-${index}-answer-score`
                      )}
                      type="text"
                      className="mb-3"
                      value={value.score ?? 0}
                      onChange={(e) => {
                        const val = Number(e.target.value);
                        if (!Number.isNaN(val)) {
                          value.score = val;
                          handleChange(value);
                        }
                      }}
                    />
                  </Col>
                </Row>
              );
            }}
          />
        </Row>
      )}
      <hr />
      <Row>
        <Col>
          <ButtonGroup size="sm" className="d-inline-block bg-white align-top">
            {questionIdx > 0 && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Conditional Question Logic</Tooltip>}
              >
                <Button
                  id={`${componentName}-${question.id}-conditionLogic-btn`}
                  onClick={(e) => openConditionalLogic()}
                  variant="outline-secondary"
                  size="sm"
                >
                  <i className="fas fa-cogs fa-lg align-middle"></i>
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete Question</Tooltip>}
            >
              <Button
                id={`${componentName}-${question.id}-deleteQuestion-btn`}
                onClick={(e) => onChange("deleteQuestion", question)}
                variant="outline-secondary"
                size="sm"
              >
                <i className="fas fa-trash-alt fa-lg align-middle"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Copy Question</Tooltip>}
            >
              <Button
                id={`${componentName}-${question.id}-copyQuestion-btn`}
                onClick={(e) => onChange("copyQuestion")}
                variant="outline-secondary"
                size="sm"
              >
                <i className="far fa-copy fa-lg align-middle"></i>
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Col>
        <Col>
          <div className="d-flex flex-nowrap justify-content-end">
            <div className="me-2">
              <Form.Control
                id={`${componentName}-${question.id}-moveType-ddl`}
                as="select"
                value={moveType}
                onChange={(e) => setMoveType(e.target.value)}
              >
                {availableMoveTypes[MOVE_TYPES.MoveBefore] && (
                  <option
                    {...getKeyAndId(
                      `${componentName}-${question.id}-moveType-moveBefore-option`
                    )}
                    value={MOVE_TYPES.MoveBefore}
                  >
                    {MOVE_TYPES.MoveBefore}
                  </option>
                )}
                {availableMoveTypes[MOVE_TYPES.MoveAfter] && (
                  <option
                    {...getKeyAndId(
                      `${componentName}-${question.id}-moveType-moveAfter-option`
                    )}
                    value={MOVE_TYPES.MoveAfter}
                  >
                    {MOVE_TYPES.MoveAfter}
                  </option>
                )}
                {availableMoveTypes[MOVE_TYPES.Swap] > 1 && (
                  <option
                    {...getKeyAndId(
                      `${componentName}-${question.id}-moveType-moveSwap-option`
                    )}
                    value={MOVE_TYPES.Swap}
                  >
                    {MOVE_TYPES.Swap}
                  </option>
                )}
              </Form.Control>
            </div>
            <div className="me-2">
              <Form.Control
                id={`${componentName}-${question.id}-movePosition-ddl`}
                as="select"
                value={movePosition}
                onChange={(e) => setMovePosition(e.target.value)}
              >
                {questionsFilterValues
                  .filter(
                    (x) =>
                      x.idx !== questionIdx &&
                      ((moveType === MOVE_TYPES.MoveBefore &&
                        x.idx !== questionIdx + 1) ||
                        moveType !== MOVE_TYPES.MoveBefore) &&
                      ((moveType === MOVE_TYPES.MoveAfter &&
                        x.idx !== questionIdx - 1) ||
                        moveType !== MOVE_TYPES.MoveAfter)
                  )
                  .map((x, idx) => (
                    <option
                      {...getKeyAndId(
                        `${componentName}-${question.id}-${idx}-movePosition-option`
                      )}
                      value={x.idx}
                    >
                      {x.text}
                    </option>
                  ))}
              </Form.Control>
            </div>
            <div>
              <Button
                id={`${componentName}-${question.id}-commitQuestionMove-btn`}
                variant="secondary"
                onClick={(e) => onChange("moveType", [moveType, movePosition])}
              >
                Commit
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        onHide={() => setCondLogicShowModal(false)}
        show={condLogicShowModal}
        size="xl"
        screenbool={true}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Conditional Question Logic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <label className="d-block">Current Question</label>
                <Form.Control
                  id={`${componentName}-${question.id}-conditionShowHide-ddl`}
                  as="select"
                  value={queueCondShowHide}
                  onChange={(e) => setQueueCondShowHide(e.target.value)}
                >
                  <option
                    {...getKeyAndId(
                      `${componentName}-${question.id}-conditionShow-option`
                    )}
                    value="true"
                  >
                    Show
                  </option>
                  <option
                    {...getKeyAndId(
                      `${componentName}-${question.id}-conditionHide-option`
                    )}
                    value="false"
                  >
                    Hide
                  </option>
                </Form.Control>
              </Col>
              <Col>
                <label className="d-block">this question if ...</label>
              </Col>
            </Row>
            <Row>
              <Col>
                {queueConditions &&
                  queueConditions.map((x, condIdx) => (
                    <>
                      {condIdx > 0 && (
                        <Row className="mb-3">
                          <Col>
                            <label
                              id={`${componentName}-${question.id}-${condIdx}-andOr-str`}
                              className="me-1"
                              style={{
                                color:
                                  x.type === "AND"
                                    ? "green"
                                    : x.type === "OR"
                                    ? "red"
                                    : "",
                              }}
                            >
                              {x.type}
                            </label>
                            <i
                              id={`${componentName}-${question.id}-${condIdx}-deleteCondition-icon`}
                              className="fas fa-times-circle cursor-p"
                              onClick={() =>
                                handleConditionChange(
                                  condIdx,
                                  "deleteCondition"
                                )
                              }
                            ></i>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            label="An earlier questions was left blank..."
                            checked={x.rule?.type === "BLANK"}
                            name={`conditions-${condIdx}`}
                            type="radio"
                            id={`${componentName}-${question.id}-${condIdx}-condition1-radio`}
                            onChange={(e) =>
                              handleConditionChange(
                                condIdx,
                                "rule-type",
                                e.target.checked ? "BLANK" : null
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            label="An earlier questions was left not blank..."
                            checked={x.rule?.type === "NOT_BLANK"}
                            name={`conditions-${condIdx}`}
                            type="radio"
                            id={`${componentName}-${question.id}-${condIdx}-condition2-radio`}
                            onChange={(e) =>
                              handleConditionChange(
                                condIdx,
                                "rule-type",
                                e.target.checked ? "NOT_BLANK" : null
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="auto">
                          <Form.Check
                            inline
                            label="An earlier questions contained a specific value:"
                            checked={x.rule?.type === "VALUE"}
                            name={`conditions-${condIdx}`}
                            type="radio"
                            id={`${componentName}-${question.id}-${condIdx}-condition3-radio`}
                            onChange={(e) =>
                              handleConditionChange(
                                condIdx,
                                "rule-type",
                                e.target.checked ? "VALUE" : null
                              )
                            }
                          />
                        </Col>
                        <Col>
                          {x.rule?.type === "VALUE" && (
                            <Form.Control
                              type="text"
                              className="mb-3"
                              value={x.rule?.value ?? ""}
                              id={`${componentName}-${question.id}-${condIdx}-condition3Value-text`}
                              onChange={(e) =>
                                handleConditionChange(
                                  condIdx,
                                  "rule-value",
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <Form.Control
                            as="select"
                            value={x.conditionQuestionId ?? "null"}
                            id={`${componentName}-${question.id}-${condIdx}-conditionQuestionIds-ddl`}
                            onChange={(e) =>
                              handleConditionChange(
                                condIdx,
                                "conditionQuestionId",
                                e.target.value
                              )
                            }
                          >
                            <option
                              {...getKeyAndId(
                                `${componentName}-conitionQuestionIds-select-option`
                              )}
                              value={"null"}
                            >
                              --Select--
                            </option>
                            {questionsFilterValues
                              .filter((x) => x.idx < questionIdx)
                              .map((x, idx) => (
                                <option
                                  {...getKeyAndId(
                                    `${componentName}-${question.id}-${idx}-conitionQuestionIds-option`
                                  )}
                                  value={x.id}
                                >
                                  {x.questionText}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </>
                  ))}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <EQHButton
            id={`${componentName}-${question.id}-addConditionOR-btn`}
            onClick={() => handleConditionChange(null, "addCondition", "OR")}
          >
            OR
          </EQHButton>
          <EQHButton
            id={`${componentName}-${question.id}-addConditionAND-btn`}
            onClick={() => handleConditionChange(null, "addCondition", "AND")}
          >
            AND
          </EQHButton>
          <EQHButton
            id={`${componentName}-${question.id}-conditionClose-btn`}
            onClick={() => setCondLogicShowModal(false)}
          >
            Close
          </EQHButton>
          <EQHButton
            id={`${componentName}-${question.id}-conditionSubmit-btn`}
            onClick={submitConditionalLogic}
          >
            Submit
          </EQHButton>
        </Modal.Footer>
      </Modal>
    </Card.Body>
  );
};

const AssessmentTemplate = ({}) => {
  const auth = useAuth();
  const inputSources = auth.getInputSources().sort();
  const assessmentTemplatePkg = useGetAssessmentFormTemplates(
    null,
    assessmentTemplateReducer
  );
  const [templateQueue, setTemplateQueue] = useState(null);
  const [isTemplateDirty, setIsTemplateDirty] = useState(false);
  const [client, setClient] = useState(
    inputSources ? (inputSources[0] ? inputSources[0] : "") : ""
  );
  const [showNameModal, setShowNameModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [showScoresModal, setShowScoresModal] = useState(false);
  const [queueScores, setQueueScores] = useState([]);
  const [errors, setErrors] = useState([]);
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    assessmentTemplatePkg.fetchData([client]);
    reset();
  }, [client]);

  const reset = () => {
    setTemplateQueue(null);
    setIsTemplateDirty(false);
    setShowNameModal(false);
    setShowScoresModal(false);
  };

  const scoresQuestionIdsMap = {};
  if (templateQueue) {
    templateQueue.questions.forEach(
      (x) =>
        (scoresQuestionIdsMap[`${x.id}`] =
          x.questionText)
    );
  }

  const changeAllIdsInQuestion = (question) => {
    const ids = { oldId: question.id + "", newId: eqhUUID() };
    question.id = ids.newId;
    return ids;
  };

  const changeAllIdsInTemplate = (template) => {
    template.id = NEW_TEMPLATE_ID;
    let questionMap = {};
    for (const question of template.questions) {
      const ids = changeAllIdsInQuestion(question);
      questionMap[ids.oldId] = ids.newId;
    }
    for (const score of template.scores) {
      let questionIds = [];
      for (const questionId of score.questionsIds) {
        let oldQuestId = questionId.substring(37);
        let newQuestId = questionMap[oldQuestId];
        questionIds.push(`${newQuestId}`);
      }
      score.questionsIds = questionIds;
    }

    return template;
  };

  const handleTemplateChange = (key, value) => {
    switch (key) {
      case "activeForm":
      case "publicForm": {
        let templateClone = { ...templateQueue };
        templateClone[key] = value;
        setTemplateQueue(templateClone);
        break;
      }
      case "addQuestion": {
        let templateClone = { ...templateQueue };
        templateClone.questions.push(getDefaultQuestion());
        setTemplateQueue(templateClone);
        break;
      }
      case "changeTemplate": {
        setTemplateQueue(
          cloneDeep(
            assessmentTemplatePkg.state.data.find((x) => x.id === value)
          )
        );
        break;
      }
      case "copyTemplate": {
        let templateListClone = [...assessmentTemplatePkg.state.data];
        let templateClone = changeAllIdsInTemplate(cloneDeep(templateQueue));
        templateClone.name = `${templateClone.name} (Copy)`;
        templateListClone.push(templateClone);
        setTemplateQueue(templateClone);
        assessmentTemplatePkg.dispatch({
          type: "Update",
          payload: templateListClone,
        });
        break;
      }
      case "deleteTemplate": {
        let templateClone = { ...templateQueue };
        if (templateClone.id === NEW_TEMPLATE_ID) {
          deleteTemplateHelper(NEW_TEMPLATE_ID);
        } else {
          deleteAssessmentTemplate(value)
            .then((x) => {
              deleteTemplateHelper(value);
            })
            .catch(() => {
              setErrors("An error occurred while processing your request.");
            });
        }
        break;
      }
      case "discardChanges": {
        setTemplateQueue(
          cloneDeep(
            assessmentTemplatePkg.state.data.find((x) => x.id === value)
          )
        );
        break;
      }
      default:
        break;
    }
    setIsTemplateDirty(true);
  };

  const deleteTemplateHelper = (templateId) => {
    let templateListClone = [...assessmentTemplatePkg.state.data];
    templateListClone.splice(
      assessmentTemplatePkg.state.data.findIndex((x) => x.id === templateId),
      1
    );
    setTemplateQueue(null);
    assessmentTemplatePkg.dispatch({
      type: "Update",
      payload: templateListClone,
    });
  };

  const handleQuestionChange = (idx) => (key, value) => {
    let templateClone = { ...templateQueue };
    switch (key) {
      case "questionText":
      case "required":
      case "answers":
        templateClone.questions[idx][key] = value;
        break;
      case "conditions":
        for (const objKey in value) {
          if (Object.hasOwnProperty.call(value, objKey)) {
            templateClone.questions[idx][objKey] = value[objKey];
          }
        }
        break;
      case "questionType":
        templateClone.questions[idx][key] = value;
        if (
          value === QUESTION_TYPES.MCMultipleAnswer ||
          value === QUESTION_TYPES.MCSingleAnswer
        ) {
          templateClone.questions[idx].answerType = "NUMERICAL";
        } else if (value === "FREE_FORM") {
          templateClone.questions[idx].answers = [];
          templateClone.questions[idx].answerType = "TEXT";
        }
        break;
      case "deleteQuestion":
        templateClone.questions.splice(idx, 1);
        deleteQuestionFromScores(templateClone, value.id)
        break;
      case "copyQuestion":
        const questionClone = cloneDeep(
          templateClone.questions[idx]
        );
        changeAllIdsInQuestion(questionClone);
        templateClone.questions.splice(idx + 1, 0, questionClone);
        break;
      case "moveType":
        if (
          value[0] === MOVE_TYPES.MoveBefore ||
          value[0] === MOVE_TYPES.MoveAfter
        ) {
          let newIdx = value[1];

          const removedItem = templateClone.questions.splice(
            idx,
            1
          )[0];

          templateClone.questions.splice(newIdx, 0, removedItem);
        } else if (value[0] === MOVE_TYPES.Swap) {
          let tmp = templateClone.questions[idx];
          templateClone.questions[idx] =
            templateClone.questions[value[1]];
          templateClone.questions[value[1]] = tmp;
        }
        break;
      default:
        break;
    }
    setIsTemplateDirty(true);
    setTemplateQueue(templateClone);
  };

  const deleteQuestionFromScores = (templateClone, selectedQuestionId) => {
    let scoreIndexes = [];
    templateClone.scores.forEach((score, scoreIndex) => {
      let questionIdIndex = score.questionsIds.findIndex(questionId => questionId.endsWith(selectedQuestionId));
      if (questionIdIndex > -1) {
        scoreIndexes.push({"scoreIndex" : scoreIndex, "questionIdIndex": questionIdIndex})
      }
    })
    scoreIndexes.forEach((indexData) => {
      templateClone.scores[indexData.scoreIndex].questionsIds.splice(indexData.questionIdIndex, 1);
    })
  }

  const submitTemplateChanges = (close) => {
    const templateClone = cloneDeep(templateQueue);
    //Validation
    const errors = [];
    if (!templateClone.questions.length) {
      errors.push("You at least 1 question.  Please add a question.");
    }
    templateClone.questions.forEach((x, idx) => {
      const isMultipleChoice =
        x.questionType === QUESTION_TYPES.MCMultipleAnswer ||
        x.questionType === QUESTION_TYPES.MCSingleAnswer;
      if (isMultipleChoice && x.answers.length < 2) {
        errors.push(`Question ${idx + 1} needs at least 2 answers.`);
      }
      if (isMultipleChoice && x.answers.some((x) => !x.text)) {
        errors.push(`Question ${idx + 1} can't have any blank answers.`);
      }
    });

    if (isObjEmpty(errors)) {
      //Populate Data
      if (templateClone.scores && templateClone.scores.length) {
        for (let score of templateClone.scores) {
          score.questionTitles = [];
          for (let questionId of score.questionsIds) {
            score.questionTitles.push(scoresQuestionIdsMap[questionId]);
          }
        }
      }

      const templateListClone = [...assessmentTemplatePkg.state.data];
      const selectedTemplateIdx = templateListClone.findIndex(
        (x) => x.id === templateClone.id
      );

      let isTemplateNew = false;
      if (templateClone.id === NEW_TEMPLATE_ID) {
        delete templateClone.id;
        isTemplateNew = true;
      }

      saveAssessmentTemplate(templateClone)
        .then((res) => {
          setIsTemplateDirty(false);
          templateClone.id = res.data;
          if (close) {
            setTemplateQueue(null);
          } else {
            setTemplateQueue(templateClone);
          }
          templateListClone[selectedTemplateIdx] = templateClone;
          assessmentTemplatePkg.dispatch({
            type: "Update",
            payload: templateListClone,
          });
        })
        .catch((err) => {
          if (isTemplateNew) {
            templateClone.id = NEW_TEMPLATE_ID;
          }
          errors.push("An error occurred while saving the template.");
          setErrors(errors);
        });
    } else {
      setErrors(errors);
    }
  };

  const showNewTemplateNameModal = () => {
    setNewTemplateName("");
    setShowNameModal(true);
  };

  const submitNewTemplate = () => {
    //Validation
    const errors = [];
    if (!newTemplateName) {
      errors.push(
        "Name must have a value. Please fill in a value for the Name field."
      );
    }
    if(assessmentTemplatePkg.state.data.some(x=> x.name === newTemplateName)) {
      errors.push("Template name already exists");
    }
    if (isObjEmpty(errors)) {
      const templateClone = getDefaultTemplate(newTemplateName, client);
      const templateListClone = [...assessmentTemplatePkg.state.data];
      templateListClone.push(templateClone);

      setIsTemplateDirty(false);
      setTemplateQueue(templateClone);
      setShowNameModal(false);
      assessmentTemplatePkg.dispatch({
        type: "Update",
        payload: templateListClone,
      });
    } else {
      setErrors(errors);
    }
  };

  const openScores = () => {
    setQueueScores(cloneDeep(templateQueue.scores));
    setShowScoresModal(true);
  };

  const submitScores = () => {
    const errors = [];
    queueScores.forEach((x, idx) => {
      if (!x.name) {
        errors.push(`Score ${idx + 1}'s name is blank.`);
      }
      if (!x.questionsIds.length) {
        errors.push(
          `For Score ${idx + 1} please make you select at least 1 question.`
        );
      }
    });

    if (isObjEmpty(errors)) {
      const templateClone = { ...templateQueue };
      templateClone.scores = queueScores;

      setIsTemplateDirty(true);
      setTemplateQueue(templateClone);
      setQueueScores([]);
      setShowScoresModal(false);
    } else {
      setErrors(errors);
    }
  };

  const handleScoreChange = (idx) => (key, value) => {
    let scoresClone = [...queueScores];
    switch (key) {
      case "name":
      case "operation":
      case "questionsIds":
        //case "questionTitles":
        scoresClone[idx][key] = value;
        break;
      case "addScore":
        scoresClone.push(getDefaultScore());
        break;
      default:
        break;
    }

    setIsTemplateDirty(true);
    setQueueScores(scoresClone);
  };

  let questionsFilterValues = [];
  if (templateQueue && !assessmentTemplatePkg.state.isLoading) {
    questionsFilterValues = templateQueue.questions.map(
      (x, idx) => {
        return {
          text: `Question ${idx + 1}`,
          idx: idx,
          id: x.id,
          questionText: x.questionText,
        };
      }
    );
  }

  return (
    assessmentTemplatePkg.state.data !== null && (
      <>
        <Container fluid className="mt-3 mb-4 pb-3">
          <Card className="mb-3 pb-3">
            <Card.Body className="mb-3">
              <EQHErrorToast
                id={`${componentName}-error-popup`}
                errors={errors}
                handleClose={() => setErrors([])}
              />
              <Row>
                <Col sm={2} className="mb-2">
                  <label className="d-block">Client</label>
                  <Form.Control
                    id={`${componentName}-client-ddl`}
                    as="select"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                  >
                    {inputSources.map((x) => (
                      <option
                        {...getKeyAndId(`${componentName}-client-${x}-option`)}
                        value={x}
                      >
                        {getDisplayValue(stateFullName, x)}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col className="mb-2">
                  <label className="d-block">Select Assessment Template</label>
                  <Form.Control
                    id={`${componentName}-selectTemplate-ddl`}
                    as="select"
                    value={templateQueue?.id ?? "null"}
                    onChange={(e) =>
                      handleTemplateChange("changeTemplate", e.target.value)
                    }
                  >
                    <option
                      {...getKeyAndId(
                        `${componentName}-selectTemplate-select-option`
                      )}
                      value={"null"}
                    >
                      --Select--
                    </option>
                    {assessmentTemplatePkg.state.data.map((x) => (
                      <option
                        {...getKeyAndId(
                          `${componentName}-${x.id}-selectTemplate-option`
                        )}
                        value={x.id}
                      >
                        {x.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col sm={1} className="mb-2">
                  <Form>
                    {templateQueue && (
                      <div
                        {...getKeyAndId(
                          `${componentName}-inline-checkbox-group`
                        )}
                        className="mb-3"
                      >
                        <Form.Check
                          label="Active"
                          type={"checkbox"}
                          id={`${componentName}-inline-checkbox-activeForm`}
                          checked={templateQueue.activeForm}
                          onChange={(e) =>
                            handleTemplateChange("activeForm", e.target.checked)
                          }
                        />
                        <Form.Check
                          label="Public"
                          type={"checkbox"}
                          id={`${componentName}-inline-checkbox-publicForm`}
                          checked={templateQueue.publicForm}
                          onChange={(e) =>
                            handleTemplateChange("publicForm", e.target.checked)
                          }
                        />
                      </div>
                    )}
                  </Form>
                </Col>
                <Col className="mb-2">
                  {templateQueue && (
                    <>
                      <label className="d-block">Selected Score(s)</label>
                      <div id={`${componentName}-selectedScores-div`}>
                        {templateQueue.scores.map((x) => x.name).join(", ")}
                      </div>
                    </>
                  )}
                </Col>
                <Col sm={1} className="text-end mb-2">
                  {templateQueue && (
                    <i
                      id={`${componentName}-editScores-icon`}
                      onClick={openScores}
                      className="fa fa-edit cursor-p"
                    ></i>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Button
                    id={`${componentName}-newTemplate-btn`}
                    variant="secondary"
                    onClick={() => showNewTemplateNameModal()}
                  >
                    New Template
                  </Button>
                </Col>
              </Row>
              {templateQueue &&
                templateQueue.questions.map((x, idx) => (
                  <Row
                    {...getKeyAndId(`${componentName}-${x.id}-row`)}
                    className="mb-3"
                  >
                    <Col>
                      <Card>
                        <Card.Header>
                          <label>Question {idx + 1}</label>
                        </Card.Header>
                        <QuestionCard
                          question={x}
                          questionIdx={idx}
                          questionsFilterValues={questionsFilterValues}
                          onChange={handleQuestionChange(idx)}
                          setErrors={setErrors}
                        />
                      </Card>
                    </Col>
                  </Row>
                ))}
              {templateQueue && (
                <>
                  <Row>
                    <Col className="text-center my-3">
                      <Button
                        variant="link"
                        id={`${componentName}-addQuestion-btn`}
                      >
                        <i
                          id={`${componentName}-addQuestion-icon`}
                          onClick={() =>
                            handleTemplateChange("addQuestion", null)
                          }
                          className="fas fa-plus-circle fa-2x"
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        id={`${componentName}-deleteTemplate-btn`}
                        variant="danger"
                        onClick={() =>
                          handleTemplateChange(
                            "deleteTemplate",
                            templateQueue.id
                          )
                        }
                      >
                        Delete
                      </Button>
                    </Col>
                    <Col sm={9} className="text-end">
                      <Button
                        id={`${componentName}-copyTemplate-btn`}
                        variant="secondary"
                        onClick={() =>
                          handleTemplateChange("copyTemplate", null)
                        }
                      >
                        Copy Template
                      </Button>
                      <Button
                        id={`${componentName}-discardChanges-btn`}
                        variant="secondary"
                        onClick={() =>
                          handleTemplateChange(
                            "discardChanges",
                            templateQueue.id
                          )
                        }
                      >
                        Discard Changes
                      </Button>
                      <Button
                        id={`${componentName}-submitTemplateChanges-btn`}
                        variant="primary"
                        onClick={() => submitTemplateChanges(false)}
                      >
                        Save
                      </Button>
                      <Button
                        id={`${componentName}-submitTemplateChangesAndClose-btn`}
                        variant="primary"
                        onClick={() => submitTemplateChanges(true)}
                      >
                        Save and Close
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
        <ReactAlert
          title={`Data That Would Be Sent To Backend`}
          onHide={() => setAlertText("")}
          text={alertText}
        />
        <Modal
          onHide={() => setShowNameModal(false)}
          show={showNameModal}
          size="xl"
          screenbool={true}
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>New Template Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <label className="d-block">Name</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    id={`${componentName}-newTemplateName-text`}
                    type="text"
                    className="mb-3"
                    value={newTemplateName ?? ""}
                    onChange={(e) => setNewTemplateName(e.target.value)}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <EQHButton
              id={`${componentName}-newTemplateNameClose-btn`}
              onClick={() => setShowNameModal(false)}
            >
              Close
            </EQHButton>
            <EQHButton
              id={`${componentName}-newTemplateNameSubmit-btn`}
              onClick={submitNewTemplate}
            >
              Submit
            </EQHButton>
          </Modal.Footer>
        </Modal>
        <Modal
          onHide={() => setShowScoresModal(false)}
          show={showScoresModal}
          size="xl"
          screenbool={true}
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Scores</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {queueScores.map((x, idx) => (
                <Row className="mb-3">
                  <Col>
                    <Card
                      {...getKeyAndId(`${componentName}-supportUploads-${idx}`)}
                      key={`${componentName}-${idx}-score-card`}
                    >
                      <Card.Body>
                        <Row className="mb-3">
                          <Col>
                            <label>
                              Name<span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              id={`${componentName}-${idx}-scoreName-text`}
                              type="text"
                              value={x.name}
                              onChange={(e) => {
                                handleScoreChange(idx)("name", e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <label className="d-block">
                              Operation<span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              id={`${componentName}-${idx}-scoreOperation-ddl`}
                              as="select"
                              value={x.operation}
                              onChange={(e) =>
                                handleScoreChange(idx)(
                                  "operation",
                                  e.target.value
                                )
                              }
                            >
                              {" "}
                              {SCORE_OPERATIONS.map((x) => (
                                <option
                                  {...getKeyAndId(
                                    `${componentName}-scoreOperation-${x}-option`
                                  )}
                                  value={x}
                                >
                                  {x}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Filter
                              handleChange={(e) =>
                                handleScoreChange(idx)(
                                  "questionsIds",
                                  e.paramValue
                                )
                              }
                              filterDefinition={{
                                type: GENERIC_DDL_TYPE,
                                getHeader: () => "Questions",
                                key: "Questions",
                                componentProps: {
                                  id: `${componentName}-${idx}-scoreQuestions-ddl`,
                                  showSearchTextbox: false,
                                  highlightWhenHasValue: false,
                                  showClearButton: true,
                                  selected: new FilterParam(
                                    "",
                                    "eq",
                                    x.questionsIds,
                                    (e) => e,
                                    (e) => scoresQuestionIdsMap[e]
                                  ),
                                  getData: (srchTxt) =>
                                    Promise.resolve(
                                      questionsFilterValues.map((x) => {
                                        return {
                                          text: x.questionText,
                                          value: `${x.id}`,
                                        };
                                      })
                                    ),
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col className="text-center my-3">
                  <Button variant="link" id={`${componentName}-addScore-btn`}>
                    <i
                      id={`${componentName}-addScore-icon`}
                      onClick={() => handleScoreChange()("addScore")}
                      className="fas fa-plus-circle fa-2x"
                    ></i>
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <EQHButton
              id={`${componentName}-scoresClose-btn`}
              onClick={() => setShowScoresModal(false)}
            >
              Close
            </EQHButton>
            <EQHButton
              id={`${componentName}-scoresSubmit-text`}
              onClick={submitScores}
            >
              Submit
            </EQHButton>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};

export default AssessmentTemplate;
