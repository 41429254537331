import React from "react";
import { Badge } from "react-bootstrap";

const componentName = "carePathOverdue";

export const CarePathOverdue = () => {
  return (
    <>
      <Badge id={`${componentName}-status`} pill bg="danger" className="ms-2">Overdue</Badge>
    </>
  );
};

export default CarePathOverdue;