import {actions} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_PATIENT_DETAILS:
      return {
        ...state,
        patientProfileInformation: action.payload,
      };
    case actions.TOGGLE_EDIT_PATIENT_DETAILS:
      return {
        ...state,
        isEditPatientDetailsEnabled: action.payload,
      };
    case actions.TOGGLE_ADD_INTERNAL_NOTE_ENABLED:
      return {
        ...state,
        isAddInternalNoteEnabled: action.payload,
      };
    case actions.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case actions.SET_SPOKEN_LANGUAGES:
      return {
        ...state,
        spokenLanguages: action.payload,
      };
    case actions.SET_READING_LANGUAGES:
      return {
        ...state,
        readingLanguages: action.payload,
      };
    case actions.SET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case actions.SET_PAYOR_STATUS:
      return {
        ...state,
        payorStatus: action.payload,
      };
    case actions.SET_SPECIALIST_TYPES:
      return {
        ...state,
        specialistTypes: action.payload,
      };
    case actions.SET_PRONOUNS_DROP_LIST:
      return {
        ...state,
        pronounsDropList: action.payload
      }
    case actions.SET_SEX_ASSIGNED_DROP_LIST:
      return {
        ...state,
        sexAssignedBirthDropList: action.payload
      }
    case actions.SET_RACE_ETHNICITY_DROP_LIST:
      return {
        ...state,
        raceEthnicityDropList: action.payload
      }
    case actions.SET_SEXUAL_ORIENTATION_LIST:
      return {
        ...state,
        sexualOrientationList: action.payload
      }
    case actions.SET_GENDER_IDENTITY_LIST:
      return {
        ...state,
        genderIdentityList: action.payload
      }
    default:
      return state;
  }
};