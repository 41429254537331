import {
  CLOSE_CARE_PATH,
  SET_CLOSE_CP_REASONS,
  INCREMENT_PATIENT_CALLS,
  SEARCH_PATIENTS,
  START_FILTER,
  UPDATE_STEP,
  SET_CO_REJECTION_REASONS,
} from "../../actions/types";

const initialState = {
  patientsList: [],
  totalSize: {},
  pageSize: {},
  pagenumber: {},
  closePathReasons: [],
  rejectCOReasons: [],
  filtering: false,
};

const updateStep = async (state, payload) => {
  const newState = Object.assign({}, state);

  await newState.patientsList.map((x) => {
    if(x.id === payload.id) {
      x.carePaths.map((y) => {
        if(y.id === payload.carePath.id) {
          y = payload.carePath;
        }
      })
    }
  });

  return newState;
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_PATIENTS:
      return {
        ...state,
        patientsList:
          payload && payload.patientCardDtos && payload.patientCardDtos.length
            ? payload.patientCardDtos
            : ["Empty"],
        totalSize: payload.totalSize,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        filtering: false,
      };
    case START_FILTER:
      return {
        ...state,
        filtering: true,
      };
    case UPDATE_STEP:
      return updateStep(state, payload);
    case INCREMENT_PATIENT_CALLS:
      return {
        ...state,
        patientsList: state.patientsList.map((x) =>
          x.id === payload.patientId ? { ...x, callsCount: payload.count } : x
        ),
      };
    case SET_CLOSE_CP_REASONS:
      return {
        ...state,
        closePathReasons: payload,
      };
    case SET_CO_REJECTION_REASONS:
      return {
        ...state,
        rejectCOReasons: payload,
      };
    case CLOSE_CARE_PATH:
      return {
        ...state,
        patientsList: state.patientsList.map((x) =>
          x.id === payload.patientId
            ? {
                ...x,
                carePaths: x.carePaths.filter((y) => y.id !== payload.pathId),
              }
            : x
        ),
      };
    default:
      return state;
  }
}
