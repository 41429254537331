import axios from "axios";
import { getProgramLead } from "../../../../actions/caremgmt/careAdminAction";

export const handleGetInitialData = (userId, userName) => {
  let data = {name: [], team: []};
  return getProgramLead(1, 1000).then(res => {
    let team = [];
    let filtered = res.data.userList
        .filter(a => a.userAccountId === userId && a.status === 'ACTIVE');
    if (filtered.length > 0) {
      team = [filtered[0]?.teamName, filtered[0]?.teamId]
    }

    return data = {
      name: [userName, filtered[0]?.programLeadId],
      team: team,
    }
  }).catch(() => data)
}

export const fetchPrograms = () => {
  return axios.get(
    `${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/programs`,
    { headers: {}, }
  )
}

export const assignMember = (payload) => {
  return axios.put(
    `${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/assign`,
    [payload]
  )
}