import React, { useEffect, useState } from "react";
import { useGetAssessmentFormTemplates } from "../../actions/assessmentTemplate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { Button as OurButton } from "../../components/buttons";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { MultiComponent } from "../../components/MultiComponent";
import { Button as EQHButton } from "../../components/buttons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  useGetCarePathTemplatesByClient,
  useGetCarePathTemplateConstants,
  saveCarePathTemplate,
  deleteCarePathTemplate,
} from "../../actions/carePathTemplateActions";
import { useAuth } from "../../context/authContext";
import {
  eqhUUID,
  getKeyAndId,
  isObjEmpty,
  logDebug,
  uuid,
  getError,
  getDisplayValue
} from "../../utils/util";
import cloneDeep from "lodash/cloneDeep";
import ReactAlert from "../../components/ReactAlert";
import EQHErrorToast from "../../components/EQHErrorToast";
import { SimpleDDL } from "../../components/dropDownLists/simpleDDL";
import Spinner from "react-bootstrap/Spinner";
import {stateFullName} from "../../utils/textValueLists";

const isEmpty = require("is-empty");
const componentName = "CarePathTemplate";

const carePathTemplateReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
};

const MOVE_TYPES = {
  MoveBefore: "Move Before",
  MoveAfter: "Move After",
  Swap: "Swap",
};

const NEW_TEMPLATE_ID = "new";

const DEFAULT_TEMPLATE = {
  id: NEW_TEMPLATE_ID,
  diagnosisSpecific: false,
  diagnosis: null,
  public: false,
  active: false,
  restricted: false,
  steps: [],
  careTransitionPath: false,
  onlyOneActiveAtATime: false,
  autoAssign: false,
  autoAssignTrigger: null,
  tinNumber: "",
};

const getDefaultTemplate = (name, clientID) => {
  const defaultTemp = cloneDeep({
    ...DEFAULT_TEMPLATE,
    name: name,
    clientID: clientID,
    newTemplate: true,
  });
  return defaultTemp;
};

const DEFAULT_STEP = {
  title: null,
  completeBy: 0,
  trigger: null,
  description: null,
  timing: null,
  isSubStep: false,
  dueDate: null,
  stepDate: null,
  customStatuses: [],
  applyModificator: false,
  conditions: [],
  required: false,
  needToScheduleAppointment: false,
};

const getDefaultStep = (subStep = false) => {
  return cloneDeep({ ...DEFAULT_STEP, isSubStep: subStep });
};

const DEFAULT_CONDITION = { rule: { type: "VALUE" }, ruleEmpty: false };

const getDefaultCondition = (type = "AND") => {
  return cloneDeep({ ...DEFAULT_CONDITION, type: type });
};

const DEFAULT_CUSTOM_STATUS = {
  needToScheduleAppointment: false,
  status: "",
  behavesLike: "",
};

const getDefaultCustomStatus = () => {
  return cloneDeep(DEFAULT_CUSTOM_STATUS);
};

const StepCard = ({
  step,
  stepIdx,
  stepsFilterValues,
  onChange,
  setErrors,
  carePathConstantsPkg,
  onTemplateChange,
  onSelectChange,
  selected,
}) => {
  const getAvailableMoveTypes = () => {
    const availableMoveTypes = {};
    if (stepIdx > 0) {
      availableMoveTypes[MOVE_TYPES.MoveBefore] = MOVE_TYPES.MoveBefore;
    }
    if (stepIdx + 1 < stepsFilterValues.length) {
      availableMoveTypes[MOVE_TYPES.MoveAfter] = MOVE_TYPES.MoveAfter;
    }
    if (stepsFilterValues.length > 1) {
      availableMoveTypes[MOVE_TYPES.Swap] = MOVE_TYPES.Swap;
    }
    return availableMoveTypes;
  };
  const [moveType, setMoveType] = useState(
    () =>
      getAvailableMoveTypes()[MOVE_TYPES.MoveBefore] ??
      getAvailableMoveTypes()[MOVE_TYPES.MoveAfter] ??
      getAvailableMoveTypes()[MOVE_TYPES.Swap] ??
      ""
  );
  const [movePosition, setMovePosition] = useState(0);
  const [condLogicShowModal, setCondLogicShowModal] = useState();
  const [queueConditions, setQueueConditions] = useState([]);
  const [queueCondShowHide, setQueueCondShowHide] = useState(
    step?.applyModificator ?? false
  );
  const [queueCondNeedAppt, setQueueCondNeedAppt] = useState(
    step?.needToScheduleAppointment ?? false
  );
  const [customStatusShowModal, setCustomStatusShowModal] = useState();
  const [queueCustomStatuses, setQueueCustomStatuses] = useState([]);

  const availableMoveTypes = getAvailableMoveTypes();

  //logDebug(() => `TEst:: stepIdx: ${stepIdx} - moveType: ${moveType} - ${JSON.stringify(availableMoveTypes)}`)

  const handleConditionChange = (idx, key, value) => {
    const queueConditionsClone = [...queueConditions];
    switch (key) {
      case "title":
        queueConditionsClone[idx][key] = value;
        break;
      case "rule-type":
      case "rule-value":
        queueConditionsClone[idx].rule[key.split("-")[1]] = value;
        break;
      case "deleteCondition":
        queueConditionsClone.splice(idx, 1);
        break;
      case "addCondition":
        queueConditionsClone.push(getDefaultCondition(value));
        break;
      default:
        break;
    }
    setQueueConditions(queueConditionsClone);
  };

  const handleCustomStatusChange = (idx, key, value) => {
    const queueCustomStatusesClone = [...queueCustomStatuses];
    switch (key) {
      case "status":
      case "behavesLike":
        queueCustomStatusesClone[idx][key] = value;
        break;
      case "deleteCustomStatus":
        queueCustomStatusesClone.splice(idx, 1);
        break;
      case "addCustomStatus":
        queueCustomStatusesClone.push(getDefaultCustomStatus());
        break;
      default:
        break;
    }
    setQueueCustomStatuses(queueCustomStatusesClone);
  };

  const openConditionalLogic = () => {
    const conditionsClone = cloneDeep(step.conditions ?? []);
    if (!conditionsClone.length) {
      conditionsClone.push(getDefaultCondition());
    }
    setQueueConditions(conditionsClone);
    setQueueCondShowHide(step.applyModificator);
    setCondLogicShowModal(true);
  };

  const submitConditionalLogic = () => {
    logDebug(() => queueConditions);
    //Validation
    const errors = [];
    queueConditions.forEach((x, idx) => {
      let hasProblems = false;
      if (!x.title) {
        hasProblems = true;
      }
      if (!x.rule.value) {
        hasProblems = true;
      }
      if(hasProblems) {
        errors.push(
          `Condition ${idx + 1} is incomplete; please check Condition ${idx + 1}.`
        );
      }
    });
    // if (!newTemplateName) {
    //   errors.name =
    //     "Name must have a value. Please fill in a value for the Name field.";
    // }

    if (isObjEmpty(errors)) {
      const conditionsClone = [...queueConditions];
      onChange("conditions", {
        conditions: conditionsClone,
        applyModificator: queueCondShowHide,
      });
      setQueueConditions([]);
      setQueueCondShowHide(false);
      setCondLogicShowModal(false);
    } else {
      setErrors(errors);
    }
  };

  const openCustomStatuses = () => {
    const customStatusesClone = cloneDeep(step.customStatuses ?? []);
    if (!customStatusesClone.length) {
      customStatusesClone.push(getDefaultCondition());
    }
    setQueueCustomStatuses(customStatusesClone);
    setQueueCondNeedAppt(step.needToScheduleAppointment);
    setCustomStatusShowModal(true);
  };

  const submitCustomStatuses = () => {
    logDebug(() => queueCustomStatuses);
    //Validation
    const errors = [];
    queueCustomStatuses.forEach((x, idx) => {
      if (!x.status) {
        errors.push(`Custom Status - Status ${idx + 1} is incomplete.`);
      }
      if (!x.behavesLike) {
        errors.push(`Custom Status - Behaves Like ${idx + 1} is incomplete.`);
      }
    });

    if (isObjEmpty(errors)) {
      const customStatusesClone = [...queueCustomStatuses];
      onChange("customStatuses", {
        customStatuses: customStatusesClone,
        needToScheduleAppointment: queueCondNeedAppt,
      });
      setQueueCustomStatuses([]);
      setQueueCondNeedAppt(false);
      setCustomStatusShowModal(false);
    } else {
      setErrors(errors);
    }
  };

  const movePositionValues = stepsFilterValues.filter(
    (x) =>
      x.idx !== stepIdx &&
      ((moveType === MOVE_TYPES.MoveBefore && x.idx !== stepIdx + 1) ||
        moveType !== MOVE_TYPES.MoveBefore) &&
      ((moveType === MOVE_TYPES.MoveAfter && x.idx !== stepIdx - 1) ||
        moveType !== MOVE_TYPES.MoveAfter)
  );

  const conditionsFilterValues = stepsFilterValues.filter(
    (x) => x.idx < stepIdx
  );

  return (
    <Row>
      <Col>
        <Card
          id={`${componentName}-carePathStep-${stepIdx}`}
          className="mb-3 bg-light"
          style={{ marginLeft: step.isSubStep ? "50px" : "0px" }}
        >
          <Card.Body>
            <Row>
              <Col sm={1} id={`${componentName}-stepCarePath-${stepIdx}`}>
                <label
                  id={`${componentName}-stepLabel-${stepIdx}`}
                  className="d-block"
                >
                  Step
                </label>
                <div
                  id={`${componentName}-stepValue-${stepIdx}`}
                  className="form-check form-check-inline"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`${componentName}-stepCheck-${stepIdx}`}
                    checked={selected.has(stepIdx)}
                    onChange={(e) => onSelectChange(e.target.checked)}
                  />
                  <label
                    id={`${componentName}-stepLabel-${stepIdx}`}
                    className="form-check-label"
                    for={`${componentName}-stepCheck-${stepIdx}`}
                  >
                    {step.uiStepIdxStr}
                  </label>
                </div>
              </Col>
              <Col id={`${componentName}-stepTitleCarePath-${stepIdx}`}>
                <label
                  id={`${componentName}-stepTitleLabel-${stepIdx}`}
                  className="d-block"
                >
                  Title<span className="text-danger">*</span>
                </label>
                <Form.Control
                  id={`${componentName}-stepTitleValue-${stepIdx}`}
                  type="text"
                  value={step.title}
                  onChange={(e) => onChange("title", e.target.value)}
                />
              </Col>
              <Col id={`${componentName}-stepCompleteByCarePath-${stepIdx}`}>
                <label
                  id={`${componentName}-stepCompleteByLabel-${stepIdx}`}
                  className="d-block"
                >
                  Complete By (Days)<span className="text-danger">*</span>
                </label>
                <div
                  id={`${componentName}-stepCompleteByValue-${stepIdx}`}
                  className="input-group mb-3"
                >
                  <input
                    id={`${componentName}-stepCompleteByCheck-${stepIdx}`}
                    type="text"
                    className="form-control"
                    aria-label="Complete Days"
                    aria-describedby="CompleteDays"
                    value={step.completeBy}
                    onChange={(e) => onChange("completeBy", e.target.value)}
                  />
                </div>
              </Col>
              <Col>
                <label
                  id={`${componentName}-stepTimingLabel-${stepIdx}`}
                  className="d-block"
                >
                  Timing<span className="text-danger">*</span>
                </label>
                <div
                  id={`${componentName}-stepTimeValue-${stepIdx}`}
                  className="input-group mb-3"
                >
                  <SimpleDDL
                    id={`${componentName}-step-${stepIdx}-stepTimings-ddl`}
                    list={carePathConstantsPkg.state.data.stepTimings}
                    textSelector={(x) => x.text}
                    valueSelector={(x) => x.value}
                    onChange={(x) => onChange("timing", x)}
                    selectedKey={step.timing}
                  />
                </div>
              </Col>
              <Col>
                <label
                  id={`${componentName}-stepTriggerLabel-${stepIdx}`}
                  className="d-block"
                >
                  Trigger<span className="text-danger">*</span>
                </label>
                <div
                  id={`${componentName}-stepTriggerValue-${stepIdx}`}
                  className="input-group mb-3"
                >
                  <SimpleDDL
                    id={`${componentName}-step-triggers`}
                    list={carePathConstantsPkg.state.data.triggers}
                    textSelector={(x) => x.text}
                    valueSelector={(x) => x.value}
                    onChange={(x) => onChange("trigger", x)}
                    selectedKey={step.trigger}
                  />
                </div>
              </Col>
              <Col id={`${componentName}-stepCustomStatusCarePath-${stepIdx}`}>
                <label
                  id={`${componentName}-stepCustomStatusLabel-${stepIdx}`}
                  className="d-block"
                >
                  Custom Status Options
                </label>
                <div
                  id={`${componentName}-stepCustomStatusValue-${stepIdx}`}
                  className="input-group mb-3"
                >
                  <input
                    id={`${componentName}-stepCustomStatusCheck-${stepIdx}`}
                    type="text"
                    className="form-control"
                    readOnly
                    aria-label="Custom Status Options"
                    aria-describedby="CustomStatusOptions"
                    value={
                      step.customStatuses?.length ? "Defined" : "None defined"
                    }
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="CustomStatusOptions">
                      <i
                        id={`${componentName}-stepCustomStatusIcon-${stepIdx}`}
                        className="fa fa-edit float-end"
                        onClick={openCustomStatuses}
                      ></i>
                    </span>
                  </div>
                </div>
              </Col>
              {/* <Col className="text-center">
                <label className="d-block">Show or Hide</label>
                <i
                  className="fa fa-edit"
                  onClick={(e) => openConditionalLogic()}
                ></i>
              </Col> */}
              <Col
                sm={1}
                id={`${componentName}-stepRequiredCarePath-${stepIdx}`}
                className="text-center"
              >
                <label
                  id={`${componentName}-stepRequiredLabel-${stepIdx}`}
                  className="d-block"
                >
                  Required
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`${componentName}-stepRequiredCheck-${stepIdx}`}
                    checked={step.required}
                    onChange={(e) => onChange("required", e.target.checked)}
                  />
                </div>
              </Col>
              <Col
                sm={1}
                id={`${componentName}-stepSubStepCarePath-${stepIdx}`}
                className="text-center"
              >
                <label
                  id={`${componentName}-stepSubStepLabel-${stepIdx}`}
                  className="d-block"
                >
                  Is Sub-Step
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`${componentName}-stepSubStepCheck-${stepIdx}`}
                    checked={step.isSubStep}
                    onChange={(e) => onChange("isSubStep", e.target.checked)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col id={`${componentName}-stepDescCarePath-${stepIdx}`}>
                <label
                  id={`${componentName}-stepDescLabel-${stepIdx}`}
                  className="d-block"
                >
                  Description
                </label>
                <Form.Control
                  id={`${componentName}-stepDescValue-${stepIdx}`}
                  as="textarea"
                  rows={2}
                  value={step.description ?? ""}
                  onChange={(e) => onChange("description", e.target.value)}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <ButtonGroup
                  id={`${componentName}-stepDescCarePath-${stepIdx}`}
                  size="sm"
                  className="d-inline-block bg-white align-top"
                >
                  {stepIdx > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Show or Hide</Tooltip>}
                    >
                      <Button
                        id={`${componentName}-conditionLogicBtn-${stepIdx}`}
                        onClick={(e) => openConditionalLogic()}
                        variant="outline-secondary"
                        size="sm"
                      >
                        <i
                          id={`${componentName}-conditionLogicIcon-${stepIdx}`}
                          className="fas fa-cogs fa-lg align-middle"
                        ></i>
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete Step</Tooltip>}
                  >
                    <Button
                      id={`${componentName}-deleteStepBtn-${stepIdx}`}
                      onClick={(e) => onTemplateChange("deleteStep", [stepIdx])}
                      variant="outline-secondary"
                      size="sm"
                    >
                      <i
                        id={`${componentName}-deleteStepIcon-${stepIdx}`}
                        className="fas fa-trash-alt fa-lg align-middle"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Add Sub-Step</Tooltip>}
                  >
                    <Button
                      id={`${componentName}-addSubStepBtn-${stepIdx}`}
                      onClick={(e) =>
                        onTemplateChange("addSubStep", stepIdx + 1)
                      }
                      variant="outline-secondary"
                      size="sm"
                    >
                      <i
                        id={`${componentName}-addSubStepIcon-${stepIdx}`}
                        className="far fa-plus fa-lg align-middle"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </Col>
              <Col>
                <div className="d-flex flex-nowrap justify-content-end">
                  <div className="me-2">
                    <SimpleDDL
                      id={`${componentName}-moveTypeValue-${stepIdx}`}
                      list={Object.keys(availableMoveTypes)}
                      textSelector={(x) => x}
                      valueSelector={(x) => x}
                      onChange={(x) => setMoveType(x)}
                      selectedKey={moveType}
                    />
                  </div>
                  <div className="me-2">
                    <SimpleDDL
                      id={`${componentName}-movePositionValue-${stepIdx}`}
                      list={movePositionValues}
                      textSelector={(x) => x.stepIdxStr}
                      valueSelector={(x, idx) => idx}
                      onChange={(x) => setMovePosition(x)}
                      selectedKey={movePosition}
                    />
                  </div>
                  <div>
                    <Button
                      id={`${componentName}-commitStepMoveBtn-${stepIdx}`}
                      variant="secondary"
                      onClick={(e) =>
                        onChange("moveType", {
                          moveType,
                          idx: movePositionValues[movePosition].idx,
                          currentStepIsSubStep: step.isSubStep,
                          destinationStepIsSubStep:
                            movePositionValues[movePosition].isSubStep,
                        })
                      }
                    >
                      Commit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Modal
              onHide={() => setCondLogicShowModal(false)}
              show={condLogicShowModal}
              size="xl"
              screenbool={true}
              centered
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title id={`${componentName}-showHideModalTitle`}>
                  Show or Hide
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row className="mb-3" xl={1}>
                    <Col>
                      <label
                        id={`${componentName}-stepModalLabel`}
                        className="d-block"
                      >
                        Step
                      </label>
                    </Col>
                    <Col>
                      <span id={`${componentName}-stepModalValue`}>
                        {step.title}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    id={`${componentName}-conditionModal-${stepIdx}`}
                    className="mb-3"
                  >
                    <Col>
                      <SimpleDDL
                        id={`${componentName}-conditionModalValue-${stepIdx}`}
                        list={[
                          { text: "Show", value: true },
                          { text: "Hide", value: false },
                        ]}
                        textSelector={(x) => x.text}
                        valueSelector={(x) => x.value}
                        onChange={(x) => setQueueCondShowHide(x)}
                        selectedKey={queueCondShowHide}
                      />
                    </Col>
                    <Col>
                      <label
                        id={`${componentName}-conditionModalLabel-${stepIdx}`}
                        className="d-block"
                      >
                        this step if an earlier step contained the following
                        status:
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {queueConditions &&
                        queueConditions.map((queueCondition, condIdx) => (
                          <>
                            {condIdx > 0 && (
                              <Row className="mb-3">
                                <Col>
                                  <label
                                    id={`${componentName}-andOrModalValue-${stepIdx}-${condIdx}`}
                                    className="me-1"
                                    style={{
                                      color:
                                        queueCondition.type === "AND"
                                          ? "green"
                                          : queueCondition.type === "OR"
                                            ? "red"
                                            : "",
                                    }}
                                  >
                                    {queueCondition.type}
                                  </label>
                                  <i
                                    id={`${componentName}-deleteConditionModalIcon-${stepIdx}-${condIdx}`}
                                    className="fas fa-times-circle cursor-p"
                                    onClick={() =>
                                      handleConditionChange(
                                        condIdx,
                                        "deleteCondition"
                                      )
                                    }
                                  ></i>
                                </Col>
                              </Row>
                            )}
                            <Row className="my-3">
                              <Col>
                                <SimpleDDL
                                  id={`${componentName}-conditionStepIdsModalValue-${stepIdx}-${condIdx}`}
                                  list={conditionsFilterValues}
                                  textSelector={(x) => x.title}
                                  valueSelector={(x) => x}
                                  getKeyFromListItem={(item) => item?.title}
                                  onChange={(x) =>
                                    handleConditionChange(condIdx, "title", x.title)
                                  }
                                  selectedKey={queueCondition?.title}
                                  isValueSelectorUniqueForId={false}
                                />
                              </Col>
                              <Col>
                                <span
                                  id={`${componentName}-conditionStepIdsModalValue-${stepIdx}-${condIdx}-required`}
                                  className="text-danger"
                                >
                                  {" "}
                                  *
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {queueCondition.rule?.type === "VALUE" && (
                                  <Form.Control
                                    type="text"
                                    className="mb-3"
                                    value={queueCondition.rule?.value ?? ""}
                                    id={`${componentName}-condition3ModalValue-${stepIdx}-${condIdx}`}
                                    onChange={(e) =>
                                      handleConditionChange(
                                        condIdx,
                                        "rule-value",
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </Col>
                              <Col>
                                <span
                                  id={`${componentName}-condition3ModalValue-${stepIdx}-${condIdx}-required`}
                                  className="text-danger"
                                >
                                  {" "}
                                  *
                                </span>
                              </Col>
                            </Row>
                          </>
                        ))}
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <EQHButton
                  id={`${componentName}-addConditionORModalBtn-${stepIdx}`}
                  onClick={() =>
                    handleConditionChange(null, "addCondition", "OR")
                  }
                >
                  OR
                </EQHButton>
                <EQHButton
                  id={`${componentName}-addConditionANDModalBtn-${stepIdx}`}
                  onClick={() =>
                    handleConditionChange(null, "addCondition", "AND")
                  }
                >
                  AND
                </EQHButton>
                <EQHButton
                  id={`${componentName}-conditionCloseModalBtn-${stepIdx}`}
                  onClick={() => setCondLogicShowModal(false)}
                >
                  Close
                </EQHButton>
                <EQHButton
                  id={`${componentName}-conditionSubmitModalBtn-${stepIdx}`}
                  onClick={submitConditionalLogic}
                >
                  Submit
                </EQHButton>
              </Modal.Footer>
            </Modal>
            <Modal
              onHide={() => setCustomStatusShowModal(false)}
              show={customStatusShowModal}
              size="xl"
              screenbool={true}
              centered
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id={`${componentName}-customStatusModalTitle-${stepIdx}`}
                >
                  Custom Status Options
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row className="mb-3" xl={1}>
                    <Col>
                      <label
                        id={`${componentName}-customStatusStepModalLabel-${stepIdx}`}
                        className="d-block"
                      >
                        Step
                      </label>
                    </Col>
                    <Col>
                      <span
                        id={`${componentName}-customStatusStepModalValue-${stepIdx}`}
                      >
                        {step.title}
                      </span>
                    </Col>
                  </Row>
                  {queueCustomStatuses &&
                    queueCustomStatuses.map(
                      (queueCustomStatus, custStatusIdx) => (
                        <Row className="mb-3">
                          <Col>
                            <label
                              id={`${componentName}-customStatusStatusLabel-${stepIdx}`}
                              className="d-block"
                            >
                              Status
                            </label>
                            <Form.Control
                              id={`${componentName}-customStatusStatusValue-${stepIdx}-${custStatusIdx}`}
                              type="text"
                              value={queueCustomStatus.status}
                              onChange={(e) =>
                                handleCustomStatusChange(
                                  custStatusIdx,
                                  "status",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <label
                              id={`${componentName}-customStatusBehavesModalLabel-${stepIdx}`}
                              className="d-block"
                            >
                              Behaves Like
                            </label>
                            <SimpleDDL
                              id={`${componentName}-customStatusBehavesModalValue-${stepIdx}-${custStatusIdx}`}
                              list={
                                carePathConstantsPkg.state?.data
                                  ?.carePathStepState
                              }
                              textSelector={(x) => x.text}
                              valueSelector={(x) => x.value}
                              onChange={(x) =>
                                handleCustomStatusChange(
                                  custStatusIdx,
                                  "behavesLike",
                                  x
                                )
                              }
                              selectedKey={queueCustomStatus?.behavesLike}
                            />
                          </Col>
                          <Col className="my-auto">
                            <i
                              id={`${componentName}-deleteCustomStatusModalIcon-${stepIdx}-${custStatusIdx}`}
                              className="fas fa-times-circle cursor-p text-danger"
                              onClick={() =>
                                handleCustomStatusChange(
                                  custStatusIdx,
                                  "deleteCustomStatus"
                                )
                              }
                            ></i>
                          </Col>
                        </Row>
                      )
                    )}
                  <Row>
                    <Col className="text-center my-3">
                      <Button
                        variant="link"
                        id={`${componentName}-addCustomStatusModalBtn-${stepIdx}`}
                      >
                        <i
                          id={`${componentName}-addCustomStatusModalIcon-${stepIdx}`}
                          onClick={() =>
                            handleCustomStatusChange(null, "addCustomStatus")
                          }
                          className="fas fa-plus-circle fa-2x"
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Col>
                  <Form.Check
                    className="me-2 d-inline-block"
                    type={"checkbox"}
                    id={`${componentName}-addLinkToAppointmentModalCheck-${stepIdx}`}
                    checked={queueCondNeedAppt ? true : false}
                    onChange={(e) => setQueueCondNeedAppt(e.target.checked)}
                  />
                  <label className="align-bottom">
                    Add Link to Appointment
                  </label>
                </Col>
                <Col className="text-end">
                  <EQHButton
                    id={`${componentName}-customStatusCloseModalBtn-${stepIdx}`}
                    onClick={() => setCustomStatusShowModal(false)}
                    variant="secondary"
                  >
                    Close
                  </EQHButton>
                  <EQHButton
                    id={`${componentName}-customStatusSubmitModalBtn-${stepIdx}`}
                    onClick={submitCustomStatuses}
                    variant="primary"
                  >
                    Save
                  </EQHButton>
                </Col>
              </Modal.Footer>
            </Modal>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const CarePathTemplate = ({ }) => {
  const auth = useAuth();
  const inputSources = auth.getInputSources().sort();
  const [templateQueue, setTemplateQueue] = useState(null);
  const [isTemplateDirty, setIsTemplateDirty] = useState(false);
  const [client, setClient] = useState(
    inputSources ? (inputSources[0] ? inputSources[0] : "") : ""
  );
  const [showNameModal, setShowNameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [showScoresModal, setShowScoresModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [alertText, setAlertText] = useState("");
  const carePathTemplatePkg = useGetCarePathTemplatesByClient(
    [],
    carePathTemplateReducer
  );
  const carePathConstantsPkg = useGetCarePathTemplateConstants({});
  const [selected, setSelected] = useState(new Set());
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    carePathConstantsPkg.fetchData();
  }, []);

  useEffect(() => {
    carePathTemplatePkg.fetchData([client]);
    reset();
  }, [client]);

  if (templateQueue?.steps?.length && templateQueue.steps[0].isSubStep) {
    let templateClone = { ...templateQueue };
    templateClone.steps[0].isSubStep = false;
  }

  const reset = () => {
    setTemplateQueue(null);
    setIsTemplateDirty(false);
    setShowNameModal(false);
    setShowScoresModal(false);
  };

  const handleStepSelectChange = (idx) => (checked) => {
    const selectedClone = new Set(selected.values());

    if (checked) {
      selectedClone.add(idx);
    } else {
      selectedClone.delete(idx);
    }

    setSelected(selectedClone);
  };

  const handleStepChange = (idx) => (key, value) => {
    let templateClone = { ...templateQueue };
    switch (key) {
      case "title":
      case "completeBy":
      case "trigger":
      case "description":
      case "timing":
      case "required":
      case "isSubStep":
      case "needToScheduleAppointment":
        templateClone.steps[idx][key] = value;
        break;
      case "customStatuses":
      case "conditions":
        for (const objKey in value) {
          if (Object.hasOwnProperty.call(value, objKey)) {
            templateClone.steps[idx][objKey] = value[objKey];
          }
        }
        break;
      case "moveType":
        //value[1] = {idx: *, currentStepIsSubStep: *, swapWithIsSubStep: *, }
        if (
          value.moveType === MOVE_TYPES.MoveBefore ||
          value.moveType === MOVE_TYPES.MoveAfter
        ) {
          let newIdx = value.idx;

          const removedItem = templateClone.steps.splice(idx, 1)[0];

          templateClone.steps.splice(newIdx, 0, removedItem);
        } else if (value.moveType === MOVE_TYPES.Swap) {
          let tmp = templateClone.steps[idx];
          templateClone.steps[idx] = templateClone.steps[value.idx];
          templateClone.steps[value.idx] = tmp;
        }
        break;
      default:
        break;
    }
    setIsTemplateDirty(true);
    setTemplateQueue(templateClone);
  };
  //onTemplateChange
  const handleTemplateChange = (key, value) => {
    switch (key) {
      case "tinNumber":
      case "public":
      case "autoAssign":
      case "restricted":
      case "diagnosisSpecific":
      case "careTransitionPath":
      case "onlyOneActiveAtATime":
      case "active":
      case "autoAssignTrigger":
      case "riskLevel":
      case "diagnosis": {
        let templateClone = { ...templateQueue };
        templateClone[key] = value;
        setTemplateQueue(templateClone);
        break;
      }
      case "changeTemplate": {
        setTemplateQueue(
          cloneDeep(carePathTemplatePkg.state.data.find((x) => x.id === value))
        );
        break;
      }
      case "deleteStep": {
        let templateClone = { ...templateQueue };
        const selectedClone = new Set(selected.values());
        for (const idx of value) {
          templateClone.steps.splice(idx, 1);
          selectedClone.delete(idx);
        }
        setTemplateQueue(templateClone);
        setSelected(selectedClone);
        break;
      }
      case "addSubStep":
      case "addStep": {
        let templateClone = { ...templateQueue };
        let step = getDefaultStep(key === "addSubStep" ? true : false);
        if (!value) {
          templateClone.steps.push(step);
        } else {
          templateClone.steps.splice(value, 0, step);
        }
        setTemplateQueue(templateClone);
        break;
      }
      case "duplicateSelected": {
        let templateClone = { ...templateQueue };
        const selectedClone = new Set(selected.values());
        for (const idx of value) {
          let stepClone = cloneDeep(templateClone.steps[idx]);
          if (templateClone.steps[idx].isSubStep) {
            templateClone.steps.splice(idx + 1, 0, stepClone);
          } else {
            templateClone.steps.push(stepClone);
          }
          selectedClone.delete(idx);
        }
        setTemplateQueue(templateClone);
        setSelected(selectedClone);
        break;
      }
      case "deleteTemplate": {
        deleteCarePathTemplate(value)
          .then((res) => {
            let templateListClone = [...carePathTemplatePkg.state.data];
            templateListClone.splice(
              carePathTemplatePkg.state.data.findIndex((x) => x.id === value),
              1
            );
            setTemplateQueue(null);
            carePathTemplatePkg.dispatch({
              type: "Update",
              payload: templateListClone,
            });
          })
          .catch((err) => {
            errors.push(err + "");
            setErrors(errors);
          });
        break;
      }
      case "discardChanges": {
        setTemplateQueue(
          cloneDeep(carePathTemplatePkg.state.data.find((x) => x.id === value))
        );
        break;
      }
      default:
        break;
    }
    setIsTemplateDirty(true);
  };

  const showNewTemplateNameModal = () => {
    setNewTemplateName("");
    setShowNameModal(true);
  };

  const submitNewTemplate = () => {
    //Validation
    const errors = [];
    if (!newTemplateName) {
      errors.push(
        "Name must have a value. Please fill in a value for the Name field."
      );
    } else {
      if (carePathTemplatePkg.state.data && carePathTemplatePkg.state.data.some(x => x.name === newTemplateName)) {
        errors.push(
          `Template name "${newTemplateName}" is already taken.  Please choose a different name.`
        );
      }
    }

    if (isObjEmpty(errors)) {
      const templateClone = getDefaultTemplate(newTemplateName, client);
      const templateListClone = [...carePathTemplatePkg.state.data];
      templateListClone.push(templateClone);

      setIsTemplateDirty(false);
      setTemplateQueue(templateClone);
      setShowNameModal(false);
      carePathTemplatePkg.dispatch({
        type: "Update",
        payload: templateListClone,
      });
    } else {
      setErrors(errors);
    }
  };

  const submitTemplateChanges = (close) => {
    setIsSaving(true);
    const templateClone = { ...templateQueue };
    //Validation
    const errors = [];

    if (templateQueue.riskLevel == null) {
      errors.push("Risk Level is Required.");
    }
    if (templateQueue.diagnosisSpecific && !templateQueue.diagnosis) {
      errors.push(`Please select a value in the 'Diagnosis' drop down list or uncheck 'Diagnosis Specific'`);
    }
    for (const step of templateQueue.steps) {
      if (step.title == null || step.title === "") {
        errors.push(`Step ${step.uiStepIdxStr} is missing a Title.`);
      }
      if (step.completeBy == null || step.completeBy === "") {
        errors.push(`Step ${step.uiStepIdxStr} is missing a Complete By.`);
      }
      if (step.timing == null) {
        errors.push(`Step ${step.uiStepIdxStr} is missing Timing value.`);
      }
      if (step.trigger == null) {
        errors.push(`Step ${step.uiStepIdxStr} is missing Trigger value.`);
      }      
    }

    if (isObjEmpty(errors)) {
      //Populate Data

      const templateListClone = [...carePathTemplatePkg.state.data];
      const selectedTemplateIdx = templateListClone.findIndex(
        (x) => x.id === templateClone.id
      );

      let isTemplateNew = false;
      if (templateClone.id === NEW_TEMPLATE_ID) {
        delete templateClone.id;
        isTemplateNew = true;
      }

      saveCarePathTemplate(templateClone)
        .then((res) => {
          setIsTemplateDirty(false);
          templateClone.id = res.data.id;
          if (close) {
            setTemplateQueue(null);
          } else {
            setTemplateQueue(templateClone);
          }
          templateListClone[selectedTemplateIdx] = templateClone;
          carePathTemplatePkg.dispatch({
            type: "Update",
            payload: templateListClone,
          });
          setIsSaving(false);
        })
        .catch((err) => {
          if (isTemplateNew) {
            templateClone.id = NEW_TEMPLATE_ID;
          }
          setIsSaving(false);
          errors.push(getError(err));
          setErrors(errors);
        });
    } else {
      setIsSaving(false);
      setErrors(errors);
    }

    logDebug(() => templateClone);
    //setAlertText(JSON.stringify(templateClone, null, 2));
  };

  let stepsFilterValues = [];
  if (templateQueue && !carePathTemplatePkg.state.isLoading) {
    let mainStepIdx = 0;
    let subMainStepIdx = 1;
    for (let [idx, step] of templateQueue.steps.entries()) {
      let stepIdxStr = null;
      if (step.isSubStep) {
        stepIdxStr = mainStepIdx + "." + subMainStepIdx;
        subMainStepIdx += 1;
      } else {
        mainStepIdx += 1;
        subMainStepIdx = 1;
        stepIdxStr = mainStepIdx + "";
      }
      stepsFilterValues.push({
        stepIdxStr: stepIdxStr,
        stepIdx: parseFloat(stepIdxStr),
        idx,
        title: step.title,
        isSubStep: step.isSubStep,
        step,
      });
      step.uiStepIdxStr = stepIdxStr;
      step.uiStepIdx = parseFloat(stepIdxStr);
    }
  }

  return (
    <>
      <Modal
        show={showDeleteModal}
        onHide={(e) => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to delete this care path?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              setShowDeleteModal(false);
              handleTemplateChange("deleteTemplate", templateQueue.id);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="mt-3 mb-4 pb-3">
        <Card className="mb-3">
          <Card.Body>
            <EQHErrorToast
              id={`${componentName}-error-popup`}
              errors={errors}
              handleClose={() => setErrors([])}
            />
            <Row>
              <Col id={`${componentName}-clientCarePath`} className="mb-3">
                <label id={`${componentName}-clientLabel`} className="d-block">
                  Client
                </label>
                <SimpleDDL
                  id={`${componentName}-clientValue`}
                  list={inputSources}
                  textSelector={(x) => getDisplayValue(stateFullName,x)}
                  valueSelector={(x) => x}
                  onChange={(x) => setClient(x)}
                  selectedKey={client}
                />
              </Col>
              <Col id={`${componentName}-nameCarePath`} className="mb-3">
                <label id={`${componentName}-nameLabel`} className="d-block">
                  Name
                </label>
                <SimpleDDL
                  id={`${componentName}-nameValue`}
                  list={carePathTemplatePkg.state.data}
                  textSelector={(x) => x.name}
                  valueSelector={(x) => x.id}
                  onChange={(x) => handleTemplateChange("changeTemplate", x)}
                  selectedKey={templateQueue?.id}
                />
              </Col>
              {templateQueue && (
                <>
                  <Col
                    id={`${componentName}-risklevelCarePath`}
                    className="mb-3"
                  >
                    <label
                      id={`${componentName}-risklevelLabel`}
                      className="d-block"
                    >
                      Risk Level<span className="text-danger">*</span>
                    </label>
                    <SimpleDDL
                      id={`${componentName}-risklevelValue`}
                      list={carePathConstantsPkg.state.data.riskLevels}
                      textSelector={(x) => x.level}
                      valueSelector={(x) => x}
                      getKeyFromListItem={(item) => item?.id}
                      onChange={(x) => handleTemplateChange("riskLevel", x)}
                      selectedKey={templateQueue?.riskLevel?.id}
                    />
                  </Col>
                  {templateQueue.diagnosisSpecific && (
                    <Col
                      id={`${componentName}-diagnosisCarePath`}
                      className="mb-3"
                    >
                      <label
                        id={`${componentName}-diagnosisLabel`}
                        className="d-block"
                      >
                        Diagnosis
                      </label>
                      <SimpleDDL
                        id={`${componentName}-diagnosisValue`}
                        list={carePathConstantsPkg.state.data.allDiagnoses}
                        textSelector={(x) => x.description}
                        valueSelector={(x) => x}
                        onChange={(x) => handleTemplateChange("diagnosis", x)}
                        getKeyFromListItem={(x) => x?.id}
                        selectedKey={templateQueue?.diagnosis?.id}
                      />
                    </Col>
                  )}
                  <Col id={`${componentName}-tinCarePath`} className="mb-3">
                    <label id={`${componentName}-tinLabel`} className="d-block">
                      TIN Number
                    </label>
                    <Form.Control
                      id={`${componentName}-tinValue`}
                      type="text"
                      value={templateQueue.tinNumber}
                      onChange={(e) =>
                        handleTemplateChange("tinNumber", e.target.value)
                      }
                    />
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <Col className="mb-3">
                <Button
                  id={`${componentName}-newTemplateBtn`}
                  variant="secondary"
                  onClick={() => showNewTemplateNameModal()}
                >
                  New Template
                </Button>
                {templateQueue && (
                  <>
                    <div
                      id={`${componentName}-diagnosisSpecificCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-diagnosisSpecificCheck`}
                        checked={templateQueue.diagnosisSpecific}
                        onChange={(e) =>
                          handleTemplateChange(
                            "diagnosisSpecific",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        id={`${componentName}-diagnosisSpecificLabel`}
                        className="form-check-label"
                        htmlFor="DiagnosisSpecific"
                      >
                        Diagnosis Specific
                      </label>
                    </div>
                    <div
                      id={`${componentName}-publicCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-publicCheck`}
                        checked={templateQueue.public}
                        onChange={(e) =>
                          handleTemplateChange("public", e.target.checked)
                        }
                      />
                      <label
                        id={`${componentName}-publicLabel`}
                        className="form-check-label"
                        for="Public"
                      >
                        Public
                      </label>
                    </div>
                    <div
                      id={`${componentName}-activeCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-activeCheck`}
                        checked={templateQueue.active}
                        onChange={(e) =>
                          handleTemplateChange("active", e.target.checked)
                        }
                      />
                      <label
                        id={`${componentName}-activeLabel`}
                        className="form-check-label"
                        for="Active"
                      >
                        Active
                      </label>
                    </div>
                    <div
                      id={`${componentName}-careTransitionCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-careTransitionCheck`}
                        checked={templateQueue.careTransitionPath}
                        onChange={(e) =>
                          handleTemplateChange(
                            "careTransitionPath",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        id={`${componentName}-careTransitionLabel`}
                        className="form-check-label"
                        for="CareTransition"
                      >
                        Care Transition
                      </label>
                    </div>
                    <div
                      id={`${componentName}-oneActiveAtATimeCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-oneActiveAtATimeValueCheck`}
                        checked={templateQueue.onlyOneActiveAtATime}
                        onChange={(e) =>
                          handleTemplateChange(
                            "onlyOneActiveAtATime",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        id={`${componentName}-oneActiveAtATimeLabel`}
                        className="form-check-label"
                        for="OneActiveAtATime"
                      >
                        Only One Active At A Time
                      </label>
                    </div>
                    <div
                      id={`${componentName}-autoAssignCarePath`}
                      className="form-check form-check-inline"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`${componentName}-autoAssignValueCheck`}
                        checked={templateQueue.autoAssign}
                        onChange={(e) =>
                          handleTemplateChange("autoAssign", e.target.checked)
                        }
                      />
                      <label
                        id={`${componentName}-autoAssignLabel`}
                        className="form-check-label"
                        for="AutoAssign"
                      >
                        Auto-Assign
                      </label>
                    </div>
                  </>
                )}
              </Col>
              {templateQueue && templateQueue.autoAssign && (
                <Col id={`${componentName}-triggerCarePath`} className="mb-3">
                  <label
                    id={`${componentName}-triggerLabel`}
                    className="d-block"
                  >
                    Trigger
                  </label>
                  <SimpleDDL
                    id={`${componentName}-triggerValue`}
                    list={carePathConstantsPkg.state.data.triggers}
                    textSelector={(x) => x.text}
                    valueSelector={(x) => x.value}
                    onChange={(x) =>
                      handleTemplateChange("autoAssignTrigger", x)
                    }
                    selectedKey={templateQueue.autoAssignTrigger}
                  />
                </Col>
              )}
            </Row>
            {templateQueue && (
              <>
                <hr />
                <Row>
                  <Col
                    id={`${componentName}-stepActionsCarePath`}
                    className="mb-3"
                  >
                    <label
                      id={`${componentName}-stepActionsLabel`}
                      className="me-3"
                    >
                      Step Actions
                    </label>
                    <Button
                      id={`${componentName}-hideSelectedBtn`}
                      variant="secondary"
                      onClick={(e) =>
                        handleTemplateChange("deleteStep", selected)
                      }
                      disabled={selected.size === 0}
                    >
                      Hide Selected
                    </Button>
                    <Button
                      id={`${componentName}-duplicateSelectedBtn`}
                      variant="secondary"
                      onClick={(e) =>
                        handleTemplateChange("duplicateSelected", selected)
                      }
                      disabled={selected.size === 0}
                    >
                      Duplicate Selected
                    </Button>
                    <Button
                      id={`${componentName}-addStepBtn`}
                      variant="secondary"
                      onClick={(e) => handleTemplateChange("addStep")}
                    >
                      Add Step
                    </Button>
                  </Col>
                </Row>
                <Container fluid className="mb-3 p-0">
                  {templateQueue.steps.map((x, idx) => (
                    <StepCard
                      step={x}
                      stepIdx={idx}
                      stepsFilterValues={stepsFilterValues}
                      onChange={handleStepChange(idx)}
                      setErrors={setErrors}
                      carePathConstantsPkg={carePathConstantsPkg}
                      onTemplateChange={handleTemplateChange}
                      onSelectChange={handleStepSelectChange(idx)}
                      selected={selected}
                    />
                  ))}
                </Container>
                <Row>
                  <Col>
                    <Button
                      id={`${componentName}-deleteBtn`}
                      variant="danger"
                      onClick={() =>
                        setShowDeleteModal(true)
                      }
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col className="text-md-end">
                    <Button
                      id={`${componentName}-discardChangesBtn`}
                      variant="secondary"
                      onClick={() =>
                        handleTemplateChange("discardChanges", templateQueue.id)
                      }
                    >
                      Discard Changes
                    </Button>
                    <Button
                      id={`${componentName}-saveBtn`}
                      variant="primary"
                      disabled={isSaving}
                      onClick={() => submitTemplateChanges(false)}
                    >
                      {isSaving ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Saving...</span>
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>
                    <Button
                      id={`${componentName}-saveCloseBtn`}
                      variant="primary"
                      disabled={isSaving}
                      onClick={() => submitTemplateChanges(true)}
                      className="me-0"
                    >
                      {isSaving ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Saving...</span>
                        </>
                      ) : (
                        "Save & Close"
                      )}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
      <ReactAlert
        title={`Data That Would Be Sent To Backend`}
        onHide={() => setAlertText("")}
        text={alertText}
      />
      <Modal
        onHide={() => setShowNameModal(false)}
        show={showNameModal}
        size="xl"
        screenbool={true}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>New Template Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <label className="d-block">Name<span className="text-danger">*</span></label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  id={`${componentName}-newTemplateName-text`}
                  type="text"
                  className="mb-3"
                  value={newTemplateName ?? ""}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <EQHButton
            id={`${componentName}-newTemplateNameClose-btn`}
            onClick={() => setShowNameModal(false)}
          >
            Close
          </EQHButton>
          <EQHButton
            id={`${componentName}-newTemplateNameSubmit-btn`}
            onClick={submitNewTemplate}
          >
            Submit
          </EQHButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CarePathTemplate;
