import {Button, Col, Container, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import isEmpty from "is-empty";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import styled from "styled-components";

const componentName = "Workspace";

const COMPLETED = "COMPLETED";
const UNABLE_TO_COMPLETE = "UNABLE_TO_COMPLETE";
const RESCHEDULE = "RESCHEDULE";

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
const currentDate = new Date();

export const ResolveTaskModal = ({
    closeResolveTask,
    setDefaultMember,
    showResolveTask,
    openTaskModal,
    taskAction,
    task,
    reasons,
    save,
    filterTime,
    popperModifiers,
    minMinutes,
    maxMinutes,
    minHour,
    maxHour,
}) => {
    const [reason, setReason] = useState(null);
    const [notes, setNotes] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const onChangeReason = (event) => {
        setReason(event.target.value)
    }

    const onChangeNotes = (event) => {
        setNotes(event.target.value)
    }

    const onChangeDueDate = (date) => {
        if (date.getHours() < minHour) {
            date.setHours(minHour, minMinutes)
        }
        setDueDate(date)
    }

    const clearData = () => {
        setReason(null)
        setNotes(null)
        setDueDate(null)
    }

    const validateRequireFieldsByAction = () => {
        let error = null
        switch (taskAction.value) {
            case UNABLE_TO_COMPLETE:
                error = validateRequireFields([reason, notes])
                break
            case RESCHEDULE:
                error = validateRequireFields([reason, notes, dueDate])
                break
        }
        return error
    }

    const validateRequireFields = (fieldsRequired) => {
        if (fieldsRequired.some(field => isEmpty(field))) {
            return "All fields are required"
        }
        return null
    }

    const resolveTask = (e, addTask = false) => {
        let error = validateRequireFieldsByAction()
        let taskActionRequest = {
            reason,
            notes,
            dueDate,
            action: taskAction.value,
            taskId: task.taskId
        }
        save(taskActionRequest, error)
        if (!error) {
            clearData()
            closeResolveTask()
        }
        if (addTask && !error) {
            const {patientId, patientFullName, userAccountId} = task;
            setDefaultMember({
                patientId,
                patientFullName,
                userAccountId
            })
            openTaskModal();
        }
    }

    const showDueDate = (action) => {
        if (action && action.value === RESCHEDULE) {
            return (
                <Col className="mb-3" sm={12} md={3}>
                    <label id={`${componentName}-resolveTaskDueDateLabel`} className="d-block">
                        Due Date<span className="text-danger">*</span>
                    </label>
                    <DatePickerStyled>
                        <DatePicker
                            name={`${componentName}-rescheduleDueDate`}
                            selected={dueDate}
                            onChange={(date) => onChangeDueDate(date)}
                            value={dueDate}
                            filterTime={filterTime}
                            showTimeSelect
                            className="form-control"
                            inputProps={{
                                id: `${componentName}-rescheduleDateValue`
                            }}
                            minDate={currentDate}
                            minTime={setHours(setMinutes(currentDate, minMinutes), minHour)}
                            maxTime={setHours(setMinutes(currentDate, maxMinutes), maxHour)}
                            popperModifiers={popperModifiers}
                            autoComplete="off"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />

                    </DatePickerStyled>
                </Col>
            )
        }
    }

    const closeResolveTaskModal = () => {
        clearData()
        closeResolveTask()
    }

    return (
        <Container fluid className="px-4">
            <Modal size="lg" centered show={showResolveTask} onHide={closeResolveTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{taskAction && taskAction.text}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="mb-3" md={6}>
                            <label id={`${componentName}-resolveTaskReasonLabel`} className="d-block">
                                Resolution Type{ taskAction &&
                                        taskAction.value !== COMPLETED && <span className="text-danger">*</span> }
                            </label>
                            <select id={`${componentName}-resolveTaskReasonValue`} name="reason" value={reason}
                                    className="form-select" onChange={onChangeReason}>
                                <option id={`${componentName}-resolveTaskReasonOption`} value="" />
                                {!isEmpty(reasons) && reasons.map((reason, index) =>
                                    <option id={`${componentName}-resolveTaskReasonOption-${index}`} value={reason.value}>
                                        {reason.text}
                                    </option>
                                )}
                            </select>
                        </Col>
                        <Col className="mb-3" md={6}>
                            <label id={`${componentName}-resolveTaskNotesLabel`} className="d-block">
                                Notes{ taskAction &&
                                       taskAction.value !== COMPLETED && <span className="text-danger">*</span> }
                            </label>
                            <Form.Control id={`${componentName}-resolveTaskNotesValue`} type="text" onChange={onChangeNotes} />
                        </Col>
                        {taskAction && showDueDate(taskAction)}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeResolveTaskModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={resolveTask}>
                        Save
                    </Button>
                    {(taskAction?.value == 'COMPLETED' || taskAction?.value == "UNABLE_TO_COMPLETE") &&
                        <Button
                            variant="primary"
                            onClick={e => resolveTask(e, true)}
                            disabled = {!task?.patientId}
                        >
                            Save & +New task
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default ResolveTaskModal;