import React from "react";
import { Row, Col } from "react-bootstrap";

const componentName = "PatientDetailsNoteForm";

/**
 * PatientDetailsNoteForm component renders a form element for a single patient note.
 *
 * @param {string} elementPrefix - Prefix for the note element to ensure unique IDs.
 * @param {Object} note - Object containing the details of the note.
 * @returns {JSX.Element} A form group displaying the note.
 */
const PatientDetailsNoteForm = ({ elementPrefix, note }) => {
  const {author, subject, time, text} = note;

  return (
    <Row className="mb-2" controlId={`${componentName}-${elementPrefix}`}>
      <Col sm={2}>
        <Row>
          <Col className="d-block">
            <span className="d-block fw-bold small">{time}</span>
            <span className="d-block small">{author}</span>
          </Col>
        </Row>
      </Col>
      <Col sm={10} style={{ paddingLeft: "30px", paddingRight: "50px" }}>
        <Row>
          <Col className="d-block">
            <span className="d-block fw-bold">{subject}</span>
            <span className="d-block">{text}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PatientDetailsNoteForm;
