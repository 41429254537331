import {actions} from "./referralsActions";
import {
    findPayers, findProviders,
    findSpecialities, saveReferral,
    searchAssociatedProvider, searchICD10ByCode, searchICD10ByDescription,
    searchPracticeName,
} from "./referralsApi";
import {convertDateToString} from "../../../../../../utils/util";
import {setUserEventAction} from "../../../../../../actions/manageVisitActions";

export function getCallbacks(dispatch, state, patient, setNotification) {
    const toggleIsLoadingICD10Options = (value, index) => {
        const icd10List = state.icd10List.map(icd10 => ({...icd10}));
        icd10List[index].isLoading = value;
        return icd10List;
    }

    const getICD10Filled = () => {
        return state.icd10List.filter(icd10 => !!icd10?.code?.[0]).map(icd10 => ({
            code: icd10.code[0],
            description: icd10.description[0]
        }));
    }

    const getSelectedProvider = () => {
        return state.providers.find(provider => provider.id === state.selectedProvider);
    }

    return {
        getSpecialities: () => {
            dispatch({type: actions.TOGGLE_LOADING_SPECIALITIES, payload: true})
            findSpecialities(patient.id).then(response => {
                const specialities = response?.data?.map(speciality => ({
                    text: speciality.specialtyName, value: speciality.specialtyName,
                }));
                dispatch({type: actions.SET_SPECIALITIES, payload: specialities})
                dispatch({type: actions.TOGGLE_LOADING_SPECIALITIES, payload: false})
            }).catch(_ => {
                dispatch({type: actions.TOGGLE_LOADING_SPECIALITIES, payload: false})
                setNotification("Error", ["Error on search specialities"])
            });
        },
        getPayers: () => {
            dispatch({type: actions.TOGGLE_LOADING_PAYERS, payload: true})
            findPayers(patient.id).then(response => {
                const payers = response?.data?.map(payer => {
                    if (payer.toLowerCase() === patient?.payer?.toLowerCase()) {
                        const selectedPayer = {
                            paramValue: [payer], displayValue: [payer]
                        };
                        dispatch({type: actions.SET_SELECTED_PAYER, payload: selectedPayer});
                    }
                    return {
                        text: payer, value: payer,
                    }
                });
                dispatch({type: actions.SET_PAYERS, payload: payers});
                dispatch({type: actions.TOGGLE_LOADING_PAYERS, payload: false})
            }).catch(_ => {
                dispatch({type: actions.TOGGLE_LOADING_PAYERS, payload: false})
                setNotification("Error", ["Error on search payers"])
            });
        },
        asyncSearchPracticeName: query => {
            if (query) {
                dispatch({type: actions.TOGGLE_LOADING_PRACTICES, payload: true});
                const filters = [{
                    fieldName: "searchProviderPatientName", value: "", valuePresentation: patient.id,
                }];
                searchPracticeName(query, filters).then(response => {
                    dispatch({type: actions.SET_PRACTICES, payload: response?.data});
                    dispatch({type: actions.TOGGLE_LOADING_PRACTICES, payload: false});
                }).catch(_ => {
                    dispatch({type: actions.TOGGLE_LOADING_PRACTICES, payload: false});
                    setNotification("Error", ["Error on search practice name"])
                })
            }
        },
        asyncSearchAssociatedProvider: query => {
            if (query) {
                dispatch({type: actions.TOGGLE_LOADING_ASSOCIATED_PROVIDERS, payload: true});
                searchAssociatedProvider(query).then(response => {
                    dispatch({type: actions.SET_ASSOCIATED_PROVIDERS, payload: response?.data});
                    dispatch({type: actions.TOGGLE_LOADING_ASSOCIATED_PROVIDERS, payload: false});
                }).catch(_ => {
                    dispatch({type: actions.TOGGLE_LOADING_ASSOCIATED_PROVIDERS, payload: false});
                    setNotification("Error", ["Error on search associated providers"])
                })
            }
        },
        searchProviders: (filters, nextStep) => {
            findProviders(filters).then(response => {
                const data = response?.data;
                let providers = [];
                data.forEach(provider => {
                    provider.subItems.forEach(item => {
                        const providerData = {
                            id: item.id,
                            specialistName: item.name,
                            providerName: provider.name,
                            address: item.address,
                            city: item.city,
                            state: item.state,
                            zipCode: item.zipCode,
                            specialistPhoneNumber: item.phone,
                            organizationId: item.organizationId,
                            specialtyProviderId: item.specialtyProviderId,
                            parentPracticeName: item.parentPracticeName,
                            location: item.location,
                        };
                        providers.push(providerData);
                    });
                })
                dispatch({type: actions.SET_PROVIDERS, payload: providers})
                dispatch({type: actions.SET_CURRENT_SPECIALIST_REFERRAL_STEP, payload: nextStep})
            }).catch(_ => {
                setNotification("Error", ["Error on search providers"])
            })
        },
        asyncIcd10CodeSearch: (query, index) => {
            dispatch({type: actions.TOGGLE_LOADING_ICD10_OPTION, payload: toggleIsLoadingICD10Options(true, index)})
            searchICD10ByCode(query).then(response => {
                const options = response?.data?.map(icd10Code => ({
                    id: icd10Code.id,
                    code: icd10Code.code,
                    description: icd10Code.description
                }));
                dispatch({type: actions.SET_ICD10_OPTIONS, payload: options})
                dispatch({
                    type: actions.TOGGLE_LOADING_ICD10_OPTION,
                    payload: toggleIsLoadingICD10Options(false, index)
                })
            }).catch(error => {
                setNotification("Error", ["Error on search ICD 10 code"])
                dispatch({
                    type: actions.TOGGLE_LOADING_ICD10_OPTION,
                    payload: toggleIsLoadingICD10Options(false, index)
                })
            })
        },
        asyncIcd10DescriptionSearch: (query, index) => {
            dispatch({type: actions.TOGGLE_LOADING_ICD10_OPTION, payload: toggleIsLoadingICD10Options(true, index)})
            searchICD10ByDescription(query).then(response => {
                const options = response?.data?.map(icd10Code => ({
                    id: icd10Code.id,
                    code: icd10Code.code,
                    description: icd10Code.description
                }));
                dispatch({type: actions.SET_ICD10_OPTIONS, payload: options})
                dispatch({
                    type: actions.TOGGLE_LOADING_ICD10_OPTION,
                    payload: toggleIsLoadingICD10Options(false, index)
                })
            }).catch(error => {
                setNotification("Error", ["Error on search ICD 10 description"])
                dispatch({
                    type: actions.TOGGLE_LOADING_ICD10_OPTION,
                    payload: toggleIsLoadingICD10Options(false, index)
                })
            })
        },
        saveSpecialistReferral: (userName, globalDispatch) => {
            const visitExpirationDate = convertDateToString();
            const selectedProvider = getSelectedProvider()
            const referral = {
                patientId: patient?.id,
                referrerName: userName,
                sentByFullName: userName,
                refDateUTC: new Date(Date.now()).toISOString(),
                status: "50_PENDING",
                referralType: "SPECIALIST",
                visitExpirationDate,
                referredPractice: selectedProvider?.parentPracticeName,
                referredToPracticeId: selectedProvider?.organizationId,
                note: state.note,
                referredToPCPId: selectedProvider?.specialtyProviderId,
                visitType: state.selectedVisitType?.paramValue?.[0],
                urgency: state.selectedUrgency,
                files: state.uploadedFiles,
                referredPracticePhone: selectedProvider?.specialistPhoneNumber,
                referredPracticeAddress: selectedProvider?.location,
                diagnosisICD10dto: getICD10Filled()
            }
            saveReferral(referral).then(() => {
                dispatch({type: actions.TOGGLE_SPECIALIST_REFERRAL_SAVED, payload: true});
                globalDispatch(setUserEventAction('REFERRAL_ADDED'))
            }).catch(error => {
                dispatch({type: actions.TOGGLE_SPECIALIST_REFERRAL_SAVED, payload: false});
                setNotification("Error", ["Error on save referral"])
            })
        }
    }
}