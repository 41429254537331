import React, { Fragment, useRef } from "react";
import { Card } from "react-bootstrap";
import {
  saveCareManagement,
  getCareManagementAcceptanceTypes,
  getCareManagementStatuses,
  getAllUsersForHeadOrganization,
  getCareManagementPrograms,
} from "../../actions/patientProfileActions";
import { Button as OurButton } from "../../components/buttons";
import { EQHSpinner } from "../../components/EQHSpinner";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import styled, { css } from "styled-components";
import {
  DropDownList,
  DDLDataMode,
  DDLMode,
} from "../../components/dropDownLists/genericDDL";
import { FilterParam } from "../../dtos/PatientSearchDTO";

import DatePicker from "react-datepicker";

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const EditCareManagementModal = ({
  careManagementPkg,
  show,
  handleOnClose,
}) => {
};

export { EditCareManagementModal };
