import React, { useEffect, useState } from 'react'
import { useAssessmentsContext } from '../context/Assessments.context'
import { Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Timeline } from '../../../../../components/Timeline'
import AssessmentTimeLineTitle from './AssessmentsTimeLineTitle'
import AssessmentsCard from './AssessmentsCard'
import AddAssessment from './AddAssessment'
import axios from '../../../../../utils/apiClient'
import isEmpty from 'is-empty'
import SortMenu from './SortMenu'
import FilterBar from './FilterBar'
import { useAuth } from '../../../../../context/authContext'

const componentName = 'AssessmentsTab'
const baseURL = process.env.REACT_APP_REST_API_BASE_URL

export const sortItems = [
  {
    value: "Default",
    text: "Default",
    hasDivider: false,
    condition: true
  },
  {
    value: "Assessment Name (A-Z)",
    text: "Name (A-Z)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Assessment Name (Z-A)",
    text: "Name (Z-A)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Assessment Status (A-Z)",
    text: "Status (A-Z)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Assessment Status (Z-A)",
    text: "Status (Z-A)",
    hasDivider: false,
    condition: true
  },
]

function Assessments({ patient }) {
  const {
    setPatientId,
    assessments,
    toggleAddNewAssessment,
    setMode,
    setSelectedTemplate,
    setfillAssessment,
    setIndividualAssessmentCard,
    getAssessmentMutation,
    totalSize
  } = useAssessmentsContext();
  const auth = useAuth();

  const [templateList, setTemplateList] = useState([]);
  const hasWriteAccess = auth.hasAuthority("AUTH_PD_ASSESSMENTS_WRITE_ACCESS");
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  const getTemplateList = () => {
    axios
      .get(baseURL + `/v1/assessments/patient-templates?patientId=${patient?.id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data && !isEmpty(response.data)) {
          setTemplateList(response.data);
        }
      })
  };

  useEffect(() => {
    setPatientId(patient?.id)
    getTemplateList()
  }, [])

  const handleChangeTemplate = (template) => {
    const {templateName, templateId} = template
    setSelectedTemplate({ templateName, templateId });
    setIndividualAssessmentCard({});
    setMode("new");
    setfillAssessment(true);
    toggleAddNewAssessment(true);
  };

  const handleFilterSort = (pageNumber, filterParams, sortParams) => {
    getAssessmentMutation({
      pageNumber,
      filterParams,
      sortParams,
      patientId: patient?.id,
      pageSize: 20
    })
  }

  useEffect(() => {
    if (assessments) {
      setOpenStates(prevStates => {
        const newStates = new Array(assessments.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [assessments])

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  return (
    <>
      <Row className="align-items-center px-3">
        <Col className="d-flex">
          <FilterBar handleFilterSort={handleFilterSort} />
          <SortMenu items={sortItems} refreshPatientList={handleFilterSort} />
        </Col>
        <Col className="text-end pe-0">
          <Dropdown className="dropdown-end">
            <Dropdown.Toggle
              variant="primary"
              id={`${componentName}-AssessmentsActions`}
              disabled={!hasWriteAccess}
              style={{
                backgroundColor: !hasWriteAccess ? '#CBCDCE' : '#007db7',
                border: 'none',
                fontWeight: '500'
              }}
            >Add New Assessment
            </Dropdown.Toggle>
            <Dropdown.Menu align='end' className="dropdown-menu-aligned">
              {templateList.map(template => {
                return <Dropdown.Item
                  id={`${componentName}-actionsBtnItems-${template?.id}`}
                  key={template.id}
                  onClick={e => handleChangeTemplate(template)}
                >{template.templateName}</Dropdown.Item>
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className='align-items-baseline'>
        <Col>
            <span
              bg="light"
              id={`assessmentsEventsCount`}
              className="border text-dark badge badge-pill mt-4">
              {totalSize}{" "}{"Assessment"}{totalSize === 1 ? '' : 's'}
            </span>
        </Col>
        <Col md={6} className='text-end'>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip>
                Expand / Collapse All
              </Tooltip>
            }
          >
            <span
              bg="light"
              className="border text-dark badge badge-pill mt-2"
              onClick={() => toggleAll(!expandAll)}
              style={{maxWidth: 'fit-content', fontSize: '12px'}}
            >
              <i className='fas fa-angle-double-up'/>
              <i className='fas fa-angle-double-down'/>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      <AddAssessment />
      <Row className={`${componentName}-view`}>
        <Timeline
          items={assessments}
          titleBuilder={(assessment, idx) => <AssessmentTimeLineTitle
            assessment={assessment}
            idx={idx} />}
          bodyBuilder={(assessment, idx) =>
            <AssessmentsCard
              toggleIndividual={() => toggleIndividual(idx)}
              open={openStates[idx]}
              assessment={assessment}
              idx={idx}
            />
          }/>
      </Row>
    </>
  )
}

export default Assessments