import React, { useState, Fragment, useRef, useEffect } from "react";

import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import { getNestedValues } from "../../utils/util";
import Spinner from "react-bootstrap/Spinner";
import { InputGroup, Overlay, Popover } from "react-bootstrap";
import { emptyFilterParam, FilterParam } from "../../dtos/PatientSearchDTO";
import { Filter } from "../filters/SideFilters/components";
import '../filters/SideFilters/styles/DDL.css';

const componentName = "GenericDDL";
const GENERIC_DDL_TYPE = "GenericDDL";

const DDLDataMode = {
  // This will get all data with 1 api call and filter in the UI.
  // This is default mode and should be used if data set is like under 2,000 records
  // This mode will also limit backend calls
  OneAPICallFilterInUI: "OneAPICallFilterInUI",
  // This will make an api call everytime a user searches(uses filter)
  // Should use this if dataset is too big like over 2,000 records
  APICallOnEveryFilter: "APICallOnEveryFilter",
};

const DDLMode = {
  SelectOne: "SelectOne",
  MultipleSelect: "MultipleSelect",
};

function makeDelay(ms) {
  var timer = 0;
  return function (callback) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}

const delayFunctionCall = makeDelay(1600);

const DropDownList = ({
  header,
  getData,
  handleChange,
  showSearchTextbox = true,
  selected,
  dataMode = DDLDataMode.OneAPICallFilterInUI,
  mode = DDLMode.MultipleSelect,
  checkboxFilters, //{text: Only Provider Care, filter: (item) => }
  showRequired,
  showClearButton = true,
  removeSelected,
  removeStateHandler = false,
  externalDataMap,
  onLoad,
  refreshDataOnOpen = false,
  refreshDataOnStateChange = null,
  idx,
  showSelectClearAllButton = true,
}) => {
  const handlePassedInSelected = () => {
    const selectedMap = new Map();
    if (selected && selected.paramValue) {
      for (let i = 0; i < selected.paramValue.length; i++) {
        if (selected.displayValue) {
          selectedMap.set(selected.paramValue[i], {
            value: selected.paramValue[i],
            text: selected.displayValue[i],
          });
        } else {
          selectedMap.set(selected.paramValue[i], {
            value: selected.paramValue[i],
            text: selected.paramValue[i],
          });
        }
      }
    }
    return selectedMap;
  };

  const [mySelected, setMySelected] = useState(selected);
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMap, setSelectedMap] = useState(handlePassedInSelected);
  const [searchText, setSearchText] = useState("");
  const [getDataOnFirstOpen, setGetDataOnFirstOpen] = useState(true);
  const searchTextboxRef = useRef(null);
  const [chkFilterFuncSet, setChkFilterFuncSet] = useState(new Set());
  const [loadingData, setLoadingData] = useState(false);
  const [dataErrorText, setDataErrorText] = useState("");
  const idFragment = header ? header.replace(/ /g, "_").toUpperCase() : "";

  useEffect(() => {
    refreshDataOnStateChange && getData()
      .then((data) => {
        setList(data);
        setLoadingData(false);
        setDataErrorText("");
      });
  }, [refreshDataOnStateChange])

  const getOnChangeDTO = (selectedMap) => {
    if (!selectedMap || selectedMap.size === 0) {
      return emptyFilterParam(header);
    }

    return new FilterParam(
      header,
      "eq",
      Array.from(selectedMap.values()),
      (x) => x.value,
      (x) => x.text
    );
  };

  const setSelectedSetCallOnChange = (selectedMap) => {
    setSelectedMap(selectedMap);
    handleChange(getOnChangeDTO(selectedMap));
  };

  if (selected !== mySelected) {
    setSelectedMap(handlePassedInSelected());
    setMySelected(selected);
  }

  if (onLoad) {
    onLoad(externalDataMap, selectedMap, setSelectedSetCallOnChange);
  }

  const handleToggle = (ddlOpen, event, metadata) => {
    setIsOpen(ddlOpen);

    switch (dataMode) {
      case DDLDataMode.APICallOnEveryFilter:
        break;
      case DDLDataMode.OneAPICallFilterInUI:
      default:
        if (ddlOpen && getDataOnFirstOpen) {
          setLoadingData(true);
          getData()
            .then((data) => {
              setList(data);
              setLoadingData(false);
              if (!refreshDataOnOpen) {
                setGetDataOnFirstOpen(false);
              }
              setDataErrorText("");
            })
            .catch((err) => {
              if (err.response) {
                setDataErrorText(
                  "Error! Please close and open drop down list again"
                );
              } else if (err.request) {
                setDataErrorText(
                  "Error! Please close and open drop down list again"
                );
              } else {
                setDataErrorText(
                  "Error! Please close and open drop down list again"
                );
              }
              setLoadingData(false);
            });
        }
    }
    //event.persist();
  };

  useEffect(() => {
    selectedMap.size === 0 && setSearchText('')
  }, [selectedMap])

  useEffect(() => {
    if (isOpen && searchTextboxRef.current) {
      if (refreshDataOnOpen) {
        searchTextboxRef.current.value = "";
        clearTextbox();
      }
      searchTextboxRef.current.focus();
    }
  }, [isOpen]);

  const itemClicked = (item, checked) => {
    switch (mode) {
      case DDLMode.SelectOne:
        //document.dispatchEvent(new MouseEvent("click"));
        setSelectedSetCallOnChange(new Map([[item.value, item]]));
        break;
      case DDLMode.MultipleSelect:
      default:
        let copyOfMap = new Map(selectedMap);
        let values = [];
        if (item.subItems) {
          Array.from(
            getNestedValues(
              item,
              new Set(),
              (propertyName, level, obj) =>
                !(
                  obj.hasOwnProperty("subItems") &&
                  Array.isArray(obj["subItems"] && obj["subItems"].length > 0)
                ) &&
                isMatch(searchText, obj) &&
                obj.value
            )
          ).forEach((item) => values.push(item));
        } else {
          values.push(item);
        }
        if (checked) {
          if (item.isOtherSelectItem) {
            const valsToDel = [];
            copyOfMap.forEach((value, key, map) => {
              if (value.isOtherSelectItem) {
                valsToDel.push(key);
              }
            });
            for (let valToDel of valsToDel) {
              copyOfMap.delete(valToDel);
            }
            copyOfMap.set(item.value, { ...item });
          } else {
            values.forEach((x) =>
              copyOfMap.set(x.value, { value: x.value, text: x.text })
            );
          }
          setSelectedSetCallOnChange(copyOfMap);
        } else {
          values.forEach((x) =>
            copyOfMap.delete(item.isOtherSelectItem ? x.text : x.value)
          );
          setSelectedSetCallOnChange(copyOfMap);
        }
    }
  };

  const selectedAll = () => {
    let copyOfMap = new Map();
    Array.from(
      getNestedValues(
        list,
        new Set(),
        (propertyName, level, obj) =>
          !(
            obj.hasOwnProperty("subItems") &&
            Array.isArray(obj["subItems"] && obj["subItems"].length > 0)
          ) &&
          isMatch(searchText, obj) &&
          obj.value
      )
    ).forEach((item) =>
      copyOfMap.set(item.value, { value: item.value, text: item.text })
    );
    setSelectedSetCallOnChange(copyOfMap);
  };

  const clearAll = () => {
    let emptySet = new Map();
    setSelectedSetCallOnChange(emptySet);
  };

  const onTextChange = (searchText) => {
    searchText.length <= 100 && setSearchText(searchText);
    switch (dataMode) {
      case DDLDataMode.APICallOnEveryFilter:
        if (searchText && searchText.length > 2) {
          setLoadingData(true);
          delayFunctionCall(() =>
            getData(searchText).then((data) => {
              setList(data);
              setLoadingData(false);
            })
          );
        } else {
          setList([]);
        }
        break;
      case DDLDataMode.OneAPICallFilterInUI:
      default:
    }
  };

  const clearTextbox = () => {
    setSearchText("");
  };

  const isMatch = (searchText, item) => {
    let chkFilterResult = true;
    let searchTextResult = true;
    if (item) {
      if (chkFilterFuncSet.size > 0) {
        switch (dataMode) {
          case DDLDataMode.APICallOnEveryFilter:
          case DDLDataMode.OneAPICallFilterInUI:
          default:
            chkFilterResult = Array.from(chkFilterFuncSet).every((func) =>
              func(item)
            );
        }
      }
      if (searchText) {
        switch (dataMode) {
          case DDLDataMode.APICallOnEveryFilter:
            searchTextResult = true;
            break;
          case DDLDataMode.OneAPICallFilterInUI:
          default:
            searchTextResult =
              item.text &&
              item.text.toLowerCase().includes(searchText.toLowerCase());
        }
      }
    }
    return chkFilterResult && searchTextResult;
  };

  const onChkFilterChange = (chkFilter, checked) => {
    let copyOfChkFilterFuncSet = new Set(chkFilterFuncSet);
    if (checked) {
      copyOfChkFilterFuncSet.add(chkFilter.filter);
    } else {
      copyOfChkFilterFuncSet.delete(chkFilter.filter);
    }
    setChkFilterFuncSet(copyOfChkFilterFuncSet);
  };

  const closedAndHasSelectedData = () => {
    return selectedMap.size;
  };

  const HeaderComponent = () => {
    return (
      <label
        id={`${componentName}-${idFragment}-filterTitle`}
        className="d-block"
      >
        <span id={`${componentName}-${idFragment}-filterTitleText`}>
          {header?.replace(/\d+/g, '')}
        </span>
        {showRequired && (
          <span
            id={`${componentName}-${idFragment}-filterRequired`}
            className="text-danger"
          >
            {" "}
            *
          </span>
        )}
        {showClearButton && closedAndHasSelectedData() ? (
          <i id={`${componentName}-${idFragment}-filterReset`} className="fas fa-circle fa-2xs cursor-p d-inline-block ms-2 me-0 text-primary"></i>
        ) : (
          ""
        )}
      </label>
    )
  }

  return (
    <Filter
      onToggle={handleToggle}
      index={`${componentName}-${idFragment}-filterDropdown`}
      header={<HeaderComponent />}
      componentName={componentName}
      idFragment={idFragment}
    >
      <div>
        {showSearchTextbox && (
          <InputGroup>
            {
                (!searchText || searchText?.length <= 0) &&
                <div className="search-icon" style={{padding: "5px"}}>
                  <i className="fas fa-search" style={{color: "#6c757d"}}/>
                </div>
            }
            <Form.Control
              type="text"
              id={`${componentName}-${idFragment}-filterKeywordSearch`}
              value={searchText}
              onChange={(e) => onTextChange(e.target.value)}
              placeholder={"Search"}
              ref={searchTextboxRef}
            />
            {showClearButton &&
              <InputGroup.Text
                  id={`${componentName}-${idFragment}-filterClear`}
                  onClick={() => clearTextbox()}
                  style={{cursor: 'pointer'}}
              >
                Clear
              </InputGroup.Text>
            }
          </InputGroup>
        )}
        {mode !== DDLMode.SelectOne && showSelectClearAllButton && (
            <div className="mb-3">
              <Button
                  id={`${componentName}-${idFragment}-filterSelectAll`}
                  variant="link"
                  onClick={selectedAll}
                  className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none me-2"
              >
                Select All
              </Button>    &nbsp;
              <Button
                  id={`${componentName}-${idFragment}-filterClearAll`}
                  variant="link"
                  onClick={clearAll}
                  className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none"
              >
                Clear All
              </Button>
            </div>
        )}
        {checkboxFilters &&
          checkboxFilters.map((x) => (
            <div
              className="form-check form-switch"
              id={`${componentName}-${idFragment}Item-${x.text.replaceAll(" ", "")}`}
            >
              <input className="form-check-input" type="checkbox"
                checked={chkFilterFuncSet.has(x.filter)}
                onChange={(e) => onChkFilterChange(x, e.target.checked)}
                key={`${componentName}-${idFragment}Item-${x.text.replaceAll(" ", "")}Value`}
                id={`${componentName}-${idFragment}Item-${x.text.replaceAll(" ", "")}Value`} />
              <label className="form-check-label"
                for={`${componentName}-${idFragment}Item-${x.text.replaceAll(" ", "")}Value`}
                id={`${componentName}-${idFragment}Item-${x.text.replaceAll(" ", "")}Label`} >
                {x.text}
              </label>
            </div>
          ))}
        {loadingData ? (
          <div>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : dataErrorText ? (
          <div>{dataErrorText}</div>
        ) : (
          <div style={{marginTop:showSelectClearAllButton ? '0px' : '10px'}}>
            {list.map((item) => (
              <DDLItems
                item={item}
                searchText={searchText}
                handleChange={itemClicked}
                selectedMap={selectedMap}
                level={0}
                mode={mode}
                isMatch={isMatch}
                idx={idx}
                header={header}
              />
            ))}
          </div>
        )}
      </div>
    </Filter>
  );
};

const DDLItems = ({
  item,
  searchText,
  handleChange,
  selectedMap,
  level,
  isMatch,
  mode,
  header,
  closeAllParentPopovers,
  idx,
}) => {
  const [otherChkBox, setOtherChkBox] = useState(false);
  const [otherTB, setOtherTB] = useState("");
  const nestedSSRefMap = useRef({});
  const [nestedSSMap, setNestedSSMap] = useState({});

  const addNestedSSEl = (key, el) => {
    nestedSSRefMap.current[key] = el;
  };

  const getNestedSSEl = (key) => {
    return nestedSSRefMap.current[key];
  };

  const closeAllPopovers = () => {
    const nestedSSMapClone = { ...nestedSSMap };
    for (const key in nestedSSMapClone) {
      if (Object.hasOwnProperty.call(nestedSSMapClone, key)) {
        nestedSSMapClone[key] = false;
      }
    }
    setNestedSSMap(nestedSSMapClone);
    if (closeAllParentPopovers) {
      closeAllParentPopovers();
    }
  };

  const id = `${item.text.replaceAll(" ", "_").toUpperCase()}`;
  const idFragment = header.replaceAll(" ", "_").toUpperCase();
  const marginSize = (level) * 20;

  if (item && !item.subItems && isMatch(searchText, item, idx)) {
    return mode === DDLMode.MultipleSelect ? (
      !item.isOtherSelectItem ? (
        <Form.Check
          type="checkbox"
          style={{ marginLeft: marginSize + "px" }}
          key={`${componentName}-${idFragment}-Item-${id}`}
          id={`${componentName}-${idFragment}-Item-${id}`}
          label={item.text}
          onChange={(e) => handleChange(item, e.target.checked)}
          checked={selectedMap.has(item.value)}
          level={level}
        />
      ) : (
        <>
          <Form.Check
            type="checkbox"
            style={{ marginLeft: marginSize + "px" }}
            key={`${componentName}-${idFragment}-Item-${id}`}
            id={`${componentName}-${idFragment}-Item-${id}`}
            label="Other"
            onChange={(e) => {
              (!e.target.checked || otherTB) &&
                handleChange(
                  { text: "Other", value: otherTB, isOtherSelectItem: e.target.checked },
                  e.target.checked
                );
              setOtherChkBox(e.target.checked);
            }}
            checked={otherChkBox}
            level={level}
          />
          <Form.Control
            maxLength="50"
            disabled={!otherChkBox}
            type="text"
            value={otherChkBox ? otherTB : ""}
            id={`${componentName}-${idFragment}-Item-Other`}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                handleChange(
                  {
                    text: "Other",
                    value: e.target.value,
                    isOtherSelectItem: true,
                  },
                  otherChkBox
                );
              } else {
                handleChange(
                  { text: "Other", value: otherTB, isOtherSelectItem: false },
                  false
                );
              }
              setOtherTB(e.target.value);
            }}
          />
        </>
      )
    ) : (
      <Form.Check
        key={`${componentName}-${idFragment}-Item-${id}`}
        id={`${componentName}-${idFragment}-Item-${id}`}
        label={item.text}
        type='radio'
        level={level}
        checked={selectedMap.has(item.value)}
        onClick={(e) => {
          handleChange(item, e.target.checked);
          closeAllPopovers();
        }}
      />
    );
  } else if (item && item.subItems) {
    const validSubItems = item.subItems.filter(
      (subItem) => subItem && isMatch(searchText, subItem)
    );

    return (
      validSubItems.length > 0 && (
        <Fragment>
          {mode === DDLMode.MultipleSelect ? (
            <>
              <Form.Check
                key={`${componentName}-${idFragment}-Item-${id}`}
                id={`${componentName}-${idFragment}-Item-${id}`}
                level={level}
                type="checkbox"
                style={{ marginLeft: marginSize + "px" }}
                label={item.text}
                checked={Array.from(
                  getNestedValues(
                    item,
                    new Set(),
                    (propertyName, level, obj) =>
                      !(
                        obj.hasOwnProperty("subItems") &&
                        Array.isArray(
                          obj["subItems"] && obj["subItems"].length > 0
                        )
                      ) &&
                      isMatch(searchText, obj) &&
                      obj.value
                  )
                ).every((x) => selectedMap.has(x.value))}
                onChange={(e) => handleChange(item, e.target.checked)}
              />
              {validSubItems.map((item) => (
                <DDLItems
                  item={item}
                  searchText={searchText}
                  handleChange={handleChange}
                  selectedMap={selectedMap}
                  level={level + 1}
                  mode={mode}
                  isMatch={isMatch}
                  header={header}
                  idx={idx}
                />
              ))}
            </>
          ) : (
            <>
              <div
                key={`${id}-div`}
                id={`${id}-div`}
                className="dropdown-item fw-bold"
                ref={(el) => addNestedSSEl(id, el)}
                onMouseEnter={() =>
                  setNestedSSMap({
                    ...nestedSSMap,
                    [id]: true,
                  })
                }
                onMouseLeave={() =>
                  setNestedSSMap({
                    ...nestedSSMap,
                    [id]: false,
                  })
                }
              >
                {item.text}
                <i className="fa fa-chevron-right float-end" />
              </div>
              <Overlay
                key={`${id}-ol`}
                id={`${id}-ol`}
                rootClose
                onHide={() =>
                  setNestedSSMap({
                    ...nestedSSMap,
                    [id]: false,
                  })
                }
                target={getNestedSSEl(id)}
                show={nestedSSMap[id]}
                placement="right"
              >
                <Popover
                  key={`${id}-po`}
                  id={`${id}-po`}
                  onMouseEnter={() =>
                    setNestedSSMap({
                      ...nestedSSMap,
                      [id]: true,
                    })
                  }
                  onMouseLeave={() =>
                    setNestedSSMap({
                      ...nestedSSMap,
                      [id]: false,
                    })
                  }
                >
                  <Popover.Body>
                    {validSubItems.map((item) => (
                      <DDLItems
                        key={`${componentName}-ddlSubItem-${item.text}-${idx}`}
                        item={item}
                        searchText={searchText}
                        handleChange={handleChange}
                        selectedMap={selectedMap}
                        level={1}
                        mode={mode}
                        isMatch={isMatch}
                        header={header}
                        closeAllParentPopovers={closeAllPopovers}
                        idx={idx}
                      />
                    ))}
                  </Popover.Body>
                </Popover>
              </Overlay>
            </>
          )}
        </Fragment>
      )
    );
  } else {
    return <div></div>;
  }
};

export { DropDownList, DDLDataMode, DDLMode, GENERIC_DDL_TYPE };
