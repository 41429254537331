import {isEmpty, trim} from "lodash";

export const mapFiltersToSearch = (filtersParams) => {
  const FILTERS_KEYS_MAP = {
    'Birth Date Comp': 'Birth Date',
    'PCP Filter On Full Name': 'PCP',
    'Patient Name': 'Member Name',
    'Care Program Step Due Date': 'Due Date'
  }
  return mapFilters(filtersParams, FILTERS_KEYS_MAP);
}
export const mapFiltersSelected = (filtersParams) => {
  const FILTERS_KEYS_MAP = {
    'Birth Date': 'Birth Date Comp',
    'PCP': 'PCP Filter On Full Name',
    'Member Name': 'Patient Name',
    'Due Date': 'Care Program Step Due Date'
  }
  return mapFilters(filtersParams, FILTERS_KEYS_MAP);
}

const mapFilters = (filtersParams, filtersKeysMap) => {
  return filtersParams.map(filterParam => {
    const filter = {...filterParam};
    if (!isEmpty(trim(filtersKeysMap[filter.paramName]))) {
      filter.paramName = filtersKeysMap[filter.paramName]

      // In most cases, the date component does not contain values in the displayValue field,
      // this causes the filter to not work correctly as described in the VEGA-13734 bug,
      // this solution solves the problem, however, I think a better solution would go in the base filterGroupNew component,
      // the reason it is not addressed there is that its impact is unknown
      if (filter.paramName === 'Birth Date Comp') {
        filter.displayValue = filter.paramValue;
      }
    }
    return filter;
  });
}

