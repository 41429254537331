import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const componentName = "PatientDetailsFieldForm";

/**
 * PatientDetailsFieldForm component renders a single field of patient information.
 * It handles special text formatting, making parts of the text italic if marked as "(preferred)".
 * 
 * @param {string} elementPrefix - Prefix for the form element to ensure unique IDs.
 * @param {string} textLabel - The label text for the form field.
 * @param {string} textValue - The value of the form field, which may include "(preferred)".
 * @returns {JSX.Element} A form group displaying a label and its associated value with special formatting.
 */
const PatientDetailsFieldForm = ({ elementPrefix, textLabel, textValue }) => {
  const textValueSplitted = textValue.split(/(\(preferred\))/g);
  return (
    <Form.Group as={Row} className="mb-2" controlId={`${componentName}-${elementPrefix}`}>
      <Form.Label id={`${componentName}-${elementPrefix}Label`} column sm={4}>
        {textLabel}
      </Form.Label>
      <Col sm={8} className="d-flex align-items-center">
        <span id={`${componentName}-${elementPrefix}Value`} style={{ whiteSpace: 'pre-line' }}>
          {textValueSplitted.map(
            (textValue, index) =>
              textValue === "(preferred)" ? <i key={`${componentName}-${elementPrefix}Value-${index}`}>
                {textValue}
              </i> : textValue
          )}
        </span>
      </Col>
    </Form.Group>
  );
};

export default PatientDetailsFieldForm;
