import React, { useEffect, useRef, useState } from 'react'
import { getDailyTicker, searchProgramLead } from '../../../../../actions/caremgmt/hubWorkCenterAction';
import Modals from '../../../../../components/modals';
import { useAuth } from '../../../../../context/authContext';
import { defaultState, reassignMember } from '../utils';
import ReassignBody from './ReassignBody';

const ReassignModal = ({
  showReassignModal,
  setShowReassignModal,
  memberSelected,
  refreshMembers,
  viewAsPL,
  onError
}) => {
  const [data, setData] = useState({...defaultState});
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [teamSelected, setTeamSelected] = useState('');
  const [programLeads, setProgramLeads] = useState([]);
  const ref = useRef();

  let auth = useAuth();

  useEffect(() => {
    setData({...defaultState})
    getDailyTicker().then(res => setTeamList(res?.data))
  }, []);

  useEffect(() => {
    setData({
      ...defaultState,
      patientId: memberSelected?.patientId,
      userId: viewAsPL?.userAccountId || auth.getUserId(),
      patientProgramId: memberSelected?.patientProgramId,
      programName: memberSelected?.programName,
      currentProgramLeadId: memberSelected?.programLeadId
    })
  }, [memberSelected]);

  const onChangeTeam = (event) => {
    const team = teamList.find((team) => team.teamId == event.target?.value);
    setTeamSelected(team);
  }

  const onChangeProgramLead = (event) => {
    if (event && event[0]) {
        const programLead = event[0];
        if (programLead?.value == data?.currentProgramLeadId) {
          onError({
            message: `The member has already been assigned to program ${data?.programId} and program lead ${programLead?.text}`
          })
          ref.current.clear();
          setData({
            ...data,
            programLeadId: ''
          })
        } else {
          setData({
              ...data,
              programLeadId: programLead.value
          });
          onError([])
        }
    }
  }

  const asyncSearch = async (name) => {
    const timeout = setTimeout(async () => {
        if (name) {
          setIsLoading(true)
          searchProgramLead(name, teamSelected?.teamId)
              .then(response => {
                setProgramLeads(response.data)
                setIsLoading(false)
              })
              .catch(() => setIsLoading(false))
        }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    reassignMember(data)
      .then(res => {
        setIsLoading(false);
        setShowReassignModal(false);
        refreshMembers();
      })
      .catch(error => {
        setIsLoading(false);
        setShowReassignModal(false);
        refreshMembers();
        onError(error);
      })
  }

  return (
    <Modals
      scrollable={true}
      referralHeight={true}
      show={showReassignModal}
      closeSubmitModal={() => setShowReassignModal(false)}
      onHide={() => setShowReassignModal(false)}
      onCreate={() => handleSubmit()}
      disabledClickButton={data.programLeadId === '' || data.notes === '' || isLoading}
      size="xl"
      title="Reassign Referral"
      completeBtn="Submit"
      closeBtnId={'reassignModal-close-btn'}
      completeBtnId={'reassignModal-save-btn'}
      hideFooter={false}
      centered={true}
      loading={isLoading}
      body={
        <ReassignBody
          data={data}
          setData={setData}
          teamList={teamList}
          onChangeTeam={onChangeTeam}
          teamSelected={teamSelected}
          asyncsearch={asyncSearch}
          onChangeProgramLead={onChangeProgramLead}
          programLeads={programLeads}
          ref={ref}
        />
      }
    />
  )
}

export default ReassignModal;