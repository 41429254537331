import React, { useEffect, Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import {
  saveNotificationSettings,
  useGetNotificationSettings,
} from "../../actions/accountSettingsAction";
import Toast from "react-bootstrap/Toast";

const componentName = "NotificationSettings";
const isEmpty = require("is-empty");

const CapitalizingInput = styled.input`
  text-transform: capitalize;
`;

const notificationSettingsReducer = (state, action) => {
  switch (action.type) {
    case "Update":
      const newState = { ...state, data: action.payload };
      return newState;
    default:
      throw new Error();
  }
};

const initialState = {
  messageType: [],
  email: false,
  voice: false,
  sms: false,
  newPatientNotification: false
};

const NotificationSettings = () => {
  const notificationSettingsPkg = useGetNotificationSettings(initialState, notificationSettingsReducer);

  const [show, setShow] = useState(false);

  const save = (notificationSettings) => {
    saveNotificationSettings(notificationSettings)
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    notificationSettingsPkg.fetchData();
  }, []);

  const isChecked = (state, key) => {
    return state.messageType && state.messageType.length != null ? state.messageType.includes(key) === true : false;
  };

  const onChanged = (e, state, dispatch, key) => {
    const newState = Object.assign({}, state);
    if (newState.messageType.includes(key)) {
      newState.messageType.splice(newState.messageType.indexOf(key), 1);
    } else {
      newState.messageType.push(key);
    }

    dispatch({
      type: "Update",
      payload: newState,
    });
  };

  const onSimpleChanged = (e, name) => {
    const newState = {...notificationSettingsPkg.state.data, [name]: e.target.checked}
    notificationSettingsPkg.dispatch({
      type: "Update",
      payload: newState,
    });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <p id={`${componentName}-messageNotificationsTitle`} className="fw-bold">
                Message Notifications
              </p>
              <p id={`${componentName}-messageNotificationsInstr`}>
                Please indicate which of the following new Message Types you
                would like to be notified of
              </p>
              <Form.Check id={`${componentName}-careManagement`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Care Management")}
                  checked={isChecked(notificationSettingsPkg.state.data, "Care Management")}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Care Management"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-careManagementLabel`}>Care Management</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-hospitalEvents`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Hospital Event(s)")}
                  checked={isChecked(notificationSettingsPkg.state.data, "Hospital Event(s)")}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Hospital Event(s)"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-hospitalEventsLabel`}>Hospital Event(s)</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-medicationAlert`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Medication Alert")}
                  checked={isChecked(notificationSettingsPkg.state.data, "Medication Alert")}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Medication Alert"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-medicationAlertLabel`}>Medication Alert</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-codingDoc`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(
                    notificationSettingsPkg.state.data,
                    "Coding and Documentation"
                  )}
                  checked={isChecked(
                    notificationSettingsPkg.state.data,
                    "Coding and Documentation"
                  )}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Coding and Documentation"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-codingDocLabel`}>Coding and Documentation</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-qualityRiskAdj`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(
                    notificationSettingsPkg.state.data,
                    "Quality and Risk Adjustment"
                  )}
                  checked={isChecked(
                    notificationSettingsPkg.state.data,
                    "Quality and Risk Adjustment"
                  )}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Quality and Risk Adjustment"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-qualityRiskAdjLabel`}>Quality and Risk Adjustment</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-reportAvailable`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Report Available")}
                  checked={isChecked(notificationSettingsPkg.state.data, "Report Available")}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Report Available"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-reportAvailableLabel`}>Report Available</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-referralFollowUp`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Referral Follow Up")}
                  checked={isChecked(
                    notificationSettingsPkg.state.data,
                    "Referral Follow Up"
                  )}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Referral Follow Up"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-referralFollowUpLabel`}>Referral Follow Up</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-other`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  value={isChecked(notificationSettingsPkg.state.data, "Other")}
                  checked={isChecked(notificationSettingsPkg.state.data, "Other")}
                  onChange={(e) =>
                    onChanged(
                      e,
                      notificationSettingsPkg.state.data,
                      notificationSettingsPkg.dispatch,
                      "Other"
                    )
                  }
                />
                <Form.Check.Label id={`${componentName}-otherLabel`}>Other</Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
          <hr />
          <Row>
            <p id={`${componentName}-activityNotificationsTitle`} className="fw-bold">
              Activity Notifications
            </p>
            <p id={`${componentName}-activityNotificationsInstr`}>
              Please indicate which of the following activities you
              would like to be notified of
            </p>
            <Form.Check id={`${componentName}-newPatientNotification`} type="checkbox">
              <Form.Check.Input
                  type="checkbox"
                  checked={notificationSettingsPkg.state.data.newPatientNotification}
                  onChange={(e) => onSimpleChanged(e, "newPatientNotification")}
              />
              <Form.Check.Label id={`${componentName}-newPatientNotificationLabel`}>New Patients</Form.Check.Label>
            </Form.Check>
          </Row>
          <hr />
          <Row>
            <Col>
              <p id={`${componentName}-notifyLabel`}>Notify me via</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check id={`${componentName}-SMS`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={notificationSettingsPkg.state.data.sms}
                  onChange={(e) => onSimpleChanged(e, "sms")}
                />
                <Form.Check.Label id={`${componentName}-SMSLabel`}>SMS</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-email`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={notificationSettingsPkg.state.data.email}
                  onChange={(e) => onSimpleChanged(e, "email")}
                />
                <Form.Check.Label id={`${componentName}-emailLabel`}>Email</Form.Check.Label>
              </Form.Check>
              <Form.Check id={`${componentName}-voice`} type="checkbox">
                <Form.Check.Input
                  type="checkbox"
                  checked={notificationSettingsPkg.state.data.voice}
                  onChange={(e) => onSimpleChanged(e, "voice")}
                />
                <Form.Check.Label id={`${componentName}-voiceLabel`}>Voice</Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Button id={`${componentName}-saveButton`}
                variant="primary"
                onClick={() => save(notificationSettingsPkg.state.data)}
              >
                Save
              </Button>
            </Col>
            <Col sm={10}>
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <i className="fas fa-check-square text-success"></i>
                  <strong className="me-auto">
                    Notification settings updated
                  </strong>
                </Toast.Header>
              </Toast>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default NotificationSettings;
