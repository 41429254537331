import { disPatchError } from "../utils"
import {
    RESET_ERRORS, UPDATE_SYSTEM_FILE, UPDATE_SUCCESS, GET_SYSTEM_FILE_TYPES, GET_SYSTEM_FILES
} from "../types";
  import axios from "../../utils/apiClient";
  export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/system-file/";

  export const updateSystemFile = (fileType, file, fileName) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD", payload: true });
    dispatch({ type: RESET_ERRORS });
    axios.post(URL + "upload-file?fileType=" + fileType, file, {
        headers: {
          "Accept" : "application/json",
          "Content-Type": "application/octet-stream",
          "X-Filename" : fileName
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_SYSTEM_FILE,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SUCCESS,
          payload: "Successfully Updated",
        });
      })
      .catch((err) => {
        disPatchError(err,dispatch);
      });
  };

    export const getFileTypes = (setFileType) => (dispatch) => {
        dispatch({ type: "TOGGLE_LOAD" });
        dispatch({ type: RESET_ERRORS });
        axios
            .get(URL + "file-types", {
                headers: {
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_SYSTEM_FILE_TYPES,
                    payload: res.data,
                });
                setFileType(res.data[0].value);
            })
            .catch((err) => disPatchError(err, dispatch));
    };

    export const getFiles = (fileType) => (dispatch) => {
        dispatch({ type: "TOGGLE_LOAD" });
        dispatch({ type: RESET_ERRORS });
        axios
            .get(URL + "files?fileType=" + fileType, {
                headers: {
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_SYSTEM_FILES,
                    payload: res.data,
                });
            })
            .catch((err) => disPatchError(err, dispatch));
    };
