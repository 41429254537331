import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';

const SuccessNotification = ({message, onClose}) => {
    const isEmpty = require("is-empty");

    return (
        <Toast onClose={onClose} show={!isEmpty(message)} delay={3000} autohide>
            <Toast.Header>
                <strong className="me-auto fw-bold text-success"><i className="fas fa-exclamation-triangle"></i> Success</strong>
            </Toast.Header>
    		<Toast.Body>{message}</Toast.Body>
        </Toast>
    );
}

export default SuccessNotification;