import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LAST_QUERY_USAGE_KEY } from "../patientProfile/PatientProfileHeader";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Card,
  Form,
  Dropdown,
  DropdownButton,
  Badge,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import AssignPatient from "./AssignPatient";
import AddToWorkList from "./AddToWorkList";
import ExportSummary from "./ExportSummary";

import ListView from "./components/patientList";
import TableView from "./PatientListView";
import CustomPagination from "../common/CustomPaginaton";

import styled from "styled-components";
import {
  searchPatientMain,
  removePatientsFromList,
  deleteUserWorklist,
  cancelRequest,
  searchPatientMultiple,
} from "../../actions/patientSearch/patientSearchActions";
import { removeQuicklistById } from "../../actions/navigation/navigationActions";
import { useDispatch } from "react-redux";
import { PatientSearchDTO, FilterParam } from "../../dtos/PatientSearchDTO";
import axios from "../../utils/apiClient";
import Modals from "../../components/modals";
import {
  formatDate,
  getPatientProfileLink,
  preventiveCarePatientFilterParams,
  getQuickListId,
  getCustomWorklistName,
  getCustomListName,
} from "../../utils/util";
import ShareWorklist from "./ShareWorklist";
import { FilterGroup } from "../../components/filters/filterGroup";
import _ from "lodash";
import { AppPropertiesUtil, LocalStorageUtil, SessionStorageUtil } from "../../utils/localStorageUtil";
import { QuickListContext } from "./QuickListContext";
import { useContext } from "react";
import { CE_RESTORE_OBJ } from "../common/MainLayout";
import AssignCareManagerModal from "../caremgmt/AssignCareManagerModal";
import { assignCareManager } from "../../actions/caremgmt/hubWorkCenterAction";

import CustomFilterList from "./../../views/quickList/CustomFilterList";
import UpdateCustomFilterList from "./../../views/quickList/UpdateCustomFilterList";
import { FilterGroupNew } from "../../components/filters/filterGroupNew";
import WorklistQuickfilter from "./worklistQuickfilter";
const isEmpty = require("is-empty");

export const PRINT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print";
export const PRINT_SYNC_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print-sync";
export const EXPORT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export";
export const SUMMARY_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
export const CUSTOM_LIST_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/custom-filtered-list/custom-lists";
export const WORKLIST_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/custom-work-list/work-list";
const LAST_QUERY_SETTINGS_SUFFIX = `-QLQuerySettings`;
const RECENT_QUERY_SETTINGS_KEY = `ce-recent-query-settings-key`;

let pathName = window.location.pathname;
let modifiedPathName = pathName.endsWith("/") ? pathName.substring(0, pathName.length - 1) : pathName;
export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + modifiedPathName + "?loadLastQuerySettings=true"
);

const StyledListBtnsOS = styled(Button)`
  display: ${(props) => (!props.show ? "none" : `inline-block`)}!important;
`;

const componentName = "QuickList";

const RemoveModalBody = ({ patientsSelected }) => {
  const location = useLocation();

  return (
    <div>
      <span>
        You have selected {patientsSelected.length} member
        {patientsSelected.length > 1 && "s"} to remove from your organiazation's patient list.
      </span>
      <br />
      <br />
      <span>Would you like to proceed?</span>
      <br />
      {patientsSelected.map((patient) => (
        <a className="nav-link pt-0 pb-0" href={getPatientProfileLink(patient.id, location, "PreventiveCare")}>
          {patient.lastName}, {patient.firstName}
        </a>
      ))}
    </div>
  );
};

const ConfirmWorklistDeletion = ({ showConfirmation, setConfirmation, id, customListDto }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let modalTitle = "Delete Custom List";
  let modalMessage = "Are you sure you want to delete this custom list?";

  const handleWorklistDeletion = async () => {
    await deleteUserWorklist(id);
    let listId = null;
    if (customListDto) {
      listId = "/" + customListDto.quickList.toLowerCase() + "?id=" + id;
    }
    await dispatch(removeQuicklistById(listId));
    setConfirmation(false);
    history.push("/all_patients/");
  };

  return (
    <Modals
      show={showConfirmation}
      onHide={() => setConfirmation(false)}
      title={modalTitle}
      size="md"
      onCreate={handleWorklistDeletion}
      hideClickButton={false}
      showExtraButton={false}
      hideFooter={false}
      completeBtn="Delete"
      completeBtnId={`${componentName}-deleteButton`}
      body={<div>{modalMessage}</div>}
    />
  );
};

const VIEW = { card: "CARD", list: "LIST" };

const QuickLists = ({
  titleProp,
  filters,
  pageNumberProp,
  pageSizeProp,
  totalSizeProp,
  defaultFilterParamsProp,
  defaultSortParamsProp,
  exportPatientsUrl,
  quickListType,
  displayExtraRows,
  quickListId,
  /*
  cardViewActionObj and listViewActionObj allow the quick list page to pass in a query for the card view and list view.
  You can have 1 query for both card view and list view or you can have 2 separate queries 1 for card view and 1 for list view.

  If page doesn't pass in a cardViewActionObj(will use default query) or passes in a custom cardViewActionObj,
  only 1 query will execute and both card view and list view will be based of that 1 query.

  On other hand if you pass listViewActionObj, 2 async queries will execute; cardViewActionObj for card view and listViewActionObj for list view.

  cardViewActionObj and listViewActionObj have fetchData field for making query to get data and
  copyMap field is to define how to copy data from return set of query to
  patientList, totalSize, pageNumber, and pageSize. copyMap field allows us to handle any return set from a query with no code change.
  */
  cardViewActionObj = {
    fetchData: searchPatientMain,
    copyMap: {
      list: "patientCardDtos",
      totalSize: "totalSize",
      pageSize: "pageSize",
      pageNumber: "pageNumber",
    },
  },
  unScheduledActionObj = {
    fetchData: searchPatientMain,
    copyMap: {
      list: "patientCardDtos",
      totalSize: "totalSize",
      pageSize: "pageSize",
      pageNumber: "pageNumber",
    },
  },
  listViewActionObj,
  startingView = VIEW.card,
  listViewColumns,
  children,
  careManagementActions,
  onCareManagerAssigmentError,
  careManagerPrograms,
  careManagerTeams,
  showCount = false,
}) => {
  const qlsSettings = useContext(QuickListContext);
  let quicklistId = getQuickListId();
  let customWorklistName = getCustomWorklistName();
  let customListName = getCustomListName();
  useEffect(() => {
    let location = window.location.pathname;
    let workingPathName = location.endsWith("/") ? location.substring(0, location.length - 1) : location;
    if (quicklistId && !isNaN(quicklistId)) {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" +
          workingPathName +
          encodeURIComponent("?loadLastQuerySettings=true&id=" + quicklistId + "&customListName=" + customListName)
      );
    } else {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent("returnTo_" + workingPathName + "?loadLastQuerySettings=true");
    }
  }, [quickListType, quicklistId]);

  const [selectedPatients, setSelectedPatients] = useState(new Map());
  const [title, setTitle] = useState(titleProp);
  //const [patientsList, setPatientsList] = useState([]);
  const [unscheduledPatientsList, setUnscheduledPatientsList] = useState([]);
  const [patientsList, setPatientsList] = useState({
    default: [],
    scheduledPatients: [],
    unScheduledPatients: [],
    unableToContact: [],
    patientRefused: [],
    leftMessage: [],
    changedPCP: [],
  });
  const [cardViewData, setCardViewData] = useState({});
  const [listViewData, setListViewData] = useState({});
  const [pageNumber, setPageNumber] = useState(pageNumberProp);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [totalSize, setTotalSize] = useState(totalSizeProp);
  const [filterApplied, setFilterApplied] = useState(true);
  const [multipleFilterApplied, setMultipleFilterApplied] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);

  const [scheduledPatientSize, setScheduledPatientSize] = useState({});
  const [unScheduledPatientSize, setUnScheduledPatientSize] = useState({});
  const [unableToContactPatientSize, setUnableToContactPatientSize] = useState({});
  const [patientRefusedPatientSize, setPatientRefusedPatientSize] = useState({});
  const [leftMessagePatientSize, setLeftMessagePatientSize] = useState({});
  const [changedPCPPatientSize, setChangedPCPPatientSize] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [listViewLoadingData, setListViewLoadingData] = useState(listViewActionObj ? true : false);

  const [defaultFilterParams, setDefaultFilterParams] = useState(defaultFilterParamsProp);
  const [filterParams, setFilterParam] = useState(defaultFilterParamsProp);
  const [customListDto, setCustomListDto] = useState(null);
  const [sortParams, setSortParams] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  let [removablePatients, setRemovablePatients] = useState([]);
  const [filterButtons, setFilterButtons] = useState(false);
  const [modalSize, setModalSize] = useState(null);
  let [disableButtons, setDisableButtons] = useState(true);
  const [modalTitle, setModalTitle] = useState(null);
  const [buttonName, setButtonName] = useState(null);
  const [showPrintSpinner, setShowPrintSpinner] = useState(false);
  const [showExportSpinner, setShowExportSpinner] = useState(false);
  const [view, setView] = useState(startingView);
  const [errorText, setErrorText] = useState();
  const [showAssignCareManagerModal, setShowAssignCareManagerModal] = useState(false);
  const [patientIds, setPatientIds] = useState(null);
  const [allPatientsChecked, setAllPatientsChecked] = useState(false);
  const [showCustomFilterList, setShowCustomFilterList] = useState(false);
  const [showUpdateCustomFilterList, setShowUpdateCustomFilterList] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [canceled, setCanceled] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [activeFilterView, setActiveFilterView] = useState('');

  const LAST_QUERY_SETTINGS_KEY = `${quickListType}` + LAST_QUERY_SETTINGS_SUFFIX;
  const QUERY_PARAMS = new URLSearchParams(window.location.search);
  const PRINT_THRESHOLD = AppPropertiesUtil.get("export.download.limit");
  const PRINT_THRESHOLD_PDF = AppPropertiesUtil.get("export.chart.download.limit");

  const setDefaultFilterParamHelper = (filterParams) => {
    setDefaultFilterParams(defaultFilterParamsProp);
    setFilterParam(filterParams);
  };

  

  const handleFilterChange = (filterParams, p) => {
    cancelRequest(setCanceled);
    setFilterParam(filterParams);
    handleRemovablePatients([]);
    setSelectedPatients(new Map());
    setPageNumber(1);
    if (!p) {
      p = 1;
    }
    query(new PatientSearchDTO(filterParams, sortParams, p, pageSize, quickListType, totalSize), title, view);
  };

  const handleSortByChange = (e) => {
    var sortArr = e.target.value.split("-");
    let sortObj = { paramName: sortArr[0], direction: sortArr[1], priority: 1 };
    setSortParams([sortObj]);
    query(new PatientSearchDTO(filterParams, [sortObj], pageNumber, pageSize, quickListType, totalSize), title, view);
  };

  const handleFilterPageSize = (e) => {
    var result = e.target.value.split("-");
    query(new PatientSearchDTO(filterParams, sortParams, 1, result[0], quickListType, totalSize), title, view);
  };
  const handleColumnSort = (attArr, att, desc) => {
    let sortObj = [];
    if (attArr && attArr.length) {
      attArr.forEach((e) => {
        if (e) {
          sortObj.push({
            paramName: e.attr,
            direction: e.desc,
            priority: e.priority,
          });
        }
      });
      setSortParams(sortObj);
    } else {
      let obj = { paramName: att, direction: desc, priority: 1 };
      sortObj.push(obj);
      setSortParams([obj]);
    }

    query(new PatientSearchDTO(filterParams, sortObj, pageNumber, pageSize, quickListType, totalSize), title, view);
  };

  const handlePaginationChange = (pageNumber) => {
    setPageNumber(pageNumber);
    //setSelectedPatients(new Map());
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType, totalSize), title, view);
  };
  const handlePatientGapStatusChange = () => {
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
  };
  const sectionPatientsByGapStatus = (patientListSectioned) => {
    let patientsWithNextVisit = [];
    let newUnscheduledData = patientListSectioned?.unscheduledData || [];
    if (!isEmpty(newUnscheduledData)) {
      for (let i = 0; i < newUnscheduledData.length; i++) {
        if (newUnscheduledData[i].nextVisit) {
          patientsWithNextVisit.push(newUnscheduledData[i]);
        }
      }
    }

    if (!isEmpty(patientsWithNextVisit)) {
      patientListSectioned.unScheduledPatients = patientsWithNextVisit;
    }

    return patientListSectioned;
  };
  let pageObject = {
    pageNumber: 0,
    data: {},
    unscheduledData: {},
    unableToContact: {},
    patientRefused: {},
    leftMessage: {},
    changedPCP: {},
  };

  const copyToPatientListVariables = (
    data,
    unscheduledData,
    unableToContact,
    leftMessage,
    changedPCP,
    patientRefused,
    inView
  ) => {
    let copyMap = null;
    if (!inView) {
      inView = view;
    }
    if (inView === VIEW.card || (inView === VIEW.list && !listViewActionObj)) {
      copyMap = cardViewActionObj.copyMap;
    } else if (inView === VIEW.list && listViewActionObj) {
      copyMap = listViewActionObj.copyMap;
    }
    setTotalSize(_.get(data, copyMap["totalSize"]));
    setPageSize(_.get(data, copyMap["pageSize"]));
    setPageNumber(_.get(data, copyMap["pageNumber"]));
    let patientListSectioned = {
      default: [],
      scheduledPatients: [],
      unScheduledPatients: [],
      unableToContact: [],
      patientRefused: [],
      leftMessage: [],
      changedPCP: [],
    };
    let totalSize;
    if (quickListType == "USER_WORK_LIST") {
      patientListSectioned.scheduledPatients = _.get(data, copyMap["list"]);
      totalSize = _.get(data, copyMap["totalSize"]);
      setPageSize(_.get(data, copyMap["pageSize"]));
      setPageNumber(_.get(data, copyMap["pageNumber"]));
      setScheduledPatientSize(_.get(data, copyMap["totalSize"]));
      patientListSectioned.unScheduledPatients = _.get(unscheduledData, copyMap["list"]);
      if (totalSize < _.get(unscheduledData, copyMap["totalSize"])) {
        totalSize = _.get(unscheduledData, copyMap["totalSize"]);
        setPageSize(_.get(unscheduledData, copyMap["pageSize"]));
        setPageNumber(_.get(unscheduledData, copyMap["pageNumber"]));
        setTotalSize(totalSize);
      }
      setUnScheduledPatientSize(_.get(unscheduledData, copyMap["totalSize"]));
      patientListSectioned.unableToContact = _.get(unableToContact, copyMap["list"]);
      if (totalSize < _.get(unableToContact, copyMap["totalSize"])) {
        totalSize = _.get(unableToContact, copyMap["totalSize"]);
        setPageSize(_.get(unableToContact, copyMap["pageSize"]));
        setPageNumber(_.get(unableToContact, copyMap["pageNumber"]));
        setTotalSize(totalSize);
      }
      setUnableToContactPatientSize(_.get(unableToContact, copyMap["totalSize"]));
      patientListSectioned.patientRefused = _.get(patientRefused, copyMap["list"]);
      if (totalSize < _.get(patientRefused, copyMap["totalSize"])) {
        totalSize = _.get(patientRefused, copyMap["totalSize"]);
        setPageSize(_.get(patientRefused, copyMap["pageSize"]));
        setPageNumber(_.get(patientRefused, copyMap["pageNumber"]));
        setTotalSize(totalSize);
      }
      setPatientRefusedPatientSize(_.get(patientRefused, copyMap["totalSize"]));
      patientListSectioned.leftMessage = _.get(leftMessage, copyMap["list"]);
      if (totalSize < _.get(leftMessage, copyMap["totalSize"])) {
        totalSize = _.get(leftMessage, copyMap["totalSize"]);
        setPageSize(_.get(leftMessage, copyMap["pageSize"]));
        setPageNumber(_.get(leftMessage, copyMap["pageNumber"]));
        setTotalSize(totalSize);
      }
      setLeftMessagePatientSize(_.get(leftMessage, copyMap["totalSize"]));
      patientListSectioned.changedPCP = _.get(changedPCP, copyMap["list"]);
      if (totalSize < _.get(changedPCP, copyMap["totalSize"])) {
        totalSize = _.get(changedPCP, copyMap["totalSize"]);
        setPageSize(_.get(changedPCP, copyMap["pageSize"]));
        setPageNumber(_.get(changedPCP, copyMap["pageNumber"]));
        setTotalSize(totalSize);
      }
      setChangedPCPPatientSize(_.get(changedPCP, copyMap["totalSize"]));
      setTotalSize(totalSize);
    } else {
      patientListSectioned.default = _.get(data, copyMap["list"]);
    }
    patientListSectioned = sectionPatientsByGapStatus(patientListSectioned);
    setPatientsList(patientListSectioned);
    setPageCount(
      _.get(data, copyMap["totalSize"]) +
        _.get(unscheduledData, copyMap["totalSize"]) +
        _.get(leftMessage, copyMap["totalSize"]) +
        _.get(unableToContact, copyMap["totalSize"]) +
        _.get(changedPCP, copyMap["totalSize"]) +
        _.get(patientRefused, copyMap["totalSize"])
    );
  };

  useEffect(() => {
    if (cardViewData && cardViewData.pageNumber == pageNumber) {
      if (!listViewActionObj) {
        setListViewData(cardViewData);
        copyToPatientListVariables(
          cardViewData.data,
          cardViewData.unscheduledData,
          cardViewData.unableToContact,
          cardViewData.leftMessage,
          cardViewData.changedPCP,
          cardViewData.patientRefused
        );
      } else if (listViewActionObj && view === VIEW.card) {
        copyToPatientListVariables(
          cardViewData.data,
          cardViewData.unscheduledData,
          cardViewData.unableToContact,
          cardViewData.leftMessage,
          cardViewData.changedPCP,
          cardViewData.patientRefused
        );
      }
      setLoadingData(false);
    }
  }, [cardViewData]);

  function getPageObjArr(selectedValues, mapping) {
    return selectedValues.map(value => mapping[value]).filter(Boolean);
  }

  const callworklistquery = async (patientSearchDTO, updatedTitle, updatedView, filterApplied, paramValue, multipleFilterApplied) => {
    let emptyResponse = {
      patientCardDtos: [],
      pageNumber: 1,
      pageSize: 20,
      totalSize: 0,
    };
    pageObject.data = emptyResponse;
    let pageObjArr = ["unScheduledPatients", "unableToContact", "leftMessage", "changedPCP", "patientRefused"];
    let values = ["APPOINTMENT_UNSCHEDULED", "UNABLE_TO_CONTACT", "LEFT_MESSAGE", "CHANGED_PCPS", "PATIENT_REFUSAL"];
    const mapping = {"APPOINTMENT_UNSCHEDULED": "unScheduledPatients",
      "UNABLE_TO_CONTACT": "unableToContact",
      "LEFT_MESSAGE": "leftMessage",
      "CHANGED_PCPS": "changedPCP",
      "PATIENT_REFUSAL": "patientRefused"}
    if(!filterApplied) {
      setFilterApplied(false);
    }
    else {
      values = paramValue;
      pageObjArr = getPageObjArr(values, mapping);
      pageObjArr.forEach((filter)=> {
        setSelectedFilter((prevState) => [...prevState, filter]);
      })
    }
    let allWorklistsCall = [];
    for (let i = 0; i < values.length; i++) {
      const patientParam = createParamObjectForWorklist(
        patientSearchDTO,
        updatedTitle,
        updatedView,
        filterApplied,
        values[i],
        pageObjArr[i],
        multipleFilterApplied
      );
      allWorklistsCall.push(patientParam);
    }
    cancelRequest(setCanceled);
    await searchPatientMultiple(allWorklistsCall)
      .then(async (res) => {
        if (res.data) {
          pageObject.pageNumber = patientSearchDTO.pageNumber;
          pageObject.unscheduledData = res.data["APPOINTMENT_UNSCHEDULED"] ? res.data["APPOINTMENT_UNSCHEDULED"] : emptyResponse;
          pageObject.unableToContact = res.data["UNABLE_TO_CONTACT"] ? res.data["UNABLE_TO_CONTACT"] : emptyResponse;
          pageObject.patientRefused = res.data["PATIENT_REFUSAL"] ? res.data["PATIENT_REFUSAL"] : emptyResponse;
          pageObject.leftMessage = res.data["LEFT_MESSAGE"] ? res.data["LEFT_MESSAGE"] : emptyResponse;
          pageObject.changedPCP = res.data["CHANGED_PCPS"] ? res.data["CHANGED_PCPS"] : emptyResponse;
          setCardViewData(pageObject);
          if(activeFilterView && activeFilterView !='UNSELECT') {
            let finalTitle = `- ${formatText(activeFilterView)}`;
            if(activeFilterView === 'REQUIRING_ACTION'){
              finalTitle = '- High Risk'
            }
            window.localStorage.setItem("worklistTitle", finalTitle);
            window.dispatchEvent(new Event("worklistTitleEvent"));
          } else {
            let finalTitle = '';
            window.localStorage.setItem("worklistTitle", finalTitle);
            window.dispatchEvent(new Event("worklistTitleEvent"));
          }
        }
      })
      .catch((err) => {
        setErrorText("Error getting results");
      });
  };

  const createParamObjectForWorklist = (psd, updatedTitle, updatedView, filterApplied, value, pageObjVal, multipleFilterApplied) => {
    let patientSearchDTO = {};

    SessionStorageUtil.setObj(RECENT_QUERY_SETTINGS_KEY, LAST_QUERY_SETTINGS_KEY);
    SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
      patientSearchDTO: psd,
      view: updatedView,
      title: updatedTitle,
    });
    
    if (listViewActionObj) {
      // setListViewLoadingData(true);
    }

    if (!filterApplied || (filterApplied && multipleFilterApplied)) {
      psd.pageSize = 5;
    }
    let defaultFilterPresent = false;
    let filterParams = psd.filterParams;
    let filterParamsNew = [];
    for (let i = 0; i < filterParams.length; i++) {
      const obj = { paramName: "", paramValue: "" };
      obj.paramName = filterParams[i].paramName;
      if (filterParams[i].paramName == "worklistStatus") {
        obj.paramValue = [value];
        defaultFilterPresent = true;
      } else {
        obj.paramValue = filterParams[i].paramValue;
      }
      filterParamsNew.push(obj);
    }
    if (!defaultFilterPresent) {
      let defaultFilterParam = {
        paramName: "worklistStatus",
        paramValue: [value],
        comparison: "eq",
        displayValue: [],
      };
      filterParamsNew.push(defaultFilterParam);
    }
    if (cardViewData && cardViewData[pageObjVal] && cardViewData[pageObjVal].totalSize) {
      patientSearchDTO.previousTotalSize = cardViewData[pageObjVal].totalSize;
    }
    patientSearchDTO.filterParams = filterParamsNew;
    patientSearchDTO.pageNumber = psd.pageNumber;
    patientSearchDTO.quickListName = "USER_WORK_LIST";
    patientSearchDTO.quickFilterName = psd.quickFilterName;
    patientSearchDTO.pageSize= psd.pageSize
    return patientSearchDTO;
  };

  useEffect(() => {
    if (listViewActionObj && listViewData && listViewData.pageNumber == pageNumber) {
      if (view === VIEW.list) {
        copyToPatientListVariables(listViewData.data);
      }
      setListViewLoadingData(false);
    }
  }, [listViewData]);

  useEffect(() => {
    if(activeFilterView) {
      query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType, totalSize), title, view);
    }
  }, [activeFilterView]);

  const query = async (patientSearchDTO, updatedTitle, updatedView) => {
    setLoadingData(true);
    setPageNumber(patientSearchDTO.pageNumber);
    if (quickListType != "USER_WORK_LIST") {
      SessionStorageUtil.setObj(RECENT_QUERY_SETTINGS_KEY, LAST_QUERY_SETTINGS_KEY);
      SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
        patientSearchDTO: patientSearchDTO,
        view: updatedView,
        title: updatedTitle,
      });
    }
    if (listViewActionObj) {
      setListViewLoadingData(true);
    }
    let filterApplied = false;
    let multipleFilterApplied = false;
    let paramValue;
    let filterParams = patientSearchDTO.filterParams;
    if (quickListType == "USER_WORK_LIST") {
      if(activeFilterView && activeFilterView !='UNSELECT'){
        patientSearchDTO.quickFilterName = activeFilterView
      }
      if (patientSearchDTO.pageSize < 20) {
        patientSearchDTO.pageSize = 20;
      }
      SessionStorageUtil.setObj(RECENT_QUERY_SETTINGS_KEY, LAST_QUERY_SETTINGS_KEY);
      SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
        patientSearchDTO: patientSearchDTO,
        view: updatedView,
        title: updatedTitle,
      });
      setSelectedFilter([]);
      for (let i = 0; i < filterParams.length; i++) {
        if (filterParams[i].paramName == "worklistStatus") {
          filterApplied = true;
          let paramValue = filterParams[i].paramValue;
          let displayValue = filterParams[i].displayValue;
          setFilterApplied(true);
          for (let j = 0; j < paramValue.length; j++) {
            if (paramValue.length > 1) {
              setMultipleFilterApplied(true);
              multipleFilterApplied= true;
            } else {
              setMultipleFilterApplied(false);
            }
          }
          callworklistquery(patientSearchDTO, updatedTitle, updatedView, filterApplied, paramValue, multipleFilterApplied);
          patientSearchDTO.filterParams[i].paramValue = paramValue;
        }
      }
      if (!filterApplied) {
        patientSearchDTO.pageSize = 5;
        callworklistquery(patientSearchDTO, updatedTitle, updatedView, filterApplied);
      }
    } else {
      cardViewActionObj
        .fetchData(patientSearchDTO)
        .then((res) => {
          if (res.data) {
            const pageObject = {
              pageNumber: patientSearchDTO.pageNumber,
              data: res.data,
            };
            setCardViewData(pageObject);
          }
          setCanceled(false);
        })
        .catch((err) => {
          if (err?.message !== "cancel by user") {
            setCanceled(false);
          }
          setErrorText("Error");
          setLoadingData(false);
        });
    }
    if (listViewActionObj) {
      listViewActionObj
        .fetchData(patientSearchDTO)
        .then((res) => {
          if (res.data) {
            const pageObject = {
              pageNumber: patientSearchDTO.pageNumber,
              data: res.data,
            };
            setListViewData(pageObject);
          }
          setCanceled(false);
        })
        .catch((err) => {
          if (err?.message !== "cancel by user") {
            setCanceled(false);
          }
          setErrorText("Error");
          setListViewLoadingData(false);
        });
    }
  };


  const fetchData = async (filterParams, quicklistName, title, view) => {
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quicklistName), title, view);
  };

  
  const formatText = (value) => {
    return value?.toLowerCase().split('_').map(capitalize).join(' ');
}

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

  useEffect(() => {
    const restoreObject = SessionStorageUtil.getObj(CE_RESTORE_OBJ);
    let quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    let useLastQuery = SessionStorageUtil.getObj(LAST_QUERY_USAGE_KEY);
    if (restoreObject) {
      if (restoreObject.recentKey === LAST_QUERY_SETTINGS_KEY) {
        quicklistObj = restoreObject.quickListObj;
      }
    }
    if ((restoreObject || useLastQuery) && quicklistObj && quicklistObj.patientSearchDTO) {
      if (quickListType == "USER_WORK_LIST") {
        setFilterParam(quicklistObj.patientSearchDTO.filterParams);
        setPageNumber(quicklistObj.patientSearchDTO.pageNumber);
        setPageSize(quicklistObj.patientSearchDTO.pageSize)
        setSortParams(quicklistObj.patientSearchDTO.sortParams);
        setActiveFilterView(quicklistObj.patientSearchDTO.quickFilterName ? quicklistObj.patientSearchDTO.quickFilterName : "UNSELECT");
        return;
      }
    }
    setInitialLoad(true);
  }, [quicklistId]);

  useEffect(() => {
    if (!initialLoad) return;
    setInitialLoad(false);
    //restore from last timeout override
    const restoreObject = SessionStorageUtil.getObj(CE_RESTORE_OBJ);
    let quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    let useLastQuery = SessionStorageUtil.getObj(LAST_QUERY_USAGE_KEY);
    SessionStorageUtil.setObj(LAST_QUERY_USAGE_KEY, null);
    if (restoreObject) {
      if (restoreObject.recentKey === LAST_QUERY_SETTINGS_KEY) {
        quicklistObj = restoreObject.quickListObj;
        SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, quicklistObj);
      }
      SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);
    }
    if ((restoreObject || useLastQuery) && quicklistObj && quicklistObj.patientSearchDTO) {
      if (defaultSortParamsProp) {
        quicklistObj.patientSearchDTO.sortParams = defaultSortParamsProp;
      }
      //load from back button
      setTitle(quicklistObj.title);
      setView(quicklistObj.view);
      setFilterButtons(quicklistId && !isNaN(quicklistId));
      setDefaultFilterParamHelper(quicklistObj.patientSearchDTO.filterParams);
      setSortParams(quicklistObj.patientSearchDTO.sortParams);
      query(quicklistObj.patientSearchDTO, quicklistObj.title, quicklistObj.view);
    } else if (quicklistId && !isNaN(quicklistId)) {
      //load from custom list
      axios
        .get(CUSTOM_LIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setFilterButtons(true);
          setDefaultFilterParamHelper(response.data.patientRequestDTO.filterParams);
          setTitle(response.data.name);
          fetchData(response.data.patientRequestDTO.filterParams, response.data.quickList, response.data.name, view);
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalSize("lg");
          if (error.response != null) {
            setModalMessage(error.response.data.message);
          } else {
            setModalMessage(error.message);
          }
          setLoadingData(false);
        });
    } else if (quicklistId && quicklistId.includes("customWorklist")) {
      //load member list
      axios
        .get(WORKLIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setLoadingData(false);
        });

      setFilterButtons(true);
      let valuesAndDisplayValues = [];
      valuesAndDisplayValues.push({
        text: customWorklistName,
        value: quicklistId,
      });

      let filterParam = new FilterParam(
        "Member Worklist",
        "eq",
        valuesAndDisplayValues,
        (x) => x.value,
        (x) => x.text
      );
      const tempFilterParams = Object.assign([], filterParams);
      tempFilterParams.push(filterParam);
      setDefaultFilterParamHelper(tempFilterParams);
      setTitle(customWorklistName);
      fetchData(tempFilterParams, "ALL_PATIENTS", customWorklistName, view);
      setFilterParam(tempFilterParams);
    } else if (quicklistId && quicklistId.includes("data")) {
      let patientFilters = preventiveCarePatientFilterParams;
      axios
        .get(process.env.REACT_APP_REST_API_BASE_URL + `/v1/patients/overview/summary?patientId=${quicklistId}`, {
          headers: {},
        })
        .then((response) => {
          patientFilters[0].displayValue[0] = response.data.lastName;
          patientFilters[0].displayValue[1] = response.data.firstName;
          patientFilters[0].paramValue[0] = response.data.lastName + ", " + response.data.firstName;
          patientFilters[1].displayValue[0] = "On " + formatDate(response.data.dateOfBirth);
          patientFilters[1].paramValue[0] = formatDate(response.data.dateOfBirth);

          setFilterButtons(true);
          setDefaultFilterParamHelper(patientFilters);
          setTitle(titleProp);
          fetchData(patientFilters, quickListType, titleProp, view);
        })
        .catch((error) => {
          setLoadingData(false);
        });
    } else {
      setTitle(titleProp);
      setDefaultFilterParamHelper(defaultFilterParamsProp);
      setFilterButtons(false);

      if (defaultSortParamsProp) {
        setSortParams(defaultSortParamsProp);
        query(
          new PatientSearchDTO(defaultFilterParamsProp, defaultSortParamsProp, pageNumber, pageSize, quickListType),
          titleProp,
          view
        );
      } else {
        fetchData(defaultFilterParamsProp, quickListType, titleProp, view);
      }
    }
  }, [initialLoad]);

  const reloadPatientView = () => {
    fetchData(filterParams, quickListType, titleProp, view);
  };

  const summary = () => {
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch((error) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
        setLoadingData(false);
      });
  };

  const print = () => {
    setShowPrintSpinner(true);
    const filterData = new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType);

    let criteria = {};
    criteria.patientRequest = filterData;
    criteria.selectedItems = Array.from(selectedPatients, ([key, value]) => value.id);
    criteria.quickList = quickListType;
    axios
      .post(PRINT_URL, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        summary();
      })
      .catch((error) => {
        setShowPrintSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const exportPatients = () => {
    setShowExportSpinner(true);
    const filterData = new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType);
    let criteria = {};
    criteria.patientRequest = filterData;
    criteria.selectedItems = Array.from(selectedPatients, ([key, value]) => value.id);
    criteria.quickList = quickListType;
    axios
      .post(exportPatientsUrl, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        summary();
      })
      .catch((error) => {
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const selectPatient = (patient, selected) => {
    const selectedPatientsCopy = new Map(selectedPatients);
    if (selected) {
      selectedPatientsCopy.set(patient.id, patient);
    } else {
      selectedPatientsCopy.delete(patient.id);
    }
    handleRemovablePatients(selectedPatientsCopy);
    setSelectedPatients(selectedPatientsCopy);
  };
  const selectPatients = (patients, selected) => {
    const selectedPatientsCopy = new Map(selectedPatients);
    if (selected) {
      for (let i = 0; i < patients.length; i++) {
        selectedPatientsCopy.set(patients[i].id, patients[i]);
      }
    } else {
      for (let i = 0; i < patients.length; i++) {
        selectedPatientsCopy.delete(patients[i].id);
      }
    }
    handleRemovablePatients(selectedPatientsCopy);
    setSelectedPatients(selectedPatientsCopy);
  };
  const handleRemovablePatients = (selectedPatients) => {
    if (selectedPatients.size > 0) {
      setDisableButtons(false);
      checkRemovablePatients(selectedPatients);
    } else {
      setDisableButtons(true);
      setRemovablePatients([]);
    }
  };

  const onModalClick = (e, requery) => {
    setModalMessage(null);
    if (requery) {
      query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
      setSelectedPatients(new Map());
    }
  };

  const assignTo = () => {
    setModalTitle("Assign Patients");
    setModalSize("lg");
    let modalAssignTo = (
      <AssignPatient
        patientsSelected={Array.from(selectedPatients, ([key, value]) => value)}
        onHideClicked={onModalClick}
      />
    );

    setModalMessage(modalAssignTo);
    setButtonName("Assign");
  };

  const addToWorklist = () => {
    setModalTitle("Add to Worklist");
    setModalSize("md");
    setModalMessage(
      <AddToWorkList
        patientsSelected={Array.from(selectedPatients, ([key, value]) => value)}
        onHideClicked={onModalClick}
      />
    );
  };

  const removeFromWorklist = () => {
    const patientIds = Array.from(selectedPatients).map(([name, value]) => name);

    axios
      .delete(process.env.REACT_APP_REST_API_BASE_URL + `/v1/custom-work-list/work-list/items?id=` + quicklistId, {
        headers: {},
        data: patientIds,
      })
      .then((resp) => {
        query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
        //alert("ok");
      })
      .catch((error) => {
        alert("error");
      });
  };

  const [selectAllLabel, setSelectAllLabel] = useState("Select All");

  const isPatientSelected = (patient) => {
    return selectedPatients.has(patient.id);
  };

  const selectOrUnSelectAll = (selected) => {
    const selectedPatients = new Map();
    setAllPatientsChecked(selected);

    if (selected) {
      patientsList.default.forEach((x) => {
        selectedPatients.set(x.id, x);
      });
    }
    handleRemovablePatients(selectedPatients);
    setSelectedPatients(selectedPatients);

    if (selectAllLabel) {
      setSelectAllLabel(selectAllLabel === "Select All" ? "Deselect All" : "Select All");
    }
  };

  const checkRemovablePatients = async (selectedPatients) => {
    let globalPass = true;
    await selectedPatients.forEach((patient, id, map) => {
      if (!patient.global) {
        globalPass = false;
      }
    });
    if (globalPass) {
      setRemovablePatients(["true"]);
    } else {
      setRemovablePatients([]);
    }
  };

  const removePatients = async () => {
    let patientIds = [];
    await selectedPatients.forEach((patient, id, map) => {
      patientIds.push(id);
    });
    await removePatientsFromList(patientIds);
    setRemoveModal(false);
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
  };

  const closeEvidenceModal = () => {
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
  };

  const updateParent = (updatedRef, sourceType) => {
    let newState = patientsList.default;
    if (sourceType && sourceType === "ManageEvidence") {
    } else if (sourceType && sourceType === "SubmitRecentVisit") {
      newState.map((pat, patI) => {
        if (pat.id === updatedRef.patientId) {
          newState[patI].nextVisit = updatedRef.nextVisit;
          newState[patI].recentVisitStatus = updatedRef.recentVisitStatus;
          newState[patI].nextVisitInfo = updatedRef.nextVisitInfoDesc;
          newState[patI].recentVisit = updatedRef.recentVisit;
          newState[patI].recentVisitInfo = updatedRef.recentVisitInfoDesc;
          //newState[patI].patientGapStatus = updatedRef.patientGapStatus;
          let patientListSectioned = {
            default: [],
            scheduledPatients: [],
            unScheduledPatients: [],
            unableToContact: [],
            patientRefused: [],
            leftMessage: [],
            changedPCP: [],
          };
          patientListSectioned.default = [...newState];
          setPatientsList(patientListSectioned);
        }
      });
    } else if (updatedRef) {
      let patIndex = null;
      let refIndex = null;
      newState.map((pat, patI) => {
        if (pat.id === updatedRef.patient.id) {
          patIndex = patI;
          pat.referralDtoList.map((ref, refI) => {
            if (ref.id === updatedRef.referralId) {
              refIndex = refI;
            }
          });
        }
      });
      if (newState[patIndex].referralDtoList[refIndex].status !== updatedRef.status) {
        newState[patIndex].referralDtoList[refIndex].status = updatedRef.status;
        let patientListSectioned = {
          default: [],
          scheduledPatients: [],
          unScheduledPatients: [],
          unableToContact: [],
          patientRefused: [],
          leftMessage: [],
          changedPCP: [],
        };
        patientListSectioned.default = [...newState];
        setPatientsList(patientListSectioned);
      }
    }
  };
  const appointmentCancelled = () => {
    query(new PatientSearchDTO(filterParams, sortParams, pageNumber, pageSize, quickListType), title, view);
  };

  const onShowAssignCareManagerModal = (patientIds) => {
    setShowAssignCareManagerModal(true);
    setPatientIds(patientIds);
  };

  const bulkCareManagerAssignment = () => {
    if (!isEmpty(selectedPatients)) {
      setSelectedName("");
      setShowAssignCareManagerModal(true);
      let patientIds = Array.from(selectedPatients).map(([patientId]) => patientId);
      setPatientIds(patientIds);
    }
  };

  const assignToCareManager = (assignment) => {
    const payload = patientIds.map((patientId) => {
      return { ...assignment, patientId, origin: "CARE_EMPOWER_REFERRALS" };
    });
    assignCareManager(payload)
      .then(() => {
        setSelectedPatients(new Map());
        setShowAssignCareManagerModal(false);
        setAllPatientsChecked(false);
        reloadPatientView();
      })
      .catch((error) => {
        onCareManagerAssigmentError(error);
        setShowAssignCareManagerModal(false);
      });
  };

  const getCurrentFilters = () => {
    let filterView = [];
    let filterValue;
    for (let i = 0; i < filters.length; i++) {
      filterValue = "";
      for (let j = 0; j < filterParams.length; j++) {
        if (filters[i].getKey() === filterParams[j].paramName) {
          if (filterParams[j].paramValue.length > 1) {
            filterValue = "Multiple";
          } else {
            if (
              filterParams[j].displayValue != null &&
              (filterParams[j].displayValue[0] != null || filterParams[j].displayValue[0] !== undefined)
            ) {
              filterValue = getFilterValue(filterParams[j].displayValue[0]);
            } else {
              filterValue = getFilterValue(filterParams[j].paramValue[0]);
            }
          }
          break;
        }
      }
      if (filterValue === "") {
        filterValue = "All";
      }
      filterView.push({
        filterName: filters[i].getHeader(),
        filterValue: filterValue,
      });
    }
    return filterView;
  };
  const getFilterValue = (values) => {
    let filterValue = "";
    if (Array.isArray(values)) {
      for (let z = 0; z < values.length; z++) {
        if (filterValue !== "") {
          filterValue = filterValue + ", ";
        }
        filterValue = filterValue + values[z];
      }
    } else {
      filterValue = values;
    }
    return filterValue;
  };
  const customFilterLists = () => {
    setShowCustomFilterList(true);
  };
  const disableExport = !!(isEmpty(totalSize) || (totalSize > PRINT_THRESHOLD_PDF && selectedPatients.size === 0));

  return (
    <Container fluid className="py-3">
      <Helmet>
        <title>{title} | CareEmpower</title>
      </Helmet>
      <Modals
        show={removeModal}
        onHide={() => setRemoveModal(false)}
        title="Remove Member(s) from organization's patient list"
        size="lg"
        onCreate={removePatients}
        hideClickButton={false}
        showExtraButton={false}
        hideFooter={false}
        completeBtn="Save"
        completeBtnId={`${componentName}-saveButton`}
        body={<RemoveModalBody patientsSelected={Array.from(selectedPatients, ([key, value]) => value)} />}
      />
      {quicklistId && !isNaN(quicklistId) ? (
        <ShareWorklist
          shareModal={shareModal}
          setShareModal={setShareModal}
          setModalMessage={setModalMessage}
          setModalSize={setModalSize}
          setModalTitle={setModalTitle}
          customListDto={customListDto}
          setCustomListDto={setCustomListDto}
          quicklistId={quicklistId}
          customWorklistName={customWorklistName}
        />
      ) : (
        ""
      )}
      <ConfirmWorklistDeletion
        showConfirmation={showDeleteConfirmation}
        setConfirmation={setShowDeleteConfirmation}
        id={quicklistId}
        customListDto={customListDto}
      />
      {quickListType == "USER_WORK_LIST" ? (
        <> </>
      ) : (
        <Row className="mb-3">
          <Col>
            <Row>
              <Col>
                  <FilterGroupNew
                    filters={filters}
                    handleChange={handleFilterChange}
                    defaultFilterParams={defaultFilterParams}
                    filterParams={filterParams}
                    customWorklistName={customWorklistName}
                    additionalValidation={qlsSettings.additionalFilterValidation}
                  />
              </Col>
            </Row>
            <Row>
              <Col sm={view === VIEW.card ? 5 : 5} md={4}>
                {view === VIEW.card && (
                  <Form.Check
                    checked={allPatientsChecked}
                    onChange={(e) => selectOrUnSelectAll(e.target.checked)}
                    type="checkbox"
                    id={`${componentName}-PatientSelect`}
                    className="d-inline-block ms-3 me-2"
                  />
                )}
                {!isEmpty(totalSize) && (
                  <Badge pill bg="light" id={`${componentName}-PatientCount`} className="border text-dark me-2">
                    {totalSize.toLocaleString()} Patients
                  </Badge>
                )}
                {quickListId && quickListId === "REFERRALS_CARE_MANAGEMENT" && (
                  <DropdownButton
                    id={`${componentName}-actionsBtn`}
                    disabled={selectedPatients.size < 2}
                    variant="light"
                    text="secondary"
                    className="d-inline-block rounded border me-2"
                    title="Bulk Actions"
                  >
                    {careManagementActions &&
                      careManagementActions.map((action, idx) => (
                        <Dropdown.Item
                          id={`${componentName}-actionsBtnItems-${idx}`}
                          key={`${componentName}-${action.value}-${idx}-key`}
                          onClick={() => bulkCareManagerAssignment()}
                        >
                          {action.text}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                )}
                {children}
                {(loadingData || listViewLoadingData || canceled) && (
                  <Spinner
                    className="align-middle mb-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Col>
              <Col className="text-start text-sm-end mt-sm-0">
                {(showExportSpinner || showPrintSpinner) && (
                  <Spinner
                    className="align-middle mb-4"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {!isEmpty(patientsList.default) && quickListId !== "REFERRALS_LIST" && (
                  <>
                    <div className="d-inline-block me-2 align-top">
                      {view === VIEW.card && (
                        <select
                          className="form-select"
                          id={`${componentName}-Sorting`}
                          onChange={(e) => handleSortByChange(e)}
                        >
                          <option value="Sort by" selected disabled hidden>
                            Sort by
                          </option>
                          <option value="fullname-Asc">Name (A - Z)</option>
                          <option value="fullname-Desc">Name (Z - A)</option>
                          <option value="dateofbirth-Asc">Date of Birth (oldest first)</option>
                          <option value="dateofbirth-Desc">Date of Birth (youngest first)</option>
                          <option value="risk-Asc">Risk Score (lowest first)</option>
                          <option value="risk-Desc">Risk Score (highest first)</option>

                          <option value="nextVisit-Asc">Recommended Next Visit Date (oldest first)</option>
                          <option value="nextVisit-Desc">Recommended Next Visit Date (newest first)</option>
                        </select>
                      )}
                    </div>
                  </>
                )}
                {quickListType !== "ACTIVE_INACTIVE_PATIENTS" && (
                  <StyledListBtnsOS
                    variant="secondary"
                    size="sm"
                    className="align-top border me-2"
                    id={`${componentName}-Share`}
                    show={filterButtons}
                    onClick={() => setShareModal(true)}
                  >
                    Share
                  </StyledListBtnsOS>
                )}
                <StyledListBtnsOS
                  variant="secondary"
                  size="sm"
                  className="align-top border me-2"
                  id={`${componentName}-Delete`}
                  show={filterButtons}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Delete
                </StyledListBtnsOS>
                <StyledListBtnsOS
                  variant="secondary"
                  size="sm"
                  className="align-top border me-2"
                  id={`${componentName}-Update`}
                  show={filterButtons}
                  onClick={() => setShowUpdateCustomFilterList(true)}
                >
                  Update
                </StyledListBtnsOS>

                {!isEmpty(patientsList.default) && (
                  <>
                    <div className="d-inline-block ms-0 ms-sm-2">
                      <CustomPagination
                        paginateButClicked={handlePaginationChange}
                        MAX_PAGE={Math.ceil(totalSize / pageSize)}
                        pageNumber={Math.ceil(pageNumber)}
                      />
                    </div>{" "}
                  </>
                )}
                {exportPatientsUrl != null && (
                  <Dropdown className="d-inline-block align-top">
                    <Dropdown.Toggle id={`${componentName}-Export`} variant="link" className="border-0">
                      <i className="fa-solid fa-ellipsis text-dark"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item id={`${componentName}-saveCustomList`} onClick={() => customFilterLists()}>
                        Save As Custom List
                      </Dropdown.Item>
                      {disableExport ? (
                        <OverlayTrigger
                          placement="bottom"
                          trigger={["hover", "focus"]}
                          popperConfig={{
                            modifiers: {
                              preventOverflow: { enabled: false },
                            },
                          }}
                          overlay={<Tooltip id="tooltip">{"Export allowed upto 200 members"}</Tooltip>}
                        >
                          <Dropdown.Item id={`${componentName}-ExportPDF`}>Export Chart Prep Tool</Dropdown.Item>
                        </OverlayTrigger>
                      ) : (
                        <Dropdown.Item
                          id={`${componentName}-ExportPDF`}
                          disabled={disableExport}
                          onClick={() => print()}
                        >
                          Export Chart Prep Tool
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        id={`${componentName}-ExportCSV`}
                        disabled={isEmpty(totalSize) || (totalSize > PRINT_THRESHOLD && selectedPatients.size == 0)}
                        onClick={() => exportPatients()}
                      >
                        Export to CSV
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Col>
            </Row>
            {/* {!isEmpty(patientsList.default) && showCount && (
              <Row className="mx-2">
                <Col className="text-primary">
                  {totalSize} Members Listed
                </Col>
              </Row>
            )} */}
          </Col>
        </Row>
      )}
      {quickListType == "USER_WORK_LIST" ? (
        <div>
          <Row>
            <Col>
              <FilterGroupNew
                filters={filters}
                handleChange={handleFilterChange}
                totalSize={totalSize}
                defaultFilterParams={defaultFilterParams}
                filterParams={filterParams}
                sortParams={sortParams}
                pageNumber={pageNumber}
                pageSize={pageSize}
                quickListType={quickListType}
                setModalMessage={setModalMessage}
                setModalSize={setModalSize}
                setModalTitle={setModalTitle}
                customWorklistName={customWorklistName}
                additionalValidation={qlsSettings.additionalFilterValidation}
              />
            </Col>
            <Col md={2} className="text-end">
              {(loadingData || listViewLoadingData) && (
                <Badge pill bg="light" text="dark" className="border align-items-center position-absolute top-50 start-50 zindex-1040">
                  <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
                </Badge>
              )}
            </Col>
          </Row>
          {(!isEmpty(patientsList.scheduledPatients) ||
            !isEmpty(patientsList.unScheduledPatients) ||
            !isEmpty(patientsList.unableToContact) ||
            !isEmpty(patientsList.leftMessage) ||
            !isEmpty(patientsList.changedPCP) ||
            !isEmpty(activeFilterView) ||
            !isEmpty(patientsList.patientRefused)) && (
            <>
              <Row className="mb-3">
                <Col>
                   <WorklistQuickfilter activeFilterView={activeFilterView} setActiveFilterView={setActiveFilterView} setPageNumber={setPageNumber} />
                  {!isEmpty(pageCount) && (
                    <Badge pill bg="light" id={`${componentName}-PatientCount`} className="border text-dark">
                      {pageCount.toLocaleString()} Patients
                    </Badge>
                  )}
                  {(showExportSpinner || showPrintSpinner) && (
                    <Badge pill bg="light" text="dark" className="border align-items-center position-absolute top-50 start-50 zindex-1040">
                      <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Please Wait...
                    </Badge>
                  )}
                </Col>
                <Col className="text-start text-sm-end mt-sm-0">
                  {filterApplied && !multipleFilterApplied && (
                    <div className="d-inline-block me-2 align-top">
                      <select
                        className="form-select"
                        id={`${componentName}-ResultsPerPage`}
                        disabled={!filterApplied}
                        onChange={(e) => handleFilterPageSize(e)}
                      >
                        <option value="20-Results" selected disabled hidden>
                          20 Results Per Page
                        </option>
                        <option value="50-Results">50 Results Per Page</option>
                        <option value="20-Results">20 Results Per Page</option>
                      </select>
                    </div>
                  )}

                  <div className="d-inline-block ms-0 ms-sm-2">
                    <CustomPagination
                      paginateButClicked={handlePaginationChange}
                      MAX_PAGE={Math.ceil(totalSize / pageSize)}
                      pageNumber={Math.ceil(pageNumber)}
                    />
                  </div>

                  {exportPatientsUrl != null && (
                    <Dropdown className="d-inline-block align-top">
                      <Dropdown.Toggle id={`${componentName}-Export`} variant="link" className="border-0">
                        <i className="fa-solid fa-ellipsis text-dark"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item
                          id={`${componentName}-ExportCSV`}
                          disabled={
                            isEmpty(totalSize) ||
                            (totalSize > PRINT_THRESHOLD && selectedPatients.size == 0) ||
                            !filterApplied ||
                            multipleFilterApplied
                          }
                          onClick={() => exportPatients()}
                        >
                          Export to CSV
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Col>
              </Row>
            </>
          )}
          <ListView
            updatePatients={setPatientsList}
            patientList={patientsList}
            workListStatusSize={[
              scheduledPatientSize,
              unScheduledPatientSize,
              unableToContactPatientSize,
              patientRefusedPatientSize,
              leftMessagePatientSize,
              changedPCPPatientSize,
            ]}
            activeFilterView={activeFilterView}
            quickListType={quickListType}
            loadingData={loadingData}
            listViewLoadingData={listViewLoadingData}
            handleChangeCheckBox={selectPatient}
            handleAllPatientsCheckbox={selectPatients}
            isPatientSelected={isPatientSelected}
            quicklistId={quicklistId}
            returnToMyPatientsUrl={RETURN_TO_MY_PATIENTS_URL}
            updateParent={updateParent}
            closeEvidenceModal={closeEvidenceModal}
            reloadPatientView={reloadPatientView}
            onShowAssignCareManagerModal={onShowAssignCareManagerModal}
            careManagementActions={careManagementActions}
            titleProp={titleProp}
            appointmentCancelled={appointmentCancelled}
            handlePatientGapStatusChange={handlePatientGapStatusChange}
            handleFilterChange={handleFilterChange}
            filterParams={filterParams}
            pageNumber={pageNumber}
            filterApplied={filterApplied}
            selectedFilter={selectedFilter}
          />
        </div>
      ) : patientsList.default && patientsList?.default?.length > 0 && patientsList.default[0] !== "Empty" ? (
        view === VIEW.card ? (
          <ListView
            updatePatients={setPatientsList}
            patientList={patientsList}
            quickListType={quickListType}
            workListStatusSize={[
              scheduledPatientSize,
              unScheduledPatientSize,
              unableToContactPatientSize,
              patientRefusedPatientSize,
              leftMessagePatientSize,
              changedPCPPatientSize,
            ]}
            loadingData={loadingData}
            listViewLoadingData={listViewLoadingData}
            handleChangeCheckBox={selectPatient}
            handleAllPatientsCheckbox={selectPatients}
            isPatientSelected={isPatientSelected}
            quicklistId={quicklistId}
            returnToMyPatientsUrl={RETURN_TO_MY_PATIENTS_URL}
            updateParent={updateParent}
            closeEvidenceModal={closeEvidenceModal}
            reloadPatientView={reloadPatientView}
            onShowAssignCareManagerModal={onShowAssignCareManagerModal}
            careManagementActions={careManagementActions}
            titleProp={titleProp}
            handleFilterChange={handleFilterChange}
            filterParams={filterParams}
            pageNumber={pageNumber}
          />
        ) : (
          <TableView
            patientList={patientsList}
            handleChangeCheckBox={selectPatient}
            sortCol={handleColumnSort}
            selectOrUnSelectAll={selectOrUnSelectAll}
            isPatientSelected={isPatientSelected}
            quickListType={quickListType}
            displayExtraRows={displayExtraRows}
            columnList={listViewColumns}
            updateParent={updateParent}
            closeEvidenceModal={closeEvidenceModal}
            reloadPatientView={reloadPatientView}
            onShowAssignCareManagerModal={onShowAssignCareManagerModal}
            careManagementActions={careManagementActions}
            allPatientsChecked={allPatientsChecked}
            setSelectedName={setSelectedName}
          />
        )
      ) : (
        !loadingData &&
        !listViewLoadingData && (
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Text id={`${componentName}-NoPatients`}>
                    {!canceled ? "No Patients Found" : "Loading..."}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      )}
      <CustomFilterList
        filters={getCurrentFilters()}
        showCustomFilterList={showCustomFilterList}
        setShowCustomFilterList={setShowCustomFilterList}
        filterParams={filterParams}
        sortParams={sortParams}
        pageNumber={pageNumber}
        pageSize={pageSize}
        quickList={quickListType}
        setModalMessage={setModalMessage}
        setModalSize={setModalSize}
        setModalTitle={setModalTitle}
      />
      <UpdateCustomFilterList
        id={quicklistId}
        filters={getCurrentFilters()}
        showUpdateCustomFilterList={showUpdateCustomFilterList}
        setShowUpdateCustomFilterList={setShowUpdateCustomFilterList}
        filterParams={filterParams}
        setModalMessage={setModalMessage}
        setModalSize={setModalSize}
        setModalTitle={setModalTitle}
      />
      <Modals
        show={modalMessage != null}
        onHide={() => setModalMessage(null)}
        title={modalTitle}
        size={modalSize || "md"}
        hideClickButton={true}
        showExtraButton={false}
        hideFooter={false}
        closeBtnId={`${componentName}-closeButton`}
        body={modalMessage}
      />
      <AssignCareManagerModal
        show={showAssignCareManagerModal}
        teams={careManagerTeams}
        programs={careManagerPrograms}
        onAssign={assignToCareManager}
        onClose={() => setShowAssignCareManagerModal(false)}
        onError={onCareManagerAssigmentError}
        selectedName={selectedName}
      />
    </Container>
  );
};

export { QuickLists, VIEW, LAST_QUERY_SETTINGS_SUFFIX, RECENT_QUERY_SETTINGS_KEY };
