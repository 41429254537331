import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";

import { EQHDatePicker } from "../datePicker";
import _ from "lodash";
import { FilterParam } from "../../dtos/PatientSearchDTO";
import { Filter } from "../filters/SideFilters/components";

const componentName = "ReadmitDDL";
const READMIT_DDL_TYPE = "ReadmitDDL";

const yyyyMMddToMMddyyyy = (strDate) => {
  return strDate
    ? `${strDate.substr(5, 2)}/${strDate.substr(8, 2)}/${strDate.substr(
      0,
      4
    )}`
    : "";
};

const MMddyyyyToyyyyMMdd = (strDate) => {
  return strDate
    ? `${strDate.substr(6, 4)}-${strDate.substr(0, 2)}-${strDate.substr(
      3,
      2
    )}`
    : "";
};

const DEFAULT_TEXT = '-Select-'

const ReadmitDropDownList = ({
  header,
  selected,
  errors,
  showRequired,
  handleChange,
}) => {
  const idFragment = header.replaceAll(" ", "_").toUpperCase();
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState(() => {
    if (selected?.paramValue?.length > 0) {
      const startDate = selected?.paramValue.filter(p => p.includes('startDate'));
      const endDate = selected?.paramValue.filter(p => p.includes('endDate'));
      if (startDate.length > 0 && endDate.length > 0) {
        return {
          startDate: startDate[0].replace('startDate:', '').replaceAll('-', '/'),
          endDate: endDate[0].replace('endDate:', '').replaceAll('-', '/'),
        };
      }
    }
    return { startDate: '', endDate: '' };
  });
  useEffect(() => {
    if (!selected) {
      setDates({
        startDate: '',
        endDate: ''
      });
      setOptions({
        'IP': false,
        'ER': false,
        'BH': false
      })
    }
  }, [selected])

  const [options, setOptions] = useState(() => {
    if (selected?.paramValue?.length > 0) {
      const IPvalue = selected?.paramValue.filter(p => p === 'IP_ADMIT');
      const ERvalue = selected?.paramValue.filter(p => p === 'ER_ADMIT');
      const BHvalue = selected?.paramValue.filter(p => p === 'BH_IP_ADMIT');
      return {
        'IP': IPvalue && IPvalue.length > 0,
        'ER': ERvalue && ERvalue.length > 0,
        'BH': BHvalue && BHvalue.length > 0
      };
    }
    return {
      'IP': false,
      'ER': false,
      'BH': false
    };
  });

  useEffect(() => {
    handleChange(onChange())
  }, [options, dates])

  const handleToggle = (ddlOpen) => {
    setIsOpen(ddlOpen);
  };

  const onChange = () => {
    let value = []
    dates.startDate !== '' && value.push(`startDate:${dates.startDate}`)
    dates.endDate !== '' && value.push(`endDate:${dates.endDate}`)
    options.IP && value.push('IP_ADMIT')
    options.ER && value.push('ER_ADMIT')
    options.BH && value.push('BH_IP_ADMIT')

    let filterParam = new FilterParam(
      header,
      'range',
      value.map(v => ({ value: v, text: v })),
      (x) => x.value,
      (x) => x.text
    );
    return filterParam;
  }

  const getDisplayText = () => {
    let res = '';
    if (
      dates.startDate === '' &&
      dates.endDate === '' &&
      !options.IP &&
      !options.ER &&
      !options.BH
    ) {
      res += DEFAULT_TEXT
    } else {
      let isDateSelected = dates.startDate !== '' || dates.endDate !== '';
      let isOptionSelected = options.IP || options.ER || options.BH;
      res = `${dates.startDate} ${isDateSelected ? '-' : ''} ${dates.endDate} ${(isDateSelected && isOptionSelected) ? ':' : ''} ${options.IP ? 'IP Readmit' : ''} ${options.ER ? 'ER Readmit' : ''} ${options.BH ? 'BH-IP Readmit' : ''}`
    }
    return res
  }

  const onClearAll = () => {
    setOptions({
      IP: false,
      ER: false,
      BH: false
    });
    setDates({
      startDate: '',
      endDate: ''
    });
  }

  const HeaderComponent = () => {
    return (
      <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
        <span id={`${componentName}-${idFragment}-filterTitleText}`}>{header}</span>
        {showRequired && <span className="text-danger">*</span>}
        {getDisplayText() !== DEFAULT_TEXT && getDisplayText().length > 0 &&
          <>
            <i id={`${componentName}-${idFragment}-filterReset`} className="fas fa-circle fa-2xs cursor-p d-inline-block ms-2 me-0 text-primary"></i>
          </>
        }
      </label>
    )
  }

  return (
    <Filter
      onToggle={handleToggle}
      index={`${componentName}-${idFragment}-filterDropdown`}
      header={<HeaderComponent />}
    >
      <EQHDatePicker
        id={`${componentName}-${idFragment}-datePicker`}
        onChange={(e) => setDates({ ...dates, startDate: yyyyMMddToMMddyyyy(e.strDate) })}
        value={MMddyyyyToyyyyMMdd(dates.startDate)}
        max={MMddyyyyToyyyyMMdd(dates?.endDate)}
      />
      <EQHDatePicker
        min={MMddyyyyToyyyyMMdd(dates?.startDate)}
        id={`${componentName}-${idFragment}-datePicker2`}
        onChange={(e) => setDates({ ...dates, endDate: yyyyMMddToMMddyyyy(e.strDate) })}
        value={MMddyyyyToyyyyMMdd(dates.endDate)}
      />
      {errors.length > 0 &&
        <span style={{ fontSize: '10px'}} className="text-danger fw-bold">
          {errors[0]}
        </span>
      }
      <div className="mt-4">
        <Button
          id={`${componentName}-${idFragment}-filterSelectAll`}
          variant="link"
          onClick={() => setOptions({
            IP: true,
            ER: true,
            BH: true
          })}
          className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none me-2"
        >
          Select All
        </Button>
        &nbsp;
        <Button
          id={`${componentName}-${idFragment}-filterClearAll`}
          variant="link"
          onClick={() => onClearAll()}
          className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none"
        >
          Clear All
        </Button>
        <div className='mt-3 px-4'>
          <Form.Check
            type="checkbox"
            key={`${componentName}-${idFragment}-Item-1`}
            id={`${componentName}-${idFragment}-Item-1`}
            label={'IP Readmit'}
            onChange={e => setOptions({ ...options, IP: e.target.checked })}
            checked={options?.IP}
            level={1}
          />
          <Form.Check
            type="checkbox"
            key={`${componentName}-${idFragment}-Item-2`}
            id={`${componentName}-${idFragment}-Item-2`}
            label={'ER Readmit'}
            onChange={e => setOptions({ ...options, ER: e.target.checked })}
            checked={options?.ER}
            level={1}
          />
          <Form.Check
            type="checkbox"
            key={`${componentName}-${idFragment}-Item-3`}
            id={`${componentName}-${idFragment}-Item-3`}
            label={'BH-IP Readmit'}
            onChange={e => setOptions({ ...options, BH: e.target.checked })}
            checked={options?.BH}
            level={1}
          />
        </div>
      </div>
    </Filter>
  );
};

export { ReadmitDropDownList, READMIT_DDL_TYPE };
