import { Dropdown, Form } from 'react-bootstrap'
import { useCMWorklistContext } from './CMWorklistModals/context/CMWorklist.context';
import React, { useEffect, useState } from 'react';
import { buildCompositeKey } from "../CompositeKeyHelper";
import './styles.css';

const componentName = 'SelectAll'

export const SelectAll = () => {
  const [isAllSelected, setisAllSelected] = useState(false);
  const [isSomeSelected, setisSomeSelected] = useState(false);
  const {
    patientsList,
    selectPatientEventAction,
    selectedPatientsEvents
  } = useCMWorklistContext();

  useEffect(() => {
    const list = [...patientsList.map(p => p.id)]
    setisAllSelected(list.length > 0 && list.length === selectedPatientsEvents.length)
    setisSomeSelected(selectedPatientsEvents.length > 0 && list.length !== selectedPatientsEvents.length)
  }, [selectedPatientsEvents])

  return (
    <Dropdown id={`${componentName}-selectActions`} key={`${componentName}-selectActions-key`}>
      <Dropdown.Toggle className="border d-flex align-items-center" variant="light" id={`${componentName}-menuToggle`} key={`${componentName}-menuToggle`}>
        <Form.Check
          type="checkbox"
          id={`${componentName}-createCarePathCheckbox`}
          onChange={() => { }}
          checked={isAllSelected || isSomeSelected}
          style={{ transform: 'scale(1.4)' }}
          className={isSomeSelected ? 'intermediate' : ''}
        />
        <i className="ms-2 fs-6 fa fa-chevron-down"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`}>
        <Dropdown.Item
          onClick={() => selectPatientEventAction(
            patientsList.map(patientEvent => ({...buildCompositeKey(patientEvent), data: patientEvent}))
          )}
        >All</Dropdown.Item>
        <Dropdown.Item onClick={() => selectPatientEventAction([])}>None</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
