const patientOverviewReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "update":
        return {
          ...state,
          data: payload,
        };
      default:
        throw new Error();
    }
  };

  export {patientOverviewReducer};