import React, { useEffect, useState } from "react";

import axios from "../../utils/apiClient";
import { Button } from "../../components/buttons";
import Col from "react-bootstrap/Col";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from 'react-select'
import { format } from "date-fns";

import {EQHDatePicker} from "../../components/datePicker";

const baseURLSR =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/submit-recent-visit";

const componentName = "SubmitRecentVisit";

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const SubmitRecentVisitBody = ({
  patient,
  updateParent,
  closeSubmitModal
}) => {
  const [selectedDate, setSelectedDate] = useState(patient.recentVisit);
  const [selectedStatus, setSelectedStatus] = useState(patient.recentVisitStatus ? patient.recentVisitStatus.name : null);
  const [maxDate, setMaxDate] = useState(null);

  const isFormValid = () => {
    return (selectedStatus && selectedDate);
  };

  const submitRecentVisit = () => {
    const query = {
      patientId: patient.id,
      recentVisit: selectedDate,
      recentVisitStatus: selectedStatus
    };
    axios
      .post(baseURLSR + "/save", query, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        await updateParent(response.data, "SubmitRecentVisit");
        closeSubmitModal();
      })
      .catch((error) => {
      });
  };

  const statusOptions = [
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'SCHEDULED', label: 'Scheduled' }
  ]

  useEffect(() => {
    if(selectedStatus === "COMPLETED") {
      setMaxDate(format(new Date(), "yyyy-MM-dd"))
      if(selectedDate > format(new Date(), "yyyy-MM-dd")) {
        setSelectedDate(null);
      }
    } else {
      setMaxDate(null)
    }
  }, [selectedStatus])

  return (
    <>
      <div style={{minHeight: "150px"}}>
          <Row className="mb-3">
              <Col sm={6}>
                <label id={`${componentName}-recentVisitStatusLabel`} className="d-block">
                  Visit Status<span className="text-danger">*</span>
                </label>
                <Select
                    id={`${componentName}-recentVisitStatusValue`}
                    options={statusOptions}
                    onChange={(e) => setSelectedStatus(e.value)}
                />
              </Col>
              <Col sm={3}>
                <label id={`${componentName}-recentVisitDateLabel`} className="d-block">Visit Date<span className="text-danger">*</span>
                </label>
                <DatePickerStyled>
                  <EQHDatePicker
                      id={`${componentName}-recentVisitDateValue`}
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.strDate)}
                      max={maxDate}
                  />
                </DatePickerStyled>
              </Col>
          </Row>
        <StyledFooter>
          <Button id={`${componentName}-closeButton`} onClick={() => closeSubmitModal()}>Close</Button>{" "}
          <Button id={`${componentName}-submitButton`} disabled={!isFormValid()} onClick={() => submitRecentVisit()}>
            Submit
          </Button>
        </StyledFooter>
      </div>
    </>
  );
};

export default SubmitRecentVisitBody;
