import {actions} from "./actions";

export function getSetters(dispatch) {
    return {
        setSelectedDate: payload => dispatch({ type: actions.SET_SELECTED_DATE, payload: payload }),
        setOptions: payload => dispatch({ type: actions.SET_OPTIONS, payload: payload }),
        setAddressList: payload => dispatch({ type: actions.SET_ADDRESS_LIST, payload: payload }),
        setAppointments: payload => dispatch({ type: actions.SET_APPOINTMENTS, payload: payload }),
        setTotalSize: payload => dispatch({ type: actions.SET_TOTAL_SIZE, payload: payload }),
    }
}