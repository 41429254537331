import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";
import { RedAsterisk, carePathValues, formatDate, formatDateModern } from "../../../../../../utils/util";
import { useManageVisitContext } from "../../../context/ManageVisitContext/ManageVisit.context";
import ToastMessage from "../../../../../../components/Toast";
import { useDispatch } from "react-redux";
import { setReloadWorklist } from "../../../../../../actions/manageVisitActions";


const componentName = "editCareStep";
const isEmpty = require("is-empty");

export const EditCareStep = ({ step, index, editExistingStep, saveNewStep, handleCancel, action }) => {
  const manageVisitContext = useManageVisitContext();
  const dispatch = useDispatch();
  const [changeTriggered, setChangeTriggered] = useState(false)

  const getCleanFormData = () => {
    return {
      title: "",
      completeBy: 0,
      trigger: "",
      description: "",
      timing: "BEFORE",
      isSubStep: false,
      dueDate: null,
      stepDate: null,
      customStatuses: [],
      applyModificator: false,
      conditions: [],
      id: null,
      carePathInstanceId: "",
      carePathStepStatus: (isEmpty(step?.carePathStepStatus) || action === "before" || action === "after")
        ? "AWAITING_TRIGGER"
        : step.carePathStepStatus,
      stepNumber: null,
      notes: [],
      lastModifiedBy: null,
      assignedTo: null,
      userModified: null,
      lastStep: false,
      dueDateSelected: step?.dueDateSelected,
      manuallyAddedOn: new Date(),
      stepCustomState: null,
      closeCarePathAfterCondition: false,
      hideStep: false,
      subStep: false,
      deletedReason: "",
      deleted: false
    };
  };

  const [formData, setFormData] = useState(getCleanFormData());
  useEffect(() => {
    if (step && (action == 'edit' || action == 'delete')) {
      setFormData({
        ...formData,
        assignedTo: step.assignedTo,
        completeBy: step.completeBy ? step.completeBy : 0,
        description: step.description,
        dueDate: isEmpty(step?.dueDate) ? null : new Date(step.dueDate),
        stepNumber: step.stepNumber,
        carePathInstanceId: step.carePathInstanceId,
        notes: step.notes,
        timing: step.timing,
        title: step.title,
        trigger: step.trigger,
        needToScheduleAppointment: step.needToScheduleAppointment,
        changeTriggered: false
      });
    }
  }, []);

  const updateFormFieldDynamic = (e, key, value) => {
    const newFormData = {
      ...formData,
      [key]: value, changeTriggered: true
    };
    setFormData(newFormData);
  };

  const onDateChange = (e, key, value) => {
    const dt = new Date(e).toISOString()
    const newFormData = {
      ...formData,
      "dueDate": dt, "dueDateLocal": e, changeTriggered: true
    };
    setFormData(newFormData);
    //updateFormFieldMain(e);
  };

  const onRadioChange = (e, key, value) => {
    const newFormData = {
      ...formData,
      [key]: value, changeTriggered: true
    };
    setFormData(newFormData);
  };

  const updateFormField = (e) => {

    let newState = formData;
    newState[e.target.name] = e.target.value;
    setFormData({ ...newState, changeTriggered: true });
  };
  const disableActionButton = () => {
    let lockS = true

    switch (action) {
      case 'delete':
        if (formData.deletedReason) {
          lockS = false
        }
      default:
        if (
          (formData.title)
        ) {

          if (formData.dueDateSelected && formData.changeTriggered) {
            if (formData.dueDate) {
              lockS = false
            }
          }
          else {
            if ((formData.timing && (formData.completeBy || formData.completeBy === 0) && formData.trigger && formData.changeTriggered)) {
              lockS = false
            }
          }
        }
        break;
    }

    return lockS

  };

  const saveStep = () => {
    if (action === 'edit') {
      editExistingStep(step, formData, index);
    }
    else if (action === 'delete') {
      let newState = formData;
      newState['deleted'] = true;
      setFormData({ ...newState });
      saveNewStep(formData, index, action, step.stepNumber);
    }
    else {
      console.info("editExising=" + editExistingStep);
      saveNewStep(formData, index, action, step.stepNumber);
    }
    dispatch(setReloadWorklist(true));
    //closeOpenForm();
  };

  return (
    <>
      <Modal.Body>
        {action != 'delete' ?
          <Row>
            <Col>
              <Row className="mb-4">
                <Form.Group>
                  <Form.Label>
                    Care Step Name<RedAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    id={`${componentName}-carePathESNameValue-${index}`}
                    value={formData?.title}
                    onChange={(e) => updateFormField(e)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group>
                  <Form.Label>
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="description"
                    id={`${componentName}-carePathESDaysDescValue-${index}`}
                    value={formData.description}
                    onChange={(e) => updateFormField(e)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group>
                  <Form>
                    {["radio"].map((type) => (
                      <div id={`${componentName}-carePathESAction-${index}`} key={`${componentName}-carePathESAction-${index}`} className="mb-3">
                        <Form.Check
                          custom
                          inline
                          label="Trigger Event"
                          type={type}
                          id={`${componentName}-carePathESActionTE-${index}`}
                          onClick={(e) =>
                            updateFormFieldDynamic(e, "dueDateSelected", false)
                          }
                          checked={!formData.dueDateSelected}
                        />
                        <Form.Check
                          custom
                          inline
                          label="Due Date"
                          type={type}
                          id={`${componentName}-carePathESActionDD-${index}`}
                          onClick={(e) =>
                            updateFormFieldDynamic(e, "dueDateSelected", true)
                          }
                          checked={formData.dueDateSelected}
                        />
                      </div>
                    ))}
                  </Form>
                  {/* If Event Selected */}
                  {!formData?.dueDateSelected && <>
                    <Row className="mb-4">
                      <Col>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              Number of Days
                            </Form.Label>
                            <select className="form-select"
                              value={formData.completeBy}
                              onChange={(e) => onRadioChange(e, "completeBy", e.target.value)}>
                              {[...Array(31).keys()].map((i) => <option value={i}>{i}</option>)}


                            </select>
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              Timing
                            </Form.Label>
                            <div>
                              <Form.Check
                                inline
                                label="Before"
                                type={"radio"}
                                id={`${componentName}-carePathESActionTE-${index}`}
                                onClick={(e) =>
                                  updateFormFieldDynamic(e, "timing", "BEFORE")
                                }
                                checked={formData.timing === 'BEFORE'}
                              />
                              <Form.Check
                                inline
                                label="After"
                                type={"radio"}
                                id={`${componentName}-radio-${index}`}
                                onClick={(e) =>
                                  updateFormFieldDynamic(e, "timing", "AFTER")
                                }
                                checked={formData.timing === 'AFTER'}
                              />
                            </div>
                          </Form.Group>
                        </Row>
                      </Col>

                    </Row>

                    <Row>
                      <Form.Label>
                        Event<RedAsterisk />
                      </Form.Label>
                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Control
                          as="select"
                          defaultValue=""
                          name="trigger"
                          id={`${componentName}-carePathESDaysTrigger-${index}`}
                          onChange={(e) => updateFormField(e)}
                          disabled={formData.dueDateSelected}
                          value={formData.trigger}
                          className="form-select"
                        >
                          <option selected disabled></option>
                          {carePathValues.map((item, index) => (
                            <option id={`${componentName}-carePathESDaysTriggerItems-${index}`} value={item.value}>{item.text}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Row> </>}
                  {/* If Event Selected */}
                  {formData?.dueDateSelected && <Calendar
                    onChange={onDateChange} value={formData.dueDateLocal ? formData.dueDateLocal : new Date()} />}
                </Form.Group>
              </Row>
            </Col>
          </Row> :
          <><Row className="mb-4 bg-light p-3 rounded-3">
            <Col>
              <Row className="mb-3">
                <Form.Group id={`${componentName}-patient`} className="d-flex flex-column">
                  <Form.Label id={`${componentName}-patientLabel`}>
                    Patient Name
                  </Form.Label>
                  <span id={`${componentName}-patientValue`} className="forced-capital">{manageVisitContext?.patient_object?.fullName}</span>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                  <Form.Label id={`${componentName}-currentLabel`}>
                    Current Program Step
                  </Form.Label>
                  <div id={`${componentName}-carePath-1`} className="d-flex flex-column">
                    <span id={`${componentName}-careStepName-2`}>{step?.title}</span>
                  </div>
                </Form.Group>
                <div id={`${componentName}-carePath-1`} className="d-block">
                  {formData?.dueDate && <>Due: <span id={`${componentName}-careStepName-2`}>{formatDateModern(formData?.dueDate)}</span></>}
                </div>
              </Row>
            </Col>
          </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                  <Form.Group id={`${componentName}-reason`} className="d-flex flex-column">
                    <Form.Label id={`${componentName}-reasonLabel`}>
                      Reason<RedAsterisk />
                    </Form.Label>
                    <Form className="mt-2">
                      <Form.Control
                        size="sm"
                        as="select"
                        name="deletedReason"
                        id={`${componentName}-careStepReasonValue`}
                        onChange={(e) => updateFormField(e)}
                        className="form-select"
                      >
                        <option id={`${componentName}-careStepReasonItem`} value="">-Select-</option>
                        <option id={`${componentName}-careStepReasonItem`} value="Patient_Declined">Patient Declined</option>
                        <option id={`${componentName}-careStepReasonItem`} value="Not_Applicable">Not Applicable</option>

                      </Form.Control>
                    </Form>
                  </Form.Group>
                </Row>
              </Col>
            </Row></>}
        <ToastMessage />

      </Modal.Body>

      <Modal.Footer>
        <Button id={`${componentName}-careStepCancelBtn`} variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button id={`${componentName}-careStepSubmitBtn`} variant="primary" onClick={() => { saveStep(); handleCancel(); }} disabled={disableActionButton()}>
          {action === 'edit' ? 'Edit Step' : action === 'delete' ? 'Delete Step' : 'Add Step'}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default EditCareStep;