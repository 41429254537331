import { format } from "date-fns";
import { FILTER_DEFINITIONS_MAP } from '../filters/filterDefinition';

export const getFilterDef = (key, componentProps, replaceHeader) => {
    const fd = FILTER_DEFINITIONS_MAP[key];
    const fdClone = componentProps ? { ...fd, componentProps: { ...fd.componentProps, ...componentProps } } : { ...fd };

    if (replaceHeader) {
        fdClone.getHeader = () => replaceHeader;
    }

    return fdClone;
};


export const formatRangeDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd');
}

export const checkRange = (range) => {
    return new Date(range[0]) <= new Date(range[1]);
}