import React, { useEffect, useState } from 'react';
import { DropDownList, DDLMode, DDLDataMode } from "./genericDDL";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FilterParam } from '../../dtos/PatientSearchDTO';
import { RedAsterisk, getSafeMultiSelectedItems } from '../../utils/util';

/**
 * Component that provides a multi-select dropdown menu with an unique option for free text input.
 * 
 * @param {Array} items - The items to be displayed in the dropdown.
 * @param {Function} handleChange - Function to handle changes in selection or free text input.
 * @param {String} header - The heading or label for the dropdown.
 * @param {String} selectedItems - Currently selected items, expected to be a JSON string format.
 * @param {String} otherItemText - Text used to identify the free text option.
 * @param {Boolean} disabled - Indicates whether the dropdown should be disabled.
 */
const MultiSelectOtherOptionDDL = ({ items, handleChange, header, selectedItems, otherItemText, disabled }) => {

  const [isOtherValueSelected, setOtherValueSelected] = useState(false);
  const [otherText, setOtherText] = useState("");

  let safeSelectedItems = getSafeMultiSelectedItems(selectedItems);

  /**
   * Handle initialization and re-initialization of the component based on selectedItems changes.
   * 
   * It parses the selectedItems to determine if the 'other' option is selected and sets the state for otherText
   * and isOtherValueSelected based on this determination. If the 'other' text option is present, it extracts and 
   * sets the corresponding text value.
   */
  useEffect(() => {
    setOtherText(null);
    setOtherValueSelected(false);

    safeSelectedItems.some(item => {
      const selectedItemText = item.split(":");
      const itemOption = selectedItemText.shift();
      if (otherItemText === itemOption) {
        setOtherValueSelected(true);
        const otherText = selectedItemText.shift();
        setOtherText(otherText);
        return true;
      }
      return false;
    });

  }, [selectedItems]);

  /**
   * Handles the selection of items in the dropdown.
   * It serializes the selected item(s) into a JSON string for consistent
   * data handling across components that expect a string format.
   * 
   * @param {Array} selected - The selected items from the dropdown.
   */
  const onSelectItem = (selected) => {

    const newSelectedItems = selected?.map(item => {
      if (item.startsWith(`${otherItemText}`)) {
        return `${otherItemText}:${otherText || ""}`;
      }
      return item;
    });

    handleChange(JSON.stringify(newSelectedItems));
  };

  /**
   * Handles changes to the free text input associated with the 'other' option in the dropdown.
   * This function updates the otherText state with the new value, maps over the current selectedItems
   * to update the entry corresponding to the 'other' option with the new text, and then triggers handleChange
   * with the serialized selected items into a JSON string for consistent
   * data handling across components that expect a string format.
   * 
   * @param {string} value - The new text entered by the user for the 'other' input field.
   */
  const onChangeOtherText = (value) => {
    setOtherText(value)

    const newSelectedItems = safeSelectedItems?.map(item => {
      if (item.startsWith(`${otherItemText}`)) {
        return `${otherItemText}:${value}`;
      }
      return item;
    });

    handleChange(JSON.stringify(newSelectedItems));
  };

  /**
   * Create the filter parameters for use in API calls or internal filtering logic. This function builds
   * a filter object based on the current selections in the dropdown, extracting key information for
   * each selected item, especially handling the extraction of values for standard and 'other' type entries.
   * 
   * @returns {Object} A filter parameter object containing the header, comparison values, and a selector function
   *                   for use in filtering operations, tailored to the specific needs of the dropdown's implementation.
   */
  const createFilterParam = () => {
    const comparison = "eq";
    const displayValues = safeSelectedItems && Array.isArray(safeSelectedItems) ?
      safeSelectedItems.map(item => item.split(":")?.shift()) : [];
    const selector = (e) => e;
    return new FilterParam(header, comparison, displayValues, selector, selector);
  }

  /**
   * Converts a JSON string into an array of items.
   * 
   * If parsing fails and the input is a string, it encapsulates the string in an array. 
   * This function ensures that the output is always an array.
   * 
   * @param {String} selectedItems - A JSON string representing an array or a single string value.
   * @returns {Array} An array of selected items, ensuring compatibility with multi-select
   *                  expectations and simplifying further processing.
   */
  function getSafeMultiSelectedItems(selectedItems) {
    let safeSelectedItems = [];

    if (!selectedItems) {
      return safeSelectedItems;
    }

    try {
      const parsedItems = JSON.parse(selectedItems);

      if (Array.isArray(parsedItems)) {
        safeSelectedItems = parsedItems;
      }
      else if (typeof parsedItems === 'string') {
        safeSelectedItems = [selectedItems];
      }
    } catch (e) {
      if (typeof selectedItems === 'string') {
        safeSelectedItems = [selectedItems];
      }
    }

    return safeSelectedItems;
  }

  return (
    <Col>
      <DropDownList
        mode={DDLMode.MultipleSelect}
        dataMode={DDLDataMode.OneAPICallFilterInUI}
        header={header}
        handleChange={(e) => {
          onSelectItem(e.paramValue)
        }}
        selected={createFilterParam()}
        getData={() => Promise.resolve(items)}
        highlightWhenHasValue={false}
        customStyle="mb-0"
        disabled={disabled}
      />
      {isOtherValueSelected && (
        <>
          <Form.Label id={`${header}-otherTextLabel`}>
            Open-ended response<RedAsterisk />
          </Form.Label>
          <Form.Control
            type="text"
            value={otherText}
            onChange={(event) => onChangeOtherText(event.target.value)}
          />
        </>
      )}
    </Col>
  );
}

export default MultiSelectOtherOptionDDL;