import React, {useEffect} from "react";
import {Row} from "react-bootstrap";
import {DDLMode, DropDownList} from "../../../../../../components/dropDownLists/genericDDL";
import AsyncSearch from "../components/AsyncSearch";
import Form from "react-bootstrap/Form";
import {InputNumber} from "../components";
import {useReferralsContext} from "../context/Referrals.context";

const componentName = "SpecialistReferralStep1";

const SpecialistReferralStep1 = () => {

    const {
        currentSpecialistReferralStep,
        getSpecialities,
        specialities,
        getPayers,
        payers,
        setSelectedSpeciality,
        selectedSpeciality,
        setSelectedPayer,
        selectedPayer,
        asyncSearchPracticeName,
        practices,
        isLoadingPractices,
        asyncSearchAssociatedProvider,
        associatedProviders,
        isLoadingAssociatedProviders,
        setSelectedPractice,
        selectedPractice,
        setSelectedAssociatedProvider,
        selectedAssociatedProvider,
        setZipCode,
        zipCode,
        setGenders,
        genders,
        setDefaultGender,
        setSelectedGender,
        selectedGender,
        isLoadingSpecialities,
        isLoadingPayers
    } = useReferralsContext();

    useEffect(() => {
        if (currentSpecialistReferralStep) {
            getSpecialities();
            getPayers();
            setGenders();
            setDefaultGender();
        }
    }, [currentSpecialistReferralStep]);

    const isSpecialitySelected = () => {
        return !!selectedSpeciality?.paramValue?.[0];
    }

    const isPayerSelected = () => {
        return !!selectedPayer?.paramValue?.[0];
    }

    const onSelect = (gender) => {
        setSelectedGender(gender);
    }
    return (
        <>
            <Row>
                <Form.Group>
                    <DropDownList
                        id={`${componentName}-providerSpeciality`}
                        key={`${componentName}-providerSpeciality`}
                        mode={DDLMode.SelectOne}
                        header="Provider Speciality"
                        getData={() => Promise.resolve(specialities)}
                        handleChange={setSelectedSpeciality}
                        disabled={isLoadingSpecialities}
                        selected={selectedSpeciality}
                        showRequired={true}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group>
                    <DropDownList
                        id={`${componentName}-payor`}
                        key={`${componentName}-payor`}
                        mode={DDLMode.SelectOne}
                        showSearchTextbox={false}
                        header="Payor"
                        getData={() => Promise.resolve(payers)}
                        handleChange={setSelectedPayer}
                        selected={selectedPayer}
                        disabled={isLoadingPayers}
                        showRequired={true}
                    />
                </Form.Group>
            </Row>
            <Row>
            <span>
                You may use any of the following criteria to identify a provider in
                Equality Health's network
            </span>
            </Row>
            <Row className="pt-3">
                <AsyncSearch
                    id={`${componentName}-practiceName`}
                    title="Practice Name"
                    disabled={!isSpecialitySelected() || !isPayerSelected()}
                    minLength={1}
                    onSearch={asyncSearchPracticeName}
                    options={practices}
                    isLoading={isLoadingPractices}
                    labelKey="practiceGroupName"
                    onChange={setSelectedPractice}
                    selected={selectedPractice}
                />
            </Row>
            <Row className="pt-3">
                <AsyncSearch
                    id={`${componentName}-provider`}
                    title="Associated Provider Name"
                    disabled={!isSpecialitySelected() || !isPayerSelected()}
                    minLength={1}
                    onSearch={asyncSearchAssociatedProvider}
                    options={associatedProviders}
                    isLoading={isLoadingAssociatedProviders}
                    labelKey="associatedProviderName"
                    onChange={setSelectedAssociatedProvider}
                    selected={selectedAssociatedProvider}
                />
            </Row>
            <Row className="pt-3">
                <InputNumber
                    label="Zip Code"
                    disabled={!isSpecialitySelected() || !isPayerSelected()}
                    maxLength="11"
                    onChange={setZipCode}
                    value={zipCode}
                />
            </Row>
            <Row className="pt-3">
                <Form.Group>
                    <DropDownList
                        id={`${componentName}-providerGender`}
                        key={`${componentName}-providerGender`}
                        mode={DDLMode.SelectOne}
                        header="Provider Gender"
                        showSearchTextbox={false}
                        disabled={!isSpecialitySelected() || !isPayerSelected()}
                        getData={() => Promise.resolve(genders)}
                        handleChange={onSelect}
                        selected={selectedGender}
                    />
                </Form.Group>
            </Row>
        </>)
};

export default SpecialistReferralStep1;