import React, { useEffect, useState, useRef } from "react";

import { Button } from "../../../components/buttons";
import { Card } from "../../../components/cards";
import { Table } from "../../../components/tables";
import { SortableTh } from "../../../components/SortableTh";



import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

import {
  formatDateTime,
} from "../../../utils/util";

const componentName="UpdateUser";
const isEmpty = require("is-empty");

const TableLink = styled.td`
  color: #007bff;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledInput = styled.input`
  width: 13px;
  height: 13px;
`;


export const UpdateUser = ({
  updateStatus,
  handleModal,
  users,
  handleChangeCheckBox,
  showAddUpdateButtons,
  enableActDeactButton,
  sortCol,
  usersToActivateOrD,
  setSortOrder
}) => {
  const cb = useRef([]);
  const scb = useRef();
  const selectAll = (m) => {
    cb.current.forEach((e) => {
      e.el.checked = m.currentTarget.checked;
      handleChangeCheckBox({ target: m.currentTarget }, e.user.userAccountId);
    });
  };

  const [cbChecked, setCbChecked] = useState(false);
  //Check If currentUsers Exists, & If Not Dispatches getUsers

  useEffect(() => {
    if (cb) {
      cb.current.forEach((e) => {
        e.el.checked = false;
      });
    }
    if (scb && scb.current) {
      scb.current.checked = false;
    }
    showAddUpdateButtons = false;
  }, [users]);
  // since it is an array we need to method to add the refs
  const addToRefs = (el, user) => {
    if (el && !cb.current.includes(el)) {
      cb.current.push({ el: el, user: user });
    }
  };
  const sortColl = (el, so) => {
    setSortOrder({
      el,
      so: so==='DESC'? true : false
    });
    sortCol(el, so==='DESC'? true : false);
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group hidden={!showAddUpdateButtons}>
              <Button id={`${componentName}-bulkUpdateUserButton`}
                onClick={() => handleModal("BULK UPDATE USER")}
                disabled={!enableActDeactButton}
                className="me-2"
              >
                Update User
              </Button>
              <Button id={`${componentName}-addUserButton`} onClick={() => handleModal("ADD USER")}>Add User</Button>
            </Form.Group>
          </Col>
          <Col sm={6} className="text-end">
            <Form.Group>
              <Button
                id={`${componentName}-activateButton`}
                variant="primary"
                onClick={() => updateStatus("Activate")}
                disabled={!enableActDeactButton}
                className="me-2"
              >
                Activate
              </Button>
              <Button
                id={`${componentName}-deactivateButton`}
                variant="primary"
                onClick={() => updateStatus("Deactivate")}
                disabled={!enableActDeactButton}
              >
                Deactivate
              </Button>
            </Form.Group>
          </Col>
        </Row>
        {!users || !users.length || users[0] === "Empty" ? null : (
          <Table className="table-bordered">
            <thead className="table-light">
              <tr>
                <th scope="col" data-tablesaw-priority="persist">
                  <StyledInput
                    hidden={!showAddUpdateButtons}
                    checked={usersToActivateOrD}
                    onChange={(e) => selectAll(e)}
                    className="form-check-input"
                    type="checkbox"
                    id={`${componentName}-selectAllCheckbox`}
                    ref={scb}
                  />
                  <label htmlFor="basic_checkbox_1"></label>
                </th>
                <SortableTh  sortColumn ={sortColl} fieldName="email"  columnName="User ID" id={`${componentName}-emailColLabel`}/>
                <SortableTh sortColumn ={sortColl} fieldName="lastName"  columnName="Name" id={`${componentName}-nameColLabel`}/>
                <SortableTh columnName="Access Type" id={`${componentName}-accessTypeColLabel`}/>
                <SortableTh columnName="Identifier" id={`${componentName}-identifierColLabel`}/>
                <SortableTh columnName="Role" id={`${componentName}-roleColLabel`}/>
                <SortableTh columnName="Last Login" id={`${componentName}-lastLoginColLabel`}/>
                <SortableTh columnName="Status" id={`${componentName}-statusColLabel`}/>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {users.map((user, idx) => {
                return (
                  <tr key={user.id}>
                    <td>
                      {" "}
                      <StyledInput
                        ref={(e) => addToRefs(e, user)}
                        type="checkbox"
                        className="form-check-input"
                        name={user.id}
                        id={`${componentName}-selectCheckBox-${idx}`}
                        onChange={(e) =>
                          handleChangeCheckBox(e, user.userAccountId)
                        }
                      />
                      <label htmlFor="basic_checkbox_2"></label>
                    </td>
                    <TableLink id={`${componentName}-emailValue=${idx}`} onClick={() => handleModal("UPDATE USER", user)}>
                      {user.userName}
                    </TableLink>
                    <td id={`${componentName}-nameValue-${idx}`}>
                      {user.lastName && user.firstName
                        ? user.lastName + "," + user.firstName
                        : null}
                    </td>
                    <td id={`${componentName}-accessTypeValue-${idx}`}>{user.accessType ? user.accessType : null}</td>
                    <td id={`${componentName}-identifierValue-${idx}`}>{user.identifier ? user.identifier : null}</td>
                    <td id={`${componentName}-roleValue-${idx}`}>{user.userRole ? user.userRole.name : null}</td>
                    <td id={`${componentName}-lastLoginValue-${idx}`}>{!isEmpty(user.lastLoginTime) && formatDateTime(user.lastLoginTime)}</td>
                    {!user.accountActive ? (
                      <td id={`${componentName}-statusValue-${idx}`} className="text-danger">Inactive</td>
                    ) : (
                      <td id={`${componentName}-statusValue-${idx}`} className="text-success">Active</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {users && users.length > 0 && users[0] === "Empty" && (
          <div>
            <Card>
              <Card.Body>
                <Row>
                  <h2>User Is Not Available</h2>
                </Row>
              </Card.Body>
            </Card>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default UpdateUser;
