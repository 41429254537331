import React, { useEffect, useState } from "react";

import { useAuth } from "../../context/authContext";
import { incrementPatientsCounter } from "../../actions/patientSearch/patientSearchActions";
import Spinner from "react-bootstrap/Spinner";

import { Button } from "../../components/buttons";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import CalendarPagination from "../appointment/CalendarPagination";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import {
  formatDate,
  _calculateAge,
  getWrittenDate,
  formatDuration,
  formatStringProper,
  getPatientProfileLink,
  getPatientProfileLinkRx,
} from "../../utils/util";
import Modals from "../../components/modals";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../utils/apiClient";
import CalendarViewModal from "../appointment/CalenderViewModal";
import { useLocation } from "react-router";
import Dropdown from "react-bootstrap/Dropdown";
import SubmitReferralBody from "./SubmitReferral";
import Badge from "react-bootstrap/Badge";

const componentName = "CalendarCardView";

const StyledRiskLevel = styled.span`
  color: #fff;
  background-color: ${(props) =>
    props.riskLevel === `Very Low`
      ? `#3DCA37`
      : props.riskLevel === `Low`
      ? `#188dcd`
      : props.riskLevel === `Medium`
      ? `#FDCA6C`
      : props.riskLevel === `High`
      ? `#fd7e14`
      : props.riskLevel === `Very High`
      ? `#DD6161`
      : `#D1D3D5`};
`;

const StyledSdohBtn = styled.button`
  color: ${(props) =>
    props.scraScore >= 28
        ? `#fff`
        : props.scraScore >= 20
        ? `#000`
        : props.scraScore >= 0
        ? `#fff`
        : `#fff`} !important;
  background-color: ${(props) =>
    props.scraScore >= 28
      ? `#DD6161`
      : props.scraScore >= 20
      ? `#FDCA6C`
      : props.scraScore >= 0
      ? `#3DCA37`
      : `#3DCA37`};
`;

export let returnToAppointmentsUrl = encodeURIComponent(
  "returnTo_" + window.location.pathname
);

const referralTypes = [
  { text: "Specialist", value: "SPECIALIST" },
  { text: "Care Coordination (Equality Health)", value: "CARE_COORDINATION" },
];

const CalendarCardView = ({
  patientList,
  loadingPatients,
  handleDateChange,
  updateAppointment,
  setDateObject,
  dateObject,
  returnToUrl,
  removeAppointment,
  reloadPatientView,
}) => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();

  const auth = useAuth();
  const location = useLocation();

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const authorizedForGaps = auth.hasAuthority("CARE_GAP_QUICK_LIST_ACCESS");
  const authorizedForRafs = auth.hasAuthority("RAF_QUICK_LIST_ACCESS");
  const authorizedRecentVisit = auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS");
  const authorizedNextVisit = auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS");
  const authorizedReferral = auth.hasAuthority("AUTH_REFERRAL_SENT_ACCESS");
  const authorizedCareCoordinator = auth.hasAuthority(
    "AUTH_REFERRAL_CARE_COORDINATION_SENT_ACCESS"
  );
  const authorizedchronic = auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS");
  const handlePaginationChange = (date) => {
    handleDateChange(date, "CARD");
  };

  const handleReferralModal = (patient, type) => {
    setSelectedPatient(patient);
    setSelectedType(type);
    setShowReferralModal(true);
  };

  const refreshReferrals = (data) => {
    setShowReferralModal(false);
  };
  return (
    <>
      <Modals
        scrollable={true}
        referralHeight={true}
        show={showReferralModal}
        closeSubmitModal={() => setShowReferralModal(false)}
        onHide={() => setShowReferralModal(false)}
        size="xl"
        title="Submit Referral"
        completeBtn="SUBMIT"
        hideFooter={true}
        body={
          <SubmitReferralBody
            patient={selectedPatient}
            closeSubmitModal={() => setShowReferralModal(false)}
            addNewReferral={refreshReferrals}
            preSelectedType={selectedType}
          />
        }
      />{" "}
      <div className="d-inline-block ms-2">
        <CalendarPagination
          type={"CARD_VIEW"}
          paginateButtonClicked={handlePaginationChange}
          dateObject={dateObject}
          setDateObject={setDateObject}
        />
      </div>
      {!isEmpty(patientList) && !loadingPatients ? (
        <Row className="container-fluid px-0 px-sm-1 px-md-2">
          <Col>
            {patientList.map((patient, idx) => {
              return (
                <CardGroup key={patient.id}>
                  <Card id={`${componentName}-appointmentCard-${idx}`} className="mb-4 appCard">
                    <Row className="g-0">
                      <Col xl={4} lg={5} md={12} id={`${componentName}-cardLeft-${idx}`} className="card-left">
                        <Card.Body className="card-body h-100 position-relative">
                          <Row>
                            <Col className="ms-0">
                              <div className="form-check d-inline-block me-3 px-0">
                                <input
                                  id={`${componentName}-patientSelect-${idx}`}
                                  type="checkbox"
                                  name={patient.id}
                                />
                                <label htmlFor="basic_checkbox_2"></label>
                              </div>

                              <a
                                id={`${componentName}-patientFullName-${idx}`}
                                href={getPatientProfileLink(
                                  patient.id,
                                  location
                                )}
                                className="h4 fw-bold me-2 patientName align-middle"
                              >
                                <span>{patient.fullName}</span>
                              </a>
                              <a
                                id={`${componentName}-patientProfileLink-${idx}`}
                                href={getPatientProfileLink(
                                  patient.id,
                                  location
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                              >
                                <i className="fas fa-external-link-alt"></i>
                              </a>
                              <Badge
                                id={`${componentName}-patientGender-${idx}`}
                                pill
                                bg="light"
                                className="align-middle border forced-capital text-dark"
                              >
                                {patient.gender}
                              </Badge>
                              {patient.expired ?
                                <span id={`${componentName}-patientExpired-${idx}`} 
                                className="badge pill align-middle border text-light"
                                style={{ backgroundColor: "#E75334"}}>
                                  Expired
                                </span>
                              : null}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col
                              sm={6}
                              id={`${componentName}-patientDOB-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientDOBLabel-${idx}`}
                                className="d-block"
                              >
                                DOB
                              </label>
                              <span
                                id={`${componentName}-patientDOBValue-${idx}`}
                              >
                                {formatDate(patient.dateOfBirth)} (
                                {_calculateAge(patient.dateOfBirth)})
                              </span>
                            </Col>
                            <Col
                              sm={6}
                              id={`${componentName}-patientPayor-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientPayorLabel-${idx}`}
                                className="d-block"
                              >
                                Payor
                              </label>
                              <span
                                id={`${componentName}-patientPayorValue-${idx}`}
                              >
                                {patient.payer}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              sm={6}
                              id={`${componentName}-patientMemberID-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientMemberIDLabel-${idx}`}
                                className="d-block"
                              >
                                Member ID
                              </label>
                              <span
                                id={`${componentName}-patientMemberIDValue-${idx}`}
                              >
                                {patient.memberId}
                              </span>
                            </Col>
                            <Col
                              sm={6}
                              id={`${componentName}-patientLOB-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientLOBLabel-${idx}`}
                                className="d-block"
                              >
                                Line of Business
                              </label>
                              <span
                                id={`${componentName}-patientLOBValue-${idx}`}
                              >
                                {patient.lineOfBusiness}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              sm={6}
                              id={`${componentName}-patientPCPPractice-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientPCPPracticeLabel-${idx}`}
                                className="d-block"
                              >
                                PCP Practice
                              </label>
                              <span
                                id={`${componentName}-patientPCPPracticeValue-${idx}`}
                              >
                                {patient.originatingOrganization}
                              </span>
                            </Col>
                            <Col
                              sm={6}
                              id={`${componentName}-patientVBCPartner-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientVBCPartnerLabel-${idx}`}
                                className="d-block"
                              >
                                VBC Partner
                              </label>
                              <span
                                id={`${componentName}-patientVBCPartnerValue-${idx}`}
                              >
                                {patient.network1}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-5">
                            <Col
                              sm={6}
                              id={`${componentName}-patientPrimaryPhone-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientPrimaryPhoneLabel-${idx}`}
                                className="d-block"
                              >
                                Primary Phone
                              </label>
                              <span
                                id={`${componentName}-patientPrimaryPhoneValue-${idx}`}
                              >
                                {patient.primaryPhone}
                              </span>
                            </Col>
                            <Col
                              sm={6}
                              id={`${componentName}-patientSecondaryPhone-${idx}`}
                              className="mb-3"
                            >
                              <label
                                id={`${componentName}-patientSecondaryPhoneLabel-${idx}`}
                                className="d-block"
                              >
                                Secondary Phone
                              </label>
                              <span
                                id={`${componentName}-patientSecondaryPhoneValue-${idx}`}
                              >
                                {patient.secondaryPhone}
                              </span>
                            </Col>
                          </Row>
                          <Row className="p-3 position-alb">
                            <Col>
                              {patient && <>
                                <Counter
                                  patient={patient}
                                  reloadPatientView={reloadPatientView}
                                  idx={idx}
                                />
                                <CareCounter patient={patient} idx={idx} />
                                <MemberCounter patient={patient} idx={idx} />
                              </>
                              }
                            </Col>
                          </Row>
                        </Card.Body>
                      </Col>
                      <Col xl={8} lg={7} md={12} id={`${componentName}-cardRight-${idx}`} className="bg-light d-flex flex-column">
                        <div className="d-flex px-3 mt-3 mb-n2">
                          <div className="me-auto">
                            <ul className="nav fw-bold">
                              <li className="nav-item">
                                <StyledRiskLevel
                                  id={`${componentName}-patientRiskLevel-${idx}`}
                                  type="button"
                                  className="btn fw-bold btn-sm text-white mb-1 me-3 cursor-d text-nowrap"
                                  riskLevel={patient.riskLevel}
                                >
                                  Medical Risk: {patient.riskLevel}
                                </StyledRiskLevel>
                              </li>
                              <li className="nav-item">
                                <StyledSdohBtn
                                  id={`${componentName}-patientSDOH-${idx}`}
                                  type="button"
                                  className="btn fw-bold btn-sm text-white cursor-d text-nowrap"
                                  scraScore={patient.scraScore}
                                >
                                  SDOH Risk: {patient.scraScore}
                                </StyledSdohBtn>
                              </li>
                            </ul>
                          </div>
                          <div className="text-start text-md-end">
                            {authorizedRecentVisit ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {patient.recentVisitInfo ?? "No information reported at this time"}
                                  </Tooltip>
                                )}
                              >
                                <div
                                  className="d-inline-block me-4 me-md-0"
                                  id={`${componentName}-patientLastVisit-${idx}`}
                                >
                                  <label
                                    id={`${componentName}-patientLastVisitLabel-${idx}`}
                                    className="me-2"
                                  >
                                    Last PCP Visit:
                                  </label>
                                  <span
                                    id={`${componentName}-patientLastVisitValue-${idx}`}
                                  >
                                  {formatDate(patient.recentVisit)}
                                  </span>
                                </div>
                              </OverlayTrigger>
                            ): null }
                            {authorizedNextVisit ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {patient.nextVisitInfo ?? "No information reported at this time"}
                                  </Tooltip>
                                )}
                              >
                                <div
                                  className="d-inline-block ms-0 ms-md-4"
                                  id={`${componentName}-patientNextVisit-${idx}`}
                                >
                                  <label
                                    id={`${componentName}-patientNextVisitLabel-${idx}`}
                                    className="me-2"
                                  >
                                    Recommended Next Visit:
                                  </label>
                                  <span
                                    id={`${componentName}-patientNextVisitValue-${idx}`}
                                  >
                                  {formatDate(patient.nextVisit)}
                                  </span>
                                </div>
                              </OverlayTrigger>
                            ): null }
                            {(patient.ecipEligible && authorizedchronic) ? (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={(props) => (
                                        <Tooltip id="button-tooltip" {...props}>
                                          ECIP Eligible
                                        </Tooltip>
                                    )}
                                >
                                  <div
                                      className="d-inline-block ms-0 ms-md-4"
                                      id={`${componentName}-patientEcipEligible-${idx}`}
                                  >
                                    <Badge id={`${componentName}-patientEcipEligibleBadge-${idx}`} variant="primary" className="rounded-circle me-2 font-12">E</Badge>
                                  </div>
                                </OverlayTrigger>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                        <Card.Body className="pt-0">
                          <AppointmentCardBody
                            patient={patient}
                            updateAppointment={updateAppointment}
                            updating={loadingPatients}
                            removeAppointment={removeAppointment}
                            idx={idx}
                          />
                        </Card.Body>
                        <div>
                          <hr />
                          <Row className="mt-auto mb-4">
                            <Col sm={12}>
                              <div className="d-flex">
                                <div>
                                  <ul className="nav fw-bold">
                                    {authorizedForGaps ? (
                                      <li className="nav-item">
                                        <a
                                          id={`${componentName}-patientCareOpportunities-${idx}`}
                                          className="nav-link pt-0 pb-0"
                                          href={getPatientProfileLink(
                                            patient.id,
                                            location,
                                            "PreventiveCare"
                                          )}
                                        >
                                          {patient.openCareGapsCount} Care
                                          Opportunities
                                        </a>
                                      </li>
                                    ) : null}
                                    {authorizedForGaps ? (
                                      <li className="nav-item">
                                        <a
                                          id={`${componentName}-patientSuspectConditions-${idx}`}
                                          className="nav-link pt-0 pb-0"
                                          href={getPatientProfileLink(
                                            patient.id,
                                            location,
                                            "PreventiveCare"
                                          )}
                                        >
                                          {patient.activeRafsCount} Suspect
                                          Conditions
                                        </a>
                                      </li>
                                    ) : null}
                                    <li className="nav-item">
                                      <a
                                        id={`${componentName}-patientMedication-${idx}`}
                                        className="nav-link pt-0 pb-0"
                                        href={getPatientProfileLinkRx(
                                          patient.id,
                                          location,
                                          "History"
                                        )}
                                      >
                                        {patient.medicationsCount} RX
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        id={`${componentName}-patientNotes-${idx}`}
                                        className="nav-link pt-0 pb-0"
                                        href={getPatientProfileLink(
                                          patient.id,
                                          location,
                                          "Notes"
                                        )}
                                      >
                                        {patient.notesCount} Notes
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        id={`${componentName}-patientHospitalEvents-${idx}`}
                                        className="nav-link pt-0 pb-0"
                                        href={getPatientProfileLink(
                                          patient.id,
                                          location,
                                          "HospitalEvents"
                                        )}
                                      >
                                        {patient.allADTsCount} Hospitalizations
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        id={`${componentName}-patientEDVisits-${idx}`}
                                        className="nav-link pt-0 pb-0"
                                        href={getPatientProfileLink(
                                          patient.id,
                                          location
                                        )}
                                      >
                                        {patient.frequentEdVisitsCount} ED
                                        Visits
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="ms-auto nowrap">
                                  <Dropdown
                                    className="d-inline-block me-3"
                                    id={`${componentName}-patientMoreActions-${idx}`}
                                  >
                                    <Dropdown.Toggle variant="primary" id={`${componentName}-patientMoreActionsButton-${idx}`} className="btn-sm mb-0 me-0">
                                      Refer
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {authorizedReferral ? (
                                        <Dropdown.Item
                                          id={`${componentName}-patientMoreActionsSpecialist-${idx}`}
                                          onClick={() =>
                                            handleReferralModal(
                                              patient,
                                              referralTypes[0]
                                            )
                                          }
                                        >
                                          Refer to Specialist
                                        </Dropdown.Item>
                                      ) : null}
                                      {authorizedCareCoordinator ? (
                                        <Dropdown.Item
                                          id={`${componentName}-patientMoreActionsCareCoordination-${idx}`}
                                          onClick={() =>
                                            handleReferralModal(
                                              patient,
                                              referralTypes[1]
                                            )
                                          }
                                        >
                                          Refer for Care Coordination (Equality
                                          Health)
                                        </Dropdown.Item>
                                      ) : null}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </CardGroup>
              );
            })}
          </Col>
        </Row>
      ) : loadingPatients ? (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text id={`${componentName}-noAppointmentsFound`}>No Appointments Found</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

const Counter = ({ patient, reloadPatientView, idx }) => {
  const [counter, setCounter] = useState(patient.callsCount);
  const handleIncrementPatientCounter = () => {
    incrementPatientsCounter(patient.id)
      .then((res) => {
        setCounter(res.data);
        reloadPatientView();
      })
      .catch((err) => {
        //Handle Error
      });
  };

  return (
    <Button
      id={`${componentName}-patientContactCounter-${idx}`}
      variant="secondary"
      className="me-2 px-2"
      onClick={handleIncrementPatientCounter}
    >
      <i
        id={`${componentName}-patientContactCounterIcon-${idx}`}
        className="fa fa-phone-volume"
      />{" "}
      <span
        id={`${componentName}-patientContactCounterValue-${idx}`}
        className="badge bg-danger"
      >
        {counter}
      </span>
    </Button>
  );
};
const CareCounter = ({ patient, idx }) => {
  let activeCarePaths = 0;
  let carePaths = [];
  for (let i = 0; i < patient?.carePaths?.length; i++) {
    if (patient?.carePaths[i]) {
      activeCarePaths++;
      carePaths.push(patient.carePaths[i].name);
    }
  }

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={`${componentName}-careCountToolTip-${idx}`}>
          {" "}
          Care Programs: {activeCarePaths ? carePaths.toString() : " None"}{" "}
        </Tooltip>
      }
    >
      <Button
        id={`${componentName}-activeCarePathsCounter-${idx}`}
        variant="secondary"
        className="me-2 px-2"
      >
        <i
          id={`${componentName}-activeCarePathsCounterIcon-${idx}`}
          className="fas fa-hand-holding-medical"
        />{" "}
        <span
          id={`${componentName}-activeCarePathsCounterValue-${idx}`}
          className="badge bg-danger"
        >
          {activeCarePaths}
        </span>
      </Button>
    </OverlayTrigger>
  );
};

const MemberCounter = ({ patient, idx }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={`${componentName}-memberCountToolTip-${idx}`}>
          {" "}
          Worklists:{" "}
          {patient.workLists.length > 0
            ? patient.workLists.toString()
            : " None"}{" "}
        </Tooltip>
      }
    >
      <Button
        id={`${componentName}-workListCounter-${idx}`}
        variant="secondary"
        className="me-2 px-2"
      >
        <i
          id={`${componentName}-workListCounterIcon-${idx}`}
          className="fas fa-clipboard-list"
        />{" "}
        <span
          id={`${componentName}-workListCounterValue-${idx}`}
          className="badge bg-danger"
        >
          {patient.workLists.length}
        </span>
      </Button>
    </OverlayTrigger>
  );
};

const AppointmentCardBody = ({
  patient,
  updateAppointment,
  updating,
  removeAppointment,
  idx
}) => {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);

  return (
    <>
      <Modals
        show={showAppointmentModal}
        onHide={() => setShowAppointmentModal(false)}
        title={`Edit Appointment: ${patient.lastName}, ${patient.firstName}`}
        size="xl"
        completeBtn={"SAVE"}
        hideFooter={true}
        body={
          <CalendarViewModal
            parent="CPCard"
            carePath={null}
            onHideClicked={() => setShowAppointmentModal(false)}
            selectedPatient={patient?.appointment}
            updateParentAppointment={updateAppointment}
            removeAppointment={removeAppointment}
            updating={updating}
            patientSpecific={patient}
            patientCarePaths={[]}
          />
        }
      />
      <Row className="mb-2">
        <Col sm={4} id={`${componentName}-appointmentName-${idx}`}>
          <label id={`${componentName}-appointmentNameLabel-${idx}`} className="d-block">Appointment Name</label>
          <span id={`${componentName}-appointmentNameValue-${idx}`}>{patient?.appointment?.name}</span>
        </Col>
        <Col sm={4} id={`${componentName}-appointmentDate-${idx}`}>
          <label id={`${componentName}-appointmentDateLabel-${idx}`} className="d-block">{getWrittenDate(patient?.appointment?.appointmentDateTime)}</label>
          <span id={`${componentName}-appointmentDateValue-${idx}`}>{formatDuration(
            patient?.appointment?.appointmentDateTime,
            patient?.appointment?.durationInMinutes
          )}</span>
        </Col>
        <Col sm={2} className="text-end">
          <Button
            variant="secondary"
            className="align-end"
            id={`${componentName}-appointmentEdit-${idx}`}
            onClick={() => setShowAppointmentModal(true)}
          >
            Edit Appointment
          </Button>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} id={`${componentName}-appointmentProvider-${idx}`}>
          <label id={`${componentName}-appointmentProviderLabel-${idx}`} className="d-block">Provider</label>
          <span id={`${componentName}-appointmentProviderValue-${idx}`}>{patient?.appointment?.provider?.lastName},{" "}
          {patient?.appointment?.provider?.firstName}</span>
        </Col>
        <Col sm={4} id={`${componentName}-appointmentPatientReminder-${idx}`}>
          <input
            type="checkbox"
            id={`${componentName}-appointmentPatientReminderCheck-${idx}`}
            checked={patient?.appointment?.shouldSendReminder}
            disabled={true}
          />{" "}
          <span id={`${componentName}-appointmentPatientLabel-${idx}`}>Patient Reminder ({patient?.appointment?.notificationDelay}{" "}
          {formatStringProper(patient?.appointment?.notificationDelayUnit)} Delay)</span>
        </Col>
        <Col sm={4} id={`${componentName}-appointmentPatientNeeds-${idx}`}>
          <input
            type="checkbox"
            id={`${componentName}-appointmentPatientNeedsCheck-${idx}`}
            checked={patient?.appointment?.needsTransportation}
            disabled={true}
          />{" "}
          <span id={`${componentName}-appointmentPatientLabel-${idx}`}>Patient Needs Transportation</span>
        </Col>
      </Row>
    </>
  );
};

export default CalendarCardView;
