import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { DropDownList, DDLMode, DDLDataMode } from "../../dropDownLists/genericDDL";
import { useManageVisitContext } from '../../../views/quickList/components/context/ManageVisitContext/ManageVisit.context'
import { FileUpload } from '../../FileUpload'
import { updateAction } from '../api'
import { setManageVisitOpenedFromPageTitle } from '../../../actions/manageVisitActions'
import { useDispatch } from 'react-redux'

const componentName = 'AwaitingChart'

const AwaitingChartFields = ({item, setErrors }) => {
  const manageVisitState = useManageVisitContext();
  const documentOptions = item?.documents || [];
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [updateState, setUpdateState] = useState({ documents: [], });
  const [selectedDocs, setSelectedDocs] = useState([]);

  const [basisState, setBasisState] = useState({
    uploadedFiles: [],
    documents: [],
    visitIds: [item?.id],
    patientId: manageVisitState?.patient_get?.patientId,
    patientLastUpdated: manageVisitState?.patient_get?.patientLastUpdated,
    visitDate: item?.visitDate
  });

  useEffect(() => {

    let doc = [];
    if (updateState?.documents[0]?.paramValue !== undefined && updateState.documents[0].paramValue.length > 0) {
      doc = updateState?.documents[0]?.paramValue;
    }
    setBasisState({
      ...basisState,
      uploadedFiles: [...files],
      documents: [...doc]
    })
  }, [files, updateState]);

  const checkDisabled = () => {
    return files.length === 0 && (!selectedDocs.displayValue || selectedDocs.displayValue.length === 0);
  };

  const handleUpdate = () => {
    setErrors([])
    updateAction(basisState)
      .then(() => {
        dispatch(setManageVisitOpenedFromPageTitle('Missed Opportunities'));
        manageVisitState.findPatientPost(manageVisitState?.patient_get?.patientId);
        manageVisitState.findPatientGet(manageVisitState?.patient_get?.patientId)
      }).catch(e => setErrors([e?.response?.data?.message]))
  }

  const handleDocumentSelect = (selectedDocs) => {
    setSelectedDocs(selectedDocs);
    setUpdateState({ ...updateState, documents: [selectedDocs] });
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          <Form.Group id={`${componentName}-AwaitingChartDocuments`}>
            <DropDownList
              mode={DDLMode.MultipleSelect}
              dataMode={DDLDataMode.OneAPICallFilterInUI}
              header="Documents"
              showRequired={false}
              getData={() => 
                Promise.resolve(
                  documentOptions.map((document) => {
                    return {
                      value: document.id,
                      text: document.displayName,
                    };
                }))
              }
              highlightWhenHasValue={false}
              showSearchTextbox={false}
              handleChange={(selectedDocs) => handleDocumentSelect(selectedDocs)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group id={`${componentName}-AwaitingChartFiles`}>
            <Form.Label id={`${componentName}-AwaitingChartFilesLabel`} className="d-block">
              Evidence
            </Form.Label>
            <FileUpload multiple={false} onChange={e => setFiles([...e])} />
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
          <Button id={`${componentName}-AwaitingChartSubmit`} onClick={handleUpdate} disabled={checkDisabled()}>
            Submit
          </Button>
      </div>
    </>
  )
}

export default AwaitingChartFields