import React from "react";
import { formatDateVanilla } from "../../../../../../utils/util";

const componentName = "SourceColumn";

const SnoozedColumn = ({ patient, idx }) => {
  return (
    <><span id={`${componentName}-patientcarepathTile-${idx}`} className="fw-bold">
        {patient?.carePathStepDto?.title}
      </span>
      <span>
        <span id={`${componentName}-patientcareprogram-${idx}`} className="text-muted fw-bold" style={{ fontSize: '12px', marginRight: '0.5em' }}>
          Snooze Reason:
        </span>
        <span id={`${componentName}-patientsnoozeReason-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
          {patient?.carePathStepDto?.snoozeReason}
        </span>
      </span>
      <span>
        <span id={`${componentName}-patienttimesSnoozedLabel-${idx}`} className="text-muted fw-bold" style={{ fontSize: '12px', marginRight: '0.5em' }}>
          Times Snoozed:
        </span>
        <span id={`${componentName}-patienttimesSnoozed-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
          {patient?.carePathStepDto?.timesSnoozed}
        </span>
      </span>
      <span id={`${componentName}-patientDischargeDate-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
        <span id={`${componentName}-patientEventDateValue-${idx}`}>
          {formatDateVanilla(patient?.carePathStepDto?.snoozeStarts, "MMM dd, yyyy hh:mm")} - {formatDateVanilla(patient?.carePathStepDto?.snoozeEnds, "MMM dd, yyyy hh:mm")}
        </span>
      </span>
    </>
  )
}

export default SnoozedColumn