import {get, isEmpty} from "lodash";

export const transformToUpdateManyRequestBody = (patientId, careVisits,
  uploadedFiles, {patientLastUpdated, visitDate}) => {
  return careVisits?.map(careVisit => {
    return {
      "bloodPressureSystolic": careVisit.bloodPressureSystolic,
      "bloodPressureDiastolic": careVisit.bloodPressureDiastolic,
      "a1cWholes": careVisit.a1cWholes,
      "a1cDecimal": careVisit.a1cDecimal,
      "note": "",
      "uploadedFiles": uploadedFiles,
      "careGapAdditionalInfos": getCareGapAdditionalInfos(careVisit),
      "patientLastUpdated": patientLastUpdated,
      "visitDate": careVisit.date ?? visitDate,
      "patientId": patientId,
      "visitIds": getVisitIds(careVisit),
      "updateCareGapActionDto": getUpdateCareGapActionDto(careVisit),
      "updateTOCCareGapActionDto": null,
      "updateValidationCareGapActionDto": null,
      "updateValidationConditionActionDto": null,
      "updateConditionActionDto": getUpdateConditionActionDto(
        {...careVisit, visitDate}),
      "immunizationsDTO": {}
    }
  })
}

const getVisitIds = ({id, actionType}) => {
  if (actionType !== 'VISIT_DUE') {
    return [];
  }
  return [id];
}

const getUpdateCareGapActionDto = ({id, date, statusEnum, actionType}) => {
  if (actionType !== 'CARE_GAP') {
    return null;
  }

  return {
    "ids": [id],
    "newStatus": {
      "source": statusEnum
    },
    "additionalInfos": [],
    "additionalInfoCaption": null,
    "exclusionCriteria": [],
    "newDateOfService": date
  };
}

const getUpdateConditionActionDto = ({id, date, visitDate, actionType}) => {
  if (actionType !== 'SUSPECT') {
    return null;
  }

  return {
    ids: [id],
    visitDate: date ?? (visitDate ? visitDate : null),
    validation: null,
    icd10DiseaseDto: null,
    forPreviousYear: false,
  }
}

const getCareGapAdditionalInfos = ({id, attestation}) => {
  const careGapAdditionalInfos = attestation?.filterValue?.paramValue.map(
    (id, index) => {
      const description = get(attestation,
        'filterValue.displayValue.' + index);
      return {id: id, description: description, selected: true};
    })

  if (isEmpty(careGapAdditionalInfos)) {
    return {};
  }

  return {[id]: careGapAdditionalInfos};
}