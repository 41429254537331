import React, { useState } from "react";
import styled from "styled-components";
import ErrorToast from "../views/common/ErrorToast";
import axios from "../utils/apiClient";
import EQHErrorToast from "./EQHErrorToast";

const isEmpty = require("is-empty");
const StyledUpload = styled.div`

  .custom-file ::-webkit-file-upload-button {

    display: none;

  }

`;
const componentName = "Upload";
const Upload = ({ fileName, setFile, disabled, accept, multiple }) => {

  const [errors, setErrors] = useState(null);
  const [inputKey, setInputKey] = useState("inputKey");


  const handleFileChange = (e) => {

    let filesObj = {};
    const file = e.currentTarget.files && e.currentTarget.files.length > 1
      ? e.currentTarget.files
      : e.currentTarget.files[0]
    const fileCount = e.currentTarget.files.length;

    //for (let file of e.target.files) {
    const fileReader = new FileReader();
    fileReader.filename = file.name;
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = function (readerEvt) {
      if (
        readerEvt &&
        readerEvt.currentTarget.result &&
        !isEmpty(readerEvt.currentTarget.result)
      ) {
        filesObj[readerEvt.currentTarget.filename] = Array.from(
          new Uint8Array(readerEvt.currentTarget.result)
        );
      }
    };
    // }

    (function wait() {
      if (Object.keys(filesObj).length < fileCount) {
        setTimeout(wait, 2000);
      } else {

        validate(filesObj, file);

      }
    })();
  };

  // const handleFileChange = (e) => {
  //   setErrors('')
  //   const fil = e.currentTarget.files && e.currentTarget.files.length > 1
  //     ? e.currentTarget.files
  //     : e.currentTarget.files[0]

  //   let filesObj = null;
  //   const fileReader = new FileReader();
  //   fileReader.filename = fil.name;
  //   if (fileReader && fil) {
  //     fileReader.readAsArrayBuffer(fil);
  //     fileReader.onload = function (ev) {
  //       filesObj=Array.from(
  //         new Uint8Array(ev.currentTarget.result))
  //         validate(filesObj, fil)
  //     };
  //   }
  //   //return file?. = size >0 && ((file.size / 1024) / 1024) < 20;
  // }


  const validate = (filesObj, file) => {
    axios
      .post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/v1/file-upload/fileUpload/validateOnly`,
        filesObj,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      )
      .then((res) => {
        const error = res?.data?.error && res?.data?.errorMessage ? res?.data?.errorMessage : ''
        if (error) {
          setErrors(error);
          setInputKey(`inputKey-${Math.random()}`)
        }
        else {
          //handleChange(res.data);
          setFile(file)
        }
        //setShowProgress(false);
      })
      .catch((err) => {
        //formatErrorMessage(err);
      });
  };

  return (
    <StyledUpload className="input-group">
      <div className="custom-file">
        <label id={`${componentName}-uploadButton`} className="btn btn-primary me-2" for={`${componentName}-uploadFile`}>
          <i className="fa fa-cloud-upload me-2"></i> Choose File
        </label>
        <input
          disabled={disabled}
          type="file"
          onChange={(e) => handleFileChange(e)}
          id={`${componentName}-uploadFile`}
          aria-describedby="inputGroupFileAddon01"
          data-test="uploadInput"
          multiple={multiple}
          accept={accept}
          key={inputKey}
        />
      </div>
      {errors?.length > 0 &&
        <ErrorToast
          errorParam={errors}
          closeToast={() => setErrors(null)}
        />
      }
    </StyledUpload>
  );
};

export default Upload;
