import React, { useState, Fragment } from "react";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  DropDownButton,
  CustomMenuStyled,
  paramValue,
} from "./sharedDDL";
import {
  FilterParam,
  emptyFilterParam,
} from "../../dtos/PatientSearchDTO";
import { EQHDatePicker } from "../datePicker";
import _ from "lodash";

const monthByNameMap = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const monthByNumberMap = Object.fromEntries(
  monthByNameMap.map((kvp) => [kvp.value, kvp.text])
);

const componentName = "ComparisonDDL";
const COMPARISON_DDL_TYPE = "ComparisonDDL";

const ComparisonDDLKind = {
  DatePicker: "DatePicker",
  FreeText: "FreeText",
};

const ComparisonDropDownList = ({
  header,
  handleChange,
  selected,
  height = "",
  width = "100%",
  type = ComparisonDDLKind.DatePicker,
  highlightWhenHasValue = true,
  externalDataMap,
  onLoad,
  onlyShowRange = false,
  allowRange = false,
  allowMonth = false,
  showRequired,
}) => {

  const idFragment = header.replaceAll(" ", "_").toUpperCase();

  const getComparisonText = (operator) => {
    if (operator) {
      operator = operator.toLowerCase();
    }
    switch (operator) {
      case "lt":
      case "less_than":
        return operators[1];
      case "gt":
      case "greater_than":
        return operators[2];
      case "range":
        return operators[3] ?? operators[0];
      case "month":
        return (allowRange ? operators[4] : operators[3]) ?? operators[0];
      case "eq":
      case "equals_to":
      default:
        return operators[0];
    }
  };
  let operators =
    type === ComparisonDDLKind.DatePicker
      ? ["On", "Prior to", "Since"]
      : ["Equal to", "Less than", "Greater than"];

  if (type === ComparisonDDLKind.DatePicker && allowRange) {
    operators.push("Range");
  }

  if (type === ComparisonDDLKind.DatePicker && allowMonth) {
    operators.push("Month");
  }

  if (onlyShowRange) {
    operators = ["Range"];
    allowRange = true;
  }

  const comparisonValues = paramValue(selected);
  const [selectedOperator, setSelectedOperator] = useState(() =>
    selected && selected.comparison
      ? getComparisonText(selected.comparison)
      : operators[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  const isRange = (operator) => (operator ?? selectedOperator) === "Range";
  const isMonth = (operator) => (operator ?? selectedOperator) === "Month";

  if (isMonth()) {
    for (let i = 0; i < comparisonValues.length; i++) {
      if (typeof comparisonValues[i] === "string") {
        comparisonValues[i] = parseInt(comparisonValues[i]);
      }
    }
  }

  const getOnChangeDTO = (comparisonValues, operator) => {
    let filterParamOperator = "eq";

    if (_.isEmpty(comparisonValues)) {
      return emptyFilterParam(header);
    }

    if (operator) {
      switch (operator) {
        case "Less than":
        case "Prior to":
          filterParamOperator = "lt";
          break;
        case "Greater than":
        case "Since":
          filterParamOperator = "gt";
          break;
        case "Range":
          filterParamOperator = "range";
          break;
        case "Month":
          filterParamOperator = "month";
          break;
        case "Equal to":
        case "On":
        default:
          filterParamOperator = "eq";
      }
    }
    let filterParam = new FilterParam(
        header,
        filterParamOperator,
        comparisonValues.map(x => {return {text: monthByNumberMap[x], value: x}}),
        (e) => e?.value,
        (e) => e?.text
    );
    return filterParam;
  };

  const onDataChange = (comparisonValues, selectedOperator) => {
    if (!Array.isArray(comparisonValues)) {
      comparisonValues = [comparisonValues];
    }
    setSelectedOperator(selectedOperator);
    handleChange(getOnChangeDTO(comparisonValues, selectedOperator));
  };

  const onDateChange = (date, idx) => {
    let comparisonValuesClone = [...comparisonValues];
    comparisonValuesClone[idx] = date;
    onDataChange(comparisonValuesClone, selectedOperator);
  };

  const onMonthChange = (month, selected) => {
    let comparisonValuesClone = [...comparisonValues];
    if (selected) {
      comparisonValuesClone.push(month);
    } else {
      var index = comparisonValuesClone.indexOf(month);
      if (index > -1) {
        comparisonValuesClone.splice(index, 1);
      }
    }
    onDataChange(comparisonValuesClone, selectedOperator);
  };

  const onFreeTextChange = (text) => {
    onDataChange(text, selectedOperator);
  };

  if (onLoad) {
    onLoad(
      externalDataMap,
      getOnChangeDTO(comparisonValues, selectedOperator),
      onDataChange
    );
  }

  const closedAndHasSelectedData = () => {
    return !isOpen && !_.isEmpty(comparisonValues);
  };

  const handleToggle = (ddlOpen, event, metadata) => {
    setIsOpen(ddlOpen);
  };

  const onRadioClick = (operator) => {
    onDataChange([], operator);
  };

  const onClearClick = () => {
    onDataChange([], onlyShowRange ? "Range" : "On");
  };

  const yyyyMMddToMMddyyyy = (strDate) => {
    return strDate
      ? `${strDate.substr(5, 2)}/${strDate.substr(8, 2)}/${strDate.substr(
          0,
          4
        )}`
      : "";
  };

  const MMddyyyyToyyyyMMdd = (strDate) => {
    return strDate
      ? `${strDate.substr(6, 4)}-${strDate.substr(0, 2)}-${strDate.substr(
          3,
          2
        )}`
      : "";
  };

  const getDisplayText = () => {
    let text = "";
    if (_.isEmpty(comparisonValues)) {
      text = "-Select-";
    } else if (isRange()) {
      text = `${selectedOperator} ${
        comparisonValues[0] ? comparisonValues[0] : ""
      } - ${comparisonValues[1] ? comparisonValues[1] : ""}`;
    } else if (isMonth()) {
      text = comparisonValues.map((x) => monthByNumberMap[x]).join(", ");
    } else {
      text =
        `${selectedOperator} ` + comparisonValues[0] ? comparisonValues[0] : "";
    }

    return text;
  };

  return (
    <Row xl={1}>
      <Col id={`${componentName}-${idFragment}-filter`} className="mb-3">
        <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
          <span id={`${componentName}-${idFragment}-filterTitleText}`}>{header}</span>
          {showRequired && <span className="text-danger">*</span>}
          {closedAndHasSelectedData() ? (
            <Fragment>
              &nbsp;
              <i id={`${componentName}-${idFragment}-filterReset`} onClick={onClearClick} className="fas fa-times-circle cursor-p"></i>
            </Fragment>
          ) : (
            ""
          )}
        </label>
        <Dropdown id={`${componentName}-${idFragment}-filterDropdown`} onToggle={handleToggle}>
          <Dropdown.Toggle
            id={`${componentName}-${idFragment}-filterValue`}
            as={DropDownButton}
            cssOpen={isOpen}
            activeClosedFilter={
              highlightWhenHasValue && closedAndHasSelectedData()
            }
            cusWidth={width}
          >
            <span>{getDisplayText()}</span>
            <i className="fa fa-chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuStyled} custHeight={height} className="p-3 w-100">
            {selectedOperator !== "Month" &&
              (type === ComparisonDDLKind.DatePicker ? (
                <>
                  <EQHDatePicker
                    id={`${componentName}-${idFragment}-datePicker`}
                    onChange={(e) =>
                      onDateChange(yyyyMMddToMMddyyyy(e.strDate), 0)
                    }
                    value={MMddyyyyToyyyyMMdd(comparisonValues[0])}
                  />
                  {isRange() ? (
                    <EQHDatePicker
                      id={`${componentName}-${idFragment}-datePicker2`}
                      onChange={(e) =>
                        onDateChange(yyyyMMddToMMddyyyy(e.strDate), 1)
                      }
                      value={MMddyyyyToyyyyMMdd(comparisonValues[1])}
                    />
                  ) : null}
                </>
              ) : (
                <Form.Control
                  type="text"
                  value={_.isEmpty(comparisonValues) ? "" : comparisonValues[0]}
                  onChange={(e) => onFreeTextChange(e.target.value)}
                />
              ))}

            <Button id={`${componentName}-${idFragment}-clearValue`} variant="link" onClick={onClearClick} className="ps-0">
              Clear
            </Button>
            {!onlyShowRange &&
              operators.map((x, idx) => (
                <Form.Check
                  key={`${componentName}-${x}-${idx}`}
                  id={`${componentName}-${x}-${idx}`}
                >
                  <Form.Check.Input id={`${componentName}-${x}-${idx}`} type="radio" onChange={() => onRadioClick(x)} checked={x === selectedOperator} />
                  <Form.Check.Label id={`${componentName}-${x}Label-${idx}`} onChange={() => onRadioClick(x)}>{x}</Form.Check.Label>
                </Form.Check>
              ))}
            {isMonth() && (
              <>
                {" "}
                {monthByNameMap.map((x, idx) => (
                  <Form.Check
                    id={`${componentName}-months-${idFragment}-${idx}`}
                  >
                    <Form.Check.Input id={`${componentName}-months-${idFragment}-${idx}`} type="checkbox" onChange={(e) => onMonthChange(x.value, e.target.checked)}  checked={comparisonValues.includes(x.value)} />
                    <Form.Check.Label id={`${componentName}-months-${idFragment}Label-${idx}`} onChange={(e) => onMonthChange(x.value, e.target.checked)}>{x.text}</Form.Check.Label>
                  </Form.Check>
                ))}{" "}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export { ComparisonDropDownList, ComparisonDDLKind, COMPARISON_DDL_TYPE };
