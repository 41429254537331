import React, { useEffect, useState } from "react";
import { Card } from "../../components/cards";
import {
  Button,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
  Badge
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import axios from "../../utils/apiClient";
import { useAuth } from "../../context/authContext";
import { QuickListForCareMgmt } from "./QuickListForCareMgmt";
import { useGetTransQuickListSettings } from "../quickList/quickListSettings";
import {
  getExportSummary,
  addReferral, searchPatients,
  getDailyTicker, uploadFile,
  exportMembers, handleNavigateToCarePath, MARKETS
} from "../../actions/caremgmt/hubWorkCenterAction";
import EQHErrorToast from "../../components/EQHErrorToast";
import { EQHSpinner } from "../../components/EQHSpinner";
import CustomPagination from "../common/CustomPaginaton";
import { SortableTh } from "../../components/SortableTh";
import { _calculateAge, formatDate, formatDateWithTime, getRiskLevelStyle } from "../../utils/util";
import ExportSummary from "../quickList/ExportSummary";
import ImportSummary from "../quickList/ImportSummary";
import Modals from "../../components/modals";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Upload from "../../components/Upload";
import styled from "styled-components";
import {AssignmentTracker} from "./hubWorkCenter/assignmentTracker";
import { setManageVisitOpenedFromPageTitle, setManageVisitPatient } from "../../actions/manageVisitActions";
import { useDispatch } from "react-redux";
import PatientListOffCanvas from "./PatientListOffCanvas";
import { Helmet } from "react-helmet";

const componentName = "MemberAssign";

const defaultSorting = [
  {paramName: "risk", direction: "ASC", priority: 1},
  {paramName: "readmit", direction: "ASC", priority: 2},
  {paramName: "lastPCPVisit", direction: "ASC", priority: 3},
  {paramName: "fullName", direction: "ASC", priority: 4},
]

export const MemberAssign = () => {

  const isEmpty = require("is-empty");
  const [openStatusObj, setOpenStatusObj] = useState({});
  const [showAssign, setShowAssign] = useState(false);
  const handleCloseAssign = () => setShowAssign(false);
  const [showDecline, setShowDecline] = useState(false);
  const handleCloseDecline = () => setShowDecline(false);
  const [showMember, setShowMember] = useState(false);
  const handleCloseMember = () => setShowMember(false);
  const handleShowMember = () => setShowMember(true);
  const [selectedName, setSelectedName]  = useState('');
  const [showMemberList, setShowMemberList] = useState(false);
  const handleCloseMemberList = () => {
    setShowMemberList(false);
    setSelectedPatients(new Set());
    setPatientSearchResultRow([]);
    setAddingMember(false);
    setShowMember(false);
  };
  const handleShowMemberList = () => setShowMemberList(true);
  const [teams, setTeams] = useState([]);
  const [count, setCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [members, setMembers] = useState([]);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20, totalSize: 0 });
  const [actions, setActions] = useState([]);
  const [allMemberCheckToggle, setAllMemberCheckToggle] = useState(false);
  const [patientSearchParam, setPatientSearchParam] = useState("");
  const handlePatientSearchParamChange = (event) => {
    setPatientSearchParam(event.target.value);
    if (event.target.value.trim() === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };
  const [patientSearchResultRow, setPatientSearchResultRow] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState(new Set());
  const [isSearchingPatients, setSearchingPatients] = useState(false);
  const [isAddingMember, setAddingMember] = useState(false);
  const toogleAddingMember = () => setAddingMember(!isAddingMember);
  const [assignment, setAssignment] = useState([]);
  const [declineReasons, setDeclineReasons] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [activity, setActivity] = useState({});
  const [selectedReferrals, setSelectedReferrals] = useState(new Set());
  const [programLead, setProgramLead] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleDeclineNotesParamChange = (event) => setActivity({ ...activity, "notes": event.target.value });

  const [modalMessage, setModalMessage] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [showExportSpinner, setShowExportSpinner] = useState(false);
  const [showImportSpinner, setShowImportSpinner] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [referralIdSelected, setReferralIdSelected] = useState(null);
  const [filterParam, setFilterParam] = useState([]);
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [disabled, setDisabled] = useState(true);
  const [selectedMarkets, setSelectedMarket] = useState("");
  const [createCarePath, setCreateCarePath] = useState(true);
  const [sortParam, setSortParam] = useState([]);
  const [exportFilterParms, setExportFilterParams] = useState([]);

  const auth = useAuth();
  const getSettingsRef = useGetTransQuickListSettings("hubCenter", 0, auth);
  const dispatch = useDispatch();

  const DropDownDiv = styled.div`
  #GenericDDL--filterValue { margin-bottom: 0!important; }
  #GenericDDL--filter { margin-bottom: 0!important; }
`;

  const fetchDailyTicker = () => {
    const market = selectedMarkets?.displayValue?.join(",");
    getDailyTicker(market).then((response) => {
      setTeams(response.data || []);
    }).catch((error) => {
      onError(error);
    });
  }

  useEffect(() => {
    fetchDailyTicker();
    setTimeout(() => setCount(count + 1), 60000);
  }, [count, selectedMarkets]);

  useEffect(() => {
    fetchReferrals();
    fetchActions();
    fetchDeclineReasons();
    fetchPrograms();
    setFile({});
    setFileName('Choose File');
  }, []);

  const validateActivity = () => {
    const invalid = isEmpty(activity?.reason) && isEmpty(programLead?.notes)
    return validate(!invalid);
  }

  const validateAsignment = () => {
    const invalid = isEmpty(assignment?.teamId) ? true : isEmpty(programLead?.userAccountId) ? true : isEmpty(activity?.program) ? true : false;
    return validate(!invalid);
  }

  const validate = (condition) => {
    if (!condition) {
      onError("All fields are required");
    }
    return condition;
  }

  const fetchReferrals = (
    pageNumber = pagination.pageNumber,
    pageSize = pagination.pageSize,
    sortParams = sortParam,
    filterParams = filterParam
  ) => {
    const useDefaultSorting = sortParams.length == 0 ? defaultSorting : sortParams;
    setIsLoadingMembers(true);

    const params = {
      "filterParams": filterParams,
      "sortParams": useDefaultSorting,
      "pageNumber": pageNumber,
      "pageSize": pageSize,
      "quickListName": "HUB_WORKSPACE"
    };
    return axios.post(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/referrals`,
      params,
      {
        headers: {},
      }
    ).then((resp) => {
      if (resp && resp.data) {
        const { patientCardDtos, pageNumber, pageSize, totalSize } = resp.data;
        setMembers(processReferralsFetched(patientCardDtos));
        setPagination({ pageNumber: pageNumber, pageSize: pageSize, totalSize: totalSize });
      }
      setIsLoadingMembers(false);
    }).catch((error) => {
      onError(error);
      setIsLoadingMembers(false);
    });
  }

  const processReferralsFetched = (patientCardDtos) => {
    return patientCardDtos.map(patient => {
      let referral = { isSelected: false, ...patient }
      if (selectedReferrals && selectedReferrals.has(patient.id)) {
        referral = { isSelected: true, ...patient }
      }
      return referral
    })
  }

  const fetchActions = () => {
    return axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/actions`, { headers: {}, }
    ).then((resp) => {
      if (resp && resp.data) {
        setActions(resp.data || []);
      }
    }).catch((error) => {
      onError(error);
    });
  }

  const fetchDeclineReasons = () => {
    return axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/decline-reasons`, { headers: {}, }
    ).then((resp) => {
      if (resp && resp.data) {
        setDeclineReasons(resp.data);
      }
    }).catch((error) => {
      onError(error);
    });
  }

  const fetchPrograms = () => {
    return axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/programs`, { headers: {}, }
    ).then((resp) => {
      if (resp && resp.data) {
        setPrograms(resp.data || []);
      }
    }).catch((error) => {
      onError(error);
    });
  }

  const assignMember = () => {
    let payload = [];
    let isBulkAction = false
    if (referralIdSelected) {
      payload = [{
        ...buildActivityPayload(referralIdSelected, programLead.userAccountId, activity.program, activity.programName),
        openCarePath: createCarePath
      }]
    } else {
      isBulkAction = true
      payload = Array.from(selectedReferrals).map(referral =>
      ({
        ...buildActivityPayload(referral, programLead.userAccountId, activity.program, activity.programName),
        openCarePath: createCarePath
      })
      )
    }

    return axios.put(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/assign`, payload)
      .then((resp) => {
        handleActionResponse(resp)
        if (isBulkAction) {
          clearSelectedReferrals()
        }
      }).catch((error) => {
        fetchReferrals();
        onError(error);
      });
  }

  const saveActivity = () => {
    let payload = []
    let isBulkAction = false
    if (referralIdSelected) {
      payload = [buildActivityPayload(referralIdSelected, "", "", "")]
    } else {
      isBulkAction = true
      payload = Array.from(selectedReferrals).map(referral =>
        buildActivityPayload(referral, "", "", ""))
    }

    return axios.post(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/add-activity`, payload)
      .then((resp) => {
        handleActionResponse(resp)
        setCreateCarePath(true);
        if (isBulkAction) {
          clearSelectedReferrals()
        }
      }).catch((error) => {
        setCreateCarePath(true);
        onError(error);
      });
  }

  const handleActionResponse = (response) => {
    if (response && response.data) {
      fetchReferrals();
      fetchDailyTicker();
    }
  }

  const buildActivityPayload = (patientId, programLeadId, programId, programName) => {
    const payload = {
      reason: activity.reason,
      notes: activity.notes,
      action: activity.action,
      origin: 'HUB_WORKCENTER',
      patientId,
      programLeadId,
      programId,
      programName
    };
    return payload
  }

  const onError = (err) => {
    if (typeof err === 'string') {
      setErrors([err]);
    } else if (Array.isArray(err)) {
      setErrors([err.join(', ')]);
    }
    else {
      const { response } = err;
      if (response && response.data) {
        setErrors([response.data.message]);
      }
    }
  }

  const paginate = (i) => {
    if (i) {
      fetchReferrals(i, 20, sortParam);
    }
  };

  const sortColl = (el, so) => {
    const sortParams = [
      {
        paramName: el,
        direction: so,
        priority: 1
      }
    ];
    setSortParam(sortParams);
    fetchReferrals(1, 20, sortParams);
  };

  const sortZipCode = (el, so) => {
    const sortParams = [
      {
        paramName: el,
        direction: so,
        priority: 1
      },
      {
        paramName: "fullName",
        direction: "ASC",
        priority: 2
      }
    ];
    setSortParam(sortParams);
    fetchReferrals(1, 20, sortParams);
  };

  const handleOpenTableDetail = (key) => {
    if (openStatusObj) {
      const statusObj = !openStatusObj[key];
      const newOpenStatusObj = { ...openStatusObj, ...{ [key]: statusObj } };
      setOpenStatusObj(newOpenStatusObj);
    }
  }

  const isTableDetailOpen = (key) => {
    return openStatusObj[key];
  };

  const handleAction = (type, member) => {
    if (member) {
      setReferralIdSelected(member.id)
    }
    setSelectedName(member ? member.fullName : '')

    const types = ["ASSIGN", "DECLINE"];
    if (types.includes(type)) {
      if (type === "ASSIGN") {
        setShowAssign(true);
      }
      else {
        setShowDecline(true);
      }
      setActivity({ ...activity, action: type })
    }
  }


  const handleOnCheckboxChange = (patientId, event) => {
    if (event.target.checked) {
      selectedReferrals.add(patientId);
    } else {
      selectedReferrals.delete(patientId);
    }
    updateMemberSelected(patientId, event.target.checked)
  }

  const updateMemberSelected = (patientId, isChecked) => {
    let membersSelected = members.map(member => {
      if (member.id === patientId) {
        member.isSelected = isChecked
      }
      return member
    })
    setMembers(membersSelected)
  }

  const handleOnAllCheckboxChange = ({ target }) => {
    setAllMemberCheckToggle(target.checked);
    setMembers(members.map(member => {
      return {
        ...member,
        isSelected: target.checked
      }
    }))
    let allMemberCheck = []
    if (target.checked) {
      allMemberCheck = members.map(member => member.id)
    }
    setSelectedReferrals(new Set(allMemberCheck))
  }

  const handleExportList = () => {
    setShowExportSpinner(true);
    exportMembers(selectedReferrals, exportFilterParms)
      .then(() => {
        summary();
      }).catch((error) => {
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  }

  const summary = () => {
    getExportSummary()
      .then((response) => {
        setShowExportSpinner(false);
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch((error) => {
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const handlePatientSelected = (patientId, event) => {
    if (event.target.checked) {
      selectedPatients.add(patientId);
    } else {
      selectedPatients.delete(patientId);
    }
    setDisabled(selectedPatients.size === 0);
  }

  const handlePatientSearch = () => {
    handleShowMemberList();
    toogleAddingMember();
    searchPatients(patientSearchParam).then((response) => {
      const result = response.data?.patients || [];
      const resultList = [];
      result.forEach((searched, searchedIdx) => {
        const memberStatus = searched.membershipActive ?
          <span className="badge rounded-pill bg-success">Active</span> :
          <span className="badge rounded-pill bg-danger">Inactive</span>;
        const patientUrl = "/patient-profile?" + new URLSearchParams({
          'patientId': searched.id,
          'amp;returnTo': '/caremgmt/workcenter/?loadLastQuerySettings=true'
        });
        resultList.push(
          <Row>
            <Col sm={1}>
              <input onChange={(event) => handlePatientSelected(searched.id, event)} type="checkbox" />
            </Col>
            <Col className="mb-3">
              <a href={patientUrl} className="fw-bold d-block" target="_blank">
                <div id={"PatientListView-patientFullName-" + searchedIdx} className="forced-capital">
                  {searched.surnameCommaName}
                </div>
              </a>
            </Col>
            <Col className="mb-3">
              <label className="d-block">Member ID</label>
              <span>{searched.memberId} {memberStatus}</span>
            </Col>
            <Col className="mb-3">
              <label className="d-block">Phone Number</label>
              <span>{searched.primaryPhone}</span>
            </Col>
            <Col className="mb-3">
              <label className="d-block">Date of Birth</label>
              <span>{searched.dateOfBirth}</span>
            </Col>
            <Col className="mb-3">
              <label className="d-block">Payer</label>
              <span>{searched.payer}</span>
            </Col>
          </Row>,
          <hr className="mt-0" />
        )
      })
      if (resultList.length === 0) {
        resultList.push(<b id="PatientListView-no-results">No matching members were found. Please review your search criteria.</b>);
      }
      setPatientSearchResultRow(resultList);
      setAddingMember(false);
    });
  }

  const handleAddMember = () => {
    toogleAddingMember();
    const promises = [];
    selectedPatients.forEach((value) => {
      promises.push(addReferral(value));
    });
    Promise.allSettled(promises)
      .then(results => {
        const failedReasons = results
          .filter(e => e?.status === 'rejected')
          .map(e => e?.reason?.response?.data?.message);

        setErrors(failedReasons);
        handleCloseMemberList();
        fetchReferrals();
      })
  }

  const closeActivityModals = () => {
    handleCloseAssign();
    handleCloseDecline();
    resetAssignment();
  }

  const clearReferralIdSelected = () => setReferralIdSelected(null)

  const resetAssignment = () => {
    setAssignment({});
    setProgramLead({});
    setActivity({});
  }

  const handleAssignment = async () => {
    if (!isEmpty(selectedReferrals) || referralIdSelected) {
      switch (activity.action) {
        case "ASSIGN":
          if (validateAsignment()) {
            await assignMember();
          }
          break
        case "DECLINE":
          if (validateActivity()) {
            await saveActivity();
          }
          break
      }
    }
    clearReferralIdSelected()
    closeActivityModals();
  }

  const handleProgramChange = (e) => {
    const value = programs.find((t) => t.value == e.target?.value);
    setActivity({
      ...activity,
      "program": value?.value,
      "programName": value?.text
    });
  }

  const handleDeclineChange = (e) => {
    const value = declineReasons.find((t) => t.value == e.target?.value);
    setActivity({
      ...activity,
      "reason": value?.value
    });
  }

  const handleTeamChange = (e) => {
    const value = teams.find((t) => t.teamId == e.target?.value);
    setAssignment({
      ...assignment,
      "teamId": value?.teamId,
      "teamName": value?.name
    });
  }

  const handleMemberNameChange = (e) => {
    if (e && e[0]) {
      const value = e[0];
      setProgramLead({
        ...programLead,
        "name": value.text,
        "userAccountId": value.value
      });
    }
  }

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSearch = (query) => {
    const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/hub-workcenter";
    if (query) {
      const url =
        baseURL +
        "/program-leads" +
        (query ? "?name=" + query + "&teamId=" + (assignment?.teamId || '') : "");
      setIsLoading(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          if (resp && resp.data) {
            setOptions(resp.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          onError(error);
        });
    }
  };

  const searchFromInputText = (e) => {
    let value = e.target.value || '';
    value = value.trimEnd();

    if (value.length > 2 && e.type !== 'change') {
      const sortParams = { paramName: "fullName", direction: "asc", priority: 1 };
      const filterParams = [{ paramName: "hubcentersearch", paramValue: [value] }];
      fetchReferrals(1, 20, [sortParams], filterParams);
    }
    if (value.length === 0 && e.type === 'change') {
      fetchReferrals(1, 20);
    }
  }

  const getPatientsFromFilters = (data) => {
    clearSelectedReferrals()
    setMembers(processReferralsFetched(data.patientCardDtos))
    setPagination({
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      totalSize: data.totalSize
    });
  }

  const clearSelectedReferrals = () => {
    setSelectedReferrals(new Set())
  }

  const isSearchingWithFilters = (isLoading) => {
    setIsLoadingMembers(isLoading)
  }

  const getFilters = (filters) => {
    setFilterParam(filters)
  }

  const setFileMethod = (file) => {
    if (file && file.name) {
      setFileName(file.name)
    }
    setFile(file);
  };


  const handleFileUpload = () => {
    setShowImportSpinner(true);
    const formData = new FormData();
    formData.append('file', file, fileName);
    uploadFile(formData).then((res) => {
      setShowImportSpinner(false);
      setModalTitle("Import Succeeded");
      setModalSize("lg");
      setModalMessage(<ImportSummary notFoundRegisters={res.data?.notFoundRegisters} />);
      fetchReferrals();
    }).catch((error) => {
      setShowImportSpinner(false);
      onError(error);
    });
  }

  const getFilterParams = (params) => {
    setExportFilterParams(params?.filterParams || []);
  }

  return (
    <QuickListForCareMgmt
      {...getSettingsRef()}
      searchFromInputText={searchFromInputText}
      getPatientsData={getPatientsFromFilters}
      isSearching={isSearchingWithFilters}
      getFilters={getFilters}
      getFilterParams={getFilterParams}
    >
      <Helmet>
        <title>Hub Workcenter | CareEmpower</title>
      </Helmet>
      <div className="overflow-inherit w-100">
        <Row className="sticky-top mx-0 py-2 stickyTitle stickyTitle_custom" style={{ ['--offset']: '54px' }}>
          <Col className="px-0">
            <Row className="mb-2">
              <Col sm={6}>
                {!isEmpty(members) && Math.ceil(pagination.totalSize / pagination.pageSize) > 0 && (
                  <Badge pill bg="light" text="dark" className="border me-2">{pagination.totalSize} Members Listed</Badge>
                )}
                <DropdownButton
                  id={`${componentName}-actionsBtn`}
                  variant="light"
                  className="d-inline-block border rounded me-2"
                  title="Bulk Actions"
                  disabled={selectedReferrals.size < 2}
                >
                  {actions.map(act => {
                    return <Dropdown.Item id={`${componentName}-actionsBtnItems`} key={act.value} onClick={() => handleAction(act.value)}>{act.text}</Dropdown.Item>
                  })}
                </DropdownButton>
                <Button id={`${componentName}-addMemberBtn`} variant="primary" className="me-2" onClick={handleShowMember}>+ Member</Button>
                <Button variant="primary" className="me-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTeamList" aria-controls="offcanvasTeamList">
                  <i className="fa fa-users me-2"></i> Team List
                </Button>
                <AssignmentTracker />
              </Col>
              <Col className="text-end">
                {showImportSpinner && (
                  <Button variant="secondary" className="align-top">
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm" />
                  </Button>) || (
                  <DropdownButton variant="light" className="d-inline-block me-2" title="Import" id={`${componentName}-Import`}>
                    <Card className="shadow-sm border-0">
                      <Upload
                        id={`${componentName}-uploadFile`}
                        fileName={fileName}
                        setFile={setFileMethod}
                        accept="text/csv"
                      ></Upload>
                      <button type="button" disabled={isEmpty(file)} onClick={handleFileUpload}><i className="fas fa-upload"></i> Upload</button>
                    </Card>
                  </DropdownButton>)}
                {showExportSpinner && (
                  <Button variant="secondary" className="align-top">
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm" />
                  </Button>) || (
                  <DropdownButton
                    variant="light"
                    className="d-inline-block"
                    title="Export"
                    id={`${componentName}-Export`}
                  >
                    <Dropdown.Item
                      id={`${componentName}-ExportCSV`}
                      onClick={() => handleExportList()}
                    >
                      <i className="fas fa-file-csv"></i> CSV
                    </Dropdown.Item>
                  </DropdownButton>
                )}
                {!isEmpty(members) && Math.ceil(pagination.totalSize / pagination.pageSize) > 0 && (
                    <CustomPagination
                      paginateButClicked={paginate}
                      MAX_PAGE={Math.ceil(pagination.totalSize / pagination.pageSize)}
                      pageNumber={Math.ceil(pagination.pageNumber)}
                    />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Modals
          show={modalMessage != null}
          onHide={() => setModalMessage(null)}
          title={modalTitle}
          size={modalSize || "md"}
          hideClickButton={true}
          showExtraButton={false}
          hideFooter={false}
          closeBtnId={`${componentName}-closeButton`}
          body={modalMessage}
        />
        <Modal centered show={showMember} onHide={handleCloseMember}>
          <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-addMemberModalTitle`}>Add Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isSearchingPatients && <EQHSpinner />}
            {!isSearchingPatients && <Row>
              <Col className="mb-3">
                <label id={`${componentName}-addMemberNameLabel`} className="d-block">Member Name or ID</label>
                <Form.Control id={`${componentName}-addMemberNameValue`} onChange={handlePatientSearchParamChange} type="text" />
              </Col>
            </Row>}
          </Modal.Body>
          <Modal.Footer>
            <Button id={`${componentName}-addMemberCancelBtn`} variant="secondary" onClick={handleCloseMember}>
              Cancel
            </Button>
            <Button id={`${componentName}-addMemberSearchBtn`} variant="primary" onClick={handlePatientSearch} disabled={buttonDisabled}>
              Search Members
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" centered show={showMemberList} onHide={handleCloseMemberList}>
          <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-memberSearchModalTitle`}>Member Search Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isAddingMember && <EQHSpinner />}
            {!isAddingMember && patientSearchResultRow}
          </Modal.Body>
          <Modal.Footer>
            <Button id={`${componentName}-memberSearchCancelBtn`} variant="secondary" onClick={handleCloseMemberList}>
              Cancel
            </Button>
            <Button id={`${componentName}-memberSearchAddBtn`} disabled={disabled} variant="primary" onClick={handleAddMember}>
              Add Member
            </Button>
          </Modal.Footer>
        </Modal>
        <PatientListOffCanvas
          teams={teams}
          setTeams={setTeams}
          onError={onError}
        />
        <Container fluid className="px-0">
          


          <Card className="shadow-sm border-0">
            <EQHErrorToast errors={errors} handleClose={() => setErrors([])} isList={true} />
            <Card.Body>
              <div className="table-responsive text-nowrap overflow-auto">
                <table className="table table-bordered table-hover">
                  <thead className="table-light fixedHead">
                    <tr>
                      <th id={componentName + "-check"} scope="col">
                        <input type="checkbox" name={`table-header-checkbox`} checked={allMemberCheckToggle} className="form-check-input" onChange={handleOnAllCheckboxChange} />
                      </th>
                      <th id={`${componentName}-actions`} scope="col">
                        Actions
                      </th>
                      <SortableTh sortColumn={sortColl} fieldName="fullName" columnName="Name" id={`${componentName}-name`} />
                      <SortableTh sortColumn={sortColl} fieldName="dateOfBirth" columnName="DOB" id={`${componentName}-dob`} />
                      <th id={`${componentName}-memberID`} scope="col">Member ID</th>
                      <SortableTh sortColumn={sortColl} fieldName="Program" columnName="Program" id={`${componentName}-program`} />
                      <th id={`${componentName}-payer`} scope="col">Payer</th>
                      <SortableTh sortColumn={sortColl} fieldName="zone" columnName="Zone" id={`${componentName}-zone`} />
                      <SortableTh sortColumn={sortZipCode} fieldName="zipCode" columnName="Zip Code" id={`${componentName}-zipcode`} />
                      <th id={`${componentName}-lineOfBusiness`} scope="col">Line of Business</th>
                      <SortableTh sortColumn={sortColl} fieldName="risk" columnName="Risk Level" id={`${componentName}-riskLevel`} />
                      <SortableTh sortColumn={sortColl} fieldName="source" columnName="Source" id={`${componentName}-source`} />
                      <SortableTh sortColumn={sortColl} fieldName="readmit" columnName="Readmit" id={`${componentName}-readmit`} scope="col">Readmit</SortableTh>
                      <SortableTh sortColumn={sortColl} fieldName="lastPCPVisit" columnName="PCP Visit" id={`${componentName}-pcpVisit`} />
                      <SortableTh sortColumn={sortColl} fieldName="latestAdmitDate" columnName="Admit Date" id={`${componentName}-admitDate`} />
                      <SortableTh sortColumn={sortColl} fieldName="latestDischargeDate" columnName="Discharge Date" id={`${componentName}-dischargeDate`} />
                      <SortableTh sortColumn={sortColl} fieldName="assignDate" columnName="Creation Date" id={`${componentName}-creationDate`} />
                    </tr>
                  </thead>
                  {!isLoadingMembers && isEmpty(members) &&
                    <tbody>
                      <tr>
                        <td colspan="100%">
                          No Patients Found
                        </td>
                      </tr>
                    </tbody>
                  }
                  {isLoadingMembers && <tbody>
                    <tr>
                      <td colSpan="100%">
                        <EQHSpinner />
                      </td>
                    </tr>
                  </tbody>}
                  {!isLoadingMembers && !isEmpty(members) &&
                    <tbody>
                      {members.map((m, idx) => {
                        return (
                          <>
                            <tr key={`${m.memberId}${idx}`} className={m.reAdmission && 'table-active'}>
                              <td scope="row">
                                <div className="d-flex align-items-center">
                                  <input id={componentName + "-memberCheck-" + idx} type="checkbox" className="form-check-input mt-0 me-3" name={`check-memberdetails-${idx}`} checked={m.isSelected} onChange={(e) => handleOnCheckboxChange(m.id, e)} />
                                  <Button id={componentName + "-additionalMemberInfo-" + idx} variant="link" className="lh-1 p-0"
                                    onClick={() => handleOpenTableDetail(`memberdetails${idx}`)}
                                    aria-controls={`memberdetails${idx}`}
                                    aria-expanded={isTableDetailOpen(`memberdetails${idx}`)}>
                                    <i className={`fal ${isTableDetailOpen(`memberdetails${idx}`) ? 'fa-minus-circle' : 'fa-plus-circle'} `} />
                                  </Button>
                                </div>
                              </td>
                              <td>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={(props) => (
                                  <Tooltip id="actionTooltip" {...props}>
                                    Actions
                                  </Tooltip>
                                )} >
                                  <Dropdown>
                                    <Dropdown.Toggle variant="light" text="dark" id={`${componentName}-memberAction-${idx}`} className="border py-1 px-2 border">
                                      <i className="fa fa-arrow-alt-from-left"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {actions.map(act => {
                                        return <Dropdown.Item id={`${componentName}-memberActionItem-${idx}`} key={act.value}
                                          onClick={() => handleAction(act.value, m)}>{act.text}</Dropdown.Item>
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <a id={`${componentName}-fullNameLink-${idx}`}
                                  data-bs-toggle={"offcanvas"}
                                  data-bs-target={"#patientDetailsModal"}
                                  aria-controls="patientDetailsModal"
                                  className="d-flex align-items-center fw-bold patientName"
                                  onClick={() => {
                                    dispatch(setManageVisitPatient(m));
                                    dispatch(setManageVisitOpenedFromPageTitle(componentName))
                                  }}
                                >
                                  <div id={`${componentName}-fullName-${idx}`} className="forced-capital">
                                    {m.fullName}
                                  </div>
                                </a>
                              </td>
                              <td>
                                <span id={`${componentName}-dateOfBirth-${idx}`}>
                                  {`${formatDate(m.dateOfBirth)}`} <span
                                    className="badge rounded-pill bg-light text-dark border align-top ms-1">{`${_calculateAge(m.dateOfBirth)}`}</span>
                                </span>
                                <span data-toggle="tooltip" data-placement="top" title={m.gender} className="badge rounded-pill bg-secondary border align-top ms-1">{m.gender?.charAt(0)}</span>
                              </td>
                              <td>
                                <span id={`${componentName}-memberId-${idx}`}>{m.memberId}</span>
                              </td>
                              <td className="align-middle text-center">
                                <span id={`${componentName}-memberId-${idx}`}>{m.hasOpenProgram ? 'Y' : ''}</span>
                              </td>
                              <td>
                                <span id={`${componentName}-payer1Payer-${idx}`}>{m.payer1Payer}</span>
                              </td>
                              <td>
                                <span id={`${componentName}-zone-${idx}`}>{m.zone ? m.zone : ''}</span>
                              </td>
                              <td>
                                <span id={`${componentName}-zipCode-${idx}`}>{m.zipCode ? m.zipCode : ''}</span>
                              </td>
                              <td>
                                <span id={`${componentName}-lineOfBusiness-${idx}`}>{m.lineOfBusiness}</span>
                              </td>
                              <td className="align-middle text-center">
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  popperConfig={{
                                    modifiers: {
                                      preventOverflow: { enabled: false },
                                    },
                                  }}
                                  overlay={<Tooltip id="tooltip">{m.riskLevel}</Tooltip>}
                                >
                                  <Badge pill bg={getRiskLevelStyle(m.riskLevel)}>{m.riskLevel}</Badge>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <span id={`${componentName}-source-${idx}`}>{m.source ? m.source : ''}</span>
                              </td>
                              <td className="align-middle text-center">
                                <span id={`${componentName}-readmission-${idx}`}>{m.reAdmission ? 'Y' : ''}</span>
                              </td>
                              <td>
                                <span
                                    id={`${componentName}-pcpVisit-${idx}`}>{formatDate(m.lastPCPVisit)}</span>
                              </td>
                              <td>
                                <span
                                  id={`${componentName}-latestAdmitTime-${idx}`}>{formatDate(m.latestAdmitTime)}</span>
                              </td>
                              <td>
                                <span
                                  id={`${componentName}-latestDischargeTime-${idx}`}>{formatDate(m.latestDischargeTime)}</span>
                              </td>
                              <td>
                                <span
                                  id={`${componentName}-creationDate-${idx}`}>{formatDateWithTime(m.assignDate)}</span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" colspan="12" className="p-0">
                                <Collapse in={isTableDetailOpen(`memberdetails${idx}`)}>
                                  <div id={`${componentName}-memberDetails-${idx}`}
                                    className="p-3 fw-normal text-wrap">
                                    {/* <div id={`${componentName}-memberAlert-${idx}`} className="alert alert-danger" role="alert">
                                        Patient is aggressive
                                      </div> */}
                                    <Row>
                                      <Col
                                        id={`${componentName}-memberFacility-${idx}`}
                                        className="mb-3">
                                        <label
                                          id={`${componentName}-memberFacilityLabel-${idx}`}
                                          className="d-block">Facility</label>
                                        <span
                                          id={`${componentName}-memberFacilityValue-${idx}`}>{m.latestDischarge?.facility}</span>
                                      </Col>
                                      <Col
                                        id={`${componentName}-memberAdmitType-${idx}`}
                                        className="mb-3">
                                        <label
                                          id={`${componentName}-memberAdmitTypeLabel-${idx}`}
                                          className="d-block">Admit Type</label>
                                        <span
                                          id={`${componentName}-memberAdmitTypeValue-${idx}`}>{m.disposition}</span>
                                      </Col>
                                      <Col
                                        id={`${componentName}-memberCarePath-${idx}`}
                                        className="mb-3">
                                        <label
                                          id={`${componentName}-memberCarePathLabel-${idx}`}
                                          className="d-block">
                                          Open Care Paths
                                        </label>
                                        {
                                          (!isEmpty(m.openCarePaths)) &&
                                          <Button
                                            id={`${componentName}-memberCarePathValue-${idx}`}
                                            variant="link"
                                            onClick={() => handleNavigateToCarePath(m.id)}
                                          >{m.openCarePaths}</Button>
                                        }
                                      </Col>
                                      <Col
                                        id={`${componentName}-memberPrograms-${idx}`}
                                        className="mb-3">
                                        <label
                                          id={`${componentName}-memberProgramsLabel-${idx}`}
                                          className="d-block">
                                          Programs
                                        </label>
                                        {
                                          (!isEmpty(m.openProgramList)) &&
                                          <p>{m.openProgramList.split(',').sort().join(', ')}</p>
                                        }
                                      </Col>
                                      <Col
                                          id={`${componentName}-dischargeDiagnosis-${idx}`}
                                          className="mb-3">
                                        <label
                                            id={`${componentName}-dischargeDiagnosisLabel-${idx}`}
                                            className="d-block">Discharge Diagnosis</label>
                                        <span
                                            id={`${componentName}-dischargeDiagnosisValue-${idx}`}>{m.dischargeDiagnosis}</span>
                                      </Col>
                                      <Col
                                          id={`${componentName}-chiefComplaint-${idx}`}
                                          className="mb-3">
                                        <label
                                            id={`${componentName}-chiefComplaintLabel-${idx}`}
                                            className="d-block">Chief Complaint</label>
                                        <span
                                            id={`${componentName}-chiefComplaintValue-${idx}`}>{m.chiefComplaint}</span>
                                      </Col>
                                      <Col
                                          id={`${componentName}-erEdDischargeCount-${idx}`}
                                          className="mb-3">
                                        <label
                                            id={`${componentName}-erEdDischargeCountLabel-${idx}`}
                                            className="d-block">ER/ED Discharges</label>
                                        <span
                                            id={`${componentName}-erEdDischargeCountValue-${idx}`}>{m.erEdDischargeCount}</span>
                                      </Col>
                                      <Col
                                          id={`${componentName}-ipDischargeCount-${idx}`}
                                          className="mb-3">
                                        <label
                                            id={`${componentName}-ipDischargeCountLabel-${idx}`}
                                            className="d-block">IP Discharges</label>
                                        <span
                                            id={`${componentName}-ipDischargeCountValue-${idx}`}>{m.ipDischargeCount}</span>
                                      </Col>
                                      <Col
                                          id={`${componentName}-bhIpDischargeCount-${idx}`}
                                          className="mb-3">
                                        <label
                                            id={`${componentName}-bhIpDischargeCountLabel-${idx}`}
                                            className="d-block">BH-IP Discharge</label>
                                        <span
                                            id={`${componentName}-bhIpDischargeCountValue-${idx}`}>{m.bhIpDischargeCount}</span>
                                      </Col>
                                      {/* <Col id={`${componentName}-memberSMI-${idx}`} className="mb-3">
                                          <label id={`${componentName}-memberSMILabel-${idx}`} className="d-block">SMI indicator</label>
                                          <span id={`${componentName}-memberSMIValue-${idx}`}></span>
                                        </Col>
                                        <Col id={`${componentName}-memberChiefComplaint-${idx}`} className="mb-3">
                                          <label id={`${componentName}-memberChiefComplaintLabel-${idx}`} className="d-block">Chief Complaint</label>
                                          <span id={`${componentName}-memberChiefComplaintValue-${idx}`}>FUH_UI_DX_CODE</span>
                                        </Col> */}
                                    </Row>
                                    {/* <Row>
                                        <Col id={`${componentName}-memberCTAssignment-${idx}`} className="mb-3">
                                          <label id={`${componentName}-memberCTAssignmentLabel-${idx}`} className="d-block">Previous CT Assignment</label>
                                          <span id={`${componentName}-memberCTAssignmentValue-${idx}`}></span>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col id={`${componentName}-memberUtilization-${idx}`} sm={4} className="mb-3">
                                          <label id={`${componentName}-memberUtilizationLabel-${idx}`} className="d-block">Utilization</label>
                                          <span id={`${componentName}-memberUtilizationValue-${idx}`}></span>
                                        </Col>
                                      </Row> */}
                                  </div>
                                </Collapse>
                              </th>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  }
                </table>
              </div>
              <Modal size="lg" centered show={showAssign} onHide={closeActivityModals}>
                <Modal.Header closeButton>
                  <Modal.Title id={`${componentName}-assignModalTitle`} className='forced-capital' >Enroll {selectedName === '' ? 'Member' : selectedName.toUpperCase()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col className="mb-3">
                      <label id={`${componentName}-assignTeamLabel`} className="d-block">Team</label>
                      <select id={`${componentName}-assignTeamValue`} name="team" value={assignment.teamId}
                        className="form-select"
                        required={true}
                        onChange={(e) => handleTeamChange(e)}>
                        <option id={`${componentName}-assignTeamItem`} value=""></option>
                        {teams.map((el, i) =>
                          <option id={`${componentName}-assignTeamItem-${i}`} value={el.teamId}>{el.teamName}</option>
                        )}
                      </select>
                    </Col>
                    <Col className="mb-3">
                      <label id={`${componentName}-assignProgramLeadLabel`} className="d-block">Program Lead</label>
                      <AsyncTypeahead
                        id={`${componentName}-assignProgramLeadValue`}
                        labelKey="text"
                        isLoading={isLoading}
                        onSearch={asyncsearch}
                        options={options}
                        onChange={(e) => handleMemberNameChange(e)}
                        placeholder="Search Members by Name"
                      />
                    </Col>
                    <Col className="mb-3">
                      <label id={`${componentName}-assignProgramLabel`} className="d-block">Program</label>
                      <select id={`${componentName}-assignProgramValue`} name="program" value={activity.program}
                        className="form-select"
                        required={true}
                        onChange={(e) => handleProgramChange(e)}>
                        <option id={`${componentName}-assignProgramItem`} value=""></option>
                        {programs.map((el, i) =>
                          <option id={`${componentName}-assignProgramItem-${i}`} value={el.value}>{el.text}</option>
                        )}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-12">
                      <Form.Check
                        type="checkbox"
                        id={`${componentName}-createCarePathCheckbox`}
                        onChange={(e) => setCreateCarePath(e.target.checked)}
                        checked={createCarePath}
                        label='Open Care Path'
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button id={`${componentName}-assignCancelBtn`} variant="secondary" onClick={closeActivityModals}>
                    Cancel
                  </Button>
                  <Button id={`${componentName}-assignAssignBtn`} variant="primary" onClick={handleAssignment}>
                    Assign
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal size="lg" centered show={showDecline} onHide={closeActivityModals}>
                <Modal.Header closeButton>
                  <Modal.Title id={`${componentName}-declineModalTitle`}>Decline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col className="mb-3">
                      <label id={`${componentName}-declineReasonLabel`} className="d-block">Reason</label>
                      <select id={`${componentName}-declineReasonValue`} name="reason" value={activity.reason}
                        className="form-select"
                        required={true}
                        onChange={(e) => handleDeclineChange(e)}>
                        <option id={`${componentName}-declineReasonItem`} value=""></option>
                        {declineReasons.map((el, i) =>
                          <option id={`${componentName}-declineReasonItem-${i}`} value={el.value}>{el.text}</option>
                        )}
                      </select>
                    </Col>
                    <Col className="mb-3">
                      <label id={`${componentName}-declineNotesLabel`} className="d-block">Notes</label>
                      <Form.Control id={`${componentName}-declineNotesValue`} type="text" onChange={handleDeclineNotesParamChange} />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button id={`${componentName}-declineCancelBtn`} variant="secondary" onClick={closeActivityModals}>
                    Cancel
                  </Button>
                  <Button id={`${componentName}-declineAssignBtn`} variant="primary" onClick={handleAssignment}>
                    Assign
                  </Button>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </QuickListForCareMgmt>
  );
};
export default MemberAssign;

const StyledSpanZone = styled.span`
   padding-left: 0.5em;
   font-size: 10px;
   font-style: italic;
`;