import React from "react";
import { Col, Row } from "react-bootstrap";

export default function HandlePatientSearch({
  handlePatientSelected,
  patientSelected,
  results,
  isEmpty
}) {

  const MemberStatus = ({ searched }) => {
    return (
      searched.membershipActive ?
        <span className="badge rounded-pill bg-success">Active</span> :
        <span className="badge rounded-pill bg-danger">Inactive</span>
    )
  }

  function getPatientUrl(searched) {
    return (
      "/patient-profile?" + new URLSearchParams({
        'patientId': searched.id,
        'returnTo': '/caremgmt/workcenter/?loadLastQuerySettings=true'
      })
    )
  }

  return (
    isEmpty
      ? <b id="PatientListView-no-results">No matching members were found. Please review your search criteria.</b>
      : results.map((searched, searchedIdx) => {
        return <><Row>
          <Col sm={1}>
            <input onChange={(event) => handlePatientSelected(searched, event)} type="checkbox" checked={patientSelected?.id === searched?.id} />
          </Col>
          <Col className="mb-3">
            <a href={getPatientUrl(searched)} className="fw-bold d-block" target="_blank">
              <div id={"PatientListView-patientFullName-" + searchedIdx} className="forced-capital">
                {searched.surnameCommaName}
              </div>
            </a>
          </Col>
          <Col className="mb-3">
            <label className="d-block">Member ID</label>
            <span>{searched.memberId} <MemberStatus searched={searched} /></span>
          </Col>
          <Col className="mb-3">
            <label className="d-block">Phone Number</label>
            <span>{searched.primaryPhone}</span>
          </Col>
          <Col className="mb-3">
            <label className="d-block">Date of Birth</label>
            <span>{searched.dateOfBirth}</span>
          </Col>
          <Col className="mb-3">
            <label className="d-block">Payer</label>
            <span>{searched.payer}</span>
          </Col>
        </Row><hr className="mt-0" /></>
      })
  )
}
