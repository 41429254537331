import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, FormGroup, InputGroup, Row, Toast } from 'react-bootstrap';
import { EQHDatePicker } from '../../datePicker';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useManageVisitContext } from '../../../views/quickList/components/context/ManageVisitContext/ManageVisit.context';
import { updateAction } from '../api';
import { setManageVisitOpenedFromPageTitle } from '../../../actions/manageVisitActions';
import { DropDownButton } from '../../listFilters/sharedDDL';
import { SimpleDDL } from '../../dropDownLists/simpleDDL';
import { FileUpload } from '../../FileUpload';

const componentName = 'GapsFields'

function GapsFields({ item, setErrors }) {
  const manageVisitState = useManageVisitContext();
  const statusOptions = manageVisitState?.patient_get?.caregapStatusesDropDownMap || {};

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const [biometricsConditions, setBiometricsConditions] = useState({
    a1c: false,
    a1c2: false,
    bloodPressure: false,
    bloodPressure2: false
  });

  const [updateState, setupdateState] = useState({
    forPreviousYear: false,
    icd10DiseaseDto: null,
    ids: [item?.id],
    validation: null,
    visitDate: manageVisitState?.patient_get?.visitDate
  })

  const [basisState, setbasisState] = useState({
    a1cDecimal: null,
    a1cWholes: null,
    bloodPressureDiastolic: null,
    bloodPressureSystolic: null,
    careGapAdditionalInfos: {},
    note: '',
    updateCareGapActionDto: null,
    updateTOCCareGapActionDto: null,
    updateValidationCareGapActionDto: null,
    updateValidationConditionActionDto: null,
    uploadedFiles: [],
    visit: [],
    patientId: manageVisitState?.patient_get?.patientId,
    patientLastUpdated: manageVisitState?.patient_get?.patientLastUpdated,
    visitDate: manageVisitState?.patient_get?.visitDate,
  })

  useEffect(() => {
    setBiometricsConditions({
      a1c: basisState?.a1cDecimal > 20.9,
      a1c2: basisState?.a1cWholes > 20.9,
      bloodPressure: basisState?.bloodPressureDiastolic < 0 || basisState?.bloodPressureDiastolic > 400,
      bloodPressure2: basisState?.bloodPressureSystolic < 0 || basisState?.bloodPressureSystolic > 400
    })

    setbasisState({
      ...basisState,
      updateCareGapActionDto: { ...updateState },
      uploadedFiles: [...files]
    })
  }, [updateState, files])

  function checkDisabled() {
    if (!updateState?.newStatus) return true;
    if (item?.requireDOS && !updateState?.newDateOfService) return true;
    if (item?.requireFileUpload && basisState?.uploadedFiles.length === 0) return true;
    if (item?.abbreviation !== "CDCE" && updateState?.newStatus?.source === 'MARKED_FOR_CLOSURE' && item?.additionalInfoOptions?.length > 0 && Object.keys(basisState?.careGapAdditionalInfos || {}) === 0) return true;
    return false
  }

  const handleUpdate = () => {
    setErrors([])
    updateAction(basisState)
      .then(() => {
        dispatch(setManageVisitOpenedFromPageTitle('Missed Opportunities'));
        manageVisitState.findPatientPost(manageVisitState?.patient_get?.patientId);
        manageVisitState.findPatientGet(manageVisitState?.patient_get?.patientId)
      }).catch(e => setErrors([e?.response?.data?.message]))
  }

  return (
    <><Row>
      <Col sm={12}>
        <Row>
          <Col sm={6}>
            <Form.Group id={`${componentName}-GapsStatus`}>
              <Form.Label
                id={`${componentName}-GapsStatusLabel`}
                className="d-block"
              >
                Status<span className="text-danger">*</span>
              </Form.Label>
              <Dropdown value={''}>
                <Dropdown.Toggle
                  as={DropDownButton}
                  cssOpen={false}
                  id={`${componentName}-GapsStatusValue-${item.id}`}
                  cusWidth="100% !important"
                >
                  <span>
                    {updateState?.newStatus?.value
                      ? updateState?.newStatus?.value
                      : '--Select--'
                    }
                  </span>
                  <i className="fa fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu className='w-100 dropdown-menu-aligned'>
                  {Object.keys(statusOptions).map(
                    (status) =>
                      <Dropdown.Item
                        key={statusOptions?.[status]}
                        onClick={() => setupdateState({ ...updateState, newStatus: { source: statusOptions?.[status], value: status } })}
                      >
                        {status}
                      </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Label
              id={`${componentName}-GapsDOSLabel`}
              className="d-block"
            >
              Date of Service
              <span className="text-danger">*</span>
            </Form.Label>
            <EQHDatePicker
              id={`${componentName}-GapsDOSValue`}
              onChange={e => setupdateState({ ...updateState, newDateOfService: e.strDate })}
              min={null}
              max="9999-12-31" />
          </Col>
          {item?.requireA1C && updateState?.newStatus?.source === 'MARKED_FOR_CLOSURE' &&
            <Col sm={6}>
              <Form className="border-0">
                <Form.Label id={`${componentName}-AddDetailsA1CValueText`} className="d-block">
                  A1C Value
                </Form.Label>
                <Row className="g-0">
                  <Col sm={5}>
                    <Form.Group>
                      <Form.Control
                        id={`${componentName}-AddDetailsA1CValue1Value`}
                        type="number"
                        name="a1cWholes"
                        className="border"
                        value={basisState?.a1cWholes}
                        onChange={e => setbasisState({ ...basisState, a1cWholes: e.target.value })}
                        disabled={false}
                        isInvalid={biometricsConditions?.a1c2}
                      />
                      <Form.Control.Feedback id={`${componentName}-pressureValidation`} type="invalid">
                        A1C Values must be integers and cannot exceed 20.9
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={1} className="pt-3 text-center fw-bold">
                    .
                  </Col>
                  <Col sm={5}>
                    <Form.Group>
                      <Form.Control
                        id={`${componentName}-AddDetailsA1CValue2Value`}
                        type="number"
                        name="a1cValue2"
                        className="border"
                        value={basisState?.a1cDecimal}
                        onChange={e => setbasisState({ ...basisState, a1cDecimal: e.target.value })}
                        disabled={false}
                        isInvalid={biometricsConditions?.a1c}
                      />
                      <Form.Control.Feedback id={`${componentName}-pressureValidation2`} type="invalid">
                        A1C Values must be integers and cannot exceed 20.9
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          }
          {item?.requireBloodPressure && updateState?.newStatus?.source === 'MARKED_FOR_CLOSURE' &&
            <Col sm={6}>
              <Form className="border-0">
                <Form.Label id={`${componentName}-AddDetailsBloodPressValueText`} className="d-block">
                  Blood Pressure Value
                </Form.Label>
                <Row className="g-0">
                  <Col sm={5}>
                    <Form.Group>
                      <Form.Control
                        id={`${componentName}-AddDetailsBloodPress1Value`}
                        type="number"
                        name="bloodPressureSystolic"
                        className="border"
                        value={basisState?.bloodPressureSystolic}
                        onChange={e => setbasisState({ ...basisState, bloodPressureSystolic: e.target.value })}
                        disabled={false}
                        isInvalid={biometricsConditions?.bloodPressure2}
                      />
                      <Form.Control.Feedback id={`${componentName}-blood1Validation`} type="invalid">
                        Must be an integer between 0 and 400
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={1} className="h1 mb-0 text-center fw-bold">
                    /
                  </Col>
                  <Col sm={5}>
                    <Form.Group>
                      <Form.Control
                        id={`${componentName}-AddDetailsBloodPress2Value`}
                        type="number"
                        name="bloodPreasure2"
                        className="border"
                        value={basisState?.bloodPressureDiastolic}
                        onChange={e => setbasisState({ ...basisState, bloodPressureDiastolic: e.target.value })}
                        disabled={false}
                        isInvalid={biometricsConditions?.bloodPressure}
                      />
                      <Form.Control.Feedback id={`${componentName}-blood2Validation`} type="invalid">
                        Must be an integer between 0 and 400
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          }
        </Row>
        {item?.additionalInfoOptions?.length > 0 && updateState?.newStatus?.source === 'MARKED_FOR_CLOSURE' &&
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label
                  id={`${componentName}-AddDetailsProvAttestationText-${item?.id}`}
                  className="d-block"
                >
                  {item.additionalInfoCaption}
                  {
                    item.abbreviation !== "CDCE" && (<span className="text-danger">*</span>)
                  }
                </Form.Label>
                <SimpleDDL
                  id={`${componentName}-AddDetailsProvAttestationValue-${item?.id}`}
                  list={item.additionalInfoOptions}
                  textSelector={(x) => x.description}
                  valueSelector={(x) => x}
                  getKeyFromListItem={(item) => item?.id}
                  onChange={e => {
                    e === null
                      ? setbasisState({})
                      : setbasisState({
                        ...basisState,
                        careGapAdditionalInfos: {
                          [item.id]: [{ ...e, selected: true }]
                        }
                      })
                  }
                  }
                  selectedKey={basisState?.careGapAdditionalInfos?.[item?.id]?.[0]?.id || null}
                />
              </Form.Group>
            </Col>
          </Row>
        }
      </Col>
    </Row>
      <Row>
        <Col>
          <Form.Group id={`${componentName}-GapsDOS`}>
            <Form.Label
              id={`${componentName}-GapsDOSLabel`}
              className="d-block"
            >
              Evidence
              {item?.requireFileUpload &&
                <span className="text-danger">*</span>
              }
            </Form.Label>
            <FileUpload multiple={false} onChange={e => setFiles([...e])} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='text-end'>
          <Button
            id={`${componentName}-gapsSubmit`}
            onClick={() => handleUpdate()}
            disabled={checkDisabled()}
          >Submit</Button>
        </Col>
      </Row></>
  )
}

export default GapsFields