import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Timeline } from "../../../components/Timeline/Timeline";
import {
  formatDateWithMonthInTextAndHourMinutes,
} from "../../../utils/util";
import { useManageVisitContext } from "./context/ManageVisitContext/ManageVisit.context";
import EQHErrorToast from "../../../components/EQHErrorToast";

const componentName = "missedOpportunities";

export const MissedOpportunities = ({ listType }) => {
  const manageVisitState = useManageVisitContext();
  const missedOpportunities = manageVisitState?.patient_get?.missedOpportunities || [];
  const [errors, setErrors] = useState([]);
  const [key, setKey] = useState('');

  useEffect(() => {
    const conditions = {
        'awaitingchart': listType === 'awaitingChart',
        'gaps': missedOpportunities?.filter(activity => activity.type === 'CARE_GAP').length > 0,
    };
    const key = Object.keys(conditions).find(key => conditions[key]) || 'suspects';
    setKey(key);
  }, [missedOpportunities, listType]);

  return (
    <>
      <Row>
        <EQHErrorToast
          errors={errors}
          handleClose={() => setErrors([])}
        />
        <Col>
          <Tabs id={`${componentName}-gapsSuspects`} activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 patientdrawer-tab-buttongroup">
            <Tab eventKey="gaps" title="Care Opportunity">
              <Timeline
                items={missedOpportunities?.filter(activity => activity.type === 'CARE_GAP')}
                titleBuilder={gap => formatDateWithMonthInTextAndHourMinutes(gap.date)}
                subTitleBuilder={gap => gap.displayName}
                type='GAPS'
                setErrors={setErrors}
              />
            </Tab>
            <Tab eventKey="suspects" title="Suspect Condition">
              <Timeline
                items={missedOpportunities?.filter(activity => activity.type === 'SUSPECT')}
                titleBuilder={suspect => formatDateWithMonthInTextAndHourMinutes(suspect.date)}
                subTitleBuilder={suspect => suspect.displayName + " " + suspect.measureYear}
                type='SUSPECTS'
                setErrors={setErrors}
              />
            </Tab>
            <Tab eventKey="awaitingchart" title="Awaiting Chart">
              <Timeline
                items={missedOpportunities?.filter(activity => activity.type === 'AWAITING_CHART')}
                titleBuilder={awaitingchart => formatDateWithMonthInTextAndHourMinutes(awaitingchart.date)}
                subTitleBuilder={awaitingchart => awaitingchart.displayName}
                type='AWAITING_CHART'
                setErrors={setErrors}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default MissedOpportunities;