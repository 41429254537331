import { GET_BRAND_ORG, RESET_UPDATE, GET_BRAND_ORG_LOGO, UPDATE_BRAND_ORG } from "../../actions/types";

const initialState = {
    brandOrgs: [],
    orgLogo: {},
    fileUploadId: {},
    updateSuccess: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BRAND_ORG:
      return {
        ...state,
        brandOrgs: payload,
      };
      case GET_BRAND_ORG_LOGO:
        return {
          ...state,
          orgLogo: payload
        };
        case UPDATE_BRAND_ORG:
          return {
            ...state,
            fileUploadId: payload,
            updateSuccess: true
          };
    default:
      return state;
  }
}
