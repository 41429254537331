import React from "react";
import EHNLogin from "./EHNLogin";
import DefaultLogin from "./DefaultLogin";
import CignaLogin from "./CignaLogin";
import TermsAndAgreements from "./TermsAndAgreements";
import { useLoginInfoContext } from "./context/LoginInfoContext";


// Uses startWith to match the first matching String to render a login component.
// If no match, renders DefaultLogin.
const LOGIN_PAGES = [
  ["127.0.0.1", EHNLogin], // for testing locally to get a different login screen
  // Equality Health (QA, UAT, prod subdomains)
  ["vega-qa-qpoint.", EHNLogin],
  ["vega-qa-equality.", EHNLogin],
  ["vega-uat-equality.", EHNLogin],
  ["equality.", EHNLogin],
  // Cigna (QA, UAT and prod subdomains)
  ["vega-qa-cigna.", CignaLogin],
  ["vega-uat-cigna.", CignaLogin],
  ["cigna.", CignaLogin],
];

const LoginView = () => {
  const loginInfo = useLoginInfoContext();

  const hostname = window.location.hostname;
  const maybe = LOGIN_PAGES.find((e) => hostname.startsWith(e[0]));
  if (maybe) {
    return React.createElement(
      maybe[1],
      {
        onLogin: loginInfo.onLogin(),
        error: loginInfo.error,
        sessionExpired: false,
      },
      null
    );
  } else {
    return (
      <>
        <DefaultLogin />
        {loginInfo.termsRequired && <TermsAndAgreements />}
      </>
    );
  }
};

export default LoginView;
