import React, { useEffect } from 'react'
import Modals from '../../../../components/modals'
import PreventiveCareAddModal from '../../PreventiveCareAddModal'
import { useManageVisitContext } from '../context/ManageVisitContext/ManageVisit.context';

function AddSuspectConditionsModal({ patient, setShow, refresh, type }) {
  const {showAddModal, modalType, findPatientGet, findPatientPost, toggleAddModal } = useManageVisitContext();

  useEffect(() => {
    setShow(showAddModal)
  }, [showAddModal])

  const findLatestGapAndSusPect = () => {
    if(patient?.id){
      findPatientPost(patient.id);
      findPatientGet(patient.id);
    }
  }

  return (
    <Modals
      scrollable={true}
      show={showAddModal}
      closeEvidenceModal={() => toggleAddModal(false)}
      onHide={() => toggleAddModal(false)}
      size="xl"
      title={`New ` + modalType}
      titleid={`ManageVisit-NewModal`}
      completeBtn="UPLOAD"
      hideFooter={true}
      body={
        <PreventiveCareAddModal
          closeModal={() =>  toggleAddModal(false)}
          patient={patient}
          type={modalType}
          submitCreation={() => {findLatestGapAndSusPect()}}
        />
      }
    />
  )
}

export default AddSuspectConditionsModal;