import React, {useEffect, useState } from "react";

import { Button } from "../../../components/buttons";
import { Card } from "../../../components/cards";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { RESET_SUCCESS } from "../../../actions/types";

const componentName="SearchUsers";
const CapitilizingInput = styled.input`
  text-transform: capitalize;
`;

export const SearchUsers = ({ submitSearchPrameters, roles, accessTypes }) => {
  const [searchParameters, setSearchParameters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    accessType: "",
    accessId: "",
    accessName: "",
    roleId: "",
  });
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchParameters({ ...searchParameters, [name]: value });
  };

  const handleSearchClick = (e) => {
    submitSearchPrameters(searchParameters);
  };
  const handleResetClick = (e) => {
    const searchParam = { firstName: "",
    lastName: "",
    email: "",
    accessType: "",
    accessId: "",
    accessName: "",
    roleId: ""}
    setSearchParameters({
      ...searchParameters,
      firstName: "",
      lastName: "",
      email: "",
      accessType: "",
      accessId: "",
      accessName: "",
      roleId: ""
    });
    submitSearchPrameters(searchParam)
  };

  roles.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  return (
    <Card className="mb-3">
      <Card.Body className="overflow-auto">
        <Row>
          <Col className="mb-3">
            <label id={`${componentName}-firstNameLabel`} className="d-block">First Name</label>
            <CapitilizingInput
              type="text"
              name="firstName"
              id={`${componentName}-firstNameValue`}
              value={searchParameters.firstName}
              className="form-control"
              maxLength="50"
              onChange={(e) => handleSearchChange(e)}
            />
          </Col>
          <Col className="mb-3">
            <label id={`${componentName}-lastNameLabel`} className="d-block">Last Name</label>
            <CapitilizingInput
              type="text"
              name="lastName"
              id={`${componentName}-lastNameValue`}
              value={searchParameters.lastName}
              className="form-control"
              maxLength="50"
              onChange={(e) => handleSearchChange(e)}
            />
          </Col>
          <Col className="mb-3">
            <label id={`${componentName}-emailLabel`} className="d-block">User ID or Email</label>
            <input
              type="email"
              name="email"
              id={`${componentName}-emailValue`}
              value={searchParameters.email}
              className="form-control"
              maxLength="100"
              onChange={(e) => handleSearchChange(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <label id={`${componentName}-accessTypeLabel`} className="d-block">Access Type</label>
            <Form.Control
              disabled={!accessTypes.length}
              as="select"
              name="accessType"
              id={`${componentName}-accessTypeValue`}
              value={searchParameters.accessType}
              className="form-select"
              onChange={(e) => handleSearchChange(e)}
            >
              <option value="">-Select-</option>
              {accessTypes.map((access) => (
                <option key={access} value={access}>
                  {access}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col className="mb-3">
            <label id={`${componentName}-identifierLabel`} className="d-block">Identifier</label>
            <CapitilizingInput
              type="text"
              name="accessId"
              id={`${componentName}-identifierValue`}
              value={searchParameters.accessId}
              className="form-control"
              onChange={(e) => handleSearchChange(e)}
            />
          </Col>
          <Col className="mb-3">
            <label id={`${componentName}-rolesLabel`} className="d-block">Role</label>
            <Form.Control
              disabled={!roles.length}
              as="select"
              name="roleId"
              id={`${componentName}-rolesValue`}
              value={searchParameters.roleId}
              className="form-select"
              onChange={(e) => handleSearchChange(e)}
            >
              <option value="">-Select-</option>
              {roles.map((role) => (
                <option key={role.value} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="text-end">
          <Col>
            <Button
              id={`${componentName}-resetButton`}
              variant="secondary"
              onClick={() => handleResetClick()}
              className="me-2"
            >
              Reset
            </Button>
            <Button
              id={`${componentName}-searchButton`}
              variant="primary"
              onClick={() => handleSearchClick()}
            >
              Search
            </Button>
          </Col>
        </Row>        
      </Card.Body>
    </Card>
  );
};

export default SearchUsers;
