import {GET_SYSTEM_FILE_TYPES, GET_SYSTEM_FILES, UPDATE_SYSTEM_FILE} from "../../actions/types";

const initialState = {
  fileTypes: [],
  updateSuccess: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
        case GET_SYSTEM_FILE_TYPES:
          return {
            ...state,
            fileTypes: payload,
          };
        case GET_SYSTEM_FILES:
          return {
            ...state,
            files: payload,
          };
        case UPDATE_SYSTEM_FILE:
          return {
            ...state,
            files: payload,
            updateSuccess: true
            };
    default:
      return state;
  }
}
