import axios from "axios";

const ZONE_STATUS_ACTIVE = "ACTIVE";
const URL_ZONES = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/configuration/zone";
const URL_ZIP_CODE = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/configuration/zip-code-dropdown";

export const getZones = async () => {
    return axios.get(URL_ZONES);
}

export const getZipCodes = async () => {
    return axios.get(URL_ZIP_CODE);
}

export const postZone = (data)=> {
    const param = {...data, status: ZONE_STATUS_ACTIVE};
    return axios
        .post(URL_ZONES, param, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const putZone = (data)=> {
    return axios
        .put(URL_ZONES, data, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}