export const neededServices = [
  { text: "Transportation", value: "TRANSPORTATION", isPrimary: false },
  { text: "Food Support", value: "FOOD_SUPPORT", isPrimary: false },
  { text: "Housing Support", value: "HOUSING_SUPPORT", isPrimary: false },
  {
    text: "Disease Education & Management",
    value: "DISEASE_EDUCATION_MANAGEMENT",
    isPrimary: false
  },
  { text: "Medication Education", value: "MEDICATION_EDUCATION", isPrimary: false },
  {
    text: "Behavioral Health Referral Assistance",
    value: "BEHAVIORAL_REFERRAL_ASSISTANCE",
    isPrimary: false
  },
  { text: "Spiritual Support", value: "SPIRITUAL_SUPPORT", isPrimary: false },
  { text: "Complex Care", value: "COMPLEX_CARE", isPrimary: false },
  { text: "Annual Comprehensive Visit", value: "ANNUAL_COMPREHENSIVE_VISIT", isPrimary: false },
  { text: "Other", value: "OTHER", isOtherSelectItem: true, isPrimary: false },
  { text: "External Referral to CBO", value: "EXTERNAL_REFERRAL_TO_CBO", isOtherSelectItem: true, isPrimary: false }
];

export const neededServicesEnums = [
  "TRANSPORTATION",
  "FOOD_SUPPORT",
  "HOUSING_SUPPORT",
  "DISEASE_EDUCATION_MANAGEMENT",
  "MEDICATION_EDUCATION",
  "BEHAVIORAL_REFERRAL_ASSISTANCE",
  "SPIRITUAL_SUPPORT",
  "COMPLEX_CARE",
  "ANNUAL_COMPREHENSIVE_VISIT",
  "OTHER",
];

export const declineReason = [
  {
    text: "Currently not accepting new referrals",
    value: "CURRENTLY_NOT_ACCEPTING_NEW_REFERRALS",
  },
  {
    text: "Service cannot be scheduled within required timelines",
    value: "SERVICE_CANNOT_BE_SCHEDULED_WITHIN_REQUIRED_TIMELINES",
  },
  {
    text: "Practice does not provide requested service(s)",
    value: "PRACTICE_DOES_NOT_PROVIDE_REQUESTED_SERVICE",
  },
  {
    text: "Unable to meet the complexity of need(s)",
    value: "UNABLE_TO_MEET_THE_COMPLEXITY_OF_NEED",
  },
  {
    text: "Patient declined treatment",
    value: "PATIENT_DECLINED_TREATMENT",
  },
  {
    text: "Requires behavioral health specialty care",
    value: "REQUIRES_BEHAVIORAL_HEALTH_SPECIALTY_CARE",
  },
];

export const durationList = [
  { text: "10 min", value: 10 },
  { text: "15 min", value: 15 },
  { text: "20 min", value: 20 },
  { text: "30 min", value: 30 },
  { text: "45 min", value: 45 },
  { text: "60 min", value: 60 },
  { text: "75 min", value: 75 },
  { text: "90 min", value: 90 },
];

export const notificationDelayList = [
  { text: 24, value: 24 },
  { text: 12, value: 12 },
  { text: 8, value: 8 },
  { text: 5, value: 5 },
  { text: 2, value: 2 },
];

export const dispositionList = [
  { text: "BH IP Discharge", value: "BH_IP_DISCHARGE" },
  { text: "ER Discharge", value: "ER_DISCHARGE" },
  { text: "IP Discharge", value: "IP_DISCHARGE" }
];

export const units = [
  { text: "Hours", value: "HOURS" },
  { text: "Minutes", value: "MINUTES" },
  { text: "Days", value: "DAYS" },
];

export const appointmentModalStatus = [
  {
    text: "Scheduled",
    value: "SCHEDULED",
  },
  {
    text: "Confirmed",
    value: "CONFIRMED",
  },
  {
    text: "Completed",
    value: "COMPLETED",
  },
  {
    text: "No Show",
    value: "NO_SHOW",
  },
];
export const carePathStepsList = [
  {
    text: "ADMIT",
    value: "ADMIT",
  },
  {
    text: "DISCHARGE",
    value: "DISCHARGE",
  },
  {
    text: "PCP APPOINTMENT",
    value: "PCP_APPOINTMENT",
  },
  {
    text: "SPECIALIST APPOINTMENT",
    value: "SPECIALIST_APPOINTMENT",
  },
  {
    text: "CARE PROGRAM START DATE",
    value: "CARE_PATH_START_DATE",
  },
  {
    text: "PREVIOUS STEP",
    value: "PREVIOUS_STEP",
  },
  {
    text: "NURSE APPOINTMENT",
    value: "NURSE_APPOINTMENT",
  },
];

export const stateFullName = [
  {
    text: "Arizona",
    value: "AZEH",
  },
  {
    text: "Texas",
    value: "TXEH",
  },
  {
    text: "Louisiana",
    value: "LOEH",
  },
  {
    text: "Tennessee",
    value: "TNEH",
  },
  {
    text: "Virginia",
    value: "VAEH",
  },

];

export const clientToStateMap =
{
  "AZEH":"Arizona",
  "TXEH":"Texas",
  "LOEH":"Louisiana",
  "TNEH":"Tennessee",
  "VAEH":"Virginia",
};


export const cancelledReason = [
  {
    text: "Duplicate referral",
    value: "DUPLICATE_REFERRAL",
  },
  {
    text: "No Response from provider",
    value: "NO_RESPONSE_FROM_PROVIDER",
  },
  {
    text: "Referral created in error",
    value: "REFERRAL_CREATED_IN_ERROR",
  },
  {
    text: "Referral no longer needed",
    value: "REFERRAL_NO_LONGER_NEEDED",
  },
];

export const rafStatuses = [
  {
    text: "Open",
    value: "OPEN",
  },
  {
    text: "Pending Diagnostic Workup",
    value: "PENDING_DIAGNOSTIC_WORKUP",
  },
  {
    text: "Confirmed Diagnosis",
    value: "CONFIRMED_DIAGNOSIS",
  },
  {
    text: "Does NOT Have Diagnosis",
    value: "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS",
  },
  {
    text: "Closed",
    value: "CLOSED",
  },
  {
    text: "Unable To Complete",
    value: "UNABLE_TO_COMPLETE",
  },
  {
    text: "Duplicate Condition",
    value: "DUPLICATE",
  },
];

export const rafValidation = [
  {
    text: "In Review",
    value: "IN_REVIEW",
  },
  {
    text: "Approved",
    value: "APPROVED",
  },
  {
    text: "Rejected",
    value: "REJECTED",
  },
  {
    text: "Does NOT Have Diagnosis",
    value: "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS",
  },
  {
    text: "Modified and confirmed",
    value: "MODIFIED_AND_CONFIRMED",
  },
];

export const exclusionStatus = {
  closesGap: true,
  name: "Excluded",
  source: "EXCLUDED",
};

export const visit_type_referrals = [
  {
    text: "Consult, Evaluate, and Treat",
    value: "CONSULT_EVALUATE_TREAT",
  },
  {
    text: "Consultation",
    value: "CONSULTATION",
  },
  {
    text: "Evaluation and Treatment",
    value: "EVALUATION_AND_TREATMENT",
  },
  {
    text: "Second Opinion",
    value: "SECOND_OPINION",
  },
  {
    text: "Established Patient Follow Up",
    value: "PATIENT_FOLLOW_UP",
  },
];

export const referral_types = [
  {
    text: "Specialist",
    value: "SPECIALIST",
  },
  {
    text: "Care Coordination",
    value: "CARE_COORDINATION",
  },
  {
    text: "Community Based Organization",
    value: "COMMUNITY_BASED_ORGANIZATION",
  },
];

export const referral_referredTo = [
  {
    text: "Equality Health",
    value: "EQUALITY HEALTH",
  },
];

export const urgency_referrals = [
  {
    text: "Routine",
    value: "10_ROUTINE",
  },
  {
    text: "Priority",
    value: "20_PRIORITY",
  },
  {
    text: "Urgent",
    value: "30_URGENT",
  },
  {
    text: "Emergent",
    value: "40_EMERGENT",
  },
  {
    text: "CBO Referral",
    value: "CBO_REFERRAL",
  },
];

export const status_referrals = [
  {
    text: "Accepted",
    value: "40_ACCEPTED",
  },
  {
    text: "Declined",
    value: "60_DECLINED",
  },
  {
    text: "Completed",
    value: "30_COMPLETED",
    isCompleted: true,
  },
  {
    text: "Patient Cancelled",
    value: "20_PATIENT_CANCELLED",
  },
  {
    text: "Pending",
    value: "50_PENDING",
    isPending: true,
  },
  {
    text: "Cancelled",
    value: "10_CANCELLED",
  },
  {
    text: "Referred to CBO",
    value: "CBO_REFERRAL",
  },
];

export const appointmentStatus = [
  {
    text: "Scheduled appointment",
    value: "SCHEDULED_APPOINTMENT",
  },
  {
    text: "Rescheduled appointment",
    value: "RESCHEDULED_APPOINTMENT",
  },
  {
    text: "Unable to reach patient",
    value: "UNABLE_TO_REACH_PATIENT",
  },
];

export const opportunityValidations = [
  {
    name: "In Review",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.in_review",
  },
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.approved",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.rejected",
  },
];

export const conditionValidations = [
  {
    name: "In Review",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.in_review",
  },
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.approved",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.rejected",
  },
  {
    name: "Modified and Confirmed",
    value: "MODIFIED_AND_CONFIRMED",
    class:
      "com.hbi.ce.backend.common.enums.conditionvalidationenum.modifiedandconfirmed",
  },
];

export const conditionValidationTitles = [
  {
    name: "Date of service",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.in_review",
  },
  {
    name: "Approved on",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.approved",
  },
  {
    name: "Rejected on",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.rejected",
  },
  {
    name: "Date of service",
    value: "MODIFIED_AND_CONFIRMED",
    class:
        "com.hbi.ce.backend.common.enums.conditionvalidationenum.modifiedandconfirmed",
  },
];

export const wc15Validations = [
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.approved",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.rejected",
  },
];

export const eyeExamTypes = [
  {
    name: "Positive",
    value: "true"
  },
  {
    name: "Negative",
    value: "false"
  },
];
export const codeTypes = [
  {
    value: "CPT",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationcodetypeenum.cpt",
  },
  {
    value: "CPTII",
    class:
      "com.hbi.ce.backend.common.enums.caregapvalidationcodetypeenum.cptii",
  },
  {
    value: "LOINC",
    class:
      "com.hbi.ce.backend.common.enums.caregapvalidationcodetypeenum.loinc",
  },
  {
    value: "ICD_10",
    class:
      "com.hbi.ce.backend.common.enums.caregapvalidationcodetypeenum.icd_10",
  },
  {
    value: "HCPCS",
    class:
      "com.hbi.ce.backend.common.enums.caregapvalidationcodetypeenum.hcpcs",
  },
];

export const opportunityValidationOptions = [
  {
    name: "In Review",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.in_review",
  },
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.approved",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.rejected",
  },
];

export const gapValidationTitles = [
  {
    name: "Validation",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.in_review",
  },
  {
    name: "Approved on",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.approved",
  },
  {
    name: "Rejected on",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.rejected",
  },
];

export const opportunityDualValidationOptions = [
  {
    name: "Awaiting Chart",
    value: "AWAITING_CHART",
    class:
      "com.hbi.ce.backend.common.enums.caregapdualvalidationenum.awaiting_chart",
  },
  {
    name: "Awaiting Claim",
    value: "AWAITING_CLAIM",
    class:
      "com.hbi.ce.backend.common.enums.caregapdualvalidationenum.awaiting_claim",
  },
  {
    name: "Dual Approved",
    value: "DUAL_APPROVED",
    class:
      "com.hbi.ce.backend.common.enums.caregapdualvalidationenum.dual_approved",
  },
];

export const gapValidationOptions = [
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.approved",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.rejected",
  },
];

export const opportunityReviewOption = [
  {
    name: "In Review",
    value: "IN_REVIEW",
    class: "com.hbi.ce.backend.common.enums.caregapvalidationenum.in_review",
  },
];

export const suspectValidationOptions = [
  {
    name: "Approved",
    value: "APPROVED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.approved",
  },
  {
    name: "Modified and Confirmed",
    value: "MODIFIED_AND_CONFIRMED",
    class:
      "com.hbi.ce.backend.common.enums.conditionvalidationenum.modifiedandconfirmed",
  },
  {
    name: "Rejected",
    value: "REJECTED",
    class: "com.hbi.ce.backend.common.enums.conditionvalidationenum.rejected",
  },
];

export const opportunityRejectionReasons = [
  {
    text: "Illegible",
    value: "ILLEGIBLE",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.illegible",
  },
  {
    text: "Wrong Member",
    value: "WRONG_MEMBER",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.wrong_member",
  },
  {
    text: "Blank Form",
    value: "BLANK_FORM",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.blank_form",
  },
  {
    text: "Incorrect or Wrong Form",
    value: "INCORRECT_OR_WRONG_FORM",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.incorrect_or_wrong_form",
  },
  {
    text: "Incomplete Member Identifiers",
    value: "INCOMPLETE_MEMBER_IDENTIFIERS",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.incomplete_member_identifiers",
  },
  {
    text: "Incomplete Form",
    value: "INCOMPLETE_FORM",
    class:
      "com.hbi.ce.backend.common.enums.caregaprejectionreasonenum.incomplete_form",
  },
];

export const suspectRejectionReasons = [
  {
    text: "Blank Form/Bad File",
    value: "BLANK_FORM",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.blank_form",
  },
  {
    text: "Clinical Inquiry:  See Note",
    value: "CLINICAL_ENQUIRY",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.clinical_enquiry",
  },
  {
    text: "Documentation does not support condition",
    value: "DOCUMENTATION_DOES_NOT_SUPPORT_CONDITION",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.documentation_does_not_support",
  },
  {
    text: "Documentation provided supports condition",
    value: "DOCUMENTATION_SUPPORTS_CONDITION",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.documentation_supports",
  },
  {
    text: "Wrong Member",
    value: "WRONG_MEMBER",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.wrong_member",
  },
  {
    text: "Cavo Reject",
    value: "CAVO_REJECT",
    class:
      "com.hbi.ce.backend.common.enums.conditionrejectionreasonenum.cavo_reject",
  },
];

export const suspectHistoryStatuses = [
  {
    text: "Open",
    value: "OPEN",
  },
  {
    text: "Pending Diagnostic Workup",
    value: "PENDING_DIAGNOSTIC_WORKUP",
  },
  {
    text: "Confirmed Diagnosis",
    value: "CONFIRMED_DIAGNOSIS",
  },
  {
    text: "Does NOT Have Diagnosis",
    value: "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS",
  },
  {
    text: "Closed",
    value: "CLOSED",
  },
  {
    text: "Unable To Complete",
    value: "UNABLE_TO_COMPLETE",
  },
  {
    text: "Duplicate Condition",
    value: "DUPLICATE",
  },
];

export const suspectDateOfServiceStatuses = [
  {
    text: "Date of service",
    value: "OPEN",
  },
  {
    text: "Pending as of",
    value: "PENDING_DIAGNOSTIC_WORKUP",
  },
  {
    text: "Confirmed on",
    value: "CONFIRMED_DIAGNOSIS",
  },
  {
    text: "Does Not Have as of",
    value: "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS",
  },
  {
    text: "Date of service",
    value: "CLOSED",
  },
  {
    text: "Date of service",
    value: "UNABLE_TO_COMPLETE",
  },
  {
    text: "Date of service",
    value: "DUPLICATE",
  },
];

export const worklistStatusMessage = [
  {
    text: "Unable to Contact on",
    value: "UNABLE_TO_CONTACT",
  },
  {
    text: "Left Message on",
    value: "LEFT_MESSAGE",
  },
  {
    text: "Changed PCP on",
    value: "CHANGED_PCPS",
  },
  {
    text: "Patient Refused on",
    value: "PATIENT_REFUSAL",
  },
];

export const patientProfileField = [
  {
    text: "Referring Practice",
    value: "referredPractice",
  },
  {
    text: "Referred To Provider/Practice",
    value: "referredTo",
  },
  {
    text: "Visit Type",
    value: "visitType",
  },
  {
    text: "Referral Type",
    value: "referralType",
  },
  {
    text: "Services Needed",
    value: "servicesNeeded",
  },
  {
    text: "Previous Specialist 1",
    value: "previous specialist 1",
  },
  {
    text: "Previous Specialist 1",
    value: "previous specialist 1",
  },
  {
    text: "New Specialist 1",
    value: "new specialist 1",
  },
  {
    text: "Previous Specialist 1",
    value: "previous specialist 1",
  },
  {
    text: "File",
    value: "file",
  },
  {
    text: "Attachments",
    value: "files",
  },
  {
    text: "End",
    value: "end",
  },
  {
    text: "Start",
    value: "start",
  },
  {
    text: "Previous Health Plan Care Manager",
    value: "previous health plan care manager",
  },
  {
    text: "New Health Plan Care Manager",
    value: "new health plan care manager",
  },

  {
    text: "New Nurse",
    value: "new nurse",
  },
  {
    text: "Previous Nurse",
    value: "previous nurse",
  },
  {
    text: "Allowable Days",
    value: "allowabledays",
  },
  {
    text: "Provider",
    value: "provider",
  },
  {
    text: "Assessment Type",
    value: "assessmenttype",
  },
  {
    text: "Unit Room",
    value: "unitroom",
  },
  {
    text: "Language",
    value: "language",
  },
  {
    text: "Patient Name",
    value: "patientname",
  },
  {
    text: "Admit Date",
    value: "admitdate",
  },
  {
    text: "New Secondary Address City",
    value: "secondary_address.city.new",
  },
  {
    text: "Previous Treatment Date ",
    value: "previoustreatmentdate",
  },
  {
    text: "Previous Date Of Birth",
    value: "previousdateofbirth",
  },
  {
    text: "New Care Coordinator",
    value: "newcarecoordinator",
  },
  {
    text: "New Care Coordinator",
    value: "new care coordinator",
  },
  {
    text: "Previous Secondary Address State",
    value: "secondary_address.state.previous",
  },
  {
    text: "Inactive",
    value: "status_false",
  },
  {
    text: "New Nurse",
    value: "newnurse",
  },
  {
    text: "Previous Name",
    value: "previousname",
  },
  {
    text: "Previous Nurse",
    value: "previousnurse",
  },
  {
    text: "Previous Health Plan Care Manager",
    value: "previoushealthplancaremanager",
  },
  {
    text: "Type",
    value: "type",
  },
  {
    text: "Previous Primary Care Provider",
    value: "previousprimarycareprovider",
  },
  {
    text: "Active",
    value: "status_true",
  },
  {
    text: "Drug Alerts",
    value: "drugalerts",
  },
  {
    text: "Discharge Diagnosis",
    value: "dischargediagnosis",
  },
  {
    text: "New Primary Care Provider",
    value: "newprimarycareprovider",
  },
  {
    text: "Discharge To",
    value: "dischargeto",
  },
  {
    text: "Bed",
    value: "bed",
  },
  {
    text: "Previous Secondary Address Source",
    value: "secondary_address.source.previous",
  },
  {
    text: "New Date Of Birth",
    value: "newdateofbirth",
  },
  {
    text: "Total Days Supply",
    value: "totaldayssupply",
  },
  {
    text: "Drug Product No.",
    value: "drugproductno",
  },
  {
    text: "Previous Secondary Address Street Address",
    value: "secondary_address.street_address.previous",
  },
  {
    text: "Admit Diagnosis",
    value: "admitdiagnosis",
  },
  {
    text: "No",
    value: "primary_false",
  },
  {
    text: "Drug Forms",
    value: "drugforms",
  },
  {
    text: "Refills",
    value: "refills",
  },
  {
    text: "Date of Service",
    value: "dateofservice",
  },
  {
    text: "New Secondary Address State",
    value: "secondary_address.state.new",
  },
  {
    text: "Discharge Date",
    value: "dischargedate",
  },
  {
    text: "Yes",
    value: "primary_true",
  },
  {
    text: "Previous Care Coordinator",
    value: "previouscarecoordinator",
  },
  {
    text: "Previous Care Coordinator",
    value: "previous care coordinator",
  },
  {
    text: "Current PDC",
    value: "currentpdc",
  },
  {
    text: "Start Date",
    value: "startdate",
  },
  {
    text: "Close Reason Comment",
    value: "closereasoncomment",
  },
  {
    text: "Days Missed",
    value: "daysmissed",
  },
  {
    text: "Type",
    value: "reportType",
  },
  {
    text: "Fill Date",
    value: "filldate",
  },
  {
    text: "Drug Appl. No.",
    value: "drugapplno",
  },
  {
    text: "New Health Plan Care Manager",
    value: "newhealthplancaremanager",
  },
  {
    text: "Previous Secondary Address City",
    value: "secondary_address.city.previous",
  },
  {
    text: "Primary",
    value: "primary",
  },
  {
    text: "Quantity",
    value: "quantity",
  },
  {
    text: "Refill Status",
    value: "refillstatus",
  },
  {
    text: "Attending Physician",
    value: "attendingphysician",
  },
  {
    text: "Pharmacy",
    value: "pharmacy",
  },
  {
    text: "New Name",
    value: "newname",
  },
  {
    text: "Disposition",
    value: "disposition",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Chief Complaint",
    value: "chiefcomplaint",
  },
  {
    text: "Completed",
    value: "completed",
  },
  {
    text: "New Secondary Address Zip Code",
    value: "secondary_address.zip_code.new",
  },
  {
    text: "Adherence Category ",
    value: "adherencecategory",
  },
  {
    text: "Prescriber",
    value: "prescriber",
  },
  {
    text: "Facility",
    value: "facility",
  },
  {
    text: "Description",
    value: "description",
  },
  {
    text: "New Specialist {0}",
    value: "newspecialist",
  },
  {
    text: "Previous Specialist {0}",
    value: "previousspecialist",
  },
  {
    text: "New Secondary Address Street Address",
    value: "secondary_address.street_address.new",
  },
  {
    text: "Previous Secondary Address Zip Code",
    value: "secondary_address.zip_code.previous",
  },
  {
    text: "Previous Secondary Address Update",
    value: "secondary_address.last_updated.previous",
  },
  {
    text: "Close Reason",
    value: "closereason",
  },
  {
    text: "Patient Date Of Birth",
    value: "patientdateofbirth",
  },
  {
    text: "New Secondary Address Update",
    value: "secondary_address.last_updated.new",
  },
  {
    text: "End",
    value: "end",
  },
  {
    text: "Start",
    value: "start",
  },
  {
    text: "Notes",
    value: "notes",
  },
  {
    text: "Message",
    value: "message",
  },
  {
    text: "Message Type",
    value: "messageType",
  },
  {
    text: "Phone",
    value: "phone",
  },
  {
    text: "New Secondary Address Source",
    value: "secondary_address.source.new",
  },
  {
    text: "Previous Disposition",
    value: "previousdisposition",
  },
  {
    text: "Note",
    value: "note",
  },
  {
    text: "Provider",
    value: "ce.appointments.card.provider",
  },
  {
    text: "Length of Stay",
    value: "lengthofstay",
  },
  {
    text: "Care Program Step Name",
    value: "carepathstepname",
  },
  {
    text: "Status",
    value: "carepathstatus",
  },
  {
    text: "Care Program Step Coordinator",
    value: "carepathcordinator",
  },
];

export const report_Url_Map = {
  "Activity Report: Logins": "login-activity-report",

  "Activity Report: Chart Prep Tool": "chart-prep-tool-activity-report",

  "Activity Report: Quality and Risk": "activity-quality-risk",

  "ADT Report": "adt-report",

  "Assessments Report": "assessments-report",

  "Care Program Report": "care-path-report",

  "Care Program Detail Report": "care-path-detail-report",

  "Ecip Report": "ecip-report",

  "Member Activity Report": "member-activity",

  "RAF Summary Report": "raf-summary-report",

  "RAF Practice Report": "raf-practice-report",

  "STAR Report": "star-summary-report",

  "HRM Report": "hrm-report",

  "AAWV Report": "aawv-report",

  "Diagnosis Report": "diagnosis-report",

  "Care Program Step Report": "care-path-step-report",

  "Task Activity Report": "cm-task-activity-report",

  "Collaborative Care Team Report": 'collaborative-report',

  "Lost Patients Report": 'lost-patients-report',

  "Complex Password Tracking Report": 'check-password-report',

  "Users Configured Teams Report" : "users-configured-teams-report"
};

export const CHRONIC_COND_NAME_MAP = {
  Asthma: "Asthma",
  CKD: "Chronic Kidney Disease",
  COPD: "Chronic obstructive pulmonary disease",
  CVD: "Cardiovascular disease",
  Depression: "Depression",
  Diabetes: "Diabetes",
};

export const urgency_referrals_options = [
  {
    text: "Priority (4-7 day response)",
    value: "20_PRIORITY",
  },
  {
    text: "Routine (8-14 day response)",
    value: "10_ROUTINE",
  },
];

export const reportsWithoutParams = [
  "Users Configured Teams Report"
];
