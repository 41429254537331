import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, ListGroup, Button } from "react-bootstrap";
import ErrorToast from "../common/ErrorToast"
import SuccessToast from "../common/SuccessToast"
import cloneDeep from "lodash/cloneDeep";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getPractice, updatePracticeInformation } from "../../actions/admin/practiceInformationActions";
import { theme } from "../../components/themes/theme1";

const componentName="PracticeInformation";

export const PracticeInformation = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { payersDetails } = useSelector((state) => state.practiceInformation);
  const errors = useSelector((state) => state.errors);
  const success = useSelector(state => state.success);

  const updatePracticeInfo = () => {
  dispatch(updatePracticeInformation(formData))
  };

  useEffect(() => {
    (async function fetchData() {
      await dispatch(getPractice());
    })();
  }, []);

  useEffect(() => {
    if (payersDetails && payersDetails.payers && payersDetails.payers.length) {
      const newObject = cloneDeep(payersDetails);
      setFormData(newObject);
    }
  }, [payersDetails]);

  const refresh = () => {
    const newObject = cloneDeep(payersDetails);
    setFormData(newObject);
  };

  const handleChange = (e, payerObj) => {
    const { name, value, checked } = e.target;
    if (
      name === "referralEmail1" ||
      name === "referralEmail2" ||
      name === "referralEmail3"
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      var index = formData.payers.indexOf(payerObj);
      if (checked && formData.payers && formData.payers.length) {
        formData.payers[index].selected = true;
      } else {
        formData.payers[index].selected = false;
      }
      setFormData({ ...formData, payers: formData.payers });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Row className="container-fluid p-3">
        <Col>
          <Card>
            <Card.Body>
            <Row className="mb-3">
              <Col>
                <Form>
                  <Form.Group>
                    <Card.Title id={`${componentName}-payersLabel`}>Participating Payers</Card.Title>
                    <Card.Text id={`${componentName}-payersInstructions`}>
                      Please indicate which of the following payer(s) your practice participates with.
                    </Card.Text>
                    <ListGroup className="mh-300 overflow-y-auto border" variant="flush">
                      {formData.payers &&
                        formData.payers.length &&
                        formData.payers.map((val) => (
                          <ListGroup.Item>
                            <Form.Check type="checkbox" key={val.payerName} id={`${componentName}-${val.payerName}`}>
                              <Form.Check.Input type="checkbox" id={`${componentName}-${val.payerName}Value`} name={val.payerName} checked={val.selected} onChange={(e) => handleChange(e, val)} />
                              <Form.Check.Label id={`${componentName}-${val.payerName}Label`}>{val.payerDisplayName}</Form.Check.Label>
                            </Form.Check>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Card.Title id={`${componentName}-referralEmailsLabel`}>Referral Emails</Card.Title>
                <Card.Text id={`${componentName}-referralEmailsInstructions`}>
                  Referral Emails Please enter email addresses associated to your practice where referral notifications will be sent.
                </Card.Text>
                <Form.Group className="mb-3">
                  <Form.Label id={`${componentName}-emailLabel1`}>E-mail 1</Form.Label>
                  <Form.Control
                    type="email"
                    className="form-control"
                    name="referralEmail1"
                    id={`${componentName}-emailValue1`}
                    onChange={(e) => handleChange(e)}
                    value={
                      formData && formData.referralEmail1
                        ? formData.referralEmail1
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label id={`${componentName}-emailLabel2`}>E-mail 2</Form.Label>
                  <Form.Control
                    type="email"
                    className="form-control"
                    name="referralEmail2"
                    id={`${componentName}-emailValue2`}
                    onChange={(e) => handleChange(e)}
                    value={
                      formData && formData.referralEmail1
                        ? formData.referralEmail2
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label id={`${componentName}-emailLabel3`}>E-mail 3</Form.Label>
                  <Form.Control
                    type="email"
                    name="referralEmail3"
                    id={`${componentName}-emailValue3`}
                    onChange={(e) => handleChange(e)}
                    value={
                      formData && formData.referralEmail1
                        ? formData.referralEmail3
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            {errors && errors.message && <ErrorToast/>}
            {success && success.message && <SuccessToast />}
            <Row>
              <Col className="d-flex justify-content-end">
                <Button id={`${componentName}-cancelButton`} variant="secondary" onClick={() => refresh()} className="me-2">
                  Cancel
                </Button>
                <Button id={`${componentName}-confirmButton`} variant="primary" onClick={() => updatePracticeInfo()}>
                  Confirm
                </Button>
              </Col>
            </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export default PracticeInformation;
