import React, { useLayoutEffect } from 'react'
import { useAuth } from '../context/authContext'
import { Spinner } from 'react-bootstrap';

function Logout() {
  const auth = useAuth();

  useLayoutEffect(() => {
    auth.logout()
  }, [])

  return (
    <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />
  )
}

export default Logout