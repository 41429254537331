import React, { useEffect, useState } from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../../utils/apiClient";
import { Button } from "../../components/buttons";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../components/dropDownLists/sharedDDL";
import {
  DropDownList,
  DDLDataMode,
  DDLMode,
} from "../../components/dropDownLists/genericDDL";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { Table } from "../../components/tables";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

const componentName = "ProviderSearch";

const baseURLCP =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/submit-referral";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const RedAsterisk = styled.span`
  color: red;
`;

const StyledSearchBtn = styled(Button)`
  background: 188dcd !important;
  border: 1px solid #d2d2d2;
  padding: 0 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 30px;
  color: white;
  display: inline-block;
  white-space: nowrap;

  :focus {
    border: 1px solid #d2d2d2;
    color: #404040;
  }
`;

const StyledResetBtn = styled(Button)`
  background: white !important;
  border: 1px solid #d2d2d2;
  padding: 0 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 30px;
  color: #404040;
  display: inline-block;
  white-space: nowrap;

  :focus {
    border: 1px solid #d2d2d2;
    color: #404040;
  }

  :hover {
    color: #188dcd;
    border: 1px solid #188dcd !important;
  }
`;

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
`;

const StyledTh = styled.tr`
  margin-left: 20px !important;
`;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const StyledTable = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`;

const genders = ["Male", "Female", "Any"];

const ProviderSearch = ({ patient, updateProvider, onHide }) => {
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState([]);
  const [associatedOptions, setAssociatedOptions] = useState([]);
  const [selectedAssociated, setSelectedAssociated] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [gender, setGender] = useState("Any");
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [payers, setPayers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [searching, setSearching] = useState(false);

  const isEmpty = require("is-empty");

  useEffect(() => {
    (async function retreiveData() {
      await getPayers();
    })();
  }, []);

  const getSpecialties = () => {
    return axios.get(
      baseURLCP +
        `/find-specialties/?nameFragment=${"*"}&patientId=${patient.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const getPayers = () => {
    axios
      .get(baseURLCP + `/find-payers/?patientId=${patient.id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        await findPatientPayer(response.data);
        await setPayers(response.data);
      })
      .catch((error) => {
      });
  };

  const findPatientPayer = (payerlist) => {
    for (var i = 0; i < payerlist.length; i++) {
      if (payerlist[i].toLowerCase() === patient.payer.toLowerCase()) {
        setSelectedPayer(payerlist[i]);
      }
    }
  };

  const searchPracticeName = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        const body = {
          page: 1,
          pageSize: 20,
          sortingCriteria: [],
          filters: [
            {
              fieldName: "searchProviderAssociatedProviderName",
              value: "",
              valuePresentation: selectedAssociated
                ? selectedAssociated.associatedProviderName
                : "",
            },
            {
              fieldName: "searchProviderGender",
              value: "",
              valuePresentation: gender,
            },
            {
              fieldName: "searchProviderLocation",
              value: String(zipCode),
              valuePresentation: String(zipCode),
            },
            {
              fieldName: "searchProviderPayer",
              value: "",
              valuePresentation: selectedPayer,
            },
            {
              fieldName: "searchProviderPracticeName",
              value: "",
              valuePresentation: selectedPractice
                ? selectedPractice.practiceGroupName
                : "",
            },
            {
              fieldName: "searchProviderSpecialty",
              value: "",
              valuePresentation: selectedSpecialty[0],
            },
            {
              fieldName: "searchProviderPatientName",
              value: "",
              valuePresentation: patient.id,
            },
          ],
        };
        axios
          .post(baseURLCP + `/find-practices/?nameFragment=${query}*`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setPracticeOptions(response.data);
          })
          .catch((error) => {
          });
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handlePracticeChange = (e) => {
    let newArr = [];
    if (e && e[0]) {
      newArr.push(e[0].practiceGroupName);
      setSelectedPractice(newArr);
    } else {
      setSelectedPractice(newArr);
    }
  };

  const searchAssociatedName = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        const body = { searchParameters: query };
        axios
          .post(
            baseURLCP + `/find-associated-provider/?nameFragment=${query}*`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setAssociatedOptions(response.data);
          })
          .catch((error) => {
          });
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleAssociatedChange = (e) => {
    let newArr = [];
    if (e && e[0]) {
      newArr.push(e[0].associatedProviderName);
      setSelectedAssociated(newArr);
    } else {
      setSelectedAssociated(newArr);
    }
  };

  const checkSelected = (provider) => {
    if (selectedProvider && selectedProvider.id === provider.id) {
      setSelectedProvider(null);
    } else {
      setSelectedProvider(provider);
    }
  };

  const searchProviders = () => {
    const body = {
      page: 1,
      pageSize: 20,
      sortingCriteria: [],
      filters: [
        {
          fieldName: "searchProviderAssociatedProviderName",
          value: "",
          valuePresentation: selectedAssociated.length
            ? selectedAssociated[0]
            : "",
        },
        {
          fieldName: "searchProviderGender",
          value: "",
          valuePresentation: gender,
        },
        {
          fieldName: "searchProviderLocation",
          value: String(zipCode),
          valuePresentation: String(zipCode),
        },
        {
          fieldName: "searchProviderPayer",
          value: "",
          valuePresentation: selectedPayer,
        },
        {
          fieldName: "searchProviderPracticeName",
          value: "",
          valuePresentation: selectedPractice.length ? selectedPractice[0] : "",
        },
        {
          fieldName: "searchProviderSpecialty",
          value: "",
          valuePresentation: selectedSpecialty.displayValue[0],
        },
        {
          fieldName: "searchProviderPatientName",
          value: "",
          valuePresentation: patient.id,
        },
      ],
    };
    setSearching(true);
    axios
      .post(baseURLCP + `/find-practice-providers`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        setSearching(false);
        setProviderList(response.data);
      })
      .catch((error) => {
        setSearching(false);
      });
  };

  const handleReset = () => {
    setSelectedSpecialty(null);
    findPatientPayer(payers);
    setSelectedPractice([]);
    setSelectedAssociated([]);
    setZipCode("");
    setProviderList([]);
  };

  const submitProvider = () => {
    updateProvider(selectedProvider);
    handleReset();
    onHide();
  };

  const handleSpecialtyChange = (filterParam) => {
    setSelectedSpecialty(filterParam);
  };

  return (
    <div>
      <Row className="mb-3">
        <Col sm={12} id={`${componentName}-providerInstruction`}>
          You may use any of the following criteria to identify a provider in
          Equality Health's network.
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <DropDownList
            mode={DDLMode.SelectOne}
            dataMode={DDLDataMode.OneAPICallFilterInUI}
            header={"Provider Specialty"}
            showRequired={true}
            handleChange={handleSpecialtyChange}
            selected={selectedSpecialty}
            getData={() =>
              getSpecialties().then((res) =>
                res.data.map((speciality) => {
                  return {
                    text: speciality.specialtyName,
                    value: speciality.specialtyName,
                  };
                })
              )
            }
            highlightWhenHasValue={false}
            removeSelected={() => setSelectedSpecialty()}
            removeStateHandler={true}
          />
        </Col>
        <Col sm={6} className="mb-3">
          <label className="d-block" id={`${componentName}-providerPayer`}>
            Payer<span className="text-danger">*</span>
          </label>
          <Dropdown id={`${componentName}-providerPayerValue`}>
            <Dropdown.Toggle
              as={DropDownButton}
              cssOpen={isOpen}
              cusWidth="100% !important"
            >
              <span>{selectedPayer ? selectedPayer : "-Select-"}</span>
              <i className="fa fa-chevron-down" />
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
              {payers.map((payer, idx) => (
                <Dropdown.Item  id={`${componentName}-providerPayerItemValue-${idx}`}
                  value={payer}
                  onSelect={() => setSelectedPayer(payer)}
                >
                  {payer}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mb-3">
          <label className="d-block" id={`${componentName}-providerPracticeName`}>Practice Name</label>
          <StyledTypeAhead
            disabled={isEmpty(selectedSpecialty) || isEmpty(selectedPayer)}
            inputProps={{
              id: `${componentName}-practiceNameValue`
            }}
            labelKey="practiceGroupName"
            isLoading={isLoadingComp}
            minLength={1}
            onSearch={searchPracticeName}
            options={practiceOptions}
            onChange={(e) => handlePracticeChange(e)}
            selected={selectedPractice}
          />
        </Col>
        <Col sm={6} className="mb-3">
          <label className="d-block" id={`${componentName}-providerAssociateProvName`}>Associated Provider Name</label>
          <StyledTypeAhead
            disabled={isEmpty(selectedSpecialty) || isEmpty(selectedPayer)}
            inputProps={{
              id: `${componentName}-associateProvNameValue`
            }}
            labelKey="associatedProviderName"
            isLoading={isLoadingComp}
            minLength={1}
            onSearch={searchAssociatedName}
            options={associatedOptions}
            onChange={(e) => handleAssociatedChange(e)}
            selected={selectedAssociated}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mb-3">
          <label className="d-block" id={`${componentName}-providerLocation`}>Location (Zip Code)</label>
          <Form.Group>
            <input
              disabled={isEmpty(selectedSpecialty) || isEmpty(selectedPayer)}
              type="number"
              name="zipCode"
              id={`${componentName}-providerLocationValue`}
              value={zipCode}
              className="form-control"
              maxLength="11"
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} className="mb-3">
          <label className="d-block" id={`${componentName}-providerGender`}>Provider Gender</label>
          <Dropdown id={`${componentName}-providerGenderValue`}>
            <Dropdown.Toggle
              disabled={isEmpty(selectedSpecialty) || isEmpty(selectedPayer)}
              as={DropDownButton}
              cssOpen={isOpen}
              cusWidth="100% !important"
            >
              <span>{gender.length ? gender : "-Select-"}</span>
              <i className="fa fa-chevron-down" />
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
              {genders.map((gen, idx) => (
                <Dropdown.Item key={gen} onSelect={() => setGender(gen)} id={`${componentName}-providerGenderItemValue-${idx}`}>
                  {gen}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col className="text-end pe-0 mt-3">
          {searching && (
            <>
              <Spinner
                className="me-3"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          )}
          <StyledSearchBtn
            variant="primary"
            id={`${componentName}-providerSearchBtn`}
            disabled={isEmpty(selectedSpecialty) || isEmpty(selectedPayer)}
            onClick={() => searchProviders()}
          >
            Search
          </StyledSearchBtn>
          <StyledResetBtn
            variant="outline-secondary"
            id={`${componentName}-providerResetBtn`}
            onClick={() => handleReset()}
          >
            Reset
          </StyledResetBtn>
        </Col>
      </Row>
      <StyledTable>
        <Table className="mt-3" striped bordered responsive="xl">
          <thead className="table-light">
            <tr>
              <th></th>
              <th>Practice/Provider</th>
              <th>Location</th>
              <th>Telephone</th>
            </tr>
          </thead>
          <tbody>
            {providerList.length
              ? providerList.map((provider) => {
                  return (
                    <>
                      <tr key={provider.id}>
                        <th>
                          <StyledInput
                            onChange={() => checkSelected(provider)}
                            type="checkbox"
                            id="PatientSelect"
                            checked={
                              (selectedProvider &&
                                selectedProvider.id === provider.id) ||
                              (selectedProvider &&
                                selectedProvider.organizationId ===
                                  provider.organizationId)
                            }
                          />
                        </th>
                        <td className="text-uppercase fw-bold">
                          {provider.name}
                        </td>
                        <td className="text-uppercase fw-bold">
                          {provider.location}
                        </td>
                        <td className="text-uppercase fw-bold">
                          {provider.phone}
                        </td>
                      </tr>
                      {provider.subItems.length
                        ? provider.subItems.map((item) => {
                            return (
                              <StyledTh key={item.id}>
                                <th style={{ marginLeft: "inherit" }}>
                                  <StyledInput
                                    style={{ marginLeft: "inherit" }}
                                    onChange={() => checkSelected(item)}
                                    type="checkbox"
                                    id="PatientSelect"
                                    checked={
                                      selectedProvider &&
                                      selectedProvider.id === item.id
                                    }
                                  />
                                </th>
                                <td>{item.name}</td>
                                <td>{item.location}</td>
                                <td>{item.phone}</td>
                              </StyledTh>
                            );
                          })
                        : null}
                    </>
                  );
                })
              : null}
          </tbody>
          {!providerList.length && <div style={{ marginTop: "14vh" }} />}
        </Table>
      </StyledTable>
      <StyledFooter>
        <Button onClick={() => onHide()} id={`${componentName}-providerCloseBtn`}>Close</Button>{" "}
        <Button disabled={!selectedProvider} onClick={() => submitProvider()}  id={`${componentName}-providerSelectBtn`}>
          Select
        </Button>
      </StyledFooter>
    </div>
  );
};

export default ProviderSearch;
