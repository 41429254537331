import {
    SET_MANAGE_VISIT_PATIENT,
    GET_MANAGE_VISIT_PATIENT,
    SET_RELOAD_PATIENT_LIST,
    SET_SHOW_FULL_HISTORY,
    SET_MANAGE_VISIT_OPENED_FROM,
    SET_PATIENT_LIST_TYPE,
    SET_CARE_PROGRAM_MODAL,
    SET_RELOAD_WORKLIST,
    SET_HIDE_PATIENT_DETAILS_MODAL,
    SET_MODAL_OPEN,
    SET_USER_EVENT_ACTION,
    SET_ADD_NEW_ASSESSMENT,
    SET_ADD_NEW_APPOINTMENT,
    SET_MINIMIZED_PATIENTS,
    REMOVE_MINIMIZED_PATIENT,
    GET_MINIMIZED_PATIENTS,
    SET_SNOOZED_MODAL,
    SET_STEP,
    SET_ADD_NEW_NOTE,
    SET_PCP_ID,
    SET_OLD_PATIENT_MODAL,
    SET_CUSTOM_STATUS
} from './types';

export const setManageVisitPatient = (patient) => ({
    type: SET_MANAGE_VISIT_PATIENT,
    payload: patient,
});

export const setModalOpen = (payload) => {
    return {
    type: SET_MODAL_OPEN,
    payload: payload
}}

export const setReloadPatientList = (payload) => ({
    type: SET_RELOAD_PATIENT_LIST,
    payload: payload,
});

export const getManageVisitPatient = () => ({
    type: GET_MANAGE_VISIT_PATIENT,
});

export const setShowFullHistory = (payload) => ({
    type: SET_SHOW_FULL_HISTORY,
    payload: payload
});

export const setManageVisitOpenedFromPageTitle = (payload) => ({
    type: SET_MANAGE_VISIT_OPENED_FROM,
    payload: payload,
});

export const setPatientListType = (listType) => ({
    type: SET_PATIENT_LIST_TYPE,
    payload: listType,
});

export const setCareProgramModalOpen = (payload) => ({
    type: SET_CARE_PROGRAM_MODAL,
    payload: payload,
});

export const setReloadWorklist =(payload) => ({
    type: SET_RELOAD_WORKLIST,
    payload: payload
})

export const setHidePatientDetailsModal =(payload) => ({
    type: SET_HIDE_PATIENT_DETAILS_MODAL,
    payload: payload
})

export const setPcpId =(payload) => ({
    type: SET_PCP_ID,
    payload: payload
})

export const setAddNewAsessment =(payload) => ({
    type: SET_ADD_NEW_ASSESSMENT,
    payload: payload
})

export const setSnoozedModal = (payload) => ({
    type: SET_SNOOZED_MODAL,
    payload: payload
})

export const setCustomStatus = (payload) => ({
    type: SET_CUSTOM_STATUS,
    payload: payload
})

export const setstep = (payload) => ({
    type: SET_STEP,
    payload: payload
})

export const setAddNewAppointment =(payload) => ({
    type: SET_ADD_NEW_APPOINTMENT,
    payload: payload
})

export const setAddNewNote =(payload) => ({
    type: SET_ADD_NEW_NOTE,
    payload: payload
})

export const setOldPatientModal =(payload) => ({
    type: SET_OLD_PATIENT_MODAL,
    payload: payload
})

export const setUserEventAction = (data) => ({
    type: SET_USER_EVENT_ACTION,
    payload: {data, timestamp: new Date().getTime() }
})

export const setMinimizedPatients = (payload) => ({
    type: SET_MINIMIZED_PATIENTS,
    payload: payload
})

export const getMinimizedPatients = (payload) => ({
    type: GET_MINIMIZED_PATIENTS,
    payload: payload
})

export const removeMinimizedPatient = (payload) => ({
    type: REMOVE_MINIMIZED_PATIENT,
    payload: payload
})
