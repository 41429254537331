import {isEmpty, trim} from "lodash";

const FILTERS_KEYS_MAP = {
  'activityType': 'Category',
  'author': 'Note Author'

}

export const getCustomFilterKeys = (filtersParams) => {
  return filtersParams.map(f => {
    let paramName = f.paramName
    switch (f.paramName) {
      case "Category":
        paramName = 'activityType'
        break;
      case "Note Author":
        paramName = "author"
      default:
        break;
    }
    return {...f, paramName}
  });
}

export const mapFiltersSelected = (filtersParams) => {
  return filtersParams.map(filterParam => {
    const filter = {...filterParam};
    if (!isEmpty(trim(FILTERS_KEYS_MAP[filter.paramName]))) {
      filter.paramName = FILTERS_KEYS_MAP[filter.paramName]
    }
    return filter;
  });
}