import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { isArray, isEmpty, isString } from "lodash";
import { CollapsibleCard } from './CollapsibleCard';
import { NOTES_ACTIVITY_TYPE_MAP } from "../../../../utils/constants";
import {
  formatDate,
  getDisplayValue,
  getFieldValueLabel,
  getFieldValueVal
} from "../../../../utils/util";
import {
  patientProfileField,
  visit_type_referrals
} from "../../../../utils/textValueLists";
import { mapReferralServicesToText } from "../../Referrals/referralUtils";
import { getFileDownload } from "../../../../actions/fileUploadActions";

const componentName = 'NoteCard'

const NoteCard = ({ note, idx, open, toggleIndividual }) => {
  const dispatch = useDispatch();

  const handleClickOnLink = (event, fileId, fileName) => {
    event.preventDefault();
    dispatch(getFileDownload(fileId, fileName));
  };

  const getTitle = () => {
    return note.description;
  }

  const getSubTitle = () => {
    return NOTES_ACTIVITY_TYPE_MAP[note.activityType]
  }

  const getDetails = (filterFn, defaultValue) => {
    return note?.fieldValues?.filter(filterFn) ?? defaultValue;
  }

  const ClosedContent = (
    <Container fluid>
      <div
        className="d-flex justify-content-between align-items-center card-header">
        <Button id={`${componentName}-ccTitle-${idx}`}
          onClick={() => toggleIndividual(!open)} variant="link"
          className="text-start text-dark p-0 text-decoration-none btn btn-link">
          <div className="d-flex align-items-center">
            {!open
              ? <i id={`${componentName}-ccPlus-${idx}`}
                className="fa-solid fa-chevron-right me-4" />
              : <i id={`${componentName}-ccMinus-${idx}`}
                className="fa-solid fa-chevron-down me-4" />
            }
            <div className="d-inline-block">
              <div className='d-flex align-items-center'>
                <span id={`${componentName}-ccTitle-${idx}`}
                  className="fs-5 fw-bold d-block lh-1">{getTitle()}</span>
              </div>
              <span id={`${componentName}-ccSubTitle-${idx}`}
                className="text-muted">{getSubTitle()}</span>
            </div>
          </div>
        </Button>
        <div className="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </Container>
  );

  const isDateType = (caption) => {
    return ['start', 'end', 'dateofservice'].includes(caption);
  }

  const getDisplayFiles = (value) => {
    if (!value) {
      return null;
    }

    const ClickableFile = ({ id, name }) => {
      return <p>
        <a id={`${componentName}-infoDDLinkValue-${idx}`} href="#"
          onClick={(event) => {
            handleClickOnLink(event, id, name)
          }}>
          {name ?? ""}
        </a>
      </p>
    }
    if (value.files) {
      return !isEmpty(value.files) &&
        value.files.map(file => <ClickableFile id={file.fileMetadata.fileId}
          name={file.fileMetadata.fileName} />);
    } else if (isArray(value) && !isEmpty(value)) {
      return value.map(file => <ClickableFile id={file.fileId}
        name={file.fileName} />);
    } else if (isString(value)) {
      const { fileName, fileId } = JSON.parse(value).value;
      return <ClickableFile id={fileId}
        name={fileName} />
    }
    return null;
  }

  const formatValue = (caption, value) => {
    if (isEmpty(caption) || ['file', 'files'].includes(caption)) {
      return getDisplayFiles(value);
    } else if (isEmpty(value)) {
      return "";
    } else if (isDateType(caption)) {
      return formatDate(value);
    } else if (caption === 'servicesNeeded') {
      const snValue = value?.servicesNeeded ? value.servicesNeeded : value;
      return mapReferralServicesToText(snValue);
    } else if (caption === 'visitType') {
      const vtValue = value?.visitType ? value.visitType : value;
      return vtValue ? getDisplayValue(visit_type_referrals, vtValue) : "-";
    }
    return value;
  }

  const getDetailsOrdered = () => {
    return [
      ...getDetails(
        item => !['note', 'files', 'file'].includes(item.caption),
        []
      ),
      ...getDetails(
        item => 'note' === item.caption,
        []
      )
    ]
  }

  const getAttachments = () => {
    return [
      ...getDetails(
        item => 'files' === item.caption,
        []
      ),
      ...getDetails(
        item => 'file' === item.caption,
        {}
      )
    ]
  }

  const attachments = getAttachments()?.filter(
    attachmentDetail => !isEmpty(getFieldValueVal(attachmentDetail)));

  const OpenContent = (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          {getDetailsOrdered().map(detail => {
            const label = getFieldValueLabel(detail);
            const value = getFieldValueVal(detail);

            return <Row className="mb-2">
              <Col className="form-label col-form-label" sm={3}>
                {getDisplayValue(patientProfileField, label)}
              </Col>
              <Col className="d-flex align-items-center">
                <span> {formatValue(label, value)} </span>
              </Col>
            </Row>
          }
          )}

          {!isEmpty(attachments) &&
            <Row className="mb-2">
              <Col className="form-label col-form-label" sm={3} style={{ paddingTop: '3px' }}>
                Attachments
              </Col>
              <Col>
                {attachments.map(attachmentDetail => {
                  const label = getFieldValueLabel(attachmentDetail);
                  const value = getFieldValueVal(attachmentDetail);
                  return <Row className="d-flex align-items-center">
                    {formatValue(label, value)}
                  </Row>
                }
                )}
              </Col>
            </Row>}
        </Col>
      </Row>
    </Container>
  )

  return (
    <>
      <CollapsibleCard
        openContent={OpenContent}
        open={open}
        setOpen={toggleIndividual}
        idx={idx}
        closedContent={ClosedContent}
      />
    </>
  );
};

export default NoteCard