import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { H3, H6 } from "../../components/headers";
import { Button } from "../../components/buttons";
import { Card } from "../../components/cards";

const LoginCard = styled(Card)`
  width: 400px;
  margin: 0 auto;
  border-radius: 0;
  border: none;

  p {
    text-align: center;
    color: #99abb4;
  }
`;

const Header = styled(H3)`
  text-align: center;
  margin-bottom: 20px;
`;

const LoginButton = styled(Button).attrs(props => ({
  variant: "primary",
  size: "lg"
}))`
  width: 100%;
  margin: 25px 0;
`;

const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .form-group {
    margin-bottom: 25px;
  }

  input {
    background-image: linear-gradient(#1976d2, #1976d2),
      linear-gradient(rgba(120, 130, 140, 0.13), rgba(120, 130, 140, 0.13));
    border: 0 none;
    border-radius: 0;

    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0 10px;
    transition: background 0s ease-out 0s;

    &:focus {
      box-shadow: none;
      background-size: 100% 2px, 100% 1px;
      transition-duration: 0.3s;
    }
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: #343a40;
  font-size: 14px;
`;

const ExternalForgotPasswordLink = styled.a`
  color: #343a40;
  font-size: 14px;
`;

const TermsAndConditionLink = styled(H6)`
  text-align: center;
  color: #343a40;
  font-weight: bold;
  font-family: Century Gothic;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export {
  LoginCard,
  Header,
  LoginButton,
  LoginForm,
  ForgotPasswordLink,
  ExternalForgotPasswordLink,
  TermsAndConditionLink
};
