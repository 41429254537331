import React from 'react'
import {
  PatientDetailsProvider
} from './components/PatientDetailsModals/context/PatientDetails.context'
import Main from './components/Main'
import Modals from './components/PatientDetailsModals/Modals'

const PatientDetails = () => {
  return (
    <PatientDetailsProvider>
      <Modals/>
      <Main/>
    </PatientDetailsProvider>
  )
}

export {PatientDetails}