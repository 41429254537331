import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button } from 'react-bootstrap'
import { trim, isEmpty } from "lodash";

import { RedAsterisk, isObjEmpty } from "../../../../../../utils/util";
import { MAX_LENGTH_NOTE_SUBJECT, MAX_LENGTH_NOTE_DETAIL, INTERNAL_NOTE_TYPE } from "../../../utils";
import { usePatientDetailsContext } from "../context/PatientDetails.context";
import { useEQHToastContext } from "../../../../../../components/toast/EQHToast.context";

const componentName = "AddInternalNote";

/**
 * AddInternalNote component provides functionality to add new internal notes for a patient within patient details.
 *
 * @returns {JSX.Element} A modal for adding new internal note to a patient's record within patient details.
 */
const AddInternalNote = () => {
  const {
    patientProfileInformation,
    isAddInternalNoteEnabled,
    toggleAddInternalNoteEnabled,
    addPatientInternalNote
  } = usePatientDetailsContext();

  const { removeNotification, setNotification } = useEQHToastContext();
  const [noteSubject, setNoteSubject] = useState("");
  const [noteDetail, setNoteDetail] = useState("");
  let patientName = `${patientProfileInformation?.firstName}, ${patientProfileInformation?.lastName}`;
  let patientId = patientProfileInformation?.id;
  let isSubmitDisabled = isEmpty(trim(noteSubject)) || isEmpty(trim(noteDetail));

  useEffect(() => {
    if (isAddInternalNoteEnabled === true) {
      patientName = `${patientProfileInformation?.firstName}, ${patientProfileInformation?.lastName}`;
      patientId = patientProfileInformation?.id;
    }
  }, [isAddInternalNoteEnabled])

  useEffect(() => {
    isSubmitDisabled = !isEmpty(trim(noteSubject)) && !isEmpty(trim(noteDetail));
  }, [noteSubject, noteDetail])

  /**
   * Retrieves the api object structure for a patient's internal notes.
   *
   * This function return an object with the api structure in order to add a internal notes,
   * including necessary fields. It ensures consistency in how internal notes are recorded.
   *
   * @returns {Object} The internal note object.
   */
  const getPatientInternalNoteContract = () => {
    return {
      patientId: patientId,
      description: noteSubject,
      noteText: noteDetail,
      createdOn: new Date(),
      noteType: INTERNAL_NOTE_TYPE
    };
  }

  /**
   * Validates an internal note object against specific criteria.
   *
   * This function examines the fields of the internalNote object to ensure they comply with different criteria. 
   * If violations are found, corresponding error messages are generated.
   *
   * @param {Object} internalNote - The internal note object to validate.
   * @returns {Object|null} An object containing error messages for each invalid field, or null if no errors are found.
   */
  const validateInternalNote = (internalNote) => {
    let errors = {};

    if (internalNote?.description?.length > MAX_LENGTH_NOTE_SUBJECT) {
      errors["noteSubject"] = `Subject must contain no more than ${MAX_LENGTH_NOTE_SUBJECT} alphanumeric characters`;
    }
    if (internalNote?.noteText?.length > MAX_LENGTH_NOTE_DETAIL) {
      errors["noteDetail"] = `Note Detail must contain no more than ${MAX_LENGTH_NOTE_DETAIL} alphanumeric characters`;
    }

    return isObjEmpty(errors) ? null : errors;
  }

  /**
   * Resets form fields and submission state.
   */
  const reset = () => {
    patientName = "";
    patientId = null;
    isSubmitDisabled = true;
    setNoteDetail("");
    setNoteSubject("");
    removeNotification();
  }

  /**
   * Handles closing of the modal.
   */
  const closeModal = () => {
    reset();
    toggleAddInternalNoteEnabled(false);
  }

  /**
   * Submits the internal note data.
   */
  const submitModal = () => {
    const internalNote = getPatientInternalNoteContract();
    const errors = validateInternalNote(internalNote);

    if (errors) {
      setNotification("Error", Object.values(errors));
    } else {
      addPatientInternalNote(internalNote);
    }
  }

  return (
    <Modal
      id={`${componentName}-modal`}
      show={isAddInternalNoteEnabled}
      onHide={() => { closeModal(); }}
      centered
      size="sm"
    >
      <Modal.Header>
        <Modal.Title className="h5">Add Internal Note</Modal.Title>
        <button
          id={`${componentName}-modalClose`}
          type="button" className="btn-close"
          aria-label="Close"
          onClick={() => { closeModal(); }}
        />
      </Modal.Header>
      <Modal.Body className="pb-2">
        <Row className="mb-4">
          <Col className="d-block">
            <span className="d-block fw-bold small">Patient Name</span>
            <span className="d-block small forced-capital">{patientName}</span>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="d-block">
            <span className="d-block fw-bold small">Subject<RedAsterisk/></span>
            <Form.Control
              type="text"
              size="sm"
              value={noteSubject}
              onChange={(e) => setNoteSubject(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-block">
            <span className="d-block fw-bold small">Note Detail<RedAsterisk/></span>
            <Form.Control
              as="textarea"
              size="sm"
              value={noteDetail}
              rows={4}
              onChange={(e) => setNoteDetail(e.target.value)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="pb-4">
        <Button
          variant="secondary"
          id={`${componentName}-modalCancel`}
          size="sm"
          style={{ paddingLeft: '34px', paddingRight: '34.5px' }}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          id={`${componentName}-submitPatientEdit`}
          size="sm"
          style={{ paddingLeft: '34.5px', paddingRight: '34.5px', marginLeft: '3.8px' }}
          onClick={() => {
            submitModal();
            closeModal();
          }}
          disabled={isSubmitDisabled}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddInternalNote;
