import React from 'react'
import {useCMWorklistContext} from '../context/CMWorklist.context';

import {
  SaveAsCustomWorklist as MyMembersSaveAsCustomWorklist
} from "../../../../../../components/CustomWorklist";

import {
  getQuickListNameByRole,
} from "../../../utils";
import {useAuth} from "../../../../../../context/authContext";

const componentName = 'MyMembersSaveAsCustomWorklist';

function SaveAsCustomWorklist() {
  const auth = useAuth();
  const {
    activeRole,
    patientSearch,
    sendEvent,
  } = useCMWorklistContext();

  const role = (activeRole.role) ?? auth.getRoles()[0];
  const quickListName = getQuickListNameByRole(role);

  return (
    <MyMembersSaveAsCustomWorklist {...{
      quickListName,
      componentName,
      patientSearch,
      sendEvent,
      activeRole
    }} />
  )
}

export default SaveAsCustomWorklist;