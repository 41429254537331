import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';
import EQHErrorToast from "../../../../../../components/EQHErrorToast";

const componentName = 'MarkAsOutreached';

function MarkAsOutreached() {
    const isEmpty = require("is-empty");

    const {
        isMarkAsOutreachedEnabled,
        toggleMarkAsOutreached,
        campaigns,
        markAsOutreached,
        patientsEventsToBeMarkedAsOutreached,
        setPatientsEventsToBeMarkedAsOutreached,
        sendEvent
    } = useCMWorklistContext();
    const [usedCampaign, setUsedCampaign] = useState('');
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        if (isMarkAsOutreachedEnabled) {
            resetState()
        }
    }, [isMarkAsOutreachedEnabled])

    const patientData = patientsEventsToBeMarkedAsOutreached.map(patientEvent => ({
        reason: usedCampaign,
        notes: null,
        action: 'OUTREACHED',
        origin: 'CM_WORKLIST',
        patientId: patientEvent.patientId,
        programLeadId: '',
        programId: '',
        programName: '',
        referralId: patientEvent.referralId,
        adtId: patientEvent.adtId
    }));

    const areRequiredFieldsValid = () => {
        return !isEmpty(usedCampaign);
    }

    const submitAction = async () => {
        if (areRequiredFieldsValid()) {
            sendEvent('CONFIRM_SAVE_OUTREACHED');
            await markAsOutreached(patientData);
        } else {
            setErrors(["All fields are required"]);
        }
    }

    const resetState =  () => {
        setUsedCampaign('');
        setErrors([]);
    }

    const closeAction = async () => {
        resetState();
        toggleMarkAsOutreached(false);
        setPatientsEventsToBeMarkedAsOutreached([]);
    }

    return (
        <Modal
            id={`${componentName}-patientMarkAsOutreachedConfirmModal`}
            size="md"
            show={isMarkAsOutreachedEnabled}
            onHide={() => closeAction()}
            centered
        >
            <Modal.Header>
                <Modal.Title id={`${componentName}-patientMarkAsOutreachedConfirmTitle`}>Mark as Outreached</Modal.Title>
                <button
                    id={`${componentName}-patientMarkAsOutreachedConfirmModalClose`}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closeAction()}
                />
            </Modal.Header>
            <Modal.Body>
                <EQHErrorToast errors={errors} handleClose={() => setErrors([])} isList={true} />
                <Row className="mb-4">
                    <Col>
                        <Row>
                            <Form.Group>
                                <Form.Label id={`${componentName}-campaignUsedLabel`}>
                                    Campaign Used
                                </Form.Label>
                                <Form.Control as="select" value={usedCampaign} onChange={(e) => setUsedCampaign(e.target.value)}
                                    id={`${componentName}-MarkAsOutreachedUsedCampaignLabel`}>
                                    <option
                                        disabled={true}
                                        key={`${componentName}-select-MarkAsOutreachedUsedCampaignItem`}
                                        id={`${componentName}-select-MarkAsOutreachedUsedCampaignItem`}
                                        value=''
                                    >
                                        Select
                                    </option>
                                    {campaigns.map((x) => (
                                        <option
                                            key={`${componentName}-${x.value}-MarkAsOutreachedUsedCampaignItem`}
                                            id={`${componentName}-${x.value}-MarkAsOutreachedUsedCampaignItem`}
                                            value={x.text}
                                        >
                                            {x.text}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button id={`${componentName}-patientMarkAsOutreachedNoBtn`} variant="secondary" onClick={() => closeAction()}>
                    Cancel
                </Button>
                <Button
                    id={`${componentName}-patientMarkAsOutreachedYesBtn`}
                    variant="primary"
                    disabled={isEmpty(usedCampaign)}
                    onClick={() => submitAction()}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MarkAsOutreached;