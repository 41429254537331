import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import MultipleUploads from "../../../../components/MultipleUploads";
import { uploadFile } from "../../../../actions/myMembersActions";
import EQHErrorToast from "../../../../components/EQHErrorToast";
import { formatDate } from '../../../../utils/util';
import { FileUpload } from '../../../../components/FileUpload';

UpdateMember.defaultProps = {
  programStatusTypes: []
};

const MAX_FILE_SIZE_MB = 20;

export default function UpdateMember({
  showEditMember,
  handleEditMember,
  handleEditMemberClose,
  setNoteData,
  noteData,
  programStatusTypes,
  memberSelected,
  isMemberLoading,
  interactionTypes,
  interactionOutcomes,
}) {
  const componentName = "Workspace";
  const [programStatusOptions, setProgramStatusOptions] = useState([])
  const [fileAdded, setFileAdded] = useState({});
  const [errors, setErrors] = useState([]);

  const isEmpty = require("is-empty");
  const handleUploadFileChange = (arr) => {
    setNoteData(
      { ...noteData, files: arr }
    );
  };


  useEffect(() => {
    setProgramStatusOptions(programStatusTypes)
    const memberProgramStatus = programStatusTypes.some(programStatus => memberSelected.programStatusId === programStatus.value)
    if (!memberProgramStatus) {
      setProgramStatusOptions([
        {
          value: memberSelected.programStatusId,
          text: memberSelected.programStatus,
        },
        ...programStatusTypes
      ])

    }
  }, [programStatusTypes])

  return (
    <Modal size="lg" centered show={showEditMember} onHide={handleEditMemberClose}>
      <Modal.Header closeButton>
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          <h5>{memberSelected?.fullName}</h5>
          <div className="d-flex fw-bold" style={{ gap: '20px' }}>
            <p>{formatDate(memberSelected?.dob)}</p>
            <p>{memberSelected?.memberId}</p>
            <p>{memberSelected?.phoneNumber}</p>
            <p>{memberSelected?.payer1}</p>
            <p>{memberSelected?.payer2}</p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
        <Row>
          <Col id={`${componentName}-memberStatus`} className="mb-3" md={6}>
            <label id={`${componentName}-memberStatusLabel`} className="d-block">Status<span className="text-danger">*</span></label>
            <select
              id={`${componentName}-memberStatusValue`}
              className="form-select"
              onChange={e => setNoteData(
                { ...noteData, programStatusId: e.target.value }
              )}
            >
              {programStatusOptions?.map(programStatusOption => {
                return (
                  <option
                    selected={programStatusOption.value === memberSelected.programStatusId}
                    value={programStatusOption.value}>{programStatusOption.text}
                  </option>
                )
              })
              }
            </select>
          </Col>
          <Col id={`${componentName}-memberInteractionType`} className="mb-3" md={3}>
            <label id={`${componentName}-memberInteractionTypeLabel`} className="d-block">Interaction Type</label>
            <select
              id={`${componentName}-memberInteractionTypeValue`}
              className="form-select"
              onChange={e => setNoteData(
                { ...noteData, interactionTypeId: e.target.value }
              )}
            >
              {
                interactionTypes?.map(interactionType => {
                  return (
                    <option
                      selected={interactionType.value === memberSelected.interactionTypeId}
                      value={interactionType.value}>{interactionType.text}
                    </option>
                  )
                })
              }
            </select>
          </Col>
          <Col id={`${componentName}-memberInteractionOutcome`} className="mb-3" md={3}>
            <label id={`${componentName}-memberInteractionOutcomeLabel`} className="d-block">Interaction Outcome</label>
            <select
              id={`${componentName}-memberInteractionOutcomeValue`}
              className="form-select"
              onChange={e => {
                setNoteData(
                  { ...noteData, interactionOutcomeId: e.target.value }
                )
              }}
            >
              {interactionOutcomes?.map(interactionOutcome => {
                return (
                  <option
                    selected={interactionOutcome.value === memberSelected.interactionOutcomeId}
                    value={interactionOutcome.value}>{interactionOutcome.text}
                  </option>
                )
              })
              }
            </select>
          </Col>
        </Row>
        <Row>
          <Col id={`${componentName}-memberNotes`} className="mb-3">
            <label id={`${componentName}-memberNotesLabel`} className="d-block">Notes</label>
            <Form.Control
              id={`${componentName}-memberNotesValue`}
              max="500"
              as="textarea"
              rows={3}
              onChange={e => setNoteData(
                { ...noteData, noteText: e.target.value }
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col className="form-group m-b-10" id={`${componentName}-memberFiles`}>

            <FileUpload
              onChange={handleUploadFileChange}
              clearStateProp={0}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleEditMemberClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleEditMember}>
          {
            isMemberLoading ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
