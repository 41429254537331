import React, {createContext, useContext, useEffect, useReducer} from "react";
import {reducer} from "./Referrals.reducer";
import {initialState,} from "./referralsActions";
import {getGetters} from "./referralsGetters";
import {getToggles} from "./referralsToggles";
import {getCallbacks} from "./referralsCallbacks";
import {useEQHToastContext} from "../../../../../../components/toast/EQHToast.context";
import {getSetters} from "./referralsSetters";

const ReferralsContext = createContext();

const ReferralsProvider = ({children, showSpecialistReferral, toggleShowSpecialistReferral, patient}) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {setNotification} = useEQHToastContext();
    const getters = getGetters(state);
    const setters = getSetters(dispatch, state);
    const toggles = getToggles(dispatch);
    const callbacks = getCallbacks(dispatch, state, patient, setNotification);

    const value = {
        ...getters,
        ...setters,
        ...toggles,
        ...callbacks,
        showSpecialistReferral,
        toggleShowSpecialistReferral,
        patient,
    }

    return (<ReferralsContext.Provider value={value}>
        {children}
    </ReferralsContext.Provider>)
}

const useReferralsContext = () => useContext(ReferralsContext);

export {
    ReferralsProvider, useReferralsContext
}