import axios from "../../../../../utils/apiClient";

const baseURL = process.env.REACT_APP_REST_API_BASE_URL;


export const getAppointments = (id, filters = []) => {
    const url = `${baseURL}/v1/patient-profile/appointments`
    return axios.post(url, {
        "patientsIds": [
          id
        ],
        "filterParams": filters,
        "sortParams": [],
        "pageNumber": 1,
        "pageSize": 100
    })
}

export const getSteps = (id) => {
  const url = `${baseURL}/v1/care-paths/care-path-instance-steps?carePathInstanceId=${id}`
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const getCarePaths = (id) => {
  const url = `${baseURL}/v1/patient-profile/care-paths?patientId=${id}`
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const cancelAppointment = async (requestBody) => {
  const url = `${baseURL}/v1/appointment/cancel-with-note`
  return axios.delete(url,
    {data: requestBody, headers: {"Content-Type": "application/json"}});
}