import {isEmpty} from "lodash";
import {getAttestationDDLOption, normalizeDate} from "./common";
import {CARE_GAP_STATUS_BY_DESCRIPTION} from "../../../../utils/constants";

export const getVisitsDue = (visitsDetails) => {
  if(isEmpty(visitsDetails?.careVisitDtos)){
    return [];
  }

  const statusesOptionsMap = visitsDetails?.caregapStatusesDropDownMap ?? {}

  return visitsDetails?.careVisitDtos?.map(
    (careVisit) => {
      const title = isEmpty(careVisit.gapAndYear) ? careVisit.name
        : careVisit.name + " - " + careVisit.gapAndYear;

      return {
        showTitle: false,
        title: careVisit.name,
        activities: [
          {
            id: careVisit.visitId,
            title: title,
            status: careVisit.careGapStatus,
            statusEnum: CARE_GAP_STATUS_BY_DESCRIPTION[careVisit.careGapStatus],
            date: normalizeDate(careVisit.dueDate),
            conditionType: null,
            statusesOptionsMap: statusesOptionsMap,
            requireA1C: false,
            requireBloodPressure: false,
            isAttestationRequired: false,
            requireFileUpload: false,
            actionType: 'VISIT_DUE',
            requiredActivitiesIds: Object.keys(
              careVisit.requiredActivitiesMap ?? {}),
            validation: null,
            isDocumentationAndCoding: false,
            attestationDDLOption: getAttestationDDLOption(careVisit)
          }
        ]
      }
    });
}
