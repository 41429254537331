export const colorPalette = {
    orange: "#ECA466",
    lightOrange: "#FFEBD9",
    green: "#7DAA73",
    lightGreen: "#F3F9F2",
    blue: "#1E89C4",
    lightBlue: "#D3E4F5",
    white: "#FFFFFF",
    gray: "#808080",
    mediumGray: "#ABADAF",
    lightGray: "#D3D3D3",
    defaultBackground: "#86898B"
};

export const notificationDelayList = [
    { text: '-Select-', value: 0},
    { text: 24, value: 24 },
    { text: 12, value: 12 },
    { text: 8, value: 8 },
    { text: 5, value: 5 },
    { text: 2, value: 2 },
];

export const units = [
    { text: "-Select-", value: "" },
    { text: "Hours", value: "HOURS" },
    { text: "Minutes", value: "MINUTES" },
    { text: "Days", value: "DAYS" },
];

export const durationList = [
    { text: "Select", value: 0 },
    { text: "10 min", value: 10 },
    { text: "15 min", value: 15 },
    { text: "20 min", value: 20 },
    { text: "30 min", value: 30 },
    { text: "45 min", value: 45 },
    { text: "60 min", value: 60 },
    { text: "75 min", value: 75 },
    { text: "90 min", value: 90 },
];

export const appointmentModalStatus = [
    {
        text: "Scheduled",
        value: "SCHEDULED",
    },
    {
        text: "Confirmed",
        value: "CONFIRMED",
    },
    {
        text: "Completed",
        value: "COMPLETED",
    },
    {
        text: "No Show",
        value: "NO_SHOW",
    },
];

export const carePathStepsList = [
    {
        text: "-Select-",
        value: "",
    },
    {
        text: "ADMIT",
        value: "ADMIT",
    },
    {
        text: "DISCHARGE",
        value: "DISCHARGE",
    },
    {
        text: "PCP APPOINTMENT",
        value: "PCP_APPOINTMENT",
    },
    {
        text: "SPECIALIST APPOINTMENT",
        value: "SPECIALIST_APPOINTMENT",
    },
    {
        text: "CARE PROGRAM START DATE",
        value: "CARE_PATH_START_DATE",
    },
    {
        text: "PREVIOUS STEP",
        value: "PREVIOUS_STEP",
    },
    {
        text: "NURSE APPOINTMENT",
        value: "NURSE_APPOINTMENT",
    },
];

export const initialForm = {
    provider: {
        id: "",
        firstName: "",
        lastName: "",
        phone: "",
        facility: "",
        streetAddress: "",
        specialties: [],
        userAccountId: "",
        type: "",
    },
    appointmentId: null,
    type: "NEW",
    status: "SCHEDULED",
    carePathStepId: null,
    name: "",
    appointmentDateTime: "",
    durationInMinutes: 0,
    address: "",
    providersToSelect: [],
    shouldSendReminder: true,
    notificationDelay: 0,
    notificationDelayUnit: "",
    needsTransportation: false,
    note: "",
    files: [],
    uploadedFiles: [],
    patientId: "",
    carePathId: "",
    carePathEvent: "",
}