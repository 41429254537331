import { actions } from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload,
      };
    case actions.SET_PATIENT_ID:
      return {
        ...state,
        patientId: action.payload
      };
    case actions.SET_MODE:
      return {
        ...state,
        mode: action.payload
      };
    case actions.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      }
    case actions.SET_FILL_ASSESSMENT:
      return {
        ...state,
        fillAssessment: action.payload
      }
    case actions.SET_INDIVIDUAL_ASSESSMENT_CARD:
      return {
        ...state,
        individualAssessmentCard: action.payload
      }
    case actions.SET_EXPORT_PARAMS:
      return {
        ...state,
        exportParams: action.payload
      };
    case actions.SET_EXPORT_DATA:
      return {
        ...state,
        exportData: action.payload
      };
    case actions.SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload
      };
    case actions.SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.payload
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload
      };
    default:
      return state;
  }
};