import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../utils/apiClient";
import { useAuth } from "../../../../context/authContext";

import { Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import Modals from "./modal";
import MultipleUploads from "../../../../components/MultipleUploads";
import { sendReply, markAsRead, createConversation, createMessage, getMessagesFromConvo, resetReplyBoolean } from "../../../../actions/admin/header/messageActions";
import { getAllUsersByCLients } from "../../../../actions/admin/userManagementActions";
import { updateFile, getFileDownload, clearFileUploads, getFileDownloadGeneric } from "../../../../actions/fileUploadActions";
import ErrorToast from "../../../common/ErrorToast";
import { formatDate, formatDateTime, reactQuickLists } from "../../../../utils/util";
import { FileUpload } from "../../../../components/FileUpload";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const componentName = "Conversation";

const Conversation = ({ title, conversationId, messageType }) => {
  const { allUsers } = useSelector((state) => state.userManagement);
  const { fileObject } = useSelector((state) => state.fileUpload);
  const { conversationMessages, replyMessageSuccess } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const isEmpty = require("is-empty");
  const URL = "/transitions_of_care?source=message";
  const [newParticipants, setNewParticipants] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState();
  const [fileAdded, setFileAdded] = useState({});
  const [reply, setReply] = useState("");
  const [urgent, setUrgent] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  let auth = useAuth();
  const [emailData, setEmailData] = useState({});
  const errors = useSelector((state) => state.errors);
  const messageRef = useRef();

  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const [optionsPatient, setOptionsPatients] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [patient, setPatient] = useState(null);

  const handleChangePatient = (e) => {
    if (e && e[0]) {
      findPatient(e[0].value);
    }
  };
  const findPatient = (id) => {
    if (id) {
      const input = {
        filterParams: [
          {
            paramName: "Patient Id",
            paramValue: [id],
            comparison: "eq",
            displayValue: [null],
          },
        ],
        pageNumber: 1,
        pageSize: 15,
        quickListName: "APPOINTMENT_PATIENT",
        sortParams: [],
      };
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL + "/v1/patients/myPatients";
      axios
        .post(SEARCH_TEXT_URL, input, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.patientCardDtos &&
            resp.data.patientCardDtos.length
          ) {
            setPatient(resp.data.patientCardDtos[0]);
          }
        })
        .catch((error) => {
        });
    }
  };

  const asyncsearchPatient = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearchPatient(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSearchPatient = (query) => {
    if (query) {
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL +
        "/v1/global-search/findPatientInfo?searchString=" +
        query;
      setIsLoadingPatient(true);
      axios
        .get(SEARCH_TEXT_URL, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data && resp.data.patients) {
            options = resp?.data?.patients?.map((i) => ({
              value: i?.id,
              text: i?.fullName + " " + i?.memberId + " DOB: " + formatDate(i?.dateOfBirth),
            }));
          }
          setOptionsPatients(options);
          setIsLoadingPatient(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
          setIsLoadingPatient(false);
        });
    }
  };

  useEffect(() => {
    (async function fetchMessages() {
      if (!allUsers.length) {
        await dispatch(getAllUsersByCLients());
      }
      await dispatch(markAsRead([conversationId], true));

    })
      ();
  }, []);
  useEffect(() => {
    (async function fetchMessages() {
      await dispatch(getMessagesFromConvo(conversationId));
    })();
  }, [conversationId]);

  useEffect(() => {
    (async function fetchMessages() {
      if (replyMessageSuccess) {
        messageRef.current.value = ''
        await dispatch(resetReplyBoolean());
      }
    })();

  }, [replyMessageSuccess]);


  useEffect(() => {
    if (fileObject) {
      setFileAdded(fileObject);
    }
  }, [fileObject]);

  const StyledSentence = styled.p`
  margin: 0px;
`;

  const updateUsers = async (e, error) => {
    let idArray = [];
    if (e.length > 0) {
      e.forEach((user) => {
        if (!idArray.includes(user.id)) {
          idArray.push(user.id);
        }
      });
    }
    setNewParticipants(idArray);
  };

  const setFileMethod = async (filesList) => {
    let file;
    let fileName
    if (filesList && filesList.name) {
      fileName = filesList.name
    }
    const fileReader = new FileReader();
    if (fileReader && filesList) {
      fileReader.readAsArrayBuffer(filesList);
      fileReader.onload = function () {
        if (fileReader && fileReader.result && !isEmpty(fileReader.result)) {
          dispatch(updateFile(fileReader.result, fileName));
        }
      };
    }
  };

  const handleReplyChange = (e) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const isFormValid = () => {
    const { message } = emailData;
    if (
      message &&
      message.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const submitReplyFunc = async () => {
    const messageObj = {
      conversationId: conversationId,
      title: title,
      message: emailData.message,
      messageType: messageType,
      newParticipants: newParticipants,
      important: urgent,
      patientId: patient ? patient.id : null,
    };
    if (uploadedFiles && uploadedFiles.length) {
      messageObj["uploadedFiles"] = uploadedFiles;
    }
    dispatch(createMessage(messageObj));
  };
  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };
  const updateFiles = (arr) => {
    setUploadedFiles(arr);
  };

  const handleClickofLink = (file) => {
    if (file && file.fileId) {
      dispatch(getFileDownload(file.fileId, file.fileName))
    }
  };

  const linkElement = (linkURI, linkText) => {
    if (linkURI) {
      const index = linkURI.indexOf("/");
      const quicklistType = linkURI.substring(0, index);
      const quicklistId = linkURI.substring(index + 1);

      if (index === -1) {
        return <a href={`/${quicklistId}`} id={`${componentName}-messageLink`} className="btn btn-secondary border px-3">
          <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
        </a>;
      } else {
        if (reactQuickLists.includes(quicklistType)) {
          return <a href={`/${quicklistType}?id=${decodeURIComponent(quicklistId)}&customListName=${linkText}`} id={`${componentName}-messageLink`} className="btn btn-secondary border px-3">
            <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
          </a>;
        } else {
          return <a href={`/#!${quicklistType}/${encodeURIComponent(quicklistId)}`} id={`${componentName}-messageLink`} className="btn btn-secondary border px-3">
            <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
          </a>
        }
      }
    } else {
      return null;
    }
  };

  return (
    <div>
      <form>
        {conversationMessages &&
          conversationMessages.messages &&
          conversationMessages.messages.length ? (
          <Row>
            <Col>
              <h5 id={`${componentName}-conversationTitle`}>{conversationMessages.title}</h5>
              {conversationMessages.messages.map((message) => {
                return (
                  <div key={message.id}>
                    <div className="d-flex w-100 justify-content-between mt-2">
                      <small>
                        <b id={`${componentName}-senderName`} className="me-2">{message.sender.firstName + " " + message.sender.lastName}</b>
                        <span id={`${componentName}-senderOrg`}>{message.sender.organizationName}</span>
                      </small>
                      <small id={`${componentName}-senderDate`} className="text-end text-nowrap">{formatDateTime(message.sendDateTime)}</small>
                    </div>
                    <hr className="mt-2" />
                    <p><b id={`${componentName}-messageType`}>Message Type: </b>{message.messageType}</p>
                    <p id={`${componentName}-senderMessage`}>{message?.message?.split('\n').map((item, i) => <StyledSentence key={i}>{item}</StyledSentence>)}</p>
                    {message.linkURI && (
                      <p>
                        {linkElement(
                          message.linkURI,
                          message.linkText
                        )}
                      </p>
                    )}
                    {message.uploadedFiles &&
                      message.uploadedFiles.length > 0 &&
                      message.uploadedFiles.map((file, idx) => (
                        <p key={file.fileId} id={`${componentName}-uploadFile-${idx}`}>
                          {file && (
                            <a id={`${componentName}-uploadFileLink-${idx}`} href="#" onClick={() => handleClickofLink(file)} className="btn btn-secondary border px-3">
                              {file.fileName ? file.fileName : ""}
                            </a>
                          )}
                        </p>
                      ))}
                    {message.exportFiles &&
                      message.exportFiles.length > 0 &&
                      message.exportFiles.map((file, idx) => (
                        <p key={file.fileName} id={`${componentName}-exportFile-${idx}`}>
                          {file && (
                            <Button variant="secondary" onClick={() => getFileDownloadGeneric(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/files/get?fileUri=${encodeURIComponent(file.fileUri)}&fileName=${file.fileName}`, file.fileName)} id={`${componentName}-exportFileLink-${idx}`} className="border px-3">{file.fileName ? file.fileName : ""}</Button>
                          )}
                        </p>
                      ))}
                  </div>
                )
              })
              }
            </Col>
          </Row>
        ) : (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Loading...
          </>
        )}
        <Row className="p-3">
          <Col className="bg-light border rounded-3 p-3">
            <h5 id={`${componentName}-conversationReply`}>Reply</h5>
            <div className="form-group">
              <label id={`${componentName}-conversationUserLabel`} className="d-block">Choose Additional Users</label>
              {errors && errors.message && <ErrorToast />}
              {allUsers && allUsers.length > 0 ? (
                <Typeahead
                  id={`${componentName}-usersList`}
                  inputProps={{
                    id: `${componentName}-usersList`
                  }}
                  multiple
                  labelKey="email"
                  onChange={(e) => updateUsers(e)}
                  options={allUsers}
                />
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </div>
            {patient && (
              <>
                <div>
                  <span id={`${componentName}-patient`}>
                    <span id={`${componentName}-patientLabel`} className="fw-bold">Patient:</span>{" "}
                    <span id={`${componentName}-patientValue`}>{patient?.lastName}, {patient?.firstName}</span>
                  </span>
                </div>
                <div>
                  <span id={`${componentName}-memberId`}>{patient?.memberId}</span>{" "}
                  <span id={`${componentName}-memberStatus`}>({patient?.active ? (
                    <span id={`${componentName}-memberStatusActive`} className="text-success">Active</span>
                  ) : (
                    <span id={`${componentName}-memberStatusInactive`} className="text-danger">Inactive</span>
                  )})
                  </span>
                  <span id={`${componentName}-DOB`} className={"ms-2"}>
                    <span id={`${componentName}-DOBLabel`} className="fw-bold">DOB: </span>{" "}
                    <span id={`${componentName}-DOBValue`}>{patient && formatDate(patient?.dateOfBirth)} ({patient?.age})</span>
                  </span>
                </div>
              </>
            )}
            <div id={`${componentName}-MessagePatient`} sm={4} className="py-3">
              <label id={`${componentName}-MessagePatientLabel`}>Patient</label>
              <StyledTypeAhead
                inputProps={{
                  id: `${componentName}-MessagePatientValue`
                }}
                labelKey="text"
                isLoading={isLoadingPatient}
                onSearch={asyncsearchPatient}
                options={optionsPatient}
                onChange={(e) => handleChangePatient(e)}
                placeholder="Search Patients by Name"
              />
            </div>
            <div className="form-group mb-3">
              <FileUpload
                onChange={handleUploadFileChange}
                clearStateProp={0}
              />
            </div>
            <div className="form-group">
              <label id={`${componentName}-convoUserLabel`} className="d-block">Message<span className="text-danger">*</span></label>
              <textarea
                className="form-control"
                id={`${componentName}-conversationReplyMessage`}
                name="message"
                ref={messageRef}
                onChange={(e) => handleReplyChange(e)}
                rows="5"
              ></textarea>
            </div>
          </Col>
        </Row>
      </form>
      <div className="modal-footer">
        <div className="me-auto">
          <div id={`${componentName}-conversationUrgent`} className="form-check">
            <input type="checkbox" id={`${componentName}-conversationUrgentCheck`} className="form-check-input" onClick={() => setUrgent(!urgent)} value={urgent} />
            <label id={`${componentName}-conversationUrgentLabel`} className="form-check-label" for={`${componentName}-conversationUrgentCheck`}>Mark As Urgent</label>
          </div>
        </div>
        <Button disabled={isFormValid()} id={`${componentName}-conversationSendButton`} type="button" className="btn btn-primary" onClick={submitReplyFunc}>
          Send
        </Button>
      </div>
    </div>
  );
};

const ConversationModal = ({ showModal, setModal, conId, messageType }) => {
  const dispatch = useDispatch();

  const onModalCLose = async () => {
    await dispatch(clearFileUploads());

    await setModal(false);
  }

  useEffect(() => {
    (async function fetchMessages() {
      await dispatch(clearFileUploads());
    })();
  }, []);

  return (
    <>
      <Modals
        show={showModal}
        onHide={() => onModalCLose()}
        title="Conversation"
        titleid={`${componentName}-conversationModal`}
        size="xl"
        hideClickButton={true}
        hideFooter={true}
        body={<Conversation conversationId={conId} messageType={messageType} />}
      />
    </>
  );
};

export default ConversationModal;
