import React, { useImperativeHandle, forwardRef, useRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

const componentName = "Workspace";

export default forwardRef( function ReassignBody ({
  data,
  setData,
  teamList,
  onChangeTeam,
  teamSelected,
  asyncsearch,
  onChangeProgramLead,
  isLoading,
  programLeads,
},  ref) {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    clear: () => inputRef.current?.clear()
  }));

  return (
    <>
      <Row>
        <Col className="mb-3">
          <label id={`${componentName}-assignTeamLabel`} className="d-block">Team</label>
          <select id={`${componentName}-assignTeamValue`} name="team" value={teamSelected?.teamId}
            className="form-select"
            required={true}
            onChange={(e) => onChangeTeam(e)}>
            <option id={`${componentName}-assignTeamItem`} value=""></option>
            {teamList.map((el, i) =>
              <option id={`${componentName}-assignTeamItem-${i}`} value={el.teamId}>{el.teamName}</option>
            )}
          </select>
        </Col>
        <Col className="mb-3">
          <label id={`${componentName}-assignProgramLeadLabel`} className="d-block">Program Lead</label>
          <AsyncTypeahead
            id={`${componentName}-assignProgramLeadValue`}
            labelKey="text"
            isLoading={isLoading}
            onSearch={asyncsearch}
            options={programLeads}
            onChange={(e) => onChangeProgramLead(e)}
            placeholder="Search Members by Name"
            ref={inputRef}
          />
        </Col>
      </Row>
      <Row >
        <Col className="mb-3" md={6} id={`${componentName}-reassignReasonNote`}>
          <label id={`${componentName}-reassignReasonNoteLabel`} className="d-block">
            Note
          </label>
          <Form.Control
            id={`${componentName}-reassignReasonNoteValue`}
            type="text"
            onChange={e => setData({ ...data, notes: e.target.value })}
          />
        </Col>
      </Row>
    </>
  )
})
