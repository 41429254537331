import React, { useContext } from "react";
import { AccordionContext, useAccordionButton, Button } from "react-bootstrap";
import { isArrayNonEmpty } from "../quickList/PatientDetails/utils";
export function PlusMinusToggle({ children, eventKey, callback, idx }) {
    const componentName = "PlusMinusToggle";
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    let isCurrentEventKey = activeEventKey === eventKey;
    if (isArrayNonEmpty(activeEventKey)) {
        isCurrentEventKey = activeEventKey.findIndex(e => e === eventKey) >= 0;
    }

    return (
        <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none" onClick={decoratedOnClick}>
            <i id={`${componentName}-plusMinusIcon-${idx}`} className={isCurrentEventKey ? "fa fa-chevron-down me-4" : "fa fa-chevron-right me-4"}></i>
            <span id={`${componentName}-itemTitle-${idx}`}>{children}</span>
        </Button>
    );
}