import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/authContext";

const componentName = "Account";

const Account = () => {
  const auth = useAuth();
  const history = useHistory();
  return (
    <>
      <div id={`${componentName}-account`} className="btn-group">
          <button id={`${componentName}-accountIcon`} type="button" className="btn btn-link dropdown-toggle border-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-regular fa-user border-0 text-dark"></i>
          </button>
          <ul id={`${componentName}-accountDropdown`} className="dropdown-menu dropdown-menu-end">
            <li>
              <button id={`${componentName}-accountSettings`} className="dropdown-item" eventKey="0" onClick={() => history.push({pathname:"/settings", state:{pageTitle:"Account Settings"}})}>
                Account
              </button>
            </li>
            <li>
              <button className="dropdown-item" id={`${componentName}-accountLogout`} type="button" onClick={() => auth.logout()}>
                Sign Out
              </button>
            </li>
            <li><hr className="dropdown-divider" /></li>
            <li id={`${componentName}-loggedInAs`}>
              <h6 className="dropdown-header">You are logged in as {auth.getUsername()}</h6>
            </li>
          </ul>
      </div>
    </>
  );
};

export default Account;
