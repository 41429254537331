import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import { Card } from "../../components/cards";
import { theme } from "../../components/themes/theme1";
import styled, { ThemeProvider } from "styled-components";
import { Container, Col, Form, Spinner } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import axios from "../../utils/apiClient";
import AssessmentReport from "./AssessmentReport";
import ExportSummary from "../quickList/ExportSummary";
import Modals from "../../components/modals";
import { useAuth } from "../../context/authContext";
import { report_Url_Map, reportsWithoutParams } from "../../utils/textValueLists";
import {
  stateFullName,
} from "../../utils/textValueLists";
import cloneDeep from"lodash/cloneDeep";
import {getDisplayValue} from "../../utils/util";

const componentName = "ReportMain";

const DEFAULT_SEARCH_PARAMS = {
  filterParams: [],
  quickListName: "",
  sortParams: [],
  pageNumber: 1,
  pageSize: 20,
};
const baseURLPC = process.env.REACT_APP_REST_API_BASE_URL + "/v1/reports/";

export const ReportMain = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [showPrintSpinner, setShowPrintSpinner] = useState(false);
  const [showExportSpinner, setShowExportSpinner] = useState(false);
  const [searchParameters, setSearchParameters] = useState({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [reportTypes, setReportTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("");

  const auth = useAuth();
  const inputSources = auth.getInputSources().sort();

  useEffect(() => {
    if (inputSources.length > 0) {
      setSelectedClient(inputSources[0]);
      findReportTypesForCLient(getDisplayValue(stateFullName,inputSources[0]));
    }
  }, []);

  const handleReportChange = (e) => {
    const { name, value } = e.target;
    //setReportParam({ ...reportParam, [name]: value });
    setSelectedReportType(value);
    //setSearchParameters({...DEFAULT_SEARCH_PARAMS});
  };

  const findReportTypesForCLient = (id) => {
    const url = process.env.REACT_APP_REST_API_BASE_URL;
    axios
      .get(url + `/v1/reports/client-report-types?clientId=${id}`, {
        headers: {},
      })
      .then((response) => {
        setSelectedReportType("Select Report Type");
        //reportDropDRef.current.value = 'Report Type'
        let arr = response.data;
        arr.splice(0, 0, "Select Report Type");
        setReportTypes(arr);
      })
      .catch((err) => {
        setModalMessage("Failed to get Report Types. Please try again.");
        setReportTypes([]);
      });
  };

  const handleInputSourceChange = (e) => {
    //setSearchParameters({...DEFAULT_SEARCH_PARAMS});
    setSelectedClient(e.target.value);
    findReportTypesForCLient(getDisplayValue(stateFullName,e.target.value));
  };

  const handleFilterChange = (e, type) => {
    let searchParametersClone = {...searchParameters};
    if (checkForValidation(e)) {
      // Remove empty values
      searchParametersClone.filterParams = e.filter(function(e){return e});
      let searchParametersCloneBackend = cloneDeep(searchParametersClone);
      // async
      setSearchParameters(searchParametersClone);
      if (
          selectedClient &&
          searchParametersCloneBackend &&
          searchParametersCloneBackend.filterParams &&
          searchParametersCloneBackend.filterParams.filter(
              (r) => r?.paramName === "clientID"
          ).length === 0
      ) {
        const clientIdObject = {
          comparison: "eq",
          displayValue: [selectedClient],
          paramName: "clientID",
          paramValue: [selectedClient],
        };
        searchParametersCloneBackend.filterParams.push(clientIdObject);
      }
      searchParametersCloneBackend.filterParams.forEach((param)=>{
        if(param.paramName=="Status" && param.paramValue == "BOTH"){
          let paramValues=[];
          paramValues.push("OPEN_CARE_PATHS");
          paramValues.push("CLOSED_CARE_PATHS");
          param.paramValue=paramValues;
        }

      });
      generateReport(searchParametersCloneBackend);
    }
  };

  const checkForValidation = (param) => {
    let errorArray = [];
    const arrOfParamVal = param.map((e) => e.paramName);
    let loclVar = true;

    if(reportsWithoutParams.includes(selectedReportType)) {
      setErrors(errorArray);
      return loclVar;
    }

    if (!param || !param.length) {
      errorArray.push("Please enter all the mandatory fields");
      loclVar = false;
    } else if (param && selectedReportType !== "AAWV Report" && selectedReportType !== "Diagnosis Report" &&!(arrOfParamVal.some((x) => x.includes("Time Range") || x.includes("Status")))) {
      errorArray.push("Time Range is Mandatory");
      loclVar = false;
    } else if (param && param.length) {
      param.forEach((e) => {
        if (e) {
          if (
            selectedReportType === "Activity Report: Logins" || selectedReportType === "Activity Report: Chart Prep Tool" ||
            selectedReportType === "Activity Report: Quality and Risk"
          ) {
            if (!arrOfParamVal.includes("Practice Name")) {
              errorArray.push("Practice Name is Mandatory");
              loclVar = false;
            }
          }
          if (selectedReportType === "Assessments Report") {
            if (!arrOfParamVal.includes("Assessment Template")) {
              errorArray.push("Assessment Template is Mandatory");
              loclVar = false;
            }
          }
          else if (selectedReportType === "Care Program Report") {
            if (!arrOfParamVal.includes("Status")) {
              errorArray.push("Status is Mandatory");
              loclVar = false;
            }
          }
          else if (selectedReportType === "Complex Password Tracking Report") {
            if (!arrOfParamVal.includes("Status")) {
              errorArray.push("Status is Mandatory");
              loclVar = false;
            }
          }
          else if (selectedReportType === "AAWV Report" || selectedReportType === "Diagnosis Report") {
            if (!arrOfParamVal.includes("Year")) {
              errorArray.push("Year is Mandatory");
              loclVar = false;
            }
            if (!arrOfParamVal.includes("Practice Name")) {
              errorArray.push("Practice Name is Mandatory");
              loclVar = false;
            }
          }
          if(new Date(e.paramValue[0]) > new Date(e.paramValue[1])){
            errorArray.push("Start date must be less than or equal to End date.");
            loclVar = false;
          }
          if (e.paramName === "Time Range" && (e.paramValue.length < 2 || e.paramValue.includes(undefined))) {
            errorArray.push("Both Values in Time Range are Mandatory");
            loclVar = false;
          }
        }
      });
    }
    setErrors(errorArray);
    return loclVar;
  };

  const generateReport = (searchParameters) => {
    setShowExportSpinner(true);
    // if (
    //     selectedClient &&
    //     searchParametersClone &&
    //     searchParametersClone.filterParams &&
    //     searchParametersClone.filterParams.filter(
    //         (r) => r.paramName === "clientID"
    //     ).length === 0
    // ) {
    //   const clientIdObject = {
    //     comparison: "eq",
    //     displayValue: [selectedClient],
    //     paramName: "clientID",
    //     paramValue: [selectedClient],
    //   };
    //   searchParametersClone.filterParams.push(clientIdObject);
    // }
    const repType = report_Url_Map[selectedReportType];
    axios
      .post(baseURLPC + repType, searchParameters, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        summary();
      })
      .catch((error) => {
      });
  };

  const summary = () => {
    const SUMMARY_URL =
      process.env.REACT_APP_REST_API_BASE_URL +
      "/v1/patient-card/export-summary";
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch((error) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const reset = () => {};

  return (
    <ThemeProvider theme={theme}>
      <Modals
        show={errors.length}
        onHide={() => setErrors([])}
        title="Error"
        titleid={`${componentName}-errorModal`}
        size="md"
        completeBtn={"Close"}
        completeBtnId={`${componentName}-closeButton`}
        hideFooter={false}
        hideClickButton={true}
        showExtraButton={false}
        body={<>{errors.toString()}</>}
      />
      <Container fluid className="p-4">
        <Row>
          <Col>
            {showExportSpinner && (
              <div>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            )}
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={4} id={`${componentName}-clients`} className="mb-3">
                    <label id={`${componentName}-clientsLabel`} className="d-block">Clients</label>
                    <Form.Control
                      as="select"
                      name="reportType"
                      value={selectedClient}
                      id={`${componentName}-clientsValuse`}
                      className="form-select"
                      onChange={(e) => handleInputSourceChange(e)}
                    >
                      {inputSources.map((r, idx) => (
                        <option id={`${componentName}-clientsItems-${idx}`} key={r} value={r}>
                          {getDisplayValue(stateFullName, r)}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col sm={4} id={`${componentName}-reportType`} className="mb-3">
                    {reportTypes && reportTypes.length > 0 && (
                      <>
                        <label id={`${componentName}-reportTypeLabel`} className="d-block">Report Type</label>
                        <Form.Control
                          as="select"
                          name="reportType"
                          defaultValue="Select Report Type"
                          value={selectedReportType}
                          id={`${componentName}-reportTypeValue`}
                          className="form-select"
                          onChange={(e) => handleReportChange(e)}
                        >
                          {reportTypes.map((r, idx) => (
                            <option id={`${componentName}-reportTypeItems-${idx}`} key={r} value={r}>
                              {r}
                            </option>
                          ))}
                        </Form.Control>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {reportTypes && reportTypes.length > 0 && (
                      <AssessmentReport
                        value={searchParameters.filterParams}
                        reportType={selectedReportType}
                        client={selectedClient}
                        handleFilterChange={handleFilterChange}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modals
          show={modalMessage != null}
          onHide={() => setModalMessage(null)}
          title={modalTitle}
          size={modalSize || "md"}
          hideClickButton={false}
          showExtraButton={false}
          hideFooter={true}
          body={modalMessage}
        />
      </Container>
    </ThemeProvider>
  );
};

export default ReportMain;
