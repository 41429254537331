import React, { useState, Fragment } from "react";

import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { emptyFilterParam, simpleFilterParam } from "../dtos/PatientSearchDTO";
import { paramValue } from "./dropDownLists/sharedDDL";

const componentName = "FreeText";
const Free_Text_TYPE = "FreeText";

const FreeTextStyle = styled(Form.Control)`
  width: ${(props) => (props.cusWidth ? props.cusWidth : "auto")};
  ${(props) =>
    props.activeClosedFilter
      ? "box-shadow: 0 0 0 0.2rem #ccffcc !important;"
      : ""}
`;

const FreeText = ({
  header,
  handleChange,
  selected,
  width = "100%",
  highlightWhenHasValue = true,
  comparisonValue = "eq",
  externalDataMap,
  onLoad,
  showRequired,
  onlyNumbers,
  placeholder = ""
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const text = paramValue(selected)[0];
  const idFragment = header.replaceAll(" ", "_").toUpperCase();

  const onChange = (value) => {
    handleChange(simpleFilterParam(header, comparisonValue, value));
  };

  const onTextChange = (e) => {
    const value = e.target.value ? e.target.value : "";

    if(onlyNumbers){
      const re = /^\d*(\.\d+)?$/;
      if(re.test(value)){
        onChange(value);
      }
    }else{
      onChange(value);
    }
  };

  if (onLoad) {
    onLoad(
      externalDataMap,
      simpleFilterParam(header, comparisonValue, text),
      onChange
    );
  }

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const clear = () => {
    handleChange(emptyFilterParam(header));
  };

  const notFocusedAndHashData = () => {
    return !isFocused && text;
  };

  return (
    <Row lg={1}>
      <Col id={`${componentName}-${idFragment}-filter`} className="mb-3">
        <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
          <span id={`${componentName}-${idFragment}-filterTitleText`}>{header}</span>
          {showRequired && <span className="text-danger">*</span>}
          {notFocusedAndHashData() ? (
            <Fragment>
              &nbsp;
              <i id={`${componentName}-${idFragment}-filterReset`} onClick={clear} className="fas fa-times-circle cursor-p"></i>
            </Fragment>
          ) : (
            ""
          )}
        </label>
        <FreeTextStyle
          id={`${componentName}-${idFragment}-filterValue`}
          type="text"
          value={text ? text : ""}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onTextChange}
          activeClosedFilter={highlightWhenHasValue && notFocusedAndHashData()}
          cusWidth={width}
          placeholder={placeholder}
        />
      </Col>
    </Row>
  );
};

export { FreeText, Free_Text_TYPE };
