import React from 'react';
import { Button, Modal, Spinner } from "react-bootstrap";

export default function AddEditModal({
    show,
    setShow,
    isEditing,
    title,
    handleSave,
    isLoading,
    children
}) {
    return (
        <Modal size="lg" centered show={show} onHide={setShow}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditing ? 'Edit' : 'Add'} {title} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    id='AddEditModalCloseBtn'
                    variant="secondary"
                    onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button
                    id='AddEditModalSaveBtn'
                    variant="primary"
                    disabled={isLoading}
                    onClick={() => handleSave()}>
                    {
                        isLoading ?
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{" "}
                            Loading...
                        </> :
                        isEditing ? 'Edit' : 'Save'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
