import React from "react";
import {formatDateVanilla} from "../../../../utils/util";

const componentName = 'HospitalEventTimelineTitle';

/**
 * Renders the title elements for a hospital event in the timeline, including date and time details.
 *
 * @param {Object} hospitalEvent - The event data for rendering its title.
 * @param {number} idx - The index of the event in the timeline.
 */
const HospitalEventTimelineTitle = ({ hospitalEvent, idx }) => {

  const splitDateTime = (dateTime) => {
    const lastCommaIndex = dateTime.lastIndexOf(',');
    const date = dateTime.substring(0, lastCommaIndex);
    const time = dateTime.substring(lastCommaIndex + 1).trim();

    return [date, time];
  };

  const [date, time] = splitDateTime(formatDateVanilla(hospitalEvent?.dateTime, "MMM dd, yyyy, hh:mm a"));

  return (
    <div>
      <span
        id={`${componentName}-date-${idx}`}
        className="d-block fw-bold"
      >
        {date}
      </span>
      <span
        id={`${componentName}-time-${idx}`}
      >
        {time}
      </span>
    </div>
  );
};

export default HospitalEventTimelineTitle;
