import React from 'react'
import { Col, Row } from "react-bootstrap"
import { formatDate } from '../../../../../../../utils/util'

export default function DataBlock({ customClass, param1, param2, param3, param4, data, title, colNumber }) {

  const isDate = (date) => {
    if (date.endsWith('%') || date.includes('.')) {
      return false;
    }
    let out = (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    return out;
  }

  return (
    <Col>
      <div className="bg-light rounded-3 p-3">
        <h5 className="fs-5 fw-bold">{title}</h5>
        <Row className={`row-cols-${colNumber} g-2`}>
          {data?.map((e, idx) =>
            <Col key={`dataBlock-${title}-${idx}`}>
              <div className={`${customClass} mb-2`}>
                {e?.[param1] && (
                  <label className="d-block">
                    {e?.[param1]}
                  </label>
                )}
                {e?.[param2] && (
                  <div>
                    {e?.[param2]}
                  </div>
                )}
                {e?.[param3] && (
                  <small className="d-block text-secondary">
                    Ordered by: {e?.[param3]}
                  </small>
                )}
                {e?.[param4] && (
                  <small className="d-block text-secondary">
                    {formatDate(e?.[param4])}
                  </small>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Col>
  )
}
