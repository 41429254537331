import React from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import EqualityHealth from "../../img/eqlogo.jpg";
import AuthBg from "../../img/ce-authbg.jpg";
import { Container, Row, Col, Card } from "react-bootstrap";

import ResetPasswordView from "./ResetPassword";
import RecoverPassword from "./RecoverPassword";
import Message from "../admin/broadcastMessenger/message";
import Login from "./Login";

const componentName = "DefaultLogin";

const AuthPage = styled(Container)`
  width: 100%;
  height: 100%;
  background: url(${AuthBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`;

const UnbrandedLogin = () => {
  return (
    <>
      <AuthPage fluid className="p-3 pt-5 p-sm-5">
        <Message className="mb-5" />
        <Row className="justify-content-center justify-content-sm-start">
          <Col>
            <Card className="authbox shadow mx-auto mx-sm-0">
              <Card.Body id={`${componentName}-login`}>
                <div id={`${componentName}-logo`} className="text-center mb-3">
                  <img src={EqualityHealth} id={`${componentName}-EQlogoImage`} height={40} alt="Equality Health Logo" />
                </div>
                <Switch>
                  <Route path="/recover-password" component={RecoverPassword} />
                  <Route path="/reset-password/:token" component={ResetPasswordView} />
                  {/* Login is the last route in this list (and without a path) to make sure it's displayed if no other matching routes */}
                  <Route component={Login} />
                </Switch>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AuthPage>
    </>
  );
};

export default UnbrandedLogin;
