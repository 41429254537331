export class PatientSearchDTO {
  constructor(filterParams, sortParams, pageNumber, pageSize, quickListName, previousTotalSize) {
    this.filterParams = filterParams;
    this.sortParams = sortParams;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.quickListName = quickListName;
    this.previousTotalSize=previousTotalSize;
  }
}

// export class FilterParam {
//     constructor(paramName, paramValue, comparison, displayValue) {
//         this.paramName = paramName;
//         this.paramValue = paramValue;
//         this.comparison = comparison;
//         this.displayValue = displayValue;
//     }
// }

export class SortParam {
  constructor(paramName, direction, priority) {
    this.paramName = paramName;
    this.direction = direction;
    this.priority = priority;
  }
}

export class FilterParam {
  constructor(
    paramName,
    comparison,
    valuesAndDisplayValues,
    valuesSelector,
    displayValuesSelector
  ) {
    let values = [];
    let displayValues = [];
    for (let item of valuesAndDisplayValues) {
      values.push(valuesSelector(item));
      displayValues.push(displayValuesSelector(item));
    }
    this.paramName = paramName;
    this.paramValue = values;
    this.comparison = comparison;
    this.displayValue = displayValues;
  }
}

export const emptyFilterParam = (header) => {
  return new FilterParam(
    header,
    "eq",
    [],
    (e) => e,
    (e) => e
  );
};

export const simpleFilterParam = (header, comparisonValue, value) => {
  return new FilterParam(
    header,
    comparisonValue,
    Array.isArray(value) ? value : [value],
    (e) => e,
    (e) => e
  );
};

export const simpleOneFilterParam = (header, comparisonValue, value, text) => {
  return new FilterParam(
    header,
    comparisonValue,
    [{value, text}],
    (e) => e.value,
    (e) => e.text
  );
};
