import { LOAD_CUSTOM_FILTERED_LIST, UPDATE_CUSTOM_LISTS } from "../types";
import axios from "../../utils/apiClient";
import { setVisibleOrderCustomListModal } from "../modal/orderCustomListActions";
const isEmpty = require("is-empty");

const CUSTOM_QUICKLISTS =
  process.env.REACT_APP_REST_API_BASE_URL + `/v1/quicklist/custom-quicklists`;

export const loadCustomFilteredList = (callback) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .get(CUSTOM_QUICKLISTS, config)
    .then((res) => {
      let items = res.data.map((x) => {
        //let urlPath = "/" + x.quicklistType + "?id=" + x.quicklistId;
        let quicklistType = x.quicklistType === 'requiring_action' ? 'high_risk' : x.quicklistType
        let notes = isEmpty(x.notes) ? '' : encodeURIComponent(x.notes);

        let pathname = quicklistType;
        // the team lead and the hub coordinator enter the same module (same url),
        // we need to always set 'cm_worklist' to avoid creating additional urls.
        // that is, we replace cm_worklist_hub_coordinator with cm_worklist
        if (quicklistType.startsWith("cm_worklist_")) {
          quicklistType = 'my_members';
          pathname = quicklistType;
        }

        if (quicklistType.startsWith("new_referrals_received")) {
          pathname = 'referrals-received';
          quicklistType = 'new_referrals_received';
        }

        return {
          canAccess: (auth) => true,
          type: quicklistType,
          title: x.name,
          //to:urlPath,
          to: {pathname:"/" + pathname, search: "?id=" + x.quicklistId + "&customListName=" + encodeURIComponent(x.name) + "&customListNotes=" + notes, state:{pageTitle:x.name}},
          // this case is unlikely, but we add the quicklistId to avoid selecting two menu items that have the same name
          id: `${x.quicklistId}-${x.name}-${x.quicklistType}`,
        };
      });
      if (!isEmpty(items)) {
        items.push({
          title: "Reorder",
          id: "reorderCustomList",
          canAccess: (auth) => true,
          onClick: () => setVisibleOrderCustomListModal(true),
          useDispatch: true,
          hideChevron: true,
          fontWeight: "510",
          color: "#2274A5",
        });
      }

      dispatch({ type: LOAD_CUSTOM_FILTERED_LIST, payload: items });

      if (callback != null) {
        callback();
      }
    })
    .catch((error) => {
    });
};

export const removeQuicklistById = (id) => async (dispatch) => {
  dispatch({
      type: UPDATE_CUSTOM_LISTS,
      payload: id
  });
  dispatch(loadCustomFilteredList());
}
export const updateQuicklists = () => async (dispatch) => {
  dispatch(loadCustomFilteredList());
}
