import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LAST_QUERY_USAGE_KEY } from "../patientProfile/PatientProfileHeader";
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Button, CardDeck, Card, Form, Dropdown, DropdownButton, ButtonGroup, Badge, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

import ExportSummary from "./ExportSummary";

import ListView from "./components/patientList";
import TableView from "./PatientListView";
import CustomPagination from "../common/CustomPaginaton";

import styled from "styled-components";
import {
  searchPatientMain,
  removePatientsFromList,
  deleteUserWorklist,
  cancelRequest,
} from "../../actions/patientSearch/patientSearchActions";
import { removeQuicklistById } from "../../actions/navigation/navigationActions";
import { useDispatch } from "react-redux";
import {
  PatientSearchDTO,
  FilterParam,
} from "../../dtos/PatientSearchDTO";
import axios from "../../utils/apiClient";
import Modals from "../../components/modals";
import {
  formatDate,
  getPatientProfileLink,
  preventiveCarePatientFilterParams,
  getQuickListId,
  getCustomWorklistName,
  getCustomListName
} from "../../utils/util";
import ShareWorklist from "./ShareWorklist";
import _ from "lodash";
import { AppPropertiesUtil, LocalStorageUtil, SessionStorageUtil } from "../../utils/localStorageUtil";
import { QuickListContext } from "./QuickListContext";
import { useContext } from "react";
import { CE_RESTORE_OBJ } from "../common/MainLayout";
import AssignCareManagerModal from "../caremgmt/AssignCareManagerModal";
import { assignCareManager } from "../../actions/caremgmt/hubWorkCenterAction";

import CustomFilterList from "./CustomFilterList";
import UpdateCustomFilterList from "./../../views/quickList/UpdateCustomFilterList";
import WeekFilter from "./weekFilter";
import ScheduleListView from "./components/ScheduleList";
import { FilterGroupNew } from "../../components/filters/filterGroupNew";
import cloneDeep from "lodash/cloneDeep";

const isEmpty = require("is-empty");

export const PRINT_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print";
export const PRINT_SYNC_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print-sync";
export const EXPORT_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export";
export const SUMMARY_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
export const CUSTOM_LIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-filtered-list/custom-lists";
export const WORKLIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/custom-work-list/work-list";
const LAST_QUERY_SETTINGS_SUFFIX = `-QLQuerySettings`;
const RECENT_QUERY_SETTINGS_KEY = `ce-recent-query-settings-key`;

let pathName = window.location.pathname;
let modifiedPathName = pathName.endsWith("/")
  ? pathName.substring(0, pathName.length - 1)
  : pathName;
export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + modifiedPathName + "?loadLastQuerySettings=true"
);

const StyledListBtnsOS = styled(Button)`
  display: ${(props) => (!props.show ? "none" : `inline-block`)}!important;
`;

const componentName = "QuickListNew";

const RemoveModalBody = ({ patientsSelected }) => {
  const location = useLocation();

  return (
    <div>
      <span>
        You have selected {patientsSelected.length} member
        {patientsSelected.length > 1 && "s"} to remove from your organiazation's
        patient list.
      </span>
      <br />
      <br />
      <span>Would you like to proceed?</span>
      <br />
      {patientsSelected.map((patient) => (
        <a
          className="nav-link pt-0 pb-0"
          href={getPatientProfileLink(patient.id, location, "PreventiveCare")}
        >
          {patient.lastName}, {patient.firstName}
        </a>
      ))}
    </div>
  );
};




const ConfirmWorklistDeletion = ({
  showConfirmation,
  setConfirmation,
  id,
  customListDto,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let modalTitle = "Delete Custom List";
  let modalMessage = "Are you sure you want to delete this custom list?";

  const handleWorklistDeletion = async () => {
    await deleteUserWorklist(id);
    let listId = null;
    if (customListDto) {
      listId = "/" + customListDto.quickList.toLowerCase() + "?id=" + id;
    }
    await dispatch(removeQuicklistById(listId));
    setConfirmation(false);
    history.push("/all_patients/");
  };

  return (
    <Modals
      show={showConfirmation}
      onHide={() => setConfirmation(false)}
      title={modalTitle}
      size="md"
      onCreate={handleWorklistDeletion}
      hideClickButton={false}
      showExtraButton={false}
      hideFooter={false}
      completeBtn="Delete"
      completeBtnId={`${componentName}-deleteButton`}
      body={<div>{modalMessage}</div>}
    />
  );
};

const VIEW = { card: "CARD", list: "LIST" };

const QuickListNew = ({
  titleProp,
  filters,
  pageNumberProp,
  pageSizeProp,
  totalSizeProp,
  defaultFilterParamsProp,
  defaultSortParamsProp,
  exportPatientsUrl,
  quickListType,
  displayExtraRows,
  quickListId,
  cardViewActionObj = {
    fetchData: searchPatientMain,
    copyMap: {
      list: "patientCardDtos",
      totalSize: "totalSize",
      pageSize: "pageSize",
      pageNumber: "pageNumber",
    },
  },
  listViewActionObj,
  startingView = VIEW.card,
  listViewColumns,
  children,
  careManagementActions,
  onCareManagerAssigmentError,
  careManagerPrograms,
  careManagerTeams,
  showCount = false
}) => {
  const qlsSettings = useContext(QuickListContext);
  let quicklistId = getQuickListId();
  let customWorklistName = getCustomWorklistName();
  let customListName = getCustomListName();
  const quickListState = useLocation();
  useEffect(() => {
    let location = window.location.pathname;
    let workingPathName = location.endsWith("/")
      ? location.substring(0, location.length - 1)
      : location;
    if (quicklistId && !isNaN(quicklistId)) {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" +
        workingPathName +
        encodeURIComponent("?loadLastQuerySettings=true&id=" + quicklistId + "&customListName=" + customListName)
      );
    } else {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" + workingPathName + "?loadLastQuerySettings=true"
      );
    }
  }, [quickListType, quicklistId]);
  const [initialParam, setInitialParam] = useState(cloneDeep(defaultFilterParamsProp))



  const [selectedPatients, setSelectedPatients] = useState(new Map());
  const [title, setTitle] = useState(titleProp);
  const [patientsList, setPatientsList] = useState({
    default: [],
    weekView: []
  });
  const [cardViewData, setCardViewData] = useState({});
  const [listViewData, setListViewData] = useState({});
  const [pageNumber, setPageNumber] = useState(pageNumberProp);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [totalSize, setTotalSize] = useState(totalSizeProp);


  const [scheduledPatientSize, setScheduledPatientSize] = useState({});
  const [unScheduledPatientSize, setUnScheduledPatientSize] = useState({});
  const [unableToContactPatientSize, setUnableToContactPatientSize] = useState({})
  const [patientRefusedPatientSize, setPatientRefusedPatientSize] = useState({});
  const [leftMessagePatientSize, setLeftMessagePatientSize] = useState({});
  const [changedPCPPatientSize, setChangedPCPPatientSize] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [listViewLoadingData, setListViewLoadingData] = useState(
    listViewActionObj ? true : false
  );

  const [defaultFilterParams, setDefaultFilterParams] = useState(
    defaultFilterParamsProp
  );
  const [filterParams, setFilterParam] = useState(defaultFilterParamsProp);
  const [customListDto, setCustomListDto] = useState(null);
  const [sortParams, setSortParams] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  let [removablePatients, setRemovablePatients] = useState([]);
  const [filterButtons, setFilterButtons] = useState(false);
  const [modalSize, setModalSize] = useState(null);
  let [disableButtons, setDisableButtons] = useState(true);
  const [modalTitle, setModalTitle] = useState(null);
  const [buttonName, setButtonName] = useState(null);
  const [showPrintSpinner, setShowPrintSpinner] = useState(false);
  const [showExportSpinner, setShowExportSpinner] = useState(false);
  const [view, setView] = useState(startingView);
  const [errorText, setErrorText] = useState();
  const [showAssignCareManagerModal, setShowAssignCareManagerModal] = useState(false);
  const [patientIds, setPatientIds] = useState(null);
  const [allPatientsChecked, setAllPatientsChecked] = useState(false);
  const [showCustomFilterList, setShowCustomFilterList] = useState(false);
  const [showUpdateCustomFilterList, setShowUpdateCustomFilterList] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [canceled, setCanceled] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [weekFilterApplied, setWeekFilterApplied] = useState(false);
  const [filterDates, setFilterDates] = useState([]);
  const [resetDefault, setResetDefault] = useState(false);
  const [scheduleFilterApplied, setScheduleFilterApplied] = useState(false);



  const LAST_QUERY_SETTINGS_KEY = `${quickListType}` + LAST_QUERY_SETTINGS_SUFFIX;
  const QUERY_PARAMS = new URLSearchParams(window.location.search);
  const PRINT_THRESHOLD = AppPropertiesUtil.get("export.download.limit");

  const setDefaultFilterParamHelper = (filterParams) => {
    setDefaultFilterParams(defaultFilterParamsProp);
    setFilterParam(filterParams);
  };

  const handleFilterChange = (filterParams, p) => {
    cancelRequest(setCanceled);
    if (filterParams.length > 0 && filterParams.some(param => param.paramName !== 'schedule')) {
      setWeekFilterApplied(false);
      setFilterDates("");
      let copyParams = cloneDeep(initialParam);
      copyParams[0].paramValue = ["APPOINTMENT_SCHEDULED"];
      for (let i = 0; i < filterParams.length; i++) {
        if (filterParams[i].paramName !== 'schedule') {
          copyParams.push(filterParams[i]);
        }
      }
      filterParams = copyParams;
    }
    else {
      filterParams = cloneDeep(initialParam);
      setResetDefault(true);
    }
    setFilterParam(filterParams);
    handleRemovablePatients([]);
    setSelectedPatients(new Map());
    setPageNumber(1);
    if (!p) {
      p = 1;
    }
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        p,
        pageSize,
        quickListType,
        totalSize
      ),
      title,
      view
    );
  };

  const handleFilterPageSize = (e) => {
    var result = e.target.value.split("-");
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        1,
        result[0],
        quickListType,
        totalSize
      ),
      title,
      view
    );

  };
  const handleColumnSort = (attArr, att, desc) => {
    let sortObj = [];
    if (attArr && attArr.length) {
      attArr.forEach((e) => {
        if (e) {
          sortObj.push({
            paramName: e.attr,
            direction: e.desc,
            priority: e.priority,
          });
        }
      });
      setSortParams(sortObj);
    } else {
      let obj = { paramName: att, direction: desc, priority: 1 };
      sortObj.push(obj);
      setSortParams([obj]);
    }

    query(
      new PatientSearchDTO(
        filterParams,
        sortObj,
        pageNumber,
        pageSize,
        quickListType,
        totalSize
      ),
      title,
      view
    );
  };

  const handlePaginationChange = (pageNumber) => {
    setPageNumber(pageNumber);
    if (weekFilterApplied && !scheduleFilterApplied) {
      handleFilterDatesResult(pageNumber);
    }
    else {
      query(
        new PatientSearchDTO(
          filterParams,
          sortParams,
          pageNumber,
          pageSize,
          quickListType,
          totalSize
        ),
        title,
        view
      );
    }
  };

  let pageObject = {
    pageNumber: 0,
    data: {},
    weekView: []
  };

  const copyToPatientListVariables = (
    data
    , weekView
    , inView
  ) => {
    let copyMap = null;
    if (!inView) {
      inView = view;
    }
    if (inView === VIEW.card || (inView === VIEW.list && !listViewActionObj)) {
      copyMap = cardViewActionObj.copyMap;
    } else if (inView === VIEW.list && listViewActionObj) {
      copyMap = listViewActionObj.copyMap;
    }
    let patientListSectioned = {
      default: [],
      weekView: []
    };
    if (weekFilterApplied && !scheduleFilterApplied) {
      let dateGroup = [];
      let totalRecordsCount;
      let totalSize;
      let pageSize;
      if (!isEmpty(weekView)) {
        for (let i = 0; i < weekView.length; i++) {
          totalSize = (totalSize >= _.get(weekView[i].data, copyMap["totalSize"])) ? totalSize : _.get(weekView[i].data, copyMap["totalSize"]);
          pageSize = (pageSize >= _.get(weekView[i].data, copyMap["pageSize"])) ? pageSize : _.get(weekView[i].data, copyMap["pageSize"]);
          let dateMap = { date: weekView[i].date, data: (_.get(weekView[i].data, copyMap["list"])), totalSize: (_.get(weekView[i].data, copyMap["totalSize"])) };
          totalRecordsCount = totalRecordsCount + _.get(weekView[i].data, copyMap["totalSize"]);
          dateGroup.push(dateMap);
        }
      }
      setTotalSize(totalSize);
      setPageSize(pageSize);
      setPageCount(totalRecordsCount);
      patientListSectioned.weekView = dateGroup;
    } else {
      setTotalSize(_.get(data, copyMap["totalSize"]));
      setPageSize(_.get(data, copyMap["pageSize"]));
      setPageCount(_.get(data, copyMap["totalSize"]));
      patientListSectioned.default = (_.get(data, copyMap["list"]));

    }
    //setPageNumber(_.get(data, copyMap["pageNumber"]));
    setPatientsList(patientListSectioned);
  };

  useEffect(() => {
    setSelectedPatients(new Map());
    if (!isEmpty(filterDates)) {
      setPageNumber(1);
      handleFilterDatesResult(1);
    }
  }, [filterDates]);

  useEffect(() => {
    if (cardViewData && cardViewData.pageNumber == pageNumber) {
      if (!listViewActionObj) {
        setListViewData(cardViewData);
        copyToPatientListVariables(
          cardViewData.data
          , cardViewData.weekView
        );
      } else if (listViewActionObj && view === VIEW.card) {
        copyToPatientListVariables(
          cardViewData.data
          , cardViewData.weekView
        );
      }
      setLoadingData(false);
    }
  }, [cardViewData]);

  useEffect(() => {
    if (listViewActionObj && listViewData && listViewData.pageNumber == pageNumber) {
      if (view === VIEW.list) {
        copyToPatientListVariables(listViewData.data);
      }
      setListViewLoadingData(false);
    }
  }, [listViewData]);

  const handleFilterDatesResult = async (pageNumber) => {
    if ((!isEmpty(filterDates)) && filterDates[0] != 'defaultView') {
      for (const date of filterDates) {
        for (let i = 0; i < filterParams.length; i++) {
          if (filterParams[i].paramName === 'schedule') {
            filterParams[i].paramValue = ["APPOINTMENT_SCHEDULED", date];
          }
        }
        await query(new PatientSearchDTO(filterParams,
          sortParams,
          pageNumber,
          5,
          quickListType,
          totalSize
        ),
          title,
          view,
          date
        );
      }
      setCardViewData(pageObject);
    }
    else {
      fetchData(initialParam, quickListType, titleProp, VIEW.card, true);
    }
  };
  const query = async (patientSearchDTO, updatedTitle, updatedView, date) => {
    setResetDefault(false); //clear flag
    setLoadingData(true);
    setPageNumber(patientSearchDTO.pageNumber);
    SessionStorageUtil.setObj(RECENT_QUERY_SETTINGS_KEY, LAST_QUERY_SETTINGS_KEY);
    SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
      patientSearchDTO: patientSearchDTO,
      view: updatedView,
      title: updatedTitle,
    });
    if (listViewActionObj) {
      setListViewLoadingData(true);
    }
    await cardViewActionObj
      .fetchData(patientSearchDTO)
      .then((res) => {
        if (res.data) {
          if ((!isEmpty(filterDates)) && filterDates[0] != 'defaultView' && patientSearchDTO.filterParams.length == 1) {
            const groupObject = {
              date: date,
              data: res.data
            };
            pageObject.pageNumber = patientSearchDTO.pageNumber;
            pageObject.weekView.push(groupObject);
          } else {
            pageObject = {
              pageNumber: patientSearchDTO.pageNumber,
              data: res.data
            };
            setCardViewData(pageObject);
          }
        }
        setCanceled(false)
      })
      .catch((err) => {
        if (err?.message !== 'cancel by user') {
          setCanceled(false)
        }
        setErrorText("Error");
        setLoadingData(false);
      });

    if (listViewActionObj) {
      listViewActionObj
        .fetchData(patientSearchDTO)
        .then((res) => {
          if (res.data) {
            const pageObject = {
              pageNumber: patientSearchDTO.pageNumber,
              data: res.data
            };
            setListViewData(pageObject);
          }
          setCanceled(false)
        })
        .catch((err) => {
          if (err?.message !== 'cancel by user') {
            setCanceled(false)
          }
          setErrorText("Error");
          setListViewLoadingData(false);
        });
    }
  };
  const fetchData = async (filterParams, quicklistName, title, view, reloadTodayFromSchedule) => {
    let dto = new PatientSearchDTO(
      filterParams,
      sortParams,
      pageNumber,
      pageSize,
      quicklistName
    )
    if (reloadTodayFromSchedule) {
      dto = new PatientSearchDTO(
        filterParams,
        sortParams,
        1,
        20,
        quicklistName
      )
    }

    query(
      dto,
      title,
      view
    );
  };

  useEffect(() => {
    //restore from last timeout override
    const restoreObject = SessionStorageUtil.getObj(CE_RESTORE_OBJ);
    let quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    let useLastQuery = SessionStorageUtil.getObj(LAST_QUERY_USAGE_KEY);
    SessionStorageUtil.setObj(LAST_QUERY_USAGE_KEY, null);
    if (restoreObject) {
      if (restoreObject.recentKey === LAST_QUERY_SETTINGS_KEY) {
        quicklistObj = restoreObject.quickListObj;
        SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, quicklistObj);
      }
      SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);
    }
    if ((restoreObject || useLastQuery) && quicklistObj && quicklistObj.patientSearchDTO) {
      if (defaultSortParamsProp) {
        quicklistObj.patientSearchDTO.sortParams = defaultSortParamsProp;
      }
      //load from back button
      setTitle(quicklistObj.title);
      setView(quicklistObj.view);
      setFilterButtons(quicklistId && !isNaN(quicklistId));
      setDefaultFilterParamHelper(quicklistObj.patientSearchDTO.filterParams);
      setSortParams(quicklistObj.patientSearchDTO.sortParams);
      query(
        quicklistObj.patientSearchDTO,
        quicklistObj.title,
        quicklistObj.view
      );
    } else if (quicklistId && !isNaN(quicklistId)) {
      //load from custom list
      axios
        .get(CUSTOM_LIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setFilterButtons(true);
          setDefaultFilterParamHelper(
            response.data.patientRequestDTO.filterParams
          );
          setTitle(response.data.name);
          fetchData(
            response.data.patientRequestDTO.filterParams,
            response.data.quickList,
            response.data.name,
            view,
          );
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalSize("lg");
          if (error.response != null) {
            setModalMessage(error.response.data.message);
          } else {
            setModalMessage(error.message);
          }
          setLoadingData(false);
        });
    } else if (quicklistId && quicklistId.includes("customWorklist")) {
      //load member list
      axios
        .get(WORKLIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setLoadingData(false);
        });

      setFilterButtons(true);
      let valuesAndDisplayValues = [];
      valuesAndDisplayValues.push({
        text: customWorklistName,
        value: quicklistId,
      });

      let filterParam = new FilterParam(
        "Member Worklist",
        "eq",
        valuesAndDisplayValues,
        (x) => x.value,
        (x) => x.text
      );
      const tempFilterParams = Object.assign([], filterParams);
      tempFilterParams.push(filterParam);
      setDefaultFilterParamHelper(tempFilterParams);
      setTitle(customWorklistName);
      fetchData(tempFilterParams, "ALL_PATIENTS", customWorklistName, view);
      setFilterParam(tempFilterParams);
    } else if (quicklistId && quicklistId.includes("data")) {
      let patientFilters = preventiveCarePatientFilterParams;
      axios
        .get(
          process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/patients/overview/summary?patientId=${quicklistId}`,
          {
            headers: {},
          }
        )
        .then((response) => {
          patientFilters[0].displayValue[0] = response.data.lastName;
          patientFilters[0].displayValue[1] = response.data.firstName;
          patientFilters[0].paramValue[0] =
            response.data.lastName + ", " + response.data.firstName;
          patientFilters[1].displayValue[0] =
            "On " + formatDate(response.data.dateOfBirth);
          patientFilters[1].paramValue[0] = formatDate(
            response.data.dateOfBirth
          );

          setFilterButtons(true);
          setDefaultFilterParamHelper(patientFilters);
          setTitle(titleProp);
          fetchData(patientFilters, quickListType, titleProp, view);
        })
        .catch((error) => {
          setLoadingData(false);
        });
    } else {
      setTitle(titleProp);
      setDefaultFilterParamHelper(defaultFilterParamsProp);
      setFilterButtons(false);

      if (defaultSortParamsProp) {
        setSortParams(defaultSortParamsProp);
        query(
          new PatientSearchDTO(
            defaultFilterParamsProp,
            defaultSortParamsProp,
            pageNumber,
            pageSize,
            quickListType
          ),
          titleProp,
          view,
        );
      } else {
        fetchData(defaultFilterParamsProp, quickListType, titleProp, view);
      }
    }
  }, [quicklistId]);

  const reloadPatientView = () => {
    fetchData(filterParams, quickListType, titleProp, view);
  };

  const selectPatient = (patient, selected) => {
    const selectedPatientsCopy = new Map(selectedPatients);
    if (selected) {
      selectedPatientsCopy.set(patient.id, patient);
    } else {
      selectedPatientsCopy.delete(patient.id);
    }
    handleRemovablePatients(selectedPatientsCopy);
    setSelectedPatients(selectedPatientsCopy);
  };
  const selectPatients = (patients, selected) => {
    const selectedPatientsCopy = new Map(selectedPatients);
    if (selected) {
      for (let i = 0; i < patients.length; i++) {
        selectedPatientsCopy.set(patients[i].id, patients[i]);
      }
    } else {
      for (let i = 0; i < patients.length; i++) {
        selectedPatientsCopy.delete(patients[i].id);
      }
    };
    handleRemovablePatients(selectedPatientsCopy);
    setSelectedPatients(selectedPatientsCopy);
  };
  const handleRemovablePatients = (selectedPatients) => {
    if (selectedPatients.size > 0) {
      setDisableButtons(false);
      checkRemovablePatients(selectedPatients);
    } else {
      setDisableButtons(true);
      setRemovablePatients([]);
    }
  };


  const [selectAllLabel, setSelectAllLabel] = useState("Select All");

  const isPatientSelected = (patient) => {
    return selectedPatients.has(patient.id);
  };

  const selectOrUnSelectAll = (selected) => {
    const selectedPatients = new Map();
    setAllPatientsChecked(selected)

    if (selected) {
      patientsList.default.forEach((x) => {
        selectedPatients.set(x.id, x);
      });
    }
    handleRemovablePatients(selectedPatients);
    setSelectedPatients(selectedPatients);

    if (selectAllLabel) {
      setSelectAllLabel(
        selectAllLabel === "Select All" ? "Deselect All" : "Select All"
      );
    }
  };

  const checkRemovablePatients = async (selectedPatients) => {
    let globalPass = true;
    await selectedPatients.forEach((patient, id, map) => {
      if (!patient.global) {
        globalPass = false;
      }
    });
    if (globalPass) {
      setRemovablePatients(["true"]);
    } else {
      setRemovablePatients([]);
    }
  };

  const removePatients = async () => {
    let patientIds = [];
    await selectedPatients.forEach((patient, id, map) => {
      patientIds.push(id);
    });
    await removePatientsFromList(patientIds);
    setRemoveModal(false);
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        pageNumber,
        pageSize,
        quickListType
      ),
      title,
      view
    );
  };

  const closeEvidenceModal = () => {
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        pageNumber,
        pageSize,
        quickListType
      ),
      title,
      view
    );
  };

  const updateParent = (updatedRef, sourceType) => {
    let newState = patientsList.default;
    if (sourceType && sourceType === "ManageEvidence") {
    }
    else if (sourceType && sourceType === "SubmitRecentVisit") {
      newState.map((pat, patI) => {
        if (pat.id === updatedRef.patientId) {
          newState[patI].nextVisit = updatedRef.nextVisit;
          newState[patI].recentVisitStatus = updatedRef.recentVisitStatus;
          newState[patI].nextVisitInfo = updatedRef.nextVisitInfoDesc;
          newState[patI].recentVisit = updatedRef.recentVisit;
          newState[patI].recentVisitInfo = updatedRef.recentVisitInfoDesc;
          //newState[patI].patientGapStatus = updatedRef.patientGapStatus;
          let patientListSectioned = {
            default: [],
            scheduledPatients: [],
            unScheduledPatients: [],
            unableToContact: [],
            patientRefused: [],
            leftMessage: [],
            changedPCP: []
          };
          patientListSectioned.default = [...newState];
          setPatientsList(patientListSectioned);
        }
      });
    } else if (updatedRef) {
      let patIndex = null;
      let refIndex = null;
      newState.map((pat, patI) => {
        if (pat.id === updatedRef.patient.id) {
          patIndex = patI;
          pat.referralDtoList.map((ref, refI) => {
            if (ref.id === updatedRef.referralId) {
              refIndex = refI;
            }
          });
        }
      });
      if (
        newState[patIndex].referralDtoList[refIndex].status !==
        updatedRef.status
      ) {
        newState[patIndex].referralDtoList[refIndex].status = updatedRef.status;
        let patientListSectioned = {
          default: [],
          scheduledPatients: [],
          unScheduledPatients: [],
          unableToContact: [],
          patientRefused: [],
          leftMessage: [],
          changedPCP: []
        };
        patientListSectioned.default = [...newState];
        setPatientsList(patientListSectioned);
      }
    }
  };


  const onShowAssignCareManagerModal = (patientIds) => {
    setShowAssignCareManagerModal(true)
    setPatientIds(patientIds)
  }

  const bulkCareManagerAssignment = () => {
    if (!isEmpty(selectedPatients)) {
      setSelectedName('')
      setShowAssignCareManagerModal(true)
      let patientIds = Array.from(selectedPatients).map(([patientId]) => patientId)
      setPatientIds(patientIds)
    }
  }

  const assignToCareManager = (assignment) => {
    const payload = patientIds.map(patientId => {
      return { ...assignment, patientId, origin: 'CARE_EMPOWER_REFERRALS' }
    });
    assignCareManager(payload)
      .then(() => {
        setSelectedPatients(new Map())
        setShowAssignCareManagerModal(false)
        setAllPatientsChecked(false)
        reloadPatientView()
      })
      .catch(error => {
        onCareManagerAssigmentError(error)
        setShowAssignCareManagerModal(false)
      })
  }

  const getCurrentFilters = () => {
    let filterView = [];
    let filterValue;
    for (let i = 0; i < filters.length; i++) {
      filterValue = "";
      for (let j = 0; j < filterParams.length; j++) {
        if (filters[i].getKey() === filterParams[j].paramName) {
          if (filterParams[j].paramValue.length > 1) {
            filterValue = "Multiple";
          }
          else {
            if (
                filterParams[j].displayValue != null &&
                (filterParams[j].displayValue[0] != null ||
                    filterParams[j].displayValue[0] !== undefined)
            ) {
              filterValue = getFilterValue(filterParams[j].displayValue[0]);
            } else {
              filterValue = getFilterValue(filterParams[j].paramValue[0]);
            }
          }
          break;
        }
      }
      if (filterValue === "") {
        filterValue = "All";
      }
      filterView.push({
        filterName: filters[i].getHeader(),
        filterValue: filterValue,
      });
    }
    return filterView;
  };
  const getFilterValue = (values) => {
    let filterValue = "";
    if (Array.isArray(values)) {
      for (let z = 0; z < values.length; z++) {
        if (filterValue !== "") {
          filterValue = filterValue + ", ";
        }
        filterValue = filterValue + values[z];
      }
    } else {
      filterValue = values;
    }
    return filterValue;
  };

  const print = (alreadyAddedPatients) => {
    setShowPrintSpinner(true);
    const filterData = new PatientSearchDTO(
      filterParams,
      sortParams,
      pageNumber,
      pageSize,
      quickListType
    );

    let criteria = {};
    criteria.patientRequest = filterData;
    criteria.selectedItems = []
    if (selectedPatients.size) {
      criteria.selectedItems = Array.from(
        selectedPatients,
        ([key, value]) => value.id
      );
    }
    else if (alreadyAddedPatients && alreadyAddedPatients.length && !selectedPatients.size) {
      criteria.selectedItems = alreadyAddedPatients
    }

    else {
      const arr = []
      patientsList.default.forEach((x) => {
        arr.push(x.id);
      });
      criteria.selectedItems = arr
    }
    criteria.quickList = quickListType;
    axios
      .post(PRINT_URL, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        summary();
      })
      .catch((error) => {
        setShowPrintSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const summary = () => {
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch((error) => {
        setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
        setLoadingData(false);
      });
  };


  return (
    <Container fluid className="py-3">
      <Helmet>
        <title>{title} | CareEmpower</title>
      </Helmet>
      <Modals
        show={removeModal}
        onHide={() => setRemoveModal(false)}
        title="Remove Member(s) from organization's patient list"
        size="lg"
        onCreate={removePatients}
        hideClickButton={false}
        showExtraButton={false}
        hideFooter={false}
        completeBtn="Save"
        completeBtnId={`${componentName}-saveButton`}
        body={
          <RemoveModalBody
            patientsSelected={Array.from(
              selectedPatients,
              ([key, value]) => value
            )}
          />
        }
      />
      {quicklistId && !isNaN(quicklistId) ?
        <ShareWorklist
          shareModal={shareModal}
          setShareModal={setShareModal}
          setModalMessage={setModalMessage}
          setModalSize={setModalSize}
          setModalTitle={setModalTitle}
          customListDto={customListDto}
          setCustomListDto={setCustomListDto}
          quicklistId={quicklistId}
          customWorklistName={customWorklistName}
        /> : ''}
      <ConfirmWorklistDeletion
          showConfirmation={showDeleteConfirmation}
          setConfirmation={setShowDeleteConfirmation}
          id={quicklistId}
          customListDto={customListDto}
      />
      <Row className="mb-3">
        <Col>
          <Row>
            <Col className="d-flex justify-content-between">
              <div>
                <FilterGroupNew
                  filters={filters}
                  handleChange={handleFilterChange}
                  defaultFilterParams={defaultFilterParams}
                  filterParams={filterParams}
                  customWorklistName={customWorklistName}
                  additionalValidation={
                    qlsSettings.additionalFilterValidation
                  }
                  setScheduleFilterApplied={setScheduleFilterApplied}

                />
              </div>
              <div className="">
                {!scheduleFilterApplied && (<WeekFilter
                  setFilterDates={setFilterDates}
                  filterDates={filterDates}
                  setWeekFilterApplied={setWeekFilterApplied}
                  loading={loadingData || listViewLoadingData || canceled}
                  resetDefault={resetDefault}
                />)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={view === VIEW.card ? 5 : 5} md={4} className="d-flex align-items-end">
              {!isEmpty(totalSize) && !weekFilterApplied && !scheduleFilterApplied && (
                <div className="d-flex flex-column">
                  <small id={`${componentName}-MonthandDay`}>{new Date().toLocaleDateString(undefined, { month: 'long', day: 'numeric' })}</small>
                  <h5 className="mb-0">
                    <span id={`${componentName}-DayoftheWeek`} className="me-2">Today</span>
                    <Badge pill id={`${componentName}-PatientCount`} className="bg-primary">
                      {totalSize.toLocaleString()}
                    </Badge>
                  </h5>
                </div>
              )}
              {scheduleFilterApplied && (
                <div className="d-flex flex-column">
                  <h5 className="mb-0 d-flex align-items-center">
                    <span id={`${componentName}-filteredResults`} className="me-2">Filtered Results</span>
                    <Badge pill id={`${componentName}-PatientCount`} className="bg-primary">
                      {totalSize.toLocaleString()}
                    </Badge>
                  </h5>
                </div>
              )}
              {quickListId && quickListId === "REFERRALS_CARE_MANAGEMENT" && (
                <DropdownButton
                  id={`${componentName}-actionsBtn`}
                  disabled={selectedPatients.size < 2}
                  size="sm"
                  variant="outline-secondary"
                  className="d-inline-block bg-white align-top me-2"
                  title="Bulk Actions"
                >
                  {careManagementActions && careManagementActions.map((action, idx) => (
                    <Dropdown.Item id={`${componentName}-actionsBtnItems-${idx}`} key={`${componentName}-${action.value}-${idx}-key`}
                      onClick={() => bulkCareManagerAssignment()}>{action.text}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}
              {children}
              {(loadingData || listViewLoadingData || canceled) && (
                <Spinner
                  className="align-middle mb-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Col>
            <Col className="d-flex align-items-end justify-content-start justify-content-sm-end mt-sm-0">
              {(showExportSpinner || showPrintSpinner) && (
                <Spinner
                  className="align-middle mb-4"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {(!weekFilterApplied) && (<div className="d-inline-block mx-2 align-top">
                <select className="form-select" id={`${componentName}-ResultsPerPage`} onChange={(e) => handleFilterPageSize(e)}>
                  <option value="20-Results" selected disabled hidden>
                    20 Results Per Page
                  </option>
                  <option value="50-Results">50 Results Per Page</option>
                  <option value="20-Results">20 Results Per Page</option>
                </select>
              </div>)}
              {!weekFilterApplied && (
                <Button onClick={() => print()}
                  disabled={loadingData || listViewLoadingData || canceled} variant="secondary" id={`${componentName}-patientChartPrepTool`} className="border">
                  Print Chart Prep Tool
                </Button>)}
              {((!isEmpty(patientsList.default)) || (!isEmpty(patientsList.weekView) && (totalSize != 0)))
                && (<div className="d-inline-block ms-0 ms-sm-2">
                  <CustomPagination
                    paginateButClicked={handlePaginationChange}
                    MAX_PAGE={Math.ceil(totalSize / pageSize)}
                    pageNumber={Math.ceil(pageNumber)}
                  />
                </div>)}
              <StyledListBtnsOS
                variant="secondary"
                size="sm"
                className="align-top border me-2"
                id={`${componentName}-Delete`}
                show={filterButtons}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Delete
              </StyledListBtnsOS>
              <StyledListBtnsOS
                  variant="secondary"
                  size="sm"
                  className="align-top border me-2"
                  id={`${componentName}-Update`}
                  show={filterButtons}
                  onClick={() => setShowUpdateCustomFilterList(true)}
              >
                Update
              </StyledListBtnsOS>
            </Col>
          </Row>
          {!isEmpty(patientsList.default) && showCount && (
            <Row className="mx-2">
              <Col className="text-primary">
                {totalSize} Members Listed
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {patientsList.default && patientsList?.default?.length > 0 && patientsList.default[0] !== "Empty" ? (
        view === VIEW.card ? (
          <ListView
            updatePatients={setPatientsList}
            patientList={patientsList}
            quickListType={quickListType}
            workListStatusSize={[scheduledPatientSize, unScheduledPatientSize, unableToContactPatientSize, patientRefusedPatientSize, leftMessagePatientSize, changedPCPPatientSize]}
            loadingData={loadingData}
            listViewLoadingData={listViewLoadingData}
            handleChangeCheckBox={selectPatient}
            handleAllPatientsCheckbox={selectPatients}
            isPatientSelected={isPatientSelected}
            quicklistId={quicklistId}
            returnToMyPatientsUrl={RETURN_TO_MY_PATIENTS_URL}
            updateParent={updateParent}
            closeEvidenceModal={closeEvidenceModal}
            reloadPatientView={reloadPatientView}
            onShowAssignCareManagerModal={onShowAssignCareManagerModal}
            careManagementActions={careManagementActions}
            titleProp={titleProp}
            handleFilterChange={handleFilterChange}
            filterParams={filterParams}
            pageNumber={pageNumber}
          />
        ) : (
          <TableView
            patientList={patientsList}
            handleChangeCheckBox={selectPatient}
            sortCol={handleColumnSort}
            selectOrUnSelectAll={selectOrUnSelectAll}
            isPatientSelected={isPatientSelected}
            quickListType={quickListType}
            displayExtraRows={displayExtraRows}
            columnList={listViewColumns}
            updateParent={updateParent}
            closeEvidenceModal={closeEvidenceModal}
            reloadPatientView={reloadPatientView}
            onShowAssignCareManagerModal={onShowAssignCareManagerModal}
            careManagementActions={careManagementActions}
            allPatientsChecked={allPatientsChecked}
            setSelectedName={setSelectedName}
          />
        )
      ) : (weekFilterApplied && !isEmpty(patientsList.weekView) ? (
        <ScheduleListView
          updatePatients={setPatientsList}
          patientList={patientsList.weekView}
          quickListType={quickListType}
          // workListStatusSize={[scheduledPatientSize, unScheduledPatientSize, unableToContactPatientSize, patientRefusedPatientSize, leftMessagePatientSize, changedPCPPatientSize]}
          loadingData={loadingData}
          listViewLoadingData={listViewLoadingData}
          handleChangeCheckBox={selectPatient}
          handleAllPatientsCheckbox={selectPatients}
          isPatientSelected={isPatientSelected}
          quicklistId={quicklistId}
          returnToMyPatientsUrl={RETURN_TO_MY_PATIENTS_URL}
          updateParent={updateParent}
          closeEvidenceModal={closeEvidenceModal}
          reloadPatientView={reloadPatientView}
          onShowAssignCareManagerModal={onShowAssignCareManagerModal}
          careManagementActions={careManagementActions}
          titleProp={titleProp}
          handleFilterChange={handleFilterChange}
          filterParams={filterParams}
          pageNumber={pageNumber}
          print={print}
          handleFilterDatesResult={handleFilterDatesResult}
        />)
        : (
          !loadingData &&
          !listViewLoadingData && (
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Text id={`${componentName}-NoPatients`}>
                      {!canceled ? 'No Patients Found' : 'Loading...'}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )
        )
      )
      }
      <CustomFilterList
        filters={getCurrentFilters()}
        showCustomFilterList={showCustomFilterList}
        setShowCustomFilterList={setShowCustomFilterList}
        filterParams={filterParams}
        sortParams={sortParams}
        pageNumber={pageNumber}
        pageSize={pageSize}
        quickList={quickListType}
        setModalMessage={setModalMessage}
        setModalSize={setModalSize}
        setModalTitle={setModalTitle}
      />
      <UpdateCustomFilterList
          id={quicklistId}
          filters={getCurrentFilters()}
          showUpdateCustomFilterList={showUpdateCustomFilterList}
          setShowUpdateCustomFilterList={setShowUpdateCustomFilterList}
          filterParams={filterParams}
          setModalMessage={setModalMessage}
          setModalSize={setModalSize}
          setModalTitle={setModalTitle}
      />
      <Modals
        show={modalMessage != null}
        onHide={() => setModalMessage(null)}
        title={modalTitle}
        size={modalSize || "md"}
        hideClickButton={true}
        showExtraButton={false}
        hideFooter={false}
        closeBtnId={`${componentName}-closeButton`}
        body={modalMessage}
      />
      <AssignCareManagerModal
        show={showAssignCareManagerModal}
        teams={careManagerTeams}
        programs={careManagerPrograms}
        onAssign={assignToCareManager}
        onClose={() => setShowAssignCareManagerModal(false)}
        onError={onCareManagerAssigmentError}
        selectedName={selectedName}
      />
    </Container>
  );
};

export { QuickListNew, VIEW, LAST_QUERY_SETTINGS_SUFFIX, RECENT_QUERY_SETTINGS_KEY };
