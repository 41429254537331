import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../actions/errorActions";

const componentName = "ErrorToast";

const ErrorToast = ({ errorParam, closeToast =()=>{}, multipleErrors, resetCurrentErrors }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const errors = useSelector((state) => state.errors);

  useEffect(() => {
    if ((errors && errors.message) || errorParam) {
      setShow(true);
    } else if (multipleErrors && multipleErrors.length) {
      setShow(true);
    }
  }, [multipleErrors, errorParam]);

  const hideIt = async () => {
    await dispatch(resetErrors());
    await setShow(false);
    if(resetCurrentErrors){
       await resetCurrentErrors();
    }
    closeToast()
  };

  return (
    <Toast id={`${componentName}-errorToast`} onClose={() => hideIt()} show={show}>
      <div className="toast-header">
        <strong id={`${componentName}-errorHeader`} className="me-auto fw-bold text-danger">
          <i className="fas fa-exclamation-triangle"></i> Error
        </strong>
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <Toast.Body id={`${componentName}-errorText`}>
        {multipleErrors && multipleErrors.length && multipleErrors.length >0 
          ? multipleErrors.map((err) => <>{err}</>)
          : errorParam || errors.message}
      </Toast.Body>
    </Toast>
  );
};

export default ErrorToast;
