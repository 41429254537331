import React, {useContext, createContext, useReducer} from 'react';
import {useDispatch} from "react-redux";

import {actions, initialState} from './actions';
import {reducer} from './Referrals.reducer';
import {getMyMembersReferrals} from './api';
import {getGetters} from './getters';
import {getToggles} from './toggles';


const ReferralsContext = createContext();

const ReferralsProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const callbacks = {
    setReferral: payload => dispatch({type: actions.SET_REFERRAL, payload: payload}),
    setQuery: payload => dispatch({type: actions.SET_QUERY, payload: payload}),
    getMyMembersReferrals: async (patientId, filters = [], sorts= []) => {
      toggles.setReferralsList([])
      toggles.setTotalSize(0)
      return await getMyMembersReferrals(patientId, filters, sorts)
      .then((referralResponse) => {
        toggles.setReferralsList(referralResponse?.data?.referralDtos)
        toggles.setTotalSize(referralResponse?.data?.totalSize)
      })
    }
  }

  const value = {
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <ReferralsContext.Provider value={value}>
      {children}
    </ReferralsContext.Provider>
  )
};
const useReferralsContext = () => useContext(ReferralsContext);

export {
  ReferralsProvider,
  useReferralsContext,
}