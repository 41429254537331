import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import { Alert } from "../../components/alerts";

import ehnLogoImage from "../../img/equality-health-network.png";
import backgroundImage from "../../img/ehn_login.jpg";

import {
  LoginCard,
  Header,
  LoginButton,
  LoginForm,
  ForgotPasswordLink,
  TermsAndConditionLink,
} from "./loginStyles";
import ResetPasswordView from "./ResetPassword";
import RecoverPassword from "./RecoverPassword";
import Message from "../admin/broadcastMessenger/message";
import { SESSION_HAS_EXPIRED } from "../../context/authContext";

const StyledEHNLogin = styled.div`
  background: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
`;

const LoginPadding = styled.div`
  padding: 5% 0;
`;

const EHNLoginCard = styled(LoginCard)`
  margin: auto 100px;
`;

const Logo = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 17px;

  img {
    width: 300px;
    margin-right: 16px;
  }
`;

const TermsOfUsePdf = process.env.REACT_APP_REST_API_BASE_URL + "/system_files/Terms_of_Use.pdf";
const PrivacyPolicyPdf = "/docs/Privacy_Policy.pdf";
const UserAccessAgreement = "/docs/CareEmpower_User_Access_Agreement.pdf";

const EHNLogin = ({ onLogin, error, sessionExpired }) => {
  return (
    <StyledEHNLogin>
      <Message />
      <LoginPadding>
        <EHNLoginCard>
          <EHNLoginCard.Body>
            <Logo>
              <img src={ehnLogoImage} alt="Equality Health Network"></img>
            </Logo>
            <Switch>
              <Route path="/recover-password">
                <RecoverPassword buttonBackgroundColor="#f7931d"></RecoverPassword>
              </Route>
              <Route
                path="/reset-password/:token"
                component={ResetPasswordView}
              />
              {/* Login is the last route in this list (and without a path) to make sure it's displayed if no other matching routes */}
              <Route
                component={() => (
                  <Login
                    onLogin={onLogin}
                    error={error}
                    sessionExpired={sessionExpired}
                  />
                )}
              />
            </Switch>
          </EHNLoginCard.Body>
        </EHNLoginCard>
      </LoginPadding>
    </StyledEHNLogin>
  );
};

const Login = ({ onLogin, error, sessionExpired }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const sessionHasExpired = sessionStorage.getItem(SESSION_HAS_EXPIRED);
  const isEmpty = require("is-empty");

  useEffect(() => {
    if (!isEmpty(error)) {
      let errorMessage;
      if (error && typeof error == "string") {
        errorMessage = error;
      } else if (error && error.response && error.response.data) {
        if (error.response.data.error_description) {
          errorMessage = error.response.data.error_description;
        } else if (error.response.data.message) {
          errorMessage = error.response.data.error_description;
        }
      } else if (error && error.message && !errorMessage) {
        errorMessage = error.message;
      }
      setErrorToDisplay(errorMessage);
    } else {
      setErrorToDisplay("");
    }
  }, [error]);

  return (
    <LoginForm
      onSubmit={(e) => {
        e.preventDefault();
        onLogin(username, password);
      }}
    >
      <Header>Sign In</Header>
      {sessionHasExpired && (
        <Alert variant="warning">You were logged out due to inactivity</Alert>
      )}
      {error &&
        error.response &&
        error.response.data &&
        error.response.data.error_description && (
          <Alert variant="danger">
            {error.response.data.error_description}
          </Alert>
        )}
      <LoginForm.Group>
        <LoginForm.Control
          type="text"
          autoComplete="username"
          required
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </LoginForm.Group>
      <LoginForm.Group>
        <LoginForm.Control
          type="password"
          autoComplete="current-password"
          required
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </LoginForm.Group>
      <TermsAndConditionLink>
        By using CareEmpower, I agree to the <br/>
        <a href={TermsOfUsePdf} target="blank">Terms of Use</a>
        &#44;
        <a href={PrivacyPolicyPdf} target="blank"> Privacy Policy </a>
        and <br/>
        <a href={UserAccessAgreement} target="blank"> User Access Agreement </a>
      </TermsAndConditionLink>
      <ForgotPasswordLink to="/recover-password">
        Forgot password?
      </ForgotPasswordLink>
      <LoginButton type="submit" backgroundColor="#f7931d">
        Log In
      </LoginButton>
    </LoginForm>
  );
};

export default EHNLogin;
