import React from "react";
import {Col, Dropdown} from "react-bootstrap";
import {SortParam} from "../../../../dtos/PatientSearchDTO";

const componentName = "ReferralsTabs-SortMenu"

const SortMenu = ({
  items,
  setSortParams,
}) => {
  const onSortItemClick = (menuItemSortClicked) => {
    onPatientActionSortClicked(menuItemSortClicked.text);
  }

  const onPatientActionSortClicked = (menuItemSortClicked) => {
    let sortParams = [];

    const submittedSort = new SortParam("statusupdated", "DESC", 1);

    switch (menuItemSortClicked) {
      case "Referral Type (A - Z)":
        setSortParams(sortParams = [new SortParam("type", "ASC", 1), submittedSort]);
        break;
      case "Referral Type (Z - A)":
        setSortParams(sortParams = [new SortParam("type", "DESC", 1), submittedSort]);
        break;
      case "Referral Status (A - Z)":
        setSortParams(sortParams = [new SortParam("status", "ASC", 1), submittedSort]);
        break;
      case "Referral Status (Z - A)":
        setSortParams(sortParams = [new SortParam("status", "DESC", 1), submittedSort]);
        break;
      default:
        setSortParams(sortParams = [submittedSort]);
    }

    return sortParams;
  }

  return (
    <Dropdown
      id={`${componentName}-dropDown`}
      key={`${componentName}-dropDown-key`}
      className="ms-4"
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        id={`${componentName}-memberAction`}
        data-display="static"
        data-bs-display="static"
        data-bs-flip="false"
        size="s"
      >
        <i className="fa fa-arrow-up-arrow-down me-2" />Sort
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`}
                     key={`${componentName}-dropDownMenu-key`}
                     className='custom-dropdown-menu'>
        {items && items.map((item, itemIdx) => {
          return (
            <Col
              id={`${componentName}-colMenuItem-${itemIdx}`}
              key={`${componentName}-colMenuItem-${item.value}`}
            >
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => onSortItemClick(item)}
              >{item.text}</Dropdown.Item>
            </Col>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SortMenu;