import React, {useRef, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {CustomMenuStyled, DropDownButton} from "./sharedDDL";
import Form from "react-bootstrap/Form";
import {Col, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const componentName = "PrimaryDDL";

const PrimaryDDL = ({
                        placeHolder = "-Select-",
                        label,
                        data,
                        handleChange,
                        otherServiceValue,
                        isRequired = false,
                    }) => {
    const searchTextBoxRef = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [otherItemText, setOtherItemText] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState(data);
    const [selectedPrimary, setSelectedPrimary] = useState(false);

    const buildDropdownText = () => {
        if (selectedItems && selectedItems.length > 0) {
            return selectedItems.map(item => item.text).join(", ")
        }
        return placeHolder;
    }

    const onTextChange = (searchText) => {
        setSearchText(searchText);
        let itemsFiltered = data;
        if (searchText && searchText.length > 1) {
            itemsFiltered = items.filter(item => item.text.toLowerCase().includes(searchText.toLowerCase()));
        }
        setItems(itemsFiltered);
    }

    const handleSelectItem = (item, isChecked) => {
        let currentSelectedItems = selectedItems;
        if (isChecked) {
            currentSelectedItems.push(item);
        } else {
            currentSelectedItems = currentSelectedItems.filter(selectedItem => selectedItem.value !== item.value)
            setSelectedPrimary(currentSelectedItems.some(item => item.isPrimary));
            if (item.value === otherServiceValue) {
                setOtherItemText("");
            }
        }

        currentSelectedItems = addDefaultPrimarySelection(currentSelectedItems)
        setSelectedItems([...currentSelectedItems]);
        handleChange(currentSelectedItems);
    }

    const addDefaultPrimarySelection = (currentSelectedItems) => {
        if (currentSelectedItems?.length === 1) {
            setSelectedPrimary(true);
            return [{...currentSelectedItems[0], isPrimary: true}];
        }
        return currentSelectedItems;
    }

    const selectAll = () => {
        setSelectedItems(data);
    }

    const clearAll = () => {
        setSelectedItems([]);
    }

    const handleSelectPrimary = (item, isChecked) => {
        setSelectedPrimary(isChecked)
        let currentSelectedItems = selectedItems.map(selectedItem => {
            if (selectedItem.value === item.value) {
                selectedItem = {...selectedItem, isPrimary: isChecked}
            }
            return selectedItem;
        });
        setSelectedItems([...currentSelectedItems]);
        handleChange(currentSelectedItems);
    }

    const isItemSelected = (item) => {
        return selectedItems.some(selectedItem => selectedItem.value === item.value);
    }

    const isPrimarySelected = (item) => {
        const currentItem = selectedItems.filter(selectedItem => selectedItem.value === item.value);
        if (currentItem && currentItem.length > 0) {
            return currentItem[0].isPrimary;
        }
        return false;
    }

    const isPrimaryDisabled = (item) => {
        return !isItemSelected(item) || (selectedPrimary && !isPrimarySelected(item));
    }

    const clearSearchBox = () => {
        setSearchText("");
        setItems(data);
    }

    const onChangeOtherItemText = (text) => {
        setOtherItemText(text);
        const items = selectedItems.map(item => {
            if (item.value === otherServiceValue) {
                return {...item, otherItemText: text};
            }
            return item;
        });
        setSelectedItems([...items]);
    }

    const isOtherItemSelected = () => {
        return !selectedItems.some(item => item.value === otherServiceValue)
    }

    return (
        <div id={`${componentName}-filter`}>
            <label id={`${componentName}-filterTitle`} className="d-block">
                <span id={`${componentName}-filterTitleText`}>
                  {label}
                </span>
                {isRequired && 
                    <span id={`${componentName}-filterRequired`} className="text-danger">*</span>
                }
            </label>
            <Dropdown>
                <Dropdown.Toggle as={DropDownButton} cusWidth="100%">
                    <span id={`${componentName}-filterText`}>{buildDropdownText()}</span>
                    <i className="fa fa-chevron-down"/>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenuStyled} className="select-dropdown w-100 pb-3">
                    <InputGroup className="mb-2">
                        <Form.Control
                            type="text"
                            id={`${componentName}-filterKeywordSearch`}
                            value={searchText}
                            onChange={(e) => onTextChange(e.target.value)}
                            placeholder="Keyword Search"
                            ref={searchTextBoxRef}
                        />
                        <Button id={`${componentName}-filterClear`}
                                variant={"light"}
                                className="rounded-end"
                                onClick={() => clearSearchBox()}>
                            Clear
                        </Button>
                    </InputGroup>
                    <Row className="mb-2">
                        <Col className="justify-content-start">
                            <Button
                                id={`${componentName}-filterSelectAll`}
                                variant="link"
                                className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none me-2"
                                onClick={() => selectAll()}>
                                Select All
                            </Button>
                            <Button
                                id={`${componentName}-filterClearAll`}
                                variant="link"
                                className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none"
                                onClick={() => clearAll()}>
                                Clear All
                            </Button>
                        </Col>
                        <Col className="badge text-uppercase text-dark text-decoration-none d-flex justify-content-end">
                            <Row>
                                <span className="pe-5">Primary</span>
                            </Row>
                        </Col>
                    </Row>
                    {items.map((item, idx) => (
                        <Row
                            key={`${componentName}-item-row-${idx}`}
                            id={`${componentName}-item-row-${idx}`}>
                            <Col>
                                <Form.Check
                                    type="checkbox" label={item.text}
                                    key={`${componentName}-item-select-${idx}`}
                                    id={`${componentName}-item-select-${idx}`}
                                    checked={isItemSelected(item)}
                                    onChange={event => handleSelectItem(item, event.target.checked)}
                                    className="ps-5"
                                />
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Form.Check
                                    type="checkbox"
                                    className="pe-5"
                                    key={`${componentName}-item-primary-${idx}`}
                                    id={`${componentName}-item-primary-${idx}`}
                                    disabled={isPrimaryDisabled(item)}
                                    checked={isPrimarySelected(item)}
                                    onChange={event => handleSelectPrimary(item, event.target.checked)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Form.Control
                        maxLength="50"
                        disabled={isOtherItemSelected()}
                        type="text"
                        value={otherItemText}
                        id={`${componentName}-Item-Other`}
                        onChange={(e) => onChangeOtherItemText(e.target.value)}
                        className="mt-2"
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
};

export {PrimaryDDL};