const useLocalStorageUtil = (useSessionStorage) => {
    const storage = useSessionStorage ? sessionStorage : localStorage;
    const getObj = (key) => {
        return storage.getItem(key) ?
            JSON.parse(new Buffer(storage.getItem(key), 'base64').toString('utf8')) :
            null;
    };

    const setObj = (key, obj) => {
        storage.setItem(key, Buffer.from(JSON.stringify(obj), 'utf8').toString('base64'));
    };

    const getPropertyValue = (key, propertyName) => {
        const obj = getObj(key);
        return obj ? obj[propertyName] : null;
    };

    const setPropertyValue = (key, propertyName, propertyValue) => {
        const obj = getObj(key);
        if (obj !== null) { 
            const updatedObj = JSON.parse(atob(obj));
            updatedObj[propertyName] = propertyValue;
            setObj(updatedObj);
        }
    };
    return { 
        getObj, 
        setObj, 
        getPropertyValue, 
        setPropertyValue,
    };
}

const LocalStorageUtil = useLocalStorageUtil();
const SessionStorageUtil = useLocalStorageUtil(true);

const AppPropertiesUtil = {
    get: (propertyName) => {
        return SessionStorageUtil.getPropertyValue("properties", propertyName);
    },
    set: (properties) => {
        SessionStorageUtil.setObj("properties", properties);
    }
}

export { LocalStorageUtil, SessionStorageUtil, AppPropertiesUtil };