import React, { useEffect, Fragment, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useGetPatientProfileADTs } from "../../actions/patientProfileActions";
import { Timeline } from "../../components/Timeline";
import { MiniCard } from "../../components/MiniCard";
import { CollapsibleCard } from "../../components/CollapsibleCard";
import { EQHSpinner } from "../../components/EQHSpinner";
import { adtsReducer } from "../../reducers/patientProfile/adtsReducer";
import { format } from "date-fns";
import { ItemsAccordion } from "../../components/ItemsAccordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { EQHDatePicker } from "../../components/datePicker";
import {
  arrayToKVP,
  getFieldValueVal,
  getFieldValueLabel,
  getDisplayValue,
  formatDate,
  formatTimeOnly,
  handlePkgErrors,
  formatLocalDate,
  formatDateVanilla,
  convertToMMddYYYY,
} from "../../utils/util";
import {
  dispositionList,
  patientProfileField,
} from "../../utils/textValueLists";
import { Filter } from "../../components/filters/filterGroup";
import { GENERIC_DDL_TYPE } from "../../components/dropDownLists/genericDDL";
import { patientFilterADTDisposition } from "../../actions/patientFilterActions";
import axios from "../../utils/apiClient";
import { DropdownComponent } from "../../components/DropdownComponent";
import { useAuth } from "../../context/authContext";
import ErrorToast from "../common/ErrorToast";
import EQHErrorToast from "../../components/EQHErrorToast";

const componentName = "patientProfile-HospitalEvents";

const HospitalEventCard = ({
  hospitalEvent,
  idx,
  handleNewHospitalEventEvt,
}) => {
  const auth = useAuth();
  if (hospitalEvent) {
    const openContent = (
      <Container fluid>
        <Row xl={4}>
          {hospitalEvent.fieldValues.map((x, idx) => {
            const miniCardlabel = getFieldValueLabel(x);
            let miniCardValue = getFieldValueVal(x);

            if (miniCardlabel === "admitdate") {
              miniCardValue = formatDate(miniCardValue);
            } else if (miniCardlabel === "dischargedate") {
              if (miniCardValue != null) {
                miniCardValue = formatDate(miniCardValue);
              } else {
                miniCardValue = "-";
              }
            }
            return (
              <Col>
                <MiniCard
                  label={getDisplayValue(patientProfileField, miniCardlabel)}
                  value={miniCardValue}
                  idx={idx}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    );
    const fieldValuesKVP = arrayToKVP(
      hospitalEvent.fieldValues,
      "caption",
      "value"
    );
    const closedContent = (
      <Container fluid>
        <Row xl={4}>
          <Col>
            <MiniCard
              label="Disposition"
              value={
                fieldValuesKVP.disposition ? fieldValuesKVP.disposition[1] : "-"
              }
            />
          </Col>
          <Col>
            <MiniCard
              label="Admit Date"
              value={
                fieldValuesKVP.admitdate
                  ? formatLocalDate(fieldValuesKVP.admitdate[1])
                  : "-"
              }
            />
          </Col>
          <Col>
            <MiniCard
              label="Discharge Date"
              value={
                fieldValuesKVP.dischargedate
                  ? formatDate(fieldValuesKVP.dischargedate[1])
                  : "-"
              }
            />
          </Col>
        </Row>
      </Container>
    );
    return (
      <CollapsibleCard
        title={
          <>
            {hospitalEvent.title}{" "}
            {auth.hasAnyAuthority(["AUTH_ADD_UPDATE_ADT_ACCESS"]) ? (
              <Button
                id={`${componentName}-editButton`}
                className="btn btn-secondary mb-0 btn btn-primary float-end"
                onClick={() => handleNewHospitalEventEvt(hospitalEvent, true)}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </>
        }
        openContent={openContent}
        closedContent={closedContent}
        idx={idx}
      />
    );
  }
  return <></>;
};

const HospitalEvents = ({ patientId, selectedTab, idx }) => {
  const [isSearching, setIsSearching] = useState(false);
  const auth = useAuth();
  const hospitalEventsPkg = useGetPatientProfileADTs([], adtsReducer);

  const [showNewHospitalEvent, setShowNewHospitalEvent] = useState(false);
  const [hospitalEvent, setHospitalEvent] = useState({
    eventId: null,
    admitDate: null,
    dischargeDate: null,
    disposition: null,
    admitDiagnosis: {},
    dischargeDiagnosis: {},
    facility: "",
    chiefComplaint: "",
    notes: "",
    patientId: patientId,
    adtEventContainerId:null
  });

  const [editMode, setEditMode] = useState(false);
  const [minYear, setMinYear] = useState(() => {
   const dt = new Date()
   dt.setFullYear(dt.getFullYear() - 1)
    return dt;
  });

  useEffect(() => {
    if (selectedTab) {
      hospitalEventsPkg.fetchData(patientId);
    }

  }, [patientId, selectedTab]);

  const headerAndSelectorMap = {
    "All Hospital Events": (item) => true,
    Admits: (item) => item.adtType === "ADMIT",
    Discharges: (item) => item.adtType === "DISCHARGE",
    Transfers: (item) => item.adtType === "TRANSFER",
  };

  const handleChange = (key, value) => {
    switch (key) {
      case "disposition":
        setHospitalEvent({
          ...hospitalEvent,
          [key]: value.value,
        });
        break;
      case "admitDiagnosis":
      case "dischargeDiagnosis":
        let diagnosis = { code: "", description: value };
        setHospitalEvent({
          ...hospitalEvent,
          [key]: diagnosis,
        });

        break;
        case "dischargeDate":
        case "admitDate":
          if(value.error){
            setErrorsBeforeConfirm(value.error)
          }
          else{
            setHospitalEvent({
              ...hospitalEvent,
              [key]: value.strDate,
            });
          }
        break;
      default:
        setHospitalEvent({
          ...hospitalEvent,
          [key]: value,
        });
        break;
    }
  };
  const [showConfirmationDiag, setShowConfirmationDiag] = useState(false);
  const onSubmit = () => {
    if (hospitalEvent.dischargeDate < hospitalEvent.admitDate) {
      setErrorsBeforeConfirm(
        "Admit Date must be less than or equal to discharge Date"
      );
    } else if (
      !hospitalEvent.dischargeDate ||
      !hospitalEvent.admitDate ||
      !hospitalEvent.disposition
    ) {
      setErrorsBeforeConfirm("Required fields cannot be empty or null");
    } else {
      setShowConfirmationDiag(true);
      setErrorsBeforeConfirm("");
    }
  };

  const onSubmitFinal = () => {
    setIsSearching(true);
    let url = "";
    if (editMode) {
      url = `${process.env.REACT_APP_REST_API_BASE_URL}/v1/adt/editEvent`;
      axios
        .put(url, hospitalEvent, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          hospitalEventsPkg.fetchData(patientId);
          handleNewHospitalEventClose();
          setShowConfirmationDiag(false);
          setIsSearching(false);
        })
        .catch((error) => {
          setIsSearching(false);
          formatErrorMessage(error);
        });
    } else {
      url = `${process.env.REACT_APP_REST_API_BASE_URL}/v1/adt/addEvent`;
      axios
        .post(url, hospitalEvent, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data === "ADT_DUPLICATE") {
            setErrors("Hospital Event already available for the patient with the provided admit and discharge dates.");
          } else {
            hospitalEventsPkg.fetchData(patientId);
            handleNewHospitalEventClose();
            setShowConfirmationDiag(false);
          }
          setIsSearching(false);
        })
        .catch((error) => {
          formatErrorMessage(error);
          setIsSearching(false);
        });
    }
  };

  const [errors, setErrors] = useState(null);
  const [errorsBeforeConfirm, setErrorsBeforeConfirm] = useState(null);

  const [multipleErrors, setMultipleErrors] = useState([]);
  const formatErrorMessage = (error) => {
    if (error?.response?.data?.message?.includes("|")) {
      var errors = error?.response?.data?.message?.split("|");
      setMultipleErrors(errors);
    } else {
      setErrors(error?.response?.data?.message);
    }
  };
  const handleNewHospitalEventShow = (hosEv, editMode) => {
    setErrors("");
    setMultipleErrors([]);
    if (editMode) {
      const fieldValuesKVP = arrayToKVP(hosEv.fieldValues, "caption", "value");
      hospitalEvent.disposition = fieldValuesKVP.disposition
        ? fieldValuesKVP.disposition[1]
        : "-";
      hospitalEvent.admitDate = fieldValuesKVP.admitdate
        ? formatDateVanilla(fieldValuesKVP.admitdate[1], "yyyy-MM-dd", true)
        : "-";
      hospitalEvent.dischargeDate = fieldValuesKVP.dischargedate
        ? formatDateVanilla(fieldValuesKVP.dischargedate[1], "yyyy-MM-dd", true)
        : "-";
      hospitalEvent.eventId = hosEv.eventId;
      hospitalEvent.facility = fieldValuesKVP.facility;
      hospitalEvent.chiefComplaint = fieldValuesKVP.chiefcomplaint;
      hospitalEvent.notes = fieldValuesKVP.notes;
      hospitalEvent.dischargeDiagnosis.description =
        fieldValuesKVP.dischargediagnosis;
      hospitalEvent.admitDiagnosis.description = fieldValuesKVP.admitdiagnosis;
      hospitalEvent.adtEventContainerId = hosEv.originalDocId;
      setEditMode(true);
    } else {
      setHospitalEvent({
        eventId: null,
        admitDate: null,
        dischargeDate: null,
        disposition: null,
        admitDiagnosis: {code:"", description:""},
        dischargeDiagnosis: {code:"", description:""},
        facility: "",
        chiefComplaint: "",
        notes: "",
        patientId: patientId,
      });
      setEditMode(false);
    }
    setShowNewHospitalEvent(true);
  };
  const handleNewHospitalEventClose = () => {
    setShowNewHospitalEvent(false);
  };

  return (
    <Fragment>
      <Container fluid>
        {auth.hasAnyAuthority(["AUTH_ADD_UPDATE_ADT_ACCESS"]) ? (
          <Button
            id={`${componentName}-addHospitalEvent`}
            variant="primary"
            className="mb-3"
            onClick={handleNewHospitalEventShow}
          >
            New Hospital Event
          </Button>
        ) : (
          ""
        )}
        {hospitalEventsPkg.state.isLoading && <EQHSpinner />}
        {hospitalEventsPkg.state.error ? (
          <Card>
            <Card.Body id={`${componentName}-errorText-${idx}`}>
              {handlePkgErrors(hospitalEventsPkg.state.error)}
            </Card.Body>
          </Card>
        ) : hospitalEventsPkg.state.data &&
          hospitalEventsPkg.state.data.length === 0 ? (
          <Card>
            <Card.Body id={`${componentName}-noRecordsFound-${idx}`}>
              No Records Found
            </Card.Body>
          </Card>
        ) : (
          <Card>
            <Card.Body>
              <ItemsAccordion
                items={hospitalEventsPkg.state.data ?? []}
                headerAndSelectorMap={headerAndSelectorMap}
                selected={"All Hospital Events"}
                idx={idx}
                bodyBuilder={(key, list, idx) => (
                  <Timeline
                    items={list}
                    idx={idx}
                    titleBuilder={(hospitalEvent, idx) =>
                      hospitalEvent && hospitalEvent.dateTime ? (
                        <div>
                          <span
                            id={`${componentName}-tlDate-${idx}`}
                            className="d-block fw-bold"
                          >
                            {formatLocalDate(hospitalEvent.dateTime)}
                          </span>
                          <span
                            id={`${componentName}-tlTime-${idx}`}
                            className="d-block"
                          >
                            {formatTimeOnly(hospitalEvent.dateTime)}
                          </span>
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="auto"
                            overlay={
                              <Tooltip>
                                {hospitalEvent.author
                                  ? hospitalEvent.author
                                  : "No Author"}
                              </Tooltip>
                            }
                          >
                            <Badge
                              id={`${componentName}-tlAuthor-${idx}`}
                              variant="secondary text-truncate mw-100"
                            >
                              {hospitalEvent.author
                                ? hospitalEvent.author
                                : "No Author"}
                            </Badge>
                          </OverlayTrigger>
                        </div>
                      ) : (
                        "-"
                      )
                    }
                    bodyBuilder={(hospitalEvent, idx) => (
                      <HospitalEventCard
                        hospitalEvent={hospitalEvent}
                        handleNewHospitalEventEvt={handleNewHospitalEventShow}
                        idx={idx}
                      />
                    )}
                  />
                )}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
      <Modal
        size="lg"
        centered
        show={showNewHospitalEvent}
        onHide={handleNewHospitalEventClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id={`${componentName}-createHospitalEventsModal`}>
            Create Hospital Event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorsBeforeConfirm && errorsBeforeConfirm.length ? (
            <ErrorToast
              className="float-end"  
              errorParam={errorsBeforeConfirm}
              closeToast={() => setErrorsBeforeConfirm("")}
            />
          ) : null}
          <Row>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-admitDate`}
                className="mb-3"
                controlId="admitDate"
              >
                <Form.Label id={`${componentName}-admitDateLabel`}>
                  Admit Date<span className="text-danger">*</span>
                </Form.Label>
                <EQHDatePicker
                  id={`${componentName}-admitDateValue`}
                  value={hospitalEvent.admitDate}
                  onChange={(e) => {
                    e && handleChange("admitDate", e);
                  }}
                  max={format(new Date(), "yyyy-MM-dd")}
                  min={ format(minYear, "yyyy-MM-dd")}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-dischargeDate`}
                className="mb-3"
                controlId="dischargeDate"
              >
                <Form.Label id={`${componentName}-dischargeDateLabel`}>
                  Discharge Date<span className="text-danger">*</span>
                </Form.Label>
                <EQHDatePicker
                  id={`${componentName}-dischargeDateValue`}
                  value={hospitalEvent.dischargeDate}
                  onChange={(e) => {
                    e && handleChange("dischargeDate", e);
                  }}
                  max={format(new Date(), "yyyy-MM-dd")}
                  min={ format(minYear, "yyyy-MM-dd")}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-dischargeDisposition`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <DropdownComponent
                  name="disposition"
                  list={dispositionList}
                  handleInputChange={handleChange}
                  label="Discharge Disposition"
                  required={true}
                  isOpen={true}
                  val={hospitalEvent.disposition}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
            <Col sm={3} disabled={editMode}>
              <Form.Group
                id={`${componentName}-diagnosis`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label id={`${componentName}-diagnosisLabel`}>
                  {" "}
                  Admit Diagnosis
                </Form.Label>
                <Form.Control
                  id={`${componentName}-admitDiagnosisValue`}
                  type="text"
                  onChange={(e) => {
                    e && handleChange("admitDiagnosis", e.target.value);
                  }}
                  value={hospitalEvent.admitDiagnosis.description}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-diagnosis`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label id={`${componentName}-diagnosisLabel`}>
                  {" "}
                  Discharge Diagnosis
                </Form.Label>
                <Form.Control
                  id={`${componentName}-dischargeDiagnosisValue`}
                  type="text"
                  onChange={(e) => {
                    e && handleChange("dischargeDiagnosis", e.target.value);
                  }}
                  value={hospitalEvent.dischargeDiagnosis.description}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-facility`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label id={`${componentName}-facilityLabel`}>
                  Facility
                </Form.Label>
                <Form.Control
                  id={`${componentName}-facilityValue`}
                  type="text"
                  onChange={(e) => {
                    e && handleChange("facility", e.target.value);
                  }}
                  value={hospitalEvent.facility}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group
                id={`${componentName}-chiefComplaint`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label id={`${componentName}-chiefComplaintLabel`}>
                  Chief Complaint
                </Form.Label>
                <Form.Control
                  id={`${componentName}-chiefComplaintValue`}
                  type="text"
                  onChange={(e) => {
                    e && handleChange("chiefComplaint", e.target.value);
                  }}
                  value={hospitalEvent.chiefComplaint}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group
                id={`${componentName}-notes`}
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label id={`${componentName}-notesLabel`}>
                  Notes
                </Form.Label>
                <Form.Control
                  id={`${componentName}-notesValue`}
                  as="textarea"
                  rows={3}
                  onChange={(e) => {
                    e && handleChange("notes", e.target.value);
                  }}
                  value={hospitalEvent.notes}
                  disabled={editMode}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={`${componentName}-closeButton`}
            variant="secondary"
            onClick={handleNewHospitalEventClose}
          >
            Close
          </Button>
          <Button
            id={`${componentName}-submitButton`}
            variant="primary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        centered
        show={showConfirmationDiag}
        onHide={() => setShowConfirmationDiag(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id={`${componentName}-confirmModal`}>
            Confirm!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <span>
              Verify Admit/Discharge dates are accurate and then select 'Submit'
              to create hospital event.
            </span>
          </Col>
          {" "}
          <Row>
            <Col sm={4}>
              <MiniCard
                className="p-2"
                label={"Admit Date"}
                value={convertToMMddYYYY(hospitalEvent.admitDate)}
                idx={1}
              />
            </Col>
            <Col sm={4}>
              <MiniCard
                label={"Discharge Date"}
                value={convertToMMddYYYY(
                  hospitalEvent.dischargeDate)}
                idx={2}
              />
            </Col>
          </Row>
          {isSearching && <EQHSpinner />}{" "}
          {errors && errors.length ? (
            <ErrorToast
              className="float-end"
              errorParam={errors}
              multipleErrors={multipleErrors}
              closeToast={() => {
                setErrors("");
                setMultipleErrors([]);
              }}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={`${componentName}-closeButtonForConfirmDialogue`}
            variant="secondary"
            onClick={() => setShowConfirmationDiag(false)}
          >
            Close
          </Button>
          <Button
            id={`${componentName}-submitButton`}
            variant="primary"
            onClick={onSubmitFinal}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export { HospitalEvents };
