import React from "react";
import { getFileDownload } from "../actions/fileUploadActions";
import { useDispatch } from "react-redux";
import { getDisplayValue } from "../utils/util";
import { visit_type_referrals } from "../utils/textValueLists";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { mapReferralServicesToText } from "../views/quickList/Referrals/referralUtils";
import { AppPropertiesUtil } from "../utils/localStorageUtil";

const componentName = "MiniCard";

const MiniCard = ({ label, value, idx }) => {
  const dispatch = useDispatch();

  const handleClickofLink = (fileId, fileName) => {
    dispatch(getFileDownload(fileId, fileName));
  };

  const getDownloadLink = (fileId, fileName) => {
    return (
      <a id={`${componentName}-infoDDLinkValue-${idx}`} href="#" onClick={() => handleClickofLink(fileId, fileName)}>
        {fileName ? fileName : ""}
      </a>
    );
  };

  const getDownloadLinkSec = (val) => {
    let value = val;
    if (typeof val === "string") {
      value = JSON.parse(val).value;
    }
    const { fileName, fileId } = value;
    return (
      <a id={`${componentName}-infoDLLinkSecValue-${idx}`} href="#" onClick={() => handleClickofLink(fileId, fileName)}>
        {fileName ? fileName : ""}
      </a>
    );
  };

  const getContent = () => {
    if (label == null || label === "File" || label === "Attachments") {
      if (value.files) {
        return (
          value.files.length > 0 &&
          value.files.map((file, idx) => (
            <p id={`${componentName}-infoDLLink-${idx}`} className="m-0" key={file.fileId}>
              {file &&
                getDownloadLink(
                  file.fileMetadata.fileId,
                  file.fileMetadata.fileName
                )}
            </p>
          ))
        );
      } else if (Array.isArray(value) && value.length) {
        return value.map((file, idx) => (
          <p id={`${componentName}-infoDLLink-${idx}`} className="m-0">
            {getDownloadLink(file.fileId, file.fileName)}
          </p>
        ));
      } else {
        return <p id={`${componentName}-infoDLLinkSec-${idx}`} className="m-0">{getDownloadLinkSec(value)}</p>;
      }
    }

    if (label === "Visit Type") {
      const vtValue = value?.visitType ? value.visitType : value;
      return vtValue ? getDisplayValue(visit_type_referrals, vtValue) : "-";
    }
    if (label === "Services Needed") {
      const snValue = value?.servicesNeeded ? value.servicesNeeded : value;
      return snValue?.length ? (
        <div className="me-4 mb-2 w-40">
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip>{mapReferralServicesToText(snValue)}</Tooltip>}
          >
            <div id={`${componentName}-infoServicesNeeded-${idx}`} className="text-truncate">{mapReferralServicesToText(snValue)}</div>
          </OverlayTrigger>
        </div>
      ) : (
        "-"
      );
    } else {
      return value;
    }
  };

  return (
    <span id={`${componentName}-info-${idx}`} className="d-block mb-2">
      <span id={`${componentName}-infoLabel-${idx}`} className="d-block fw-bold">{label}</span>
      <span id={`${componentName}-infoValue-${idx}`}>{getContent()}</span>
    </span>
  );
};

export { MiniCard };
