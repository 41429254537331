import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  addUser,
  createMessage,
  deleteMessages,
  getMessagesFromConvo,
  markAsRead,
  getAllUserMessages, getMessageCount,
} from "../../actions/admin/header/messageActions";
import {
  updateFile,
  getFileDownload,
  clearFileUploads,
} from "../../actions/fileUploadActions";
import { getAllUsersByCLients } from "../../actions/admin/userManagementActions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "../../components/buttons";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { theme } from "../../components/themes/theme1";
import Spinner from "react-bootstrap/Spinner";
import { Typeahead } from "react-bootstrap-typeahead";
import ComposeMessage from "./ComposeMessage";
import Modal from "../../components/modals";
import MultipleUploads from "../../components/MultipleUploads";
import { formatDateTime, reactQuickLists } from "../../utils/util";
import { CardGroup } from "react-bootstrap";
import { getFileDownloadGeneric } from "../../actions/fileUploadActions";
import { FileUpload } from "../../components/FileUpload";

const componentName = "MessageInbox";

const MessageInboxContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  padding: ${(props) => props.theme.themeBase.divPadding};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
`;

const ActionButtons = styled(Button)`
  margin: 0 5px;
`;

const SelectAll = styled.span`
  margin: 0 25px 0 5px;
  cursor: pointer;
  color: #007bff;
`;

const URL = "/transitions_of_care?source=message";

const MessageInbox = () => {
  const { messages } = useSelector((state) => state.messages);
  const { allUsers } = useSelector((state) => state.userManagement);
  const [userMessages, setUserMessages] = useState();
  const { count } = useSelector((state) => state.messages);
  const [filter, setFilter] = useState("All");
  const [sendDate, setSendDate] = useState("new");
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [noMacthes, setNoMatches] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchMessages() {
      setLoadingMessages(true);
      await dispatch(getAllUserMessages());
      await dispatch(getAllUsersByCLients());
      setTimeout(() => setLoadingMessages(false), 2000);
    })();
  }, []);

  useEffect(() => {
    setUserMessages(messages);
    dispatch(getMessageCount());
  }, [messages]);

  const handleFilterChange = async (e) => {
    const update = e.target.value;
    setFilter(update);
    let filtered = [];
    await messages.forEach((email) => {
      if (update === "Read" && email.viewed) {
        filtered.push(email);
      }
      if (update === "Unread" && !email.viewed) {
        filtered.push(email);
      }
      if (update === "All") {
        filtered.push(email);
      }
    });
    await setUserMessages(filtered);
    setTimeout(() => {
      filterByDate(sendDate, filtered);
    }, 1000);
  };

  const handleDateChange = (e) => {
    const update = e.target.value;
    setSendDate(update);
    filterByDate(update);
  };

  const filterByDate = (date, emails) => {
    let filteredEmails = emails ? emails : userMessages;
    if (date === "new") {
      setUserMessages(
        filteredEmails.sort(
          (a, b) => new Date(b.sendDateTime) - new Date(a.sendDateTime)
        )
      );
    } else if (date === "old") {
      setUserMessages(
        filteredEmails.sort(
          (a, b) => new Date(a.sendDateTime) - new Date(b.sendDateTime)
        )
      );
    }
  };

  const checkEmail = (id) => {
    let newArr;
    if (checked.includes(id)) {
      newArr = checked.filter((i) => i !== id);
      setChecked(newArr);
    } else {
      setChecked([id].concat(checked));
    }
  };

  const handleSelectAll = () => {
    let tempArr = [];
    if (!selectAll) {
      userMessages.forEach((email) => {
        tempArr.push(email.conversationId);
      });
    }
    setChecked(tempArr);
    setSelectAll(!selectAll);
  };

  const readMessage = (id) => {
    userMessages.find((email) => email.conversationId === id).viewed = true;
  };

  const updateStatus = async (e) => {
    await dispatch(markAsRead(checked, e));
    await setUserMessages(messages);
    await dispatch(getMessageCount());
    setChecked([]);
  };

  const deleteMessage = () => {
    const task1 = () => {
      setUserMessages(messages);
      dispatch(getMessageCount());
      setChecked([]);
    }

    const task2 = () => {
      dispatch(getAllUserMessages(task1));
    }

    dispatch(deleteMessages(checked, task2));
  };

  const handleModal = async () => {
    await dispatch(clearFileUploads());
    await setShowModal(true);
  };

  const handleSearch = async (e) => {
    const searchParam = searchString.toLowerCase();
    if (e.key === "Enter") {
      if (searchParam.length === 0) {
        setUserMessages(messages);
      } else {
        const matches = [];
        for (var i = 0; i < messages.length; i++) {
          if (
            messages[i].title.toLowerCase().includes(searchParam) ||
            messages[i].message.toLowerCase().includes(searchParam)
          ) {
            matches.push(messages[i]);
          }
        }
        await setUserMessages([]);
        if (matches.length === 0) {
          setNoMatches(true);
        } else {
          await setNoMatches(false);
          await setUserMessages(matches);
        }
      }
    }
  };

  return (
    <MessageInboxContainer theme={theme} id="message_inbox_container">
      <Modal
        show={showModal}
        hideFooter={true}
        onHide={() => setShowModal(false)}
        title="New Message"
        titleid={`${componentName}-newMessageModal`}
        size="xl"
        completeBtn={"Close"}
        completeBtnId={`${componentName}-closeButton`}
        body={<ComposeMessage closeModal={() => setShowModal(false)} />}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Row className="p-3 pb-0">
                <Col sm={4}>
                  {loadingMessages ? (
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm"
                      className="d-inline-block"
                    />
                  ) : (
                    <>
                      <span id={`${componentName}-inboxMessageTotal`} className="me-2">{messages.length && messages[0] !== "Empty" ? messages.length : 0} Messages</span>
                      <span id={`${componentName}-inboxUnreadMessages`}>({messages.length && messages[0] !== "Empty" ? count : 0} Unread)</span>{" "}
                      <a
                        href="#"
                        onClick={() => dispatch(getAllUserMessages())}
                        id={`${componentName}-inboxRefreshIcon`}
                      >
                        <i className="fa fa-sync"></i>
                      </a>
                    </>
                  )}
                </Col>
                <Col sm={5}>
                  <Row>
                    <Col sm={5} className="mb-0">
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="status"
                          value={filter}
                          id={`${componentName}-statusSort`}
                          className="form-control"
                          onChange={(e) => handleFilterChange(e)}
                        >
                          <option id={`${componentName}-statusSortAll`} key={"all"} value="All">
                            All
                          </option>
                          <option id={`${componentName}-statusSortRead`} key={"read"} value="Read">
                            Read
                          </option>
                          <option id={`${componentName}-statusSortUnread`} key={"unread"} value="Unread">
                            Unread
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-0">
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="status"
                          value={sendDate}
                          id={`${componentName}-statusSortAll`}
                          className="form-control"
                          onChange={(e) => handleDateChange(e)}
                        >
                          <option id={`${componentName}-statusSortAllNew`} value="new">Newest One On Top</option>
                          <option id={`${componentName}-statusSortAllOld`} value="old">Oldest one on top</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={3}>
                  <input
                    type="text"
                    id={`${componentName}-keywordFilter`}
                    className="form-control mb-0"
                    placeholder="Keyword Filter"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyDown={(e) => handleSearch(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectAll
                    onClick={() => handleSelectAll()}
                    id={`${componentName}-inboxSelectAll`}
                  >
                    {checked.length > 0 ? "Deselect All" : "Select All"}
                  </SelectAll>
                  <ActionButtons
                    onClick={() => handleModal()}
                    id={`${componentName}-inboxComposeButton`}
                  >
                    Compose
                  </ActionButtons>
                  <ActionButtons
                    backgroundColor="#6c757d"
                    onClick={() => updateStatus(false)}
                    id={`${componentName}-inboxUnreadButton`}
                  >
                    Mark As Unread
                  </ActionButtons>
                  <ActionButtons
                    backgroundColor="#6c757d"
                    onClick={() => updateStatus(true)}
                    id={`${componentName}-inboxReadButton`}
                  >
                    Mark Read
                  </ActionButtons>
                  <ActionButtons
                    backgroundColor="#dc3545"
                    onClick={() => deleteMessage()}
                    id={`${componentName}-inboxDeleteButton`}
                  >
                    Delete
                  </ActionButtons>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {loadingMessages ? (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="d-inline-block"
        />
      ) : (
        <Messages
          emails={userMessages}
          users={allUsers}
          updateSelected={checkEmail}
          read={readMessage}
          selected={checked}
          noResults={noMacthes}
        />
      )}
    </MessageInboxContainer>
  );
};

const StyledRows = styled.div`
  display: inline-block;
`;

const MessageCheckbox = styled.input`
  display: inline-block;
`;

const ReadSpacing = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

const MessagesCards = styled(CardGroup)`
  border: 0px solid transparent;
  background: #eef5f9;
  backgroundcolor: #eef5f9;
`;

const ConversationCard = styled(Card)`
  padding: 5px;
  max-height: calc(100vh - 175px);
  overflow-y: auto;
`;

const MessagesCard = styled(Card)`
  padding: 5px;
  max-height: calc(100vh - 175px);
  overflow-y: auto;
`;

const Messages = ({
  emails,
  users,
  updateSelected,
  read,
  selected,
  noResults,
}) => {
  const dispatch = useDispatch();
  const { fileObject } = useSelector((state) => state.fileUpload);
  const { conversationMessages } = useSelector((state) => state.messages);
  const isEmpty = require("is-empty");
  const [fileAdded, setFileAdded] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [urgent, setUrgent] = useState(false);
  const [transitionLoading, setTransitionLoading] = useState(false);
  const [newParticipants, setNewParticipants] = useState([]);
  useEffect(() => {
    if (fileObject) {
      setFileAdded(fileObject);
    }
  }, [fileObject]);

  const StyledSentence = styled.p`
  margin: 0px;
`;

  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };

  const linkElement = (linkURI, linkText) => {
    if (linkURI) {
      const index = linkURI.indexOf("/");
      const quicklistType = linkURI.substring(0, index);
      const quicklistId = linkURI.substring(index + 1);

      if (index === -1) {
        return <a href={`/${quicklistId}`}>
          {linkText ? linkText : ""}
        </a>;
      } else {
        if (reactQuickLists.includes(quicklistType)) {
          return <a href={`/${quicklistType}?id=${decodeURIComponent(quicklistId)}&customListName=${linkText}`}>
            {linkText ? linkText : ""}
          </a>;
        } else {
          return <a href={`/#!${quicklistType}/${encodeURIComponent(quicklistId)}`}>
            {linkText ? linkText : ""}
          </a>
        }
      }
    } else {
      return null;
    }
  };

  const updateUsers = async (e, error) => {
    let idArray = [];
    if (e.length > 0) {
      e.forEach((user) => {
        if (!idArray.includes(user.id)) {
          idArray.push(user.id);
        }
      });
    }
    setNewParticipants(idArray);
  };

  const setFileMethod = async (filesList) => {
    let file;
    let fileName;
    if (filesList && filesList.name) {
      fileName = filesList.name;
    }
    const fileReader = new FileReader();
    if (fileReader && filesList) {
      fileReader.readAsArrayBuffer(filesList);
      fileReader.onload = function () {
        if (fileReader && fileReader.result && !isEmpty(fileReader.result)) {
          dispatch(updateFile(fileReader.result, fileName));
        }
      };
    }
  };

  const handleReplyChange = (e) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const isFormValid = () => {
    const { message } = emailData;
    if (
      message &&
      message.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateFiles = (arr) => {
    setUploadedFiles(arr);
  };

  const handleClickofLink = (file) => {
    if (file && file.fileId) {
      dispatch(getFileDownload(file.fileId, file.fileName));
    }
  };

  const openMessage = async (id) => {
    await dispatch(markAsRead(id, true));
    await read(id);
    await dispatch(getMessagesFromConvo(id));
    await dispatch(getMessageCount());
  };

  const submitReplyFunc = async () => {
    const messageObj = {
      conversationId: conversationMessages.id,
      title: conversationMessages.title,
      message: emailData.message,
      newParticipants: newParticipants,
      important: urgent,
    };
    if (uploadedFiles && uploadedFiles.length) {
      messageObj["uploadedFiles"] = uploadedFiles;
    }
    dispatch(createMessage(messageObj));
  };

  useEffect(() => {
    (async function fetchMessages() {
      setTransitionLoading(true);
      await dispatch(clearFileUploads());
      await setFileAdded(fileObject);

      await setTimeout(() => {
        setTransitionLoading(false);
      }, 1000);
    })();
  }, [conversationMessages]);

  return (
    <div id="message_inbox_messages_container">
      <MessagesCards>
        <MessagesCard style={{ width: "20rem !important" }}>
          <Card.Body>
            {emails && emails.length && emails[0] !== "Empty" ? (
              emails.map((email, idx) => {
                return (
                  <div
                    key={email.id}
                    onClick={() => openMessage(email.conversationId)}
                    id={`${componentName}-inboxMessageCenter-${idx}`}
                    className="message-center text-decoration-none"
                    style={{
                      color: "#67757c",
                      hover: { background: "#f2f4f8 !important" },
                    }}
                  >
                    <a
                      href="#"
                      className="text-decoration-none"
                      style={{ color: "#67757c" }}
                    >
                      <StyledRows>
                        <MessageCheckbox
                          checked={selected.includes(email.conversationId)}
                          type="checkbox"
                          onClick={() => updateSelected(email.conversationId)}
                          id={`${componentName}-inboxMessageCheckbox-${idx}`}
                        />
                        <StyledRows>
                          {!email.viewed ? (
                            <i
                              id={`${componentName}-inboxMessageViewed-${idx}`}
                              className="fa fa-circle text-primary d-inline-block ms-3"
                            />
                          ) : (
                            <ReadSpacing />
                          )}
                          {email.important ? (
                            <i
                              id={`${componentName}-inboxMessageImportant-${idx}`}
                              className="fa fa-exclamation-circle text-danger d-inline-block ms-1"
                            />
                          ) : null}
                          {email.uploadedFiles && email.uploadedFiles.length ? (
                            <i
                              id={`${componentName}-inboxMessageUploads-${idx}`}
                              className="fas fa-paperclip d-inline-block ms-1"
                            />
                          ) : null}
                          <b
                            id={`${componentName}-inboxMessageFullName-${idx}`}
                            className="d-inline-block ms-4"
                          >
                            {email.sender.firstName +
                              " " +
                              email.sender.lastName}
                          </b>{" "}
                          <span id={`${componentName}-inboxMessageOrgName-${idx}`}>- {email.sender.organizationName}</span>
                        </StyledRows>
                      </StyledRows>
                      <p id={`${componentName}-inboxMessageDate-${idx}`}>{formatDateTime(email.sendDateTime)}</p>
                      <p>
                        {!isEmpty(email?.message) && (
                          email.message.split('\n').map((item, i) => {
                            return <StyledSentence id={`${componentName}-inboxMessageText-${idx}-${i}`} key={i}>{item}</StyledSentence>
                          })
                        )}
                        {email.linkURI && (
                          <li>
                            <a href={email.linkURI} id={`${componentName}-inboxMessageLink-${idx}`}>
                              {linkElement(email.linkURI, email.linkText)}
                            </a>
                          </li>
                        )}
                        {email.exportFiles &&
                          email.exportFiles.length > 0 &&
                          email.exportFiles.map((file) => (
                            <p key={file.fileName}>
                              {file && (
                                <li>
                                  <Button variant="link" onClick={() => getFileDownloadGeneric(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/files/get?fileUri=${encodeURIComponent(file.fileUri)}&fileName=${file.fileName}`, file.fileName)} id={`${componentName}-inboxMessageFile-${idx}`}>{file.fileName ? file.fileName : ""}</Button>
                                </li>
                              )}
                              {"  "}
                            </p>
                          ))}
                      </p>
                    </a>
                  </div>
                );
              })
            ) : (emails && emails[0] === "Empty") || noResults ? (
              <p id={`${componentName}-inboxNoMessages`}>You don't have any messages</p>
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Loading...
              </>
            )}
          </Card.Body>
        </MessagesCard>
        <ConversationCard>
          {conversationMessages &&
            conversationMessages.messages &&
            conversationMessages.messages.length ? (
            <div>
              {!transitionLoading ? (
                <div>
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <p id={`${componentName}-convoTitle`} className="fw-bold">
                            {conversationMessages.title}
                          </p>
                          {conversationMessages.messages.map((message) => {
                            return (
                              <div
                                key={message.id}
                                className="b-all bg-light-part p-2 m-b-10"
                              >
                                <div className="drop-title">
                                  <b id={`${componentName}-convoFullName`}>
                                    {message.sender.firstName +
                                      " " +
                                      message.sender.lastName}
                                  </b>{" "}
                                  <span id={`${componentName}-convoOrgName`}>{message.sender.organizationName}{" "}</span>
                                  <span id={`${componentName}-convoDate`} className="float-end">
                                    <small>
                                      {formatDateTime(message.sendDateTime)}
                                    </small>
                                  </span>
                                </div>
                                <hr className="m-t-5" />
                                <p id={`${componentName}-convoMessageType`}>
                                  <b id={`${componentName}-convoMessageTypeLabel`}>Message Type: </b>
                                  <span id={`${componentName}-convoMessageTypeValue`}>{message.messageType}</span>
                                </p>
                                <p>
                                  {!isEmpty(message?.message) && (
                                    message.message.split('\n').map((item, i) => <StyledSentence key={i}>{item}</StyledSentence>)
                                  )}

                                  {message.linkURI && (
                                    <li>
                                      {linkElement(
                                        message.linkURI,
                                        message.linkText
                                      )}
                                    </li>
                                  )}
                                  {message.exportFiles &&
                                    message.exportFiles.length > 0 &&
                                    message.exportFiles.map((file, idx) => (
                                      <p key={file.fileName}>
                                        {file && (
                                          <li>
                                            <Button variant="link" onClick={() => getFileDownloadGeneric(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/files/get?fileUri=${encodeURIComponent(
                                              file.fileUri
                                            )}&fileName=${file.fileName}`, file.fileName)} id={`${componentName}-mailexportFiles-111-${idx}`}>{file.fileName ? file.fileName : ""}</Button>
                                          </li>
                                        )}
                                        {"  "}
                                      </p>
                                    ))}
                                </p>
                                {message.uploadedFiles &&
                                  message.uploadedFiles.length > 0 &&
                                  message.uploadedFiles.map((file) => (
                                    <p key={file.fileId}>
                                      {file && (
                                        <small>
                                          Attachment:{" "}
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleClickofLink(file)
                                            }
                                            id="message_inbox_file_attachment"
                                          >
                                            {file.fileName ? file.fileName : ""}
                                          </a>
                                        </small>
                                      )}
                                      {"  "}
                                    </p>
                                  ))}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <hr />
                      <label id={`${componentName}-convoReplyLabel`}>Reply</label>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group m-b-10">
                            <label id={`${componentName}-convoUserLabel`} className="d-block">Choose Additional Users</label>
                            {users &&
                              users.length > 0 &&
                              users[0] !== "Empty" ? (
                              <Typeahead
                                id={`${componentName}-convoUserList`}
                                inputProps={{
                                  id: `${componentName}-convoUserList`
                                }}
                                multiple
                                labelKey="email"
                                onChange={(e) => updateUsers(e)}
                                options={users}
                              />
                            ) : users[0] === "Empty" ? (
                              <p id={`${componentName}-convoNoUserList`}>No users found</p>
                            ) : (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div className="form-group m-b-10">
                            <div className="form-group m-b-10">

                              <FileUpload
                                onChange={handleUploadFileChange}
                                clearStateProp={0}
                              />
                            </div>
                          </div>
                          <div className="form-group m-b-0">
                            <label id={`${componentName}-convoUserLabel`} className="d-block">Message<span className="text-danger">*</span></label>
                            <textarea
                              className="form-control"
                              id={`${componentName}-convoReplyValue`}
                              name="message"
                              onChange={(e) => handleReplyChange(e)}
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <div className="me-auto">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id={`${componentName}-convoUrgentValue`}
                          className="custom-control-input"
                          onClick={() => setUrgent(!urgent)}
                          value={urgent}
                        />
                        <span id={`${componentName}-convoUrgentLabel`} className="custom-control-label">
                          Mark As Urgent
                        </span>
                      </label>
                    </div>
                    <Button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitReplyFunc}
                      disabled={isFormValid()}
                      id={`${componentName}-convoSendButton`}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              )}
            </div>
          ) : null}
        </ConversationCard>
      </MessagesCards>
    </div>
  );
};

export default MessageInbox;
