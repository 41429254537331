import { actions } from "./actions";

export function getToggles(dispatch, globalDispatch) {
  return {
    setAccepted: payload => dispatch({ type: actions.TOGGLE_ACCEPTED, payload: payload }),
    setCancelled: payload => dispatch({ type: actions.TOGGLE_CANCELLED, payload: payload }),
    setPatientCancelled: payload => dispatch({ type: actions.TOGGLE_PATIENT_CANCELLED, payload: payload }),
    setDeclined: payload => dispatch({ type: actions.TOGGLE_DECLINED, payload: payload }),
    setPending: payload => dispatch({ type: actions.TOGGLE_PENDING, payload: payload }),
    setCompleted: payload => dispatch({ type: actions.TOGGLE_COMPLETED, payload: payload }),
    setReferralsList: payload => dispatch({ type: actions.SET_REFERRALS_LIST, payload: payload }),
    setTotalSize: payload => dispatch({ type: actions.SET_TOTAL_SIZE, payload: payload }),
  }
}