import React, { useEffect, useState } from "react";

import axios from "../../utils/apiClient";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { Button } from "../../components/buttons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Dropdown from "react-bootstrap/Dropdown";
import cloneDeep from "lodash/cloneDeep";
import styled from "styled-components";

import {
  appointmentModalStatus,
  durationList,
  notificationDelayList,
  units,
  carePathStepsList,
} from "../../utils/textValueLists";
import { formatDate, getDisplayValue } from "../../utils/util";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  CustomMenuStyled,
  DropDownButton,
} from "../../components/dropDownLists/sharedDDL";
import {
  DDLDataMode,
  DDLMode,
  DropDownList,
} from "../../components/dropDownLists/genericDDL";
import Modals from "../../components/modals";
import MultipleUploads from "../../components/MultipleUploads";
import { useSelector, useDispatch } from "react-redux";
import ErrorToast from "../common/ErrorToast";
import { setHours, setMinutes } from "date-fns";
import ManageEvidence from "../quickList/ManageEvidence";

import GapModal from "./GapModal";
import { getFileDownload } from "../../actions/fileUploadActions";
import { FileUpload } from "../../components/FileUpload";

const componentName = "CalendarViewModal";

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
const RedAsterisk = styled.span`
  color: red;
`;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const StyledRow = styled(Row)`
  height: 100%;
  margin-top: -16px;
`;

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/appointment";
const carePathURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile";



const StyledTypeAhead = styled(AsyncTypeahead)``;

const CalendarViewModal = ({
  parent,
  onHideClicked,
  selectedPatient,
  updateParentAppointment,
  removeAppointment,
  patientSpecific,
  patientCarePaths,
}) => {
  const [errors, setErrors] = useState(null);
  const [multipleErrors, setMultipleErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [patient, setPatient] = useState(null);
  const [carePaths, setCarePaths] = useState(null);
  const [patientSearch, setPatientSearch] = useState(null);
  const [selectedCarePath, setSelectedCarePath] = useState(null);
  const [show, setShow] = useState(false);

  const [formBody, setFormBody] = useState({
    provider: {
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      facility: "",
      streetAddress: "",
      specialties: [],
      userAccountId: "",
      type: "",
    },
    appointmentId: null,
    type: "NEW",
    status: "SCHEDULED",
    name: "",
    appointmentDateTime: "",
    durationInMinutes: 0,
    address: "",
    providersToSelect: [],
    shouldSendReminder: true,
    notificationDelay: 0,
    notificationDelayUnit: "HOURS",
    needsTransportation: false,
    note: "",
    files: [],
    uploadedFiles: [],
    patientId: "",
    carePathId: "",
    carePathEvent: "",
  });
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsPatient, setOptionsPatients] = useState([]);
  const [addressList, setAddressList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileAdded, setFileAdded] = useState({});

  const isEmpty = require("is-empty");
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPatient && !patientSpecific) {
      (async function setPatientData() {
        setPatient(selectedPatient);
        await findAddress(selectedPatient.appointment.provider.id, selectedPatient.appointment);
        setTimeout(async () => {
          let stateCopy = cloneDeep(selectedPatient.appointment);
          let cp = {};
          await setSelectedDate(
            new Date(selectedPatient.appointment.appointmentDateTime)
          );
          await setFormBody({ ...stateCopy });
          if (
            selectedPatient.appointment.carePathId &&
            selectedPatient.appointment.carePathName
          ) {
            cp.id = selectedPatient.appointment.carePathId;
            cp.name = selectedPatient.appointment.carePathName;

            setSelectedCarePath(cp);
          }
        }, 500);
      })();
    } else if (!selectedPatient && patientSpecific) {
      setPatient(patientSpecific);
    } else if (selectedPatient && patientSpecific) {
      (async function setPatientData() {
        setPatient(patientSpecific);
        await findAddress(selectedPatient.provider.id, selectedPatient);
        setTimeout(async () => {
          let stateCopy = cloneDeep(selectedPatient);
          let cp = {};
          await setSelectedDate(new Date(selectedPatient.appointmentDateTime));
          await setFormBody({ ...stateCopy });
          if (selectedPatient.carePathId && selectedPatient.carePathName) {
            cp.id = selectedPatient.carePathId;
            cp.name = selectedPatient.carePathName;

            setSelectedCarePath(cp);
          }
        }, 500);
      })();
    }
  }, []);

  const getCarePaths = () => {
    axios
        .get(carePathURL + `/care-paths?patientId=${patient.id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setCarePaths(res.data);
        })
        .catch((err) => {
        });
  };

  useEffect(() => {
    let newState = formBody;
    if (patient?.careGaps?.length) {
      setShow(true);
    }
    if (patient && patient.id) {
      newState.patientId = patient.id;
      setFormBody({ ...newState });
      getCarePaths();
    }
  }, [patient]);

  const handleInputChange = (name, value) => {
    let newState = formBody;
    if (name === "duration") {
      newState.durationInMinutes = value.value;
    }
    if (name === "notificationDelay") {
      newState.notificationDelay = value.value;
    }
    if (name === "notificationDelayUnit") {
      newState.notificationDelayUnit = value.value;
    }
    if (name === "status") {
      newState.status = value.value;
    }
    if (name === "carePathEvent") {
      newState.carePathEvent = value.value;
    }
    if (name === "name") {
      newState.name = value.value;
    }
    if (name === "shouldSendReminder") {
      newState.shouldSendReminder = value;
    }
    if (name === "needsTransportation") {
      newState.needsTransportation = value;
    }
    if (name === "address") {
      newState.address = value.value;
    }
    if (name === "note") {
      newState.note = value.value;
    }
    setFormBody({ ...newState });
  };

  const handleCPChange = (e) => {
    let newState = formBody;
    let cp = {};
    cp.id = e.id;
    cp.name = e.name;
    setSelectedCarePath(cp);
    if (cp) {
      newState.carePathId = cp.id;
      newState.carePathName = cp.name;
      setFormBody({ ...newState });
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const setSelectedDateFunc = (selectedDate) => {
    setSelectedDate(selectedDate);
    let newState = formBody;
    if (selectedDate) {
      const date = new Date(selectedDate);
      newState.appointmentDateTime = date;
      setFormBody({ ...newState });
    }
  };

  const handleChange = (e) => {
    if (e && e[0]) {
      let prov = e[0];
      let newState = formBody;
      if (prov) {
        newState.provider = prov.provider;
        setFormBody({ ...newState });
      }
      findAddress(prov.value, null);
    }
  };

  const handleChangePatient = (e) => {
    if (e && e[0]) {
      findPatient(e[0].value);
    }
  };
  const findPatient = (id) => {
    if (id) {
      const input = {
        filterParams: [
          {
            paramName: "Patient Id",
            paramValue: [id],
            comparison: "eq",
            displayValue: [null],
          },
        ],
        pageNumber: 1,
        pageSize: 15,
        quickListName: "APPOINTMENT_PATIENT",
        sortParams: [],
      };
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL + "/v1/patients/myPatients";
      axios
        .post(SEARCH_TEXT_URL, input, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.patientCardDtos &&
            resp.data.patientCardDtos.length
          ) {
            setPatient(resp.data.patientCardDtos[0]);
          }
        })
        .catch((error) => {
          formatErrorMessage(error);
        });
    }
  };

  const findAddress = (id, appointment) => {
    if (id) {
      id = encodeURIComponent(id);
      axios
        .get(baseURL + `/get-provider-address?providerId=${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i,
              text: i,
            }));
          }
          setAddressList(options);
          let newState = appointment ? appointment : formBody;
          newState.address = options[0].value;
          setFormBody({ ...newState });
        })
        .catch((error) => {
          formatErrorMessage(error);
        });
    }
  };

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const asyncsearchPatient = (query) => {
    setPatientSearch(query);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (patientSearch) {
        handleSearchPatient(patientSearch);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  }, [patientSearch]);

  const handleSearch = (query) => {
    if (query) {
      const patientId =
        patient && patient.id ? encodeURIComponent(patient.id) : "";
      const url =
        baseURL +
        "/get-providers" +
        (query ? "?providerName=" + query : "") +
        (patientId ? "&patientId=" + patientId : "");
      setIsLoading(true);
      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i.id,
              text: i?.firstName + " " + i?.lastName,
              provider: i,
            }));
          }
          setOptions(options);
          setIsLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
        });
    }
  };

  const handleSearchPatient = (query) => {
    if (query) {
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL +
          "/v1/global-search/findPatientInfo?searchString=" +
        query;
      setIsLoadingPatient(true);
      axios
        .get(SEARCH_TEXT_URL, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data && resp.data.patients) {
            options = resp?.data?.patients?.map((i) => ({
              value: i?.id,
              text: i?.fullName + " " + i?.memberId + " DOB: " + formatDate(i?.dateOfBirth),
            }));
          }
          setOptionsPatients(options);
          setIsLoadingPatient(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
          setIsLoadingPatient(false);
        });
    }
  };

  const handleUploadFileChange = (arr) => {
    let newState = formBody;
    setUploadedFiles(arr);
    newState.uploadedFiles = [];
    newState.uploadedFiles.push(...arr);
    setFormBody({ ...newState });
  };

  const enableButton = () => {
    if(temporarilyDisableSubmitButton){
      return false
    }
    if (!selectedPatient) {
      if (
        formBody &&
        formBody.status &&
        formBody.name &&
        formBody.appointmentDateTime &&
        formBody.durationInMinutes &&
        formBody.address &&
        formBody.patientId &&
        formBody.provider
      ) {
        if (formBody.shouldSendReminder) {
          if (formBody.notificationDelay && formBody.notificationDelayUnit) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
        return true;
      } else {
        return false;
      }
    } else {
      if (
        JSON.stringify(selectedPatient.appointment) !== JSON.stringify(formBody)
      ) {
        if (formBody.provider.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const cancelAppointment = (e) => {
    let id;
    if (selectedPatient && patientSpecific) {
      id = selectedPatient.appointmentId;
    } else {
      id = selectedPatient?.appointment?.appointmentId;
    }
    axios
      .delete(baseURL + `/cancel?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        onHideClicked();
        await removeAppointment(id);
      })
      .catch((error) => {
        formatErrorMessage(error);
      });
  };

  const [temporarilyDisableSubmitButton, setTemporarilyDisableSubmitButton]= useState(false)
  const onsubmitAppointment = () => {
    setTemporarilyDisableSubmitButton(true)
    if (selectedPatient && patientSpecific) {
      axios
        .put(baseURL + `/edit`, formBody, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response) => {
          onHideClicked(null, false, "COMPLETE");
          await updateParentAppointment(response.data, "CARD_VIEW");
          setFileUploadResetCounter(fileUploadResetCounter + 1);
          setTemporarilyDisableSubmitButton(false)
        })
        .catch((error) => {
          formatErrorMessage(error);
          setTemporarilyDisableSubmitButton(false)
        });
    } else {
      let newState = formBody;
      newState.carePathId = selectedCarePath?.id;
      axios
        .post(baseURL + `/save`, newState, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response) => {
          onHideClicked();
          await updateParentAppointment(response.data);
          setFileUploadResetCounter(fileUploadResetCounter + 1);
          setTemporarilyDisableSubmitButton(false)
        })
        .catch((error) => {
          formatErrorMessage(error);
          setTemporarilyDisableSubmitButton(false)
        });
    }
  };
  const handleClickofLink = (file) => {
    if (file?.metadata?.fileId) {
      dispatch(
        getFileDownload(file?.metadata?.fileId, file?.metadata?.fileName)
      );
    }
  };

  const formatErrorMessage = (error) => {
    if (error?.response?.data?.message?.includes("|")) {
      var errors = error?.response?.data?.message?.split("|");
      setMultipleErrors(errors);
    } else {
      setErrors(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Container>
      
        <Col sm={4}>
          {errors && errors.length ? (
            <ErrorToast
              className="float-end"
              errorParam={errors}
              multipleErrors={multipleErrors}
            />
          ) : null}
        </Col>
        
        <StyledRow className="mt-3">
          {patient && (
            <Col xs={12}>
              <span id={`${componentName}-patient`}>
                <span id={`${componentName}-patientLabel`} className="fw-bold">Patient:</span>{" "}
                {patient?.firstName && patient?.lastName ? (
                  <span id={`${componentName}-patientValue`}>{patient?.lastName}, {patient?.firstName}</span>
                ) : (
                  <span id={`${componentName}-patientValue`}>{patient?.fullName}</span>
                )}
              </span>
              <div className="mt-1">
                <span id={`${componentName}-memberId`}>{patient?.memberId}</span>{" "}
                <span id={`${componentName}-memberStatus`}>({patient?.active ? (
                  <span id={`${componentName}-memberStatusActive`} className="text-success">Active</span>
                ) : (
                  <span id={`${componentName}-memberStatusInactive`} className="text-danger">Inactive</span>
                )})</span>{" "}
                <span id={`${componentName}-DOB`}>
                  <span id={`${componentName}-DOBLabel`} className="fw-bold">DOB: </span>{" "}
                  <span id={`${componentName}-DOBValue`}>{patient && formatDate(patient?.dateOfBirth)} ({patient?.age})</span>
                </span>
              </div>
              <span>{"    "}</span>
            </Col>
          )}
          <Col sm={8} className="pt-3">
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Row>
                  <Col id={`${componentName}-AppointmentName`} xs={6}>
                    <Form.Label id={`${componentName}-AppointmentNameLabel`} className="fw-bold">
                      Appointment Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => handleInputChange("name", e.target)}
                      type="text"
                      id={`${componentName}-AppointmentNameValue`}
                      value={formBody.name}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col id={`${componentName}-AppointmentDate`} xs={4}>
                    <Form.Label id={`${componentName}-AppointmentDateLabel`} className="fw-bold">
                      Date<span className="text-danger">*</span>
                    </Form.Label>
                    <DatePickerStyled>
                      <DatePicker
                        name="dueDate"
                        filterTime={filterPassedTime}
                        value={selectedDate}
                        selected={selectedDate}
                        onChange={(e) => setSelectedDateFunc(e)}
                        showTimeSelect
                        inputProps={{
                          id: `${componentName}-AppointmentDateValue`
                        }}
                        className="form-control"
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), 0), 6)}
                        maxTime={setHours(setMinutes(new Date(), 30), 18)}
                        popperPlacement="auto-left"
                        autoComplete="off"
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </DatePickerStyled>
                  </Col>
                  <Col sm={3}>
                    <DropdownComponent
                      name="duration"
                      list={durationList}
                      inputProps={{
                        id: `${componentName}-AppointmentDurationValue`
                      }}
                      handleInputChange={handleInputChange}
                      label="Duration:"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.durationInMinutes}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col id={`${componentName}-AppointmentSearch`} xs={4}>
                    <Form.Label id={`${componentName}-AppointmentSearchLabel`} className="fw-bold">
                      Search Providers by Name<span className="text-danger">*</span>
                    </Form.Label>
                    <StyledTypeAhead
                      placeholder={
                        formBody.provider.lastName
                          ? `${formBody.provider.lastName}, ${formBody.provider.firstName}`
                          : patient?.id ? "Search Providers by Name" : "Please Select Patient First"
                      }
                      inputProps={{
                        id: `${componentName}-AppointmentSearchValue`
                      }}
                      labelKey="text"
                      isLoading={isLoading}
                      onSearch={asyncsearch}
                      options={options}
                      onChange={(e) => handleChange(e)}
                      disabled={!patient?.id}
                    />
                  </Col>
                  <Col xs={6}>
                    <DropdownComponent
                      name="address"
                      list={addressList}
                      handleInputChange={handleInputChange}
                      inputProps={{
                        id: `${componentName}-AppointmentAddressValue`
                      }}
                      label="Address"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.address}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col id={`${componentName}-AppointmentPatReminder`} xs={4}>
                    <Form.Label id={`${componentName}-AppointmentPatReminderLabel`} className="fw-bold">
                      Patient Reminder<span className="text-danger">*</span>
                    </Form.Label>
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`custom-inline-${type}`} className="mb-3">
                          <Form.Check
                            custom
                            inline
                            label="Yes"
                            type={type}
                            id={`${componentName}-AppointmentPatReminderYes`}
                            onClick={() =>
                              handleInputChange("shouldSendReminder", true)
                            }
                            checked={formBody.shouldSendReminder}
                          />
                          <Form.Check
                            custom
                            inline
                            label="No"
                            type={type}
                            id={`${componentName}-AppointmentPatReminderNo`}
                            onClick={() =>
                              handleInputChange("shouldSendReminder", false)
                            }
                            checked={!formBody.shouldSendReminder}
                          />
                        </div>
                      ))}
                    </Form>
                  </Col>
                  {formBody.shouldSendReminder && (
                    <Col xs={4}>
                      <DropdownComponent
                        name="notificationDelay"
                        list={notificationDelayList}
                        handleInputChange={handleInputChange}
                        inputProps={{
                          id: `${componentName}-AppointmentNotificationValue`
                        }}
                        label="Notification Delay:"
                        required={true}
                        isOpen={isOpen}
                        val={formBody.notificationDelay}
                      />
                    </Col>
                  )}
                  {formBody.shouldSendReminder && (
                    <Col xs={4}>
                      <DropdownComponent
                        name="notificationDelayUnit"
                        list={units}
                        handleInputChange={handleInputChange}
                        inputProps={{
                          id: `${componentName}-AppointmentUnitsValue`
                        }}
                        label="Units:"
                        required={true}
                        isOpen={isOpen}
                        val={formBody.notificationDelayUnit}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col id={`${componentName}-AppointmentTransport`} xs={4}>
                    <Form.Label id={`${componentName}-AppointmentTransportLabel`} className="fw-bold">
                      Patient Needs Transportation<span className="text-danger">*</span>
                    </Form.Label>
                    <Form>
                      {["radio"].map((type) => (
                        <div
                          key={`custom-inline-transportation-${type}`}
                          className="mb-3"
                        >
                          <Form.Check
                            custom
                            inline
                            label="Yes"
                            type={type}
                            id={`${componentName}-AppointmentTransportYes`}
                            onClick={() =>
                              handleInputChange("needsTransportation", true)
                            }
                            checked={formBody.needsTransportation}
                          />
                          <Form.Check
                            custom
                            inline
                            label="No"
                            type={type}
                            id={`${componentName}-AppointmentTransportNo`}
                            onClick={() =>
                              handleInputChange("needsTransportation", false)
                            }
                            checked={!formBody.needsTransportation}
                          />
                        </div>
                      ))}
                    </Form>
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={6}>
                    <Form.Group id={`${componentName}-AppointmentCarePath`}>
                      <Form.Label id={`${componentName}-AppointmentCarePathLabel`} className="fw-bold d-block">
                        Care Program
                      </Form.Label>
                      <Dropdown
                        value={selectedCarePath ? selectedCarePath.name : null}
                      >
                        <Dropdown.Toggle
                          as={DropDownButton}
                          id={`${componentName}-AppointmentCarePathDropDown`}
                          cusWidth="100% !important"
                        >
                          {selectedCarePath ? (
                            selectedCarePath.name
                          ) : (
                            <span>-Select-</span>
                          )}
                          <i className="fa fa-chevron-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
                          {patient &&
                          carePaths &&
                          carePaths.length > 0
                            ? carePaths.map((cp) => (
                                <Dropdown.Item
                                  key={cp.id}
                                  onClick={() => handleCPChange(cp)}
                                >
                                  {cp.name}
                                </Dropdown.Item>
                              ))
                            : patientCarePaths.length
                            ? patientCarePaths.map((cp) => (
                                <Dropdown.Item
                                  key={cp.id}
                                  onClick={() => handleCPChange(cp)}
                                >
                                  {cp.name}
                                </Dropdown.Item>
                              ))
                            : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <DropdownComponent
                      name="carePathEvent"
                      list={carePathStepsList}
                      handleInputChange={handleInputChange}
                      inputProps={{
                        id: `${componentName}-AppointmentCarePathEventValue`
                      }}
                      label="Care Program Event:"
                      isOpen={isOpen}
                      val={formBody.carePathEvent}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={4}>
                    <DropdownComponent
                      name={"status"}
                      list={appointmentModalStatus}
                      handleInputChange={handleInputChange}
                      inputProps={{
                        id: `${componentName}-AppointmentStatusValue`
                      }}
                      label="Status:"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.status}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col id={`${componentName}-AppointmentAddNote`} xs={6}>
                    <Form.Label id={`${componentName}-AppointmentAddNoteLabel`} className="fw-bold">
                      Add A Note
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id={`${componentName}-AppointmentAddNoteValue`}
                      onChange={(e) => handleInputChange("note", e.target)}
                      value={formBody.note}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          {!selectedPatient && !patientSpecific ? (
            <Col id={`${componentName}-AppointmentPatient`} sm={4} className="border-start bg-light py-3">
              <Form.Label id={`${componentName}-AppointmentPatientLabel`} className="fw-bold">
                Patient<span className="text-danger">*</span>
              </Form.Label>
              <StyledTypeAhead
                inputProps={{
                  id: `${componentName}-AppointmentPatientValue`
                }}
                labelKey="text"
                isLoading={isLoadingPatient}
                onSearch={asyncsearchPatient}
                options={optionsPatient}
                onChange={(e) => handleChangePatient(e)}
                placeholder="Search Patients by Name"
                // disabled={selectedPatient}
              />
            </Col>
          ) : null}
        </StyledRow>
        <Row className="mt-3 mb-5">
          <Col xs={4}>
            <FileUpload
              onChange={handleUploadFileChange}
              clearStateProp={fileUploadResetCounter}
            />{" "}
          </Col>
          <Col xs={4}>
            {selectedPatient?.appointment?.files &&
              selectedPatient?.appointment?.files.length > 0 &&
              selectedPatient?.appointment?.files.map((file) => (
                <p key={file?.metadata?.fileId}>
                  {file && (
                    <small>
                      <a
                        href="#"
                        onClick={() => handleClickofLink(file)}
                      >
                        {file?.metadata?.fileName}
                      </a>
                    </small>
                  )}
                  {"  "}
                </p>
              ))}
          </Col>
        </Row>
        <StyledFooter>
          {selectedPatient && (!patientSpecific || patientSpecific) ? (
            <Button id={`${componentName}-AppointmentCancelAppButton`}
              className="bg-danger border-danger"
              onClick={(e) => cancelAppointment(e)}
            >
              Cancel Appointment
            </Button>
          ) : null}{" "}
          <Button id={`${componentName}-AppointmentCloseAppButton`} onClick={(e) => onHideClicked()}>Close</Button>{" "}
          <Button id={`${componentName}-AppointmentSubmitAppButton`}
            disabled={!enableButton()}
            onClick={() => onsubmitAppointment()}
          >
            Submit
          </Button>
        </StyledFooter>
        <Modals
          show={
            show &&
            patient.careGaps.length != null &&
            patient.careGaps.length > 0
          }
          onHide={() => setShow(false)}
          title="Care Opportunities"
          titleid={`${componentName}-CareOppModal`}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          completeBtn={"Close"}
          completeBtnId={`${componentName}-CloseButton`}
          hideFooter={true}
          hideClickButton={true}
          showExtraButton={false}
          body={
            <GapModal
              patient={patient}
              setShow={setShow}
              updateParent={updateParentAppointment}
            />
          }
        />
      </Container>
    </>
  );
};

const DropdownComponent = ({
  name,
  val,
  label,
  list,
  isOpen,
  handleInputChange,
  required,
}) => {
  return (
    <div>
      <Form.Label className="fw-bold">
        {label} {required ? <RedAsterisk>*</RedAsterisk> : ""}
      </Form.Label>
      <Dropdown value={val}>
        <Dropdown.Toggle
          as={DropDownButton}
          cssOpen={isOpen}
          cusWidth="100% !important"
        >
          {val ? getDisplayValue(list, val) : <span>-Select-</span>}
          <i className="fa fa-chevron-down" />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
          {list.map((type) => (
            <Dropdown.Item
              key={type.value}
              onClick={() => handleInputChange(name, type)}
            >
              {type.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CalendarViewModal;
