// In this list we are not talking about patients per se,
// we are talking about events that occur around the patients (referral, adt).
// due to this, a patient can appear many times in the list for each type of event that may have registered.
// that is the reason why the patient ID does not work for us, and we must build a compound ID.
// this method allows us to build a key composed of the patient ID, the source and the event ID.
// this key will be used to select events from the table or to send a group of selected events to be downloaded via CSV
export const buildCompositeKey = (patientEvent) => {
    const compositeKeyBuilder = (source, eventId) => ({
        patientId: patientEvent.id,
        source: source,
        eventId: eventId,
        careId: patientEvent?.carePathStepDto?.id
    });
    const referralSource = 'REFERRAL';
    const adtSource = 'ADT';
    const patientSource = "PATIENT";

    if (patientEvent?.source === referralSource) {
        const referral = patientEvent?.referralDtoList?.[0];
        if (referral) {
            return compositeKeyBuilder(referralSource, referral?.id);
        }
    } else if (patientEvent?.source === adtSource) {
        return compositeKeyBuilder(adtSource, patientEvent?.adtId + "");
    } else if (!patientEvent?.source) {
        return compositeKeyBuilder(patientSource, patientEvent?.id);
    }

    return null;
}

export const areCompositeKeysEquals = (compositeKeyA, compositeKeyB) => {
    return compositeKeyA.source === compositeKeyB.source &&
        compositeKeyA.patientId === compositeKeyB.patientId &&
        compositeKeyA.eventId === compositeKeyB.eventId &&
        compositeKeyA.careId === compositeKeyB.careId
}