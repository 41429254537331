import { GET_REFERRALS, GET_EVENTS, UPDATE_REFERRAL } from "../../actions/types";

const initialState = {
  refList: [],
  referralDetails: [],
    updateSuccess: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRALS:
      return {
        ...state,
        refList: payload,
      };
      case GET_EVENTS:
      return {
        ...state,
        events: payload,
      };
        case UPDATE_REFERRAL:
          return {
            ...state,
            referralDetails: payload,
            updateSuccess: true
          };
    default:
      return state;
  }
}
