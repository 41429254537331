import React, { useEffect, useRef } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Badge, Table } from "react-bootstrap";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  index,
  data,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

const PieGraph = ({ data }) => {
  return (
    <>
      <PieChart width={400} height={400} className="mx-auto">
        <Pie
          data={data}
          cx={200}
          cy={200}
          label
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <Table>
        <thead>
          <tr>
            <th>
              Low{" "}
              <Badge
                pill
                style={{ backgroundColor: "#4A90E2", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
            <th>
              Very High{" "}
              <Badge
                pill
                style={{ backgroundColor: "#EC2B2B", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
            <th>
              Unknown{" "}
              <Badge
                pill
                style={{ backgroundColor: "#D1D3D5", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
            <th>
              Medium{" "}
              <Badge
                pill
                style={{ backgroundColor: "#FCE000", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
            <th>
              Very Low{" "}
              <Badge
                pill
                style={{ backgroundColor: "#3DCA37", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
            <th>
              High{" "}
              <Badge
                pill
                style={{ backgroundColor: "#EE7600", display: "inline-grid" }}
                className="border"
              ></Badge>
            </th>
          </tr>
        </thead>
      </Table>
    </>
  );
};

export default PieGraph;
