import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  Toast,
  useAccordionButton,
  Button
} from "react-bootstrap";
import { EQHDatePicker } from "../../../components/datePicker";
import cloneDeep from "lodash/cloneDeep";
import { format } from "date-fns";
import { exclusionStatus } from "../../../utils/textValueLists";
import isEmpty from "is-empty";
import { RedAsterisk, formatDate, getKeyAndId, logDebug } from "../../../utils/util";
import { CareOpportunitiesList } from "./CareOpportunityList";
import { SimpleDDL } from "../../../components/dropDownLists/simpleDDL";

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
      <i className={isCurrentEventKey ? "fa fa-minus me-1" : "fa fa-plus me-1"} ></i>{" "}
      <span className="fw-bold">{children}</span>
    </Button>
  );
}

const CARE_OPPORTUNITIES_TYPE = {
  toc: "TOC",
  CareOpportunities: "CareOpp",
  Validation: "Validation",
};

const DEFAULT_UPDATE_CARE_GAP_DTO = {
  ids: [],
  newStatus: null,
  additionalInfos: [],
  additionalInfoCaption: null,
  exclusionCriteria: [],
};

const DEFAULT_INFO = {
  requireDOS: false,
  requireStatus: false,
  removeDateLimit: false,
  opportunityDosError: null,
  fuhSelected : false,
  fuhMaxDate : null
};

const getDefaultInfo = () => {
  return cloneDeep(DEFAULT_INFO);
};

const getDefaultUpdateDTO = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_UPDATE_CARE_GAP_DTO);
  dto.newDateOfService = dateOfService;
  return dto;
};

const DEFAULT_DATA = {
  selectedGaps: [],
  additionalGapIdsSelected: [],
  errors: [],
  confirmationWarning: "",
  finalizeForBackend: () => false,
  info: getDefaultInfo(),
};

const getDefaultData = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_DATA);
  dto.updateCareGapDto = getDefaultUpdateDTO(dateOfService);
  return dto;
};

const CareOpportunitiesAccordion = ({
  opportunities,
  additionalOpportunities,
  isSearching,
  patient,
  componentName,
  onChange,
  disableCheckList = false,
  type = CARE_OPPORTUNITIES_TYPE.CareOpportunities,
  title = "Care Opportunities",
  activeKey,
  resetKeyProp = null,
  accordionKey,
  statuses,
  dateOfServiceOverride,
  addToSelecedOpportunityParams,
  processAddToSelecedOpportunityParamsKeyProp,
  selectedParentChildCareOpportunites={},
  setCareOppMsg=(msg)=>{careOppMsg=msg;},
  careOppMsg="",
}) => {
  const [trackDateOfService, setTrackDateOfService] = useState(
    dateOfServiceOverride
  );
  const [data, setData] = useState(() => getDefaultData(dateOfServiceOverride));
  const [exclusionDetails, setExclusionDetails] = useState([]);
  const [resetKey, setResetKey] = useState(null);
  const [
    processAddToSelecedOpportunityParamsKey,
    setProcessAddToSelecedOpportunityParamsKey,
  ] = useState(null);

  const setDataAndCallOnChange = (data) => {
    setData(data);
    data.errors = getErrors(data);
    data.finalizeForBackend = function () {
      if (this.selectedGaps && this.updateCareGapDto) {
        const ids = [];
        this.selectedGaps.forEach((x) => {
          ids.push(x.careGapSummary.id);
        });
        this.updateCareGapDto.ids = ids;
      }
    };
    onChange(data);
  };

  const getErrors = (data) => {
    let errors = [];
    if (data.selectedGaps.length) {
      if (data.updateCareGapDto.newStatus?.name) {
        if (
          (data.info.requireDOS && !data.updateCareGapDto.newDateOfService) ||
          data.info.opportunityDosError
        ) {
          errors.push("Date of Service is Required");
        }
      } else {
        errors.push("Status is Required");
      }
    }

    return errors;
  };

  if (dateOfServiceOverride !== trackDateOfService) {
    let dataClone = { ...data };
    dataClone.updateCareGapDto["newDateOfService"] = dateOfServiceOverride;
    setDataAndCallOnChange(dataClone);

    setTrackDateOfService(dateOfServiceOverride);
  }

  const reset = () => {
    setExclusionDetails([]);
    setCareOppMsg("");
    setDataAndCallOnChange(getDefaultData(dateOfServiceOverride));
  };

  if (resetKeyProp !== resetKey) {
    reset();
    setResetKey(resetKeyProp);
  }

  const applyConditionalRequirements = (dataClone, dateError) => {
    const obj = getDefaultInfo();

    if (!dataClone.updateCareGapDto.exclusionCriteria.length) {
      dataClone.selectedGaps.forEach((gap) => {
        if (gap.careGapSummary.requireDOS) {
          obj.requireDOS = true;
        }
      });
      if (dataClone.selectedGaps.length) {
        obj.requireStatus = true;
      }
      if (dateError) {
        obj.opportunityDosError = dateError;
      }
      if (
        dataClone.updateCareGapDto?.newStatus?.name === "Scheduled Appointment"
      ) {
        obj.removeDateLimit = true;
      }
    }

    dataClone.selectedGaps.forEach((gap) => {
      if (gap.careGapSummary.abbreviation === "FUH") {
        obj.fuhSelected = true;
        obj.fuhMaxDate = new Date(gap.careGapSummary.dueDate);
      }
    });

    dataClone.info = obj;
  };

  const handleCriteriaChange = (criteria, gapId, checked) => {
    let dataClone = { ...data };
    if (checked) {
      dataClone.updateCareGapDto.exclusionCriteria = [criteria].concat(
        dataClone.updateCareGapDto.exclusionCriteria
      );
      dataClone.updateCareGapDto.exclusionCriteria.forEach((crit) => {
        crit.enabled = true;
      });
      dataClone.updateCareGapDto.newStatus = exclusionStatus;
      dataClone.selectedGaps = dataClone.selectedGaps.filter(
        (x) => x.careGapSummary.id === gapId
      );
    } else {
      dataClone.updateCareGapDto.exclusionCriteria =
        dataClone.updateCareGapDto.exclusionCriteria.filter(
          (i) => i.id !== criteria.id
        );

      if (!dataClone.updateCareGapDto.exclusionCriteria.length) {
        // handleExclusionLimitsReset();
        dataClone.updateCareGapDto.newStatus = null;
      }
    }

    updateExclusionDetials(dataClone);
    applyConditionalRequirements(dataClone);
    setDataAndCallOnChange(dataClone);
  };

  const handleOpportunityChange = (name, value) => {
    let finalValue = value;
    let dataClone = { ...data };
    let dateError = null;

    switch (name) {
      case "newDateOfService":
        dateError = value?.error;
        finalValue = value?.strDate;
        // if (value.strDate >= originalVisitDate) {
        //   setVisitDate(value.strDate);
        // }
        break;
      case "newStatus":
        if (
          value?.name !== "Scheduled Appointment" &&
          dataClone.updateCareGapDto.newDateOfService
        ) {
          //clear DOS if in the future and not schedule appt
          if (
            new Date(dataClone.updateCareGapDto.newDateOfService) > new Date()
          ) {
            dataClone.updateCareGapDto.newDateOfService = null;
          }
        }
        if (
          value?.name === "Scheduled Appointment" &&
          dataClone.updateCareGapDto.newDateOfService
        ) {
          //clear DOS if in the past and schedule appt
          if (
            new Date(dataClone.updateCareGapDto.newDateOfService) < new Date()
          ) {
            dataClone.updateCareGapDto.newDateOfService = null;
          }
        }
        break;
      default:
        break;
    }

    dataClone.updateCareGapDto[name] = finalValue;
    applyConditionalRequirements(dataClone, dateError);
    setDataAndCallOnChange(dataClone);
  };

  const getExclustionCriterias = (gap) => {
    let gapCriteria = [];
    if (
      gap.careGapSummary.exclusionCriteria.length > 1 ||
      (gap.careGapSummary.exclusionCriteria.length === 1 &&
        gap.careGapSummary.exclusionCriteria[0].name !== "No Exclusions")
    ) {
      gap.careGapSummary.exclusionCriteria.forEach((item) => {
        if (item.name !== "No Exclusions") {
          item.gapId = gap.careGapSummary.id;
          gapCriteria.push(item);
        }
      });
    }

    return gapCriteria;
  };

  const updateExclusionDetials = (dataClone) => {
    let newExclusions = [];
    dataClone.selectedGaps.forEach((gap) => {
      let gapCriteria = getExclustionCriterias(gap);
      if (gapCriteria.length) {
        let newExclusion = {
          gap: gap.careGapSummary.abbreviation,
          gapId: gap.careGapSummary.id,
          criterias: gapCriteria,
        };
        newExclusions.push(newExclusion);
      }
    });

    setExclusionDetails(newExclusions);
  };

  //to initiate global count after loading opportunities
  useEffect(()=>{
    let dataClone = { "confirmationWarning":"" ,"selectedGaps":[]};
    applyCareGapMessage(dataClone);
  },[opportunities]);

  const applyCareGapMessage = (dataClone) => {
    const parentGapsAbbreviations = ["WC15", "WC30", "WCV", "AWV", "AAWV", "HRM", "ACV", "ADT-IP","FUH"];
    let oppFoundCount = 0;
    let selOppFoundCount = 0;
    let childParentGapsAbbreviationsMap = {
      "AWV" : ["COAF","COAP","IMA","CIS","WCC-BMI","UCHN","ECGPR","UCDM","WCC-NUT","WCC-PA","OMW","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "AAWV" : ["COAF","COAP","IMA","CIS","WCC-BMI","UCHN","ECGPR","UCDM","WCC-NUT","WCC-PA","OMW","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "ACV" : ["COAF","COAP","IMA","CIS","WCC-BMI","UCHN","ECGPR","UCDM","WCC-NUT","WCC-PA","OMW","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "WCV" : ["COAF","COAP","UCHN","ECGPR","UCDM","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "WC15" : ["COAF","COAP","UCHN","ECGPR","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "WC30" : ["COAF","COAP","UCHN","ECGPR","BCS","CCS","COL","SSD","CDCE","CDCN"],
      "ADT-IP" : ["MRP"],
      "FUH" : [],
      "HRM" : ["UCHN","ECGPR","UCDM","RCAI","RCAD","RCBB","RCCMA","RCCMC","SPC","SUPD","OMW","BCS","CCS","COL","SSD","CDCE","CDCN"]
    }

    let foundGapsList = []
    opportunities.forEach((x) => {
      if (childParentGapsAbbreviationsMap[x.careGapSummary.abbreviation]) {
        oppFoundCount++;
        foundGapsList.push(x.careGapSummary.measureYear+"_"+x.careGapSummary.abbreviation);
        opportunities.forEach((y) => {
          if(childParentGapsAbbreviationsMap[x.careGapSummary.abbreviation].includes(y.careGapSummary.abbreviation)
          && !foundGapsList.includes(y.careGapSummary.measureYear+"_"+y.careGapSummary.abbreviation)){
            foundGapsList.push(y.careGapSummary.measureYear+"_"+y.careGapSummary.abbreviation)
            oppFoundCount++;
          }
        });
      }
    });
     let isAnyOneParentSelected = false;
    dataClone.selectedGaps.forEach((x) => {
     if (parentGapsAbbreviations.includes(x.careGapSummary.abbreviation)) {
       isAnyOneParentSelected = true;
     }
      if (foundGapsList.includes(x.careGapSummary.measureYear+"_"+x.careGapSummary.abbreviation)) {
        selOppFoundCount++;
      }
    });
    logDebug(
      () =>
        `oppFoundCount:${oppFoundCount}--selOppFoundCount:${selOppFoundCount}---isAnyOneParentSelected:${isAnyOneParentSelected}`
    );

    //save count and parent selection at globally
    if(selectedParentChildCareOpportunites.hasOwnProperty(type)){
      const selectedParentChildCareOpportunity = selectedParentChildCareOpportunites[type];
      selectedParentChildCareOpportunity["oppFoundCount"] = oppFoundCount;
      selectedParentChildCareOpportunity["isAnyOneParentSelected"] = isAnyOneParentSelected;
      selectedParentChildCareOpportunity["selOppFoundCount"] = selOppFoundCount;
    }

    //find consolidated count and parent selection
    oppFoundCount = 0;
    selOppFoundCount = 0;
    for(const key in selectedParentChildCareOpportunites){
      const selectedParentChildMetric = selectedParentChildCareOpportunites[key];
      isAnyOneParentSelected = selectedParentChildMetric["isAnyOneParentSelected"]?selectedParentChildMetric["isAnyOneParentSelected"]:isAnyOneParentSelected;
      oppFoundCount = oppFoundCount + selectedParentChildMetric["oppFoundCount"];
      selOppFoundCount = selOppFoundCount + selectedParentChildMetric["selOppFoundCount"];
    }


    if (oppFoundCount > selOppFoundCount && selOppFoundCount > 0 && isAnyOneParentSelected) {
      setCareOppMsg(
        "Please be sure to designate a status for all remaining care opportunities while actioning the Wellness Visit."
      );
      dataClone.confirmationWarning =
        "Are you sure you'd like to proceed without managing all care opportunities?";
    } else {
      setCareOppMsg("");
      dataClone.confirmationWarning = "";
    }
  };

  const updateSelected = (item, key, selected, gapType) => {
    let dataClone = { ...data };

    if (gapType === "additional") {
      if (dataClone.additionalGapIdsSelected.includes(item.careGapSummary.id)) {
        dataClone.additionalGapIdsSelected =
          dataClone.additionalGapIdsSelected.filter(
            (x) => x !== item.careGapSummary.id
          );
      } else {
        dataClone.additionalGapIdsSelected.push(item.careGapSummary.id);
      }
    } else {
      if (selected) {
        dataClone.selectedGaps = [item].concat(dataClone.selectedGaps);
      } else {
        dataClone.selectedGaps = dataClone.selectedGaps.filter(
          (i) => i.careGapSummary.id !== item.careGapSummary.id
        );

        //Removed Criteria Linked To Gap That Was Just UnChecked
        dataClone.updateCareGapDto.exclusionCriteria = [];

        if (
          !dataClone.selectedGaps.length &&
          dataClone.updateCareGapDto.exclusionCriteria.length
        ) {
          dataClone.updateCareGapDto.newStatus = null;
        }

        if (!dataClone.selectedGaps.length) {
          dataClone.updateCareGapDto.newStatus = {
            source: "",
            closesGap: true,
            name: "",
          };
          dataClone.updateCareGapDto.newDateOfService =
            dateOfServiceOverride ?? null;
          //setVisitDate(originalVisitDate);
        }
      }

      //checkForVisits();
      applyCareGapMessage(dataClone);
      applyConditionalRequirements(dataClone);
      updateExclusionDetials(dataClone);
    }

    setDataAndCallOnChange(dataClone);
  };

  if (
    processAddToSelecedOpportunityParamsKeyProp !==
    processAddToSelecedOpportunityParamsKey
  ) {
    setProcessAddToSelecedOpportunityParamsKey(
      processAddToSelecedOpportunityParamsKeyProp
    );
    if (
      addToSelecedOpportunityParams &&
      ((addToSelecedOpportunityParams.type === "careGap" &&
        type === CARE_OPPORTUNITIES_TYPE.CareOpportunities) ||
        (addToSelecedOpportunityParams.type === "transitionsOfCare" &&
          type === CARE_OPPORTUNITIES_TYPE.toc))
    ) {
      let careOpp = data.selectedGaps.find(
        (x) => x.careGapSummary.id === addToSelecedOpportunityParams.id
      );
      if (!careOpp) {
        careOpp = opportunities.find(
          (x) => x.careGapSummary.id === addToSelecedOpportunityParams.id
        );
        if (careOpp) {
          updateSelected(careOpp, type, true, "main");
        }
      }
    }
  }

  return (
    <Accordion
      id={`${componentName}-AllCareOpportunities`}
      defaultActiveKey={activeKey}
    >
      <Card>
        <Card.Header
          eventKey={accordionKey}
          id={`${componentName}-CareOpp`}
          className="cursor-p"
        >
          <PlusMinusToggle eventKey={accordionKey}>
            <span id={`${componentName}-CareOppHeader`}>{title}</span>
          </PlusMinusToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={accordionKey}>
          <Card.Body>
            <Row className="mb-3">
              <Col sm={6}>
                <div className="mb-3">
                  <label
                    id={`${componentName}-CareOppTitle`}
                    className="d-block"
                  >
                    {title}<RedAsterisk/>
                  </label>
                  <span id={`${componentName}-CareOppSelected`}>
                    {data.selectedGaps.length} Selected
                  </span>
                </div>
                {isSearching && (
                  <>
                    <Spinner
                      className="me-3"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                )}
                <CareOpportunitiesList
                  opportunities={opportunities}
                  additionalOpportunities={additionalOpportunities}
                  selectedOpportunities={data.selectedGaps}
                  onChange={updateSelected}
                  listType={type}
                  componentName={componentName}
                  patient={patient}
                  disableCheckList={disableCheckList}
                  disableCheckbox={(gap) =>
                    data.updateCareGapDto.exclusionCriteria?.length
                      ? !data.updateCareGapDto.exclusionCriteria.some(
                          (x) => x.gapId === gap.careGapSummary.id
                        )
                      : false
                  }
                />
                {careOppMsg && (
                  <span className="fw-bold text-danger">
                    {careOppMsg}
                  </span>
                )}
              </Col>
              <Col sm={6} className="border bg-light py-3">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label
                        id={`${componentName}-CareOpportunitiesStatusLabel`}
                        className="d-block"
                      >
                        Status<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="me-2">
                        <SimpleDDL
                          id={`${componentName}-${type}-status-ddl`}
                          list={statuses}
                          textSelector={(x) => x.name}
                          valueSelector={(x) => x}
                          getKeyFromListItem={(item) =>
                            item?.name
                          }
                          onChange={(x) =>
                            handleOpportunityChange("newStatus", x)
                          }
                          disabled={!data.info.requireStatus}
                          selectedKey={data.updateCareGapDto?.newStatus?.name}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      {data.info.opportunityDosError ? (
                        <Toast>
                          <Toast.Header>
                            <strong
                              id={`${componentName}-CareOpportunitiesErrorIcon`}
                              className="me-auto fw-bold text-danger"
                            >
                              <i className="fas fa-exclamation-triangle"></i>{" "}
                            </strong>
                            <small
                              id={`${componentName}-CareOpportunitiesErrorText`}
                            >
                              {data.info.opportunityDosError}
                            </small>
                          </Toast.Header>
                        </Toast>
                      ) : null}
                      <Form.Label
                        id={`${componentName}-CareOpportunitiesDOSLabel`}
                        className="d-block"
                      >
                        Date of Service
                        {data.info.requireDOS ? (
                          <span className="text-danger">*</span>
                        ) : null}
                      </Form.Label>
                      <EQHDatePicker
                        id={`${componentName}-CareOpportunitiesDOSValue`}
                        value={data.updateCareGapDto.newDateOfService}
                        onChange={(e) => {
                          handleOpportunityChange("newDateOfService", e);
                        }}
                        min={
                          data.info.removeDateLimit
                            ? format(new Date(), "yyyy-MM-dd")
                            : null
                        }
                        max={
                          data.info.removeDateLimit
                            ? null
                            : data.info.fuhSelected && new Date() >= data.info.fuhMaxDate
                                ? format(data.info.fuhMaxDate, "yyyy-MM-dd")
                                : format(new Date(), "yyyy-MM-dd")
                        }
                        disabled={!data.info.requireDOS}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {exclusionDetails.length ? (
                  <Accordion>
                    <Card>
                      <Card.Header
                        eventKey="0"
                        className="cursor-p"
                      >
                        <PlusMinusToggle eventKey="0">
                          <span
                            id={`${componentName}-CareOpportunitiesECLabel`}
                          >
                            Exclusion Criteria
                          </span>
                        </PlusMinusToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {exclusionDetails.length
                            ? exclusionDetails.map((exclusion, idx) => (
                                <div
                                  id={`${componentName}-CareOpportunitiesECGap-${idx}`}
                                  key={exclusion.gapId}
                                >
                                  <label
                                    id={`${componentName}-CareOpportunitiesECGapLabel-${idx}`}
                                    className="d-block mb-1"
                                  >
                                    ({exclusion.gap})
                                  </label>
                                  {exclusion.criterias.map((criteria) => (
                                    <div
                                      className="d-block mb-1"
                                      key={criteria.id}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`${componentName}-CareOpportunitiesECGapCheck-${idx}`}
                                        selected={data.updateCareGapDto.exclusionCriteria.find(
                                          (x) => x.id === criteria.id
                                        )}
                                        onClick={(e) =>
                                          handleCriteriaChange(
                                            criteria,
                                            exclusion.gapId,
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                      <span
                                        id={`${componentName}-CareOpportunitiesECGapValue-${idx}`}
                                      >
                                        {criteria.name}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              ))
                            : null}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export { CareOpportunitiesAccordion, CARE_OPPORTUNITIES_TYPE, getDefaultData };
