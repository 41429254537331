import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import {EQHToastProvider} from "./components/toast/EQHToast.context";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import EQHToast from "./components/toast/EQHToast";

// Normally you should not be adding css here!
const GlobalStyle = createGlobalStyle`
  ${normalize}

  
`;
const queryClient = new QueryClient()


ReactDOM.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
  <Provider store={store}>
    <Router>
      <AuthProvider>
        <EQHToastProvider>
          <GlobalStyle></GlobalStyle>
          <App/>
          <EQHToast/>
          <ReactQueryDevtools initialIsOpen={true} />
        </EQHToastProvider>
      </AuthProvider>
    </Router>
  </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();