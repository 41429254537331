import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropDownButton, CustomMenuStyled } from "./sharedDDL";

const SimpleDDL = ({
  id,
  list,
  textSelector,
  valueSelector,
  onChange,
  disabled,
  selectedKey,
  getKeyFromListItem = valueSelector,
  isValueSelectorUniqueForId = true
}) => {
  if (!list) {
    list = [];
  }
  let value = list.find((x, idx) => getKeyFromListItem(x, idx) === selectedKey);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id={id}
        as={DropDownButton}
        cusWidth="100% !important"
        disabled={disabled}
      >
        <span>{value == null ? "-Select-" : textSelector(value)}</span>
        <i className="fa fa-chevron-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
        <Dropdown.Item
          id={`${id}-option-select`}
          style={{}}
          onClick={() => onChange(null)}
          active={value == null}
        >
          {"-Select-"}
        </Dropdown.Item>
        {list.map((item, idx) => (
          <Dropdown.Item
            key={`${id}-option-${valueSelector ? isValueSelectorUniqueForId ? valueSelector(item, idx) : idx : idx}`}  
            id={`${id}-option-${valueSelector ? isValueSelectorUniqueForId ? valueSelector(item, idx) : idx : idx}`}
            style={{}}
            onClick={() => onChange(valueSelector(item, idx))}
            active={value == null ? false : getKeyFromListItem(value, idx) === getKeyFromListItem(item, idx)}
          >
            {textSelector(item)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { SimpleDDL };
