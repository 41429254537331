import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import DropDownMenu from '../../../../caremgmt/worklist/components/DropDownMenu';
import styled from 'styled-components';
import { useAppointmentsContext } from '../context/Appointments.context';
import { useAuth } from '../../../../../context/authContext';

const componentName = 'AppointmentsCardClosed'
const colors = {
  SCHEDULED: {
    color: '#64a456',
    background: '#eef5ec'
  },
  COMPLETED: {
    color: '#9fa1a3',
    background: '#eaebeb'
  },
  NO_SHOW: {
    color: '#e38736',
    background: '#faebdd'
  },
  CONFIRMED: {
    color: '',
    background: '#1c8bbf'
  },
  CANCELLED: {
    color: '#D12026',
    background: '#F8DEDF'
  }
}

const texts = {
  CONFIRMED:'Confirmed',
  NO_SHOW: 'No Show',
  COMPLETED: 'Completed',
  SCHEDULED: 'Scheduled',
  CANCELLED: 'Cancelled'
}

const AppointmentsBadgeStyled = styled.label`
  color: ${(props) => colors?.[props.status]?.color};
  background-color: ${(props) => colors?.[props.status]?.background};
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
`;

const AppointmentsBadgeStyle = ({ status }) => {
  return (
    <AppointmentsBadgeStyled
      status={status}
      className="badge rounded-pill border-0"
    >
      {texts?.[status]}
    </AppointmentsBadgeStyled>
  );
};

function AppointmentsCardClosed({ appointment, setOpen, opened, idx, setFormBody }) {
  const {
    setSelectedDate,
    toggleAddNewAppointment,
    toggleShowAddAppointment,
    toggleIsEdit
  } = useAppointmentsContext();
  const auth = useAuth();
  const hasWriteAccess = auth.hasAuthority("AUTH_PD_APPOINTMENTS_WRITE_ACCESS");

  return (
    <>
    <Container fluid>
      <Row className="text-center align-items-center">
        <Col style={{ maxWidth: '30px' }}>
          <Button id={`${componentName}-ccTitle-${idx}`}
            onClick={() => setOpen(!opened)} variant="link"
            className="text-start text-dark p-0 text-decoration-none btn btn-link w-50 btn btn-link">
            {!opened
              ? <i id={`${componentName}-ccPlus-${idx}`}
                className="fa-solid fa-chevron-right me-4" />
              : <i id={`${componentName}-ccMinus-${idx}`}
                className="fa-solid fa-chevron-down me-4" />}
          </Button>
        </Col>
        <Col className="text-start">
          <p className="fs-5 fw-bold mb-1 d-block lh-1" style={{ fontSize: '14px' }}>
            {appointment?.name}
          </p>
          <p className="mb-0 text-muted" style={{ fontSize: '1rem' }}>
            {appointment?.provider?.firstName} {appointment?.provider?.lastName}
          </p>
        </Col>
        <Col className='d-flex align-items-center'>
          <Col className='text-end'>
            <AppointmentsBadgeStyle status={appointment?.status} />
          </Col>
          <Col className='text-end' style={{ maxWidth: '50px' }}>
            <div id={`${componentName}-AppointmentsActions`} className="btn-group">
              <DropDownMenu
                idx={idx}
                items={[{value: 'Edit', text: 'Edit', disabled: !hasWriteAccess || appointment?.status === 'CANCELLED'}]}
                onItemClick={() => {
                  setSelectedDate(new Date(appointment?.appointmentDateTime) || [])
                  setFormBody(appointment)
                  toggleAddNewAppointment(true)
                  toggleShowAddAppointment(true)
                  toggleIsEdit(true)
                }}/>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default AppointmentsCardClosed