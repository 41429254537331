import axios from "../../../../utils/apiClient";

export const getReferrals = (id) => {
    const url =
      process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-referral/";
    return axios
      .get(url + `find?referralId=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
};

export const updateReferral = (query) => {
  const url =
    process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-referral/";
  return axios
    .post(url + "update", query, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const getMyMembersReferrals = (patientId, filters = [], sorts= []) => {
  const criteria = {
    "filterParams": filters,
    "sortParams": sorts,
    "pageNumber": 1,
    // pagination is not supported in this functionality,
    // because it's impossible for a patient to have such a high value,
    // we use the highest possible value to obtain all referrals.
    "pageSize": 2147483647,
  }

  return axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + `/v1/patient-profile/my-members-referrals?patientId=${patientId}`, criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}