import React, { useState } from "react";
import { Button } from "../../components/buttons";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { formatDate } from "../../utils/util";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Modals from "../../components/modals";
import ManageEvidence from "../quickList/ManageEvidence";

const componentName = "GapModal";

export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + window.location.pathname
);

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;
export const GapModal = ({ patient, setShow, updateParent }) => {
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const allGaps = patient.careGaps.concat(patient.closedGaps);
  const [changesMade, setChangesMade] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);

  const closeAllmodal = () => {
    setShowEvidenceModal(false);
    setShow(false);
  };

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
    setShow(false);
  };

  const handleClose = () => {
    if (changesMade) {
      setConfirmationMessage(true);
    } else {
      setShowEvidenceModal(false);
    }
  };

  return (
    <div style={{ maxHeight: "1000px" }} className="overflow-x-hidden overflow-y-auto">
      <span className="d-block fw-bold mb-1 text-danger text-start">
        This patient has active care opportunities. Please review them before
        scheduling an appointment.
      </span>
      <hr className="my-3" />

      {allGaps.map((gap) => {
        return (
          <Row className="g-0">
            <Col xs={1} className="text-nowrap">
              {gap?.evidenceFileNames?.length > 0 && (
                <i className="fas fa-paperclip me-1"></i>
              )}
              {gap?.gapCategory === "STAR" && (
                <i className="fa fa-star text-danger"></i>
              )}
              {gap?.validation?.validation === "REJECTED" && (
                <span className="text-danger fw-bold">
                  &nbsp;!&nbsp;
                </span>
              )}
            </Col>
            <Col xs={4} className="text-start">
              <span className="ms-3">
                {gap?.name}
                {gap?.measureYear != null && (
                  <span>&nbsp;{gap.measureYear}</span>
                )}
              </span>
            </Col>
            <Col xs={4} className="fw-bold text-nowrap">
              {gap?.status?.name}
            </Col>
            <Col xs={2} className="text-start">
              <span className="ms-3">{formatDate(gap.createdOn)}</span>
            </Col>
            <Modals
              scrollable={true}
              show={showEvidenceModal}
              closeEvidenceModal={() => closeEvidenceModalMain()}
              onHide={() => handleClose()}
              size="xl"
              title="Manage Visit"
              completeBtn="UPDATE"
              hideFooter={true}
              body={
                <ManageEvidence
                  patient={patient}
                  fromAppointmentScreen={true}
                  closeEvidenceModal={() => closeAllmodal()}
                  returnPath={RETURN_TO_MY_PATIENTS_URL}
                  updateParent={updateParent}
                  updatesMade={setChangesMade}
                  setConfirmationMessage= {setConfirmationMessage}
                  confirmationMessage ={confirmationMessage}
                  activeKey="0"
                />
              }
            />
            <Modals
              centered={true}
              show={confirmationMessage}
              scrollable={true}
              closeConfirmation={() => setConfirmationMessage(false)}
              onCreate={() => closeEvidenceModalMain()}
              onHide={() => setConfirmationMessage(false)}
              size="sm"
              title=""
              closeBtn="NO"
              completeBtn="YES"
              hideFooter={false}
              body="Do you want to close without saving?"
            />
          </Row>
        );
      })}
      <StyledFooter>
        <Button id={`${componentName}-manageVisitButton`} onClick={() => setShowEvidenceModal(true)}>
          Manage Evidence
        </Button>{" "}
        <Button id={`${componentName}-closeButton`} onClick={() => setShow(false)}>Close</Button>
      </StyledFooter>
    </div>
  );
};
export default GapModal;
