import axios from "../../utils/apiClient";
import { formatDate, formatDateTime } from "../../utils/util";

export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-manager-workspace/";
export const TASK_URL = `${URL}tasks`;
export const APPOINTMENT_URL = `${URL}appointments`;
export const TYPES_URL = `${URL}task-types`;
export const TASK_FILTERS_URL = `${URL}filter-types`;
export const FILTER_ALL = "ALL";
export const DUE_TODAY = "TASKS_DUE_TODAY";
export const TASK_ACTIONS_URL = `${URL}actions`;
export const INTERVENTION_URL = `${URL}intervention`;
const DROPDOWN_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/configuration/care-management-configuration-filter";

export const getTasks = (
    pageNumber = 1,
    pageSize = 10,
    filter = FILTER_ALL,
    sortParameter = "",
    sortDirection = "",
    fromDate = "",
    toDate = "",
    userId = null
) => {
    const params = userId? { pageNumber, pageSize, filter, sortParameter, sortDirection, fromDate, toDate, userId } : { pageNumber, pageSize, filter, sortParameter, sortDirection, fromDate, toDate};
    return axios
        .get(TASK_URL, {
            params: params,
            headers: {
            },
        });
}

export const getTaskTypes = () => {
    return axios
        .get(TYPES_URL, {
            headers: {
            },
        });
}

export const createTask = (task) => {
     return axios
        .post(TASK_URL, task, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const updateTask = (task) => {
    return axios
        .post(TASK_URL, task, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}


export const createAppointment = (appointmentObject) => {
    return axios
        .post(APPOINTMENT_URL, appointmentObject, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const getAllAppointments = (curdate, calendarViewType, userId = null) => {
    const params = userId? {dateRangeType:calendarViewType, date:curdate, userId} : {dateRangeType:calendarViewType, date:curdate};
    return axios
        .get(APPOINTMENT_URL, {
            headers: {},
            params
        });
}

export const updateAppointment = (appointmentObject) => {
    return axios
        .put(APPOINTMENT_URL, appointmentObject, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const deleteAppointment = (appointmentObject) => {
    return axios
        .delete(APPOINTMENT_URL, {
            params: {
                "id": appointmentObject.id
            }
        });
}

export const getTaskFilters = () => {
    return axios
        .get(TASK_FILTERS_URL, {
            headers: {},
        });
}

export const getTaskActions = () => {
    return axios.get(TASK_ACTIONS_URL)
}

export const getReasonsByAction = (actionName) => {
    return axios.get(`${URL}reasons?action=${actionName}`)
}

export const putResolveTask = (request) => {
    let body = { ...request }
    let resolveTaskURL = `${URL}resolve`
    return axios.put(resolveTaskURL, body)
}

export const handleError = (error) => {
    if (error.response) {
        return error.response.data.message
    }
    if (error.request) {
        return error.request
    }
    return error.message
}

export const getMembersByText = (text) => {
    return axios.get(`${URL}members?text=${text}`)
}

export const getAllProgramLeads = () => {
    return axios.get(process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-manager-workspace/users");
}

export const getDOChannel = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "dropListName": "CHANNEL"
        }});
}
export const getDOSource = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "dropListName": "SOURCE"
        }});
}
export const getDOType = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "dropListName": "TYPES"
        }});
}
export const getDONoteTypes = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "dropListName": "OUTCOME"
        }});
}
export const submitIntervention = (intervention)=> {
    return axios
        .post(INTERVENTION_URL, intervention, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}