import React, { useEffect, useState } from "react";
import { Button } from "../../components/buttons";
import { Card } from "../../components/cards";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorToast from "../common/ErrorToast";
import Form from "react-bootstrap/Form";
import SuccessToast from "../common/SuccessToast";
import cloneDeep from "lodash/cloneDeep";
import styled, { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { theme } from "../../components/themes/theme1";
import { getReferrals, updateReferralSettings, getEvents } from "../../actions/admin/refferalEmailSettingsActions";

const componentName="ReferralSettings";
const ReferralSettingsContainer = styled(Form)`
  width: ${(props) => props.theme.themeBase.divWidth};
  padding: ${(props) => props.theme.themeBase.divPadding};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
`;

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
  padding: 0px 0px 0px 0px;
`;

const StyledRadio = styled.input`
width: 20px;
height: 20px;
padding: 0px 15px 0px 25px;
`;

export const ReferralSettings = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);

  const [selectedObj, setSelectedObj] = useState({});
  const { refList, events} = useSelector((state) => state.referralSettings);
  const errors = useSelector((state) => state.errors);
  const success = useSelector((state) => state.success);

  const updateReferral = () => {
    dispatch(updateReferralSettings(formData));
  };

 const RadioButtonGroup = ({obj, handleChangeRadio, index}) => {
    const [afterOrBefore, setAfterOrBefore] = useState("");
    const handleChangeR = (str)=>{
      setAfterOrBefore(str)
      handleChangeRadio(obj, str)
    }



    useEffect(() => {
      if (obj && obj.moment) {
        setAfterOrBefore(obj.moment)
      }
      document.addEventListener("mousedown", handleChangeRadio);
    }, []);
    return (
      <div key={`inline-radio`} className="mb-3">
      <StyledRadio
        inline
        id={`${componentName}-beforeValue-${index}`}
        onChange={() => handleChangeR("BEFORE")}
        onClick={() => handleChangeR("BEFORE")}
        checked={afterOrBefore === "BEFORE"}
        value={afterOrBefore}
        type="radio"
      /> 
      {'          '}
     <StyledRadio
        id={`${componentName}-afterValue-${index}`}
        onChange={() => handleChangeR("AFTER")}
        onClick={() => handleChangeR("AFTER")}
        checked={afterOrBefore === "AFTER"}
        type="radio"
        value={afterOrBefore}
      />
       
    </div>
    );
  }

  useEffect(() => {
    (async function fetchData() {
      await dispatch(getReferrals());
      await dispatch(getEvents());
    })();
  }, []);

  useEffect(() => {
    if (refList && refList.length) {
      const newObject = cloneDeep(refList);
      setFormData(newObject);
    }
  }, [refList]);

  const handleChange = (e, obj) => {
    const index = formData.indexOf(obj);
    const { name, value, checked } = e.target;
    if (formData && formData.length) {
      if (name === "hours") {
        if (value) {
          formData[index].hours = value;
        } else {
          formData[index].hours = "";
        }
      }
      if (name === "refActive") {
        if (checked) {
          formData[index].active = true;
        } else {
          formData[index].active = false;
        }
      }
      if (name === "displayEvent") {
        if (value !== "--Select") {
          formData[index].displayEvent = value;
        } else {
          formData[index].displayEvent = "";
        }
      }
      setFormData([...formData]);
    }
  };

  const handleChangeRadio = (obj, str) => {
    const index = formData.indexOf(obj);
    if (str === "AFTER") {
      formData[index].moment = "AFTER";
    }
    if (str === "BEFORE") {
      formData[index].moment = "BEFORE";
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <ReferralSettingsContainer>
        {errors && errors.message && <ErrorToast />}
        {success && success.message && <SuccessToast />}
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col sm={1}>
                <label id={`${componentName}-activeLabel`}>Active</label>
              </Col>
              <Col sm={4}>
                <label id={`${componentName}-referralEmailTypeLabel`}>Referral Email Type</label>
              </Col>
              <Col sm={1}>
                <label id={`${componentName}-hoursLabel`}># Hours</label>
              </Col>
              <Col sm={2}>
                <label id={`${componentName}-beforeAfterLabel`}>Before After</label>
              </Col>
              <Col sm={4}>
                <label id={`${componentName}-eventLabel`}>Event</label>
              </Col>
            </Row>
            {formData &&
              formData.length &&
              formData.map((refObj, index) => {
                return (
                  <Row key={`${index}${refObj.event}--row`}>
                    <Col sm={1}>
                      <StyledInput
                        checked={refObj.active}
                        type="checkbox"
                        name="refActive"
                        id={`${componentName}-activeValue-${index}`}
                        onChange={(e) => handleChange(e, refObj)}
                      />
                    </Col>
                    <Col id={`${componentName}-referralEmailTypeValue-${index}`} sm={4}>{refObj.displayReferralEmailType}</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        className="form-control"
                        name="hours"
                        id={`${componentName}-hoursValue-${index}`}
                        onKeyDown={ (e) => e.key === 'e' && e.preventDefault() }
                        value={refObj.hours ? refObj.hours : ""}
                        onChange={(e) => handleChange(e, refObj)}
                      />
                    </Col>
                    <Col sm={2}>
                      <RadioButtonGroup  obj= {refObj} index={index} handleChangeRadio ={handleChangeRadio}/>
                    </Col>
                    <Col sm={4}>
                      <Form.Group key={`${index}${refObj.displayEvent}--group`}>
                        <Form.Control
                          as="select"
                          key={`${index}${refObj.displayEvent}--control`}
                          className="form-control"
                          defaultValue={refObj.displayEvent ? refObj.displayEvent : ""}
                          name="displayEvent"
                          id={`${componentName}-eventValue-${index}`}
                          // defaultValue={user.roles.length ? user.roles[0].roleName : user.roles[0].role}
                          onChange={(e) => handleChange(e, refObj)}
                        >
                          {refObj.displayEvent ? (
                            <option value={refObj.displayEvent}>
                              {refObj.displayEvent}
                            </option>
                          ) : (
                            <option value="">--Select</option>
                          )}
                          {events && events.length && events.map((event, indEvent) =>
                            refObj.displayEvent != event ? (
                              <option
                                key={`${index}${indEvent}${refObj.displayEvent}--option3`}
                                value={event}
                              >
                                {event}
                              </option>
                            ) : (
                              ""
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
            <Row>
              <Col className="text-end">
                <Button id={`${componentName}-saveButton`} variant="primary" className="me-0" onClick={() => updateReferral()}>
                  Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </ReferralSettingsContainer>
    </ThemeProvider>
  );
};
export default ReferralSettings;
