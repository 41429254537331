import React, { useEffect, useState } from "react";
import { useCMWorklistContext } from "../context/CMWorklist.context";
import { Badge, Col, Dropdown, Modal } from "react-bootstrap";
import { getCarePathsColors } from "../../../../../../utils/util";
import { AppPropertiesUtil } from "../../../../../../utils/localStorageUtil";
import { patientInformationSection, useGetCareManagements } from "../../../../../../actions/patientProfileActions";
import axios from "../../../../../../utils/apiClient";
import { useAuth } from "../../../../../../context/authContext";
import { careManagementsReducer } from "../../../../../../reducers/patientProfile/careManagementsReducer";
import EnrollMember from "../../../../../quickList/components/careProgramsMock/components/modals/EnrollMember";
import CloseCareProgram from "../../../../../quickList/components/careProgramsMock/components/modals/closeCareProgram";
import { useDispatch } from "react-redux";
import { setCareProgramModalOpen, setReloadWorklist } from "../../../../../../actions/manageVisitActions";
import isEmpty from "is-empty";
import { useManageVisitContext } from "../../../../../quickList/components/context/ManageVisitContext/ManageVisit.context";
import {
  useEQHToastContext
} from "../../../../../../components/toast/EQHToast.context";

const componentName = "StatusColumn"

const StatusColumn = ({ patient, idx }) => {
  const {
    activeRole,
    selectPatient,
  } = useCMWorklistContext();

  const { role } = activeRole;

  const getColumn = () => {
    switch (role) {
      case "Community Health Workers":
      case "Clinical Coordinators":
      case "Care Specialists":
      case "Hospital Liaisons":
      case "Chaplains":
        return <StatusColumnCarePathStatus patient={patient} idx={idx} role={role} />
      default:
        return null;
    }
  }

  const columnContent = getColumn();

  if (!columnContent) {
    return null;
  }

  return columnContent;
}

const StatusColumnCarePathStatus = ({ patient, idx, role }) => {
  const auth = useAuth();
  const { removeNotification, setNotification } = useEQHToastContext();

  const dispatch = useDispatch();
  const { selectPatient, toggleAssignPatientTo, persistErrorMessage } = useCMWorklistContext();
  const carePathStatusesFeatureFlag = AppPropertiesUtil.get("newCarePathStatuses.feature.enabled");
  const careManagementsPkg = useGetCareManagements([], careManagementsReducer);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedExpired, setSelectedExpired] = useState(false);
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(null);
  const [selectedExpiredSource, setSelectedExpiredSource] = useState(null);
  const [primaryCloseError, setPrimaryCloseError] = useState(false);
  const [careProgramObject, setCareProgrammObject] = useState({
    show: false,
    careProgram: {
      id: patient?.carePathStepDto?.carePathInstanceId,
      patientProgramId: patient?.carePathStepDto?.id,
    },
    action: '',
    title: ''
  });

  if (!carePathStatusesFeatureFlag) {
    return null;
  }

  const {
    carePathStatus = '',
  } = patient?.carePathStepDto || {};
  const { font: color, bg: backgroundColor } = getCarePathsColors(carePathStatus);

  const disableQuickAction = () => {
    return (
      role === 'Admin Role' ||
      role === 'Hub Coordinator'
    )
  }

  const handleCheckClose = (action) => {
    checkCanCloseCarePath(careProgramObject?.careProgram, 'closeCareProgram', 'Close Care Program');
  };

  const checkCanCloseCarePath = (selectedCarePath, action, title) => {
    removeNotification();
    careManagementsPkg.fetchData(patient.id)
    const baseURLCP = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-paths";
    const payLoad = {
      carePathInstanceId: selectedCarePath?.id,
    };
    const url = baseURLCP + "/check-close-care-path";
    axios
      .post(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response && response.data && !isEmpty(response.data.errorMessage)) {
          setNotification('Error', response.data.errorMessage)
        } else {
          await openCloseProgram(selectedCarePath, action, title);
          handleModal();
        }
      })
  };

  const openCloseProgram = (careProgram, action, title) => {
    dispatch(setCareProgramModalOpen(true));

    setCareProgrammObject({
      ...careProgramObject,
      show: true,
      careProgram: careProgram,
      action: action,
      title: title
    });
  };

  const handleClose = () => {
    setCareProgrammObject({ ...careProgramObject, action: 'closeCareProgram', show: true })
  }

  const handleEnroll = () => {
    careManagementsPkg.fetchData(patient.id)
    patientInformationSection(patient.id)
      .then(res => {
        setCareProgrammObject({
          ...careProgramObject,
          careProgram: res.data?.carePaths.filter(cp => cp.id === patient?.carePathStepDto?.carePathInstanceId)[0],
          action: 'enroll',
          show: true,
          title: 'Enroll Member in Care Program'
        })
      })
  }

  const handleOutreach = () => {
    try {
      axios.patch(process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/care-path-status', {
        patientId: patient.id,
        userId: auth.getUserId(),
        carePathStatus: "OUTREACH_IN_PROGRESS",
        carePathInstanceId: careProgramObject?.careProgram?.id,
        patientProgramId: careProgramObject?.careProgram?.patientProgramId
      }, {
        headers: {
          "Content-Type": "application/json"
        },
      }).then(() => {
        careManagementsPkg.fetchData(patient.id)
        dispatch(setReloadWorklist(true))
      });
    } catch (error) {
    }
  }

  const handleCancel = (action) => {
    setCareProgrammObject({ ...careProgramObject, show: false })
    setSelectedReason("")
  };

  const handleModal = () => {
    setSelectedReason(selectedReason);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
  };

  const handleRemovePath = () => {
    setCareProgrammObject({
      ...careProgramObject,
      careProgram: null
    })
    careManagementsPkg.fetchData(patient.id);
  };

  const handleCarePathOptionChange = (e, careProgram, action, title) => {
    switch (action) {
      case 'addCarePathStep':
        dispatch(setCareProgramModalOpen(true));
        setCareProgrammObject({ ...careProgramObject, show: true, careProgram: careProgram, action: action, title: 'Add Care Step' });
        break;
      case 'reassign':
        selectPatient(patient);
        dispatch(setCareProgramModalOpen(true));
        toggleAssignPatientTo({ show: true, source: "mangeVisitReassignCareProgram", careProgram: careProgram });
        break;
      default:
        break;
    }
  };

  return (
    <Col
      id={`${componentName}-cp-status-${idx}`}
      className="col-2 d-flex align-items-center justify-content-center"
    >
      <Dropdown>
        <Dropdown.Toggle
          className={`badge rounded-pill border-0 me-4 status-pill cursor-p ${disableQuickAction() ? 'pe-none' : ''}`}
          id="dropdown-basic"
          style={{
            color: color,
          }}
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                'background-color',
                backgroundColor,
                'important'
              )
            }
          }}
        >
          <Badge id={`${componentName}-cp-status-badge-${idx}`}
            style={{
              color: color,
            }}
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  'background-color',
                  backgroundColor,
                  'important'
                )
              }
            }}>
            <div>
              <span>
                {carePathStatus}
              </span>
              {!disableQuickAction() && <i className={`fa fa-angle-down`} style={{ marginLeft: '5px' }} />}
            </div>
          </Badge>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleEnroll()}
          >Enroll Member</Dropdown.Item>
          <Dropdown.Item onClick={() => handleOutreach()}>Outreach In Progress</Dropdown.Item>
          <Dropdown.Item onClick={() => handleCheckClose()}>Close Care Program</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal id={`${componentName}-modal`} show={careProgramObject.show} onHide={handleCancel} onSelect={(eventKey, event) => handleCarePathOptionChange(event, patient?.carePathStepDto, eventKey)}>
        <Modal.Header>
          <Modal.Title id={`${componentName}-carePathTitle`}>{careProgramObject.title}</Modal.Title>
          <button id={`${componentName}-carePathClose`} type="button" onClick={handleCancel} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </Modal.Header>
        {careProgramObject && careProgramObject?.action === 'enroll' &&
          <EnrollMember
            patient={patient}
            careManagementsPkg={careManagementsPkg}
            cb={() => dispatch(setReloadWorklist(true))}
            handleCancel={handleCancel}
            carePath={careProgramObject.careProgram}
            handleModal={handleModal}
          />
        }
        {careProgramObject && careProgramObject?.action === 'closeCareProgram' &&
          <CloseCareProgram
            removePath={handleRemovePath}
            careManagementsPkg={careManagementsPkg}
            patient={patient}
            selectedCarePath={careProgramObject.careProgram}
            openPrimaryModal={() => { }}
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
            selectedExpired={selectedExpired}
            setSelectedExpired={setSelectedExpired}
            selectedExpiredDate={selectedExpiredDate}
            setSelectedExpiredDate={setSelectedExpiredDate}
            selectedExpiredSource={selectedExpiredSource}
            setSelectedExpiredSource={setSelectedExpiredSource}
            close={handleCancel}
            primaryCloseError={primaryCloseError}
            patientInformation={() => { }}
          />
        }
      </Modal>
    </Col>
  );
}

export default StatusColumn;