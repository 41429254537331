import React, { useEffect, Fragment, useState } from "react";
import { Button } from "../../components/buttons";
import { Card } from "react-bootstrap";
import { Timeline } from "../../components/Timeline";
import { MiniCard } from "../../components/MiniCard";
import { CollapsibleCard } from "../../components/CollapsibleCard";
import { EQHSpinner } from "../../components/EQHSpinner";
import { ItemsAccordion } from "../../components/ItemsAccordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import {
  handlePkgErrors,
  formatDate,
  formatLocalDate,
  getDisplayValue,
  formatTimeOnly,
} from "../../utils/util";
import { useGetPatientProfileReferrals } from "../../actions/patientProfileActions";
import { referralsReducer } from "../../reducers/patientProfile/referralsReducer";
import SubmitReferralBody from "../quickList/SubmitReferral";
import Modals from "../../components/modals";
import {
  referral_types,
  status_referrals,
  urgency_referrals,
  visit_type_referrals,
} from "../../utils/textValueLists";
import { ReferralModal } from "../quickList/ReferraModal";
import { useAuth } from "../../context/authContext";
import { debounce } from 'lodash'
import { isCBOAccessAllowed } from "../caremgmt/worklist/constants";
import useCboLink from "../../actions/hooks/useCboLink";

const componentName = "patientProfile-Referral";

const ReferralCard = ({ referral, refreshReferrals, section, idx, patient }) => {
  const [show, setShow] = useState(false);
  const auth = useAuth();
  const roles = auth.getRoles();

  const [referComponent, getSamlResponse, setSamlResponse] = useCboLink(patient?.clientId, patient, referral?.referredTo?.name);
  useEffect(() => {
    if (referral?.referredTo?.name === 'Uniteus' || referral?.referredTo?.name === 'Find Help') {
      getSamlResponse((response) => setSamlResponse(response.data))
    }
  }, [referral?.referredTo?.name]);

  const openContent = (
    <Container fluid>
      <Row xl={4}>
        <Col>
          <MiniCard
            label="Referring Practice"
            idx={idx}
            value={referral.referredBy.name ? referral.referredBy.name : "-"}
          />
        </Col>
        <Col>
          <>
            {(referral?.referredTo?.name === 'Uniteus' || referral?.referredTo?.name === 'Find Help') && isCBOAccessAllowed(roles) ?
              <><span id={`${componentName}-infoLabel-${idx}`} className="d-block fw-bold">Referred To Provider/Practice</span> {referComponent()}</> :
              <MiniCard
                label="Referred To Provider/Practice"
                idx={idx}
                value={(referral.referredPractitioner
                  ? referral.referredPractitioner
                  : "-") +
                  " / " +
                  (referral.referredTo.name ? referral.referredTo.name : "-")}
              />
            }
          </>
        </Col>
        <Col>
          <MiniCard
            label="Referral Type"
            idx={idx}
            value={
              referral.referralType
                ? getDisplayValue(referral_types, referral.referralType)
                : "-"
            }
          />
        </Col>
        <Col>
          {referral.referralType === "SPECIALIST" ? (
            <MiniCard label={"Visit Type"} idx={idx} value={referral} />
          ) : (
            <MiniCard label={"Services Needed"} idx={idx} value={referral} />
          )}
        </Col>
      </Row>
      <Row xl={4}>
        <Col>
          <MiniCard
            label="Referral Status"
            idx={idx}
            value={
              referral.status
                ? getDisplayValue(status_referrals, referral.status)
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Referral Sent Date"
            idx={idx}
            value={
              referral.refDateUTC ? formatLocalDate(referral.refDateUTC) : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Diagnosis"
            idx={idx}
            value={
              referral.diagnosisICD10dto.length
                ? referral.diagnosisICD10dto[0].description
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Urgency"
            idx={idx}
            value={
              referral.urgency
                ? getDisplayValue(urgency_referrals, referral.urgency)
                : "-"
            }
          />
        </Col>
      </Row>
      <Row xl={4}>
        <Col>
          <MiniCard
            label="Referral Notes"
            idx={idx}
            value={referral.statusComments ? referral.statusComments : referral.referralComments ? referral.referralComments : "-"}
          />
        </Col>
        <Col>
          <MiniCard
            label="Preferred Location"
            idx={idx}
            value={
              referral.referredPracticeAddress
                ? referral.referredPracticeAddress
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard label="Attachments" idx={idx} value={referral} />
        </Col>
      </Row>
    </Container>
  );
  const closedContent = (
    <Container fluid>
      <Row xl={4}>
        <Col>
          <MiniCard
            label="Referring Practice"
            idx={idx}
            value={referral.referredBy.name ? referral.referredBy.name : "-"}
          />
        </Col>
        <Col>
          <>
            {(referral?.referredTo?.name === 'Uniteus' || referral?.referredTo?.name === 'Find Help') && isCBOAccessAllowed(roles) ?
              <><span id={`${componentName}-infoLabel-${idx}`} className="d-block fw-bold">Referred To Provider/Practice</span> {referComponent()}</> :
              <MiniCard
                label="Referred To Provider/Practice"
                idx={idx}
                value={(referral.referredPractitioner
                  ? referral.referredPractitioner
                  : "-") +
                  " / " +
                  (referral.referredTo.name ? referral.referredTo.name : "-")}
              />
            }
          </>
        </Col>
        <Col>
          <MiniCard
            label="Referral Type"
            idx={idx}
            value={
              referral.referralType
                ? getDisplayValue(referral_types, referral.referralType)
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Referral Status"
            idx={idx}
            value={
              referral.status
                ? getDisplayValue(status_referrals, referral.status)
                : "-"
            }
          />
        </Col>
      </Row>
    </Container>
  );
  return (
    <>
      <CollapsibleCard
        // title={referral.senderPracticeName}
        openContent={openContent}
        idx={idx}
        closedContent={closedContent}
        editTask={section === "All" ? null : () => setShow(true)}
      />
      <Modals
        show={show}
        onHide={() => setShow(false)}
        onCreate={() => setShow(false)}
        title={"Manage Referral"}
        titleid={"ManageReferral"}
        size="xl"
        hideFooter={true}
        completeBtn={"SAVE CHANGES"}
        body={
          <ReferralModal
            closeModal={() => setShow(false)}
            referralObject={referral}
            quickListType={
              referral.dataType === "sent"
                ? "REFERRALS_SENT"
                : "REFERRALS_RECEIVED"
            }
            updateParent={refreshReferrals}
          />
        }
      />
    </>
  );
};

const ALL = "All";
const RECEIVED = "Received";
const SENT = "Sent";

const Referrals = ({ patient, selectedTab, reloadPatientOverview }) => {
  const auth = useAuth();
  const [showReferralModal, setShowReferralModal] = useState(false);
  const referralsPkg = useGetPatientProfileReferrals([], referralsReducer);
  const headerAndSelectorMap = {
    [ALL]: (item) => item.showAll,
    [RECEIVED]: (item) => item.dataType === "received",
    [SENT]: (item) => item.dataType === "sent",
  };

  useEffect(() => {
    if (selectedTab) {
      referralsPkg.fetchData(patient.id);
    }
  }, [patient.id, selectedTab]);

  const refreshReferrals = () => {
    referralsPkg.fetchData(patient.id);
    reloadPatientOverview();
  };

  return (
    <Fragment>
      <Container fluid>
        {referralsPkg.state.isLoading && <EQHSpinner />}
        {referralsPkg.state.error ? (
          <Card>
            <Card.Body>{handlePkgErrors(referralsPkg.state.error)}</Card.Body>
          </Card>
        ) : (
          <Card>
            <Modals
              scrollable={true}
              referralHeight={true}
              show={showReferralModal}
              closeSubmitModal={() => setShowReferralModal(false)}
              onHide={() => {
                setShowReferralModal(false);
                referralsPkg.fetchData(patient.id);
              }}
              size="xl"
              title="Submit Referral"
              titleid={`${componentName}-submitReferralModal`}
              completeBtn="Submit"
              completeBtnId={`${componentName}-submitReferralButton`}
              hideFooter={true}
              body={
                <SubmitReferralBody
                  patient={patient}
                  closeSubmitModal={() => setShowReferralModal(false)}
                  addNewReferral={refreshReferrals}
                  preSelectedType={null}
                  showReferralModal={showReferralModal}
                />
              }
            />
            <Card.Body>
              <Row className="mb-2">
                <Col>
                  {(auth.hasAuthority("AUTH_REFERRAL_SENT_ACCESS") ||
                    auth.hasAuthority(
                      "AUTH_REFERRAL_CARE_COORDINATION_SENT_ACCESS"
                    )) && (
                    <Button
                      variant="primary"
                      id={`${componentName}-submitReferral`}
                      onClick={() => setShowReferralModal(true)}
                    >
                      Submit Referral
                    </Button>
                  )}
                </Col>
              </Row>
              <ItemsAccordion
                items={referralsPkg.state.data}
                headerAndSelectorMap={headerAndSelectorMap}
                selected={"All"}
                bodyBuilder={(key, list, idx) => (
                  <Timeline
                    items={
                      [...list].sort((firstEl, secondEl) => {
                        return secondEl.statusUpdated.localeCompare(
                          firstEl.statusUpdated
                        );
                      }) ?? []
                    }
                    titleBuilder={(referral, idx) =>
                      referral && referral.statusUpdated ? (
                        <div>
                            <span id={`${componentName}-tlDate-${idx}`} className="d-block fw-bold">
                            {formatLocalDate(referral.statusUpdated)}
                          </span>
                          <span id={`${componentName}-tlTime-${idx}`} className="d-block">
                            {formatTimeOnly(referral.statusUpdated)}
                          </span>
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip>
                                {referral.sentByFullName
                                  ? referral.sentByFullName
                                  : "No Author"}
                              </Tooltip>
                            }
                          >
                            <Badge id={`${componentName}-tlAuthor-${idx}`} bg="secondary" className="text-truncate mw-100">
                              {referral.sentByFullName
                                ? referral.sentByFullName
                                : "No Author"}
                            </Badge>
                          </OverlayTrigger>
                        </div>
                      ) : (
                        "-"
                      )
                    }
                    subTitleBuilder={(referral) => referral.dateTime}
                    bodyBuilder={(referral, idx) => (
                      <ReferralCard
                        section={key}
                        idx={idx}
                        referral={referral}
                        refreshReferrals={refreshReferrals}
                        patient={patient}
                      />
                    )}
                  />
                )}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};

export { Referrals };
