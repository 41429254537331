import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const StyledButton = styled(({ backgroundColor, ...rest }) => (
  <Button {...rest} />
))`
  padding: 7px 12px;

  &.btn-lg {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  &.btn-primary,
  &.btn-primary.disabled {
    background: ${props => props.backgroundColor || "#188dcd"};
    border: 1px solid ${props => props.backgroundColor || "#188dcd"};
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    font-size: 14px;
    font-weight: 700;
  }

  &.btn-primary.disabled:hover,
  &.btn-primary:hover,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background: ${props => props.backgroundColor || "#188dcd"};
    border: 1px solid ${props => props.backgroundColor || "#188dcd"};
  }
`;

export { StyledButton as Button };
