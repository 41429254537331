import React, {useEffect, useState} from 'react'
import NewAssessmentModal
    from '../../../../../patientProfile/assessment/NewAssessmentModal'
import {useCMWorklistContext} from '../context/CMWorklist.context';

const NewAssessmentFlow = () => {
    const {
        selectedPatientTemplate,
        isNewAssessmentFlowEnabled,
        toggleNewAssessmentFlow,
        patient,
        saveAssessment,
        isSavingAssessment,
        toggleNewAssessmentTemplateSelector,
        sendEvent
    } = useCMWorklistContext();

    const fillAssessmentClose = () => {
        toggleNewAssessmentFlow(false);
    }
    const [mode, setMode] = useState('new');
    const [individualAssessmentCard, setIndividualAssessmentCard] = useState(
        {});
    const [detailAssessment, setDetailAssessment] = useState(false);
    const detailAssessmenClose = () => setDetailAssessment(false);

    useEffect(() => {
        if (!isSavingAssessment) {
            toggleNewAssessmentTemplateSelector(false);
            fillAssessmentClose();
        }
    }, [isSavingAssessment])

    const saveNewTemplate = (payload, status, isDelete, id) => {
        if (status === 'Completed') {
            sendEvent('CONFIRM_SAVE_ASSESMENT');
        }

        const assessmentData = {
            id: id,
            patientId: patient?.id,
            templateId: selectedPatientTemplate?.templateId,
            answers: payload,
            status: status,
        };

        saveAssessment(assessmentData);
    }

    return (<>
            {isNewAssessmentFlowEnabled && (<NewAssessmentModal
                fillAssessment={isNewAssessmentFlowEnabled}
                saveNewTemplate={saveNewTemplate}
                fillAssessmentClose={fillAssessmentClose}
                selectedTemplate={selectedPatientTemplate}
                individualAssessmentData={individualAssessmentCard}
                mode={mode}
                patientId={patient?.id}
                patient={patient}
                isSaving={isSavingAssessment}
                detailAssessmenClose={detailAssessmenClose}
            />)}
        </>
    )
}

export default NewAssessmentFlow