import React from "react";
import { useAuth } from "./context/authContext";
import LoginView from "./views/login/LoginView";
import MainView from "./views/MainView";
import { Switch, Route } from "react-router-dom";
import { DeclineReferral } from "./views/DeclineReferralView";
import { LoginInfoProvider } from "./views/login/context/LoginInfoContext";
import Logout from "./views/Logout";

const App = () => {
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated()
  const isEmpty = require("is-empty");

  const mainView = React.useMemo(() => <MainView />, []);
  const login = React.useMemo(() => <LoginInfoProvider><LoginView /></LoginInfoProvider>, []);
  const declineReferral = React.useMemo(() => <DeclineReferral />, []);


  const fromCBO = document.referrer && !isEmpty(document.referrer) && 
  ((document.referrer.indexOf("uniteus") !== -1 && document.referrer.indexOf("uniteUS/logout") === -1) ||
  (document.referrer.indexOf("findhelp") !== -1))


  return (
    <Switch>
      <Route path={["/recover-password", "/reset-password/:token"]}>
        {login}
      </Route>
      <Route path={["/referral/decline"]}>
        {declineReferral}
      </Route>
      <Route path={["/uniteUS/logout", "/findhelp/logout"]}>
        {<Logout />}
      </Route>
      <Route>{isAuthenticated && !fromCBO ? mainView : login}</Route>
    </Switch>
  );
};

export default App;
