import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import axios from "../../../utils/apiClient";

import { Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";

import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import styled, { css } from "styled-components";

import { addUser, getUserMessages, createMessage, createConversation, getMessageCount, getMessageTypes } from "../../../actions/admin/header/messageActions";
import MultipleUploads from "../../../components/MultipleUploads";
import { getAllUsers, getAllUsersByCLients } from "../../../actions/admin/userManagementActions";
import ConversationModal from "./modals/conversation";
import { getFileDownloadGeneric, uploadFile, updateFile, clearFileUploads } from "../../../actions/fileUploadActions";
import { formatDate, formatDateTime, reactQuickLists } from "../../../utils/util";
import { FileUpload } from "../../../components/FileUpload";



const componentName = "Messages";
const URL = "/transitions_of_care?source=message";
const StyledTypeAhead = styled(AsyncTypeahead)``;
const StyledSentence = styled.p`
  margin: 0px;
  word-wrap : break-word;
`;
const EmailDisplay = ({
  firstName,
  urgent,
  viewed,
  file,
  lastName,
  orgName,
  dateObj,
  title,
  message,
  messageType,
  conversationId,
  linkText,
  linkURI,
  exportFiles, uploadedFiles,
  idx
}) => {
  const [downloadShow, setDownloadShow] = useState(false);
  const index = linkURI.indexOf("/");
  const quicklistType = linkURI.substring(0, index);
  const quicklistId = linkURI.substring(index + 1);

  const linkElement = () => {
    if (!linkText) {
      return;
    }

    if (index === -1) {
      return <p id={`${componentName}-mailFile-${idx}`}>
        <a href={`/${quicklistId}`} id={`${componentName}-mailFileLink-${idx}`} className="btn btn-secondary border px-3">
          <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
        </a>
      </p>;
    } else {
      if (reactQuickLists.includes(quicklistType)) {
        return <p id={`${componentName}-mailFile-${idx}`}>
          <a href={`/${quicklistType}?id=${decodeURIComponent(quicklistId)}&customListName=${linkText}`} id={`${componentName}-mailFileLink-${idx}`} className="btn btn-secondary border px-3">
            <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
          </a>
        </p>;
      } else {
        return <p id={`${componentName}-mailFile-${idx}`}>
          <a href={`/#!${quicklistType}/${encodeURIComponent(quicklistId)}`} id={`${componentName}-mailFileLink-${idx}`} className="btn btn-secondary border px-3">
            <i className="fa-regular fa-link me-2"></i> {linkText ? linkText : ""}
          </a>
        </p>
      }
    }
  }

  const onClockOfComp = () => {
    setDownloadShow(true)
  }
  return (
    <a id={`${componentName}-messageCenter-${idx}`} className="list-group-item list-group-item-action message-center">
      <div onClick={() => onClockOfComp()}>
        <div className="d-flex w-100 justify-content-between mt-2">
          <div>
            {!viewed && <i id={`${componentName}-viewedIcon-${idx}`} className="fa fa-circle text-primary fa-xs me-2" />}
            {urgent ? (
              <i id={`${componentName}-urgentIcon-${idx}`} className="fa fa-exclamation-circle text-danger fa-xs me-2" />
            ) : null}
            {file ? (
              <i id={`${componentName}-fileIcon-${idx}`} className="fas fa-paperclip fa-xs me-2" />
            ) : null}
            <h5 id={`${componentName}-messageTitle-${idx}`} className="fw-bold d-inline mb-0">{title}</h5>
          </div>
          <small id={`${componentName}-messageDate-${idx}`} className="text-end text-nowrap">{formatDateTime(dateObj)}</small>
        </div>
        <small className="mb-2"><b id={`${componentName}-mailName-${idx}`}>{firstName + " " + lastName}</b><span id={`${componentName}-mailOrgName-${idx}`}> - {orgName}</span></small>
        <p id={`${componentName}-mailMessageSummary-${idx}`}>{message.split('\n').map((item, i) => <StyledSentence key={i}>{item}</StyledSentence>)}</p>
      </div>
      {linkElement()}
      {exportFiles && exportFiles.length > 0 && exportFiles.map((file) => (
        <p id={`${componentName}-mailFile-${idx}`} key={file.fileName}>
          {file && (
            <Button variant="secondary" id={`${componentName}-mailFileLink-${idx}`} onClick={() => getFileDownloadGeneric(process.env.REACT_APP_REST_API_BASE_URL + `/v1/files/get?fileUri=${encodeURIComponent(file.fileUri)}&fileName=${file.fileName}`, file.fileName)} className="border px-3">
              <i className="fa-light fa-file-arrow-down me-2"></i> {file.fileName ? file.fileName : ""}
            </Button>
          )}
        </p>
      ))}
      {uploadedFiles && uploadedFiles.length > 0 && uploadedFiles.map((file) => (
        <p id={`${componentName}-mailFile-${idx}`} key={file.fileName}>
          {file && (
            <Button variant="secondary" id={`${componentName}-mailFileLink-${idx}`} onClick={() => getFileDownloadGeneric(process.env.REACT_APP_REST_API_BASE_URL + `/v1/files/get?fileUri=${encodeURIComponent(file.relativeFilePath)}&fileName=${file.fileName}`, file.fileName)} className="border px-3">
              <i className="fa-light fa-file-arrow-down me-2"></i> {file.fileName ? file.fileName : ""}
            </Button>
          )}
        </p>
      ))}
      <ConversationModal
        showModal={downloadShow}
        setModal={setDownloadShow}
        conId={conversationId}
        messageType={messageType}
        title={title}
      />
    </a>
  );
};

const ComposeEmail = ({ onModalClose, selectedPatient, patientSpecific }) => {
  let auth = useAuth();
  const token = auth.getAuthToken();
  const [emailData, setEmailData] = useState({});
  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const handleChangeWithinModal = (e, error) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const { allUsers } = useSelector((state) => state.userManagement);
  const { fileObject } = useSelector((state) => state.fileUpload);
  const { conversationId, messageTypes } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [fileAdded, setFileAdded] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [urgent, setUrgent] = useState(false);
  const [validate, setValidate] = useState(true);
  const [msgType, setMsgType] = useState({});
  const errors = useSelector((state) => state.errors);
  const [optionsPatient, setOptionsPatients] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [patient, setPatient] = useState(null);

  const isEmpty = require("is-empty");

  const handleChangePatient = (e) => {
    if (e && e[0]) {
      findPatient(e[0].value);
    }
  };
  const findPatient = (id) => {
    if (id) {
      const input = {
        filterParams: [
          {
            paramName: "Patient Id",
            paramValue: [id],
            comparison: "eq",
            displayValue: [null],
          },
        ],
        pageNumber: 1,
        pageSize: 15,
        quickListName: "APPOINTMENT_PATIENT",
        sortParams: [],
      };
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL + "/v1/patients/myPatients";
      axios
        .post(SEARCH_TEXT_URL, input, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.patientCardDtos &&
            resp.data.patientCardDtos.length
          ) {
            setPatient(resp.data.patientCardDtos[0]);
          }
        })
        .catch((error) => {
        });
    }
  };

  const asyncsearchPatient = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearchPatient(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSearchPatient = (query) => {
    if (query) {
      const SEARCH_TEXT_URL =
        process.env.REACT_APP_REST_API_BASE_URL +
        "/v1/global-search/findPatientInfo?searchString=" +
        query;
      setIsLoadingPatient(true);
      axios
        .get(SEARCH_TEXT_URL, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data && resp.data.patients) {
            options = resp?.data?.patients?.map((i) => ({
              value: i?.id,
              text: i?.fullName + " " + i?.memberId + " DOB: " + formatDate(i?.dateOfBirth),
            }));
          }
          setOptionsPatients(options);
          setIsLoadingPatient(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
          setIsLoadingPatient(false);
        });
    }
  };

  const setFileMethod = async (filesList) => {
    let file;
    let fileName;
    if (filesList && filesList.name) {
      fileName = filesList.name;
    }
    const fileReader = new FileReader();
    if (fileReader && filesList) {
      fileReader.readAsArrayBuffer(filesList);
      fileReader.onload = function () {
        if (fileReader && fileReader.result && !isEmpty(fileReader.result)) {
          dispatch(updateFile(fileReader.result, fileName));
        }
      };
    }
  };

  const createConvAndSend = () => {
    let userId = auth.getUserId();
    const converseMessage = {
      conversationDto: {
        title: emailData.title,
        participants: [...users, userId],
        messageType: msgType,
      },
      messageDto: {
        title: emailData.title,
        message: emailData.message,
        messageType: msgType,
        important: urgent,
        patientId: patient ? patient.id : null,
      },
    };
    if (uploadedFiles && uploadedFiles.length) {
      converseMessage.messageDto["uploadedFiles"] = uploadedFiles;
    }
    dispatch(createConversation(converseMessage));
  };

  const updateUsers = async (e, error) => {
    let idArray = [];
    e.forEach((user) => {
      idArray.push(user.id);
      setUsers(idArray);
    });
    if (e.length === 0) {
      setUsers([]);
    }
  };

  const handleChange = (e) => {
    if (e.length === 0) {
      setMsgType([]);
    } else {
      setMsgType(e[0]);
    }
  };

  useEffect(() => {
    (async function fetchData() {
      if (!messageTypes.length) {
        await dispatch(getMessageTypes(token));
      }
    })();
  }, []);

  const isFormValid = () => {
    const { title, message } = emailData;
    if (
      title &&
      msgType &&
      message &&
      users &&
      title.length &&
      msgType.length &&
      message.length &&
      users.length
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };
  const updateFiles = (arr) => {
    setUploadedFiles(arr);
  };

  const closeModal = async () => {
    await dispatch(clearFileUploads());

    await onModalClose();
  };

  useEffect(() => {
    if (patientSpecific) {
      findPatient(selectedPatient);
    }
    (async function fetchMessages() {
      await dispatch(clearFileUploads());
      if (!allUsers.length) {
        await dispatch(getAllUsersByCLients());
      }
    })();
  }, []);

  useEffect(() => {
    if (fileObject) {
      setFileAdded(fileObject);
    }
  }, [fileObject]);
  return (
    <>
      {allUsers && allUsers.length && allUsers[0] !== "Empty" ? (
        <>
          <form>
            <div id={`${componentName}-chooseUsers`} className="form-group mb-3">
              <label id={`${componentName}-chooseUsersLabel`} className="d-block">Choose Users<span className="text-danger">*</span></label>
              <Typeahead id={`${componentName}-chooseUsersValue`} inputProps={{ id: `${componentName}-chooseUsersValue` }}
                multiple labelKey="email" onChange={(e) => updateUsers(e)} options={allUsers} />
            </div>
            <div id={`${componentName}-chooseMessageType`} className="form-group mb-3">
              <label id={`${componentName}-chooseMessageTypeLabel`} className="d-block">Choose Message Type<span className="text-danger">*</span></label>
              <Typeahead id={`${componentName}-chooseMessageTypeValue`} inputProps={{ id: `${componentName}-chooseMessageTypeValue` }}
                labelKey="msgType" onChange={(e) => handleChange(e)} options={messageTypes} />
            </div>
            <div id={`${componentName}-subject`} className="form-group mb-3">
              <label id={`${componentName}-subjectLabel`} className="d-block">Subject<span className="text-danger">*</span></label>
              <input type="text" id={`${componentName}-subjectValue`} onChange={(e) => handleChangeWithinModal(e)}
                className="form-control" name="title" />
            </div>
            {patient && (
              <>
                <div id={`${componentName}-patient`} className="form-group mb-3">
                  <label id={`${componentName}-patientLabel`} className="d-block">Patient</label>
                  <span id={`${componentName}-patientValue`}>{patient?.lastName}, {patient?.firstName}</span>
                </div>
                <div>
                  <span id={`${componentName}-memberId`}>{patient?.memberId}</span>{" "}
                  <span id={`${componentName}-memberStatus`}>
                    ({patient?.active ? (
                      <span id={`${componentName}-memberStatusActive`} className="text-success">Active</span>
                    ) : (
                      <span id={`${componentName}-memberStatusInactive`} className="text-danger">Inactive</span>
                    )})
                  </span>
                  <span id={`${componentName}-DOB`} className={"ms-2"}>
                    <span id={`${componentName}-DOBLabel`} className="fw-bold">DOB: </span>{" "}
                    <span id={`${componentName}-DOBValue`}>{patient && formatDate(patient?.dateOfBirth)} ({patient?.age})</span>
                  </span>
                </div>
              </>
            )}
            {!selectedPatient && !patientSpecific ? (
              <div id={`${componentName}-MessagePatient`} className="form-group mb-3">
                <label id={`${componentName}-MessagePatientLabel`} className="d-block">Patient</label>
                <StyledTypeAhead inputProps={{ id: `${componentName}-MessagePatientValue` }}
                  labelKey="text"
                  isLoading={isLoadingPatient}
                  onSearch={asyncsearchPatient}
                  options={optionsPatient}
                  onChange={(e) => handleChangePatient(e)}
                  placeholder="Search Patients by Name"
                />
              </div>
            ) : null}
            <div className="form-group mb-3">

              <FileUpload
                onChange={handleUploadFileChange}
                clearStateProp={0}
              />
            </div>
            <div id={`${componentName}-message`} className="form-group mb-3">
              <label id={`${componentName}-messageLabel`} className="d-block">Message<span className="text-danger">*</span></label>
              <textarea
                className="form-control"
                id={`${componentName}-messageValue`}
                name="message"
                onChange={(e) => handleChangeWithinModal(e)}
                rows="5"
              ></textarea>
            </div>
          </form>
          <div className="d-flex">
            <div className="me-auto">
              <div id={`${componentName}-messageUrgent`} className="form-check">
                <input id={`${componentName}-messageUrgentValue`} className="form-check-input" type="checkbox" value={urgent} name="markUrgent" onClick={() => setUrgent(!urgent)} />
                <label id={`${componentName}-messageUrgentLabel`} className="form-check-label" for={`${componentName}-messageUrgentValue`}>
                  Mark As Urgent
                </label>
              </div>
            </div>
            <Button variant="secondary" id={`${componentName}-cancelButton`} className="me-3" data-dismiss="modal" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button variant="primary" id={`${componentName}-sendButton`} onClick={() => createConvAndSend()} disabled={isFormValid()}>
              Send
            </Button>
          </div>
        </>
      ) : allUsers[0] === "Empty" ? (
        <p id={`${componentName}-noUsersFound`}>No users found</p>
      ) : (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...
        </>
      )}
    </>
  );
};
const EmailList = ({ patientSpecific }) => {
  const { messages } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchMessages() {
      if (!messages.length && !patientSpecific) {
        await dispatch(getUserMessages());
      }
    })();
  }, []);

  return (
    <div className="list-group">
      {messages && messages.length && messages[0] !== "Empty" ? (
        messages.map((message, idx) => {
          return (
            <EmailDisplay
              idx={idx}
              firstName={message && message.sender ? message.sender.firstName : ""}
              lastName={message && message.sender ? message.sender.lastName : ""}
              viewed={message && message.viewed ? message.viewed : false}
              urgent={message && message.important ? message.important : false}
              file={message && message.uploadedFiles.length ? true : false}
              orgName={message && message.sender ? message.sender.organizationName : ""}
              dateObj={message && message.sendDateTime ? message.sendDateTime : ""}
              title={message && message.title ? message.title : ""}
              message={message && message.message ? message.message : ""}
              messageType={message && message.messageType ? message.messageType : ""}
              conversationId={message && message.conversationId ? message.conversationId : ""}
              linkText={message && message.linkText ? message.linkText : ""}
              linkURI={message && message.linkURI ? message.linkURI : ""}
              exportFiles={message && message.exportFiles ? message.exportFiles : []}
              uploadedFiles={message && message.uploadedFiles ? message.uploadedFiles : []}
            />
          );
        })
      ) : messages[0] === "Empty" ? (
        <p id={`${componentName}-noMessages`}>You don't have any messages</p>
      ) : (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...
        </>
      )}
    </div>
  );
};

const DynamicModal = ({
  onHide,
  changePage,
  title,
  show,
  body,
  size,
  navigate,
}) => {
  const [shrinkScreen, setShrinkScreen] = useState(false);

  const toggleScreen = () => {
    setShrinkScreen(!shrinkScreen);
  };

  return (
    <Modal onHide={onHide} show={show} size={size} centered scrollable>
      <Modal.Header>
        <Modal.Title id={`${componentName}-title`}>Messages</Modal.Title>
        <div className="ms-auto">
          <Button id={`${componentName}-composeButton`} variant="secondary" className="mb-0 me-3" onClick={changePage}>Compose</Button>
          <a id={`${componentName}-seeAllButton`} href="/message_inbox" className="btn btn-primary me-3" onClick={() => navigate()}>See All</a>
          {/* <Button id={`${componentName}-closeButton`} variant="link" className="fas fa-times fa-lg text-secondary p-0 align-middle" onClick={() => onHide()}></Button> */}
          <button id={`${componentName}-closeButton`} type="button" className="btn-close" aria-label="Close" onClick={() => onHide()}></button>
        </div>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

const Messages = ({ count, patientSpecific, selectedPatient }) => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const { newConversation } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  //Hide Pop Up Once New Conver is successfull
  useEffect(() => {
    if (newConversation) {
      setModalShow(false);
    }
  }, [newConversation]);

  //Toggles Modal Display
  const handleModal = (e) => {
    setModalShow(true);
    setShowCompose(patientSpecific);
  };

  const changePage = () => {
    setShowCompose(true);
  };

  const navigateToInbox = () => {
    history.push({ pathname: "/message_inbox", state: { pageTitle: "Message Inbox" } });
    setModalShow(false);
  };

  const onModalCloseHere = () => {
    setModalShow(false);
  };

  const body = showCompose ? (
    <ComposeEmail onModalClose={onModalCloseHere} patientSpecific={patientSpecific} selectedPatient={selectedPatient} />
  ) : (
    <EmailList patientSpecific={patientSpecific} />
  );

  return (
    <>
      {patientSpecific ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="auto"
          overlay={
            <Tooltip>
              Send Secure Message
            </Tooltip>
          }
        >
          <Button
            onClick={(e) => handleModal(e)}
            id={`${componentName}-patientMessagesButton`}
            disabled={patientSpecific && !selectedPatient}
            className="text-dark mt-1 px-2 py-0"
            variant="link"
          >
            <i className="fas fa-envelope-open-text fa-2x align-middle me-2 mt-2" />{" "}
          </Button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="auto"
          overlay={
            <Tooltip>
              {count} Messages
            </Tooltip>
          }
        >
          <Button id={`${componentName}-messagesButton`} variant="link" className="position-relative" onClick={(e) => handleModal(e)}>
            <i className="fa-light fa-envelope fa-lg text-dark"></i>
            {count > 0 ? (
              <span className="position-absolute top-40 start-60 translate-middle p-1 bg-danger border border-light rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
            ) : null}
          </Button>
        </OverlayTrigger>
      )}
      <DynamicModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Messages Modal"
        size="lg"
        completeBtn={"Close"}
        body={body}
        changePage={changePage}
        navigate={navigateToInbox}
      />
    </>
  );
};
export default Messages;
