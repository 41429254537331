import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Badge } from "react-bootstrap";
import { EQHDatePicker } from "../../../../../../components/datePicker";
import { format } from "date-fns";
import { RedAsterisk, expiredSources, formatDateTime } from "../../../../../../utils/util";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { closeCarePath, retrieveCloseCpReasons } from "../../../../../../actions/patientSearch/patientSearchActions";
import ToastMessage from "../../../../../../components/Toast";
import { useManageVisitContext } from "../../../context/ManageVisitContext/ManageVisit.context";
import { setReloadWorklist } from "../../../../../../actions/manageVisitActions";
import {
  useEQHToastContext
} from "../../../../../../components/toast/EQHToast.context";

const componentName = "closeCareProgram";

export const CloseCareProgram = ({
  removePath,
  careManagementsPkg,
  patient,
  selectedCarePath,
  openPrimaryModal,
  selectedReason,
  setSelectedReason,
  selectedExpired,
  setSelectedExpired,
  selectedExpiredDate,
  setSelectedExpiredDate,
  selectedExpiredSource,
  setSelectedExpiredSource,
  close,
  primaryCloseError,
  patientInformation,
}) => {
  const dispatch = useDispatch();
  const manageVisitContext = useManageVisitContext();
  const { closePathReasons } = useSelector((state) => state.patients);
  const [notes, setNotes] = useState("");
  const {removeNotification, setNotification} = useEQHToastContext();


  useEffect(() => {
    dispatch(retrieveCloseCpReasons());
  }, []);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
  };


  const handleClose = () => {
    closeCarePath(selectedCarePath?.id, selectedReason, selectedExpired, selectedExpiredDate, selectedExpiredSource, notes)
      .then((res) => {
        if (res.data.assignPrimary) {
          openPrimaryModal(res.data.remainingCarePaths);
            //setAssignPrimary(true);
            setNotification('Error', "Please assign Primary care program before closing current Primary care program")
        } else {
          dispatch(setReloadWorklist(true));
          removePath();
          close();
          patientInformation(patient);
        }
      })
      .catch((err) => {
      });
  };
  return (
    <>
      <Modal.Body>
        <Row className="mb-4 bg-light p-3 rounded-3">
          <Col>
            <Row className="mb-3">
              <Form.Group id={`${componentName}-patient`} className="d-flex flex-column">
                <Form.Label id={`${componentName}-patientLabel`}>
                  Patient Name
                </Form.Label>
                <span id={`${componentName}-patientValue`} className="forced-capital">
                  {patient.fullName}
                </span>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                <Form.Label id={`${componentName}-currentLabel`}>
                  Current Care Programs
                </Form.Label>
                {careManagementsPkg.state.data &&
                  careManagementsPkg.state.data.map((cp, idx) => cp.isActive ? (
                    <>
                      <div id={`${componentName}-carePath-${idx}`} className="d-block">
                        <span id={`${componentName}-carePathName-${idx}`}>{cp.name}</span>
                        {cp.primary && (
                          <Badge id={`${componentName}-carePathPrimary-${idx}`} variant="secondary" className="ms-2">
                            Primary
                          </Badge>
                        )}
                      </div>
                    </>
                  ) : null
                  )}
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                <Form.Label id={`${componentName}-currentLabel`}>
                  Reason<RedAsterisk />
                </Form.Label>
                <Form className="mt-2">
                  {closePathReasons && closePathReasons.length ? (
                    <>
                      <Form.Control
                        size="sm"
                        as="select"
                        id={`${componentName}-carePathReasonValue`}
                        onChange={(e) => handleReasonChange(e)}
                        value={selectedReason}
                        className="form-select"
                      >
                        <option value="Actions" selected hidden></option>
                        {closePathReasons
                          .map((reason) => reason.replace('Closed - ', ''))
                          .map((reason, idx) => (
                            <option id={`${componentName}-carePathReasonItem-${idx}`} value={reason}>
                              {reason}
                            </option>
                          ))}
                      </Form.Control>
                      {selectedExpired ? (
                        <Row>
                          <Form.Label id={`${componentName}-ExpiredDateLabel`} className="d-block mt-1">
                            Expired Date
                          </Form.Label>
                          <EQHDatePicker
                            id={`${componentName}-ExpiredDateValue`}
                            className="mt-1"
                            onChange={(e) => {
                              setSelectedExpiredDate(e.strDate);
                            }}
                            min={null}
                            max={format(new Date(), "yyyy-MM-dd")}
                            value={selectedExpiredDate}
                          />
                          <Form.Label id={`${componentName}-ExpiredSourceLabel`} className="d-block mt-1">
                            Confirmation Source
                          </Form.Label>
                          <Form.Control
                            size="sm"
                            as="select"
                            id={`${componentName}-carePathExpiredSourceValue`}
                            className="mt-1"
                            onChange={(e) => setSelectedExpiredSource(e.target.value)}
                            value={selectedExpiredSource}
                          >
                            <option value="Sources" selected hidden></option>
                            {expiredSources.map((source, idx) => (
                              <option id={`${componentName}-carePathExpiredSourceItem-${idx}`} value={source.value}>{source.value}</option>
                            ))}
                          </Form.Control>
                        </Row>
                      ) : (<></>)}
                    </>
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Form>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group>
                <Form.Label id={`${componentName}-carePathNotes`} className="fw-bold d-block">
                  Notes
                </Form.Label>
                <textarea id={`${componentName}-addNotesValue`}
                  className="form-control"
                  value={notes}
                  onChange={e => {
                    setNotes(e.target.value);
                  }}
                  rows="3">
                </textarea>
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-carePathReasonCancel`} className="btn" data-dismiss="modal" onClick={() => close()}>
          Cancel
        </Button>

        <Button id={`${componentName}-carePathReasonConfirm`}
          disabled={!selectedReason.length}
          className="btn btn-primary"
          onClick={() => { handleClose(); close(); }}
        >
          Close Program
        </Button>
        <ToastMessage />
      </Modal.Footer>
    </>
  );
};

export default CloseCareProgram;