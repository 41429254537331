import {actions} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_EVENTS_TIMELINE_LIST:
      return {
        ...state,
        eventsTimelineList: action.payload,
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload,
      };
    case actions.SET_USER_NAME_LIST_FILTER:
      return {
        ...state,
        userNameListFilter: action.payload,
      };
    case actions.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };
    case actions.SET_EVENT_TIMELINE_SEARCH:
      return {
        ...state,
        eventTimelineSearch: action.payload,
      };
    case actions.SET_AVAILABLE_EVENT_TYPES:
      return {
        ...state,
        availableEventTypes: action.payload,
      };
    default:
      return state;
  }
};