import React, { useRef, useState } from "react";
import {
    Button,
    Col,
    Row,
    Spinner,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Accordion from "react-bootstrap/Accordion";
import { Card } from "../../../../components/cards";
import { PlusMinusToggle } from "../../../common/PlusMinusToggle";
import styled from "styled-components";
import { searchPatients } from "../../../../actions/caremgmt/hubWorkCenterAction";
import { handleError } from "../../../../actions/caremgmt/myWorkspaceActions";
import EQHErrorToast from "../../../../components/EQHErrorToast";
import { searchPatientPrograms } from "./utils";
import { formatDate } from "../../../../utils/util";

export const AssignmentTracker = () => {
    const componentName = "AssignmentTracker";
    const SimpleText = styled.span`font-weight: normal!important;`;
    const typeaheadRef = useRef(null);

    const [errors, setErrors] = useState([]);
    const [isMemberLoading, setMemberLoading] = useState(false);
    const [memberOptions, setMemberOptions] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);
    const [programs, setPrograms] = useState([]);
    const isEmpty = require("is-empty");


    const handleMemberChanged = (e) => {
        if (!isEmpty(e)) {
            setSelectedMember(e[0]);
        }
    }

    const onError = (err) => {
        const error = handleError(err)
        setErrors([error]);
    }

    const membersearch = (query) => {
        if (query && query.length > 3) {
            const timeout = setTimeout(() => {
                setMemberLoading(true);
                searchPatients(query).then((response) => {
                    if (response && response.data) {
                        const options = response.data.patients.map(p => {
                            return { text: `${p.surnameCommaName} - ${p.memberId}`, value: p.id }
                        });
                        setMemberOptions(options);
                    }
                    setMemberLoading(false);
                }).catch((error) => {
                    onError(error);
                    setMemberLoading(false);
                });
            }, 1600);

            return () => clearTimeout(timeout);
        }
    }

    const handleReset = () => {
        setSelectedMember({})
        setMemberOptions([]);
        setPrograms([]);
        typeaheadRef.current.clear();
        setMemberLoading(false);
    }

    const handleSearch = () => {
        if (!isEmpty(selectedMember)) {
            setProgramsLoading(true);
            searchPatientPrograms(selectedMember.value).then((response) => {
                if (response && response.data) {
                    setPrograms(response.data);
                }
                setProgramsLoading(false);
            }).catch((error) => {
                onError(error);
                setProgramsLoading(false);
            });
        }
    }

    return (<>
        <Button variant="primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTracker" aria-controls="offcanvasTracker">
            Tracker
        </Button>
        <div
            className="offcanvas offcanvas-end mt-5 pt-4"
            data-bs-scroll="true"
            data-bs-backdrop="false"
            tabIndex="-1"
            id="offcanvasTracker"
            aria-labelledby="offcanvasTracker"
        >
            <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
            <div className="offcanvas-header mb-0 pb-0">
                <h5 className="offcanvas-title">Member Tracker</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <Col className="mb-3">
                    <Row className="px-2 mt-3">
                        <label id={`${componentName}-MemberLabel`}>Member Name or ID</label>
                        <AsyncTypeahead
                            labelKey="text"
                            isLoading={isMemberLoading}
                            onSearch={membersearch}
                            options={memberOptions}
                            onChange={(e) => handleMemberChanged(e)}
                            placeholder="Search Members by Name or ID"
                            ref={typeaheadRef}
                        />

                    </Row>
                    <Row className='px-2 mt-3 gap-4'>
                        <Col md={4}>
                            <Button
                                disabled={isEmpty(selectedMember)}
                                onClick={handleSearch} id={`${componentName}-searchBtn`}
                                variant="primary"
                            >Search</Button>
                        </Col>
                        <Col md={4}>
                            <Button
                                onClick={handleReset}
                                id={`${componentName}-resetBtn`}
                                variant="secondary"
                            ><i className="fas fa-redo-alt"></i> Reset</Button>
                        </Col>
                    </Row>
                </Col>
                <Row>
                    {programsLoading && <Card>
                        <Spinner animation="border" variant="secondary" size="lg" />
                    </Card>}
                    <Row className="text-center">
                        {isEmpty(programs) && !programsLoading && <p className="mt-3">No programs found for selected member</p>}
                    </Row>
                    {programs.map((program, i) =>
                        <Col style={{ minWidth: '100%' }}>
                            <Accordion defaultActiveKey="0" className="mb-3 border-0">
                                <Card className="rounded shadow-sm border-0">
                                    <Card.Header eventKey="0">
                                        <PlusMinusToggle eventKey="0">
                                            Program: {program.programName} (<SimpleText>{formatDate(program.creationDate)}</SimpleText>)
                                        </PlusMinusToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <table style={{ fontSize: '10px' }} className="table table-bordered table-responsive mb-0 pb-0">
                                            <thead className="text-center">
                                                <th>Date</th>
                                                <th>Program Lead</th>
                                                <th>Program Status / Reassignment</th>
                                            </thead>
                                            <tbody>
                                                {program.programStatusHistoryList?.map((h, index) =>
                                                    <tr><td>{formatDate(h.creationDate)}</td><td>{h.programLead}</td><td>{h.status}</td></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    </>)
}