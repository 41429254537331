export function getGetters(state) {
    return {
        showAddAppointment: state.showAddAppointment,
        selectedDate: state.selectedDate,
        options: state.options,
        addressList: state.addressList,
        isEdit: state.isEdit,
        showCancelAppointment: state.showCancelAppointment,
        isCancelingAppointment: state.isCancelingAppointment,
        appointments: state.appointments,
        totalSize: state.totalSize
    }
}