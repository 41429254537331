import React from 'react'
import {Col, Dropdown} from "react-bootstrap";
import {StatusButton} from "../constants";

const componentName = "StatusMenu"

const StatusMenu = ({
  items,
  onClick,
  status,
  disabled = false
}) => {

  const onMenuItemClick = (selectedStatus) => {
    onClick && onClick(selectedStatus.text);
  }

  return (
    <Dropdown disabled={disabled} className="text-end">

      <Dropdown.Toggle as={StatusButton} status={status} disabled={disabled}
                       className="badge badge-pill border-0 text-wrap"
                       variant="outline-secondary"
                       size="s"
                       style={{padding: '4px 16px 4px 10px'}}>
        {status} <i className={"fa-solid fa-chevron-down fa-xs ms-2"}/>
      </Dropdown.Toggle>

      <Dropdown.Menu disabled={disabled}
                     className='dropdown-menu-aligned'>
        {items && items.map((item, itemIdx) => {
          return (
            <Col
              id={`${componentName}-colMenuItem-${itemIdx}`}
              key={`${componentName}-colMenuItem-${item.value}`}
            >
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => onMenuItemClick(item)}
              >{item.text}</Dropdown.Item>
            </Col>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default StatusMenu;