const appointmentsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "update":
        break;
      default:
        throw new Error();
    }
  };

  export {appointmentsReducer};