import axios from "axios"
import { formatDateVanilla } from "../../utils/util";
const STEP_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/reports/care-path-step-name-filter";
const DETAILED_REPORT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/reports/care-path-step-report/detailed";

export const getStepNameFilter = (clientId) => {
    if (typeof clientId === 'undefined') clientId = "";
    return axios.get(STEP_URL + "?clientId=" + clientId);
}


export const getDetailedReport = (stepName, page, dateRange) => {
    return axios.post(DETAILED_REPORT_URL, {
        stepName,
        pageSize: 20,
        pageNumber: page,
        startDate: formatDateVanilla(dateRange[0], 'yyyy-MM-dd'),
        endDate: formatDateVanilla(dateRange[1], 'yyyy-MM-dd')
    }, {
        headers: {
          "Content-Type": "application/json",
        },
    })
}