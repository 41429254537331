import React, {createContext, useContext, useReducer} from 'react';
import {useDispatch} from "react-redux";
import {uniqBy, orderBy} from 'lodash'

import {initialState} from './actions';
import {reducer} from './Notes.reducer';
import {addNote, getNoteCategories, getNotes} from './api';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from "./setters";
import {
  useEQHToastContext
} from "../../../../components/toast/EQHToast.context";
import { setReloadWorklist } from '../../../../actions/manageVisitActions';

const NotesContext = createContext();

const NotesProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {removeNotification, setNotification} = useEQHToastContext();

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const toUsernamesFilter = (userNameList) => {
    return (userNameList ?? []).map(userName => ({
      value: userName,
      text: userName
    }))
  }

  const callbacks = {
    getNotes: async (patientId, requestBody) => {
      setters.setNotesList([])
      setters.setTotalSize(0)
      return await getNotes(patientId, requestBody)
      .then((notesResponse) => {
        const {
          totalSize,
          pageNumber,
          pageSize,
          patientActivityDtoList,
          userNameList
        } = notesResponse?.data
        setters.setNotesList(patientActivityDtoList)
        setters.setTotalSize(totalSize)
        setters.setNotesSearch(requestBody)
        toggles.setHasMore(pageNumber <= (totalSize / pageSize))
        setters.setUserNameListFilter(toUsernamesFilter(userNameList))
      })
      .finally(() => {
        toggles.setIsLoading(false)
      })
    },
    scrollNotes: async (patientId, requestBody) => {
      const notesSearch = {
        ...requestBody,
        pageNumber: state.notesSearch?.pageNumber + 1
      };

      toggles.setIsLoading(true)
      await getNotes(patientId, notesSearch)
      .then((notesResponse) => {
        const {
          totalSize,
          pageSize,
          pageNumber,
          patientActivityDtoList,
          userNameList
        } = notesResponse?.data
        setters.setNotesList([
          ...state.notesList,
          ...patientActivityDtoList
        ])
        const userNameListFilter = [
          ...state.userNameListFilter, ...toUsernamesFilter(userNameList)
        ];

        setters.setUserNameListFilter(uniqBy(userNameListFilter, 'value'))
        setters.setNotesSearch(notesSearch)
        setters.setTotalSize(totalSize)
        toggles.setHasMore(pageNumber <= (totalSize / pageSize))
      })
      .finally(() => {
        toggles.setIsLoading(false)
      })
    },
    addNote: async (patientId, requestBody) => {
      removeNotification();
      toggles.toggleIsAddingNote(true)
      return await addNote(requestBody)
      .then((res) => {
        callbacks.getNotes(patientId, state.notesSearch)
        toggles.toggleIsAddingNote(false)
        toggles.toggleShowAddNote(false)
        globalDispatch(setReloadWorklist(true));
        setNotification('Successful', 'Note added successfully');
      })
      .catch((err) => {
        setNotification('Error',
          (err?.response?.data?.message
            || "Error while adding the note"));
      }).finally(() => {
        toggles.toggleIsAddingNote(false)
      });
    },
    getNoteCategories: async () => {
      setters.setCategories([])
      return await getNoteCategories().then(
        response => {
          const categories = response.data && orderBy(response.data,
            [item => item.description.toLowerCase()], ['asc']).map(
            category => ({text: category.description, value: category.name}));
          setters.setCategories(categories);
        })
    },
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <NotesContext.Provider value={value}>
      {children}
    </NotesContext.Provider>
  )
};
const useNotesContext = () => useContext(NotesContext);

export {
  NotesProvider,
  useNotesContext,
}