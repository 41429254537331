import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row, Badge } from 'react-bootstrap';
import styled from 'styled-components';
import {
  _calculateAge,
  formatDate,
  formatDateWithPattern,
  formatLocalDate,
  getDynamicDisplayValue,
  getRiskLevelStyle
} from '../../../utils/util';
import { setManageVisitPatient, setManageVisitOpenedFromPageTitle, setPatientListType } from '../../../actions/manageVisitActions';
import { useDispatch, useSelector } from 'react-redux';
import { hidePatientsFound, hidePopulationSearchForm, temporarilyHidePGS } from '../../../actions/populationSearch/populationSearchActions';
import QuarterlyVisitDueIcon from "../../../img/quarterlyvisitdue-icon.svg";
import {AppPropertiesUtil} from "../../../utils/localStorageUtil";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {worklistStatusMessage} from "../../../utils/textValueLists";

const componentName = 'PatientRow'

export default function PatientRow({
  patient,
  quickListType,
  idx,
  isPatientSelected,
  handleChangeCheckBox = () => { },
  setSelectedPatient,
  setShowPatientDetails,
  pageTitle,
  key,
  handleShowConfirmUnschedule,
  showConfirmUnschedule,
  handleShowNoShow,
  handleCloseNoShow,
  showNoShow,
  handleCloseConfirmUnschedule,
  handlePatientGapStatus,
  handleShowSchedule,
  handleShowUnableToContact,
  showConfirmUnableToContact,
  handleCloseConfirmUnableToContact,
  handleShowLeftMessage,
  showConfirmLeftMessage,
  handleCloseConfirmLeftMessage,
  handleShowChangedPCP,
  showConfirmChangedPCP,
  handleCloseConfirmChangedPCP,
  handleShowPatientRefused,
  showConfirmPatientRefused,
  handleCloseConfirmPatientRefused,
  isGlobalSearch = false,
  listType
}) {
  const dispatch = useDispatch()
  const [isUnattributed, setisUnattributed] = useState(false)
  const state = useSelector(state => state.populationSearch);

  useEffect(() => {
    if (!state?.patientsFound?.isPatientVisible && state?.isShowingForm) {
      setisUnattributed(true)
    } else {
      setisUnattributed(false)
    }
  }, [state.patientsFound, state.isShowingForm])

  const isReferralsPage = quickListType === "REFERRALS_BY_USER";

  const enableVistDue = AppPropertiesUtil.get("react.properties.ecip.eligible.enabled");

  const getDateSent = (referralDtoList) => {
    let referral = null;
    referralDtoList && referralDtoList.forEach((cp, index) => {
      referral = cp;
    });
    return formatLocalDate(referral?.refDateUTC);
  }
  return (
    <Card key={patient.id} id={`${componentName}-patientsCard-${idx}`}
      className="ps-2 pe-3 mb-3 patientCard">
      <Card.Body>
        <Row>
          <Col xl={4} lg={12} md={12} className="align-self-center">
            <div className="d-flex flex-row align-items-center mb-2 mb-xl-0">
              {!isGlobalSearch &&
                <div className="form-check">
                  <input
                    id={`${componentName}-patientSelect-${idx}`}
                    className="form-check-input me-3"
                    type="checkbox"
                    name={patient.id}
                    checked={isPatientSelected(patient)}
                    onChange={(e) =>
                      handleChangeCheckBox(
                        patient,
                        e.target.checked
                      )
                    }
                  />
                  <label htmlFor="basic_checkbox_2"></label>
                </div>
              }
              <div>
                <Row>
                  <Col>
                    <a
                      id={`${componentName}-patientFullName-${idx}`}
                      data-bs-toggle={!isUnattributed ? "offcanvas" : ''}
                      data-bs-target={!isUnattributed ? "#patientDetailsModal" : ''}
                      aria-controls="patientDetailsModal"
                      className="fw-bold patientName align-middle me-3"
                      onClick={() => {
                        if (!isUnattributed) {
                          setSelectedPatient(patient);
                          setShowPatientDetails(true);
                          dispatch(setManageVisitPatient(patient))
                          dispatch(hidePopulationSearchForm())
                          dispatch(setManageVisitOpenedFromPageTitle(pageTitle))
                          dispatch(temporarilyHidePGS(true))
                          dispatch(setPatientListType(listType))
                        }
                      }}
                    >
                      <span style={{ color: isUnattributed ? 'gray' : '' }} className="forced-capital fs-5 d-inline-block">{patient.fullName || patient.surnameCommaName}</span>
                    </a>
                    <Badge pill bg="light" text="dark" className="border me-1 forced-capital" id={`${componentName}-patientGender-${idx}`}>{patient.gender}</Badge>
                    <Badge pill bg={getRiskLevelStyle(patient.riskLevel)} id={`${componentName}-patientRiskLevel-${idx}`}>
                      {patient.riskLevel}
                    </Badge>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div id={`${componentName}-patientMemberID-${idx}`} className="d-inline-block me-3">
                      <span id={`${componentName}-patientMemberIDLabel-${idx}`} className="fw-bold me-2">ID:</span>
                      <span id={`${componentName}-patientMemberIDValue-${idx}`}>{patient.memberId}</span>
                    </div>
                    <div id={`${componentName}-patientDOB-${idx}`} className="d-inline-block">
                      <span id={`${componentName}-patientDOBLabel-${idx}`} className="fw-bold me-2">DOB:</span>
                      <span id={`${componentName}-patientDOBValue-${idx}`}>
                        {formatDate(patient.dateOfBirth)} <Badge pill bg="light" text="dark" className="border p-1">{patient.age}</Badge>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={6} className="align-self-center mb-2 mb-md-0">
            {!isReferralsPage && (
                <div id={`${componentName}-patientPayor-${idx}`}>
                  <label id={`${componentName}-patientPayorLabel-${idx}`}
                         className="me-2">
                    Payor:
                  </label>
                  <span id={`${componentName}-patientPayorValue-${idx}`}>
                      {patient.payer}
                    </span>
                </div>
            )}

            <div id={`${componentName}-patientPCPPractice-${idx}`}>
              <label id={`${componentName}-patientPCPPracticeLabel-${idx}`} className="me-2">
                PCP Practice:
              </label>
              <span id={`${componentName}-patientPCPPracticeValue-${idx}`}>
                {patient.originatingOrganization}
              </span>
            </div>
          </Col>

          { isReferralsPage ? (
              <>
                <Col className="align-self-center mb-2 mb-md-0">
                  <div id={`${componentName}-patientProgramStatus-${idx}`}>
                    <label
                        id={`${componentName}-patientProgramStatusLabel-${idx}`}
                        className="me-2">
                      Program Status:
                    </label>
                    <span
                        id={`${componentName}-patientProgramStatusValue-${idx}`}>
                      {patient.programStatus}
                    </span>
                  </div>
                </Col>
                <Col className="align-self-center mb-2 mb-md-0">
                  <div id={`${componentName}-patientDateSent-${idx}`}>
                    <label id={`${componentName}-patientDateSentLabel-${idx}`}
                           className="me-2">
                      Date Sent:
                    </label>
                    <span id={`${componentName}-patientDateSentValue-${idx}`}>
                      {getDateSent(patient.referralDtoList)}
                    </span>
                  </div>
                </Col>
              </>
          ) : (
            <Col xl={3} lg={4} md={6} className="d-flex flex-nowrap justify-content-start justify-content-md-end justify-content-lg-center align-items-center ps-md-5">
              {enableVistDue && patient.ecipEligible && (
                <OverlayTrigger placement="auto" overlay={<Tooltip>Quarterly Visit Eligible</Tooltip>}>
                  <div className="align-ecip" id={`${componentName}-quaterly-icon`} >
                    <img src={QuarterlyVisitDueIcon} alt="Quarterly Incentive Icon" width="25px" className="me-2"></img>
                  </div>
                </OverlayTrigger>
              )}
              <div className="text-start w-100">
                {quickListType === "TRANSITIONS_OF_CARE" ? (
                  <>
                    {patient.latestAdmitTime ?
                      (
                        <Row>
                          <Col>
                            <span id={`${componentName}-AdmittedOn-${idx}`} className="fw-bold me-2">
                              Admitted on {patient.latestAdmitTime ? formatDateWithPattern(patient.latestAdmitTime, "MMM dd, yyyy") : ""}
                            </span>
                          </Col>
                        </Row>
                      ) : ("")
                    }
                    {patient.latestDischargeDate ?
                      (
                        <Row>
                          <Col>
                            <span id={`${componentName}-DischargedOn-${idx}`} className="fw-bold me-2">
                              Discharged on {patient.latestDischargeDate ? formatDateWithPattern(patient.latestDischargeDate, "MMM dd, yyyy") : ""}
                            </span>
                          </Col>
                        </Row>
                      ) : ("")
                    }
                    {patient.nextVisit ?
                      (
                        <Row>
                          <Col>
                            <span id={`${componentName}-FollowUpDueBy-${idx}`} className="fw-bold me-2">
                              Follow up due by {patient.nextVisit ? formatDateWithPattern(patient.nextVisit, "MMM dd, yyyy") : ""}
                            </span>
                          </Col>
                        </Row>
                      ) : ("")
                    }
                  </>
                ) : quickListType === "USER_WORK_LIST" || quickListType === "SCHEDULE" ? ( patient.patientGapStatus == "APPOINTMENT_SCHEDULED" ? 
                  (
                    <Row>
                      <Col>
                        <span id={`${componentName}-patientAppointmentValue-${idx}`} className="fw-bold me-2">
                          Appointment Scheduled for {patient.patientGapAppointmentDate ? formatDateWithPattern(patient.patientGapAppointmentDate, "MMM dd, yyyy") : ""}
                        </span>
                      </Col>
                    </Row>
                  ) : ( patient.patientGapStatus === "APPOINTMENT_UNSCHEDULED" && patient?.appointmentCancelDate != null ? 
                    ( 
                      <Row>
                        <Col>
                          <div id={`${componentName}-patientNextVisitValue-${idx}`} className="fw-bold">
                            {patient.displayName}
                          </div>
                          <small id={`${componentName}-patientNextVisitValue-${idx}`} className="fw-bold text-danger fst-italic">
                            No Show on {formatDate(patient.appointmentCancelDate)}
                          </small>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <div id={`${componentName}-patientNextVisitValue-${idx}`} className="fw-bold me-2">
                            {patient.displayName}
                          </div>
                          {patient.gapStatusChangeDate !== null && (patient.patientGapStatus === "UNABLE_TO_CONTACT" || patient.patientGapStatus === "LEFT_MESSAGE" ||
                          patient.patientGapStatus === "CHANGED_PCPS" || patient.patientGapStatus === "PATIENT_REFUSAL") ?
                            <small id={`${componentName}-patientWorklistStatusChangeValue-${idx}`}
                                    className="fw-bold text-danger fst-italic">
                              {getDynamicDisplayValue(
                                  worklistStatusMessage,
                                  patient.patientGapStatus,
                                  "value",
                                  "text"
                              )}{" "}
                              {formatDate(patient.gapStatusChangeDate)}
                            </small> : ""
                          }
                        </Col>
                      </Row>
                    )
                  )
                ) : (quickListType === "REQUIRING_ACTION" ?
                  (patient.displayName ?
                    (
                      <Row>
                        <Col>
                          <span id={`${componentName}-QuarterlyVisitDueBy-${idx}`} className="fw-bold me-2">
                            {patient.displayName ? patient.displayName : ""}
                          </span>
                        </Col>
                      </Row>
                    ) : ("")
                  ) : (quickListType !== "MISSED_OPPORTUNITIES" ? (
                    <div className="d-flex flex-wrap justify-content-start gap-2">
                      <span id={`${componentName}-CareOpportunities-${idx}`} className="d-flex align-items-center fw-bold text-nowrap me-3 me-lg-0 me-xl-3">
                        <span className="badge bg-light border text-dark p-2 rounded-circle me-2 minb-1">{patient.openCareGapsCount ? patient.openCareGapsCount : "0"}</span> Care Opportunities
                      </span>
                      <span id={`${componentName}-Suspects-${idx}`} className="d-flex align-items-center fw-bold text-nowrap">
                        <span className="badge bg-light border text-dark p-2 rounded-circle me-2 minb-1">{patient.activeRafsCount ? patient.activeRafsCount : "0"}</span> Suspect Conditions
                      </span>
                    </div>
                    ) : (quickListType === "MISSED_OPPORTUNITIES" && (
                      <>
                        <div className="d-flex flex-wrap justify-content-start gap-2">
                          {listType === "requiredActivities" && patient.missedCareOppsCount !== 0 && (
                            <span id={`${componentName}-MO-CareOpportunities-${idx}`} className="d-flex align-items-center fw-bold">
                              <span className="badge bg-light border text-dark p-2 rounded-circle me-2 minb-1">{patient.missedCareOppsCount ? patient.missedCareOppsCount : "0"}</span> {patient.missedCareOppsCount > 1 ? "Care Opportunities" : "Care Opportunity"} to be addressed
                            </span>
                          )}
                          {listType === "requiredActivities" && patient.missedSuspectsCount !== 0 && (
                            <span id={`${componentName}-MO-Suspects-${idx}`} className="d-flex align-items-center fw-bold">
                              <span className="badge bg-light border text-dark p-2 rounded-circle me-2 minb-1">{patient.missedSuspectsCount ? patient.missedSuspectsCount : "0"}</span> {patient.missedSuspectsCount > 1 ? "Suspect Conditions" : "Suspect Condition"} to be addressed
                            </span>
                          )}
                          {listType === "awaitingChart" && (
                            <span id={`${componentName}-MO-AwaitingChart-${idx}`} className="fw-bold me-2">
                              {patient.displayName ? patient.displayName : ""}
                            </span>
                          )}
                          {listType === "rejected" && patient.careGapLastRejectionDate && (
                            <span id={`${componentName}-MO-RejectedCareOpp-${idx}`} className="fw-bold me-2">
                              Care Opportunity was rejected on {formatDate(patient.careGapLastRejectionDate)}
                            </span>
                          )}
                          {listType === "rejected" && patient.conditionLastRejectionDate && (
                            <span id={`${componentName}-MO-RejectedSuspect-${idx}`} className="fw-bold me-2">
                              Suspect Condition was rejected on {formatDate(patient.conditionLastRejectionDate)}
                            </span>
                          )}
                          {patient.missingOppCompletingOrganization && (
                            <span id={`${componentName}-MO-VisitComplete-${idx}`} className="fw-bold fst-italic text-danger me-2">
                              Visit completed by {patient.missingOppCompletingOrganization}
                            </span>
                          )}
                        </div>
                      </>
                    ))
                  )
                )}
              </div>
            </Col>
          )}
          { !isReferralsPage && (
          <Col xl={2} lg={4} md={12} className="d-flex justify-content-between text-nowrap align-items-center align-self-center">
            <div id={`${componentName}-patientPrimaryPhoneValue-${idx}`} className="h5 mb-0 me-3">
              {patient.primaryPhone}
            </div>
            {quickListType !== "SCHEDULE" && patient.active ?
              (
                <div id={`${componentName}-patientActions`} className="btn-group">
                  {
                    !isUnattributed &&
                    <button id={`${componentName}-patientActionsIcon`} type="button" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa-solid fa-ellipsis border-0 text-dark"></i>
                    </button>
                  }
                  <ul id={`${componentName}-patientActionsDropdown`} className="dropdown-menu dropdown-menu-end">
                    {key == "unScheduledPatients" || patient.patientGapStatus == "APPOINTMENT_UNSCHEDULED" || patient.patientGapStatus == "UNABLE_TO_CONTACT" || patient.patientGapStatus == "LEFT_MESSAGE" || patient.patientGapStatus == "PATIENT_REFUSAL" || patient.patientGapStatus == "CHANGED_PCPS" ?
                      (
                        <li>
                          <button id={`${componentName}-patientUnScheduledAppLink`} className="dropdown-item" eventKey="0" disabled>
                            Unschedule Appointment
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientUnScheduledAppLink`} className="dropdown-item" eventKey="0" onClick={() => { setSelectedPatient(patient); handleShowConfirmUnschedule(); }}>
                            Unschedule Appointment
                          </button>
                          <Modal id={`${componentName}-patientUnScheduledConfirmModal`} size="md" show={showConfirmUnschedule} onHide={handleCloseConfirmUnschedule} backdropClassName="confirmbackdrop" centered>
                            <Modal.Header>
                              <Modal.Title id={`${componentName}-patientUnScheduledConfirmTitle`}>Confirm</Modal.Title>
                              <button id={`${componentName}-patientUnScheduledConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmUnschedule}></button>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mb-3">
                                <Col>
                                  <p>Are you sure you want to Unschedule this appointment?</p>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button id={`${componentName}-patientUnScheduledNoBtn`} variant="secondary" onClick={handleCloseConfirmUnschedule}>
                                No
                              </Button>
                              <Button id={`${componentName}-patientUnScheduledYesBtn`} variant="primary" onClick={() => { handleCloseConfirmUnschedule(); handlePatientGapStatus("APPOINTMENT_UNSCHEDULED"); }}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </li>
                      )
                    }
                    {patient.patientGapStatus == "APPOINTMENT_SCHEDULED" ?
                      (
                        <li>
                          <button id={`${componentName}-patientScheduledAppLink`} className="dropdown-item" eventKey="0" disabled>
                            Schedule Appointment
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientScheduledAppLink`} onClick={() => {
                            setSelectedPatient(patient);
                            handleShowSchedule();
                          }}
                            className="dropdown-item" eventKey="0">
                            Schedule Appointment
                          </button>
                        </li>
                      )
                    }
                    {patient.patientGapStatus == "APPOINTMENT_SCHEDULED" || patient.patientGapStatus == "UNABLE_TO_CONTACT" ?
                      (
                        <li>
                          <button id={`${componentName}-patientUnabletoContactAppLink`} className="dropdown-item" eventKey="0" disabled>
                            Unable to Contact
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientUnabletoContactAppLink`} onClick={() => { setSelectedPatient(patient); handleShowUnableToContact(); }} className="dropdown-item" eventKey="0">
                            Unable to Contact
                          </button>
                          <Modal id={`${componentName}-patientUnabletoContactConfirmModal`} size="md" show={showConfirmUnableToContact} onHide={handleCloseConfirmUnableToContact} backdropClassName="confirmbackdrop" centered>
                            <Modal.Header>
                              <Modal.Title id={`${componentName}-patientUnabletoContactConfirmTitle`}>Confirm</Modal.Title>
                              <button
                                id={`${componentName}-patientUnabletoContactConfirmModalClose`}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCloseConfirmUnableToContact}
                              ></button>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mb-3">
                                <Col>
                                  <p>Are you sure you want to set this Patient as Unable to Contact?</p>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button id={`${componentName}-patientUnabletoContactNoBtn`} variant="secondary" onClick={handleCloseConfirmUnableToContact}>
                                No
                              </Button>
                              <Button id={`${componentName}-patientUnabletoContactYesBtn`} variant="primary" onClick={() => { handleCloseConfirmUnableToContact(); handlePatientGapStatus("UNABLE_TO_CONTACT"); }}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </li>
                      )
                    }
                    {patient.patientGapStatus == "APPOINTMENT_SCHEDULED" || patient.patientGapStatus == "LEFT_MESSAGE" ?
                      (
                        <li>
                          <button id={`${componentName}-patientLeftMessLink`} className="dropdown-item" type="button" disabled>
                            Left Message
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientLeftMessLink`} onClick={() => { setSelectedPatient(patient); handleShowLeftMessage(); }} className="dropdown-item" type="button" >
                            Left Message
                          </button>
                          <Modal id={`${componentName}-patientLeftMessageConfirmModal`} size="md" show={showConfirmLeftMessage} onHide={handleCloseConfirmLeftMessage} backdropClassName="confirmbackdrop" centered>
                            <Modal.Header>
                              <Modal.Title id={`${componentName}-patientLeftMessageConfirmTitle`}>Confirm</Modal.Title>
                              <button id={`${componentName}-patientLeftMessageConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmLeftMessage}></button>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mb-3">
                                <Col>
                                  <p>Are you sure you want to set this Patient as Left Message?</p>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button id={`${componentName}-patientLeftMessageNoBtn`} variant="secondary" onClick={handleCloseConfirmLeftMessage}>
                                No
                              </Button>
                              <Button id={`${componentName}-patientLeftMessageYesBtn`} variant="primary" onClick={() => { handleCloseConfirmLeftMessage(); handlePatientGapStatus('LEFT_MESSAGE'); }}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </li>
                      )
                    }
                    {patient.patientGapStatus == "APPOINTMENT_SCHEDULED" || patient.patientGapStatus == "CHANGED_PCPS" ?
                      (
                        <li>
                          <button id={`${componentName}-patientChangedPCPAppLink`} className="dropdown-item" eventKey="0" disabled>
                            Changed PCP
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientChangedPCPAppLink`} onClick={() => { setSelectedPatient(patient); handleShowChangedPCP(); }} className="dropdown-item" eventKey="0">
                            Changed PCP
                          </button>
                          <Modal id={`${componentName}-patientChangedPCPConfirmModal`} size="md" show={showConfirmChangedPCP} onHide={handleCloseConfirmChangedPCP} backdropClassName="confirmbackdrop" centered>
                            <Modal.Header>
                              <Modal.Title id={`${componentName}-patientChangedPCPConfirmTitle`}>Confirm</Modal.Title>
                              <button id={`${componentName}-patientChangedPCPConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmChangedPCP}></button>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mb-3">
                                <Col>
                                  <p>Are you sure you want to set this Patient as Changed PCP?</p>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button id={`${componentName}-patientChangedPCPNoBtn`} variant="secondary" onClick={handleCloseConfirmChangedPCP}>
                                No
                              </Button>
                              <Button id={`${componentName}-patientChangedPCPYesBtn`} variant="primary" onClick={() => { handleCloseConfirmChangedPCP(); handlePatientGapStatus('CHANGED_PCPS') }}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </li>
                      )
                    }
                    {patient.patientGapStatus == "APPOINTMENT_SCHEDULED" || patient.patientGapStatus == "PATIENT_REFUSAL" ?
                      (
                        <li>
                          <button id={`${componentName}-patientPatientRefusedAppLink`} className="dropdown-item" eventKey="0" disabled>
                            Patient Refused
                          </button>
                        </li>
                      ) : (
                        <li>
                          <button id={`${componentName}-patientPatientRefusedAppLink`} onClick={() => { setSelectedPatient(patient); handleShowPatientRefused(); }} className="dropdown-item" eventKey="0">
                            Patient Refused
                          </button>
                          <Modal id={`${componentName}-patientPatientRefusedConfirmModal`} size="md" show={showConfirmPatientRefused} onHide={handleCloseConfirmPatientRefused} backdropClassName="confirmbackdrop" centered>
                            <Modal.Header>
                              <Modal.Title id={`${componentName}-patientPatientRefusedConfirmTitle`}>Confirm</Modal.Title>
                              <button id={`${componentName}-patientPatientRefusedConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmPatientRefused}></button>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mb-3">
                                <Col>
                                  <p>Are you sure you want to set this Patient as Patient Refused?</p>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button id={`${componentName}-patientPatientRefusedNoBtn`} variant="secondary" onClick={handleCloseConfirmPatientRefused}>
                                No
                              </Button>
                              <Button id={`${componentName}-patientPatientRefusedYesBtn`} variant="primary" onClick={() => { handleCloseConfirmPatientRefused(); handlePatientGapStatus('PATIENT_REFUSAL'); }}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </li>
                      )
                    }
                  </ul>
                </div>
              )
              :
                quickListType === "SCHEDULE" ? (
              <div id={`${componentName}-patientActions`} className="btn-group">
                {
                  !isUnattributed &&
                  <button id={`${componentName}-patientActionsIcon`} type="button" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa-solid fa-ellipsis border-0 text-dark"></i>
                  </button>
                }
                <ul id={`${componentName}-patientActionsDropdown`} className="dropdown-menu dropdown-menu-end">
                  <li>
                    <button id={`${componentName}-patientUnScheduledAppLink`} className="dropdown-item" eventKey="0" onClick={() => { setSelectedPatient(patient); handleShowConfirmUnschedule(); }}>
                      Unschedule Appointment
                    </button>
                    <Modal id={`${componentName}-patientUnScheduledConfirmModal`} size="md" show={showConfirmUnschedule} onHide={handleCloseConfirmUnschedule} backdropClassName="confirmbackdrop" centered>
                      <Modal.Header>
                        <Modal.Title id={`${componentName}-patientUnScheduledConfirmTitle`}>Confirm</Modal.Title>
                        <button id={`${componentName}-patientUnScheduledConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmUnschedule}></button>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="mb-3">
                          <Col>
                            <p>Are you sure you want to Unschedule this appointment?</p>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button id={`${componentName}-patientUnScheduledNoBtn`} variant="secondary" onClick={handleCloseConfirmUnschedule}>
                          No
                        </Button>
                        <Button id={`${componentName}-patientUnScheduledYesBtn`} variant="primary" onClick={() => { handleCloseConfirmUnschedule(); handlePatientGapStatus("APPOINTMENT_UNSCHEDULED"); }}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>
                  <li>
                    <button id={`${componentName}-patientScheduledAppLink`} onClick={() => {
                      setSelectedPatient(patient);
                      handleShowSchedule();
                    }}
                      className="dropdown-item" eventKey="0">
                      Reschedule Appointment
                    </button>
                  </li>
                  {(patient.patientGapAppointmentDate) && formatLocalDate(patient.patientGapAppointmentDate) < formatLocalDate(new Date()) &&
                    <li><hr className="dropdown-divider" /></li>
                  }
                  <li>
                    {(patient.patientGapAppointmentDate) && formatLocalDate(patient.patientGapAppointmentDate) < formatLocalDate(new Date()) &&
                      <button id={`${componentName}-patientScheduledAppLink`} onClick={() => {setSelectedPatient(patient); handleShowNoShow();}} className="dropdown-item" eventKey="0">
                        No Show
                      </button>
                    }
                    <Modal id={`${componentName}-patientUnScheduledConfirmModal`} size="md" show={showNoShow} onHide={handleCloseNoShow} backdropClassName="confirmbackdrop" centered>
                      <Modal.Header>
                        <Modal.Title id={`${componentName}-patientUnScheduledConfirmTitle`}>Confirm</Modal.Title>
                        <button id={`${componentName}-patientUnScheduledConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseNoShow}></button>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="mb-3">
                          <Col>
                            <p>Are you sure you want to set this patient to No show?</p>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button id={`${componentName}-patientUnScheduledNoBtn`} variant="secondary" onClick={handleCloseNoShow}>
                          No
                        </Button>
                        <Button id={`${componentName}-patientUnScheduledYesBtn`} variant="primary" onClick={() => { handleCloseNoShow(); handlePatientGapStatus("NO_SHOW"); }}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>
                </ul>
              </div>)
                  :(<div id={`${componentName}-noActions`} className="btn-group">
                  </div>
                  ) }
          </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}
