import { CREATE_USER, UPDATE_USER, UPDATE_MULTIPLE_USER,GET_ALL_USERS, GET_USERS, GET_VALUES_USER_CREATE, ACTIVATE_DEACTIVATE_USER } from "../../actions/types";

const initialState = {
  currentUsers: [],
  allUsers: [],
  totalSize: {},
  pageSize: {},
  pagenumber: {},
  loading: false,
  userCreateDropDownValues: {},
  userActivatedDeactivated: ''

};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_VALUES_USER_CREATE:
      return {
        ...state,
       userCreateDropDownValues: payload,
      };

      case ACTIVATE_DEACTIVATE_USER:
        return {
          ...state,
          currentUsers: [...state.currentUsers.filter(item1 => !payload.find(item2 => item1.userAccountId === item2.userAccountId)), ...payload],
         userActivatedDeactivated: payload,
         loading: false
        };

      case "TOGGLE_LOAD":
      return {
        ...state,
        loading: payload,
      };
    case GET_USERS:
      return {
        ...state,
        currentUsers: payload.content && payload.content.length? payload.content : ["Empty"],
        totalSize: payload.totalSize,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        loading: false,
        userActivatedDeactivated: ''
      };
      case GET_ALL_USERS:
        return {
          ...state,
          allUsers: payload && payload.length? payload : ["Empty"],
        };
    case CREATE_USER:
      return {
        ...state,
        currentUsers: state.currentUsers.push(payload),
        totalSize: payload.totalSize,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        loading: false
      };
      case UPDATE_USER:
        return {
          ...state,
          currentUsers: state.currentUsers.map(x => (x.userAccountId === payload.userAccountId) ? payload : x),
          totalSize: payload.totalSize,
          pageSize: payload.pageSize,
          pageNumber: payload.pageNumber,
          loading: false
        };

        case UPDATE_MULTIPLE_USER:
          return {
            ...state,
            currentUsers: [...state.currentUsers.filter(item1 => !payload.find(item2 => item1.userAccountId === item2.userAccountId)), ...payload],
            totalSize: payload.totalSize,
            pageSize: payload.pageSize,
            pageNumber: payload.pageNumber,
            loading: false
          };
    default:
      return state;
  }
}
