import React from 'react'

import {
  DeleteCustomWorklist as MyMembersDeleteCustomWorklist
} from "../../../../../../components/CustomWorklist";
import {useCMWorklistContext} from "../context/CMWorklist.context";
import {getQuickListNameByRole} from "../../../utils";
import {useAuth} from "../../../../../../context/authContext";

const componentName = 'MyMembersDeleteCustomWorklist';

function DeleteCustomWorklist() {
  const auth = useAuth();

  const {
    activeRole,
    sendEvent
  } = useCMWorklistContext();

  const locationToRedirect = "/my_members";
  const role = (activeRole.role) ?? auth.getRoles()[0];
  const quickListName = getQuickListNameByRole(role);

  return (
    <MyMembersDeleteCustomWorklist {...{
      quickListName,
      componentName,
      sendEvent,
      locationToRedirect,
      activeRole
    }}/>
  )
}

export default DeleteCustomWorklist;