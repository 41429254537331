import { UPDATE_SUCCESS, RESET_SUCCESS } from "../actions/types";

const initialState = {
  message: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SUCCESS:
      return { ...state, message: payload };
    case RESET_SUCCESS:
      return {
        state: {},
      };
    default:
      return state;
  }
}
