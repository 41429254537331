export function getGetters(state) {
    return {
        eventsTimelineList: state.eventsTimelineList,
        userNameListFilter: state.userNameListFilter,
        pageNumber: state.pageNumber,
        isLoading: state.isLoading,
        hasMore: state.hasMore,
        totalSize: state.totalSize,
        eventTimelineSearch: state.eventTimelineSearch,
        availableEventTypes: state.availableEventTypes
    }
}