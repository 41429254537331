import axios from "../../../../../../utils/apiClient";

export const updatePatientProfileDetails = async (patientDetails) => {
  return await axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
    `/v1/patients/update-patient-details?id=${patientDetails.id}`,
    patientDetails,
    {
      headers: {"Content-Type": "application/json"},
    }
  )
}

export const addPatientInternalNote = async (internalNote) => {
  return await axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
    "/v1/patient-notes/save",
    internalNote,
    {
      headers: {"Content-Type": "application/json"},
    }
  )
}
