import React, { useState, useEffect } from "react";
import { ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";
import isEmpty from "is-empty";

const componentName = "WeekFilter";

export const WeekFilter = ({ setFilterDates, setWeekFilterApplied, resetDefault, loading }) => {

  const [activeScheduleView, setScheduleView] = useState('');

  const weekFilterDates = ( dateRange, isActive) => {
    const today = new Date();
    setWeekFilterApplied(true);
    const weekDates = [];
    if (isActive) {
      weekDates.push('defaultView')
      setFilterDates(weekDates);
      setScheduleView('');
      setWeekFilterApplied(false);
    }
    else {
      switch (dateRange) {
        case 'lastWeek':
          const lastSunday = new Date(today);
          lastSunday.setTime(lastSunday.getTime() - lastSunday.getTimezoneOffset() * 60 * 1000);
          lastSunday.setDate(today.getDate() - today.getDay() - 7);
          const lastSaturday = new Date(lastSunday);
          lastSaturday.setDate(lastSunday.getDate() + 6);
          lastSaturday.setTime(lastSaturday.getTime() + lastSaturday.getTimezoneOffset() * 60 * 1000);
          for (let date = lastSunday; date <= lastSaturday; date.setDate(date.getDate() + 1)) {
            weekDates.push(date.toISOString().split('T')[0]);
          }
          setFilterDates(weekDates);
          setScheduleView('lastWeek-schedule');
          break;
        case 'thisWeek':
          const presentDay = today.getDay();
          const startDate = new Date(today);
          startDate.setTime(startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000);
          startDate.setDate(today.getDate()- presentDay);
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);
          endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
          while(startDate <= endDate){
            weekDates.push(new Date(startDate).toISOString().split('T')[0]);
            startDate.setDate(startDate.getDate() + 1);
          }
          setFilterDates(weekDates);
          setScheduleView('thisWeek-schedule');
          break;
        case 'nextWeek':
          const currentDay = today.getDay();
          const dayUntilNextSunday = 7 - currentDay;
          const nextSunday = new Date(today);
          nextSunday.setTime(nextSunday.getTime() - nextSunday.getTimezoneOffset() * 60 * 1000);
          nextSunday.setDate(today.getDate() + dayUntilNextSunday);
          for (let i = 0; i < 7; i++) {
            const date = new Date(nextSunday);
            date.setDate(nextSunday.getDate() + i);
            weekDates.push(date.toISOString().split('T')[0]);
          }
          setFilterDates(weekDates);
          setScheduleView('nextWeek-schedule');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (resetDefault) {
      weekFilterDates(null, true);
    }
  }, [resetDefault]);

  return (
    <>
      <ToggleButtonGroup type="radio" className="careProgramFilters" name="scheduleFilters" dis>
        <Button disabled={loading} id="lastWeek-schedule" variant="secondary" className={`border px-3 text-dark rounded-start ${activeScheduleView === 'lastWeek-schedule' ? 'active' : ''}`}
          onClick={(e) => { weekFilterDates('lastWeek', activeScheduleView === 'lastWeek-schedule'); }} value="Last Week">
          Last Week
        </Button>
        <Button disabled={loading} id="thisWeek-schedule" variant="secondary" className={`border px-3 text-dark rounded-0 ${activeScheduleView === 'thisWeek-schedule' ? 'active' : ''}`}
          onClick={() => { weekFilterDates('thisWeek', activeScheduleView === 'thisWeek-schedule'); }} value="This Week">
          This Week
        </Button>
        <Button disabled={loading} id="nextWeek-schedule" variant="secondary" className={`border px-3 text-dark rounded-start-0 rounded-end ${activeScheduleView === 'nextWeek-schedule' ? 'active' : ''}`}
          onClick={() => { weekFilterDates('nextWeek', activeScheduleView === 'nextWeek-schedule'); }} value="Next Week">
          Next Week
        </Button>
      </ToggleButtonGroup>
    </>
  );
};

export default WeekFilter;