import { noTokenApiClient } from "../utils/loginApiClient";
import { dataFetchHelper } from "./baseDataFetch";
import {PRINT_URL, SUMMARY_URL} from "../views/quickList/QuickList";
import {PREVENTIVE_CARE_COUNT_URL} from "../views/patientProfile/Overview";

const [
  getIsDeclineReferralTokenValid,
  useGetIsDeclineReferralTokenValid,
] = dataFetchHelper(([token]) => {
  return noTokenApiClient.get(
    `/v1/referrals/validate-decline-token?token=${token}`,
    {
      headers: {},
    }
  );
});

const declineReferral = (referralDecline) => {
  return noTokenApiClient.post(`/v1/referrals/decline`, referralDecline, {
    headers: {},
  });
};

export { declineReferral, getIsDeclineReferralTokenValid, useGetIsDeclineReferralTokenValid };
