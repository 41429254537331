import React, { useEffect, useState } from 'react'
import { Tab, Nav, Button, Col } from 'react-bootstrap'

import { useAuth } from '../../../../context/authContext';
import { useHospitalEventsContext } from "../context/HospitalEvents.context";

import { hospitalEventFilterParams } from '../constants';

const componentName = 'HospitalEventCard';

/**
 * Displays a header for the hospital events section, including filter selection and new patient action button.
 *
 * @param {Object} patient - The patient object.
 */
const HospitalEventsHeader = ({ patient }) => {
  const auth = useAuth();
  const hasWriteAccess = auth.hasAnyAuthority(["AUTH_PD_HOSPITAL_EVENTS_WRITE_ACCESS"]);

  const {
    getHospitalEvents,
    toggleNewHospitalEventModalActive
  } = useHospitalEventsContext();

  const [selectedTab, setSelectedTab] = useState([]);

  const onSelectTab = (tabKey) => {
    setSelectedTab(tabKey);
  }

  const requestFilteredHospitalEvents = (tabKey) => {
    const filterParams = [hospitalEventFilterParams[tabKey] || []].flat();
    getHospitalEvents(patient?.id, { filters: filterParams });
  }

  useEffect(() => {
    requestFilteredHospitalEvents(selectedTab);
  }, [selectedTab])

  return (
    <Tab.Container defaultActiveKey="all">
      <Nav variant="tabs" as="nav" className="patientdrawer-tabs align-items-center stickyscroll bg-light px-3" onSelect={onSelectTab}>
        <Nav.Link eventKey="all" className="nav-item rounded-start">All</Nav.Link>
        <Nav.Link eventKey="inpatient" className="nav-item rounded-0">Inpatient</Nav.Link>
        <Nav.Link eventKey="behavioral" className="nav-item rounded-0">Behavioral</Nav.Link>
        <Nav.Link eventKey="emergency" className="nav-item rounded-start-0 rounded-end">Emergency</Nav.Link>
        <Col className='text-end'>
          <Button
            id={`${componentName}-new-Hospital-Event`}
            variant="primary"
            disabled={!hasWriteAccess}
            onClick={() => {
              toggleNewHospitalEventModalActive(true)
            }}
          >
            New Hospital Event
          </Button>
        </Col>
      </Nav>
    </Tab.Container>
  );
}

export default HospitalEventsHeader;
