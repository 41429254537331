import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { SortParam } from "../../../../dtos/PatientSearchDTO";

const componentName = "SortMenu"

const SortMenu = ({
  items,
  filterParams,
  setSortParams,
  refreshPatientList
}) => {
  const onSortItemClick = (menuItemSortClicked) => {
    onPatientActionSortClicked(menuItemSortClicked.text);
  }

  const onPatientActionSortClicked = (menuItemSortClicked) => {
    let sortParams = [];

    switch (menuItemSortClicked) {
      case "Default Sort":
        setSortParams(sortParams = [new SortParam("statusUpdatedDateOnly", "DESC", 1), new SortParam("lastName", "ASC", 1)]);
        break;
      case "Name (A-Z)":
        setSortParams(sortParams = [new SortParam("fullName", "ASC", 1)]);
        break;
      case "Name (Z-A)":
        setSortParams(sortParams = [new SortParam("fullName", "DESC", 1)]);
        break;
      case "Risk Level (lowest first)":
        setSortParams(sortParams = [new SortParam("risk", "ASC", 1)]);
        break;
      case "Risk Level (highest first)":
        setSortParams(sortParams = [new SortParam("risk", "DESC", 1)]);
        break;
      default:
        setSortParams(sortParams = []);
    }

    refreshPatientList(1, filterParams, sortParams);
  }

  return (
    <Dropdown
      id={`${componentName}-dropDown`}
      key={`${componentName}-dropDown-key`}
      className="text-end"
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        id={`${componentName}-memberAction`}
        size="s"
        style={{ padding: '4px 16px 4px 10px' }}
      >
        <i className="fa fa-arrow-up-arrow-down"
          style={{paddingRight: '5px'}}
        />Sort
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`}
        key={`${componentName}-dropDownMenu-key`}>
        {items && items.map((item, itemIdx) => {
          return (
            <Col
              id={`${componentName}-colMenuItem-${itemIdx}`}
              key={`${componentName}-colMenuItem-${item.value}`}
            >
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => onSortItemClick(item)}
              >{item.text}</Dropdown.Item>
            </Col>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SortMenu;