import React, { useEffect, useRef, useState } from "react";

import AssessmetsCardClosed from './AssessmentsCardClosed';
import AssessmentsCardOpen from "./AssessmentsCardOpen";
import { getAssessmentById } from "../context/api";
import isEmpty from "is-empty";
import { CollapsibleCard } from "./CollapsibleCard";

const createAssessmentDisplay = (assessment) => {
  let qAndA = [];
  if (assessment && !isEmpty(assessment.formTemplate.questions)) {
    assessment.formTemplate.questions.forEach((qqq) => {
      const answer = findAnswer(qqq, assessment);
      const obj = { q: qqq.questionText, a: answer };
      qAndA.push(obj);
    });
    return qAndA;
  }
};

function findAnswer(k, assessment) {
  let answer = [];
  for (const [key, value] of Object.entries(assessment?.answers)) {
    if (key.includes(k.id)) {
      answer = value;
    }
  }
  return answer;
}

const AssessmentsCard = ({ assessment, idx, toggleIndividual, open }) => {
  const [questions, setQuestions] = useState([]);

  const ref = useRef(null);
  const redirectID = sessionStorage.getItem('itemID');

  useEffect(() => {
    if (ref.current ) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      setTimeout(() => {
        toggleIndividual()
      }, 1000);
      sessionStorage.removeItem('itemID');
    }
  }, [assessment.id])

  useEffect(() => {
    if (open) {
      getAssessmentById(assessment?.id)
        .then(res => {
          setQuestions(createAssessmentDisplay(res?.data))
        })
    }
  }, [open])

  const ClosedContent = (
    <AssessmetsCardClosed
      assessment={assessment}
      idx={idx}
      opened={open}
      setOpen={toggleIndividual}
    />
  );

  const OpenedContent = (
    <AssessmentsCardOpen
      questions={questions}
      idx={idx}
    />
  )

  return (
    <div ref = {redirectID && assessment.id == redirectID ? ref : null}>
      <CollapsibleCard
        openContent={OpenedContent}
        open={open}
        idx={idx}
        closedContent={ClosedContent}
      />
    </div>
  );
};

export default AssessmentsCard;
