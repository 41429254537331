export const dropdowns = [
    {
        name: 'General Parameters',
        content: [
            {
                title: 'Does Not Meet Criteria/Decline',
                dropDownListName: 'DOES_NOT_MEET_CRITERIA',
                moduleName: 'HUB_WORKCENTER',
            },
            {
                title: 'Program Status',
                dropDownListName: 'PROGRAM_STATUS',
                moduleName: 'MY_WORKSPACE',
                customColumns: [
                    {
                        name: 'Description',
                        key: 'value'
                    },
                    {
                        name: 'Step Status',
                        key: 'stepStatus',
                        isDropDown: true,
                        dropDownDefault: 0,
                        dropDownOptions: [
                            {
                                key: 'CLOSED',
                                value: 'Closed'
                            },
                            {
                                key: 'OPEN',
                                value: 'Open'
                            }
                        ]
                    },
                    {
                        name: 'Status',
                        key: 'status'
                    }
                ]
            },
            {
                title: 'ZIP codes',
                dropDownListName: 'ZIP_CODE',
                moduleName: 'CONFIGURATION',
                customColumns: [
                    {
                        name: 'Zip Code',
                        key: 'value',
                        validations: {
                            maxLength: {
                                cb: w => w.length > 5,
                                message: 'The maximum length for this field is 5'
                            },
                            numbers: {
                                cb: w => isNaN(w),
                                message: 'This field just allows numbers'
                            }
                        }
                    },
                    {
                        name: 'Description',
                        key: 'description',
                        validations: {
                            maxLength: {
                                cb: w => w.length > 15,
                                message: `The maximum length for this field is 15`
                            }
                        }
                    },
                    {
                        name: 'Status',
                        key: 'status'
                    }
                ]
            }
        ]
    },
    {
        name: 'Member Interaction Options',
        content: [
            {
                title: 'Interaction Types',
                dropDownListName: 'INTERACTION_TYPES',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Interaction Outcome',
                dropDownListName: 'INTERACTION_OUTCOME',
                moduleName: 'MY_WORKSPACE'
            },
        ]
    },
    {
        name: 'Episodic Intervention Options',
        content: [
            {
                title: 'Channel',
                dropDownListName: 'CHANNEL',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Source',
                dropDownListName: 'Source',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Intervention Type',
                dropDownListName: 'TYPES',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Outcome',
                dropDownListName: 'OUTCOME',
                moduleName: 'MY_WORKSPACE'
            },
        ]
    },
    {
        name: 'Task Resolution Options',
        content: [
            {
                title: 'Completed Resolution Types',
                dropDownListName: 'COMPLETED_REASONS',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Unable to Complete Resolution Types',
                dropDownListName: 'UNABLE_TO_COMPLETE_REASONS',
                moduleName: 'MY_WORKSPACE'
            },
            {
                title: 'Reschedule Resolution Types',
                dropDownListName: 'RESCHEDULE_REASONS',
                moduleName: 'MY_WORKSPACE'
            }
        ]
    }
]

export const getErrorMsg = (type) => {
    switch (type) {
        case 'fetch':
            return 'Something goes wrong fetching the data, try again.'
        case 'edit':
            return 'There was a problem editing the data, please try again'
        case 'required':
            return 'There is a required field unfilled, please fill it and try again'
        case 'minLength':
            return 'The minimum length of this field is 5'
        default:
            return 'There was a problem saving the data, please try again'
    }
}