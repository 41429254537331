import React, {Fragment, useEffect, useState} from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { trim, isEmpty, get, set, isArray } from "lodash";
import {
  DropDownList, DDLDataMode, DDLMode, GENERIC_DDL_TYPE,
} from "../../../../../../components/dropDownLists/genericDDL";
import {
  FilterParam,
} from "../../../../../../dtos/PatientSearchDTO";
import {
  stateAbbrReverseMap,
} from "../../../../../../actions/constantsActions";
import {
  getCareProviderPartialNameSearch,
} from "../../../../../../actions/patientProfileActions";
import {
  formatPhoneNumber, isObjEmpty, isValidPhoneNumber, formatDate, expiredSources, 
} from "../../../../../../utils/util";
import _ from "lodash";
import {Filter} from "../../../../../../components/filters/filterGroup";
import { format } from "date-fns";
import { EQHDatePicker } from "../../../../../../components/datePicker";
import {
  formatAddress,
  parseAddress,
  careProviderTypeMapping,
  PDExtractFreeInputFieldInfo,
  PREFER_TO_SELF_DESCRIBE_PRONOUN_TEXT,
  OTHER_PLEASE_SPECIFY_RACE_ETHNICITY_TEXT,
  DropDownDiv
} from "../../../utils"
import { useAuth } from "../../../../../../context/authContext";
import {
  postcodeValidator,
} from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import {usePatientDetailsContext} from "../context/PatientDetails.context";
import {
  useEQHToastContext
} from "../../../../../../components/toast/EQHToast.context";
import SimpleOtherOptionDDL from "../../../../../../components/dropDownLists/SimpleOtherOptionDDL";
import MultiSelectOtherOptionDDL from "../../../../../../components/dropDownLists/MultiSelectOtherOptionDDL";
import { setReloadWorklist } from "../../../../../../actions/manageVisitActions";
import { useDispatch } from "react-redux";

const componentName = "EditPatientDetailsModal";

const ALPHANUMERIC_PATTERN = new RegExp(/^([a-zA-Z0-9 ]+)$/);
const MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED = 1000;
const MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_SHORT_FIELD = 255;
const OTHER_LANGUAGE="Other and unspecified languages";
const PRIMARY_SPOKEN_LANGUAGE="Primary Spoken Language";
const SECONDARY_SPOKEN_LANGUAGE="Secondary Spoken Language";
const TERTIARY_SPOKEN_LANGUAGE="Tertiary Spoken Language";
const OTHER_PREFER_SELF_DESCRIBE="Prefer to self-describe";
const PRIMARY_READING_LANGUAGE="Primary Reading Language";
const SECONDARY_READING_LANGUAGE="Secondary Reading Language";
const TERTIARY_READING_LANGUAGE="Tertiary Reading Language";

const CARE_PROV_FD = (header, patientId) => {
  return {
    type: GENERIC_DDL_TYPE,
    getHeader: () => header,
    key: "Care_Provider",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      showSearchTextbox: true,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (srchTxt) => getCareProviderPartialNameSearch(
        [patientId, srchTxt]).then((res) => res.data.map((careProvider) => {
        return {
          text: careProvider.name, value: careProvider,
        };
      })),
    },
  };
};

const PREFERRED_OPTIONS = {
  SECONDARY: "secondary", TERTIARY: "tertiary"
}

const PREFERRED_OPTIONS_BY_ORDER = {
  0: PREFERRED_OPTIONS.SECONDARY, 1: PREFERRED_OPTIONS.TERTIARY,
}

class PreferredOption {
  constructor(key, value, preferred, order) {
    this.key = key;
    this.value = value;
    this.preferred = preferred;
    this.order = parseInt(order);
  }
}

class CareTeamMember {
  constructor(id, type, name, address, phone, indexId) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.address = address;
    this.phone = phone;
    this.indexId = parseInt(indexId);
  }
}

const emptyCareTeamMember = (indexId = 0) => {
  return new CareTeamMember(null, null, null, null, null, indexId)
}

/**
 * PatientDetailsEdit component allows updating personal details of a patient.
 * It includes logic to format addresses and emails, and to annotate primary fields.
 *
 * @param {Object} PatientDetailsContext - shared object that contains information about the current patient, as well as property states that are displayed to the user
 * @returns {JSX.Element} A modal that allows updating personal details of a patient.
 */
const PatientDetailsEdit = () => {
  const {
    patientProfileInformation,
    isEditPatientDetailsEnabled,
    toggleEditPatientDetailsEnabled,
    updatePatientProfileDetails,
    languages,
    states,
    payorStatus,
    specialistTypes,
    pronounsDropList,
    spokenLanguages,
    sexAssignedBirthDropList,
    raceEthnicityDropList,
    sexualOrientationList,
    genderIdentityList,
  } = usePatientDetailsContext();
  const dispatch = useDispatch();
  const {removeNotification, setNotification} = useEQHToastContext();
  const [patientOverviewClone, setPatientOverviewClone] = useState(
    () => _.cloneDeep(patientProfileInformation));
  const [useSecondaryAddress, setUseSecondaryAddress] = useState(false);
  const [careTeamMembers, setcareTeamMembers] = useState([]);
  const [preferredOptionPhone, setPreferredOptionPhone] = useState({});
  const [preferredOptionEmail, setPreferredOptionEmail] = useState({});
  const [preferredOptionLanguage, setPreferredOptionLanguage] = useState({});
  let isSecondaryPhoneEmpty = isEmpty(trim(get(preferredOptionPhone, 'secondary.value')));
  let isSecondaryEmailEmpty = isEmpty(trim(get(preferredOptionEmail, 'secondary.value')));
  let isSecondaryLangEmpty = isEmpty(trim(get(preferredOptionLanguage, 'secondary.value')));

  const setUpPatientProfileInfo = () => {
    setUseSecondaryAddress(
      !isObjEmpty(patientProfileInformation.secondaryAddress));
    setPreferredOptionPhone(getPreferredOptionsFromDetailsOrDefault(
      patientProfileInformation.detailsPhones));
    setPreferredOptionEmail(getPreferredOptionsFromDetailsOrDefault(
      patientProfileInformation.detailsEmails));
    setPreferredOptionLanguage(getPreferredOptionsFromDetailsOrDefault(
      patientProfileInformation.detailsOtherLanguages));
    setcareTeamMembers(
      getCareTeamMembersFromPatientInformationOrDefault(
        patientProfileInformation));
  }

  useEffect(() => {
    resetState()
  }, [isEditPatientDetailsEnabled])

  useEffect(() => {
    if (patientProfileInformation?.id) {
      setUpPatientProfileInfo();
    }
  }, [patientProfileInformation])

  useEffect(() => {
    isSecondaryPhoneEmpty = isEmpty(trim(get(preferredOptionPhone, 'secondary.value')));
    isSecondaryEmailEmpty = isEmpty(trim(get(preferredOptionEmail, 'secondary.value')));
    isSecondaryLangEmpty = isEmpty(trim(get(preferredOptionLanguage, 'secondary.value')));
  }, [preferredOptionPhone, preferredOptionEmail, preferredOptionLanguage])

  const getCareTeamMembersFromPatientInformationOrDefault = (patientInformation) => {
    const careTeamMembers = [];
    let indexId = 0;

    if (patientInformation.pcp) {
      careTeamMembers.push(
        new CareTeamMember(patientInformation.pcp.id,
          "Primary Care",
          patientInformation.pcp.name, patientInformation.pcp.address,
          patientInformation.pcp.phone, ++indexId));
    }
    if(patientInformation.caregiverFirstName) {
      const address = {
        streetAddress: patientInformation.careGiverStreetAddress1,
        city: patientInformation.careGiverCity,
        state: patientInformation.careGiverState,
        zipCode: patientInformation.careGiverZipcode
      }

      careTeamMembers.push(
        new CareTeamMember("",
          "Caregiver",
          `${patientInformation.caregiverFirstName} ${patientInformation.caregiverLastName}`, formatAddress(address),
          patientInformation.careGiverPhone, ++indexId));
    }
    if (patientInformation.careCoordinator) {
      careTeamMembers.push(
        new CareTeamMember(patientInformation.careCoordinator.id,
          "Care Coordinator",
          patientInformation.careCoordinator.name,
          patientInformation.careCoordinator.address,
          patientInformation.careCoordinator.phone, ++indexId));
    }
    if (patientInformation.nurse) {
      careTeamMembers.push(
        new CareTeamMember(patientInformation.nurse.id,
          "Nurse",
          patientInformation.nurse.name,
          patientInformation.nurse.address,
          patientInformation.nurse.phone, ++indexId));
    }
    if (patientInformation.healthPlanCareManager) {
      careTeamMembers.push(
        new CareTeamMember(patientInformation.healthPlanCareManager.id,
          "Health Plan Manager",
          patientInformation.healthPlanCareManager.name,
          patientInformation.healthPlanCareManager.address,
          patientInformation.healthPlanCareManager.phone, ++indexId));
    }

    if (!isEmpty(patientInformation.specialists)) {
      patientInformation.specialists.forEach(specialist => {
        careTeamMembers.push(
          new CareTeamMember(specialist.id,
            "Other Specialist",
            specialist.name,
            specialist.address,
            specialist.phone, ++indexId));
      })
    }

    if (careTeamMembers.length === 0) {
      careTeamMembers.push(emptyCareTeamMember(careTeamMembers.length));
    }

    return careTeamMembers;
  }

  const getPreferredOptionsFromDetailsOrDefault = (details) => {
    const preferredOptions = {};

    Object.keys(PREFERRED_OPTIONS_BY_ORDER).forEach(order => {
      const optionType = PREFERRED_OPTIONS_BY_ORDER[order];
      preferredOptions[optionType] = new PreferredOption(optionType, null,
        false, order)
    });

    if (isArray(details) && !isEmpty(details)) {
      details.forEach((detail, order) => {
        const optionType = PREFERRED_OPTIONS_BY_ORDER[order];
        preferredOptions[optionType] = new PreferredOption(optionType,
          detail.detail, detail.preferred, order)
      })
    }

    return preferredOptions;
  }

  const resetState = () => {
    setPatientOverviewClone({});
    setUseSecondaryAddress(false);
    setcareTeamMembers([emptyCareTeamMember(0)]);
    setPreferredOptionPhone({});
    setPreferredOptionEmail({});
    setPreferredOptionLanguage({});
  }

  const closeAction = async () => {
    resetState();
    toggleEditPatientDetailsEnabled(false);
  }

  if (!isObjEmpty(patientProfileInformation) && isObjEmpty(patientOverviewClone,
    {lastUpdated: true})) {
    setPatientOverviewClone(_.cloneDeep(patientProfileInformation));
    setUpPatientProfileInfo();
  }

  const formValidationCareTeam = (patientOverview) => {
    const errors = {};
    const specialistNameErrorMsg = "Specialist Name Is Required";
    const specialistTypeErrorMsg = "Specialist Type is Required";

    const validateSpecialist = (specialistKey, errorCategory) => {
      let errors = {};

      if (specialistKey !== "caregiver") {
        const specialist = patientOverview[specialistKey];
        if (specialist) {
          switch (specialistKey) {
            case "pcp":
            case "careCoordinator":
            case "nurse":
            case "healthPlanCareManager":
              if (isEmpty(trim(specialist.relationType))) {
                errors[`${specialistKey}.relationType`] = `${errorCategory}: ${specialistTypeErrorMsg}`;
              }
              if (isEmpty(trim(specialist.name))) {
                errors[`${specialistKey}.name`] = `${errorCategory}: ${specialistNameErrorMsg}`;
              }
              break;
            case "specialists":
              if (isArray(specialist)) {
                specialist.forEach((specialist, index) => {
                  if (isEmpty(trim(specialist.relationType))) {
                    errors[`${specialistKey}[${index}].relationType`] = `${errorCategory}: ${specialistTypeErrorMsg}`;
                  }
                  if (isEmpty(trim(specialist.name))) {
                    errors[`${specialistKey}[${index}].name`] = `${errorCategory}: ${specialistNameErrorMsg}`;
                  }
                });
              }
              break;
          }
        }
      } else {
        if (isEmpty(trim(patientOverview.caregiverFirstName+patientOverview.caregiverLastName))) {
          errors[`${specialistKey}.name`] = `${errorCategory}: ${specialistNameErrorMsg}`;
        }
      }

      return errors;
    };

    const allErrors = {
      ...errors,
      ...validateSpecialist("pcp", "Primary Care"),
      ...validateSpecialist("caregiver", "Caregiver"),
      ...validateSpecialist("careCoordinator", "Care Coordinator"),
      ...validateSpecialist("nurse", "Nurse"),
      ...validateSpecialist("healthPlanCareManager", "Health Plan Manager"),
      ...validateSpecialist("specialists", "Other specialist")
    }

    return allErrors;
  }

  const formValidateFreeInputFields = (patientOverview) => {
    const errors = {};
    const freeInputIsRequiredErrorMsg = "Open-ended response is required.";
    const freeInputFormatErrorMsg = "Invalid format of open-ended response. Please use only alphanumeric characters up to 255 max.";

    const validateFreeInputText = (field, freeInputPrefix, errorFieldKey, fieldDisplayName) => {
      const errors = {};
      const { isFreeInput, freeInputTextValue } = PDExtractFreeInputFieldInfo(field, freeInputPrefix);
      const errorKey = `${fieldDisplayName}.${errorFieldKey}`;

      if (isFreeInput) {
        if (isEmpty(freeInputTextValue)) {
          errors[errorKey] = `${fieldDisplayName}: ${freeInputIsRequiredErrorMsg}`;
        } else if (!ALPHANUMERIC_PATTERN.test(freeInputTextValue) ||
                  freeInputTextValue.length > MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_SHORT_FIELD) {
          errors[errorKey] = `${fieldDisplayName}: ${freeInputFormatErrorMsg}`;
        }
      }

      return errors;
    }

    const validateFreeInputField = (fieldKey, freeInputPrefix, errorFieldKey, fieldDisplayName) => {
      return validateFreeInputText(patientOverview[fieldKey], freeInputPrefix, errorFieldKey, fieldDisplayName)
    }

    const validateMultiFreeInputField = (fieldKey, freeInputPrefix, errorFieldKey, fieldDisplayName, isJsonString) => {
      let multiField = patientOverview[fieldKey];
      let errors = {};

      if(isJsonString) {
        try {
          multiField = JSON.parse(patientOverview[fieldKey]);
        } catch (e) {
          multiField = null;
        }
      }

      if (Array.isArray(multiField)) {
          multiField.forEach((item, index) => {
              const errorKey = `${errorFieldKey}-${index}`;
              const itemErrors = validateFreeInputText(item, freeInputPrefix, errorKey, fieldDisplayName);
              errors = {...errors, ...itemErrors};
          });
      }

      return errors;
  };

    const allErrors = {
      ...errors,
      ...validateFreeInputField("pronoun", PREFER_TO_SELF_DESCRIBE_PRONOUN_TEXT, "self-describe", "Pronouns"),
      ...validateMultiFreeInputField("raceEthnicity", OTHER_PLEASE_SPECIFY_RACE_ETHNICITY_TEXT, "other-specified", "Race/Ethnicity", true),
      ...validateFreeInputField("genderIdentity", OTHER_PREFER_SELF_DESCRIBE, "self-describe", "Gender Identity"),
      ...validateFreeInputField("sexualOrientation", OTHER_PREFER_SELF_DESCRIBE, "self-describe", "Sexual Orientation"),
      ...validateFreeInputField("primarySpokenLanguage", OTHER_LANGUAGE, "other-language", "Primary Spoken Language"),
      ...validateFreeInputField("secondarySpokenLanguage", OTHER_LANGUAGE, "other-language", "Secondary Spoken Language"),
      ...validateFreeInputField("tertiarySpokenLanguage", OTHER_LANGUAGE, "other-language", "Tertiary Spoken Language"),
      ...validateFreeInputField("primaryReadingLanguage", OTHER_LANGUAGE, "other-language", "Primary Reading Language"),
      ...validateFreeInputField("secondaryReadingLanguage", OTHER_LANGUAGE, "other-language", "Secondary Reading Language"),
      ...validateFreeInputField("tertiaryReadingLanguage", OTHER_LANGUAGE, "other-language", "Tertiary Reading Language"),
    }

    return allErrors;
  }

  const formValidation = (patientOverview) => {
    let errors = {};

    if (useSecondaryAddress) {
      if (patientOverview.secondaryAddress) {
        if (isEmpty(trim(patientOverview.secondaryAddress.streetAddress))) {
          errors["secondaryAddress.streetAddress"] = "Secondary Address Street Address Is Required";
        }
        if (isEmpty(trim(patientOverview.secondaryAddress.city))) {
          errors["secondaryAddress.city"] = "Secondary Address City Is Required";
        }
        if (isEmpty(trim(patientOverview.secondaryAddress.state))) {
          errors["secondaryAddress.state"] = "Secondary Address State Is Required";
        }
        if (isEmpty(trim(patientOverview.secondaryAddress.zipCode))) {
          errors["secondaryAddress.zipCode"] = "Secondary Address Zip Code Is Required";
        } else if (!postcodeValidator(patientOverview.secondaryAddress.zipCode,
          'US')) {
          errors["secondaryAddress.zipCode.value"] = "Invalid format of zip code. Please use NNNNN or NNNNN-NNNN";
        }
      } else {
        errors.secondaryAddress = "Please Fill Out Secondary Address Fields Or Delete. ";
      }
    }
    if (!isEmpty(trim(patientOverview.careGiverPhone)) && !isValidPhoneNumber(
      patientOverview.careGiverPhone)) {
      errors["careGiverPhone"] = "Invalid format of phone number. Please use NNN-NNN-NNNN. ";
    }
    if (!isEmpty(trim(patientOverview.insuranceInfo))
      && (!ALPHANUMERIC_PATTERN.test(patientOverview.insuranceInfo)
      || patientOverview.insuranceInfo.length > MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED)) {
      errors["insuranceInfo"] = "Invalid format of Insurance Info. Please use only alphanumeric characters up to 1000 max.";
    }
    if (!isEmpty(trim(patientOverview.mrn))
      && (!ALPHANUMERIC_PATTERN.test(patientOverview.mrn)
      || patientOverview.mrn.length > MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED)) {
      errors["mrn"] = "Invalid format of MRN. Please use only alphanumeric characters up to 1000 max.";
    }
    if (!isEmpty(trim(patientOverview.detailsPhones))) {
      let secondaryPhone = patientOverview.detailsPhones[0];
      if (!isEmpty(secondaryPhone) && !isEmpty(secondaryPhone.detail)
        && !isValidPhoneNumber(secondaryPhone.detail)) {
        errors["preferredSecondaryPhone"] = "Invalid format of 2nd phone number. Please use NNN-NNN-NNNN.";
      }

      let tertiaryPhone = patientOverview.detailsPhones[1];
      if (!isEmpty(tertiaryPhone) && !isEmpty(tertiaryPhone.detail)
        && !isValidPhoneNumber(tertiaryPhone.detail)) {
        errors["tertiaryPreferredPhone"] = "Invalid format of 3rd phone number. Please use NNN-NNN-NNNN";
      }
    }
    if (!isEmpty(trim(patientOverview.detailsEmails))) {
      let secondaryEmail = patientOverview.detailsEmails[0];
      if (!isEmpty(secondaryEmail) && !isEmpty(secondaryEmail.detail)
        && !EmailValidator.validate(secondaryEmail.detail)) {
        errors["preferredSecondaryEmail"] = "Invalid format of 2nd email address.";
      }

      let tertiaryEmail = patientOverview.detailsEmails[1];
      if (!isEmpty(tertiaryEmail) && !isEmpty(tertiaryEmail.detail)
        && !EmailValidator.validate(tertiaryEmail.detail)) {
        errors["tertiaryPreferredEmail"] = "Invalid format of 3rd email address.";
      }
    }

    const careTeamErrors = formValidationCareTeam(patientOverview);
    const freeInputFieldErrors = formValidateFreeInputFields(patientOverview);
    const allErrors = { ...errors, ...careTeamErrors, ...freeInputFieldErrors };

    return isObjEmpty(allErrors) ? null : allErrors;
  };

  const getPreferredDetailsContract = (preferredDetails) => {
    return Object.keys(preferredDetails).filter(preferredKey => {
      return !isEmpty(trim(preferredDetails[preferredKey].value))
    })
    .sort((a, b) => a.order - b.order)
    .map(preferredKey => {
      return {
        detail: preferredDetails[preferredKey].value,
        preferred: preferredDetails[preferredKey].preferred
      }
    })
  }

  const getSpecialistsPropertiesContract = () => {
    const contractProperties = {
      careCoordinator: null,
      healthPlanCareManager: null,
      nurse: null,
      pcp: null,
      specialists: [],
      caregiverFirstName: null,
      caregiverLastName: null,
      careGiverStreetAddress1: null,
      careGiverCity: null,
      careGiverState: null,
      careGiverZipcode: null,
      careGiverPhone: null
    }

    const transformIntoSpecialist = (careTeamMember, relationType) => ({
      id: careTeamMember.id,
      name: careTeamMember.name,
      relationType: relationType,
      location: careTeamMember.location,
      address: careTeamMember.address,
      phone: careTeamMember.phone
    })

    careTeamMembers.forEach(careTeamMember => {
      const careProvider = transformIntoSpecialist(careTeamMember, careProviderTypeMapping[careTeamMember.type]);

      switch (careTeamMember.type) {
        case 'Primary Care':
          if (contractProperties.pcp) {
            contractProperties.specialists.push(careProvider)
          } else {
            contractProperties.pcp = careProvider;
          }
          break
        case 'Caregiver':
          if (contractProperties.caregiverFirstName) {
            contractProperties.specialists.push(careProvider)
          } else {
            const fullName = careProvider.name.split(" ");
            const address = parseAddress(careProvider.address);
            contractProperties.caregiverFirstName = fullName.length > 0 ? fullName[0] : "";
            contractProperties.caregiverLastName = fullName.length > 1 ? fullName[1] : "";
            contractProperties.careGiverStreetAddress1 = address.streetAddress;
            contractProperties.careGiverCity = address.city;
            contractProperties.careGiverState = address.state;
            contractProperties.careGiverZipcode = address.zipCode;
            contractProperties.careGiverPhone = careProvider.phone;
          }
          break
        case 'Care Coordinator':
          if (contractProperties.careCoordinator) {
            contractProperties.specialists.push(careProvider)
          } else {
            contractProperties.careCoordinator = careProvider;
          }
          break
        case 'Nurse':
          if (contractProperties.nurse) {
            contractProperties.specialists.push(careProvider)
          } else {
            contractProperties.nurse = careProvider;
          }
          break
        case 'Health Plan Manager':
          if (contractProperties.healthPlanCareManager) {
            contractProperties.specialists.push(careProvider)
          } else {
            contractProperties.healthPlanCareManager = careProvider;
          }
          break
        case 'Other Specialist':
          contractProperties.specialists.push(careProvider);
      }
    });

    return contractProperties;
  }

  const submitAction = () => {
    const patientProfile = {
      ...patientOverviewClone,
      detailsPhones: getPreferredDetailsContract(preferredOptionPhone),
      detailsEmails: getPreferredDetailsContract(preferredOptionEmail),
      detailsOtherLanguages: getPreferredDetailsContract(
        preferredOptionLanguage),
      ...getSpecialistsPropertiesContract()
    };

    // the first item in this list corresponds to the alternative phone
    patientProfile.altPhone = get(patientProfile.detailsPhones, '0.detail');
    const errors = formValidation(patientProfile);

    dispatch(setReloadWorklist(true));

    if (errors) {
      setNotification("Error", Object.values(errors));
    } else {
      updatePatientProfileDetails(patientProfile);
    }
  };

  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const auth = useAuth();

  const cancelExpiredModal = () => {
    setShowExpiredModal(false)
  }

  const closeExpiredModal = () => {
    setShowExpiredModal(false)
    handleChange("expired", true)
  }

  const handleChange = (key, value) => {
    let patientOverviewNewObj = {...patientOverviewClone};
    switch (key) {
      // Personal information
      case "firstName":
      case "middleName":
      case "lastName":
      case "memberId":
      case "address":
      case "primaryPhone":
      case "dateOfBirth":
      case "city":
      case "gender":
      case "pronoun":
      case "sexAssignedBirth":
      case "raceEthnicity":
      case "state":
      case "zipCode":
      case "email":
      case "detailsPhones":
      case "detailsOtherLanguages":
      case "detailsEmails":
      case "primaryLanguage":
      case "preferredStreetAddress":
      case "preferredCity":
      case "preferredState":
      case "preferredZipCode":
      case "secondaryAddress":
      case "secondaryPhone":
      case "tertiarySpokenLanguage":
      case "tertiaryReadingLanguage":
      case "sexualOrientation":
      case "genderIdentity":
      case "expired":
      // Insurance information
      case "payer":
      case "mrn":
      case "insuranceInfo":
      // Care Team information
      case "pcp":
      case "caregiverFirstName":
      case "caregiverLastName":
      case "caregiverContact":
      case "careGiverStreetAddress1":
      case "careGiverCity":
      case "careGiverState":
      case "careGiverZipcode":
      case "careGiverPhone":
      case "careCoordinator":
      case "nurse":
      case "healthPlanCareManager":
      case "specialists":
      case "careTeamMembers":
      case "expired":
      case "expiredDate":
      case "expiredSource":
        if (key === "careGiverPhone") {
          value = formatPhoneNumber(patientOverviewNewObj[key], value);
        }
        patientOverviewNewObj[key] = value;
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      // Personal information
      case "secondaryAddress.streetAddress":
      case "secondaryAddress.city":
      case "secondaryAddress.lastUpdated":
      case "secondaryAddress.state":
      case "secondaryAddress.zipCode":
      case "secondaryAddress.otherSource":
      case "secondaryAddress.source":
        if (key === "secondaryAddress.state") {
          value = value.replaceAll(" ", "_");
        }
        if (!patientOverviewNewObj.secondaryAddress) {
          //Don't know if we really need this object but existing data has it so...
          patientOverviewNewObj.secondaryAddress = {
            streetAddress: null,
            city: null,
            state: null,
            zipCode: null,
            source: null,
            otherSource: null,
            lastUpdated: null,
          };
        }
        patientOverviewNewObj.secondaryAddress[key.split(".")[1]] = value;
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      // Personal information
      case "primarySpokenLanguage":
        patientOverviewNewObj[key] = value;
        if(!value) {
          patientOverviewNewObj.secondarySpokenLanguage=null;
          patientOverviewNewObj.tertiarySpokenLanguage=null;
        }
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      case "secondarySpokenLanguage":
        patientOverviewNewObj[key] = value;
        if(!value) {
          patientOverviewNewObj.tertiarySpokenLanguage=null;
        }
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      case "primaryReadingLanguage":
        patientOverviewNewObj[key] = value;
        if(!value) {
          patientOverviewNewObj.secondaryReadingLanguage=null;
          patientOverviewNewObj.tertiaryReadingLanguage=null;
        }
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      case "secondaryReadingLanguage":
        patientOverviewNewObj[key] = value;
        if(!value) {
          patientOverviewNewObj.tertiaryReadingLanguage=null;
        }
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      default:
        break;
    }
  };

  const uncheckPreferredOptions = (preferredOptions) => {
    let uncheckedOptions = {...preferredOptions};
    Object.keys(uncheckedOptions).forEach(preferredKey => {
      uncheckedOptions = set(uncheckedOptions, preferredKey + '.preferred',
        false);
    });
    return uncheckedOptions;
  }

  const createCareTeamMemberFragment = (careTeamMember = {}, index,
    handleValueChange) => {
    const isPrimaryPcp = index === 0 && careTeamMember.type === "Primary Care";

    return <Fragment><Row xs={1} sm={1} md={3}>
      <Col md={4}>
        <Filter
          filterDefinition={{
            type: GENERIC_DDL_TYPE,
            getHeader: () => "Specialist Type",
            key: "Specialist_Type",
            componentProps: {
              mode: DDLMode.SelectOne,
              dataMode: DDLDataMode.OneAPICallFilterInUI,
              showSearchTextbox: false,
              highlightWhenHasValue: false,
              showClearButton: !(isPrimaryPcp),
              disabled: isPrimaryPcp,
              getData: () => Promise.resolve(specialistTypes || []),
            },
          }}
          selected={new FilterParam("Specialist_Type", "eq",
            careTeamMember.type ? [careTeamMember.type] : [], (e) => e,
            (e) => e)}
          handleChange={(e) => handleValueChange({type: e.paramValue[0]})}
        />
      </Col>
      <Col md={8}>
        <Filter
          filterDefinition={CARE_PROV_FD("Specialist Name",
            patientOverviewClone.id)}
          selected={new FilterParam("Specialist_Name", "eq",
            careTeamMember.name ? [careTeamMember.name] : [],
            (e) => e,
            (e) => e,
          )}
          handleChange={(e) => {
            const memberSelected = e.paramValue[0];
            let member = {id: '', name: '', address: '', phone: ''};

            if (memberSelected) {
              member = {
                id: memberSelected.id,
                name: memberSelected.name,
                address: memberSelected.address,
                phone: memberSelected.phone 
              };
            }

            handleValueChange(member);
          }}
        />
      </Col>
    </Row>

      <Row xs={1} sm={1} md={3} className="d-flex flex-row-reverse">
        <Col md={8}>
          <Col className="mb-3">
            <Form.Label>
              Specialist Address
            </Form.Label>
            <Form.Control
              type="text"
              disabled
              value={careTeamMember.address}
              onChange={(e) => handleValueChange({address: e.target.value})}
            />
          </Col>
        </Col>
      </Row>

      <Row xs={1} sm={1} md={3} className="d-flex flex-row-reverse">
        <Col md={8}>
          <Col className="mb-3">
            <Form.Label>
              Specialist Phone
            </Form.Label>
            <Form.Control
              type="text"
              disabled
              value={careTeamMember.phone}
              onChange={(e) => handleValueChange({phone: e.target.value})}
            />
          </Col>
        </Col>
      </Row>
    </Fragment>
  }

  const languagesPromise = Promise.resolve(languages?.map((language) => {
    return {
      text: language, value: language,
    };
  }))

  const spokenLanguagesPromise = Promise.resolve(spokenLanguages?.map((language) => {
    return {
      text: language.text, value: language.text,
    };
  }));

  const statesPromise = Promise.resolve(states || []);

  return (<>
    <Modal
      onHide={() => closeAction()}
      show={isEditPatientDetailsEnabled}
      size="lg"
      screenbool={true}
      centered
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Edit Patient Details</Modal.Title>
        <button id={`${componentName}-ModalClose`} type="button"
                className="btn-close" aria-label="Close"
                onClick={() => closeAction()}
        />
      </Modal.Header>
      <Modal.Body>
        {!isObjEmpty(patientOverviewClone) && (<Container>

          <h5 className="my-4">Personal Information</h5>

          <Row xs={1} sm={1} md={3} className="mb-3">
            <Col>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                disabled
              />
            </Col>
            <Col>
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.middleName}
                onChange={(e) => handleChange("middleName", e.target.value)}
                disabled
              />
            </Col>
            <Col>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                disabled
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col>
              <Form.Label>Member ID Number</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.memberId}
                onChange={(e) => handleChange("memberId", e.target.value)}
                disabled
              />
            </Col>
            <Col>
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="text"
                value={formatDate(patientOverviewClone.dateOfBirth)}
                onChange={(e) => handleChange("dateOfBirth", e.target.value)}
                disabled
              />
            </Col>
            <Col md={4}>
              <DropDownDiv>
                <DropDownList
                  mode={DDLMode.SelectOne}
                  dataMode={DDLDataMode.OneAPICallFilterInUI}
                  header="Gender"
                  disabled
                  handleChange={() => {
                  }}
                  getData={() => Promise.resolve([{
                    value: patientOverviewClone.gender,
                    text: patientOverviewClone.gender
                  }])}
                  selected={new FilterParam("Gender", "eq",
                    patientOverviewClone.gender ? [patientOverviewClone.gender]
                      : [], (e) => e, (e) => e)}
                  highlightWhenHasValue={false}
                  showClearButton={false}
                />
              </DropDownDiv>
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col className="mb-3">
              <SimpleOtherOptionDDL
                items={pronounsDropList.map(dropListItem => ({ text: dropListItem, value: dropListItem }))}
                handleChange={(value) => handleChange("pronoun", value)}
                header={"Pronouns"}
                selectedItem={patientOverviewClone.pronoun}
                otherItemText={PREFER_TO_SELF_DESCRIBE_PRONOUN_TEXT}
              />
            </Col>
            <Col className="mb-3">
              <SimpleOtherOptionDDL
                items={sexAssignedBirthDropList.map(dropListItem => ({ text: dropListItem, value: dropListItem }))}
                handleChange={(value) => handleChange("sexAssignedBirth", value)}
                header={"Sex Assigned At Birth"}
                selectedItem={patientOverviewClone.sexAssignedBirth}
                otherItemText={""}
              />
            </Col>
            <Col className="mb-3">
             <MultiSelectOtherOptionDDL
                items={raceEthnicityDropList.map(dropListItem => ({ text: dropListItem, value: dropListItem }))}
                handleChange={(value) => handleChange("raceEthnicity", value)}
                header={"Race/Ethnicity"}
                selectedItems={patientOverviewClone.raceEthnicity}
                otherItemText={OTHER_PLEASE_SPECIFY_RACE_ETHNICITY_TEXT}
              />
            </Col>
            <Col className="mb-3">
              <SimpleOtherOptionDDL
                  items={genderIdentityList.map(genderIdentity => ({ text: genderIdentity.text, value: genderIdentity.text }))}
                  handleChange={(value) => handleChange("genderIdentity", value)}
                  header={"Gender Identity"}
                  selectedItem={patientOverviewClone.genderIdentity}
                  otherItemText={OTHER_PREFER_SELF_DESCRIBE}
              />
            </Col>
            <Col className="mb-3">
              <SimpleOtherOptionDDL
                  items={sexualOrientationList.map(sexualOrientation => ({ text: sexualOrientation.text, value: sexualOrientation.text }))}
                  handleChange={(value) => handleChange("sexualOrientation", value)}
                  header={"Sexual Orientation"}
                  selectedItem={patientOverviewClone.sexualOrientation}
                  otherItemText={OTHER_PREFER_SELF_DESCRIBE}
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={1}>
            <Col className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.streetAddress}
                onChange={(e) => handleChange("address", e.target.value)}
                disabled
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.city}
                onChange={(e) => handleChange("city", e.target.value)}
                disabled
              />
            </Col>
            <Col>
              <Filter
                filterDefinition={{
                  type: GENERIC_DDL_TYPE,
                  getHeader: () => "State",
                  componentProps: {
                    mode: DDLMode.SelectOne,
                    dataMode: DDLDataMode.OneAPICallFilterInUI,
                    showSearchTextbox: false,
                    highlightWhenHasValue: false,
                    showClearButton: true,
                    getData: (text) => statesPromise,
                  },
                }}
                selected={new FilterParam("", "",
                  patientOverviewClone.state ? [{
                    value: patientOverviewClone.state,
                    text: stateAbbrReverseMap[patientOverviewClone.state],
                  },] : [], (e) => e.value, (e) => e.text)}
                handleChange={(e) => handleChange("state", e.paramValue[0])}
                showClearButton={false}
                disabled
              />
            </Col>
            <Col>
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.zipCode}
                onChange={(e) => handleChange("zipCode", e.target.value)}
                disabled
              />
            </Col>
          </Row>

          {useSecondaryAddress ? (<Fragment>
            <Row xs={1} sm={1} md={1}>
              <Col className="mb-3">
                <Form.Label>
                  Address<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={patientOverviewClone.secondaryAddress?.streetAddress}
                  onChange={(e) => handleChange(
                    "secondaryAddress.streetAddress", e.target.value)}
                />
              </Col>
            </Row>

            <Row xs={1} sm={1} md={3}>
              <Col className="mb-3">
                <Form.Label>
                  City<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={patientOverviewClone.secondaryAddress?.city}
                  onChange={(e) => handleChange("secondaryAddress.city",
                    e.target.value)}
                />
              </Col>
              <Col className="mb-3">
                <Filter
                  filterDefinition={{
                    type: GENERIC_DDL_TYPE,
                    getHeader: () => "State",
                    componentProps: {
                      mode: DDLMode.SelectOne,
                      dataMode: DDLDataMode.OneAPICallFilterInUI,
                      showSearchTextbox: false,
                      highlightWhenHasValue: false,
                      showClearButton: true,
                      getData: (text) => statesPromise,
                    },
                  }}
                  selected={new FilterParam("", "",
                    patientOverviewClone?.secondaryAddress?.state ? [{
                      value: patientOverviewClone?.secondaryAddress?.state,
                      text: stateAbbrReverseMap[patientOverviewClone?.secondaryAddress
                      ?.state?.replaceAll("_", " ")],
                    },] : [], (e) => e.value, (e) => e.text)}
                  handleChange={(e) => handleChange("secondaryAddress.state",
                    e.paramValue[0])}
                  showRequired={true}
                />
              </Col>
              <Col className="mb-3">
                <Form.Label>
                  Zip<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={patientOverviewClone.secondaryAddress?.zipCode}
                  onChange={(e) => handleChange("secondaryAddress.zipCode",
                    e.target.value)}
                />
              </Col>
            </Row>
          </Fragment>) : (<Fragment>
            <Row xs={1} sm={1} md={3} className="mb-3">
              <a
                className="cursor-p fw-bold"
                onClick={() => {
                  setUseSecondaryAddress(true);
                }}
              >
                <i className="fas fa-plus me-3"/> Add Address
              </a>
            </Row>
          </Fragment>)}

          <Row xs={1} sm={1} md={3}>
            <Col className="mb-3">
              <Form.Label>Primary Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.primaryPhone}
                onChange={(e) => handleChange("primaryPhone", e.target.value)}
                disabled
              />
            </Col>
            <Col className="mb-3">
              <Form.Label className="d-flex justify-content-between">
                Secondary Phone
                <Form.Check type="checkbox" style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isSecondaryPhoneEmpty}
                    value={get(preferredOptionPhone, 'secondary.preferred')}
                    checked={get(preferredOptionPhone, 'secondary.preferred')}
                    onChange={(e) => {
                      let preferredPhone = uncheckPreferredOptions(
                        preferredOptionPhone);
                      if (e.target.value) {
                        preferredPhone = set(preferredPhone,
                          'secondary.preferred', true);
                      }
                      setPreferredOptionPhone(preferredPhone);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>
              </Form.Label>
              <Form.Control
                type="text"
                value={get(preferredOptionPhone, 'secondary.value')}
                onChange={(e) => {
                  const value = e.target.value;
                  let preferredPhone = {...preferredOptionPhone};
                  if (isEmpty(trim(value))) {
                    preferredPhone = set(preferredPhone, 'secondary.preferred',
                      false);
                  }
                  setPreferredOptionPhone(
                    set(preferredPhone, 'secondary.value', value));
                }}
              />
            </Col>
            <Col className="mb-3">

              <Form.Label className="d-flex justify-content-between">
                Tertiary Phone
                <Form.Check type="checkbox" style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isEmpty(
                      trim(get(preferredOptionPhone, 'tertiary.value')))}
                    value={get(preferredOptionPhone, 'tertiary.preferred')}
                    checked={get(preferredOptionPhone, 'tertiary.preferred')}
                    onChange={(e) => {
                      let preferredPhone = uncheckPreferredOptions(
                        preferredOptionPhone);
                      if (e.target.value) {
                        preferredPhone = set(preferredPhone,
                          'tertiary.preferred', true);
                      }
                      setPreferredOptionPhone(preferredPhone);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>
              </Form.Label>
              <Form.Control
                type="text"
                disabled = {isSecondaryPhoneEmpty}
                value={get(preferredOptionPhone, 'tertiary.value')}
                onChange={(e) => {
                  const value = e.target.value;
                  let preferredPhone = {...preferredOptionPhone};
                  if (isEmpty(trim(value))) {
                    preferredPhone = set(preferredPhone, 'tertiary.preferred',
                      false);
                  }
                  setPreferredOptionPhone(
                    set(preferredPhone, 'tertiary.value', value));
                }}
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col md={4} className="mb-3">
              <Form.Label>Primary Email</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.email}
                onChange={(e) => handleChange("email", e.target.value)}
                disabled
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label className="d-flex justify-content-between">
                Secondary Email
                <Form.Check type="checkbox" style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isSecondaryEmailEmpty}
                    value={get(preferredOptionEmail, 'secondary.preferred')}
                    checked={get(preferredOptionEmail, 'secondary.preferred')}
                    onChange={(e) => {
                      let preferredEmail = uncheckPreferredOptions(
                        preferredOptionEmail);
                      if (e.target.value) {
                        preferredEmail = set(preferredEmail,
                          'secondary.preferred', true);
                      }
                      setPreferredOptionEmail(preferredEmail);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>
              </Form.Label>
              <Form.Control
                type="text"
                value={get(preferredOptionEmail, 'secondary.value')}
                onChange={(e) => {
                  const value = e.target.value;
                  let preferredEmail = {...preferredOptionEmail};
                  if (isEmpty(trim(value))) {
                    preferredEmail = set(preferredEmail, 'secondary.preferred',
                      false);
                  }
                  setPreferredOptionEmail(
                    set(preferredEmail, 'secondary.value', value));
                }}
              />
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label className="d-flex justify-content-between">
                Tertiary Email
                <Form.Check type="checkbox" style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isEmpty(
                      trim(get(preferredOptionEmail, 'tertiary.value')))}
                    value={get(preferredOptionEmail, 'tertiary.preferred')}
                    checked={get(preferredOptionEmail, 'tertiary.preferred')}
                    onChange={(e) => {
                      let preferredEmail = uncheckPreferredOptions(
                        preferredOptionEmail);
                      if (e.target.value) {
                        preferredEmail = set(preferredEmail,
                          'tertiary.preferred', true);
                      }
                      setPreferredOptionEmail(preferredEmail);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>
              </Form.Label>
              <Form.Control
                type="text"
                disabled={isSecondaryEmailEmpty}
                value={get(preferredOptionEmail, 'tertiary.value')}
                onChange={(e) => {
                  const value = e.target.value;
                  let preferredEmail = {...preferredOptionEmail};
                  if (isEmpty(trim(value))) {
                    preferredEmail = set(preferredEmail, 'tertiary.preferred',
                      false);
                  }
                  setPreferredOptionEmail(
                    set(preferredEmail, 'tertiary.value', value));
                }}
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col>
              <DropDownList
                mode={DDLMode.SelectOne}
                dataMode={DDLDataMode.OneAPICallFilterInUI}
                header="Primary Language"
                disabled
                handleChange={(e) => handleChange("primaryLanguage",
                  e.paramValue[0])}
                selected={new FilterParam("Primary Language", "eq",
                  patientOverviewClone.primaryLanguage
                    ? [patientOverviewClone.primaryLanguage] : [], (e) => e,
                  (e) => e)}
                getData={() => languagesPromise}
                highlightWhenHasValue={false}
              />
            </Col>
            <Col>
              <DropDownList
                mode={DDLMode.SelectOne}
                dataMode={DDLDataMode.OneAPICallFilterInUI}
                header="Secondary Language"
                filterTitleClassName="d-flex justify-content-between"
                headerActions={<Form.Check type="checkbox"
                                           style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isSecondaryLangEmpty}
                    value={get(preferredOptionLanguage, 'secondary.preferred')}
                    checked={get(preferredOptionLanguage,
                      'secondary.preferred')}
                    onChange={(e) => {
                      let preferredLanguage = uncheckPreferredOptions(
                        preferredOptionLanguage);
                      if (e.target.value) {
                        preferredLanguage = set(preferredLanguage,
                          'secondary.preferred', true);
                      }
                      setPreferredOptionLanguage(preferredLanguage);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>}
                handleChange={(e) => {
                  const value = e.paramValue[0];
                  let preferredLanguage = {...preferredOptionLanguage};
                  if (isEmpty(trim(value))) {
                    preferredLanguage = set(preferredLanguage,
                      'secondary.preferred', false);
                  }
                  setPreferredOptionLanguage(
                    set(preferredLanguage, 'secondary.value', value));
                }}
                selected={new FilterParam("Secondary Language", "eq",
                  get(preferredOptionLanguage, 'secondary.value') ? [get(
                    preferredOptionLanguage, 'secondary.value')] : [], (e) => e,
                  (e) => e)}
                getData={() => languagesPromise}
                highlightWhenHasValue={false}
              />
            </Col>
            <Col>
              <DropDownList
                mode={DDLMode.SelectOne}
                dataMode={DDLDataMode.OneAPICallFilterInUI}
                header="Tertiary Language"
                filterTitleClassName="d-flex justify-content-between"
                headerActions={<Form.Check type="checkbox"
                                           style={{fontSize: 10}}>
                  <Form.Check.Input
                    type="checkbox"
                    disabled={isEmpty(
                      trim(get(preferredOptionLanguage, 'tertiary.value')))}
                    value={get(preferredOptionLanguage, 'tertiary.preferred')}
                    checked={get(preferredOptionLanguage, 'tertiary.preferred')}
                    onChange={(e) => {
                      let preferredLanguage = uncheckPreferredOptions(
                        preferredOptionLanguage);
                      if (e.target.value) {
                        preferredLanguage = set(preferredLanguage,
                          'tertiary.preferred', true);
                      }
                      setPreferredOptionLanguage(preferredLanguage);
                    }}
                  />
                  <Form.Check.Label
                    className="fw-bold">Preferred?</Form.Check.Label>
                </Form.Check>}
                handleChange={(e) => {
                  const value = e.paramValue[0];
                  let preferredLanguage = {...preferredOptionLanguage};
                  if (isEmpty(trim(value))) {
                    preferredLanguage = set(preferredLanguage,
                      'tertiary.preferred', false);
                  }
                  setPreferredOptionLanguage(
                    set(preferredLanguage, 'tertiary.value', value));
                }}
                disabled={isSecondaryLangEmpty}
                selected={new FilterParam("Tertiary Language", "eq",
                  get(preferredOptionLanguage, 'tertiary.value') ? [get(
                    preferredOptionLanguage, 'tertiary.value')] : [], (e) => e,
                  (e) => e)}
                getData={() => languagesPromise}
                highlightWhenHasValue={false}
              />
            </Col>
          </Row>
          <Row xs={1} sm={1} md={3}>
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("primarySpokenLanguage", value)}
                header={PRIMARY_SPOKEN_LANGUAGE}
                selectedItem={patientOverviewClone.primarySpokenLanguage}
                otherItemText={OTHER_LANGUAGE}
            />
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("secondarySpokenLanguage", value)}
                header={SECONDARY_SPOKEN_LANGUAGE}
                selectedItem={patientOverviewClone.secondarySpokenLanguage}
                otherItemText={OTHER_LANGUAGE}
                disabled={!patientOverviewClone.primarySpokenLanguage}
            />
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("tertiarySpokenLanguage", value)}
                header={TERTIARY_SPOKEN_LANGUAGE}
                selectedItem={patientOverviewClone.tertiarySpokenLanguage}
                otherItemText={OTHER_LANGUAGE}
                disabled={!patientOverviewClone.secondarySpokenLanguage}
            />
          </Row>
          <Row xs={1} sm={1} md={3}>
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("primaryReadingLanguage", value)}
                header={PRIMARY_READING_LANGUAGE}
                selectedItem={patientOverviewClone.primaryReadingLanguage}
                otherItemText={OTHER_LANGUAGE}
            />
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("secondaryReadingLanguage", value)}
                header={SECONDARY_READING_LANGUAGE}
                selectedItem={patientOverviewClone.secondaryReadingLanguage}
                otherItemText={OTHER_LANGUAGE}
                disabled={!patientOverviewClone.primaryReadingLanguage}
            />
            <SimpleOtherOptionDDL
                items={spokenLanguages.map(language => ({text:language.text, value: language.text}))}
                handleChange={(value) => handleChange("tertiaryReadingLanguage", value)}
                header={TERTIARY_READING_LANGUAGE}
                selectedItem={patientOverviewClone.tertiaryReadingLanguage}
                otherItemText={OTHER_LANGUAGE}
                disabled={!patientOverviewClone.secondaryReadingLanguage}
            />
          </Row>
          <Row xs={1} sm={1} md={3}>
            <Col>
              <Modal onHide={cancelExpiredModal} show={showExpiredModal} size="sm" centered scrollable>
                <Modal.Header>
                  <Modal.Title>Expired Confirmation</Modal.Title>
                  <button id={`${componentName}-ModalClose`} type="button" className="btn-close" aria-label="Close" onClick={cancelExpiredModal}></button>
                </Modal.Header>
                <Modal.Body>
                  I have verified that this patient is deceased.
                </Modal.Body>
                <Modal.Footer>
                  <Button id={`${componentName}-closeExpired`} onClick={cancelExpiredModal} variant="secondary">Cancel</Button>
                  <Button id={`${componentName}-okExpired`} variant="primary" onClick={closeExpiredModal}>Ok</Button>
                </Modal.Footer>
              </Modal>
              <Form.Check type="checkbox" id={`${componentName}-patientDeceased`} className="mt-3">
                <Form.Check.Input
                  type="checkbox"
                  id={`${componentName}-patientDeceasedCheck`}
                  value={patientOverviewClone.expired}
                  checked={patientOverviewClone.expired}
                  disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                  onChange={(e) => {
                    if(e.target.checked) {
                      setShowExpiredModal(true)
                    } else {
                      handleChange("expired", false)
                    }
                  }}
                />
                <Form.Check.Label id={`${componentName}-patientDeceasedLabel`}>Patient is deceased</Form.Check.Label>
              </Form.Check>
            </Col>
            {patientOverviewClone.expired ? 
              <Col className="mb-3">
                <Form.Label id={`${componentName}-dateExpiredLabel`}>Date Expired</Form.Label>
                <EQHDatePicker
                    id={`${componentName}-dateExpiredValue`}
                    value={patientOverviewClone.expiredDate}
                    onChange={(e) => handleChange("expiredDate", e.strDate)}
                    max={format(new Date(), "yyyy-MM-dd")}
                    disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                />
              </Col>
            : null}
            {patientOverviewClone.expired ?
              <Col className="mb-3">
                <Form.Label id={`${componentName}-confirmationSourceLabel`}>Confirmation Source</Form.Label>
                <Form.Control
                    as="select"
                    id={`${componentName}-confirmationSourceValue`}
                    onChange={(e) => handleChange("expiredSource", e.target.value)}
                    value={patientOverviewClone.expiredSource}
                    disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                >
                  <option value="Sources" selected>-Select-</option>
                  {expiredSources.map((source, idx) => (
                    <option value={source.value}>{source.value}</option>
                  ))}
                </Form.Control>
              </Col>
            : null}
          </Row>
          <hr/>
          <h5 className="my-4">Insurance Information</h5>

          <Row xs={1} sm={1} md={3}>
            <Col className="mb-3" md={8}>
              <Form.Label>Payor</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.payer}
                disabled
              />
              {patientOverviewClone.secondPayer && <Form.Control
                className = "mt-1"
                type="text"
                value={patientOverviewClone.secondPayer}
                disabled
              />}
            </Col>
            <Col className="mb-3" md={4}>
              <Form.Label>Medical Record Number</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.mrn}
                onChange={(e) => handleChange("mrn", e.target.value)}
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col md={8}>
              <Form.Label>Insurance Info</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.insuranceInfo}
                onChange={(e) => handleChange("insuranceInfo", e.target.value)}
              />
            </Col>
            <Col md={4}>
              <DropDownList
                mode={DDLMode.SelectOne}
                dataMode={DDLDataMode.OneAPICallFilterInUI}
                header="Payor Status"
                disabled
                handleChange={() => {
                }}
                getData={() => Promise.resolve(payorStatus || [])}
                selected={new FilterParam("Payor Status", "eq",
                  patientOverviewClone.membershipActive ? ['Active']
                    : ['Active'], (e) => e, (e) => e)}
                highlightWhenHasValue={false}
                showClearButton={false}
              />
            </Col>
          </Row>

          <Row xs={1} sm={1} md={3}>
            <Col className="mb-3">
              <Form.Label>Member ID / Ins Number</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.memberId}
                disabled
              />
            </Col>
            <Col className="mb-3">
              <Form.Label>Term Date</Form.Label>
              <Form.Control
                type="text"
                value={patientOverviewClone.termDate}
                onChange={(e) => handleChange("termDate", e.target.value)}
                disabled
              />
            </Col>
          </Row>

          <hr/>
          <h5 className="my-4">Care Team Information</h5>

          {careTeamMembers?.map((careTeamMember, index) =>
            createCareTeamMemberFragment(careTeamMember, index, (changedProperties) => {
              let members = [...careTeamMembers];
              for (const [prop, value] of Object.entries(changedProperties)) {
                const propertyPath = index + '.' + prop;
                members = set(members, propertyPath, value);
              }
              setcareTeamMembers(members);
            }))}

          <Row xs={1} sm={1} md={3} className="mb-3">
            <a
              className="cursor-p fw-bold"
              onClick={() => {
                const indexId = careTeamMembers.length;
                const membersFragments = _.cloneDeep(careTeamMembers);
                membersFragments.push(emptyCareTeamMember(indexId));
                setcareTeamMembers(membersFragments);
              }}
            >
              <i className="fas fa-plus me-3"/> Add Care Team Member
            </a>
          </Row>

        </Container>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" id={`${componentName}-closePatientEdit`}
                onClick={() => closeAction()}>Cancel</Button>
        <Button id={`${componentName}-submitPatientEdit`}
                onClick={() => submitAction()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  </>);
};

export default PatientDetailsEdit;
