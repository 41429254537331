import {isObject} from "lodash";

import {actions} from "./actions";
import {formatReverseDate} from "../../../../utils/util";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case actions.SET_VISITS_DETAILS:
      return {
        ...state,
        visitsDetails: action.payload,
      };
    case actions.TOGGLE_SHOW_CLOSED_OR_INACTIVE:
      return {
        ...state,
        showClosedOrInactive: action.payload,
      };
    case actions.CLEAR_UNSAVED_VISITS_MAP:
      return {
        ...state,
        unsavedVisitsMap: {},
      };
    case actions.UPDATE_UNSAVED_VISITS_MAP:
      let unsavedVisitsMap = {...state.unsavedVisitsMap};
      const {visitId, fieldsToUpdate} = action?.payload;
      if (isObject(fieldsToUpdate)) {
        const existingVisit = unsavedVisitsMap[visitId] ?? {};
        const isBeingUpdated = (field) => {
          return existingVisit[field] !== fieldsToUpdate[field]
        }

        if (isBeingUpdated('date') || isBeingUpdated('status')) {
          if (!existingVisit.date && !fieldsToUpdate.date) {
            fieldsToUpdate.date = formatReverseDate(new Date());
          }
          fieldsToUpdate.checked = true;
          unsavedVisitsMap[visitId] = {...existingVisit, ...fieldsToUpdate};
        } else if (isBeingUpdated('checked')) {
          if (fieldsToUpdate.checked) {
            if (!existingVisit.date) {
              fieldsToUpdate.date = formatReverseDate(new Date());
            }
            unsavedVisitsMap[visitId] = {...existingVisit, ...fieldsToUpdate};
          } else {
            delete unsavedVisitsMap[visitId];
          }
        }
      } else if (fieldsToUpdate === null || fieldsToUpdate === undefined) {
        delete unsavedVisitsMap[visitId];
      }
      return {
        ...state,
        unsavedVisitsMap: unsavedVisitsMap,
      };
    case actions.TOGGLE_IS_SAVING_ACTIVITIES:
      return {
        ...state,
        isSavingActivities: action.payload,
      };
    default:
      return state;
  }
};