import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';

function ManageVisitWrapper({ extraFilters, badges, children, setShow, show }) {
  return (
    <>
      <div className='d-flex align-items-start'>
        <div>
          <Button
            variant='light'
            className='border me-2 mb-2'
            style={{ minWidth: '88px' }}
            onClick={() => setShow(true)}
          >
            <span className='me-2'>
              <i className='fa-solid fa-bars-filter me-2'></i>Filter</span>
          </Button>
        </div>
        <div>
          {extraFilters && extraFilters}
        </div>
        <div className="mb-2 d-flex flex-wrap">
          {badges}
        </div>
      </div>
      {show &&
        <div id='overlay-side-filters-manage-visit' onClick={() => setShow(false)}></div>
      }
      <div
        style={{
          background: 'white',
          left: !show ? '-300px' : '0px',
          top: '-15px',
          position: 'absolute',
          minHeight: '94vh',
          maxHeight: '94vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '300px',
          zIndex: 1
        }}
      >
        <Row className="p-4">
          <Col>
            <span className='h5 fw-bold ml-3'>
              Filters
            </span>
          </Col>
          <Col className="text-end">
            <button type='button' id='closeSideFiltersManageVisit' className='btn-close text-reset' onClick={() => setShow(false)}></button>
          </Col>
        </Row>
        <div className='accordion' id='accordionFiltersManageVisit' style={{ minHeight: '76vh', maxHeight: '76vh', overflowY: 'auto' }}>
          {children}
        </div>
      </div>
    </>
  )
}

export default ManageVisitWrapper