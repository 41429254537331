import React from 'react'
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { useLoginInfoContext } from './context/LoginInfoContext';
import { EQHSpinner } from '../../components/EQHSpinner';


const PdfView = styled.iframe``;

const PdfContainer = styled.div`
    background-color: white;
    height: 90vh;
    position: fixed;
    width: 600px;
    right: 10%;
    top: 5%;
    border-radius: 5px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    height: 40px;
`

const PDFTitle = styled.h1`
    margin-left: 20px;
    font-size: 15px
`

export default function TermsAndAgreements({ onLogin, setTermsRequired }) {
  const loginInfo = useLoginInfoContext();
  const termsOfUsePdf = process.env.REACT_APP_REST_API_BASE_URL + "/system_files/Terms_of_Use.pdf";
  const termsOfUsePdfView = termsOfUsePdf + "#zoom=FitW";

  return (
    <PdfContainer>
      <PDFTitle>Terms and Agreements</PDFTitle>
      <PdfView src={termsOfUsePdfView} title="W3Schools Free Online Web" height='89%' width='100%'>
        Presss me: <a href={termsOfUsePdf}>Download PDF</a>
      </PdfView>
      <ButtonsContainer>
        <Button variant='secondary' onClick={() => loginInfo.setTermsRequired(false)}>Close</Button>
        <Button variant='primary' onClick={() => loginInfo.onLoginAccepted()}>
          {loginInfo.loading
            ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            : 'Accept'
          }
        </Button>
      </ButtonsContainer>
    </PdfContainer>
  )
}
