import React from "react";
import { Dropdown } from "react-bootstrap";
import { getStatusColors } from "../../../../../utils/util";
import { useAuth } from "../../../../../context/authContext";

const componentName = "careStepStatus";

export const CareStepStatus = ({ index, step, findCustomStatusLabel, careProgram, isNotFromPatientDrawer }) => {
  const auth = useAuth();
  const authorizedCareProgramWrite = isNotFromPatientDrawer || auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");
  const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1)

  return (
    <>
      <Dropdown className="dropdown-end carePathStatus">
        <Dropdown.Toggle
          id={`${componentName}-carePathStepStatus-${index}`}
          menuAlign="right"
          style={{
            color: getStatusColors(step.carePathStepStatus).font,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '0 15px'
          }}
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                'background-color',
                getStatusColors(step.carePathStepStatus).bg,
                'important'
              )
            }
          }}
          className="badge badge-pill border-0"
          disabled={!authorizedCareProgramWrite || !careProgram?.active}
        >
          <span className="forced-capital" style={{ maxWidth: '90%', margin: '2px 0', padding: '2px 0', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {step.stepCustomState && (step.stepCustomState.behavesLike === step.carePathStepStatus) ? capitalizeWord(step.stepCustomState.status) : capitalizeWord(step.carePathStepStatus)}
          </span>
          <i className="fa-solid fa-chevron-down fa-sm"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {findCustomStatusLabel(step)}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CareStepStatus;