import styled from "styled-components";
import {CARE_PATHS_COLORS} from "../../../utils/util";

export const StatusLabel = styled.label`
color: ${(props) =>
  props.type === 'Care Program'
    ? getColorForCareProgram(props.status) :
    props.type === 'Lab'
      ? getColorForLab(props.status) :
      props.status === "Scheduled"
        ? `#FFFFFF` :
        props.type === 'Assessment'
          ? `#FFFFFF` :
          props.status === "Confirmed"
            ? `#FFFFFF` :
            props.status === "Cancelled"
              ? `#FFFFFF` :
              props.status === "Completed"
                ? `#FFFFFF` :
                props.status === "No Show"
                  ? `#FFFFFF` :
                  props.status === "Cancelled"
                    ? `#FFFFFF` :
                    props.status === "ADW"
                      ? `#75797B`
                      : `#FFFFFF`};
background-color: ${(props) =>
  props.type === 'Care Program'
    ? getBackgroundColorForCareProgram(props.status) :
      props.type === 'Lab'
      ? getBackgroundColorForLab(props.status) :
      props.status === "Scheduled"
        ? `#75797B` :
        props.status === "Completed" && props.type === 'Assessment'
          ? `#519940` :
          props.status === "Draft" && props.type === 'Assessment'
            ? `#DE6D70` :
            props.type === 'Referral Notes'
              ? getBackgroundColorForReferralNotes(props.status) :
              props.status === "Confirmed"
                ? `#3987BD` :
                  props.status === "Completed"
                    ? `#519940` :
                    props.status === "No Show"
                      ? `#DE6D70` :
                      props.status === "Cancelled"
                        ? `#DE6D70` :
                        props.status === "ADW"
                          ? `#EBEBEB`
                          : `#75797B`};
font-weight: bold;
font-size: 12px;
padding: 5px 20px;
`;

const getBackgroundColorForReferralNotes = (status) => {
  switch (status) {
    case 'Pending':
      return `#F0C133`;
    case 'Declined':
      return `#75797B`;
    case 'Accepted':
      return `#3987BD`;
    case 'Completed':
      return `#519940`;
    case 'Patient Cancelled':
      return `#D12026`;
    case 'Cancelled':
      return `#DE6D70`;
    default:
      return `#86898B`;
  }
}

export const sortItems = [
  {
    value: "Default",
    text: "Default",
    hasDivider: false,
    condition: true
  }
]

export const labStatusMap = {
  "H": "High",
  "L": "Low"
}

const getColorForLab = (status) => {
  const isHighlighted = Object.keys(labStatusMap).includes(status);
  return isHighlighted ? `#D12026` : `#FFFFFF`;
};

const getBackgroundColorForLab = (status) => {
  const isHighlighted = Object.keys(labStatusMap).includes(status);
  return isHighlighted ? `#F8DEDF` : `#86898B`;
};

const getColorForCareProgram = (status) => {
  const colors = {...CARE_PATHS_COLORS,
    'Closed': {
      bg: '#EAEBEB',
      font: '#86898B'
    }}
  return colors?.[status]?.font || `#FFFFFF`;
};

const getBackgroundColorForCareProgram = (status) => {
  const colors = {...CARE_PATHS_COLORS,
    'Closed': {
      bg: '#EAEBEB',
      font: '#86898B'
    }}
  return colors?.[status]?.bg || `#86898B`;
};

export const EVENTS_TYPE_MAPPER = {
  'Appointment': 'Appointments',
  'Assessment': 'Assessments',
  'Care Opportunity': 'Care Opportunities',
  'Care Program': 'Care Programs',
  'Diagnosis': 'Diagnoses',
  'Encounter': 'Encounters',
  'Hospitalization': 'Hospitalizations',
  'Lab Data': 'Lab Data',
  'Medication Data': 'Medication Data',
  'Referral Notes': 'Referrals'
}