import {
    UPDATE_IS_SHOWING_FORM,
    UPDATE_FIELD,
    GET_PATIENTS,
    SHOW_ALERT,
    HIDE_ALERT,
    SHOW_REQUEST_ALERT,
    HIDE_PATIENTS_FOUND,
    HIDE_POPULATION_SEARCH_VIEW,
    UPDATE_SHOW_SPINNER,
    USER_ADDED_SUCCESFUL,
    RESET_GLOBAL_FORM,
    TEMP_HIDE_PATIENTS_FOUND
} from "../../actions/types";

const initialState = {
    addedUser: false,
    searchForm: {
        firstName: "",
        lastName: "",
        memberId: "",
        dateOfBirthFirstOption: null,
        dateOfBirthSecondOption: null
    },
    searchString: "",
    patientsFound: null,
    searchText: "",
    searchPlaceHolder: "",
    isShowingForm : false,
    error: null,
    isShowingSpinner: false,
    showFormOnCloseAlert: true,
    doLogout: false,
    temporaryHidePSG : false,
};

const updateField = (state, payload) => {
    let name = payload.fieldName;
    let value = payload.event;

    if(name == null){
        name = payload.event.target.name;
        value = payload.event.target.value;
    }

    if(name == "searchText"){
        return {
            ...state,
            searchText: value
        };
    }else if(name == "searchPlaceHolder"){
        return {
            ...state,
            searchPlaceHolder: value
        };
    }
    
    {
        const newState = Object.assign({}, state);
        newState.searchForm[name] = value;
        return newState;
    }
};

const updateIsShowingForm = (state, payload) => {
    const newState = Object.assign({}, state);
    newState.isShowingForm = payload;

    if(payload){
        newState.searchForm.firstName = "";
        newState.searchForm.lastName = "";
        newState.searchForm.memberId = "";
        newState.searchForm.dateOfBirthFirstOption = null;
        newState.searchForm.dateOfBirthSecondOption = null;
    }

    return newState;
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case UPDATE_FIELD:
          return updateField(state, payload);
      case UPDATE_IS_SHOWING_FORM:
          return updateIsShowingForm(state, payload);
      case SHOW_ALERT:
          return {
              ...state,
              error: payload,
          };
      case HIDE_ALERT:
          return {
              ...state,
              error: null,
          };
      case RESET_GLOBAL_FORM:
          return {
              ...state,
              searchForm: {
                  firstName: "",
                  lastName: "",
                  memberId: "",
                  dateOfBirthFirstOption: null,
                  dateOfBirthSecondOption: null
              }
          };
      case SHOW_REQUEST_ALERT:
          return {
              ...state,
              error: payload.message,
              isShowingSpinner: false,
              showFormOnCloseAlert: payload.showFormOnCloseAlert,
              doLogout: payload.doLogout ? true : false
          };
      case GET_PATIENTS:
          return {
              ...state,
              patientsFound: payload,
              isShowingSpinner: false
          };
      case HIDE_PATIENTS_FOUND:
          return {
              ...state,
              patientsFound: null,
              isShowingForm: false,
              searchText: ""
          };
      case TEMP_HIDE_PATIENTS_FOUND:
          return {
                ...state,
                temporaryHidePSG: payload,
            };
      case HIDE_POPULATION_SEARCH_VIEW:
          return {
              ...state,
              patientsFound: null,
              isShowingForm: false,
              error: null
          };
      case UPDATE_SHOW_SPINNER:
          return {
              ...state,
              isShowingSpinner: payload
          };
        case USER_ADDED_SUCCESFUL:
            return {
                ...state,
                addedUser: true
            };
      default:
        return state;
  }
}