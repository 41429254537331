import React from "react";
import { Col, Dropdown } from "react-bootstrap";
import { SortParam } from "../../../../../dtos/PatientSearchDTO";
import { useAssessmentsContext } from "../context/Assessments.context";

const componentName = "SortMenu"

const SortMenu = ({
  items,
  refreshPatientList
}) => {
  const {
    setSortParams,
    filterParams,
  } = useAssessmentsContext();

  const onSortItemClick = (menuItemSortClicked) => {
    onPatientActionSortClicked(menuItemSortClicked.value);
  }

  const onPatientActionSortClicked = (menuItemSortClicked) => {
    let sortParams = [];
    switch (menuItemSortClicked) {
      case "Default":
        setSortParams(sortParams = []);
        break;
      case "Assessment Name (A-Z)":
        setSortParams(sortParams = [new SortParam("name", "ASC", 1)]);
        break;
      case "Assessment Name (Z-A)":
        setSortParams(sortParams = [new SortParam("name", "DESC", 1)]);
        break;
      case "Assessment Status (A-Z)":
        setSortParams(sortParams = [new SortParam("status", "ASC", 1)]);
        break;
      case "Assessment Status (Z-A)":
        setSortParams(sortParams = [new SortParam("status", "DESC", 1)]);
        break;
      default:
        setSortParams(sortParams = []);
    }

    refreshPatientList(1, filterParams, sortParams);
  }

  return (
    <Dropdown
      id={`${componentName}-dropDown`}
      key={`${componentName}-dropDownMenu-key`}
      className="text-end"
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        id={`${componentName}-assessmentAction`}
        size="s"
        style={{ padding: '4px 16px 4px 10px' }}
      >
        <i className="fa fa-arrow-up-arrow-down"
          style={{ paddingRight: '5px' }}
        />Sort
      </Dropdown.Toggle>
      <Dropdown.Menu
        id={`${componentName}-dropDownMenu`}
        key={`${componentName}-dropDownMenu-key`}
        className='dropdown-menu-aligned'
      >
        {items && items.map((item, itemIdx) => {
          return (
            <Col
              id={`${componentName}-colMenuItem-${itemIdx}`}
              key={`${componentName}-colMenuItem-${item.value}`}
            >
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => onSortItemClick(item)}
              >{item.text}</Dropdown.Item>
            </Col>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SortMenu;