import {searchPatients} from "../../../../actions/caremgmt/hubWorkCenterAction";
import {
    getDOChannel,
    getDONoteTypes,
    getDOSource,
    getDOType, handleError,
    submitIntervention
} from "../../../../actions/caremgmt/myWorkspaceActions";
import React, {useEffect, useState} from "react";
import {Row, Col, Button, Modal, Form} from "react-bootstrap";
import EQHErrorToast from "../../../../components/EQHErrorToast";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

export const InterventionModal = () => {
    const componentName = "InterventionModal";
    const [errors, setErrors] = useState([]);
    const isEmpty = require("is-empty");
    const [success, setSuccess] = useState(null);
    const [channels, setChannels] = useState([]);
    const [sources, setSources] = useState([]);
    const [interventionTypes, setInterventionTypes] = useState([]);
    const [noteCategories, setNoteCategories] = useState([]);
    const [interventionForm, setInterventionForm] = useState({});

    const [patienOption, setPatientOptions] = useState([]);
    const [patientsLoading, setPatientsLoading] = useState(false);

    const [showIntervention, setShowIntervention] = useState(false);
    const handleInterventionShow = ()=> setShowIntervention(true);
    const handleInterventionClose = ()=> {
        setShowIntervention(false);
        clearInterventionForm();
    };

    const patientSearch = (query) => {
        if (query && query.length > 3) {
            const timeout = setTimeout(() => {
                setPatientsLoading(true);
                searchPatients(query).then((response) => {
                    if (response && response.data) {
                        const options = response.data.patients.map(p => {
                            return {text: `${p.surnameCommaName} - ${p.memberId}`, value: p.id}
                        });
                        setPatientOptions(options);
                    }
                    setPatientsLoading(false);
                }).catch((error) => {
                    onError(error);
                    setPatientsLoading(false);
                });
            }, 1600);

            return () => clearTimeout(timeout);
        }
    }

    const fetchInterventionDropdowns = () => {
        getDOChannel().then((resp) => {
            if (resp && resp.data) {
                setChannels(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });

        getDOSource().then((resp) => {
            if (resp && resp.data) {
                setSources(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });

        getDONoteTypes().then((resp) => {
            if (resp && resp.data) {
                setNoteCategories(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });

        getDOType().then((resp) => {
            if (resp && resp.data) {
                setInterventionTypes(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });
    };

    const validateInterventionForm = () => {
        return !isEmpty(interventionForm.patientId) &&
            !isEmpty(interventionForm.channelId) &&
            !isEmpty(interventionForm.typeId) &&
            !isEmpty(interventionForm.outcomeId)
    };

    const handleInterventionFormChanged = (property, value) => {
        let formCopy = interventionForm;
        formCopy[property] = value;
        setInterventionForm(formCopy);
    };

    const handleInterventionSubmit = () => {
        if (validateInterventionForm()) {
            submitIntervention(interventionForm).then((resp) => {
                handleInterventionClose();
                setSuccess({message: "Intervention submited successfuly"});
            }).catch((error) => {
                onError(error);
            });
        }else{
            setErrors(["Some mandatory fields are empty"]);
        }
    };

    const onError = (err) => {
        const error = handleError(err)
        setErrors([error]);
    };

    const clearInterventionForm = () => {
        setInterventionForm({});
    };

    useEffect(() => {
        fetchInterventionDropdowns();
    }, []);

    return (<>
        <Button id={`${componentName}-interventionButton`} variant="secondary"
                onClick={handleInterventionShow} className="me-2">
            + Intervention
        </Button>
        <Modal size="lg" centered show={showIntervention} onHide={handleInterventionClose}>
        <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-interventionModalTitle`}>Add Intervention</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
            <Row>
                <Col className="mb-3" md={8}>
                    <label id={`${componentName}-addMemberNameLabel`} className="d-block">
                        Member Name or ID<span className="text-danger">*</span></label>
                    <AsyncTypeahead
                        id={`${componentName}-member-name`}
                        labelKey="text"
                        isLoading={patientsLoading}
                        onSearch={patientSearch}
                        options={patienOption}
                        onChange={(e) => handleInterventionFormChanged("patientId", e[0]?.value)}
                        placeholder="Search Members by Name or ID"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <label className="d-block">Channel<span className="text-danger">*</span></label>
                    <select id={`${componentName}-channel`}
                            onChange={(e) => handleInterventionFormChanged("channelId", e?.target?.value)}
                            value={interventionForm?.channelId} className="form-select">
                        <option value=""></option>
                        {channels && channels.map((f, i) => {
                            return (
                                <option id={`channel-${i}`} value={f.value}>{f.text}</option>);
                        })}
                    </select>
                </Col>
                <Col md={6} className="mb-3">
                    <label className="d-block">Source</label>
                    <select id={`${componentName}-source`}
                            onChange={(e) => handleInterventionFormChanged("sourceId", e?.target?.value)}
                            value={interventionForm?.sourceId} className="form-select">
                        <option value=""></option>
                        {sources && sources.map((f, i) => {
                            return (
                                <option id={`source-${i}`} value={f.value}>{f.text}</option>);
                        })}
                    </select>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <label className="d-block">Intervention Type<span className="text-danger">*</span></label>
                    <select id={`${componentName}-intervention-type`}
                            onChange={(e) => handleInterventionFormChanged("typeId", e?.target?.value)}
                            value={interventionForm.typeId} className="form-select">
                        <option value=""></option>
                        {interventionTypes && interventionTypes.map((f, i) => {
                            return (
                                <option id={`intervention-type-${i}`} value={f.value}>{f.text}</option>);
                        })}
                    </select>
                </Col>
                <Col md={6} className="mb-3">
                    <label className="d-block">Outcome<span className="text-danger">*</span></label>
                    <select id={`${componentName}-note-category`}
                            onChange={(e) => handleInterventionFormChanged("outcomeId", e?.target?.value)}
                            value={interventionForm.outcomeId} className="form-select">
                        <option value=""></option>
                        {noteCategories && noteCategories.map((f, i) => {
                            return (
                                <option id={`note-category-${i}`} value={f.value}>{f.text}</option>);
                        })}
                    </select>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mb-6">
                    <label id={`${componentName}-interventionNotesLabel`} className="d-block">Notes</label>
                    <Form.Control id={`${componentName}-interventionNotesValue`} type="text" as="textarea" rows={5}
                                  onChange={(e) => handleInterventionFormChanged("notes", e?.target?.value)}/>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" id={`${componentName}-interventionModalClose`}
                    onClick={handleInterventionClose}>
                Close
            </Button>
            <Button variant="primary" id={`${componentName}-interventionModalSubmit`}
                    onClick={handleInterventionSubmit}>
                Submit
            </Button>
        </Modal.Footer>
    </Modal>
    </>);
}