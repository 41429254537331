import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { createConversation, addUser, getMessageTypes, getUserMessages } from '../../actions/admin/header/messageActions';
import { getAllUsers, getAllUsersByCLients } from "../../actions/admin/userManagementActions";
import { updateFile, clearFileUploads } from "../../actions/fileUploadActions";
import { Typeahead } from "react-bootstrap-typeahead";
import MultipleUploads from "../../components/MultipleUploads";
import { Button } from '../../components/buttons';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from "../../context/authContext";
import { FileUpload } from '../../components/FileUpload';

const componentName = "ComposeMessage";

const ComposeDiv = styled.div`
  flex: 1;
  h5 {
    font-weight: bold;
  }
  h6 {
    padding-top: 5px;
    padding-bottom: 10px;
  }
`;
const RedAsterisk = styled.span`
  color: red;
`;
const ComposeMessage = ({ closeModal }) => {
    let auth = useAuth();
    const token = auth.getAuthToken();
    const [emailData, setEmailData] = useState({});
    const [msgType, setMsgType] = useState({});
    const handleChangeWithinModal = (e, error) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value,
        });
        isFormValid();
    };
    const handleUploadFileChange = (arr) => {
        setUploadedFiles(arr);
    };

    const { allUsers } = useSelector((state) => state.userManagement);
    const { fileObject } = useSelector((state) => state.fileUpload);
    const { conversationId, messageTypes } = useSelector((state) => state.messages);
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [fileAdded, setFileAdded] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [urgent, setUrgent] = useState(false);

    const isEmpty = require("is-empty");

    const setFileMethod = async (filesList) => {
        let file;
        let fileName
        if (filesList && filesList.name) {
            fileName = filesList.name
        }
        const fileReader = new FileReader();
        if (fileReader && filesList) {
            fileReader.readAsArrayBuffer(filesList);
            fileReader.onload = function () {
                if (fileReader && fileReader.result && !isEmpty(fileReader.result)) {
                    dispatch(updateFile(fileReader.result, fileName));
                }
            };
        }
    };

    const handleChange = (e) => {
        if (e.length === 0) {
            setMsgType([]);
        } else {
            setMsgType(e[0]);
        }
        isFormValid();
    };

    const createConvAndSend = async () => {
        let userId = auth.getUserId();
        const converseMessage = {
            conversationDto: {
                title: emailData.title,
                participants: [...users, userId],
                messageType: msgType,
            },
            messageDto: {
                title: emailData.title,
                message: emailData.message,
                messageType: msgType,
                important: urgent,
            },
        };
        if (uploadedFiles && uploadedFiles.length) {
            converseMessage.messageDto["uploadedFiles"] = uploadedFiles;
        }
        await dispatch(createConversation(converseMessage));
        onModalClose();
    };

    const updateUsers = async (e, error) => {
        let idArray = [];
        e.forEach((user) => {
            idArray.push(user.id);
            setUsers(idArray);
        });
        if (e.length === 0) {
            setUsers([]);
        }
    };

    useEffect(() => {
        (async function fetchData() {
            if (!messageTypes.length) {
                await dispatch(getMessageTypes(token));
            }
        })();
    }, []);

    const isFormValid = () => {
        const { title, message } = emailData;
        if (
            title &&
            msgType &&
            message &&
            users &&
            title.length &&
            msgType.length &&
            message.length &&
            users.length
        ) {
            return false;
        } else {
            return true;
        }
    };

    const updateFiles = (arr) => {
        setUploadedFiles(arr);
    };

    const onModalClose = async () => {
        await dispatch(clearFileUploads());

        await closeModal();
    }

    useEffect(() => {
        (async function fetchMessages() {
            if (!allUsers.length) {
                await dispatch(getAllUsersByCLients());
            }
        })();
    }, []);

    useEffect(() => {
        if (fileObject) {
            setFileAdded(fileObject);
        }
    }, [fileObject]);

    return (
        <>
            {allUsers && allUsers.length ? (
                <ComposeDiv>
                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group m-b-10">
                                        <label id={`${componentName}-chooseUsersLabel`} className="d-block">Choose Users<span className="text-danger">*</span></label>
                                        <Typeahead
                                            id={`${componentName}-chooseUsersValue`}
                                            inputProps={{
                                                id: `${componentName}-chooseUsersValue`
                                            }}
                                            multiple
                                            labelKey="email"
                                            onChange={(e) => updateUsers(e)}
                                            options={allUsers}
                                        />
                                    </div>
                                    <div className="form-group m-b-10">
                                        <b className="d-block">Choose Message Type<RedAsterisk>*</RedAsterisk></b>
                                        <Typeahead
                                            id={`${componentName}-chooseMessageTypesValue`}
                                            inputProps={{
                                                id: `${componentName}-chooseMessageTypesValue`
                                            }}
                                            labelKey="msgType"
                                            onChange={(e) => handleChange(e)}
                                            options={messageTypes}
                                        />
                                    </div>
                                    <div className="form-group m-b-10">
                                        <b className="d-block">Subject<RedAsterisk>*</RedAsterisk></b>
                                        <input
                                            type="text"
                                            onChange={(e) => handleChangeWithinModal(e)}
                                            className="form-control"
                                            name="title"
                                        />
                                    </div>
                                    <div className="form-group m-b-10">
                                        <FileUpload
                                            onChange={handleUploadFileChange}
                                            clearStateProp={0}
                                        />
                                    </div>
                                    <div className="form-group m-b-0">
                                        <b className="d-block">Message<RedAsterisk>*</RedAsterisk></b>
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            onChange={(e) => handleChangeWithinModal(e)}
                                            rows="5"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <div className="me-auto">
                            <label className="custom-control custom-checkbox">
                                <input
                                    value={urgent}
                                    type="checkbox"
                                    name="markUrgent"
                                    onClick={() => setUrgent(!urgent)}
                                    className="custom-control-input"
                                />
                                <span className="custom-control-label">Mark As Urgent</span>
                            </label>
                        </div>
                        <Button className="btn" data-dismiss="modal" onClick={() => onModalClose()}>
                            Cancel
                        </Button>
                        <Button
                            className="btn btn-primary"
                            onClick={() => createConvAndSend()}
                            disabled={isFormValid()}>
                            Send
                        </Button>
                    </div>
                </ComposeDiv>
            ) : (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />{" "}
                    Loading...
                </>
            )}
        </>
    );
}

export default ComposeMessage;