import { actions } from "./actions";

export const reducer = (state, action) => {
    switch (action.type) {
      case actions.SELECT_USERNAME:
        return state.username;
      case actions.SELECT_PASSWORD:
        return state.password;
      case actions.SELECT_ERROR:
        return state.error;
      case actions.SELECT_TERMS_REQUIRED:
        return state.termsRequired;
      case actions.SELECT_LOGIN_FAILED:
        return state.latestLoginFailed;
      case actions.SET_USERNAME:
        return {
          ...state,
          username: action.payload
        };
      case actions.SET_LOADING:
        return {
          ...state,
          loading: action.payload
        };
      case actions.SET_PASSWORD:
        return {
          ...state,
          password: action.payload
        };
      case actions.SET_LOGIN_FAILED:
        return {
          ...state,
          latestLoginFailed: action.payload
        };
      case actions.SET_ERROR:
        return {
          ...state,
          error: action.payload
        };
      case actions.SET_TERMS_REQUIRED:
        return {
          ...state,
          termsRequired: action.payload
        }
      default:
        return state;
    }
};