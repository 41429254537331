import {isEmpty} from "lodash";
import {getAttestationDDLOption, normalizeDate} from "./common";

export const getVisits = (visitsDetails, showInactiveActivity) => {
  if(isEmpty(visitsDetails?.manageVisitActivityTypeDtos)){
    return [];
  }

  const statusByActivityTypeMap = {
    "CARE_GAP": visitsDetails?.caregapStatusesDropDownMap ?? {},
    "SUSPECT": visitsDetails?.conditionStatusesDropDownMap ?? {}
  }

  return visitsDetails?.manageVisitActivityTypeDtos?.map((visitActivity) => {
    const {visitActivitySubTypeDtos, name} = visitActivity;
    const activities = getActivities(visitActivitySubTypeDtos,
      {
        showInactiveActivity,
        statusByActivityTypeMap,
        groupName: name
      });
    const showTitle = shouldShowTitle(
      visitActivitySubTypeDtos,
      null,
      showInactiveActivity
    );

    return {
      showTitle: showTitle,
      title: name,
      activities: activities
    }
  }).filter(visitGroup => visitGroup?.activities?.length > 0);
}

const getActivities = (visitActivitySubTypeDtos,
  {
    showInactiveActivity,
    statusByActivityTypeMap,
    groupName
  }) => {
  return visitActivitySubTypeDtos
  .filter(visit => returnActiveOrInActive(visit?.visitActivities ?? [],
    showInactiveActivity).length > 0)
  .filter(visit => visit?.currentStatus !== '' && visit?.displayName !== '')
  .map((visitActivitySubType) => {
    const {name, visitActivities} = visitActivitySubType;
    const showTitle = shouldShowTitle(
      visitActivities,
      "visitActivities",
      showInactiveActivity
    ) && !isEmpty(name) && name !== "None"

    return {
      title: name,
      showTitle: showTitle,
      activities: returnActiveOrInActive((visitActivities ?? []),
        showInactiveActivity)
      .map(
        (activity) => toActivity(activity, statusByActivityTypeMap, groupName))
    }
  })
}

const toActivity = (visitActivity, statusByActivityTypeMap, groupName) => {
  return {
    id: visitActivity.id,
    title: getTitle(visitActivity),
    status: visitActivity.currentStatusDisplayName,
    statusEnum: visitActivity.currentStatus,
    date: normalizeDate(visitActivity.dateOfService),
    conditionType: visitActivity.conditionType,
    statusesOptionsMap: statusByActivityTypeMap[visitActivity.actionType] ?? {},
    actionType: visitActivity.actionType,
    requireA1C: visitActivity.requireA1C,
    requireBloodPressure: visitActivity.requireBloodPressure,
    isAttestationRequired: visitActivity.isAttestationRequired,
    requireFileUpload: visitActivity.requireFileUpload,
    requiredActivitiesIds: [],
    validation: visitActivity.validation,
    isDocumentationAndCoding: (groupName === 'Documentation And Coding'),
    attestationDDLOption: getAttestationDDLOption(visitActivity)
  }
}

const getTitle = (visitActivity) => {
  if (visitActivity.toggleStatusValue === "INFO_ONLY") {
    if (visitActivity.currentStatus === "OPEN" || visitActivity.currentStatus
      === "CLOSED") {
      return visitActivity.displayName;
    }
    return null;
  }

  const gapAndYear = isEmpty(visitActivity.gapAndYear) ? ""
    : visitActivity.gapAndYear;
  return visitActivity.displayName + " " + gapAndYear;
}

/*
This method checks if the label should be displayed or not with various scenarios.
*/
const shouldShowTitle = (arrProvided, val, showInactiveActivity) => {
  let arr = []
  if (val === "visitActivities") {
    arr = arrProvided
  } else {
    arr = arrProvided.flatMap(m => m.visitActivities)
  }
  if (showInactiveActivity) {
    return arr.some(e => (checkFilter(e.currentStatus) || hasDosesCompleted(e)))
  } else {
    return arr.some(e => (!checkFilter(e.currentStatus)))
  }
}

const hasDosesCompleted = (e) => {
  return (e.abbreviation === 'IMA' || e.abbreviation === 'CIS')
    && e.immunizationsDTO.careGapImmunizationList.some(
      n => n.dosesActual >= n.doses)
}

const checkFilter = (currentStatus) => {
  return currentStatus === "MARKED_FOR_CLOSURE" || currentStatus
    === "GAP_INELIGIBLE" || currentStatus === "EXCLUDED" || currentStatus
    === "CLOSED" || currentStatus === "CONFIRMED_DIAGNOSIS" || currentStatus
    === "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS" || currentStatus
    === "UNABLE_TO_COMPLETE"
}

const returnActiveOrInActive = (activities, showInactiveActivity) => {
  if (showInactiveActivity) {
    return activities.filter(
      e => (checkFilter(e.currentStatus) || hasDosesCompleted(e)))
  } else {
    return activities.filter(e => (!checkFilter(e.currentStatus)))
  }
}