import axios from "../../../../../../utils/apiClient";
import { URL_DEFAULT, URL_TEAM_LEAD, alternativeFilters } from "../../../constants";

let QUICKLIST_NAME = 'CM_WORKLIST_HUB_COORDINATOR'

const getCarePathsOpen = async (url) => {
  return await axios.get(
    url,
    {
      headers: {},
    }
  )
};

const getCarePrograms = async (url) => {
  return await axios.get(
    url,
    {
      headers: {},
    }
  )
};

const getDeclineReasons = async (url) => {
  return await axios.get(
    url,
    {
      headers: { "Content-Type": "application/json" },
    }
  )
};

const saveActivity = async (url, patientData) => {
  return await axios.post(
    url,
    patientData,
    {
      headers: { "Content-Type": "application/json" },
    }
  )
}

const exportToCSV = async (url,...queryArgs) => {
  const selectedPatientsEventsKeys = queryArgs[2]
  const selectedCompositeKeys = [...new Set(selectedPatientsEventsKeys)];

  const criteria = {
    "patientRequest": {
      "filterParams": [],
      "sortParams": [],
      "pageNumber": 1,
      "pageSize": 20,
      "quickListName": QUICKLIST_NAME
    },
    "selectedItems": [],
    "selectedCompositeKeys": selectedCompositeKeys,
    "quickList": QUICKLIST_NAME
  }

  return axios.post(
    url,
    criteria, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const getExportSummary = (url) => {
  return axios.get(
    url,
    {
      headers: {
        "Content-Type": "application/json",
      },
    });
}

const getPatientsList = (url, queryArgs) => {
  const newFilters = queryArgs?.filterParams?.map(f => {
    if (alternativeFilters[f.paramName]) {
      return { ...f, paramName: alternativeFilters[f.paramName]}
    } else {
      return { ...f}
    }
  });

  return axios.post(
    url,
    {...queryArgs, quickListName: QUICKLIST_NAME, filterParams: newFilters || []},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export const getTeamsCapacity = (url) => {
  return axios
    .get(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
}

export const getCareManagementTeams = (url) => {
  return axios
    .get(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
}

export const assignPatient = (url, ...queryArgs) => {
  const [assignPayload] = queryArgs
  return axios
      .put(
          url,
          assignPayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
      );
}

export const getPatientTemplates = (url) => {
  return axios
  .get(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
  );
}

const saveAssessment = async (url, ...queryArgs) => {
  const assessmentData = queryArgs[0];
  return await axios.post(
      url,
      assessmentData,
      {
        headers: { "Content-Type": "application/json" },
      }
  )
}

const getWorklistDefaultView = async (url, ...queryArgs) => {
  const programLeadId = queryArgs[0];
  return axios
  .get(
      url + QUICKLIST_NAME,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          programLeadId: programLeadId
        }
      }
  );
};

const saveWorklistDefaultView = async (url, ...queryArgs) => {
  let worklistDefaultViewData = queryArgs[0];

  if (!worklistDefaultViewData.quickList) {
    worklistDefaultViewData.quickList = QUICKLIST_NAME;
  }

  if (!worklistDefaultViewData.patientRequestDTO.quickListName ) {
    worklistDefaultViewData.patientRequestDTO.quickListName = QUICKLIST_NAME;
  }

  return await axios.post(
      url,
      worklistDefaultViewData,
      {
        headers: { "Content-Type": "application/json" },
      }
  )
}

const exportToActivator = async (url,...queryArgs) => {
  const selectedPatientsEventsKeys = queryArgs[2]
  const selectedCompositeKeys = [...new Set(selectedPatientsEventsKeys)];

  const criteria = {
    "patientRequest": {
      "filterParams": [],
      "sortParams": [],
      "pageNumber": 1,
      "pageSize": 20,
      "quickListName": "CM_WORKLIST_DIGITAL_OUTREACHER_ACTIVATOR"
    },
    "selectedItems": [],
    "selectedCompositeKeys": selectedCompositeKeys,
    "quickList": "CM_WORKLIST_DIGITAL_OUTREACHER_ACTIVATOR"
  }

  return axios.post(
    url,
    criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    });
}

export const getCampaigns = (url) => {
  return axios
  .get(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

const calls = {
  carePathsOpen: getCarePathsOpen,
  carePrograms: getCarePrograms,
  declinedReasons: getDeclineReasons,
  saveActivity: saveActivity,
  exportToCSV: exportToCSV,
  exportToActivator: exportToActivator,
  exportSummary: getExportSummary,
  searchPatient: getPatientsList,
  teamCapacity: getTeamsCapacity,
  careManagementTeams: getCareManagementTeams,
  assignPatient: assignPatient,
  patientTemplates: getPatientTemplates,
  saveAssessment: saveAssessment,
  worklistDefaultView: getWorklistDefaultView,
  saveWorklistDefaultView: saveWorklistDefaultView,
  campaigns: getCampaigns
}

export const GetCall = async (role, key, urlParam, ...queryArgs) => {
  let url;

  switch (role) {
    case "Admin Role":
    case "Super User Access":
    case "Care Management Admin":
    case "Hub Coordinator":
      url = URL_DEFAULT?.[key] || ''
      QUICKLIST_NAME = 'CM_WORKLIST_HUB_COORDINATOR';
      break;
    case 'Chaplains':
      url = URL_TEAM_LEAD?.[key] || URL_DEFAULT?.[key]
      QUICKLIST_NAME = 'CM_WORKLIST_CHAPLAIN';
      break;
    case 'Care Specialists':
      url = URL_TEAM_LEAD?.[key] || URL_DEFAULT?.[key]
      QUICKLIST_NAME = 'CM_WORKLIST_CARE_SPECIALIST';
      break;
    case 'Community Health Workers':
      url = URL_TEAM_LEAD?.[key] || URL_DEFAULT?.[key]
      QUICKLIST_NAME = 'CM_WORKLIST_CHW';
      break;
    case 'Clinical Coordinators':
      url = URL_TEAM_LEAD?.[key] || URL_DEFAULT?.[key]
      QUICKLIST_NAME = 'CM_WORKLIST_CLINICAL_COORDINATOR';
      break;
    case 'Hospital Liaisons':
      url = URL_TEAM_LEAD?.[key] || URL_DEFAULT?.[key]
      QUICKLIST_NAME = 'CM_WORKLIST_HOSPITAL_LIAISONS';
      break;
    default:
      url = URL_DEFAULT?.[key] || ''
      QUICKLIST_NAME = '';
      break;
  }

  url = process.env.REACT_APP_REST_API_BASE_URL + url + urlParam
  return calls[key](url, ...queryArgs)
}