import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from "./buttons";

const Timer = (ms, action) => {
    let timer;
    function start() {
        this.timer = setTimeout(() => action(), ms);
    };
    function stop() {
        clearTimeout(this.timer);
    }

    return { start, stop };
}

const IdleTimeoutModal = ({ showModal, handleExtendSession, handleLogout, logoutAfterMS }) => {
    const logoutTimer = Timer(logoutAfterMS, () => handleLogout(true));

    if (showModal) {
        logoutTimer.start();
    }
    return (
        <Modal show={showModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>{`Your Session Will End In ${logoutAfterMS / 60 / 1000} Minutes.`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do You Want To Extend Your Session?</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { logoutTimer.stop(); handleLogout(false); }}>
                    Logout
            </Button>
                <Button onClick={() => { logoutTimer.stop(); handleExtendSession(); }}>
                    Extend Session
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default IdleTimeoutModal