import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { Card, Col, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import PieGraph from "./PieChart";
import BarGraph from "./BarChart";
import cloneDeep from"lodash/cloneDeep";

import axios from "../../utils/apiClient";

const componentName = "ECIPDashboard";

const patientURLCP =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-filter";
const dashboardURLCP =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/ytddashboard";

const DEFAULT_SEARCH_PARAMS = {
  filterParams: [],
  quickListName: "",
  sortParams: [],
  pageNumber: 1,
  pageSize: 20,
};

export const ECIPDashboard = () => {
  const auth = useAuth();

  const [organizations, setOrganizations] = useState([]);
  const [legend, setLegend] = useState(null);
  const [stats, setStats] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [searchParameters, setSearchParameters] = useState({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [memberships, setMemberships] = useState(null);
  const [sortedGaps, setSortedGaps] = useState(null);

  useEffect(() => {
    let sources = auth.getInputSources();
    async function getData() {
      const options = await axios.get(patientURLCP + "/practice-for-ytd");
      let orgIds = options.data.map((x) => x.value);
      const defaultOrg = {
        metadata: {},
        text: "ALL EHN",
        value: orgIds,
      };
      let organizationAarray = options.data;
      organizationAarray.unshift(defaultOrg);
      setOrganizations(organizationAarray);
      setSelectedOrganization(organizationAarray[0]);

      const legendResponse = await axios.post(
        dashboardURLCP + `/legend?inputSources=${sources}`
      );
      setLegend(legendResponse.data);
    }
    getData();
  }, []);

  useEffect(() => {
    async function updateStats() {

      let searchParametersClone = {...searchParameters};
        // searchParametersClone.filterParams = e;
        let searchParametersCloneBackend = cloneDeep(searchParametersClone);
        // async
        setSearchParameters(searchParametersClone);
      if (
          selectedOrganization &&
          searchParametersCloneBackend &&
          searchParametersCloneBackend.filterParams
      ) {
        const orgIdsObject = {
          comparison: "eq",
          displayValue: selectedOrganization.value,
          paramName: "organizations",
          paramValue: selectedOrganization.value,
        };
        searchParametersCloneBackend.filterParams.push(orgIdsObject);
      }
      const response = await axios.post(
        dashboardURLCP + `/dashboardstats`, searchParametersCloneBackend,
          {
            headers: {"Content-Type": "application/json"},
          });
      setStats(response.data);
      await sortMembers(response.data.membershipValues);
      await sortGaps(response.data.careGapStatsValues);
    }

    if (selectedOrganization) {
      updateStats();
    }
  }, [selectedOrganization]);

  const sortMembers = (dataObject) => {
    let newArray = [];
    let newObject = null;
    let namesFound = [];
    dataObject.map((obj, index) => {
        if (namesFound.includes(obj.healthProvider)) {
          if (index === dataObject.length - 1) {
            newObject.rows.push({ indicator: obj.indicator, value: obj.value });
            newArray.push(newObject);
          } else {
            newObject.rows.push({ indicator: obj.indicator, value: obj.value });
          }
        } else {
          if (namesFound.length) {
            newArray.push(newObject);
          }
          namesFound.push(obj.healthProvider);

          newObject = {};
          newObject.healthProvider = obj.healthProvider;
          newObject.rows = [{ indicator: obj.indicator, value: obj.value }];
        }
    });
    setMemberships(newArray);
  };

  const sortGaps = (dataObject) => {
    let tempArray = [];
    Object.keys(dataObject).map((key, index) => {
      let obj = {};
      obj = dataObject[key];
      obj.type = key;
      tempArray.push(obj);
    });

    let gridVals = [];
    tempArray.map((item) => {
      let values = item.gridValues;

      let newArray = [];
      let newObject = null;
      let namesFound = [];
      values.map((obj, index) => {
        if (namesFound.includes(obj.healthProvider)) {
          if (index === values.length - 1) {
            newObject.rows.push({ indicator: obj.indicator, value: obj.value });
            newArray.push(newObject);
          } else {
            newObject.rows.push({ indicator: obj.indicator, value: obj.value });
          }
        } else {
          if (namesFound.length) {
            newArray.push(newObject);
          }
          namesFound.push(obj.healthProvider);

          newObject = {};
          newObject.healthProvider = obj.healthProvider;
          newObject.rows = [{ indicator: obj.indicator, value: obj.value }];
        }
      });
      let type = item.type;
      newArray.unshift(type);
      gridVals.push(newArray);
    });
    setSortedGaps(gridVals);
  };

  return (
    <Container fluid className="p-4">
      <Row className="mb-5">
        <Col>
          {organizations.length && selectedOrganization ? (
            <>
              <Row className="mb-3">
                <Col></Col>
                <Col>{stats && stats.generatedTime && <p className="text-center">*Data last refreshed : {new Date(stats.generatedTime).toLocaleString('en-US', {timeZone: 'America/Phoenix'})}</p>}</Col>
                <Col className="text-end">
                  <label id={`${componentName}-OrganizationsLabel`} className="d-block">Organizations</label>
                  <Dropdown>
                    <Dropdown.Toggle id={`${componentName}-OrganizationsValue`} variant="secondary" className="me-0">
                      {selectedOrganization.text}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {organizations.map((org) => (
                        <Dropdown.Item
                          onClick={() => setSelectedOrganization(org)}
                        >
                          {org.text}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col>
                  {memberships && (
                    <>
                      <Card className="mb-4">
                        <Card.Body>
                          <h5 id={`${componentName}-MembershipAsOf`} className="fw-bold text-center mb-3">
                            Membership as of {stats.membershipAsOfDate}
                          </h5>
                          <Table id={`${componentName}-MembershipAsOfTable`} striped bordered hover>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Age 21 and Younger</th>
                                <th>Over Age 21</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {memberships.filter(item=>item.healthProvider !== "Total")
                                  .sort((e1, e2) => { return e1.healthProvider.localeCompare(e2.healthProvider) })
                                  .map((item) => (
                                <tr>
                                  <td>{item.healthProvider}</td>
                                  {item.rows.map((row) => (
                                    <td>{row.value}</td>
                                  ))}
                                </tr>
                              ))}
                              {memberships.filter(item=>item.healthProvider === "Total")
                                  .map((item) => (
                                      <tr>
                                        <td>{item.healthProvider}</td>
                                        {item.rows.map((row) => (
                                            <td>{row.value}</td>
                                        ))}
                                      </tr>
                                  ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </Col>
                <Col>
                  {stats && (
                    <Card className="mb-4">
                      <Card.Body>
                        <h5 id={`${componentName}-EDFreqUtilizers`} className="fw-bold text-center mb-3">
                          ED Frequent Utilizers
                        </h5>
                        <Table id={`${componentName}-EDFreqUtilizersTable`} striped bordered hover>
                          <thead>
                            <tr>
                              <th></th>
                              <th>3 or more visits in 12 months</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stats.edFrequentUtilizers.map((item) => (
                              <tr>
                                <td>{item.indicator}</td>
                                <td>{item.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
                <Col>
                <Card className="mb-4">
                <Card.Body>
                  <h5 id={`${componentName}-RiskAssignment`} className="fw-bold text-center mb-3">
                    Risk Assignment
                  </h5>
                  <span id={`${componentName}-RiskAssignmentChart`}>{stats && <PieGraph data={stats.riskAssignmentValues} />}</span>
                </Card.Body>
              </Card>
              </Col>
              </Row>


              {sortedGaps && sortedGaps.map((gap, idx) => <BarGraph idx={idx} data={gap} />)}

              {legend && legend.length ? (
                <Card className="mb-4">
                  <Card.Body>
                    <Table id={`${componentName}-GapsTable`} striped bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Care Gap</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {legend.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.careGap}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ) : null}
              <Card className="mb-4">
                <Card.Body>
                  <Table id={`${componentName}-GapsDescription`} striped bordered hover>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Open</td>
                        <td>
                          Current membership with gaps that need to be completed
                        </td>
                      </tr>
                      <tr>
                        <td>Ineligible/Excluded</td>
                        <td>
                          Gap was not closed before it expired (ADT within 7
                          days; UCDM/UCHN within 30 days; WC15 6 visits prior to
                          15 mo) or gaps where Exclusion button was selected
                        </td>
                      </tr>
                      <tr>
                        <td>Closed by CCD</td>
                        <td>
                          Gap originally marked for closure by CCD or other
                          provider office data files
                        </td>
                      </tr>
                      <tr>
                        <td>Closed by Claim/Lab/Quality</td>
                        <td>
                          Gap processed directly from open to closed via a
                          claim, lab or quality file
                        </td>
                      </tr>
                      <tr>
                        <td>Closed by Provider</td>
                        <td>Gap closed by User Interface</td>
                      </tr>
                      <tr>
                        <td>All Closed</td>
                        <td>
                          Total counts from the following columns: Ineligible,
                          Closed by CCD, Closed by Claim/Lab/Quality and Closed
                          by Practice
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Row className="mb-4">
                <Col></Col>
              </Row>
            </>
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ECIPDashboard;
