import React, { useEffect, useState } from 'react'
import { componentName } from '../utils';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const NavLink = styled.span`
  display: block;
  padding: .5rem 1rem;
  color #0d6efd;
  text-decoration: none;
`


export const FiltersResume = ({
  commonFilters,
  followUpFilters = [],
  patients,
  pagination,
  filtersPerRow = 3
}) => {
  const [elements, setElements] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters({...commonFilters, ...followUpFilters})
  }, [patients])

  useEffect(() => {
    const keys = Object.keys(filters);
    let filteredElements = [];

    if (keys.length > 0) {
      let filteredKeys = keys.filter(checkEmpty);

      filteredKeys.forEach(key => {
        let element = {};
        if (key === 'rangeDate') {
          element = {
            title: filters[key].paramName,
            content: `${filters[key].paramValue[0]} - ${filters[key].paramValue?.[1]}`
          }
        } else if (filters[key].paramValue.length > 1) {
          element = {
            title: filters[key].paramName,
            content: `${filters[key].displayValue.join(', ')}`
          }
        } else {
          element = {
            title: filters[key].paramName,
            content: filters[key].displayValue[0]
          }
        }
        filteredElements.push(element)
      });
      setElements(filteredElements)
    }
  }, [filters])

  function checkEmpty(key) {
    return filters[key].displayValue.length > 0 && filters[key].paramValue.length > 0
  }

  return (
    <div id={`${componentName}-filterResumeContainer`}>
      <Row>
      {
        elements.map(e => (
          <Col lg={12/filtersPerRow} key={e.title}>
            <p id={`${componentName}-${e.title}`}><b>{e.title}</b>: {e.content}</p>
          </Col>
        ))
      }
      </Row>
      <Row>
        <NavLink id={`${componentName}-filterResumeCount`}>
          Your search returned {pagination.count} members
        </NavLink>
      </Row>
    </div>
  )
}
