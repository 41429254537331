import React, { useEffect } from "react";
import {Switch, Route, useLocation, Redirect} from "react-router-dom";
import MainLayout from "./common/MainLayout";
import { useAuth } from "../context/authContext";
import { FLATTED_NAVIGATION_ITEMS, ROUTES } from "../utils/navigationItems";
import { SessionStorageUtil } from "../utils/localStorageUtil";
import { getProperties } from "../actions/constantsActions";

const MainView = () => {
    const currentPath = useLocation().pathname;
    const auth = useAuth();
    const maybeActiveView = FLATTED_NAVIGATION_ITEMS.find(
        (e) => currentPath === e.to
    );

    return (
        <MainLayout
            data-test="appComponent"
            activeViewName={maybeActiveView ? maybeActiveView.title : ""}
        >
            <Switch>
                {Object.values(ROUTES).map(({ exact, canAccess, path, component }) => {
                    if ((!canAccess || canAccess(auth)) && component) {
                        return (
                            <Route
                                exact={exact}
                                key={path}
                                path={path}
                                render={(props) => component(props)}
                            />
                        );
                    }
                    else {
                        if (auth.hasAuthority("ALL_PATIENTS_QUICK_LIST_ACCESS")) {
                            return (<Route exact={exact} key={path} path={path}
                                           render={() => <Redirect to={"/all_patients"}/>}/>)
                        }
                        else {
                            return (<Route exact={exact} key={path} path={path}
                                    render={() => <Redirect to={"/settings"}/>} />)
                        }
                    }
                })}

            </Switch>
        </MainLayout>
    );
};

export default MainView;
