export const initialState = {
    currentSpecialistReferralStep: null,
    specialities: [],
    isLoadingSpecialities: false,
    payers: [],
    isLoadingPayers: false,
    practices: [],
    associatedProviders: [],
    providers: [],
    genders: [],
    selectedSpeciality: {},
    selectedPayer: {},
    selectedPractice: {},
    selectedAssociatedProvider: {},
    selectedProvider: "",
    zipCode: "",
    selectedGender: {},
    isLoadingPractices: false,
    isLoadingAssociatedProviders: false,
    specialistReferralSteps: {
        step1: "STEP1",
        step2: "STEP2",
        step3: "STEP3",
    },
    visitTypes: [],
    selectedVisitType: null,
    selectedUrgency: null,
    note: "",
    uploadedFiles: [],
    icd10List: [{isLoading: false}],
    icd10Options: [],
    substanceAbuseICD10: null,
    isSpecialistReferralSaved: false,
}

export const actions = {
    SET_CURRENT_SPECIALIST_REFERRAL_STEP: "SET_CURRENT_SPECIALIST_REFERRAL_STEP",
    SET_SPECIALITIES: "SET_SPECIALITIES",
    SET_PAYERS: "SET_PAYERS",
    SET_SELECTED_SPECIALITY: "SET_SELECTED_SPECIALITY",
    SET_SELECTED_PAYER: "SET_SELECTED_PAYER",
    ASYNC_SEARCH_PRACTICE: "ASYNC_SEARCH_PRACTICE",
    ASYNC_SEARCH_ASSOCIATED_PROVIDER: "ASYNC_SEARCH_ASSOCIATED_PROVIDER",
    SET_PRACTICES: "SET_PRACTICES",
    TOGGLE_LOADING_PRACTICES: "TOGGLE_LOADING_PRACTICES",
    SET_SUBSTANCE_ABUSE_ICD10: "SET_SUBSTANCE_ABUSE_ICD10",
    SET_ASSOCIATED_PROVIDERS: "SET_ASSOCIATED_PROVIDERS",
    SET_PROVIDERS: "SET_PROVIDERS",
    TOGGLE_LOADING_ASSOCIATED_PROVIDERS: "TOGGLE_LOADING_ASSOCIATED_PROVIDERS",
    TOGGLE_LOADING_ICD10_OPTION: "TOGGLE_LOADING_ICD10_OPTION",
    SET_SELECTED_PRACTICE: "SET_SELECTED_PRACTICE",
    SET_SELECTED_ASSOCIATED_PROVIDER: "SET_SELECTED_ASSOCIATED_PROVIDER",
    SET_ZIP_CODE: "SET_ZIP_CODE",
    SET_GENDERS: "SET_GENDERS",
    SET_SELECTED_GENDER: "SET_SELECTED_GENDER",
    SET_DEFAULT_GENDER: "SET_DEFAULT_GENDER",
    INIT_SPECIALIST_REFERRAL_VARS: "INIT_SPECIALIST_REFERRAL_VARS",
    SET_SELECTED_PROVIDER: "SET_SELECTED_PROVIDER",
    SET_VISIT_TYPES: "SET_VISIT_TYPES",
    SET_SELECTED_VISIT_TYPE: "SET_SELECTED_VISIT_TYPE",
    SET_URGENCY_OPTIONS: "SET_URGENCY_OPTIONS",
    SET_SELECTED_URGENCY: "SET_SELECTED_URGENCY",
    SET_NOTE: "SET_NOTE",
    SET_UPLOADED_FILES: "SET_UPLOADED_FILES",
    SET_ICD10_OPTIONS: "SET_ICD10_OPTIONS",
    SET_SELECTED_ICD10_OPTION: "SET_SELECTED_ICD10_OPTION",
    ADD_MORE_ICD10: "ADD_MORE_ICD10",
    TOGGLE_SPECIALIST_REFERRAL_SAVED: "TOGGLE_SPECIALIST_REFERRAL_SAVED",
    TOGGLE_LOADING_SPECIALITIES: "TOGGLE_LOADING_SPECIALITIES",
    TOGGLE_LOADING_PAYERS: "TOGGLE_LOADING_PAYERS",
}
