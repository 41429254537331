import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Button,
  Col,
  Collapse,
  Container,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import {formatLocalDate, getDisplayValue} from '../../../../utils/util';
import {
  referral_types,
  status_referrals,
  urgency_referrals,
  visit_type_referrals
} from '../../../../utils/textValueLists';
import Modals from '../../../../components/modals';
import {ReferralModal} from '../../ReferraModal';
import axios from '../../../../utils/apiClient';
import DropDownMenu from '../../../caremgmt/worklist/components/DropDownMenu';
import {InfoCol, menuItems, StatusLabel, UrgencyLabel} from '../constants';
import {useReferralsContext} from '../context/Referrals.context';
import {setModalOpen} from '../../../../actions/manageVisitActions';
import {useDispatch} from 'react-redux';
import {getReferrals} from '../context/api';
import {mapReferralServicesToText} from "../referralUtils";
import useCboLink from '../../../../actions/hooks/useCboLink';
import { isCBOAccessAllowed } from '../../../caremgmt/worklist/constants';
import { useAuth } from '../../../../context/authContext';

const componentName = 'ReferralCard'

const ReferralCard = ({ referral, refreshReferrals, idx,patient, open, toggleIndividual, toggleIndividualOpen }) => {
  const [show, setShow] = useState(false);
  const [history, setHistory] = useState([]);
  const ref = useRef(null);
  const redirectID = sessionStorage.getItem('itemID');
  const auth = useAuth();
  const roles = auth.getRoles();

  const {
    setAccepted,
    setDeclined,
    setReferral,
    setCancelled,
    setPending,
    setCompleted,
    setPatientCancelled
  } = useReferralsContext();

  const dispatch = useDispatch();
  const [referComponent, getSamlResponse, setSamlResponse] = useCboLink(patient?.clientId, patient, referral?.referredTo?.name);

  useEffect(() => {
    if (referral) {
      if (referral?.urgency ==='CBO_REFERRAL' && open) {
        getSamlResponse((response) => setSamlResponse(response.data))
      }
    }
  }, [open])

  useEffect(() => {
    if (ref.current ) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      setTimeout(() => {
        toggleIndividualOpen()
        sessionStorage.removeItem('itemID');
      }, 2000);
    }
  }, [referral.id])

  useEffect(() => {
    open && getReferrals(referral?.id)
      .then(r => {
        setHistory(r?.data?.historyEvents)
      })
      .catch()
  }, [open])

  const getDownloadLinkSec = (val) => {
    let value = val;
    if (typeof val === "string") {
      value = JSON.parse(val).value;
    }
    const { fileName, fileId } = value;
    return (
      <a id={`${componentName}-infoDLLinkSecValue-${idx}`} href="#" onClick={() => getFileDownload(fileId, fileName)}>
        {fileName ? fileName : ""}
      </a>
    );
  };

  const getFileDownload = useCallback(async (event, id, filename) => {
    event.preventDefault();
    const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/file-upload";
    await axios
      .get(URL + `/file?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        var type = response.headers["content-type"];
        var blob = new Blob([response.data], { type });
        var link = document.createElement("a");
        var blobURL = window.URL.createObjectURL(blob);
        link.href = blobURL
        link.download = filename;
        link.dispatchEvent(new MouseEvent('click'));

        URL.revokeObjectURL(blobURL)
      })
      .catch();
  });

  const getDownloadLink = (fileId, fileName) => {
    return (
      <a id={`${componentName}-infoDDLinkValue-${idx}`} href="#" onClick={event => getFileDownload(event, fileId, fileName)}>
        {fileName ? fileName : ""}
      </a>
    );
  };

  const getReferralType = useCallback((label, value) => {
    if (label == null || label === "File" || label === "Attachments") {
      if (value.files) {
        return (
          value.files.length > 0 ?
          value.files.map((file, idx) => (
            <p id={`${componentName}-infoDLLink-${idx}`} className="m-0" key={file.fileId}>
              {file &&
                getDownloadLink(
                  file.fileMetadata.fileId,
                  file.fileMetadata.fileName
                )}
            </p>
          )) : '-'
        );
      } else if (Array.isArray(value) && value.length) {
        return value.map((file, idx) => (
          <p id={`${componentName}-infoDLLink-${idx}`} className="m-0">
            {getFileDownload(file.fileId, file.fileName)}
          </p>
        ));
      } else {
        return <p id={`${componentName}-infoDLLinkSec-${idx}`} className="m-0">{getDownloadLinkSec(value)}</p>;
      }
    }
    if (label === "Visit Type") {
      const vtValue = value?.visitType ? value.visitType : value;
      return vtValue ? getDisplayValue(visit_type_referrals, vtValue) : "-";
    }

    if (label === "Services Needed") {
      const snValue = value?.servicesNeeded ? value.servicesNeeded : value;
      return snValue?.length > 0 ? (
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip>{mapReferralServicesToText(snValue)}</Tooltip>}
          >
            <p
              id={`${componentName}-infoServicesNeeded-${idx}`}
              className="overflow-hidden"
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {mapReferralServicesToText(snValue)}
            </p>
          </OverlayTrigger>
      ) : (
        "-"
      );
    } else {
      return '-';
    }
  })

  const handleActions = (key) => {
    getReferrals(referral?.id)
      .then(r => {
        setReferral(r?.data)
      })
      .catch()
    switch (key) {
      case 'accepted':
        setAccepted(true);
        break;
      case 'declined':
        setDeclined(true);
        break;
      case 'cancelled':
        setCancelled(true);
        break;
      case 'pending':
        setPending(true);
        break;
      case 'completed':
        setCompleted(true);
        break;
      case'patient_cancelled':
        setPatientCancelled(true);
        break;
      default:
        break;
    }
    dispatch(setModalOpen(true));
  }

  const getTitle = () => {
    if (referral?.urgency ==='CBO_REFERRAL') {
      const title = getDisplayValue(urgency_referrals, referral?.urgency);
      return title ? title : "-";
    }

    return referral.referralType
      ? getDisplayValue(referral_types, referral.referralType)
      : "-";
  }

  const ClosedContent = React.memo(() => (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center card-header">
        <Button id={`${componentName}-ccTitle-${idx}`} onClick={() => toggleIndividual()} variant="link" className="text-start text-dark p-0 text-decoration-none btn btn-link w-50">
          <div className="d-flex align-items-center">
            {!open
              ? <i id={`${componentName}-ccPlus-${idx}`} className="fa-solid fa-chevron-right me-4"></i>
              : <i id={`${componentName}-ccMinus-${idx}`} className="fa-solid fa-chevron-down me-4"></i>
            }
            <div className="d-inline-block">
              <span id={`${componentName}-ccTitle-${idx}`} className="fs-5 fw-bold mb-1 d-block lh-1">{getTitle()}</span>
              <span id={`${componentName}-ccSubTitle-${idx}`} className="text-muted">{referral.referredBy.name ? referral.referredBy.name : "-"}</span>
            </div>
          </div>
        </Button>
        <div className="w-25">
          <UrgencyLabel id={`${componentName}-urgencyReferrals-${idx}`} className="badge badge-pill border-0" urgency={referral?.urgency}>
            {referral.status ? getDisplayValue(urgency_referrals, referral?.urgency) : "-"}
          </UrgencyLabel>
        </div>
        <div className="d-flex justify-content-end align-items-center w-25">
          <div className="text-end">
            <StatusLabel id={`${componentName}-statusReferrals-${idx}`} className="badge badge-pill border-0" urgency={referral?.status}>
              {referral.status ? getDisplayValue(status_referrals, referral?.status) : "-"}
            </StatusLabel>
          </div>
          <div className="text-end">
            <DropDownMenu id={`${componentName}-referralActions-${idx}`} onItemClick={(key) => handleActions(key.value)} items={menuItems} />
          </div>
        </div>
      </div>
    </Container>
  ));

  const OpenContent = React.memo(() => (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-referredFromLabel-${idx}`}>Referred From</span>
            </Col>
            <Col className="d-flex align-items-center">
            <span id={`${componentName}-referredFromValue-${idx}`}>{referral.referredBy.name ? referral.referredBy.name : "-"}</span>  
            </Col>
          </Row>
          {referral?.urgency === 'CBO_REFERRAL' && (
            <Row>
              <Col className="form-label col-form-label" sm={3}>
                <span id={`${componentName}-referredToLabel-${idx}`}>Referred To</span>
              </Col>
              <Col className="d-flex align-items-center">
              {(referral?.referredTo?.name === 'Uniteus' || referral?.referredTo?.name === 'Find Help') && isCBOAccessAllowed(roles) ?
              <>{referComponent()}</> :
              <span id={`${componentName}-referredFromValue-${idx}`}>{referral.referredBy.name ? referral.referredBy.name : "-"}</span>
              }
              </Col>
            </Row>
          )}
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-specialistLabel-${idx}`}>{referral.referralType === "SPECIALIST" ? 'Visit Type' : 'Services Needed'}</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-specialistValue-${idx}`}>{getReferralType(referral.referralType === "SPECIALIST" ? 'Visit Type' : 'Services Needed', referral)}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-locationLabel-${idx}`}>Location</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-locationValue-${idx}`}>{referral.referredPracticeAddress ? referral.referredPracticeAddress : "-"}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-diagnosisLabel-${idx}`}>Diagnosis</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-diagnosisLabel-${idx}`}>{referral.diagnosisICD10dto.length > 0 && referral?.diagnosisICD10dto?.[0]?.description?.length > 0 ? referral.diagnosisICD10dto[0].description : "-"}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-attachmentsLabel-${idx}`}>Attachments</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-attachmentsValue-${idx}`}>{getReferralType("Attachments", referral)}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <small className="fw-bold">Activity</small>
            </Col>
          </Row>
          {history?.length > 0 && history?.map(h => (
            <Row className="mb-3">
              <Col className="form-label col-form-label" sm={3}>
                <span id={`${componentName}-createdAtLabel-${idx}`}>{formatLocalDate(h?.createdAt)}</span>
              </Col>
              <Col>
                <span id={`${componentName}-referralStatusLabel-${idx}`} className="d-block">{getDisplayValue(status_referrals, h?.status)}</span>
                <span id={`${componentName}-referralStatusValue-${idx}`} className="d-block">{h?.note ? h.note : '-'}</span>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  ));

  return (
    <div ref = {redirectID && referral.id == redirectID ? ref : null}>
      <div>
        <div id={`${componentName}-ccClosedInfo-${idx}`}><ClosedContent/></div>
        <Collapse in={open}>
          <div id={`${componentName}-ccOpenInfo-${idx}`} className="mt-3"><OpenContent/></div>
        </Collapse>
      </div>
      <Modals
        show={show}
        onHide={() => setShow(false)}
        onCreate={() => setShow(false)}
        title={"Manage Referral"}
        titleid={"ManageReferral"}
        size="xl"
        hideFooter={true}
        completeBtn={"SAVE CHANGES"}
        body={
          <ReferralModal
            closeModal={() => setShow(false)}
            referralObject={referral}
            quickListType={
              referral.dataType === "sent"
                ? "REFERRALS_SENT"
                : "REFERRALS_RECEIVED"
            }
            updateParent={refreshReferrals}
          />
        }
      />
    </div>
  );
};

export default ReferralCard