import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPathToObj,
  getAllObjsByPath,
} from "../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import {LocalStorageUtil, SessionStorageUtil} from "../../utils/localStorageUtil";
import { CE_RESTORE_OBJ } from "./MainLayout";
import {getProperties} from "../../actions/constantsActions";

const componentName = "Navigation";

const SubTitle = ({ initialValue, getData }) => {
  const [value, setValue] = useState(initialValue);
  getData().then((data) => {
    setValue(data);
  });

  return (
    <span className="badge badge-pill bg-secondary text-white float-end">
      {value}
    </span>
  );
};

const getCurrentPath = (auth, history, currentPath) => {
  const restoreObj = LocalStorageUtil.getObj(CE_RESTORE_OBJ);
  if (currentPath === "/" && !restoreObj) { //default to landing page when no path defined
    let landingPage = auth.getLandingPage();
    landingPage = landingPage ? "/" + landingPage : "/all_patients"; //default to all_patients if no landing page
    history.push(landingPage);
  }
  return currentPath;
};

const deriveIsOpenAndActive = (menuItem, currentPath, level) => {
  return (
    menuItem.isOpen ||
    (currentPath &&
      level < currentPath.length &&
      currentPath[level] === menuItem)
  );
};

const MenuList = ({ menuItems, level, currentPath, setActiveMenu, activeMenu, propertiesToSideBar, index }) => {
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  //const [menuOptionClicked, setMenuOptionClicked] = useState('');

  const onClickMenuItem = (menuItem) => {
    if (menuItem.to) {
      setActiveMenu(menuItem.id);
      history.push(menuItem.to);
    }
    if (menuItem.onClick) {
      if (menuItem.useDispatch) {
        dispatch(menuItem.onClick());
      } else {
        menuItem.onClick();
      }
    }
  };

  return menuItems
    .filter(
      (menuItem) => menuItem && menuItem.canAccess && menuItem.canAccess(auth)
    )
    .filter((menuItem) => {
      if (menuItem.title === "Care Management" && menuItem.items && menuItem.items.length) {
        let length = menuItem.items.filter((subMenuItem) => subMenuItem && subMenuItem.canAccess && subMenuItem.canAccess(auth)).length;
        if (length > 0) {
          return true;
        } else {
          return false;
        }
      }
      else if (menuItem.title === 'Schedule' && propertiesToSideBar["react.properties.scheduleQL.feature.enabled"] != true) {
        return false
      } else {
        return true;
      }
    })
    .map((menuItem, index) => {
      return (
        <>
          {menuItem.items || menuItem.onSelect || menuItem.onLoad ? (
            menuItem.useDispatch ? (
              <StatefulMenu
                menuItem={menuItem}
                level={level}
                currentPath={currentPath}
                index={index}
                setActiveMenu={setActiveMenu}
                activeMenu={activeMenu}
              />
            ) : (
              <Menu
                setActiveMenu={setActiveMenu}
                activeMenu={activeMenu}
                menuItem={menuItem}
                level={level}
                currentPath={currentPath}
                index={index}
              />
            )
          ) : (
            <li key={`${componentName}-${menuItem.id}-navItem-${level}-${index}`} id={`${componentName}-${menuItem.id}-navItem-${level}-${index}`} active={deriveIsOpenAndActive(menuItem, currentPath, level)} level={level} className={`nav-item ${menuItem.id === activeMenu ? "active" : null}`}>
              <a id={`${componentName}-${menuItem.id}-${index}`} onClick={() => onClickMenuItem(menuItem)} className={`nav-link px-0 ${menuItem.id === activeMenu ? "active" : null}`}>
                {menuItem.icon && <i className={`fa-regular ${menuItem.icon}`} />} <span className="nav-main">{menuItem.title}</span>
              </a>
              {menuItem.subTitle && (
                <SubTitle
                  className="sub-title"
                  initialValue="..."
                  getData={() => menuItem.subTitle()}
                ></SubTitle>
              )}
            </li>
          )}
        </>
      );
    });
};

const Menu = ({ menuItem, level, currentPath, activeMenu, setActiveMenu, index }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(() =>
    deriveIsOpenAndActive(menuItem, currentPath, level)
  );

  const openCloseMenu = (menuItem) => {
    if (menuItem.to) {
      history.push(menuItem.to);
      setActiveMenu(menuItem.id);
    }
  };

  return (
    <>
      {/* Menu Header Dropdown */}
      <li key={`${componentName}-${menuItem.id}-navHeader-${level}-${index}`} id={`${componentName}-${menuItem.id}-navHeader-${level}-${index}`} className={menuItem.id === activeMenu ? "active" : null}>
        <a onClick={() => openCloseMenu(menuItem)} id={`${componentName}-menuHeader-${menuItem.id}`} data-bs-toggle="collapse" className="nav-link px-0">
          {menuItem.icon && <i id={`${componentName}-menuIcon-${menuItem.id}`} className={`fa-regular ${menuItem.icon}`} />}
          <span id={`${componentName}-${menuItem.id}`}>{menuItem.title}</span>
          {menuItem.subTitle && (
            <SubTitle
              className="sub-title"
              initialValue="..."
              getData={() => menuItem.subTitle()}
            ></SubTitle>
          )}
          {isOpen ? (
            <i id={`${componentName}-menuArrow-${menuItem.id}`} className="fa-regular fa-chevron-down" onClick={() => setIsOpen(!isOpen)}/>
          ) : (
            <i id={`${componentName}-menuArrow-${menuItem.id}`} className="fa-regular fa-chevron-right" onClick={() => setIsOpen(!isOpen)}/>
          )}
        </a>
        <ul key={`${componentName}-${menuItem.id}-list-${level}-${index}`} id={`${componentName}-${menuItem.id}-list-${level}-${index}`} className="collapse show nav flex-column ms-1" data-bs-parent={`#${componentName}-menuHeaderOpen-${menuItem.id}`}>
          {isOpen ? (
            <MenuList
              menuItems={menuItem.items}
              level={level + 1}
              currentPath={currentPath}
              setActiveMenu={setActiveMenu}
              activeMenu={activeMenu}
              index={index}
            />
          ) : (
            <div></div>
          )}
        </ul>
      </li>
    </>
  );
};

const StatefulMenu = ({ menuItem, level, currentPath, activeMenu, setActiveMenu, index }) => {
  const menuItemData = useSelector(
    (reduxState) => reduxState[menuItem.reducerName]
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(() =>
    deriveIsOpenAndActive(menuItem, currentPath, level)
  );

  useEffect(() => {
    dispatch(menuItem.onLoad());
  }, []);

  let menuItems = menuItemData;

  if (menuItem.filterMenuItems) {
    menuItems = menuItem.filterMenuItems(menuItems);
  }

  return (
    <>
      {menuItems ? (
        <MenuList
          menuItems={menuItems}
          level={level + 1}
          currentPath={currentPath}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

const Navigation = ({ menuItems }) => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [propertiesToSideBar, setPropertiesToSideBar] = useState({});
  const currentPath = getCurrentPath(auth, history, location.pathname);

  const isPathMatch = (urlPattern, url) => {
    const urlParts = url.split("uuid");
    if (!url.startsWith("/#!") && urlParts && urlParts.length === 2) {
      url = urlParts[0] + "uuid" + urlParts[1];
    }
    return urlPattern === url;
  };

  const currentNavPath = getPathToObj(menuItems, (propertyName, level, obj) =>
    isPathMatch(obj.to, currentPath)
  );

  let currentNavPathObjs = [];
  if (currentNavPath && currentNavPath.length) {
    currentNavPathObjs = getAllObjsByPath(menuItems, currentNavPath[0]);
  }

  useEffect(() => {
    initProperties()
  }, [])

  const initProperties = async () => {
    let res = await getProperties();
    SessionStorageUtil.setObj("properties", res.data);
    if (res.data) {
      setPropertiesToSideBar(res.data)
    }
  }


  return (
    <ul className="nav nav-pills flex-column mb-sm-auto mt-4 mb-0 align-items-center align-items-sm-start">
      <MenuList
        menuItems={menuItems}
        level={0}
        currentPath={currentNavPathObjs}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        propertiesToSideBar={propertiesToSideBar}
      />
    </ul>
  );
};

export default Navigation;
