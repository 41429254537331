import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Row, Col, Card, Button, Form} from "react-bootstrap";
import ErrorToast from "../../common/ErrorToast";
import Upload from "../../../components/Upload";

import "react-bootstrap-typeahead/css/Typeahead.css";

import {
    updateSystemFile, getFileTypes, getFiles
} from "../../../actions/admin/systemFileActions";
import SuccessToast from "../../common/SuccessToast";
import {Table} from "../../../components/tables";
import {SortableTh} from "../../../components/SortableTh";
import {formatDateTime} from "../../../utils/util";
import isEmpty from "is-empty";

const componentName = "UploadSystemFile";

export const UploadSystemFile = () => {
    const isEmpty = require("is-empty");
    const dispatch = useDispatch();
    const { fileTypes } = useSelector((state) => state.systemFile);
    const { files } = useSelector((state) => state.systemFile);
    const errors = useSelector((state) => state.errors);
    const success = useSelector(state => state.success);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileType, setFileType] = useState("");

    useEffect(() => {
        (async function fetchFileTypes() {
            await dispatch(getFileTypes(setFileType));
        })();
    }, []);

    useEffect(() => {
        if (!isEmpty(fileType)) {
            (async function fetchFiles() {
                await dispatch(getFiles(fileType));
            })();
        }
    }, [fileType]);

    const setFileMethod = (file) => {
        if(file && file.name){
            setFileName(file.name)
        }
        const fileReader = new FileReader();
        if (fileReader && file) {
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = function () {
                setFile(fileReader.result)
            };
        }
    };

    const changeFileType = (fileType) => {
        setFileType(fileType);
    };

    return (
        <Row className="container-fluid p-3">
            <Col>
                <Card>
                    <Card.Body>
                        <Row>
                            <Form.Label>
                                <span id={`${componentName}-fileTypeLabel`}>File Type</span>
                            </Form.Label>
                            <select
                                className="form-select"
                                name="fileTypeValue"
                                onChange={(e) => changeFileType(e.target.value)}
                                id={`${componentName}-fileTypeValue`}
                            >
                                {fileTypes.map((entry) => <option value={entry.value}>{entry.text}</option>)}
                            </select>
                        </Row>
                        <Row>
                            <Col>
                                <Upload
                                    id={`${componentName}-uploadFile`}
                                    fileName={fileName}
                                    setFile={setFileMethod}
                                    accept="application/pdf"
                                >
                                </Upload>
                                {errors && errors.message && <ErrorToast/>}
                                {success && success.message && <SuccessToast />}
                                <div>&nbsp;</div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        id={`${componentName}-confirmButton`}
                                        variant="primary"
                                        disabled={isEmpty(file)}
                                        onClick={() => dispatch(updateSystemFile(fileType, file, fileName))}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        {!files || !files.length ? null : (
                        <Row>
                            <Col>
                                <Table className="table-bordered">
                                    <thead className="table-light">
                                    <tr>
                                        <SortableTh columnName="Uploaded On" id={`${componentName}-uploadedOnColLabel`}/>
                                        <SortableTh columnName="Uploaded By" id={`${componentName}-uploadedByColLabel`}/>
                                        <SortableTh columnName="File Name" id={`${componentName}-fileNameColLabel`}/>
                                    </tr>
                                    </thead>
                                    <tbody className="border-top-0">
                                    {files.map((file, idx) => {
                                        return (
                                            <tr>
                                                <td id={`${componentName}-uploadedOnValue-${idx}`}>{file.created ? formatDateTime(file.created) : null}</td>
                                                <td id={`${componentName}-uploadedByValue-${idx}`}>{file.creator ? file.creator : null}</td>
                                                <td id={`${componentName}-fileNameValue-${idx}`}>{file.fileName ? file.fileName : null}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
