import React, { useState } from 'react';
import '../styles/Filter.css';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useCMWorklistContext } from '../../../../views/caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context';

function Filter({ header, index, onToggle = () => {}, children, componentName, idFragment }) {
  const context = useCMWorklistContext();

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (context?.activeRole?.leadId) {
      const element = document.getElementById(`collapse-${index}`)
      if (element && collapsed) {
        setCollapsed(false)
        onToggle(false)
        new window.bootstrap.Collapse(element).hide()
      }
    }
  }, [context?.activeRole?.leadId])

  return (
    <>
      <Row xl={1}>
        <Col id={`${componentName}-${idFragment}-filter`} className="border-bottom p-0">
        <div className='accordion-item'>
          <h2
            className='accordion-header'
            id={`heading-${index}`}
            onClick={() => {
              setCollapsed(!collapsed);
              onToggle(!collapsed)
            }}
          >
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#collapse-${index}`}
              aria-expanded='true'
              aria-controls={`collapse-${index}`}
              id={`accordion-${index}`}
            >
              {!collapsed
                ? <i className='fa fa-chevron-right' aria-hidden='true'></i>
                : <i className='fa fa-chevron-down' aria-hidden='true'></i>
              }{header}
            </button>
          </h2>
          <div
            id={`collapse-${index}`}
            className='accordion-collapse collapse'
            aria-labelledby={`heading-${index}`}
            data-bs-parent={`#accordion-${index}`}
          >
            <div className='accordion-body'>
              {children}
            </div>
          </div>
        </div>
        </Col>
      </Row>
    </>
  )
}

export default Filter