import brandOrg from "./admin/brandingReducer";
import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import referralSettings from "./admin/referralSettingsReducer";
import userManagementReducer from "./admin/userManagementReducer";
import practiceInformationReducer from "./admin/practiceInformationReducer";
import successReducer from "./successReducer";
import broadcastMessengerReducer from './broadcastMessengerReducer';
import systemFileReducer from './admin/systemFileReducer';
import messageReducer from "./admin/message/messageReducer";
import populationSearchReducer from './populationSearch/populationSearchReducer';
import patientSearchReducer from './patientSearch/patientSearchReducer';
import gapDocumentationReducer from "./admin/gapDocumentationReducer";
import fileUploadReducer from "./fileUploadReducer";
import navigationReducer from './navigation/navigationReducer';
import orderCustomListReducer from './modal/orderCustomListReducer';
import manageVisitReducer from "./manageVisitReducer";

export default combineReducers({
  errors: errorReducer,
  success: successReducer,
  referralSettings: referralSettings,
  userManagement: userManagementReducer,
  practiceInformation: practiceInformationReducer,
  brandOrg: brandOrg,
  gapDocumentation: gapDocumentationReducer,
  systemFile : systemFileReducer,
  broadcastMessenger: broadcastMessengerReducer,
  messages: messageReducer,
  populationSearch: populationSearchReducer,
  fileUpload: fileUploadReducer,
  patients: patientSearchReducer,
  navigation: navigationReducer,
  orderCustomListModal: orderCustomListReducer,
  manageVisitReducer: manageVisitReducer
});
