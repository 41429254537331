import React from 'react'
import { columns, componentName } from '../utils'

export const ReportingTable = ({ patients }) => {
  return (
    <div className="table-responsive text-nowrap overflow-auto">
      <table className="table table-bordered table-hover">
        <thead className="table-light fixedHead">
          <tr>
            {
              columns.map((column, idx) => (
                <th key={`column-${idx}`} id={`${componentName}-${column}`} scope="col">
                  {column}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {patients.length > 0 &&
            patients.map((p, idx) => (
              <tr key={`${p?.memberId}-${idx}`}>
                <td>{p?.memberFullName}</td>
                <td>{p?.ehnId}</td>
                <td>{p?.memberId}</td>
                <td>{p?.programName}</td>
                <td
                  title={p?.programLead}
                  style={{
                    maxWidth: "150px",
                     overflow: "hidden",
                     textOverflow: "ellipsis"
                  }}
                >
                  {p?.programLead}
                </td>
                <td>{p?.programStatus}</td>
                <td>{p?.payer}</td>
                <td>{p?.lineOfBusiness?.toUpperCase()}</td>
                <td>{p?.riskLevel}</td>
                <td>{p?.interventionType}</td>
                <td>{p?.interventionOutcome}</td>
                <td>{p?.interventionChannel}</td>
                <td>{p?.interventionSource}</td>
                <td>{p?.providerTin}</td>
                <td>{p?.providerName}</td>
                <td>{p?.practiceName}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
