export const initialState = {
    username: '',
    password: '',
    termsRequired: false,
    latestLoginFailed: false,
    error: '',
    loading: false
};

export const actions = {
    SELECT_USERNAME: 'SELECT_USERNAME',
    SELECT_PASSWORD: 'SELECT_PASSWORD',
    SELECT_TERMS_REQUIRED: 'SELECT_TERMS_REQUIRED',
    SELECT_LOGIN_FAILED: 'SELECT_LOGIN_FAILED',
    SELECT_ERROR: 'SELECT_ERROR',
    SET_USERNAME: 'SET_USERNAME',
    SET_LOADING: 'SET_LOADING',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_TERMS_REQUIRED: 'SET_TERMS_REQUIRED',
    SET_LOGIN_FAILED: 'SET_LOGIN_FAILED',
    SET_ERROR: 'SET_ERROR'
};