import axios from "../utils/apiClient";

const patientFilterBaseURL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-filter/";

const patientFilterGeneric = (text, endpointUrl, patientId, includeAll= false, searchInFullName = false) => {
  let url =
    patientFilterBaseURL + endpointUrl + (text ? "&text=" + text : "")
    + (patientId ? "&patientId=" + patientId : "")
    + (includeAll ? "&includeAll=" + includeAll : "")
    + (searchInFullName ? "&searchInFullName=" + searchInFullName : "");
  url = url.replace("&", "?");
  return axios.get(url, {
    headers: {},
  });
};

const patientFilterRiskLevel = (text) => {
  return patientFilterGeneric(text, "risk-level");
};

const patientFilterChronicCondition = (text) => {
  return patientFilterGeneric(text, "chronic-condition");
};

const patientFilterConditionStatus = (text) => {
  return patientFilterGeneric(text, "condition-status");
};

const patientFilterPayer = (text, includeAll = false) => {
  return patientFilterGeneric(text, "payer", null, includeAll);
};

const patientFilterPCPPractice = (text) => {
  return patientFilterGeneric(text, "practice");
};

const patientFilterPCP = (text, searchInFullName = false) => {
  return patientFilterGeneric(text, "pcp", null, false, searchInFullName);
};

const patientFilterADTDisposition = (text) => {
  return patientFilterGeneric(text, "adt-disposition");
};

const patientFilterADTByFacility = (text) => {
  return patientFilterGeneric(text, "adt-by-facility");
};

const patientFilterCareCoordinator = (text) => {
  return patientFilterGeneric(text, "care-coordinator");
};

const patientFilterCity = (text) => {
  return patientFilterGeneric(text, "city");
};

const patientFilterCarePathCoordinator = (text) => {
  return patientFilterGeneric(text, "care-path-coordinator");
};

const patientFilterCarePath = (text) => {
  return patientFilterGeneric(text, "care-path");
};

const patientFilterPatients = (text) => {
  return patientFilterGeneric(text, "patients");
};

const patientFilterPatientStatus = (text) => {
  return patientFilterGeneric(text, "patientStatus");
};

const patientFilterState = (text) => {
  return patientFilterGeneric(text, "state");
};

const patientFilterZipCode = (text) => {
  return patientFilterGeneric(text, "zip-code");
};

const patientFilterMemberWorklist = (text) => {
  return patientFilterGeneric(text, "member-worklists");
};

const patientFilterReferralSentStatus = (text) => {
  return patientFilterGeneric(text, "referral-sent-status");
};

const patientFilterReferralReceivedStatus = (text) => {
  return patientFilterGeneric(text, "referral-received-status");
};

const patientFilterReferralUrgency = (text) => {
  return patientFilterGeneric(text, "referral-urgency");
};

const patientFilterValidation = (text) => {
  return patientFilterGeneric(text, "opportunity-suspect-validation");
};

const patientFilterOpportunityDefinition = (text) => {
  return patientFilterGeneric(text, "opportunity-definition");
};

const patientFilterOpportunityStatus = (text) => {
  return patientFilterGeneric(text, "opportunity-status");
};

const patientFilterSuspectStatus = (text) => {
  return patientFilterGeneric(text, "suspect-status");
};

const patientFilterMeasureYear = (text) => {
  return patientFilterGeneric(text, "measure-year");
};

const patientFilterWC15Approved = (text) => {
  return patientFilterGeneric(text, "wc15-approved");
};

const patientFilterLabGroup = (text, patientId) => {
  return patientFilterGeneric(text, "lab-groups", patientId);
};

const patientFilterLabDescriptions = (text, patientId) => {
  return patientFilterGeneric(text, "lab-descriptions", patientId);
};

const patientFilterMsaRegions = (text) => {
  return patientFilterGeneric(text, "provider-msa");
};

const patientFilterLineOfBusiness = (text) => {
  return patientFilterGeneric(text, "line-of-business");
};

const patientFilterEmpowerReferralStatus = (text) => {
  return patientFilterGeneric(text, "care-empower-referral-status");
};

const patientFilterProvider = (text) => {
  return patientFilterGeneric(text, "provider");
};

export {
  patientFilterLabDescriptions,
  patientFilterLabGroup,
  patientFilterRiskLevel,
  patientFilterPayer,
  patientFilterPCPPractice,
  patientFilterPCP,
  patientFilterADTDisposition,
  patientFilterADTByFacility,
  patientFilterCareCoordinator,
  patientFilterCity,
  patientFilterCarePathCoordinator,
  patientFilterCarePath,
  patientFilterPatients,
  patientFilterPatientStatus,
  patientFilterState,
  patientFilterZipCode,
  patientFilterMemberWorklist,
  patientFilterReferralReceivedStatus,
  patientFilterReferralSentStatus,
  patientFilterReferralUrgency,
  patientFilterValidation,
  patientFilterOpportunityDefinition,
  patientFilterOpportunityStatus,
  patientFilterSuspectStatus,
  patientFilterMeasureYear,
  patientFilterWC15Approved,
  patientFilterChronicCondition,
  patientFilterConditionStatus,
  patientFilterMsaRegions,
  patientFilterLineOfBusiness,
  patientFilterEmpowerReferralStatus,
  patientFilterProvider
};
