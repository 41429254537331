import React, {createContext, useContext, useReducer} from 'react';
import {useDispatch} from "react-redux";

import {initialState} from './actions';
import {reducer} from './Visit.reducer';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from "./setters";
import {updateMany} from "./api";
import {
  useEQHToastContext
} from "../../../../components/toast/EQHToast.context";

const VisitContext = createContext();

const VisitProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const {removeNotification, setNotification} = useEQHToastContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const callbacks = {
    updateMany: async (careGaps) => {
      removeNotification();
      toggles.toggleIsSavingActivities(true)
      return await updateMany(careGaps)
      .then((res) => {
        toggles.toggleIsSavingActivities(false)
        setters.clearUnsavedVisitsMap();
        setNotification('Successful', 'Updated successfully');
      })
      .catch((err) => {
        toggles.toggleIsSavingActivities(false)
        setNotification('Error',
          (err?.response?.data?.message || "Error while updating visits"));
      });
    },
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <VisitContext.Provider value={value}>
      {children}
    </VisitContext.Provider>
  )
};
const useVisitContext = () => useContext(VisitContext);

export {
  VisitProvider,
  useVisitContext
}