import React from 'react'
import {
  PatientDetailsEdit,
  AddInternalNote
} from './modalsComponents'

const Modals = () => {
  return (
    <>
      <PatientDetailsEdit />
      <AddInternalNote />
    </>
  )
}

export default Modals