import {Button, Col, Modal, Row} from "react-bootstrap";
import React from "react";


const ConfirmationModal = ({
    show,
    bodyMessage,
    cancelButtonLabel,
    acceptButtonLabel,
    onAccept,
    onCancel
}) => {
    return (
        <Modal size="md" centered show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className="d-block">{bodyMessage}</label>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    {cancelButtonLabel}
                </Button>
                <Button variant="primary" onClick={onAccept}>
                    {acceptButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal