import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Button, Form } from 'react-bootstrap';

import { isArray } from "lodash";
import { format } from "date-fns";
import { EQHDatePicker } from "../../../../components/datePicker";
import { isObjEmpty, arrayToKVP, formatDateVanilla } from "../../../../utils/util";
import { DropdownComponent } from "../../../../components/DropdownComponent";
import { newHospitalEventsDispositionList } from "../constants";

import { useHospitalEventsContext } from "../context/HospitalEvents.context";
import { useEQHToastContext } from "../../../../components/toast/EQHToast.context";
import { isEmpty, trim, get } from "lodash"

const componentName = 'HospitalEventEditorModal';

/**
 * Modal component for editing or adding new hospital events. It provides
 * a form within a modal dialog, allowing users to submit hospital event data.
 *
 * @param {string} patientId - The id of the patient for whom the hospital event is being added or edited.
 */
const HospitalEventEditorModal = ({ patientId }) => {

  const {
    isNewHospitalEventModalActive,
    isEditHospitalEventModalActive,
    toggleNewHospitalEventModalActive,
    toggleEditHospitalEventModalActive,
    addHospitalEvent,
    hospitalEventToEdit,
    editHospitalEvent,
    hospitalEventsList
  } = useHospitalEventsContext();

  const createEmptyHospitalEvent = () => {
    return {
      eventId: null,
      admitDate: null,
      dischargeDate: null,
      disposition: null,
      admitDiagnosis: { code: "", description: "" },
      dischargeDiagnosis: { code: "", description: "" },
      facility: "",
      chiefComplaint: "",
      notes: "",
      patientId: patientId,
    }
  }

  const [hospitalEvent, setHospitalEvent] = useState(createEmptyHospitalEvent());
  const [editMode, setEditMode] = useState(false);
  const { removeNotification, setNotification } = useEQHToastContext();

  const [minYear, setMinYear] = useState(() => {
    const dt = new Date()
    dt.setFullYear(dt.getFullYear() - 1)
    return dt;
  });

  const show = isNewHospitalEventModalActive || isEditHospitalEventModalActive;

  const resetModal = () => {
    setHospitalEvent(createEmptyHospitalEvent());
    setEditMode(false);
    removeNotification();
  };

  const closeModal = () => {
    toggleEditHospitalEventModalActive(false);
    toggleNewHospitalEventModalActive(false);
  };

  const handleChange = (key, value) => {
    let errors = {};
    let changeValue;

    switch (key) {
      case "admitDate":
      case "dischargeDate":
        if (value.error) {
          errors[key] = value.error;
        } else {
          changeValue = value.strDate;
        }
        break;
      case "admitDiagnosis":
      case "dischargeDiagnosis":
        changeValue = { code: "", description: value };
        break;
      case "disposition":
        changeValue = value.value
        break;
      default:
        changeValue = value;
        break;
    }

    if (!isObjEmpty(errors)) {
      setNotification("Error", Object.values(errors));
    } else {
      setHospitalEvent(hospitalEvent => ({
        ...hospitalEvent,
        [key]: changeValue
      }));
    }
  };

  const onSubmit = () => {
    const errors = formValidation(hospitalEvent);

    if (errors) {
      setNotification("Error", Object.values(errors));
    } else {
      if (!editMode) {
        addHospitalEvent(hospitalEvent);
      } else {
        editHospitalEvent(hospitalEvent);
      }
    }
  };

  const isAdmitGreaterThanDischargeDate = (admitDate, dischargeDate) => {
    return new Date(admitDate) > new Date(dischargeDate);
  };

  const formValidation = (hospitalEvent) => {
    let errors = {};
    const admitDate = hospitalEvent.admitDate;
    const dischargeDate = hospitalEvent.dischargeDate;

    if (isAdmitGreaterThanDischargeDate(admitDate, dischargeDate)) {
      errors["admitDate"] = "Admit Date must be less than or equal to Discharge Date";
    }

    return isObjEmpty(errors) ? null : errors;
  };

  const checkForMandatoryFields = () => {
    return isEmpty(trim(get(hospitalEvent, "admitDate"))) ||
      isEmpty(trim(get(hospitalEvent, "dischargeDate"))) ||
      isEmpty(trim(get(hospitalEvent, "disposition")));
  };

  const getKVPValueWithDefault = (fieldValuesKVP, key, options = {dateFormat: null, isDate: false}) => {
    const value = fieldValuesKVP[key] || "";

    if(isArray(value) && key === "disposition") {
      return value[1];
    }
    else if (isArray(value) && options.isDate && options.dateFormat) {
      return formatDateVanilla(value[1], options.dateFormat, true);
    }

    return value;
  };

  const getHospitalEventToEditInfo = () => {
    const hospitalEventToEditInfo = hospitalEventsList[hospitalEventToEdit.idx];
    const fieldValuesKVP = arrayToKVP(hospitalEventToEditInfo.fieldValues, "caption", "value");

    return {
      eventId: hospitalEventToEditInfo.eventId,
      adtEventContainerId: hospitalEventToEditInfo.originalDocId,
      disposition: getKVPValueWithDefault(fieldValuesKVP, "disposition"),
      admitDate: getKVPValueWithDefault(fieldValuesKVP, "admitdate", {dateFormat: "yyyy-MM-dd", isDate: true}),
      dischargeDate: getKVPValueWithDefault(fieldValuesKVP, "dischargedate", {dateFormat: "yyyy-MM-dd", isDate: true}),
      facility: getKVPValueWithDefault(fieldValuesKVP, "facility"),
      chiefComplaint: getKVPValueWithDefault(fieldValuesKVP, "chiefcomplaint"),
      notes: getKVPValueWithDefault(fieldValuesKVP, "notes"),
      dischargeDiagnosis: {
        description: getKVPValueWithDefault(fieldValuesKVP, "dischargediagnosis")
      },
      admitDiagnosis: {
        description: getKVPValueWithDefault(fieldValuesKVP, "admitdiagnosis")
      },
      patientId: patientId
    };
  };

  useEffect(() => {
    if (!isNewHospitalEventModalActive) {
      resetModal();
    }
  }, [isNewHospitalEventModalActive]);

  useEffect(() => {
    if (isEditHospitalEventModalActive) {
      const hospitalEventToEditInfo = getHospitalEventToEditInfo();
      setHospitalEvent(hospitalEventToEditInfo);
      setEditMode(true);
    } else {
      resetModal();
    }
  }, [isEditHospitalEventModalActive]);

  return (
    <Modal
      id={`${componentName}-Modal`}
      onHide={closeModal}
      show={show}
      size="md"
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-ModalTitle`}>
          {editMode ? "Edit" : "New"} Hospital Event
        </Modal.Title>
        <button
          id={`${componentName}-closeButton`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        />
      </Modal.Header>
      <Modal.Body className="overflow-y-auto">
        <Row>
          <Col sm={7}>
            <Form.Group
              id={`${componentName}-admitDate`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-admitDateLabel`}>
                Admit Date<span className="text-danger">*</span>
              </Form.Label>
              <EQHDatePicker
                id={`${componentName}-admitDateValue`}
                value={hospitalEvent.admitDate}
                onChange={(e) => {
                  e && handleChange("admitDate", e);
                }}
                max={format(new Date(), "yyyy-MM-dd")}
                min={format(minYear, "yyyy-MM-dd")}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group
              id={`${componentName}-admitDiagnosis`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-admitDiagnosisLabel`}>
                Admit Diagnosis
              </Form.Label>
              <Form.Control
                id={`${componentName}-admitDiagnosisValue`}
                type="text"
                onChange={(e) => {
                  e && handleChange("admitDiagnosis", e.target.value);
                }}
                value={hospitalEvent.admitDiagnosis.description}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={7}>
            <Form.Group
              id={`${componentName}-dischargeDate`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-dischargeDateLabel`}>
                Discharge Date<span className="text-danger">*</span>
              </Form.Label>
              <EQHDatePicker
                id={`${componentName}-dischargeDateValue`}
                value={hospitalEvent.dischargeDate}
                onChange={(e) => {
                  e && handleChange("dischargeDate", e);
                }}
                max={format(new Date(), "yyyy-MM-dd")}
                min={format(minYear, "yyyy-MM-dd")}
              />
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group
              id={`${componentName}-dischargeDiagnosis`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-dischargeDiagnosisLabel`}>
                {" "}
                Discharge Diagnosis
              </Form.Label>
              <Form.Control
                id={`${componentName}-dischargeDiagnosisValue`}
                type="text"
                onChange={(e) => {
                  e && handleChange("dischargeDiagnosis", e.target.value);
                }}
                value={hospitalEvent.dischargeDiagnosis.description}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              id={`${componentName}-facility`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-facilityLabel`}>
                Facility
              </Form.Label>
              <Form.Control
                id={`${componentName}-facilityValue`}
                type="text"
                onChange={(e) => {
                  e && handleChange("facility", e.target.value);
                }}
                value={hospitalEvent.facility}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              id={`${componentName}-chiefComplaint`}
              className="mb-3"
            >
              <Form.Label id={`${componentName}-chiefComplaintLabel`}>
                Chief Complaint
              </Form.Label>
              <Form.Control
                id={`${componentName}-chiefComplaintValue`}
                type="text"
                onChange={(e) => {
                  e && handleChange("chiefComplaint", e.target.value);
                }}
                value={hospitalEvent.chiefComplaint}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              id={`${componentName}-dischargeDisposition`}
              className="mb-3"
            >
              <DropdownComponent
                name="disposition"
                list={newHospitalEventsDispositionList}
                handleInputChange={handleChange}
                label="Discharge Disposition"
                required={true}
                isOpen={true}
                val={hospitalEvent.disposition}
                disabled={editMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Col>
          <Form.Group
            id={`${componentName}-notes`}
            className="mb-3"
          >
            <Form.Label id={`${componentName}-notesLabel`}>
              Notes
            </Form.Label>
            <Form.Control
              id={`${componentName}-notesValue`}
              as="textarea"
              rows={5}
              onChange={(e) => {
                e && handleChange("notes", e.target.value);
              }}
              value={hospitalEvent.notes}
              disabled={editMode}
            />
          </Form.Group>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          id={`${componentName}-cancelButton`}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          id={`${componentName}-submitButton`}
          disabled={checkForMandatoryFields()}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HospitalEventEditorModal;
