import React from "react";
import Modals from "./Modals";
import { HospitalEvents } from "./components";
import { HospitalEventsProvider } from "./context/HospitalEvents.context";

const HospitalEventsTab = ({ patient }) => {
  return (
    <HospitalEventsProvider>
      <HospitalEvents patient={patient} />
      <Modals patient={patient} />
    </HospitalEventsProvider>
  )
}

export default HospitalEventsTab;
