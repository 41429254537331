import React, {useEffect, useState, useContext, useCallback} from "react";
import { Row, Col, Card, Accordion, AccordionContext, useAccordionButton, Button, Toast, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

import { useAuth } from "../../context/authContext";
import {
  conditionValidations, conditionValidationTitles, gapValidationTitles,
  opportunityDualValidationOptions,
  opportunityValidationOptions, suspectDateOfServiceStatuses,
  suspectHistoryStatuses,
  suspectRejectionReasons,
} from "../../utils/textValueLists";
import {
  downloadSecureFile,
  formatDate,
  formatDateTime,
  getDynamicDisplayValue,
} from "../../utils/util";
import { EQHSpinner } from "../../components/EQHSpinner";

const componentName = "patientHistory";

const isEmpty = require("is-empty");

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
      <i id={`${componentName}-plusMinusIcon`} className={isCurrentEventKey ? "fa fa-chevron-down me-3" : "fa fa-chevron-right me-3"}></i>
      <span id={`${componentName}-itemTitle`} className="text-start fw-bold">{children}</span>
    </Button>
  );
}

const PreventiveCareHistory = ({
  allOpportunitiesForHist,
  allSuspectsForHist,
  showFullHistory,
  setShowFullHistory,
  handleUserSelectedCareOppHistoryIdsChange,
  careOppHistoryIds,
  setUserSelectedCareOppHistoryIds,
  setSelectedGaps,
  isLoading = false,
  featureFlagCIS,
}) => {
  const auth = useAuth();
  const authorizedGapRead = auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS");
  const authorizedGapWrite = auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS");
  const authorizedRafsRead = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS"
  );
  const authorizedRafsWrite = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS"
  );

  const calculateVisits = (visits) => {
    let approved = 0;
    visits.map((visit) => {
      if (visit.validation === "APPROVED") {
        approved++;
      }
    });
    return approved;
  };

  const getDosesData = useCallback((data) => {
    const groupedData = {};
    let count = 0;
    data.forEach( (history) => {
      const doseDescription = history?.doseDescription;
      const doseNumber = history?.visitNumber;
      if (doseDescription == null) {
        if (groupedData[history.status] && groupedData[history.status][history.status]) {
          count = count + 1;
          groupedData[history.status + count] ={};
          groupedData[history.status + count][history.status]= [];
          groupedData[history.status + count][history.status].push(history);
        } else {
          groupedData[history.status] = {};
          groupedData[history.status][history.status] = [];
          groupedData[history.status][history.status].push(history);
        }
      } else {
        if (doseNumber !== null) {
          if (!groupedData[doseDescription] ){
            groupedData[doseDescription] = {};
          }
          if(!groupedData[doseDescription][doseNumber]) {
            groupedData[doseDescription][doseNumber] = [];
          }
          groupedData[doseDescription][doseNumber].push(history);
        }
      }
    });
      return groupedData;
    }, [allOpportunitiesForHist.history]);

  const resetValues = ()=> {
    setUserSelectedCareOppHistoryIds([]);
    setSelectedGaps(null);
  }

  return (
    <Toast
      onClose={() => setShowFullHistory(false)}
      show={showFullHistory}
      animation={false}
      className="top-0 end-0 position-absolute zindex-1040 rounded-3"
    >
      <Toast.Header closeButton={false} className="d-flex justify-content-between align-items-center rounded-3 border-0">
        <h6 className="mb-0">History</h6>
        <button type="button" className="btn-close" onClick={() => {resetValues(); setShowFullHistory(false)}}></button>
      </Toast.Header>
      <Toast.Body className="overflow-y-auto overflow-x-hidden rounded-3" style={{ maxHeight: "500px" }}>
        {isLoading ? <EQHSpinner /> : authorizedGapRead || authorizedGapWrite ? (
          <>
            <span className="d-block fw-bold mb-1">
              Care Opportunities
            </span>
            <Accordion className="mb-3">
              {allOpportunitiesForHist.length &&
                allOpportunitiesForHist.map((gap, i) => {
                  return (
                    <Accordion
                      activeKey={careOppHistoryIds && careOppHistoryIds.has(gap.careGapSummary.id) ? i + 1 : ''}
                      key={gap.careGapSummary.id}>
                      <Card className="rounded-3 mb-2">
                        <Card.Header
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUserSelectedCareOppHistoryIdsChange(gap.careGapSummary.id);
                          }}
                          eventKey={i + 1}
                          className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                        >
                          <PlusMinusToggle eventKey={i + 1}>
                            {gap.careGapSummary.gapAndYear}{" "}
                            {gap.careGapSummary.abbreviation === "WC15"
                              ? `${calculateVisits(
                                  gap.careGapSummary.visits
                                )}/${gap.careGapSummary.visitsUpperBound} Approved Visits`
                              : null}
                            {gap.careGapSummary.abbreviation === "WC30"
                              ? `${calculateVisits(
                                  gap.careGapSummary.visits
                                )}/2 Approved Visits`
                              : null}
                          </PlusMinusToggle>
                        </Card.Header>
                        {gap.careGapSummary.abbreviation === "WC15" || gap.careGapSummary.abbreviation === "WC30" ? (
                          <Accordion.Collapse eventKey={i + 1}>
                            <Card.Body>
                              {gap.history.length &&
                                gap.history.map((history, j) => {

                                  function ordinal(n) {
                                    return `${n}${["th", "st", "nd", "rd"][n % 10] || "th"}`;
                                  }
                                  const ordinalVisitNumber = ordinal(history.visitNumber);

                                    return (
                                      <Accordion>
                                        <Card className="rounded-3 mb-2">
                                          <Card.Header
                                            eventKey={j + 1}
                                            className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                                          >
                                            <PlusMinusToggle eventKey={j + 1}>
                                              {history.visitNumber != 0 ? (
                                                    <span>{ordinalVisitNumber} Well Child Visit</span>
                                                ) : (
                                                  <span>{history.status}</span>
                                              ) }
                                            </PlusMinusToggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey={j + 1}>
                                            <Card.Body>
                                              <div className="d-flex flex-column">
                                                {history.visitNumber != 0 && (
                                                  <span className="fw-bold">{history.status}</span>
                                                )}
                                                <span>Status Updated: {formatDateTime(history.createdAt)}</span>
                                                {history.status !== 'Marked for Closure' &&(
                                                    (history.status === 'Open' || history.status === 'Gap Ineligible') && history.source === null ?
                                                        <span>Source: {history.userName}</span>
                                                        :
                                                        <span>User: {history.userName}</span>
                                                    )}
                                                {history.additionalInfoOptionValue.length != null &&
                                                history.additionalInfoOptionValue.length >0 && (
                                                  <span>
                                                    {history.additionalInfoCaption}:{" "}
                                                    {history.additionalInfoOptionValue.join(", ")}
                                                  </span>
                                                )}
                                                {history.reviewed === true &&
                                                  history.careGapName != null &&
                                                  (history.careGapName !== "WC15" ||
                                                    (history.careGapName === "WC15" &&
                                                      history.source != null)) && (
                                                    <span>Documentation reviewed</span>
                                                  )}
                                                {history.hideReason != null && (
                                                  <span>Hide reason: {history.hideReason}</span>
                                                )}
                                                {history.description
                                                  .split("\n")
                                                  .map((str) => (
                                                      <span>{str}</span>
                                                  ))}
                                                { history.uploadedFiles.length != null &&
                                                  history.uploadedFiles.length > 0 && [
                                                  <span>Uploaded Files:</span>,
                                                  history.uploadedFiles.map(
                                                    (uploadedFile) => {
                                                      return (
                                                        <span>
                                                          <a className="text-primary"
                                                            onClick={() =>
                                                              downloadSecureFile(
                                                                process.env
                                                                  .REACT_APP_REST_API_BASE_URL +
                                                                  `/v1/file-upload/file?id=${encodeURIComponent(
                                                                    uploadedFile
                                                                      .fileMetadata
                                                                      .fileId
                                                                  )}`,
                                                                uploadedFile
                                                                  .fileMetadata.fileName
                                                              )
                                                            }
                                                          >
                                                            {uploadedFile.fileMetadata
                                                              .fileName != null
                                                              ? uploadedFile
                                                                  .fileMetadata.fileName
                                                              : ""}
                                                          </a>
                                                        </span>
                                                      );
                                                    }
                                                  ),
                                                ]}

                                                {history.applicableCode != null &&  history.status !== 'Marked for Closure' && (
                                                  <span>Applicable Code: {history.applicableCode}</span>
                                                )}
                                                {history.dateOfService != null && (
                                                  <span>
                                                    Date of Service: {formatDate(history.dateOfService)}
                                                  </span>
                                                )}
                                                {history.dateOfService == null &&
                                                  history.status === "Closed" && (
                                                    <span>
                                                      Date of service: No Available Date
                                                    </span>
                                                  )}
                                                {history.careGapName === "WC15" &&
                                                history.visitStatus ===
                                                  "VERIFIED_BY_CLAIM" &&
                                                history.userId == null ? (
                                                  <span>Verified by Claims</span>
                                                ) : history.careGapName === "WC15" &&
                                                  history.visitStatus ===
                                                    "CLOSED_BY_CLAIM" &&
                                                  history.userId === null ? (
                                                  <span>Verified by Claims</span>
                                                ) : history.validation ||
                                                history.dualValidation ? (
                                                <div>
                                                  <span>
                                                    Validation:{" "}
                                                    {history.validation && (
                                                      <>
                                                        {getDynamicDisplayValue(
                                                          opportunityValidationOptions,
                                                          history.validation,
                                                          "class",
                                                          "name"
                                                        )}
                                                      </>
                                                    )}
                                                    {history.dualValidation && (
                                                      <>
                                                        {" "}
                                                        {getDynamicDisplayValue(
                                                          opportunityDualValidationOptions,
                                                          history.dualValidation,
                                                          "class",
                                                          "name"
                                                        )}
                                                      </>
                                                    )}
                                                  </span>
                                                  {history.rejectionReason != null && (
                                                    <span>
                                                      Rejection Reason:{" "}
                                                      <OverlayTrigger
                                                          trigger="click"
                                                          rootClose
                                                          overlay={
                                                            <Tooltip>
                                                              {" "}
                                                              {history.rejectionToolTipInfo
                                                                  ? history.rejectionToolTipInfo
                                                                  : "No Description Available"}{" "}
                                                            </Tooltip>
                                                          }
                                                      >
                                                      <span className="text-decoration-underline cursor-p" style={{ textDecorationColor: "#188dcd" }}>
                                                            {history.rejectionReason}
                                                          </span>
                                                      </OverlayTrigger>
                                                    </span>
                                                  )}
                                                  {history.approvalBycode === true && (
                                                    <span>
                                                      Applicable Code:{" "}
                                                      {history.validationCode
                                                        ? history.validationCode
                                                        : null}
                                                    </span>
                                                  )}
                                                </div>
                                                ) : null}
                                                {history.diastolicCode !== null ? (
                                                  <span>
                                                    Diastolic Code:{" "}
                                                    {history.diastolicCode}
                                                  </span>
                                                ) : null}
                                                {history.systolicCode !== null ? (
                                                  <span>
                                                    Systolic Code: {history.systolicCode}
                                                  </span>
                                                ) : null}
                                                {history.validationNote !== null && history.validationNote !== ""? (
                                                    <span>
                                                      Validation Note: {history.validationNote}
                                                    </span>
                                                ) : null}
                                                {history.source != null ? (
                                                  <span>Source: {history.source}</span>
                                                ) : (
                                                  (history.userId != null ||
                                                    (history.careGapName == "WC15" &&
                                                      history.visitNumber != 0)) && (
                                                    <span>Source: UI</span>
                                                  )
                                                )}
                                              </div>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      </Accordion>
                                    );
                                  }).reverse()}
                            </Card.Body>
                          </Accordion.Collapse>
                        ) : featureFlagCIS && (gap.careGapSummary.abbreviation === "CIS" || (gap.careGapSummary.abbreviation === "IMA")) ? (
                            <Accordion.Collapse eventKey={i + 1}>
                              <Card.Body>
                                {gap.history.length &&
                                    getDosesData(gap.history) && Object.entries(getDosesData(gap.history)).map(([header, values], j) => {
                                      return (
                                          <Accordion>
                                            <Card className="rounded-3 mb-2">
                                              <Card.Header
                                                  eventKey={j + 1}
                                                  className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                                              >
                                                <PlusMinusToggle eventKey={j + 1}>
                                                  {(header === "RV1" || header === "RV5" || header === "IIV4" || header === "MCV4") ?
                                                      <span>{header}</span>
                                                      : <span>{header.replace(/\d+$/, '')}</span>}
                                                </PlusMinusToggle>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey={j + 1}>
                                                <Card.Body>
                                                  {Object.entries(values).map(([value, data], i) => {
                                                    return (
                                                        data.length && data.map((history, k) => {
                                                          return (
                                                              <div className="d-flex flex-column mb-3">
                                                                {history.doseDescription != null &&
                                                                    <span
                                                                        className="fw-bold"> Dose {i + 1} - {history.status}</span>}
                                                                <span>Status Updated: {formatDateTime(history.createdAt)}</span>
                                                                {header !== 'Marked for Closure' &&(
                                                                    (history.status === 'Open' || history.status === 'Gap Ineligible') && history.source === null ?
                                                                        <span>Source: {history.userName}</span>
                                                                        :
                                                                        <span>User: {history.userName}</span>
                                                                    )}
                                                                {history.additionalInfoOptionValue.length != null &&
                                                                    history.additionalInfoOptionValue.length > 0 && (
                                                                        <span>
                                                                            {history.additionalInfoCaption}:{" "}
                                                                          {history.additionalInfoOptionValue.join(", ")}
                                                                          </span>
                                                                    )}
                                                                {history.hideReason != null && (
                                                                    <span>Hide reason: {history.hideReason}</span>
                                                                )}
                                                                {history.description
                                                                    .split("\n")
                                                                    .map((str) => (
                                                                        <span>{str}</span>
                                                                    ))}
                                                                {history.uploadedFiles.length != null &&
                                                                    history.uploadedFiles.length > 0 && [
                                                                      <span>Uploaded Files:</span>,
                                                                      history.uploadedFiles.map(
                                                                          (uploadedFile) => {
                                                                            return (
                                                                                <span>
                                                                                  <a className="text-primary"
                                                                                      onClick={() =>
                                                                                          downloadSecureFile(
                                                                                              process.env
                                                                                                  .REACT_APP_REST_API_BASE_URL +
                                                                                              `/v1/file-upload/file?id=${encodeURIComponent(
                                                                                                  uploadedFile
                                                                                                      .fileMetadata
                                                                                                      .fileId
                                                                                              )}`,
                                                                                              uploadedFile
                                                                                                  .fileMetadata.fileName
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                    {uploadedFile.fileMetadata
                                                                                        .fileName != null
                                                                                        ? uploadedFile
                                                                                            .fileMetadata.fileName
                                                                                        : ""}
                                                                                  </a>
                                                                                </span>
                                                                            );
                                                                          }
                                                                      ),
                                                                    ]}

                                                                {history.applicableCode != null && history.applicableCode != "" && header !== 'Marked for Closure' && (
                                                                    <span>Applicable Code: {history.applicableCode}</span>
                                                                )}
                                                                {history.dateOfService != null && (
                                                                    <span>
                                                                            Date of Service: {formatDate(history.dateOfService)}
                                                                          </span>
                                                                )}
                                                                {history.dateOfService == null &&
                                                                    history.status == "Closed" && (
                                                                        <span>
                                                                              Date of service: No Available Date
                                                                            </span>
                                                                    )}
                                                                {history.careGapName == "WC15" &&
                                                                history.visitStatus ==
                                                                "VERIFIED_BY_CLAIM" &&
                                                                history.userId == null ? (
                                                                    <span>Verified by Claims</span>
                                                                ) : history.careGapName == "WC15" &&
                                                                history.visitStatus ==
                                                                "CLOSED_BY_CLAIM" &&
                                                                history.userId == null ? (
                                                                    <span>Verified by Claims</span>
                                                                ) : history.validation ||
                                                                history.dualValidation ? (
                                                                    <div>
                                                                          <span>
                                                                            Validation:{" "}

                                                                          </span>
                                                                      {history.rejectionReason != null && (
                                                                          <span>
                                                                              Rejection Reason:{" "}
                                                                            <OverlayTrigger
                                                                                trigger="click"
                                                                                rootClose
                                                                                overlay={
                                                                                  <Tooltip>
                                                                                    {" "}
                                                                                    {history.rejectionToolTipInfo
                                                                                        ? history.rejectionToolTipInfo
                                                                                        : "No Description Available"}{" "}
                                                                                  </Tooltip>
                                                                                }
                                                                            >
                                                                              <span className="text-decoration-underline cursor-p" style={{ textDecorationColor: "#188dcd" }}>
                                                                                {history.rejectionReason}
                                                                              </span>
                                                                              </OverlayTrigger>
                                                                            </span>
                                                                      )}
                                                                      {history.approvalBycode === true && (
                                                                          <span>
                                                                              Applicable Code:{" "}
                                                                            {history.validationCode
                                                                                ? history.validationCode
                                                                                : null}
                                                                          </span>
                                                                      )}
                                                                    </div>
                                                                ) : null}
                                                                {history.diastolicCode !== null ? (
                                                                    <span>
                                                                            Diastolic Code:{" "}
                                                                      {history.diastolicCode}
                                                                          </span>
                                                                ) : null}
                                                                {history.systolicCode !== null ? (
                                                                    <span>
                                                                            Systolic Code: {history.systolicCode}
                                                                          </span>
                                                                ) : null}
                                                                {history.validationNote !== null && history.validationNote !== "" ? (
                                                                    <span>
                                                                      Validation Note: {history.validationNote}
                                                                    </span>
                                                                ) : null}
                                                                {history.source != null ? (
                                                                    <span>Source: {history.source}</span>
                                                                ) : (history.visitNumber != 0 && (
                                                                        <span>Source: UI</span>
                                                                    )
                                                                )}
                                                              </div>
                                                          );
                                                        })
                                                    );
                                                  })}
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                      );
                                    }).reverse()}
                              </Card.Body>
                            </Accordion.Collapse>
                        ): (
                          <Accordion.Collapse eventKey={i + 1}>
                            <Card.Body>
                              {gap.history.length &&
                                gap.history.map((history, j) => {
                                    return (
                                      <Accordion className="mb-2"

                                      >
                                        <Card className="rounded-3 mb-2">
                                          <Card.Header
                                            eventKey={j + 1}
                                            className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                                          >
                                            <PlusMinusToggle eventKey={j + 1}>
                                              {history.status}
                                            </PlusMinusToggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey={j + 1}>
                                            <Card.Body>
                                              <div className="d-flex flex-column">
                                                <span>Status Updated: {formatDateTime(history.createdAt)}</span>
                                                {history.userName === "System data intake process" &&
                                                (history.status === 'Open' || history.status === 'Gap Ineligible') ?
                                                    <span>Source: {history.userName}</span>
                                                    :
                                                    <span>User: {history.userName}</span>
                                                }
                                                {history.additionalInfoOptionValue.length != null &&
                                                history.additionalInfoOptionValue.length >0 && (
                                                  <span>
                                                    {history.additionalInfoCaption}:{" "}
                                                    {history.additionalInfoOptionValue.join(", ")}
                                                  </span>
                                                )}
                                                {history.reviewed === true &&
                                                  history.careGapName != null &&
                                                  (history.careGapName !== "WC15" ||
                                                    (history.careGapName === "WC15" &&
                                                      history.source != null)) && (
                                                    <span>Documentation reviewed</span>
                                                  )}
                                                {history.hideReason != null && (
                                                  <span>Hide reason: {history.hideReason}</span>
                                                )}
                                                {history.description
                                                  .split("\n")
                                                  .map((str) => (
                                                      <span>{str}</span>
                                                  ))}
                                                { history.uploadedFiles.length != null &&
                                                  history.uploadedFiles.length > 0 && [
                                                  <span>Uploaded Files:</span>,
                                                  history.uploadedFiles.map(
                                                    (uploadedFile) => {
                                                      return (
                                                        <span>
                                                          <a className="text-primary"
                                                            onClick={() =>
                                                              downloadSecureFile(
                                                                process.env
                                                                  .REACT_APP_REST_API_BASE_URL +
                                                                  `/v1/file-upload/file?id=${encodeURIComponent(
                                                                    uploadedFile
                                                                      .fileMetadata
                                                                      .fileId
                                                                  )}`,
                                                                uploadedFile
                                                                  .fileMetadata.fileName
                                                              )
                                                            }
                                                          >
                                                            {uploadedFile.fileMetadata
                                                              .fileName != null
                                                              ? uploadedFile
                                                                  .fileMetadata.fileName
                                                              : ""}
                                                          </a>
                                                        </span>
                                                      );
                                                    }
                                                  ),
                                                ]}
                                                {history.visitNumber !== 0 && (
                                                  <span>Visit: {history.visitNumber}</span>
                                                )}
                                                {history.applicableCode != null && (
                                                  <span>Applicable Code: {history.applicableCode}</span>
                                                )}
                                                {history.dateOfService != null && (
                                                    history.status === "Scheduled Appointment" ?
                                                  <span>
                                                    Appointment Scheduled for: {formatDate(history.dateOfService)}
                                                  </span>
                                                  :
                                                  history.validation != null && getDynamicDisplayValue(
                                                      opportunityValidationOptions,
                                                        history.validation,
                                                        "class",
                                                        "name"
                                                    ) === 'Approved' ?
                                                            <span>
                                                  {getDynamicDisplayValue(
                                                  gapValidationTitles,
                                                  history.validation,
                                                  "class",
                                                  "name")}:{" "}
                                                              {formatDate(history.dateOfService)}
                                                  </span>
                                                  :
                                                  <span>
                                                    Date of Service: {formatDate(history.dateOfService)}
                                                  </span>
                                                )}
                                                {history.dateOfService === null &&
                                                  history.status === "Closed" && (
                                                    <span>
                                                      Date of service: No Available Date
                                                    </span>
                                                  )}
                                                {history.careGapName === "WC15" &&
                                                history.visitStatus ===
                                                  "VERIFIED_BY_CLAIM" &&
                                                history.userId === null ? (
                                                  <span>Verified by Claims</span>
                                                ) : history.careGapName === "WC15" &&
                                                  history.visitStatus ===
                                                    "CLOSED_BY_CLAIM" &&
                                                  history.userId === null ? (
                                                  <span>Verified by Claims</span>
                                                ) : history.validation ||
                                                history.dualValidation ? (
                                                <div>
                                                  <p>
                                                    Validation:{" "}
                                                    {history.validation && (
                                                      <>
                                                        {getDynamicDisplayValue(
                                                          opportunityValidationOptions,
                                                          history.validation,
                                                          "class",
                                                          "name"
                                                        )}
                                                      </>
                                                    )}
                                                    {history.dualValidation && (
                                                      <>
                                                        {" "}
                                                        {getDynamicDisplayValue(
                                                          opportunityDualValidationOptions,
                                                          history.dualValidation,
                                                          "class",
                                                          "name"
                                                        )}
                                                      </>
                                                    )}
                                                  </p>
                                                  {history.rejectionReason != null && (
                                                    <p>
                                                      Rejection Reason:{" "}
                                                      <OverlayTrigger
                                                          trigger="click"
                                                          rootClose
                                                          overlay={
                                                            <Tooltip>
                                                              {" "}
                                                              {history.rejectionToolTipInfo
                                                                  ? history.rejectionToolTipInfo
                                                                  : "No Description Available"}{" "}
                                                            </Tooltip>
                                                          }
                                                      >
                                                      <span className="text-decoration-underline cursor-p" style={{ textDecorationColor: "#188dcd" }}>
                                                            {history.rejectionReason}
                                                          </span>
                                                      </OverlayTrigger>
                                                    </p>
                                                  )}
                                                  {history.approvalBycode === true && (
                                                    <p>
                                                      Applicable Code:{" "}
                                                      {history.validationCode
                                                        ? history.validationCode
                                                        : null}
                                                    </p>
                                                  )}
                                                </div>
                                                ) : null}
                                                {history.diastolicCode !== null ? (
                                                  <span>
                                                    Diastolic Code:{" "}
                                                    {history.diastolicCode}
                                                  </span>
                                                ) : null}
                                                {history.systolicCode !== null ? (
                                                  <span>
                                                    Systolic Code: {history.systolicCode}
                                                  </span>
                                                ) : null}
                                                {history.validationNote !== null && history.validationNote !== ""? (
                                                    <span>
                                                      Validation Note: {history.validationNote}
                                                    </span>
                                                ) : null}
                                                {history.source != null ? (
                                                  <span>Source: {history.source}</span>
                                                ) : (
                                                  (history.userId != null ||
                                                    (history.careGapName == "WC15" &&
                                                      history.visitNumber != 0)) && (
                                                    <span>Source: UI</span>
                                                  )
                                                )}
                                              </div>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      </Accordion>
                                    );
                                  }).reverse()}
                            </Card.Body>
                          </Accordion.Collapse>
                        )}
                      </Card>
                    </Accordion>
                  );
                })}
            </Accordion>
          </>
        ) : null}

        {isLoading ? <EQHSpinner /> : authorizedRafsRead || authorizedRafsWrite ? (
          <>
            <span className="d-block fw-bold mb-1">Suspects</span>
            <Accordion className="mb-3">
              {allSuspectsForHist.length &&
                allSuspectsForHist.map((suspect, i) => {
                  return (
                    <Card className="rounded-3 mb-2">
                      <Card.Header
                        eventKey={i + 1}
                        className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                      >
                        <PlusMinusToggle eventKey={i + 1}>
                          {suspect.conditionSummary.name &&
                          suspect.conditionSummary.name.length
                            ? suspect.conditionSummary.name
                            : suspect.conditionSummary.dxCodeDescription +
                              (suspect.conditionSummary.measureYear
                                ? " " + suspect.conditionSummary.measureYear
                                : "")}
                        </PlusMinusToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i + 1}>
                        <Card.Body>
                          {suspect.history.length &&
                            suspect.history
                                .map((x) => x)
                                .reverse()
                              .map((history, j) => {
                                return (
                                  <Accordion className="mb-2">
                                    <Card className="rounded-3 mb-2">
                                      <Card.Header
                                        eventKey={j + 1}
                                        className="d-flex align-items-center bg-white rounded-3 cursor-p border-0"
                                      >
                                        <PlusMinusToggle eventKey={j + 1}>
                                          {getDynamicDisplayValue(
                                            suspectHistoryStatuses,
                                            history.status,
                                            "value",
                                            "text"
                                          )}
                                          {"(Applied to: " +
                                              history.conditionName +  " " +
                                            suspect.conditionSummary
                                              .measureYear +
                                          ")"}
                                        </PlusMinusToggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={j + 1}>
                                        <Card.Body>
                                          <div className="d-flex flex-column">
                                            <span>Status Updated: {formatDateTime(history.createdAt)}</span>
                                            {!isEmpty(history.isDuplicate) && (
                                              <div>
                                                {!isEmpty(history.newHealthPlan) && (
                                                  <span>
                                                    Open Source:{" "}
                                                    {history.newHealthPlan}
                                                  </span>
                                                )}
                                                {!isEmpty(history.fileName) && (
                                                  <span>File Name: {history.fileName}</span>
                                                )}
                                                {!isEmpty(history.datePulled) && (
                                                  <span>
                                                    Open Received service:{" "}
                                                    {formatDate(history.datePulled)}
                                                  </span>
                                                )}
                                                {!isEmpty(history.icd10Code) && (
                                                  <span>
                                                    ICD-10: [{history.icd10Code}]{" "}
                                                    {history.icd10Description}
                                                  </span>
                                                )}
                                                {isEmpty(history.icd10Code) &&
                                                  (!isEmpty(history.hccNumber) ||
                                                    !isEmpty(
                                                      history.hccDescription
                                                    )) && (
                                                    <span>
                                                      HCC: [{history.hccNumber}]{" "}
                                                      {history.hccDescription}
                                                    </span>
                                                  )}
                                              </div>
                                            )}
                                            <span>User: {history.userName}</span>
                                            {history.hideReason != null && (
                                              <span>Hide reason: {history.hideReason}</span>
                                            )}
                                            {j === suspect.history.length - 1 && (
                                              <span>
                                                Applicable Code:{" "}
                                                {history.hccNumber
                                                  ? history.hccNumber
                                                  : suspect.conditionSummary.dxCode}
                                              </span>
                                            )}
                                            {history.description
                                              .split("\n")
                                              .map((str) => (
                                                <span>{str}</span>
                                              ))}
                                            {history.uploadedFiles.length != null &&
                                              history.uploadedFiles.length > 0 && [
                                                <span>Uploaded Files:</span>,
                                                history.uploadedFiles.map(
                                                  (uploadedFile) => {
                                                    return (
                                                      <span>
                                                        <a className="text-primary"
                                                          onClick={() =>
                                                            downloadSecureFile(
                                                              process.env
                                                                .REACT_APP_REST_API_BASE_URL +
                                                                `/v1/file-upload/file?id=${encodeURIComponent(
                                                                  uploadedFile
                                                                    .fileMetadata
                                                                    .fileId
                                                                )}`,
                                                              uploadedFile
                                                                .fileMetadata.fileName
                                                            )
                                                          }
                                                        >
                                                          {uploadedFile.fileMetadata
                                                            .fileName != null
                                                            ? uploadedFile
                                                                .fileMetadata.fileName
                                                            : ""}
                                                        </a>
                                                      </span>
                                                    );
                                                  }
                                                ),
                                              ]}
                                            {history.createdAt != null && (
                                                <>
                                                  {history.validation != null && (getDynamicDisplayValue(
                                                      conditionValidations,
                                                            history.validation,
                                                        "class",
                                                        "name"
                                                  )=== 'Approved' || getDynamicDisplayValue(
                                                      conditionValidations,
                                                            history.validation,
                                                        "class",
                                                        "name"
                                                  ) === 'Rejected') ? (
                                                  <span>
                                                    {getDynamicDisplayValue(
                                                        conditionValidationTitles,
                                                        history.validation,
                                                        "class",
                                                        "name")}:{" "}
                                                    {formatDate(history.createdAt)}
                                                  </span>
                                                  )
                                                  :
                                                  <span>
                                                 {getDynamicDisplayValue(
                                                          suspectDateOfServiceStatuses,
                                                          history.status,
                                                          "value",
                                                          "text"
                                                      )}:{" "}
                                                      {formatDate(history.dateOfService)}
                                                 </span>
                                                 }
                                                </>
                                            )}
                                            {history.validation ||
                                            history.dualValidation ? (
                                              <>
                                                <span>
                                                  Validation:{" "}
                                                  {history.validation && (
                                                    <>
                                                      {getDynamicDisplayValue(
                                                        conditionValidations,
                                                        history.validation,
                                                        "class",
                                                        "name"
                                                      )}
                                                    </>
                                                  )}
                                                  {history.dualValidation && (
                                                    <>
                                                      {" "}
                                                      {getDynamicDisplayValue(
                                                        opportunityDualValidationOptions,
                                                        history.dualValidation,
                                                        "class",
                                                        "name"
                                                      )}
                                                    </>
                                                  )}
                                                </span>
                                                {history.rejectionReason != null && history.conditionRejectionReason == null ? (
                                                  <span>
                                                    Rejection Reason:{" "}
                                                    {getDynamicDisplayValue(
                                                      suspectRejectionReasons,
                                                      history.rejectionReason,
                                                      "class",
                                                      "text"
                                                    )}
                                                  </span>
                                                ) :  history.conditionRejectionReason != null &&
                                                    ( <span>
                                                    Reject Reason: {" "}
                                                      <OverlayTrigger placement="auto" overlay={<Tooltip>{history.recommendation}</Tooltip>}>
                                                    <span className="text-decoration-underline cursor-p" style={{ textDecorationColor: "#188dcd" }}>
                                                          {history.conditionRejectionReason}
                                                        </span>
                                                    </OverlayTrigger>
                                                  </span>)
                                                }
                                              </>
                                            ) : null}
                                            {history.validationNote !== null && history.validationNote !== "" && history.conditionRejectionReason == null ? (
                                                <span>
                                                    Validation Note:{history.validationNote}
                                                  </span>
                                            ) : null}
                                            {history.source != null && (
                                              <span>Source: {history.source}</span>
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                              })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
            </Accordion>
          </>
        ) : null}
      </Toast.Body>
    </Toast>
  );
};

export default PreventiveCareHistory;
