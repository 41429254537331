import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";

const [getCarePathTemplatesByClient, useGetCarePathTemplatesByClient] =
  dataFetchHelper(([client]) => {
    return axios.get(
      `${process.env.REACT_APP_REST_API_BASE_URL}/v1/care-path-template-admin/templates?client=${client}`,
      {
        headers: {},
      }
    );
  });

const [getCarePathTemplateConstants, useGetCarePathTemplateConstants] =
  dataFetchHelper(() => {
    return axios.get(
      `${process.env.REACT_APP_REST_API_BASE_URL}/v1/care-path-template-admin/constants`,
      {
        headers: {},
      }
    );
  });

const saveCarePathTemplate = (carePathTemplate) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/care-path-template-admin/templates`,
    carePathTemplate,
    {
      headers: {},
    }
  );
};

const deleteCarePathTemplate = (id) => {
  return axios.delete(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/care-path-template-admin/templates?id=${id}`,
    {
      headers: {},
    }
  );
};

export {
  getCarePathTemplatesByClient,
  useGetCarePathTemplatesByClient,
  getCarePathTemplateConstants,
  useGetCarePathTemplateConstants,
  saveCarePathTemplate,
  deleteCarePathTemplate,
};
