import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  createRef,
} from "react";

import { Container, Card, Row, Col, Dropdown, Toast, Form, Spinner, Accordion, AccordionContext, useAccordionButton, Badge } from "react-bootstrap";
import { Button } from "../../components/buttons";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";
import axios from "../../utils/apiClient";
import DatePicker from "react-datepicker";
import { useAuth } from "../../context/authContext";
import { EQHSpinner } from "../../components/EQHSpinner";
import { careManagementsReducer } from "../../reducers/patientProfile/careManagementsReducer";
import { useGetCareManagements } from "../../actions/patientProfileActions";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../components/dropDownLists/sharedDDL";
import {
  formatDate,
  formatDateTime,
  carePathValues,
  expiredSources,
  getStatusColors,
} from "../../utils/util";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Modals from "../../components/modals";
import AssignCarePath from "../quickList/AssignCarePath";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  closeCarePath,
  retrieveCloseCpReasons,
} from "../../actions/patientSearch/patientSearchActions";
import ErrorToast from "../common/ErrorToast";
import EQHErrorToast from "../../components/EQHErrorToast";
import AppointmentModal from "../appointment/AppointmentModal";
import { hideAlert } from "../../actions/populationSearch/populationSearchActions";
import { EQHDatePicker } from "../../components/datePicker";
import { format } from "date-fns";
import { getFileDownload } from "../../actions/fileUploadActions";
import { FileUpload } from "../../components/FileUpload";

const isEmpty = require("is-empty");

const componentName = "patientProfile-CarePath";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card";
const baseURLCP = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-paths";

const ProvDropdown = styled.div`
  .dropdown-item {
    white-space: normal;
  }
`;

const StyledInput = styled.input`
  padding: 10px;
`;

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" onClick={decoratedOnClick} className="text-dark">
      <i className={
          isCurrentEventKey
            ? "fa fa-minus align-top mt-1 me-2"
            : "fa fa-plus align-top mt-1 me-2"
        }
      ></i>
      {children}
    </Button>
  );
}

const CarePath = ({ patient, selectedTab, reloadPatientOverview }) => {
  const auth = useAuth();
  const careManagementsPkg = useGetCareManagements([], careManagementsReducer);
  const [notesAdded, setNotesAdded] = useState([]);
  const [selectedCareManagement, setSelectedCareManagement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCarePath, setSelectedCarePath] = useState(null);
  const [selectedPCP, setSelectedPCP] = useState({});
  const [completedHidden, setCompletedHidden] = useState(false);

  const [carePathSteps, setCarePathSteps] = useState([]);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentError, setCurrentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [newSteps, setNewSteps] = useState([]);
  const [showAssignCarePathModal, setShowAssignCarePathModal] = useState(false);
  const [showConfirmExpiredModal, setShowConfirmExpiredModal] = useState(false);
  const [removablePath, setRemovablePath] = useState();
  const [changedPath, setChangedPath] = useState();
  const [closeModal, setCloseModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedExpired, setSelectedExpired] = useState(false);
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(null);
  const [selectedExpiredSource, setSelectedExpiredSource] = useState(null);
  const [newCarePath, setNewCarePath] = useState(null);
  const [loadingPath, setLoadingPath] = useState(false);
  const [errorMessageForPathCoord, setErrorMessageForPathCoord] = useState([]);
  const [primaryCloseError, setPrimaryCloseError] = useState(false);
  const isEmpty = require("is-empty");
  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");

  const handleChange = (e) => {
    if (e && e[0]) {
      setSelectedPCP(e[0]);
      handleAssignChange(e[0].value);
    }
  };

  useEffect(() => {
    if (selectedTab && patient?.id != null) {
      careManagementsPkg.fetchData(patient.id);
      setNotesAdded([]);
    } else if (Object.keys(selectedPCP).length === 0) {
      careManagementsPkg.fetchData(patient.id);
    }
  }, [patient.id, selectedTab, selectedPCP]);

  useEffect(() => {
    let count = 0;
    let length = careManagementsPkg.state.data.length;
    let primary = null;
    if (length) {
      let newArray = careManagementsPkg.state.data.filter(
        (x) => x.isActive === true
      );
      let arrayLength = newArray.length;
      newArray.forEach((cp) => {
        if (selectedCarePath != null) {
          if (cp.id === selectedCarePath.id) {
            primary = cp;
          }
        } else {
          count++;
          if (newCarePath && cp.id === newCarePath) {
            primary = cp;
          } else if (cp.primary) {
            primary = cp;
          }
          if (count === arrayLength && !primary && !cp.primary) {
            primary = newArray[0];
          }
        }
      });
      if (!arrayLength && length) {
        handleChangeCarePath(careManagementsPkg.state.data[0]);
      }
      if (primary) {
        handleChangeCarePath(primary);
      }
      setNewCarePath(null);
    }
  }, [careManagementsPkg.state.data]);

  const ref = React.createRef();

  const handleChangeCarePath = (cp) => {
    setLoadingPath(true);
    ref.current.clear();
    setSelectedCarePath(cp);
    axios
      .get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          cp.id
        )}`,
        {
          headers: {},
        }
      )
      .then((resp) => {
        let options = addSteps(resp.data);
        setCarePathSteps(options);
        setIsLoadingComp(false);
        setLoadingPath(false);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.message);
        // setCurrentError(true);
      });
  };
  const handleRefreshSteps = (cp) => {
    setLoadingPath(true);
    axios
        .get(
            process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/care-paths/care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
                cp.id
            )}`,
            {
              headers: {},
            }
        )
        .then((resp) => {
          let options = addSteps(resp.data);
          setCarePathSteps(options);
          setIsLoadingComp(false);
          setLoadingPath(false);
        })
        .catch((error) => {

        });
  };

  const addSteps = (list) => {
    let subStep = 0;
    let mainStep = 0;
    let finalList = [];
    list.forEach((cp, index) => {
      if (!cp.subStep) {
        mainStep++;
        cp.stepNumber = mainStep;
        subStep = 1;
      } else {
        const subSTepNum = mainStep + "." + subStep;
        cp.stepNumber = Number(subSTepNum);
        subStep++;
      }
      finalList.push(cp);
    });
    return finalList;
  };

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const clientId = patient && patient.clientId ? patient.clientId : "";
      const url =
        baseURL +
        "/search-coordinators" +
        (query ? "?name=" + query : "") +
        (clientId ? "&clientId=" + clientId : "") +
        ("&patientId=" + encodeURIComponent(patient.id));
      setIsLoadingComp(true);
      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp.data.map((i) => ({
              value: i.value,
              text: i.text,
            }));
          }
          setOptions(options);
          setIsLoadingComp(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
        });
    }
  };

  const moveItem = (from, to, newState) => {
    var f = newState.splice(from, 1)[0];
    newState.splice(to, 0, f);
    return newState;
  };

  const handleStepMovement = (e, step) => {
    let currentIndex = null;
    let prevStepNum = step.stepNumber;
    let newState = carePathSteps;
    let instance = step.carePathInstanceId;
    newState.forEach((stp, i) => {
      if (stp.stepNumber === step.stepNumber) {
        currentIndex = i;
      }
    });
    if (e === "up") {
      let newIndex = currentIndex - 1;
      newState[currentIndex].stepNumber = newState[newIndex].stepNumber;
      newState[newIndex].stepNumber = prevStepNum;
      newState = moveItem(currentIndex, newIndex, newState);
      updateStepAction(newState, instance);
    } else if (e === "down") {
      let newIndex = currentIndex + 1;
      newState[currentIndex].stepNumber = newState[newIndex].stepNumber;
      newState[newIndex].stepNumber = prevStepNum;
      newState = moveItem(newIndex, currentIndex, newState);
      updateStepAction(newState, instance);
    } else if (e === "remove") {
      newState[currentIndex].deleted = true;
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > prevStepNum) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber - 1) * 10) / 10;
        }
      }
      removeStepAction(newState, instance);
    }
    setCarePathSteps([...newState]);
  };

  const submitNewStep = (stepObj,carePathSteps) => {
    let carePathStepDto = stepObj;
    const index = carePathSteps.findIndex(object => {
      return stepObj.stepNumber === object.stepNumber;
    });
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/add-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          selectedCarePath.id
        )}&carePathStepNumber=${index}`,
        carePathStepDto,
        { headers: {} }
      )
      .then(async (res) => {
        await handleRefreshSteps(selectedCarePath);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
        setCurrentError(true);
      });
  };

  const saveNewStep = async (stepObj, index, action, number) => {
    let newState = carePathSteps;
    if (action === "before") {
      if (index === 0) {
        for (let i = 0; i < newState.length; i++) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
        stepObj.stepNumber = number;
        newState.unshift(stepObj);
      } else {
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].stepNumber >= number) {
            newState[i].stepNumber =
              Math.round((newState[i].stepNumber + 1) * 10) / 10;
          }
        }
        stepObj.stepNumber = number;
        newState.splice(index, 0, stepObj);
      }
    } else {
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > number) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
      }
      stepObj.stepNumber = Math.floor(number + 1);
      newState.splice(index + 1, 0, stepObj);
    }
    await submitNewStep(stepObj, newState);
    //setCarePathSteps([...newState]);
  };

  const editExistingStep = (step, stepObj, index) => {
    let newState = carePathSteps;
    let oldObj = newState[index];
    let newObj = {};
    let instance = step.carePathInstanceId;
    Object.keys(oldObj).forEach((key) =>
      key in stepObj
        ? (newObj[key] = stepObj[key])
        : (newObj[key] = oldObj[key])
    );
    newState[index] = newObj;
    setCarePathSteps([...newState]);
    updateStepAction(newState, instance);
  };

  const updateSelected = async (path) => {
    setNewCarePath(path.id);
    await careManagementsPkg.fetchData(patient.id);
  };

  const handleCheckClose = () => {
    checkCanCloseCarePath(selectedCarePath);
  };

  const checkCanCloseCarePath = (selectedCarePath) => {
    const payLoad = {
      carePathInstanceId: selectedCarePath.id,
    };
    const url = baseURLCP + "/check-close-care-path";
    axios
      .post(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response && response.data && !isEmpty(response.data.errorMessage)) {
          setErrorText(response.data.errorMessage);
        } else {
          handleModal();
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const handleModal = () => {
    setRemovablePath(selectedCarePath);
    setChangedPath(selectedCarePath);
    setSelectedReason(selectedReason);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
    setCloseModal(true);
  };

  const handleRemovePath = () => {
    setSelectedCarePath(null);
    setCarePathSteps([]);
    careManagementsPkg.fetchData(patient.id);
  };

  const openPrimaryModal = (paths) => {
    setPrimaryCloseError(true);
  };

  const assignAsPrimary = (cp) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-primary-care-path?carePathInstanceId=${cp.id}`,
        {
          headers: {},
        }
      )
      .then(async (res) => {
        careManagementsPkg.fetchData(patient.id);
      })
      .catch((error) => {
      });
  };

  const handleStatusChange = (e, value, isCustomStatus, customStatus) => {
    let newState = carePathSteps;

    newState.forEach((stp) => {
      if (stp.stepNumber === value.stepNumber) {
        value.carePathStepStatus = e;
      }
    });

    const body = {
      action: "SET_STATUS",
      careProviderId: value.assignedTo ? value.assignedTo.id : "",
      text: null,
      status: e,
      assignToId: null,
      stepCustomStatus: isCustomStatus ? customStatus: null,
    };

    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          value.carePathInstanceId
        )}&carePathStepId=${value.id}`,
        body,
        { headers: {} }
      )
      .then((res) => {
        newState.forEach((stp) => {
          if (stp.stepNumber === res.data.result.stepNumber) {
            stp.stepCustomState = res.data.result.stepCustomState;
          }
        });
        setCarePathSteps([...newState]);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        setCurrentError(true);
      });
  };

  const handleAssignChange = (value) => {
    const body = {
      carePathId: selectedCarePath.id,
      primary: selectedCarePath.primary,
      careCoordinatorId: value,
    };

    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update?carePathId=${encodeURIComponent(
          selectedCarePath.id
        )}`,
        body,
        { headers: {} }
      )
      .then((res) => {
        if (res.data.fail && res.data.type === "FAIL") {
          setErrorMessageForPathCoord([res.data.error]);
          setSelectedPCP({});
        } else {
          setSelectedPCP({});
        }
        // setCarePathSteps([...newState]);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        setCurrentError(true);
      });
  };

  const removeStepAction = (newObj, instance) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then((res) => {
        handleRefreshSteps(selectedCarePath);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        setCurrentError(true);
      });
  };

  const updateStepAction = (newObj, instance) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then((res) => {
        careManagementsPkg.fetchData(patient.id);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        setCurrentError(true);
      });
  };

  const handleCancelExpired = () => {
    setShowConfirmExpiredModal(false);
    setSelectedExpired(false);
    setSelectedReason("Actions");

  };

  const handleConfirmExpired = () => {
    setShowConfirmExpiredModal(false);
    setSelectedExpired(true);

  };

  return (
    <Fragment>
      {errorMessage && errorMessage.length > 0 &&
          <ToastOverlay>
            <EQHErrorToast
                errors={[errorMessage]}
                handleClose={() => setErrorMessage([])}
                styleRemove={false}
            />
          </ToastOverlay>
      }
      <Container fluid>
        <Modals
          centered={true}
          show={showConfirmExpiredModal}
          scrollable={true}
          onHide={() => handleCancelExpired()}
          onCreate={() => handleConfirmExpired()}
          size="sm"
          title=""
          titleid={`${componentName}-ConfirmExpired`}
          closeBtn="Cancel"
          closeBtnId={`${componentName}-CloseExpired`}
          completeBtn="Confirm"
          completeBtnId={`${componentName}-CompleteExpired`}
          hideFooter={false}
          body="I have verified that this patient is deceased."
        />
        <Modals
          show={showAssignCarePathModal}
          onHide={() => setShowAssignCarePathModal(false)}
          title="Assign Care Program"
          titleid={`${componentName}-assignCarePath`}
          size="xl"
          completeBtn={"Confirm"}
          completeBtnId={`${componentName}-confirmButton`}
          hideFooter={true}
          body={
            <AssignCarePath
              onHideClicked={() => setShowAssignCarePathModal(false)}
              patientsSelected={patient}
              updateSelectedCarePath={updateSelected}
              carePathsArray={careManagementsPkg.state.data}
            />
          }
        />
        <Modals
          show={closeModal}
          onHide={() => setCloseModal(false)}
          title="Please Enter a reason for closing Care Program"
          titleid={`${componentName}-closeCarePathModal`}
          size="lg"
          completeBtn={"Confirm"}
          completeBtnId={`${componentName}-confirmButton`}
          hideFooter={true}
          body={
            <CloseGapSelect
              removePath={handleRemovePath}
              path={selectedCarePath}
              close={() => setCloseModal(false)}
              patients={null}
              patient={patient}
              openPrimaryModal={openPrimaryModal}
              selectedReason={selectedReason}
              setSelectedReason={setSelectedReason}
              setShowConfirmExpiredModal={setShowConfirmExpiredModal}
              selectedExpired={selectedExpired}
              setSelectedExpired={setSelectedExpired}
              selectedExpiredDate={selectedExpiredDate}
              setSelectedExpiredDate={setSelectedExpiredDate}
              selectedExpiredSource={selectedExpiredSource}
              setSelectedExpiredSource={setSelectedExpiredSource}
              reloadPatientOverview={reloadPatientOverview}

            />
          }
        />
        <Modals
          centered={true}
          show={errorText}
          scrollable={true}
          onCreate={() => setErrorText(null)}
          onHide={() => setErrorText(null)}
          size="sm"
          title=""
          closeBtn="Close"
          hideClickButton={true}
          hideFooter={false}
          body={errorText}
        />

        <Card>
          <Card.Body>
            {primaryCloseError ? (
              <Row className="text-end mb-2">
                <Col>
                  <Toast
                    style={{ minWidth: "fit-content" }}
                    onClose={() => setPrimaryCloseError(false)}
                  >
                    <Toast.Header>
                      <strong id={`${componentName}-primaryCloseErrorIcon`} className="me-auto fw-bold text-danger">
                        <i className="fas fa-exclamation-triangle"></i>{" "}
                      </strong>
                      <div id={`${componentName}-primaryCloseErrorText`}>
                        Please assign Primary care program before closing current
                        Primary care program
                      </div>
                    </Toast.Header>
                  </Toast>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md={5}>
                <Row>
                  <Col>
                    <label id={`${componentName}-CarePathLabel`} className="d-block">Care Program</label>
                    <ProvDropdown>
                      <Form.Group>
                        <Dropdown value={selectedCarePath} id={`${componentName}-CarePathDropdown`}>
                          <Dropdown.Toggle
                            as={DropDownButton}
                            cssOpen={isOpen}
                            id={`${componentName}-CarePathValue`}
                            cusWidth="100% !important"
                            disabled={!careManagementsPkg.state.data.length}
                          >
                            {selectedCarePath ? (
                              <span>
                                {selectedCarePath.name}
                                {selectedCarePath.primary
                                  ? " (Primary)"
                                  : null}{" "}
                                {!selectedCarePath.isActive
                                  ? " (Close)"
                                  : selectedCarePath.overdue
                                    ? " (Overdue)"
                                    : null}
                                {selectedCarePath.isActive &&
                                  selectedCarePath.hasDueWithin7DaysSteps
                                  ? " (Within 7d)"
                                  : null}
                              </span>
                            ) : (
                              <span>-Select-</span>
                            )}
                            <i className="fa fa-chevron-down" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
                            {careManagementsPkg.state.data.length &&
                              careManagementsPkg.state.data.map((cp) => (
                                <Dropdown.Item
                                  style={{}}
                                  key={cp.id}
                                  onClick={() => handleChangeCarePath(cp)}
                                >
                                  {cp.name} {cp.primary ? " (Primary)" : null}{" "}
                                  {!cp.isActive
                                    ? " (Close)"
                                    : cp.overdue
                                      ? " (Overdue)"
                                      : null}
                                  {cp.isActive && cp.hasDueWithin7DaysSteps
                                    ? " (Within 7d)"
                                    : null}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form.Group>
                    </ProvDropdown>
                  </Col>
                  <Col>
                    <label id={`${componentName}-CarePathCoordinatorLabel`} className="d-block">Care Program Coordinator</label>
                    <StyledTypeAhead
                      ref={ref}
                      disabled={isEmpty(selectedCarePath)}
                      inputProps={{
                        id: `${componentName}-CarePathCoordinatorValue`
                      }}
                      labelKey="text"
                      isLoading={isLoadingComp}
                      onSearch={asyncsearch}
                      options={options}
                      onChange={(e) => handleChange(e)}
                      placeholder={
                        selectedCarePath && selectedCarePath.assignedTo
                          ? `${selectedCarePath.assignedTo.firstName} ${selectedCarePath.assignedTo.lastName}`
                          : "Search coordinators by name"
                      }
                    />
                    {errorMessageForPathCoord && (
                      <ToastOverlay>
                        <EQHErrorToast
                          errors={errorMessageForPathCoord}
                          handleClose={() => setErrorMessageForPathCoord([])}
                          styleRemove={true}
                        />
                      </ToastOverlay>
                    )}
                  </Col>
                </Row>
              </Col>
              {auth.hasAuthority("AUTH_CARE_PATHS_WRITE_ACCESS") && (
                <Col md={7} className="text-end">
                  <Button
                    disabled={!selectedCarePath || !selectedCarePath.active || !authorizedCareProgramWrite}
                    variant="secondary"
                    id={`${componentName}-closeCarePathButton`}
                    className="align-top"
                    onClick={() => handleCheckClose()}
                  >
                    Close Care Program
                  </Button>
                  <Button
                    disabled={!selectedCarePath || selectedCarePath.primary || !selectedCarePath.active || !authorizedCareProgramWrite}
                    variant="secondary"
                    id={`${componentName}-primaryCarePathButton`}
                    className="align-top"
                    onClick={() => assignAsPrimary(selectedCarePath)}
                  >
                    Make Primary
                  </Button>
                  <Button
                    disabled={!authorizedCareProgramWrite}
                    variant="secondary"
                    id={`${componentName}-assignCarePathButton`}
                    className="align-top me-0"
                    onClick={() => setShowAssignCarePathModal(true)}
                  >
                    Assign New Care Program
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <span id={`${componentName}-carePathSteps`} className="fw-bold">
                  {carePathSteps.length} Steps
                </span>
              </Col>
              <Col className="text-end">
                <StyledInput
                  type="checkbox"
                  id={`${componentName}-hideCompletedCheck`}
                  onChange={() => setCompletedHidden(!completedHidden)}
                  checked={completedHidden}
                  disabled={!selectedCarePath}
                  className="me-1 align-middle"
                />
                <small id={`${componentName}-hideCompletedLabel`}>Hide Completed</small>
              </Col>
            </Row>
            {careManagementsPkg.state.isLoading && <EQHSpinner />}
            {careManagementsPkg.state.error ? (
              <Card>
                <Card.Body id={`${componentName}-careManagementError`}>{careManagementsPkg.state.error}</Card.Body>
              </Card>
            ) : careManagementsPkg.state.data &&
              careManagementsPkg.state.data.length === 0 ? (
              <Card>
                <Card.Body id={`${componentName}-careManagementNoRecord`}>No Records Found</Card.Body>
              </Card>
            ) : null}
    
            {carePathSteps && carePathSteps.length
              ? carePathSteps.map((step, index) => (
                <>
                  {!completedHidden ? (
                    <StepComponent
                      step={step}
                      patientsSelected={patient}
                      newStep={setNewSteps}
                      handleStepMovement={handleStepMovement}
                      handleStatusChange={handleStatusChange}
                      index={index}
                      pathsLength={carePathSteps.length}
                      saveNewStep={saveNewStep}
                      editExistingStep={editExistingStep}
                      reloadPatientOverview={reloadPatientOverview}
                      notesAdded={notesAdded}
                      setNotesAdded={setNotesAdded}
                      selectedPath={selectedCarePath}
                      loading={loadingPath}
                      handleChangeCarePath={handleChangeCarePath}
                      errorMessage={errorMessage}
                    />
                  ) : completedHidden &&
                    step.carePathStepStatus != "COMPLETED" ? (
                    <StepComponent
                      step={step}
                      patientsSelected={patient}
                      newStep={setNewSteps}
                      handleStepMovement={handleStepMovement}
                      handleStatusChange={handleStatusChange}
                      index={index}
                      pathsLength={carePathSteps.length}
                      saveNewStep={saveNewStep}
                      editExistingStep={editExistingStep}
                      notesAdded={notesAdded}
                      setNotesAdded={setNotesAdded}
                      selectedPath={selectedCarePath}
                      errorMessage={errorMessage}
                      loading={loadingPath}
                    />
                  ) : null}
                </>
              ))
              : null}
          </Card.Body>
        </Card>
      </Container>
    </Fragment>
  );
};

const StepComponent = ({
  step,
  patientsSelected,
  newSteps,
  handleStepMovement,
  handleStatusChange,
  index,
  pathsLength,
  saveNewStep,
  editExistingStep,
  reloadPatientOverview,
  notesAdded,
  setNotesAdded,
  selectedPath,
  loading,
  handleChangeCarePath,
  errorMessage
}) => {
  const isEmpty = require("is-empty");

  const [textAreaValue, setTextAreaValue] = useState("");

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  }
  const [selectedPCP, setSelectedPCP] = useState({});

  const [showBeforeForm, setShowBeforeForm] = useState(false);
  const [showAfterForm, setShowAfterForm] = useState(false);
  const [editStep, setEditStep] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const textBoxRefForNote = useRef();
  const auth = useAuth();
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [options, setOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");


  useEffect(() => {
    if (step?.files) {
      setFiles([ ...step?.files ]);
    }
  }, [step])

  const handleClickOnLink = (event, fileId, fileName) => {
    event.preventDefault();
    dispatch(getFileDownload(fileId, fileName));
  };

  const cancelNote = () => {
    setShowAdd(false);
    textBoxRefForNote.current.value = "";
  };

  const findCustomStatusLabel = (step) => {
    let statuses = ["PENDING", "COMPLETED", "SKIPPED", "CANCELLED"];
    let items = [];
    if (step && step.customStatuses) {
      step.customStatuses.forEach((em) => {
        items.push(<Dropdown.Item
          id={`${componentName}-carePathStepActionsCompleted-${index}`}
          eventKey={em.behavesLike}
        >
          {em.status}
        </Dropdown.Item>)
        if (statuses.includes(em.behavesLike) && statuses.indexOf(em.behavesLike)) {
          const ind = statuses.indexOf(em.behavesLike)
          if(ind){
            statuses.splice(ind, 1)
          }
          
        }
      })
      if (statuses.length) {
        statuses.forEach((em) => {
          items.push(<Dropdown.Item
            id={`${componentName}-carePathStepActionsCompleted-${index}`}
            eventKey={em}
          >
            {em}
          </Dropdown.Item>)
        })
      }
    }

    return items;
  };

  useEffect(() => {
    setShowAfterForm(false);
    setEditStep(false);
    if (textBoxRefForNote.current) {
      cancelNote();
    }
  }, [selectedPath]);

  const openCreationView = (e, step) => {
    if (e === "before") {
      setShowAfterForm(false);
      setSelectedAction("before");
      setShowBeforeForm(true);
      setEditStep(false);
    } else if (e === "after") {
      setShowBeforeForm(false);
      setSelectedAction("after");
      setShowAfterForm(true);
      setEditStep(false);
    } else if (e === "edit") {
      setShowBeforeForm(false);
      setSelectedAction("edit");
      setEditStep(true);
      setShowAfterForm(true);
    }
  };

  const closeOpenForm = () => {
    setShowAfterForm(false);
    setShowBeforeForm(false);
  };

  const onAdd = () => {
    let newName;
    if (
      textBoxRefForNote &&
      textBoxRefForNote.current &&
      textBoxRefForNote.current.value
    ) {
      setNotesAdded([...notesAdded, textBoxRefForNote.current.value]);

      let value = {
        action: "ADD_NOTE",
        careProviderId: step.assignedTo ? step.assignedTo.id : "",
        text: textBoxRefForNote.current.value,
        status: step?.carePathStepStatus || null,
        assignToId: null,
        stepCustomStatus: null,
      };
      textBoxRefForNote.current.value = "";
      updateCarePathStep(value);
    }
    setTextAreaValue("");
  };

  const [onSavingNote, setOnSavingNote] = useState(false);
  const [errorMessageForNote, setErrorMessageForNote] = useState([]);
  
  const updateCarePathStep = (value) => {
    cancelNote();
    setOnSavingNote(true);
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          step.carePathInstanceId
        )}&carePathStepId=${encodeURIComponent(step.id)}`,
        value,
        { headers: {} }
      )
      .then((res) => {
        step.notes = res.data.result.notes;
        setOnSavingNote(false);
        setNotesAdded([]);
        reloadPatientOverview();
      })
      .catch((err) => {
        setOnSavingNote(false);
        setErrorMessageForNote([err.response.data.message,]);
      });
  };
  const [coordinatorChange, setCoordinatorChange] = useState(false);

  const [errorMessageForCoord, setErrorMessageForCoord] = useState([]);
  const handleCoordinatorChange = (e) => {
    setCoordinatorChange(true);
    let value;
    if (e && e[0]) {
      value = e[0].value;
    }
    const payload = {
      action: "ASSIGN_STEP",
      assignToId: value,
    };
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          step.carePathInstanceId
        )}&carePathStepId=${encodeURIComponent(step.id)}`,
        payload,
        { headers: {} }
      )
      .then((res) => {
        if (res.data.fail && res.data.type === "FAIL") {
          setErrorMessageForCoord([
            "Can't change care coordinator. This coordinator not able to see this patient",
          ]);
          step.assignedTo = {};
        } else {
          step.assignedTo = res.data.result.assignedTo;
        }
        setCoordinatorChange(false);
      })
      .catch((err) => {
        setCoordinatorChange(false);
      });
  };

  const handleSelect = (eventKey, event, step) => { 
    switch (eventKey) { 
        case 'before': 
        case 'after': 
        case 'edit': 
            openCreationView(eventKey, step);
            break; 
        case 'up': 
        case 'down': 
        case 'remove': 
            handleStepMovement(eventKey, step); 
            break; 
        default: 
          handleStatusChange(eventKey, step) 
    } 
  }

  const handleUploadFile = (e) => {
    const fileIds = [...e]?.map(filter => ({fileId: filter.fileId}))
    const oldFileIds = [...step.files]?.map(filter => ({fileId: filter?.metadata?.fileId}))
    axios.post(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-path-step/upload-documentation`,
      {
        carePathStepId: step?.id,
        uploadedFiles: [...oldFileIds, ...fileIds]
      },
      {
        headers: {},
      }
    ).then(() => {
      setFiles([
        ...e.map(f => ({metadata: {fileId: f.fileId, fileName: f.fileName}})),
        ...step.files
      ])
    });
  }

  return (
    <Row className="mb-3">
      {loading ? null : (
        <Col>
          {showBeforeForm && (
            <>
              <br />
              <PopUpComponent
                toggleShow={setShowBeforeForm}
                step={editStep ? step : null}
                saveNewStep={saveNewStep}
                editExistingStep={editExistingStep}
                index={index}
                action={selectedAction}
                closeOpenForm={closeOpenForm}
                number={step.stepNumber}
              />
              <br />
            </>
          )}
          <Accordion>
            <Card className="overflow-inherit">
              <Card.Header id={`${componentName}-carePathStepHead-${index}`} eventKey="0">
                <PlusMinusToggle id={`${componentName}-carePathStepHeader-${index}`} eventKey="0">
                  <span className="d-inline-block align-top me-2">
                    <Badge
                      id={`${componentName}-carePathStepStatus-${index}`}
                      style={{
                        color: getStatusColors(step.carePathStepStatus).font
                      }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            'background-color',
                            getStatusColors(step.carePathStepStatus).bg,
                            'important'
                          )
                        }
                      }}
                    >
                      <i
                        className={
                          step.carePathStepStatus === "OVERDUE"
                            ? "far fa-times-circle"
                            : step.carePathStepStatus === "AWAITING TRIGGER"
                              ? "fas fa-ellipsis-h"
                              : "far fa-check-circle"
                        }
                      ></i>{" "}
                      {step.stepCustomState && (step.stepCustomState.behavesLike === step.carePathStepStatus)  ? step.stepCustomState.status: step.carePathStepStatus}
                    </Badge>
                  </span>
                  <span className="d-inline-block">
                    <span id={`${componentName}-carePathStepTitle-${index}`} className="fw-bold d-block">
                      Step {step.stepNumber}: {step.title}
                      {step.required && (
                        <span className="text-danger">*</span>
                      )}
                    </span>
                    <span id={`${componentName}-carePathStepDate-${index}`} className="d-block font-12">
                      {formatDate(step.dueDate)}
                    </span>
                  </span>
                </PlusMinusToggle>
                <Dropdown className="float-end" onSelect={(eventKey, event) => handleSelect(eventKey, event, step)}>
                  <Dropdown.Toggle
                    size="sm"
                    variant="secondary me-0"
                    id={`${componentName}-carePathStepActions-${index}`}
                    disabled={showAfterForm || showBeforeForm || !authorizedCareProgramWrite}
                  >
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    {findCustomStatusLabel(step)}
                    

                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsBefore-${index}`}
                      eventKey="before"
                    >
                      Add Step Before
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsAfter-${index}`}
                      eventKey="after"
                    >
                      Add Step After
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsEdit-${index}`}
                      eventKey="edit"
                    >
                      Edit Step
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsMoveUp-${index}`}
                      eventKey="up"
                      disabled={index === 0}
                    >
                      Move Up
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsMoveDown-${index}`}
                      eventKey="down"
                      disabled={index + 1 === pathsLength}
                    >
                      Move Down
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${componentName}-carePathStepActionsRemove-${index}`}
                      eventKey="remove"
                    >
                      Remove Step
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col md={4}>
                      <StepCoordinator
                        patientsSelected={patientsSelected}
                        handleChange={handleCoordinatorChange}
                        step={step}
                        index={index}
                        coordinatorChange={coordinatorChange}
                        setCoordinatorChange={setCoordinatorChange}
                        errorMessageForCoord={errorMessageForCoord}
                        setErrorMessageForCoord={setErrorMessageForCoord}
                        selectedCarePath={selectedPath}
                        handleStatusChange={handleStatusChange}
                      />
                    </Col>
                    <Col id={`${componentName}-carePathStepDescription-${index}`} md={4}>
                      <Row>
                        <Form.Label id={`${componentName}-carePathStepDescriptionLabel-${index}`} className="d-block">Description</Form.Label>
                        <span id={`${componentName}-carePathStepDescriptionValue-${index}`}>{step.description}</span>
                      </Row>
                      <Row>
                        <Form.Label id={`${componentName}-carePathStepDescriptionLabel-${index}`} className="d-block mt-222">Attachments</Form.Label>
                        <Col>
                          {files.map((file, idx) =>
                              <Row>
                                <a id={`${componentName}-carestepFile-${idx}`} href="#"
                                  onClick={(event) => {
                                    handleClickOnLink(event, file?.metadata?.fileId, file?.metadata?.fileName)
                                  }}>
                                  {file?.metadata?.fileName ?? ""}
                                </a>
                              </Row>
                            )
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col id={`${componentName}-carePathStepNotes-${index}`} md={4}>
                      <Form.Label id={`${componentName}-carePathStepNotesLabel-${index}`} className="fw-bold d-block">
                        Notes {onSavingNote && <EQHSpinner />}
                      </Form.Label>
                      {!isEmpty(step.notes) &&
                        step.notes.map((note, idx) => {
                          return (
                            <Row id={`${componentName}-Note-${idx}`} className="mb-3 px-3">
                              <Col className="border py-2 bg-light">
                                <Row>
                                  <Col id={`${componentName}-NotesName-${idx}`}>{note.author.firstName}{" "}{note.author.lastName}</Col>
                                  <Col id={`${componentName}-NotesDate-${idx}`} className="text-end">
                                    <small>{formatDateTime(new Date(note.creationDate).toISOString())}</small>
                                  </Col>
                                </Row>
                                <hr className="my-2" />
                                <Row>
                                  <Col id={`${componentName}-NotesText-${idx}`}>{note.note}</Col>
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                      <Form.Control
                        as="textarea"
                        ref={textBoxRefForNote}
                        onFocus={() => setShowAdd(false)}
                        onChange={handleTextAreaChange}
                        id={`${componentName}-carePathNotesBox-${index}`}
                        rows="3"
                        className="mb-3"
                      />
           			  {errorMessageForNote && errorMessageForNote.length > 0 &&
          				<ToastOverlay>
            				<EQHErrorToast
              					errors={errorMessageForNote}
              					handleClose={() => setErrorMessageForNote([])}
              					styleRemove={true}
            				/>
            			</ToastOverlay>
                  }     <div style={{ position: 'absolute', bottom: '14px', left: '14px' }}>
                          <FileUpload
                            disabled={!authorizedCareProgramWrite}
                            label="Upload Files"
                            showIcon={false}
                            btnClass="text-primary bg-light border-light"
                            onChange={e => handleUploadFile(e)}
                            clearStateProp={0}
                            showFileList={false}
                          />
                        </div>
                        <Button
                          onClick={onAdd} disabled={showAdd || !textAreaValue.trim() || !authorizedCareProgramWrite}
                          variant="primary"
                          id={`${componentName}-carePathAddNotes-${index}`}
                          size="sm"
                          className="me-2"
                        >
                          Add
                        </Button>
                      {showAdd && (
                        <Button
                          onClick={() => cancelNote()}
                          variant="secondary"
                          id={`${componentName}-carePathCancelNotes-${index}`}
                          size="sm"
                        >
                          Cancel
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          {showAfterForm && (
            <>
              <br />
              <PopUpComponent
                toggleShow={setShowAfterForm}
                step={editStep ? step : null}
                saveNewStep={saveNewStep}
                editExistingStep={editExistingStep}
                index={index}
                action={selectedAction}
                closeOpenForm={closeOpenForm}
                number={step.stepNumber}
              />
              <br />
            </>
          )}
        </Col>
      )}
    </Row>
  );
};

const PopUpComponent = ({
  toggleShow,
  step,
  saveNewStep,
  editExistingStep,
  index,
  action,
  closeOpenForm,
  number,
}) => {
  const getCleanFormData = () => {
    return {
      title: "",
      completeBy: 0,
      trigger: "",
      description: "",
      timing: "BEFORE",
      isSubStep: false,
      dueDate: null,
      stepDate: null,
      customStatuses: [],
      applyModificator: false,
      conditions: [],
      id: null,
      carePathInstanceId: "",
      carePathStepStatus: isEmpty(step?.carePathStepStatus)
        ? "AWAITING_TRIGGER"
        : step.carePathStepStatus,
      stepNumber: null,
      notes: [],
      lastModifiedBy: null,
      assignedTo: null,
      userModified: null,
      lastStep: false,
      dueDateSelected: step?.dueDateSelected,
      manuallyAddedOn: new Date(),
      stepCustomState: null,
      closeCarePathAfterCondition: false,
      hideStep: false,
      subStep: false,
    };
  };

  const [formData, setFormData] = useState(getCleanFormData());
  //const [dateOrTrigger, setDateOrTrigger] = useState(step.dueDateSelected);
  const [lockSave, setLockSave] = useState(true);

  useEffect(() => {
    if (step) {
      setFormData({
        ...formData,
        assignedTo: step.assignedTo,
        completeBy: step.completeBy,
        description: step.description,
        dueDate: isEmpty(step?.dueDate) ? null : new Date(step.dueDate),
        stepNumber: step.stepNumber,
        notes: step.notes,
        timing: step.timing,
        title: step.title,
        trigger: step.trigger,
        needToScheduleAppointment: step.needToScheduleAppointment,
      });
    }
  }, []);

  const updateFormDateField = (e) => {
    let newState = formData;
    newState.dueDate = e;
    setFormData({
      ...newState,
    });
    updateFormFieldMain(newState);
  };

  const updateFormField = (e) => {
    let newState = formData;
    newState[e.target.name] = e.target.value;
    setFormData({ ...newState });
    updateFormFieldMain(newState);
  };

  const updateFormFieldDynamic = (e, key, value) => {
    const newFormData = {
      ...formData,
      [key]: value,
    };
    setFormData(newFormData);
    updateFormFieldMain(e);
  };

  const updateFormFieldMain = (newState) => {
    if (step) {
      setLockSave(false);
    } else if (
      newState?.title?.length &&
      newState?.timing?.length &&
      newState?.trigger?.length
    ) {
      setLockSave(false);
    } else if (newState?.title?.length && newState?.dueDate) {
      setLockSave(false);
    } else {
      setLockSave(true);
    }
  };

  const saveStep = () => {
    if (step) {
      editExistingStep(step, formData, index);
    } else {
      saveNewStep(formData, index, action, number);
    }

    closeOpenForm();
  };

  return (
    <Row id={`${componentName}-carePathEditStep-${index}`} className="border mx-0 pt-3">
      <Col>
        <Form.Group>
          <Form.Label id={`${componentName}-carePathESNameLabel-${index}`} className="fw-bold d-block">
            Step<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            id={`${componentName}-carePathESNameValue-${index}`}
            value={formData.title}
            onChange={(e) => updateFormField(e)}
          />
        </Form.Group>

        <Form>
          {["radio"].map((type) => (
            <div id={`${componentName}-carePathESAction-${index}`} key={`${componentName}-carePathESAction-${index}`} className="mb-3">
              <Form.Check
                custom
                inline
                label="Trigger Event"
                type={type}
                id={`${componentName}-carePathESActionTE-${index}`}
                onClick={(e) =>
                  updateFormFieldDynamic(e, "dueDateSelected", false)
                }
                checked={!formData.dueDateSelected}
              />
              <Form.Check
                custom
                inline
                label="Due Date"
                type={type}
                id={`${componentName}-carePathESActionDD-${index}`}
                onClick={(e) =>
                  updateFormFieldDynamic(e, "dueDateSelected", true)
                }
                checked={formData.dueDateSelected}
              />
            </div>
          ))}
        </Form>

        <Row>
          <Form.Group>
            <Form.Label id={`${componentName}-carePathESDaysLabel-${index}`}>
              Days<span className="text-danger">*</span>
            </Form.Label>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Control
              type="number"
              disabled={formData.dueDateSelected}
              name="completeBy"
              id={`${componentName}-carePathESDaysValue-${index}`}
              value={formData.completeBy}
              onChange={(e) => updateFormField(e)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Control
              as="select"
              defaultValue="Before"
              name="timing"
              id={`${componentName}-carePathESDaysOptions-${index}`}
              onChange={(e) => updateFormField(e)}
              disabled={formData.dueDateSelected}
              value={formData.timing}
            >
              <option id={`${componentName}-carePathESDaysBefore-${index}`} value="BEFORE">Before</option>
              <option id={`${componentName}-carePathESDaysAfter-${index}`} value="AFTER">After</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <DatePickerStyled>
              <DatePicker
                disabled={!formData.dueDateSelected}
                name="dueDate"
                value={formData.dueDate}
                selected={formData.dueDate}
                onChange={(e) => updateFormDateField(e)}
                id={`${componentName}-carePathESDaysDate-${index}`}
                className="form-control"
                popperPlacement="auto-left"
                autoComplete="off"
              />
            </DatePickerStyled>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Control
              as="select"
              defaultValue=""
              name="trigger"
              id={`${componentName}-carePathESDaysTrigger-${index}`}
              onChange={(e) => updateFormField(e)}
              disabled={formData.dueDateSelected}
              value={formData.trigger}
            >
              <option selected disabled></option>
              {carePathValues.map((item, index) => (
                <option id={`${componentName}-carePathESDaysTriggerItems-${index}`} value={item.value}>{item.text}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Form.Group id={`${componentName}-carePathESDaysDesc-${index}`}>
          <Form.Label id={`${componentName}-carePathESDaysDescLabel-${index}`} className="fw-bold d-block">
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="description"
            id={`${componentName}-carePathESDaysDescValue-${index}`}
            value={formData.description}
            onChange={(e) => updateFormField(e)}
          />
        </Form.Group>

        <Row className="float-end">
          <Form.Group>
            <Button id={`${componentName}-carePathESCancel-${index}`}
              onClick={() => {
                toggleShow(false);
                setFormData(getCleanFormData());
              }}
            >
              Cancel
            </Button>
          </Form.Group>
          <Form.Group className="ms-2">
            <Button id={`${componentName}-carePathESSave-${index}`} onClick={() => saveStep()} disabled={lockSave}>
              Save
            </Button>
          </Form.Group>
        </Row>
      </Col>
    </Row>
  );
};

const StepCoordinator = ({
  patientsSelected,
  handleChange,
  step,
  coordinatorChange,
  setCoordinatorChange,
  errorMessageForCoord,
  setErrorMessageForCoord,
  selectedCarePath,
  handleStatusChange,
}) => {
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [options, setOptions] = useState([]);
  const auth = useAuth();
  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const clientId =
        patientsSelected && patientsSelected.clientId
          ? patientsSelected.clientId
          : "";
      const url =
        baseURL +
        "/search-coordinators" +
        (query ? "?name=" + query : "") +
        (clientId ? "&clientId=" + clientId : "") +
        ("&patientId=" + encodeURIComponent(patientsSelected.id));
      setIsLoadingComp(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp.data.map((i) => ({
              value: i.value,
              text: i.text,
            }));
          }
          setOptions(options);
          setIsLoadingComp(false);
        });
    }
  };
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  return (
    <Form.Group controlId="carepathcoordicator">
      <Form.Label className="fw-bold d-block">
        Step Coordinator {coordinatorChange && <EQHSpinner />}
        {errorMessageForCoord && (
          <ToastOverlay>
            <EQHErrorToast
              errors={errorMessageForCoord}
              handleClose={() => setErrorMessageForCoord([])}
              styleRemove={true}
            />
          </ToastOverlay>
        )}
      </Form.Label>
      <StyledTypeAhead
        inputProps={{
          id: `${componentName}-CarePathStepCoordinatorValue`
        }}
        disabled={!authorizedCareProgramWrite}
        labelKey="text"
        isLoading={isLoadingComp}
        onSearch={asyncsearch}
        options={options}
        onChange={(e) => handleChange(e)}
        placeholder={
          step.assignedTo
            ? `${step.assignedTo.firstName} ${step.assignedTo.lastName}`
            : "Search coordinators by name"
        }
      />
      {step.needToScheduleAppointment &&
        step.carePathStepStatus != "COMPLETED" && (
          <StyledMenuBtns
            disabled={!authorizedCareProgramWrite}
            variant="outline-secondary"
            onClick={() => setShowAppointmentModal(true)}
          >
            Appointment
          </StyledMenuBtns>
        )}
      <Modals
        show={showAppointmentModal}
        onHide={() => setShowAppointmentModal(false)}
        title="Appointment"
        size="xl"
        completeBtn={"CONFIRM"}
        hideFooter={true}
        body={
          <AppointmentModal
            patient={patientsSelected}
            parent="CPCard"
            carePath={selectedCarePath}
            onHideClicked={() => {
              setShowAppointmentModal(false);
              handleStatusChange("COMPLETED", step);
            }}
            fromPatientProfile={true}
            onCancelClick={() => setShowAppointmentModal(false)}
          />
        }
      />
    </Form.Group>
  );
};
const ToastOverlay = styled.div`
  z-index: 999;
  position: absolute;
  right: 30px;
  bottom: 90px;
  width: 100%;

  > * {
    margin-left: auto;
  }
`;

const CloseGapSelect = ({
  path,
  close,
  patients,
  removePath,
  patient,
  openPrimaryModal,
  selectedReason,
  setSelectedReason,
  setShowConfirmExpiredModal,
  selectedExpired,
  setSelectedExpired,
  selectedExpiredDate,
  setSelectedExpiredDate,
  selectedExpiredSource,
  setSelectedExpiredSource,
  reloadPatientOverview,
  index
}) => {
  const dispatch = useDispatch();
  const { closePathReasons } = useSelector((state) => state.patients);

  useEffect(() => {
    dispatch(retrieveCloseCpReasons());
  }, []);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
    // May be implemented in the future for notification purposes
    //if (e.target.value == "Patient expired" && !patient.expired) {
    //  setShowConfirmExpiredModal(true);
    //}
  };

  const handleClose = () => {
    closeCarePath(path.id, selectedReason, selectedExpired, selectedExpiredDate, selectedExpiredSource)
      .then((res) => {
        removePath();
        close();
        if (res.data.assignPrimary) {
          openPrimaryModal(res.data.remainingCarePaths);
        }
        if (selectedExpired) {
          reloadPatientOverview();
        }
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <Form className="mt-2">
        {closePathReasons && closePathReasons.length ? (
          <><Form.Control
            size="sm"
            as="select"
            id={`${componentName}-carePathReasonValue-${index}`}
            onChange={(e) => handleReasonChange(e)}
            value={selectedReason}
          >
            <option value="Actions" selected hidden></option>
            {closePathReasons.map((reason, idx) => (
              <option id={`${componentName}-carePathReasonItem-${idx}`} value={reason}>{reason}</option>
            ))}
          </Form.Control>
            {selectedExpired ? (
              <Row>
                <Form.Label
                  id={`${componentName}-ExpiredDateLabel`}
                  className="d-block mt-1"
                >
                  Expired Date
                </Form.Label>
                <EQHDatePicker
                  id={`${componentName}-ExpiredDateValue`}
                  className="mt-1"
                  onChange={(e) => {
                    setSelectedExpiredDate(e.strDate);
                  }}
                  min={null}
                  max={format(new Date(), "yyyy-MM-dd")}
                  value={selectedExpiredDate}
                />
                <Form.Label
                  id={`${componentName}-ExpiredSourceLabel`}
                  className="d-block mt-1"
                >
                  Confirmation Source
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  id={`${componentName}-carePathExpiredSourceValue-${index}`}
                  className="mt-1"
                  onChange={(e) => setSelectedExpiredSource(e.target.value)}
                  value={selectedExpiredSource}
                >
                  <option value="Sources" selected hidden></option>
                  {expiredSources.map((source, idx) => (
                    <option id={`${componentName}-carePathExpiredSourceItem-${idx}`} value={source.value}>{source.value}</option>
                  ))}
                </Form.Control>
              </Row>) : (<></>)}</>
        ) : (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Form>
      <div className="modal-footer">
        <Button id={`${componentName}-carePathReasonCancel-${index}`} className="btn" data-dismiss="modal" onClick={() => close()}>
          Cancel
        </Button>
        <Button id={`${componentName}-carePathReasonConfirm-${index}`}
          disabled={!selectedReason.length}
          className="btn btn-primary"
          onClick={() => handleClose()}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

const StyledMenuBtns = styled(Button)`
  margin-right: 10px;
  background: white !important;
  color: #188dcd;

  :hover {
    color: #188dcd;
    border: 1px solid #188dcd !important;
  }
`;

export { CarePath };
