import {actions, initialState} from "./referralsActions";

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_CURRENT_SPECIALIST_REFERRAL_STEP:
            return {
                ...state,
                currentSpecialistReferralStep: action.payload
            }
        case actions.SET_SPECIALITIES:
            return {
                ...state,
                specialities: action.payload
            }
        case actions.SET_SELECTED_SPECIALITY:
            return {
                ...state,
                selectedSpeciality: action.payload
            }
        case actions.SET_PAYERS:
            return {
                ...state,
                payers: action.payload
            }
        case actions.SET_SELECTED_PAYER:
            return {
                ...state,
                selectedPayer: action.payload
            }
        case actions.SET_PRACTICES:
            return {
                ...state,
                practices: action.payload
            }
        case actions.SET_ASSOCIATED_PROVIDERS:
            return {
                ...state,
                associatedProviders: action.payload
            }
        case actions.TOGGLE_LOADING_PRACTICES:
            return {
                ...state,
                isLoadingPractices: action.payload
            }
        case actions.TOGGLE_LOADING_ASSOCIATED_PROVIDERS:
            return {
                ...state,
                isLoadingAssociatedProviders: action.payload
            }
        case actions.SET_SELECTED_PRACTICE:
            return {
                ...state,
                selectedPractice: action.payload
            }
        case actions.SET_SELECTED_ASSOCIATED_PROVIDER:
            return {
                ...state,
                selectedAssociatedProvider: action.payload
            }
        case actions.SET_ZIP_CODE:
            return {
                ...state,
                zipCode: action.payload
            }
        case actions.SET_GENDERS:
            return {
                ...state,
                genders: action.payload
            }
        case actions.SET_SELECTED_GENDER:
            return {
                ...state,
                selectedGender: action.payload
            }
        case actions.INIT_SPECIALIST_REFERRAL_VARS:
            return {
                ...initialState
            }
        case actions.SET_PROVIDERS:
            return {
                ...state,
                providers: action.payload
            }
        case actions.SET_SELECTED_PROVIDER:
            return {
                ...state,
                selectedProvider: action.payload
            }
        case actions.SET_VISIT_TYPES:
            return {
                ...state,
                visitTypes: action.payload
            }
        case actions.SET_SELECTED_VISIT_TYPE:
            return {
                ...state,
                selectedVisitType: action.payload
            }
        case actions.SET_URGENCY_OPTIONS:
            return {
                ...state,
                urgencyOptions: action.payload
            }
        case actions.SET_SELECTED_URGENCY:
            return {
                ...state,
                selectedUrgency: action.payload
            }
        case actions.SET_NOTE:
            return {
                ...state,
                note: action.payload
            }
        case actions.SET_UPLOADED_FILES:
            return {
                ...state,
                uploadedFiles: action.payload
            }
        case actions.SET_ICD10_OPTIONS:
            return {
                ...state,
                icd10Options: action.payload
            }
        case actions.TOGGLE_LOADING_ICD10_OPTION:
            return {
                ...state,
                icd10List: action.payload
            }
        case actions.SET_SELECTED_ICD10_OPTION:
            return {
                ...state,
                icd10List: action.payload
            }
        case actions.SET_SUBSTANCE_ABUSE_ICD10:
            return {
                ...state,
                substanceAbuseICD10: action.payload
            }
        case actions.ADD_MORE_ICD10:
            return {
                ...state,
                icd10List: [...state.icd10List, {...action.payload}]
            }
        case actions.TOGGLE_SPECIALIST_REFERRAL_SAVED:
            return {
                ...state,
                isSpecialistReferralSaved: action.payload
            }
        case actions.TOGGLE_LOADING_SPECIALITIES:
            return {
                ...state,
                isLoadingSpecialities: action.payload
            }
        case actions.TOGGLE_LOADING_PAYERS:
            return {
                ...state,
                isLoadingPayers: action.payload
            }
        default:
            return state;
    }
}