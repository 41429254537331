import {
    UPDATE_IS_SHOWING_FORM,
    UPDATE_FIELD,
    GET_PATIENTS,
    SHOW_ALERT,
    HIDE_ALERT,
    HIDE_PATIENTS_FOUND,
    HIDE_POPULATION_SEARCH_VIEW,
    SHOW_REQUEST_ALERT,
    UPDATE_SHOW_SPINNER,
    USER_ADDED_SUCCESFUL,
    RESET_GLOBAL_FORM,
    TEMP_HIDE_PATIENTS_FOUND
} from "../types";
import axios from "../../utils/apiClient";
import { getPatientProfileLink } from "../../utils/util";
const isEmpty = require("is-empty");

const SEARCH_BY_MEMBER_ID_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/global-search/patients-member-id-dob";
const SEARCH_BY_NAMES_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/global-search/patients-fullname-dob";
const SEARCH_TEXT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/global-search/patients";
const ADD_USER_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/global-search/add-member-to-user";

export const showPopulationSearchForm = () => (dispatch) => {
    dispatch({type: UPDATE_IS_SHOWING_FORM, payload: true});
}

export const hidePopulationSearchForm = () => (dispatch) => {
    dispatch({type: UPDATE_IS_SHOWING_FORM, payload: false});
    resetGlobalForm()
}

export const resetGlobalForm = () => (dispatch) => {
    dispatch({type: RESET_GLOBAL_FORM, payload: null})
}

export const hideAlert = () => (dispatch) => {
    dispatch({type: HIDE_ALERT, payload: null});
}

export const hidePatientsFound = () => (dispatch) => {
    dispatch({type: HIDE_PATIENTS_FOUND, payload: null});
    resetGlobalForm();
}

export const temporarilyHidePGS = (val) => (dispatch) => {
    dispatch({type: TEMP_HIDE_PATIENTS_FOUND, payload: val});
}

export const onChangeField = (event, fieldName) => (dispatch) => {
    let param = {
        event: event,
        fieldName: fieldName
    };

    dispatch({type: UPDATE_FIELD, payload: param});
}

export const showPatient = (history, patient, location) => (dispatch) => {

    dispatch({type: HIDE_POPULATION_SEARCH_VIEW, payload: null});

    window.location.href = getPatientProfileLink(patient.id, location);
};

export const getPatients = (data, auth) => (dispatch) => {
    let url = null;
    if(!isEmpty(data.dateOfBirthFirstOption) && data.dateOfBirthFirstOption.getFullYear() > 9999) {
        dispatch({type: SHOW_ALERT, payload: "Date of birth must be in the format of MM/DD/YYYY"});
        return;
    }
    if(!isEmpty(data.dateOfBirthSecondOption) && data.dateOfBirthSecondOption.getFullYear() > 9999) {
        dispatch({type: SHOW_ALERT, payload: "Date of birth must be in the format of MM/DD/YYYY"});
        return;
    }

    else if(!isEmpty(data.dateOfBirthSecondOption) && !isEmpty(data.memberId)){
        url = SEARCH_BY_MEMBER_ID_URL;
    } else if(!isEmpty(data.dateOfBirthFirstOption) && !isEmpty(data.firstName) && !isEmpty(data.lastName)){
        url = SEARCH_BY_NAMES_URL;
    }

    if(url == null){
        if(isEmpty(data.dateOfBirthFirstOption) && !isEmpty(data.firstName) && !isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter date of birth"});
        } else if(!isEmpty(data.dateOfBirthFirstOption) && isEmpty(data.firstName) && !isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter first name"});
        } else if(!isEmpty(data.dateOfBirthFirstOption) && !isEmpty(data.firstName) && isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter last name"});
        } else if(!isEmpty(data.dateOfBirthFirstOption) && isEmpty(data.firstName) && isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter first and last name"});
        } else if(isEmpty(data.dateOfBirthFirstOption) && isEmpty(data.firstName) && !isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter first name and date of birth"});
        } else if(isEmpty(data.dateOfBirthFirstOption) && !isEmpty(data.firstName) && isEmpty(data.lastName)){
            dispatch({type: SHOW_ALERT, payload: "Enter last name and date of birth"});
        } else if (!isEmpty(data.dateOfBirthSecondOption) && isEmpty(data.memberId)) {
            dispatch({type: SHOW_ALERT, payload: "Enter member id"});
        } else if(isEmpty(data.dateOfBirthSecondOption) && !isEmpty(data.memberId)) {
            dispatch({type: SHOW_ALERT, payload: "Enter date of birth"});
        } else {
            dispatch({type: SHOW_ALERT, payload: "Enter valid input to find a member"});
        }
    } else{
        executeSearchRequest(dispatch, url, data, true, auth);
    }
}

export const searchFromInputText = (e, searchText, auth) => (dispatch) => {
    if (e.key === 'Enter' && searchText) {
        dispatch(onChangeField(searchText.trim(), "searchPlaceHolder"))
        executeSearchRequest(dispatch, SEARCH_TEXT_URL, searchText.trim(), false, auth, "text/plain");
    }
}

export const searchFromInputTextOnChange = (searchText, auth) => (dispatch) => {
    executeSearchRequest(dispatch, SEARCH_TEXT_URL, searchText.trim(), false, auth, "text/plain");
}

const executeSearchRequest = (dispatch, url, dataRequest, showFormOnCloseAlert, auth, contentType) => {
    dispatch({type: UPDATE_SHOW_SPINNER, payload: true});

    if(isEmpty(contentType)){
        contentType = "application/json";
    }

    axios.post(url, dataRequest, {
        headers: {
            "Content-Type": contentType
        },
    })
        .then((response) => {
            if (response.data.errorMessage) {
                var payload = {};
                payload.message = response.data.errorMessage;
                payload.showFormOnCloseAlert = showFormOnCloseAlert;
                dispatch({type: SHOW_REQUEST_ALERT, payload: payload});
            }
            else {
                dispatch({type: GET_PATIENTS, payload: response.data});
            }
        })
        .catch((error) => {
            var payload = {};
            payload.message = error.response.data.message;
            payload.showFormOnCloseAlert = showFormOnCloseAlert;

            if (payload.message.startsWith("For security reasons")) {
                payload.doLogout = true;
            }

            dispatch({type: SHOW_REQUEST_ALERT, payload: payload});
        });
}

export const AddMemberToUser = (patientId) => (dispatch) => {

    axios.put(ADD_USER_URL + "?patientId=" + patientId, null,{
        headers: {
            "Content-Type": "application/json"
        },
    }).then((response) => {
        dispatch({ type: USER_ADDED_SUCCESFUL, payload: true });
    }).catch((error) => {
        var payload = {};
        payload.message = error.response.data.message;
        payload.showFormOnCloseAlert = false;

        if (payload.message.startsWith("For security reasons")) {
            payload.doLogout = true;
        }

        dispatch({type: SHOW_REQUEST_ALERT, payload: payload});
        return false;
    });
}