import {actions, initialState} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_NOTES_SEARCH:
      return {
        ...state,
        notesSearch: action.payload
      };
    case actions.SET_NOTES_LIST:
      return {
        ...state,
        notesList: action.payload,
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };
    case actions.SET_USER_NAME_LIST_FILTER:
      return {
        ...state,
        userNameListFilter: action.payload,
      };
    case actions.SET_SHOW_ADD_NOTE:
      return {
        ...state,
        showAddNote: action.payload,
      };
    case actions.SET_IS_ADDING_NOTE:
      return {
        ...state,
        isAddingNote: action.payload,
      };
    case actions.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actions.SET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
};