import React, {useState} from 'react'
import {Collapse, Nav} from "react-bootstrap";

const componentName = "CollapsibleNavItem";

/**
 * CollapsibleNavItem component shows' menu components that are expandable via an option
 * Example:
 *  Full Patient (collapsible option - main menu)
 *      Patient Details (clickable sub menu)
 *      Event Timeline (clickable sub menu)
 *      Referrals (clickable sub menu)
 *
 * @param {Object} title - The title or description of the main menu.
 * @param {Object} children - Children that will be hidden by default.
 * @param {Object} onOpen - A callback that is called when the menu is expanded.
 * @param {Object} eventKeySelected - The currently selected submenu.
 * @returns {JSX.Element} A collapsible menu.
 */
const CollapsibleNavItem = ({
  title,
  children,
  onOpen,
  eventKeySelected,
  defaultOpen=false
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const childrenEventKeys = React.Children.map(children,
    child => child?.props?.eventKey) ?? [];

  const handleOnClick = () => {
    const isOpen = !open;
    setOpen(isOpen);

    if (!childrenEventKeys.includes(eventKeySelected)) {
      onOpen && onOpen(isOpen)
    }
  }

  return (
    <>
      <Nav.Item onClick={() => handleOnClick()}>
        <Nav.Link
          className="d-flex justify-content-between align-items-center pe-0">
          {title}
          {!open
            ? <i id={`${componentName}-ccPlus`}
                 className="fa-solid fa-chevron-right fa-xs me-4"/>
            : <i id={`${componentName}-ccMinus`}
                 className="fa-solid fa-chevron-down fa-xs me-4"/>
          }
        </Nav.Link>
      </Nav.Item>

      <Collapse in={open} className="collapsible-nav-item" style={{ paddingLeft: '20px' }}>
        <div>
          <Nav.Item>
            {children}
          </Nav.Item>
        </div>
      </Collapse>
    </>
  );
};

export {CollapsibleNavItem};
