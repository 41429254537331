import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal} from 'react-bootstrap';
import {isEmpty, trim} from 'lodash'

import {useEQHToastContext} from "../../toast/EQHToast.context";
import {useCustomWorklistContext} from "../context/CustomWorklist.context";

const WORKLIST_NAME_PATTERN = new RegExp(/^([a-zA-Z0-9 ]+)$/);
const MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_FOR_NAME = 100;
const MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_FOR_NOTES = 1000;

function SaveAsCustomWorklist({
  quickListName,
  componentName,
  patientSearch,
  sendEvent
}) {
  const {
    isSaveAsCustomWorklistEnabled,
    toggleSaveAsCustomWorklist,
    saveCustomWorklist,
    customWorklistError,
    setCustomWorkListError
  } = useCustomWorklistContext();
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');

  const {removeNotification, setNotification} = useEQHToastContext();

  useEffect(() => {
    if (customWorklistError?.length > 0) {
      setNotification(customWorklistError[0], customWorklistError[1]);
    } else {
      removeNotification();
    }
  }, [customWorklistError])

  useEffect(() => {
    if (isSaveAsCustomWorklistEnabled) {
      resetState()
    }
  }, [isSaveAsCustomWorklistEnabled])

  const patientRequest = {
    filterParams: patientSearch?.filterParams,
    sortParams: patientSearch?.sortParams,
    pageNumber: patientSearch?.pageNumber,
    pageSize: patientSearch?.pageSize,
    quickListName: quickListName
  }

  const getErrorMessage = () => {
    if (!isEmpty(notes) && notes.length
      > MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_FOR_NOTES) {
      return 'Notes exceed the maximum character length allowed';
    } else if (isEmpty(name)) {
      return 'You must provide a value for the name field';
    } else if (!WORKLIST_NAME_PATTERN.test(name) && name.length
      > MAXIMUM_AMOUNT_OF_CHARACTERS_PERMITTED_FOR_NAME) {
      return 'Worklist Name must contain no more than 100 alphanumeric characters';
    }
    return null;
  }

  const saveAction = async () => {
    const errorMessage = getErrorMessage();
    if (!isEmpty(errorMessage)) {
      setNotification("Error", errorMessage);
    } else {
      await saveCustomWorklist(quickListName, name, notes, patientRequest);
      sendEvent('CONFIRM_SAVE_WORKLIST');
    }
  }

  const resetState = () => {
    setNotes('');
    setName('');
    setCustomWorkListError([]);
    removeNotification();
  }

  const closeModal = () => {
    resetState();
    toggleSaveAsCustomWorklist(false);
  }

  return (
    <Modal
      id={`${componentName}-patientSaveAsCustomWorklistConfirmModal`}
      size="md"
      show={isSaveAsCustomWorklistEnabled}
      onHide={() => closeModal()}
      centered
    >
      <Modal.Header>
        <div className="m-4 mb-0">
          <Modal.Title
            id={`${componentName}-patientSaveAsCustomWorklistConfirmTitle`}>Save
            Custom Worklist</Modal.Title>
          <button
            id={`${componentName}-patientSaveAsCustomWorklistConfirmModalClose`}
            type="button"
            className="btn-close position-absolute"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
            style={{right: '18px', top: '18px', fontSize: '10px'}}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="m-4">
          <Col className="mb-3">
            <label id={`${componentName}-saveAsCustomWorklistNotesLabel`}
                   className="d-block">Worklist Name</label>
            <Form.Control
              id={`${componentName}-saveAsCustomWorklistNameValue`}
              max="100"
              as="input"
              rows={3}
              onChange={e => setName(e.target.value)}
            />
          </Col>
        </div>

        <div className="m-4">
          <Col className="mb-3">
            <label id={`${componentName}-saveAsCustomWorklistNotesLabel`}
                   className="d-block">Notes</label>
            <Form.Control
              id={`${componentName}-saveAsCustomWorklistNotesValue`}
              max="1000"
              as="textarea"
              rows={6}
              onChange={e => setNotes(e.target.value)}
            />
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer className="m-4">
        <Button id={`${componentName}-patientSaveAsCustomWorklistNoBtn`}
                variant="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-patientSaveAsCustomWorklistYesBtn`}
          variant="primary"
          disabled={isEmpty(trim(name))}
          onClick={() => saveAction()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SaveAsCustomWorklist;