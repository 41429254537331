export const initialState = {
    patient_post: {},
    error_from_services:{},
    patient_get: {},
    patient_object: {},
    loading: false,
    loading_post: false,
    showAddModal: false,
    modalType: 'Suspect'
};

export const actions = {
    SELECT_PATIENT_POST: 'SELECT_PATIENT_POST',
    SELECT_PATIENT_GET: 'SELECT_PATIENT_GET',
    TOGGLE_LOADING: 'TOGGLE_LOADING',
    TOGGLE_LOADING_POST: 'TOGGLE_LOADING_POST',
    TOGGLE_SHOW_ADD_MODAL: 'TOGGLE_SHOW_ADD_MODAL',
    SET_MODAL_TYPE: 'SET_MODAL_TYPE',
    PERSIST_PATIENT_OBJECT: 'PERSIST_PATIENT_OBJECT',
    ERROR_FROM_SERVICES: 'ERROR_FROM_SERVICES'
};