export const initialState = {
  notesList: [],
  isLoading: false,
  totalSize: 0,
  hasMore: true,
  notesSearch: {
    filters: [], sorts: [], pageNumber: 1, pageSize: 20
  },
  userNameListFilter: [],
  showAddNote: false,
  isAddingNote: false,
  categories: []
};

export const actions = {
  SET_NOTES_LIST: 'SET_NOTES_LIST',
  SET_TOTAL_SIZE: 'SET_TOTAL_SIZE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_NOTES_SEARCH: 'SET_NOTES_SEARCH',
  SET_HAS_MORE: 'SET_HAS_MORE',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  SET_USER_NAME_LIST_FILTER: 'SET_USER_NAME_LIST_FILTER',
  SET_SHOW_ADD_NOTE: 'SHOW_ADD_NOTE',
  SET_IS_ADDING_NOTE: 'SET_IS_ADDING_NOTE',
  SET_CATEGORIES: 'SET_CATEGORIES'
};