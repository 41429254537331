import React, { useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";

const componentName = "statusFilter";

export const StatusFilter = ({ filterApplied, setvalue, value }) => {

  const handleChange = (status) => {
    filterApplied(status)
    setvalue(status)
  }
  return (
    <>
      <ToggleButtonGroup
        type="radio"
        className="careProgramFilters"
        name="careProgramFilters"
        value={value}
        onChange={(e) => handleChange(e)}
      >
        <ToggleButton
          id="tbg-radio-1"
          variant="secondary"
          className={`border px-3 text-dark rounded-start ${value === 'All' ? 'active' : ''}`}
          value='All'
        >
          All
        </ToggleButton>
        <ToggleButton
          id="tbg-radio-2"
          variant="secondary"
          className={`border px-3 text-dark rounded-0 ${value === 'Open' ? 'active' : ''}`}
          value='Open'
        >
          Open
        </ToggleButton>
        <ToggleButton
          id="tbg-radio-3"
          variant="secondary"
          className={`border px-3 text-dark rounded-start-0 rounded-end ${value === 'Closed' ? 'active' : ''}`}
          value='Closed'
        >
          Closed
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default StatusFilter;