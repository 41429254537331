import React, { useContext, createContext, useReducer, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from "react-redux";

import { initialState } from './actions';
import { reducer } from './HospitalEvents.reducer';
import { getHospitalEvents, addHospitalEvent, editHospitalEvent } from './api';
import { getGetters } from './getters';
import { getToggles } from './toggles';
import { getSetters } from "./setters";

import { useEQHToastContext } from '../../../../components/toast/EQHToast.context';

const HospitalEventsContext = createContext();

const HospitalEventsProvider = ({ children }) => {
  const globalDispatch = useDispatch();
  const {removeNotification, setNotification} = useEQHToastContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { refetch: getHospitalEventsQuery } = useQuery({
    queryKey: ['getHospitalEvents', state?.hospitalEventsRequest?.patientId],
    queryFn: () => {
      getHospitalEvents(
        state?.hospitalEventsRequest?.patientId,
        state?.hospitalEventsRequest?.requestBody
      )
        .then((res) => {
          setters.setHospitalEventsList(res.data.patientAdtDtos);
          setters.setTotalSize(res.data.totalSize);
        })
    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: !!state?.hospitalEventsRequest?.patientId
  })

  const addHospitalEventQuery = useMutation(addHospitalEvent, {
    onSuccess: (res, variables) => {
      if (res.data === "ADT_DUPLICATE") {
        setNotification("Error", "Hospital Event already available for the patient with the provided admit and discharge dates and disposition field.");
      } else {
        const patientId = variables?.patientId;
        const requestBody = state?.hospitalEventsRequest?.requestBody;
        setters.setHospitalEventsList([]);
        setters.setHospitalEventsRequest({ patientId, requestBody });
        toggles.toggleNewHospitalEventModalActive(false);
      }
    },
    onError: (err) => {
      toggles.toggleNewHospitalEventModalActive(false);
    },
  });

  const editHospitalEventQuery = useMutation(editHospitalEvent, {
    onSuccess: (res, variables) => {
      const patientId = variables?.patientId;
      const requestBody = state?.hospitalEventsRequest?.requestBody;
      setters.setHospitalEventsList([]);
      setters.setHospitalEventsRequest({ patientId, requestBody });
      toggles.toggleEditHospitalEventModalActive(false);
    },
    onError: (err) => {
      toggles.toggleEditHospitalEventModalActive(false);
    },
  });

  useEffect(() => {
    if(state?.hospitalEventsRequest?.patientId) {
      getHospitalEventsQuery()
    }
  }, [
    state?.hospitalEventsRequest
  ])

  useEffect(() => {
    if(state?.addHospitalEventRequest?.patientId) {
      addHospitalEventQuery.mutate(state.addHospitalEventRequest);
    }
  }, [state?.addHospitalEventRequest])

  useEffect(() => {
    if(state?.editHospitalEventRequest?.patientId) {
      editHospitalEventQuery.mutate(state.editHospitalEventRequest);
    }
  }, [state?.editHospitalEventRequest])

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const callbacks = {
    getHospitalEvents: async (patientId, requestBody) => {
      removeNotification();
      setters.setHospitalEventsList([])
      setters.setHospitalEventsRequest({ patientId, requestBody });
    },
    addHospitalEvent: async (requestBody) => {
      removeNotification();
      setters.setAddHospitalEventRequest(requestBody);
    },
    editHospitalEvent: async (requestBody) => {
      removeNotification();
      setters.setEditHospitalEventRequest(requestBody);
    }
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <HospitalEventsContext.Provider value={value}>
      {children}
    </HospitalEventsContext.Provider>
  )
};
const useHospitalEventsContext = () => useContext(HospitalEventsContext);

export {
  HospitalEventsProvider,
  useHospitalEventsContext,
}
