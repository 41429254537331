import React from "react";
import styled, { css } from "styled-components";

const ViewLink = styled.span`
  color: ${(props) => props.color};
  height: ${(props) => props.height};
  :hover {
    color: ${(props) => props.hoverColor};
    cursor: pointer;
  }
`;

const EQHLink = ({
  height = "auto",
  color = "blue",
  hoverColor = "blue",
  children,
  onClick
}) => (
  <ViewLink height={height} color={color} hoverColor={hoverColor} onClick={onClick}>
    {children}
  </ViewLink>
);

export { EQHLink };