import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useAuth } from '../../../../../context/authContext';
import { fetchPrograms, handleGetInitialData } from '../utils';


export default function ProgramSelect({formData, setFormData, currentUser = null}) {
  const componentName = 'ProgramSelect';
  const auth = useAuth();

  useEffect(() => {
      const initPromise = currentUser? handleGetInitialData(currentUser.userAccountId, currentUser.name):
          handleGetInitialData(auth.getUserId(), auth.getName());

      initPromise
      .then(res => setFormData(prev => {
        return {
          ...prev,
          ...res
        }
      }))
      .then(() => {
        fetchPrograms()
          .then(res => setFormData(prev => {
            return {
              ...prev,
              programSelected: res.data[0]?.value,
              programName: res.data[0]?.text,
              programs: res.data
            }
          }))
      });
  }, []);

  return (
    <Row>
      <Col className="mb-3">
        <label id={`${componentName}-assignTeamLabel`} className="d-block">Team</label>
        <Form.Control
          id={`${componentName}-assignTeamValue`}
          type="text"
          disabled
          placeholder={formData?.team?.[0]}
        />
      </Col>
      <Col className="mb-3">
        <label id={`${componentName}-assignProgramLeadLabel`} className="d-block">Program Lead</label>
        <Form.Control
          id={`${componentName}-assignProgramLeadValue`}
          type="text"
          disabled
          placeholder={formData?.name?.[0]}
        />
      </Col>
      <Col className="mb-3">
        <label id={`${componentName}-assignProgramLabel`} className="d-block">Program</label>
        <Form.Control
          aria-label="priority"
          as="select"
          id={`${componentName}-assignProgramValue`}
          defaultValue={formData?.programSelected}
          onChange={e => {
              const programData = formData?.programs?.find((program) => program.value == e.target?.value);
              if (programData) {
                  setFormData({ ...formData, programSelected: programData.value, programName: programData.text})
              }
          }}
        >
          {
            formData?.programs
              .map(opt => <option value={opt?.value}>{opt?.text}</option>)
          }
        </Form.Control>
      </Col>
    </Row>
  )
}
