import axios from "../../../../utils/apiClient";
import {getCustomFilterKeys} from '../mappers/FiltersMapper'

export const getNotes = (patientId,
  {filters = [], sorts = [], pageNumber = 1, pageSize = 20}) => {
  const criteria = {
    "filterParams": filters,
    "sortParams": sorts,
    "pageNumber": pageNumber,
    "pageSize": pageSize
  }

  criteria.filterParams = getCustomFilterKeys(criteria.filterParams);

  if (!criteria.filterParams.some(filter => filter.paramName === 'hasNote')) {
    criteria.filterParams.push({
      "paramName": "hasNote",
      "paramValue": [
        "true"
      ],
      "comparison": "eq",
      "displayValue": [
        "true"
      ]
    })
  }

  return axios
  .post(process.env.REACT_APP_REST_API_BASE_URL
    + `/v1/patients/notes?patientId=${patientId}`,
    criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const addNote = async (requestBody) => {
  const url = `${process.env.REACT_APP_REST_API_BASE_URL}/v1/patient-notes/save`
  return axios.post(url, requestBody, {
    headers: {
      "Content-Type": "application/json"
    },
  });
}

export const getNoteCategories = () => {
  return axios
  .get(process.env.REACT_APP_REST_API_BASE_URL
    + "/v1/patients/notes-activity-types", {
    headers: {"Content-Type": "application/json"},
  });
}