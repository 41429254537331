import React from 'react'
import Modals from '../../../../../components/modals';
import { SubmitReferralContent } from './SubmitReferralContent';

const SubmitReferralModal = ({
  showSubmitReferral,
  setShowSubmitReferral
}) => {
  return (
    <Modals
      scrollable={true}
      referralHeight={true}
      show={showSubmitReferral}
      closeSubmitModal={() => setShowSubmitReferral(false)}
      onHide={() => setShowSubmitReferral(false)}
      size="xl"
      title="Submit Referral"
      completeBtn="SUBMIT"
      hideFooter={true}
      centered={true}
      body={
        <SubmitReferralContent
          screen='MyWorkSpace'
          setShowSubmitReferral={setShowSubmitReferral}
          preSelectedType
          showSubmitReferral={showSubmitReferral}
        />
      }
    />
  )
}

export default SubmitReferralModal;