import React from 'react';
import { useDispatch } from 'react-redux';
import {getPdfFile} from "../../../actions/fileUploadActions";
import { AppPropertiesUtil } from "../../../utils/localStorageUtil";

const componentName = "Support";

const gapDocList = new Set();

const Support = ({ toggleContactModal, toggleKnowledgeModal, toggleResourcesModal, toggleFeedbackModal, toggleAboutModal }) => {
    const usermanualPdf = "/docs/User_Manual.pdf";
    const releaseNotesPdf = "/docs/Release_Notes.pdf";
    const gapDocuments = AppPropertiesUtil.get("react.properties.gap.documents");

    const dispatch = useDispatch();

    const handleClickofLink = (file) => {
        if (file && file.fileUploadId) {
            dispatch(getPdfFile(file.fileUploadId, file.displayName))
        }
    };

    return (
        <>
            <div className="btn-group" id={`${componentName}-support`}>
                <button type="button" id={`${componentName}-supportBtn`} className="btn btn-link dropdown-toggle border-0" data-bs-toggle="dropdown" aria-expanded="false">
                    <i id={`${componentName}-supportIcon`} className="fa-regular fa-circle-question border-0 text-dark"></i>
                </button>
                <ul id={`${componentName}-supportDropdown`} className="dropdown-menu dropdown-menu-end">
                    {/*}
                    <li>
                        <button className="dropdown-item" id={`${componentName}-contactUs`} eventKey="0" onClick={toggleContactModal}>
                            Contact Us1
                        </button>
                    </li>
                    {*/}
                    <li>
                        <button className="dropdown-item" id={`${componentName}-resources`} eventKey="0" onClick={toggleResourcesModal}>
                            Resources
                        </button>
                    </li>
                    <li>
                        <button className="dropdown-item" id={`${componentName}-feedback`} eventKey="0" onClick={toggleFeedbackModal}>
                            Feedback
                        </button>
                    </li>
                    <li>
                        <button className="dropdown-item" id={`${componentName}-about`} eventKey="0" onClick={toggleAboutModal}>
                            About
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Support;