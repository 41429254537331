import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import EQHErrorToast from "../../EQHErrorToast";
import {
  getCustomListName,
  getCustomListNotes,
  getQuickListId
} from "../../../utils/util";
import {useHistory} from "react-router-dom";
import {
  removeQuicklistById
} from "../../../actions/navigation/navigationActions";
import {useCustomWorklistContext} from "../context/CustomWorklist.context";

const componentName = 'DeleteCustomWorklist';

function DeleteCustomWorklist({
  locationToRedirect,
  sendEvent
}) {
  const {
    isDeleteCustomWorklistEnabled,
    toggleDeleteCustomWorklist,
    deleteCustomWorklist
  } = useCustomWorklistContext();

  const isEmpty = require("is-empty");
  const dispatch = useDispatch()
  const history = useHistory();
  const [errors, setErrors] = useState([]);

  let quickListId = getQuickListId();
  let customListName = getCustomListName();
  let customListNotes = getCustomListNotes();

  const deleteAction = async () => {
    await deleteCustomWorklist(quickListId);
    await dispatch(removeQuicklistById("/cm_worklist?id=" + quickListId));
    sendEvent('CONFIRM_DELETE_WORKLIST');
    history.push(locationToRedirect);
  }

  return (
    <Modal
      id={`${componentName}-patientDeleteCustomWorklistConfirmModal`}
      size="md"
      show={isDeleteCustomWorklistEnabled}
      onHide={() => toggleDeleteCustomWorklist(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title
          id={`${componentName}-patientDeleteCustomWorklistConfirmTitle`}>Delete
          Custom Worklist</Modal.Title>
        <button
          id={`${componentName}-patientDeleteCustomWorklistConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleDeleteCustomWorklist(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <EQHErrorToast errors={errors} handleClose={() => setErrors([])}
                       isList={true}/>
        <Row className="mb-4 bg-light p-3 rounded-3">
          <Col>
            <Row className="mb-3">
              <Form.Group id={`${componentName}-name`}
                          className="d-flex flex-column">
                <Form.Label id={`${componentName}-nameLabel`}>
                  Worklist Name
                </Form.Label>
                <span id={`${componentName}-nameValue`}>{customListName}</span>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group id={`${componentName}-work`}
                          className="d-flex flex-column">
                <Form.Label
                  id={`${componentName}-worklistNotesLabel`}>
                  Notes
                </Form.Label>
                {!isEmpty(customListNotes) &&
                  <span
                    id={`${componentName}-notesValue`}>{customListNotes}</span>
                }
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-deleteCustomWorklistNoBtn`}
                variant="secondary"
                onClick={() => toggleDeleteCustomWorklist(false)}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-deleteCustomWorklistYesBtn`}
          variant="primary"
          onClick={() => deleteAction()}>
          Delete Custom Worklist
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteCustomWorklist;