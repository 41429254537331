export const initialState = {
    referrals: [],
    loading: false,
    referralsSelected: [],
    exportData: [],
    referralsSearch: {},
};

export const actions = {
    SET_REFERRALS: 'SET_REFERRALS',
    SET_LOADING: 'SET_LOADING',
    SET_REFERRALS_SELECTED: 'SET_REFERRALS_SELECTED',
    SET_EXPORT_DATA: 'SET_EXPORT_DATA',
    SET_REFERRALS_SEARCH: 'SET_REFERRALS_SEARCH',
};