import React, {useContext, createContext, useReducer} from 'react';
import {useDispatch} from "react-redux";

import {initialState} from './actions';
import {reducer} from './PatientDetails.reducer';
import {} from './api';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import { updatePatientProfileDetails, addPatientInternalNote } from './api';
import {
  getPatientProfileOverview
} from "../../../../../../actions/patientProfileActions";
import {
  useEQHToastContext
} from "../../../../../../components/toast/EQHToast.context";
import {
  getLanguages,
  getStates,
  getDropListByName
} from "../../../../../../actions/constantsActions";

const PatientDetailsContext = createContext();

const PatientDetailsProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const {removeNotification, setNotification} = useEQHToastContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const getPatientProfileDetails = async (patientId) => {
    toggles.setPatientProfileDetails({});
    toggles.toggleEditPatientDetailsEnabled(false);
    await getPatientProfileOverview([patientId])
    .then((response) => {
      toggles.setPatientProfileDetails(response.data);
    })
    .catch((err) => {
    });
  }

  const callbacks = {
    getPatientProfileDetails: getPatientProfileDetails,
    updatePatientProfileDetails: async (patientProfileDetails) => {
      removeNotification();
      return await updatePatientProfileDetails(patientProfileDetails)
      .then((res) => {
        setNotification('Successful', 'Patient updated successfully');
        if(patientProfileDetails && patientProfileDetails.id != null) {
          getPatientProfileDetails(patientProfileDetails?.id);
        }
      })
      .catch((err) => {
        removeNotification();
      });
    },
    addPatientInternalNote: async (internalNote) => {
      removeNotification();
      return await addPatientInternalNote(internalNote)
      .then((res) => {
        const { patientId } = res?.data;
        const status = res?.status;

        if(internalNote && patientId && status === 200) {
          setNotification('Successful', 'Internal note added successfully');
          getPatientProfileDetails(patientId);
        } else {
          setNotification('Error', 'There was an error adding the internal note');
        }
      })
      .catch((err) => {
        removeNotification();
      });
    },
    getLanguages: async () => await getLanguages()
    .then(languagesResponse => {
      toggles.setLanguages(languagesResponse.data)
    }),
    getSpokenReadingLanguages: async () => await getDropListByName('SPOKEN_LANGUAGE')
    .then(response => {
      const languages = response?.data?.sort((a, b) => a.value - b.value);
      toggles.setSpokenLanguages(languages)
      toggles.setReadingLanguages(languages)
    }),
    getStates: async (valueAsAbbr, textAsAbbr, valueAllCaps,
      textAllCaps) => await getStates(
      [valueAsAbbr, textAsAbbr, valueAllCaps, textAllCaps])
    .then(statesResponse => {
      toggles.setStates(statesResponse.data)
    }),
    getPayorStatus: async () => await Promise.resolve([{
      value: 'Active',
      text: 'Active'
    }, {
      value: 'Inactive',
      text: 'Inactive'
    }])
    .then(payorStatusResponse => {
      toggles.setPayorStatus(payorStatusResponse)
    }),
    getSpecialistTypes: async () => await Promise.resolve([{
      value: 'Primary Care',
      text: 'Primary Care'
    }, {
      value: 'Caregiver',
      text: 'Caregiver'
    }, {
      value: 'Care Coordinator',
      text: 'Care Coordinator'
    }, {
      value: 'Nurse',
      text: 'Nurse'
    }, {
      value: 'Health Plan Manager',
      text: 'Health Plan Manager'
    }, {
      value: 'Other Specialist',
      text: 'Other Specialist'
    }])
    .then(specialistTypesResponse => {
      toggles.setSpecialistTypes(specialistTypesResponse)
    }),
    getPronounsDropList: async () => await getDropListByName("PRONOUNS")
    .then(pronounsDropList => toggles.setPronounsDropList(pronounsDropList.data.map(item => item.text))),
    getSexAssignedBirthDropList: async () => await getDropListByName("SEX_ASSIGNED_BIRTH")
    .then(sexAssignedBirthDropList => toggles.setSexAssignedBirthDropList(sexAssignedBirthDropList.data.map(item => item.text))),
    getRaceEthnicityDropList:  async () => await getDropListByName("RACE_ETHNICITY")
    .then(raceEthnicityDropList => toggles.setRaceEthnicityDropList(raceEthnicityDropList.data.map(item => item.text))),
    getSexualOrientationList: async () => await getDropListByName('SEXUAL_ORIENTATION')
    .then(response => {
      const sexualOrientationList = response?.data?.sort((a, b) => a.value - b.value);
      toggles.setSexualOrientationList(sexualOrientationList)
    }),
    getGenderIdentityList: async () => await getDropListByName('GENDER_IDENTITY')
    .then(response => {
      const genderIdentityList = response?.data?.sort((a, b) => a.value - b.value);
      toggles.setGenderIdentityList(genderIdentityList)
    }),
  }

  const value = {
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <PatientDetailsContext.Provider value={value}>
      {children}
    </PatientDetailsContext.Provider>
  )
};
const usePatientDetailsContext = () => useContext(PatientDetailsContext);

export {
  PatientDetailsProvider,
  usePatientDetailsContext,
}