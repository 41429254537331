const careManagementsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "new":
        return {
          ...state,
          data: [...state.data, payload]
        };
      case "update":
        return {
          ...state,
          data: state.data.map((x) =>
            x.enrollmentId === payload.enrollmentId ? payload : x
          ),
        };
      default:
        throw new Error();
    }
  };

  export {careManagementsReducer};