import React from "react"
import {Appointments} from "./components";
import { AppointmentsProvider } from "./context/Appointments.context";

const AppointmentsTab = ({ patient }) => {
  return (
    <AppointmentsProvider>
      <Appointments patient={patient} />
    </AppointmentsProvider>
  );
}

export default AppointmentsTab;
