import React, {useContext, createContext, useReducer, useEffect} from 'react';
import {useDispatch} from "react-redux";

import {actions, initialState} from './actions';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from "./setters";
import { reducer } from './Assessments.reducer';
import { getAssessmentMutationCall, getAssessments, getExportToCSV } from './api';
import { useMutation, useQuery } from 'react-query';

const AssessmentsContext = createContext();

const AssessmentsProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const { refetch: getAssessmentsFn } = useQuery({
    queryKey: ['getAssessments', state?.patientId],
    queryFn: () => {
      getAssessments(state?.patientId)
        .then(res => {
          setters.setAssessments(res?.data?.assessmentList)
          setters.setTotalSize(res?.data?.totalSize);
        })
    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: !!state?.patientId
  })

  const getAssessmentMutation = useMutation(getAssessmentMutationCall, {
    onSuccess: (res) => {
      setters.setAssessments(res?.data?.assessmentList);
      setters.setTotalSize(res?.data?.totalSize);
    }
  });

  const reloadAssessments  = () => {
    getAssessmentMutation.mutate({
      pageNumber: 1,
      filterParams: state.filterParams,
      sortParams: state.sortParams,
      patientId: state.patientId,
      pageSize: 20
    })
  }

  const { refetch: getExportToCSVFnQuery } = useQuery({
    queryKey: ['getExportToCSV', state?.exportParams],
    queryFn: () => {
      getExportToCSV(state?.exportParams)
        .then(res => setters.setExportData(res?.data))
    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: state.exportParams?.patientId !== undefined && state.exportParams?.answerSetId !== undefined 
  })

  useEffect(() => {
    state?.patientId && getAssessmentsFn()
  }, [state?.patientId])


  useEffect(() => {
    const patientId = state.exportParams?.patientId;
    const answerSetId = state.exportParams?.answerSetId;

    if(patientId && answerSetId) {
      getExportToCSVFnQuery();
    }
  }, [state?.exportParams])

  const callbacks = {
    getAssessments: () => getAssessmentsFn(),
    getAssessmentMutation: (params) => getAssessmentMutation.mutate(params),
    reloadAssessments: () => reloadAssessments(),
    exportToCSV: (exportParams) => {
      setters.setExportParams(exportParams);
    },
    clearExportData: () => {
      setters.setExportData([]);
    }
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <AssessmentsContext.Provider value={value}>
      {children}
    </AssessmentsContext.Provider>
  )
};
const useAssessmentsContext = () => useContext(AssessmentsContext);

export {
  AssessmentsProvider,
  useAssessmentsContext,
}