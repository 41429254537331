import React, {useState, useRef} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FileUpload} from "../../../../../components/FileUpload";
import axios from "../../../../../utils/apiClient";
import {useDispatch} from "react-redux";
import {getFileDownload} from "../../../../../actions/fileUploadActions";
import {useAuth} from "../../../../../context/authContext";
import Modals from "../../../../../components/modals";
import {
  useEQHToastContext
} from "../../../../../components/toast/EQHToast.context";

const componentName = "careStepDetails";

export const CareStepDetails = ({ 
  index,
  step,
  formatDateModern,
  formatTimeMinimal,
  textBoxRefForNote,
  setShowAdd,
  showAdd,
  onAdd,
  onSavingNote,
  EQHSpinner,
  isEmpty,
  handleRefreshSteps
  }) => {
    const [textAreaValue, setTextAreaValue] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDeletingAttachment, setIsDeletingAttachment] = useState(null);
    const [files, setFiles] = useState([...step?.files]);
    const [editingNoteIndex, setEditingNoteIndex] = useState(null);
    const [editingNoteValue, setEditingNoteValue] = useState("");
    const [disabledEditNoteIconIndex, setDisabledEditNoteIconIndex] = useState(null);
    const noteRefs = useRef([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const { removeNotification, setNotification } = useEQHToastContext();
    const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");

    const handleClickOnLink = (event, fileId, fileName) => {
      event.preventDefault();
      dispatch(getFileDownload(fileId, fileName));
    };

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    }

    const handleAddClick = (isDraft) => {
        onAdd(isDraft);
        setTextAreaValue("");
        if (textBoxRefForNote.current) {
            textBoxRefForNote.current.value = "";
        }
    }

    const handleUploadFile = (e) => {
      const fileIds = [...e]?.map(filter => ({fileId: filter.fileId}))
      const oldFileIds = [...step.files]?.map(filter => ({fileId: filter?.metadata?.fileId}))
      axios.post(
        process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/care-path-step/upload-documentation`,
        {
          carePathStepId: step?.id,
          uploadedFiles: [...oldFileIds, ...fileIds]
        },
        {
          headers: {},
        }
      ).then(() => {
        setFiles([
          ...e.map(f => ({metadata: {fileId: f.fileId, fileName: f.fileName, userId: auth.getUserId()}})),
          ...step.files
        ])
      });
    }

    const handleEditNoteClick = (idx) => {
      setEditingNoteIndex(idx);
      setEditingNoteValue(step.notes[idx].note);
      setDisabledEditNoteIconIndex(idx);
      setTimeout(() => {
        if(noteRefs.current[idx]){
          noteRefs.current[idx].focus();
        }
      }, 0);
    };

    const handleSaveEditNoteClick = (isDraft) => {
      const noteId = step.notes[editingNoteIndex].id;
      const updatedDate = new Date().toISOString();

      try {
          axios.patch(
            process.env.REACT_APP_REST_API_BASE_URL +
              `/v1/care-path-step/note`,
            {
              carePathStepNoteId: noteId,
              note: editingNoteValue,
              editionDate: updatedDate,
              isDraft: isDraft
            },
            {
              headers: {},
            });

          const updatedNotes = [...step.notes];
          updatedNotes[editingNoteIndex].note = editingNoteValue;
          updatedNotes[editingNoteIndex].creationDate = updatedDate;
          updatedNotes[editingNoteIndex].isDraft = isDraft;
          setEditingNoteIndex(null);
          setEditingNoteValue("");
          setDisabledEditNoteIconIndex(null);
      } catch (error) {
      }
    };

    const handleCancelEditNoteClick = () => {
      setEditingNoteIndex(null);
      setEditingNoteValue("");
      setDisabledEditNoteIconIndex(null);
    };

  /**
   * Check if the logged-in user can delete files
   * @param event
   * @param file
   * @return true if the logged-in user can delete files. otherwise, false
   */
  const isAllowedToRemoveAttachment = (file) => {
    return file?.metadata?.userId === auth.getUserId();
  };

  const handleClickOnRemoveAttachment = (event, file) => {
    event.preventDefault();
    setShowDeleteConfirmation(true);
    setSelectedFile(file);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedFile(null);
    setIsDeletingAttachment(false);
  };

 const removeAttachment = () => {
   removeNotification();
   setIsDeletingAttachment(true);

   axios.delete(process.env.REACT_APP_REST_API_BASE_URL + `/v1/care-path-step/delete-documentation`, {
     data: {carePathStepId: step?.id, uploadedFiles: [{fileId: selectedFile?.metadata?.fileId}]},
     headers: {"Content-Type": "application/json"}
   }).then(response => {
     setNotification('Successful', 'Attachment deleted successfully.');
     hideDeleteConfirmation();
     handleRefreshSteps && handleRefreshSteps()
   }).catch(err => {
     setIsDeletingAttachment(false);
     setNotification("Error", 'There was an error deleting the attachment')
   });
 };

  return (
    <div style={{ userSelect: 'text'}} >
      <Modals
        show={showDeleteConfirmation}
        size="sm"
        hideFooter={false}
        onCreate={removeAttachment}
        onHide={() => hideDeleteConfirmation()}
        centered={true}
        title="Confirm"
        titleid={`${componentName}-removeConfirm`}
        completeBtn="Confirm"
        disabledClickButton={isDeletingAttachment}
        completeBtnId={`${componentName}-removeConfirmButton`}
        closeBtn="Cancel"
        disabledClickCloseButton={isDeletingAttachment}
        closeBtnId={`${componentName}-removeCloseButton`}
        body={<span>Are you sure you want to delete the attachment?</span>}
      />

      <Row
        className="ps-1 draggable"
        onDragStart={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Col className="ps-5">
          <Form.Group id={`${componentName}-carePathStepDescription-${index}`} as={Row} className="mb-4">
            <Form.Label id={`${componentName}-carePathStepDescriptionLabel-${index}`} column sm={3}>
              Description
            </Form.Label>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-carePathStepDescriptionValue-${index}`}>{step.description}</span>
            </Col>
          </Form.Group>
          <Form.Group id={`${componentName}-carePathStepUploadFiles-${index}`} as={Row} className="mb-4">
            <Form.Label id={`${componentName}-carePathSteUploadFilesLabel-${index}`} column sm={3}>
              Attachments
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Row>
                {files.map((file, idx) =>
                    <div>
                        { isAllowedToRemoveAttachment(file) &&
                          <i className="fa-sharp fa-regular fa-square-xmark me-3 cursor-p" onClick={(event) => {
                            handleClickOnRemoveAttachment(event, file)
                          }} />
                        }

                      <a id={`${componentName}-carestepFile-${idx}`} href="#"
                        onClick={(event) => {
                          handleClickOnLink(event, file?.metadata?.fileId, file?.metadata?.fileName)
                        }}>
                        {file?.metadata?.fileName ?? ""}
                      </a>
                    </div>
                  )
                }
              </Row>
            </Col>
          </Form.Group>
          <Form.Group id={`${componentName}-carePathStepNotes-${index}`} as={Row}>
            <Form.Label id={`${componentName}-carePathStepNotesLabel-${index}`} column sm={3}>
              Notes {onSavingNote && <EQHSpinner />}
            </Form.Label>
            <Col id={`${componentName}-carePathStepNotes-${index}`}>
              {!isEmpty(step.notes) &&
                step.notes.filter(notes => notes.note !== '' && notes.notes !== null).map((note, idx) => {
                  const isEditing = editingNoteIndex === idx;
                  const isDisabled = disabledEditNoteIconIndex === idx || !authorizedCareProgramWrite;
                  const canEditNote = auth.getUserId() === note.author.userAccountId;
                  return (
                    <Row key={idx} id={`${componentName}-Note-${idx}`} className="mb-3">
                      <Col>
                        <div className="d-flex align-items-start">
                          {note.isDraft && (
                            <span id={`${componentName}-NotesDraft-${idx}`}
                                  data-placement="top" 
                                  className="badge rounded-pill bg-warning border me-2"
                                  style={{color: "#d68729"}}> Draft
                            </span>
                          )}
                          {canEditNote && (
                            <i
                              className="fa-thin fa-pen cursor-p me-1"
                              style={isDisabled ? { opacity: 0.5, pointerEvents: 'none', filter: 'grayscale(100%)' } : {}}
                              onClick={() => !isDisabled && handleEditNoteClick(idx)}
                              aria-disabled={isDisabled}>
                            </i>
                          )}
                          <div className="d-flex align-items-start" style={{ minWidth: '150px' }}>
                            <div className="d-flex flex-column me-3">
                              <span id={`${componentName}-NotesName-${idx}`} className="fw-bold">
                                {formatDateModern(new Date(note.creationDate).toISOString())}
                              </span>
                              <small id={`${componentName}-NotesDate-${idx}`}>
                                {formatTimeMinimal(new Date(note.creationDate).toISOString())}
                              </small>
                            </div>
                            {note.isSnoozed && <i className="fas fa-snooze text-muted ms-2"></i>}
                          </div>
                        </div>
                      </Col>
                      <Col id={`${componentName}-NotesText-${idx}`} sm={6} className="d-flex flex-column ms-2">
                      {isEditing ? (
                          <>
                            <Form.Control
                              as="textarea"
                              ref={el => noteRefs.current[idx] = el}
                              value={editingNoteValue}
                              onChange={(e) => setEditingNoteValue(e.target.value)}
                              rows={3}
                            />
                            <div className="d-flex justify-content-end mt-2">
                              <Button variant="secondary" onClick={handleCancelEditNoteClick} className="me-2">Cancel</Button>
                              <Button variant="primary" onClick={() => handleSaveEditNoteClick(true)} disabled={!editingNoteValue.trim()} className="me-2">Save As Draft</Button>
                              <Button variant="primary" onClick={() => handleSaveEditNoteClick(false)} disabled={!editingNoteValue.trim()}>Save</Button>
                            </div>
                          </>
                        ) : (
                          <>
                            {note.note}
                          </>
                        )}
                      </Col>
                    </Row>
                );
              })}
              <Form.Control
                as="textarea"
                ref={textBoxRefForNote}
                onFocus={() => setShowAdd(false)}
                onChange={handleTextAreaChange}
                id={`${componentName}-carePathNotesBox-${index}`}
                rows={3}
                className="mt-2"
              />
            </Col>
            <Row>
              <Col>
                <div className="mt-3 text-start">
                  <FileUpload
                    label="Upload Files"
                    showIcon={false}
                    btnClass="text-primary bg-light border-light"
                    onChange={e => handleUploadFile(e)}
                    clearStateProp={0}
                    showFileList={false}
                    disabled={!authorizedCareProgramWrite}
                  />
                </div>
              </Col>
              <Col>
                <div className="mt-3 text-end">
                  <Button id={`${componentName}-carePathSaveNotesAsDraft-${index}`}
                    variant="primary" 
                    onClick={() => handleAddClick(true)} 
                    disabled={!authorizedCareProgramWrite || !textAreaValue.trim()}
                    className="me-2">
                    Save As Draft
                  </Button>
                              
                  <Button id={`${componentName}-carePathAddNotes-${index}`}
                    onClick={() => handleAddClick(false)} variant="primary"
                    disabled={!authorizedCareProgramWrite || (showAdd || !textAreaValue.trim())}>
                    Add Note
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default CareStepDetails;