import React, { useState } from "react";
import { forgotPassword } from "../../utils/loginApiClient";
import { ResponseAlert } from "../../components/alerts";
import { Button, Form } from "react-bootstrap";

const componentName = "RecoverPassword";

const RecoverPassword = ({}) => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState(null);
  const [waitingForApiResponse, setWaitingForApiResponse] = useState(false);
  return (
    <Form id={`${componentName}-recoverPassword`}
      onSubmit={async e => {
        e.preventDefault();
        setWaitingForApiResponse(true);
        const res = await forgotPassword(email);
        setEmail("");
        setResponse(res);
        setWaitingForApiResponse(false);
      }}
    >
      <h4 id={`${componentName}-recoverTitle`} className="text-center">Recover Password</h4>
      {response && (
        <ResponseAlert id={`${componentName}-resetAlert`}
          response={response}
          success="Instructions on how to reset your password has been sent to your email."
          error="Something went wrong. Please check your email and try again."
        ></ResponseAlert>
      )}
      <p id={`${componentName}-enterEmail`} className="text-center">Enter your email and instructions will be sent to you!</p>
      <div id={`${componentName}-email`} className="mb-5">
        <label>Email</label>
        <Form.Control id={`${componentName}-emailValue`}
          type="text"
          required
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end">
        <Button id={`${componentName}-submitButton`} type="submit" disabled={waitingForApiResponse}>
          Reset Password
        </Button>
      </div>
    </Form>
  );
};

export default RecoverPassword;
