import React, {useEffect, useState} from 'react'
import {Button} from "react-bootstrap";
import {isEmpty} from "lodash";
import {StyledBadgeBtn} from "../constants";
import {useVisitContext} from "../context/Visit.context";
import ActivityVisitGroup from "./ActivityVisitGroup";
import {getVisits} from "../mappers/visitsActivitiesMapper";
import {getVisitsDue} from "../mappers/visitsDueMapper";
import {validateCareVisits} from "./visitValidator";
import {
  transformToUpdateManyRequestBody
} from "../mappers/updateManyRequestBodyMapper";
import './styles.css';
import {FileUpload} from "../../../../components/FileUpload";
import {useAuth} from "../../../../context/authContext";
import {
  useEQHToastContext
} from "../../../../components/toast/EQHToast.context";

const componentName = 'visit';

function Visit({
  visitsDetails,
  fileUploadResetCounter,
  refreshManageVisit,
  resetAfterUpdate,
  handleUploadFileChange,
  visitDocumentationNotRequired,
  attachmentRequired,
  patient,
  visitDate
}) {
  const {
    showClosedOrInactive,
    toggleShowClosedOrInactive,
    unsavedVisitsMap,
    clearUnsavedVisitsMap,
    updateMany,
    isSavingActivities
  } = useVisitContext();

  const {setNotification} = useEQHToastContext();

  const auth = useAuth();
  const authorizedForWriting = auth.hasAnyAuthority(
    ["AUTH_PD_NEW_MANAGE_VISIT_WRITE_ACCESS"]);

  const [visits, setVisits] = useState([]);
  const [visitsDue, setVisitsDue] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const visits = getVisits(visitsDetails ?? {}, showClosedOrInactive);
    setVisits(visits);

    const visitsDue = getVisitsDue(visitsDetails ?? {});
    setVisitsDue(visitsDue);

  }, [visitsDetails?.careVisitDtos, visitsDetails?.manageVisitActivityTypeDtos,
    showClosedOrInactive])

  const handleUploadFile = (uploadedFiles) => {
    setUploadedFiles(uploadedFiles);
    handleUploadFileChange(uploadedFiles);
  };

  const handleSubmitAction = async () => {
    const careVisits = Object.values(unsavedVisitsMap);
    const errors = validateCareVisits(careVisits);

    if (isEmpty(errors)) {
      const careVisitsRequestBodies = transformToUpdateManyRequestBody(
        patient?.id,
        careVisits,
        uploadedFiles,
        {patientLastUpdated: visitsDetails.patientLastUpdated, visitDate}
      )

      await updateMany(careVisitsRequestBodies);
      resetAfterUpdate && resetAfterUpdate();
      refreshManageVisit && refreshManageVisit();
    } else {
      setNotification('Error', errors);
    }
  }

  const countSelectedVisitsIds = Object.keys(unsavedVisitsMap).length;

  const requiredDocumentsActivitiesNames = visits?.map(mainVisit => {
    return mainVisit?.activities?.flatMap(
      activityGroup => activityGroup?.activities).filter(
      activityVisit => {
        const unsavedVisit = unsavedVisitsMap[activityVisit.id] ?? {};
        const isChecked = unsavedVisit.checked ?? activityVisit.checked;
        return isChecked && activityVisit.requireFileUpload
          && activityVisit.isDocumentationAndCoding;
      }).map(
      activity => activity.title);
  }).flatMap(title => title);

  const countUploadedFiles = isEmpty(uploadedFiles) ? 0 : uploadedFiles.length;
  const isDocumentsRequirementCompleted = () => {
    const documentsRequired = (attachmentRequired || !isEmpty(
      requiredDocumentsActivitiesNames));
    return documentsRequired ? countUploadedFiles > 0 : true
  }

  const enableSubmitButton = countSelectedVisitsIds > 0
    && isDocumentsRequirementCompleted();

  const Header = ({enableShowClosedOrInactive = false, title}) => {
    return <div
      className="d-flex align-items-center justify-content-between mb-4">
      <div className="main-title fw-bold">{title}</div>

      {enableShowClosedOrInactive && <StyledBadgeBtn pill
                                                     bg="light"
                                                     className="border text-dark cursor-p"
                                                     onClick={() => {
                                                       clearUnsavedVisitsMap()
                                                       toggleShowClosedOrInactive(
                                                         !showClosedOrInactive)
                                                     }}>
        <i className={
          showClosedOrInactive ? "fa fa-minus" : "fa fa-plus"
        }/>{" "}
        Show Closed/ Inactive
      </StyledBadgeBtn>
      }

    </div>
  }

  return (
    <div className={componentName + "-container"}>

      {!isEmpty(visitsDue) &&
        <>
          <div className="p-4 visits-due">
            <Header enableShowClosedOrInactive={!isEmpty(visitsDue)}
                    title="Visits Due"/>

            {visitsDue?.map(visitDue => {
              return <>
                <ActivityVisitGroup
                  subTitle={visitDue.showTitle ? visitDue.title : null}
                  activities={visitDue.activities}/>
              </>
            })}
          </div>

          <div className="divider mb-1 my-1"/>
        </>
      }

      <div className="p-4 visits-activities">
        <Header enableShowClosedOrInactive={isEmpty(visitsDue)}
                title="Visit Activities"/>

        {visits?.map(mainVisit => {
          return <div className="activity">
            <div className="title mb-3 fw-bold">{mainVisit.title}</div>
            {mainVisit.activities?.map(activity => {
              return <>
                <ActivityVisitGroup
                  subTitle={activity.showTitle ? activity.title : null}
                  activities={activity.activities}/>
              </>
            })}
            <div className="divider mb-1 my-1"/>
          </div>
        })}
      </div>

      <div className="divider mb-1 my-1"/>

      <div className="pe-4 ps-4 py-2">
        <div className="main-title fw-bold mb-3">
          Evidence{(attachmentRequired || !isEmpty(
            requiredDocumentsActivitiesNames))
          && <span className="text-danger">*</span>}

          {(attachmentRequired || !isEmpty(requiredDocumentsActivitiesNames)) &&
            <div className="document-required-message my-2">
              Required for:
              <ul>
                {requiredDocumentsActivitiesNames?.map(
                  activityName => <li>{activityName}</li>)}
              </ul>
            </div>}
        </div>

        {visitDocumentationNotRequired && <p className="mb-3">Thank you for
          allowing
          Equality Health to integrate with your EHR! As a result of this
          partnership with us, you do not need to upload any visit
          documentation.</p>}

        <FileUpload
          id={`${componentName}-fileUpload`}
          disabled={!authorizedForWriting}
          onChange={handleUploadFile}
          clearStateProp={fileUploadResetCounter}
        />
      </div>

      <div className="d-flex justify-content-end align-items-end p-4">
        <Button id={`${componentName}-cancelBtn`} variant="secondary"
                disabled={isSavingActivities}
                data-bs-dismiss="offcanvas"
                className="me-3" nClick={() => {
        }}>Cancel</Button>
        <Button id={`${componentName}-updateBtn`} variant="primary"
                disabled={!enableSubmitButton || !authorizedForWriting || isSavingActivities}
                onClick={() => {
                  handleSubmitAction();
                }}>Submit</Button>
      </div>

    </div>
  )
}

export default Visit