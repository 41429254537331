import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import PatientDetailsFieldForm from './PatientDetailsFieldForm';
import { cleanPatientInfo } from '../utils';

const componentName = "CareTeamInformation";

/**
 * CareTeamInformation component displays information about the patient's care team.
 * It includes logic to format care staff member details into a string.
 *
 * @param {Object} patientInfo - Object containing the patient's care team information.
 * @returns {JSX.Element} A section of the patient details view with care team information.
 */
const CareTeamInformation = ({ patientInfo }) => {
  const {
    pcp,
    caregiverFirstName,
    caregiverLastName,
    caregiverContact,
    careCoordinator,
    nurse,
    healthPlanCareManager,
    specialists,
  } = cleanPatientInfo(componentName, patientInfo);

  /**
   * Formats a care staff member's information into a string with their name and phone number.
   * If the member is not provided, returns an empty string.
   *
   * @param {Object} member - Object containing the care staff member's name and phone.
   * @returns {string} Formatted care staff member information.
   */
  const formatCareStaffMember = (member) => {
    if (!member) return "";
  
    const { name, phone } = member;
    return `${name} \n${phone}`;
  };

  return (
    <Form>
      <Row>
        <Col>
          <p className="fs-6 fw-bold">Care Team Information</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <PatientDetailsFieldForm elementPrefix="primaryCare" textLabel="PRIMARY CARE - PCP" textValue={formatCareStaffMember(pcp)} />
          <PatientDetailsFieldForm elementPrefix="careGiver" textLabel="CAREGIVER" textValue={`${caregiverFirstName} ${caregiverLastName} \n${caregiverContact}`} />
          <PatientDetailsFieldForm elementPrefix="careCoordinator" textLabel="CARE COORDINATOR" textValue={formatCareStaffMember(careCoordinator)} />
        </Col>
        <Col>
          <PatientDetailsFieldForm elementPrefix="nurse" textLabel="NURSE" textValue={formatCareStaffMember(nurse)} />
          <PatientDetailsFieldForm elementPrefix="healthPlanMngr" textLabel="HEALTH PLAN MNGR" textValue={formatCareStaffMember(healthPlanCareManager)} />
          <PatientDetailsFieldForm elementPrefix="otherSpecialist" textLabel="OTHER SPECIALIST"
            textValue={specialists.map(specialist => `${specialist.name} \n ${specialist.phone}`).join('\n')}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default CareTeamInformation;
