import axios from "../../../utils/apiClient";

export const saveCustomWorklist = (quickListName, name, notes,
  patientRequest) => {
  const criteria = {
    "id": null,
    "quickList": quickListName,
    "patientRequestDTO": {...patientRequest, quickListName: quickListName},
    "ownerId": null,
    "sharedWithUsers": null,
    name,
    notes
  };

  criteria.patientRequestDTO.filterParams = criteria.patientRequestDTO.filterParams
    || [];

  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL
    + '/v1/custom-filtered-list/custom-filtered-lists',
    criteria,
    {
      headers: {
        "Content-Type": "application/json",
      },
    })
};

export const getCustomWorklist = async (worklistId) => {
  return await axios.get(
    process.env.REACT_APP_REST_API_BASE_URL
    + '/v1/custom-filtered-list/custom-lists?id=' + worklistId,
    {
      headers: {},
    }
  )
};

export const deleteCustomWorklist = async (worklistId) => {
  return axios.delete(
    process.env.REACT_APP_REST_API_BASE_URL
    + '/v1/custom-filtered-list/custom-list?id=' + worklistId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};