import styled from "styled-components";

export const MessageOutline = styled.div`
    height: auto;
    flex: grow;
    outline: none;
    display: block;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const MessageOuterWrapper = styled.div`
    height: 100%;
    outline: none;
    display: block;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const MessageInnerWrapper = styled.div`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid transparent;
    font: 400 14px/1.55 "Open Sans", sans-serif;
    color: #464646;
    background-color: #fafafa;
    cursor: default;
    overflow: auto;
    position: relative;
    display: block;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const MessageOuterContainer = styled.div`
    width: 100%;
    vertical-align: middle;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    white-space: normal;
    outline: none;
`;

export const BroadcastMessage = styled.div`
    width: 100%;
    height: 42px;
    white-space: nowrap;
    background: ${props =>
        props.broadcast.priority === `Low Priority` ? `rgb(190, 228, 149)` :
            props.broadcast.priority === `Medium Priority` ? `rgb(253, 202, 108)` :
                `rgb(221, 97, 97)`
    };
    padding: 10px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    outline: none;
`;

export const MessageInnerContainer = styled.div`
    padding-left: 24px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
`;

export const LabelContainer = styled.div`
    width: 100%;
    margin-left: -24px;
    position: relative;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    display: inline-block;
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const Label = styled.div`
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    user-select: text;
    box-sizing: border-box;
    text-align: left;
    outline: none;
`;

export const Spacing = styled.div`
    height: 0 !important;
    width: 10px;
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;    
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const ExitWrapper = styled.div`
    position: relative;
    height: 100%;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    display: inline-block;
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const ExitBtn = styled.div`
    padding: 0px;
    background: none;
    border: none;
    background-image: none;
    border-radius: 3px;
    box-sizing: content-box;
    box-shadow: none;
    height: auto;
    position: relative;
    text-align: center;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    color: #191919;
    font-weight: 500;
    margin-left: auto;
    vertical-align: middle;
    display: inline-block;
`;

export const ExitBtnWrap = styled.span`
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const ExitIcon = styled.span`
    display: inline-block;
    line-height: inherit;
    cursor: inherit;
    user-select: none;
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;

export const ExitSpace = styled.span`
    margin-left: 0;
    outline: none;
    &::after {
        box-sizing: border-box
    }
    &::before {
        box-sizing: border-box
    }
`;