import {actions} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_REFERRALS:
      return {
        ...state,
        referrals: action.payload,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case actions.SET_REFERRALS_SELECTED:
      return {
        ...state,
        referralsSelected: action.payload
      }
    case actions.SET_EXPORT_DATA:
      return {
        ...state,
        exportData: action.payload
      };
    case actions.SET_REFERRALS_SEARCH:
      return {
        ...state,
        referralsSearch: action.payload
      };
    default:
      return state;
  }
};