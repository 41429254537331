import {isEmpty, trim} from "lodash";

const FILTERS_KEYS_MAP = {
  'Risk Level': 'Patient Risk Level',
  'PCP': 'Sender - PCP Name',
  'Payer': 'Payor',
  'Birth Date': 'Birth Date Comp',
  'STATE': 'Market',
  'fileinput': 'Market'
}

export const getCustomFilterKeys = (filtersParams) => {
  return filtersParams.map(f => {
    let paramName = f.paramName
    switch (f.paramName) {
      case "Patient Risk Level":
        paramName = 'Risk Level'
        break;
      case "Sender - PCP Name":
        paramName = 'PCP'
        break;
      case "Birth Date Comp":
        paramName = "Birth Date"
        break;
      case "State":
      case "Market":
      case "fileinput":
        paramName = "STATE"
        break;
      default:
        break;
    }
    return {...f, paramName}
  });
}

export const mapFiltersSelected = (filtersParams) => {
  return filtersParams.map(filterParam => {
    const filter = {...filterParam};
    if (!isEmpty(trim(FILTERS_KEYS_MAP[filter.paramName]))) {
      filter.paramName = FILTERS_KEYS_MAP[filter.paramName]
    }
    return filter;
  });
}