import React, { useEffect, useState } from "react";

import { Row, Col, Modal, Button } from "react-bootstrap";
import Modals from "../../../../components/modals";
import {EQHDatePicker} from "../../../../components/datePicker";
import { format } from "date-fns";
import ErrorToast from "../../../common/ErrorToast";
//const [visitDate, setVisitDate] = useState(null);

const componentName = "scheduledAppointment";

export const ScheduledAppointment = ({
  onHide,
  show,
  handlePatientGapStatus,
  patient,
  patientGapStatus,}) => {
  const [showConfirmSchedule, setShowConfirmSchedule] = useState(false);
  const handleCloseConfirmSchedule = () => {setShowConfirmSchedule(false); setError( '')};
  const [patientGapAppointmentDate, setPatientGapAppointmentDate] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [error, setError] = useState('');
  const handleShowConfirmSchedule = () => {
    setShowConfirmSchedule(true);
  }
  const handleScheduleAppointment = () => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    if(patientGapAppointmentDate < currentDate) {
      setError("Selected date must be equal to or later than today");
      setShowConfirmSchedule(false);
    }
    else{
    handleCloseConfirmSchedule();
    handlePatientGapStatus(patientGapStatus, patientGapAppointmentDate);
    setError('');
    onHide();
    }
  }
  const handleVisitChange = (value) => {
    setPatientGapAppointmentDate(value.strDate);
  };
  const handleInputClick = () => {
    setError("");
  };

  useEffect(()=> {
    const timeout = setTimeout(() => {
      setError('');
    }, 5000);
    return () => clearTimeout(timeout);
  }, [error])


  useEffect(() => {
    setIsDisable(patientGapAppointmentDate != null && patientGapAppointmentDate.length > 0)
  }, [patientGapAppointmentDate])

  return (
    <>
      <Modal id={`${componentName}-appointmentDetailsModal`} size="sm" show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title id={`${componentName}-title`}>Appointment Details</Modal.Title>
          <button id={`${componentName}-ModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setPatientGapAppointmentDate("");onHide(); setError("")}}></button>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <label id={`${componentName}-patientNameLabel`} className="d-block">Patient Name</label>
              <span id={`${componentName}-patientNameValue`}>{patient.fullName}</span>
            </Col>
          </Row>
          {error && (
              <ErrorToast
                  className="float-end"
                  errorParam={error}
                  closeToast= {() => {
                    setIsDisable(true); }}
              />
          )}
          <Row>
            <Col>
              <label id={`${componentName}-scheduleDateLabel`} className="d-block">Schedule Date <span className="text-danger">*</span></label>
              <EQHDatePicker
                  id={`${componentName}-scheduleDateValue`}
                  className="form-control"
                  value={patientGapAppointmentDate}
                  onChange={(e) => {
                    handleVisitChange(e);
                  }}
                  onInputClick={handleInputClick}
                  style={{ maxWidth: "fit-content" }}
                  min={format(new Date(), "yyyy-MM-dd")}
                  max="9999-12-31"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button id={`${componentName}-cancelBtn`} variant="secondary" onClick= {() => {setPatientGapAppointmentDate(""); onHide(); setError("")}}>
            Cancel
          </Button>
          <Button id={`${componentName}-saveBtn`} variant="primary" onClick={handleShowConfirmSchedule} disabled={!isDisable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal id={`${componentName}-appointmentDetailsConfirmModal`} size="md" show={showConfirmSchedule} onHide={handleCloseConfirmSchedule} backdropClassName="confirmbackdrop" contentClassName="shadow border" centered>
        <Modal.Header>
          <Modal.Title id={`${componentName}-confirmTitle`}>Confirm</Modal.Title>
          <button id={`${componentName}-ConfirmModalClose`} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseConfirmSchedule}></button>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <p>Are you sure you want to Schedule this appointment?</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button id={`${componentName}-noBtn`} variant="secondary" onClick= {handleCloseConfirmSchedule}>
            No
          </Button>
          <Button id={`${componentName}-yesBtn`} variant="primary" onClick= {() => {handleScheduleAppointment();}} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduledAppointment;