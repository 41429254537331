import React, { useRef, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const componentName = "CustomPagination";

const StyledInput = styled.input`
  width: 75px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  height: 25px!important;
  border: 0;
`;

const StyledLabel = styled.label`
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  border: 0px;
  padding-top: 9px;
  padding-left: 3px;
  text-align: center;
  display: inline-block;
  text-align: left;
  white-space: normal;
  margin: 0 5px;
`;

export const CustomPagination = ({
  paginateButClicked,
  MAX_PAGE,
  pageNumber,
}) => {
  const [myPageNumber, setMyPageNumber] = useState(pageNumber);

  if (myPageNumber != pageNumber) {
    setMyPageNumber(parseInt(pageNumber));
  }
  let items = [];
  const paginate = (i) => {
      const intI = parseInt(i);
    if (intI && intI >= 1 && MAX_PAGE && intI <= MAX_PAGE) {
      setMyPageNumber(intI);
      paginateButClicked(intI);
    } else {
    }
  };

  items.push(
    <Pagination.First key={`${componentName}-firstPage`} id={`${componentName}-firstPage`}
      disabled={myPageNumber === 1}
      onClick={() => paginate(1)}
    />,
    <Pagination.Prev key={`${componentName}-prevPage`} id={`${componentName}-prevPage`}
      disabled={myPageNumber - 1 < 1}
      onClick={() => paginate(myPageNumber - 1)}
    />
  );
  items.push(
    <StyledInput key={`${componentName}-inputPage`} id={`${componentName}-inputPage`}
      className="form-control me-2 bg-light"
      type="number"
      max={MAX_PAGE}
      min={1}
      value={myPageNumber}
      onChange={(e) => paginate(e.target.value)}
    />
  );
  items.push(<StyledLabel key={`${componentName}-of`} id={`${componentName}-of`}>of</StyledLabel>);
  items.push(<StyledLabel key={`${componentName}-maxPage`} id={`${componentName}-maxPage`}>{MAX_PAGE}</StyledLabel>);

  items.push(
    <Pagination.Next key={`${componentName}-nextPage`} id={`${componentName}-nextPage`}
      disabled={myPageNumber + 1 > MAX_PAGE}
      onClick={() => paginate(Number(myPageNumber) + 1)}
    />,
    <Pagination.Last key={`${componentName}-lastPage`} id={`${componentName}-lastPage`}
      disabled={myPageNumber === MAX_PAGE}
      onClick={() => paginate(MAX_PAGE ? MAX_PAGE : "")}
    />
  );
  return <Pagination key={`${componentName}-qlPagination`} id={`${componentName}-qlPagination`} className="float-end rounded">{items}</Pagination>;
};

export default CustomPagination;
