import React, { useState } from 'react'
import { useAssessmentsContext } from '../context/Assessments.context';
import axios from '../../../../../utils/apiClient';
import isEmpty from 'is-empty';
import NewAssessmentModal from '../../../../patientProfile/assessment/NewAssessmentModal';
import './styles.css';

const baseURL = process.env.REACT_APP_REST_API_BASE_URL;

function AddAssessment() {
  const {
    patientId,
    reloadAssessments,
    individualAssessmentCard,
    mode,
    selectedTemplate,
    setfillAssessment,
    fillAssessment,
    toggleAddNewAssessment,
  } = useAssessmentsContext();

  const [isSaving, setIsSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [detailAssessment, setDetailAssessment] = useState(false);

  const newAssessmentClose = () => {
    setIsSaving(false);
    toggleAddNewAssessment(false);
  }

  const saveNewTemplate = (payload, status, isDelete, id) => {
    let wholeUrl = baseURL + `/v1/assessments/save`;
    setIsSaving(true)
    const finalPayload = {
      id: id,
      patientId: patientId,
      templateId: selectedTemplate?.templateId,
      answers: payload,
      status: status,
    };
    if (isDelete && id) {
      wholeUrl = baseURL + `/v1/assessments/cancel-draft`;
      axios
        .post(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadAssessments();
          if (response.data && !isEmpty(response.data)) {
            setfillAssessment(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsSaving(false)
        });
    } else if (!isDelete && id) {
      wholeUrl = baseURL + `/v1/assessments/save`;
      axios
        .put(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadAssessments();
          if (response.data && !isEmpty(response.data)) {
            setfillAssessment(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsSaving(false)
        });
    } else if (!isDelete && !id) {
      wholeUrl = baseURL + `/v1/assessments/save`;
      axios
        .post(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadAssessments();
          if (response.data && !isEmpty(response.data)) {
            setfillAssessment(false);
          }
        })
        .catch((error) => {
          setIsLoading(false)
          setIsSaving(false)
        });
    }
    newAssessmentClose();
  };

  return (
    <>
      {fillAssessment &&
        <NewAssessmentModal
          saveNewTemplate={saveNewTemplate}
          fillAssessment={fillAssessment}
          fillAssessmentClose={() => {
            setfillAssessment(false)
            toggleAddNewAssessment(false)
          }}
          selectedTemplate={selectedTemplate}
          individualAssessmentData={individualAssessmentCard}
          mode={mode}
          patientId={patientId}
          isSaving={isSaving}
          detailAssessmenClose={() => {
            toggleAddNewAssessment(false)
            setDetailAssessment(false)
          }}
        ></NewAssessmentModal>
      }</>
  )
}

export default AddAssessment