import React, { useEffect, useState } from "react";
import {Row, Col, Card, Form, ListGroup} from "react-bootstrap";
import ErrorToast from "../common/ErrorToast"
import SuccessToast from "../common/SuccessToast"
import cloneDeep from "lodash/cloneDeep";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getAllPayers, getAllPayersFromColl, getPractice, updatePracticeInformation, updatePracticeLevel } from "../../actions/admin/practiceInformationActions";
import { theme } from "../../components/themes/theme1";
import { EQHSpinner } from "../../components/EQHSpinner";
import {Typeahead} from "react-bootstrap-typeahead";

const componentName = "PracticeInformation";

export const PracticeLevelConfiguration = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ practiceLevel: {}, orgArray: [] });
  const [isLoading, setLoading] = useState(false);
  const [isActionTaken, setActionTaken] = useState(true);
  const { allOrganizations } = useSelector((state) => state.practiceInformation);
  const errors = useSelector((state) => state.errors);
  const success = useSelector(state => state.success);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [practiceLevel, setPracticeLevel] = useState({});
  const [previousOrgArray,setPreviousOrgArray]  = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState([]);
  const [practiceFilterApplied, setPracticeFilterApplied] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);

  const isOptionChanged = () => {
    const currentOrgArray = formData.orgArray.filter(f=>f.selected).map(e => e.id).sort().join(',');        
    if(previousOrgArray == currentOrgArray){
      return false;
    }else{
      return true;
    }
  }
  
  const updatePracticeInfo = () => {       
    setLoading(true)   
    const practiceData = formData.orgArray.filter(f => f.recentlySelected && !f.recentlyUnselected).map((e) => e.id);
    const practiceDataWithUncheck = formData.orgArray.filter(f => !f.recentlySelected && f.recentlyUnselected).map((e) => e.id);
    if (practiceDataWithUncheck && practiceDataWithUncheck.length) {
      dispatch(updatePracticeLevel(practiceDataWithUncheck, "noIntegration"))
    }
    if (practiceData && practiceData.length) {
      dispatch(updatePracticeLevel(practiceData, formData.practiceLevel))
    }
  };

  const handleChangeDropDown = (e) => {
    setActionTaken(true)
    const { name, value } = e.target;
    const newNewObject = mapData(value);
    setPracticeLevel(value);
    setPracticeFilterApplied(false);
    setStatusFilter(true);
    if(value === "") {
      setStatusFilter(false);
    }
  };

  const handleChangePractice = (e) => {
    e.target = {};
    e.target.name = "organization";
    e.target.value = e[0];
    if(e && e[0]) {
      setSelectedPractice(e[0]);
      const value = e[0].name;
      const newNewObject = searchPracticeData(value);
      const selectedPractice = e[0];
      setSelectedPractice(selectedPractice.name);
      setPracticeFilterApplied(true);
      setStatusFilter(false);
      setPracticeLevel(value);
    } else if (e.length === 0) {
      setPracticeFilterApplied(false);
      setStatusFilter(false);
      setPracticeLevel("");
      setSelectedPractice("");
      const newObject = cloneDeep(allOrganizations);
      setFormData({ ...formData, orgArray: newObject });
    }
  };

  const searchPracticeData = (value) => {
    const newNewObject = []
    if (allOrganizations && allOrganizations.length) {
      const newObject = cloneDeep(allOrganizations);
      newObject.forEach(element => {
        const obj = {}
        obj.name = element.name
        // obj.id = element.id
        if (!isEmpty(element.name) && element.name === value) {
          obj.selected = true;
          obj.recentlyUnselected = false;
          obj.recentlySelected = false;
          newNewObject.push(obj)
        }
      });
      setFormData({ ...formData, practiceLevel: value, orgArray: newNewObject });
    }
    return newNewObject
  }


  const mapData = (value) => {
    const newNewObject = [];
    setPracticeFilterApplied(false);
    if (allOrganizations && allOrganizations.length) {
      const newObject = cloneDeep(allOrganizations);
      newObject.forEach(element => {
        const obj = {}
        obj.name = element.name
        obj.id = element.id
        if (!isEmpty(element.practiceLevel) && element.practiceLevel === value) {
          obj.selected = true;
          obj.recentlyUnselected = false;
          obj.recentlySelected = false;
        }
        newNewObject.push(obj)
      });
      setFormData({ ...formData, practiceLevel: value, orgArray: newNewObject });
    }    
    return newNewObject
  }

  useEffect(() => {
    (async function fetchData() {
      setLoading(true)
      await dispatch(getAllPayers());    
    })();
  }, []);

  useEffect(()=>{
    const practiceData = formData.orgArray.filter(f=>f.selected).map(e => e.id).sort().join(',');
    setPreviousOrgArray(practiceData)    
  },[formData.orgArray]);

  useEffect(() => {    
    setLoading(false)      
    const newNewObject = mapData(practiceLevel);
  }, [allOrganizations, refresh]);

  const selectPayer = (e, val) => {
    setActionTaken(false)
    const { name, value, checked } = e.target;
    var index = formData.orgArray.indexOf(val);
    if (checked && formData.orgArray && formData.orgArray.length) {
      formData.orgArray[index].selected = true;
      formData.orgArray[index].recentlySelected = true;
      formData.orgArray[index].recentlyUnselected = false;
    } else {
      formData.orgArray[index].selected = false;
      formData.orgArray[index].recentlySelected = false;
      formData.orgArray[index].recentlyUnselected = true;
    }
    setFormData({ ...formData, orgArray: formData.orgArray });
  };
  return (
    <ThemeProvider theme={theme}>
      <Row className="container-fluid p-3">
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-3">
                <Col md={5} className="col-xxl-3">
                  <Form.Group disabled={isLoading} className="mb-3">
                    <Card.Title id={`${componentName}-payersLabel`}>Practice Level EHR connection status</Card.Title>
                    <Form.Control
                      as="select"
                      className="form-control"
                      disabled={isEmpty(allOrganizations) || practiceFilterApplied}
                      name="practiceLevel"
                      id={`${componentName}-pracLevel`}
                      // defaultValue={user.roles.length ? user.roles[0].roleName : user.roles[0].role}
                      onChange={(e) => handleChangeDropDown(e)}
                      value={practiceLevel}
                    >
                      <option value="">--Select</option>
                      <option value="integratedEHR">Integrated EHR</option>
                      <option value="noIntegration">No Integration</option>
                    </Form.Control>
                  </Form.Group>
                  <div id={`${componentName}-orLabel`} className="text-center mb-3">OR</div>
                  <Form.Group>
                    <Card.Title id={`${componentName}-practiceLabel`}>Practice Search</Card.Title>
                    <Typeahead
                        id={`${componentName}-practiceValue`}
                        inputProps={{
                          id: `${componentName}-practiceValue`
                        }}
                        clearButton={true}
                        disabled={isEmpty(allOrganizations) || statusFilter}
                        labelKey="name"
                        multiple={false}
                        positionFixed={true}
                        flip={true}
                        onChange={(e) => handleChangePractice(e)}
                        options={allOrganizations}
                        placeholder="Search for a practice"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {isLoading && <EQHSpinner />}{" "}
                  <Form>
                    <Card.Title id={`${componentName}-payersLabel`}>Participating Practices</Card.Title>
                    <ListGroup className="mh-300 overflow-y-auto border" variant="flush">
                      {formData.orgArray &&
                        formData.orgArray.length > 0 &&
                        formData.orgArray.map((val) => (
                            (isEmpty(practiceLevel) && isEmpty(selectedPractice) || val.selected) && (<ListGroup.Item>
                              <span id={`${componentName}-${val.name}Value`} name={val.name} onChange={(e) => selectPayer(e, val)} disabled={isEmpty(practiceLevel) || isLoading} />
                              <span id={`${componentName}-${val.id}Label`}>{val.name}</span>
                          </ListGroup.Item>)
                        ))}
                    </ListGroup>
                  </Form>
                </Col>
              </Row>
              {errors && errors.message && <ErrorToast />}
              {success && success.message && <SuccessToast />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export default PracticeLevelConfiguration;
