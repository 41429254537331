import React from "react";
import { Col, Container, Row } from 'react-bootstrap';

const componentName = 'AssessmentsCardOpen';

const AssessmentsCardOpen = ({ questions }) => {
  return (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          {questions.length > 0 && questions?.map((q, i) =>
              <Row id={`${componentName}-question-${i}`} key={`question-${i}`}>
                <Col id={`${componentName}-questionLabel-${i}`} className="form-label col-form-label" sm={3}>
                  {q?.q}
                </Col>
                <Col id={`${componentName}-questionValue-${i}`} className="d-flex align-items-center">
                  {q?.a[0]?.text || '-'}
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default AssessmentsCardOpen;