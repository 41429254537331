import React, {useEffect, useState} from 'react';
import {DDLDataMode, DDLMode, DropDownList} from "./genericDDL";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {FilterParam} from "../../dtos/PatientSearchDTO";
import { RedAsterisk } from '../../utils/util';


const componentName = "SimpleOtherOptionDDL";

const SimpleOtherOptionDDL = ({items, handleChange, header, selectedItem, otherItemText, disabled}) => {

    const [isOtherValueSelected, setOtherValueSelected] = useState(false);
    const [otherText, setOtherText] = useState();

    useEffect(() => {
        setOtherText(null);
        setOtherValueSelected(false);
        const selectedItemText = selectedItem?.split(":");
        const itemOption = selectedItemText?.shift();
        if (otherItemText === itemOption) {
            setOtherValueSelected(true);
            const otherText = selectedItemText?.shift();
            setOtherText(otherText)
        }
    }, [selectedItem])


    const onSelectItem = (itemValue) => {
        setOtherValueSelected(false);
        if (itemValue && itemValue === otherItemText) {
            setOtherValueSelected(true);
        }
        handleChange(itemValue);
    }

    const onChangeOtherText = (value) => {
        setOtherText(value)
        handleChange(`${otherItemText}:${value}`);
    }

    const createFilterParam = () => {
        const comparison = "eq";
        const displayValue = selectedItem ? [selectedItem.split(":")?.shift()] : [];
        const selector = (e) => e;
        return new FilterParam(header, comparison, displayValue, selector, selector);
    }

    return (<Col>
        <DropDownList
            mode={DDLMode.SelectOne}
            dataMode={DDLDataMode.OneAPICallFilterInUI}
            header={header}
            handleChange={(e) => onSelectItem(e.paramValue[0])}
            selected={createFilterParam()}
            getData={() => Promise.resolve(items)}
            highlightWhenHasValue={false}
            customStyle="mb-0"
            disabled={disabled}
        />
        {isOtherValueSelected &&
            <>
                <Form.Label id={`${componentName}-${header}-otherTextLabel`}>
                    Open-ended response<RedAsterisk />
                </Form.Label>
                <Form.Control
                    type="text"
                    value={otherText}
                    onChange={(event) => onChangeOtherText(event.target.value)}
                />
            </>
        }
    </Col>)
}

export default SimpleOtherOptionDDL;
