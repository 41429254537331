import React, { useState } from 'react'
import '../styles/Wrapper.css'
import DefaultWrapper from './DefaultWrapper'
import ManageVisitWrapper from './ManageVisitWrapper'
import { useCMWorklistContext } from '../../../../views/caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context';
import { useEffect } from 'react';

function SideFilters({ children, badges, extraFilters, isManageVisit, setShow, show }) {
  const context = useCMWorklistContext();
  const [randomKey, setrandomKey] = useState(Math.random())

  useEffect(() => {
    if (context?.activeRole) {
      setrandomKey(Math.random())
    }
  }, [context?.activeRole])

  return (
      isManageVisit
        ? <ManageVisitWrapper
            extraFilters={extraFilters}
            badges={badges}
            setShow={setShow}
            show={show}
          >{children}</ManageVisitWrapper>
        : <DefaultWrapper extraFilters={extraFilters} badges={badges} key={randomKey}>{children}</DefaultWrapper>
  )
}

export default SideFilters