import styled from "styled-components";

/**
 * Maps ADT event types to their display names.
 */
export const adtTypeMap = {
  ADMIT: "Admit",
  DISCHARGE: "Discharge",
  TRANSFER: "Transfer"
};

/**
 * Maps hospital event types to categories for display.
 */
export const hospitalEventTypeMap = {
  IP_ADMIT: "Inpatient",
  IP_DISCHARGE: "Inpatient",
  BH_IP_ADMIT: "Behavioral",
  BH_IP_DISCHARGE: "Behavioral",
  ER_ADMIT: "Emergency",
  ER_DISCHARGE: "Emergency",
  TRANSFER: "Transfer"
};

/**
 * Maps ADT event field captions to keys used in transforming data.
 * Update `adtToHospitalEventsFieldHandlers` if new fields are added.
 */
export const adtToHospitalEventFieldKeyMap = {
  disposition: "hospitalEventType",
  facility: "facility",
  phone: "phone",
  admitdate: "admitDate",
  admitdiagnosis: "admitDiagnosis",
  dischargedate: "dischargeDate",
  dischargediagnosis: "dischargeDiagnosis",
  chiefcomplaint: "chiefComplaint",
  lengthofstay: "lengthOfStay",
  unitroom: "unitRoom",
  bed: "bed",
  language: "language",
  notes: "notes"
};

/**
 * Defines form fields for displaying and editing hospital event details.
 */
export const hospitalEventOpenContentFormFields = [
  { label: "Phone", key: "phone" },
  { label: "Admit Date", key: "admitDate" },
  { label: "Admit Diagnosis", key: "admitDiagnosis" },
  { label: "Discharge Date", key: "dischargeDate" },
  { label: "Discharge Diagnosis", key: "dischargeDiagnosis" },
  { label: "Chief Complaint", key: "chiefComplaint" },
  { label: "Stay Length", key: "lengthOfStay" },
  { label: "Unit Room", key: "unitRoom" },
  { label: "Bed", key: "bed" },
  { label: "Language", key: "language" },
  { label: "Notes", key: "notes" }
];

/**
 * Defines the color palette used throughout the hospital event components.
 */
const colorPalette = {
  orange: "#ECA466",
  lightOrange: "#FFEBD9" ,
  green: "#7DAA73",
  lightGreen: "#F3F9F2",
  blue: "#1E89C4",
  lightBlue: "#D3E4F5",
  white: "#FFFFFF",
  gray: "#808080",
  mediumGray: "#ABADAF",
  lightGray: "#D3D3D3",
  defaultBackground: "#86898B"
};

/**
 * Maps ADT event types to specific color schemes.
 */
const adtColors = {
  admit: {
    primary: colorPalette.orange,
    background: colorPalette.lightOrange
  },
  discharge: {
    primary: colorPalette.green,
    background: colorPalette.lightGreen
  },
  transfer: {
    primary: colorPalette.blue,
    background: colorPalette.lightBlue
  },
  default: {
    primary: colorPalette.white,
    background: colorPalette.defaultBackground
  }
};

/**
 * Retrieves the primary color based on the ADT event type.
 */
const getAdtColor = (type) => {
  const lowerType = type.toLowerCase();
  return adtColors[lowerType]?.primary || adtColors.default.primary;
};

/**
 * Retrieves the background color based on the ADT event type.
 */
const getAdtBackgroundColor = (type) => {
  const lowerType = type.toLowerCase();
  return adtColors[lowerType]?.background || adtColors.default.background;
};

/**
 * Styled components for displaying ADT type status with specific colors.
 */
export const AdtTypeStatusStyle = styled.label`
  color: ${(props) => getAdtColor(props.type)};
  background-color: ${(props) => getAdtBackgroundColor(props.type)};
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
`;

/**
 * Styled components for displaying hospital event status with specific colors.
 */
export const HospitalEventStatusStyle = styled.label`
  color: ${colorPalette.gray};
  background-color: ${colorPalette.lightGray};
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
`;

/**
 * Styles for hospital event field labels, setting width, color, and typography.
 */
export const HospitalEventFieldLabelColStyle = styled.div`
  max-width: 150px;
  margin-bottom: 10px;
  color: ${colorPalette.mediumGray};
  text-transform: uppercase;
  font-weight: 500;
`
/**
 * Defines filter parameters for hospital events by category, used in data querying.
 */
export const hospitalEventFilterParams = {
  "inpatient": {
    paramName: "Disposition",
    paramValue: ["IP_DISCHARGE"],
    comparison: "eq",
    displayValue: ["Inpatient"]
  },
  "behavioral": {
    paramName: "Disposition",
    paramValue: ["BH_IP_DISCHARGE"],
    comparison: "eq",
    displayValue: ["Behavioral"]
  },
  "emergency": {
    paramName: "Disposition",
    paramValue: ["ER_DISCHARGE"],
    comparison: "eq",
    displayValue: ["Emergency"]
  }
};

/**
 * Lists actions available for hospital events.
 */
export const hospitalEventsActions = [
  {
    value: "Edit",
    text: "Edit",
    hasDivider: false,
    disabled: (hasWriteAccess) => !hasWriteAccess,
    condition: true,
  }
]

/**
 * Disposition list supported for new hospital events.
 */
export const newHospitalEventsDispositionList = [
  { text: "BH IP Discharge", value: "BH_IP_DISCHARGE" },
  { text: "ER Discharge", value: "ER_DISCHARGE" },
  { text: "IP Discharge", value: "IP_DISCHARGE" }
];
