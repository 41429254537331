import { setCustomStatus, setSnoozedModal, setstep } from "../../../../../../actions/manageVisitActions";
import { actions } from "./actions";

export function getToggles(dispatch, globalDispatch) {
    return {
        setPatientSearch: payload => dispatch({ type: actions.SET_PATIENT_SEARCH, payload: payload }),
        toggleIsLoading: payload => dispatch({ type: actions.SET_LOADING, payload: payload }),
        toggleAssignPatientTo: payload => dispatch({ type: actions.TOGGLE_ASSIGN_PATIENT_TO, payload: payload }),
        toggleUpdateReferralStatus: payload => dispatch({ type: actions.TOGGLE_UPDATE_REFERRAL_STATUS, payload: payload }),
        toggleRemoveFromWorklist: payload => dispatch({ type: actions.TOGGLE_REMOVE_FROM_WORKLIST, payload: payload }),
        togglePatientAlreadyRemoved: payload => dispatch({ type: actions.PATIENT_HAS_ALREADY_BEEN_REMOVED, payload: payload }),
        toggleSaveAsCustomWorklist: payload => dispatch({ type: actions.TOGGLE_SAVE_AS_CUSTOM_WORKLIST, payload: payload }),
        selectPatient: payload => dispatch({ type: actions.SELECT_PATIENT, payload: payload }),
        toggleDeleteCustomWorklist: payload => dispatch({ type: actions.TOGGLE_DELETE_CUSTOM_WORKLIST, payload: payload }),
        toggleNewAssessmentTemplateSelector: payload => dispatch({ type: actions.TOGGLE_NEW_ASSESSMENT_TEMPLATE_SELECTOR, payload: payload }),
        setPatientTemplates: payload => dispatch({ type: actions.SET_PATIENT_TEMPLATES, payload: payload }),
        toggleNewAssessmentFlow: payload => dispatch({ type: actions.TOGGLE_NEW_ASSESSMENT_FLOW, payload: payload }),
        setSelectedPatientTemplate: payload => dispatch({ type: actions.SET_SELECTED_PATIENT_TEMPLATE, payload: payload }),
        toggleIsSavingAssessment: payload => dispatch({ type: actions.TOGGLE_IS_SAVING_ASSESSMENT, payload: payload }),
        toggleReassignCareStep: payload => dispatch({ type: actions.TOGGLE_REASSIGN_CARE_STEP, payload: payload}),
        toggleWorkOnCareStep: payload => dispatch({ type: actions.TOGGLE_WORK_ON_CARE_STEP, payload: payload}),
        setWorklistDefaultView: payload => dispatch({ type: actions.SET_WORKLIST_DEFAULT_VIEW, payload: payload }),
        toggleViewAs: payload => dispatch({ type: actions.TOGGLE_VIEW_AS, payload: payload }),
        setPage: payload => dispatch({ type: actions.SET_PAGE, payload: payload }),
        toggleIsBulk: payload => dispatch({ type: actions.TOGGLE_IS_BULK, payload: payload }),
        toggleMarkAsOutreached: payload => dispatch({ type: actions.TOGGLE_MARK_AS_OUTREACHED, payload: payload }),
        toggleSnoozedModal: payload => globalDispatch(setSnoozedModal(payload)),
        toggleCustomStatus: payload => globalDispatch(setCustomStatus(payload)),
        setstep: payload => globalDispatch(setstep(payload)),
        setPatientsEventsToBeMarkedAsOutreached: payload => dispatch({ type: actions.SET_PATIENTS_EVENTS_TO_BE_MARKED_AS_OUTREACHED, payload: payload }),
        setAdminRolesHubWL: payload => dispatch({ type: actions.SET_ADMIN_ROLES_HUB_WL, payload: payload }),
    }
}