import React, { useRef } from "react";

import { QuickLists } from "./QuickList";
import { useGetTransQuickListSettings } from "./quickListSettings";
import { useAuth } from "../../context/authContext";
import { QuickListContext } from "./QuickListContext";

const AllPatients = () => {
  const auth = useAuth();
  const getSettingsRef = useGetTransQuickListSettings("allPatients", 0, auth);

  return (
    <QuickListContext.Provider value={getSettingsRef()}>
      <QuickLists {...getSettingsRef()} />
    </QuickListContext.Provider>
  );
};

export { AllPatients };
