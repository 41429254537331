import React, { useContext, createContext, useReducer, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import sendGAEvent from "../../../../../../utils/googleAnalytics";
import { actions, initialState } from './actions';
import { reducer } from './CMWorklist.reducer';
import { useAuth } from '../../../../../../context/authContext';
import { GetCall } from './api';
import {
  useEQHToastContext
} from "../../../../../../components/toast/EQHToast.context";
import { getGetters } from './getters';
import { getToggles } from './toggles';
import { eventActions } from '../../../constants';
import { useMutation, useQuery } from 'react-query';
import { getDropListByName } from '../../../../../../actions/constantsActions';
import { isEqual } from 'lodash';
import { getQuickListNameByRole } from '../../../utils';

const CMWorklistContext = createContext();

const RequestInfo = {
  isCalling: false
}

const CMWorklistProvider = ({ children }) => {
  const auth = useAuth();
  const authorizedCMWorklistAccess = auth.hasAnyAuthority(["AUTH_CM_WORKLIST_ACCESS"]);
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);
  const role = (state.activeRole.role) ?? auth.getRoles()[0];

  const { removeNotification, setNotification } = useEQHToastContext();

  const GetUrlByRole = (...args) => {
    const role = (state.activeRole.role) ?? auth.getRoles()[0];
    return GetCall(role, ...args)
  }

  const { refetch: getCareManagementTeamsQuery } = useQuery({
    queryKey: ['careManagementTeams'],
    queryFn: () => {
      GetUrlByRole('careManagementTeams', '')
        .then((response) => {
          dispatch({ type: actions.GET_CARE_MANAGEMENT_TEAMS, payload: response.data.map(team => team.name) })
        })
        .catch(err => setCustomWorkListError(["Error", err?.response?.data?.message || err?.message]))
    },
    refetchOnWindowFocus: false,
    manual: true,
    enabled: false
  })

  const { refetch: getAssignPatient } = useQuery({
    queryKey: ['assignPatient'],
    queryFn: () => callbacks?.assignPatientRequest(state?.assignPayload, false),
    refetchOnWindowFocus: false,
    manual: true,
    enabled: false
  })

  const searchPatientFn = () => {
    RequestInfo.isCalling = true;
    dispatch({ type: actions.SET_SELECTED_PATIENTS, payload: [] });
    dispatch({ type: actions.SET_LOADING, payload: true });
    const { leadId } = state.activeRole;
    const params = leadId ? `?programLeadId=${leadId}` : '';
    const payload = {
      pageSize: 20,
      quickListName: "CM_WORKLIST_HUB_COORDINATOR",
      sortParams: [],
      ...state.patientSearch,
    };
    payload.filterParams = [...payload.filterParams].filter(value => Object.keys(value).length !== 0);
    toggles.setPatientSearch({ ...payload });
    return GetUrlByRole('searchPatient', params, payload);
  }

  const getSearchPatient = useMutation(searchPatientFn, {
    onSuccess: (res) => {
      dispatch({ type: actions.SET_PATIENTS_LIST, payload: res?.data?.patientCardDtos });
      dispatch({ type: actions.SET_LOADING, payload: false });
      dispatch({ type: actions.SET_TOTAL_SIZE, payload: res?.data?.totalSize });
      dispatch({ type: actions.ASSIGN_PATIENT, payload: false });
    },
    onError: (err) => {
      dispatch({ type: actions.SET_LOADING, payload: false });
    },
    onSettled: () => {
      RequestInfo.isCalling = false;
    }
  });

  const useDeepCompareMemoize = (value) => {
    const ref = useRef();
    if (!isEqual(value, ref.current)) {
      ref.current = value;
    }
    return ref.current;
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(), delay);
    };
  };

  const memoizedFilterParams = useMemo(() => state?.patientSearch?.filterParams, [state?.patientSearch?.filterParams]);
  const memoizedSortParams = useMemo(() => state?.patientSearch?.sortParams, [state?.patientSearch?.sortParams]);
  const debouncedGetPatientsList = useCallback(debounce(() => getPatientsListAsync(), 300), []);

  useEffect(() => {
    const shouldFetchPatients =
      !state?.activeRole?.custom &&
      memoizedFilterParams !== null &&
      authorizedCMWorklistAccess &&
      state?.careManagementTeams?.length !== 0 &&
      state?.adminRolesHubWL?.length !== 0 &&
      (!RequestInfo.isCalling);
    if (shouldFetchPatients) {
      RequestInfo.isCalling = true;
      debouncedGetPatientsList();
    } else {
      setActiveRole({ ...state?.activeRole, custom: false });
    }
  }, [
    useDeepCompareMemoize(memoizedFilterParams),
    state?.patientSearch?.pageNumber,
    useDeepCompareMemoize(memoizedSortParams),
    state?.patientSearch?.reload,
    state?.activeRole?.leadId,
    debouncedGetPatientsList,
    authorizedCMWorklistAccess,
    state?.careManagementTeams?.length,
    state?.adminRolesHubWL?.length
  ]);

  useEffect(() => {
    toggles.toggleIsLoading(true);
  }, [])

  const getPatientsListAsync = async () => {
    state?.assignPayload && await getAssignPatient()
    getSearchPatient.mutate()
  }

  const setExportSummaryData = (exportSummaryData) => {
    dispatch({ type: actions.SET_EXPORT_SUMMARY_DATA, payload: exportSummaryData })
  }

  const setCustomWorkListError = (errorInfo) => {
    if (errorInfo?.length > 1) {
      setNotification(errorInfo[0], errorInfo[1]);
    } else {
      removeNotification();
    }
  }

  const setActiveRole = (activeRole) => {
    dispatch({ type: actions.SET_ACTIVE_ROLE, payload: activeRole })
  }

  const sendEvent = async (action) => {
    await sendGAEvent('CM Worklist | CareEmpower', {
      event_category: state.activeRole?.role || auth.getRoles()[0],
      action_performed: eventActions[action],
      send_to: 'G-5QKH8J5J9X'
    });
  }

  const callbacks = {
    setCustomWorkListError: setCustomWorkListError,
    setActiveRole: setActiveRole,
    saveActivity: async (patientData) => await GetUrlByRole('saveActivity', '', patientData)
      .then(() => {
      }),
    setExportSummaryData: setExportSummaryData,
    getPatientsList: getPatientsListAsync,
    removePatient: async (patientData) => await GetUrlByRole('saveActivity', '', patientData)
      .then(() => {
        dispatch({ type: actions.TOGGLE_REMOVE_FROM_WORKLIST, payload: false })
        toggles.setPatientSearch({ ...state.patientSearch, pageNumber: 1, reload: !state?.patientSearch?.reload })
      }).catch(err => {
        if (err?.response?.data?.message === 'PATIENT_NOT_FOUND') {
          dispatch({ type: actions.TOGGLE_REMOVE_FROM_WORKLIST, payload: false })
          dispatch({ type: actions.PATIENT_HAS_ALREADY_BEEN_REMOVED, payload: true })
        }
      }),
    getCarePaths: async (id) => await GetUrlByRole('carePathsOpen', id)
      .then(carePaths => {
        dispatch({ type: actions.SELECT_CARE_PATHS, payload: carePaths.data })
      })
      .catch(err => setCustomWorkListError(["Error", err?.response?.data?.message || err?.message])),
    getCarePrograms: async () => await GetUrlByRole('carePrograms', '')
      .then(carePrograms => {
        dispatch({ type: actions.SELECT_CARE_PATHS, payload: carePrograms.data })
      })
      .catch(err => setCustomWorkListError(["Error", err?.response?.data?.message || err?.message])),
    getDeclineReasons: async () => await GetUrlByRole('declinedReasons', '')
      .then(declineReasons => {
        dispatch({ type: actions.DECLINE_REASONS, payload: declineReasons.data })
      }),
    exportToCSV: (filters, sortParams, selectedPatientsEventsKeys) =>
      GetUrlByRole('exportToCSV', '', filters, sortParams, selectedPatientsEventsKeys)
        .then((response) => {
          GetUrlByRole('exportSummary', '')
            .then((response) => {
              setExportSummaryData(response.data);
            })
        }),
    exportToActivator: (filters, sortParams, selectedPatientsEventsKeys) =>
      GetUrlByRole('exportToActivator', '', filters, sortParams, selectedPatientsEventsKeys)
        .then((response) => {
          GetUrlByRole('exportSummary', '')
            .then((response) => {
              setExportSummaryData(response.data);
            })
        }),
    selectPatientEventAction: payload => dispatch({ type: actions.SET_SELECTED_PATIENTS, payload: payload }),
    getTeamsCapacity: async () => await GetUrlByRole('teamCapacity', '')
      .then((response) => {
        dispatch({ type: actions.GET_TEAMS_CAPACITY, payload: response.data })
      })
      .catch(err => setCustomWorkListError(["Error", err?.response?.data?.message || err?.message])),
    getCareManagementTeams: getCareManagementTeamsQuery,
    assignPatient: (payload) => {
      dispatch({ type: actions.SET_ASSIGN_PAYLOAD, payload: payload })
      toggles.toggleAssignPatientTo(false)
      toggles.toggleUpdateReferralStatus(true)
    },
    assignPatientRequest: async (assignPayload, isAssigned = true) => {
      dispatch({ type: actions.SET_ASSIGN_PAYLOAD, payload: null })
      await GetUrlByRole('assignPatient', '', assignPayload)
        .then(() => {
          dispatch({ type: actions.ASSIGN_PATIENT, payload: isAssigned });
        })
        .catch(err => {
          setCustomWorkListError(["Error", err?.response?.data?.message || err?.message])
        })
    },
    getPatientTemplates: async (id) => await GetUrlByRole('patientTemplates', id)
      .then(patientTemplates => {
        toggles.setPatientTemplates(patientTemplates.data);
      }),
    saveAssessment: async (assessmentData) => {
      toggles.toggleIsSavingAssessment(true);
      return await GetUrlByRole('saveAssessment', '', assessmentData)
        .then(() => {
          toggles.toggleIsSavingAssessment(false);
        })
        .catch(err => setCustomWorkListError(["Error", err?.response?.data?.message || err?.message]))
    },
    getWorklistDefaultView: async () => {
      const { leadId } = state.activeRole;

      return await GetUrlByRole('worklistDefaultView', '', leadId)
        .then(worklistDefaultView => {
          let worklistDefaultViewData = null;

          if (worklistDefaultView.data === '') {
            worklistDefaultViewData = {
              id: '',
              quickList: getQuickListNameByRole(role),
              patientRequestDTO: {
                filterParams: [
                  {
                    paramName: 'stepState',
                    paramValue: [
                      'ACTIVE'
                    ],
                    comparison: 'eq',
                    displayValue: [
                      'ACTIVE'
                    ]
                  }
                ],
                sortParams: [],
                pageSize: 0,
                quickListName: null,
                quickFilterName: null,
                previousTotalSize: 0
              }
            }
          } else if (worklistDefaultView.data) {
            worklistDefaultViewData = worklistDefaultView.data;
          }
          toggles.setWorklistDefaultView(worklistDefaultViewData);
          toggles.setPatientSearch({ ...state.patientSearch, filterParams: worklistDefaultViewData?.patientRequestDTO?.filterParams })
        }).catch(() => {
          toggles.toggleIsLoading(false);
          toggles.setWorklistDefaultView(null);
        })
    },
    saveWorklistDefaultView: async (worklistDefaultViewData) => {
      removeNotification();
      return await GetUrlByRole('saveWorklistDefaultView', '', worklistDefaultViewData)
        .then(response => {
          response?.data?.id && setNotification('Successful', 'Custom worklist saved successfully.');
        }).catch(err => {
          removeNotification();
        })
    },
    getCampaigns: async () => await GetUrlByRole('campaigns', '')
      .then(response => {
        dispatch({ type: actions.SET_CAMPAIGNS, payload: response.data })
      }),
    markAsOutreached: async (data) => await GetUrlByRole('saveActivity', '', data)
      .then(() => {
        toggles.toggleMarkAsOutreached(false)
        toggles.setPatientsEventsToBeMarkedAsOutreached([])
        toggles.setPatientSearch({ ...state.patientSearch, pageNumber: 1 });
      }).catch(err => {
        if (err?.response?.data?.message === 'PATIENT_NOT_FOUND') {
          toggles.toggleMarkAsOutreached(false)
          dispatch({ type: actions.PATIENT_HAS_ALREADY_BEEN_OUTREACHED, payload: true })
        }
      }),
    getAdminRolesHubWL: async () => await getDropListByName('ADMIN_ROLES_HUB_WL')
      .then(adminRolesHubWLDropList => toggles.setAdminRolesHubWL(adminRolesHubWLDropList.data.map(item => item.text))),
    sendEvent: async (action) => sendEvent(action)
  }

  const value = {
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <CMWorklistContext.Provider value={value}>
      {children}
    </CMWorklistContext.Provider>
  )
};
const useCMWorklistContext = () => useContext(CMWorklistContext);

export {
  CMWorklistProvider,
  useCMWorklistContext,
}