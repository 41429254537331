import React, { useEffect, Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { findHistory } from "../../actions/patientProfileActions";
import { formatDate } from "../../utils/util";
import { CollapsibleCard } from "../../components/CollapsibleCard";
import { Timeline } from "../../components/Timeline";
import { MiniCard } from "../../components/MiniCard";
import CustomPagination from "../common/CustomPaginaton";
import { FilterGroup } from "../../components/filters/filterGroup";
import {
  patientFilterRiskLevel,
  patientFilterLabGroup,
  patientFilterLabDescriptions,
} from "../../actions/patientFilterActions";
import { GENERIC_DDL_TYPE } from "../../components/dropDownLists/genericDDL";
import { COMPARISON_DDL_TYPE } from "../../components/dropDownLists/comparisonDDL";
import { PatientSearchDTO } from "../../dtos/PatientSearchDTO";
import Spinner from "react-bootstrap/Spinner";
import { getDefaultFilterParams } from "../quickList/quickListSettings";

const componentName = "patientProfile-History";

const isEmpty = require("is-empty");

const VISIT_HISTORY = "Visit History";

const VisitCard = ({ visit, refreshHistory, idx }) => {
  const getProviderName = () => {
    const firstName = visit.providerFirstName || "";
    const lastName = visit.providerLastName || "";
    const metaFullName = firstName + " " + lastName;
    const fullName = metaFullName.trim();

    return isEmpty(fullName) ? "-" : fullName;
  };

  const getDateOfService = () => {
    //no timezone for dates
    const visitStart = formatDate(visit.visitStart);
    const visitEnd = formatDate(visit.visitEnd);

    if (!isEmpty(visitStart) && !isEmpty(visitEnd)) {
      if (visitStart === visitEnd) {
        return visitEnd;
      }
      return visitStart + " - " + visitEnd;
    } else {
      if (!isEmpty(visitStart)) {
        return visitStart;
      }
      if (!isEmpty(visitEnd)) {
        return visitEnd;
      }
    }

    return "-";
  };

  const openContent = (
    <Container fluid>
      <Row xl={4}>
        <Col>
          <MiniCard label="Date of Service" idx={idx} value={getDateOfService()} />
        </Col>
        <Col>
          <MiniCard label="Provider Name" idx={idx} value={getProviderName()} />
        </Col>
        <Col>
          <MiniCard
            label="Provider Specialty"
            idx={idx}
            value={
              !isEmpty(visit.providerSpeciality)
                ? visit.providerSpeciality
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Facility Name"
            idx={idx}
            value={!isEmpty(visit.facilityName) ? visit.facilityName : "-"}
          />
        </Col>
      </Row>
      <Row xl={4}>
        <Col>
          <MiniCard
             label="CPT Code"
             idx={idx}
             value={
               !isEmpty(visit.cptCodes)
                   ? visit.cptCodes.map((cptCodes, i) => (
                       <>
                         {cptCodes}
                         {i != visit.cptCodes.length - 1 && <br />}
                       </>
                   ))
                   : "-"
             }
          />
        </Col>
        <Col>
          <MiniCard
            label="Diagnosis Code"
            idx={idx}
            value={
              !isEmpty(visit.diagnose)
                ? visit.diagnose.map((diagnose, i) => (
                    <>
                      {diagnose}
                      {i != visit.diagnose.length - 1 && <br />}
                    </>
                  ))
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Source"
            value={!isEmpty(visit.verified) ? visit.verified : "-"}
          />
        </Col>
      </Row>
    </Container>
  );

  const closedContent = (
    <Container fluid>
      <Row xl={4}>
        <Col>
          <MiniCard label="Date of Service" idx={idx} value={getDateOfService()} />
        </Col>
        <Col>
          <MiniCard label="Provider Name" idx={idx} value={getProviderName()} />
        </Col>
        <Col>
          <MiniCard
            label="Provider Specialty"
            idx={idx}
            value={
              !isEmpty(visit.providerSpeciality)
                ? visit.providerSpeciality
                : "-"
            }
          />
        </Col>
        <Col>
          <MiniCard
            label="Facility Name"
            idx={idx}
            value={!isEmpty(visit.facilityName) ? visit.facilityName : "-"}
          />
        </Col>
      </Row>
    </Container>
  );
  return (
    <CollapsibleCard openContent={openContent} closedContent={closedContent} />
  );
};

const LabTableHeader = (props) => {
  const onClickHeader = () => {
    if (props.sortBy.sortAttribute === props.dataFieldName) {
      let newDesc;
      if (props.sortBy.desc == null) {
        newDesc = true;
      } else {
        newDesc = !props.sortBy.desc;
      }

      let newSortBy = {
        sortAttribute: props.dataFieldName,
        desc: newDesc,
        priority: 1,
      };

      props.setSortBy(newSortBy);
    } else {
      let newSortBy = {
        sortAttribute: props.dataFieldName,
        desc: true,
        priority: 1,
      };

      props.setSortBy(newSortBy);
    }
  };

  if (props.sortBy.sortAttribute === props.dataFieldName) {
    return (
      <th onClick={onClickHeader}>
        {props.children}{" "}
        {props.sortBy.desc == null ? (
          <i className="fas fa-sort"></i>
        ) : props.sortBy.desc ? (
          <i className="fas fa-sort-down"></i>
        ) : (
          <i className="fas fa-sort-up"></i>
        )}
      </th>
    );
  } else {
    return (
      <th onClick={onClickHeader}>
        {props.children} <i className="fas fa-sort"></i>
      </th>
    );
  }
};

const getSelectedFilterParams = (auth) => {
  let labGroup = {
    key: "Lab Tests",
    selected: [
      { text: "Most Common Tests", value: "Most Common Tests" },
      { text: "CBC", value: "CBC" },
      { text: "CMP", value: "CMP" },
      { text: "Coagulation", value: "Coagulation" },
      { text: "Diabetes", value: "Diabetes" },
      { text: "Lipid", value: "Lipid" },
      { text: "Liver", value: "Liver" },
      { text: "Micro", value: "Micro" },
      { text: "Renal", value: "Renal" },
    ],
    comparison: "eq",
  };
  let defaultParams = [labGroup];
  return getDefaultFilterParams(defaultParams);
};

const FilterHistory = ({
  query,
  filterDefinitions,
  filterParams,
  setFilterParams,
}) => {
  const [totalSize, setTotalSize] = useState();
  const [defaultFilterParams, setDefaultFilterParams] = useState(
    getSelectedFilterParams()
  );
  //const [defaultFilterParams, setDefaultFilterParams] = useState((auth) => []);
  const [sortParams, setSortParams] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSize, setModalSize] = useState(null);

  const handleFilterChange = (varFilterParams) => {
    setFilterParams(varFilterParams);
    setPageNumber(1);
    query(1, varFilterParams);
  };

  return (
    <FilterGroup
      filters={filterDefinitions}
      handleChange={handleFilterChange}
      totalSize={totalSize}
      defaultFilterParams={defaultFilterParams}
      filterParams={filterParams}
      sortParams={sortParams}
      pageNumber={pageNumber}
      pageSize={pageSize}
      quickListType={"ALL_PATIENTS"}
      setModalMessage={setModalMessage}
      setModalSize={setModalSize}
      setModalTitle={setModalTitle}
      hideSaveCustomFilterLists={true}
      skipHistoryPushOnReset={true}
      height={300}
    />
  );
};

const historyTypeRequest = {
  VISIT_HISTORY: "VISIT_HISTORY",
  LAB_HISTORY: "LAB_HISTORY",
  MEDICATION_HISTORY: "MEDICATION_HISTORY",
};

const DEFAULT_SORT = {
  sortAttribute: null,
  desc: null,
  priority: 1,
};

const getFilterDefinitions = (patientId) => {
  const FILTER_DEFINITIONS = [
    {
      type: GENERIC_DDL_TYPE,
      key: "Lab Tests",
      header: "Lab Tests",
      getHeader: () => "Lab Tests",
      componentProps: {
        getData: (text) =>
          patientFilterLabGroup(text, patientId).then((res) => {
            return res.data;
          }),
        showSearchTextbox: true,
      },
    },
    {
      type: COMPARISON_DDL_TYPE,
      key: "Date Range",
      getHeader: () => "Date Range",
      componentProps: {
        allowRange: true,
      },
    },
    {
      type: GENERIC_DDL_TYPE,
      key: "labdescription",
      header: "Lab Description",
      getHeader: () => "Lab Description",
      componentProps: {
        getData: (text) =>
          patientFilterLabDescriptions(text, patientId).then((res) => res.data),
        showSearchTextbox: true,
      },
    },
  ];

  return FILTER_DEFINITIONS;
};

const History = ({ selectedTab, patient }) => {
  const [filterModal, setfilterModalShow] = useState(false);
  const filterModalClose = () => setfilterModalShow(false);
  const filterModalShow = () => setfilterModalShow(true);

  const [visitHistoryData, setVisitHistoryData] = useState(null);
  const [labHistoryData, setLabHistoryData] = useState(null);
  const [medicationHistoryData, setMedicationHistoryData] = useState(null);

  const [showVisitSection, setShowVisitSection] = useState(true);
  const [showLabSection, setShowLabSection] = useState(false);
  const [showMedicationSection, setShowMedicationSection] = useState(false);

  const [sortBy, setSortBy] = useState(DEFAULT_SORT);
  const [showSpinner, setShowSpinner] = useState(false);

  const [filterParams, setFilterParams] = useState((auth) => []);

  useEffect(() => {
    if (selectedTab && patient.id) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let selectedParam = params.get("selected");
      if (selectedParam === "rx") {
        showMedicationHistorySection();
      } else {
        refreshVisitHistory();
      }
    }
  }, [patient.id, selectedTab]);

  useEffect(() => {
    if (patient.id) {
      refreshLabHistory();
    }
  }, [sortBy]);

  const refreshVisitHistory = (pageNumber) => {
    refreshHistory(
      historyTypeRequest.VISIT_HISTORY,
      setVisitHistoryData,
      pageNumber
    );
  };

  const refreshLabHistory = (pageNumber, varFilterParams) => {
    refreshHistory(
      historyTypeRequest.LAB_HISTORY,
      setLabHistoryData,
      pageNumber,
      varFilterParams
    );
  };

  const refreshMedicationHistory = (pageNumber) => {
    refreshHistory(
      historyTypeRequest.MEDICATION_HISTORY,
      setMedicationHistoryData,
      pageNumber
    );
  };

  const handlePaginationChange = (refreshTask, pageNumber) => {
    const timeout = setTimeout(() => {
      refreshTask(pageNumber);
    }, 2000);

    return () => clearTimeout(timeout);
  };

  const refreshHistory = (
    historyRequestType,
    setter,
    pageNumber,
    varFilterParams
  ) => {
    setShowSpinner(true);
    if (pageNumber == null || pageNumber < 1) {
      pageNumber = 1;
    }

    let body = {
      filterParams: [],
      sortParams: [],
      pageNumber: pageNumber,
      pageSize: 20,
      historyRequestType: historyRequestType,
    };

    if (historyRequestType === historyTypeRequest.LAB_HISTORY) {
      if (sortBy.desc != null) {
        body.sortParams.push({
          paramName: sortBy.sortAttribute,
          direction: sortBy.desc ? "DESC" : "ASC",
          priority: sortBy.priority,
        });
      }
      if (isEmpty(varFilterParams)) {
        varFilterParams = filterParams;
      }
      if (!isEmpty(varFilterParams)) {
        body.filterParams.push(...varFilterParams);
      }
    }

    if (historyRequestType === historyTypeRequest.VISIT_HISTORY) {
      body.sortParams.push({
        paramName: "dateofservice",
        direction: "desc",
        priority: sortBy.priority,
      });
      if (!isEmpty(filterParams)) {
        body.filterParams.push(...filterParams);
      }
    }

    findHistory(
      patient.id,
      body,
      (response) => {
        setter(response.data);
        setShowSpinner(false);
      },
      (error) => {
        setShowSpinner(false);
      }
    );
  };

  const showVisitHistorySection = () => {
    if (!showVisitSection) {
      setShowVisitSection(true);
      setShowLabSection(false);
      setShowMedicationSection(false);

      refreshVisitHistory();
    }
  };

  const showLabHistorySection = () => {
    if (!showLabSection) {
      setShowVisitSection(false);
      setShowLabSection(true);
      setShowMedicationSection(false);

      refreshLabHistory();
    }
  };

  const showMedicationHistorySection = () => {
    if (!showMedicationSection) {
      setShowVisitSection(false);
      setShowLabSection(false);
      setShowMedicationSection(true);

      refreshMedicationHistory();
    }
  };

  return (
    <Fragment>
      <Container fluid>
        <Card>
          <Card.Body>
            {showVisitSection && (
              <>
                <Row className="mb-3">
                  <Col>
                    <h6 id={`${componentName}-historyTitle`} className="d-inline-block fw-bold">History</h6>
                  </Col>
                  <Col className="text-end">
                    <ButtonGroup id={`${componentName}-historyGroup`} size="sm" className="d-inline-block align-top">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Visit History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-visitHistoryIcon`}
                          className="me-0"
                          onClick={showVisitHistorySection}
                        >
                          <i className="fa fa-h-square"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Lab History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-labHistoryIcon`}
                          className="me-0"
                          onClick={showLabHistorySection}
                        >
                          <i className="fa fa-flask"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Medication History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-medHistoryIcon`}
                          className="me-0"
                          onClick={showMedicationHistorySection}
                        >
                          <i className="fa fa-prescription"></i>
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                    {!isEmpty(visitHistoryData) && (
                      <>
                        <div className="d-inline-block ms-2">
                          <CustomPagination
                            paginateButClicked={(pageNumber) =>
                              handlePaginationChange(
                                refreshVisitHistory,
                                pageNumber
                              )
                            }
                            MAX_PAGE={Math.ceil(
                              visitHistoryData.totalSize /
                                visitHistoryData.pageSize
                            )}
                            pageNumber={Math.ceil(visitHistoryData.pageNumber)}
                          />
                        </div>{" "}
                      </>
                    )}
                    {showSpinner && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Col>
                </Row>
                {visitHistoryData && (
                  <Row className="mb-3">
                    <Col>
                      <Timeline
                        items={visitHistoryData.visitDtos ?? []}
                        titleBuilder={(visit, idx) => {
                          //no timezone for dates
                          const visitStart = formatDate(visit.visitStart);
                          const visitEnd = formatDate(visit.visitEnd);

                          if (!isEmpty(visitStart) && !isEmpty(visitEnd)) {
                            if (visitStart === visitEnd) {
                              return (
                                <span id={`${componentName}-visitEndDate-${idx}`} className="fw-bold">
                                  {visitEnd}
                                </span>
                              );
                            } else {
                              return (
                                <>
                                  <span id={`${componentName}-visitStartDate-${idx}`} className="fw-bold">
                                    {visitStart}
                                  </span>
                                  <br />
                                  <span id={`${componentName}-visitEndDate-${idx}`} className="fw-bold">
                                    {visitEnd}
                                  </span>
                                </>
                              );
                            }
                          } else {
                            if (!isEmpty(visitStart)) {
                              return (
                                <span id={`${componentName}-visitStartDate-${idx}`} className="fw-bold">
                                  {visitStart}
                                </span>
                              );
                            }
                            if (!isEmpty(visitEnd)) {
                              return (
                                <span id={`${componentName}-visitEndDate-${idx}`} className="fw-bold">
                                  {visitEnd}
                                </span>
                              );
                            }
                          }

                          return "-";
                        }}
                        bodyBuilder={(visit) => (
                          <VisitCard
                            visit={visit}
                            refreshHistory={refreshVisitHistory}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            {showLabSection && (
              <>
                <Row className="mb-3">
                  <Col>
                    <h6 id={`${componentName}-labHistoryTitle`} className="d-inline-block fw-bold me-3">
                      Lab History
                    </h6>
                    <FilterHistory
                      query={refreshLabHistory}
                      filterDefinitions={getFilterDefinitions(patient.id)}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                    />
                  </Col>
                  <Col className="text-end">
                    <ButtonGroup size="sm" className="d-inline-block align-top">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Visit History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-visitHistoryIcon`}
                          className="me-0"
                          onClick={showVisitHistorySection}
                        >
                          <i className="fa fa-h-square"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Lab History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-labHistoryIcon`}
                          className="me-0"
                          onClick={showLabHistorySection}
                        >
                          <i className="fa fa-flask"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Medication History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-medHistoryIcon`}
                          className="me-0"
                          onClick={showMedicationHistorySection}
                        >
                          <i className="fa fa-prescription"></i>
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                    {!isEmpty(labHistoryData) && (
                      <>
                        <div className="d-inline-block ms-2">
                          <CustomPagination
                            paginateButClicked={(pageNumber) =>
                              handlePaginationChange(
                                refreshLabHistory,
                                pageNumber
                              )
                            }
                            MAX_PAGE={Math.ceil(
                              labHistoryData.totalSize / labHistoryData.pageSize
                            )}
                            pageNumber={Math.ceil(labHistoryData.pageNumber)}
                          />
                        </div>{" "}
                      </>
                    )}
                    {showSpinner && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Col>
                </Row>
                <Table id={`${componentName}-labHistoryResults`} striped bordered>
                  <thead>
                    <tr id={`${componentName}-labHistoryResultsHeader`}>
                      <th id={`${componentName}-labHistoryRefPhyHead`}>Referring Physician</th>
                      <th id={`${componentName}-labHistoryCollDateHead`}>Collection Date</th>
                      <th id={`${componentName}-labHistoryLabDescHead`}>Lab Description</th>
                      <th id={`${componentName}-labHistoryResultHead`}>Result</th>
                      <th id={`${componentName}-labHistoryAbnResultHead`}>Abnormal Result</th>
                      <th id={`${componentName}-labHistoryRefRangeHead`}>Reference Range</th>
                      <th id={`${componentName}-labHistorySourceHead`}>Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labHistoryData &&
                      labHistoryData.labDtos &&
                      labHistoryData.labDtos.map((lab, idx) => {
                        return (
                          <tr id={`${componentName}-labHistoryResultsBody-${idx}`}>
                            <td id={`${componentName}-labHistoryRefPhy-${idx}`}>{lab.referringPhysician}</td>
                            <td id={`${componentName}-labHistoryCollDate-${idx}`}>{formatDate(lab.collectionDate)}</td>
                            <td id={`${componentName}-labHistoryLabDesc-${idx}`}>{lab.labDescription}</td>
                            <td id={`${componentName}-labHistoryResult-${idx}`}>{lab.result}</td>
                            <td id={`${componentName}-labHistoryAbnResult-${idx}`}><span className="fw-bold text-danger">{lab.abnormalResult}</span></td>
                            <td id={`${componentName}-labHistoryRefRange-${idx}`}>{lab.referenceRange}</td>
                            <td id={`${componentName}-labHistorySource-${idx}`}>{lab.datasource}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </>
            )}
            {showMedicationSection && (
              <>
                <Row className="mb-3">
                  <Col>
                    <h6 id={`${componentName}-medHistoryTitle`} className="d-inline-block fw-bold">
                      Medication History
                    </h6>
                  </Col>
                  <Col className="text-end">
                    <ButtonGroup size="sm" className="d-inline-block align-top">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Visit History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-visitHistoryIcon`}
                          className="me-0"
                          onClick={showVisitHistorySection}
                        >
                          <i className="fa fa-h-square"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Lab History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-labHistoryIcon`}
                          className="me-0"
                          onClick={showLabHistorySection}
                        >
                          <i className="fa fa-flask"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Medication History</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          id={`${componentName}-medHistoryIcon`}
                          className="me-0"
                          onClick={showMedicationHistorySection}
                        >
                          <i className="fa fa-prescription"></i>
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                    {!isEmpty(medicationHistoryData) && (
                      <>
                        <div className="d-inline-block ms-2">
                          <CustomPagination
                            paginateButClicked={(pageNumber) =>
                              handlePaginationChange(
                                refreshMedicationHistory,
                                pageNumber
                              )
                            }
                            MAX_PAGE={Math.ceil(
                              medicationHistoryData.totalSize /
                                medicationHistoryData.pageSize
                            )}
                            pageNumber={Math.ceil(
                              medicationHistoryData.pageNumber
                            )}
                          />
                        </div>{" "}
                      </>
                    )}
                    {showSpinner && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  {medicationHistoryData &&
                  medicationHistoryData.medicationDtos.length ? (
                    <Col className="mb-3">
                      <Table id={`${componentName}-medHistoryResults`} striped bordered style={{ width: "auto" }}>
                        <thead>
                          <tr id={`${componentName}-medHistoryResultsHeader`}>
                            <th id={`${componentName}-medHistoryDrugNameHead`}>Drug Name</th>
                            <th id={`${componentName}-medHistorySupplyHead`}>Supply(days)</th>
                            <th id={`${componentName}-medHistoryDosageHead`}>Dosage</th>
                            <th id={`${componentName}-medHistoryPresciberNameHead`}>Prescriber Name</th>
                          </tr>
                        </thead>
                        <tbody>
                        {medicationHistoryData &&
                          medicationHistoryData.medicationDtos.map(
                            (medication, idx) => {
                              return (
                                <tr id={`${componentName}-medHistoryResultsBody-${idx}`}>
                                  <td id={`${componentName}-medHistoryDrugName-${idx}`}>{medication.drugName}</td>
                                  <td id={`${componentName}-medHistorySupply-${idx}`}>
                                    {medication.totalDaysSupply
                                      ? medication.totalDaysSupply
                                      : "-"}
                                  </td>
                                  <td id={`${componentName}-medHistoryDosage-${idx}`}>
                                    {medication.dosage
                                      ? medication.dosage
                                      : "-"}
                                  </td>
                                  <td id={`${componentName}-medHistoryPresciberName-${idx}`}>
                                    {medication.prescriberName
                                      ? medication.prescriberName
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  ) : null}
                </Row>
              </>
            )}
          </Card.Body>
        </Card>

        <Modal
          show={filterModal}
          onHide={filterModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  Filter 1<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control as="select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={filterModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={filterModalClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Fragment>
  );
};

export { History };
