import axios from "../../../../../utils/apiClient";

const baseURLPC = process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";
const baseURLMV = process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-visit";
const baseURLDS = process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-visit";

export const findPatientPost = async (id) => {
  return await axios.post(baseURLPC + `/find?patientId=${id}`, {
    headers: { "Content-Type": "application/json" },
  })
};

export const findSuspectDelete = async (subVisitActivity) => {
  return axios.delete(baseURLDS + `/deleteSuspect?id=${subVisitActivity}`, subVisitActivity, {
    headers: { "Content-Type": "application/json" },
  })
};

export const findPatientGet = async (id) => {
  return await axios.get(baseURLMV + `/find?patientId=${id}`, {
    headers: { "Content-Type": "application/json" },
  })
}