import React, { useEffect, useState } from "react";
import { Button } from "../../../components/buttons";
import { Card } from "../../../components/cards";
import { HorizontalDiv } from "../../../components/themes/styles";
import Form from "react-bootstrap/Form";
import SuccessNotification from "../../common/SuccessNotification"
import ErrorToast from "../../common/ErrorToast"
import Modals from "../../../components/modals";
import cloneDeep from "lodash/cloneDeep";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../components/themes/theme1";
import { RolesSelect } from "./RolesSelect";
import { Authorities } from "./Authorities";
import axios from "../../../utils/apiClient";

const componentName="RolesAuthorities"
const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/role-authorities-admin/";
  
const NEW_ROLE_PREFIX = "new-role";

const RolesAuthoritiesContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  padding: ${(props) => props.theme.themeBase.divPadding};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
`;

const ModalLabel = styled(Form.Label)`
  font-weight: ${(props) => props.theme.themeBase.modalFontWeight};
`;

const CustomDiv = styled.div`
  flex: 1;
`;

const Custom1Div = styled.div`
  flex: 1;
`;

const CustomRightDiv = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const CustomLabel = styled(Form.Group)`
  width: 100%;
  height: 215px;
`;

const CustomCard = styled(Card)`
`;

const ToastOverlay = styled.div`
	z-index: 999;
	position: absolute;
	right: 30px;
	bottom: 90px;
	width: 100%;
	
	> * {
		margin-left: auto;
	}
`;

 const RolesAuthorities = () => {
  const isEmpty = require("is-empty");
  const [formData, setFormData] = useState({});
  const [newRoleName, setNewRoleName] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [roleNameInvalid, setRoleNameInvalid] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [roles, setRoles] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [updatedRole, setUpdatedRole] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
      getUserRoles();
      getAuthorities();
  }, []);

  useEffect(() => {
  	const clonedRoles = cloneDeep(roles).sort((a, b) => a.name.localeCompare(b.name));
  	
  	if(updatedRole){
  		setFormData({ ...formData, roles: clonedRoles, currentRoleId: updatedRole.id, roleName: updatedRole.name});
		setSelectedRoleId(updatedRole.id);
	} else {
		setFormData({ ...formData, roles: clonedRoles});
	}
	setUpdatedRole();
  }, [roles]);

  useEffect(() => {
	const clonedAuthorities = cloneDeep(authorities);
	setFormData({ ...formData, authorities: clonedAuthorities});
  }, [authorities]);
  
  const handleSubmit = () => {
  	setShowSubmitConfirmation(false);
  	const data = {
				userRoleId: isNewRole(formData.currentRoleId) ? null : formData.currentRoleId,
				userRoleName: formData.roleName,
			 	authorities: formData.authorities
  	};
  	updateRolesAuthorities(data);
  };
  
  const isNewRole = roleId => {
	return (roleId !== '' && roleId.startsWith(NEW_ROLE_PREFIX));
  }
  
  
  const handleRoleSelected = (selectComponent) => {
  	if(selectComponent.selectedOptions.length > 0) {
  		// prevent multiple selection
  		if(selectComponent.selectedOptions.length > 1) {
		  	selectComponent.selectedOptions.forEach(o => {
		  			if (o.value !== selectedRoleId) o.selected = false
		  		}
		  	);
		  	return;
		}
	  	const currentRoleId = selectComponent.value;
	  	const selectedOption = selectComponent.options[selectComponent.selectedIndex];
	  	const selectedRoleName = selectedOption.text;
	  	
	  	setSelectedRoleId(currentRoleId);
	  	if(!isNewRole(currentRoleId)){
	  		setFormData({ ...formData, currentRoleId: currentRoleId, roleName: selectedRoleName});
	  		getRoleAuthorities(currentRoleId);
		} else {
			const clearedAuthorities = clearAuthoritiesGrants();
			setFormData({ ...formData, authorities: clearedAuthorities, currentRoleId: currentRoleId, roleName: selectedRoleName});
		}
	} else {
		const clearedAuthorities = clearAuthoritiesGrants();
		setFormData({ ...formData, authorities: clearedAuthorities, currentRoleId: '', roleName: ''});
		setSelectedRoleId('');
	}
  }
  
  const handleRoleAdded = e => {
  	if(!newRoleName.match(/^[a-zA-Z0-9\s]+$/)) {
  		setRoleNameInvalid('Role name is invalid');
  		return;
  	}
  	if(formData.roles.find(r => r.name === newRoleName)) {
  		setRoleNameInvalid('A role with that name already exists');
  		return;
  	}
  	const clonedRoles = cloneDeep(formData.roles);
  	const tempRoleId = NEW_ROLE_PREFIX + Date.now();
  	const clearedAuthorities = clearAuthoritiesGrants();
  	setFormData({ 
  		...formData,
  		roles: clonedRoles.concat([{ id: tempRoleId, name: newRoleName }]).sort((a, b) => a.name.localeCompare(b.name)),
  		authorities: clearedAuthorities,
  		currentRoleId: tempRoleId,
  		roleName: newRoleName
  	});
  	setSelectedRoleId(tempRoleId);
  	setNewRoleName('');
  }
  
  const clearAuthoritiesGrants = () => {
  	const clonedAuthorities = cloneDeep(authorities);
	clonedAuthorities.flatMap(a => a.subAuthorities).concat(clonedAuthorities).forEach(a => {
		a.writeGranted = false;
		a.readGranted = false;
	});
	
	return clonedAuthorities;
  }
  
  const handleRoleRemoved = e => {
  	setShowDeleteConfirmation(false);
  	if(!isNewRole(formData.currentRoleId)){
		removeRole(formData.currentRoleId);
	} else {
		const clonedRoles = cloneDeep(formData.roles);
		const index = clonedRoles.findIndex(item => item.id === formData.currentRoleId);
		if(index >= 0){
			clonedRoles.splice(index, 1);
			const clearedAuthorities = clearAuthoritiesGrants();
				setFormData({ 
  				...formData,
  				roles: clonedRoles,
  				authorities: clearedAuthorities,
  				currentRoleId: '',
  				roleName: ''
  			});
  			setSelectedRoleId('');
		}
	}
  };

  const handleAuthorityGrantChanged = e => {
    const { value, checked } = e.target;
	const clonedAuthorities = cloneDeep(formData.authorities);
	
	const authority = clonedAuthorities.flatMap(a => a.subAuthorities).concat(clonedAuthorities).find(item => item.readMapping === value || item.writeMapping === value);
	if (authority){
		if(authority.readMapping === value) {
			authority.readGranted = checked;
			if(!checked){
				authority.subAuthorities.forEach(s => s.readGranted=false);
			}
		} else if(authority.writeMapping === value) {
				authority.writeGranted = checked;
				if(!checked){
					authority.subAuthorities.forEach(s => s.writeGranted=false);
				}
		}
		setFormData({ ...formData, authorities: clonedAuthorities });
	}
  };
  
  const handleRoleNameChanged = e => {
  	setRoleNameInvalid(false);
  	setNewRoleName(e.target.value);
  }
  
  const getUserRoles = () =>  {
    axios
      .get(URL + "user-roles", {
        headers: {
        },
      })
      .then(res => {
      		setRoles(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(err => {
        setErrorMessage("Error fetching roles");
	  });
  };

  const getAuthorities = () => {
    axios
      .get(URL + "authority-categories", {
        headers: {
        },
      })
      .then(res => {
      	setAuthorities(res.data);
      })
      .catch(err => {
		setErrorMessage("Error fetching authorities");
	  });
  };
  
  const getRoleAuthorities = (userRoleId) => {
    axios
      .get(URL + `role-authorities?userRoleId=${encodeURIComponent(userRoleId)}`, {
        headers: {
        },
      })
      .then(res => {
      	setAuthorities(res.data);
      })
      .catch(err => {
        setErrorMessage(err.response.data.message);
	  });
  };
  
  const updateRolesAuthorities = requestData => {
    axios
      .put(URL + "role-authorities", requestData, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
      		const savedRole = res.data.result;
      		const clonedRoles = [...cloneDeep(formData.roles).filter(r => r.id !== savedRole.id).filter(r => !isNewRole(r.id)), savedRole];
      		setUpdatedRole(savedRole);
        	setRoles(clonedRoles);
        	setSuccessMessage("Role successfully saved");
      })
      .catch(err => {
        	setErrorMessage("Error when updating role");
      });
  };
  
  const removeRole = userRoleId => {
    axios
      .delete(URL + `role?userRoleId=${encodeURIComponent(userRoleId)}`, 
      	{
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
			const clearedAuthorities = clearAuthoritiesGrants();
			setAuthorities(clearedAuthorities);
			
			const clonedRoles = cloneDeep(formData.roles).filter(item => item.id !== res.data).sort((a, b) => a.name.localeCompare(b.name));
        	setRoles(clonedRoles);
			
			setSelectedRoleId('');
			
        	setSuccessMessage("Role successfully removed");
      })
      .catch((err) => {
        setErrorMessage("Error when removing role");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <RolesAuthoritiesContainer>
		{ errorMessage && (
        	<ToastOverlay>
	            <ErrorToast errorParam={errorMessage} closeToast={setErrorMessage} />
	        </ToastOverlay>
		)}
        {successMessage && (
        	<ToastOverlay>
        		<SuccessNotification message={successMessage} onClose={()=>setSuccessMessage("")} />
        	</ToastOverlay>
        )}
        <CustomCard>
		        <Card.Body>
					<HorizontalDiv>
				    	<Custom1Div>
			                <Form className="h-100">
				                <Form.Group className="h-100 d-flex flex-column">
				    				<Form.Label id={`${componentName}-rolesLabel`}>Roles</Form.Label>
				    				<RolesSelect id={`${componentName}-roleValue`} className="flex-grow-1" options={formData.roles} onChange={e => handleRoleSelected(e.target)} value={formData.currentRoleId}/>
				    				
				    				<div className="mt-3">
					                   	<Form.Label id={`${componentName}-roleNameLabel`}>Role Name</Form.Label>
					    				<Form.Control id={`${componentName}-roleNameValue`} type="input" onChange={handleRoleNameChanged} value={newRoleName}
					    					className={roleNameInvalid ? "w-50 is-invalid" : "w-50"} />
					    				{roleNameInvalid && (
							    				<div className="invalid-feedback">
										        	{roleNameInvalid}
										        </div>
								        )}
					    				<div className="mt-3">
					    					<Button id={`${componentName}-addRoleButton`} variant="primary" disabled={newRoleName === ''} onClick={handleRoleAdded}>Add</Button>{' '}
					    					<Button id={`${componentName}-removeRoleButton`} variant="primary" backgroundColor="#dc3545" disabled={selectedRoleId === ''} onClick={() => setShowDeleteConfirmation(true)}>Remove</Button>
					    				</div>
					    			</div>
								</Form.Group>
			                </Form>
						</Custom1Div>
			
						<CustomRightDiv>
							<Authorities authorities={formData.authorities} onChange={handleAuthorityGrantChanged} key={'auth_'+selectedRoleId}/>
							<Button id={`${componentName}-submitButton`} variant="primary" onClick={() => setShowSubmitConfirmation(true)} className="float-end" disabled={selectedRoleId === ''} >
								Submit
				            </Button>
						</CustomRightDiv>
		        	</HorizontalDiv>
		        </Card.Body>
		  </CustomCard>
      </RolesAuthoritiesContainer>
      <Modals
        show={showDeleteConfirmation}
        hideFooter={false}
        onCreate={handleRoleRemoved}
        onHide={() => setShowDeleteConfirmation(false)}
        centered={true}
        title="Confirm"
		titleid={`${componentName}-removeConfirm`}
        completeBtn="Confirm"
		completeBtnId={`${componentName}-removeConfirmButton`}
        closeBtn="Cancel"
		closeBtnId={`${componentName}-removeCloseButton`}
        body={<span>Are you sure you want to delete this Role?</span>}
      />
      <Modals
        show={showSubmitConfirmation}
        hideFooter={false}
        onCreate={handleSubmit}
        onHide={() => setShowSubmitConfirmation(false)}
        centered={true}
        title="Roles Authorities"
		titleid={`${componentName}-submitConfirm`}
        completeBtn="Confirm"
		completeBtnId={`${componentName}-submitConfirmButton`}
        closeBtn="Cancel"
		closeBtnId={`${componentName}-submitCloseButton`}
        body={<span>If role is currently assigned to users, their access will be updated next time they log in?</span>}
      />
    </ThemeProvider>
  );
};

export default RolesAuthorities;