import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap'
import '../styles.css';
import { useNewRRContext } from '../context/NewRR.context';

const componentName = 'SelectAll'

export const SelectAll = () => {
  const [isAllSelected, setisAllSelected] = useState(false);
  const [isSomeSelected, setisSomeSelected] = useState(false);
  const {
    referrals,
    referralsSelected,
    clearSelected,
    selectAll
  } = useNewRRContext();

  useEffect(() => {
    const list = referrals?.patientCardDtos?.map(p => p.id)
    setisAllSelected(list?.length > 0 && list?.length == referralsSelected?.length)
    setisSomeSelected(referralsSelected?.length > 0 && list?.length !== referralsSelected?.length)
  }, [referralsSelected])

  return (
    <Dropdown
      id={`${componentName}-selectActions`}
      key={`${componentName}-selectActions-key`}
      className="text-end"
    >
      <Dropdown.Toggle
        className="border mb-2 btn btn-light"
        variant="light"
        size="md"
        id={`${componentName}-menuToggle`}
        key={`${componentName}-menuToggle`}
        style={{ padding: '5px 10px' }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <Form.Check
            type="checkbox"
            id={`${componentName}-createCarePathCheckbox`}
            onChange={() => { }}
            checked={isAllSelected || isSomeSelected}
            style={{ transform: 'scale(1.4)' }}
            className={isSomeSelected ? 'intermediate' : ''}
          />
          <i className="ms-2 fs-6 fa fa-chevron-down"></i>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`}>
        <Dropdown.Item onClick={() => selectAll(referrals?.patientCardDtos?.map(referral => [referral.id, referral?.referralDtoList?.[0]?.id]))}>All</Dropdown.Item>
        <Dropdown.Item onClick={() => clearSelected()}>None</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
