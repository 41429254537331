import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useAssessmentsContext } from '../context/Assessments.context';

const FilterBar = ({ handleFilterSort }) => {
  const {
    sortParams,
    setFilterParams
  } = useAssessmentsContext();

  const [activeFilter, setactiveFilter] = useState(0);

  const handleFilter = (filter) => {
    setactiveFilter(filter)

    const filterParam = filter == 0 ? [] : [{
      paramName: 'status',
      paramValue: [
        filter === 1 ? 'draft' : 'completed'
      ]
    }]

    setFilterParams(filterParam)
    handleFilterSort(1, filterParam, sortParams)
  }

  return (
    <Row className="filterBar flex-nowrap me-3">
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0 rounded-start ${activeFilter === 0 ? 'custom-active' : ''}`}
          onClick={() => handleFilter(0)}
        >All</Button>
      </Col>
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0  ${activeFilter === 1 ? 'custom-active' : ''}`}
          onClick={() => handleFilter(1)}
        >Open</Button>
      </Col>
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0 rounded-end  ${activeFilter === 2 ? 'custom-active' : ''}`}
          onClick={() => handleFilter(2)}
        >Closed</Button>
      </Col>
    </Row>
  )
}

export default FilterBar