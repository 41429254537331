import React from 'react'
import {useCMWorklistContext} from '../context/CMWorklist.context';
import ExportSummaryBody from "../../../../../quickList/ExportSummary";
import Modals from "../../../../../../components/modals";

const componentName = 'ExportSummary';

function ExportSummary() {
    const {
        setExportSummaryData,
        exportSummaryData
    } = useCMWorklistContext();

    return (
        <Modals
            show={exportSummaryData && exportSummaryData.length > 0}
            onHide={() => setExportSummaryData([])}
            title='Export Summary'
            size="lg"
            hideClickButton={true}
            showExtraButton={false}
            hideFooter={false}
            closeBtnId={`${componentName}-closeButton`}
            body={<ExportSummaryBody exportSummary={exportSummaryData || []}/>}
        />
    )
}

export default ExportSummary