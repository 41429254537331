import React from "react"
import Assessments from "./components/Assessments";
import { AssessmentsProvider } from "./context/Assessments.context";
import ExportSummaryModal from "./modalComponents/ExportSummaryModal";

const AssessmentsTab = ({ patient }) => {
  return (
    <AssessmentsProvider>
      <Assessments patient={patient} />
      <ExportSummaryModal />
    </AssessmentsProvider>
  );
}

export default AssessmentsTab;
