import React, { useEffect, useState } from "react";
import { Col, Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  DDLMode,
  GENERIC_DDL_TYPE,
} from "../../components/dropDownLists/genericDDL";
import { Filter } from "../../components/filters/filterGroup";
import { getAssessmentFormTemplates } from "../../actions/assessmentTemplate";
import { getFilterDef } from "../../components/filters/filterDefinition";
import { Button } from "../../components/buttons";
import { patientFilterPCPPractice } from "../../actions/patientFilterActions";
import {getDetailedReport, getStepNameFilter} from "./utils";
import DetailedReport from "./DetailedReport";

const componentName = "AssessmentReport";

const FilterGroups = (client, reportType) => {
  switch (reportType) {
    case "HRM Report":
    case "Activity Report: Logins":
    case "Activity Report: Quality and Risk":
    case "Activity Report: Chart Prep Tool":
      let filterDefinitions = [
        getFilterDef("Reports Time Range", { showRequired: true }),
        getFilterDef(
          "PCP Practice",
          {
            mode: DDLMode.SelectOne,
            highlightWhenHasValue: false,
            showRequired: true,
          },
          "Practice Name"
        ),
      ];
      filterDefinitions[1].componentProps.getData = (text) => patientFilterPCPPractice(text)
        .then((res) => res.data)
        .then(data => {
          data.splice(0, 0, { text: "All EHN", value: "all_ehn" });
          return data;
        });
      return filterDefinitions;
    case "AAWV Report":
      let aawvFilterDefinitions = [
        getFilterDef(
            "PCP Practice",
            {
              mode: DDLMode.SelectOne,
              highlightWhenHasValue: false,
              showRequired: true,
            },
            "Practice Name"
        ),
        getFilterDef("Measure Year", { mode: DDLMode.SelectOne, showRequired: true }, "Year"),
        getFilterDef("Opportunity Status", { showRequired: false }, "Status"),
      ];
      aawvFilterDefinitions[0].componentProps.getData = (text) => patientFilterPCPPractice(text)
          .then((res) => res.data)
          .then(data => {
            data.splice(0, 0, { text: "All EHN", value: "all_ehn" });
            return data;
          });
        return aawvFilterDefinitions;
      case "Diagnosis Report":
      let diagFilterDefinitions = [
        getFilterDef(
            "PCP Practice",
            {
              mode: DDLMode.SelectOne,
              highlightWhenHasValue: false,
              showRequired: true,
            },
            "Practice Name"
        ),
        getFilterDef("Measure Year", { mode: DDLMode.MultipleSelect, showRequired: true }, "Year"),
        getFilterDef("Suspect Status", { showRequired: false }, "Status"),
      ];
      diagFilterDefinitions[0].componentProps.getData = (text) => patientFilterPCPPractice(text)
          .then((res) => res.data)
          .then(data => {
            data.splice(0, 0, { text: "All EHN", value: "all_ehn" });
            return data;
          });
      return diagFilterDefinitions;
    case "ADT Report":
    case "STAR Report":
    case "RAF Summary Report":
    case "RAF Practice Report":
    case "Ecip Report":
    case "Collaborative Care Team Report":
      return [getFilterDef("Reports Time Range", { showRequired: true })];
    case "Assessments Report":
      return [
        getFilterDef("Reports Time Range", { showRequired: true }),
        {
          type: GENERIC_DDL_TYPE,
          getHeader: () => "Assessment Template",
          componentProps: {
            showRequired: true,
            mode: DDLMode.SelectOne,
            highlightWhenHasValue: false,
            showClearButton: false,
            getData: () =>
              getAssessmentFormTemplates([client]).then((res) =>
                res.data.map((x) => {
                  return { text: x.name, value: x.id };
                })
              ),
          },
        },
      ];
    case "Care Program Report":
      return [
        getFilterDef("Reports Time Range"),
        getFilterDef("Reports Status"),
      ];
    case "Care Program Detail Report":
      return [
        getFilterDef("Reports Time Range"),
        getFilterDef("Staff Member", {
          highlightWhenHasValue: false,
          showRequired: false,
          placeholder: "Any"
        }),
      ];
    case "Member Activity Report":
      return [
        getFilterDef("Reports Time Range"),
        getFilterDef("Member Id", {
          showRequired: false,
          placeholder: "All"
        }),
        getFilterDef("Staff Member", {
          highlightWhenHasValue: false,
          showRequired: false,
          placeholder: "Any",
        }),
      ];
    case "Care Program Step Report":
      return [
        getFilterDef("Reports Time Range"),
        getFilterDef("Step Name", {
            mode: DDLMode.SelectOne,
            getData: () =>
                getStepNameFilter([client]).then((res) => res.data),
          }),
      ]
    case "Task Activity Report":
      return [
        getFilterDef("Reports Time Range"),
        getFilterDef("Task State"),
      ];
    case "Lost Patients Report":
      return [
          getFilterDef("Reports Time Range", { showRequired: true })
      ];
    case "Complex Password Tracking Report":
      return [
          getFilterDef("passwordChangedStatus", { showRequired: true })
      ];
    default:
      return null;
  }
};

export const AssessmentReport = ({
  value,
  reportType,
  client,
  handleFilterChange
}) => {
  const [filters, setFilters] = useState(value ?? []);
  const [rows, setRows] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [stepName, setStepName] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [pagination, setPagination] = useState({total:0, page:1});
  const [isLoading, setIsLoading] = useState(false);
  const [randomIdx, setRandomIdx] = useState(Math.random());

  useEffect(() => {
    setFilters([]);
    setShowReport(false);
    setRandomIdx(Math.random());
    //handleFilterChange([]);
  }, [client, reportType]);

  function generateReport() {
    setIsLoading(true);
    getDetailedReport(stepName, 1, dateRange).then(res => {
      setRows(res.data)
      setShowReport(true)
      setPagination({...pagination, "page": 1, "total": res.data.count})
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  }

  const paginate = (i) => {
    if (i) {
        setIsLoading(true);
        getDetailedReport(stepName, i, dateRange).then(res => {
          setRows(res.data)
          setShowReport(true)
          setPagination({...pagination, "page": i, "total": res.data.count})
          setIsLoading(false)
        }).catch(() => setIsLoading(false));
    }
  };

  const filterDefinitions = FilterGroups(client, reportType);

  return (
    <>
      <Row>
        {filterDefinitions && filterDefinitions.map((x, idx) => (
          <Col>
            <Filter
              selected={filters[idx]}
              filterDefinition={x}
              key={`${idx}-${randomIdx}`}
              handleChange={(e) => {
                setFilters(Object.assign([], filters, { [idx]: e }))
                e.paramName === 'Step Name' && setStepName(e.displayValue[0])
                e.paramName === 'Time Range' && setDateRange(e.paramValue)
              }}
              refreshDataOnOpen={true}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <Button
            variant="secondary"
            id={`${componentName}-resetButton`}
            className="me-2"
            onClick={() => setFilters([])}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            id={`${componentName}-exportButton`}
            className="me-2"
            onClick={() => handleFilterChange(filters)}
          >
            Export
          </Button>
          {reportType === 'Care Program Step Report' &&
            <Button
              variant="secondary"
              id={`${componentName}-seeReport`}
              className="align-top"
              onClick={() => generateReport(filters)}
              disabled={dateRange.length < 2 || stepName === ''}
            >
              {
                isLoading
                  ? <Spinner animation="border" variant="secondary" size="sm"/>
                  : "View Report"
              }
            </Button>
          }
        </Col>
      </Row>
      <Row>
        { showReport &&
          <DetailedReport
            rows={rows}
            pagination={pagination}
            paginate={paginate}
            isLoading={isLoading}
          />
        }
      </Row>
    </>
  );
};

export default AssessmentReport;
