import React from "react";
import { useDispatch } from 'react-redux';
import {getPdfFile} from "../../../../actions/fileUploadActions";
import { AppPropertiesUtil } from "../../../../utils/localStorageUtil";
import { getKeyAndId } from '../../../../utils/util';

const componentName = "Resources";

const Resources = () => {

    const usermanualPdf = "/docs/User_Manual.pdf";
    const releaseNotesPdf = "/docs/Release_Notes.pdf";
    const gapDocuments = AppPropertiesUtil.get("react.properties.gap.documents");

  const dispatch = useDispatch();

  const handleClickofLink = (file) => {
      if (file && file.fileUploadId) {
          dispatch(getPdfFile(file.fileUploadId, file.displayName))
      }
  };

  return (
    <>
      <div className="list-group list-group-flush">
          {/*
        <a id={`${componentName}-userManual`} eventKey="0" href={usermanualPdf} target="blank" className="list-group-item list-group-item-action border-0">
          <i className="fa-light fa-file me-2"></i> User Manual
        </a>
        */}
          <a id={`${componentName}-releaseNotes`} eventKey="0" href={releaseNotesPdf} target="blank" className="list-group-item list-group-item-action border-0">
              <i className="fa-light fa-file me-2"></i> Release Notes
          </a>
        {gapDocuments && gapDocuments.map((upload, idx) => {
        return (
          <a {...getKeyAndId(`${componentName}-supportUploads-${idx}`)} className="list-group-item list-group-item-action border-0" eventKey="0" href="#" onClick={() => handleClickofLink(upload)} target="blank">
            <i className="fa-light fa-file me-2"></i> {upload.displayName}
          </a>
        )
      })}
      </div>
    </>
  );
};

export default Resources;
