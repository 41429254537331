import React, {useEffect, useState} from 'react';
import { useAuth } from "../../../context/authContext";
import { Alert } from "react-bootstrap";
import {
    closeReleaseNoteMessage,
    showNewReleaseBanner
} from '../../../actions/broadcastMessengerActions';

const BroadcastNewReleaseNoteMessage = () => {
    const auth = useAuth();
    const token = auth.getAuthToken();
    const [hidden, setHidden] = useState(true);
    const releaseNotesPdf = "/docs/Release_Notes.pdf";

    useEffect(() => {
        (async () => {
            const response = await showNewReleaseBanner(token);
            if (response) {
                setHidden(false);
            }
        })();
    }, []);

    const hideMessage = async () => {
        if (auth.isAuthenticated()) {
            await closeReleaseNoteMessage(token);
            setHidden(true);
        } else {
            setHidden(true);
        }
    }

    return (
        <>
            {!hidden ? (
                <Alert variant="success" className="position-absolute top-0 start-50 translate-middle-x zindex-1040 rounded-0">
                    <div className="d-flex justify-content-between">
                        <span className="me-4">CareEmpower Release 7.8 is live! Click <a href={releaseNotesPdf} onClick={hideMessage} target="blank">here</a> to learn about the newest features and updates.</span>
                        <span><i onClick={hideMessage} className="fa fa-times fa-lg cursor-p"></i></span>
                    </div>
                </Alert>
            ) : null}
        </>
    );
}
export default BroadcastNewReleaseNoteMessage;