import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, ProgressBar, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';
import PatientInformationSummary  from './PatientInformationSummary';
import { RedAsterisk } from '../../../../../../utils/util';
import {useAuth} from "../../../../../../context/authContext";

const componentName = 'AssignPatientToNewCareProgram';

const assignCareProgramTitle = "Assign New Care Program";
const teamCapacityTitle = "Available Team Members";
const programAlreadyAssignedError = "Patient has an open Care Path under this program.";
const programAlreadyAssignedErrorBulk = "One or more patients have an open Care Path under this program.";
const samePatientError = "A care program can't be assigned to one same patient selected more than once";


const submitLabel = "Submit";
const assignLabel = "Assign"

function AssignPatientToNewCareProgram() {
  const {
    assignPatientTo,
    toggleAssignPatientTo,
    patient,
    teamsCapacity,
    getTeamsCapacity,
    getCarePrograms,
    carePaths,
    assignPatient,
    isAssignedPatient,
    getPatientsList,
    assignPatientRequest,
    sendEvent,
    toggleIsBulk,
    isBulk,
    selectedPatientsEvents,
    activeRole,
  } = useCMWorklistContext();

  const auth = useAuth()

  const [note, setNote] = useState('');
  const [currentOpenPrograms, setCurrentOpenPrograms] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [modalTitle, setModalTitle] = useState(assignCareProgramTitle);
  const [isPatientInfoStep, setIsPatientInfoStep] = useState(true);
  const [isTeamCapacityStep, setIsTeamCapacityStep] = useState(false);
  const [isSelectProgramStep, setIsSelectProgramStep] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState(submitLabel);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [selectedProgram, setSelectedProgram] = useState();
  const [programId, setProgramId] = useState("");
  const [isProgramAlreadyAssigned, setIsProgramAlreadyAssigned] = useState(false);
  const [isSamePatientSelected, setIsSamePatientSelected] = useState(false);
  const [listPatients, setListPatients] = useState([]);
  const [isAssignToSelfEnabled, setIsAssignToSelfEnabled] = useState(false);

  useEffect(() => {
    if (assignPatientTo?.show) {
      setListPatients(isBulk ? [...selectedPatientsEvents.map(p => p.data)] : [patient])
      setModalTitle(assignCareProgramTitle);
      setSubmitButtonLabel(submitButtonLabel);
      getTeamsCapacity();
      loadCurrentOpenPrograms();
    } else {
      closeModal()
    }
  }, [assignPatientTo?.show])

  useEffect(() => {
    if(isAssignToSelfEnabled) {
      if (selectedTeam) {
        if(!selectedTeamMember) {
          const userProgramLead = getUserProgramLead();
          if (userProgramLead) {
            selectTeamMember({target: {value: userProgramLead.programLeadId}});
            submit();
          }
        }
      } else {
        const userTeamCapacity = getUserTeamCapacity();
        userTeamCapacity && selectTeam({target: {value: userTeamCapacity.teamId}});
      }
    }
  }, [isAssignToSelfEnabled, selectedTeam])

  const splitPrograms = (patient) => {
    return patient?.openProgramList?.split(",")
  }

  const loadCurrentOpenPrograms = () => {
    if (patient && patient.openProgramList) {
      setCurrentOpenPrograms(splitPrograms(patient));
    }
  }

  const closeModal = () => {
    toggleAssignPatientTo({ ...assignPatientTo, show: false });
    setCurrentOpenPrograms([]);
    setModalTitle(null);
    setIsPatientInfoStep(true);
    setIsTeamCapacityStep(false);
    setSelectedTeamId("");
    setIsSubmitButtonDisabled(true);
    setIsSelectProgramStep(false);
    setIsProgramAlreadyAssigned(false);
    setIsSamePatientSelected(false);
    setSelectedProgram(null);
    setProgramId("");
    toggleIsBulk(false);
    setListPatients([]);
    setIsAssignToSelfEnabled(false);
  }

  const cancel = () => {
    if (isPatientInfoStep) {
      closeModal();
    }
    if (isTeamCapacityStep) {
      setIsTeamCapacityStep(false);
      setIsPatientInfoStep(true);
      setModalTitle(assignCareProgramTitle);
      setSelectedTeam(null);
      setSelectedTeamId("");
      setSubmitButtonLabel(submitLabel);
      setIsSubmitButtonDisabled(true);
      setIsAssignToSelfEnabled(false);
    }
    if (isSelectProgramStep) {
      setIsPatientInfoStep(false);
      setIsTeamCapacityStep(true);
      setIsSelectProgramStep(false);
      setModalTitle(teamCapacityTitle);
      setSubmitButtonLabel(assignLabel);
      setIsSubmitButtonDisabled(false);
    }
    setIsProgramAlreadyAssigned(false);
    setIsSamePatientSelected(false);
    setSelectedProgram(null);
    setProgramId("");
  }

  const selectTeam = (event) => {
    const teamId = event.target.value;
    const team = teamsCapacity.filter(team => team.teamId === teamId)?.shift();
    setSelectedTeam(team);
    setModalTitle(teamCapacityTitle);
    setSelectedTeamId(teamId)
    setIsPatientInfoStep(false);
    setIsTeamCapacityStep(true);
    setSubmitButtonLabel(assignLabel);
    setSelectedTeamMember(null);

    if (isAssignToSelfEnabled) {
      const userTeamCapacity = getUserTeamCapacity();
      if (userTeamCapacity?.teamId !== teamId) {
        setIsAssignToSelfEnabled(false);
      }
    }
  }

  const calculateCapacity = (programLead) => {
    return programLead.totalDailyCapacity - programLead.dailyAssignment;
  }

  const getProgressBarVariant = (programLead) => {
    const totalDailyCapacity = parseInt(programLead.totalDailyCapacity);
    const dailyAssignment = parseInt(programLead.dailyAssignment);
    const halfCapacity = totalDailyCapacity / 2;
    let variant = "success";
    if (dailyAssignment > halfCapacity) {
      variant = "warning";
    }
    if (dailyAssignment >= totalDailyCapacity) {
      variant = "danger"
    }
    return variant;
  }

  const submit = () => {
    if (isTeamCapacityStep) {
      assignTeamMember();
    }
    if (isSelectProgramStep) {
      if (selectedPatientsEvents?.length > 0 && isBulk) {
        const ids = Array.from(new Set(selectedPatientsEvents.map(e => e.patientId)))
        if (ids?.length != selectedPatientsEvents?.length) {
          setIsSamePatientSelected(true)
          return
        }
      }
      assignPatientToTeamMember()
      sendEvent('SUBMIT_ASSIGN_PATIENT');
    }
  }

  const assignTeamMember = () => {
    setIsSelectProgramStep(true);
    setIsTeamCapacityStep(false);
    setIsPatientInfoStep(false);
    setModalTitle(assignCareProgramTitle);
    setSubmitButtonLabel(submitLabel);
    sendEvent('SELECT_TEAM_MEMBER');
    if (!selectedProgram) {
      setIsSubmitButtonDisabled(true);
    }
    if (assignPatientTo.source === "worklist") {
      getCarePrograms();
    }
  }

  const assignPatientToTeamMember = () => {
    if (selectedTeamMember && selectedProgram) {

      const assignPayload = listPatients?.map(patient => ({
        "notes": note,
        "action": "ASSIGN",
        "patientId": patient.id,
        "programLeadId": selectedTeamMember.programLeadId,
        "programId": selectedProgram.value,
        "programName": selectedProgram.text,
        "openCarePath": true,
        "referralId": patient.referralDtoList[0]?.id,
        "adtId": patient.adtId
      }))
      if (listPatients?.length > 1 && isBulk) {
        assignPatientRequest(assignPayload)
      } else {
        const patient = listPatients?.[0];
        patient?.source === "REFERRAL" ? assignPatient(assignPayload) : assignPatientRequest(assignPayload);
      }
    }
  }

  useEffect(() => {
    if (isAssignedPatient) {
      getPatientsList()
      closeModal();
    }
  }, [isAssignedPatient])

  const reassignTeamMember = () => {
    sendEvent('CLICK_REASSIGN');
    setIsSelectProgramStep(false);
    setIsTeamCapacityStep(true);
    setIsSubmitButtonDisabled(false);
    setSubmitButtonLabel(assignLabel);
  }

  const selectTeamMember = (event) => {
    const teamMemberId = event.target.value;
    const teamMember = selectedTeam?.programLeads?.filter(programLead => programLead.programLeadId === teamMemberId)?.shift();
    setSelectedTeamMember(teamMember);
    setIsSubmitButtonDisabled(false);

    if (isAssignToSelfEnabled) {
      const programLead = getUserProgramLead();
      if (programLead?.programLeadUserAccountId !== teamMember?.programLeadUserAccountId) {
        setIsAssignToSelfEnabled(false);
      }
    }
  }

  const selectProgram = (event) => {
    const programId = event.target.value;
    const program = carePaths?.filter(careProgram => careProgram.value === programId)?.shift();
    setIsSubmitButtonDisabled(true);
    setProgramId(programId);
    if (!isValidProgramSelection(program)) {
      setSelectedProgram(program);
      if (program) {
        sendEvent('SELECT_EXISTING_PROGRAM');
        setIsSubmitButtonDisabled(false);
      }
    }
  }

  const isValidProgramSelection = (program) => {
    let programList = isBulk ? listPatients?.map(l => l.openProgramList) : patient?.openProgramList
    if (isBulk && programList?.length > 0) { programList = programList.join(', ') }
    const isValid = programList?.includes(program?.text);
    setIsProgramAlreadyAssigned(isValid);
    return isValid;
  }

  const UpperComponent = ({ patient }) => {
    return (
      <>
        <PatientInformationSummary
          rowLayout={2}
          fullName={patient.fullName}
          memberId={patient.memberId}
          dateOfBirth={patient.dateOfBirth}
          className='mb-3'
        />
        {(isPatientInfoStep || isSelectProgramStep) ? isBulk
          ? splitPrograms(patient)?.length > 0 && <CarePrograms patient={patient} currentOpenPrograms={splitPrograms(patient)}/>
          : currentOpenPrograms?.length > 0 && <CarePrograms patient={patient} currentOpenPrograms={currentOpenPrograms}/>
          :<></>
        }
      </>
    )
  }

  const CarePrograms = ({ patient }) => {
    return (
      <Row className='mb-5'>
        <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
          <Row className="mb-1">
            <Col md={8}>
              <Form.Label id={`${componentName}-currentLabel`}>
                Current Care Programs
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Label id={`${componentName}-currentLabel`}>
                Days Opened
              </Form.Label>
            </Col>
          </Row>

          {patient?.openCarePathPrograms?.map((program, index) => (
            <Row className="mb-1">
              <Col md={8}>
                {program.name}
              </Col>
              <Col md={4}>
                {program.daysSinceStarted}
              </Col>
            </Row>
          ))}
        </Form.Group>
      </Row>
    )
  }

  const getUserTeamCapacity = () => {
    const userTeam = auth.getTeam() ?? {};
    return teamsCapacity?.find((data, i) => !!userTeam[data.teamId]);
  }

  const getUserProgramLead = () => {
    const userId = auth.getUserId();
    return getUserTeamCapacity()?.programLeads?.find((programLead, i) => {
      return userId === programLead.programLeadUserAccountId
    })
  }

  const assignToSelf = (enabled) => {
    setIsTeamCapacityStep(true);
    setIsPatientInfoStep(true);
    setModalTitle(assignCareProgramTitle);
    setSelectedTeam(null);
    setSelectedTeamId("");
    setSubmitButtonLabel(submitLabel);
    setIsSubmitButtonDisabled(true);
    setIsAssignToSelfEnabled(enabled);
  }

  const showAssignToSelf = () => {
    if (auth.isAdmin()) {
      return false;
    }
    return !(activeRole?.role === 'Hub Coordinator' || auth.getRoles()[0] === 'Hub Coordinator' );
  }

  return (
    <Modal
      id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModal`}
      size="md"
      show={assignPatientTo?.show}
      onHide={() => closeModal()}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-patientAssignPatientToNewCareProgramConfirmTitle`}>{modalTitle}</Modal.Title>
        <button
          id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        />
      </Modal.Header>
      <Modal.Body style={{ height: "35rem", overflow: 'auto' }} >
        <div
          className={`rounded-3 ${isSamePatientSelected ? 'border-danger' : ''}`}
          style={{
            border: isSamePatientSelected ? 'solid 1px' : '',
            padding: '2px 12px'
          }}>
          {
            <Row
              className={`bg-light p-3 rounded-3`}
              style={{
                maxHeight: '200px',
                overflow: 'auto',
              }}
            >

              <Col>
                  {listPatients.map(p =>
                    <UpperComponent patient={p} key={`${p?.id}-${p?.adtId}-upper`} />
                  )}
              </Col>
            </Row>
          }
        </div>
        <small className="fw-bold text-danger" hidden={!isSamePatientSelected}>{samePatientError}</small>
        <Row className="mt-4 mb-4">
          <Col>
            <Row>
              <Form.Group id={`${componentName}-team`}>

                <div className="d-flex justify-content-between">
                  <Form.Label id={`${componentName}-teamLabel`}>
                    Team<RedAsterisk/>
                  </Form.Label>

                  { showAssignToSelf() && <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      disabled={!teamsCapacity?.length || isSelectProgramStep}
                      value={isAssignToSelfEnabled}
                      checked={isAssignToSelfEnabled}
                      onChange={(e) => {
                         assignToSelf(!isAssignToSelfEnabled)
                      }}
                    />
                    <Form.Check.Label className="fw-bold">Assign to
                      Self?</Form.Check.Label>
                  </Form.Check> }
                </div>

                <select
                  id={`${componentName}-teamOptions`}
                  className="form-select"
                  disabled={isSelectProgramStep}
                  onChange={event => selectTeam(event)}
                  value={selectedTeamId}
                >
                  <option id="teamOption-select" value="">Select</option>
                  {teamsCapacity?.map((data, i) => (
                    <option id={`teamOption${i}`} value={data.teamId} key={`${data.teamId}-teamCapacity`}>{data.teamName}</option>
                  ))}
                </select>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {isTeamCapacityStep && selectedTeam?.programLeads?.length > 0 &&
          <Row>
            <Col>
              <Row className="fw-bold">
                <Col>
                  <span>Team Member<RedAsterisk/></span>
                </Col>
                <Col className="text-end">
                  <span>Capacity</span>
                </Col>
              </Row>
              <Row style={{ maxHeight: '365px', overflow: 'auto' }}>
                <ToggleButtonGroup
                  type="radio"
                  className="careTeamCapacity d-flex flex-column"
                  name="careTeamCapacity"
                  value={selectedTeamMember?.programLeadId}
                >
                  {selectedTeam.programLeads.map((programLead, index) => (
                    <ToggleButton
                      key={`${programLead?.programLeadId}-${index}-${selectedTeamId}`}
                      id={`tbg-radio-${index}-${selectedTeamId}`}
                      variant="team-member"
                      className={`border p-3 text-dark rounded-3 mb-2 ${selectedTeamMember?.programLeadId == programLead?.programLeadId ? 'active' : ''}`}
                      value={programLead.programLeadId}
                      onChange={(event) => selectTeamMember(event)}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column text-start w-50">
                          <span className="text-dark">{programLead.programLeadName}</span>
                          {(selectedTeam.teamName === 'Community Health Workers' && programLead.zone) ? (
                            <small className="text-muted text-truncate">{programLead.zone}</small>
                          ) : (
                            <small className="text-muted">{programLead.market}</small>
                          )
                          }
                        </div>
                        <div className="d-flex flex-column text-end w-50">
                          <ProgressBar variant={getProgressBarVariant(programLead)} min={0} max={programLead.totalDailyCapacity} now={programLead.dailyAssignment} className="w-100" />
                          <small className="text-muted">{`${calculateCapacity(programLead)} of ${programLead.totalDailyCapacity} available`}</small>
                        </div>
                      </div>
                    </ToggleButton>
                  ))
                  }
                </ToggleButtonGroup>
              </Row>
            </Col>
          </Row>
        }
        {
          isSelectProgramStep && selectedTeamMember && (
            <Row className="mb-4">
              <Col>
                <Row>
                  <Form.Group>
                    <Form.Label id={`${componentName}-assignedTeamMemberLabel`}>
                      Assigned Team Member
                    </Form.Label>
                    <div className="d-flex justify-content-between align-items-center rounded-3 border p-3">
                      <div className="d-flex flex-column">
                        <span className="fw-bold">{selectedTeamMember.programLeadName}</span>
                        <span>{selectedTeam.teamName}</span>
                        <span>{selectedTeamMember.zone}</span>
                      </div>
                      <div>
                        <Button variant="link" className="px-3 text-decoration-none" onClick={() => reassignTeamMember()}>
                          Reassign
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          )
        }
        {isSelectProgramStep &&
          <>
            <Row className="mb-4">
              <Col>
                <Row>
                  <Form.Group id={`${componentName}-program`}>
                    <Form.Label id={`${componentName}-programLabel`}>
                      Program<RedAsterisk/>
                    </Form.Label>

                    <select id={`${componentName}-programOptions`} className={`form-select ${isProgramAlreadyAssigned ? 'border-danger' : ''}`}
                      value={programId}
                      onChange={(event) => selectProgram(event)}
                    >
                      <option value="">Select</option>
                      {carePaths.map(program => (
                        <option value={program.value}>{program.text}</option>
                      ))}
                    </select>
                    <small className="fw-bold text-danger" hidden={!isProgramAlreadyAssigned}>{isBulk ? programAlreadyAssignedErrorBulk : programAlreadyAssignedError}</small>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Form.Group>
                    <Form.Label id={`${componentName}-notesLabel`}>
                      Notes
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      max="1000"
                      onChange={e => setNote(e.target.value)}
                      id={`${componentName}-notesValue`}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-patientAssignPatientToNewCareProgramNoBtn`} variant="secondary"
          onClick={() => cancel()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-submit`}
          variant="primary"
          disabled={isSubmitButtonDisabled}
          aria-disabled={isSubmitButtonDisabled}
          onClick={() => submit()}>
          {submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AssignPatientToNewCareProgram;