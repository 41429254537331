import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useManageVisitContext } from "../views/quickList/components/context/ManageVisitContext/ManageVisit.context";

const componentName = "toastMessage";

export const ToastMessage = ({ title='Error' }) => {
    const manageVisitContext = useManageVisitContext();


    const [toastObject, setToastObject] = useState({ show: false, message: '', title: '' });

    useEffect(() => {
        if (manageVisitContext && manageVisitContext.error_from_services) {
            if (manageVisitContext.error_from_services.errorMessage) {
                setToastObject({ ...toastObject, show: true, message: manageVisitContext.error_from_services.errorMessage, title: title })
            }
            else {
                setToastObject({ ...toastObject, show: false, message: '', title: '' })
            }

        }

    }, [manageVisitContext?.error_from_services?.errorMessage])

    const onToastCloseHandler=()=>{
        manageVisitContext.persistErrorMessage('')
    }

    return (
        <>
            <Toast onClose={onToastCloseHandler} show={toastObject.show} variant="primary" position="bottom-end" className="position-fixed bottom-0 end-0 zindex-1040 text-bg-primary m-3">
                <Toast.Header className="border-bottom-0 bg-primary p-4 pb-0" closeButton={true}>
                    <strong className="me-auto fs-5 text-white">{toastObject.title}</strong>
                    {/*<button type="button" className="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>*/}
                </Toast.Header>
                <Toast.Body className="bg-primary rounded-3 p-4">
                    {toastObject.message}
                </Toast.Body>
            </Toast>
        </>
    );
};

export default ToastMessage;