import React from 'react'
import { Button, Col, Dropdown } from 'react-bootstrap'
import { useCMWorklistContext } from './context/CMWorklist.context';
import { bulkActionsItems } from '../../constants';

const componentName = 'BulkActions';

function BulkActions() {
  const {
    selectedPatientsEvents,
    toggleAssignPatientTo,
    toggleRemoveFromWorklist,
    toggleIsBulk,
    activeRole,
  } = useCMWorklistContext();

  const handleClick = (item) => {
    toggleIsBulk(true)
    switch (item) {
      case 'Assign':
        toggleAssignPatientTo({show: true, source: "worklist"})
        break;
      case 'Remove':
        toggleRemoveFromWorklist(true)
      default:
        break;
    }
  }

  return (
    <Dropdown id={`${componentName}-dropDown`} key={`${componentName}-dropDown-key`} className="me-2 border rounded">
      <Dropdown.Toggle
        variant={ selectedPatientsEvents.length <= 1 ? 'light' : 'primary' }
        text={ selectedPatientsEvents.length <= 1 ? 'secondary' : 'white' }
        id={`${componentName}-memberAction`}
        disabled={selectedPatientsEvents.length <= 1}
        style={{
          backgroundColor: selectedPatientsEvents.length <= 1 ? '#f6f7f9' :'#007db7',
          borderColor: selectedPatientsEvents.length <= 1 ? '#f6f7f9' :'#007db7',
        }}
      >
        Bulk Actions
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`}
        key={`${componentName}-dropDownMenu-key`}>
        {bulkActionsItems(activeRole?.role).filter((item, itemIdx) => item.visible).map((item, itemIdx) => {
          return (
            <Col
              id={`${componentName}-colMenuItem-${itemIdx}`}
              key={`${componentName}-colMenuItem-${item.value}`}
            >
              {item.hasDivider &&
                <Dropdown.Divider
                  id={`${componentName}-itemDivider-${itemIdx}`}
                  key={`${componentName}-itemDivider-${item.value}`}
                />
              }
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => handleClick(item.value)}
              >{item.text}</Dropdown.Item>
            </Col>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default BulkActions