import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { mapReferralServicesToText } from '../../Referrals/referralUtils'
import { visit_type_referrals } from '../../../../utils/textValueLists'
import { getDisplayValue } from '../../../../utils/util'

const componentName = "PracticeColumn"

const Practice = ({ referral, idx }) => {
  return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row>
        <Col className='col-md-auto'>
          <span className='fw-bold'>PCP Practice: </span>
          <span>{referral?.referralDtoList[0]?.referredPracticeName}</span>
        </Col>
      </Row>
      <Row>
        <Col id={`${componentName}-services-${idx}`} className="d-inline-block text-muted col-md-auto">
          <span id={`${componentName}-memberIDValue-${idx}`}>
            {referral?.referralDtoList[0]?.referralType === "SPECIALIST"
              ? getDisplayValue(visit_type_referrals, referral?.referralDtoList[0]?.visitType)
              : mapReferralServicesToText(referral?.referralDtoList[0]?.servicesNeeded)
            }
          </span>
        </Col>
      </Row>
      <Row>
        <Col id={`${componentName}-sender-${idx}`} className="d-inline-block text-muted col-md-auto">

          <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold">Sender: </span>
          <span>{referral?.referralDtoList[0]?.sentByFullName}</span>
        </Col>
      </Row>
    </Col>
  )
}

export default Practice