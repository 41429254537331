import React, { useContext, useState } from "react";
import {
  AccordionContext,
  Badge,
  OverlayTrigger,
  Tooltip,
  useAccordionButton
} from "react-bootstrap";
import styled from "styled-components";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import isEmpty from "is-empty";
import { formatDate, getKeyAndId } from "../../../utils/util";
import { CARE_OPPORTUNITIES_TYPE } from "./CareOpportunitiesAccordion";

const opportunitiesStatuses = [
  {
    source: "ORDERED",
    name: "Ordered",
    closesGap: false,
  },
  {
    source: "APPOINTMENT_SCHEDULED",
    name: "Scheduled Appointment",
    closesGap: false,
  },
  {
    source: "MARKED_FOR_CLOSURE",
    name: "Marked for Closure",
    closesGap: true,
  },
  {
    source: "PATIENT_REFUSAL",
    name: "Patient Refused",
    closesGap: false,
  },
  {
    source: "CHANGED_PCPS",
    name: "Changed PCP's",
    closesGap: false,
  },
  {
    source: "LEFT_MESSAGE",
    name: "Left Message",
    closesGap: false,
  },
  {
    source: "UNABLE_TO_CONTACT",
    name: "Unable to Contact",
    closesGap: false,
  },
  {
    source: "OPEN",
    name: "Open",
    closesGap: false,
  },
];

const StyledBadgeBtn = styled(Badge)`
  :hover {
    cursor: pointer;
  }
`;

const cignaGaps = ["CBP", "CDC10", "CDC4", "COL", "PDC-STA"];

const getGapAwaitingChartToolTip = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "AAWV"
  ) {
    if (
      (gap.careGapSummary.dualValidation === null ||
        gap.careGapSummary.dualValidation === "AWAITING_CHART") &&
      gap.careGapSummary.validation.validation === null
    ) {
      return "Awaiting Chart";
    } else if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "In Review";
    } else if (gap.careGapSummary.validation.validation === "APPROVED") {
      return "Chart Approved";
    } else if (gap.careGapSummary.validation.validation === "REJECTED") {
      return "Chart Rejected";
    } else {
      return "";
    }
  } else {
    if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "In Review";
    }
    else {
      return "";
    }
  }
};

const getGapAwaitingChartContent = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "AAWV"
  ) {
    if (
      (gap.careGapSummary.dualValidation === null ||
        gap.careGapSummary.dualValidation === "AWAITING_CHART") &&
      gap.careGapSummary.validation.validation === null
    ) {
      return "fas fa-clipboard-user text-danger";
    } else if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "fas fa-clipboard-user text-primary";
    } else if (gap.careGapSummary.validation.validation === "APPROVED") {
      return "fas fa-clipboard-user text-muted";
    } else if (gap.careGapSummary.validation.validation === "REJECTED") {
      return "fas fa-clipboard-user text-danger";
    } else {
      return "";
    }
  } else {
    if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "fas fa-clipboard-user text-primary";
    }
    else {
      return "";
    }
  }
};

const getGapAwaitingClaimToolTip = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "AAWV"
  ) {
    if (
      gap.careGapSummary.dualValidation === null ||
      gap.careGapSummary.dualValidation === "AWAITING_CLAIM"
    ) {
      return "Awaiting Claim";
    } else if (
      gap.careGapSummary.dualValidation === "AWAITING_CHART" ||
      gap.careGapSummary.dualValidation === "DUAL_APPROVED"
    ) {
      return "Verified by claim";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const getGapAwaitingClaimContent = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "AAWV"
  ) {
    if (
      gap.careGapSummary.dualValidation === null ||
      gap.careGapSummary.dualValidation === "AWAITING_CLAIM"
    ) {
      return "fas fa-notes-medical text-danger";
    } else if (
      gap.careGapSummary.dualValidation === "AWAITING_CHART" ||
      gap.careGapSummary.dualValidation === "DUAL_APPROVED"
    ) {
      return "fas fa-notes-medical text-muted";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const CareOpportunitiesList = ({
  opportunities,
  additionalOpportunities,
  selectedOpportunities,
  onChange,
  listType,
  itemType,
  componentName,
  patient,
  disableCheckList = false,
  disableCheckbox = () => false,
}) => {
  const [showAdditionalOpportunities, setShowAdditionalOpportunities] =
    useState(false);
  const [isOppPlusMinus, setOppPlusMinus] = useState(false);
  // const [data, setData] = useState({
  //   selectedGaps: [],
  // });

  // const setDataAndCallOnChange = (data) => {
  //   setData(data);
  //   onChange(data);
  // };

  const updateSelected = (item, selected, gapType) => {
    onChange(item, itemType, selected, gapType);
  };

  const checkRejectionPermissions = (gap) => {
    if (
      (gap.abbreviation === "WC15" ||
        gap.abbreviation === "WCV" ||
        gap.abbreviation === "WC30")
    ) {
      return false;
    } else if (
        patient.clientId !== "AZEH" &&
      (gap.abbreviation === "AWC")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {opportunities.length
        ? opportunities.map((gap, idx) => (
            <div
              className="d-flex flex-row mb-1"
              id={`${componentName}-${listType}-CareOpportunities-${idx}`}
              key={gap.careGapSummary.id}
            >
              <div className="d-inline-block me-2">
                <input
                  type="checkbox"
                  id={`${componentName}-${listType}-CareOpportunitiesCheck-${idx}`}
                  disabled={disableCheckList || disableCheckbox(gap)}
                  checked={selectedOpportunities.find(
                    (x) => x.careGapSummary.id === gap.careGapSummary.id
                  )}
                  onClick={(e) => updateSelected(gap, e.target.checked, "main")}
                />
              </div>
              <div
                id={`${componentName}-${listType}-CareOpportunitiesSummary-${idx}`}
                className="d-inline-block me-2"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{gap.careGapSummary.description}</Tooltip>}
                >
                  <div>
                  {gap.careGapSummary.validation.validation === "REJECTED" &&
                    checkRejectionPermissions(gap.careGapSummary) && (
                      <span
                        id={`${componentName}-${listType}-CareOpportunitiesRejected-${idx}`}
                        className="text-danger fw-bold"
                      >
                        !
                      </span>
                    )}{" "}
                    {gap.openedViaMetric ? (
                      <i
                        id={`${componentName}-${listType}-CareOpportunitiesCigna-${idx}`}
                        className="fas fa-star text-danger me-1"
                      ></i>
                    ) : null}
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesGapName-${idx}`}
                    >
                      {gap.careGapSummary.fullNameAndYear}
                    </span>{" "}
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesGapAbbr-${idx}`}
                    >
                      {gap.careGapSummary.visitsUpperBound
                        ? `(${gap.careGapSummary.visitsLowerBound}/${gap.careGapSummary.visitsUpperBound})`
                        : null}
                    </span>{" "}
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesGapStatus-${idx}`}
                    >
                      ({gap.careGapSummary.status.name})
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div
                id={`${componentName}-${listType}-CareOppSummaryAwaiting-${idx}`}
                className="d-inline-block text-nowrap"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{getGapAwaitingChartToolTip(gap)}</Tooltip>}
                >
                  <i
                    id={`${componentName}-${listType}-CareOpportunitiesAwaitingChart-${idx}`}
                    className={getGapAwaitingChartContent(gap)}
                  ></i>
                </OverlayTrigger>{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{getGapAwaitingClaimToolTip(gap)}</Tooltip>}
                >
                  <i
                    id={`${componentName}-${listType}-CareOpportunitiesAwaitingClaim-${idx}`}
                    className={getGapAwaitingClaimContent(gap)}
                  ></i>
                </OverlayTrigger>
              </div>
              {listType === CARE_OPPORTUNITIES_TYPE.toc && (
                <div className="d-inline-block text-nowrap">
                  <Badge
                    pill
                    id={`${componentName}-${listType}-TOCDueDate-${idx}`}
                    bg="light"
                    className="border ms-2 text-dark"
                  >
                    Due Date {formatDate(gap.careGapSummary.dueDate)}
                  </Badge>
                </div>
              )}
              <br />
            </div>
          ))
        : null}
      <div className="d-block mb-1">
        <StyledBadgeBtn
          hidden={listType===CARE_OPPORTUNITIES_TYPE.Validation}
          bg="light"
          pill
          id={`${componentName}-${listType}-CareOpportunitiesCI`}
          className="border text-dark"
          onClick={() => {
            setShowAdditionalOpportunities(!showAdditionalOpportunities);
            setOppPlusMinus(!isOppPlusMinus);
          }}
        >
          <i className={isOppPlusMinus ? "fa fa-minus" : "fa fa-plus"}></i> Show
          Closed/ Inactive
        </StyledBadgeBtn>
      </div>
      {additionalOpportunities.length && showAdditionalOpportunities
        ? additionalOpportunities.map((gap, idx) => (
            <div
              id={`${componentName}-${listType}-CareOpportunitiesCISummary-${idx}`}
              className="d-flex flex-row mb-1"
              key={gap.careGapSummary.id}
            >
              <div className="d-inline-block me-2">
                <input
                  id={`${componentName}-${listType}-CareOpportunitiesCICheck-${idx}`}
                  type="checkbox"
                  style={{ visibility: "hidden" }}
                  disabled={true}
                />{" "}
              </div>
              {gap.careGapSummary.validation.validation === "REJECTED" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Rejected</Tooltip>}
                >
                  <span
                    id={`${componentName}-${listType}-CareOpportunitiesCIRejected-${idx}`}
                    className="text-danger fw-bold"
                  >
                    !
                  </span>
                </OverlayTrigger>
              ) : null}
              <div className="d-inline-block me-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{gap.careGapSummary.description}</Tooltip>}
                >
                  <div>
                    {gap.openedViaMetric ? (
                      <i
                        id={`${componentName}-${listType}-CareOpportunitiesCIMetric-${idx}`}
                        className="fas fa-star text-danger"
                      ></i>
                    ) : null}
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesCIYear-${idx}`}
                      className="text-primary cursor-p"
                      onClick={(e) => updateSelected(gap, true, "additional")}
                    >
                      {gap.careGapSummary.fullNameAndYear}
                    </span>
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesCIVisits-${idx}`}
                    >
                      {gap.careGapSummary.visitsUpperBound
                        ? `(${gap.careGapSummary.visitsLowerBound}/${gap.careGapSummary.visitsUpperBound})`
                        : null}
                    </span>{" "}
                    <span
                      id={`${componentName}-${listType}-CareOpportunitiesCIStatus-${idx}`}
                    >
                      ({gap.careGapSummary.status.name})
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="d-inline-block text-nowrap">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip> {getGapAwaitingChartToolTip(gap)} </Tooltip>
                  }
                >
                  <i
                    id={`${componentName}-${listType}-CareOpportunitiesCIAwaitingChart-${idx}`}
                    className={getGapAwaitingChartContent(gap)}
                  ></i>
                </OverlayTrigger>{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{getGapAwaitingClaimToolTip(gap)}</Tooltip>}
                >
                  <i
                    id={`${componentName}-${listType}-CareOpportunitiesCIAwaitingClaim-${idx}`}
                    className={getGapAwaitingClaimContent(gap)}
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          ))
        : null}
    </>
  );
};

export { CareOpportunitiesList };
