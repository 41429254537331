import axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { clearFileUploads } from '../../../../actions/fileUploadActions';
import { useAuth } from '../../../../context/authContext';
import { neededServicesEnums } from '../../../../utils/textValueLists';
import { convertDateToString } from '../../../../utils/util';
import { convertIcd10, urgencyOptions } from './utils';
import { searchPatients } from "../../../../actions/caremgmt/hubWorkCenterAction";
import {getRecommendedTeams} from "../../../../actions/patientProfileActions";

export default function SubmitReferralHook(setShowSubmitReferral, patient, addNewReferral) {

  const dispatch = useDispatch();

  const auth = useAuth();

  const baseURLSR =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/submit-referral";

  const baseURLICD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/icd10";

  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showProviderModal, setshowProviderModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [options, setOptions] = useState([]);
  const [referralObj, setReferralObj] = useState({
    referralType: "CARE_COORDINATION",
    conditions: [{ code: [], description: [] }],
    urgency: urgencyOptions[3],
    visits: 1,
    expiration: null,
    comments: "",
    servicesNeeded: [],
    servicesNeededOtherText: null,
  });
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [icdEvent, setIcdEvent] = useState(null);
  const [showEmergentMsg, setShowEmergentMsg] = useState(false);
  const [isAddingMember, setAddingMember] = useState(false);
  const [patientSelected, setPatientSelected] = useState(patient);
  const [patientSearchParam, setPatientSearchParam] = useState("");
  const [results, setResults] = useState([]);
  const [isEmptyResult, setisEmptyResult] = useState(false);
  const [showMemberList, setShowMemberList] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isMemberDisabled, setMemberDisabled] = useState(false);
  const [recommendedTeams, setRecommendedTeams] = useState(false);

  useEffect(() => {
    let newState = referralObj;
    var newDate = new Date();
    var numberOfDaysToAdd = 180;
    newDate = newDate.setDate(newDate.getDate() + numberOfDaysToAdd);
    newState.expiration = newDate;
    setReferralObj(newState);
  }, []);

  const asyncCodeSearch = (query) => {
    setIsLoadingComp(true);
    axios
      .get(
        baseURLICD + `/find-by-code?code=${query}&offset=${1}&pageSize=${30}`,
        { headers: {} }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        await setOptions(newOptions);
        setIsLoadingComp(false);
      })
      .catch((error) => {
        setIsLoadingComp(false);
      });
  };

  const handleIcdChangeImpl = (e, index) => {
    let currentIndex = index + 1;
    if (e.length) {
      let newState = referralObj;
      newState.conditions[currentIndex].code.push(e[0].code);
      newState.conditions[currentIndex].description.push(e[0].description);
      setReferralObj({ ...newState });
    } else {
      let newState = referralObj;
      newState.conditions[currentIndex].code = [];
      newState.conditions[currentIndex].description = [];
      setReferralObj({ ...newState });
    }
  };

  const handleChange = (name, value) => {
    setReferralObj({ ...referralObj, [name]: value });
    if (
      name === "urgency" &&
      value.value === "40_EMERGENT"
    ) {
      setShowEmergentMsg(true);
    }
  };

  const handleIcdChange = (e, index) => {
    let regex = /^F1[0-9]+.*$/;
    if (e.length != null && e.length > 0 && regex.test(e[0].code)) {
      setIcdEvent({ code: e, index });
    } else {
      handleIcdChangeImpl(e, index);
    }
  };

  const handlePatientSelected = (patient, event) => {
    if (event.target.checked) {
      setPatientSelected(patient);
      setPatientSearchParam(patient.surnameCommaName);
      setReferralObj({ referralType: 'CARE_COORDINATION', ...referralObj })
    } else {
      setPatientSelected();
    }
  }

  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };

  const addNewCondition = () => {
    let length = referralObj.conditions.length;
    let newState = referralObj;
    if (length < 4) {
      let newCon = { code: [], description: [] };
      newState.conditions.push(newCon);
      setReferralObj({ ...newState });
    }
  };

  const handleCloseMemberList = () => {
    setPatientSearchParam('');
    setPatientSelected();
    setResults([]);
    setButtonDisabled(true);
    setAddingMember(false);
    setShowMemberList(false);
  };

  const handleAddMember = () => {
    setAddingMember(false);
    setMemberDisabled(true);
    setShowMemberList(false);
  }

  const closeSubmitModal = () => {
    setShowSubmitReferral(false);
  }

  const removeNewCondition = (index) => {
    let currentIndex = index + 1;
    let newState = referralObj;
    newState.conditions.splice(currentIndex, 1);
    setReferralObj({ ...newState });
  };

  const submitReferral = () => {
    let filteredIcds = convertIcd10(referralObj);
    let newState = referralObj;
    const userName = auth.getName();
    let finalDate = null;
    if (typeof referralObj.expiration === "number") {
      finalDate = convertDateToString();
    } else {
      finalDate = referralObj.expiration;
    }

    const getFileObj = (file) => {
      return {
        description: null,
        fileMetadata: file,
      };
    };

    const query = {
      patientId: patientSelected.id,
      userCreated: null,
      referredByOrg: null,
      referredBy: null,
      referredTo: null,
      referredByProvider: null,
      referrerName: userName,
      referredToOrg: null,
      referredToProvider: null,
      referralDueDate: null,
      refereeName: null,
      referredPractice: null,
      note: null,
      referredToPracticeId: null,
      referredToPCPId: null,
      visitType: null,
      urgency: referralObj.urgency.value,
      refDateUTC: new Date(Date.now()).toISOString(),
      status: "50_PENDING",
      reason: null,
      sentByFullName: userName,
      referredPractitioner: null,
      statusUpdated: null,
      files:
        uploadedFiles.length
          ? uploadedFiles.map(getFileObj)
          : null,
      referredPracticePhone: null,
      referredPracticeAddress: null,
      lastModifiedByFullName: null,
      lastModified: null,
      referralComments: referralObj.comments,
      statusComments: null,
      part2Data: true,
      diagnosisICD10dto: filteredIcds,
      senderPracticeName: null,
      recepientPracticeName: null,
      dueDate: null,
      declineSource: null,
      respondedToBy: null,
      visitCount: 0,
      visitExpirationDate: finalDate,
      referralType: referralObj.referralType,
      servicesNeeded: referralObj.servicesNeeded,
      servicesNeededOtherText: referralObj.servicesNeededOtherText,
      teamId: referralObj.recommendedTeam?.teamId
    };
    axios
      .post(baseURLSR + "/save", query, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async() => {
        newState.conditions = [{ code: [], description: [] }];
        await setReferralObj({ ...newState });
        await dispatch(clearFileUploads());
        setFileUploadResetCounter(fileUploadResetCounter + 1);
        closeSubmitModal();
      })
      .catch((error) => {
      });
  };

  const filterBy = () => true;

  const handleServiceChange = (services) => {
    let objCopy = referralObj;
    objCopy.servicesNeeded = services.map(service => {
      return {service: service.value, primary: service.isPrimary};
    });
    objCopy.servicesNeededOtherText = getOtherServiceText(services);
    setReferralObj({ ...objCopy });
  };

  const getOtherServiceText = (services) => {
    const otherService = services.find(service => service.value === "OTHER");
    if (otherService) {
      return otherService.otherItemText;
    }
    return null;
  }

  const handlePatientSearchParamChange = (event) => {
    setPatientSearchParam(event.target.value);
    if (event.target.value.trim() === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleShowMemberList = () => {
    setAddingMember(true);
    setShowMemberList(true);
    searchPatients(patientSearchParam).then((response) => {
      setResults(response.data?.patients || []);
      setisEmptyResult(response.data?.patients.length === 0);
      setAddingMember(false);
      setButtonDisabled(true);
    }).catch(() => setAddingMember(false));
  }

  const loadRecommendedTeams = () => {
    getRecommendedTeams((response) => setRecommendedTeams(response.data));
  }

  return ({
    fileUploadResetCounter, setFileUploadResetCounter,
    uploadedFiles, setUploadedFiles,
    showProviderModal, setshowProviderModal,
    isOpen, setIsOpen,
    isLoadingComp, setIsLoadingComp,
    options, setOptions,
    referralObj, setReferralObj,
    selectedProvider, setSelectedProvider,
    icdEvent, setIcdEvent,
    showEmergentMsg, setShowEmergentMsg,
    isAddingMember, setAddingMember,
    patientSelected, setPatientSelected,
    patientSearchParam, setPatientSearchParam,
    results, setResults,
    isEmptyResult, setisEmptyResult,
    showMemberList, setShowMemberList,
    buttonDisabled, setButtonDisabled,
    isMemberDisabled, setMemberDisabled,
    asyncCodeSearch,
    handleChange,
    handleIcdChange,
    handlePatientSelected,
    handleUploadFileChange,
    addNewCondition,
    handleCloseMemberList,
    handleAddMember,
    removeNewCondition,
    closeSubmitModal,
    submitReferral,
    filterBy,
    handleServiceChange,
    handlePatientSearchParamChange,
    handleShowMemberList,
    recommendedTeams,
    loadRecommendedTeams
  })
}
