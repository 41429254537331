import {
  SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL
} from "../../actions/types";

const initialState = {
  isVisible: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL:
      return {
        ...state,
        isVisible: payload
      };
    default:
      return state;
  }
}