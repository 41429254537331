import {Badge} from "react-bootstrap";
import styled from "styled-components";
import {CARE_GAP_STATUS_BY_ENUM} from '../../../utils/constants'

const COLORS = {
  GREEN: '#519940',
  LIGHT_GREEN: '#EEF5EC',
  GRAY: '#86898B',
  LIGHT_GREY: '#EAEBEB',
}

const STATUS_BY_COLOR_MAP = {
  [CARE_GAP_STATUS_BY_ENUM.OPEN]: {font: COLORS.GREEN, background: COLORS.LIGHT_GREEN},
  [CARE_GAP_STATUS_BY_ENUM.ORDERED]: {font: COLORS.GRAY, background: COLORS.LIGHT_GREY},
  [CARE_GAP_STATUS_BY_ENUM.PATIENT_REFUSAL]: {
    font: COLORS.GRAY,
    background: COLORS.LIGHT_GREY
  },
  [CARE_GAP_STATUS_BY_ENUM.EXCLUDED]: {
    font: COLORS.GRAY,
    background: COLORS.LIGHT_GREY
  },
  [CARE_GAP_STATUS_BY_ENUM.PREVIOUSLY_COMPLETED]: {
    font: COLORS.GRAY,
    background: COLORS.LIGHT_GREY
  },
  [CARE_GAP_STATUS_BY_ENUM.MARKED_FOR_CLOSURE]: {
    font: COLORS.GRAY,
    background: COLORS.LIGHT_GREY
  },
  [CARE_GAP_STATUS_BY_ENUM.GAP_INELIGIBLE]: {
    font: COLORS.GRAY,
    background: COLORS.LIGHT_GREY
  },
  "Default": {font: COLORS.GRAY, background: COLORS.LIGHT_GREY},
}

export const StatusButton = styled.button`
color: ${(props) => {
  const colorConfig = STATUS_BY_COLOR_MAP[props.status];
  return colorConfig?.font ?? STATUS_BY_COLOR_MAP['Default'].font;
}};
background-color: ${(props) => {
  const colorConfig = STATUS_BY_COLOR_MAP[props.status];
  return colorConfig?.background ?? STATUS_BY_COLOR_MAP['Default'].background;
}};
font-weight: bold;
font-size: 12px;
padding: 5px 10px;
`;

export const StyledBadgeBtn = styled(Badge)`
  :hover {
    cursor: pointer;
  }
  padding: 5px 10px;
  border-radius: 4px !important;
  background-color: #F6F7F7;
`;

export const StyledRejectedValidationExclamation = styled.span.attrs(
  {"className": "text-danger fa-regular fa-circle-exclamation me-1"})`
  display: ${props => props.validation === "REJECTED" && props.status === "Open"
  ? "" : "none !important"};
`;