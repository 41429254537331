import React, {Fragment, useEffect, useState} from "react";
import {
  getPatientProfileOverviewCards,
  useGetImportantNotes,
  useGetPatientProfileRecentActivity,
} from "../../actions/patientProfileActions";
import {Timeline} from "../../components/Timeline";
import {MiniCard} from "../../components/MiniCard";
import Badge from "react-bootstrap/Badge";
import {CollapsibleCard} from "../../components/CollapsibleCard";
import {EditPatientModal} from "./EditPatientProfileModal";
import {EQHLink} from "../../components/EQHLink";
import {overviewRecentActivitiesReducer} from "../../reducers/patientProfile/overviewRecentActivitiesReducer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {getTabCount} from "./PatientProfile";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  formatDate,
  formatDateTime, formatDateTimeShifted,
  formatLocalDate,
  formatTimeOnly,
  getDisplayValue,
  getFieldValueLabel,
  getFieldValueVal,
} from "../../utils/util";
import {patientProfileField, status_referrals,} from "../../utils/textValueLists";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {useAuth} from "../../context/authContext";
import {patProfHasAccessTo} from "../../utils/patientProfilePermissions";
import _ from "lodash";
import {importantNotesReducer} from "../../reducers/patientProfile/importantNotesReducer";

const componentName = "patientProfile-Overview";

const isEmpty = require("is-empty");
/*
export const PRINT_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print";
export const SUMMARY_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
*/
export const PREVENTIVE_CARE_COUNT_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/patient-profile/preventive-care/count";

const ActivityCard = ({ activity }) => {
  if (activity) {
    //const fieldValuesKVP = arrayToKVP(activity.fieldValues, "caption", "value"); Object.entries(fieldValuesKVP).map(([key, value]) => {
    const openContent = (
      <Container fluid>
        <Row xl={3}>
          {activity.fieldValues &&
            activity.fieldValues.map((x) => {
              let miniCardlabel = getFieldValueLabel(x);
              let miniCardValue = getFieldValueVal(x);

              if (
                miniCardlabel === "startdate" ||
                miniCardlabel === "secondary_address.last_updated.previous" ||
                miniCardlabel === "secondary_address.last_updated.new"
              ) {
                miniCardValue = formatDate(miniCardValue);
              } else if (miniCardlabel === "status") {
                miniCardValue =
                  miniCardValue === "status_false" ? "Inactive" : miniCardValue === "status_true" ? "Active" : miniCardValue;
              } else if (miniCardlabel === "primary") {
                miniCardValue =
                  miniCardValue === "primary_false" ? "No" : "Yes";
              } else if (miniCardlabel === "file") {
                miniCardValue = JSON.parse(miniCardValue).value;
              } else if (
                miniCardlabel === "Start" ||
                miniCardlabel === "End"
                ) {
                  miniCardValue = formatDateTime(miniCardValue);
              } else if (
                  miniCardlabel === "admitdate" ||
                  miniCardlabel === "dischargedate"
              ) {
                miniCardValue = formatDateTimeShifted(miniCardValue);
              } else if (miniCardlabel === "dateofservice") {
                miniCardValue = formatDate(miniCardValue);
              }

              miniCardlabel = getDisplayValue(
                patientProfileField,
                miniCardlabel.toLowerCase()
              );

              if (!miniCardlabel) {
                let v = getFieldValueLabel(x);
                if (v) {
                  if (_.isObject(v)) {
                    v = JSON.stringify(v);
                  }
                  if (_.isString(v)) {
                    miniCardlabel = v;
                  }
                }
                if(miniCardlabel == "Start Date") {
                  miniCardValue = formatDate(miniCardValue);
                }
              }

              return (
                <Col>
                  <MiniCard label={miniCardlabel} value={miniCardValue} />
                </Col>
              );
            })}
        </Row>
      </Container>
    );
    return (
      <CollapsibleCard
        title={activity.description}
        openContent={openContent}
        closedContent={<></>}
      />
    );
  }
  return <></>;
};



const NotesCard = ({ notes, idx }) => {
  if (notes) {
    //const fieldValuesKVP = arrayToKVP(activity.fieldValues, "caption", "value"); Object.entries(fieldValuesKVP).map(([key, value]) => {
    const openContent = (
      <Container fluid>
        <Row>
          <Col>
            <span id={`${componentName}-noteText-${idx}`} className="wordwrap">{notes.noteText}</span>
          </Col>
        </Row>
      </Container>
    );
    return (
      <CollapsibleCard
        title={"Note"}
        openContent={openContent}
        closedContent={<></>}
      />
    );
  }
  return <></>;
};
const Overview = ({
  patientId,
  patientOverviewPkg,
  selectedTab,
  setSelectedTab,
  reloadPatientOverview,
}) => {
  const auth = useAuth();

  const recentActivityPkg = useGetPatientProfileRecentActivity(
    [],
    overviewRecentActivitiesReducer
  );
  const notesPkg = useGetImportantNotes(
    [],
    importantNotesReducer
  );

  


  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [referralStatus, setReferralStatus] = useState(null);
  const [referredTo, setReferredTo] = useState(null);
  const [referredBy, setReferredBy] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [sentDate, setSentDate] = useState(null);
  const [latestHospitalEventDate, setLatestHospitalEventDate] = useState(null);
  const [latestHospitalEventType, setLatestHospitalEventType] = useState(null);
  const [latestAppointmentDate, setLatestAppointmentDate] = useState(null);
  const [
    latestAppointmentPracticeName,
    setLatestAppointmentPracticeName,
  ] = useState(null);
  const [carePathStatus, setCarePathStatus] = useState(null);
  const [carePathName, setCarePathName] = useState(null);
  const [stepName, setStepName] = useState(null);
  const [noteText, setNoteText] = useState(null);
  const [showRecentActivitySpinner, setShowRecentActivitySpinner] = useState(
    false
  );

  const [showImportantNotes, setShowImportantNotes] = useState(false);
  const handleCloseImportantNotes = () => setShowImportantNotes(false);
  const handleShowImportantNotes = () => setShowImportantNotes(true);

  const refreshRecentActivityPkg = () => {
    recentActivityPkg.fetchData(patientId);
  };

  useEffect(() => {
    if (selectedTab) {
      recentActivityPkg.fetchData(patientId);
      notesPkg.fetchData(patientId);
      getPatientProfileOverviewCards(patientId, (response) => {
        setReferralStatus(response.data.latestReferral?.referralStatus);
        setReferredTo(response.data.latestReferral?.referredTo);
        setReferredBy(response.data.latestReferral?.referredBy);
        setDueDate(response.data.latestReferral?.dueDate);
        setSentDate(response.data.latestReferral?.sentDate);

        setLatestHospitalEventDate(response.data.latestAdt?.date);
        setLatestHospitalEventType(response.data.latestAdt?.type);

        setLatestAppointmentDate(
          response.data.latestAppointment?.appointmentDate
        );
        setLatestAppointmentPracticeName(
          response.data.latestAppointment?.practiceName
        );

        setCarePathStatus(response.data.currentStep?.carePathStatus);
        setCarePathName(response.data.currentStep?.carePathName);
        setStepName(response.data.currentStep?.stepName);
        setNoteText(response.data.importantNote?.noteText);
      });
    }
  }, [patientId, selectedTab]);

  const getCaregiverName = () => {
    if(patientOverviewPkg.state.data.caregiverFirstName &&
    patientOverviewPkg.state.data.caregiverLastName){
      return (
        <div className="font-12">
          <div className="mb-2">
            <span id={`${componentName}-piCaregiverLabel`} className="d-block fw-bold">Caregiver Name</span>
            <span id={`${componentName}-piCaregiverValue`} className="d-block">{patientOverviewPkg.state.data.caregiverFirstName + " " + patientOverviewPkg.state.data.caregiverLastName}</span>
          </div>
        </div>
      );
    }
    return ""
  };

  const getCaregiversContact = () => {
    if(!isEmpty(patientOverviewPkg.state.data.caregiverContact)){
      return (
        <div className="font-12">
          <div className="mb-2">
            <span id={`${componentName}-piCaregiverContactLabel`} className="d-block fw-bold">Caregiver's Contact</span>
            <span id={`${componentName}-piCaregiverContactValue`} className="d-block">{patientOverviewPkg.state.data.caregiverContact}</span>
          </div>
        </div>
      )
    }
    return ""
  };

  const getHealthPlanCareManager = () => {
    if(!isEmpty(patientOverviewPkg.state.data.healthPlanCareManager?.name)){
      return (
        <div className="font-12">
          <div className="mb-2">
            <span id={`${componentName}-piHealthCareManagerLabel`} className="d-block fw-bold">Health Plan Care Manager</span>
            <span id={`${componentName}-piHealthCareManagerValue`} className="d-block">{patientOverviewPkg.state.data.healthPlanCareManager.name}</span>
          </div>
        </div>
      )
    }
    return ""
  };

  const getCareCoordinator = () => {
    if(!isEmpty(patientOverviewPkg.state.data.careCoordinator?.name)){
      return (
        <div className="font-12">
          <div className="mb-2">
            <span id={`${componentName}-piCareCoordinatorLabel`} className="d-block fw-bold">Care Coordinator</span>
            <span id={`${componentName}-piCareCoordinatorValue`} className="d-block">{patientOverviewPkg.state.data.careCoordinator.name}</span>
          </div>
        </div>
      )
    }
    return ""
  };

  const getTreatingProvidersContent = () => {
    if (!isEmpty(patientOverviewPkg.state.data.specialists)) {
      return (
        <div className="font-12">
          <span id={`${componentName}-piTreatingProvLabel`} className="d-block fw-bold">Treating Providers</span>
          {patientOverviewPkg.state.data.specialists.map((specialist, idx) => {
            return (
              <Card id={`${componentName}-piTreatingProvValue-${idx}`} className="mb-3 border">
                <Card.Body className="p-2">
                  <div id={`${componentName}-piTPName-${idx}`} className="mb-2">
                    <span id={`${componentName}-piTPNameLabel-${idx}`} className="d-block fw-bold">Name</span>
                    <span id={`${componentName}-piTPNameValue-${idx}`} className="d-block">{specialist.name}</span>
                  </div>
                  <div id={`${componentName}-piTPPhoneNumber-${idx}`} className="mb-2">
                    <span id={`${componentName}-piTPPhoneNumberLabel-${idx}`} className="d-block fw-bold">Phone Number</span>
                    <span id={`${componentName}-piTPPhoneNumberValue-${idx}`} className="d-block">{specialist.phone}</span>
                  </div>
                  <div id={`${componentName}-piTPSpecialties-${idx}`} className="mb-2">
                    <span id={`${componentName}-piTPSpecialtiesLabel-${idx}`} className="d-block fw-bold">Specialties</span>
                    <span id={`${componentName}-piTPSpecialtiesValue-${idx}`} className="d-block">{specialist.specialtiesToString}</span>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      );
    }

    return "";
  };

  const getSecondaryAddressContent = () => {
    const sa = patientOverviewPkg.state.data.secondaryAddress
    if (!isEmpty(sa) && (!isEmpty(sa.streetAddress) || !isEmpty(sa.city) || !isEmpty(sa.state) || !isEmpty(sa.zipCode)
        || !isEmpty(sa.source) || !isEmpty(sa.otherSource))) {

      return (
        <div id={`${componentName}-piSecAddress`} className="font-12">
          <span id={`${componentName}-piSecAddressLabel`} className="d-block fw-bold">Secondary Address</span>
          <Card id={`${componentName}-piSecAddressItem`} className="mb-3 border">
            <Card.Body className="p-2">
              <div id={`${componentName}-piSAAddress`} className="mb-2">
                <span id={`${componentName}-piSAAddressLabel`} className="d-block fw-bold">Address</span>
                <span id={`${componentName}-piSAAddressValue`} className="d-block">{sa.streetAddress}</span>
              </div>
              <div id={`${componentName}-piSACity`} className="mb-2">
                <span id={`${componentName}-piSACityLabel`} className="d-block fw-bold">City</span>
                <span id={`${componentName}-piSACityValue`} className="d-block">{sa.city}</span>
              </div>
              <div id={`${componentName}-piSAState`} className="mb-2">
                <span id={`${componentName}-piSAStateLabel`} className="d-block fw-bold">State</span>
                <span id={`${componentName}-piSAStateValue`} className="d-block">{sa.state}</span>
              </div>
              <div id={`${componentName}-piSAZip`} className="mb-2">
                <span id={`${componentName}-piSAZipLabel`} className="d-block fw-bold">Zip</span>
                <span id={`${componentName}-piSAZipValue`} className="d-block">{sa.zipCode}</span>
              </div>
              <div id={`${componentName}-piSASource`} className="mb-2">
                <span id={`${componentName}-piSASourceLabel`} className="d-block fw-bold">Source</span>
                <span id={`${componentName}-piSASourceValue`} className="d-block">{sa.source}</span>
              </div>
              <div id={`${componentName}-piSAOSource`} className="mb-2">
                <span id={`${componentName}-piSAOSourceLabel`} className="d-block fw-bold">Other Source</span>
                <span id={`${componentName}-piSAOSourceValue`} className="d-block">{sa.otherSource}</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }

    return "";
  };

  const getPreferredPhoneContent = () => {
    if (!isEmpty(patientOverviewPkg.state.data.preferredPhone)) {
      let i = 0
      return (
          <div className="font-12">
            {
              patientOverviewPkg.state.data.preferredPhone.map((phone) => {
                return (
                    <div className="mb-2">
                      <span className="d-block fw-bold">Preferred Phone {++i}</span>
                      <span className="d-block">{phone}</span>
                    </div>
                );
              })
            }
          </div>
      );
    }

    return "";
  };

  const getPreferredEmailContent = () => {
    if (!isEmpty(patientOverviewPkg.state.data.preferredEmail)) {
      let i = 0
      return (
        <div className="font-12">
          {patientOverviewPkg.state.data.preferredEmail.map((email) => {
            return (
                <div id={`${componentName}-piPrefEmail`} className="mb-2">
                  <span id={`${componentName}-piPrefEmailLabel`} className="d-block fw-bold">Preferred Email {++i}</span>
                  <span id={`${componentName}-piPrefEmailValue`} className="d-block">{email}</span>
                </div>
              );
            })
          }
        </div>
      );
    }

    return "";
  };

  const getMrnContent = () => {
    if (!isEmpty(patientOverviewPkg.state.data.mrn)) {
      return (
        <div className="font-12">
          <div id={`${componentName}-piMRN`} className="mb-2">
            <span id={`${componentName}-piMRNLabel`} className="d-block fw-bold">MRN</span>
            <span id={`${componentName}-piMRNValue`} className="d-block">{patientOverviewPkg.state.data.mrn}</span>
          </div>
        </div>
      );
    }
    return "";
  };

  const getOtherLanguageContent = () => {
    if (!isEmpty(patientOverviewPkg.state.data.otherLanguages)) {
      return (
        <div className="font-12">
          <div id={`${componentName}-piOLang`} className="mb-2">
            <span id={`${componentName}-piOLangLabel`} className="d-block fw-bold">Other Language</span>
            <span id={`${componentName}-piOLangValue`} className="d-block">{patientOverviewPkg.state.data.otherLanguages.sort().join(', ')}</span>
          </div>
        </div>
      );
    }
    return "";
  };

  const getLineOfBusiness = () => {
    if (!isEmpty(patientOverviewPkg.state.data.lineOfBusiness)) {
      return (
          <div className="font-12">
            <div id={`${componentName}-piLOB`} className="mb-2">
              <span id={`${componentName}-piLOBLabel`} className="d-block fw-bold">Line Of Business</span>
              <span id={`${componentName}-piLOBValue`} className="d-block">{patientOverviewPkg.state.data.lineOfBusiness}</span>
            </div>
          </div>
      );
    }
    return "";
  };

  const getPrimaryLanguage = () => {
    if (!isEmpty(patientOverviewPkg.state.data.primaryLanguage)) {
      return (
          <div className="font-12">
            <div id={`${componentName}-piPLang`} className="mb-2">
              <span id={`${componentName}-piPLangLabel`} className="d-block fw-bold">Primary Language</span>
              <span id={`${componentName}-piPLangValue`} className="d-block">{patientOverviewPkg.state.data.primaryLanguage}</span>
            </div>
          </div>
      );
    }
    return "";
  };

  const getNurse = () => {
    if (!isEmpty(patientOverviewPkg.state.data.nurse)) {
      return (
          <div className="font-12">
            <div id={`${componentName}-piNurse`} className="mb-2">
              <span id={`${componentName}-piNurseLabel`} className="d-block fw-bold">Nurse</span>
              <span id={`${componentName}-piNurseValue`} className="d-block">{patientOverviewPkg.state.data.nurse.name}</span>
            </div>
          </div>
      );
    }
    return "";
  };

  const getPCP = () => {
    if(!isEmpty(patientOverviewPkg.state.data.pcp?.name)) {
      return (
          <div className="font-12">
            <div id={`${componentName}-piPCP`} className="mb-2">
              <span id={`${componentName}-piPCPLabel`} className="d-block fw-bold">PCP</span>
              <span id={`${componentName}-piPCPValue`} className="d-block">{patientOverviewPkg.state.data.pcp.name}</span>
            </div>
          </div>
      );
    }
    return "";
  };


  return (
    <Fragment>
      <Container fluid>
        <div id={`${componentName}-overviewQuickLook`} className="d-flex flex-nowrap mb-3">
          {noteText && (
            <div id={`${componentName}-qlINotes`} className="me-4 mb-2 bg-white shadow-sm border p-2 cursor-p w-20" onClick={handleShowImportantNotes}>
              <div className="d-flex flex-row">
                <div className="me-2">
                  <i id={`${componentName}-qlINotesIcon`} className="fa fa-notes-medical fa-2x mb-2"></i>
                </div>
                <div id={`${componentName}-qlINotesText`}>
                  <div className="mb-0 card-title fw-bold">
                    <div id={`${componentName}-qlINotesLabel`} className="d-block">Important Notes</div>
                  </div>
                  <div id={`${componentName}-qlINotesValue`} className="multitruncate">{noteText}</div>
                </div>
              </div>
            </div>
          )}
          {patProfHasAccessTo("HospitalEvents", auth) &&
            latestHospitalEventDate != null &&
            latestHospitalEventType != null && (
              <div id={`${componentName}-qlHosEvents`}
                className="me-4 mb-2 bg-white shadow-sm border p-2 cursor-p w-20"
                onClick={() => setSelectedTab("HospitalEvents")}
              >
                <div className="d-flex flex-row">
                  <div className="me-2">
                    <i id={`${componentName}-qlHosEventsIcon`} className="far fa-hospital fa-2x mb-2"></i>
                  </div>
                  <div>
                    <div id={`${componentName}-qlHosEventsText`} className="mb-0 card-title fw-bold">
                      <div id={`${componentName}-qlHosEventsLabel`} className="d-block">{latestHospitalEventType}</div>
                      <div id={`${componentName}-qlHosEventsValue`} className="d-block">
                        {formatDate(latestHospitalEventDate)}
                      </div>
                    </div>
                    <small id={`${componentName}-qlHosEventsDesc`}>Last Hospital Event</small>
                  </div>
                </div>
              </div>
            )}
          {patProfHasAccessTo("PreventiveCare", auth) &&
            (getTabCount(patientOverviewPkg, "activeGaps") > 0 ||
              getTabCount(patientOverviewPkg, "activeRafsCount") > 0) && (
              <div id={`${componentName}-qlPrevCare`}
                className="me-4 mb-2 bg-white shadow-sm border p-2 cursor-p w-20"
                onClick={() => setSelectedTab("PreventiveCare")}
              >
                {auth.hasAnyAuthority([
                  "AUTH_CARE_GAPS_READ_ACCESS",
                  "AUTH_CARE_GAPS_WRITE_ACCESS",
                ]) && (
                  <div id={`${componentName}-qlPCCare`} className="d-flex flex-row">
                    <div className="me-3">
                      <button type="button" className="btn btn-link text-dark position-relative p-0">
                        <i id={`${componentName}-qlPCCareIcon`} className="fa fa-plus-square fa-2x mb-2"></i>
                        <span
                          id={`${componentName}-qlPCCareCount`}
                          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                        >
                          {getTabCount(patientOverviewPkg, "activeGaps")}
                        </span>
                      </button>
                    </div>
                    <div id={`${componentName}-qlPCCareText`}>
                      <div className="mb-0 card-title fw-bold">
                        <div id={`${componentName}-qlPCCareLabel`} className="d-block">Care Opportunities</div>
                      </div>
                      <small id={`${componentName}-qlPCCareDesc`}>Active</small>
                    </div>
                  </div>
                )}
                {auth.hasAnyAuthority([
                  "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS",
                  "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS",
                ]) && (
                  <div id={`${componentName}-qlPCSuspects`} className="d-flex flex-row">
                    <div className="me-3">
                      <button type="button" className="btn btn-link text-dark position-relative p-0">
                        <i id={`${componentName}-qlPCSuspectsIcon`} className="fa fa-plus-square fa-2x mb-2"></i>
                        <span
                          id={`${componentName}-qlPCSuspectsCount`}
                          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                        >
                          {getTabCount(patientOverviewPkg, "activeRafsCount")}
                        </span>
                      </button>
                    </div>
                    <div id={`${componentName}-qlPCSuspectsText`}>
                      <div className="mb-0 card-title fw-bold">
                        <div id={`${componentName}-qlPCSuspectsLabel`} className="d-block">Suspects</div>
                      </div>
                      <small id={`${componentName}-qlPCSuspectsDesc`}>Active</small>
                    </div>
                  </div>
                )}
              </div>
            )}
          {patProfHasAccessTo("CarePath", auth) && carePathName != null && (
            <div
              id={`${componentName}-qlCarePath`}
              className="me-4 mb-2 bg-white shadow-sm border p-2 cursor-p w-20"
              onClick={() => setSelectedTab("CarePath")}
            >
              <div className="d-flex flex-row">
                <div className="me-2">
                  <i id={`${componentName}-qlCarePathIcon`} className="fa fa-map-marker-alt fa-2x mb-2"></i>
                </div>
                <div id={`${componentName}-qlCarePathText`}>
                  <div className="mb-0 card-title fw-bold">
                    <div id={`${componentName}-qlCarePathLabel`} className="d-block">
                      {carePathName} - {stepName}
                    </div>
                  </div>
                  <small id={`${componentName}-qlCarePathStatus`} className="d-block">{carePathStatus}</small>
                  <small id={`${componentName}-qlCarePathDesc`} className="d-block">Current Open Step</small>
                </div>
              </div>
            </div>
          )}
          {patProfHasAccessTo("Appointments", auth) &&
            latestAppointmentDate != null &&
            latestAppointmentPracticeName != null && (
              <div id={`${componentName}-qlAppoint`}
                className="me-4 mb-2 bg-white shadow-sm border p-2 cursor-p w-20"
                onClick={() => setSelectedTab("Appointments")}
              >
                <div className="d-flex flex-row">
                  <div className="me-2">
                    <i id={`${componentName}-qlAppointIcon`} className="fa fa-calendar fa-2x mb-2"></i>
                  </div>
                  <div id={`${componentName}-qlAppointText`}>
                    <div className="mb-0 card-title fw-bold">
                      <div id={`${componentName}-qlAppointLabel`} className="d-block">
                        {formatDate(latestAppointmentDate)}
                      </div>
                      <div id={`${componentName}-qlAppointValue`} className="d-block">
                        {latestAppointmentPracticeName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {patProfHasAccessTo("Referrals", auth) && referralStatus != null && (
            <div id={`${componentName}-qlReferrals`}
              className="mb-2 bg-white shadow-sm border p-2 cursor-p w-20"
              onClick={() => setSelectedTab("Referrals")}
            >
              <div className="d-flex flex-row">
                <div className="me-2">
                  <i id={`${componentName}-qlReferralsIcon`} className="fas fa-people-arrows fa-2x mb-2"></i>
                </div>
                <div id={`${componentName}-qlReferralsText`}>
                  <div className="mb-0 card-title fw-bold">
                    <div id={`${componentName}-qlReferralsTo`} className="d-block">{referredTo}</div>
                    <div id={`${componentName}-qlReferralsBy`} className="d-block">{referredBy}</div>
                  </div>
                  <small id={`${componentName}-qlReferralsStatus`}>
                    {getDisplayValue(status_referrals, referralStatus)}
                  </small>
                  <small id={`${componentName}-qlReferralsDate`} className="d-block">
                    {!isEmpty(sentDate) && formatDate(sentDate)}
                  </small>
                  <small id={`${componentName}-qlReferralsDesc`} className="d-block">Latest Referral</small>
                </div>
              </div>
            </div>
          )}
        </div>
        <Row>
          <Col md={3}>
            <Card>
              <Card.Header id={`${componentName}-patientInfoHeader`}>
                <span id={`${componentName}-patientInfo`} className="fw-bold">Patient Information</span>
                {auth.hasAuthority("AUTH_PATIENT_WRITE_ACCESS") && (
                  <EQHLink onClick={() => setShowEditUserModal(true)}>
                    <i id={`${componentName}-patientInfoEdit`} className="fas fa-edit float-end text-secondary"></i>
                  </EQHLink>
                )}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <MiniCard
                      label=""
                      value={
                        getLineOfBusiness()
                      }
                    />
                    <MiniCard
                      label=""
                      value={getPrimaryLanguage()}
                    />
                    <MiniCard
                        label=""
                        value={getOtherLanguageContent()}
                    />
                    <MiniCard
                        label=""
                        value={getSecondaryAddressContent()}
                    />
                    <MiniCard
                        label=""
                        value={getPreferredPhoneContent()}
                    />
                    <MiniCard
                        label=""
                        value={getPreferredEmailContent()}
                    />
                    <MiniCard
                      label=""
                      value={getNurse()}
                    />
                    <MiniCard
                      label=""
                      value={getPCP()}
                    />
                    <MiniCard
                      label=""
                      value={getTreatingProvidersContent()}
                    />
                    <MiniCard
                      label=""
                      value={getCaregiverName()}
                    />
                    <MiniCard
                      label=""
                      value={getCaregiversContact()}
                    />
                    <MiniCard
                      label=""
                      value={getHealthPlanCareManager()}
                    />
                    <MiniCard
                      label=""
                      value={getCareCoordinator()}
                    />
                    <MiniCard
                        label=""
                        value={getMrnContent()}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Card>
              <Card.Header id={`${componentName}-recentActivityHeader`}>
                <span id={`${componentName}-recentActivity`} className="fw-bold">Recent Activity</span>
                {showRecentActivitySpinner && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Card.Header>
              <Card.Body>
                <Timeline
                  items={recentActivityPkg.state.data ?? []}
                  titleBuilder={(activity, idx) =>
                    activity && activity.dateTime ? (
                      <div>
                        <span id={`${componentName}-raDate-${idx}`} className="d-block fw-bold">
                          {formatLocalDate(activity.dateTime)}
                        </span>
                        <span id={`${componentName}-raTime-${idx}`} className="d-block">
                          {formatTimeOnly(activity.dateTime)}
                        </span>
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip>
                              {activity.author ? activity.author : "No Author"}
                            </Tooltip>
                          }
                        >
                          <span id={`${componentName}-raAuthor-${idx}`} className="badge bg-secondary text-truncate mw-100">
                            {activity.author ? activity.author : "No Author"}
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <>
                        <span id={`${componentName}-raNoMeta-${idx}`}>-</span>
                      </>
                    )
                  }
                  subTitleBuilder={(activity) => activity.title}
                  bodyBuilder={(activity) => (
                    <ActivityCard activity={activity} />
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <EditPatientModal
        patientId={patientId}
        patientOverviewPkg={patientOverviewPkg}
        showProp={showEditUserModal}
        handleOnClose={() => setShowEditUserModal(false)}
        refreshRecentActivityPkg={refreshRecentActivityPkg}
        setShowRecentActivitySpinner={setShowRecentActivitySpinner}
        refreshOverview={reloadPatientOverview}
      />

      <Modal show={showImportantNotes} onHide={handleCloseImportantNotes} size="lg" aria-labelledby="contained-modal-title-vcenter"
      centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Important Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Timeline
            items={notesPkg.state.data ?? []}
            titleBuilder={(notes, idx) =>
              notes && notes.createdOn ? (
                <div>
                  <span id={`${componentName}-inDate-${idx}`} className="d-block fw-bold">
                    {formatLocalDate(notes.createdOn)}
                  </span>
                  <span id={`${componentName}-inTime-${idx}`} className="d-block">
                    {formatTimeOnly(notes.createdOn)}
                  </span>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip>
                        {notes.author}
                      </Tooltip>
                    }
                  >
                    <Badge id={`${componentName}-inAuthor-${idx}`} variant="badge bg-secondary text-truncate mw-100">
                    {notes.author}
                    </Badge>
                  </OverlayTrigger>
                </div>
              ) : (
                <>
                  <span id={`${componentName}-inNoMeta-${idx}`}>-</span>
                </>
              )
            }
            
            bodyBuilder={(note) => (
              <NotesCard notes={note} />
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button id={`${componentName}-inCloseButton`} variant="primary" onClick={handleCloseImportantNotes}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export { Overview };
