import React, { useState } from "react";
import { Button } from "../../components/buttons";
import ErrorToast from "../common/ErrorToast";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import axios from "../../utils/apiClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getPatientProfileLink } from "../../utils/util";
import { useLocation } from "react-router";

export const RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
		  "returnTo_" + window.location.pathname
		);

const componentName = "AssignPatient";

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card";
const StyledTypeAhead = styled(AsyncTypeahead)``;


const StyledFooter = styled.div`
  display: block;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  position: absolute;
  bottom: 0;
`;
const StyledRow = styled(Row)`
  min-height: 330px;
  padding-bottom: 45px;
`;
const StyledBody = styled.div`
  display: flex;
  flex-grow: 1;
`;
const StyledNameRed = styled.a`
  color: #DD6161;
`;
const StyledPatient = styled.p`
  margin-bottom: 2px;
`;

const AssignPatient = ({ patientsSelected, onHideClicked }) => {
  const isEmpty = require("is-empty");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedPCP, setSelectedPCP] = useState({});
  const [patientsNotAllowed, setPatientsNotAllowed] = useState([]);
  const handleChange = (e) => {
    if (e && e[0]) {
       checkPatients(e[0], patientsSelected);
    }
  };
  
  const checkPatients = (coord, patients) => {	  
	  const payLoad = {
	    coordinatorId: coord.value,
	    patientIds: patients.map((e) => e.id),
	  };	  
	  const url = baseURL + "/check-assign-coordinator";
	    setIsLoading(true);
	    axios
	      .post(url, payLoad, {
	        headers: {
	          "Content-Type": "application/json",
	        },
	      })
	      .then((response) => {
	    	setIsLoading(false);
	    	if (response.data && !isEmpty(response.data)) {
	          const notValidPatients = response.data.map((i) => findById(i));	
	          setPatientsNotAllowed(notValidPatients);
	        }
	        setSelectedPCP(coord);
	      })
	      .catch((error) => {
	        setIsLoading(false);
	        setErrorMessage(error);
	      });
  };
  
  function findById(idToSearch) {
	  return patientsSelected.find(item => {
	    return item.id === idToSearch
	  })
  };

  const onCreate = () => {
    const payLoad = {
      coordinatorId: selectedPCP.value,
      patientIds: patientsSelected.map((e) => e.id),
      coordinatorIdsToRecalculate: null,
      listDefinition: null,
    };
    const url = baseURL + "/assign-coordinator";
    setIsLoading(true);
    axios
      .put(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        onHideClicked(null, true);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const clientId =
        patientsSelected && patientsSelected.length
          ? patientsSelected.find((e) => e.clientId).clientId
          : "";
      const url =
        baseURL +
        "/search-coordinators" +
        (query ? "?name=" + query : "") +
        (clientId ? "&clientId=" + clientId : "")+
		("&patientId="+ encodeURIComponent(patientsSelected.id));
      setIsLoadingComp(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp.data.map((i) => ({
              value: i.value,
              text: i.text,
            }));
          }
          setOptions(options);
          setIsLoadingComp(false);
        });
    }
  };
  return (
    <StyledRow>
    
	  <StyledBody>
	      <Col sm={6}>
	      	{patientsSelected.length > 1 ? (
	    		  <h6 className="fw-bold">{patientsSelected.length} Patients for assignment</h6>
	      	) : (
	              <h6 className="fw-bold">{patientsSelected.length} Patient for assignment</h6>
	      	)}
	   
	        {patientsSelected.map((patient) => {         
	        	return (
	            <StyledPatient key={patient.id}>
	               <a href={getPatientProfileLink(patient.id, location)}>
	                {patient.firstName}, {patient.lastName}{" "}
	              </a>{" "}
	              {patient.assignedCareProvider && (
	                <span>
	                  Currently assigned to {patient.assignedCareProvider.firstName}
	                  , {patient.assignedCareProvider.lastName}
	                </span>
	              )}
	            </StyledPatient>
	            );
	        })}
	      </Col>
	      <Col sm={6}>
	        <Form>
	          <Form.Group controlId="assignPatients">
	            <Form.Label className="fw-bold d-block">
	              Assign to
	            </Form.Label>
	            <StyledTypeAhead
	              inputProps={{
                  id: `${componentName}-AssignToValue`
                }}
                labelKey="text"
	              isLoading={isLoadingComp}
	              onSearch={asyncsearch}
	              options={options}
	              onChange={(e) => handleChange(e)}
	              placeholder="Search coordinators by name"
	            />
	          </Form.Group>
	          
	          {patientsNotAllowed && !isEmpty(patientsNotAllowed) 
	        	  && <NotAssignedPatients patientsNotAssigned={patientsNotAllowed} />
	          }
	            
	        </Form>
	      </Col>
	
	      {isLoading && (
	        <Spinner
	          as="span"
	          animation="border"
	          size="sm"
	          role="status"
	          aria-hidden="true"
	          style={{ marginLeft: "-30px" }}
	        />
	      )}
	      {errorMessage && <ErrorToast errorParam={errorMessage} />}
	  
	  </StyledBody>
	      
      <StyledFooter>
        <Button onClick={(e) => onHideClicked(e, false)}>Close</Button>{" "}
        <Button disabled={isEmpty(selectedPCP)} onClick={onCreate}>
          Assign
        </Button>
      </StyledFooter>
    </StyledRow>
  );
};

const NotAssignedPatients = ({ patientsNotAssigned }) => {
  const location = useLocation();

   return (
    <div>
    	{patientsNotAssigned.length > 1 ? (
  		  <h6 className="fw-bold">Patients cannot be assigned to this coordinator</h6>
    	) : (
          <h6 className="fw-bold">Patient cannot be assigned to this coordinator</h6>
    	)}
    	 
    	{patientsNotAssigned.map((patient) => {         
        	return (
            <StyledPatient key={patient.id}>
               <StyledNameRed href={getPatientProfileLink(patient.id, location)}>
                {patient.firstName}, {patient.lastName}{" "}
              </StyledNameRed>
              {" "}
              {patient.assignedCareProvider && (
                <span>
                  Currently assigned to {patient.assignedCareProvider.firstName}
                  , {patient.assignedCareProvider.lastName}
                </span>
              )}
            </StyledPatient>
            );
        })}
    </div>
  );

};
export default AssignPatient;
