import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../../utils/apiClient";
import { Button } from "../../components/buttons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../components/dropDownLists/sharedDDL";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Modals from "../../components/modals";
import MultipleUploads from "../../components/MultipleUploads";
import { formatDate, opportunityValidations } from "../../utils/util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import Tooltip from "react-bootstrap/Tooltip";
import {
  updatePreventiveFile,
  clearFileUploads,
} from "../../actions/fileUploadActions";
import { useSelector, useDispatch } from "react-redux";
import errorReducer from "../../reducers/errorReducer";
import ErrorToast from "../common/ErrorToast";
import { ErrorBoundary } from "react-error-boundary";
import FallBackDefault from "../../ErrorBoundaries/FallBackDefault";
import { hideAlert } from "../../actions/populationSearch/populationSearchActions";
import { FileUpload } from "../../components/FileUpload";

const componentName = "PreventiveCareAddModal";
const baseURLICD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/icd10";

const baseURLPC =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const StyledInput = styled.input`
  padding: 10px;
`;

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const suspectStatus = {
  closesCondition: false,
  name: "Pending Diagnostic Workup",
  source: "PENDING_DIAGNOSTIC_WORKUP",
  translationKey:
    "com.hbi.ce.backend.common.enums.conditionstatusenum.pending_diagnostic_workup",
};

const conditionStatus = {
  closesCondition: true,
  name: "Confirmed Diagnosis",
  source: "CONFIRMED_DIAGNOSIS",
  translationKey:
    "com.hbi.ce.backend.common.enums.conditionstatusenum.confirm_diagnosis",
};

const rejectStatus = {
  closesCondition: true,
  name: "Does NOT Have Diagnosis",
  source: "CONFIRMED_DOES_NOT_HAVE_DIAGNOSIS",
  translationKey:
      "com.hbi.ce.backend.common.enums.conditionstatusenum.confirmed_does_not_have_diagnosis",
};



const PreventiveCareAddModal = ({
  closeModal,
  type,
  patient,
  submitCreation,
}) => {
  const [errors, setErrors] = useState("");

  const [formBody, setFormBody] = useState({
    conditions: [{ id: [], code: [], description: [] }],
    status: null,
    previousYear: false,
    newDateOfService: null,
    note: "",
    rejectionReason: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [optionsCode, setOptionsCode] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);
  const [optionsDesc, setOptionsDesc] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);

  const[selectedReason, setSelectedReason] = useState(null);

  const[rejectedReasons, setRejectedReasons] = useState([]);

  const[recommendationInfo, setRecommendationInfo]= useState(null);


  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [icdEvent, setIcdEvent] = useState(null);

  const [codeOptionsSet, setCodeOptionsSet] = useState(null);
  const [descOptionsSet, setDescOptionsSet] = useState(null);

  const isEmpty = require("is-empty");

  const updateFiles = (arr) => {
    setUploadedFiles(arr);
  };

  const handleFormChange = (name, value) => {
    setFormBody({ ...formBody, [name]: value });
  };

  formBody.status = type === "Suspect" ? suspectStatus : type === "Reject" ? rejectStatus :conditionStatus;

  const handleIcdChangeImpl = (e) => {
    let newState = formBody;
    if (e.length) {
      newState.conditions[0].id = [];
      newState.conditions[0].code = [];
      newState.conditions[0].description = [];
      
      newState.conditions[0].id.push(e[0].id);
      newState.conditions[0].code.push(e[0].code);
      newState.conditions[0].description.push(e[0].description);
      setFormBody({ ...newState });
    } else {
      newState.conditions[0].id = [];
      newState.conditions[0].code = [];
      newState.conditions[0].description = [];
      setFormBody({ ...newState });
    }
  };

  const handleIcdChange = (e) => {
    let regex = /^F1[0-9]+.*$/;
    if (e.length != null && e.length > 0 && regex.test(e[0].code)) {
      setIcdEvent(e);
    } else {
      handleIcdChangeImpl(e);
    }
  };

  const asyncCodeSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD + `/find-by-code?code=${query}&offset=${1}&pageSize=${30}`,
        { headers: {} }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = await response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        await setOptionsCode(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const asyncDescSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD +
          `/find-by-description?description=${query}&offset=${1}&pageSize=${30}`,
        {
          headers: {},
        }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = await response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        await setOptionsDesc(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleModalClose = async () => {
    setFileUploadResetCounter(fileUploadResetCounter + 1);

    await closeModal();
  };

  const convertIcd10 = () => {
    const newArray = formBody.conditions;
    let result = null;
    newArray.forEach(function (arrayItem) {
      let newItem = arrayItem;
      newItem.id = newItem.id.toString();
      newItem.code = newItem.code.toString();
      newItem.description = newItem.description.toString();
      result = newItem;
    });
    return result;
  };

  const [temporarilyDisableSubmitButton, setTemporarilyDisableSubmitButton]= useState(false)
  const handleSubmit = () => {
    setErrors(null)
    let filteredIcds = convertIcd10();
    const updateConditionActionDto = {
      ids: null,
      newStatus: formBody.status,
      note: formBody.note.length ? formBody.note : null,
      uploadedFiles: uploadedFiles.length ? uploadedFiles : null,
      patientId: patient.id,
      validation: null,
      icd10DiseaseDto: filteredIcds,
      forPreviousYear: formBody.previousYear,
      isNewSuspectCondition: type === "Suspect" ? true : false,
      isRejectCondition: type === "Reject" ? true : false,
      newDateOfService: formBody.newDateOfService,
      rejectionReason: formBody.rejectionReason ? formBody.rejectionReason : null
    };
    setTemporarilyDisableSubmitButton(true)
    axios
      .post(baseURLPC + `/create`, updateConditionActionDto, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {

        setFormBody({
          ...formBody,
          conditions: [{ id: [], code: [], description: [] }],
        });
        
        await submitCreation(response.data.conditions[0]);
        handleModalClose();
        setTemporarilyDisableSubmitButton(false)
      })
      .catch((error) => {
        if (error) {
          if (error?.response?.data?.message) {
            setFormBody({
              ...formBody,
              conditions: [{ id: [], code: [], description: [] }],
            });
            setErrors(error?.response?.data?.message);
          } else if (error.message) {
            setFormBody({
              ...formBody,
              conditions: [{ id: [], code: [], description: [] }],
            });
            setErrors(error.message);
          } else {
            setErrors("Error During Submit");
          }
        }
        setTemporarilyDisableSubmitButton(false)
      });
  };

  const isFormValid = () => {
    const { conditions, newDateOfService, rejectionReason } = formBody;
    if(temporarilyDisableSubmitButton){
      return true
    }
    else if (
        (conditions[0].code.length &&
      conditions[0].description.length &&
      newDateOfService) && (type !== "Reject" || rejectionReason)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    retrieveRejectionReasons();
  }, []);


  const retrieveRejectionReasons = () => {
    axios
        .get(
            `${process.env.REACT_APP_REST_API_BASE_URL}/v1/icd10/get-rejection-reasons`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        )
        .then(async (res) => {
          if (res && res.data) {
            await setRejectedReasons(res.data);
          }
        })
        .catch((error) => {
    });
  };

  const resetCurrentErrors = () => {
    setErrors("");
  };

  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const onModalClose = async () => {
    let isDateChanged = formBody.newDateOfService !== null;
    let isNoteChanged = formBody.note !== "";
    let isCodeChanged = formBody.conditions[0].code.length !== 0;
    let isDescriptionChanged = formBody.conditions[0].description.length !== 0;
    let isIdChanged = formBody.conditions[0].id.length !== 0;
    if (
      isDateChanged ||
      isNoteChanged ||
      formBody.previousYear ||
      isCodeChanged ||
      isDescriptionChanged ||
      isIdChanged
    ) {
      setConfirmationMessage(true);
    } else {
      handleModalClose();
    }
  };

  const handleRejectReasonChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedReason(selectedValue);
    let selectedReasonData = [];
    selectedReasonData = rejectedReasons.find((reason) => reason.id.toString() === selectedValue);
    setRecommendationInfo(selectedReasonData ? selectedReasonData?.recommendation : '');
      setFormBody({
        ...formBody,
        rejectionReason: selectedReasonData ? selectedReasonData?.rejection_reason : null,
        note: selectedReasonData ? selectedReasonData?.recommendation : " ",
      });

  }

  // VEGA-2728
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  // Aadded this to ManageEvidence.js & SubmitReferral.js
  const filterBy = () => true;

  return (
    <ErrorBoundary FallbackComponent={FallBackDefault}>
      <>
        <Modals
          centered={true}
          show={confirmationMessage}
          scrollable={true}
          closeConfirmation={() => setConfirmationMessage(false)}
          onCreate={() => handleModalClose()}
          onHide={() => setConfirmationMessage(false)}
          size="sm"
          title=""
          closeBtn="NO"
          completeBtn="YES"
          hideFooter={false}
          body="Do you want to close without saving?"
        />
        <Modals
          show={!isEmpty(icdEvent)}
          onHide={() => setIcdEvent(null)}
          title={<span>Message</span>}
          size="lg"
          hideClickButton={false}
          hideFooter={false}
          body={
            <span>
              By clicking <b>Confirm</b>, you attest that you have received the
              member's consent to submit information related to{" "}
              <b>substance abuse</b> treatment.
            </span>
          }
          onCreate={() => {
            handleIcdChangeImpl(icdEvent);
            setIcdEvent(null);
          }}
          closeBtn={"Cancel"}
          completeBtn={"Confirm"}
        />

        <Row className="mb-3">
          <Col sm={3}>
            <Form.Group id={`${componentName}-ICD10Code`}>
              <label id={`${componentName}-ICD10CodeLabel`} className="d-block">ICD-10 Code<span className="text-danger">*</span></label>            
              <StyledTypeAhead
                filterBy={filterBy}
                inputProps={{
                  id: `${componentName}-ICD10CodeValue`,
                }}
                labelKey="code"
                isLoading={isLoading}
                minLength={2}
                onSearch={asyncCodeSearch}
                options={optionsCode}
                selected={formBody && formBody.conditions.length &&  formBody.conditions[0].code ? formBody.conditions[0].code: []}
                onChange={(e) => handleIcdChange(e)}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group id={`${componentName}-ICD10Desc`}>
              <label id={`${componentName}-ICD10DescLabel`} className="d-block">ICD-10 Description<span className="text-danger">*</span></label>
              <StyledTypeAhead
                filterBy={filterBy}
                inputProps={{
                  id: `${componentName}-ICD10DescValue`,
                }}
                labelKey="description"
                isLoading={isLoading}
                minLength={2}
                onSearch={asyncDescSearch}
                options={optionsDesc}
                selected={formBody && formBody?.conditions.length &&  formBody.conditions[0].description ? formBody.conditions[0].description:[]}
                onChange={(e) => handleIcdChange(e)}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            {errors ? (
              <ErrorToast
                className="float-end"
                multipleErrors={[]}
                errorParam={errors}
                closeToast={resetCurrentErrors}
                resetCurrentErrors={resetCurrentErrors}
              />
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={9}>
            <Form.Group id={`${componentName}-status`} className="mb-3">
              <label id={`${componentName}-statusLabel`} className="d-block">Status<span className="text-danger">*</span></label>
              <Dropdown id={`${componentName}-statusDropdown`} value={""}>
                <Dropdown.Toggle
                  as={DropDownButton}
                  id={`${componentName}-statusValue`}
                  cssOpen={isOpen}
                  disabled={true}
                  className="w-100 mb-0"
                >
                  <span>
                    {type === "Suspect" ? "Open: " : "Closed: "}
                    {formBody.status ? formBody.status.name : null}
                  </span>
                  <i className="fa fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  id={`${componentName}-statusValueItems`}
                  as={CustomMenuStyled}
                  cusHeight={""}
                ></Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            {type === "Reject" &&
              <Form.Group id={`${componentName}-rejectReason`}>
                <label id={`${componentName}-rejectReasonLabel`} className="d-block">Rejection Reason<span className="text-danger">*</span></label>
                <select id={`${componentName}-rejectReasonsOptions`} className="form-select"
                        onChange={handleRejectReasonChange} value={selectedReason || ""}>
                  <option id="rejectOption-select" value = "">Select a Reason</option>
                  {rejectedReasons.map((reason, i) => (
                        <option id={`rejectOption${i}`} key ={reason.id} value={reason.id}>{reason.rejection_reason}</option>
                    ))}
                </select>
              </Form.Group>
            }
          </Col>
          <Col sm={3} className="mb-3">
            <Form.Group id={`${componentName}-prevMeasure`}>
              <div className="d-block">
                <label id={`${componentName}-prevMeasureText`} className="d-block">Measure Year</label>
                <StyledInput
                  type="checkbox"
                  id={`${componentName}-prevMeasureCheck`}
                  checked={formBody.previousYear}
                  onClick={() =>
                    setFormBody({
                      ...formBody,
                      previousYear: !formBody.previousYear,
                    })
                  }
                />{" "}
                <span id={`${componentName}-prevMeasureLabel`} className="ms-1">
                  Previous Measure Year
                </span>
              </div>
              <div
                id={`${componentName}-prevMeasureCurrentYear`}
                className="d-block"
              >
                (default is current year)
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3}>
            <Form.Group id={`${componentName}-DateOfService`}>
              <label id={`${componentName}-DateOfServiceLabel`} className="d-block">Date of Service<span className="text-danger">*</span></label>
              <DatePickerStyled>
                <DatePicker
                  name="dateOfService"
                  id={`${componentName}-DateOfServiceValue`}
                  value={formBody.newDateOfService}
                  selected={formBody.newDateOfService}
                  onChange={(e) => handleFormChange("newDateOfService", e)}
                  className="form-control"
                  popperPlacement="auto-left"
                  autoComplete="off"
                  maxDate={new Date()}
                />
              </DatePickerStyled>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group id={`${componentName}-AddANote`}>
              {type === "Reject" ?  <label id={`${componentName}-AddANoteLabel`} className="d-block">Recommendations</label>
              : <label id={`${componentName}-AddANoteLabel`} className="d-block">Add A Note</label>}
              <textarea
                className="form-control"
                name="message"
                rows="5"
                id={`${componentName}-AddANoteValue`}
                placeholder="Message"
                value={recommendationInfo != null ? recommendationInfo : formBody.note}
                onChange={(e) => handleFormChange("note", e.target.value)}
              ></textarea>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FileUpload
                onChange={updateFiles}
                clearStateProp={fileUploadResetCounter}
              />
          </Col>
        </Row>
        <StyledFooter>
          <Button
            id={`${componentName}-closeButton`}
            onClick={() => onModalClose()}
          >
            Close
          </Button>{" "}
          <Button
            id={`${componentName}-submitButton`}
            disabled={isFormValid()}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </StyledFooter>
      </>
    </ErrorBoundary>
  );
};

export default PreventiveCareAddModal;
