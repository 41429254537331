import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../../components/buttons";

import "../../../components/modal.css";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import styled, { css } from "styled-components";
import { useAuth } from "../../../context/authContext";
import {
  addUser,
  getUserMessages,
  createMessage,
  createConversation,
  getMessageCount, getMessageTypes,
} from "../../../actions/admin/header/messageActions";
import {AsyncTypeahead, Typeahead} from "react-bootstrap-typeahead";
import MultipleUploads from "../../../components/MultipleUploads";
import Spinner from "react-bootstrap/Spinner";
import {getAllUsers, getAllUsersByCLients} from "../../../actions/admin/userManagementActions";
import DownloadComp from "./DownloadComp";
import { getFileDownloadGeneric, uploadFile } from "../../../actions/fileUploadActions";
import { useHistory } from "react-router-dom";
import {
  updateFile,
  clearFileUploads,
} from "../../../actions/fileUploadActions";
import ErrorToast from "../../common/ErrorToast";
import {formatDate, formatDateTime, reactQuickLists} from "../../../utils/util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Col, Form} from "react-bootstrap";
import axios from "../../../utils/apiClient";
import { FileUpload } from "../../../components/FileUpload";

const componentName = "EmailComponent";
const URL = "/transitions_of_care?source=message";
const StyledTypeAhead = styled(AsyncTypeahead)``;
const StyledModal = styled(Modal)`
  ${(props) =>
    props.screenbool === true &&
    css`
      position: absolute;
      width: 300px !important;
      height: 400px !important;
      top: calc(100% - (400px)) !important;
      left: calc(100% - (300px)) !important;

      @media (min-width: 688px) {
        width: 400px !important;
        height: 640px !important;
        top: calc(100% - (650px)) !important;
        left: calc(100% - (400px)) !important;
      }

      @media (min-width: 992px) {
        width: 566px !important;
        height: 700px !important;
        top: calc(100% - (700px)) !important;
        left: calc(100% - (566px)) !important;
      }

      @media (min-width: 1300px) {
        width: 766px !important;
        height: 700px !important;
        top: calc(100% - (700px)) !important;
        left: calc(100% - (766px)) !important;
      }
    `}
  .close {
    margin-left: 0;
  }
`;
const StyledSentence = styled.p`
  margin: 0px;
  word-wrap : break-word;
`;
const EmailDisplay = ({
  firstName,
  urgent,
  viewed,
  file,
  lastName,
  orgName,
  dateObj,
  title,
  message,
  messageType,
  conversationId,
  linkText,
  linkURI,
  exportFiles,
  idx
}) => {
  const [downloadShow, setDownloadShow] = useState(false);

  const index = linkURI.indexOf("/");
  const quicklistType = linkURI.substring(0, index);
  const quicklistId = linkURI.substring(index + 1);

  const linkElement = () => {
    if (!linkText) {
      return;
    }

    if(index === -1){
      return <a href={`/${quicklistId}`}>
        {linkText ? linkText : ""}
      </a>;
    }else{
      if(reactQuickLists.includes(quicklistType)){
        return <a href={`/${quicklistType}?id=${decodeURIComponent(quicklistId)}&customListName=${linkText}`}>
          {linkText ? linkText : ""}
        </a>;
      }else{
        return <a href={`/#!${quicklistType}/${encodeURIComponent(quicklistId)}`}>
          {linkText ? linkText : ""}
        </a>
      }
    }
  }

  const onClockOfComp = () =>{
        setDownloadShow(true)
   }
  return (
    <div id={`${componentName}-messageCenter-${idx}`} className="message-center">
      <div onClick={() => onClockOfComp()} className="cursor-p">
        <div className="float-start">
          {!viewed && <i id={`${componentName}-viewedIcon-${idx}`} className="fa fa-circle text-primary fa-xs me-2" />}      
          {urgent ? (
            <i id={`${componentName}-urgentIcon-${idx}`} className="fa fa-exclamation-circle text-danger fa-xs me-2" />
          ) : null}
          {file ? (
            <i id={`${componentName}-fileIcon-${idx}`} className="fas fa-paperclip fa-xs me-2" />
          ) : null}
        </div>
        <div id={`${componentName}-mailContent-${idx}`} className="mail-contnet">
          <div>
            <h6 id={`${componentName}-messageTitle-${idx}`} className="fw-bold d-inline-block">{title}</h6>
            <div id={`${componentName}-messageDate-${idx}`} className="time float-end text-end">
              {formatDateTime(dateObj)}
            </div>
          </div>
          <div id={`${componentName}-mailDesc-${idx}`} className="mail-desc d-block w-100">
            <b id={`${componentName}-mailName-${idx}`}>{firstName + " " + lastName}</b><span id={`${componentName}-mailOrgName-${idx}`}> - {orgName}</span>
            <p id={`${componentName}-mailMessageSummary-${idx}`}>{message.split('\n').map((item, i) => <StyledSentence key={i}>{item}</StyledSentence>)}</p>
            <small>
              {linkElement()}
              {exportFiles &&
              exportFiles.length > 0 &&
              exportFiles.map((file) => (
                <p id={`${componentName}-mailFile-${idx}`} key={file.fileName}>
                  {file && (
                      <Button variant="link" onClick={() => getFileDownloadGeneric(process.env.REACT_APP_REST_API_BASE_URL + `/v1/files/get?fileUri=${encodeURIComponent(file.fileUri)}&fileName=${file.fileName}`, file.fileName)} id={`${componentName}-mailFileLink-${idx}`}>{file.fileName ? file.fileName : ""}</Button>
                  )}
                  {"  "}
                </p>
              ))}
            </small>
          </div>
        </div>
      </div>
      <hr />
      <DownloadComp
          showModal={downloadShow}
          setModal={setDownloadShow}
          conId={conversationId}
          messageType={messageType}
          title={title}
      />
    </div>
  );
};

const ComposeEmail = ({ onModalClose, selectedPatient, patientSpecific }) => {
  let auth = useAuth();
  const token = auth.getAuthToken();
  const [emailData, setEmailData] = useState({});
  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const handleChangeWithinModal = (e, error) => {
    const { name, value } = e.target;
    setEmailData({
      ...emailData,
      [name]: value,
    });
  };

  const { allUsers } = useSelector((state) => state.userManagement);
  const { fileObject } = useSelector((state) => state.fileUpload);
  const { conversationId, messageTypes } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [fileAdded, setFileAdded] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [urgent, setUrgent] = useState(false);
  const [validate, setValidate] = useState(true);
  const [msgType, setMsgType] = useState({});
  const errors = useSelector((state) => state.errors);
  const [optionsPatient, setOptionsPatients] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [patient, setPatient] = useState(null);

  const isEmpty = require("is-empty");

  const handleChangePatient = (e) => {
    if (e && e[0]) {
      findPatient(e[0].value);
    }
  };
  const findPatient = (id) => {
    if (id) {
      const input = {
        filterParams: [
          {
            paramName: "Patient Id",
            paramValue: [id],
            comparison: "eq",
            displayValue: [null],
          },
        ],
        pageNumber: 1,
        pageSize: 15,
        quickListName: "APPOINTMENT_PATIENT",
        sortParams: [],
      };
      const SEARCH_TEXT_URL =
          process.env.REACT_APP_REST_API_BASE_URL + "/v1/patients/myPatients";
      axios
          .post(SEARCH_TEXT_URL, input, {
            headers: { "Content-Type": "application/json" },
          })
          .then(async (resp) => {
            if (
                resp &&
                resp.data &&
                resp.data.patientCardDtos &&
                resp.data.patientCardDtos.length
            ) {
              setPatient(resp.data.patientCardDtos[0]);
            }
          })
          .catch((error) => {
          });
    }
  };

  const asyncsearchPatient = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearchPatient(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSearchPatient = (query) => {
    if (query) {
      const SEARCH_TEXT_URL =
          process.env.REACT_APP_REST_API_BASE_URL +
          "/v1/global-search/findPatientInfo?searchString=" +
          query;
      setIsLoadingPatient(true);
      axios
          .get(SEARCH_TEXT_URL, {
            headers: {},
          })
          .then((resp) => {
            let options = [];
            if (resp && resp.data && resp.data.patients) {
              options = resp?.data?.patients?.map((i) => ({
                value: i?.id,
                text: i?.fullName + " " + i?.memberId + " DOB: " + formatDate(i?.dateOfBirth),
              }));
            }
            setOptionsPatients(options);
            setIsLoadingPatient(false);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.message);
            setCurrentError(true);
            setIsLoadingPatient(false);
          });
    }
  };

  const setFileMethod = async (filesList) => {
    let file;
    let fileName;
    if (filesList && filesList.name) {
      fileName = filesList.name;
    }
    const fileReader = new FileReader();
    if (fileReader && filesList) {
      fileReader.readAsArrayBuffer(filesList);
      fileReader.onload = function () {
        if (fileReader && fileReader.result && !isEmpty(fileReader.result)) {
          dispatch(updateFile(fileReader.result, fileName));
        }
      };
    }
  };
  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };

  const createConvAndSend = () => {
    let userId = auth.getUserId();
    const converseMessage = {
      conversationDto: {
        title: emailData.title,
        participants: [...users, userId],
        messageType: msgType,
      },
      messageDto: {
        title: emailData.title,
        message: emailData.message,
        messageType: msgType,
        important: urgent,
        patientId: patient ? patient.id : null,
      },
    };
    if (uploadedFiles && uploadedFiles.length) {
      converseMessage.messageDto["uploadedFiles"] = uploadedFiles;
    }
    dispatch(createConversation(converseMessage));
  };

  const updateUsers = async (e, error) => {
    let idArray = [];
    e.forEach((user) => {
      idArray.push(user.id);
      setUsers(idArray);
    });
    if(e.length === 0) {
      setUsers([]);
    }
  };

  const handleChange = (e) => {
    if(e.length === 0) {
      setMsgType([]);
    }else {
      setMsgType(e[0]);
    }
  };

  useEffect(() => {
    (async function fetchData() {
      if (!messageTypes.length) {
        await dispatch(getMessageTypes(token));
      }
    })();
  }, []);

  const isFormValid = () => {
    const { title, message } = emailData;
    if (
      title &&
      msgType &&
      message &&
      users &&
      title.length &&
      msgType.length &&
      message.length &&
      users.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateFiles = (arr) => {
    setUploadedFiles(arr);
  };

  const closeModal = async () => {
    await dispatch(clearFileUploads());

    await onModalClose();
  };

  useEffect(() => {
    if (patientSpecific) {
      findPatient(selectedPatient);
    }
    (async function fetchMessages() {
      await dispatch(clearFileUploads());
      if (!allUsers.length) {
        await dispatch(getAllUsersByCLients());
      }
    })();
  }, []);

  useEffect(() => {
    if (fileObject) {
      setFileAdded(fileObject);
    }
  }, [fileObject]);
  return (
    <>
      {allUsers && allUsers.length && allUsers[0] !== "Empty" ? (
        <div>
          <div className="modal-body p-0 overflow-hidden mb-5">
            <form>
              <Row>
                <Col>
                  <div id={`${componentName}-chooseUsers`} className="form-group mb-3">
                    <label id={`${componentName}-chooseUsersLabel`} className="d-block">Choose Users<span className="text-danger">*</span></label>
                    <Typeahead
                        id={`${componentName}-chooseUsersValue`}
                        inputProps={{
                          id: `${componentName}-chooseUsersValue`
                        }}
                        multiple
                        labelKey="email"
                        onChange={(e) => updateUsers(e)}
                        options={allUsers}
                    />
                  </div>
                  <div id={`${componentName}-chooseMessageType`} className="form-group mb-3">
                    <label id={`${componentName}-chooseMessageTypeLabel`} className="d-block">Choose Message Type<span className="text-danger">*</span></label>
                    <Typeahead
                        id={`${componentName}-chooseMessageTypeValue`}
                        inputProps={{
                          id: `${componentName}-chooseMessageTypeValue`
                        }}
                        labelKey="msgType"
                        onChange={(e) => handleChange(e)}
                        options={messageTypes}
                    />
                  </div>
                  <div id={`${componentName}-subject`} className="form-group mb-3">
                    <label id={`${componentName}-subjectLabel`} className="d-block">Subject<span className="text-danger">*</span></label>
                    <input
                        type="text"
                        id={`${componentName}-subjectValue`}
                        onChange={(e) => handleChangeWithinModal(e)}
                        className="form-control"
                        name="title"
                    />
                  </div>
                  {patient && (
                      <>
                        <div>
                          <span id={`${componentName}-patient`}>
                            <span id={`${componentName}-patientLabel`} className="fw-bold">Patient:</span>{" "}
                            <span id={`${componentName}-patientValue`}>{patient?.fullName}</span>
                          </span>
                        </div>
                        <div>
                          <span id={`${componentName}-memberId`}>{patient?.memberId}</span>{" "}
                          <span id={`${componentName}-memberStatus`}>
                            ({patient?.active ? (
                            <span id={`${componentName}-memberStatusActive`} className="text-success">Active</span>
                            ) : (
                             <span id={`${componentName}-memberStatusInactive`} className="text-danger">Inactive</span>
                            )})
                          </span>
                          <span id={`${componentName}-DOB`} className={"ms-2"}>
                            <span id={`${componentName}-DOBLabel`} className="fw-bold">DOB: </span>{" "}
                            <span id={`${componentName}-DOBValue`}>{patient && formatDate(patient?.dateOfBirth)} ({patient?.age})</span>
                          </span>
                        </div>
                      </>
                  )}
                  {!selectedPatient && !patientSpecific ? (
                      <div id={`${componentName}-MessagePatient`} sm={4} className="py-3">
                        <Form.Label id={`${componentName}-MessagePatientLabel`} className="fw-bold">
                          Patient
                        </Form.Label>
                        <StyledTypeAhead
                            inputProps={{
                              id: `${componentName}-MessagePatientValue`
                            }}
                            labelKey="text"
                            isLoading={isLoadingPatient}
                            onSearch={asyncsearchPatient}
                            options={optionsPatient}
                            onChange={(e) => handleChangePatient(e)}
                            placeholder="Search Patients by Name"
                        />
                      </div>
                  ) : null}
                  <div className="form-group my-3">
                    <FileUpload onChange={handleUploadFileChange} clearStateProp={0} />
                  </div>
                  <div id={`${componentName}-message`} className="form-group mb-3">
                    <label id={`${componentName}-messageLabel`} className="d-block">Message<span className="text-danger">*</span></label>
                    <textarea
                        className="form-control"
                        id={`${componentName}-messageValue`}
                        name="message"
                        onChange={(e) => handleChangeWithinModal(e)}
                        rows="5"
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
          <div className="modal-footer p-0">
            <div className="me-auto">
              <label id={`${componentName}-messageUrgent`} className="custom-control custom-checkbox">
                <input
                  value={urgent}
                  id={`${componentName}-messageUrgentValue`}
                  type="checkbox"
                  name="markUrgent"
                  onClick={() => setUrgent(!urgent)}
                  className="custom-control-input me-2"
                />
                <span id={`${componentName}-messageUrgentLabel`} className="custom-control-label">Mark As Urgent</span>
              </label>
            </div>
            <Button
              className="btn"
              id={`${componentName}-cancelButton`}
              data-dismiss="modal"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              id={`${componentName}-sendButton`}
              onClick={() => createConvAndSend()}
              disabled={isFormValid()}
            >
              Send
            </Button>
          </div>
        </div>
      ) : allUsers[0] === "Empty" ? (
        <p id={`${componentName}-noUsersFound`}>No users found</p>
      ) : (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...
        </>
      )}
    </>
  );
};
const EmailList = ({patientSpecific}) => {
  const { messages } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchMessages() {
      if (!messages.length && !patientSpecific) {
        await dispatch(getUserMessages());
      }
    })();
  }, []);

  return (
    <div>
      {messages && messages.length && messages[0] !== "Empty" ? (
        messages.map((message, idx) => {
          return (
            <EmailDisplay
              idx={idx}
              firstName={
                message && message.sender ? message.sender.firstName : ""
              }
              lastName={
                message && message.sender ? message.sender.lastName : ""
              }
              viewed={message && message.viewed ? message.viewed : false}
            
              urgent={message && message.important ? message.important : false}	
            
              file={message && message.uploadedFiles.length ? true : false}
              orgName={
                message && message.sender ? message.sender.organizationName : ""
              }
              dateObj={
                message && message.sendDateTime ? message.sendDateTime : ""
              }
              title={message && message.title ? message.title : ""}
              message={message && message.message ? message.message : ""}
              messageType={message && message.messageType ? message.messageType : ""}
              conversationId={
                message && message.conversationId ? message.conversationId : ""
              }
              linkText={message && message.linkText ? message.linkText : ""}
              linkURI={message && message.linkURI ? message.linkURI : ""}
              exportFiles={message && message.exportFiles ? message.exportFiles : []}
            />
          );
        })
      ) : messages[0] === "Empty" ? (
        <p id={`${componentName}-noMessages`}>You don't have any messages</p>
      ) : (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...
        </>
      )}
    </div>
  );
};

const DynamicModal = ({
  onHide,
  changePage,
  title,
  show,
  body,
  size,
  navigate,
}) => {
  const [shrinkScreen, setShrinkScreen] = useState(false);

  const toggleScreen = () => {
    setShrinkScreen(!shrinkScreen);
  };

  return (
    <StyledModal
      onHide={onHide}
      show={show}
      size={size}
      screenbool={shrinkScreen}
      // centered
      scrollable
    >
      <Modal.Header>
          {shrinkScreen ? (
            <div className="ms-auto">
              <Button id={`${componentName}-composeButton`} variant="secondary" className="mb-0 me-2" onClick={changePage}>Compose</Button>
              <a id={`${componentName}-seeAllButton`} href="/message_inbox" className="btn btn-secondary me-3" onClick={() => navigate()}>See All</a>
              <Button id={`${componentName}-minButton`} variant="link" className="fa-regular fa-window-maximize fa-lg text-dark p-0 align-middle me-3 text-decoration-none" onClick={() => toggleScreen()}></Button>
              <Button id={`${componentName}-closeButton`} variant="link" className="fa-regular fa-xmark fa-lg text-secondary p-0 align-middle text-decoration-none" onClick={() => onHide()}></Button>{" "}
            </div>
          ) : (
            <div className="ms-auto">
              <Button id={`${componentName}-composeButton`} variant="secondary" className="mb-0 me-2" onClick={changePage}>Compose</Button>
              <a id={`${componentName}-seeAllButton`} href="#" className="btn btn-secondary me-3" onClick={() => navigate()}>See All</a>
              <Button id={`${componentName}-maxButton`} variant="link" className="fa-regular fa-window-restore fa-lg text-dark p-0 align-middle me-3 text-decoration-none" onClick={() => toggleScreen()}></Button>
              <Button id={`${componentName}-closeButton`} variant="link" className="fa-regular fa-xmark fa-lg text-secondary p-0 align-middle text-decoration-none" onClick={() => onHide()}></Button>{" "}
            </div>
          )}
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
    </StyledModal>
  );
};

const EmailComponent = ({
                          count,
                          patientSpecific,
                          selectedPatient
                        }) => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const { newConversation } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  //Hide Pop Up Once New Conver is successfull
  useEffect(() => {
    if (newConversation) {
      setModalShow(false);
    }
  }, [newConversation]);

  //Toggles Modal Display
  const handleModal = (e) => {
    setModalShow(true);
    setShowCompose(patientSpecific);
  };

  const changePage = () => {
    setShowCompose(true);
  };

  const navigateToInbox = () => {
    history.push("/message_inbox");
    setModalShow(false);
  };

  const onModalCloseHere = () => {
    setModalShow(false);
  };

  const body = showCompose ? (
    <ComposeEmail onModalClose={onModalCloseHere}
                  patientSpecific={patientSpecific}
                  selectedPatient = {selectedPatient} />
  ) : (
    <EmailList patientSpecific={patientSpecific} />
  );

  return (
    <>
      {patientSpecific ? (
          <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="auto"
              overlay={
                <Tooltip>
                  Send Secure Message
                </Tooltip>
              }
          >
            <Button
                onClick={(e) => handleModal(e)}
                id={`${componentName}-patientMessagesButton`}
                disabled={patientSpecific && !selectedPatient}
                className="text-dark border py-1 px-2 me-2"
                variant="secondary"
            >
              <i className="fas fa-envelope-open-text fa-lg" />
            </Button>
          </OverlayTrigger>
      ) : (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="auto"
          overlay={
            <Tooltip>
              {count} Messages
            </Tooltip>
          }
        >
          <Button
            onClick={(e) => handleModal(e)}
            id={`${componentName}-messagesButton`}
            className="text-dark mt-1 px-2 py-0"
            variant="link"
          >
            <i className="fa fa-envelope messageIcon" />{" "}
            {count > 0 ? (
            <span className="badge counter counter-lg bg-danger text-white rounded-circle messageBadge">&nbsp;</span>
                ) : null}
          </Button>
        </OverlayTrigger>
      )}
      <DynamicModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Messages Modal"
        size="xl"
        completeBtn={"Close"}
        body={body}
        changePage={changePage}
        navigate={navigateToInbox}
      />
    </>
  );
};
export default EmailComponent;
