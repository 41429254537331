import React from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';

import { AdtTypeStatusStyle, HospitalEventStatusStyle, hospitalEventsActions} from "../constants";
import DropDownMenu from "../../../caremgmt/worklist/components/DropDownMenu";

import { useHospitalEventsContext } from "../context/HospitalEvents.context";
import { useAuth } from "../../../../context/authContext";

const componentName = 'HospitalEventCardClosed';

/**
 * Represents a collapsed view of a hospital event card, showing summary information.
 *
 * @param {Object} hospitalEvent - Detailed hospital event data for display.
 * @param {number} idx - The index of the event, for key assignment.
 * @param {boolean} opened - State indicating if the card is expanded.
 * @param {Function} setOpen - Function to toggle the card's expanded state.
 */
const HospitalEventCardClosed = ({ hospitalEvent, idx, opened, setOpen }) => {
  const auth = useAuth();
  const hasWriteAccess = auth.hasAnyAuthority(["AUTH_PD_HOSPITAL_EVENTS_WRITE_ACCESS"]);

  const {
    toggleEditHospitalEventModalActive,
    setHospitalEventToEdit,
  } = useHospitalEventsContext();

  const {
    hospitalEventType,
    facility,
    admitDate,
    dischargeDate,
    adtType
  } = hospitalEvent;

  const getAdtTypeStyle = (adtType) => {
    return (
      <AdtTypeStatusStyle
        className="badge rounded-pill border-0"
        type={adtType}
      >
        {adtType}
      </AdtTypeStatusStyle>
    )
  }

  const onActionsClick = (action) => {
    switch (action?.value) {
      case "Edit":
        setHospitalEventToEdit(hospitalEvent);
        toggleEditHospitalEventModalActive(true);
        break;
    }
  }

  const getHospitalEventLabel = (adtType, admitDate, dischargeDate) => {
    if (adtType === "Admit") {
      return admitDate;
    }
    if (admitDate && dischargeDate) {
      return `${admitDate} - ${dischargeDate}`;
    }
    return admitDate || dischargeDate;
  }

  const getHospitalEventDateStyle = (adtType, admitDate, dischargeDate) => {
    return (
      <HospitalEventStatusStyle className="badge rounded-pill border-0">
        {getHospitalEventLabel(adtType, admitDate, dischargeDate)}
      </HospitalEventStatusStyle>
    );
  };

  const getHospitalEventsActions = (actions) => {
    return actions.map(action => {
      let modifiedAction = { ...action };

      switch (action.value) {
        case "Edit":
          if (typeof modifiedAction.disabled === 'function') {
            modifiedAction.disabled = modifiedAction.disabled(hasWriteAccess);
          }
          break;
      }

      return modifiedAction;
    });
  };

  return (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center card-header">
        <Button id={`${componentName}-ccTitle-${idx}`} onClick={() => setOpen()} variant="link" className="text-start text-dark p-0 text-decoration-none btn btn-link w-50">
          <div className="d-flex align-items-center">
            {!opened
              ? <i id={`${componentName}-ccPlus-${idx}`}
                className="fa-solid fa-chevron-right me-4" />
              : <i id={`${componentName}-ccMinus-${idx}`}
                className="fa-solid fa-chevron-down me-4" />
            }
            <div className="d-inline-block">
              <span id={`${componentName}-ccTitle-${idx}`} className="fs-5 fw-bold mb-1 d-block lh-1">{hospitalEventType}</span>
              <span id={`${componentName}-ccSubTitle-${idx}`} className="text-muted">{facility}</span>
            </div>
          </div>
        </Button>
        <div className="w-25">
          {getAdtTypeStyle(adtType)}
        </div>
        <div className="d-flex justify-content-end align-items-center w-25">
          <div className="text-end">
            {!opened && getHospitalEventDateStyle(adtType, admitDate, dischargeDate)}
          </div>
          <div className="text-end">
            <div id={`${componentName}-hospitalEventsActions`} className="btn-group">
              <DropDownMenu
                idx={idx}
                items={getHospitalEventsActions(hospitalEventsActions)}
                onItemClick={(item) => { onActionsClick(item); }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HospitalEventCardClosed;
