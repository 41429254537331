import React from 'react';
import { Badge } from "react-bootstrap";
import { formatDate, getRiskLevelStyle } from '../../../../../../../utils/util';

const componentName = "previsitChartHeader";
export default function Header({ extraData }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          <a href="#" id={`${componentName}-patientFullName`} className="fs-4 fw-bold text-dark me-2">{extraData?.fullNameFormatted}</a>
          <div className="me-3">
            <Badge pill bg="light" text="dark" id={`${componentName}-gender`} className="border">{extraData?.gender}</Badge>
          </div>
          <div id={`${componentName}-dob`} className="me-3">
            <label id={`${componentName}-dobLabel`}>DOB:</label> <span id={`${componentName}-dobValue`}>{formatDate(extraData?.dateOfBirth)}</span>
          </div>
          <div id={`${componentName}-phone`}>
            <label id={`${componentName}-phoneLabel`}>Phone:</label> <span id={`${componentName}-phoneValue`}>{extraData?.primaryPhone}</span>
          </div>
        </div>
        <div className="text-end">
          <Badge pill bg={getRiskLevelStyle(extraData?.riskLevel)} id={`${componentName}-riskPill`}>{extraData?.riskLevel}</Badge>
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <div id={`${componentName}-payor`} className="me-3">
          <label id={`${componentName}-payorLabel`}>Payor:</label> <span id={`${componentName}-payorValue`}>{extraData?.payer}</span>
        </div>
        <div id={`${componentName}-memberID`} className="me-3">
          <label id={`${componentName}-memberIDLabel`}>ID:</label> <span id={`${componentName}-memberIDValue`}>{extraData?.memberId}</span>
        </div>
      </div>
    </>
  )
}
