import { actions } from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SELECT_PATIENT_POST:
      return {
        ...state,
        patient_post: action.payload,
      };
      case actions.ERROR_FROM_SERVICES:
        return {
          ...state,
          error_from_services: action.payload
        };
    case actions.SELECT_PATIENT_GET:
      return {
        ...state,
        patient_get: action.payload
      };
    case actions.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actions.TOGGLE_SHOW_ADD_MODAL:
      return {
        ...state,
        showAddModal: action.payload
      };
    case actions.TOGGLE_LOADING_POST:
      return {
        ...state,
        loading_post: action.payload
      };
    case actions.SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      };
    case actions.PERSIST_PATIENT_OBJECT:
      return {
        ...state,
        patient_object: action.payload
      };
    default:
      return state;
  }
};