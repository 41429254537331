import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {Button, Col, Container, Row, Tooltip} from 'react-bootstrap';
import {isEmpty} from "lodash";
import {CollapsibleCard} from './CollapsibleCard';
import {labStatusMap, StatusLabel} from '../constants';
import {formatLocalDate} from '../../../../utils/util';
import {getFileDownload} from "../../../../actions/fileUploadActions";
import {OverlayTrigger} from 'react-bootstrap/esm';
import {
  adtTypeMap,
  AdtTypeStatusStyle,
  HospitalEventStatusStyle,
} from "../../HospitalEvents/constants";
import {formatDateToShortMDY} from "../../HospitalEvents/utils";

const componentName = 'EventTimelineCard'

const EventTimelineCard = ({eventTimeline, idx, toggleIndividual, open}) => {
  const dispatch = useDispatch();

  const isHospitalization = eventTimeline.type === 'Hospitalization';

  const getTitle = () => {
    return eventTimeline.typeDisplayValue;
  }

  const getSubTitle = () => {
    switch (eventTimeline.type) {
      case "Appointment":
        return "Appointment Date: " + formatLocalDate(
          eventTimeline.effectiveDate)
      case "Assessment":
        return "Assessment Date: " + formatLocalDate(
          eventTimeline.effectiveDate)
      case "Lab Data":
        return eventTimeline.result;
      case "Care Opportunity":
        return eventTimeline.abbreviation;
      default:
        return eventTimeline.generatedBy;
    }
  }

  const getStatus = () => {
    switch (eventTimeline.type) {
      case "Lab Data":
        return labStatusMap[eventTimeline.status] || eventTimeline.status;
      default:
        return eventTimeline.status;
    }
  };

  const handleClickOnLink = (event, fileId, fileName) => {
    event.preventDefault();
    dispatch(getFileDownload(fileId, fileName));
  };

  const tabElements = {
    Appointment: 'appointments',
    Assessment: 'assessments',
    ReferralNotes: 'referrals',
    CareProgramActivity: 'careprograms',
    CareProgram: 'careprograms',
    Hospitalization: 'hospitalEvents'
  }

  const disableRedirect = (event) => {
    var element = document.getElementById(
      'patientDetailsTabs-tab-' + tabElements[event.replace(/\s/g, "")]);
    return typeof (element) != 'undefined' && element != null
  }

  const handleRedirect = (event, id, referenceId) => {
    document.getElementById(
      'patientDetailsTabs-tab-' + tabElements[event.replace(/\s/g, "")]).click()
    sessionStorage.setItem('itemID', referenceId ? referenceId : id)
    sessionStorage.setItem('redirectTab',
      (tabElements[event.replace(/\s/g, "")]))
  }

  const getHospitalEventLabel = (adtType, admitDate, dischargeDate) => {
    if (adtType === "ADMIT") {
      return formatDateToShortMDY(admitDate);
    }
    if (admitDate && dischargeDate) {
      return `${formatDateToShortMDY(admitDate)} - ${formatDateToShortMDY(
        dischargeDate)}`;
    }
    return formatDateToShortMDY(admitDate) || formatDateToShortMDY(
      dischargeDate);
  }

  const ClosedContent = (
    <Container fluid>
      <Row className=" card-header">
        <Col md={6}>
          <Button
            id={`${componentName}-ccTitle-${idx}`}
            onClick={() => toggleIndividual()}
            variant="link"
            className="text-start text-dark p-0 text-decoration-none btn btn-link"
          >
            <div className="d-flex align-items-center">
              {!open
                ? <i id={`${componentName}-ccPlus-${idx}`}
                     className="fa-solid fa-chevron-right me-4"/>
                : <i id={`${componentName}-ccMinus-${idx}`}
                     className="fa-solid fa-chevron-down me-4"/>
              }
              <div className="d-inline-block">
                <div className='d-flex align-items-center'>
                <span id={`${componentName}-ccTitle-${idx}`}
                      className="fs-5 fw-bold d-block lh-1">{getTitle()}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Go to {eventTimeline.type}</Tooltip>}
                  >
                    <i
                      className={`fa-regular fa-arrow-up-right-from-square ms-4 fs-8`}
                      style={{
                        color: disableRedirect(eventTimeline.type) ? '#007db7'
                          : 'gray',
                        pointerEvents: disableRedirect(eventTimeline.type)
                          ? 'all'
                          : 'none',
                        cursor: disableRedirect(eventTimeline.type) ? 'pointer'
                          : 'auto',
                      }}
                      onClick={() => handleRedirect(eventTimeline.type,
                        eventTimeline.id, eventTimeline.referenceId)}
                    />
                  </OverlayTrigger>
                </div>
                <span id={`${componentName}-ccSubTitle-${idx}`}
                      className="text-muted">{getSubTitle()}</span>
              </div>
            </div>
          </Button>
        </Col>

        <Col md={6}>
          {isHospitalization ? <div
              className="d-flex justify-content-between align-items-center">
              <AdtTypeStatusStyle
                className="badge rounded-pill border-0"
                type={eventTimeline.status}
              >
                {adtTypeMap[eventTimeline.status] || eventTimeline.status}
              </AdtTypeStatusStyle>

              <HospitalEventStatusStyle className="badge rounded-pill border-0">
                {getHospitalEventLabel(eventTimeline?.status,
                  eventTimeline?.startDate, eventTimeline?.endDate)}
              </HospitalEventStatusStyle>
            </div> :
            <div className="d-flex justify-content-end align-items-center">
              <StatusLabel className="badge badge-pill border-0"
                           status={eventTimeline?.status}
                           type={eventTimeline?.type}>
                {getStatus()}
              </StatusLabel>
            </div>
          }
        </Col>


      </Row>
    </Container>
  );

  const OpenContent = (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          {eventTimeline?.details?.map(detail =>
            <Row>
              <Col id={`${componentName}-detailName-${idx}`}
                   className="form-label col-form-label" sm={3}>
                {detail.name}
              </Col>
              <Col className="d-flex align-items-center">
                {detail?.values?.map(
                  value => <span id={`${componentName}-detailValue-${idx}`}>
                  {value ?? ""}</span>)}
              </Col>
            </Row>
          )}

          {!isEmpty(eventTimeline?.files) &&
            <Row>
              <Col id={`${componentName}-infoDDLinkName-${idx}`}
                   className="form-label col-form-label" sm={3}>
                Files
              </Col>
              <Col className="d-flex align-items-start flex-column">
                {eventTimeline?.files?.map(file => <p style={{ marginBottom: 0 }}>
                  <a id={`${componentName}-infoDDLinkValue-${idx}`} href="#"
                     onClick={(event) => {
                       handleClickOnLink(event, file.id, file.name)
                     }}>
                    {file.name ?? ""}
                  </a></p>)}
              </Col>
            </Row>}
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <CollapsibleCard
        openContent={OpenContent}
        open={open}
        idx={idx}
        closedContent={ClosedContent}
      />
    </>
  );
};

export default EventTimelineCard