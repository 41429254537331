import React from "react";
import ManageEvidence from "../../quickList/ManageEvidence";
import Modals from "../../../components/modals";

const componentName = "ManageVisitModal";
const ManageVisitModal = ({
                              show,
                              close,
                              patient,
                              navigationItems,
                              activeKey,
                              setChangesMade,
                              init,
                              setConfirmationMessage,
                              confirmationMessage,
                              featureFlagCIS,
                          }) => {
    return (

        <Modals
            scrollable={true}
            show={show}
            closeEvidenceModal={() => close()}
            onHide={() => close()}
            size="xl"
            title="Manage Visit"
            titleid={`${componentName}-titleId`}
            completeBtn="Update"
            completeBtnId={`${componentName}-buttonId`}
            hideFooter={true}
            body={<ManageEvidence
                patient={patient}
                fromAppointmentScreen={true}
                closeEvidenceModal={() => close()}
                navigationParams={navigationItems}
                activeKey={activeKey}
                updateParent={init}
                updatesMade={setChangesMade}
                setConfirmationMessage={setConfirmationMessage}
                confirmationMessage={confirmationMessage}
                featureFlagCIS={featureFlagCIS}
            />}
        />)
}

export default ManageVisitModal
