import React, { useContext, createContext, useReducer } from 'react';
import { actions, initialState } from './actions';
import { reducer } from './LoginInfo.reducer';
import { useAuth } from '../../../context/authContext';

const LoginInfoContext = createContext();

const LoginInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = useAuth();

  const onLogin = async () => {
    const success = await auth.login(state.username, state.password);
    dispatch({ type: actions.SET_LOADING, payload: false });
    if (success !== true) {
      const errorDescription = success?.response?.data?.error_description;

      if (errorDescription === 'TERMS_AND_AGREEMENTS_ACCEPTANCE_REQUIRED') {
        dispatch({type: actions.SET_TERMS_REQUIRED, payload: true});
        dispatch({type: actions.SET_ERROR, payload: null});
      } else if (errorDescription === 'PASSWORD_EXPIRED') {
        dispatch({type: actions.SET_ERROR, payload: 'Your password has expired. Please reset your password now to continue accessing your account by clicking on Forgot Password option.'});
      } else {
        dispatch({ type: actions.SET_ERROR, payload: success });
      }
    }
  };

  const onRedirect = async () => {
    const success = await auth.loginForRedirect(state.username, state.password);
    dispatch({ type: actions.SET_LOADING, payload: false });
   return success
  };


  const onLoginAccepted = async () => {
    const success = await auth.login(state.username, state.password, true);
    if (success !== true) {
      dispatch({ type: actions.SET_ERROR, payload: success });
    }
  };

  const value = {
    username: state.username,
    password: state.password,
    termsRequired: state.termsRequired,
    latestLoginFailed: state.latestLoginFailed,
    error: state.error,
    loading: state.loading,
    setUsername: (username) => {
      dispatch({ type: actions.SET_USERNAME, payload: username });
    },
    setLoading: (loading) => {
      dispatch({ type: actions.SET_LOADING, payload: loading });
    },
    setPassword: (password) => {
      dispatch({ type: actions.SET_PASSWORD, payload: password });
    },
    setTermsRequired: (isRequired) => {
      dispatch({ type: actions.SET_TERMS_REQUIRED, payload: isRequired });
    },
    setLatestLoginFailed: (failed) => {
      dispatch({ type: actions.SET_LOGIN_FAILED, payload: failed });
    },
    setError: (error) => {
      dispatch({ type: actions.SET_ERROR, payload: error });
    },
    onLogin: () => onLogin(),
    onRedirect: () =>onRedirect(),
    onLoginAccepted: () => onLoginAccepted()
  };

  return (
    <LoginInfoContext.Provider value={value}>
      {children}
    </LoginInfoContext.Provider>
  )
};
const useLoginInfoContext = () => useContext(LoginInfoContext);

export {
  LoginInfoProvider,
  useLoginInfoContext,
}