import { Button } from "react-bootstrap";
import styled from "styled-components";

export const QuickListsContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
  .table td {
    vertical-align: middle;
  }
  .pagination {
    margin-bottom: 0;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 0;
    height: 27px;
  }
  .pagination .page-link {
    border: 0 !important;
    padding: 0 5px;
    height: 27px;
    font-size: 23px;
  }
  .page-item.disabled .page-link {
    color: #6c757d;
  }
  .page-item .page-link {
    background-color: transparent !important;
  }
  .pagination label {
    font-size: 12px;
    line-height: 25px;
    padding: 0;
  }
`;

export const StyledMenuBtns = styled(Button)`
  margin-right: 10px;
  background: white !important;
  color: #188dcd;

  :hover {
    color: #188dcd;
    border: 1px solid #188dcd !important;
  }
`;

export const StyledMenuBtnsOS = styled(StyledMenuBtns)`
  border: 1px solid #d2d2d2;
  border-radius: 0;
  padding: 0 10px;
  font-weight: 700;
  margin-right: 8px;
  font-size: 14px;
  line-height: 25px;
  color: #404040;
  display: inline-block;
  white-space: nowrap;

  :focus {
    border: 1px solid #d2d2d2;
    color: #404040;
  }
`;
