import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";

const [getAssessmentFormTemplates, useGetAssessmentFormTemplates] = dataFetchHelper(([client]) => {
  return axios.get(
    `${process.env.REACT_APP_REST_API_BASE_URL}/v1/assessments/form-template?client=${client}`,
    {
      headers: {},
    }
  );
});

const saveAssessmentTemplate = (carePathTemplate) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/assessments/form-template`,
    carePathTemplate,
    {
      headers: {},
    }
  );
};

const deleteAssessmentTemplate = (id) => {
  return axios.delete(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/assessments/form-template?id=${id}`,
    {
      headers: {},
    }
  );
};

export {
  getAssessmentFormTemplates,
  useGetAssessmentFormTemplates,
  saveAssessmentTemplate,
  deleteAssessmentTemplate
};
