import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { useDispatch } from 'react-redux';
import {
    createMessage,
    deleteMessage,
    getMessage,
    getPresetData,
    setMessage,
    resetMessage
} from '../../../actions/broadcastMessengerActions';
import { Row, Col, Card, Button } from 'react-bootstrap';

const componentName = "BroadcastMessage"
const BroadcastMessenger = () => {
    const auth = useAuth();
    const token = auth.getAuthToken();
    const dispatch = useDispatch();

    const [predefined, setPredefined] = useState([]);
    const [levels, setLevels] = useState([]);
    const [newMessage, setNewMessage] = useState({
        predefinedMsg: "",
        customMsg: "",
        level: "Low Priority",
        pst: ""
    });
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await getPresetData(token);
            await setPredefined(response.messages.data);
            await setLevels(response.levels.data);
        })();
    }, []);

    const handleChange = (e) => {
        setNewMessage({
            ...newMessage,
            [e.target.name]: e.target.value
        });
    }

    const handleCreation = async () => {
        setErrors([]);
        const message = newMessage.predefinedMsg.length > 0 ? newMessage.predefinedMsg : newMessage.customMsg;
        const priority = newMessage.level;
        const duration = newMessage.pst;

        if (newMessage.predefinedMsg.length > 0 && newMessage.customMsg.length > 0) {
            setNewMessage({
                ...newMessage,
                customMsg: ""
            });
        }

        if (message.length > 0 && priority.length > 0 && duration.length > 0) {
            const response = await createMessage(token, message, priority, duration);
            const result = await getMessage();
            await dispatch(setMessage(result));
        } else {
            let newArr = [];
            if (message.length === 0) {
                newArr.push("msg");
            }
            if (priority.length === 0) {
                newArr.push("lvl");
            }
            if (duration.length === 0) {
                newArr.push("pst");
            }
            setErrors(newArr);
        }

    }

    const handleDeletion = async () => {
        await deleteMessage(token);
        await dispatch(resetMessage());
    }

    const { predefinedMsg, customMsg, level, pst } = newMessage;

    return (
        <Row className="container-fluid p-3">
            <Col>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col className="mb-3">
                                <label className="d-block" id={`${componentName}-predefinedMessageLabel`}>Predefined Message</label>
                                <select
                                    className="form-select"
                                    name="predefinedMsg"
                                    id={`${componentName}-predefinedMessageValue`}
                                    value={predefinedMsg}
                                    onChange={(e) => handleChange(e)}>
                                    <option defaultValue=""></option>
                                    {predefined.map((msg, i) => <option value={msg} key={i}>{msg}</option>)}
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <label className="d-block" id={`${componentName}-customMessageLabel`}>Custom Message</label>
                                <textarea
                                    className="textarea_editor form-control"
                                    rows="3"
                                    placeholder="Enter text ..."
                                    value={customMsg}
                                    name="customMsg"
                                    id={`${componentName}-customMessageValue`}
                                    onChange={(e) => handleChange(e)}
                                    style={{ borderColor: errors.includes("msg") ? "red" : "inherent" }}
                                    disabled={predefinedMsg.length > 0 ? true : false}>
                                </textarea>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3} className="mb-3">
                                <label className="d-block" id={`${componentName}-severityLabel`}>Severity</label>
                                <select
                                    className="form-select"
                                    id={`${componentName}-severityValue`}
                                    value={level}
                                    name="level"
                                    onChange={(e) => handleChange(e)}
                                    style={{ borderColor: errors.includes("lvl") ? "red" : "inherent" }}>
                                    {levels.map(lvl => <option value={lvl.level} key={lvl.cssClass}>{lvl.level}</option>)}
                                </select>
                            </Col>
                            <Col sm={3} className="mb-3">
                                <label className="d-block" id={`${componentName}-timeframeLabel`}>Broadcast Timeframe (PST)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={pst}
                                    name="pst"
                                    id={`${componentName}-timeframeValue`}
                                    onChange={(e) => handleChange(e)}
                                    style={{ borderColor: errors.includes("pst") ? "red" : "inherent" }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button id={`${componentName}-hideMessageButton`} variant="danger" onClick={handleDeletion}>Hide Message</Button>
                            </Col>
                            <Col className="text-end">
                                <Button id={`${componentName}-submitButton`} variant="primary" className="me-0" onClick={handleCreation}>Submit</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default BroadcastMessenger;