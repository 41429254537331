import React, { useEffect, useState, useContext } from "react";
import { useGetTransQuickListSettings } from "./quickListSettings";
import { useAuth } from "../../context/authContext";
import { Button } from "../../components/buttons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { QuickListContext } from "../quickList/QuickListContext";
import CalendarView from "./CalendarView";
import Card from "react-bootstrap/Card";
import CalendarCardView from "./CalendarCardView";
import { QuickListsContainer, StyledMenuBtnsOS } from "./Theme";
import { theme } from "../../components/themes/theme1";
import { Col, Container, Row } from "react-bootstrap";
import { FilterGroup } from "../../components/filters/filterGroup";
import {
  deleteCustomWorklist,
  deleteUserWorklist,
  searchApppointment,
} from "../../actions/patientSearch/patientSearchActions";
import queryString from "query-string";
import cloneDeep from "lodash/cloneDeep";
import axios from "../../utils/apiClient";
import {
  PatientSearchDTO,
  emptyFilterParam,
  simpleFilterParam,
} from "../../dtos/PatientSearchDTO";
import Modals from "../../components/modals";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CalendarViewModal from "../appointment/CalenderViewModal";
import { formatDateVanilla, getQuickListId, getCustomListName, getCustomWorklistName } from "../../utils/util";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ExportSummary from "./ExportSummary";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { removeQuicklistById } from "../../actions/navigation/navigationActions";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ShareWorklist from "./ShareWorklist";
import { AppPropertiesUtil, LocalStorageUtil, SessionStorageUtil } from "../../utils/localStorageUtil";
import { setDate } from "date-fns";
import { CE_RESTORE_OBJ } from "../common/MainLayout";
import { Helmet } from 'react-helmet';
import isEmpty from "is-empty";
import { FilterGroupNew } from "../../components/filters/filterGroupNew";

let pathName = window.location.pathname;
let modifiedPathName = pathName.endsWith("/")
  ? pathName.substring(0, pathName.length - 1)
  : pathName;
let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + modifiedPathName + "?loadLastQuerySettings=true"
);

export const SUMMARY_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";

const StyledMenuBtns = styled(Button)`
  margin-right: 10px;
  background: white !important;
  color: #188dcd;

  :hover {
    color: #188dcd;
    border: 1px solid #188dcd !important;
  }
`;

const StyledListBtnsOS = styled(StyledMenuBtns)`
  border: 1px solid #d2d2d2;
  border-radius: 0;
  padding: 0 10px;
  font-weight: 700;
  margin-right: 8px;
  font-size: 14px;
  line-height: 25px;
  color: #404040;
  white-space: nowrap;
  display: ${(props) => (!props.show ? "none" : `inline-block`)};

  :focus {
    border: 1px solid #d2d2d2;
    color: #404040;
  }
`;

const componentName = "Appointments";

const Appointments = ({ }) => {
  const auth = useAuth();
  const getSettingsRef = useGetTransQuickListSettings("appointments", 0, auth);
  return (
    <QuickListContext.Provider value={getSettingsRef()}>
      <AppointmentsComponent {...getSettingsRef()} />
    </QuickListContext.Provider>
  );
};

export { Appointments };

const VIEW = { card: "CARD", calendar: "CALENDAR" };
export const CUSTOM_LIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-filtered-list/custom-lists";

const URL = process.env.REACT_APP_REST_API_BASE_URL;

const AppointmentsComponent = ({
  titleProp,
  filters,
  pageNumberProp,
  pageSizeProp,
  defaultFilterParamsProp,
  exportPatientsUrl,
  quickListType,
  displayExtraRows,
  startingView = VIEW.card,
  listViewColumns,
}) => {
  const [dateObject, setDateObject] = useState(new Date());
  const [title, setTitle] = useState(titleProp);
  const [patientsList, setPatientsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(pageNumberProp);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [totalSize, setTotalSize] = useState();
  const [defaultFilterParams, setDefaultFilterParams] = useState(
    defaultFilterParamsProp
  );
  const [filterButtons, setFilterButtons] = useState(false);
  const [customListDto, setCustomListDto] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [filterParams, setFilterParam] = useState(defaultFilterParamsProp);
  const [sortParams, setSortParams] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [errorText, setErrorText] = useState();
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [view, setView] = useState(VIEW.calendar);
  const [transition, setTransition] = useState(VIEW.calendar);
  const [showExportSpinner, setShowExportSpinner] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const LAST_QUERY_SETTINGS_KEY = `${quickListType}-QLQuerySettings`;
  const QUERY_PARAMS = new URLSearchParams(window.location.search);

  let quicklistId = getQuickListId();
  let customWorklistName = getCustomWorklistName();
  let customlistName = getCustomListName();

  useEffect(() => {
    let location = window.location.pathname;
    let workingPathName = location.endsWith("/")
      ? location.substring(0, location.length - 1)
      : location;
    if (quicklistId && !isNaN(quicklistId)) {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" +
        workingPathName +
        encodeURIComponent("?loadLastQuerySettings=true&id=" + quicklistId + "&customListName=" + customlistName)
      );
    } else {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" + workingPathName + "?loadLastQuerySettings=true"
      );
    }
  }, [quickListType, quicklistId]);

  useEffect(() => {
    let pValue = pValue = new Date();
    filterParams.forEach((e) => {
      if (e.paramName === "appointment date") {
        pValue = new Date(e.paramValue);
      }
    });
    pValue.setDate(pValue.getDate() + 1);
    setDateObject(pValue);
  }, [filterParams]);

  const query = async (patientSearchDTO, type, updatedTitle) => {
    setLoadingData(true);
    SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
      patientSearchDTO: patientSearchDTO,
      view: type,
      title: updatedTitle,
    });
    if (type === VIEW.calendar) {
      setView(VIEW.calendar);
      setLoadingData(false);

      searchApppointment(patientSearchDTO)
        .then((res) => {
          if (res.data) {
            setPatientsList(res.data);
            setLoadingData(false);
          }
        })
        .catch((err) => {
          setErrorText("Error");
          setLoadingData(false);
        });
    } else if (type === VIEW.card) {
      setView(VIEW.card);
      axios
        .post(`${URL}/v1/patients/myPatients`, patientSearchDTO, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data) {
            setPatientsList(res.data.patientCardDtos);
            setLoadingData(false);
          }
        })
        .catch((err) => {
          setErrorText("Error");
          setLoadingData(false);
        });
    }
  };

  const transitionView = async (type) => {
    let param = await cloneDeep(filterParams);
    await setLoadingData(true);
    fetchData(param, "APPOINTMENTS1", type, title);
  };

  const handleDateChange = (date, type) => {
    let param = cloneDeep(filterParams);
    param.forEach((e) => {
      if (e.paramName === "appointment date") {
        const dt = formatDateVanilla(date, "yyyy-MM-dd");
        e.paramValue = [];
        e.paramValue.push(dt);
      }
    });

    fetchData(param, "APPOINTMENTS2", type, title);
    //setDefaultFilterParams(param);
    setFilterParam(param);
  };
  const fetchData = async (filterParams, quicklistName, type, updatedTitle) => {
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        pageNumber,
        pageSize,
        quicklistName
      ),
      type,
      updatedTitle
    );
  };

  const setDefaultFilterParamHelper = (filterParams) => {
    setDefaultFilterParams(defaultFilterParamsProp);
    setFilterParam(filterParams);
  };

  useEffect(() => {
    //restore from last timeout override
    const restoreObject = SessionStorageUtil.getObj(CE_RESTORE_OBJ);
    let quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    if (restoreObject) {
      if (restoreObject.recentKey === LAST_QUERY_SETTINGS_KEY) {
        quicklistObj = restoreObject.quickListObj;
        SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, quicklistObj);
      }
      SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);
    }
    if ((restoreObject || QUERY_PARAMS.get("loadLastQuerySettings")) && quicklistObj && quicklistObj.patientSearchDTO) {
      //load from back button
      setTitle(quicklistObj.title);
      setFilterButtons(false);
      setDefaultFilterParamHelper(quicklistObj.patientSearchDTO.filterParams);
      //setSortParams(patientSearchDTO.sortParams);
      query(
        quicklistObj.patientSearchDTO,
        quicklistObj.view,
        quicklistObj.title
      );
    } else if (quicklistId && !isNaN(quicklistId)) {
      //load custom filters
      axios
        .get(CUSTOM_LIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setFilterButtons(true);
          setDateObject(new Date());
          setDefaultFilterParamHelper(
            response.data.patientRequestDTO.filterParams
          );

          let adFilterParam = simpleFilterParam(
            "appointment date",
            "eq",
            formatDateVanilla(new Date(), "yyyy-MM-dd")
          );
          response.data.patientRequestDTO.filterParams.push(adFilterParam);
          setTitle(response.data.name);

          fetchData(
            response.data.patientRequestDTO.filterParams,
            response.data.quickList,
            "CALENDAR",
            response.data.name
          );
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalSize("lg");
          if (error.response != null) {
            setModalMessage(error.response.data.message);
          } else {
            setModalMessage(error.message);
          }
        });
    } else {
      setTitle(titleProp);
      setDefaultFilterParamHelper(defaultFilterParamsProp);
      setFilterButtons(false);
      fetchData(defaultFilterParamsProp, quickListType, "CALENDAR", titleProp);
    }
  }, [quicklistId]);

  const handleFilterChange = (filterParamsProps) => {
    let param = cloneDeep(filterParamsProps);
    const quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    let pval = quicklistObj?.patientSearchDTO?.filterParams[0]?.paramValue
    let hasAppointmentDate = false
    if (param && param.length) {
      param.forEach(e => {
        if (e && e.paramName === 'appointment date' && pval) {
          e.paramValue = pval;
          hasAppointmentDate = true
        }
      })
    }
    if (!hasAppointmentDate && (filterParams && filterParams.length)) {
     let valFromPArams = filterParams.find(e => e.paramName === 'appointment date')
     param.push(valFromPArams)
     hasAppointmentDate = true
    }
    if (!hasAppointmentDate) {
      let prm = simpleFilterParam(
        "appointment date",
        "eq",
        formatDateVanilla(new Date(), "yyyy-MM-dd")
      );
      param.push(prm)
    }
    setFilterParam(param);
    setPageNumber(1);
    query(
      new PatientSearchDTO(
        param,
        sortParams,
        1,
        pageSize,
        quickListType
      ),
      view,
      title
    );
  };

  const updateAppointment = (updatedForm, type) => {
    let typeLocal = "";
    if (type !== "CARD_VIEW") {
      typeLocal = "CALENDAR";
    } else {
      typeLocal = "CARD";
    }
    if (typeLocal === "CALENDAR") {
      fetchData(defaultFilterParams, quickListType, typeLocal, title);
      let newState = patientsList;
      newState.map((patient) => {
        if (patient.appointment.id === updatedForm.id) {
          patient.appointment = updatedForm;
        }
      });
      setPatientsList([...newState]);
    } else {
      let newState = patientsList;
      let param = cloneDeep(filterParams);
      let dt = null;
      param.forEach((e) => {
        if (e.paramName === "appointment date") {
          dt = e.paramValue;
        }
      });
      setLoadingData(true);
      setPatientsList(null);
      newState.map((patient) => {
        if (patient.appointment.appointmentId === updatedForm.appointmentId) {
          patient.appointment = updatedForm;
        }
      });
      if (formatDateVanilla(updatedForm.appointmentDateTime, "yyyy-MM-dd") !== dt) {
        newState = newState.filter((a) => a.appointment.appointmentId !== updatedForm.appointmentId);
      }
      setPatientsList([...newState]);
      setLoadingData(false);
    }
  };

  const removeAppointment = (id) => {
    let newState = patientsList;
    setLoadingData(true);
    setPatientsList(null);
    newState = newState.filter(
      (patient) => patient.appointment.appointmentId !== id
    );
    setPatientsList([...newState]);
    setLoadingData(false);
  };

  const udateFromCalendarModal = (updatedForm) => {
    fetchData(defaultFilterParamsProp, quickListType, "CALENDAR", title);
  };

  const exportAppointments = () => {
    setShowExportSpinner(true);

    let index = null;
    for (let i = 0; i < filterParams.length; i++) {
      if (filterParams[i].paramName == "cardView") {
        index = i;
        break;
      }
    }
    if (index != null) {
      filterParams.splice(index, 1);
    }

    const filterData = new PatientSearchDTO(
      filterParams,
      sortParams,
      pageNumber,
      pageSize,
      quickListType
    );

    if (view === VIEW.card) {
      filterData.filterParams.push(emptyFilterParam("cardView"));
    }

    let criteria = {};
    criteria.patientRequest = filterData;
    criteria.selectedItems = [];
    /*
    criteria.selectedItems = Array.from(
      selectedPatients,
      ([key, value]) => value.id
    );
    */
    criteria.quickList = quickListType;
    axios
      .post(exportPatientsUrl, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        summary();
      })
      .catch((error) => {
        //alert("error exported");
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  const summary = () => {
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch((error) => {
        //setShowPrintSpinner(false);
        setShowExportSpinner(false);
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
      });
  };

  return (
    <Container fluid className="py-3">
      <Helmet>
        <title>Appointments | CareEmpower</title>
      </Helmet>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col>
              <FilterGroupNew
                filters={filters}
                handleChange={handleFilterChange}
                totalSize={totalSize}
                defaultFilterParams={defaultFilterParams}
                filterParams={filterParams}
                sortParams={sortParams}
                pageNumber={pageNumber}
                pageSize={pageSize}
                quickListType={quickListType}
                setModalMessage={setModalMessage}
                setModalSize={setModalSize}
                setModalTitle={setModalTitle}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Button
                variant="primary"
                id={`${componentName}-addAppointment`}
                className="align-top"
                onClick={() => setShowAppointmentModal(true)}
              >
                + Appointment
              </Button>
            </Col>
            <Col className="text-start text-sm-end">
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{ display: showExportSpinner ? "inline-block" : "none" }}
              />
              <DropdownButton
                size="sm"
                variant="secondary"
                id={`${componentName}-Export`}
                className="d-inline-block me-2"
                title="Export"
              >
                <Dropdown.Item id={`${componentName}-ExportCSV`} onClick={() => exportAppointments()}>
                  <i className="fas fa-file-csv"></i> CSV
                </Dropdown.Item>
              </DropdownButton>
              <StyledListBtnsOS
                variant="outline-secondary"
                size="sm"
                id={`${componentName}-Share`}
                className="align-top"
                show={filterButtons}
                onClick={() => setShareModal(true)}
              >
                Share
              </StyledListBtnsOS>
              <StyledListBtnsOS
                variant="outline-secondary"
                size="sm"
                id={`${componentName}-Delete`}
                className="align-top"
                show={filterButtons}
                onClick={() => setShowConfirmation(true)}
              >
                Delete
              </StyledListBtnsOS>
              <ButtonGroup
                size="sm"
                className="d-inline-block bg-white align-top"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Calendar View</Tooltip>}
                >
                  <Button
                    onClick={() => {
                      transitionView("CALENDAR");
                      // copyToPatientListVariables(listViewData, VIEW.calendar);
                      // setSelectedPatients(new Map());
                    }}
                    id={`${componentName}-ShowCalendarView`}
                    variant="outline-secondary"
                    size="sm"
                  >
                    <i className="fas fa-calendar fa-lg align-middle"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Card View</Tooltip>}
                >
                  <Button
                    onClick={() => {
                      transitionView("CARD");
                      // copyToPatientListVariables(cardViewData, VIEW.card);
                      // setSelectedPatients(new Map());
                    }}
                    id={`${componentName}-ShowCardView`}
                    className="me-0"
                    variant="outline-secondary"
                    size="sm"
                    //disabling card view from vega-6.1 release vega-7160
                    disabled={true}
                  >
                    <i className="fas fa-id-card fa-lg align-middle"></i>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      {view === VIEW.calendar ? (
        <CalendarView
          patientList={patientsList}
          loadingPatients={loadingData}
          handleDateChange={handleDateChange}
          dateObject={dateObject}
          setDateObject={setDateObject}
          updateAppointment={udateFromCalendarModal}
          returnToUrl={RETURN_TO_MY_PATIENTS_URL}
        />
      ) : view === VIEW.card ? (
        <CalendarCardView
          patientList={patientsList}
          loadingPatients={loadingData}
          handleDateChange={handleDateChange}
          dateObject={dateObject}
          setDateObject={setDateObject}
          updateAppointment={updateAppointment}
          removeAppointment={removeAppointment}
          returnToUrl={RETURN_TO_MY_PATIENTS_URL}
        />
      ) : null}
      <Modals
        show={showAppointmentModal}
        onHide={() => setShowAppointmentModal(false)}
        title="Appointment"
        size="xl"
        completeBtn={"CONFIRM"}
        hideFooter={true}
        body={
          <CalendarViewModal
            parent="CPCard"
            carePath={null}
            onHideClicked={() => setShowAppointmentModal(false)}
            updateParentAppointment={updateAppointment}
            selectedPatient={null}
            // updateParent={updateParent}
            patientCarePaths={[]}
          />
        }
      />
      <Modals
        show={modalMessage != null}
        onHide={() => setModalMessage(null)}
        title={modalTitle}
        size={modalSize || "md"}
        hideClickButton={false}
        showExtraButton={false}
        hideFooter={true}
        body={modalMessage}
      />
      <ConfirmWorklistDeletion
        showConfirmation={showConfirmation}
        setConfirmation={setShowConfirmation}
        id={quicklistId}
        customListDto={customListDto}
      />
      {quicklistId && !isNaN(quicklistId) ?
        <ShareWorklist
          shareModal={shareModal}
          setShareModal={setShareModal}
          setModalMessage={setModalMessage}
          setModalSize={setModalSize}
          setModalTitle={setModalTitle}
          customListDto={customListDto}
          setCustomListDto={setCustomListDto}
          quicklistId={quicklistId}
        /> : ''
      }
    </Container>
  );
};

const ConfirmWorklistDeletion = ({
  showConfirmation,
  setConfirmation,
  id,
  customListDto,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let modalTitle = "Delete Custom List";
  let modalMessage = "Are you sure you want to delete this custom list?";

  const handleWorklistDeletion = async () => {
    await deleteUserWorklist(id);
    let listId = null;
    if (customListDto) {
      listId = "/" + customListDto.quickList.toLowerCase() + "?id=" + id;
    }

    await dispatch(removeQuicklistById(listId));

    setConfirmation(false);
    history.push("/all_patients/");
  };

  return (
    <Modals
      show={showConfirmation}
      onHide={() => setConfirmation(false)}
      title={modalTitle}
      size="md"
      onCreate={handleWorklistDeletion}
      hideClickButton={false}
      showExtraButton={false}
      hideFooter={false}
      completeBtn="Delete"
      completeBtnId={`${componentName}-deleteButton`}
      body={<div>{modalMessage}</div>}
    />
  );
};
