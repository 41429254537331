import {
    GET_MESSAGE,
    DELETE_MESSAGE
} from './types';
import axios from "axios";
import { handlePromise } from '../utils/promiseUtils';

const URL = process.env.REACT_APP_REST_API_BASE_URL;

const braodcastMessengerApi = axios.create({
    baseURL: process.env.REACT_APP_REST_API_BASE_URL,
    auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
    }
});

//Request the current message and return it, and if nothing is there return null
export const getMessage = async () => {
    try {
        const res = await handlePromise(braodcastMessengerApi.get("/v1/message-broadcasting-admin/message"));

        return res[1].data;
    } catch (err) {
        return err;
    }
}

//Requests the current message and checks the status of the user's "closedMessage" to determine wether to return the message or null
export const authorizedGetMessage = async (token) => {
    try {
        const res = await axios.get(URL + '/v1/message-broadcasting-admin/message', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return res.data;
    } catch (err) {
        return err;
    }
}

//Here the parameters entered by the admin user will be used to create a new Broadcast message; deleting any existing message
export const createMessage = async (token, message, priority, duration) => {
    try {
        duration = Number(duration);

        const body = { message, priority, duration };

        let result = await axios.post(URL + `/v1/message-broadcasting-admin/message`, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (result.expires) {
            return result;
        } else {
            return undefined;
        }
    } catch (err) {
        return err;
    }
}

//When invoked by an admin user clicking the "Hide Message" button this will remove the stored message if any currently stored
export const deleteMessage = async (token) => {
    try {
        let res = axios.delete(URL + '/v1/message-broadcasting-admin/message', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return res;
    } catch (err) {
        return err;
    }
}

//Gets preset messages for common scenarios and the three different priority levels
export const getPresetData = async (token) => {
    try {
        let messages = await axios.get(URL + '/v1/message-broadcasting-admin/predefined-messages', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        let levels = await axios.get(URL + '/v1/message-broadcasting-admin/broadcast-levels', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return { messages, levels };
    } catch (err) {
        return err;
    }
}

//Closes the the message for the given user and updates thei "closedMessage" so it doesn't reapear
export const closeMessage = async (token) => {
    try {
        return axios.post(URL + '/v1/user-admin/users/messageClosed', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
    } catch (err) {
        return err;
    }
}

//Sets the reducer message key to the passe din value; ie the new message
export const setMessage = (result) => async (dispatch) => {
    await dispatch({
        type: GET_MESSAGE,
        payload: result
    });
}

//Clears the resucer message key's value
export const resetMessage = () => async (dispatch) => {
    dispatch({
        type: DELETE_MESSAGE,
        payload: null
    });
}

//Closes the the message for the given user and updates the release version so it doesn't reapear
export const closeReleaseNoteMessage = async (token) => {
    try {
        return axios.post(URL + '/v1/user-admin/users/release-note-version', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
    } catch (err) {
        return err;
    }
}

//Get the release note version.
export const showNewReleaseBanner = async (token) => {
    try {
        const res = await axios.get(URL + '/v1/user-admin/users/release-note-version', {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return res.data;
    } catch (err) {
        return err;
    }
}