import {isEmpty, isNumber, toNumber} from "lodash";

export const validateCareVisits = (careVisits) => {
  const errors = careVisits?.map((careVisit => {
    const fieldsToValidate = [];

    if (careVisit.enableBloodPressure) {
      fieldsToValidate.push(getBloodPressureFields(careVisit))
    }
    if (careVisit.enableA1C) {
      fieldsToValidate.push(getA1CFields(careVisit))
    }

    return fieldsToValidate.map(fieldsGroup => {
      if (hasError(fieldsGroup.fields, fieldsGroup.isRequired)) {
        return fieldsGroup.errorMessage;
      }
      return null;
    }).filter(errorMessage => !isEmpty(errorMessage))
  })).flatMap(value => value);

  return [...new Set(errors)];
}

const hasError = (fields, isRequired = false) => {
  const withError = Object.keys(fields).map(
    prop => isNotValid(prop, fields[prop], isRequired)).filter(error => error);
  return !isEmpty(withError);
}

const getA1CFields = (careVisit) => {
  return {
    isRequired: false,
    fields: {
      a1cWholes: getNumberOrNull(careVisit.a1cWholes),
      a1cDecimal: getNumberOrNull(careVisit.a1cDecimal)
    },
    errorMessage: 'A1C Values must be integers and cannot exceed 20.9'
  };
}

const getBloodPressureFields = (careVisit) => {
  return {
    isRequired: false,
    fields: {
      bloodPressureSystolic: getNumberOrNull(careVisit.bloodPressureSystolic),
      bloodPressureDiastolic: getNumberOrNull(careVisit.bloodPressureDiastolic),
      systolicCode: getNumberOrNull(careVisit.systolicCode),
      diastolicCode: getNumberOrNull(careVisit.diastolicCode)
    },
    errorMessage: 'Systolic / Diastolic must be an integer between 0 and 400'
  };
}

const isNotValid = (property, value, isRequired) => {
  if (!isNumber(value) && isEmpty(value)) {
    return isRequired;
  }

  switch (property) {
    case "bloodPressureSystolic":
    case "bloodPressureDiastolic":
    case "systolicCode":
    case "diastolicCode":
      return (value < 0 || value > 400);
    case "a1cWholes":
    case "a1cDecimal":
      return (value > 20.9);
    default:
      return true;
  }
};

const getNumberOrNull = (value) => {
  if (isEmpty(value)) {
    return null;
  }
  return toNumber(value);
}