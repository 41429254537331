import React, {useEffect} from 'react'
import {useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {useCMWorklistContext} from '../context/CMWorklist.context';
import EQHErrorToast from "../../../../../../components/EQHErrorToast";
import PatientInformationSummary from "./PatientInformationSummary";

const componentName = 'NewAssessment';

function NewAssessmentTemplateSelector() {
    const {
        getPatientTemplates,
        patientTemplates,
        isNewAssessmentTemplateSelectorEnabled,
        toggleNewAssessmentTemplateSelector,
        patient,
        toggleNewAssessmentFlow,
        setSelectedPatientTemplate
    } = useCMWorklistContext();
    const [template, setTemplate] = useState('');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (isNewAssessmentTemplateSelectorEnabled) {
            resetState()
            getPatientTemplates(patient?.id)
        }
    }, [isNewAssessmentTemplateSelectorEnabled])

    const resetState = () => {
        setTemplate('');
        setErrors([]);
        setSelectedPatientTemplate({});
    }

    const closeModal = () => {
        resetState();

        toggleNewAssessmentTemplateSelector(false);
        toggleNewAssessmentFlow(false);
    }

    const activateNewAssessmentFlow = (templateId) => {
        const template = patientTemplates.find(
            item => item.templateId === templateId);

        setTemplate(template.templateId);
        setSelectedPatientTemplate(template);
        toggleNewAssessmentFlow(true);
    }

    return (
        <Modal
            id={`${componentName}-patientNewAssessmentConfirmModal`}
            size="md"
            show={isNewAssessmentTemplateSelectorEnabled}
            onHide={() => closeModal()}
            centered
        >
            <Modal.Header>
                <Modal.Title
                    id={`${componentName}-patientNewAssessmentConfirmTitle`}>New
                    Assessment</Modal.Title>
                <button
                    id={`${componentName}-patientNewAssessmentConfirmModalClose`}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closeModal()}
                />
            </Modal.Header>
            <Modal.Body>
                <EQHErrorToast errors={errors} handleClose={() => setErrors([])}
                               isList={true}/>

                <PatientInformationSummary rowLayout={1}
                                           fullName={patient.fullName}
                                           memberId={patient.memberId}
                                           dateOfBirth={patient.dateOfBirth}
                                           className='mb-3 bg-light p-3 rounded-3'/>
                <Row className="mb-4">
                    <Col>
                        <Row>
                            <Form.Group>
                                <Form.Label
                                    id={`${componentName}-templateLabel`}>
                                    Assessment Template
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control as="select" value={template}
                                              onChange={(e) => activateNewAssessmentFlow(
                                                  e.target.value)}
                                              id={`${componentName}-newAssessmentTemplateLabel`}>
                                    <option
                                        disabled={true}
                                        key={`${componentName}-select-newAssessmentTemplateItem`}
                                        id={`${componentName}-select-newAssessmentTemplateItem`}
                                        value=''
                                    >
                                        --Select--
                                    </option>
                                    {patientTemplates?.map((x) => (
                                        <option
                                            key={`${componentName}-${x.templateId}-newAssessmentTemplateItem`}
                                            id={`${componentName}-${x.templateId}-newAssessmentTemplateItem`}
                                            value={x.templateId}
                                        >
                                            {x.templateName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button id={`${componentName}-patientNewAssessmentNoBtn`}
                        variant="secondary"
                        onClick={() => closeModal()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewAssessmentTemplateSelector;