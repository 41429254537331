import React from "react";

const componentName = "ContactSupport";

const ContactSupport = () => {
  return (
    <>
      <p className="mb-3">
        For support or assistance with the application or your account please contact your 
        Practice Performance Manager. 
      </p>
    </>
  );
};

export default ContactSupport;
