import React from "react";

import { QuickLists } from "./QuickList";
import { useGetTransQuickListSettings } from "./quickListSettings";
import { useAuth } from "../../context/authContext";
import { QuickListContext } from "./QuickListContext";
import { useLocation } from "react-router-dom";
import { FilterParam } from "../../dtos/PatientSearchDTO";

const AdtEvents = () => {
  const auth = useAuth();
  const location = useLocation();
  const getSettingsRef = useGetTransQuickListSettings("adtEvents", 0, auth);
  const settings = getSettingsRef();
  const source = new URLSearchParams(location.search).get("source");
  
  if (source === "message") {
    settings.defaultFilterParamsProp = [
      new FilterParam(
        "ADT Disposition",
        "eq",
        [
          { text: "BH IP Discharge", value: "BH_IP_DISCHARGE" },
          { text: "ER Discharge", value: "ER_DISCHARGE" },
          { text: "IP Discharge", value: "IP_DISCHARGE" },
        ],
        (x) => x.value,
        (x) => x.text
      ),
      new FilterParam(
        "Days Since Last ADT",
        "eq",
        [{ text: 30, value: 30 }],
        (x) => x.value,
        (x) => x.text
      ),
    ];
  }

  return (
    <QuickListContext.Provider value={settings}>
      <QuickLists {...settings} />
    </QuickListContext.Provider>
  );
};

export { AdtEvents };
