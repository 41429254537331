export const initialState = {
    carePaths: [],
    patient: [],
    patientsList: [],
    selectedPatientsEvents: [],
    totalSize: 0,
    isLoading: false,
    patientSearch: {},
    assignPatientTo: {},
    updateReferralStatus: false,
    removeFromWorklist: false,
    declineReasons: [],
    patientAlreadyRemoved: false,
    exportSummaryData: [],
    isSaveAsCustomWorklistEnabled: false,
    customWorklistError: [],
    isDeleteCustomWorklistEnabled: false,
    customWorklist: {},
    teamsCapacity: [],
    careManagementTeams: [],
    isAssignedPatient: false,
    isNewAssessmentTemplateSelectorEnabled: false,
    patientTemplates: [],
    isNewAssessmentFlowEnabled: false,
    selectedPatientTemplate: {},
    isSavingAssessment: false,
    reassignCareStep: false,
    worklistDefaultView: {},
    workOnCareStep: false,
    customFilters: [],
    isMarkAsOutreachedEnabled: false,
    campaigns: [],
    patientsEventsToBeMarkedAsOutreached: [],
    activeRole: {},
    assignPayload: null,
    isBulk: false,
    adminRolesHubWL: [],
    page: 1
};

export const actions = {
    SELECT_CARE_PATHS: 'SELECT_CARE_PATHS',
    SELECT_PATIENT: 'SELECT_PATIENT',
    SET_PATIENTS_LIST: 'SET_PATIENTS_LIST',
    SET_SELECTED_PATIENTS: 'SET_SELECTED_PATIENTS',
    REMOVE_SELECTED_PATIENTS: 'REMOVE_SELECTED_PATIENTS',
    SET_TOTAL_SIZE: 'SET_TOTAL_SIZE',
    SET_LOADING: 'SET_LOADING',
    SET_PATIENT_SEARCH: 'SET_PATIENT_SEARCH',
    TOGGLE_ASSIGN_PATIENT_TO: 'TOGGLE_ASSIGN_PATIENT_TO',
    TOGGLE_UPDATE_REFERRAL_STATUS: 'TOGGLE_UPDATE_REFERRAL_STATUS',
    TOGGLE_REMOVE_FROM_WORKLIST: 'TOGGLE_REMOVE_FROM_WORKLIST',
    TOGGLE_REASSIGN_CARE_STEP: 'TOGGLE_REASSIGN_CARE_STEP',
    TOGGLE_WORK_ON_CARE_STEP: 'TOGGLE_WORK_ON_CARE_STEP',
    DECLINE_REASONS: 'DECLINE_REASONS',
    PATIENT_HAS_ALREADY_BEEN_REMOVED: 'PATIENT_HAS_ALREADY_BEEN_REMOVED',
    SET_EXPORT_SUMMARY_DATA: 'SET_EXPORT_SUMMARY_DATA',
    TOGGLE_SAVE_AS_CUSTOM_WORKLIST: 'TOGGLE_SAVE_AS_CUSTOM_WORKLIST',
    CUSTOM_WORKLIST_ERROR: 'CUSTOM_WORKLIST_ERROR',
    TOGGLE_DELETE_CUSTOM_WORKLIST: 'TOGGLE_DELETE_CUSTOM_WORKLIST',
    SET_CUSTOM_WORK_LIST: 'SET_CUSTOM_WORK_LIST',
    GET_TEAMS_CAPACITY: 'GET_TEAMS_CAPACITY',
    GET_CARE_MANAGEMENT_TEAMS: 'GET_CARE_MANAGEMENT_TEAMS',
    ASSIGN_PATIENT: 'ASSIGN_PATIENT',
    TOGGLE_NEW_ASSESSMENT_TEMPLATE_SELECTOR: 'TOGGLE_NEW_ASSESSMENT_TEMPLATE_SELECTOR',
    SET_PATIENT_TEMPLATES: 'SET_PATIENT_TEMPLATES',
    TOGGLE_NEW_ASSESSMENT_FLOW: 'TOGGLE_NEW_ASSESSMENT_FLOW',
    SET_SELECTED_PATIENT_TEMPLATE: 'SET_SELECTED_PATIENT_TEMPLATE',
    TOGGLE_IS_SAVING_ASSESSMENT: 'TOGGLE_IS_SAVING_ASSESSMENT',
    SET_WORKLIST_DEFAULT_VIEW: 'SET_WORKLIST_DEFAULT_VIEW',
    SET_CUSTOM_FILTERS: 'SET_CUSTOM_FILTERS',
    TOGGLE_VIEW_AS: 'TOGGLE_VIEW_AS',
    SET_PAGE: 'SET_PAGE',
    TOGGLE_SNOOZED: 'TOGGLE_SNOOZED',
    SET_CAMPAIGNS: 'SET_CAMPAIGNS',
    TOGGLE_MARK_AS_OUTREACHED: 'TOGGLE_MARK_AS_OUTREACHED',
    PATIENT_HAS_ALREADY_BEEN_OUTREACHED: 'PATIENT_HAS_ALREADY_BEEN_OUTREACHED',
    SET_PATIENTS_EVENTS_TO_BE_MARKED_AS_OUTREACHED: 'SET_PATIENTS_EVENTS_TO_BE_MARKED_AS_OUTREACHED',
    SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
    SET_ASSIGN_PAYLOAD: 'SET_ASSIGN_PAYLOAD',
    TOGGLE_IS_BULK: 'TOGGLE_IS_BULK',
    SET_ADMIN_ROLES_HUB_WL: 'SET_ADMIN_ROLES_HUB_WL',
};