import React from "react";
import Credential from "../views/admin/userManagement/UserManagement";
import Branding from "../views/admin/Branding";
import PracticeInformation from "../views/admin/PracticeInformation";
import RolesAuthorities from "../views/admin/rolesAuthorities/RolesAuthorities";
import ReferralSettings from "../views/admin/ReferralSettings";
import AssessmentTemplate from "../views/admin/AssessmentTemplate";
import BroadcastMessenger from "../views/admin/broadcastMessenger/BoadcastMessenger";
import MessageInbox from "../views/Inbox/MessageInbox";
import GapDocumentation from "../views/admin/GapDocumentation";
import { AllPatients } from "../views/quickList/AllPatients";
import { WorkList } from "../views/quickList/WorkList";
import { CustomWorklist } from "../views/quickList/CustomWorklist";
import { MyPatients } from "../views/quickList/MyPatients";
import { PreventiveCare } from "../views/quickList/PreventiveCare";
import { MissedOpportunities } from "../views/quickList/MissedOpportunities";
import { AccountSettings } from "../views/accountSettings/AccountSettings";

import { AdtEvents } from "../views/quickList/AdtEvents";
import { RequiringActions } from "../views/quickList/RequiringActions";
import { ReferralsSent } from "../views/quickList/ReferralsSent";
import { ReferralsReceived } from "../views/quickList/ReferralsReceived";
import { AwaitingResponse } from "../views/quickList/AwaitingResponse";
import { AwaitingCompletion } from "../views/quickList/AwaitingCompletion";
import { loadCustomFilteredList } from "../actions/navigation/navigationActions";
import { PatientProfile } from "../views/patientProfile/PatientProfile";
import { Appointments } from "../views/quickList/Appointments";
import { InactivePatients } from "../views/quickList/InactivePatients";
import { Asthma } from "../views/quickList/Asthma";
import { Diabetes } from "../views/quickList/Diabetes";
import { Cvd } from "../views/quickList/Cvd";
import { Copd } from "../views/quickList/Copd";
import { Depression } from "../views/quickList/Depression";
import { Ckd } from "../views/quickList/Ckd";
import { MemberAssign } from "../views/caremgmt/MemberAssign";
import { CareTeam } from "../views/caremgmt/CareTeam";
import { ReportingAnalytics } from "../views/caremgmt/reportingAnalytics/ReportingAnalytics";

import ReportMain from "../views/reports/ReportMain";
import ECIPDashboard from "../views/reports/ECIPDashboard";
import CarePathTemplate from "../views/admin/CarePathTemplate";
import CareAdmin from "../views/caremgmt/CareAdmin";
import PerformanceManager from "../views/caremgmt/PerformanceManager";
import {CareEmpowerReferrals} from "../views/caremgmt/CareReferrals";
import { PracticeLevelConfiguration } from "../views/admin/PracticeLevelConfiguration";
import {ReferralsList} from "../views/quickList/ReferralsList";
import {CMWorklist} from "../views/caremgmt/worklist/CMWorklist";
import { Schedule } from "../views/quickList/Schedule";
import { NewReferralsReceived } from "../views/quickList/NewReferralReceived/NewReferralReceived";
import {UploadSystemFile} from "../views/admin/systemFile/UploadSystemFile";
const CUSTOM_FILTERED_LISTS = "Custom Lists";
const PATIENT_ROSTER = "Patient Roster";
const QUICK_LISTS = "Quick Lists";


const getCustomFilteredListObject = (navigationItems) => {
  if (navigationItems == null) {
    navigationItems = NAVIGATION_ITEMS;
  }

  for (let i = 0; i < navigationItems.length; i++) {
    if (PATIENT_ROSTER === navigationItems[i].title) {
      for (let j = 0; j < navigationItems[i].items.length; j++) {
        if (QUICK_LISTS === navigationItems[i].items[j].title) {
          let quickListObject = navigationItems[i].items[j];
          if (quickListObject.items != null) {
            for (let k = 0; k < quickListObject.items.length; k++) {
              if (CUSTOM_FILTERED_LISTS === quickListObject.items[k].title) {
                return quickListObject.items[k];
              }
            }
          }
        }
      }
    }
  }
  return null;
};


const NAVIGATION_ITEMS = [
  {
    title: "My Members",
    to: {pathname: "/my_members/", state: {pageTitle: 'My Members'}},
    id: "cmWorklist",
    icon: "fa-list-check",
    isOpen: true,
    canAccess: (auth) => auth.hasAuthority("AUTH_CM_WORKLIST_ACCESS"),
    items: [
      {
        title: 'My Members',
        id: "cmWorklistSubMenu",
        isOpen: true,
        canAccess: (auth) => auth.hasAuthority("AUTH_CM_WORKLIST_ACCESS"),
        useDispatch: true,
        reducerName: "navigation",
        onLoad: () => () => {
        },
        filterMenuItems: (menuItems) => {
          return menuItems.filter(menuItem => menuItem.type === 'my_members').sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
          });
        }
      }
    ]
  },
  {
    title: "Care Management",
    id: "careManagement",
    icon: "fa-hand-heart",
    isOpen: true,
    canAccess: () => true,
    items: [
      {
        title: "My Workspace",
        to: {pathname: "/caremgmt/workspace", state: {pageTitle: 'My Workspace'}},
        id: "careWorkspace",
      },
      {
        title: "Hub Workcenter",
        to: {pathname: "/caremgmt/workcenter", state: {pageTitle: 'Hub Workcenter'}},
        id: "careWorkcenter",
      },
      {
        title: "CareEmpower Referrals",
        to: {pathname: "/caremgmt/referrals", state: {pageTitle: 'CareEmpower Referrals'}},
        id: "careReferrals",
      }
    ]
  },
  {
    title: "Schedule",
    to: {pathname: "/schedule", state: {pageTitle: 'Schedule'}},
    id: "schedule",
    icon: "fa-regular fa-calendar",
  },
  {
    title: "Worklist",
    to: {pathname: "/worklist/",state: {pageTitle: 'Worklist'}},
    id: "workList",
    icon: "fa-list-check",
    canAccess: (auth) => auth.hasAuthority("USER_WORK_LIST_ACCESS"),
  },
  {
    title: "Missed Opportunities",
    to: {pathname: "/missed_opportunities/", state: {pageTitle: 'Missed Opportunities'}},
    id: "missedOpportunities",
    icon: "fa-regular fa-alarm-clock",
  },
  {
    title: "Roster",
    to: {pathname: "/all_patients/",state: {pageTitle: 'Roster'}},
    id: "patientRoster",
    icon: "fa-user-friends",
    isOpen: true,
    canAccess: () => true,
    items: [
      {
        title: "Preventive Care",
        to: {pathname: "/preventive_care/", state: {pageTitle: 'Preventive Care'}},
        id: "preventiveCare",
      },
      {
        title: "Transitions of Care",
        to: {pathname: "/transitions_of_care/", state: {pageTitle: 'Transitions of Care'}},
        id: "transitionsOfCare",
      },
      {
        title: "High Risk",
        to: {pathname: "/high_risk/", state: {pageTitle: 'High Risk'}},
        id: "requiringAction",
      },
      {
        title: "Active/Inactive Patients",
        to: {pathname: "/active_inactive_patients/",state: {pageTitle: 'Active/Inactive Patients'}},
        id: "activeInactivePatients",
      },
      {
        title: CUSTOM_FILTERED_LISTS,
        id: "customLists",
        isOpen: true,
        canAccess: () => true,
        onLoad: loadCustomFilteredList,
        useDispatch: true,
        reducerName: "navigation",
        filterMenuItems: (menuItems) => {
          return menuItems.filter(menuItem => !['cm_worklist', 'my_members', 'new_referrals_received'].includes(menuItem.type));
        }
      }
    ],
  },
  {
    title: "Referrals Received",
    to: {pathname: "/referrals-received/", state: {pageTitle: 'Referrals Received'}},
    id: "referrals-received",
    icon: "fa-file-plus",
    isOpen: true,
    canAccess: (auth) => auth.hasAuthority("AUTH_REFERRALS_RECEIVED_READ_ACCESS") || auth.hasAuthority("AUTH_REFERRALS_RECEIVED_WRITE_ACCESS"),
    items: [
      {
        title: 'Referrals Received',
        id: "referralsReceivedSubMenu",
        isOpen: true,
        canAccess: (auth) => auth.hasAuthority("AUTH_REFERRALS_RECEIVED_READ_ACCESS") || auth.hasAuthority("AUTH_REFERRALS_RECEIVED_WRITE_ACCESS"),
        useDispatch: true,
        reducerName: "navigation",
        onLoad: () => () => {
        },
        filterMenuItems: (menuItems) => {
          return menuItems.filter(menuItem => menuItem.type === 'new_referrals_received').sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
          });
        }
      }
    ]
  },
  {
    title: "Referrals",
    to: {pathname: "/referrals", state: {pageTitle: 'Referrals'}},
    id: "referrals",
    icon: "fa-arrow-right-to-arc",
  },
  {
    title: "Appointments",
    to: {pathname: "/appointments", state: {pageTitle: 'Appointments'}},
    id: "appointments",
    icon: "fa-calendar",
  },
  {
    title: "Reports",
    icon: "fa-archive",
    to: {pathname: "/reports", state:{pageTitle: "Reports"}},
    id: "reports",
  },
  {
    title: "YTD ECIP Dashboard",
    icon: "fa-chart-area",
    to: {pathname: "/ytddashboard",state:{pageTitle:"YTD ECIP Dashboard"}},
    id: "ytdECIPDashboard",
  },
  {
    title: "Administration",
    canAccess: (auth) => auth.isAdmin(),
    icon: "fa-user-nurse",
    id: "administration",
    items: [
      {
        title: "Branding",
        to: {pathname:"/admin/branding",state:{pageTitle: "Branding"}},
        id: "adminBranding",
      },
      {
        title: "User Management",
        to:{pathname:"/admin/users",state:{pageTitle:"User Management"}},
        id: "adminUserManagement",
      },
      {
        title: "Care Program Template",
        to:{pathname:"/admin/care-path-templates",state:{pageTitle:"Care Program Template"}},
        id: "adminCarePathTemplate",
      },
      {
        title: "Assessment Templates",
        to:{pathname:"/admin/assessment-templates",state:{pageTitle:"Assessment Templates"}},
        id: "adminAssessmentTemplates",
      },
      {
        title: "Roles Authorities",
        to:{pathname:"/admin/roles-authorities",state:{pageTitle:"Roles Authorities"}},
        id: "adminRolesAuthorities",
      },
      {
        title: "Message Broadcasting",
        to:{pathname:"/admin/message-broadcasting",state:{pageTitle:"Message Broadcasting"}},
        id: "adminMessageBroadcasting",
      },
      {
        title: "Upload System File",
        to:{pathname:"/admin/upload-system-file",state:{pageTitle:"Upload System File"}},
        id: "adminUploadSystemFile",
      },
      {
        title: "Referral Email Settings",
        to:{pathname:"/admin/referral-email-settings",state:{pageTitle:"Referral Email Settings"}},
        id: "adminReferralEmailSettings",
      },
      {
        title: "Practice Information",
        authority: "AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS",
        to:{pathname:"/admin/practice-information",state:{pageTitle:"Practice Information"}},
        id: "adminPracticeInformation",
      },
      {
        title: "Practice Level Configuration",
        authority: "AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS",
        to:{pathname:"/admin/practice-level-configuration",state:{pageTitle:"Practice Level Configuration"}},
        id: "adminPracticeLevelConfiguration",
      },
      {
        title: "Gap Documentation",
        authority: "AUTH_GAP_DOCUMENTS_ACCESS",
        to:{pathname:"/admin/gap-documentation",state:{pageTitle:"Gap Documentation"}},
        id: "adminGapDocumentation",
      },
      {
        title: "Configuration",
        to: {pathname: "/admin/configuration", state: {pageTitle: 'Configuration Manager'}},
        id: "Configuration",
      },
      {
        title: "Performance Manager",
        to: {pathname: "/admin/performancemanager", state: {pageTitle: 'Performance Manager'}},
        id: "performanceManager",
      },
      {
        title: "Reporting and Analytics",
        to: {pathname: "/admin/reporting", state: {pageTitle: 'Reporting and Analytics'}},
        id: "reportingAndAnalytics"
      }
    ],
  },
];

const ROUTES = {
  "Patient Profile": {
    path: "/patient-profile/",
    component: (props) => {
      const queryParams = new URLSearchParams(props.location.search);
      return React.createElement(PatientProfile, {
        patientId: queryParams.get("patientId"),
        tab: queryParams.get("tab"),
        itemId: queryParams.get("itemId")
      });
    },
  },
  "Hub Workcenter": {
    canAccess: (auth) => auth.hasAuthority("AUTH_CM_HUB_WORK_CENTER_ACCESS"),
    path: "/caremgmt/workcenter",
    landingPage: true,
    component: () => React.createElement(MemberAssign),
  },
  "My Workspace": {
    canAccess: (auth) => auth.hasAuthority("AUTH_CM_HUB_MY_WORKSPACE_ACCESS"),
    path: "/caremgmt/workspace",
    landingPage: true,
    component: () => React.createElement(CareTeam),
  },
  "Configuration": {
    canAccess: (auth) => auth.hasAuthority("AUTH_CONFIGURATION_ADMIN_ACCESS"),
    path: "/admin/configuration",
    landingPage: true,
    component: () => React.createElement(CareAdmin),
  },
  "Performance Manager": {
    canAccess: (auth) => auth.hasAuthority("AUTH_PERFORMANCE_MANAGER_ACCESS"),
    path: "/admin/performancemanager",
    landingPage: true,
    component: () => React.createElement(PerformanceManager),
  },
  "CareEmpower Referrals": {
    canAccess: (auth) => auth.hasAuthority("AUTH_CM_HUB_CAREEMPOWER_REFERRALS_ACCESS"),
    path: "/caremgmt/referrals",
    landingPage: true,
    component: () => React.createElement(CareEmpowerReferrals),
  },
  "Reporting and Analytics": {
    canAccess: (auth) => auth.hasAuthority("AUTH_REPORTING_ANALYTICS_ACCESS"),
    path: "/admin/reporting",
    landingPage: true,
    component: () => React.createElement(ReportingAnalytics),
  },
  "High Risk": {
    exact: true,
    path: "/high_risk/",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("REQUIRING_ACTIONS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(RequiringActions, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  
  "Preventive Care": {
    path: "/preventive_care",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("PREVENTIVE_CARE_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(PreventiveCare, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Missed Opportunities": {
    path: "/missed_opportunities",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("MISSED_OPPORTUNITIES_ACCESS"),
    component: (props) =>
      React.createElement(MissedOpportunities, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  Appointments: {
    path: "/appointments",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("APPOINTMENTS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(Appointments, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "My Patients QuickList Id": {
    path: "/my_patients?id=:quicklistId",
    canAccess: (auth) => auth.hasAuthority("MY_PATIENTS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(MyPatients, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Transitions of Care": {
    exact: true,
    path: "/transitions_of_care/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("TRANSITIONS_OF_CARE_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(AdtEvents, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "My Patients": {
    exact: true,
    path: "/my_patients/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("MY_PATIENTS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(MyPatients, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Active/Inactive Patients": {
    exact: true,
    path: "/active_inactive_patients/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("ACTIVE_INACTIVE_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(InactivePatients, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Roster": {
    exact: true,
    path: "/all_patients/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("ALL_PATIENTS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(AllPatients, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Referrals Received": {
    exact: true,
    path: "/referrals-received",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("AUTH_REFERRALS_RECEIVED_READ_ACCESS") || auth.hasAuthority("AUTH_REFERRALS_RECEIVED_WRITE_ACCESS"),
    component: (props) => React.createElement(NewReferralsReceived, {
      quicklistId: props.match.params.quicklistId,
    })
  },
  "Schedule": {
    exact: true,
    path: "/schedule",
    canAccess: () => true,
    landingPage: true,
       component: (props) =>
      React.createElement(Schedule, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Worklist": {
    exact: true,
    path: "/worklist/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("USER_WORK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(WorkList, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Custom Worklist": {
    exact: true,
    path: "/custom_worklist/",
    landingPage: true,
    canAccess: (auth) => auth.hasAuthority("ALL_PATIENTS_QUICK_LIST_ACCESS"),
    component: (props) =>
      React.createElement(CustomWorklist, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Referrals": {
    exact: true,
    path: "/referrals/",
    landingPage: true,
    canAccess: (auth) =>
        auth.hasAuthority("AUTH_REFERRALS_ACCESS"),
    component: (props) =>
      React.createElement(ReferralsList, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Referrals Sent": {
    exact: true,
    path: "/referrals_sent/",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_SENT_ACCESS") ||
      auth.hasAuthority("AUTH_REFERRAL_CARE_COORDINATION_SENT_ACCESS"),
    component: (props) =>
      React.createElement(ReferralsSent, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Referrals-Received": {
    exact: true,
    path: "/referrals_received/",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_RECEIVED_ACCESS") ||
      auth.hasAuthority("AUTH_REFERRAL_CARE_COORDINATION_RECEIVED_ACCESS"),
    component: (props) =>
      React.createElement(ReferralsReceived, {
        quicklistId: props.match.params.quicklistId,
      }),
  },

  Asthma: {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_asthma",
    component: (props) =>
      React.createElement(Asthma, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  Depression: {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_depression",
    component: (props) =>
      React.createElement(Depression, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  Diabetes: {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_diabetes",
    component: (props) =>
      React.createElement(Diabetes, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Cardiovascular disease": {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_cvd",
    component: (props) =>
      React.createElement(Cvd, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Chronic kidney disease": {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_ckd",
    component: (props) =>
      React.createElement(Ckd, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "Chronic obstructive pulmonary disease": {
    canAccess: (auth) =>
      auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS"),
    path: "/chronic_conditions_copd",
    component: (props) =>
      React.createElement(Copd, {
        quicklistId: props.match.params.quicklistId,
      }),
  },

  "\u00A0\u00A0Needs Response": {
    exact: true,
    path: "/referrals_awaiting_response/",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_RECEIVED_ACCESS") ||
      auth.hasAuthority("AUTH_REFERRAL_CARE_COORDINATION_RECEIVED_ACCESS"),
    component: (props) =>
      React.createElement(AwaitingResponse, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  "\u00A0\u00A0Needs Completion": {
    exact: true,
    path: "/referrals_awaiting_completion/",
    landingPage: true,
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_RECEIVED_ACCESS") ||
      auth.hasAuthority("AUTH_REFERRAL_CARE_COORDINATION_RECEIVED_ACCESS"),
    component: (props) =>
      React.createElement(AwaitingCompletion, {
        quicklistId: props.match.params.quicklistId,
      }),
  },
  Reports: {
    canAccess: (auth) => auth.hasAuthority("AUTH_REPORTS_ACCESS"),
    icon: "fa-archive",
    path: "/reports",
    landingPage: true,
    component: () => React.createElement(ReportMain),
  },
  "YTD ECIP Dashboard": {
    canAccess: (auth) => auth.hasAuthority("AUTH_QPOINT_YTD_DASHBOARD_ACCESS"),
    icon: "fa-chart-area",
    path: "/ytddashboard",
    landingPage: true,
    component: () => React.createElement(ECIPDashboard),
  },
  Branding: {
    canAccess: (auth) => auth.hasAuthority("AUTH_ORGANIZATION_SETTINGS_ACCESS"),
    path: "/admin/branding",
    component: () => React.createElement(Branding),
  },
  "Member Assign": {
    canAccess: (auth) => auth.hasAuthority("AUTH_USER_MANAGEMENT_ADMIN_ACCESS"),
    path: "/hub/memberassign",
    component: () => React.createElement(MemberAssign),
  },
  "Care Team": {
    canAccess: (auth) => auth.hasAuthority("AUTH_USER_MANAGEMENT_ADMIN_ACCESS"),
    path: "/hub/careteam",
    component: () => React.createElement(CareTeam),
  },
  "User Management": {
    canAccess: (auth) => auth.hasAuthority("AUTH_USER_MANAGEMENT_ADMIN_ACCESS"),
    path: "/admin/users",
    component: () => React.createElement(Credential),
  },
  "Care Program Template": {
    canAccess: (auth) => auth.hasAuthority("AUTH_CARE_PATHS_ADMIN_ACCESS"),
    path: "/admin/care-path-templates",
    component: () => React.createElement(CarePathTemplate),
  },
  "Assessment Templates": {
    canAccess: (auth) => auth.hasAuthority("AUTH_ASSESSMENTS_ADMIN_ACCESS"),
    path: "/admin/assessment-templates",
    component: () => React.createElement(AssessmentTemplate),
  },
  "Roles Authorities": {
    canAccess: (auth) => auth.hasAuthority("AUTH_ROLES_AUTHORITIES_ADMIN"),
    authority: "AUTH_ROLES_AUTHORITIES_ADMIN",
    path: "/admin/roles-authorities",
    component: () => React.createElement(RolesAuthorities),
  },
  "Message Broadcasting": {
    canAccess: (auth) => auth.hasAuthority("AUTH_MESSAGE_BROADCASTING_ACCESS"),
    path: "/admin/message-broadcasting",
    component: () => React.createElement(BroadcastMessenger),
  },
  "Upload System File": {
    canAccess: (auth) => auth.hasAuthority("AUTH_UPLOAD_SYSTEM_FILE_ADMIN_ACCESS"),
    path: "/admin/upload-system-file",
    component: () => React.createElement(UploadSystemFile),
  },
  "Referral Email Settings": {
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_EMAIL_SETTINGS_ACCESS"),
    path: "/admin/referral-email-settings",
    component: () => React.createElement(ReferralSettings),
  },
  "Practice Information": {
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS"),
    authority: "AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS",
    path: "/admin/practice-information",
    component: () => React.createElement(PracticeInformation),
  },
  "Practice Level Configuration": {
    canAccess: (auth) =>
      auth.hasAuthority("AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS"),
    authority: "AUTH_REFERRAL_PRACTICE_INFORMATION_TAB_ACCESS",
    path: "/admin/practice-level-configuration",
    component: () => React.createElement(PracticeLevelConfiguration),
  },
  "Gap Documentation": {
    canAccess: (auth) => auth.hasAuthority("AUTH_GAP_DOCUMENTS_ACCESS"),
    authority: "AUTH_GAP_DOCUMENTS_ACCESS",
    path: "/admin/gap-documentation",
    component: () => React.createElement(GapDocumentation),
  },
  "Message Inbox": {
    path: "/message_inbox",
    component: () => React.createElement(MessageInbox),
  },
  "Account Settings": {
    exact: true,
    path: "/settings/",
    component: () => React.createElement(AccountSettings),
  },
  "My Members": {
    exact: true,
    path: "/my_members/",
    canAccess: (auth) =>
        auth.hasAuthority("AUTH_CM_WORKLIST_ACCESS"),
    component: () =>
        React.createElement(CMWorklist),
    landingPage: true,
  },
};

const flattenHelper = (r, a) => {
  if (a.hasOwnProperty("items")) {
    return a.items.reduce(flattenHelper, r);
  }
  if (a.to) {
    r.push(a);
  }
  return r;
};

const FLATTED_NAVIGATION_ITEMS = NAVIGATION_ITEMS.reduce(flattenHelper, []);

FLATTED_NAVIGATION_ITEMS.filter((x) => x.to && x.title && !x.canAccess).forEach(
  (x) =>
    (x.canAccess = (auth) =>
      ROUTES[x.title] ? ROUTES[x.title].canAccess(auth) : false)
);

export {
  NAVIGATION_ITEMS,
  FLATTED_NAVIGATION_ITEMS,
  getCustomFilteredListObject,
  ROUTES,
};
