import axios from "../../utils/apiClient";
const URL = process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/notifications';
const FILTER_ALL = "ALL";
const READ = "READ";

export const fetchNotifications = (
    pageNumber = 1,
    pageSize = 10,
    filter = 'TASKS_FOR_NOTIFICATION',
) => {
    const params = { pageNumber, pageSize, filter };
    return axios
        .get(URL, {
            params: params,
            headers: {},
        })
}

export const fetchAllNotifications = (
    pageNumber = 1,
    pageSize = 999,
    filter = 'TASKS_FOR_NOTIFICATION',
) => {
    return axios.get(URL, {
        params: { pageNumber, pageSize, filter },
        headers: {},
    })
}

export const readNotification = (id) => {
    const body = {
        "status": READ,
        "notificationIdList": [id]
    }
    return axios
        .put(URL, body);
}