export const initialState = {
  showAccepted: false,
  showCancelled: false,
  showPatientCancelled: false,
  showDeclined: false,
  showPending: false,
  showCompleted: false,
  query: {},
  referral: {},
  referralsList: [],
  totalSize: 0
};

export const actions = {
  TOGGLE_ACCEPTED: 'TOGGLE_ACCEPTED',
  TOGGLE_PENDING: 'TOGGLE_PENDING',
  SET_REFERRAL: 'SET_REFERRAL',
  TOGGLE_CANCELLED: 'TOGGLE_CANCELLED',
  TOGGLE_PATIENT_CANCELLED: 'TOGGLE_PATIENT_CANCELLED',
  TOGGLE_DECLINED: 'TOGGLE_DECLINED',
  TOGGLE_COMPLETED: 'TOGGLE_COMPLETED',
  SET_QUERY: 'SET_QUERY',
  SET_REFERRALS_LIST: 'SET_REFERRALS_LIST',
  SET_TOTAL_SIZE: 'SET_TOTAL_SIZE'
};