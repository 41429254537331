import React, { useEffect, Fragment, useState, useContext } from "react";

import { Button } from "../../components/buttons";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { ItemsAccordion } from "../../components/ItemsAccordion";
import Row from "react-bootstrap/Row";
import { useGetPatientProfileAppointments } from "../../actions/patientProfileActions";
import { Timeline } from "../../components/Timeline";
import { filterUtil } from "../common/viewUtil";
import { EQHSpinner } from "../../components/EQHSpinner";
import { appointmentsReducer } from "../../reducers/patientProfile/appointmentsReducer";
import CalendarViewModal from "../appointment/CalenderViewModal";
import {
  formatDate,
  formatTimeOnly,
  getWrittenDate,
  formatDuration,
  formatStringProper, formatLocalDate,
} from "../../utils/util";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Modals from "../../components/modals";
import { getFileDownload } from "../../actions/fileUploadActions";
import { useDispatch } from "react-redux";
import axios from "../../utils/apiClient";
import { useAuth } from "../../context/authContext";
import { QuickListContext } from "../quickList/QuickListContext";
import { useGetTransQuickListSettings } from "../quickList/quickListSettings";

const componentName = "patientProfile-Appointment";

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile";

const StyledInput = styled.input`
  width: 10px;
  height: 10px;
  &.marginAdded {
    margin-right: 8px;
  }
`;

const AppointmentCard = ({ appointment, updateList, patient, carePaths, idx }) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState(
    false
  );

  const handleClickofLink = (file) => {
    if (file?.metadata?.fileId) {
      dispatch(
        getFileDownload(file?.metadata?.fileId, file?.metadata?.fileName)
      );
    }
  };
  return (
    <Container fluid>
      <Modals
        show={showEditAppointmentModal}
        onHide={() => setShowEditAppointmentModal(false)}
        title={`Edit Appointment: ${patient.lastName}, ${patient.firstName}`}
        titleid={`${componentName}-editAppointment`}
        size="xl"
        completeBtn={"Save"}
        completeBtnId={`${componentName}-saveButton`}
        hideFooter={true}
        body={
          <CalendarViewModal
            parent="CPCard"
            onHideClicked={() => setShowEditAppointmentModal(false)}
            updateParentAppointment={updateList}
            selectedPatient={appointment}
            removeAppointment={updateList}
            patientSpecific={patient}
            patientCarePaths={carePaths}
          />
        }
      />
      <Row className="mb-2">
        <Col id={`${componentName}-appointmentName-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentNameLabel-${idx}`} className="fw-bold d-block">Appointment Name</span>
          <span id={`${componentName}-appointmentNameValue-${idx}`}>{appointment.name}</span>
        </Col>
        <Col id={`${componentName}-appointmentDate-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentDateLabel-${idx}`} className="fw-bold d-block">
            {getWrittenDate(appointment.appointmentDateTime)}
          </span>
          <span id={`${componentName}-appointmentDateValue-${idx}`}>{formatDuration(
            appointment.appointmentDateTime,
            appointment.durationInMinutes
          )}</span>
        </Col>
        <Col id={`${componentName}-appointmentStatus-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentStatusLabel-${idx}`} className="fw-bold d-block">Status</span>
          <span id={`${componentName}-appointmentStatusValue-${idx}`}>{appointment.cancelled === true
            ? "Cancelled"
            : formatStringProper(appointment.status)}</span>
        </Col>
        {!appointment.cancelled ? (
          <Col id={`${componentName}-appointmentEdit-${idx}`} sm={2} className="text-end me-4">
            {auth.hasAuthority("AUTH_APPOINTMENTS_WRITE_ACCESS") && (
              <Button
                variant="secondary"
                id={`${componentName}-appointmentEditButton-${idx}`}
                className="align-end"
                onClick={() => setShowEditAppointmentModal(true)}
              >
                Edit Appointment
              </Button>
            )}
          </Col>
        ) : null}
      </Row>
      <Row className="mb-2">
        <Col id={`${componentName}-appointmentProvider-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentProviderLabel-${idx}`} className="fw-bold d-block">Provider</span>
          <span id={`${componentName}-appointmentProviderName-${idx}`}>{appointment.provider.lastName == null ? '' : appointment.provider.lastName +", "+ appointment.provider.firstName}</span>
          <br />
          <span id={`${componentName}-appointmentProviderAddress-${idx}`}>{appointment.address}</span>
        </Col>
        <Col id={`${componentName}-appointmentReminder-${idx}`} sm={3}>
          <StyledInput
            type="checkbox"
            id={`${componentName}-appointmentReminderCheck-${idx}`}
            checked={appointment.shouldSendReminder}
            disabled={true}
          />{" "}
          <span id={`${componentName}-appointmentreminderLabel-${idx}`}>Patient Reminder ({appointment.notificationDelay}{" "}
          {formatStringProper(appointment.notificationDelayUnit)} Delay)</span>
        </Col>
        <Col id={`${componentName}-appointmentNeeds-${idx}`} sm={3}>
          <StyledInput
            type="checkbox"
            id={`${componentName}-appointmentNeedsCheck-${idx}`}
            checked={appointment.needsTransportation}
            disabled={true}
          />{" "}
          <span id={`${componentName}-appointmentNeedsLabel-${idx}`}>Patient Needs Transportation</span>
        </Col>
        <Col id={`${componentName}-appointmentCarePath-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentCarePathLabel-${idx}`} className="fw-bold d-block">Care Program</span>
          <span id={`${componentName}-appointmentCarePathValue-${idx}`}>{appointment.carePathName != null ? appointment.carePathName : "-"}</span>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col id={`${componentName}-appointmentAttachments-${idx}`} sm={3}>
          <span id={`${componentName}-appointmentAttachmentsLabel-${idx}`} className="fw-bold d-block">Attachments</span>
          {appointment?.files &&
            appointment?.files.length > 0 &&
            appointment?.files.map((file) => (
              <span id={`${componentName}-appointmentAttachmentsValue-${idx}`} className="d-block" key={file?.metadata?.fileId}>
                {file && (
                  <small>
                    <a id={`${componentName}-appointmentAttachmentsLink-${idx}`} href="#" onClick={() => handleClickofLink(file)}>
                      {file?.metadata?.fileName}
                    </a>
                  </small>
                )}
                {"  "}
              </span>
            ))}
        </Col>
        <Col id={`${componentName}-appointmentNotes-${idx}`} sm={9}>
          <span id={`${componentName}-appointmentNotesLabel-${idx}`} className="fw-bold d-block">Notes</span>
          <span id={`${componentName}-appointmentNotesValue-${idx}`}>{appointment.note}</span>
        </Col>
      </Row>
    </Container>
  );
};

const ALL = "All";
const UPCOMING = "Upcoming";
const PAST = "Past";

const Appointments = ({ patient, selectedTab, reloadPatientOverview, idx }) => {
  const auth = useAuth();
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [carePaths, setCarePaths] = useState([]);
  const appointmentsPkg = useGetPatientProfileAppointments(
    [],
    appointmentsReducer
  );

  const getSettingsRef = useGetTransQuickListSettings(
    "preventiveCare",
    0,
    auth
  );

  useEffect(() => {
    if (selectedTab) {
      appointmentsPkg.fetchData(patient.id);
      getCarePaths();
    }
  }, [patient.id, selectedTab]);

  const headerAndSelectorMap = {
    [ALL]: (item) => true,
    [UPCOMING]: (item) => {
      let todayTime = new Date().getTime();
      let appointmentTime = new Date(item.appointmentDateTime).getTime();

      if (appointmentTime >= todayTime && item.cancelled === false) {
        return item;
      }
    },
    [PAST]: (item) => {
      let today = formatDate(new Date());
      let todayTime = new Date(today).getTime();
      let appointmentTime = new Date(item.appointmentDateTime).getTime();

      if (appointmentTime <= todayTime || item.cancelled === true) {
        return item;
      }
    },
  };

  const getCarePaths = () => {
    axios
      .get(baseURL + `/care-paths?patientId=${patient.id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCarePaths(res.data);
      })
      .catch((err) => {
      });
  };

  return (
    <Fragment>
      <Container fluid>
        <QuickListContext.Provider value={getSettingsRef()}>
          <Modals
            show={showAppointmentModal}
            onHide={() => setShowAppointmentModal(false)}
            title="Appointment"
            titleid={`${componentName}-AppointmentModal`}
            size="xl"
            completeBtn={"Confirm"}
            completeBtnId={`${componentName}-confirmButton`}
            hideFooter={true}
            body={
              <CalendarViewModal
                parent="CPCard"
                onHideClicked={() => setShowAppointmentModal(false)}
                updateParentAppointment={() => {
                  appointmentsPkg.fetchData(patient.id);
                  reloadPatientOverview();
                }}
                selectedPatient={null}
                removeAppointment={() => appointmentsPkg.fetchData(patient.id)}
                patientSpecific={patient}
                patientCarePaths={carePaths}
              />
            }
          />
          {appointmentsPkg.state.isLoading && <EQHSpinner />}
          {appointmentsPkg.state.error ? (
            <Card>
              <Card.Body id={`${componentName}-appointmentsError-${idx}`}>{appointmentsPkg.state.error}</Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <Row className="mb-2">
                  <Col>
                    {auth.hasAuthority("AUTH_APPOINTMENTS_WRITE_ACCESS") && (
                      <Button
                        variant="primary"
                        id={`${componentName}-addAppointmentButton`}
                        onClick={() => setShowAppointmentModal(true)}
                      >
                        + Appointment
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ItemsAccordion
                      items={appointmentsPkg.state.data}
                      headerAndSelectorMap={headerAndSelectorMap}
                      selected={"All"}
                      bodyBuilder={(key, list, idx) => (
                        <Timeline
                          items={list}
                          titleBuilder={(item, idx) =>
                            item && item.lastModified ? (
                              <span>
                                <span id={`${componentName}-tlDate-${idx}`} className="d-block fw-bold">
                                  {formatLocalDate(item.lastModified)}
                                </span>
                                <span id={`${componentName}-tlTime-${idx}`}>
                                  {formatTimeOnly(item.lastModified)}
                                </span>
                              </span>
                            ) : (
                              "-"
                            )
                          }
                          // subTitleBuilder={(note) => note.itemTime}
                          bodyBuilder={(item, idx) => (
                            <AppointmentCard
                              appointment={item}
                              idx={idx}
                              updateList={() => {
                                appointmentsPkg.fetchData(patient.id);
                                reloadPatientOverview();
                              }}
                              patient={patient}
                              carePaths={carePaths}
                            />
                          )}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </QuickListContext.Provider>
      </Container>
    </Fragment>
  );
};

export { Appointments };
