import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../components/buttons";
import { PatientSearchDTO } from "../../dtos/PatientSearchDTO";
import axios from "../../utils/apiClient";
import {
  updateQuicklists
} from "../../actions/navigation/navigationActions";
import {useHistory, useLocation} from "react-router-dom";
import {VIEW} from "./QuickList";

export const UPDATE_CUSTOM_FILTER_LIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-filtered-list/custom-list";

const componentName = "UpdateCustomFilterList";

const UpdateCustomFilterList = (props) => {
  const history = useHistory();
  const quickListState = useLocation();
  const [name, setName] = useState('');
  const [share, setShare] = useState(false);

  useEffect(() => {
    if (props.setShowUpdateCustomFilterList) {
      const queryParameters = new URLSearchParams(window.location.search);
      const nameValue = queryParameters.get("customListName");
      setName(nameValue);
    }
  }, [props.showUpdateCustomFilterList]);

  const onHide = () => {
    props.setShowUpdateCustomFilterList(false);
  };

  const onSubmit = () => {

    let tempFilterParams = props.filterParams.filter(param => param.paramName != "appointment date");

    const filterData = new PatientSearchDTO(
      tempFilterParams,
    );

    let criteria = {};
    criteria.id = props.id;
    criteria.name = name;
    criteria.share = share;
    criteria.patientRequestDTO = filterData;

    axios
      .post(UPDATE_CUSTOM_FILTER_LIST_URL, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        updateQuicklists();
        onHide();

        const endPrefix = window.location.search.indexOf('customListName=') + 15;
        const prefix = window.location.search.substring(0, endPrefix);
        const endParm = window.location.search.indexOf('&', endPrefix);
        const suffix = window.location.search.substring(endParm);
        const newName = encodeURIComponent(name);
        const windowLocationPathnameWithoutTrailingSlash = window.location.pathname.replace(/\/$/, "");
        const newUrl = windowLocationPathnameWithoutTrailingSlash + '/' + prefix + newName + suffix;

        history.push(newUrl);
        window.location.reload();
      })
      .catch((error) => {
        props.setModalTitle("Error");
        props.setModalSize("lg");
        props.setModalMessage(error.response.data.message);
      });
  };

  return (
    <Modal show={props.showUpdateCustomFilterList} onHide={() => onHide()}>
      <Modal.Header closeButton style={{ backgroundColor: "#F9F9FA" }}>
        <Modal.Title id={`${componentName}updateCustomFilteredListModal`}>Update a Custom Filtered List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form>
              <Row className="g-0">
                <Col>
                  <Form.Group controlId="updateCustomFilteredList" id={`${componentName}-customFilteredList`}>
                    <Form.Label id={`${componentName}-customFilteredListName`} className="fw-bold d-block">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id={`${componentName}-customFilteredListNameValue`}
                      placeholder="Custom Filtered List Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0">
                <Col>
                  <Form.Group controlId="updateCustomFilteredListShare" id={`${componentName}-customFilteredListShare`}>
                    <Form.Check
                        type="checkbox"
                        key={`${componentName}-customFilteredListShareKey`}
                        id={`${componentName}-customFilteredListShareId`}
                        label={'Update the custom lists you shared with other users?'}
                        onChange={(e) => setShare(e.target.checked)}
                        checked={share}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <p></p>
            <p id={`${componentName}-filterSettings`}>
              <b>List Filter Settings:</b>
            </p>
            {props.filters.filter(filter => filter.filterValue !== 'All').map((filter, idx) => {
              return (
                <span key={`${componentName}-customFilters-${idx}`} id={`${componentName}-customFilters-${idx}`} className="d-block">
                  <span id={`${componentName}-customFiltersLabel-${idx}`}>{filter.filterName}:</span> <span id={`${componentName}-customFiltersValue-${idx}`}><b>{filter.filterValue}</b></span>
                </span>
              );
            })}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          id={`${componentName}-customFiltersSubmit`}
          onClick={() => onSubmit()}
          disabled={name && name.length < 1}
        >
          Submit
        </Button>
        <Button id={`${componentName}-customFiltersClose`} onClick={() => onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCustomFilterList;
