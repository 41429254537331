export const PROCESS_USERS ="PROCESS_USERS"
export const CONFIRM_USERS = "CONFIRM_USERS";
export const GET_BRAND_ORG = "GET_BRAND_ORG";
export const GET_BRAND_ORG_LOGO = "GET_BRAND_ORG_LOGO";
export const GET_ERRORS = "GET_ERRORS";
export const GET_FILE = "GET_FILE";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const MARK_READ = "MARK_READ";
export const GET_ALL_PAYERS_FROM_COLLECTION = "GET_ALL_PAYERS_FROM_COLLECTION";

export const TOGGLE_HISTORY = "TOGGLE_HISTORY";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RESET_ERRORS = "RESET_ERRORS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_USERS = "GET_USERS";
export const GET_REFERRALS = "GET_REFERRALS";

export const GET_EVENTS = "GET_EVENTS";
export const CREATE_USER = "CREATE_USER";
export const CREATE_NEW_MESSAGE = "CREATE_NEW_MESSAGE";
export const CREATE_NEW_CONVERSATION = "CREATE_NEW_CONVERSATION";
export const ADD_USER_TO_CONVERSATION = "ADD_USER_TO_CONVERSATION";
export const SEND_REPLY = "SEND_REPLY";
export const UPDATE_BRAND_ORG = "UPDATE_BRAND_ORG";
export const GET_SYSTEM_FILE_TYPES = "GET_SYSTEM_FILE_TYPES"
export const GET_SYSTEM_FILES = "GET_SYSTEM_FILES"
export const UPDATE_SYSTEM_FILE = "UPDATE_SYSTEM_FILE";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_MULTIPLE_USER = "UPDATE_MULTIPLE_USER";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";
export const UPDATE_ROLE = "UPDATE_USER";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const RESET_GLOBAL_FORM = "RESET_GLOBAL_FORM";

export const CREATE_ROLE = "CREATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const GET_ROLES = "GET_ROLES";
export const GET_AUTHORITIES = "GET_AUTHORITIES";
export const UPDATE_ROLES_AUTHORITIES = "UPDATE_ROLES_AUTHORITIES";

export const TOGGLE_LOADING = "TOGGLE_LOADING"
export const GET_VALUES_USER_CREATE = "GET_VALUES_USER_CREATE"
export const ACTIVATE_DEACTIVATE_USER = "ACTIVATE_DEACTIVATE_USER"

export const GET_PAYERS = "GET_PAYERS"

export const GET_PRACTICE = "GET_PRACTICE"
export const GET_ALL_PAYERS = "GET_ALL_PAYERS"
export const UPDATE_PRACTICE = "UPDATE_PRACTICE"
export const UPDATE_PRACTICE_LEVEL = "UPDATE_PRACTICE_LEVEL"

export const GET_MESSAGE = "GET_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const GET_USER_MESSAGES = "GET_USER_MESSAGES";
export const GET_ALL_USER_MESSAGES = "GET_ALL_USER_MESSAGES";
export const GET_USER_MESSAGES_COUNT = "GET_USER_MESSAGES_COUNT";
export const GET_MESSAGES_FROM_CONVO = "GET_MESSAGES_FROM_CONVO";
export const DELETE_MESSAGES = "DELETE_MESSAGES";
export const MESSAGE_TYPES = "MESSAGE_TYPES";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const CLEAR_STATE = "CLEAR_STATE";
export const REMOVE_DOCUMENT  = "REMOVE_DOCUMENT";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_ACTIVE_DOCUMENTS = "GET_ACTIVE_DOCUMENTS";
export const CHANGE_CLIENT = "CHANGE_CLIENT";

export const GET_CLIENTS = "GET_CLIENTS"

export const UPDATE_IS_SHOWING_FORM = "UPDATE_IS_SHOWING_FORM";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const GET_PATIENTS = "GET_PATIENTS";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const HIDE_PATIENTS_FOUND = "HIDE_PATIENTS_FOUND";
export const TEMP_HIDE_PATIENTS_FOUND = "TEMP_HIDE_PATIENTS_FOUND";
export const HIDE_POPULATION_SEARCH_VIEW = "HIDE_POPULATION_SEARCH_VIEW";
export const UPDATE_SHOW_SPINNER = "UPDATE_SHOW_SPINNER";
export const SHOW_REQUEST_ALERT = "SHOW_REQUEST_ALERT";
export const USER_ADDED_SUCCESFUL = "USER_ADDED_SUCCESFUL";
export const SEARCH_PATIENTS = "SEARCH_PATIENTS";
export const START_FILTER = "START_FILTER";
export const UPDATE_STEP = "UPDATE_STEP";
export const INCREMENT_CALL_COUNTER = "INCREMENT_CALL_COUNTER";
export const INCREMENT_PATIENT_CALLS = "INCREMENT_PATIENT_CALLS";
export const CLOSE_CARE_PATH = "CLOSE_CARE_PATH";
export const SET_CLOSE_CP_REASONS = "SET_CLOSE_CP_REASONS";
export const SET_CO_REJECTION_REASONS = "SET_CO_REJECTION_REASONS";

export const LOAD_CUSTOM_FILTERED_LIST = "LOAD_CUSTOM_FILTERED_LIST";
export const UPDATE_CUSTOM_LISTS = "UPDATE_CUSTOM_LISTS";

export const SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL = "SET_VISIBLE_ORDER_CUSTOM_LIST_MODAL";

export const SET_MANAGE_VISIT_PATIENT = "SET_MANAGE_VISIT_PATIENT";
export const GET_MANAGE_VISIT_PATIENT = "GET_MANAGE_VISIT_PATIENT";
export const SET_RELOAD_PATIENT_LIST = "SET_RELOAD_PATIENT_LIST";
export const SET_SHOW_FULL_HISTORY = "SET_SHOW_FULL_HISTORY";
export const SET_MANAGE_VISIT_OPENED_FROM = "SET_MANAGE_VISIT_OPENED_FROM";
export const SET_PATIENT_LIST_TYPE = "SET_PATIENT_LIST_TYPE";
export const SET_CARE_PROGRAM_MODAL = "SET_CARE_PROGRAM_MODAL";
export const SET_RELOAD_WORKLIST = "SET_RELOAD_WORKLIST";
export const SET_HIDE_PATIENT_DETAILS_MODAL = "SET_HIDE_PATIENT_DETAILS_MODAL";
export const SET_PCP_ID = 'SET_PCP_ID';
export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_USER_EVENT_ACTION = "SET_USER_EVENT_ACTION";
export const SET_ADD_NEW_ASSESSMENT = 'SET_ADD_NEW_ASSESSMENT';
export const SET_ADD_NEW_APPOINTMENT = 'SET_ADD_NEW_APPOINTMENT';
export const SET_MINIMIZED_PATIENTS = 'SET_MINIMIZED_PATIENTS';
export const GET_MINIMIZED_PATIENTS = 'GET_MINIMIZED_PATIENTS'
export const REMOVE_MINIMIZED_PATIENT = 'REMOVE_MINIMIZED_PATIENTS';
export const SET_ADD_NEW_NOTE = 'SET_ADD_NEW_NOTE';
export const SET_OLD_PATIENT_MODAL = 'SET_OLD_PATIENT_MODAL';
export const SET_SNOOZED_MODAL = 'SET_SNOOZED_MODAL';
export const SET_CUSTOM_STATUS = 'SET_CUSTOM_STATUS';
export const SET_STEP = 'SET_STEP';
