import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Modal, Button } from "react-bootstrap";
import PVCPdfDownloader from "../../info/previsitChartDownloader";
import { AcuteCare, DataBlock, Header, RecommendedActivities, VisitsDue } from "./components";
import { getProfile } from "./utils";
import eqsymbol from "../../../../../img/eqsymbol.jpg";
import { findPatientGet } from "../../context/ManageVisitContext/api";

const componentName = "previsitChart";
export const PrevisitChart = ({
  modalId,
  onHide,
  show,
  patientLoaded,
}) => {
  const [extraData, setExtraData] = useState([])
  const [requiredFields, setRequiredFields] = useState([])
  const [manageVisitData, setManageVisitData] = useState({});
  const patientId = patientLoaded?.id

  useEffect(() => {
    (async function fetchData() {
      show && patientLoaded?.id && await getManageVisitData(patientLoaded?.id)
    })();
  }, [show])

  const getManageVisitData = async (id) => {
    await findPatientGet(id)
      .then(patient => {
        const hasDocument = patient?.data?.manageVisitActivityTypeDtos
          ?.filter(dto => dto?.name === 'Documentation And Coding')?.length > 0
        const newPatient = { ...patient?.data }

        if (!hasDocument) {
          newPatient.manageVisitActivityTypeDtos = [...newPatient?.manageVisitActivityTypeDtos, { name: 'Documentation And Coding', visitActivitySubTypeDtos: [] }]
        }

        newPatient.missedOpportunities = patient?.data?.missedOpportunities || [];
        
        setManageVisitData(newPatient)
      });
  }


  useEffect(() => {
    if (manageVisitData && show) {
      let storedKeys = [];
      manageVisitData?.careVisitDtos &&
      manageVisitData.careVisitDtos.forEach(
          p => storedKeys.push(...Object.keys(p.requiredActivitiesMap))
        );
      setRequiredFields(storedKeys);
      manageVisitData?.patientId &&
        getProfile(manageVisitData?.patientId)
          .then(res => {
            res?.data?.[0] && setExtraData(res?.data?.[0])
          })
    }
  }, [manageVisitData])

  return (
    <Modal size="lg" id={modalId} onHide={onHide} show={show} backdropClassName="previsitbackdrop" contentClassName="shadow border" centered scrollable>
      <Modal.Header>
        <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>
        <div id="CPTHeader">
          <div className="d-flex flex-column pb-3 mb-3 border-bottom">
            <Header extraData={extraData} />
          </div>
        </div>
        <div id="CPTId">
          <div id="CPTpg1" className="mb-3">
            <VisitsDue patient={manageVisitData} />
            <h5 id={`${componentName}-visitActivities`} className="fs-5 fw-bold mb-1">Visit Activities</h5>
            <RecommendedActivities extraData={extraData} patient={manageVisitData} requiredFields={requiredFields} />
          </div>
          <div className="html2pdf__page-break"></div>
          <div id="CPTpg2" className="mb-3">
            {extraData?.labValues && !extraData.labValues.length ? null : (
              <Row className="mb-3">
                <DataBlock
                  customClass='d-block'
                  param1='labName'
                  param2='labResult'
                  param3='orderingProvName'
                  param4='labDate'
                  data={extraData?.labValues}
                  colNumber={3}
                  title='Lab Results'
                />
              </Row>
            )}
            {extraData?.careTimelineValues && <AcuteCare data={extraData?.careTimelineValues} dataForTimeLine={extraData?.careTimeline12MonthItemsDto} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-chartPrepClose`} variant="secondary" onClick={onHide}>
          Close
        </Button>
        <PVCPdfDownloader downloadFileName="ChartPrepTool" footerLogo={eqsymbol} rootElementId="CPTId" rootHeaderId="CPTHeader" patientIds={[patientId]} />
      </Modal.Footer>
    </Modal>
  );
};

export default PrevisitChart;
