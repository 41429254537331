import React from "react";
import { Col, Container, Row } from 'react-bootstrap';

import { HospitalEventFieldLabelColStyle, hospitalEventOpenContentFormFields } from "../constants";

const componentName = 'HospitalEventCardOpened';

/**
 * Renders an expanded view of a hospital event card, displaying detailed information.
 *
 * @param {Object} hospitalEvent - Detailed hospital event data for display.
 */
const HospitalEventCardOpened = ({ hospitalEvent }) => {

  const extractDateWithoutTime = (dateTimeString) => dateTimeString.split(', ').slice(0, 2).join(', ');

  return (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          {hospitalEventOpenContentFormFields.map((field, idx) => (
            <div key={`${componentName}-${field.key}-${idx}`}>
              { field.key !== "notes" ?
                <Row>
                  <Col className="form-label col-form-label" sm={3}>
                    <span id={`${componentName}-detailName-${idx}`}>{field.label}</span>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-referredFromValue-${idx}`}>{hospitalEvent[field.key]}</span>
                  </Col>
                </Row>
                :
                <>
                  <Row>
                    <Col id={`${componentName}-infoDDLinkName-${idx}`} className="form-label col-form-label" sm={3}>
                      {field.label}
                    </Col>
                    <Col className="d-flex align-items-center">
                      {hospitalEvent[field.key] &&
                        extractDateWithoutTime(hospitalEvent?.dateTime)}
                    </Col>
                    <Col className="d-flex align-items-center">
                      {hospitalEvent[field.key]}
                    </Col>
                  </Row>
                </>
              }
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default HospitalEventCardOpened;
