import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";

const [getUserProfileInfo, useGetUserProfileInfo] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/personal-information`,
    {
      headers: {},
    }
  );
});

const saveUserProfileInfo = (userProfileInfo) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/personal-information`,
    userProfileInfo,
    {
      headers: {},
    }
  );
};

const saveUserPassword = ([currentPassword, newPassword]) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/password`,
    { currentPassword, newPassword },
    {
      headers: {},
    }
  );
};

const [getSystemSettings, useGetSystemSettings] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/account-settings/user-profile/system-settings`,
      {
        headers: {},
      }
    );
  }
);

const saveSystemSettingsInfo = (systemSettingsInfo) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/system-settings`,
    systemSettingsInfo,
    {
      headers: {},
    }
  );
};

const [getLandingPages, useGetLandingPages] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/landing-pages`,
    {
      headers: {},
    }
  );
});

const [getTimeZones, useGetTimeZones] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/account-settings/user-profile/time-zones`,
    {
      headers: {},
    }
  );
});

const [getNotificationSettings, useGetNotificationSettings] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
    `/v1/account-settings/user-profile/notification-settings`,
    {
      headers: {},
    }
  );
});

const saveNotificationSettings = (notificationSettings) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
    `/v1/account-settings/user-profile/notification-settings`,
    notificationSettings,
    {
      headers: {},
    }
  );
};

export {
  getUserProfileInfo,
  useGetUserProfileInfo,
  saveUserProfileInfo,
  getSystemSettings,
  useGetSystemSettings,
  saveSystemSettingsInfo,
  getLandingPages,
  useGetLandingPages,
  getTimeZones,
  useGetTimeZones,
  saveUserPassword,
  getNotificationSettings,
  useGetNotificationSettings,
  saveNotificationSettings,
};
