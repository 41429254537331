import React, { useEffect, useState } from 'react'
import { useCMWorklistContext } from '../context/CMWorklist.context';
import { Form, Modal, ModalBody, Row, Col, Button } from 'react-bootstrap';
import axios from '../../../../../../utils/apiClient';
import { DropdownComponent } from '../../../../../../components/DropdownComponent';
import DatePicker from "react-datepicker"
import { setHours, setMinutes } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import EQHErrorToast from '../../../../../../components/EQHErrorToast';
import { RedAsterisk } from '../../../../../../utils/util';
import { addBusinessDaysCustom } from '../../../../../../utils/addBusinessDays';
import { setReloadWorklist } from '../../../../../../actions/manageVisitActions';

const componentName = 'SnoozedModal'

const SnoozedModal = () => {
  const {
    toggleSnoozedModal,
    getPatientsList,
    activeRole,
  } = useCMWorklistContext();

  const dispatch = useDispatch();
  const snoozedModal = useSelector((state) => state?.manageVisitReducer?.snoozedModal);
  const step = useSelector((state) => state?.manageVisitReducer?.step);
  const customStatus = useSelector((state) => state?.manageVisitReducer?.customStatus);

  const [reasons, setreasons] = useState([])
  const [time, settime] = useState([])
  const [formBody, setformBody] = useState({})
  const [selectedDate, setSelectedDate] = useState('')
  const [error, setError] = useState([])

  useEffect(() => {
    setError([])
    setSelectedDate('')
    if (snoozedModal) {
      setformBody({ ...formBody, carePathStepId: step.id })
      getReasons()
      getTime()
    }
  }, [snoozedModal])

  const setSelectedDateFunc = (selectedDate) => {
    setSelectedDate(selectedDate);
    let newState = formBody;
    if (selectedDate) {
      const date = new Date(selectedDate);
      newState.newDueDate = date;
      setformBody({ ...newState });
    }
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} onClick={props.onClick} className="datePicker-container">
        <input
          {...props}
          type="text"
          value={props.value}
          className="form-control"
          style={{fontSize: '0.75rem'}}
        />
        <div className="fa-regular fa-calendar calendar-icon"></div>
      </div>
    );
  });

  const getReasons = () => {
    return axios
      .get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/configuration/care-management-configuration-filter?dropListName=SNOOZE_REASON&status=ACTIVE&isOrdered=false`,
        { headers: {} }
      )
      .then((res) => {
        setreasons(res.data)
      })
  };
  const getTime = () => {
    return axios
      .get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patient-quick-view/snooze-time-options`,
        { headers: {} }
      )
      .then((res) => {
        settime(res.data.map(t => ({
          text: t.text,
          value: parseTime(t.value)
        })))
      })
  };
  const parseTime = (time) => {
    let res;
    switch (time) {
      case '4 hours':
        res = 'HALF_BUSINESS_DAY'
        break;
      case '1 day':
        res = 'ONE_DAY'
        break;
      case '2 days':
        res = 'TWO_DAY'
        break;
      case '7 business days':
        res = 'SEVEN_BUSINESS_DAY'
        break;
      default:
        res = 'OTHER'
        break;
    }
    return res
  }
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const snoozeStep = () => {
    const newBody = { ...formBody, customStatus: customStatus?.status }
    formBody.snoozingTime === 'OTHER' ? delete newBody['snoozingTime'] : delete newBody['newDueDate']
    return axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patient-quick-view/snooze-care-path-step`,
        newBody,
        { headers: {} }
      )
      .then((res) => {
        getPatientsList()
        toggleSnoozedModal(false)
        setformBody({})
        dispatch(setReloadWorklist(false));
        setSelectedDate('')
      }).catch(error => {
        setError([error?.response?.data?.message])
      })
  };

  const handleDate = (type) => {
    const currentDate = new Date()
    switch (type.value) {
      case 'HALF_BUSINESS_DAY':
        setSelectedDateFunc(currentDate.setHours(currentDate.getHours() + 4))
        setformBody({ ...formBody, snoozingTime: type.value })
        break;
      case 'ONE_DAY':
        setSelectedDateFunc(addBusinessDaysCustom(currentDate, 1))
        setformBody({ ...formBody, snoozingTime: type.value })
        break;
      case 'TWO_DAY':
        setSelectedDateFunc(addBusinessDaysCustom(currentDate, 2))
        setformBody({ ...formBody, snoozingTime: type.value })
        break;
      case 'SEVEN_BUSINESS_DAY':
        setSelectedDateFunc(addBusinessDaysCustom(currentDate, 7))
        setformBody({ ...formBody, snoozingTime: type.value })
        break;
      case 'OTHER':
        setSelectedDate('')
        setformBody({ ...formBody, snoozingTime: type.value })
        break;
      default:
        break;
    }
  }

  const getTimeFrames = () => {
    const defaultTimes = [
      {
        value: 'HALF_BUSINESS_DAY',
        text: '4 hours'
      },
      {
        value: 'ONE_DAY',
        text: '1 business day'
      },
      {
        value: 'TWO_DAY',
        text: '2 business days'
      },
      {
        value:'OTHER',
        text: 'Other'
      }
    ]
    activeRole?.role === "Clinical Coordinators" && defaultTimes.splice(3, 0, {
      value: 'SEVEN_BUSINESS_DAY',
      text: '7 business days'
    });
    return defaultTimes;
  }

  return (
    <><EQHErrorToast errors={error} handleClose={() => setError([])} /><Modal
      size="md"
      centered
      show={snoozedModal}
      onHide={() => toggleSnoozedModal(false)}
      backdrop={false}
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-snoozedModalTitle`}>
          Snooze Care Step - {step?.title}
        </Modal.Title>
        <button
          id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleSnoozedModal(false);
            setformBody({});
          }} />
      </Modal.Header>
      <ModalBody style={{ minHeight: '500px' }}>
        <DropdownComponent
          name="Reason"
          list={reasons.map(i => ({ value: i.value, text: i.text }))}
          inputProps={{
            id: `${componentName}-SnoozedReasons`
          }}
          handleInputChange={(_, type) => setformBody({ ...formBody, reason: type.value })}
          label="Reason for Snoozing"
          required={true}
          isOpen={true}
          val={formBody.reason} />
        <Row>
          <Col>
            <DropdownComponent
              name="Snoozing Time"
              list={getTimeFrames().map(i => ({ value: i.value, text: i.text }))}
              inputProps={{
                id: `${componentName}-SnoozedTime`
              }}
              handleInputChange={(value, type) => handleDate(type, value)}
              label="Snoozing Time"
              required={true}
              isOpen={true}
              val={formBody.snoozingTime} />
          </Col>
          <Col>
            <Form.Label className="fw-bold">
              New Date<RedAsterisk />
            </Form.Label>
            <DatePicker
              disabled={formBody.snoozingTime !== 'OTHER'}
              name="dueDate"
              filterTime={filterPassedTime}
              value={selectedDate}
              selected={selectedDate}
              onChange={(e) => setSelectedDateFunc(e)}
              showTimeSelect
              className="form-control"
              minDate={new Date()}
              maxDate={new Date().setDate(new Date().getDate() + 7)}
              minTime={setHours(setMinutes(new Date(), 0), 6)}
              maxTime={setHours(setMinutes(new Date(), 30), 18)}
              autoComplete="off"
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<CustomInput />} />
          </Col>
          <Row>
            <Form.Label className="fw-bold">
              Additional Notes
            </Form.Label>
            <Form.Control
              as="textarea"
              onChange={e => setformBody({ ...formBody, notes: e.target.value })}
              value={formBody.notes}
              id={`${componentName}-carePathNotesBox-${0}`}
              rows={3}
              className="mt-2"
              style={{ width: '550px' }} />
          </Row>
        </Row>
      </ModalBody>
      <Modal.Footer>
        <Button
          id={`${componentName}-cancelSnoozed`}
          variant="secondary"
          onClick={() => {
            toggleSnoozedModal(false);
            setformBody({});
          }}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-saveSnoozed`}
          variant="primary"
          disabled={!formBody?.reason || !formBody?.snoozingTime || (formBody.snoozingTime === 'OTHER' && !formBody?.newDueDate)}
          onClick={() => snoozeStep()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal></>
  )
}

export default SnoozedModal