import React from "react";
import { Col, Dropdown } from "react-bootstrap";

const componentName = "DropDownMenu"

const DropDownMenu = ({ idx, onItemClick, items }) => {
  return (
    <Dropdown
      id={`${componentName}-dropDown-${idx}`}
      key={`${componentName}-dropDown-key-${idx}`}
    >
      <Dropdown.Toggle
        variant="link"
        id={`${componentName}-menuToggle-${idx}`}
        key={`${componentName}-menuToggle-key-${idx}`}>
        <button
          id={`${componentName}-actionIcon`}
          type="button"
          className="btn btn-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa-solid fa-ellipsis border-0 text-dark"></i>
        </button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        id={`${componentName}-dropDownMenu-${idx}`}
        key={`${componentName}-dropDownMenu-key-${idx}`}
        style={{ maxHeight: '250px' }}
        className='dropdown-menu-aligned'
      >
        {items && items.map((item, itemIdx) => {
          return (
            <>
              {item.hasDivider &&
                <Dropdown.Divider
                  id={`${componentName}-itemDivider-${itemIdx}`}
                  key={`${componentName}-itemDivider-${item.value}`}
                />
              }
              <Dropdown.Item
                id={`${componentName}-item-${itemIdx}`}
                key={`${componentName}-${item.value}-${itemIdx}`}
                onClick={() => { onItemClick(item); }}
                disabled={item?.disabled}
                {...item?.extra}
              >{item.text}</Dropdown.Item>
            </>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropDownMenu;