import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';

const componentName = 'PatientAlreadyRemovedWarning';

function PatientAlreadyRemovedWarning() {
  const {
    patientAlreadyRemoved,
    togglePatientAlreadyRemoved,
    setPatientSearch,
    patientSearch,
    selectedPatientsEvents,
    toggleIsBulk
  } = useCMWorklistContext();

  const refreshAction = () => {
    togglePatientAlreadyRemoved(false);
    setPatientSearch({ ...patientSearch, pageNumber: 1, reload: !patientSearch?.reload });
    toggleIsBulk(false);
  }

  return (
    <Modal
      id={`${componentName}-patientAlreadyRemovedWarningConfirmModal`}
      size="md"
      show={patientAlreadyRemoved}
      onHide={() => togglePatientAlreadyRemoved(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-patientAlreadyRemovedWarningConfirmTitle`}>
          Remove from Worklist
        </Modal.Title>
        <button
          id={`${componentName}-patientAlreadyRemovedWarningConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => togglePatientAlreadyRemoved(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-4 bg-light p-3 rounded-3">
          <Col>
            <Row className="mb-3">
              <Form.Group id={`${componentName}-patient`} className="d-flex flex-column">
                <Form.Label id={`${componentName}-patientLabel`}>
                  Patient Name
                </Form.Label>
                {
                  selectedPatientsEvents?.map((patient, idx) =>
                    <span
                      id={`${componentName}-patientValue`}
                      className="forced-capital"
                      key={`${patient?.eventId}-${idx}`}
                    >{patient?.data?.fullName}</span>)
                }
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              One or more patients don't longer exist in the Worklist.
              Please refresh to see the latest updates.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id={`${componentName}-patientAlreadyRemovedWarningNoBtn`}
          variant="secondary"
          onClick={() => togglePatientAlreadyRemoved(false)}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-patientAlreadyRemovedWarningYesBtn`}
          variant="primary"
          onClick={() => refreshAction()}>
          Refresh Worklist
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PatientAlreadyRemovedWarning;