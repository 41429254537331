import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {useNewRRContext} from "./context/NewRR.context";
import {FilterGroupNew} from "../../../components/filters/filterGroupNew";
import {getFilterDef} from "../../../components/filters/filterDefinition";
import {defaultSortParams, filters, sortItems} from "./const";
import CustomPagination from "../../common/CustomPaginaton";
import PatientDataCard
  from "../../caremgmt/worklist/components/PatientDataCard";
import FullName from "./columns/FullName";
import Practice from "./columns/Practice";
import DateReceived from "./columns/DateReceived";
import './styles.css'
import {SelectAll} from "./components/SelectAll";
import GeneralActions from "./components/GeneralActions";
import SortMenu from "./components/SortMenu";
import Urgency from "./columns/Urgency";
import {useCustomWorklistContext} from "../../../components/CustomWorklist";
import {getQuickListId} from "../../../utils/util";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {useAuth} from "../../../context/authContext";
import {mapFiltersSelected} from "./FiltersMapper";

const componentName = 'NewRR'

const Main = () => {
  const auth = useAuth()
  const [sortParams, setSortParams] = useState(defaultSortParams)
  const [activeFilter, setActiveFilter] = useState(0)
  const [quickListId, setQuickListId] = useState(null)
  const location = useLocation()
  const activeRole = {
    leadId: null,
    leadName: null,
    role: auth.getRoles()[0],
    custom: false
  }

  const {
    referrals,
    loading,
    referralsSelected,
    setReferralsSelected,
    clearSelected,
    referralsSearch,
    setReferralsSearch
  } = useNewRRContext();

  const {
    getCustomWorklist,
    patientSearchCustomWorklist
  } = useCustomWorklistContext();

  useEffect(() => {
    setQuickListId(getQuickListId())
  }, [location])

  useEffect(() => {
    if (getQuickListId() && quickListId) {
      getCustomWorklist(quickListId, activeRole);
    } else if (!getQuickListId() && !quickListId) {
      setReferralsSearch({timestamp: new Date().getTime()});
    }
  }, [quickListId])

  useEffect(() => {
    if (patientSearchCustomWorklist && patientSearchCustomWorklist.type) {
      setReferralsSearchAction({...patientSearchCustomWorklist});
    }
  }, [patientSearchCustomWorklist])

  const getFilterParams = () => {
    return referralsSearch?.filterParams ?? [];
  }

  const setReferralsSearchAction = (params) => {
    setReferralsSearch({...referralsSearch, ...params, timestamp: new Date().getTime()})
  }

  const filterParams = getFilterParams();

  const getFilteredReferrals = (type) => {
    clearSelected();

    const filters = filterParams.filter(
      filter => filter.paramName !== 'Referral Status');

    if (type === 'open') {
      setActiveFilter(1)
      const param = {
        "paramName": "Referral Status",
        "paramValue": [
          "40_ACCEPTED",
          "50_PENDING",
          "TREATMENT_IN_PROGRESS"
        ],
        "comparison": "eq",
        "displayValue": [
          "Accepted",
          "Pending",
          "Treatment in progress"
        ]
      }
      setReferralsSearchAction({
        pageNumber: 1,
        filterParams:  [...filters, param]
      })
    } else if (type == 'all') {
      setActiveFilter(0)
      setReferralsSearchAction(
        {pageNumber: 1, filterParams: filters})
    } else if (type == 'closed') {
      setActiveFilter(2)
      const param = {
        "paramName": "Referral Status",
        "paramValue": [
          "60_DECLINED",
          "30_COMPLETED",
          "20_PATIENT_CANCELLED",
          "10_CANCELLED"
        ],
        "comparison": "eq",
        "displayValue": [
          "Declined",
          "Completed",
          "Patient Cancelled",
          "Cancelled"
        ]
      }
      setReferralsSearchAction({
        pageNumber: 1,
        filterParams: [...filters, param]
      })
    }
  }

  const FilterBar = () => {
    return (
      <Col className="mx-4">
        <Row className="filterBar flex-nowrap">
          <Col className="col-md-auto p-0">
            <Button
              variant='light'
              className={`border rounded-0 rounded-start ${activeFilter === 0
                ? 'custom-active' : ''}`}
              onClick={() => getFilteredReferrals('all')}
            >All</Button>
          </Col>
          <Col className="col-md-auto p-0">
            <Button
              variant='light'
              className={`border rounded-0  ${activeFilter === 1
                ? 'custom-active' : ''}`}
              onClick={() => getFilteredReferrals('open')}
            >Open</Button>
          </Col>
          <Col className="col-md-auto p-0">
            <Button
              variant='light'
              className={`border rounded-0 rounded-end  ${activeFilter === 2
                ? 'custom-active' : ''}`}
              onClick={() => getFilteredReferrals('closed')}
            >Closed</Button>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <Col id={`${componentName}-view`} className="ms-4 me-4">
      <Row id={`${componentName}-header`} className="mb-2 mt-3">
        <Col className="col-md-auto">
          <SelectAll/>
        </Col>
        <Col className="ps-0">
          <Row>
            <Col className="col-md-auto">
              <FilterGroupNew
                filters={filters.map(f => getFilterDef(f))}
                handleChange={newFiltersParams => {
                  clearSelected();
                  setReferralsSearchAction({
                    ...referralsSearch,
                    pageNumber: 1,
                    filterParams: newFiltersParams
                  })
                }}
                defaultFilterParams={[]}
                filterParams={mapFiltersSelected(filterParams)}
                extraFilters={<FilterBar/>}
              />
            </Col>
          </Row>
          <span
            bg="light"
            id={`${componentName}-patientCount`}
            className="border text-dark badge badge-pill"
          >
            {referrals?.totalSize}{" "}{"Patients"}
          </span>
        </Col>
        <Col className="p-0 text-start text-sm-end pe-3 m-0 col-md-auto">
          <Row className="align-items-center">
            <Col className="pe-0 ps-0" style={{maxWidth: '80px'}}>
              <div className="d-inline-block ms-0">
                <SortMenu
                  items={sortItems}
                  filterParams={filterParams}
                  sortParams={sortParams}
                  setSortParams={setSortParams}
                  refreshPatientList={(...params) => {
                    clearSelected();
                    setReferralsSearchAction({
                      ...referralsSearch,
                      pageNumber: params[0],
                      filterParams: filterParams,
                      sortParams: params[2]
                    })
                  }}
                />
              </div>
            </Col>
            <Col style={{maxWidth: '64px'}}>
              <div className="d-inline-block align-top dropdown">
                <GeneralActions
                  id={`${componentName}-menuActions`}
                  key={`${componentName}-menuActions-key`}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <div className="d-inline-block ms-0 ms-sm-2">
          <CustomPagination
            paginateButClicked={i => setReferralsSearchAction({
              pageNumber: i,
              filterParams: filterParams,
              sortParams: sortParams
            })}
            MAX_PAGE={Math.ceil(referrals?.totalSize / referrals?.pageSize)}
            pageNumber={referrals?.pageNumber}
          />
        </div>
      </Row>
      <Row id={`${componentName}-body`} className="align-items-center">
        {loading
          ? <Col>
            <Spinner
              className="align-middle mb-4"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> </Col>
          : referrals?.patientCardDtos?.length > 0
            ? referrals?.patientCardDtos?.map((referral, idx) =>
              <>
                <PatientDataCard
                  idx={idx}
                  className="ms me"
                  id={`${componentName}-patientCard-${idx}`}
                  key={`${componentName}-patientCard-key-${idx}`}
                >
                  <Col id={`${componentName}-${idx}`}
                       className="col-md-auto px-4">
                    <Row>
                      <Form.Check
                        type="checkbox"
                        id={`${componentName}-createCarePathCheckbox`}
                        onChange={() => setReferralsSelected(
                          [referral?.id, referral?.referralDtoList?.[0]?.id])}
                        checked={referralsSelected.map(r => r[1]).includes(
                          referral?.referralDtoList?.[0]?.id)}
                      />
                    </Row>
                  </Col>
                  <FullName referral={referral} idx={idx}/>
                  <Practice referral={referral} idx={idx}/>
                  <Urgency referral={referral} idx={idx}/>
                  <DateReceived referral={referral} idx={idx}/>
                </PatientDataCard>
              </>
            ) : <PatientDataCard>No Patients Found</PatientDataCard>
        }
      </Row>
    </Col>
  );
};

export default Main;
