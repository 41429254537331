import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import CalendarPagination, {
  CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE,
  CARD_VIEW_PAGINATION_TYPE
} from "../appointment/CalendarPagination";
import { formatTimeOnly } from "../../utils/util";
import isEmpty from "is-empty";
import styled from "styled-components";
import { WEEKLY_VIEW } from "../caremgmt/CareTeam";

const componentName = "CalendarDaysView";
const DAY_START_HOUR = 7;
const DAY_END_HOUR = 20;
const DAY_STEP_HOUR = 0.5;


const CalendarDaysView = ({
  appointmentList,
  searchDate,
  onSearchDateChange,
  setSearchDate,
  isLoadingAppointment,
  viewType,
  updateAppointment,
  todayButton = false,
}) => {

  const [rows, setRows] = useState([])
  const [heads, setHeads] = useState([])

  const findAppointmentsByTime = (appointments, timeInfo) => {
    if (appointments) {
      const appointmentsFound = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.appointmentDateTime)
        const dateHour = appointmentDate.getHours()
        const appointmentHour = dateHour % 12 || 12
        const appointmentMinutes = appointmentDate.getMinutes()
        const appointmentPeriod = dateHour >= 12 ? "pm" : "am"
        return timeInfo.hour === appointmentHour && timeInfo.minutes === appointmentMinutes && timeInfo.period === appointmentPeriod
      })

      if (!isEmpty(appointmentsFound)) {
        return appointmentsFound
      }
      return [{}]
    }
    return [{}]
  }

  const buildDayAppointments = (appointments) => {
    let dayAppointments = []
    for (let time = DAY_START_HOUR; time < DAY_END_HOUR; time += DAY_STEP_HOUR) {
      const timeInfo = getHourMinutesPeriod(time)
      const appointmentsFound = findAppointmentsByTime(appointments, timeInfo)
      const timeLabel = getTimeLabel(timeInfo)
      dayAppointments.push({
        time: timeLabel,
        appointments: appointmentsFound,
        isTimeVisible: timeInfo.minutes < 30
      })
    }

    return dayAppointments
  }

  const getTimeLabel = (timeInfo) => {
    if (timeInfo.minutes > 0) {
      return `${timeInfo.hour}:${timeInfo.minutes}${timeInfo.period}`
    }
    return `${timeInfo.hour}${timeInfo.period}`
  }

  const getHourMinutesPeriod = (time) => {
    const twelve = 12
    let hour = time < 1 ? twelve : ~~time
    let minutes = 0
    let period = "am"
    if (time >= twelve) {
      const hourMod = time % twelve
      hour = hourMod < 1 ? twelve : ~~hourMod
      period = "pm"
    }
    if (time % 1 > 0) {
      minutes = 30
    }

    return { hour, minutes, period }
  }

  const formatDate = (date) => {
    const currentDate = new Date(date.replace("-", "\/"))
    const dayName = currentDate.toLocaleString('en-us', { weekday: "short" })
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    return `${dayName} ${month}/${day}`
  }

  const handlePaginationChange = (date) => {
    onSearchDateChange(date);
  };

  const getAppointmentLabel = (appointment) => {
    const name = appointment.patientId ? `${appointment.patientLastName}, ${appointment.patientFirstName} ${appointment.patientMiddleName}` : ""
    const startTime = formatTimeOnly(new Date(appointment.startTime))
    const endTime = formatTimeOnly(new Date(appointment.endTime))
    const label = [appointment.appointmentName, name, `From ${startTime} to ${endTime}`]

    return label.filter(Boolean).join(" - ")
  }

  const createRow = (dayInfo) => {
    return {
      time: dayInfo.time,
      daysAppointments: [dayInfo.appointments],
      isTimeVisible: dayInfo.isTimeVisible
    }
  }

  const addAppointmentsToRow = (currentRow, newAppointments) => {
    return {
      ...currentRow,
      daysAppointments: [
        ...currentRow.daysAppointments,
        newAppointments
      ]
    }
  }

  useEffect(() => {
    let rows = []
    let heads = []

    appointmentList.forEach(day => {
      const dayAppointments = buildDayAppointments(day.appointments)
      dayAppointments.forEach(day => {
        let rowIndex = rows.findIndex(rowInfo => rowInfo.time === day.time)
        if (rowIndex > -1) {
          rows[rowIndex] = addAppointmentsToRow(rows[rowIndex], day.appointments)
        } else {
          rows.push(createRow(day))
        }
      })
      heads.push(day.date)
    })

    setRows(rows)
    setHeads(heads)
  }, [appointmentList])


  return (
    <>
      <Row className="mb-3">
        <Col>
          <CalendarPagination
            type={viewType === WEEKLY_VIEW ? CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE : CARD_VIEW_PAGINATION_TYPE}
            dateObject={searchDate}
            setDateObject={setSearchDate}
            paginateButtonClicked={handlePaginationChange}
            todayButton={todayButton}
          />
        </Col>
      </Row>
      <StyledCalendarDiv className="table-responsive table-bordered">
        {!isLoadingAppointment && (
          <table className="table table-bordered" id="dailyView-calendar-table">
            <thead className="table-light">
              <tr>
                <th scope="col" className="calendar-time day-name" />
                {heads.map((head, index) =>
                  <th scope="col" className="day-name" id={`${componentName}-head-${index}`} key={`${componentName}-head-${index}`}>
                    {formatDate(head)}
                  </th>)}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={`${componentName}-dailyViewHour-${index}`} id={`${componentName}-dailyViewHour-${index}`}>
                  <td scope="row" className="calendar-time" id={`${componentName}-time-${index}`}>
                    {row.isTimeVisible ? row.time : "\u00A0"}
                  </td>
                  {row.daysAppointments.map((day, indexDayApp) => (
                    <StyledCalendarTd id={`${componentName}-calendarAppointmentDay-${row.time}-${indexDayApp}`}
                      key={`${componentName}-calendarAppointmentDay-${row.time}-${indexDayApp}`}>
                      {day.map((appointment, indexApp) => (
                        <Badge variant="light" id={`${componentName}-calendarAppointment-${row.time}-${indexApp}-${indexDayApp}`}
                          className="border fs-6 text-wrap">
                          {!isEmpty(appointment) && (
                            <a className="p-0" onClick={() => updateAppointment(appointment)}>
                              {getAppointmentLabel(appointment)}
                            </a>
                          )}
                        </Badge>))}
                    </StyledCalendarTd>
                  ))}
                </tr>))}
            </tbody>
          </table>
        )}
      </StyledCalendarDiv>
    </>
  );
};

export default CalendarDaysView;

const StyledCalendarTd = styled.td`
   max-width: 7rem;
   min-width: 7rem;
   overflow: auto;
`;

const StyledCalendarDiv = styled.div`
   padding: 1rem 1rem;
`;