export function getGetters(state) {
    return {
        showAccepted: state.showAccepted,
        showCancelled: state.showCancelled,
        showDeclined: state.showDeclined,
        showPending: state.showPending,
        showCompleted: state.showCompleted,
        showPatientCancelled: state.showPatientCancelled,
        query: state.query,
        referral: state.referral,
        referralsList: state.referralsList,
        totalSize: state.totalSize
    }
}