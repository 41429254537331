import React from "react";
import { Row, Col, Form, Badge } from "react-bootstrap";
import PatientDetailsFieldForm from './PatientDetailsFieldForm';
import { getPatientStatusStyle, cleanPatientInfo, isArrayNonEmpty, formatAddress } from '../utils';
import {
  usePatientDetailsContext
} from "./PatientDetailsModals/context/PatientDetails.context";

const componentName = "PersonalInformation";

/**
 * PersonalInformation component displays personal details of a patient.
 * It includes logic to format addresses and emails, and to annotate primary fields.
 *
 * @param {Object} patientInfo - Object containing the patient's personal information.
 * @returns {JSX.Element} A section of the patient details view with formatted personal information.
 */
const PersonalInformation = ({ patientInfo }) => {

  const {
    active,
    primaryPhone,
    detailsPhones,
    address,
    secondaryAddress,
    email,
    detailsEmails,
    dateOfBirth,
    gender,
    primaryLanguage,
    detailsOtherLanguages,
  } = cleanPatientInfo(componentName, patientInfo);

  const {
    toggleEditPatientDetailsEnabled
  } = usePatientDetailsContext();

  /**
 * Appends the string " (preferred)" to the primary value if both primary and secondary values are present.
 *
 * @param {string} primary - The primary value to potentially annotate as preferred.
 * @param {string} secondary - A secondary value that triggers the preferred annotation on the primary value.
 * @returns {string} The primary value, possibly annotated with " (preferred)".
 */
  const getPrimaryPreferredFieldAddition = (primary, secondary) => {
    if (primary && secondary) {
      return `${primary} (preferred)`;
    }

    return primary;
  }

  /**
   * Retrieves the primary and alternative text fields from a list of details, appending "(preferred)" as appropriate.
   * The primary field is always returned, with "(preferred)" appended if there are multiple details but none preferred.
   * The alternative field is the preferred detail found in the list, or the first detail if none is preferred.
   * If there are not other details, the primary is returned without a suffix.
   * 
   * @param {Object[]} details - An array of objects containing 'detail' and 'preferred' properties.
   * @param {string} primaryField - The primary field value, which is used as a fallback if no details are available.
   * @returns {Object} An object containing 'primary' and 'alternative' properties with their respective values.
   */
  const getPreferredFieldText = (details, primaryField) => {
    let primary = primaryField || "";
    let alternative = "";
  
    if (details && details.length > 0) {
      const preferredDetail = details.find(detail => detail.preferred);

      if (preferredDetail) {
        alternative = `${preferredDetail.detail} (preferred)`;
      } else {
        alternative = details[0].detail;
        primary = primary ? `${primary} (preferred)` : "";
      }
    }

    return { primary, alternative };
  };

  const patientStatusStyle = getPatientStatusStyle(active);
  const { primary: primaryPhoneValue, alternative: altPhoneValue } = getPreferredFieldText(detailsPhones, primaryPhone);
  const { primary: primaryLanguageValue, alternative: altLanguageValue } = getPreferredFieldText(detailsOtherLanguages, primaryLanguage);
  const { primary: primaryEmailValue, alternative: altEmailValue } = getPreferredFieldText(detailsEmails, email);
  const secondaryAddressValue = formatAddress(secondaryAddress);
  const addressValue = getPrimaryPreferredFieldAddition(address, secondaryAddressValue);

  return (
    <Form>
      <Row>
        <Col>
          <p className="fs-6 fw-bold">Personal Information</p>
        </Col>
        <Col>
          <div className="d-flex justify-content-end align-items-center mb-1 position-relative">
            <Badge bg="" id={`${componentName}-`} pill style={{ ...patientStatusStyle }} className="me-3"
            >{patientStatusStyle.value}</Badge>
            <i className="fa-thin fa-pen cursor-p" onClick={() => toggleEditPatientDetailsEnabled(true)}></i>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <PatientDetailsFieldForm elementPrefix="primaryPhone" textLabel="PHONE" textValue={primaryPhoneValue} />
          {altPhoneValue && <PatientDetailsFieldForm elementPrefix="altPhone" textLabel="ALT PHONE" textValue={altPhoneValue} />}
          <PatientDetailsFieldForm elementPrefix="address" textLabel="ADDRESS" textValue={addressValue} />
          {secondaryAddressValue && <PatientDetailsFieldForm elementPrefix="altAddress" textLabel="ALT ADDRESS" textValue={secondaryAddressValue} />}
          <PatientDetailsFieldForm elementPrefix="email" textLabel="EMAIL" textValue={primaryEmailValue} />
          {altEmailValue && <PatientDetailsFieldForm elementPrefix="altEmail" textLabel="ALT EMAIL" textValue={altEmailValue} />}
        </Col>
        <Col>
          <PatientDetailsFieldForm elementPrefix="dateOfBirth" textLabel="DATE OF BIRTH" textValue={dateOfBirth} />
          <PatientDetailsFieldForm elementPrefix="gender" textLabel="GENDER" textValue={gender} />
          <PatientDetailsFieldForm elementPrefix="language" textLabel="LANGUAGE" textValue={primaryLanguageValue} />
          {altLanguageValue && <PatientDetailsFieldForm elementPrefix="altLanguage" textLabel="ALT LANGUAGE" textValue={altLanguageValue} />}
        </Col>
      </Row>
    </Form>
  );
}

export default PersonalInformation;
