import {FullNameColumn, RiskColumn, SourceColumn, StatusColumn, ZoneColumn} from "./columns"

export const FULL_NAME_COLUMN = "fullName"
export const RISK_COLUMN = "risk"
export const SOURCE_COLUMN = "source"
export const ZONE_COLUMN = "zone"
export const STATUS_COLUMN = "status"

export const getColumnByName = (columnName) => {
  const columnDefinition = worklistColumns.filter(column => column.name === columnName);
  if (columnDefinition && columnDefinition.length > 0) {
    return columnDefinition.pop().component;
  }
  return null;
}

const worklistColumns = [
  {
    name: FULL_NAME_COLUMN, component: FullNameColumn,
  },
  {
    name: RISK_COLUMN, component: RiskColumn
  },
  {
    name: SOURCE_COLUMN, component: SourceColumn
  },
  {
    name: STATUS_COLUMN, component: StatusColumn
  },
  {
    name: ZONE_COLUMN, component: ZoneColumn
  }
]
