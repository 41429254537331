import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import CustomPagination from "../../common/CustomPaginaton";
import { UserModalForm } from "./UserModalForm";
import Modals from "../../../components/modals";
import SearchUsers from "./SearchUsers";
import UpdateUser from "./UpdateUser";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import ErrorToast from "../../common/ErrorToast";
import { theme } from "../../../components/themes/theme1";

import {
  getUsers,
  createUser,
  updateUser,
  search,
  getValues,
  activateDeactivate,
  updateMultipleUser,
} from "../../../actions/admin/userManagementActions";
import { useAuth } from "../../../context/authContext";
import { newUser } from "../../../utils/Models";
import styled, { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import store from "../../../store";
import isEmpty from "is-empty";
import { clientToStateMap } from "../../../utils/textValueLists";

const componentName = "UserManagement";
const UserManagementContainer = styled.div` `;

export const UserManagement = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const {
    currentUsers,
    userCreateDropDownValues,
    userActivatedDeactivated,
    pageNumber,
    totalSize,
    pageSize,
    loading,
  } = useSelector((state) => state.userManagement);

  const roles =
    userCreateDropDownValues && userCreateDropDownValues.userRoles
      ? userCreateDropDownValues.userRoles
      : [];
  const organizations =
    userCreateDropDownValues && userCreateDropDownValues.organizations
      ? userCreateDropDownValues.organizations
      : [];
  const accessTypes =
    userCreateDropDownValues && userCreateDropDownValues.accessTypes
      ? userCreateDropDownValues.accessTypes
      : [];
  const clients =
    userCreateDropDownValues && userCreateDropDownValues.clients
      ? userCreateDropDownValues.clients
      : [];

  const [modalShow, setModalShow] = useState(false); //Toggles Modal Display
  const [modalType, setModalType] = useState("ADD USER"); // Value Changes By Which Btn Is Clicked "ADD USER" Or "UPDATE USER"
  const [sortedUsers, setSortedUsers] = useState(null); // Handles Displayed Users, As Well As Set The Search Results
  // Data Structure For New User, Needs to Hopefully be Refactored in Future
  const [userData, setUserData] = useState();

  const auth = useAuth();
  const token = auth.getAuthToken();

  const [searchParam, setSearchParam] = useState();
  let [usersToActivateOrD, setUsersToActivateOrD] = useState([]);
  let [enableActDeactButton, setEnableActDeactButton] = useState();
  const [strForConfirmDisplay, setStrForConfirmDisplay] = useState();
  const errors = useSelector((state) => state.errors);
  const [errorToDisplay, setErrorToDisplay] = useState();
  const [sortOrder, setSortOrder] = useState({});

  const [regError, setRegError] = useState({});

  const [showConfirm, setShowConfirm] = useState(false);
  const [newCLients, setNewClients] = useState([]);

  const [showAddUpdateButtons, setShowAddUpdateButtons] = useState(false);

  const handleCloseForConfirmation = () => {
    let action = "";
    if (usersToActivateOrD && usersToActivateOrD.length > 0) {
      if (strForConfirmDisplay === "Activate") {
        action = "activate-users";
      } else if (strForConfirmDisplay === "Deactivate") {
        action = "deactivate-users";
      }
      dispatch(activateDeactivate(usersToActivateOrD, action, token));

      //const prms = { page: pageNumber ? pageNumber : 1, pageSize: pageSize ? pageSize : 1, filters: searchParam };
      //dispatch(search(prms, token));
    }
    setShowConfirm(false);
  };
  const updateStatus = (str) => {
    setStrForConfirmDisplay(str);
    setShowConfirm(true);
  };
  //Use Effects Starts
  useEffect(() => {
    (async function fetchUsers() {
      if (!currentUsers.length && isEmpty(pageSize)) {
        await dispatch(getUsers(token));
      }
      setUsersToActivateOrD([]);
      setSortedUsers(currentUsers);
      //Close the User add/update Modal after update
      setModalShow(false);
    })();
  }, [currentUsers]);

  useEffect(() => {
    (async function fetchData() {
      await dispatch(getValues(token));
    })();
  }, []);

  useEffect(() => {
    if (clients && clients.length) {
      setNewClients(clients.map((e) => ({ "text": clientToStateMap[e], "value": e })))
    }
  }, [clients]);

  useEffect(() => {
    if (
      !isEmpty(userCreateDropDownValues) &&
      roles.length &&
      organizations.length &&
      accessTypes.length &&
      clients.length
    ) {
      setShowAddUpdateButtons(true);
    }
  }, [userCreateDropDownValues]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      let errorMessage;
      if (errors && typeof errors == "string") {
        errorMessage = errors;
      } else if (errors && errors.message) {
        errorMessage = errors.message;
      } else {
        errorMessage = null;
      }
      setErrorToDisplay(errorMessage);
    }
  }, [errors]);

  //Use Effects Ends

  //Sets Modal Data, Type, & Displays
  const handleModal = (str, user = newUser) => {
    setErrorToDisplay(null);
    setModalType(str);
    setUserData(user);
    setModalShow(true);
  };

  const setSaveStatus = async () => {
    let errorMessage = "";
    const state = store.getState();
    const errors = state.errors;
    if (isEmpty(state.errors)) {
      setModalShow(false);
      setUserData({ ...userData, newUser });
    }
  };
  const handleCreation = async () => {
    let updatedUserObj;
    if (userData && userData.identifier && userData.accessType) {
      const identifier = {};
      const arrIdentifier = userData.identifier.split(",");
      identifier[userData.accessType] = arrIdentifier;
      updatedUserObj = { ...userData, identifiers: identifier };
    } else {
      updatedUserObj = { ...userData, identifiers: null };
    }
    if (validateForm(updatedUserObj, modalType)) {
      setErrorToDisplay("Please select all the required fields");
    } else {
      if (modalType === "ADD USER") {
        dispatch(createUser(updatedUserObj, token));
      }
      if (modalType === "UPDATE USER") {
        dispatch(updateUser(updatedUserObj, token));
      }
      if (modalType === "BULK UPDATE USER") {
        const prms = { userAccountIds: usersToActivateOrD, ...updatedUserObj };
        dispatch(updateMultipleUser(prms, token));
        //setUsersToActivateOrD([]);
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));
      const prms = { page: pageNumber, pageSize: 10, filters: searchParam };
      dispatch(search(prms, token));
      await new Promise((resolve) => setSaveStatus(resolve));

    }
  };

  const validateForm = (updatedUserObj, modalType) => {
    let bulkValidation =
      isEmpty(updatedUserObj.accessType) ||
     (!updatedUserObj.accessType === 'NETWORK' && isEmpty(updatedUserObj.identifier)) ||
      isEmpty(updatedUserObj.client) ||
      updatedUserObj.client.length == 0 ||
      (isEmpty(updatedUserObj.organization) || isEmpty(updatedUserObj.organization.id)) ||
      isEmpty(updatedUserObj.userRole.id);
    if (modalType === "BULK UPDATE USER") {
      return bulkValidation;
    } else {
      return (
        bulkValidation ||
        isEmpty(updatedUserObj.firstName) ||
        isEmpty(updatedUserObj.lastName) ||
        isEmpty(updatedUserObj.email)
      );
    }
  };

  // Handles Chnage of userData
  // Due To The Nested Objects && Arrays Of Objects The Setting Of userData's State Is More Ribose
  const handleUserFormChange = (e) => {
    if (e && e.paramName && e.paramName == 'CLIENTS') {
      setUserData({
        ...userData,
        client: e.paramValue,
      });
    }
    else {
      const { name, value } = e.target;
      if (name && e && e.error) {
        regError[name] = e.error
      }
      else {
        delete regError[name]
      }
      if (
        name === "firstName" ||
        name === "lastName" ||
        name === "email" ||
        name === "npi" ||
        name === "accessType" ||

        name === "accountActive" ||
        name === "identifier" ||
        name === "organization"
      ) {
        setUserData({
          ...userData,
          [name]: value,
        });
      }
      if (name === "userRole") {
        //const copy = userData.userRole;
        const obj = JSON.parse(value);
        setUserData({
          ...userData,
          [name]: {
            id: obj.id,
            name: obj.name,
          },
        });
      }
    }
  };
  const sortCol = (att, desc, page = 1) => {
    const prm = {
      page,
      pageSize: 10,
      filters: searchParam,
      sortingCriteria: [
        {
          sortAttribute: att,
          desc: desc,
        },
      ],
    };
    dispatch(search(prm, token));
  };

  const searchTable = (params) => {
    const isEmpty = Object.values(params).every((x) => x === "");
    let filters = [];
    if (isEmpty) {
      // setSortedUsers(currentUsers);
    } else {
      for (let [key, value] of Object.entries(params)) {
        if (value.length && key != "roleName") {
          filters.push({
            fieldName: key,
            value: value,
            comparisonMode: "EQUAL_TO",
          });
        }
      }
    }
    const prms = { page: 1, pageSize: 10, filters: filters };
    setSearchParam(filters);
    dispatch(search(prms, token));
    setSortOrder({});
  };

  const paginate = (i) => {
    if (i) {
      const prms = { page: i, pageSize: 10, filters: searchParam };

      if (!sortOrder?.el) {
        dispatch(search(prms, token));
      } else {
        sortCol(sortOrder.el, sortOrder.so, i)
      }
    }
  };

  const handleChangeCheckBox = (e, userAccountId) => {
    const { checked } = e.target;
    // let arr = [];
    if (checked && !usersToActivateOrD.includes(userAccountId)) {
      usersToActivateOrD.push(userAccountId);
    } else {
      var index = usersToActivateOrD.indexOf(userAccountId);
      usersToActivateOrD.splice(index, 1);
    }
    // setUsersToActivateOrD(arr);
    if (usersToActivateOrD.length > 0) {
      setEnableActDeactButton(true);
    } else {
      setEnableActDeactButton(false);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Row className="p-3">
        <Col>
          <Modals
            show={modalShow}
            onHide={() => setModalShow(false)}
            onCreate={handleCreation}
            title={modalType}
            titleid={`${componentName}`}
            size="xl"
            hideFooter={false}
            completeBtn={"SAVE CHANGES"}
            completeBtnId={`${componentName}-completeButton`}
            closeBtnId={`${componentName}-closeButton`}
            disabledClickButton={!isEmpty(regError)}
            body={
              <UserModalForm
                type={modalType}
                handleChange={handleUserFormChange}
                user={userData}
                roles={roles}
                organizations={organizations}
                accessTypes={accessTypes}
                clients={newCLients}
                closeBtnId={`${componentName}-closeButton`}
                regError={regError}
              />
            }
          />
          <SearchUsers
            submitSearchPrameters={searchTable}
            roles={roles}
            accessTypes={accessTypes}
          />
          {loading && isEmpty(errors) && <ProgressBar animated now={95} />}

          <Modals
            show={!isEmpty(errorToDisplay)}
            onHide={() => setErrorToDisplay(null)}
            title={<span>Error</span>}
            titleid={`${componentName}-error`}
            size="md"
            hideClickButton={true}
            hideFooter={false}
            body={<Alert variant="danger">{errorToDisplay}</Alert>}
          />

          {!isEmpty(sortedUsers) && sortedUsers.length > 0 && (
            <UpdateUser
              updateStatus={updateStatus}
              handleModal={handleModal}
              users={sortedUsers}
              handleChangeCheckBox={handleChangeCheckBox}
              showAddUpdateButtons={showAddUpdateButtons}
              enableActDeactButton={enableActDeactButton}
              roles={roles}
              sortCol={sortCol}
              setSortOrder={setSortOrder}
            />
          )}

          {isEmpty(sortedUsers) && (
            <UserManagementContainer>
              {" "}
              <Spinner animation="grow" />
            </UserManagementContainer>
          )}

          {!isEmpty(sortedUsers) && Math.ceil(totalSize / pageSize) > 0 && (
            <CustomPagination
              paginateButClicked={paginate}
              MAX_PAGE={Math.ceil(totalSize / pageSize)}
              pageNumber={Math.ceil(pageNumber)}
            />
          )}

          <Modals
            show={showConfirm}
            hideFooter={false}
            onHide={() => setShowConfirm(false)}
            onCreate={handleCloseForConfirmation}
            title={"Confirmation"}
            titleid={`${componentName}-confirmation`}
            size="sm"
            completeBtn={strForConfirmDisplay}
            completeBtnId={`${componentName}-confirmButton`}
            closeBtnId={`${componentName}-closeButton`}
            body={<span>Are you Sure you want to {strForConfirmDisplay} ? </span>}
          />
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export default UserManagement;
