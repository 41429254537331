import React, { useContext } from 'react';
import { Card } from "../../../../../components/cards";
import { Accordion, AccordionContext, useAccordionButton, Button } from "react-bootstrap";

const componentName = "AccordionItem";

function PlusMinusToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
            <i id={`${componentName}-plusMinusIcon-programLeads`}
                className={isCurrentEventKey ? "far fa-minus-circle me-1" : "far fa-plus-circle me-1"}></i>{" "}
            <span id={`${componentName}Title-${eventKey}`} className="fw-bold">{children}</span>
        </Button>
    );
}

function AccordionItem({
    children,
    title,
    componentName,
    currentEventKey,
    eventKey,
}) {
    return (
        <Card className="rounded shadow-sm border-0">
            <Card.Header eventKey={eventKey}>
                <PlusMinusToggle
                    componentName={componentName}
                    currentEventKey={currentEventKey}
                    eventKey={eventKey}
                > {title}
                </PlusMinusToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionItem