import React, {forwardRef, useEffect, useState} from 'react'
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {get, isEmpty} from "lodash";
import StatusMenu from "./StatusMenu";
import {StyledRejectedValidationExclamation} from "../constants";
import styled from "styled-components";
import {formatDateModern, formatReverseDate} from "../../../../utils/util";
import {Filter} from "../../../../components/filters/filterGroup";
import {
  DDLDataMode,
  DDLMode,
  GENERIC_DDL_TYPE
} from "../../../../components/dropDownLists/genericDDL";
import {SimpleDDL} from "../../../../components/dropDownLists/simpleDDL";

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const ProvDropdown = styled.div`
  .dropdown-item {
    white-space: normal;
  }
`;

const componentName = 'activityVisitItem';

function ActivityVisitItem({
  title,
  status,
  date,
  checked = false,
  attestation,
  isRequired = false,
  disabled = false,
  enableA1C = false,
  enableBloodPressure = false,
  enableAttestation = false,
  isDocumentationAndCoding = false,
  requireFileUpload = false,
  conditionType,
  attestationDDLOption = {},
  statuses = [],
  validation,

  a1cWholes,
  a1cDecimal,
  bloodPressureSystolic,
  bloodPressureDiastolic,

  onStatusChange,
  onCheckedChange,
  onDateChange,
  onA1cWholesChange,
  onA1cDecimalChange,
  onBloodPressureSystolicChange,
  onBloodPressureDiastolicChange,
  onAttestationChange,
}) {
  const [currentChecked, setCurrentChecked] = useState(false);
  const [currentAttestationFilterValues, setCurrentAttestationFilterValues] = useState(
    {paramValue: [], displayValue: []});

  useEffect(() => {
    setInitialState();
  }, [checked])

  useEffect(() => {
    setInitialState();
  }, [])

  const setInitialState = () => {
    setCurrentChecked(checked);
    setCurrentAttestationFilterValues(
      attestation?.filterValue ?? {paramValue: [], displayValue: []});
  }

  const DateSelector = forwardRef(({value, onClick}, ref) => (
    <span onClick={onClick} ref={ref}>
      {date && formatDateModern(new Date(date))}
      <i className={"fa-regular fa-calendar ms-3" + (disabled ? ''
        : ' cursor-p')}/>
    </span>
  ));

  const getConditionType = () => {
    if (conditionType === 'RECAPTURED') {
      return "Recaptured"
    }
    return 'Suspected';
  }

  const shouldShowDate = (!isEmpty(date) || currentChecked);

  return (
    <div className={componentName + "-container"}>
      <Row>
        <Col md={1} className="checked">
          <Form.Check type="checkbox" disabled={disabled}>
            <Form.Check.Input
              type="checkbox"
              disabled={disabled}
              checked={currentChecked}
              value={currentChecked}
              onChange={(e) => {
                setCurrentChecked(!currentChecked)
                onCheckedChange && onCheckedChange(!currentChecked);
              }}
            />
          </Form.Check>
        </Col>
        <Col md={6} className="title">
          <StyledRejectedValidationExclamation validation={validation}
                                               status={status}/>
          {title}{isRequired && <span className="text-danger">*</span>}
        </Col>
        <Col md={3} className="justify-content-center align-items-end status">
          <StatusMenu disabled={disabled} status={status}
                      items={statuses?.map(
                        status => ({value: status, text: status}))}
                      onClick={(selectedStatus) => {
                        onStatusChange && onStatusChange(selectedStatus)
                      }}>
          </StatusMenu>
        </Col>
        <Col md={2} className="date">
          {shouldShowDate &&
            <DatePickerStyled>
              <DatePicker
                name="dueDate"
                value={date}
                disabled={disabled}
                onChange={(date) => {
                  onDateChange && onDateChange(formatReverseDate(date))
                }}
                customInput={<DateSelector/>}
                className="form-control"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "0px, 0px"
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "scrollParent"
                  }
                }}
                autoComplete="off"
                dateFormat="MMMM d, yyyy"
              />
            </DatePickerStyled>
          }
        </Col>
      </Row>

      {isDocumentationAndCoding &&
        <Row className="mb-1 my-1">
          <Col md={1} className="checked">
            {/*empty by design*/}
          </Col>
          <Col md={11}>
            <small
              className="fst-italic text-black-50">
                  <span
                    className="fw-bold">{getConditionType()}</span> Condition
            </small>
          </Col>
        </Row>
      }

      {checked && requireFileUpload &&
        <Row className="mb-1 my-1">
          <Col md={1} className="checked">
            {/*empty by design*/}
          </Col>
          <Col md={11} className="my-1">
            <div
              className="fw-bold text-danger document-required-message">
              Required: Upload evidence below.
            </div>
          </Col>
        </Row>
      }

      {(enableA1C || enableBloodPressure || enableAttestation) &&
        <Row className="mb-3 my-3">
          <Col md={1} className="checked">
            {/*empty by design*/}
          </Col>

          {enableA1C &&
            <Col sm={4}>
              <InputGroup className="border-0">
              <span className="d-block fw-bold">
                A1C Value
              </span>
                <Row className="g-0">
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      name="a1cWholes"
                      value={a1cWholes}
                      className="border"
                      onChange={(e) => {
                        onA1cWholesChange
                        && onA1cWholesChange(e.target.value)
                      }}
                      disabled={!enableA1C}
                    />
                  </Col>
                  <Col sm={1} className="pt-3 text-center fw-bold">
                    .
                  </Col>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      name="a1cDecimal"
                      value={a1cDecimal}
                      className="border"
                      onChange={(e) => {
                        onA1cDecimalChange
                        && onA1cDecimalChange(e.target.value)
                      }}
                      disabled={!enableA1C}
                    />
                  </Col>
                </Row>
              </InputGroup>
            </Col>
          }

          {enableBloodPressure &&
            <Col sm={4}>
              <InputGroup className="border-0">
              <span className="d-block fw-bold">
                Blood Pressure Value
              </span>
                <Row className="g-0">
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      name="bloodPressureSystolic"
                      value={bloodPressureSystolic}
                      className="border"
                      onChange={(e) => {
                        onBloodPressureSystolicChange
                        && onBloodPressureSystolicChange(e.target.value)
                      }}
                      disabled={!enableBloodPressure}
                    />
                  </Col>
                  <Col sm={1} className="h2 mb-0 text-center fw-bold">
                    /
                  </Col>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      name="bloodPressureDiastolic"
                      value={bloodPressureDiastolic}
                      className="border"
                      onChange={(e) => {
                        onBloodPressureDiastolicChange
                        && onBloodPressureDiastolicChange(e.target.value)
                      }}
                      disabled={!enableBloodPressure}
                    />
                  </Col>
                </Row>
              </InputGroup>
            </Col>
          }

          {enableAttestation &&
            <Col sm={6}>
              <ProvDropdown>
                <Form.Group>
                  <Form.Label className="d-block fw-bold">
                    {attestationDDLOption.caption}
                    {attestationDDLOption.abbreviation !== "CDCE" && (
                      <span className="text-danger ms-1 fs-5 lh-1">*</span>)}
                  </Form.Label>

                  {attestationDDLOption.caption.includes("OMW") ?
                    <Filter
                      selected={currentAttestationFilterValues}
                      handleChange={(value) => {
                        setCurrentAttestationFilterValues(value);

                        onAttestationChange && onAttestationChange(
                          value,
                          attestationDDLOption.gapId,
                          attestationDDLOption.abbreviation
                        );
                      }}
                      filterDefinition={{
                        type: GENERIC_DDL_TYPE,
                        getHeader: () => "",
                        key: "OMW",
                        componentProps: {
                          mode: DDLMode.MultipleSelect,
                          dataMode: DDLDataMode.OneAPICallFilterInUI,
                          showSearchTextbox: false,
                          highlightWhenHasValue: false,
                          showClearButton: true,
                          getData: (srchTxt) => Promise.resolve([
                            {
                              text: "All",
                              subItems: attestationDDLOption.options.map(
                                (m) => {
                                  return {text: m.description, value: m.id};
                                }
                              ),
                            },
                          ]),
                        },
                      }}
                    /> :
                    <SimpleDDL
                      list={attestationDDLOption.options}
                      textSelector={(x) => x.description}
                      valueSelector={(x) => x}
                      getKeyFromListItem={(item) =>
                        item?.id
                      }
                      onChange={(value) => {
                        const filterValue = {
                          paramValue: [value.id],
                          displayValue: [value.description]
                        };
                        setCurrentAttestationFilterValues(filterValue);

                        onAttestationChange && onAttestationChange(
                          filterValue,
                          attestationDDLOption.gapId,
                          attestationDDLOption.abbreviation)
                      }}
                      disabled={!enableAttestation}
                      selectedKey={get(currentAttestationFilterValues,
                        "paramValue[0]")}
                    />}
                </Form.Group>
              </ProvDropdown>
            </Col>
          }
        </Row>
      }
    </div>
  )
}

export default ActivityVisitItem