import axios from "../../../../../utils/apiClient";

const baseURL = process.env.REACT_APP_REST_API_BASE_URL;
const SUMMARY_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";

export const getAssessments = (id) => {
  const url = `${baseURL}/v1/patients/assessments`
  return axios.post(url, {
    patientId: id,
    filterParams: [],
    sortParams: [],
    pageNumber: 1,
    pageSize: 20
  })
}

export const getAssessmentMutationCall = (variables) => {
  const url = `${baseURL}/v1/patients/assessments`
  return axios.post(url, {
    ...variables
  })
}

export const getAssessmentById = (id) => {
  const url = `${baseURL}/v1/assessments/assessment-by-id?assessmentId=${id}`
  return axios.get(url, { headers: {} })
}

const getSummary = async () => {
  let data
  await axios.get(SUMMARY_URL, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => data = res)
  return data
}

export const removeAssessment = async ({ patientId, answerSetId }) => {
  const url = `${baseURL}/v1/assessments/delete?patientId=${patientId}&answerSetId=${answerSetId}`;
  return axios.delete(url, {
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export const getExportToCSV = async ({ patientId, answerSetId }) => {
  const url = `${baseURL}/v1/patient-profile/assessment-report?patientId=${patientId}&answerSetId=${answerSetId}`
  let data;

  await axios.get(url, {
    headers: {
      "Content-Type": "application/json"
    }
  }).then(async() => data = await getSummary());

  return data;
}
