import axios from "../../../../../../utils/apiClient";

const baseURL = process.env.REACT_APP_REST_API_BASE_URL;
const URLICD = baseURL + "/v1/icd10";
const URLReferral = baseURL + "/v1/submit-referral";
const jsonConfigHeader = {
    headers: {
        "Content-Type": "application/json"
    },
};

export const saveReferral = (payload) => {
    return axios.post(URLReferral + "/save", payload, jsonConfigHeader);
}

export const findSpecialities = (patientId) => {
    return axios.get(`${URLReferral}/find-specialties/?nameFragment=${"*"}&patientId=${patientId}`, jsonConfigHeader);
};

export const findPayers = (patientId) => {
    return axios.get(`${URLReferral}/find-payers/?patientId=${patientId}`, jsonConfigHeader);
};

export const searchPracticeName = (query, filters) => {
    const body = {
        page: 1,
        pageSize: 20,
        sortingCriteria: [],
        filters
    }
    return axios.post(`${URLReferral}/find-practices/?nameFragment=${query}*`, body, jsonConfigHeader)
}

export const searchAssociatedProvider = (query) => {
    const body = {
        searchParameters: query
    }
    return axios.post(`${URLReferral}/find-associated-provider/?nameFragment=${query}*`, body, jsonConfigHeader)
}

export const searchICD10ByCode = (query, offset = 1, pageSize = 30) => {
    return axios.get(URLICD + `/find-by-code?code=${query}&offset=${offset}&pageSize=${pageSize}`, jsonConfigHeader);
}

export const searchICD10ByDescription = (query, offset = 1, pageSize = 30) => {

    return axios.get(URLICD + `/find-by-description?description=${query}&offset=${offset}&pageSize=${pageSize}`, jsonConfigHeader);
}

export const findProviders = (filters) => {
    const body = {
        page: 1,
        pageSize: 20,
        sortingCriteria: [],
        filters
    };
    return axios.post(`${URLReferral}/find-practice-providers`, body, jsonConfigHeader);
}