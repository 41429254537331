import React, { useEffect, useRef, useState } from "react";

import { CollapsibleCard } from "./CollapsibleCard";
import AppointmentsCardClosed from "./AppointmentsClosed";
import AppointmentsCardOpen from "./AppointmentsOpened";

const AppointmentsCard = ({ appointment, idx, setFormBody, toggleIndividual, open }) => {
  const ref = useRef(null);
  const redirectID = sessionStorage.getItem('itemID');

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      setTimeout(() => {
        toggleIndividual()
      }, 1000);
      sessionStorage.removeItem('itemID');
    }
  }, [appointment.appointmentId])

  return (
    <div ref = {redirectID && appointment.appointmentId == redirectID ? ref : null}>
      <CollapsibleCard
        openContent={
          <AppointmentsCardOpen
            appointment={appointment}
          />
        }
        open={open}
        setOpen={toggleIndividual}
        idx={idx}
        closedContent={
          <AppointmentsCardClosed
            setFormBody={setFormBody}
            appointment={appointment}
            idx={idx}
            setOpen={toggleIndividual}
            opened={open}
          />
        }
      />
    </div>
  );
};

export default AppointmentsCard;
