import React from "react"

import {
  HospitalEventEditorModal
} from "./modalsComponents"

const Modals = ({ patient }) => {
  return (
    <>
      <HospitalEventEditorModal patientId={patient?.id} />
    </>
  );
}

export default Modals;
