import React, { useCallback, useEffect, useState } from "react";
import { Badge } from 'react-bootstrap';
import cloneDeep from "lodash/cloneDeep";
import ImmunizationDoses from "./info/ImmunizationDoses";
import { isEmpty, isEqual } from "lodash";

const componentName = "immunizationComponent";
const ImmunizationComponent = ({ immuObject,  id, disabled, handleSave }) => {
    const [immuObjectNew, setImmuObjectNew] = useState({})
    const [show, setShow] = useState(false)

    const handleActionChange = useCallback((event) => {
        if (event.target.checked) {
            setImmuObjectNew((oldValue) => {
                
                if (isEqual(oldValue, immuObject)) {
                    return oldValue
                }
                return immuObject
            })
            setShow(true)
            document.querySelector('.patientDetailsOffcanvas').style.visibility = 'hidden';
        }
        else {
            handleSave(immuObject, id, "delete")
        }
    }, [immuObject, immuObjectNew])

    const compDoses = useCallback((immuObj) => {
        if(!isEmpty(immuObj) ){
            return <ImmunizationDoses id={id} show={show} setShow={setShow} immunizationObject={immuObj} handleSave={handleSave} />
        }
        return <></>
    }, [show, immuObjectNew, setShow])
    const checkBoxToggle= useCallback((immuObj) => {  
            return immuObj.selected
        
    }, [show, immuObjectNew])

    return (
        <>
            <div id={`${componentName}-subVisitActivity-${id}`} className="d-flex justify-content-between" >
            <div>
                <input  id={`${componentName}-${id}`} className="form-check-input me-2" checked={checkBoxToggle(immuObject)} type="checkbox" disabled={disabled} onClick={(e) => handleActionChange(e)} />
                {immuObject?.description ? immuObject?.description : ''} {immuObject?.immunizationAbbreviation ? `(${immuObject?.immunizationAbbreviation})` : ''}
            </div>
            
            <Badge className={disabled ? "badge bg-light rounded-pill text-dark border-0 text-black-50" : "badge bg-light rounded-pill text-dark border-0"}>{immuObject?.dosesActual}/{immuObject?.doses} Doses</Badge>
            {compDoses(immuObject)}
        </div>
        </>

    )
}

export default ImmunizationComponent