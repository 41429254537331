import axios from "../../../../utils/apiClient";

export const updateMany = async (payload) => {
  return await axios.post(
    process.env.REACT_APP_REST_API_BASE_URL + `/v1/manage-visit/update-many`,
    payload,
    {
      headers: {"Content-Type": "application/json"},
    }
  )
}