import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {getDisplayValue} from '../../../../utils/util'
import {UrgencyLabel} from "../../Referrals/constants";
import {urgency_referrals} from "../../../../utils/textValueLists";

const componentName = "Urgency"

const Urgency = ({ referral, idx }) => {
  return (
    <Col id={`${componentName}-${idx}`} className="px-0" style={{maxWidth: 200}}>
      <Row className='justify-content-center'>
        <Col className='col-md-auto'>
          <UrgencyLabel className="badge rounded-pill border-0" urgency={referral?.referralDtoList[0]?.urgency}>
            {
              referral?.referralDtoList[0]?.urgency
                ? getDisplayValue(urgency_referrals, referral?.referralDtoList[0]?.urgency)
                : null
            }
          </UrgencyLabel>
        </Col>
      </Row>
    </Col>
  )
}

export default Urgency