import React, { useEffect, useState, useContext, useRef } from "react";

import { Container, OverlayTrigger, Tooltip, Row, Col, Card, ButtonGroup, Badge, Dropdown, Form, Accordion, AccordionContext, useAccordionButton, Toast, Spinner } from "react-bootstrap";
import { useAuth } from "../../context/authContext";
import axios from "../../utils/apiClient";
import { Button } from "../../components/buttons";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../components/dropDownLists/sharedDDL";
import ErrorToast from "../../views/common/ErrorToast";

import {
  formatDate,
  formatDateTime,
  getPatientProfileLink,
  isObjEmpty,
  logDebug,
  uuid,
} from "../../utils/util";
import {CHRONIC_COND_NAME_MAP} from "../../utils/textValueLists";
import Modals from "../../components/modals";
import PreventiveCareAddModal from "./PreventiveCareAddModal";
import styled from "styled-components";
import cloneDeep from "lodash/cloneDeep";
import {
  clearFileUploads,
  getGapDocuemnt,
} from "../../actions/fileUploadActions";
import { useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import FallBackDefault from "../../ErrorBoundaries/FallBackDefault";
import { QuickListContext } from "./QuickListContext";
import { EQHDatePicker } from "../../components/datePicker";
import { format } from "date-fns";
import { useLocation } from "react-router";
import { FileUpload } from "../../components/FileUpload";
import {
  CareOpportunitiesAccordion,
  CARE_OPPORTUNITIES_TYPE,
  getDefaultData as careOppPkgDefault,
} from "./ManageEvidence/CareOpportunitiesAccordion";
import {
  ValidationAccordion,
  getDefaultData as validationPkgDefault,
} from "./ManageEvidence/ValidationAccordion";
import {
  getSuspectAwaitingChartContent,
  getSuspectAwaitingChartToolTip,
  getSuspectAwaitingClaimContent,
  getSuspectAwaitingClaimToolTip,
} from "./ManageEvidence/ManageEvidenceCommonCode";
import { SimpleDDL } from "../../components/dropDownLists/simpleDDL";
import EQHErrorToast from "../../components/EQHErrorToast";
import { InputGroup, Modal } from "react-bootstrap";
import { FullHistory } from "./ManageEvidence/FullHistory";
import { DDLDataMode, DDLMode, GENERIC_DDL_TYPE } from "../../components/dropDownLists/genericDDL";
import { Filter } from "../../components/filters/filterGroup";
import PreventiveCareHistory from "./PreventiveCareHistory";

const componentName = "ManageEvidence";

const baseURLPC =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const StyledBadgeBtn = styled(Badge)`
  :hover {
    cursor: pointer;
  }
`;

const ProvDropdown = styled.div`
  .dropdown-item {
    white-space: normal;
  }
`;

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
      <i
        className={isCurrentEventKey ? "fa fa-minus me-1" : "fa fa-plus me-1"}
      ></i>{" "}
      <span className="fw-bold">{children}</span>
    </Button>
  );
}

const DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO = {
  bloodPressureSystolic: null,
  bloodPressureDiastolic: null,
  a1cWholes: null,
  a1cDecimal: null,
  note: "",
  uploadedFiles: [],
  careGapAdditionalInfos: {},
};

const getDefaultPreventiveCareUpdateResultsDto = () => {
  return cloneDeep(DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO);
};

const DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITY ={"oppFoundCount":0,"selOppFoundCount":0,"isAnyOneParentSelected":false}

const DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITIES={
  "TOC":cloneDeep(DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITY),
  "CareOpp":cloneDeep(DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITY)
  //"Validation":cloneDeep(DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITY)
};

const getDefaultParentChildCareOpportunities =()=>{
  return cloneDeep(DEFAULT_PARENT_CHILD_SELECTED_CARE_OPPORTUNITIES);
};

export const ManageEvidence = ({
  patient,
  closeEvidenceModal,
  returnPath,
  updateParent,
  fromAppointmentScreen,
  navigationParams,
  activeKey,
  updatesMade,
  setConfirmationMessage,
  confirmationMessage,
  featureFlagCIS
}) => {
  const auth = useAuth();
  const authorizedGapRead = auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS");
  const authorizedGapWrite = auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS");
  const authorizedRafsRead = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS"
  );
  const authorizedRafsWrite = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS"
  );

  const authorizedGapValidate = auth.hasAuthority(
    "AUTH_CARE_OPPORTUNITY_VALIDATION_ACCESS"
  );
  const authorizedRafValidate = auth.hasAuthority(
    "AUTH_RAFS_SUSPECT_VALIDATION_ACCESS"
  );
  const authorizedRafAdd = auth.hasAuthority("AUTH_RAFS_ADD_NEW_SUSPECT");
  const authorizedChronicConditions = auth.hasAuthority(
    "CHRONIC_CONDITION_QUICK_LIST_ACCESS"
  );
  const authorizedTransitions = auth.hasAuthority(
    "HOSPITAL_EVENTS_QUICK_LIST_ACCESS"
  );
  //This message will be shared across child component
  const [careOppMsg, setCareOppMsg] = useState("");
  const [selectedParentChildCareOpportunites,setSelectedParentChildCareOpportunites] = useState(getDefaultParentChildCareOpportunities());
  const [selectedGaps, setSelectedGaps] = useState(null);
  //State for suspects / rafs
  const [showAllSuspects, setShowAllSuspects] = useState(false);
  const [selectedSuspects, setSelectedSuspects] = useState({
    ids: [],
    conditions: [],
  });
  const [suspectForm, setSuspectForm] = useState({
    status: { source: "", translationKey: "", closesCondition: true, name: "" },
    dateOfService: null,
  });
  const [userSelectedCareOppHistoryIds, setUserSelectedCareOppHistoryIds] =
    useState([]);
  const [userSelectedSuspectHistoryIds, setUserSelectedSuspectHistoryIds] =
    useState([]);

  const [careOpportunityPkgResetKey, setCareOpportunityPkgResetKey] =
    useState(null);
  const [tocPkgResetKey, setTOCPkgResetKey] = useState(null);
  const [validationPkgResetKey, setValidationPkgResetKey] = useState(null);

  //State for opportunities / gaps
  const [isSuspectPlusMinus, setSuspectPlusMinus] = useState(false);

  const [attestationDDLOptions, setAttestationDDLOptions] = useState([]);
  const [requireA1c, setRequireA1c] = useState(false);
  const [requireDocumentation, setRequireDocumentation] = useState(false);
  const [requirePressure, setRequirePressure] = useState(false);
  const [visitDate, setVisitDate] = useState(null);
  const [careOpportunityPkg, setCareOpportunityPkg] = useState(() =>
    careOppPkgDefault(visitDate)
  );
  const [tocPkg, setTOCPkg] = useState(() => careOppPkgDefault(visitDate));
  const [validationPkg, setValidationPkg] = useState(() =>
    validationPkgDefault(visitDate)
  );
  //const [originalVisitDate, setOriginalVisitDate] = useState(null);
  //State for handling different opportunitiy / gap requirements
  const [documentation, setDocumentation] = useState([]);
  const [requireUpload, setRequireUpload] = useState(false);
  const [requireAttestation, setRequireAttestation] = useState(false);
  const [requireDos, setRequireDos] = useState(true);
  //State for modals
  const [showDocuments, setShowDocuments] = useState(false);
  const [enableCreation, setEnableCreation] = useState(false);
  const [addModalTitle, setAddModalTitle] = useState(false);

  const [showFullHistory, setShowFullHistory] = useState(false);
  const toggleShowHistory = () => setShowFullHistory(!showFullHistory);
  //State for genreal and shared functionality
  const [isSearching, setIsSearching] = useState(false);
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [errors, setErrors] = useState([]);
  const [update, setUpdate] = useState(false);
  // const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [bloodPressureError, setBloodPressureError] = useState(false);
  const [ac1ValueError, setAc1ValueError] = useState(false);
  const [suspectDosError, setSuspectDosError] = useState(null);
  const [preventiveCareUpdateResultsDto, setPreventiveCareUpdateResultsDto] =
    useState(() => getDefaultPreventiveCareUpdateResultsDto());
  const [dataResponse, setDataResposne] = useState({});
  const [confirmationWarning, setConfirmationWarning] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [
    processValidationPkgAddToSelecedItemParamsKey,
    setProcessValidationPkgAddToSelecedItemParamsKey,
  ] = useState(null);
  const [
    processCareOpportunityPkgAddToSelecedItemParamsKey,
    setProcessCareOpportunityPkgAddToSelecedItemParamsKey,
  ] = useState(null);
  const [
    processTOCPkgAddToSelecedItemParamsKey,
    setProcessTOCPkgAddToSelecedItemParamsKey,
  ] = useState(null);
  const [isShowHistoryEnabled, setShowHistoryEnabled] = useState(false);

  const isEmpty = require("is-empty");
  const location = useLocation();

  const getCareOppHistoryIds = () => {
    const s = new Set();
    if (showFullHistory) {
      careOpportunityPkg.selectedGaps.forEach((x) =>
        s.add(x.careGapSummary.id)
      );
      careOpportunityPkg.additionalGapIdsSelected.forEach((x) => s.add(x));
      tocPkg.selectedGaps.forEach((x) => s.add(x.careGapSummary.id));
      tocPkg.additionalGapIdsSelected.forEach((x) => s.add(x));
      if (validationPkg.validationType === "Opportunity") {
        validationPkg.selectedItems.forEach((x) => s.add(x.careGapSummary.id));
      }
      userSelectedCareOppHistoryIds.forEach((x) => s.add(x));
    }
    return s;
  };
  const getSuspectHistoryIds = () => {
    const s = new Set();
    if (showFullHistory) {
      selectedSuspects.ids.forEach((x) => s.add(x));
      if (validationPkg.validationType === "Suspect") {
        validationPkg.selectedItems.forEach((x) =>
          s.add(x.conditionSummary.id)
        );
      }
      userSelectedSuspectHistoryIds.forEach((x) => s.add(x));
    }
    return s;
  };
  const careOppHistoryIds = getCareOppHistoryIds();
  const suspectHistoryIds = getSuspectHistoryIds();

  const handleUserSelectedCareOppHistoryIdsChange = (id) => {
    let userSelectedCareOppHistoryIdsClone = [...userSelectedCareOppHistoryIds];
    if (userSelectedCareOppHistoryIdsClone.includes(id)) {
      userSelectedCareOppHistoryIdsClone =
        userSelectedCareOppHistoryIdsClone.filter((x) => x !== id);
    } else {
      userSelectedCareOppHistoryIdsClone.push(id);
    }
    setUserSelectedCareOppHistoryIds(userSelectedCareOppHistoryIdsClone);
    setSelectedGaps(null);
  };

  const handleUserSelectedSuspectHistoryIdsChange = (id) => {
    let userSelectedSuspectHistoryIdsClone = [...userSelectedSuspectHistoryIds];
    if (userSelectedSuspectHistoryIdsClone.includes(id)) {
      userSelectedSuspectHistoryIdsClone =
        userSelectedSuspectHistoryIdsClone.filter((x) => x !== id);
    } else {
      userSelectedSuspectHistoryIdsClone.push(id);
    }
    setUserSelectedSuspectHistoryIds(userSelectedSuspectHistoryIdsClone);
  };

  const handlePreventiveCareUpdateResultsDtoChange = (key, value) => {
    switch (key) {
      case "bloodPressureSystolic":
      case "bloodPressureDiastolic":
      case "systolicCode":
      case "diastolicCode":
        setBloodPressureError(value < 0 || value > 400);
        break;
      case "a1cWholes":
      case "a1cDecimal":
        setAc1ValueError(value > 20.9);
        break;
      default:
        break;
    }
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      [key]: value,
    });
  };

  const handleAttestationChange = (value, gapId) => {
    let finalVal = [];
    finalVal.push({ id: value.id, description: value.description, selected: true });
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      careGapAdditionalInfos: {
        ...preventiveCareUpdateResultsDto.careGapAdditionalInfos,
        [gapId]: finalVal,
      },
    });
  };

  const [filterValues, setFilterValues] = useState({
    paramValue: [],
    displayValue: [],
  });
  const handleAttestationChangeForFilter = (value, gapId, abb) => {
    setFilterValues(value);
    let finalVal = []
    if(value && value.paramValue && value.paramValue.length){
      value.paramValue.forEach((val, index)=>{
        finalVal.push({id:val, description:value.displayValue[index], selected: true})
      })
    }
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      careGapAdditionalInfos: {
        ...preventiveCareUpdateResultsDto.careGapAdditionalInfos,
        [gapId]: finalVal,
      },
    });
  };

  useEffect(() => {
    setIsSearching(true);

    axios.get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/account-settings/user-profile/system-settings`,
        {
          headers: {},
        }
    ).then(function(result) {
      setShowHistoryEnabled(result.data.showHistoryAutomatically);
    });

  }, []);

  useEffect(() => {
    setIsSearching(true);
    patient?.id &&
    axios
      .post(baseURLPC + `/find?patientId=${patient.id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.data.visitDate) {
          setVisitDate(response.data.visitDate);
          if (new Date(response.data.visitDate) <= new Date()) {
            setSuspectForm({
              ...suspectForm,
              dateOfService: response.data.visitDate,
            });
          }
        }
        setDataResposne(response.data);
        setIsSearching(false);
      })
      .catch((error) => {
        logDebug(() => error);
        setIsSearching(false);
      });
  }, [patient, update]);

  useEffect(() => {
    if (navigationParams && !isObjEmpty(dataResponse)) {
      if (
        navigationParams.type === "suspect" &&
        dataResponse.conditionsResponse?.openConditions.length
      ) {
        dataResponse.conditionsResponse.openConditions.forEach((con) => {
          if (con.conditionSummary.id === navigationParams.id) {
            updateSelected(con, "suspects");
          }
        });
      }

      setProcessValidationPkgAddToSelecedItemParamsKey(1);
      setProcessCareOpportunityPkgAddToSelecedItemParamsKey(1);
      setProcessTOCPkgAddToSelecedItemParamsKey(1);
    }
  }, [dataResponse, navigationParams]);

  const updatePatientWithClosedGapsAndRafs = async (newPatient) => {
    if (!fromAppointmentScreen) {
      await updateParent(newPatient, "ManageEvidence");
    }
  };

  //Below is logic for creating new suspects & conditions
  const toggleCreation = (str) => {
    setEnableCreation(true);
    if (str === "condition") {
      setAddModalTitle("Condition");
      setEnableCreation(true);
    } else if (str === "suspect") {
      setAddModalTitle("Suspect");
      setEnableCreation(true);
    }
  };

  const handleCreation = async (data) => {
    const newPatient = cloneDeep(patient);
    const dataResponseClone = { ...dataResponse };
    const openSuspectsClone = [
      ...(dataResponse.conditionsResponse?.openConditions || []),
    ];
    const closedSuspectsClone = [
      ...(dataResponse.conditionsResponse?.closedConditions || []),
    ];
    if (data?.conditionSummary?.status?.closesCondition) {
      closedSuspectsClone.push(data);
      const disease = createDisease(data);
      newPatient.closeDiseases.push(disease);
    } else {
      openSuspectsClone.push(data);
      const disease = createDisease(data);
      newPatient.diseases.push(disease);
    }
    dataResponseClone.conditionsResponse.openConditions = openSuspectsClone;
    dataResponseClone.conditionsResponse.closedConditions = closedSuspectsClone;
    setDataResposne(dataResponseClone);
    await updateParent(newPatient, "ManageEvidence");
    setUpdate(!update);
  };

  const createDisease = (data) => {
    const closeDisease = {
      diseaseStatus: data?.conditionSummary?.status,
      dxCode: data?.dxCode,
      dxDescription: data?.conditionSummary?.name,
      dxCodeDescription: data?.conditionSummary?.dxCodeDescription,
      id: data?.conditionSummary?.id,
      labResult: data?.conditionSummary?.name,
      likelihood: data?.conditionSummary?.likelihood,
      measureYear: data?.conditionSummary?.measureYear,
      resultNote: data?.conditionSummary?.note,
      validation: data?.conditionSummary?.source,
    };
    return closeDisease;
  };

  const checkForAttestation = (
    careOpportunityGaps,
    careOpportunityHasMarkForClosure,
    tocGaps,
    tocHasMarkForClosure
  ) => {
    const attestations = [];
    const selectedAttestations = {};
    const gaps = [
      ...(careOpportunityHasMarkForClosure ? careOpportunityGaps : []),
      ...(tocHasMarkForClosure ? tocGaps : []),
    ];
    if (gaps.length) {
      gaps.forEach((gap) => {
        if (gap.careGapSummary.additionalInfoCaption) {
          attestations.push({
            gapId: gap.careGapSummary.id,
            caption:
              gap.careGapSummary.abbreviation +
              " " +
              gap.careGapSummary.additionalInfoCaption,
            options: gap.careGapSummary.additionalInfoOptions,
          });
          const selectedAttestation =
            preventiveCareUpdateResultsDto.careGapAdditionalInfos[
              gap.careGapSummary.id
            ];
          if (selectedAttestation) {
            selectedAttestations[gap.careGapSummary.id] = selectedAttestation;
          }
        }
      });
    }

    setAttestationDDLOptions(attestations);
    setPreventiveCareUpdateResultsDto({
      ...preventiveCareUpdateResultsDto,
      careGapAdditionalInfos: selectedAttestations,
    });
    return attestations;
  };

  const applyConditionalRequirements = (
    careOpportunityPkg,
    tocPkg,
    validationPkg,
    selectedSuspects,
    suspectForm
  ) => {
    const obj = {
      requireUpload: false,
      requireA1c: false,
      requirePressure: false,
      requireAttestation: false,
      requireDocumentation: false,
    };
    // const allCareOpportunities = [
    //   ...careOpportunityPkg.selectedGaps,
    //   ...tocPkg.selectedGaps,
    // ];

    const careOpportunityHasMarkForClosure =
      careOpportunityPkg.updateCareGapDto?.newStatus?.name ===
      "Marked for Closure";
    const tocHasMarkForClosure =
      tocPkg.updateCareGapDto?.newStatus?.name === "Marked for Closure";
    const formHasMarkForClosure =
      careOpportunityHasMarkForClosure || tocHasMarkForClosure;
    //true rules
    //we should move all these rules to backend at some point
    //careOpportunity sections
    const commonGapChecksAndReturnIsExclusion = (
      gap,
      exclusionCriteria,
      obj
    ) => {
      if (!exclusionCriteria.some((x) => x.gapId === gap.careGapSummary.id)) {
        if (formHasMarkForClosure) {
          if (gap.careGapSummary.requireBloodPressure) {
            obj.requirePressure = true;
          }
          if (gap.careGapSummary.requireA1C) {
            obj.requireA1c = true;
          }
          if (gap.careGapSummary.requireFileUpload) {
            obj.requireUpload = true;
          }
        }
        return false;
      } else {
        return true;
      }
    };

    tocPkg.selectedGaps.forEach((trans) => {
      if (
        !commonGapChecksAndReturnIsExclusion(
          trans,
          tocPkg.updateCareGapDto.exclusionCriteria,
          obj
        )
      ) {
        // if (trans.careGapSummary.requireFileUpload) {
        //   obj.requireUpload = true;
        // }
      }
    });

    careOpportunityPkg.selectedGaps.forEach((gap) => {
      commonGapChecksAndReturnIsExclusion(
        gap,
        careOpportunityPkg.updateCareGapDto.exclusionCriteria,
        obj
      );
    });

    //Suspects
    if (suspectForm?.status?.name === "Confirmed Diagnosis") {
      obj.requireUpload = true;
    }

    //validation
    // validationPkg.selectedItems.forEach((validationItem) => {
    //   //if validation care gap
    //   if (validationItem.careGapSummary) {
    //   } else if (validationItem.conditionSummary) {
    //   }
    // });

    if (
      (careOpportunityPkg.selectedGaps?.length ||
      careOpportunityPkg.additionalGapIdsSelected?.length ||
      selectedSuspects.ids?.length ||
      validationPkg.selectedItems?.length ||
      tocPkg.selectedGaps?.length ||
      tocPkg.additionalGapIdsSelected?.length) &&
      (isShowHistoryEnabled || showFullHistory)
    ) {
      setShowFullHistory(true);
    } else {
      setShowFullHistory(false);
    }

    const attestationDDLOptions = checkForAttestation(
      careOpportunityPkg.selectedGaps,
      careOpportunityHasMarkForClosure,
      tocPkg.selectedGaps,
      tocHasMarkForClosure
    );
    const documentation = checkForDocumentation(
      careOpportunityPkg.selectedGaps,
      careOpportunityHasMarkForClosure,
      tocPkg.selectedGaps,
      tocHasMarkForClosure
    );
    if (attestationDDLOptions?.length) {
      obj.requireAttestation = true;
    }
    if (documentation?.length) {
      obj.requireDocumentation = true;
    }

    setRequireDocumentation(obj.requireDocumentation);
    if(patient?.contracted) {
      setRequireUpload(obj.requireUpload);
    }
    setRequireA1c(obj.requireA1c);
    setRequirePressure(obj.requirePressure);
    setRequireAttestation(obj.requireAttestation);
  };

  const checkForDocumentation = (
    careOpportunityGaps,
    careOpportunityHasMarkForClosure,
    tocGaps,
    tocHasMarkForClosure
  ) => {
    let documentation = [];
    const gaps = [
      ...(careOpportunityHasMarkForClosure ? careOpportunityGaps : []),
      ...(tocHasMarkForClosure ? tocGaps : []),
    ];
    if (gaps.length) {
      gaps.forEach((gap) => {
        if (gap.careGapSummary.requiredDocumentation) {
          documentation.push({
            documentation: gap.careGapSummary.requiredDocumentation,
            description: "Required for " + gap.careGapSummary.name,
          });
        }
      });
    }
    setDocumentation(documentation);
  };

  const callUpdatesMade = (
    careOpportunityPkg,
    tocPkg,
    validationPkg,
    selectedSuspects
  ) => {
    if (
      careOpportunityPkg.selectedGaps.length ||
      tocPkg.selectedGaps.length ||
      validationPkg.selectedItems.length ||
      selectedSuspects.ids.length
    ) {
      updatesMade(true);
    } else {
      updatesMade(false);
    }
  };

  const TESTARR =[{id:"1", description: "ABCD"},{id:"2", description: "ghggggg"},{id:"3", description: "rtyt"}]
  const handlePkgChange = (pkg, key) => {
    const pkgReference = {
      careOpportunityPkg,
      tocPkg,
      validationPkg,
    };
    if (key === "careOpportunityPkg") {
      pkgReference.careOpportunityPkg = pkg;
      setCareOpportunityPkg(pkg);
    } else if (key === "tocPkg") {
      pkgReference.tocPkg = pkg;
      setTOCPkg(pkg);
    } else if (key === "validationPkg") {
      pkgReference.validationPkg = pkg;
      setValidationPkg(pkg);
    }

    callUpdatesMade(
      pkgReference.careOpportunityPkg,
      pkgReference.tocPkg,
      pkgReference.validationPkg,
      selectedSuspects
    );

    applyConditionalRequirements(
      pkgReference.careOpportunityPkg,
      pkgReference.tocPkg,
      pkgReference.validationPkg,
      selectedSuspects,
      suspectForm
    );
  };

  //Below is shared logic for suspects and conditions, followed by individual.
  const updateSelected = (item, str) => {
    let newSuspectsState = selectedSuspects;
    let suspectFormClone = { ...suspectForm };
    let historyId = "";

    if (str === "suspects") {
      historyId = item.conditionSummary.id;
      if (selectedSuspects.ids.includes(item.conditionSummary.id)) {
        newSuspectsState.ids = selectedSuspects.ids.filter(
          (i) => i !== item.conditionSummary.id
        );
        newSuspectsState.conditions = selectedSuspects.conditions.filter(
          (i) => i.conditionSummary.id !== item.conditionSummary.id
        );
        setSelectedSuspects({ ...newSuspectsState });
        if (!newSuspectsState.ids.length) {
          suspectFormClone.status = {
            source: "",
            translationKey: "",
            closesCondition: true,
            name: "",
          };
          suspectFormClone.dateOfService = "";
          setSuspectForm(suspectFormClone);
          setSuspectDosError(null);
        }
      } else {
        newSuspectsState.ids = [item.conditionSummary.id].concat(
          selectedSuspects.ids
        );
        newSuspectsState.conditions = [item].concat(
          selectedSuspects.conditions
        );
        setSelectedSuspects({ ...newSuspectsState });

        callUpdatesMade(
          careOpportunityPkg,
          tocPkg,
          validationPkg,
          newSuspectsState
        );

        applyConditionalRequirements(
          careOpportunityPkg,
          tocPkg,
          validationPkg,
          { ...newSuspectsState },
          suspectFormClone
        );
      }
    }
  };

  const handleSuspectChange = (name, value) => {
    let suspectFormClone = { ...suspectForm };
    if (name === "dateOfService") {
      setSuspectDosError(value.error);
      suspectFormClone[name] = value.strDate;
    } else {
      suspectFormClone[name] = value;
    }

    setSuspectForm(suspectFormClone);

    applyConditionalRequirements(
      careOpportunityPkg,
      tocPkg,
      validationPkg,
      selectedSuspects,
      suspectFormClone
    );
  };

  const handleVisitChange = (value) => {
    setVisitDate(value.strDate);
    if (new Date(value.strDate) <= new Date()) {
      // setOpportunitiesForm({
      //   ...opportunitiesForm,
      //   dateOfService: value.strDate,
      // });
    }
    if (new Date(value.strDate) <= new Date()) {
      setSuspectForm({
        ...suspectForm,
        dateOfService: value.strDate,
      });
    }
  };

  //Logic for file submitting
  const handleUploadFileChange = (arr) => {
    handlePreventiveCareUpdateResultsDtoChange("uploadedFiles", arr || []);
  };

  const onModalClose = async () => {
    let opportunityLength = careOpportunityPkg.selectedGaps.length;
    let tocLength = tocPkg.selectedGaps.length;
    let suspectsLength = selectedSuspects.ids.length;
    let validationLength = validationPkg.selectedItems.length;

    if (validationLength || opportunityLength || tocLength || suspectsLength) {
      setConfirmationMessage(true);
    } else {
      handleClose();
    }
  };

  const handleClose = async () => {
    await closeEvidenceModal();
  };

  //Below is logic submitting changes
  const updatePatientEvidence = (ignoreConfirmationWarnings = false) => {
    setIsUpdating(true);
    try {
      if (!ignoreConfirmationWarnings) {
        if (careOpportunityPkg.confirmationWarning) {
          setConfirmationWarning(careOpportunityPkg.confirmationWarning);
          setIsUpdating(false);
          return;
        }
        if (tocPkg.confirmationWarning) {
          setConfirmationWarning(tocPkg.confirmationWarning);
          setIsUpdating(false);
          return;
        }
        if (validationPkg.confirmationWarning) {
          setConfirmationWarning(validationPkg.confirmationWarning);
          setIsUpdating(false);
          return;
        }
      }

      careOpportunityPkg.finalizeForBackend();
      tocPkg.finalizeForBackend();
      validationPkg.finalizeForBackend();

      const dto = {
        ...preventiveCareUpdateResultsDto,
        patientLastUpdated: dataResponse.patientLastUpdated,
        visitDate: visitDate ? visitDate : null,
        patientId: patient.id,
        updateCareGapActionDto: careOpportunityPkg.selectedGaps.length
          ? careOpportunityPkg.updateCareGapDto
          : null,
        updateTOCCareGapActionDto: tocPkg.selectedGaps.length
          ? tocPkg.updateCareGapDto
          : null,
        updateValidationCareGapActionDto:
          validationPkg.selectedItems.length &&
          validationPkg.validationType === "Opportunity"
            ? validationPkg.updateDto
            : null,
        updateValidationConditionActionDto:
          validationPkg.selectedItems.length &&
          validationPkg.validationType === "Suspect"
            ? validationPkg.updateDto
            : null,
        updateConditionActionDto: selectedSuspects.ids.length
          ? {
              ids: selectedSuspects.ids,
              visitDate: visitDate ? visitDate : null,
              newStatus: suspectForm.status,
              validation: null,
              newDateOfService: selectedSuspects.ids
                ? suspectForm.dateOfService
                : null,
              icd10DiseaseDto: null,
              forPreviousYear: false,
            }
          : null,
      };
      logDebug(() => dto);
      axios
        .post(baseURLPC + `/update`, dto, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (response) => {
          // setOpportunitiesForm({
          //   ...opportunitiesForm,
          //   conditions: [{ id: [], code: [], description: [] }],
          // });//TODO
          await updatePatientWithClosedGapsAndRafs(response?.data);
          await resetModalProperties();
          setFileUploadResetCounter(fileUploadResetCounter + 1);
          setShowSuccessMsg(true);
          setUpdate(!update);
          setIsUpdating(false);
        })
        .catch((error) => {
          logDebug(() => error);
          formatErrorMessage(error);
          setIsUpdating(false);
        });
    } catch (e) {
      setIsUpdating(false);
    }
  };

  const resetModalProperties = () => {
    let suspectsLength = selectedSuspects.ids.length;

    setPreventiveCareUpdateResultsDto(
      getDefaultPreventiveCareUpdateResultsDto()
    );
    setFileUploadResetCounter(fileUploadResetCounter + 1);
    // setExclusionDetails([]);
    // setSelectedCriterias({
    //   ids: [],
    //   gapIds: [],
    //   criterias: [],
    // });
    // setExclusionSelected(false);
    // setExclusionAvailable(false);

    if (suspectsLength) {
      setSelectedSuspects({ ids: [], conditions: [] });
      setSuspectForm({
        status: {
          source: "",
          translationKey: "",
          closesCondition: true,
          name: "",
        },
        dateOfService: "",
      });
    }
    setCareOpportunityPkgResetKey(uuid());
    setTOCPkgResetKey(uuid());
    setValidationPkgResetKey(uuid());
  };

  const formatErrorMessage = (error) => {
    if (error?.response?.data?.message?.includes("|")) {
      var errors = error?.response?.data?.message.split("|");
      setErrors(errors);
    } else {
      setErrors([error?.response?.data?.message]);
    }
  };

  const resetCurrentErrors = () => {
    setErrors([]);
  };

  const isFormValid = () => {
    const suspectStatus = suspectForm.status;
    const suspectDateOfService = suspectForm.dateOfService;

    const suspectsLength = selectedSuspects.ids.length;
    const suspectErrors = [];
    const errors = [];
    const allGaps = [
      ...careOpportunityPkg.selectedGaps,
      ...tocPkg.selectedGaps,
    ];

    if (suspectsLength) {
      if (!suspectStatus.name.length || !suspectDateOfService) {
        suspectErrors.push("Suspect Date of Service is Required");
      }
      if (suspectDosError) {
        suspectErrors.push("Suspect Date of Service is invalid");
      }
    }

    //Parent Form
    if (
      !careOpportunityPkg.selectedGaps?.length &&
      !tocPkg.selectedGaps?.length &&
      !validationPkg.selectedItems?.length &&
      !suspectsLength
    ) {
      errors.push(
        "Care Opportunity, TOC, Validation, or Suspect must be selected"
      );
    }
    if (
      attestationDDLOptions?.length &&
      !attestationDDLOptions.every(
        (x) =>
          preventiveCareUpdateResultsDto.careGapAdditionalInfos[x.gapId]?.id || preventiveCareUpdateResultsDto.careGapAdditionalInfos[x.gapId]?.length
      )
      
    ) {
      errors.push("Provider Attestation is Required");
    }
    if (
      requireUpload &&
      !preventiveCareUpdateResultsDto.uploadedFiles?.length
    ) {
      errors.push("File Upload is Required");
    }

    logDebug(
      () =>
        "isValid: " +
        JSON.stringify({
          careOpportunityPkg: careOpportunityPkg.errors,
          tocPkg: tocPkg.errors,
          validationPkg: validationPkg.errors,
          suspect: suspectErrors,
          errors,
        })
    );

    if (
      isEmpty(careOpportunityPkg.errors) &&
      isEmpty(tocPkg.errors) &&
      isEmpty(validationPkg.errors) &&
      isEmpty(suspectErrors) &&
      isEmpty(errors)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const chronicConditionsLabel = (con, string) => {
    return (
      <div
        className="d-flex flex-row mb-1"
        id={`${componentName}-ChronicConditions-${string}`}
      >
        <div className="d-inline-block me-2">
          <i
            id={`${componentName}-ChronicConditionIcon-${string}`}
            className="fa fa-circle fa-xs"
          ></i>
        </div>
        <div className="d-inline-block me-2">
          <span id={`${componentName}-ChronicCondition-${string}`}>
          {CHRONIC_COND_NAME_MAP[string]}
          </span>
          <Badge
            pill
            style={{ backgroundColor: con === "Controlled" ? "black" : "red" }}
            className="text-white ms-2"
            id={`${componentName}-ChronicConditionBadge-${string}`}
          >
            {con}
          </Badge>
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={FallBackDefault}>
      <>
        <Modals
          scrollable={true}
          show={showDocuments}
          closeEvidenceModal={() => setShowDocuments(false)}
          onHide={() => setShowDocuments(false)}
          size="lg"
          title="Documents"
          titleid={`${componentName}-DocumentsModal`}
          hideFooter={false}
          hideClickButton={true}
          closeBtnId={`${componentName}-DocumentsCloseButton`}
          body={
            <Row>
              <Col>
                {dataResponse?.gapDocumentations &&
                dataResponse?.gapDocumentations?.length ? (
                  <DocumentModal
                    files={dataResponse?.gapDocumentations || []}
                    type={"Care Opportunity Documents"}
                  />
                ) : null}
              </Col>
            </Row>
          }
        />
        <Modals
          scrollable={true}
          show={enableCreation}
          closeEvidenceModal={() => setEnableCreation(false)}
          onHide={() => setEnableCreation(false)}
          size="xl"
          title={`New ` + addModalTitle}
          titleid={`${componentName}-NewModal`}
          completeBtn="UPLOAD"
          hideFooter={true}
          body={
            <PreventiveCareAddModal
              closeModal={() => setEnableCreation(false)}
              type={addModalTitle}
              patient={patient}
              submitCreation={handleCreation}
            />
          }
        />

        <Modals
          centered={true}
          show={confirmationMessage}
          scrollable={true}
          closeConfirmation={() => setConfirmationMessage(false)}
          onCreate={() => handleClose()}
          onHide={() => setConfirmationMessage(false)}
          size="sm"
          title=""
          titleid={`${componentName}-CloseWithoutSaving`}
          closeBtn="No"
          completeBtn="Yes"
          hideFooter={false}
          body="Do you want to close without saving?"
        />

        <Modal
          show={confirmationWarning ? true : false}
          onHide={(e) => setConfirmationWarning("")}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{confirmationWarning}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                setConfirmationWarning("");
                updatePatientEvidence(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={(e) => {
                setConfirmationWarning("");
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col sm={6}>
            <div id={`${componentName}-patientName`} className="mb-1">
              <label id={`${componentName}-patientNameLabel`} className="me-1">
                Patient:{" "}
              </label>
              <span id={`${componentName}-patientNameValue`}>
                {patient.lastName}, {patient.firstName}
              </span>
            </div>
            <div id={`${componentName}-patientInfo`} className="mb-2">
              <span
                id={`${componentName}-patientInfoMemberId`}
                className="me-2"
              >
                <span
                  id={`${componentName}-patientInfoMemberIdNo`}
                  className="me-1"
                >
                  {patient.memberId}
                </span>
                {patient.active ? (
                  <Badge pill bg="success" id={`${componentName}-patientInfoMemberIdActive`}>
                    Active
                  </Badge>
                ) : (
                  <Badge pill bg="danger" id={`${componentName}-patientInfoMemberIdInactive`}>
                    Inactive
                  </Badge>
                )}
              </span>
              <span id={`${componentName}-patientNameDOB`}>
                <label
                  id={`${componentName}-patientNameDOBLabel`}
                  className="me-1"
                >
                  DOB:
                </label>
                <span
                  id={`${componentName}-patientInfoDOBDate`}
                  className="me-1 text-dark"
                >
                  {patient &&
                    patient.dateOfBirth &&
                    formatDate(patient.dateOfBirth)}
                </span>
                <Badge pill bg="light" text="dark" id={`${componentName}-patientInfoDOBAge`} className="border p-1">
                  {patient.age}
                </Badge>
              </span>
            </div>
            <div
              className="d-block mb-3"
              id={`${componentName}-patientVisitDate`}
            >
              <Row>
                <Col>
                  <label
                    className="mt-2 me-3"
                    id={`${componentName}-patientVisitDateLabel`}
                  >
                    Visit Date
                  </label>
                  <EQHDatePicker
                    id={`${componentName}-patientVisitDatePicker`}
                    className="d-inline-block"
                    value={visitDate}
                    onChange={(e) => {
                      handleVisitChange(e);
                    }}
                    style={{ maxWidth: "fit-content" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6} className="text-end float-end">
            <ButtonGroup
              size="sm"
              className="d-inline-block bg-white align-top"
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Documents</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  id={`${componentName}-showDocuments`}
                  onClick={() => setShowDocuments(true)}
                >
                  <i className="fas fa-sticky-note fa-lg align-middle"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>History</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  id={`${componentName}-showHistory`}
                  onClick={() => setShowFullHistory(true)}
                >
                  <i className="fas fa-history fa-lg align-middle"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Patient Profile</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  id={`${componentName}-patientProfileLink`}
                  href={getPatientProfileLink(patient.id, location)}
                >
                  <i className="fas fa-user-alt fa-lg align-middle"></i>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Col>
        </Row>
        <PreventiveCareHistory
            allOpportunitiesForHist={dataResponse.careGapsResponse?.careGapsHistory || []}
            allSuspectsForHist={dataResponse.conditionsResponse?.conditionsHistory || []}
            showFullHistory={showFullHistory}
            setShowFullHistory={setShowFullHistory}
            isLoading={dataResponse.loading_post}
            careOppHistoryIds={careOppHistoryIds}
            handleUserSelectedCareOppHistoryIdsChange={handleUserSelectedCareOppHistoryIdsChange}
            setUserSelectedCareOppHistoryIds={setUserSelectedCareOppHistoryIds}
            setSelectedGaps={setSelectedGaps}
            featureFlagCIS={featureFlagCIS}
        />
        {dataResponse.patientActivities?.length ? (
          <Accordion
            id={`${componentName}-AllCareTeamMessages`}
            defaultActiveKey={activeKey}
          >
            <Card>
              <Card.Header
                eventKey="1"
                id={`${componentName}-CareTeamMessages`}
                className="cursor-p"
              >
                <PlusMinusToggle eventKey="1">
                  <span id={`${componentName}-CareTeamMessagesHeader`}>
                    Recent Care Team Messages (
                    {dataResponse.patientActivities?.length})
                  </span>
                </PlusMinusToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {dataResponse.patientActivities?.length
                    ? dataResponse.patientActivities?.map((activity, idx) => (
                        <Row
                          id={`${componentName}-SecureMessage-${idx}`}
                          key={activity.noteId}
                        >
                          <Col sm={6}>
                            <span
                              id={`${componentName}-SecureMessageSender-${idx}`}
                              className="fw-bold"
                            >
                              {activity.author}
                            </span>
                            <span
                              id={`${componentName}-SecureMessageOrg-${idx}`}
                            >
                              {" - "}
                              {activity.userOrgName}
                            </span>
                          </Col>
                          <Col sm={4}>
                            <span
                              id={`${componentName}-SecureMessageDate-${idx}`}
                            >
                              {formatDateTime(activity.dateTime)}
                            </span>
                          </Col>
                          <Col sm={2}>
                            <a
                              id={`${componentName}-patientProfileLink-${idx}`}
                              href={getPatientProfileLink(
                                patient.id,
                                location,
                                "Notes",
                                true,
                                activity.noteId
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="me-3 float-end"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </Col>
                        </Row>
                      ))
                    : null}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
        {authorizedGapRead || authorizedGapWrite ? (
          <CareOpportunitiesAccordion
            dateOfServiceOverride={visitDate}
            addToSelecedOpportunityParams={navigationParams}
            statuses={dataResponse.careGapsResponse?.statuses || []}
            accordionKey="0"
            resetKeyProp={careOpportunityPkgResetKey}
            activeKey={activeKey}
            opportunities={dataResponse.careGapsResponse?.openCareGaps || []}
            additionalOpportunities={
              dataResponse.careGapsResponse?.closedCareGaps || []
            }
            isSearching={isSearching}
            patient={patient}
            componentName={componentName}
            disableCheckList={validationPkg.selectedItems?.length}
            onChange={(x) => handlePkgChange(x, "careOpportunityPkg")}
            processAddToSelecedOpportunityParamsKeyProp={
              processCareOpportunityPkgAddToSelecedItemParamsKey
            }
            selectedParentChildCareOpportunites={selectedParentChildCareOpportunites}
            setCareOppMsg={(msg)=>{setCareOppMsg(msg)}}
            careOppMsg={careOppMsg}
          />
        ) : null}
        {authorizedRafsRead || authorizedRafsWrite ? (
          <Accordion
            id={`${componentName}-AllSuspects`}
            defaultActiveKey={activeKey}
          >
            <Card>
              <Card.Header
                eventKey="1"
                id={`${componentName}-Suspects`}
                className="cursor-p"
              >
                <PlusMinusToggle eventKey="1">
                  <span id={`${componentName}-SuspectsHeader`}>Suspects</span>
                </PlusMinusToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Row className="mt-3">
                    <Col sm={6} className="mb-3">
                      <div className="mb-3">
                        <label
                          id={`${componentName}-SuspectsTitle`}
                          className="d-block"
                        >
                          Suspects
                        </label>
                        <span id={`${componentName}-SuspectsSelected`}>
                          {selectedSuspects.ids.length} Selected
                        </span>
                      </div>
                      {authorizedRafAdd && (
                        <div className="mt-1 mb-2">
                          {authorizedRafsWrite ? (
                            <Button
                              variant="secondary"
                              id={`${componentName}-AddSuspects`}
                              className="align-top"
                              onClick={() => toggleCreation("suspect")}
                            >
                              <i className="fa fa-plus-circle"></i> Suspects
                            </Button>
                          ) : null}
                          {authorizedGapWrite && patient?.contracted ? (
                            <Button
                              variant="secondary"
                              id={`${componentName}-AddConditions`}
                              className="align-top"
                              onClick={() => toggleCreation("condition")}
                            >
                              <i className="fa fa-plus-circle"></i> Conditions
                            </Button>
                          ) : null}
                        </div>
                      )}
                      {isSearching && (
                        <>
                          <Spinner
                            className="me-3"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </>
                      )}
                      {dataResponse.conditionsResponse?.openConditions.length
                        ? dataResponse.conditionsResponse?.openConditions.map(
                            (disease, idx) => (
                              <div
                                id={`${componentName}-SuspectsSummary-${idx}`}
                                className="d-flex flex-row mb-1"
                                key={disease.conditionSummary.id}
                              >
                                <div className="d-inline-block me-2">
                                  <input
                                    type="checkbox"
                                    id={`${componentName}-SuspectsCheck-${idx}`}
                                    checked={selectedSuspects.ids.includes(
                                      disease.conditionSummary.id
                                    )}
                                    disabled={
                                      validationPkg.selectedItems.length
                                    }
                                    onClick={() =>
                                      updateSelected(disease, "suspects")
                                    }
                                  />{" "}
                                </div>
                                <div className="d-inline-block me-2">
                                  {disease.conditionSummary.validation ===
                                    "REJECTED" && (
                                    <span
                                      id={`${componentName}-SuspectsRejected-${idx}`}
                                      className="text-danger fw-bold"
                                    >
                                      !
                                    </span>
                                  )}
                                  {disease.conditionSummary
                                    .isRecaptureSuspect ? (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          Previously documented condition
                                        </Tooltip>
                                      }
                                    >
                                      <Badge
                                        variant="primary"
                                        id={`${componentName}-SuspectsPrevDocCondition-${idx}`}
                                        className="rounded-circle me-2"
                                      >
                                        P
                                      </Badge>
                                    </OverlayTrigger>
                                  ) : null}
                                  <span
                                    id={`${componentName}-SuspectsYear-${idx}`}
                                  >
                                    {disease.conditionSummary.conditionAndYear}
                                  </span>
                                  <span
                                    id={`${componentName}-SuspectsStatus-${idx}`}
                                  >
                                    ({disease.conditionSummary.status.name})
                                  </span>{" "}
                                  <span
                                    id={`${componentName}-SuspectsDXCode-${idx}`}
                                  >
                                    {disease.conditionSummary.dxCode}
                                  </span>
                                  <span
                                    id={`${componentName}-SuspectsDesc-${idx}`}
                                  >
                                    {disease.conditionSummary.sourceDescription
                                      ? "-Source: " +
                                        disease.conditionSummary
                                          .sourceDescription
                                      : ""}
                                  </span>
                                </div>
                                <div className="d-inline-block text-nowrap">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {" "}
                                        {getSuspectAwaitingChartToolTip(
                                          disease.conditionSummary
                                        )}{" "}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      id={`${componentName}-SuspectsAwaitingChart-${idx}`}
                                      className={getSuspectAwaitingChartContent(
                                        disease.conditionSummary
                                      )}
                                    ></i>
                                  </OverlayTrigger>{" "}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {getSuspectAwaitingClaimToolTip(
                                          disease.conditionSummary
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      id={`${componentName}-SuspectsAwaitingClaim-${idx}`}
                                      className={getSuspectAwaitingClaimContent(
                                        disease.conditionSummary
                                      )}
                                    ></i>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            )
                          )
                        : null}
                      <div className="d-block mb-1">
                        <StyledBadgeBtn
                          pill
                          variant="light"
                          id={`${componentName}-SuspectsCI`}
                          className="border text-dark"
                          onClick={() => {
                            setShowAllSuspects(!showAllSuspects);
                            setSuspectPlusMinus(!isSuspectPlusMinus);
                          }}
                        >
                          <i
                            className={
                              isSuspectPlusMinus ? "fa fa-minus" : "fa fa-plus"
                            }
                          ></i>{" "}
                          Show Closed/ Inactive
                        </StyledBadgeBtn>
                      </div>
                      {dataResponse.conditionsResponse?.closedConditions
                        .length && showAllSuspects
                        ? dataResponse.conditionsResponse?.closedConditions.map(
                            (disease, idx) => (
                              <div
                                id={`${componentName}-SuspectsCISummary-${idx}`}
                                className="d-flex flex-row mb-1"
                                key={disease.conditionSummary.id}
                              >
                                <div className="d-inline-block me-2">
                                  <input
                                    id={`${componentName}-SuspectsCIChecked-${idx}`}
                                    type="checkbox"
                                    style={{ visibility: "hidden" }}
                                    disabled={true}
                                  />{" "}
                                </div>
                                <div className="d-inline-block me-2">
                                  {disease.conditionSummary.validation ===
                                    "REJECTED" && (
                                    <span
                                      id={`${componentName}-SuspectsCIRejected-${idx}`}
                                      className="text-danger fw-bold"
                                    >
                                      !
                                    </span>
                                  )}
                                  <span
                                    id={`${componentName}-SuspectsCIYear-${idx}`}
                                    className="cursor-p"
                                    style={{
                                      color: "#4D66FB"
                                    }}
                                    onClick={() => {
                                      setShowFullHistory(true);
                                      handleUserSelectedSuspectHistoryIdsChange(
                                        disease.conditionSummary.id
                                      );
                                    }}
                                  >
                                    {disease.conditionSummary.conditionAndYear}
                                  </span>
                                  <span
                                    id={`${componentName}-SuspectsCIStatus-${idx}`}
                                  >
                                    ({disease.conditionSummary.status.name})
                                  </span>{" "}
                                  <span
                                    id={`${componentName}-SuspectsCIDXCode-${idx}`}
                                  >
                                    {disease.conditionSummary.dxCode}
                                  </span>
                                </div>
                                <div className="d-inline-block text-nowrap">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {" "}
                                        {getSuspectAwaitingChartToolTip(
                                          disease.conditionSummary
                                        )}{" "}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      id={`${componentName}-SuspectsCIAwaitingChart-${idx}`}
                                      className={getSuspectAwaitingChartContent(
                                        disease.conditionSummary
                                      )}
                                    ></i>
                                  </OverlayTrigger>{" "}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {getSuspectAwaitingClaimToolTip(
                                          disease.conditionSummary
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      id={`${componentName}-SuspectsCIAwaitingClaim-${idx}`}
                                      className={getSuspectAwaitingClaimContent(
                                        disease.conditionSummary
                                      )}
                                    ></i>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </Col>
                    {authorizedRafsWrite && authorizedRafsRead ? (
                      <Col sm={6}>
                        <Row className="border bg-light py-3">
                          <Col>
                            <Form.Group id={`${componentName}-SuspectsStatus`}>
                              <Form.Label
                                id={`${componentName}-SuspectsStatusLabel`}
                                className="d-block"
                              >
                                Status<span className="text-danger">*</span>
                              </Form.Label>
                              <Dropdown value={suspectForm.status}>
                                <Dropdown.Toggle
                                  as={DropDownButton}
                                  cssOpen={false}
                                  id={`${componentName}-SuspectsStatusValue`}
                                  cusWidth="100% !important"
                                  disabled={
                                    isEmpty(selectedSuspects.ids) ||
                                    validationPkg.selectedItems?.length
                                  }
                                >
                                  {suspectForm.status ? (
                                    suspectForm.status.name
                                  ) : (
                                    <span>-Select-</span>
                                  )}
                                  <i className="fa fa-chevron-down" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  as={CustomMenuStyled}
                                  cusHeight={""}
                                >
                                  {dataResponse.conditionsResponse?.statuses
                                    ?.length
                                    ? dataResponse.conditionsResponse?.statuses.map(
                                        (status) => (
                                          <Dropdown.Item
                                            key={status.source}
                                            onClick={() =>
                                              handleSuspectChange(
                                                "status",
                                                status
                                              )
                                            }
                                          >
                                            {status.name}
                                          </Dropdown.Item>
                                        )
                                      )
                                    : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group id={`${componentName}-SuspectsDOS`}>
                              {suspectDosError ? (
                                <Toast>
                                  <Toast.Header>
                                    <strong
                                      id={`${componentName}-SuspectsErrorIcon`}
                                      className="me-auto fw-bold text-danger"
                                    >
                                      <i className="fas fa-exclamation-triangle"></i>{" "}
                                    </strong>
                                    <small
                                      id={`${componentName}-SuspectsErrorText`}
                                    >
                                      {suspectDosError}
                                    </small>
                                  </Toast.Header>
                                </Toast>
                              ) : null}
                              <Form.Label
                                id={`${componentName}-SuspectsDOSLabel`}
                                className="d-block"
                              >
                                Date of Service
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <EQHDatePicker
                                id={`${componentName}-SuspectsDOSValue`}
                                onChange={(e) => {
                                  handleSuspectChange("dateOfService", e);
                                }}
                                min={null}
                                max={format(new Date(), "yyyy-MM-dd")}
                                disabled={isEmpty(selectedSuspects.ids)}
                                value={suspectForm.dateOfService}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
        {authorizedChronicConditions ? (
          <Accordion
            id={`${componentName}-AllChronicConditions`}
            defaultActiveKey={activeKey}
          >
            <Card>
              <Card.Header
                eventKey="3"
                id={`${componentName}-ChronicConditions`}
                className="cursor-p"
              >
                <PlusMinusToggle eventKey="3">
                  <span id={`${componentName}-ChronicConditionsHeader`}>
                    Chronic Conditions
                  </span>
                </PlusMinusToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Row>
                    <Col>
                      <label id={`${componentName}-ChronicConditionsTitle`}>
                        Chronic Conditions
                      </label>
                      {isEmpty(patient.chronicConditionDetailDtos) ? (
                        <span
                          id={`${componentName}-ChronicConditionsSelected`}
                          className="d-block"
                        >
                          No Chronic Conditions
                        </span>
                      ) : (
                        patient.chronicConditionDetailDtos.map((x) => (
                          <Container>
                            <Row>
                              <Col className="px-0">
                                {chronicConditionsLabel(x.level, x.name)}
                              </Col>
                            </Row>
                            <Row className="bg-light border pt-2 pb-2 mb-3">
                              <Col className="px-3">
                                <Row>
                                  <Col>
                                    <div className="d-block fw-bold mb-1">
                                      Actions to Consider
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {x.nextActions.open.map((x) => (
                                      <div className="d-block">
                                        <i className="far fa-info-circle"></i>{" "}
                                        {`${x.name} `}
                                      </div>
                                    ))}
                                    {x.nextActions.closed.map((x) => (
                                      <div className="d-block text-success">
                                        <i className="far fa-check-circle"></i>{" "}
                                        {`${x.name} `}
                                      </div>
                                    ))}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        ))
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
        {authorizedTransitions ? (
          <CareOpportunitiesAccordion
            dateOfServiceOverride={visitDate}
            addToSelecedOpportunityParams={navigationParams}
            statuses={dataResponse.careGapsResponse?.statuses || []}
            accordionKey="4" //Old code skipped 2 and had toc as 4
            resetKeyProp={tocPkgResetKey}
            activeKey={activeKey}
            type={CARE_OPPORTUNITIES_TYPE.toc}
            title="Transitions of Care"
            opportunities={dataResponse.careGapsResponse?.openTOCGaps || []}
            additionalOpportunities={
              dataResponse.careGapsResponse?.closedTOCGaps || []
            }
            isSearching={isSearching}
            patient={patient}
            componentName={componentName}
            disableCheckList={validationPkg.selectedItems?.length}
            onChange={(x) => handlePkgChange(x, "tocPkg")}
            processAddToSelecedOpportunityParamsKeyProp={
              processTOCPkgAddToSelecedItemParamsKey
            }
            selectedParentChildCareOpportunites={selectedParentChildCareOpportunites}
            setCareOppMsg={(msg)=>{setCareOppMsg(msg)}}
            careOppMsg={careOppMsg}
          />
        ) : null}
        {(authorizedGapValidate || authorizedRafValidate) && (
          <ValidationAccordion
            dateOfServiceOverride={visitDate}
            addToSelecedItemParams={navigationParams}
            accordionKey="5" //Old code skipped 2 and had validation as 5
            resetKeyProp={validationPkgResetKey}
            opportunities={[
              ...(dataResponse.careGapsResponse?.validationCareGaps || []),
              ...(dataResponse.careGapsResponse?.validationTOCGaps || []),
            ]}
            suspects={
              dataResponse.conditionsResponse?.validationConditions || []
            }
            isSearching={isSearching}
            componentName={componentName}
            onChange={(x) => handlePkgChange(x, "validationPkg")}
            authorizedGapWrite={authorizedGapWrite}
            authorizedGapRead={authorizedGapRead}
            authorizedGapValidate={authorizedGapValidate}
            authorizedRafValidate={authorizedRafValidate}
            activeKey={activeKey}
            patient={patient}
            disableCheckList={
              careOpportunityPkg.selectedGaps?.length ||
              tocPkg.selectedGaps?.length ||
              selectedSuspects.ids?.length
                ? true
                : false
            }
            processAddToSelecedItemParamsKeyProp={
              processValidationPkgAddToSelecedItemParamsKey
            }
          />
        )}
        {requireA1c || requirePressure || requireAttestation ? (
          <Row className="bg-light border pt-3 w-100 mx-auto mb-3">
            <Col sm={12}>
              <h5 id={`${componentName}-AddDetails`} className="fw-bold">
                Additional Details
              </h5>
            </Col>
            <Col sm={12}>
              <Row>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Label
                      id={`${componentName}-AddDetailsA1CValueText`}
                      className="d-block"
                    >
                      A1C Value
                    </Form.Label>
                    <Row className="g-0">
                      <Col sm={5}>
                        <Form.Control
                          id={`${componentName}-AddDetailsA1CValue1Value`}
                          type="number"
                          name="a1cWholes"
                          value={preventiveCareUpdateResultsDto.a1cWholes}
                          onChange={(e) =>
                            handlePreventiveCareUpdateResultsDtoChange(
                              "a1cWholes",
                              e.target.value
                            )
                          }
                          disabled={!requireA1c}
                        />
                      </Col>
                      <Col sm={1} className="pt-3 text-center fw-bold">
                        .
                      </Col>
                      <Col sm={5}>
                        <Form.Control
                          id={`${componentName}-AddDetailsA1CValue2Value`}
                          type="number"
                          name="a1cValue2"
                          value={preventiveCareUpdateResultsDto.a1cDecimal}
                          onChange={(e) =>
                            handlePreventiveCareUpdateResultsDtoChange(
                              "a1cDecimal",
                              e.target.value
                            )
                          }
                          disabled={!requireA1c}
                        />
                      </Col>
                      {ac1ValueError ? (
                        <Toast>
                          <Toast.Header>
                            <strong className="me-auto fw-bold text-danger">
                              <i
                                id={`${componentName}-AddDetailsA1CValueErrorIcon`}
                                className="fas fa-exclamation-triangle"
                              ></i>{" "}
                            </strong>
                            <small
                              id={`${componentName}-AddDetailsA1CValueErrorText`}
                            >
                              A1C Values must be integers and cannot exceed 20.9
                            </small>
                          </Toast.Header>
                        </Toast>
                      ) : null}
                    </Row>
                  </InputGroup>
                </Col>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Label
                      id={`${componentName}-AddDetailsBloodPressValueText`}
                      className="d-block"
                    >
                      Blood Pressure Value
                    </Form.Label>
                    <Row className="g-0">
                      <Col sm={5}>
                        <Form.Control
                          id={`${componentName}-AddDetailsBloodPress1Value`}
                          type="number"
                          name="bloodPressureSystolic"
                          value={
                            preventiveCareUpdateResultsDto.bloodPressureSystolic
                          }
                          onChange={(e) =>
                            handlePreventiveCareUpdateResultsDtoChange(
                              "bloodPressureSystolic",
                              e.target.value
                            )
                          }
                          disabled={!requirePressure}
                        />
                      </Col>
                      <Col sm={1} className="h1 mb-0 text-center fw-bold">
                        /
                      </Col>
                      <Col sm={5}>
                        <Form.Control
                          id={`${componentName}-AddDetailsBloodPress2Value`}
                          type="number"
                          name="bloodPreasure2"
                          value={
                            preventiveCareUpdateResultsDto.bloodPressureDiastolic
                          }
                          onChange={(e) =>
                            handlePreventiveCareUpdateResultsDtoChange(
                              "bloodPressureDiastolic",
                              e.target.value
                            )
                          }
                          disabled={!requirePressure}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <small>Systolic / Diastolic</small>
                        {bloodPressureError ? (
                          <Toast>
                            <Toast.Header>
                              <strong className="me-auto fw-bold text-danger">
                                <i
                                  id={`${componentName}-AddDetailsBloodPressErrorIcon`}
                                  className="fas fa-exclamation-triangle"
                                ></i>{" "}
                              </strong>
                              <small
                                id={`${componentName}-AddDetailsBloodPressErrorText`}
                              >
                                Must be an integer between 0 and 400
                              </small>
                            </Toast.Header>
                          </Toast>
                        ) : null}
                      </Col>
                    </Row>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ProvDropdown>
                    <Form.Group>
                      {requireAttestation && attestationDDLOptions?.length
                        ? attestationDDLOptions.map((detail, index) => (
                            <>
                              <Form.Label
                                id={`${componentName}-AddDetailsProvAttestationText`}
                                className="d-block"
                              >
                                {detail.caption}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              {detail.caption.includes("OMW") ? 
                              <Filter
                              selected={filterValues}
                              handleChange={(e) => handleAttestationChangeForFilter(e, detail.gapId,detail.abbreviation)}
                              filterDefinition={{
                                type: GENERIC_DDL_TYPE,
                                getHeader: () => "",
                                key: "OMW",
                                componentProps: {
                                  mode: DDLMode.MultipleSelect,
                                  dataMode: DDLDataMode.OneAPICallFilterInUI,
                                  showSearchTextbox: false,
                                  highlightWhenHasValue: false,
                                  showClearButton: true,
                                  getData: (srchTxt) => Promise.resolve([
                                    {
                                      text: "All",
                                      subItems: detail.options.map(
                                        (m) => {
                                          return { text: m.description, value: m.id };
                                        }
                                      ),
                                    },
                                  ]),
                                },
                              }}
                            />:
                              <SimpleDDL
                                id={`${componentName}-AddDetailsProvAttestationValue`}
                                list={detail.options}
                                textSelector={(x) => x.description}
                                valueSelector={(x) => x}
                                getKeyFromListItem={(item) =>
                                  item?.id
                                }
                                onChange={(x) =>
                                  handleAttestationChange(x, detail.gapId)
                                }
                                disabled={!attestationDDLOptions?.length}
                                selectedKey={
                                  preventiveCareUpdateResultsDto
                                    .careGapAdditionalInfos[detail.gapId]?.[0]?.id
                                }
                              />}
                            </>
                          ))
                        : null}
                    </Form.Group>
                  </ProvDropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        <Row className="mb-3">
          <Col sm={12}>
            <label id={`${componentName}-AddNoteText`} className="d-block">
              Add A Note
            </label>
            <div className="form-group m-b-0">
              <textarea
                id={`${componentName}-AddNoteValue`}
                className="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                value={preventiveCareUpdateResultsDto.note}
                onChange={(e) =>
                  handlePreventiveCareUpdateResultsDtoChange(
                    "note",
                    e.target.value
                  )
                }
                disabled={
                  isEmpty(selectedSuspects.ids) &&
                  isEmpty(careOpportunityPkg.selectedGaps) &&
                  isEmpty(validationPkg.selectedItems) &&
                  isEmpty(tocPkg.selectedGaps)
                }
              ></textarea>
            </div>
          </Col>
          <Col sm={8}>
            {requireDocumentation && documentation.length ? (
              <>
                <label
                  id={`${componentName}-RequiredDocText`}
                  className="d-block"
                >
                  Required Documentation
                </label>
                <span id={`${componentName}-RequiredDocValue`}>
                  {documentation.map((doc) => (
                    <p title={doc.description}>{doc.documentation}</p>
                  ))}
                </span>
              </>
            ) : null}
            <Row>
              <Col>
                <FileUpload
                  onChange={handleUploadFileChange}
                  clearStateProp={fileUploadResetCounter}
                />{" "}
                {requireUpload ? (
                  <span
                    id={`${componentName}-fileUploadRequired`}
                    className="text-danger fw-bold ms-1 align-top"
                  >
                    *
                  </span>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            {errors && errors.length ? (
              <EQHErrorToast
                className="float-end"
                errors={errors}
                handleClose={resetCurrentErrors}
              />
            ) : null}
            <Toast
              id={`${componentName}-successToast`}
              show={showSuccessMsg}
              onClose={() => setShowSuccessMsg(false)}
            >
              <Toast.Header>
                <strong
                  id={`${componentName}-successHeader`}
                  className="me-auto text-success"
                >
                  Success
                </strong>
                <small id={`${componentName}-successUpdated`}>
                  Successfully Updated
                </small>
              </Toast.Header>
            </Toast>
          </Col>
        </Row>
        <StyledFooter>
          <Button
            id={`${componentName}-CloseButton`}
            variant="secondary"
            onClick={() => onModalClose()}
          >
            Close
          </Button>{" "}
          <Button
            id={`${componentName}-UpdateButton`}
            variant="primary"
            className="me-0"
            disabled={!isFormValid() || isUpdating}
            onClick={() => updatePatientEvidence()}
          >
            {isUpdating ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Updating...</span>
              </>
            ) : (
              "Update"
            )}
          </Button>
        </StyledFooter>
      </>
    </ErrorBoundary>
  );
};
export default ManageEvidence;
export const DocumentModal = ({ files, type }) => {
  const dispatch = useDispatch();

  const handleClickofLink = (file) => {
    if (file && file.gapDocumentationId) {
      dispatch(getGapDocuemnt(file.gapDocumentationId, file.displayName));
    }
  };
  return (
    <Row>
      <Col>
        <span id={`${componentName}-FileUploadText`}>
          <b id={`${componentName}-FileUploadLabel`}>{type}</b>
          {files &&
            files.length > 0 &&
            files.map((file, idx) => (
              <span
                id={`${componentName}-gapDocumentationId-${idx}`}
                key={`${componentName}-gapDocumentationId-${idx}`}
                className="d-block w-100"
              >
                {file && (
                  <a
                    href="#"
                    id={`${componentName}-gapDocumentationIdLink-${idx}`}
                    onClick={() => handleClickofLink(file)}
                  >
                    {file.displayName ? file.displayName : ""}
                  </a>
                )}
                {"  "}
              </span>
            ))}
        </span>
      </Col>
    </Row>
  );
};
