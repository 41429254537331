import React from "react";

const componentName = "About";

const About = () => {
  const privatePolicyPdf = "/docs/Privacy_Policy.pdf";
  const termsOfUsePdf = process.env.REACT_APP_REST_API_BASE_URL + "/system_files/Terms_of_Use.pdf";

  return (
    <>
      <div className="list-group list-group-flush mb-5">
        <a id={`${componentName}-privacyPolicy`} href={privatePolicyPdf} target="blank" className="list-group-item list-group-item-action border-0">
          <i className="fa-light fa-file me-2"></i> Privacy Policy</a>
        <a id={`${componentName}-termsOfUse`} href={termsOfUsePdf} target="blank" className="list-group-item list-group-item-action border-0">
          <i className="fa-light fa-file me-2"></i> Terms of Use</a>
      </div>
      <div className="position-absolute bottom-0 start-0 px-4">
        <p id={`${componentName}-copyrightText`} className="ps-2">© {new Date().getFullYear()} Equality Health, All rights reserved</p>
      </div>
    </>
  );
};

export default About;
