import React, { useEffect } from "react";

import styled, { css } from "styled-components";
import { theme } from "../../components/themes/theme1";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PersonalInformation from "./PersonalInformation"
import ChangePassword from "./ChangePassword";
import SystemSettings from "./SystemSettings";
import MessageSignature from "./MessageSignature";
import NotificationSettings from "./NotificationSettings";
import { Card } from "react-bootstrap";

const componentName = "AccountSettings";

const AccountSettingsContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
  .nav-tabs .nav-link {
    border: none;
    border-right: 1px solid rgb(210, 210, 210);
    border-radius: 0px;
    padding: 0px 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: rgb(64, 64, 64);
    display: inline-block;
    white-space: nowrap;
  }
  .nav-tabs .nav-link.active {
    margin: -1px 0;
    background-color: #2274A5;
    color: #ffffff;
  }
`;

const AccountSettings = () => {

  useEffect(() => {
  }, []);

  return (
    <>
        <AccountSettingsContainer theme={theme}>
          <Row className="mt-3">
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Tabs className="border mb-3" defaultActiveKey="Personal Information">
                        <Tab
                          id={`${componentName}-personalInformation`}
                          eventKey="Personal Information"
                          title={<CustomTabStyled title="Personal Information" />}
                        >
                          <PersonalInformation/>
                        </Tab>
                        <Tab
                          id={`${componentName}-changePassword`}
                          eventKey="Change Password"
                          title={<CustomTabStyled title="Change Password" />}
                        >
                          <ChangePassword/>
                        </Tab>
                        <Tab
                          id={`${componentName}-systemSettings`}
                          eventKey="System Settings"
                          title={<CustomTabStyled title="System Settings" />}
                        >
                          <SystemSettings/>
                        </Tab>
                        <Tab
                          id={`${componentName}-messageSignature`}
                          eventKey="Message Signature"
                          title={<CustomTabStyled title="Message Signature" />}
                        >
                          <MessageSignature/>
                        </Tab>
                        <Tab
                          id={`${componentName}-notificationSettings`}
                          eventKey="Notification Settings"
                          title={<CustomTabStyled title="Notification Settings" />}
                        >
                          <NotificationSettings/>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </AccountSettingsContainer>
    </>
  );
};

const CustomTab = ({ title, count }) => {
  return (
    <span>
      {title} {count && <span className="badge bg-secondary">{count}</span>}
    </span>
  );
};

const CustomTabStyled = styled(CustomTab)``;

export { AccountSettings };
