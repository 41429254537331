import { actions } from "./actions";
import {setAddNewNote} from "../../../../actions/manageVisitActions";

export function getToggles(dispatch, globalDispatch) {
  return {
    setIsLoading: payload => dispatch({ type: actions.SET_IS_LOADING, payload: payload }),
    setHasMore: payload => dispatch({ type: actions.SET_HAS_MORE, payload: payload }),
    toggleShowAddNote: payload => {
      console.info("toggles-show-new-add-note: ", payload)
      globalDispatch(setAddNewNote(payload));
      dispatch({type: actions.SET_SHOW_ADD_NOTE, payload: payload})
    },
    toggleIsAddingNote: payload => dispatch({type: actions.SET_IS_ADDING_NOTE, payload: payload})
  }
}