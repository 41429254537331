import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import PatientInformation from "./components/patientInformation";
import StatusFilter from "./components/statusFilter";
import CareProgram from "./components/careProgram";
import NewCareProgram from "./components/newCareProgram";
import {getPatientOverview, patientInformationSection, useGetCareManagements, useGetPatientCard, useGetPatientProfileCareOpps, useGetPatientProfileOverview, useGetPatientProfileOverviewTabHeaderCount, useGetPatientProfileSuspects} from "../../../../actions/patientProfileActions";
import { careManagementsReducer } from "../../../../reducers/patientProfile/careManagementsReducer";
import { useCMWorklistContext } from "../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import { setCareProgramModalOpen, setManageVisitPatient, setOldPatientModal } from "../../../../actions/manageVisitActions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../context/authContext";
import { SnoozedModal } from "../../../caremgmt/worklist/components/CMWorklistModals/modalsComponents";
import ManageVisitlModal from "../../../patientProfile/modals/ManageVisitlModal";
import { careOppsReducer } from "../../../../reducers/patientProfile/careOppsReducer";
import { suspectsReducer } from "../../../../reducers/patientProfile/suspectsReducer";
import { patientReducer } from "../../../../reducers/patientProfile/patientReducer";
import { AppPropertiesUtil } from "../../../../utils/localStorageUtil";
import { patientOverviewReducer } from "../../../../reducers/patientProfile/patientOverviewReducer";

const componentName = "carePrograms";

export const CarePrograms = ({ patient, selectedTab }) => {

  const auth = useAuth();
  const {
    assignPatientTo
  } = useCMWorklistContext()
  const dispatch = useDispatch()
  const careManagementsPkg = useGetCareManagements([], careManagementsReducer);
  const [result, setResult] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const[patientInfo, setPatientInfo]= useState({});
  const [patientExtraInfo, setPatientExtraInfo] = useState({});
  const [value, setvalue] = useState('All')
  const [refreshFilter, setRefreshFilter] = useState(true);
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const featureFlagCIS =   AppPropertiesUtil.get("cis.immunizations.enabled");
  const showOldPatientModal = useSelector((state) => state?.manageVisitReducer?.showOldPatientModal);

  const careOppsPkg = useGetPatientProfileCareOpps({}, careOppsReducer);
  const suspectPkg = useGetPatientProfileSuspects({}, suspectsReducer);
  const patientCardPkg = useGetPatientCard({}, patientReducer);

  useEffect(() => {
    console.log(allDataPkgs)
  }, [allDataPkgs])
  

  const allDataPkgs = {
    patientOverview: useGetPatientProfileOverview({}, patientOverviewReducer),
    patientOverviewTabHeaderCount: useGetPatientProfileOverviewTabHeaderCount({}, patientOverviewReducer),
    patientNoteCount: {},
  };

  const reloadPatientOverview = () => {
    allDataPkgs.patientOverview.fetchData(patient?.id);
    allDataPkgs.patientOverviewTabHeaderCount.fetchData(patient?.id);
  };

  const init = () => {
    careOppsPkg.fetchData(patient.id);
    suspectPkg.fetchData(patient.id);
    patientCardPkg.fetchData(patient.id);
    dispatch(setManageVisitPatient(patient));
  };

  const {
    selectPatient,
    toggleAssignPatientTo,
  } = useCMWorklistContext();
  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");

  useEffect(() => {
    if (refreshFilter) {
      filterTheResult(value);
    } else {
      filterTheResult(value)
      setRefreshFilter(true)
    }
  }, [careManagementsPkg.state.data]);

  useEffect(() => {
    toggleAssignPatientTo({ ...assignPatientTo,  refreshFromReassign:false });
    if (patient?.id != null && careManagementsPkg.state.data.length == 0) {
      careManagementsPkg.fetchData(patient.id);
      filterTheResult('All');
    }
  }, [patient, selectedTab]);

  const filterTheResult = (filterApplied = value) => {
    const newResult = [];
    const distantFuture = new Date(8640000000000000)
    setSelectedFilter(filterApplied)
    careManagementsPkg.state.data.sort((a, b) => {
      let dateA = a.closedDate ? new Date(a.closedDate) : distantFuture;
      let dateB = b.closedDate ? new Date(b.closedDate) : distantFuture;
      return dateB - dateA;
    });
    careManagementsPkg.state.data.forEach((record) => {
      switch (filterApplied) {
        case 'Open':
          record.active && newResult.push(record);
          break;
        case 'Closed':
          if (!record.active) {
            newResult.push(record);
            newResult.sort(function compare(a, b) {
              var dateA = new Date(a.closedDate);
              var dateB = new Date(b.closedDate);
              return dateB - dateA;
            });
          }
          break;
        default:
          newResult.push(record);
          break;
      }
    })
    setResult([...newResult]);
  };

  useEffect(() => {
    if (assignPatientTo?.refreshFromReassign) {
      if (patient?.id != null) {
        careManagementsPkg.fetchData(patient.id);
      }
      toggleAssignPatientTo({ ...assignPatientTo,  refreshFromReassign:false });
    }

  }, [assignPatientTo?.refreshFromReassign])
  const stickyRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          // div is now sticky
          stickyRef.current.classList.add('is-sticky');
        } else {
          // div is not sticky
          stickyRef.current.classList.remove('is-sticky');
        }
      },
      { threshold: [1] }
    );

    observer.observe(stickyRef.current);

    // Cleanup the observer on component unmount
    return () => observer.disconnect();
  }, []);

  const patientInformation = (patient) =>{
    if(patient?.id != null){
      patientInformationSection(patient.id)
        .then((res) => {
          setPatientInfo({
            ...res.data
          });
        })
        .catch((err) => {
        });
      getPatientOverview(patient.id)
        .then((res) => {
          setPatientExtraInfo(res.data)
        })
    }
  }

  useEffect(() => {
   patientInformation(patient);
  }, [patient] );

  const refreshCarePrograms = () => {
    if (patient?.id != null && careManagementsPkg.state.data.length == 0) {
      careManagementsPkg.fetchData(patient.id);
      filterTheResult(value);
    }
  }

  const toggleAll = (state) => {
    setExpandAll(state)
    if (state) {
      setOpenStates(result.map((_,idx) => idx));
    } else {
      setOpenStates([])
    }
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  return (
    <>
      <Row>
        <Col>
          <PatientInformation patientInfo={patientInfo} patientExtraInfo={patientExtraInfo}/>
        </Col>
      </Row>
      <div ref={stickyRef} className="d-flex justify-content-between py-4 stickyscroll bg-light">
        <StatusFilter filterApplied={filterTheResult} setvalue={setvalue} value={value} />
        <div className="d-flex gap-3">
          <Button
            variant="secondary"
            id={`${componentName}-assignCarePathButton`}
            onClick={() => {dispatch(setOldPatientModal(true)); reloadPatientOverview();}}
          >
            Update Gap Status
          </Button>
          <Button
            id={`${componentName}-modalBtn`}
            variant="primary"
            onClick={() => {
              selectPatient(patient);
              toggleAssignPatientTo({ show: true, source: "manageVisitCareProgram" });
              dispatch(setCareProgramModalOpen(true))
            }}
            disabled={!authorizedCareProgramWrite}
          >
            New Care Program
          </Button>
          <NewCareProgram careManagementsPkg={careManagementsPkg?.state?.data}
            handleCancel={() => {
              dispatch(setCareProgramModalOpen(false));
              refreshCarePrograms()
            }}
          />
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip>
                Expand / Collapse All
              </Tooltip>
            }
          >
            <span
              bg="light"
              className="border text-dark badge badge-pill mt-2"
              onClick={() => toggleAll(!expandAll)}
              style={{maxWidth: 'fit-content', fontSize: '12px'}}
            >
              <i className='fas fa-angle-double-up'/>
              <i className='fas fa-angle-double-down'/>
            </span>
          </OverlayTrigger>
        </div>
      </div>
      <SnoozedModal />
      <Row className="mb-5">
        <Col>
          {careManagementsPkg && careManagementsPkg.state && careManagementsPkg.state.data
            && result.map((e, idx) =>
            (
              <CareProgram
                careProgram={e}
                patient={patient}
                careManagementsPkg={careManagementsPkg}
                selectedTab={selectedTab}
                patientInformation={patientInformation}
                selectedFilter={selectedFilter}
                setRefreshFilter={setRefreshFilter}
                open={openStates}
                setOpenStates={setOpenStates}
                toggleIndividual={() => toggleIndividual(idx)}
                idx={idx}
                filterResult={filterTheResult}
              />
            )
            )
          }
        </Col>
      </Row>
      <ManageVisitlModal
        show={showOldPatientModal}
        patient={allDataPkgs.patientOverview.state.data || patient}
        close={() => dispatch(setOldPatientModal(false))}
        navigationItems={{
          id: null,
          type: null,
        }}
        activeKey={"0"}
        setChangesMade={setChangesMade}
        confirmationMessage={confirmationMessage}
        setConfirmationMessage={setConfirmationMessage}
        init={init}
        featureFlagCIS={featureFlagCIS}
      />
    </>
  );
};

export default CarePrograms;