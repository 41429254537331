import React, { useState } from "react";

export const SortableTh = ({columnName, sortColumn, fieldName, ...props}) => {
  const [sortOrder, setSortOrder] = useState(fieldName ?'NONE' : '');
  const sortColl = (e) => {
    if(fieldName){
    setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    let so 
    if(sortOrder==='NONE'){
      so = 'ASC'
    }
    else{
      so = sortOrder
    }
    sortColumn(fieldName, so);
    }
  };
  return (
    <th {...props} className={`${sortOrder} cursor-p`} onClick={(e) => sortColl(e)}>
      {columnName}
      {sortOrder === 'NONE' && <i className="fa-regular fa-sort fa-sm text-black-50 ms-2"></i>}
      {sortOrder === 'ASC' && <i className="fa-duotone fa-sort-up fa-sm ms-2"></i>}
      {sortOrder === 'DESC' && <i className="fa-duotone fa-sort-down fa-sm ms-2"></i>}
    </th>
  );
};


