import React from 'react'
import { Col, Table } from 'react-bootstrap'
import { EQHSpinner } from '../../components/EQHSpinner'
import CustomPagination from '../common/CustomPaginaton'

export default function DetailedReport({
  rows,
  pagination,
  paginate,
  isLoading
}) {
  return (
    <>{pagination.total / 20 > 1 &&
      <Col className="mb-3">
        <CustomPagination
          paginateButClicked={paginate}
          MAX_PAGE={Math.ceil(pagination.total / 20)}
          pageNumber={Math.ceil(pagination.page)}
        />
      </Col>
    }
    <div className="table-responsive text-nowrap">
      {
        isLoading
          ? <EQHSpinner />
          : <Table responsive bordered hover>
              <thead>
                <tr>
                  <th scope="col">
                    Care Path Name
                  </th>
                  <th scope="col">
                    Member Name
                  </th>
                  <th scope="col">
                    Member ID
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  rows?.detailedReportList?.map(r => {
                    return (
                      <tr>
                        <td>{r.carePathName}</td>
                        <td>{r.memberName}</td>
                        <td>{r.memberId}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
      }
    </div></>
  )
}
