import axios from "axios";
import { handlePromise } from "./promiseUtils";
import {LocalStorageUtil} from "./localStorageUtil";
import { AUTHENTICATION_KEY } from "../context/authContext";

// use this axios client to call REST APIs, which don't required authenticated user like login, reset password etc..
const loginApiClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_BASE_URL,
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD
  }
});

const login = (username, password, termsAccepted = false) => {
  const params = new URLSearchParams();
  const url = termsAccepted
      ? `/oauth/token?grant_type=password&scope=read&isTermsAndAgreementsAccepted=true`
      : `/oauth/token?grant_type=password&scope=read`;
  params.append("username", username);
  params.append("password", password);
  return handlePromise(
      loginApiClient.post(
          url,
          params
      )
  );
};

const refreshAccessToken = () => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", LocalStorageUtil.getPropertyValue(AUTHENTICATION_KEY, "refresh_token"));
  return handlePromise(
    loginApiClient.post(
      `/oauth/token`,
      params
    )
  );
};

const forgotPassword = email => {
  return handlePromise(loginApiClient.get(`/v1/user-password/forgot/${email}`));
};

const resetPassword = (token, password) => {
  const params = new URLSearchParams();
  params.append("token", decodeURIComponent(token));
  params.append("password", password);
  return handlePromise(loginApiClient.post("/v1/user-password/reset", params));
};

export { login, forgotPassword, resetPassword, refreshAccessToken, loginApiClient as noTokenApiClient };
