import React, { useState } from "react";

import styled, { css } from "styled-components";
import { Spinner, Modal, Form, Button } from "react-bootstrap";

const StyledScreenBtn = styled.i`
  font-size: 18px;
`;

const StyledHeaderDiv = styled.div`
  margin-right: 33px;
`;

export const Modals = ({
  onHide,
  onCreate,
  onClose,
  title,
  titleid,
  show,
  completeBtn,
  closeBtn,
  body,
  size,
  hideClickButton,
  hideFooter,
  disabledClickButton,
  disabledClickCloseButton = false,
  centered,
  referralHeight,
  completeBtnId,
  closeBtnId,
  loading=false,
  renderFooterActions
}) => {
  const [shrinkScreen, setShrinkScreen] = useState(false);
  const onCloseCall = onClose ? onClose : onHide;
  const toggleScreen = () => {
    setShrinkScreen(!shrinkScreen);
  };
  return (
    <Modal onHide={onHide} screenbool={shrinkScreen} referralHeight={referralHeight} show={show} size={size} centered={centered} scrollable titleid={titleid}>
      <Modal.Header>
        <Modal.Title>
          <StyledHeaderDiv className="me-auto">
            <span id={`${titleid}Title`}>{title}</span>
            {title == "Messages" && shrinkScreen ? (
              <StyledScreenBtn
                className="fas fa-expand-arrows-alt"
                onClick={() => toggleScreen()}
              ></StyledScreenBtn>
            ) : title == "Messages" && !shrinkScreen ? (
              <StyledScreenBtn
                className="fas fa-crop-alt"
                onClick={() => toggleScreen()}
              ></StyledScreenBtn>
            ) : null}
          </StyledHeaderDiv>
        </Modal.Title>
        <button id={`${titleid}-Close`} type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer hidden={hideFooter} className="flex-nowrap">
        {renderFooterActions && renderFooterActions()}
        <Button id={closeBtnId} variant="secondary" disabled={disabledClickCloseButton} onClick={onCloseCall}>{closeBtn ? closeBtn : "Close"}</Button>
        <Button
          disabled={disabledClickButton}
          hidden={hideClickButton}
          onClick={onCreate}
          id={completeBtnId}
        >
          {loading ?
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : completeBtn
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modals;
