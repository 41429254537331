import { formatDate } from "../../../utils/util";

export const eventActions = {
  'LANDS_WORKLIST': 'Lands on the Worklist',
  'SELECT_PATIENT': 'Select Assign Patient to',
  'SELECT_TEAM_MEMBER': 'Select a team member',
  'SELECT_EXISTING_PROGRAM': 'Select an existing program',
  'SUBMIT_ASSIGN_PATIENT': 'Submit to assign the patient to a member',

  'SELECT_BULK_MARK': 'Select Bulk Mark as Outreached',
  'SELECT_MARK_OUTREACHED': 'Select Mark as Outreached',
  'CONFIRM_SAVE_OUTREACHED': 'Confirm and click on save to Mark as Outreached',

  'SELECT_UPDATE_GAP': 'Select Update Gap Status',
  'SELECT_COMPLETE_ASSESSMENT': 'Select Complete Assessment',
  'SELECT_REASSIGN_CARE_STEP': 'Select Reassign Care Step',
  'ASSIGN_TEAM_MEMBER': 'Assign team member',
  'CONFIRM_REASSIGN_CARE_STEP': 'Confirm and click on Reassign Care step',
  'CONFIRM_SAVE_ASSESMENT': 'Confirm and click on Save the Assesment',

  'SELECT_REMOVE_WORKLIST': 'Select Remove from WorkList',
  'CONFIRM_REMOVE_WORKLIST': 'Confirm and clicks on remove form Worklist',

  'APPLY_EVENT_TYPE_FILTER': 'Apply Event Type Filter',
  'APPLY_PAYOR_FILTER': 'Apply Payor Filter',
  'APPLY_CARE_STEP_FILTER': 'Apply Care Step Filter',
  'APPLY_ASSIGN_TO_FILTER': 'Apply Assign To Filter',
  'APPLY_RISK_LEVEL_FILTER': 'Apply Patient Risk Level Filter',
  'APPLY_EXISTING_PROGRAM_FILTER':'Apply Existing Program Filter',
  'APPLY_DUE_DATE_FILTER': 'Apply Due Date filter',
  'APPLY_PROVIDER_FILTER': 'Apply Provider Filter',
  'APPLY_PATIENT_STATUS_FILTER': 'Apply Patient Status filter',
  'APPLY_READMIT_FILTER': 'Apply Readmit Filter',
  'APPLY_CARE_PATH_STATUS_FILTER': 'Apply Care Path Status Filter',
  'APPLY_HOSPITAL_FILTER': 'Apply Hospital Filter',
  'CLEAR_ALL_FILTER': 'Clear All filter',

  'SAVE_DEFAULT': 'Save a default view',
  'CLICK_SAVE_WORKLIST': 'Click on Save Custom Worklist',
  'DELETE_WORKLIST': 'Delete a Custom Worklist',
  'EXPORT_CSV':'Export a Csv',
  'CLICK_VIEW_AS': 'Click on View As',
  'SELECT_UPDATE_REFERRAL': 'Select update Referral status',
  'CONFIRM_SAVE_REFERRAL': 'Confirm and click on Save Referral',
  'APPLY_SOURCE_FILTER':'Apply Source Filter',
  'APPLY_ZONE_FILTER':'Apply Zone Filter',
  'APPLY_ENGAGE_PCP_FILTER':'Apply Engage with PCP Filter',
  'CONFIRM_SAVE_WORKLIST': 'Confirm and click on Save Custom Worklist',
  'CONFIRM_DELETE_WORKLIST': 'Confirm and click on Delete Custom Worklist',
  'CLICK_REASSIGN': 'Click on reassign',
  'SELECT_EXPORT_ACTIVATOR': 'Select export to activator'
}

export const alternativeFilters = {
  'Patient Risk Level': 'Risk Level',
  'sourceNew': 'source',
  'Existing Program': 'program'
}

export const filters = [
  "Payor",
  "ADT Disposition",
  "Type of Referral",
  "zone",
  "Risk Level new",
  "sourceNew",
  "Readmit",
  "lostMember",
  "existingProgram",
  "Days Since Last ADT",
  "PCP Filter On Full Name",
  "Member Name",
  "Birth Date Comp",
  "Next upcoming Visit Date"
]

export const chaplainFilters = [
  'Payor',
  'Risk Level new',
  "lostMember",
  'Member Name',
  "Birth Date Comp",
  'Care Step Type',
  'Patient Status Unique Selection',
  'Care Program Step Due Date',
  'Care Path Status',
  'Care Program Name',
]

export const CHWFilters = [
  'Payor',
  'Risk Level new',
  'lostMember',
  'Days Since Last ADT',
  'Member Name',
  'Birth Date Comp',
  'Care Step Type',
  'Patient Status Unique Selection',
  'Care Program Step Due Date',
  'Care Path Status',
  'Care Program Name',
]

export const clinicalCoordinatorFilters = [
  'Payor',
  'Risk Level new',
  'Readmit',
  'lostMember',
  'Days Since Last ADT',
  'Member Name',
  'Birth Date Comp',
  'Care Step Type',
  'Patient Status Unique Selection',
  'Care Program Step Due Date',
  'Care Path Status',
  'Care Program Name',
]


export const hospitalLiaisonsFilters = [
  'Payor',
  'Risk Level new',
  'lostMember',
  'Days Since Last ADT',
  'Member Name',
  "Birth Date Comp",
  "Care Step Type",
  "Patient Status Unique Selection",
  'Care Program Step Due Date',
  'Hospital',
  'Care Path Status',
]


export const careSpecialistFilters = [
  'Care Step Type',
  'Patient Status Unique Selection',
  'Provider',
  'Care Program Step Due Date',
  'Care Path Status',
  'Next upcoming Visit Date',
  'Type of Referral',
]

export const getFilters = (role) => {
  switch (role) {
    case 'Chaplains':
      return chaplainFilters
    case 'Care Specialists':
      return careSpecialistFilters
    case 'Community Health Workers':
      return CHWFilters
    case 'Clinical Coordinators':
      return clinicalCoordinatorFilters
    case 'Hospital Liaisons':
      return hospitalLiaisonsFilters
    default:
      return filters
  }
}

export const viewAsNonMembersTeam = [
]

export const isTeamInViewAsNonMembersList = (teamName) => {
  return viewAsNonMembersTeam.some(team => team.teamName === teamName);
};

export const getPatientCardMarks = (patient) => {
  return [
    {
      text: 'Readmitted',
      customClassName: 'fa-solid fa-repeat me-2 text-muted',
      shouldBeRendered: patient?.reAdmission
    },
    {
      text: 'Not Engaged with PCP',
      customClassName: 'fa-regular fa-triangle-exclamation me-2 text-muted',
      shouldBeRendered: !patient?.engagedWithPCP
    },
    {
      text: "Next PCP Visit: " + formatDate(
        patient?.nextNearestAppointmentStartDate),
      customClassName: 'fa-solid fa-stethoscope text-muted',
      shouldBeRendered: patient?.nextNearestAppointmentStartDate
    }
  ];
}

export const sortItems = (role, adminRoles) => [
  {
    value: "Default Sort",
    text: "Default Sort",
    hasDivider: false,
    condition: true
  },
  {
    value: "Name (A-Z)",
    text: "Name (A-Z)",
    hasDivider: false,
    condition: [...adminRoles, 'Hub Coordinator', 'Hospital Liaisons', 'Clinical Coordinators', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Name (Z-A)",
    text: "Name (Z-A)",
    hasDivider: false,
    condition: [...adminRoles, 'Hub Coordinator', 'Hospital Liaisons', 'Clinical Coordinators', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Risk Level (Lowest First)",
    text: "Risk Level (Lowest First)",
    hasDivider: false,
    condition: !['Hospital Liaisons', 'Care Specialists', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Risk Level (Highest First)",
    text: "Risk Level (Highest First)",
    hasDivider: false,
    condition: !['Hospital Liaisons', 'Care Specialists', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Due Date (Most Recent)",
    text: "Due Date (Most Recent)",
    hasDivider: false,
    condition: ![...adminRoles, 'Hub Coordinator'].includes(role)
  },
  {
    value: "Due Date (Oldest)",
    text: "Due Date (Oldest)",
    hasDivider: false,
    condition: ![...adminRoles, 'Hub Coordinator'].includes(role)
  },
  {
    value: "Event Type (A-Z)",
    text: "Event Type (A-Z)",
    hasDivider: false,
    condition: false
  },
  {
    value: "Event Type (Z-A)",
    text: "Event Type (Z-A)",
    hasDivider: false,
    condition: false
  },
  {
    value: "Next Upcoming Visit Date (Most Recent)",
    text: "Next Upcoming Visit Date (Most Recent)",
    hasDivider: false,
    condition: ['Care Specialists'].includes(role)
  },
  {
    value: "Next Upcoming Visit Date (Oldest)",
    text: "Next Upcoming Visit Date (Oldest)",
    hasDivider: false,
    condition: ['Care Specialists'].includes(role)
  },
  {
    value: "Days Care Path Assigned (Most Recent)",
    text: "Days Care Path Assigned (Most Recent)",
    hasDivider: false,
    condition: ['Care Specialists', 'Clinical Coordinators', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Days Care Path Assigned (Oldest)",
    text: "Days Care Path Assigned (Oldest)",
    hasDivider: false,
    condition: ['Care Specialists', 'Clinical Coordinators', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Days Care Path Enrolled (Most Recent)",
    text: "Days Care Path Enrolled (Most Recent)",
    hasDivider: false,
    condition: ['Care Specialists', 'Clinical Coordinators', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Days Care Path Enrolled (Oldest)",
    text: "Days Care Path Enrolled (Oldest)",
    hasDivider: false,
    condition: ['Care Specialists', 'Clinical Coordinators', 'Hospital Liaisons', 'Community Health Workers', 'Chaplains'].includes(role)
  },
  {
    value: "Event Date (Most Recent)",
    text: "Event Date (Most Recent)",
    hasDivider: false,
    condition: ['Hub Coordinator'].includes(role)
  },
  {
    value: "Event Date (Oldest)",
    text: "Event Date (Oldest)",
    hasDivider: false,
    condition: ['Hub Coordinator'].includes(role)
  },
];

export const bulkActionsItems = (role) => ([
  {
    value: 'Assign',
    text: 'Assign Patient to...',
    visible: true
  },
  {
    value: 'Remove',
    text: 'Remove from Worklist',
    hasDivider: true,
    visible: (role !== 'Clinical Coordinators')
  },
]);

export const defaultItems = (patient) => ([
  {
    value: "Work on Care Step",
    text: "Work on Care Step",
    condition: () => patient?.carePathStepDto?.title ? true : false,
    hasDivider: false
  },
  {
    value: "Assign Patient to...",
    text: "Assign Patient to...",
    condition: () => true,
    hasDivider: false
  },
  {
    value: "Update Referral Status",
    text: "Update Referral Status",
    condition: () => patient?.source === "REFERRAL",
    hasDivider: false
  },
  {
    value: "Remove from Worklist",
    text: "Remove from Worklist",
    condition: () => true,
    hasDivider: true
  }
]);

export const digitalOutreacherItems = [
  {
    value: "Mark as Outreached",
    text: "Mark as Outreached",
    condition: () => true,
    hasDivider: false
  }
];

export const chaplainItems = (patient) => ([
  {
    value: "Work on Care Step",
    text: "Work on Care Step",
    condition: () => patient?.carePathStepDto?.title ? true : false,
    hasDivider: false
  },
  {
    value: "Update Gap Status",
    text: "Update Gap Status",
    condition: () => true,
    hasDivider: false,
    extra:{
      // lines 307 to 309 temporary commented due to VEGA-11794
      // ['data-bs-toggle']:"offcanvas",
      // ['data-bs-target']:"#patientDetailsModal",
      // ['aria-controls']:"patientDetailsModal,"
    }
  },
  {
    value: "Perform Assessment",
    text: "Perform Assessment",
    condition: () => true,
    hasDivider: false
  }
])

export const CHWItems = (id, patient) => ([
  {
    value: "Work on Care Step",
    text: "Work on Care Step",
    condition: () => patient?.carePathStepDto?.title ? true : false,
    hasDivider: false
  },
  {
    value: "Update Gap Status",
    text: "Update Gap Status",
    condition: () => true,
    hasDivider: false,
    extra: {
      // lines 350 to 353 temporary commented due to VEGA-11794
      // ['data-bs-toggle']:"offcanvas",
      // ['data-bs-target']:"#patientDetailsModal",
      // ['aria-controls']:"patientDetailsModal,"
    }
  },
  {
    value: "Perform Assessment",
    text: "Perform Assessment",
    condition: () => true,
    hasDivider: false
  }
])

export const clinicalCoordinatorItems = (patient) => ([
  {
    value: "Assign New Care Program",
    text: "Assign New Care Program",
    condition: () => true,
    hasDivider: false
  },
  {
    value: "Reassign Care Step",
    text: "Reassign Care Step",
    condition: () => true,
    hasDivider: false
  },
  {
    value: "Work on Care Step",
    text: "Work on Care Step",
    condition: () => patient?.carePathStepDto?.title ? true : false,
    hasDivider: false
  },
  {
    value: "Update Gap Status",
    text: "Update Gap Status",
    condition: () => true,
    hasDivider: false
  },
  {
    value: "Perform Assessment",
    text: "Perform Assessment",
    condition: () => true,
    hasDivider: false
  }
])

export const hospitalLiaisonsItems = (patient) => ([
  {
    value: "Work on Care Step",
    text: "Work on Care Step",
    condition: () => !!patient?.carePathStepDto?.title,
    hasDivider: false
  },
  {
    value: "Update Gap Status",
    text: "Update Gap Status",
    condition: () => true,
    hasDivider: false
  },
  {
    value: "Perform Assessment",
    text: "Perform Assessment",
    condition: () => true,
    hasDivider: false
  },
])

export const getActions = (role) => {
  let actions = {
    showMarkAsOutreached: false
  };

  switch (role) {
    default:
      return actions
  }
}

const showSaveAsDefault = (role) => {
  const excludedRoles = [
    'Hospital Liaisons',
    'Care Specialists',
    'Community Health Workers',
    'Clinical Coordinators',
    'Chaplains'
  ]
  return !excludedRoles.includes(role);}

const showExportCSV = (role) => {
  const excludedRoles = [
    'Hospital Liaisons',
    'Care Specialists',
    'Community Health Workers',
    'Clinical Coordinators',
    'Chaplains'
  ]
  return !excludedRoles.includes(role);
}

export const getGeneralActions = (role, {
  disableExportToCsv = true,
  showDeleteCustomWorklist = true,
  showSaveAsCustomWorklist = true,
  disableExportToActivator = true,
}) => [
  {
    value: "Save as Default View",
    text: "Save as Default View",
    hasDivider: false,
    disabled: false,
    condition: showSaveAsDefault(role) && !showDeleteCustomWorklist
  },
  {
    value: "Save as Custom Worklist",
    text: "Save as Custom Worklist",
    hasDivider: false,
    disabled: false,
    condition: showSaveAsCustomWorklist
  },
  {
    value: "Export to CSV",
    text: "Export to CSV",
    hasDivider: false,
    disabled: disableExportToCsv,
    condition: showExportCSV(role)
  },
  {
    value: "Delete Custom Worklist",
    text: "Delete Custom Worklist",
    hasDivider: true,
    disabled: false,
    condition: showDeleteCustomWorklist
  },
  {
    value: "Export to Activator",
    text: "Export to Activator",
    hasDivider: false,
    disabled: disableExportToActivator,
    condition: false
  }
];

export const URL_TEAM_LEAD = {
  searchPatient:  `/v1/hub-workcenter/adt-referrals-worklist`
}

export const URL_DEFAULT = {
  carePathsOpen: `/v1/patient-profile/open-care-paths?patientId=`,
  carePrograms: `/v1/hub-workcenter/programs`,
  declinedReasons: `/v1/hub-workcenter/decline-reasons`,
  saveActivity: `/v1/hub-workcenter/add-activity`,
  exportToCSV: `/v1/export-patients/work-list`,
  exportToActivator: `/v1/export-patients/work-list`,
  exportSummary: `/v1/patient-card/export-summary`,
  saveCustomWorkList: `/v1/custom-filtered-list/custom-filtered-lists`,
  searchPatient: `/v1/hub-workcenter/adt-referrals-worklist`,
  deleteCustomWorkList: `/v1/custom-filtered-list/custom-list?id=`,
  teamCapacity: `/v1/hub-workcenter/teams-capacity`,
  careManagementTeams: `/v1/care-management/recommended-teams`,
  assignPatient: `/v1/hub-workcenter/assign`,
  patientTemplates: `/v1/assessments/patient-templates?patientId=`,
  saveAssessment: `/v1/assessments/save`,
  worklistDefaultView: `/v1/worklist-default-view?quickList=`,
  saveWorklistDefaultView: `/v1/worklist-default-view/save`,
  campaigns: `/v1/configuration/care-management-configuration-filter?dropListName=CONFIGURATION_OUTREACHED_CAMPAIGNS`,
}

export const isCBOAccessAllowed = (rolesArray) =>{
  const CBO_ALLOWED_ROLES = ['Care Management Admin', 'EHN Admin Role', 'EHN Program Lead', 'EHN Program Lead Managers',
'EHN Role', 'Super User Access', 'TXEH Admin Role', 'TXEH Program Lead Managers', 'TXEH Program Leads', 'Admin Role',
'Care Management']
return rolesArray.some(r=> CBO_ALLOWED_ROLES.includes(r))
}
