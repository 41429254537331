import {actions} from "./actions";

export function getSetters(dispatch) {
    return {
        setAssessments: payload => dispatch({ type: actions.SET_ASSESSMENTS, payload: payload }),
        setPatientId: payload => dispatch({ type: actions.SET_PATIENT_ID, payload: payload }),
        setMode: payload => dispatch({ type: actions.SET_MODE, payload: payload }),
        setSelectedTemplate: payload => dispatch({ type: actions.SET_SELECTED_TEMPLATE, payload: payload }),
        setfillAssessment: payload => dispatch({ type:actions.SET_FILL_ASSESSMENT, payload: payload }),
        setIndividualAssessmentCard: payload => dispatch({ type: actions.SET_INDIVIDUAL_ASSESSMENT_CARD, payload: payload }),
        setExportParams: payload => dispatch({ type: actions.SET_EXPORT_PARAMS, payload: payload }),
        setExportData: payload => dispatch({ type: actions.SET_EXPORT_DATA, payload: payload }),
        setFilterParams: payload => dispatch({ type: actions.SET_FILTER_PARAMS, payload: payload }),
        setSortParams: payload => dispatch({ type: actions.SET_SORT_PARAMS, payload: payload }),
        setTotalSize: payload => dispatch({ type: actions.SET_TOTAL_SIZE, payload: payload })
    }
}