import React, { useEffect, useState } from "react";
import { Pagination, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DatePicker from "react-datepicker";

const componentName = "CalendarPagination";

export const CALENDAR_VIEW_PAGINATION_TYPE = "CALENDAR_VIEW";
export const CARD_VIEW_PAGINATION_TYPE = "CARD_VIEW";
export const CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE = "CALENDAR_WEEKLY_VIEW";
const WEEKLY_DATEPICKER_PROPS = { dateFormat: "ww/yyyy", showWeekNumbers: true, showWeekPicker: true }
const MONTHLY_DATEPICKER_PROPS = { dateFormat: "MMMM/yyyy", showMonthYearPicker: true, showFullMonthYearPicker: true, showFourColumnMonthYearPicker: true };
const DAILY_DATEPICKER_PROPS = {}

export const CalendarPagination = ({
  type,
  dateObject,
  setDateObject,
  paginateButtonClicked,
  todayButton = false
}) => {
  useEffect(() => {
    setSelectedDate(dateObject);
    handleTypeChanged(type);
  }, [type, dateObject]);

  const [selectedDate, setSelectedDate] = useState(new Date(dateObject));
  const [datePickerProps, setDatePickerProps] = useState(MONTHLY_DATEPICKER_PROPS);

  const handleTypeChanged = (paginationType) => {
    if (paginationType === CALENDAR_VIEW_PAGINATION_TYPE) {
      setDatePickerProps(MONTHLY_DATEPICKER_PROPS);
    } else if (paginationType === CARD_VIEW_PAGINATION_TYPE) {
      setDatePickerProps(DAILY_DATEPICKER_PROPS);
    } else if (paginationType === CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE) {
      setDatePickerProps(WEEKLY_DATEPICKER_PROPS);
    }
  }

  const paginate = (i) => {
    let dt = new Date(dateObject);
    if (i === "next") {
      if (type === CALENDAR_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setMonth(dt?.getMonth() + 1)));
      } else if (type === CARD_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setDate(dt?.getDate() + 1)));
      } else if (type === CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setDate(dt?.getDate() + 7)));
      }
    } else if (i === "prev") {
      if (type === CALENDAR_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setMonth(dt?.getMonth() - 1)));
      }
      if (type === CARD_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setDate(dt?.getDate() - 1)));
      } else if (type === CALENDAR_WEEKLY_VIEW_PAGINATION_TYPE) {
        setDateObject(new Date(dt.setDate(dt?.getDate() - 7)));
      }
    } else if (i instanceof Date) {
      dt = i;
      setDateObject(dt);
    }

    paginateButtonClicked(dt);
  };

  return (
    <div className="d-flex align-items-center">
      <Pagination className="rounded-3 align-items-center">
        <Pagination.Prev id={`${componentName}-prevDay`} onClick={() => paginate("prev")} />
        <DatePicker
          selected={selectedDate}
          id={`${componentName}-DateValue`}
          className="border-0"
          onChange={(date) => paginate(date)}
          {...datePickerProps}
        />
        <Pagination.Next id={`${componentName}-nextDay`} onClick={() => paginate("next")} />
      </Pagination>
      {
        todayButton && <Button className="ms-2" variant='secondary' onClick={() => paginate(new Date())}>Today</Button>
      }
    </div>
  );
};

export default CalendarPagination;
