import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Pagination} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import {Button} from "../../components/buttons";
import axios from "../../utils/apiClient";
import {shareUserQuicklist} from "../../actions/patientSearch/patientSearchActions";
import {GET_PATIENTS, SHOW_REQUEST_ALERT} from "../../actions/types";
import {CUSTOM_LIST_URL} from "./QuickList";
import * as Icon from 'react-bootstrap-icons';
import Spinner from "react-bootstrap/Spinner";

export const USER_PROFILES_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-profile/getUserProfiles";

const MessageCheckbox = styled.input`
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
`;

const StyledSearchWrapper = styled(Form.Group)`
  margin-right: auto;
  .share-pagination .page-link {
    color: #6c757d;
    background-color: #fff;
    border-color: #dee2e6;
    padding: 0 9px;
    margin-left: 0;
    line-height: 1;
    color: #404040;
    border: 1px solid #dee2e6;
    height: 31px;
    font-size: 26px;
  }
  .share-pagination label {
    line-height: 28px;
  }
`;

const StyledResultsWrapper = styled(Container)`
  max-height: 35vh !important;
  overflow: auto;
`;

export const WORKLIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-work-list/work-list";

export const SHARE_WORKLIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-work-list/work-list/share";

const ShareWorklist = (props) => {
  const getSharedWith = () => {
    let sharedWithMeta = [];
    if (props.customListDto && props.customListDto.sharedWithUsers) {
      let users = props.customListDto.sharedWithUsers;
      const keys = Object.keys(props.customListDto.sharedWithUsers);
      //const keys = props.customListDto.sharedWithUsers.map(user => user.getUserAccountId);
      let values = [];
      for (let i = 0; i < keys.length; i++) {
        values.push({
          id: keys[i],
          userName: users[keys[i]],
        });
      }
      sharedWithMeta = values;
      let newState = selectedNameList;
      newState.push(...values)
      setSelectedNameList([...newState]);
    }
    setSharedWith(sharedWithMeta);
  };

  const [selectedNameList, setSelectedNameList] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [unshared, setUnshared] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sharedWith, setSharedWith] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [retreivedShared, setRetrievedShared] = useState(false);
  const [showPageIcon, setShowPageIcon] = useState(false);
  const [descendingEmail, setDescendingEmail] = useState(null);
  const [descendingFullname, setDescendingFullname] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadUserList = () => {
    if (sharedWith.length) {
      let newArr = sharedWith.map((user) => {
        return user.id;
      });
      setSelectedUsers(newArr);
    }
    getUserProfiles(page);
  };

  useEffect(() => {
    if (props.shareModal === true) {
      loadUserList();
    }
  }, [props.shareModal]);

  useEffect(() => {
    getSharedWith();
  }, [props.customListDto]);

  const onHide = async () => {
    await setName("");
    await setEmail("");
    await setUsers([]);
    await setSelectAll(false);
    setEmail("");
    setName("");
    setPage(1);
    setTotalPage(1);
    setSelectedUsers([]);
    //setSharedWith([]);
    setSelectAll(false);
    setShowPageIcon(false);
    props.setShareModal(false);
    setSelectedNameList([]);
    setDescendingFullname(null);
    setDescendingEmail(null);
  };

  const isUnshare = () => {
    if (sharedWith.map != null) {
      let sharedWithArray = sharedWith.map(obj => obj.id);
      let unshared = sharedWithArray.length == 0 ? false : !sharedWithArray.every(selected => selectedUsers.includes(selected));

      return unshared;
    }
    return false;
  };

  const onSubmit = () => {
    let getUrl = CUSTOM_LIST_URL;
    let postUrl = null;

    if (props.customWorklistName != null) {
      getUrl = WORKLIST_URL;
      postUrl = SHARE_WORKLIST_URL
    }

    shareUserQuicklist(selectedUsers, props.customListDto.id, postUrl)
      .then((response) => {
        axios
          .get(getUrl + "?id=" + props.quicklistId, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            props.setCustomListDto(response.data);
            onHide();
          })
          .catch((error) => {
            props.setModalTitle("Error");
            props.setModalSize("lg");
            props.setModalMessage(error.response.data.message);
            onHide();
          });
      })
  };

  const getUserProfilesOnInputChange = (queryPage) => {
    const timeout = setTimeout(() => {
      setPage(queryPage);
      setShowPageIcon(false);
      getUserProfiles(queryPage);
    }, 1600);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    loadUserList();
  }, [sharedWith]);

  useEffect(() => {
    if(props.shareModal){
      getUserProfilesOnInputChange(1);
    }
  }, [name, email]);

  useEffect(() => {
    if(props.shareModal){
      getUserProfiles(1);
    }
  }, [descendingEmail, descendingFullname]);

  const handleUserToggle = (id) => {
    let newArr;
    if (selectedUsers.includes(id)) {
      newArr = selectedUsers.filter((i) => i !== id);
      setSelectedUsers(newArr);
    } else {
      setSelectedUsers([id].concat(selectedUsers));
      let hasName = selectedNameList.filter((obj) => obj.id === id);
      if (!hasName || hasName.length === 0) {
        let selectedUserName = users.filter(user => user.userAccountId === id).map(user => user.lastName + " " + user.firstName);
        setSelectedNameList([{id: id, userName: selectedUserName}].concat(selectedNameList));
      }
    }
  };

  const handleSelectAll = () => {
    let tempArr = [];
    let tempNameArr = [];
    if (!selectAll) {
      users.forEach((user) => {
        if (!selectedUsers.includes(user.userAccountId)) {
          tempArr.push(user.userAccountId);
          let hasName = selectedNameList.filter((obj) => obj.id === user.userAccountId);
          if (!hasName || hasName.length === 0) {
            let selectedUserName = user.lastName + " " + user.firstName;
            tempNameArr.push({id: user.userAccountId, userName: selectedUserName});
          }
        }
      });
      setSelectAll(true);
      setSelectedUsers(selectedUsers.concat(tempArr));
      setSelectedNameList(selectedNameList.concat(tempNameArr));
    } else {
      let removeArr = selectedUsers;
      let userArr = [];
      users.forEach((user) => {
        userArr.push(user.userAccountId);
      });
      removeArr = removeArr.filter((id) => !userArr.includes(id));
      setSelectAll(false);
      setSelectedUsers(removeArr);
    }
  };

  const checkSharedStatus = (id) => {
    if (selectedUsers.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  const getUserProfiles = (queryPage) => {
    setShowSpinner(true);
    const body = {
      page: queryPage,
      pageSize: 25,
      sortingCriteria: [],
      filters: [
        {
          fieldName: "fullName",
          value: name,
          valuePresentation: null,
          comparisonMode: "EQUAL_TO",
          nearScope: "",
        },
        {
          fieldName: "email",
          value: email,
          valuePresentation: null,
          comparisonMode: "EQUAL_TO",
          nearScope: "",
        },
      ],
    };

    if(descendingFullname != null){
      body.sortingCriteria.push({
        sortAttribute: "fullName",
        desc: descendingFullname
      });
    }
    if(descendingEmail != null){
      body.sortingCriteria.push({
        sortAttribute: "email",
        desc: descendingEmail
      });
    }

    axios
      .post(USER_PROFILES_URL, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUsers(response.data.content);
        var totalPageSize = Math.ceil(
          response.data.totalSize / response.data.pageSize
        );
        if (totalPageSize === 0) {
          totalPageSize = 1;
        }
        setTotalPage(totalPageSize);
        setShowSpinner(false);
      })
      .catch((error) => {
        props.setModalTitle("Error");
        props.setModalSize("lg");
        props.setModalMessage(error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  const leftButton = () => {
    const value = page - 1;
    setPage(value);
    getUserProfiles(value);
  };

  const rightButton = () => {
    const value = page + 1;
    setPage(value);
    getUserProfiles(value);
  };

  const onChangePage = (e) => {
    const value = e.target.value;
    let pattern = /^[0-9]*$/;
    if (value.match(pattern)) {
      setPage(value);
      if (value > totalPage) {
        setShowPageIcon(true);
        getUserProfilesOnInputChange(totalPage);
      } else if (value < 1) {
        setShowPageIcon(true);
        getUserProfilesOnInputChange(1);
      } else {
        getUserProfilesOnInputChange(value);
      }
    } else {
      setShowPageIcon(true);
      getUserProfilesOnInputChange(1);
    }
  };

  let modalTitle = "Share Custom List with ...";
  let modalDescription = "Custom list already shared with:";

  if (props.customWorklistName != null) {
    modalTitle = "Share Member Worklist with ...";
    modalDescription = "Member worklist already shared with:";
  }

  const getCurrentlySelectedUsers = () => {
    return selectedNameList.filter(user => checkSharedStatus(user.id)).map(user => user.userName).join(", ");
  };
  return (
    <Modal size="lg" show={props.shareModal} onHide={() => onHide()}>
      <Modal.Header closeButton style={{backgroundColor: "#F9F9FA"}}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} className="mb-3 fw-bold">
            {modalDescription}{" "}
            {sharedWith.length
              ? sharedWith.map((user) => user.userName).join(", ")
              : null}
          </Col>
        </Row>
        {getCurrentlySelectedUsers().length > 0 && (
          <Row>
            <Col sm={12} className="mb-3 fw-bold">
              Currently selected users: {getCurrentlySelectedUsers()}
            </Col>
          </Row>
        )}
        <Form>
          <Row className="mb-3">
            <Col>
              <StyledSearchWrapper>
                <Form.Label className="fw-bold d-block">
                  Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeHolder="All"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </StyledSearchWrapper>
            </Col>
            <Col>
              <StyledSearchWrapper>
                <Form.Label className="fw-bold d-block">
                  Email
                </Form.Label>
                <Form.Control
                  type="text"
                  placeHolder="All"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </StyledSearchWrapper>
            </Col>
            <Col>
              <StyledSearchWrapper>
                <Form.Label className="fw-bold d-block">
                  &nbsp;
                </Form.Label>
                <Pagination className="float-end share-pagination">
                  <Row className="no-gutters">
                    <Col sm={2}>
                      <Pagination.Prev
                        onClick={() => leftButton()}
                        disabled={page <= 1}
                        className="d-inline-block"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        className="form-control form-control-sm me-2"
                        type="number"
                        min={1}
                        value={page}
                        onChange={(e) => onChangePage(e)}
                      />
                    </Col>
                    <Col className="text-center">
                      <Form.Label className="me-2">of</Form.Label>
                      <Form.Label>{totalPage}</Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Pagination.Next
                        onClick={() => rightButton()}
                        disabled={page >= totalPage}
                        className="d-inline-block"
                      />
                    </Col>
                  </Row>
                </Pagination>
              </StyledSearchWrapper>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            {showSpinner && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
            <p>
              <b>Select Users:</b>
            </p>
            <StyledResultsWrapper className="px-0">
              <Table striped bordered hover>
                <th>
                  <MessageCheckbox
                    checked={selectAll}
                    type="checkbox"
                    onClick={() => handleSelectAll()}
                  />
                </th>
                <th onClick={() => {setDescendingFullname(descendingFullname == null ? false : !descendingFullname); setDescendingEmail(null);}}>
                  Name {descendingFullname != null && (descendingFullname ? <Icon.ArrowDownShort/> : <Icon.ArrowUpShort/>)}
                </th>
                <th onClick={() => {setDescendingEmail(descendingEmail == null ? false : !descendingEmail); setDescendingFullname(null);}}>
                  Email {descendingEmail != null && (descendingEmail ? <Icon.ArrowDownShort/> : <Icon.ArrowUpShort/>)}
                </th>
                <tbody>
                {users.map((user) => {
                  return (
                    <tr key={user.userAccountId}>
                      <td>
                        <MessageCheckbox
                          checked={checkSharedStatus(user.userAccountId)}
                          type="checkbox"
                          onClick={() =>
                            handleUserToggle(user.userAccountId)
                          }
                        />
                      </td>
                      <td>
                        {user.lastName}, {user.firstName}
                      </td>
                      <td>{user.email}</td>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
            </StyledResultsWrapper>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>Cancel</Button>
        <Button
          disabled={!selectedUsers.length && !isUnshare()}
          variant="primary"
          onClick={() => onSubmit()}
        >
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareWorklist;
