export const getQuickListNameByRole = (role) => {
  switch (role) {
    case "Admin Role":
    case "Super User Access":
    case "Care Management Admin":
    case "Hub Coordinator":
      return "CM_WORKLIST_HUB_COORDINATOR";
    case "Chaplains":
      return "CM_WORKLIST_CHAPLAIN";
    case "Care Specialists":
      return "CM_WORKLIST_CARE_SPECIALIST";
    case "Community Health Workers":
      return "CM_WORKLIST_CHW";
    case "Clinical Coordinators":
      return "CM_WORKLIST_CLINICAL_COORDINATOR";
    case "Hospital Liaisons":
      return "CM_WORKLIST_HOSPITAL_LIAISONS";
    default:
      return "";
  }
}

export const getBadgeClassForCarePathStatus = (status) => {
  switch (status) {
    case 'OVERDUE':
      return 'bg-danger';
    case 'COMPLETED':
      return 'bg-success';
    case 'SNOOZED':
      return 'bg-primary';
    case 'SKIPPED':
    case 'PENDING':
    case 'AWAITING_TRIGGER':
      return 'bg-warning';
    default:
      return 'bg-secondary';
  }
};

export const formatDays = (days) => {
  if (days === null || days === undefined) {
    return '';
  }
  return days === 1 ? days + ' day' : days + ' days';
}

/**
 * Maps the given team role to a configured role if available.
 *
 * @param {string} teamRole - The team role to map.
 * @returns {string} - The mapped role if found, otherwise the original team role.
*/
export const mappingCareTeamConfiguredRoles = (teamRole) => {
  const mappings = {
    "Pharmacy Care Specialists": "Care Specialists",
    "Nurse Practitioners": "Clinical Coordinators",
    "Hub Coordinators": "Hub Coordinator"
  };

  return mappings[teamRole] || teamRole;
}

/**
 * Checks if the given user role is part of the Admin Hub Coordinator roles.
 *
 * @param {string} userRole - The role of the user to check.
 * @param {Array} adminRolesHubWL - An array of admin roles and hub coordinator roles.
 * @return {boolean} - Returns true if the userRole is in adminRolesHubWL, false otherwise.
 */
export const isRoleInAdminHubCoordinator = (userRole, adminRolesHubWL) => {
  if (!userRole || !adminRolesHubWL) {
    return false;
  }

  return Array.isArray(adminRolesHubWL) &&
    adminRolesHubWL.includes(userRole);
}


/**
 * Checks if the given user role is part of the Care Management Teams.
 *
 * @param {string} userRole - The role of the user to check.
 * @param {Array} careManagementTeams - An array of care management team objects.
 * @return {boolean} - Returns true if the userRole is in careManagementTeams, false otherwise.
 */
export const isRoleInCMTeam = (userRole, careManagementTeams) => {
  if (!userRole || !careManagementTeams) {
    return false;
  }

  const cmTeams = [...careManagementTeams, "Hub Coordinator"];

  return Array.isArray(careManagementTeams) &&
    cmTeams?.includes(mappingCareTeamConfiguredRoles(userRole));
}

/**
 * Determines the appropriate user role based on the given user role and team.
 *
 * If the userRole is part of adminRolesHubWL, it will be returned as the role to use.
 * If the userRole is not in adminRolesHubWL but the userTeam is in careManagementTeams,
 * the userTeam will be returned as the role to use.
 * If neither condition is met, the userRole will be returned.
 *
 * @param {string} userRole - The role of the user to check.
 * @param {string} userTeam - The team of the user to check.
 * @param {Array} adminRolesHubWL - An array of admin roles and hub coordinator roles.
 * @param {Array} careManagementTeams - An array of care management team objects.
 * @return {string} - Returns the userRole or userTeam based on the given conditions.
 */
const resolveUserRole = (userRole, userTeam, adminRolesHubWL, careManagementTeams) => {
  let resolvedUserRole = userRole;
  const isAdmin = isRoleInAdminHubCoordinator(userRole, adminRolesHubWL);
  const isCMTeam = isRoleInCMTeam(userTeam, careManagementTeams);

  if (!isAdmin && isCMTeam) {
    resolvedUserRole = mappingCareTeamConfiguredRoles(userTeam)
  }

  return resolvedUserRole;
}

/**
 * Generates the default active role object based on the user role, user team, admin roles,
 * care management teams, and custom flag.
 *
 * Creates an object representing the default active role. It determines the appropriate role
 * based on whether the user's role is in the admin roles or if their team is part of the care management teams.
 *
 * @param {string} userRole - The initial role of the user.
 * @param {string} userTeam - The team of the user.
 * @param {Array} adminRolesHubWL - An array of admin roles and hub coordinator roles.
 * @param {Array} careManagementTeams - An array of care management team objects.
 * @param {boolean} isCustom - A flag indicating if the role is custom.
 * @return {Object} - Returns an default active role object
 */
export const getDefaultActiveRoleObject = (userRole, userTeam, adminRolesHubWL, careManagementTeams, isCustom = false) => {
  return {
    leadId: null,
    leadName: null,
    role: resolveUserRole(userRole,
      userTeam,
      adminRolesHubWL,
      careManagementTeams),
    custom: isCustom
  };
}
