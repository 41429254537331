import React from "react";
import { Badge } from "react-bootstrap";

const componentName = "carePathPrimary";

export const CarePathPrimary = () => {
  return (
    <>
      <Badge id={`${componentName}-status`} pill bg="primary" className="ms-2">Primary</Badge>
    </>
  );
};

export default CarePathPrimary;