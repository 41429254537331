import React from "react";
import { Badge } from "react-bootstrap";
import { formatDate, formatDateModern } from "../../../../../utils/util";

const componentName = "careStepDueDate";

export const CareStepDueDate = ({ index, step }) => {

  return (
    <>
      {step.dueDate &&
        <Badge id={`${componentName}-carePathStepDate-${index}`} pill bg={new Date(step.dueDate) < new Date() ? "danger" : "primary"}>
          Due by {formatDateModern(step.dueDate)}
        </Badge>
      }
    </>
  );
};

export default CareStepDueDate;