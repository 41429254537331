import React from "react";
import {
  useCMWorklistContext
} from "../context/CMWorklist.context";
import {Col, Row, Badge} from "react-bootstrap";

import {
  getPatientCardMarks,
} from "../../../constants";

import Mark from "../../Mark";
import { setManageVisitOpenedFromPageTitle, setManageVisitPatient } from "../../../../../../actions/manageVisitActions";
import { useDispatch } from "react-redux";
import {
  _calculateAge,
  formatDateModern,
} from "../../../../../../utils/util";
import PatientRecordBoldField from "../fields/PatientRecordBoldField";
import RiskColumn from "./RiskColumn";

const componentName = "FullNameColumn"

const FullNameColumn = ({ patient, idx }) => {

    const {
      activeRole,
    } = useCMWorklistContext();

    const { role } = activeRole;
    const props = {
      patient,
      idx,
      role
    }
    const getColumn = () => {
        switch (role) {
            case 'Clinical Coordinators':
                return <FullNameColumnClinical {...props} />
            case "Hospital Liaisons":
                return <FullNameColumnHospitalLiaisons {...props} />
            case 'Community Health Workers':
                return <FullNameCHW {...props} />
            case 'Chaplains':
                return <FullNameChaplain {...props} />
            default:
                return <FullNameColumnDefault {...props} />
        }
    }

    return (
        <Col id={`${componentName}-${idx}`} style={{minWidth: 350}}>
            <Row>
                <Col className="d-flex flex-column">
                    {getColumn()}
                </Col>
            </Row>
        </Col>
    )
}

const FullNameColumnDefault = ({ patient, role, idx }) => {
    const patientCardMarks = getPatientCardMarks(patient);
    const dispatch = useDispatch();
    return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row>
        <div className="d-flex align-items-center">
          <a id={`${componentName}-fullName-${idx}`}
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName align-middle me-3"
            onClick={() => {
              dispatch(setManageVisitPatient(patient));
              dispatch(setManageVisitOpenedFromPageTitle('CMWorklist'))
            }}
          >
            <span className="forced-capital fs-5 d-inline-block">{patient.fullName}</span>
          </a>
          {patientCardMarks
            .filter(item => item.shouldBeRendered)
            .map(item => <Mark text={item.text} customClassName={item.customClassName} />)
          }
          {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
            <Mark text={'Snoozed patient'} customClassName={'fas fa-snooze text-muted me-1'}/>
          }
          <RiskColumn patient={patient} idx={idx}/>
        </div>
      </Row>
      <div className="d-flex">
        <div id={`${componentName}-memberID-${idx}`} className="d-inline-block me-3 text-muted" style={{ fontSize: '12px'}}>
          <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold me-2">ID:</span>
          <span id={`${componentName}-memberIDValue-${idx}`}>{patient.memberId}</span>
        </div>
        <div className="text-muted" style={{ fontSize: '12px'}}>
          <span className="fw-bold me-2">DOB:</span>
          <span>{formatDateModern(patient?.dateOfBirth)}</span>
          {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
            <Badge bg="light" text="dark" className="border p-1 ms-2">{_calculateAge(patient?.dateOfBirth)}</Badge>
          }
          { ((role === 'Community Health Workers' || role === 'Care Specialists' || role === 'Chaplains') &&
            patient?.carePathStepDto?.stepState !== 'SNOOZED') &&
            <Badge bg="light" text="dark" className="border p-1 ms-2">{patient?.gender}</Badge>
          }
        </div>
      </div>
      <Row>
        <div className="d-flex flex-column text-muted" style={{ fontSize: '12px'}}>
          {(role !== 'Hub Coordinator' && role !== 'Admin Role') &&
            <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2">Patient Phone:
              <span className="fw-normal">{` ${patient.primaryPhone}`}</span>
            </span>
          }
          {(
            (patient?.address || patient?.address2 || patient?.fullAddress) &&
            patient?.carePathStepDto?.stepState !== 'SNOOZED') &&
            <span id={`${componentName}-patientZoneLabelAddress-${idx}`} className="fw-bolder me-2">Address:
              <span className="fw-normal text-capitalize">{` ${patient?.fullAddress || patient?.address || patient?.address2}`}</span>
            </span>
          }
          {(role === 'Care Specialists' || role === 'Chaplains') && patient?.adtPhone &&
            <span id={`${componentName}-patientZoneLabelAdtPhone-${idx}`} className="fw-bolder me-2 ml-">
              <span className="me-2">ADT Phone:</span>
              <span className="fw-normal">{patient?.adtPhone}</span>
            </span>
          }
        </div>
      </Row>
    </Col>
    )
}

const FullNameChaplain = ({ patient, idx}) => {
  const patientCardMarks = getPatientCardMarks(patient);
    const dispatch = useDispatch();
    return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row>
        <div className="d-flex align-items-center">
          <a id={`${componentName}-fullName-${idx}`}
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName align-middle me-3"
            onClick={() => {
              dispatch(setManageVisitPatient(patient));
              dispatch(setManageVisitOpenedFromPageTitle('CMWorklist'))
            }}
          >
            <span className="forced-capital fs-5 d-inline-block">{patient.fullName}</span>
          </a>
          {patientCardMarks
            .filter(item => item.shouldBeRendered)
            .map(item => <Mark text={item.text} customClassName={item.customClassName} />)
          }
          {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
            <Mark text={'Snoozed patient'} customClassName={'fas fa-snooze text-muted me-1'}/>
          }
          <RiskColumn patient={patient} idx={idx}/>
        </div>
      </Row>
      <div className="d-flex">
        <div id={`${componentName}-memberID-${idx}`} className="d-inline-block me-3 text-muted" style={{ fontSize: '12px'}}>
          <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold me-2">ID:</span>
          <span id={`${componentName}-memberIDValue-${idx}`}>{patient.memberId}</span>
        </div>
        <div className="text-muted" style={{ fontSize: '12px'}}>
          <span className="fw-bold me-2">DOB:</span>
          <span>{formatDateModern(patient?.dateOfBirth)}</span>
          {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
            <Badge bg="light" text="dark" className="border p-1 ms-2">{_calculateAge(patient?.dateOfBirth)}</Badge>
          }
          {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
            <Badge bg="light" text="dark" className="border p-1 ms-2">{patient?.gender}</Badge>
          }
        </div>
      </div>
      <Row>
        <div className="d-flex flex-column text-muted" style={{ fontSize: '12px'}}>
          <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2">Patient Phone:
            <span className="fw-normal">{` ${patient.primaryPhone}`}</span>
          </span>
          {(
            (patient?.address || patient?.address2 || patient?.fullAddress) &&
            patient?.carePathStepDto?.stepState !== 'SNOOZED') &&
            <span id={`${componentName}-patientZoneLabelAddress-${idx}`} className="fw-bolder me-2">Address:
              <span className="fw-normal text-capitalize">{` ${patient?.fullAddress || patient?.address || patient?.address2}`}</span>
            </span>
          }
          <PatientRecordBoldField sourceName={`${componentName}-adtPhone`} idx={idx} label={"ADT Phone:"} value={patient?.adtPhone}/>
        </div>
      </Row>
    </Col>
    )
}

const FullNameCHW = ({ patient, idx }) => {
  const patientCardMarks = getPatientCardMarks(patient);
  const dispatch = useDispatch();
  return (
  <Col id={`${componentName}-${idx}`} className="px-0">
    <Row>
      <div className="d-flex align-items-center">
        <a id={`${componentName}-fullName-${idx}`}
          data-bs-toggle={"offcanvas"}
          data-bs-target={"#patientDetailsModal"}
          aria-controls="patientDetailsModal"
          className="fw-bold patientName align-middle me-3"
          onClick={() => {
            dispatch(setManageVisitPatient(patient));
            dispatch(setManageVisitOpenedFromPageTitle('CMWorklist'))
          }}
        >
          <span className="forced-capital fs-5 d-inline-block">{patient.fullName}</span>
        </a>
        {patientCardMarks
          .filter(item => item.shouldBeRendered)
          .map(item => <Mark text={item.text} customClassName={item.customClassName} />)
        }
        {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
          <Mark text={'Snoozed patient'} customClassName={'fas fa-snooze text-muted me-1'}/>
        }
        <RiskColumn patient={patient} idx={idx}/>
      </div>
    </Row>
    <div className="d-flex">
      <div id={`${componentName}-memberID-${idx}`} className="d-inline-block me-3 text-muted" style={{ fontSize: '12px'}}>
        <span id={`${componentName}-memberIDLabel-${idx}`} className="fw-bold me-2">ID:</span>
        <span id={`${componentName}-memberIDValue-${idx}`}>{patient.memberId}</span>
      </div>
      <div className="text-muted" style={{ fontSize: '12px'}}>
        <span className="fw-bold me-2">DOB:</span>
        <span>{formatDateModern(patient?.dateOfBirth)}</span>
        {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
          <Badge bg="light" text="dark" className="border p-1 ms-2">{_calculateAge(patient?.dateOfBirth)}</Badge>
        }
        {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
          <Badge bg="light" text="dark" className="border p-1 ms-2">{patient?.gender}</Badge>
        }
      </div>
    </div>
    <Row>
      <div className="d-flex flex-column text-muted" style={{ fontSize: '12px'}}>
        <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2">Patient Phone:
          <span className="fw-normal">{` ${patient.primaryPhone}`}</span>
        </span>
        {(
          (patient?.address || patient?.address2 || patient?.fullAddress) &&
          patient?.carePathStepDto?.stepState !== 'SNOOZED') &&
          <span id={`${componentName}-patientZoneLabelAddress-${idx}`} className="fw-bolder me-2">Address:
            <span className="fw-normal text-capitalize">{` ${patient?.fullAddress || patient?.address || patient?.address2}`}</span>
          </span>
        }
      </div>
      <PatientRecordBoldField sourceName={`${componentName}-adtPhone`} idx={idx} label={"ADT Phone:"} value={patient?.adtPhone}/>
    </Row>
  </Col>
  )
}

const FullNameColumnClinical = ({ patient, idx }) => {
    const patientCardMarks = getPatientCardMarks(patient);
    const dispatch = useDispatch();
    return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row>
        <div className="d-flex align-items-center">
          <a id={`${componentName}-fullName-${idx}`}
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName align-middle me-3"
            onClick={() => {
              dispatch(setManageVisitPatient(patient));
              dispatch(setManageVisitOpenedFromPageTitle('CMWorklist'))
            }}
          >
            <span className="forced-capital fs-5 d-inline-block">{patient.fullName}</span>
          </a>
          {patientCardMarks
            .filter(item => item.shouldBeRendered)
            .map(item => <Mark text={item.text} customClassName={item.customClassName} />)
          }
          {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
            <Mark text={'Snoozed patient'} customClassName={'fas fa-snooze text-muted me-1'}/>
          }
          <RiskColumn patient={patient} idx={idx}/>
        </div>
      </Row>
      <Row>
        <Col>
          <PatientRecordBoldField sourceName={`${componentName}-id`} idx={idx} label={"ID:"} value={patient?.memberId}/>
          <BlankSpace />
          <PatientRecordBoldField sourceName={`${componentName}-id`} idx={idx} label={"DOB:"} value={patient?.dateOfBirth}/>
          <BlankSpace />
          {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
            <>
              <Badge pill bg="light" text="dark" className="border ms-2">
                {patient.age}
              </Badge><BlankSpace /><Badge pill bg="light" text="dark" className="border ms-2">
                  {patient.gender}
                </Badge>
            </>
          }
        </Col>
      </Row>
      <Row>
        <div className="d-flex flex-column text-muted" style={{ fontSize: '12px'}}>
          <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2">Patient Phone:
            <span className="fw-normal">{` ${patient.primaryPhone}`}</span>
          </span>
          {((patient?.address || patient?.address2) && patient?.carePathStepDto?.stepState !== 'SNOOZED') &&
            <span id={`${componentName}-patientZoneLabelAddress-${idx}`} className="fw-bolder me-2">Address:
              <span className="fw-normal text-capitalize">{` ${patient?.address || patient?.address2}`}</span>
            </span>
          }
          <PatientRecordBoldField sourceName={`${componentName}-adtPhone`} idx={idx} label={"ADT Phone:"} value={patient?.adtPhone}/>
        </div>
      </Row>
    </Col>
    )
}

const BlankSpace = () => {
  return <span>&nbsp;&nbsp;</span>;
}

const FullNameColumnHospitalLiaisons = ({ patient, idx }) => {
  const patientCardMarks = getPatientCardMarks(patient);
  const dispatch = useDispatch();
  const address = (patient?.address || patient?.address2) ?? '';

  return (
    <Col id={`${componentName}-hospital-liaisons-${idx}`} className="px-0">
      <Row>
        <div className="d-flex align-items-center">
          <a id={`${componentName}-fullName-${idx}`}
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName align-middle me-3"
            onClick={() => {
              dispatch(setManageVisitPatient(patient));
              dispatch(setManageVisitOpenedFromPageTitle('CMWorklist'))
            }}
          >
            <span className="forced-capital fs-5 d-inline-block">{patient.fullName}</span>
          </a>
          {patientCardMarks
            .filter(item => item.shouldBeRendered)
            .map(item => <Mark text={item.text} customClassName={item.customClassName} />)}

          {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
            <Mark text={'Snoozed patient'} customClassName={'fas fa-snooze text-muted me-1'}/>
          }
          <RiskColumn patient={patient} idx={idx}/>
        </div>
      </Row>
      <Row>
        <Col>
          <PatientRecordBoldField sourceName={`${componentName}-id`} idx={idx} label={"ID:"} value={patient?.memberId}/>
          <BlankSpace />
          <PatientRecordBoldField sourceName={`${componentName}-id`} idx={idx} label={"DOB:"} value={patient?.dateOfBirth}/>
          <BlankSpace />
          {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
            <>
              <Badge pill bg="light" text="dark" className="border ms-2">
                {patient.age}
              </Badge><BlankSpace /><Badge pill bg="light" text="dark" className="border ms-2">
                {patient.gender}
              </Badge>
            </>
          }
        </Col>
        {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
          <>
            <PatientRecordBoldField sourceName={`${componentName}-phone`} idx={idx} label={"Patient Phone:"} value={patient?.primaryPhone}/>
            <PatientRecordBoldField sourceName={`${componentName}-address`} idx={idx} label={"Address:"} value={address}/>
            <PatientRecordBoldField sourceName={`${componentName}-adtPhone`} idx={idx} label={"ADT Phone:"} value={patient?.adtPhone}/>
          </>
        }
        </Row>
      {patient?.carePathStepDto?.stepState === 'SNOOZED' &&
        <Row>
          <div className="d-flex flex-column text-muted" style={{ fontSize: '12px'}}>
            <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2">Phone:
              <span className="fw-normal">{` ${patient.primaryPhone}`}</span>
            </span>
          </div>
        </Row>
      }
    </Col>
  );
}

export default FullNameColumn;