import {actions} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE_SAVE_AS_CUSTOM_WORKLIST:
      return {
        ...state,
        isSaveAsCustomWorklistEnabled: action.payload
      };
    case actions.CUSTOM_WORKLIST_ERROR:
      return {
        ...state,
        customWorklistError: action.payload
      };
    case actions.TOGGLE_DELETE_CUSTOM_WORKLIST:
      return {
        ...state,
        isDeleteCustomWorklistEnabled: action.payload
      };
    case actions.SET_CUSTOM_WORK_LIST:
      return {
        ...state,
        customWorklist: action.payload
      };
    case actions.SET_CUSTOM_FILTERS:
      return {
        ...state,
        customFilters: action.payload
      }
    case actions.SET_ACTIVE_ROLE:
      return {
        ...state,
        activeRole: action.payload
      };
    case actions.SET_SAVE_CUSTOM_WORK_LIST_REQUEST:
      return {
        ...state,
        saveAsCustomWorklistRequest: action.payload
      };
    case actions.SET_PATIENT_SEARCH:
      return {
        ...state,
        patientSearchCustomWorklist: action.payload
      };
    case actions.SET_GET_CUSTOM_WORK_LIST_REQUEST:
      return {
        ...state,
        getCustomWorklistRequest: action.payload
      };
    default:
      return state;
  }
};