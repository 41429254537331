import React from 'react'
import {useNewRRContext} from '../context/NewRR.context';

import {
  SaveAsCustomWorklist as NewRRSaveAsCustomWorklist
} from "../../../../components/CustomWorklist";

const componentName = 'NewRRSaveAsCustomWorklist';

function SaveAsCustomWorklist() {
  const {
    activeRole,
    referralsSearch,
    sendEvent,
  } = useNewRRContext();

  const quickListName = 'NEW_REFERRALS_RECEIVED';

  return (
    <NewRRSaveAsCustomWorklist {...{
      quickListName,
      componentName,
      patientSearch: referralsSearch,
      sendEvent,
      activeRole
    }} />
  )
}

export default SaveAsCustomWorklist;