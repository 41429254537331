import React from 'react'
import { Button, Modal } from 'react-bootstrap';

function DeleteAssessment({
  handleDelete,
  showDeleteModal,
  setshowDeleteModal,
  name
}) {
  return (
    <Modal
      show={showDeleteModal}
      onHide={() => setshowDeleteModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="my-modal"
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ margin: 0 }}>{name}</p>
          <p style={{ fontSize: '12px', fontWeight: '400' }}>
            Delete Assessment
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        Are you sure to delete this assessment?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setshowDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteAssessment