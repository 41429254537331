import {actions} from "./actions";

export function getSetters(dispatch) {
  return {
    setVisitsDetails: payload => dispatch(
      {type: actions.SET_VISITS_DETAILS, payload: payload}),
    setPatient: payload => dispatch(
      {type: actions.SET_PATIENT, payload: payload}),

    /***
     * Creates/deletes a visit activity, or updates one or more of its fields
     *
     * @param visitId identifier of the activity
     * @param fieldsToUpdate helper object containing {date, status, checked} fields that represent a visit activity
     */
    setUnsavedVisitsMap: (visitId, fieldsToUpdate) => dispatch({
      type: actions.UPDATE_UNSAVED_VISITS_MAP,
      payload: {visitId, fieldsToUpdate}
    }),
    clearUnsavedVisitsMap: () => dispatch({
      type: actions.CLEAR_UNSAVED_VISITS_MAP
    })
  }
}