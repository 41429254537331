import { actions } from "./actions";

import {
  setHidePatientDetailsModal
} from "../../../../actions/manageVisitActions";

export function getToggles(dispatch, globalDispatch) {
  return {
    toggleNewHospitalEventModalActive: payload => {
      globalDispatch(setHidePatientDetailsModal(payload));
      dispatch({ type: actions.TOGGLE_NEW_HOSPITAL_EVENT_MODAL_ACTIVE, payload: payload });
    },
    toggleEditHospitalEventModalActive: payload => {
      globalDispatch(setHidePatientDetailsModal(payload));
      dispatch({ type: actions.TOGGLE_EDIT_HOSPITAL_EVENT_MODAL_ACTIVE, payload: payload });
    },
  }
}
