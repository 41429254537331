import React from 'react'

import {
  DeleteCustomWorklist as NewRRDeleteCustomWorklist
} from "../../../../components/CustomWorklist";
import {useNewRRContext} from "../context/NewRR.context";

const componentName = 'NewRRDeleteCustomWorklist';

function DeleteCustomWorklist() {

  const {
    sendEvent
  } = useNewRRContext();

  const locationToRedirect = "/referrals-received";
  const quickListName = 'NEW_REFERRALS_RECEIVED';

  return (
    <NewRRDeleteCustomWorklist {...{
      quickListName,
      componentName,
      sendEvent,
      locationToRedirect,
    }}/>
  )
}

export default DeleteCustomWorklist;