import {
    GET_MESSAGE,
    DELETE_MESSAGE
} from "../actions/types";

const initialState = {
    message: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_MESSAGE:
            return {
                ...state,
                message: payload
            };
        case DELETE_MESSAGE:
            return {
                ...state,
                message: payload
            }
        default:
            return state;
    }
}
