import styled from "styled-components";

export const UrgencyLabel = styled.label`
color: ${(props) =>
  props.urgency == "10_ROUTINE"
    ? `#5b9f4a`
    : props.urgency == "20_PRIORITY"
      ? `#e6954e`
      : props.urgency == "30_URGENT"
        ? `#d6383d`
        : props.urgency == "CBO_REFERRAL"
          ? `#75797B`
          : `#d12026`};
background-color: ${(props) =>
  props.urgency == "10_ROUTINE"
    ? `#eef5ec`
    : props.urgency == "20_PRIORITY"
      ? `#faebdd`
      : props.urgency == "30_URGENT"
        ? `#eeaeb0`
        : props.urgency == "CBO_REFERRAL"
          ? `#EBEBEB`
          : `#f0bbbc`};
font-weight: bold;
font-size: 12px;
padding: 5px 20px;
`;

export const InfoCol = styled.div`
  max-width: 150px;
  margin-bottom: 10px;
  color: #abadaf;
  text-transform: uppercase;
  font-weight: 500;
`

export const StatusLabel = styled.label`
color: ${(props) =>
    props.urgency == "40_ACCEPTED"
      ? `#dff0fa`
      : props.urgency == "60_DECLINED"
        ? `#f8f9fa`
        : props.urgency == "30_COMPLETED"
          ? `#eef5ec`
          : props.urgency == "20_PATIENT_CANCELLED"
            ? `#ffffff`
            : props.urgency == "50_PENDING"
              ? `#EAA465`
              : `#d12026`};
background-color: ${(props) =>
    props.urgency == "40_ACCEPTED"
      ? `#1c8bbf`
      : props.urgency == "60_DECLINED"
        ? `#6e686b`
        : props.urgency == "30_COMPLETED"
          ? `#65a456`
          : props.urgency == "20_PATIENT_CANCELLED"
            ? `#de6d70`
            : props.urgency == "50_PENDING"
              ? `#fbecc2`
              : `#f8dedf`};
font-weight: bold;
font-size: 12px;
padding: 5px 28px 5px 20px;
`;

export const menuItems = [
  {
    text: 'Accepted',
    value: 'accepted'
  },
  {
    text: 'Declined',
    value: 'declined'
  },
  {
    text: 'Completed',
    value: 'completed'
  },
  {
    text: 'Patient Cancelled',
    value: 'patient_cancelled'
  },
  {
    text: 'Pending',
    value: 'pending'
  },
  {
    text: 'Cancelled',
    value: 'cancelled'
  },
]

export const sortItems = [
  {
    value: "Default",
    text: "Default",
    hasDivider: false,
    condition: true
  },
  {
    value: "Referral Type (A - Z)",
    text: "Referral Type (A - Z)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Referral Type (Z - A)",
    text: "Referral Type (Z - A)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Referral Status (A - Z)",
    text: "Referral Status (A - Z)",
    hasDivider: false,
    condition: true
  },
  {
    value: "Referral Status (Z - A)",
    text: "Referral Status (Z - A)",
    hasDivider: false,
    condition: true
  }
]