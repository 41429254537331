import React from "react";
import {Form, Row, Col} from "react-bootstrap";

const componentName = "ReferralUrgency";

const ReferralUrgency = ({
 urgencyOptions,
 type,
 onSelect,
 showAdditionalMessage = true,
 selectedUrgency,
 isRequired = false
}) => {
    return (
        <>
            <label id={`${componentName}-urgencyTitle`}>
                Urgency
                {isRequired && <span id={`${componentName}-filterRequired`} className="text-danger">*</span>}
            </label>
            {urgencyOptions && urgencyOptions.map((urgency, idx) => (<>
                <Form.Check
                    label={urgency.text}
                    value={urgency.value}
                    type={type}
                    id={`${componentName}-urgencyOption-${idx}`}
                    key={`${componentName}-urgencyOption-${idx}`}
                    onClick={(e) => onSelect(e.target.value)}
                    name="referralUrgencyOptions"
                    checked={selectedUrgency && selectedUrgency === urgency.value}
                />
            </>))}
            {showAdditionalMessage &&
                <small id={`${componentName}-urgencyMessage`} className="fw-bold mt-1">Call 833-227-3100 for all emergent, same-day needs.</small>
            }
        </>
    )
}

export default ReferralUrgency;