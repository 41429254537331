export const initialState = {
  patientProfileInformation: {},
  isEditPatientDetailsEnabled: false,
  isAddInternalNoteEnabled: false,
  languages: [],
  states: [],
  payorStatus: [],
  specialistTypes: [],
  pronounsDropList: [],
  spokenLanguages: [],
  readingLanguages: [],
  sexAssignedBirthDropList: [],
  raceEthnicityDropList: [],
  sexualOrientationList: [],
  genderIdentityList: [],
};

export const actions = {
  SET_PATIENT_DETAILS: 'SET_PATIENT_DETAILS',
  TOGGLE_EDIT_PATIENT_DETAILS: 'TOGGLE_EDIT_PATIENT_DETAILS',
  TOGGLE_ADD_INTERNAL_NOTE_ENABLED: 'TOGGLE_ADD_INTERNAL_NOTE_ENABLED',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_SPOKEN_LANGUAGES: 'SET_SPOKEN_LANGUAGES',
  SET_READING_LANGUAGES: 'SET_READING_LANGUAGES',
  SET_STATES: 'SET_STATES',
  SET_PAYOR_STATUS: 'SET_PAYOR_STATUS',
  SET_SPECIALIST_TYPES: 'SET_SPECIALIST_TYPES',
  SET_PRONOUNS_DROP_LIST: 'SET_PRONOUNS_DROP_LIST',
  SET_SEX_ASSIGNED_DROP_LIST: 'SET_SEX_ASSIGNED_DROP_LIST',
  SET_RACE_ETHNICITY_DROP_LIST: 'SET_RACE_ETHNICITY_DROP_LIST',
  SET_SEXUAL_ORIENTATION_LIST: 'SET_SEXUAL_ORIENTATION_LIST',
  SET_GENDER_IDENTITY_LIST: 'SET_GENDER_IDENTITY_LIST',
};