import React, { useReducer } from "react";

const FETCH_INIT = "FETCH_INIT";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const FETCH_FAILURE = "FETCH_FAILURE";
const OUR_ACTIONS = [FETCH_INIT, FETCH_SUCCESS, FETCH_FAILURE];

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return { ...state, isLoading: true, error: action.error };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.payload,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
};

const useDataFetch = (getData, initialData, reducer) => {
  const [state, dispatch] = useReducer(
    (state, action) =>
      OUR_ACTIONS.includes(action.type)
        ? dataFetchReducer(state, action)
        : reducer ? reducer(state, action) : (state, action) => "bad",
    {
      isLoading: false,
      error: null,
      data: initialData,
    }
  );

  const fetchData = async (...args) => {
    dispatch({ type: FETCH_INIT });
    try {
      const result = await getData(args);
      dispatch({ type: FETCH_SUCCESS, payload: result.data });
    } catch (error) {
      dispatch({ type: FETCH_FAILURE, error: error });
    }
  };

  //return [state, fetchData, dispatch];
  return { state: state, fetchData: fetchData, dispatch: dispatch };
};

const dataFetchHelper = (action) => {
  const useDataFetchHook = (initialData, reducer) => {
    return useDataFetch(action, initialData, reducer);
  };

  return [action, useDataFetchHook];
};

export {
  dataFetchHelper
};
