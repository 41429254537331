import styled, { css } from "styled-components";

const sharedStyle = css`
  color: #455a64;
  font-weight: 400;
`;

export const H1 = styled.h1`
  ${sharedStyle}
  line-height: 40px;
  font-size: 36px;
`;

export const H2 = styled.h2`
  ${sharedStyle}
  line-height: 36px;
  font-size: 24px;
`;

export const H3 = styled.h3`
  ${sharedStyle}
  line-height: 30px;
  font-size: 21px;
`;

export const H4 = styled.h4`
  ${sharedStyle}
  line-height: 22px;
  font-size: 18px;
`;

export const H5 = styled.h5`
  ${sharedStyle}
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
`;

export const H6 = styled.h6`
  ${sharedStyle}
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
`;
