import React, { useState } from "react";
import {
  useAuth,
  SESSION_TIMEOUT_SHOW_MODAL_MS,
  SESSION_TIMEOUT_MODAL_TIMEOUT_MS,
  SESSION_HAS_EXPIRED,
} from "../../context/authContext";

import Header from "./Header";
import Sidebar from "./Sidebar";
import IdleTimeoutModal from "../../components/IdleModal";
import { useIdleTimer } from "react-idle-timer";
import axios from "../../utils/apiClient";
import {LocalStorageUtil, SessionStorageUtil} from "../../utils/localStorageUtil";
import {LAST_QUERY_SETTINGS_SUFFIX, RECENT_QUERY_SETTINGS_KEY} from "../quickList/QuickList";
import AhaWidget from "./components/AhaWidget";

const CE_RESTORE_OBJ = "ce_restore_obj";

const MainLayout = ({ children, activeViewName }) => {
  const auth = useAuth();
  const isAuthenticated = auth && auth.isAuthenticated();
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [showIdleTimeoutModal, setshowIdleTimeoutModal] = useState(false);

  const handleOnIdle = () => {
    setshowIdleTimeoutModal(true);
  };
  const handleExtendSession = () => {
    reset();
    axios.put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/session/reset`,
        {
          headers: {},
        }
    );
    setshowIdleTimeoutModal(false);
  };
  const handleLogout = (save) => {
    try {
      handleLogoutBody(save);
    } catch (e) {
      try {
        handleLogoutBody(save);
      } catch (e) {}
    }
    auth.logout();
  };

  const handleLogoutBody = (save) => {
    sessionStorage.setItem(SESSION_HAS_EXPIRED, true);
    setshowIdleTimeoutModal(false);
    if (save) {
      //save state for restore
      const recentKey = SessionStorageUtil.getObj(RECENT_QUERY_SETTINGS_KEY);
      const quickListObj = SessionStorageUtil.getObj(recentKey);
      LocalStorageUtil.setObj(CE_RESTORE_OBJ, {
        quickListObj: quickListObj,
        recentKey: recentKey,
        restoreUrl: window.location.pathname + window.location.search
      });
    }
  };

  const {
    reset
  } = useIdleTimer({
    timeout: SESSION_TIMEOUT_SHOW_MODAL_MS,
    onIdle: handleOnIdle,
    stopOnIdle: true,
  });

  return (
    <div className="main-wrapper w-100">
      {isAuthenticated ? (
        <>
          <AhaWidget appId={'7063856700519331893'}/>
          <div className="d-flex justify-content-start">
            <Sidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
            <div className="page-wrapper d-flex flex-column overflow-y-auto p-0 vh-100 w-100">
              <Header sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
              <div className="info-wrapper">
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        children
      )}
      <IdleTimeoutModal
        showModal={showIdleTimeoutModal}
        handleExtendSession={handleExtendSession}
        handleLogout={handleLogout}
        logoutAfterMS={SESSION_TIMEOUT_MODAL_TIMEOUT_MS}
      />
    </div>
  );
};

export default MainLayout;
export {CE_RESTORE_OBJ};
