import React, { useState } from 'react';
import html2pdf from "html2pdf.js";
import html2canvas from 'html2canvas';
import axios from "../../../../utils/apiClient";

const componentName = "previsitChartDownloader";
const PVCPdfDownloader = ({rootElementId, rootHeaderId , downloadFileName, patientIds, footerLogo}) => {
    const [buttonText, setButtonText] = useState('Download PDF');
    const [isDisabled, setIsDisabled] = useState(false);

    const downloadPdfDocument = () => {
        setIsDisabled(true);
        setButtonText('Downloading PDF');

        const input = document.getElementById(rootElementId);
        const newDate = new Date();
        const date = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();
        const config = {
            margin:       [30, 10, 30, 10],
            filename:     `${downloadFileName}-${month}-${date}-${year}.pdf`,
            image:        { type: 'jpeg', quality: 0.75 },
            html2canvas:  { scale: 2, logging: false, dpi: 192, letterRendering: false },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        axios.post(
            process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/patient-card/log-auditor`,
            patientIds,
            {
                headers: {},
            }
        );
        html2canvas(document.getElementById(rootHeaderId)).then(canvas => { 
            const pdfHeader = canvas.toDataURL('image/jpeg');
            html2pdf().from(input).set(config).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages();
                const pdfFooter = "Disclaimer: The information present is reflective of previously reported data, from multiple sources and is not intended to be used in place of medical diagnosis or treatment, which are solely the responsibility of the practitioner. If the data received is incomplete or inaccurate, reported results may require review or revision accordingly. Any risk scores or related financial forecasts are estimates only and actual risk factors and/or quality measures or other results may vary. This information may not be appropriate for, and should not be used for other purposes. The information should not be parsed out or reviewed out of context of the entire report.";
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(8);
                    pdf.addImage(pdfHeader, 'JPEG', 10, 10, pdf.internal.pageSize.getWidth() - 20, 18, null, 'none', 0 );
                    pdf.text(pdfFooter, 10, pdf.internal.pageSize.getHeight() - 25, { maxWidth: 165 });
                    pdf.addImage(footerLogo, 'JPEG', pdf.internal.pageSize.getWidth() - 30, pdf.internal.pageSize.getHeight() - 25, 10, 10, null, 'none', 0);
                    pdf.text('Pg ' + String(i), pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.getHeight() - 20, "right");
                }
            }).save().then(() => {
                setIsDisabled(false);
                setButtonText('Download PDF');
            });
        });
    }
    return <button id={`${componentName}-downloadChartPrepPDF`} onClick={downloadPdfDocument} className="btn btn-primary" disabled={isDisabled}>{buttonText}</button>
}

export default PVCPdfDownloader;