import React, { useEffect, useState } from "react";
import { Container, Row, Col, Badge, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { DropDownList, GENERIC_DDL_TYPE } from "../listFilters/genericDDL";
import { ComparisonDropDownList, COMPARISON_DDL_TYPE } from "../listFilters/comparisonDDL";
import { FreeText, Free_Text_TYPE } from "./SideFilters/freeText";
import EQHErrorToast from "../EQHErrorToast";
import { READMIT_DDL_TYPE, ReadmitDropDownList } from "../listFilters/readmitDDL";
import { Wrapper } from './SideFilters/components'
import { SCHEDULE_DDL_TYPE, ScheduleApptDropDownList } from "../listFilters/scheduleApptDDL";
import { useCMWorklistContext } from "../../views/caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import { differenceInDays } from "date-fns";
import {trim, isEmpty, isString} from "lodash";
import { paramValue } from "../listFilters/sharedDDL";

const componentName = "filterGroup";

const FilterGroupNew = ({
  filters,
  handleChange,
  filterParams,
  additionalValidation,
  setScheduleFilterApplied,
  extraFilters,
  isManageVisit = false
}) => {
  const [errors, setErrors] = useState([]);
  const [insideErrors, setInsideErrors] = useState([]);
  const [reAdmitErrors, setreAdmitErrors] = useState([]);
  const workListContext = useCMWorklistContext();

  //Key by Header map for the filters having different Key and Header value
  const constructKeyByHeader = () => {
    const keyByHeaderMap = new Map();
    keyByHeaderMap.set("DOB", "Birth Date");
    keyByHeaderMap.set("DOB1", "Birth Date Comp");
    keyByHeaderMap.set("Patient City", "City");
    keyByHeaderMap.set("Event Type1", "eventType");
    keyByHeaderMap.set("Patient Zip Code", "Zip Code");
    keyByHeaderMap.set("PCP Name", "PCP");
    keyByHeaderMap.set("PCP Name1", "PCP Filter");
    keyByHeaderMap.set("PCP Name2", "PCP Filter On Full Name");
    keyByHeaderMap.set("Roster Status", "Patients");
    keyByHeaderMap.set("Care Opportunity Name", "Opportunity Definition");
    keyByHeaderMap.set("Care Opportunity Status", "Opportunity Status");
    keyByHeaderMap.set("Care Opportunity Year", "Measure Year");
    keyByHeaderMap.set("Validation Status", "Validation");
    keyByHeaderMap.set("Event Type", "ADT Disposition");
    keyByHeaderMap.set("Facility", "ADT by Facility");
    keyByHeaderMap.set("MemberID", "memberIds");
    keyByHeaderMap.set("Source", "source");
    keyByHeaderMap.set("Source", "sourceNew");
    keyByHeaderMap.set("Line of Business", "lineOfBusiness");
    keyByHeaderMap.set("Admit Date", "latestAdmitDate");
    keyByHeaderMap.set("Admit Date", "latestAdmissionDate");
    keyByHeaderMap.set("Creation Date", "assignDate");
    keyByHeaderMap.set("Last PCP Visit", "lastPCPVisitFilter");
    keyByHeaderMap.set("Zone", "zone");
    keyByHeaderMap.set("Engaged with PCP", "lostMember");
    keyByHeaderMap.set("Status", "worklistStatus");
    keyByHeaderMap.set("Missed Opportunity", "missedOppStatus");
    keyByHeaderMap.set("Appointment Date", "ScheduleAppt");
    keyByHeaderMap.set("Care Step Name", "Care Step Type");
    keyByHeaderMap.set("Quarterly Visit Eligible", "ecipEligible");
    keyByHeaderMap.set("Market", "fileinput");
    keyByHeaderMap.set("Care Program Status", "Care Path Status");
    keyByHeaderMap.set("Care Program Step Due Date", "Due Date");
    keyByHeaderMap.set("Current Hospital", "Hospital");
    return keyByHeaderMap;
  };

  const handleOffCanvas = () => {
    setQueuedDataMap(new Map());
  }

  useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasFilters');
    offcanvasElement && offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffCanvas())
    return () => {
      offcanvasElement && offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffCanvas);
    };
  }, []);

  const [keyByHeader, setKeyByHeader] = useState(() =>
    constructKeyByHeader()
  );

  const getFilterParamsFromSelected = (hidden) => {
    const dataMap = new Map();
    const keyByHeaderValues = [...keyByHeader.values()];
    for (let filterParam of filterParams) {
      let matchFilterForChangedParamName;
      if (keyByHeader != 'undefined'
        && keyByHeader.size != 0
        && keyByHeaderValues.includes(filterParam.paramName)
      ) {
        //Find the match by comparing Key value instead of Header because the paramName is changed for certain filterParams  to accommodate backend filter logic
        matchFilterForChangedParamName = filters.find(
          (item) => item.key === filterParam.paramName
        );
        if ((hidden && !matchFilterForChangedParamName) || (!hidden && matchFilterForChangedParamName)) {
          dataMap.set(matchFilterForChangedParamName?.header, filterParam);
        }
      } else {
        let matchFilter = filters.find(
          (item) => item.getHeader() === filterParam.paramName
        );
        if ((hidden && !matchFilter) || (!hidden && matchFilter)) {
          dataMap.set(filterParam.paramName, filterParam);
        }
      }
    }
    return dataMap;
  };

  const [lastFilterParamChanged, setLastFilterParamChanged] = useState();
  const [mySelected, setMySelected] = useState(filterParams);
  const [show, setShow] = useState(false);

  const [dataMap, setDataMap] = useState(() =>
    getFilterParamsFromSelected(false)
  );
  const [queuedDataMap, setQueuedDataMap] = useState(new Map());

  if (filterParams !== mySelected) {
    setDataMap(getFilterParamsFromSelected(false));
    setMySelected(filterParams);
  }
  const handleClose = () => {
    setQueuedDataMap(new Map());
    let closeCanvas = document.getElementById('closeSideFilters');
    closeCanvas && closeCanvas.click();
    setErrors([]);
    setShow(false);
    setreAdmitErrors([]);
    setInsideErrors([]);
  };

  const hasDisplayValue = (filterParam) => {
    if (isEmpty(filterParam.displayValue)) {
      return false;
    }

    const nonEmptyValues = filterParam.displayValue?.filter(value => {
      if (isString(value)) {
        return !isEmpty(trim(value))
      }
      return true;
    });

    return !isEmpty(nonEmptyValues);
  }

  const removeFilterParam = (param) => {
    dataMap.delete(param);
    filterParams = [];
    dataMap.forEach((filterParam, key, map) => {
      if (hasDisplayValue(filterParam)) {
        filterParams.push(filterParam);
      }
    });
    if (setScheduleFilterApplied && !filterParams.length) {
      setScheduleFilterApplied(false);
    }
    handleChange(filterParams);
  };

  const clearAllFilters = () => {
    workListContext && workListContext.sendEvent('CLEAR_ALL_FILTER');
    setDataMap(new Map());
    setQueuedDataMap(new Map());
  };

  const getMergedMaps = () => {
    const mergedMap = new Map(dataMap);
    queuedDataMap.forEach((filterParam, key, map) => {
      if (filterParam.paramValue && filterParam.paramValue.length) {
        mergedMap.set(filterParam.paramName, filterParam);
      } else {
        mergedMap.delete(filterParam.paramName);
      }
    });
    return mergedMap;
  };

  const handleFilterChange = (filterParam) => {
    if (filterParam.paramName) {
      const newMap = new Map(queuedDataMap);
      if (checkComparisonFilters(filterParam)) {
        newMap.set(filterParam.paramName, {...filterParam, paramValue: null})
      } else {
        newMap.set(filterParam.paramName, filterParam);
        setLastFilterParamChanged(filterParam);
      }
      setQueuedDataMap(newMap);
    }
  };

  const checkComparisonFilters = (filterParam) => {
    let res = false
    if (
      filterParam.paramName === 'DOB1' ||
      filterParam.paramName === 'Birth Date Comp' ||
      filterParam.paramName === 'Next upcoming Visit Date'
    ) {
      res = (!filterParam?.paramValue?.[0] && !filterParam?.paramValue?.[1]) || filterParam?.paramValue.every((x) => x === "")
    }
    return res
  }

  const validate = () => {
    const newerrors = [];
    const mergedMap = getMergedMaps();
    let hasReadmitError = false;
    let hasInsideError = null;

    mergedMap.forEach((filterParam) => {
      if (filterParam?.paramName === 'Readmit' && filterParam?.comparison === 'range') {
        hasReadmitError = validateReadmit(filterParam);
      } else if (filterParam.paramName === 'DOB1' || filterParam.paramName === 'Birth Date Comp') {
        hasInsideError = validateFilters(filterParam).length > 0;
        hasInsideError && setInsideErrors(validateFilters(filterParam))
      } else {
        newerrors.push(validateFilters(filterParam));
      }
    });

    if (!hasReadmitError) {
      const validateBadInput = (id) => {
        return document.querySelector('#' + id)?.validity?.badInput
      }
      let errors = []
      if (validateBadInput('ReadmitDDL-READMIT-datePicker')) {
        errors.push('Readmit Needs A Valid Date For Start Date');
      }
      if (validateBadInput('ReadmitDDL-READMIT-datePicker2')) {
        errors.push('Readmit Needs A Valid Date For End Date');
      }
      if (errors.length) {
        setreAdmitErrors(errors)
        hasReadmitError = true;
      }
    }

    additionalValidation && additionalValidation(mergedMap, newerrors);
    return hasReadmitError ? 'readmitError' : hasInsideError ? 'insideError' : newerrors.filter(n => n.length > 0);
  };

  const validateFilters = (filterParam) => {
    const newerrors = [];
    if (filterParam.paramValue && filterParam.paramValue.length) {
      if (filterParam.comparison === "range") {
        const startDateTime = Date.parse(filterParam.paramValue[0]);
        const endDateTime = Date.parse(filterParam.paramValue[1]);

        if (startDateTime > endDateTime) {
          newerrors.push("Start date must be less than or equal to End date.")
        }
        if (isNaN(startDateTime) || startDateTime == '') {
          const filterName = getHeaderOrDefault(filterParam.paramName);
          newerrors.push(
            `${filterName} Needs A Valid Date For Start Date`
          );
        }
        if (isNaN(endDateTime) || endDateTime == '') {
          const filterName = getHeaderOrDefault(filterParam.paramName);
          newerrors.push(
            `${filterName} Needs A Valid Date For End Date`
          );
        }
      }
    }
    return newerrors
  }

  const getHeaderOrDefault = (filterName) => {
    if (filterName === 'DOB1') {
      return 'DOB'
    }
    if (filterName === 'Care Program Due Date') {
      return 'Due Date'
    }
    return filterName
  }

  const validateReadmit = (filterParam) => {
    let newerrors = []
    const readmitStart = filterParam?.paramValue.filter(p => p.includes('startDate'));
    const readmitEnd = filterParam?.paramValue.filter(p => p.includes('endDate'));
    if (readmitStart.length > 0 || readmitEnd.length > 0) {
      const validation = validateFilters({
        ...filterParam, paramValue: [
          readmitStart.length > 0 ? readmitStart[0] : '',
          readmitEnd.length > 0 ? readmitEnd[0] : ''
        ]
      });
      if (validation.length > 0) {
        newerrors = [...validation];
      }
    }
    setreAdmitErrors(newerrors)
    return newerrors.length > 0
  }

  const applyFilters = () => {
    const validation = validate()
    if (setScheduleFilterApplied) {
      setScheduleFilterApplied(true);
    }

    if (validation?.length > 0 && validation !== 'readmitError' && validation !== 'insideError') {
      setErrors(validation);
    } else if (validation !== 'readmitError' && validation !== 'insideError') {
      const filterParams = [
        ...Array.from(getFilterParamsFromSelected(true).values()),
      ];
      const mergedMaps = getMergedMaps();
      mergedMaps.forEach((filterParam, key, map) => {
        if (keyByHeader.has(filterParam.paramName)) {
          filterParam.paramName = keyByHeader.get(filterParam.paramName);
        }
        if (hasDisplayValue(filterParam)) {
          filterParams.push(filterParam);
        }
        if (!hasDisplayValue(filterParam) && filterParam.paramName == 'Next upcoming Visit Date' && filterParam?.paramValue?.length > 0) {
          filterParams.push(filterParam);
        }
      });
      setDataMap(mergedMaps);
      workListContext && dispatchEvents(mergedMaps);
      handleChange(filterParams);
      handleClose();
    }
  };

  const dispatchEvents = (mergedMaps) => {
    const newArray = Array.from(mergedMaps).map(e => e[0]);
    newArray.forEach(e => {
      eventMap?.[e] && workListContext.sendEvent(eventMap[e]);
    });
  }

  const eventMap = {
    'Event Type': 'APPLY_EVENT_TYPE_FILTER',
    'Payor': 'APPLY_PAYOR_FILTER',
    'Care Step Type': 'APPLY_CARE_STEP_FILTER',
    'Assigned To': 'APPLY_ASSIGN_TO_FILTER',
    'Patient Risk Level': 'APPLY_RISK_LEVEL_FILTER',
    'Existing Program': 'APPLY_EXISTING_PROGRAM_FILTER',
    'Due Date': 'APPLY_DUE_DATE_FILTER',
    'Readmit': 'APPLY_READMIT_FILTER',
    'Patient Status':'APPLY_PATIENT_STATUS_FILTER',
    'Provider': 'APPLY_PROVIDER_FILTER',
    'Source': 'APPLY_SOURCE_FILTER',
    'Engaged with PCP': 'APPLY_ENGAGE_PCP_FILTER',
    'Zone': 'APPLY_ZONE_FILTER',
    'Care Path Status': 'APPLY_CARE_PATH_STATUS_FILTER',
    'Hospital': 'APPLY_HOSPITAL_FILTER'
  }

  const getConvertedTex = (displayValue) => {
    var displayValueConverted = '';

    switch (displayValue) {
      case 'ER_ADMIT':
        displayValueConverted = 'ER Readmit';
        break;
      case 'IP_ADMIT':
        displayValueConverted = 'IP Readmit';
        break;
      case 'BH_IP_ADMIT':
        displayValueConverted = 'BH-IP Readmit';
        break;
      default:
        break;
    }
    return displayValueConverted;
  }

  const ReAdmitBadge = ({ tooltip, span, paramName }) => {
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p">
          <span className="me-2 text-truncate">{span}</span>
          <i className="fa-sharp fa-solid fa-xmark" onClick={() => removeFilterParam(paramName)}></i>
        </Badge>
      </OverlayTrigger>
    )
  }

  const getBadgeLabel = (filterParam) => {
    if ((filterParam?.paramName === 'Due Date' || filterParam?.paramName === 'Date') && filterParam.paramValue.length >= 2) {
      const date0 = filterParam.paramValue[0]
      const date1 = filterParam.paramValue[1]
      const diff = differenceInDays(new Date(date1), new Date(date0))
      switch (diff) {
        case 30:
          return 'Last 30 Days'
        case 60:
          return 'Last 60 Days'
        case 90:
          return 'Last 90 Days'
        default:
          break;
      }
      return date0 + ' - ' + date1;
    }
    return 'Multiple'
  }

  const getReadmitButton = (filterParam) => {
    if (filterParam?.paramValue?.[0].includes('startDate')) {
      const dateValue = filterParam.paramValue[0].replace('startDate:', '') + ' - ' + filterParam.paramValue[1].replace('endDate:', '');
      if (filterParam.paramValue.length === 2) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      } else if (filterParam.paramValue.length === 3) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: ${getConvertedTex(filterParam.paramValue[2])} ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      } else if (filterParam.paramValue.length > 3) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: Multiple ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      }
    } else if (filterParam?.paramValue?.length === 1) {
      const displayValueConverted = getConvertedTex(filterParam.displayValue[0]);
      return (
        <ReAdmitBadge
          tooltip={`${filterParam.paramName}: ${displayValueConverted}`}
          span={`${filterParam.paramName}: ${displayValueConverted}`}
          paramName={filterParam?.paramName}
        />
      );
    } else {
      return (
        <ReAdmitBadge
          tooltip={`${filterParam.paramName}`}
          span={`${filterParam.paramName}: Multiple`}
          paramName={filterParam?.paramName}
        />
      );
    };
  }
  const getFilterButtons = filterParams => {
    let filterButtons = [];
    let dateValue;

    for (let filterParam of filterParams) {
      let filterName = filterParam.paramName;
      const keyByHeaderValues = [...keyByHeader.values()];
      if (keyByHeaderValues.includes(filterName)) {
        const keyValues = keyByHeader.keys();
        for (let keyValue of keyValues) {
          filterName = (filterName == keyByHeader.get(keyValue)) ? keyValue : filterName;
        }
      }
      const selectedFilterParams = [
        ...Array.from(getFilterParamsFromSelected(true).keys()),
      ];
      if (filterParam.paramValue.length > 0
        && !selectedFilterParams.includes(filterName)
      ) {
        if (filterParam.comparison != 'month'
          && (filterParam.paramName == 'DOB'
            || filterParam.paramName == 'Birth Date'
            || filterParam.paramName == 'Birth Date Comp'
            || filterParam.paramName =='Care Program Step Due Date'
            || filterParam.paramName == 'Next upcoming Visit Date'
          ) || (filterParam.paramName == 'ADT Event Date')
          || (filterParam.paramName == 'Latest Discharge Date')
          || (filterParam.paramName == 'Expired Date')
          || (filterParam.paramName == 'Last Known Visit')
          || (filterParam.paramName == 'Risk Score')
        ) {
          switch (filterParam.comparison) {
            case 'month':
              dateValue = 'Month - ' + filterParam.displayValue;
              break;
            case 'eq':
              dateValue = 'On - ' + filterParam.paramValue[0];
              if (filterParam.paramName == 'Risk Score') {
                dateValue = 'Equal to ' + filterParam.paramValue[0];
              }
              break;
            case 'lt':
              dateValue = 'Prior - ' + filterParam.paramValue[0];
              if (filterParam.paramName == 'Risk Score') {
                dateValue = 'Less than ' + filterParam.paramValue[0];
              }
              break;
            case 'gt':
              dateValue = 'Since - ' + filterParam.paramValue[0];
              if (filterParam.paramName == 'Risk Score') {
                dateValue = 'Greater than ' + filterParam.paramValue[0];
              }
              break;
            case 'range':
              dateValue = 'Range ' + filterParam.paramValue[0] + ' - ' + filterParam.paramValue[1];
              break;
            default:
              dateValue = filterParam.paramValue;
          }
          filterButtons.push(
            <OverlayTrigger placement="top" overlay={<Tooltip>{filterName?.replace(/\d+/g, '')}: {dateValue}</Tooltip>}>
              <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p">
                <span className="me-2 text-truncate">{filterName?.replace(/\d+/g, '')}: {dateValue}</span>
                <i className="fa-sharp fa-solid fa-xmark" onClick={() => { removeFilterParam(filterName); }}></i>
              </Badge>
            </OverlayTrigger>
          );
        } else if (filterParam.paramName === 'Readmit' && filterParam.comparison === 'range') {
          filterButtons.push(getReadmitButton(filterParam));
        } else {
          {
            if (filterParam.paramValue.length > 1) {
              const label = getBadgeLabel(filterParam);
              filterButtons.push(
                <OverlayTrigger placement="top" overlay={<Tooltip>{filterName?.replace(/\d+/g, '')}: {label}</Tooltip>}>
                  <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p">
                    <span className="me-2 text-truncate">{filterName?.replace(/\d+/g, '')}: {label}</span>
                    <i className="fa-sharp fa-solid fa-xmark" onClick={() => { removeFilterParam(filterName); }}></i>
                  </Badge>
                </OverlayTrigger>
              );
            }
            else {
              filterButtons.push(
                <OverlayTrigger placement="top" overlay={<Tooltip>{filterName?.replace(/\d+/g, '')}: {filterParam.displayValue}</Tooltip>}>
                  <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p">
                    <span className="me-2 text-truncate">{filterName?.replace(/\d+/g, '')}: {filterParam.displayValue}</span>
                    <i className="fa-sharp fa-solid fa-xmark" onClick={() => { removeFilterParam(filterName); }}></i>
                  </Badge>
                </OverlayTrigger>
              );
            }
          }
        }
      }
    }
    return filterButtons;
  };

  return (
    <>
      <Wrapper
        badges={getFilterButtons(filterParams)}
        extraFilters={extraFilters}
        isManageVisit={isManageVisit}
        setShow={setShow}
        show={show}
      >
        <EQHErrorToast
          id={'filterErrors'}
          errors={errors}
          handleClose={() => {
            setErrors([]);
            setreAdmitErrors([]);
            setInsideErrors([])
          }}
          isList
        />
          <Container>
            <Row xs={1} sm={1} md={1}>
              {filters.map((filter, idx) => {
                const dataMapClone = getMergedMaps();
                return (
                  <Col key={`${componentName}-filter-${idx}`}>
                    <FilterNew
                      filterDefinition={filter}
                      handleChange={handleFilterChange}
                      selected={
                        queuedDataMap.get(filter.getHeader()) ??
                        dataMap.get(filter.getHeader())
                      }
                      externalDataMap={dataMapClone}
                      onLoad={(externalDataMap, selectedData, setData) =>
                        filter.onLoad
                          ? filter.onLoad(
                            externalDataMap,
                            selectedData,
                            setData,
                            lastFilterParamChanged
                          )
                          : null
                      }
                      idx={idx}
                      errors={reAdmitErrors.length > 0 ? reAdmitErrors : errors}
                      insideErrors={insideErrors}
                    />
                  </Col>
                )})}
            </Row>
          </Container>
          <div className="accordion-footer position-absolute bottom-0 d-flex justify-content-between p-4 bg-white w-100">
            <Button  onClick={() => clearAllFilters()}>Clear All</Button>
            <Button  onClick={() => applyFilters()}>Apply</Button>
          </div>
      </Wrapper>
    </>
  );
};

const FilterNew = ({ filterDefinition, ...other }) => {
  filterDefinition.componentProps = {
    ...filterDefinition.componentProps,
    ...other,
  };

  return (
    {
      [GENERIC_DDL_TYPE]: (
        <DropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [COMPARISON_DDL_TYPE]: (
        <ComparisonDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [READMIT_DDL_TYPE]: (
        <ReadmitDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [SCHEDULE_DDL_TYPE]: (
        <ScheduleApptDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [Free_Text_TYPE]: (
        <FreeText
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
    }[filterDefinition.type] || <div></div>
  );
};

export { FilterGroupNew, FilterNew };
