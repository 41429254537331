import React, { useEffect, useState } from "react";
import axios from "../../utils/apiClient";
import { useAuth } from "../../context/authContext";

import Navigation from "./Navigation";
import { NAVIGATION_ITEMS } from "../../utils/navigationItems";
import { useSelector } from "react-redux";

import OrderCustomListModal from "./../modal/orderCustomListModal";
import EqualityHealth from "../../img/eqlogo.jpg";
import PatientDetails from "../quickList/components/patientDetails";
import MinimizedPatients from "./MinimizedPatients";

const componentName = "Sidebar";

export const LOGO_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/user-admin/users/organizationLogo";

const Sidebar = ({ sidebarExpanded, setSidebarExpanded }) => {
  const [data, setData] = useState({ logo: "" });
  const { patient } = useSelector((state) => state?.manageVisitReducer);
  const [showManageVisit, setshowManageVisit] = useState(true);
  let auth = useAuth();
  let userId = auth.getUserId();

  useEffect(() => {
    if (patient?.memberId?.length) {
      setshowManageVisit(true)
    } else {
      setshowManageVisit(false)
    }
  }, [patient])

  useEffect(() => {
    axios
      .get(LOGO_URL + "?userId=" + userId, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data.byteLength != 0) {
          let src = Buffer.from(response.data, "binary").toString("base64");
          setData({ logo: "data:image/jpeg;base64, " + src });
        }
      })
      .catch((error) => {
      });
  }, []);

  return (
    <>
      <OrderCustomListModal />
      <i id={`${componentName}-closeSidebar`} className={`fa-regular fa-xmark-large cursor-p d-block d-lg-none position-absolute sidebarClose ${sidebarExpanded ? '' : 'd-none'}`} onClick={() => setSidebarExpanded(false)}></i>
      <div className={`sidebar p-3 bg-white vh-100 overflow-y-auto zindex-1040 ${sidebarExpanded ? '' : 'd-none'}`}>
        <div id={`${componentName}-header`} className="sidebar-header d-flex justify-content-between align-items-center">
          {data.logo ?
            <img src={data.logo} id={`${componentName}-logoImage`} logo={data.logo} height={40} />
            : <img src={EqualityHealth} id={`${componentName}-EQlogoImage`} height={40} />}
        </div>
        <Navigation menuItems={NAVIGATION_ITEMS} />
      </div>
      <PatientDetails show={showManageVisit} setShow={setshowManageVisit} />
      <MinimizedPatients />
    </>
  );
};

export default Sidebar;
