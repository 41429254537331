import { setAddNewAppointment } from "../../../../../actions/manageVisitActions";
import { actions } from "./actions";

export function getToggles(dispatch, globalDispatch) {
  return {
    toggleAddNewAppointment: payload => globalDispatch(setAddNewAppointment(payload)),
    toggleShowAddAppointment: payload =>  dispatch({type: actions.TOGGLE_SHOW_ADD_APPOINTMENT, payload: payload}),
    toggleIsEdit: payload => dispatch({type: actions.TOGGLE_IS_EDIT, payload: payload}),
    toggleShowCancelAppointment: payload => dispatch({type: actions.TOGGLE_SHOW_CANCEL_APPOINTMENT, payload: payload}),
    toggleIsCancelingAppointment: payload => dispatch({type: actions.TOGGLE_IS_CANCELING_APPOINTMENT, payload: payload})
  }
}