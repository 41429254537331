import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {refreshAccessToken} from "./loginApiClient";
import {LocalStorageUtil} from "./localStorageUtil";
import { AUTHENTICATION_KEY } from "../context/authContext";

axios.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return config;
});

const getAccessToken = () =>

    LocalStorageUtil.getPropertyValue(AUTHENTICATION_KEY, "access_token");

const refreshAuthLogic = async (failedRequest) => {
    const [err, res] = await refreshAccessToken();
    if (err) {
        LocalStorageUtil.setObj(AUTHENTICATION_KEY, null);
        window.location = "/";
        return Promise.reject(err);
    } else {
        LocalStorageUtil.setObj(AUTHENTICATION_KEY, res.data);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res.data.access_token;
        return Promise.resolve();
    }
}
// in axios-auth-refresh 3.0.0 skipWhileRefreshing is renamed to pauseInstanceWhileRefreshing
createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes: [ 401 ], skipWhileRefreshing: false});

export default axios;
