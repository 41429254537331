import React from "react";
import Form from "react-bootstrap/Form";
import { Table } from "../../../components/tables";
import styled, { ThemeProvider } from "styled-components";
import { AuthRow } from "./AuthRow";

const componentName="Authorities";
const CustomTable = styled(Table)`
  margin:0;
  border: 0;

  th{
  	border: 0;
	}

  td{
  	border: 0;
  	padding: 0;
  }

`;

const TableWrapper = styled.div`
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
  border: 1px solid #f3f1f1;
`;

const Authorities = ({authorities, onChange}) => {
	
	const isEmpty = require("is-empty");
	
	return ( 
		<Form>
	    	<Form.Label id={`${componentName}-authoritiesLabel`}>Authorities</Form.Label>
	    	<TableWrapper>
		    	<CustomTable>
		    		<thead>
		    			<tr>
							<th></th>
			    			<th className="text-center">Read</th>
			    			<th className="text-center">Write</th>                			
		    			</tr>
		    		</thead>
		    		<tbody>
					{
		            	!isEmpty(authorities) &&
		              	authorities.map((authority, idx) =>
		            			<> 
		                  			<AuthRow id={`${componentName}-authority`} idx={`${idx}`} authority={authority} onChange={onChange} readEnabled={true} writeEnabled={true} key={authority.readMapping+'-'+authority.writeMapping}/>
									{
										!isEmpty(authority.subAuthorities) &&
		                				authority.subAuthorities.map((subAuthority, subIdx) =>
		                						<AuthRow id={`${componentName}-subAuthority`} idx={`${idx}-${subIdx}`} authority={subAuthority} onChange={onChange} readEnabled={authority.readGranted} writeEnabled={authority.writeGranted}
		                							key={authority.readMapping+'-'+authority.writeMapping}/>
		                				)
		                			}
		            			</>
						)
		            }
		    		</tbody>
		    	</CustomTable>
	    	</TableWrapper>
	    </Form>
	)
};

 export { Authorities };