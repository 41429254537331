import React from 'react'
import {
  CMWorklistProvider
} from './components/CMWorklistModals/context/CMWorklist.context'
import {CustomWorklistProvider} from "../../../components/CustomWorklist";
import Main from './components/Main'
import Modals from './components/CMWorklistModals/Modals'

const CMWorklist = () => {
  return (
    <CustomWorklistProvider>
      <CMWorklistProvider>
        <Modals/>
        <Main/>
      </CMWorklistProvider>
    </CustomWorklistProvider>
  )
}

export {CMWorklist}