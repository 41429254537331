import React from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

const AuthorityLabel = styled(Form.Label)`
  font-weight: bold;
`;

const SubAuthorityLabel = styled(Form.Label)`
  padding-left: 12px;
`;

const StyledInput = styled.input`
  padding: 10px;
`;

const AuthRow = ({authority, onChange, readEnabled, writeEnabled, id, idx}) =>
	<tr>
  		<td className="ps-3">
  			{authority.subAuthority === true ?  <SubAuthorityLabel id={`${id}Label-${idx}`}>{authority.description}</SubAuthorityLabel> : <AuthorityLabel id={`${id}Label-${idx}`}>{authority.description}</AuthorityLabel>}
  		</td>
  		<td className="text-center">
  			{ authority.readEnabled === true && 
				<StyledInput
					id={`${id}ReadCheckbox-${idx}`}
		            type="checkbox"
		            value={authority.readMapping}
		            key={authority.readMapping}
		            checked={authority.readGranted}
		            onChange={onChange}
		            disabled={!readEnabled}
		          />
	        }
		</td>
  		<td className="text-center">
  			{ authority.writeEnabled === true &&
				<StyledInput
					id={`${id}WriteCheckbox-${idx}`}
		            type="checkbox"
		            value={authority.writeMapping}
		            key={authority.writeMapping}
		            checked={authority.writeGranted}
		            onChange={onChange}
		            disabled={!writeEnabled}
		          />
	        }
      </td>
  	</tr>
  	;

 export { AuthRow };