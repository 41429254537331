import React, { useEffect, useState } from "react"
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { transformData } from "../utils";
import { Timeline as GenericTimeline } from '../../../../components/Timeline';

import { useHospitalEventsContext } from "../context/HospitalEvents.context";

import HospitalEventTimelineTitle from "./HospitalEventTimelineTitle";
import HospitalEventCard from "./HospitalEventCard";
import HospitalEventsHeader from "./HospitalEventsHeader";

const componentName = 'hospitalEvents';

/**
 * Renders the tab content of hospital events for a given patient.
 *
 * @param {Object} patient - The patient object.
 */
const HospitalEvents = ({ patient }) => {
  const {
    hospitalEventsList,
    getHospitalEvents,
    totalSize
  } = useHospitalEventsContext();
  const [hospitalEvents, setHospitalEvents] = useState([]);
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (hospitalEventsList) {
      setOpenStates(prevStates => {
        const newStates = new Array(hospitalEventsList.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [hospitalEventsList])

  useEffect(() => {
    if (patient?.id) {
      getHospitalEvents(patient.id);
    }
  }, [patient])

  useEffect(() => {
    setHospitalEvents(transformData(hospitalEventsList));
  }, [hospitalEventsList])

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => [...prev.map((state, i) => i === index ? !state : state)]);
  };

  return (
    <Row className={`${componentName}-view`}>
      <HospitalEventsHeader patient={patient} />

      <Row md={12} className='align-items-baseline'>
        <Col md={6}>
            <span
              bg="light"
              id={`${componentName}-hospitalEventsCount`}
              className="border text-dark badge badge-pill mt-4">
              {totalSize}{" "}{"Event"}{totalSize === 1 ? '' : 's'}
            </span>
        </Col>
        <Col md={6} className='text-end'>
          <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Expand / Collapse All
                </Tooltip>
              }
          >
            <span
              bg="light"
              className="border text-dark badge badge-pill mt-2"
              onClick={() => toggleAll(!expandAll)}
              style={{maxWidth: 'fit-content', fontSize: '12px', marginRight: '-20px' }}
            >
              <i className='fas fa-angle-double-up'/>
              <i className='fas fa-angle-double-down'/>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>

      <GenericTimeline
        items={hospitalEvents}
        titleBuilder={(hospitalEvent, idx) => (
          <HospitalEventTimelineTitle
            hospitalEvent={hospitalEvent}
            idx={idx}
          />
        )}
        bodyBuilder={(hospitalEvent, idx) => (
          <HospitalEventCard
            hospitalEvent={hospitalEvent}
            idx={idx}
            toggleIndividual={() => toggleIndividual(idx)}
            open={openStates[idx]}
          />
        )}
      />
    </Row>
  );
}

export default HospitalEvents;
