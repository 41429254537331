import React from "react";
import Form from "react-bootstrap/Form";
import { isBefore, parseISO, isAfter } from "date-fns";

const EQHDatePicker = ({ id, onChange, min, max, ...restProps }) => {
  if (restProps.hasOwnProperty("value") && !restProps.value) {
    restProps.value = "";
  }
  const handleChange = (e) => {
    const clear = () => {
      e.target.value = "";
    };
    const strDate = e.target.value;
    let objDate = null;
    try {
      objDate = parseISO(strDate + "T00:00:00");
    } catch (e) {
      objDate = null;
    }
    const milliseconds = e.target.valueAsNumber;
    if (onChange) {
      let error = "";
      if (min) {
        if (strDate.substr(0, 4).localeCompare("1900") > 0 && strDate.localeCompare(min) < 0) {
          error = "The selected date cannot be in the past.";
          clear();
        }
      }
      if (max) {
        if (strDate.localeCompare(max) > 0) {
          error =
            "Future Dates are not allowed, please check your date and re-enter.";
          clear();
        }
      }
      onChange({
        milliseconds,
        objDate,
        strDate,
        error,
      });
    }
  };

  return (
    <Form.Control
      type="date"
      min={min}
      max={max}
      id={id}
      {...restProps}
      onChange={handleChange}
    />
  );
};

export { EQHDatePicker };
