import React, { useEffect, useState } from 'react';
import { fetchDropDown, addItem, editItem, getTotalSize } from '../actions';
import { getErrorMsg } from '../utils';
import {
  Row,
  Table,
  Col,
  Button,
  Form,
  Spinner
} from "react-bootstrap";
import AddEditModal from './AddEditModal';
import CustomPagination from '../../../../common/CustomPaginaton';

function DropDownList({
  dropDownListName,
  title,
  moduleName,
  onError,
  setErrors,
  customColumns,
}) {

  //States
  const [defaultState, setDefaultState] = useState({id: '', name: '', value: '', status: 'ACTIVE', stepStatus: ''});
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20, totalSize: 0 });
  const [columns, setColumns] = useState([
    {
      name: 'Description',
      key: 'value',
    },
    {
      name: 'Status',
      key: 'status'
    }
  ]);

  //Effects
  useEffect(() => {
    if(customColumns) {
      setColumns([...customColumns]);
      customColumns.forEach(c => {
        c?.isDropDown &&  setDefaultState({...currentItem, [c?.key]: c?.dropDownOptions?.[c?.dropDownDefault]?.key});
      })
    }
    getDropDown();
  }, []);

  useEffect(() => {
    setErrors([]);
    !show && setIsEditing(false);
  }, [show]);

  //Functions
  function validateField(e, column) {
    const validations = Object.keys(column?.validations);
    let hasErrors = false;
    let message = [];

    validations.forEach(key => {
      const cb = column?.validations?.[key]?.cb;

      if (cb(e?.target?.value)) {
        hasErrors = true
        message.push(column?.validations?.[key]?.message)
      }
    })

    if (hasErrors) {
      onError([message.join(', ')]);
    } else {
      setCurrentItem({ ...currentItem, [column?.key]: e.target.value })
    }
  }

  function getDropDown(page = 1) {
    setIsLoading(true)
    fetchDropDown(dropDownListName, page)
      .then(res => {
        setData(res?.data?.data);
        setPagination({
          ...pagination,
          pageNumber: page,
          totalSize: res?.data?.count
        });
        setIsLoading(false);
      })
      .catch(() => {
        onError(getErrorMsg('fetch'));
        setIsLoading(false);
      });
  }

  const handleSave = () => {
    setIsLoading(true);
    if (dropDownListName === 'ZIP_CODE' && (!currentItem?.description || !currentItem?.value)) {
      setIsLoading(false);
      onError(getErrorMsg('required'));
      return
    }
    if (dropDownListName === 'ZIP_CODE' && currentItem?.value?.length !== 5) {
      setIsLoading(false);
      onError(getErrorMsg('minLength'));
      return
    }
    if (!isEditing) {
      addItem(
        moduleName,
        dropDownListName,
        currentItem
      ).then(() => refreshData())
        .catch(err => {
          onError(err);
          setIsLoading(false);
        });
    } else {
      editItem(currentItem)
        .then(() => refreshData(pagination?.pageNumber))
        .catch(err => {
          onError(err);
          setIsLoading(false);
        });
    }
  }

  const refreshData = (page = 1) => {
    fetchDropDown(dropDownListName, page)
      .then(res => {
        setPagination({ pageNumber: page, pageSize: 20, totalSize: res.data.count })
        setData(res?.data?.data);
        setShow(false);
        setCurrentItem(defaultState);
        setIsLoading(false);
        setErrors([]);
      })
  }

  const handleEdit = (member) => {
    setCurrentItem({ ...member });
    setShow(true);
  }

  return (
    <>
      <Row>
        <Col>
          <Button
            id={`${dropDownListName}-addItem`}
            variant="secondary"
            className="mb-3"
            onClick={() => {
              setShow(true);
              setCurrentItem(defaultState);
            }}>
            Add Item
          </Button>
        </Col>
        <Col>
          {isLoading
            ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : Math.ceil(pagination.totalSize / pagination.pageSize) > 1 &&
            <CustomPagination
              paginateButClicked={page => getDropDown(page)}
              MAX_PAGE={Math.ceil(pagination.totalSize / pagination.pageSize)}
              pageNumber={Math.ceil(pagination.pageNumber)}
            />
          }
        </Col>
      </Row><div className="table-responsive text-nowrap">
        <Table responsive bordered hover>
          <thead><tr><th scope="col"></th>
            {
              columns.map(column => {
                return (
                  <th scope="col">
                    {column?.name || column}
                  </th>
                )
              })
            }
          </tr></thead>
          <tbody>
            {data && data?.map((m, idx) => {
              return (
                <tr key={`${m?.value}${idx}`}>
                  <th>
                    <Button
                      id={`${dropDownListName}-EditBtn-${idx}`}
                      variant="link"
                      className="p-0 ms-2"
                      onClick={() => {
                        handleEdit(m);
                        setIsEditing(true);
                      }
                      }><i className="fa fa-edit"></i>
                    </Button>
                  </th>
                  {
                    columns?.map(column => {
                      if (column?.name === 'Status') {
                        return (
                          <td id={`${dropDownListName}-status-${idx}`}>
                            <span className={m?.status === "ACTIVE" ? 'badge bg-success' : 'badge bg-secondary'}>
                              {m?.status}
                            </span>
                          </td>
                        )
                      } else {
                        return (
                          <td id={`${dropDownListName}-program-${idx}`}>{m?.[column.key]}</td>
                        )
                      }
                    })
                  }
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AddEditModal
        show={show}
        setShow={setShow}
        isEditing={isEditing}
        isLoading={isLoading}
        title={title}
        handleSave={handleSave}
      >
        <Row>
          {
            columns.map(column => {
              if (column.name === 'Status') {
                return (
                  <Col className="mb-3">
                    <label id={`${dropDownListName}-${column.name}-label`} className="d-block">
                      {column.name}
                    </label>
                    <select
                      id={`${dropDownListName}-${column.name}-value`}
                      name="status"
                      value={currentItem.status}
                      onChange={e => setCurrentItem({ ...currentItem, status: e.target.value })}
                      className="form-select"
                      required={true}>
                      <option id={`${dropDownListName}-teamItem-active`} value="ACTIVE">Active</option>
                      <option id={`${dropDownListName}-teamItem-inactive`} value="INACTIVE">Inactive</option>
                    </select>
                  </Col>
                )
              } else {
                return (
                  <Col className="mb-3" md={6}>
                    <label id={`${dropDownListName}-memberLabel-${column.name}`} className="d-block">{column.name}</label>
                      {column?.isDropDown
                        ? <Form.Control
                            as="select"
                            className="form-select"
                            onChange={e => setCurrentItem({ ...currentItem, [column?.key]: e.target.value.toUpperCase() })}
                            value={
                              currentItem?.[column?.key].toUpperCase() ||
                              column?.dropDownOptions[column?.dropDownDefault]?.key.toUpperCase()
                            }
                          >
                            {column?.dropDownOptions?.map(o => <option key={o.key} value={o.key}>{o.value}</option>)}
                        </Form.Control>
                        : <Form.Control
                            id={`${dropDownListName}-${column.name}`}
                            placeholder={currentItem?.[column.key]}
                            onChange={e => {
                              const hasValidation = column?.validations ? true : false
                              hasValidation
                                ? validateField(e, column)
                                : setCurrentItem({ ...currentItem, [column?.key]: e.target.value })
                            }}
                            value={currentItem?.[column?.key]}
                            disabled={isEditing && column.key === 'value'}
                            required={true}
                            type='text'
                          />
                      }
                  </Col>
                )
              }
            })
          }
        </Row>
      </AddEditModal>
    </>
  )
}

export default DropDownList;