import {invert} from "lodash";

export const CARE_GAP_STATUS_BY_ENUM = {
  ORDERED: 'Ordered',
  OPEN: 'Open',
  PATIENT_REFUSAL: 'Patient Refused',
  LEFT_MESSAGE: 'Left Message',
  APPOINTMENT_SCHEDULED: 'Scheduled Appointment',
  REFERRED: 'Referred',
  PENDING_DOCUMENTATION: 'Pending Documentation',
  CHANGED_PCPS: 'Changed PCP\'s',
  UNABLE_TO_CONTACT: 'Unable to Contact',
  APPOINTMENT_UNSCHEDULED: 'Unscheduled Appointment',
  MARKED_FOR_CLOSURE: 'Marked for Closure',
  INCOMPLETE: 'Incomplete',
  CONFIRMED: 'Confirmed',
  GAP_INELIGIBLE: 'Gap Ineligible',
  EXCLUDED: 'Excluded',
  CLOSED: 'Closed',
  PREVIOUSLY_COMPLETED: 'Previously Completed',
}

export const CARE_GAP_STATUS_BY_DESCRIPTION = invert(CARE_GAP_STATUS_BY_ENUM);

export const NOTES_ACTIVITY_TYPE_MAP = {
  APPOINTMENT: "Appointments",
  ASSESSMENT: "Assessments",
  ATTACHMENT: "Attachments",
  BEHAVIORAL_HEALTH_RECORD: "Behavioral Health Records",
  CARE_OPPORTUNITIES: "Care Opportunities",
  CARE_PATH: "Care Paths",
  CARE_PLAN: "Care Plans",
  CONSULT_NOTES: "Consult Notes",
  DISCHARGE_SUMMARY: "Discharge Summaries",
  HEALTH_PLAN_NOTIFICATION: "Health Plan Notifications",
  IMPORTANT_NOTES: "Important Notes",
  INBOUND_CALL: "Inbound Calls",
  LAB_RECORD: "Lab Records",
  MEDICATION_RECORD: "Medication Records",
  MEMBER_COMMUNICATION: "Member Communications",
  OP_REPORT: "OP Reports",
  OTHER: "Other",
  OTHER_REFERRAL_NOTES: "Other Referral Notes",
  OUTBOUND_CALL: "Outbound Calls",
  PHYSICAL_HEALTH_RECORD: "Physical Health Records",
  PROVIDER_PLAN_NOTIFICATION: "Provider Plan Notifications",
  RADIOLOGY_RECORD: "Radiology Records",
  REFERRAL_NOTES: "Referral Notes",
  SECURE_MESSAGE: "Secure Messages",
  SUSPECTS: "Suspects",
  CARE_TEAM_VISIT_FACE_TO_FACE: "Care Team - Visit: Face to Face",
  CARE_TEAM_VISIT_HOSPITAL: "Care Team - Visit: Hospital",
  CARE_TEAM_VISIT_PROVIDER: "Care Team - Visit: Provider",
  CARE_TEAM_CHART_PREP: "Care Team - Chart Prep",
  CARE_TEAM_TELEPHONIC_OUTREACH: "Care Team - Telephonic Outreach",
  CARE_TEAM_EMAIL: "Care Team - Email",
  CARE_TEAM_TEXT: "Care Team - Text",
  CARE_TEAM_GENERAL: "Care Team - General",
};
