import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';

function DefaultWrapper({ extraFilters, badges, children }) {
  const [showOverlay, setshowOverlay] = useState(false)

  const handleOffCanvas = (state) => {
    setshowOverlay(state)
  }
  useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasFilters');
    offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffCanvas(false));
    offcanvasElement.addEventListener('shown.bs.offcanvas', () => handleOffCanvas(true));
    return () => {
      offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffCanvas);
      offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffCanvas);
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-start gap-2 mb-2">
        <Button variant="light" className="border nowrap px-3" data-bs-target="#offcanvasFilters" data-bs-toggle="offcanvas" aria-controls="offcanvasFilters">
          <i className="fa-solid fa-bars-filter me-2"></i> Filter
        </Button>
        <div>
          {extraFilters && extraFilters}
        </div>
        <div className="d-flex flex-wrap align-items-start gap-2">
          {badges}
        </div>
      </div>
      {showOverlay &&
        <div id="overlay-side-filters" onClick={() => { let closeCanvas = document.getElementById('closeSideFilters'); closeCanvas.click() }}></div>
      }
      <div className="offcanvas offcanvas-start filterOffcanvas" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasFilters" aria-labelledby="offcanvasFilters">
        <div className="d-flex justify-content-between align-items-center p-4">
          <span className='h5 fw-bold mb-0'>Filters</span>
          <button type="button" id="closeSideFilters" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="accordion mb-5 pb-5" id="accordionFilters">
          {children}
        </div>
      </div>
    </>
  )
}

export default DefaultWrapper