import React, { useCallback } from 'react'
import { Row, Col } from "react-bootstrap";
import { formatDate } from '../../../../../../../utils/util';

const componentName = "previsitChartVisitDue";
const VisitsDue = ({ patient }) => {

   const checkFilter = useCallback((currentStatus) => {
    return currentStatus === "Open" || currentStatus === "Scheduled Appointment" 
    || currentStatus === "Ordered" || currentStatus === "Patient Refused" 
    || currentStatus === "Left Message" || currentStatus === "Referred" || currentStatus === "Pending Documentation"
    || currentStatus === "Changed PCP's" || currentStatus === "Unable to Contact"
    || currentStatus === "Unscheduled Appointment";
  },[])
  const getQuarterFromMonth = useCallback((gapAndYear) => {
   return ` (${gapAndYear?.split(" ")?.[1]?.split(".")?.[0]})`
  },[])

  const isOpen = useCallback((arr) => {
    return arr?.some(e=>checkFilter(e.careGapStatus))
  },[patient])


  return (
    <>
      {patient.careVisitDtos && patient.careVisitDtos.length > 0 && isOpen(patient.careVisitDtos) ? (
        <div className="bg-light rounded-3 p-3 mb-3">
          <Row>
            <h5 id={`${componentName}-visitsDue`} className="fs-5 fw-bold mb-1">Visits Due</h5>
            {patient?.careVisitDtos?.map((e, idx) =>(checkFilter(e?.careGapStatus)) &&
              <Col key={`${patient.name}-${idx}`} id={`${componentName}-visit-${idx}`} sm={6}>
                <div className="d-flex align-items-start">
                  <input id={`${componentName}-visitCheck`} className="form-check-input opacity-100 me-3" type="checkbox" value="" disabled />
                  <div>
                    <div id={`${componentName}-visitName`} className="fw-bold">{e?.name}{e?.abbreviation == 'HRM' ? getQuarterFromMonth(e?.gapAndYear) :""}</div>
                    {e?.chronicConditionName && (
                      <small id={`${componentName}-chronicConditionName`} className="fst-italic">{e?.chronicConditionName}</small>
                    )}
                    {(e?.abbreviation === 'WC15' || e?.abbreviation === 'WC30' || e?.abbreviation === 'FUH' || e?.abbreviation === 'ADT-IP')
                    && e?.dueDate ? <small id={`${componentName}-dueByDate`} className="nowrap">Due by {formatDate(e?.dueDate)}</small> : <small id={`${componentName}-dueByAfterDate`} className="nowrap">Due after {formatDate(e?.dueDate)}</small> }
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      ) : null}
    </>
  )
}

export default VisitsDue