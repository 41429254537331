import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, ProgressBar, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useCMWorklistContext } from '../../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context';
import { reassignMember } from '../../../../caremgmt/careTeam/ReassignMemberModal/utils';
import { useManageVisitContext } from '../../context/ManageVisitContext/ManageVisit.context';
import { RedAsterisk } from '../../../../../utils/util';
import ToastMessage from '../../../../../components/Toast';
import { useAuth } from '../../../../../context/authContext';
import PatientInformationSummary
  from "../../../../caremgmt/worklist/components/CMWorklistModals/modalsComponents/PatientInformationSummary";
import { setReloadWorklist } from '../../../../../actions/manageVisitActions';
import { useDispatch } from 'react-redux';


const componentName = 'NewCareProgram';
const programAlreadyAssignedError = "Patient has an open Care Path under this program";


const assignCareProgramTitle = "Assign New Care Program";

const teamCapacityTitle = "Available Team Members";
const reassignCareProgram = "Reassign Care Program";

const submitLabel = "Submit";
const assignLabel = "Assign"

function NewCareProgram({handleCancel, careManagementsPkg}) {
  const {
    assignPatientTo,
    toggleAssignPatientTo,
    patient,
    teamsCapacity,
    getTeamsCapacity,
    getCarePrograms,
    carePaths,
    assignPatientRequest,
    isAssignedPatient,
    getPatientsList,
    patientSearch,
    activeRole,
  } = useCMWorklistContext();
  const auth = useAuth();
  const dispatch = useDispatch();
  const manageVisitState = useManageVisitContext();
  const [note, setNote] = useState('');
  const [currentOpenPrograms, setCurrentOpenPrograms] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [modalTitle, setModalTitle] = useState(assignPatientTo.source === 'manageVisitCareProgram' ? assignCareProgramTitle : reassignCareProgram);
  const [isPatientInfoStep, setIsPatientInfoStep] = useState(true);
  const [isTeamCapacityStep, setIsTeamCapacityStep] = useState(false);
  const [isSelectProgramStep, setIsSelectProgramStep] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState(submitLabel);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [selectedProgram, setSelectedProgram] = useState();
  const [programId, setProgramId] = useState("");
  const [isProgramAlreadyAssigned, setIsProgramAlreadyAssigned] = useState(false);
  const [isAssignToSelfEnabled, setIsAssignToSelfEnabled] = useState(false);

  useEffect(() => {
    if (assignPatientTo.show) {
      setModalTitle(assignPatientTo.source === 'manageVisitCareProgram' ? assignCareProgramTitle : reassignCareProgram);
      setSubmitButtonLabel(submitButtonLabel);
      getTeamsCapacity();
      loadCurrentOpenPrograms();
    }

  }, [assignPatientTo.show])

  useEffect(() => {
    if (isAssignedPatient) {
      closeModal(true);
    }
  }, [isAssignedPatient])

  useEffect(() => {
    if(isAssignToSelfEnabled) {
      if (selectedTeam) {
        if(!selectedTeamMember) {
          const userProgramLead = getUserProgramLead();
          if (userProgramLead) {
            selectTeamMember({target: {value: userProgramLead.programLeadId}});
            submit();
          }
        }
      } else {
        const userTeamCapacity = getUserTeamCapacity();
        userTeamCapacity && selectTeam({target: {value: userTeamCapacity.teamId}});
      }
    }
  }, [isAssignToSelfEnabled, selectedTeam])


  const loadCurrentOpenPrograms = () => {
    if (patient && patient.openProgramList) {
      const programs = patient.openProgramList.split(",")
      setCurrentOpenPrograms(programs);
    }
  }

  const closeModal = (action) => {
    if(action){
      toggleAssignPatientTo({ ...assignPatientTo, show: false, refreshFromReassign:true });
    }
    else{
      toggleAssignPatientTo({ ...assignPatientTo, show: false, refreshFromReassign:true });
    } 
    setCurrentOpenPrograms([]);
    setModalTitle(null);
    setIsPatientInfoStep(true);
    setIsTeamCapacityStep(false);
    setSelectedTeamId("");
    setIsSubmitButtonDisabled(true);
    setIsSelectProgramStep(false);
    setIsProgramAlreadyAssigned(false);
    setSelectedProgram(null);
    setProgramId("");
    handleCancel()
    setIsAssignToSelfEnabled(false);
  }

  const cancel = () => {
    if (isPatientInfoStep) {
      closeModal();
    }
    if (isTeamCapacityStep) {
      setIsTeamCapacityStep(false);
      setIsPatientInfoStep(true);
      setModalTitle(assignPatientTo.source === 'manageVisitCareProgram' ? assignCareProgramTitle : reassignCareProgram);
      setSelectedTeam(null);
      setSelectedTeamId("");
      setSubmitButtonLabel(submitLabel);
      setIsSubmitButtonDisabled(true);
      setIsAssignToSelfEnabled(false);
    }
    if (isSelectProgramStep) {
      setIsPatientInfoStep(false);
      setIsTeamCapacityStep(true);
      setIsSelectProgramStep(false);
      setModalTitle(teamCapacityTitle);
      setSubmitButtonLabel(assignLabel);
      setIsSubmitButtonDisabled(false);
    }
    setIsProgramAlreadyAssigned(false);
    setSelectedProgram(null);
    setProgramId("");
  }

  const selectTeam = (event) => {
    const teamId = event.target.value;
    const team = teamsCapacity.filter(team => team.teamId === teamId)?.shift();
    setSelectedTeam(team);
    setModalTitle(teamCapacityTitle);
    setSelectedTeamId(teamId)
    setIsPatientInfoStep(false);
    setIsTeamCapacityStep(true);
    setSubmitButtonLabel(assignLabel);
    setSelectedTeamMember(null);

    if (isAssignToSelfEnabled) {
      const userTeamCapacity = getUserTeamCapacity();
      if (userTeamCapacity?.teamId !== teamId) {
        setIsAssignToSelfEnabled(false);
      }
    }
  }

  const calculateCapacity = (programLead) => {
    return programLead.totalDailyCapacity - programLead.dailyAssignment;
  }

  const getProgressBarVariant = (programLead) => {
    const totalDailyCapacity = parseInt(programLead.totalDailyCapacity);
    const dailyAssignment = parseInt(programLead.dailyAssignment);
    const halfCapacity = totalDailyCapacity / 2;
    let variant = "success";
    if (dailyAssignment > halfCapacity) {
      variant = "warning";
    }
    if (dailyAssignment >= totalDailyCapacity) {
      variant = "danger"
    }
    return variant;
  }

  const submit = () => {
    if (isTeamCapacityStep) {
      assignTeamMember();
    }
    if (isSelectProgramStep) {
      assignPatientToTeamMember()
    }
  }

  const assignTeamMember = () => {
    setIsSelectProgramStep(true);
    setIsTeamCapacityStep(false);
    setIsPatientInfoStep(false);
    setModalTitle(assignPatientTo.source === 'manageVisitCareProgram' ? assignCareProgramTitle : reassignCareProgram);
    setSubmitButtonLabel(submitLabel);
    if (!selectedProgram && assignPatientTo.source === 'manageVisitCareProgram') {
      setIsSubmitButtonDisabled(true);
    }
    if (assignPatientTo.source === "manageVisitCareProgram") {
      getCarePrograms();
    }
  }

  const assignPatientToTeamMember = () => {
    if (selectedTeamMember) {
      if (assignPatientTo.source === "manageVisitCareProgram"  && selectedProgram) {
        const assignPayload = [{
          "notes": note,
          "action": "ASSIGN",
          "patientId": patient.id,
          "programLeadId": selectedTeamMember.programLeadId,
          "programId": selectedProgram.value,
          "programName": selectedProgram.text,
          "openCarePath": true,
          "referralId": patient.referralDtoList[0]?.id,
          "adtId": patient.adtId
        }]
        assignPatientRequest(assignPayload)
            .then(_ => {
              closeModal(true);
            })
            .catch(error => {
              manageVisitState.persistErrorMessage(error?.response?.data?.message)
            })
      }
      else {
        const data ={
          "notes": note,
          "action": "REASSIGN",
          "patientId": patient.id,
          "programLeadId":selectedTeamMember.programLeadId,
          "programId": assignPatientTo?.careProgram?.id,
          "programName": assignPatientTo?.careProgram?.name,
          "currentProgramLeadId": assignPatientTo?.careProgram?.programLeadId,
          "patientProgramId": assignPatientTo?.careProgram?.patientProgramId,
          "userId":auth.getUserId()
        }
        reassignMember(data)
          .then(res => {
            closeModal(true);
          })
          .catch(error => {
            manageVisitState.persistErrorMessage(error?.response?.data?.message)
          })

      }
    }
    dispatch(setReloadWorklist(true));
  }

  useEffect(() => {
    if (isAssignedPatient) {
      getPatientsList(patientSearch);
      closeModal();
    }
  }, [isAssignedPatient]
  )

  const reassignTeamMember = () => {
    setIsSelectProgramStep(false);
    setIsTeamCapacityStep(true);
    setIsSubmitButtonDisabled(false);
    setSubmitButtonLabel(assignLabel);
  }

  const selectTeamMember = (event) => {
    const teamMemberId = event.target.value;
    const teamMember = selectedTeam?.programLeads?.filter(programLead => programLead.programLeadId === teamMemberId)?.shift();
    setSelectedTeamMember(teamMember);
    setIsSubmitButtonDisabled(false);

    if (isAssignToSelfEnabled) {
      const programLead = getUserProgramLead();
      if (programLead?.programLeadUserAccountId !== teamMember?.programLeadUserAccountId) {
        setIsAssignToSelfEnabled(false);
      }
    }
  }

  const selectProgram = (event) => {
    const programId = event.target.value;
    const program = carePaths?.filter(careProgram => careProgram.value === programId)?.shift();
    setIsSubmitButtonDisabled(true);
    setProgramId(programId);
    if (!isValidProgramSelection(program)) {
      setSelectedProgram(program);
    if (program) {
      setIsSubmitButtonDisabled(false);
    }
    }
  }

  const isValidProgramSelection = (program)=>{
    const activeProgramList =[];
    const activePro= careManagementsPkg.map((data, index) => data.isActive ? (activeProgramList.push(data.name)):null);
      const isValid = activeProgramList.includes(program.text);
       setIsProgramAlreadyAssigned(isValid);
       return isValid;
  }


  const isTeamMemberFullCapacity = (programLead) => {
    return Number(programLead.dailyAssignment) >= Number(programLead.totalDailyCapacity);
  }

  const getUserTeamCapacity = () => {
    const userTeam = auth.getTeam() ?? {};
    return teamsCapacity?.find((data, i) => !!userTeam[data.teamId]);
  }

  const getUserProgramLead = () => {
    const userId = auth.getUserId();
    return getUserTeamCapacity()?.programLeads?.find((programLead, i) => {
      return userId === programLead.programLeadUserAccountId
    })
  }

  const assignToSelf = (enabled) => {
    setIsTeamCapacityStep(false);
    setIsPatientInfoStep(true);
    setModalTitle(assignCareProgramTitle);
    setSelectedTeam(null);
    setSelectedTeamId("");
    setSubmitButtonLabel(submitLabel);
    setIsSubmitButtonDisabled(true);
    setIsAssignToSelfEnabled(enabled);
  }

  const showAssignToSelf = () => {
    if (auth.isAdmin()) {
      return false;
    }
    return !(activeRole?.role === 'Hub Coordinator' || auth.getRoles()[0] === 'Hub Coordinator' );
  }

  return (
    <Modal
      id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModal`}
      size="md"
      show={assignPatientTo.show}
      onHide={() => closeModal()}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-patientAssignPatientToNewCareProgramConfirmTitle`}>{modalTitle}</Modal.Title>
        <button
          id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        />
      </Modal.Header>
      <Modal.Body className="overflow-y-auto" style={{ "height": "35rem" }} >
        {(isPatientInfoStep || isSelectProgramStep) &&
          <Row className="mb-4 bg-light p-3 rounded-3">
            <Col>
              <PatientInformationSummary rowLayout={2}
                                         fullName={patient.fullName}
                                         memberId={patient.memberId}
                                         dateOfBirth={patient.dateOfBirth}
                                         className='mb-3'/>
              {assignPatientTo.source === 'mangeVisitReassignCareProgram' &&
                <Row>
                  <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                    <Form.Label id={`${componentName}-currentLabel`}>
                      Current Care Program
                    </Form.Label>
                    <div id={`${componentName}-carePath-1`} className="d-flex flex-column">
                      <span id={`${componentName}-careStepName-2`}>{assignPatientTo?.careProgram?.name}</span>

                    </div>
                  </Form.Group>

                </Row>
              }
              {(careManagementsPkg && assignPatientTo.source === 'manageVisitCareProgram') &&
                <Row>
                  <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                    <Row className="mb-1">
                      <Col md={7}>
                        <Form.Label id={`${componentName}-currentLabel`}>
                          Current Care Programs
                        </Form.Label>
                      </Col>
                      <Col md={5}>
                        <Form.Label id={`${componentName}-currentLabel`}>
                          Days Opened
                        </Form.Label>
                      </Col>
                    </Row>

                    {careManagementsPkg?.filter((data, index) => data.isActive).map((data, index) => (
                      <Row className="mb-1">
                        <Col md={7}>
                          {data.name}
                        </Col>
                        <Col md={5}>
                          {data.isClosed ? null : data.daysSinceStarted}
                        </Col>
                      </Row>
                    ))}
                  </Form.Group>
                </Row>
              }
            </Col>
          </Row>
        }
        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Group id={`${componentName}-team`}>

                <div className="d-flex justify-content-between">
                  <Form.Label id={`${componentName}-teamLabel`}>
                    Team<RedAsterisk/>
                  </Form.Label>

                  { showAssignToSelf() && <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      disabled={!teamsCapacity?.length || isSelectProgramStep}
                      value={isAssignToSelfEnabled}
                      checked={isAssignToSelfEnabled}
                      onChange={(e) => {
                        assignToSelf(!isAssignToSelfEnabled)
                      }}
                    />
                    <Form.Check.Label className="fw-bold">Assign to
                      Self?</Form.Check.Label>
                  </Form.Check> }
                </div>

                <select id={`${componentName}-teamOptions`} className="form-select"
                  disabled={isSelectProgramStep}
                  onChange={event => selectTeam(event)} value={selectedTeamId}>
                  <option id="teamOption-select" value="">Select</option>
                  {teamsCapacity.map((data, i) => (
                    <option id={`teamOption${i}`} value={data.teamId}>{data.teamName}</option>
                  ))}
                </select>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {isTeamCapacityStep && selectedTeam?.programLeads?.length > 0 &&
          <Row>
            <Col>
              <Row className="fw-bold">
                <Col>
                  <span>Team Member</span><RedAsterisk/>
                </Col>
                <Col className="text-end">
                  <span>Capacity</span>
                </Col>
              </Row>
              <Row>
                <ToggleButtonGroup
                  type="radio"
                  className="careTeamCapacity d-flex flex-column"
                  name="careTeamCapacity"
                  value={selectedTeamMember?.programLeadId}
                >
                  {selectedTeam.programLeads.map((programLead, index) => (
                    <ToggleButton
                      id={`tbg-radio-${index}-${selectedTeamId}`}
                      variant="team-member"
                      className={`border p-3 text-dark rounded-3 mb-2 ${selectedTeamMember?.programLeadId == programLead?.programLeadId ? 'active' : ''}`}
                      value={programLead.programLeadId}
                      onChange={(event) => selectTeamMember(event)}
                      key={`${programLead?.programLeadId}-${selectedTeamId}-${index}`}
                      tabIndex={index}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column text-start w-50">
                          <span className="text-dark">{programLead.programLeadName}</span>
                          {(selectedTeam.teamName === 'Community Health Workers' && programLead.zone) ? (
                              <small className="text-muted text-truncate">{programLead.zone}</small>
                          ) : (
                              <small className="text-muted">{programLead.market}</small>
                          )
                          }
                        </div>
                        <div className="d-flex flex-column text-end w-50">
                          <ProgressBar variant={getProgressBarVariant(programLead)} min={0} max={programLead.totalDailyCapacity} now={programLead.dailyAssignment} className="w-100" />
                          <small className="text-muted">{`${calculateCapacity(programLead)} of ${programLead.totalDailyCapacity} available`}</small>
                        </div>
                      </div>
                    </ToggleButton>
                  ))
                  }
                </ToggleButtonGroup>
              </Row>
            </Col>
          </Row>
        }
        {
          isSelectProgramStep && selectedTeamMember && (
            <Row className="mb-4">
              <Col>
                <Row>
                  <Form.Group>
                    <Form.Label id={`${componentName}-assignedTeamMemberLabel`}>
                      Assigned Team Member
                    </Form.Label>
                    <div className="d-flex justify-content-between align-items-center rounded-3 border p-3">
                      <div className="d-flex flex-column">
                        <span className="fw-bold">{selectedTeamMember.programLeadName}</span>
                        <span>{selectedTeam.teamName}</span>
                        <span>{selectedTeamMember.zone}</span>
                      </div>
                      <div>
                        <Button variant="link" className="px-3 text-decoration-none" onClick={() => reassignTeamMember()}>
                          Reassign
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          )
        }
        {isSelectProgramStep &&
          <>
            {assignPatientTo.source != 'mangeVisitReassignCareProgram' && <Row className="mb-4">
              <Col>
                <Row>
                  <Form.Group id={`${componentName}-program`}>
                    <Form.Label id={`${componentName}-programLabel`}>
                      Program<RedAsterisk/>
                    </Form.Label>
                    <select id={`${componentName}-programOptions`} className={`form-select ${isProgramAlreadyAssigned ? 'border-danger' : ''}`}
                      value={programId}
                      onChange={(event) => selectProgram(event)}
                    >
                      <option value="">Select</option>
                      {carePaths.map(program => (
                        <option value={program.value}>{program.text}</option>
                      ))}
                    </select>
                    <small className="fw-bold text-danger" hidden={!isProgramAlreadyAssigned}>{programAlreadyAssignedError}</small>
                  </Form.Group>
                </Row>
              </Col>
            </Row>}
            <Row>
              <Col>
                <Row>
                  <Form.Group>
                    <Form.Label id={`${componentName}-notesLabel`}>
                      Notes
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      max="1000"
                      onChange={e => setNote(e.target.value)}
                      id={`${componentName}-notesValue`}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <ToastMessage/>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-patientAssignPatientToNewCareProgramNoBtn`} variant="secondary"
          onClick={() => cancel()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-submit`}
          variant="primary"
          disabled={isSubmitButtonDisabled}
          aria-disabled={isSubmitButtonDisabled}
          onClick={() => submit()}>
          {submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewCareProgram;