import React, { useState, useEffect } from "react";
import { ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";
import isEmpty from "is-empty";

const componentName = "WeekFilter";

export const WorklistQuickfilter = ({ activeFilterView, setActiveFilterView, setPageNumber, resetDefault, loading }) => {



  const onFilterButtonClickHandler = ( filterType, isActive) => {
    if (isActive) {
      setActiveFilterView('UNSELECT');
      setPageNumber(1);
    }
    else {
      switch (filterType) {
        case 'toc':
          setActiveFilterView('TRANSITIONS_OF_CARE');
          setPageNumber(1);
          break;
        case 'hr':
          setActiveFilterView('REQUIRING_ACTION');
          setPageNumber(1);
          break;
        case 'pc':
          setActiveFilterView('PREVENTIVE_CARE');
          setPageNumber(1);
          break;
        default:
          break;
      }
    }
    
  };

  useEffect(() => {
    if (resetDefault) {
      onFilterButtonClickHandler(null, true);
    }
  }, [resetDefault]);

  return (
    <>
      <ToggleButtonGroup type="radio" className="careProgramFilters me-3" name="scheduleFilters">
        <Button disabled={loading} id="TRANSITIONS_OF_CARE" variant="secondary" className={`border px-3 text-dark rounded-start ${activeFilterView === 'TRANSITIONS_OF_CARE' ? 'active' : ''}`}
          onClick={(e) => { onFilterButtonClickHandler('toc', activeFilterView === 'TRANSITIONS_OF_CARE'); }} value="TRANSITIONS_OF_CARE">
          Transition of Care
        </Button>
        <Button disabled={loading} id="REQUIRING_ACTION" variant="secondary" className={`border px-3 text-dark rounded-0 ${activeFilterView === 'REQUIRING_ACTION' ? 'active' : ''}`}
          onClick={() => { onFilterButtonClickHandler('hr', activeFilterView === 'REQUIRING_ACTION'); }} value="REQUIRING_ACTION">
          High Risk
        </Button>
        <Button disabled={loading} id="PREVENTIVE_CARE" variant="secondary" className={`border px-3 text-dark rounded-start-0 rounded-end ${activeFilterView === 'PREVENTIVE_CARE' ? 'active' : ''}`}
          onClick={() => { onFilterButtonClickHandler('pc', activeFilterView === 'PREVENTIVE_CARE'); }} value="PREVENTIVE_CARE">
          Preventive Care
        </Button>
      </ToggleButtonGroup>
    </>
  );
};

export default WorklistQuickfilter;