import React, {useEffect} from "react";
import {Button, Row, Col, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import {useReferralsContext} from "../context/Referrals.context";

const componentName = "SpecialistReferralStep2";

const SpecialistReferralStep2 = () => {
    const {
        setCurrentSpecialistReferralStep,
        specialistReferralSteps,
        providers,
        selectedProvider,
        setSelectedProvider,
        initSpecialistReferralVars
    } = useReferralsContext()

    const goBack = () => {
        setSelectedProvider("");
        initSpecialistReferralVars();
        setCurrentSpecialistReferralStep(specialistReferralSteps.step1);
    }
    return (
        <>
            <Row id={`${componentName}-backRow`}
                 key={`${componentName}-backRow`}>
                <Col id={`${componentName}-backCol`}
                     key={`${componentName}-backCol`}>
                    <Button variant="link"
                            className="text-decoration-none fw-bolder"
                            id={`${componentName}-backButton`}
                            key={`${componentName}-backButton`}
                            onClick={() => goBack()}>
                        <i className="fa-solid fa-arrow-left pe-3"/>
                        Back to Search Criteria
                    </Button>
                </Col>
            </Row>
            <Row className="d-flex justify-content-between fw-bold"
                 id={`${componentName}-labelRow`}
                 key={`${componentName}-labelRow`}>
                <Col className="text-start"
                     id={`${componentName}-provider`}
                     key={`${componentName}-provider`}>
                    <span>Provider</span>
                </Col>
                <Col className="text-end"
                     id={`${componentName}-contact`}
                     key={`${componentName}-contact`}>
                    <span>Contact</span>
                </Col>
            </Row>
            <Row id={`${componentName}-providerCard`}
                 key={`${componentName}-providerCard`}>
                <ToggleButtonGroup
                    id={`${componentName}-toggleGroup`}
                    key={`${componentName}-toggleGroup`}
                    className="careTeamCapacity d-flex flex-column bg-transparent"
                    type="radio" value={selectedProvider}
                    name="specialistReferral">
                    {providers && providers.map((provider, idx) => (
                        <ToggleButton
                            id={`${componentName}-toggleGroup-${idx}`}
                            key={`${componentName}-toggleGroup-${idx}`}
                            className="border p-3 rounded-3 mb-2"
                            variant="team-member" value={provider.id}
                            onChange={(event) => setSelectedProvider(event?.target?.value)}>
                            <Row
                                id={`${componentName}-toggleButtonRow-${idx}`}
                                key={`${componentName}-toggleButtonRow-${idx}`}
                                className="text-muted">
                                <Col
                                    id={`${componentName}-toggleButtonProvider-${idx}`}
                                    key={`${componentName}-toggleButtonProvider-${idx}`}
                                    className="text-start">
                                    <Row
                                        id={`${componentName}-toggleButtonSpecialistName-${idx}`}
                                        key={`${componentName}-toggleButtonSpecialistName-${idx}`}> <span
                                        className="text-dark">{provider.specialistName}</span> </Row>
                                    <Row
                                        id={`${componentName}-toggleButtonProviderName-${idx}`}
                                        key={`${componentName}-toggleButtonProviderName-${idx}`}>
                                        <span>{provider.providerName}</span> </Row>
                                </Col>
                                <Col
                                    id={`${componentName}-toggleButtonContact-${idx}`}
                                    key={`${componentName}-toggleButtonContact-${idx}`}
                                    className="text-end">
                                    <Row
                                        id={`${componentName}-toggleButtonProviderAddress-${idx}`}
                                        key={`${componentName}-toggleButtonProviderAddress-${idx}`}>
                                        <span>{provider.address}</span> </Row>
                                    <Row
                                        id={`${componentName}-toggleButtonProviderCity-${idx}`}
                                        key={`${componentName}-toggleButtonProviderCity-${idx}`}>
                                        <span>{`${provider.city}, ${provider.state} ${provider.zipCode}`}</span></Row>
                                    <Row
                                        id={`${componentName}-toggleButtonPhoneNumber-${idx}`}
                                        key={`${componentName}-toggleButtonPhoneNumber-${idx}`}>
                                        <span>{provider.specialistPhoneNumber}</span> </Row>
                                </Col>
                            </Row>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

            </Row>
        </>

    )
}

export default SpecialistReferralStep2;