import React, { useEffect, Fragment, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "../../../utils/apiClient";
import styled from "styled-components";
import { useIdleTimer } from "react-idle-timer";
import IdleTimeoutModalForAssessment from "./IdleTimeoutModalForAssessment";
import { EQHSpinner } from "../../../components/EQHSpinner";
import PatientInformationSummary
  from "../../caremgmt/worklist/components/CMWorklistModals/modalsComponents/PatientInformationSummary";

const RedAsterisk = styled.span`
  color: red;
`;

const NewAssessmentModal = ({
                              fillAssessment,
                              saveNewTemplate,
                              fillAssessmentClose,
                              selectedTemplate,
                              individualAssessmentData,
                              mode,
                              patientId,
                              patient,
                              isSaving,
                              detailAssessmenClose,
                            }) => {
  const SESSION_TIMEOUT_SHOW_MODAL_MS = 1 * 60 * 1000; // After 1 minutes, Idle Session Modal
  const [showIdleTimeoutModal, setshowIdleTimeoutModal] = useState(false);
  const recentlySavedAssessmentId = useRef(null);

  const handleOnIdle = () => {
    setshowIdleTimeoutModal(true);
  };

  const idleTimer = useIdleTimer({
    timeout: SESSION_TIMEOUT_SHOW_MODAL_MS,
    onIdle: handleOnIdle
  });

  const handleSave = () => {
    saveNewTemplateInitial();
    setshowIdleTimeoutModal(false);
    idleTimer.reset();
  };
  const handleCancel = () => {
    setshowIdleTimeoutModal(false);
    idleTimer.reset();
  };

  const handleCloseMain = () => {
    fillAssessmentClose();
  };

  const [assessmentTemplate, setAssessmentTemplate] = useState({});
  const [answersinId, setAnswersinId] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isForcedDisable, setForcedDisable] = useState(false);

  const baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const isEmpty = require("is-empty");
  useEffect(() => {
    if (mode && mode === "new") {
      setAssessmentTemplate({});
    }
    if (selectedTemplate && selectedTemplate.templateId) {
      getAssessmentTemplate(selectedTemplate.templateId);
    }
    if (!isEmpty(individualAssessmentData.answers)) {
      setAnswersinId(individualAssessmentData.answers);
    }
    if (!isEmpty(individualAssessmentData.id)) {
      recentlySavedAssessmentId.current = individualAssessmentData.id;
    }
  }, []);

  const saveNewTemplateInitial = () => {
    setshowIdleTimeoutModal(false);
    setIsLoading(true);
    let assessId = "";
    if (!isEmpty(recentlySavedAssessmentId.current)) {
      assessId = recentlySavedAssessmentId.current;
    } else {
      assessId = null;
    }
    const finalPayload = {
      id: assessId,
      patientId: patientId,
      templateId: selectedTemplate?.templateId,
      answers: answersinId,
      status: "Draft",
      autoSave: true,
    };
    axios
      .put(baseURL + `/v1/assessments/auto-save`, finalPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data && !isEmpty(response.data) && response.data?.id) {
          recentlySavedAssessmentId.current = response.data.id;
          setshowIdleTimeoutModal(false);
          //fillAssessmentClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  const getAssessmentTemplate = (id) => {
    setIsLoading(true);
    const assessmentId = encodeURIComponent(id);
    axios
      .get(
        baseURL + `/v1/assessments/template-by-id?templateId=${assessmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data) {
          setAssessmentTemplate(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        //setErrorMessage(error);
      });
  };

  const handleCheckData = (
    value,
    answer,
    qnId,
    type,
    required,
    score
  ) => {
    let id = `${qnId}`;
    let temp = answersinId[id];
    if (value) {
      if (!Object.keys(answersinId).includes(id)) {
        setAnswersinId({
          ...answersinId,
          [id]: [{ text: answer, score: score }],
        });
      } else {
        if (type === "FREE_FORM") {
          setAnswersinId({
            ...answersinId,
            [id]: [{ text: answer, score: score }],
          });
        } else if (type === "SINGLE_ANSWER") {
          if (answer !== "") {
            setAnswersinId({
              ...answersinId,
              [id]: [{ text: answer, score: score }],
            });
          }
        } else {
          temp.push({ text: answer, score: score });
          setAnswersinId({ ...answersinId, [id]: temp });
        }
      }
    } else {
      temp = temp.filter(function (el) {
        return el.text !== answer;
      });
      setAnswersinId({ ...answersinId, [id]: temp });
    }
  };

  const fillifExists = (qnId) => {
    const ansId = qnId;
    let returnVal = "";
    if (!isEmpty(individualAssessmentData.answers)) {
      const draftedDataQuestions = Object.keys(
        individualAssessmentData.answers
      );
      if (draftedDataQuestions.includes(ansId)) {
        const answers = individualAssessmentData.answers[ansId];
        returnVal = answers[0].text;
      }
    }
    return returnVal;
  };
  const checkAnswers = (qnId, ansText, type) => {
    const ansId = qnId;
    let returnVal = false;
    if (!isEmpty(individualAssessmentData.answers)) {
      const draftedDataQuestions = Object.keys(
        individualAssessmentData.answers
      );
      if (draftedDataQuestions.includes(ansId)) {
        if (type === "radio") {
          const answers = individualAssessmentData.answers[ansId];
          const MCans = answers.filter((ans) => ans.text === ansText);
          if (MCans.length > 0) {
            returnVal = true;
          }
        } else if (type === "freeForm") {
          for (const [key, value] of Object.entries(
            individualAssessmentData.answers
          )) {
            if (key === ansId) {
              returnVal = value[0].text;
            }
          }
        } else if (type === "checkbox") {
          const answers = individualAssessmentData.answers[ansId];
          const MCans = answers.filter((ans) => ans.text === ansText);
          if (MCans.length > 0) {
            returnVal = true;
          }
        }
      }
    }
    return returnVal;
  };

  const isNotSuppressed = (questions, answers) => {
    if (
      questions.conditions &&
      questions.conditions.length &&
      questions.conditions?.[0].type === "OR"
    ) {
      //setErrorMessage("First condition should be 'AND' type");
    }
    let result = false;
    if (questions.conditions && questions.conditions.length) {
      result = true;
    }

    questions.conditions.forEach((condition) => {
      const answersLst = getAnswers(
        condition?.conditionQuestionId,
        answers
      );
      const shouldBeSuppressed = calculateRule(condition.rule, answersLst);
      if (condition.type === "AND") {
        result = result && shouldBeSuppressed;
      }

      if (condition.type === "OR") {
        result = result || shouldBeSuppressed;
      }
    });

    if (questions.applyModificator) {
      return result;
    }
    return !result;
  };

  const calculateRule = (rule, answersLst) => {
    switch (rule.type) {
      case "BLANK": {
        return isValuesEmpty(answersLst);
      }
      case "NOT_BLANK": {
        return !isValuesEmpty(answersLst);
      }
      case "VALUE": {
        return isValueExists(answersLst, rule.value);
      }
      default: {
        //setErrorMessage("Unknown condition rule");
      }
    }
  };

  const isValuesEmpty = (answersLst) => {
    if (isEmpty(answersLst)) {
      return true;
    }
    return answersLst.some((str) => isEmpty(str));
  };

  const isValueExists = (answersLst, value) => {
    let val = false;
    if (isEmpty(answersLst)) {
      return false;
    }
    if (answersLst.filter((e) => e === value).length) {
      val = true;
    }
    return val;
  };

  const saveComplete = () =>{
    saveNewTemplate(
      answersinId,
      "Completed",
      false,
      recentlySavedAssessmentId.current
    );
  }

  // answer could be composite
  const getAnswers = (fullQuestionId, answers) => {
    let arr = [];
    for (const [key, value] of Object.entries(answersinId)) {
      if (key === fullQuestionId) {
        value.forEach((t) => {
          arr.push(t.text);
        });
      }
    }
    return arr;
  };

  const prepareQuestionLayout = (qn, index) => {
    const isEnabled = isNotSuppressed(
      qn,
      answersinId
    );
    if (isEnabled) {
      if (qn.questionType === "MULTIPLE_ANSWER") {
        return (
          <>
            <Form.Label className="d-block">
              {qn.questionText}
              {qn.required ? <RedAsterisk>*</RedAsterisk> : ""}
            </Form.Label>
            {qn.answers.map((ans, ind) => (
              <>
                <input
                  type="checkbox"
                  id={index}
                  defaultChecked={checkAnswers(qn.id, ans.text, "checkbox")}
                  name={ans.text}
                  onChange={(e) => {
                    handleCheckData(
                      e.target.checked,
                      ans.text,
                      qn.id,
                      null,
                      qn.required,
                      ans.score
                    );
                  }}
                />{" "}
                &nbsp;&nbsp;
                <label htmlFor={ind + index}>{ans.text}</label>
                <br />
              </>
            ))}
            <hr />
          </>
        );
      } else if (qn.questionType === "SINGLE_ANSWER") {
        return (
          <>
            <Form.Label className="d-block">
              {qn.questionText}
              {qn.required ? <RedAsterisk>*</RedAsterisk> : ""}
            </Form.Label>
            {qn.answers.map((ans, ind) => (
              <>
                <input
                  type="radio"
                  id={ind + index}
                  name={index}
                  defaultChecked={checkAnswers(qn.id, ans.text, "radio")}
                  onChange={(e) => {
                    handleCheckData(
                      e.target.checked,
                      ans.text,
                      qn.id,
                      qn.questionType,
                      qn.required,
                      ans.score
                    );
                  }}
                />{" "}
                &nbsp;&nbsp;
                <label htmlFor={ind + index}>{ans.text}</label>
                <br />
              </>
            ))}
            <hr />
          </>
        );
      } else if (qn.questionType === "FREE_FORM")
        return (
          <>
            <Form.Label className="d-block">
              {qn.questionText}
              {qn.required ? <RedAsterisk>*</RedAsterisk> : ""}
            </Form.Label>
            <textarea
              className="form-control"
              defaultValue={fillifExists(qn.id)}
              onChange={(e) => {
                handleCheckData(
                  true,
                  e.target.value,
                  qn.id,
                  qn.questionType,
                  qn.required,
                  0
                );
              }}
            ></textarea>
            <hr />
          </>
        );
      else {
        return null;
      }
    } else {
      return null;
    }
  };

  const isCompleteButtonDisabled = () => {
    if(!isEmpty(assessmentTemplate?.questions)) {
      let questionKeys = assessmentTemplate?.questions
        .filter(question => question.required && isNotSuppressed(question, answersinId))
        .map(question => question.id);
      if(isEmpty(questionKeys)){
        return false;
      }
      if(!isEmpty(answersinId)){
        let answerKeys = Object.keys(answersinId).filter(answerKey => questionKeys.includes(answerKey));
        let allAnswered = answerKeys
          .filter(answerKey => !isEmpty(answersinId[answerKey].filter(obj => !isEmpty(obj.text))))
          .length === questionKeys.length;

        if (questionKeys.length > 0 && allAnswered) {
          return false;
        }
      }
    }

    return true;
  }

  return (
    <Fragment>
      {isLoading && <EQHSpinner />}
      <Modal
        show={fillAssessment}
        onHide={fillAssessmentClose}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="my-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p style={{ margin: 0 }}>{selectedTemplate?.templateName}</p>
            <p style={{ fontSize: '12px', fontWeight: '400' }}>
              New Assessment {isSaving && <small className="text-muted"> Saving...</small>}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>

          {patient && <PatientInformationSummary rowLayout={1}
                                                 fullName={patient.fullName}
                                                 memberId={patient.memberId}
                                                 dateOfBirth={patient.dateOfBirth}
                                                 className='mb-3 bg-light p-3 rounded-3'/>}

          {assessmentTemplate &&
          assessmentTemplate.questions &&
          assessmentTemplate.questions.length && (
            <Form>
              {assessmentTemplate.questions.map((qn, ind) => (
                <>{prepareQuestionLayout(qn, ind)}</>
              ))}
            </Form>
          )}
          {isSaving && <EQHSpinner/>}
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={() => handleCloseMain()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {setForcedDisable(true); saveComplete(); detailAssessmenClose()}}
            disabled={isForcedDisable || isCompleteButtonDisabled()}
          >
            Complete
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setForcedDisable(true);
              saveNewTemplate(
                answersinId,
                "Draft",
                false,
                recentlySavedAssessmentId.current
              );
              detailAssessmenClose();
            }}
            disabled={isForcedDisable}
          >
            Save As Draft
          </Button>
        </Modal.Footer>
      </Modal>
      <IdleTimeoutModalForAssessment
        showModal={showIdleTimeoutModal}
        handleSave={handleSave}
        handleCancel={handleCancel}
        displayPopUpAfter={SESSION_TIMEOUT_SHOW_MODAL_MS}
      />
    </Fragment>
  );
};

export default NewAssessmentModal;
