import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

const componentName = "CollapsibleCard";

const CollapsibleCard = ({ heading, title, openContent, closedContent, editTask, idx }) => {
  const [open, setOpen] = useState(true);

  return (
    <div>
      {heading ? <div id={`${componentName}-ccHeader-${idx}`} className="fw-bold">{heading}</div> : null}
      <div className="d-flex">
        <Button id={`${componentName}-ccTitle-${idx}`} onClick={() => setOpen(!open)} variant="link" className="bg-transparent text-start text-secondary fw-bold mb-0 px-0 border-0 w-100 text-decoration-none fs-5">
          {open ? (
            <i id={`${componentName}-ccMinus-${idx}`} className="fa-solid fa-chevron-down fa-xs me-4"></i>
          ) : (
            <i id={`${componentName}-ccPlus-${idx}`} className="fa-solid fa-chevron-right fa-xs me-4"></i>
          )}
          {title}
        </Button>
        {editTask != null && (
          <Button id={`${componentName}-ccEditReferral-${idx}`} variant="secondary" className="text-end" onClick={editTask}>Edit Referral</Button>
        )}
      </div>
      <Collapse in={open}>
        <div id={`${componentName}-ccOpenInfo-${idx}`} className="mt-3">{openContent}</div>
      </Collapse>
      <Collapse in={!open}>
        <div id={`${componentName}-ccClosedInfo-${idx}`}>{closedContent}</div>
      </Collapse>
    </div>
  );
};

export { CollapsibleCard };
