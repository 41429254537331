import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";
import {PRINT_URL, SUMMARY_URL} from "../views/quickList/QuickList";
import {PREVENTIVE_CARE_COUNT_URL} from "../views/patientProfile/Overview";
import {AppPropertiesUtil} from "../utils/localStorageUtil";



const [
  getPatientProfileOverview,
  useGetPatientProfileOverview,
] = dataFetchHelper(([patientId]) => {
  const featureFlagPatientProfile=AppPropertiesUtil.get("ms.patient-profile.enabled");
  if(featureFlagPatientProfile)
  return axios.get(
      process.env.REACT_APP_REST_API_MS_SERVER +
      `/v2/patient-profile-service/findById?patientId=${patientId}`,
    {
      headers: {},
    }
  )
  else
    return axios.get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patients/overview?patientId=${patientId}`,
        {
          headers: {},
        }
    );
});

export const getPatientOverview = (patientId) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
    `/v1/patients/overview?patientId=${patientId}`,
    {
      headers: {},
    }
  );
}

const [
  getPatientProfileOverviewTabHeaderCount,
  useGetPatientProfileOverviewTabHeaderCount,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patients/overview/tab-header-count?patientId=${patientId}`,
      {
        headers: {},
      }
  );
});

const updatePatientProfileOverview = (paitentProfileOverview) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patients/update?id=${paitentProfileOverview.id}`,
    paitentProfileOverview,
    {
      headers: {},
    }
  );
};
const patientInformationSection = (patientId) => {
  return  axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-quick-view/care-paths?patientId=${patientId}`,
      {
        headers: {},
      }
  );
};

const [
  getPatientProfileRecentActivity,
  useGetPatientProfileRecentActivity,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patients/recent-activity?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [
  getImportantNotes,
  useGetImportantNotes,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-profile/important-notes?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [
  getPatientProfileCareOpps,
  useGetPatientProfileCareOpps,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/care-gaps/patient-profile?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [getPatientProfileSuspects, useGetPatientProfileSuspects] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/rafs/patient-profile?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const [getPatientCard, useGetPatientCard] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patients/card-summary?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const [getPatientProfileADTs, useGetPatientProfileADTs] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patients/adts?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const [
  getPatientProfileAppointments,
  useGetPatientProfileAppointments,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-profile/appointments?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [
  getPatientProfileReferrals,
  useGetPatientProfileReferrals,
] = dataFetchHelper(([patientId]) => {

  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-profile/referrals?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [getPatientProfileNotes, useGetPatientProfileNotes] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patients/notes?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const [getPatientProfileNotesCount, useGetPatientProfileNotesCount] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patients/notes-count?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const [
  getCareProviderPartialNameSearch,
  useGetCareProviderPartialNameSearch,
] = dataFetchHelper(([patientId, name]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/care-providers/partial-name?patientId=${patientId}&name=${name}`,
    {
      headers: {},
    }
  );
});

const [getCareManagements, useGetCareManagements] = dataFetchHelper(
  ([patientId]) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patient-profile/care-paths?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }
);

const saveCareManagement = (careManagement) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-care-management/save-care-managements`,
    careManagement,
    {
      headers: {},
    }
  );
};

const [
  getCareManagementAcceptanceTypes,
  useGetCareManagementAcceptanceTypes,
] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/constants/careManagementAcceptanceTypes`,
    {
      headers: {},
    }
  );
});

const [
  getCareManagementStatuses,
  useGetCareManagementStatuses,
] = dataFetchHelper(([newEnrollment]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/constants/careManagementStatuses?newEnrollment=${newEnrollment}`,
    {
      headers: {},
    }
  );
});

const [
  getAllUsersForHeadOrganization,
  useGetAllUsersForHeadOrganization,
] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/user-profile/organization/tinNumber`,
    {
      headers: {},
    }
  );
});

const [
  getCareManagementPrograms,
  useGetCareManagementPrograms,
] = dataFetchHelper(([patientId, newEnrollment]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-care-management/care-managements-programs?patientId=${patientId}&newEnrollment=${newEnrollment}`,
    {
      headers: {},
    }
  );
});

const [
  getCareManagementOverview,
  useGetCareManagementOverview,
] = dataFetchHelper(([patientId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/patient-care-management/care-management-overview?patientId=${patientId}`,
    {
      headers: {},
    }
  );
});

const [
  getCareGap,
  useGetCareGap,
] = dataFetchHelper(([careGapId]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/manage-gaps/single?id=${careGapId}`,
    {
      headers: {},
    }
  );
});

const [
  getCareGaps,
  useGetCareGaps,
] = dataFetchHelper(([careGapIds]) => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/manage-gaps/multiple?id=${careGapIds}`,
    {
      headers: {},
    }
  );
});

const updateSingleCareGap = (careGap) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/manage-gaps/single`,
      careGap,
    {
      headers: {},
    }
  );
};

const updateMultipleCareGap = (careGaps) => {
  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/manage-gaps/multiple`,
      careGaps,
    {
      headers: {},
    }
  );
};

const loadPreventiveCareHistoryData = (patientId, setAllOpportunitiesFOrHist, setAllSuspectsForHist, baseURLPC) => {
  axios
    .post(baseURLPC + `/find?patientId=${patientId}`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      setAllOpportunitiesFOrHist(response.data.careGapsResponse?.careGapsHistory || []);
      setAllSuspectsForHist(response.data.conditionsResponse?.conditionsHistory || []);
    })
    .catch((error) => {
    });
};

const printOverviewAction = (criteria, thenTask, catchTask) => {
  axios
    .post(PRINT_URL, criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
      catchTask(error);
    });
}

const exportSummaryOverviewAction = (thenTask, catchTask) => {
  axios
    .get(SUMMARY_URL, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
      catchTask(error);
    });
}

const preventiveCareCountAction = (patientId, thenTask) => {
  axios.get(PREVENTIVE_CARE_COUNT_URL + "?patientId=" + patientId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const getLatestReferralAction = (patientId, thenTask) => {
  axios.get(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/latestReferral" + "?patientId=" + patientId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const getLatestAdtAction = (patientId, thenTask) => {
  axios.get(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/latest-adt" + "?patientId=" + patientId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const getPatientProfileOverviewCards = (patientId, thenTask) => {
  axios.get(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/overview-cards" + "?patientId=" + patientId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const getExportPatientAssessments = (patientId, answerSetId, thenTask) => {
  axios.get(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/assessment-report" + "?patientId=" + patientId + "&answerSetId=" + answerSetId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const postReportExtract = (patientId, thenTask) => {
  axios.post(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/report-extract" + "?patientId=" + patientId, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
    });
}

const findHistory = (patientId, body, thenTask, catchTask) => {
  axios.post(process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-profile/history" + "?patientId=" + patientId, body, {
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then((response) => {
      thenTask(response);
    })
    .catch((error) => {
      catchTask(error);
    });
}

const getRecommendedTeams = (thenTask) => {
  axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/care-management/recommended-teams`, {
    headers: {
      "Content-Type": "application/json"
    },
  })
      .then((response) => {
        thenTask(response);
      })
      .catch((error) => {
      });
}

export {
  findHistory,
  postReportExtract,
  getExportPatientAssessments,
  getPatientProfileOverviewCards,
  getLatestAdtAction,
  getLatestReferralAction,
  preventiveCareCountAction,
  printOverviewAction,
  exportSummaryOverviewAction,
  getPatientProfileOverview,
  useGetPatientProfileOverview,
  getPatientProfileOverviewTabHeaderCount,
  useGetPatientProfileOverviewTabHeaderCount,
  getPatientProfileRecentActivity,
  useGetPatientProfileRecentActivity,
  getImportantNotes,
  useGetImportantNotes,
  getPatientProfileCareOpps,
  useGetPatientProfileCareOpps,
  getPatientProfileSuspects,
  useGetPatientProfileSuspects,
  getPatientCard,
  useGetPatientCard,
  getPatientProfileADTs,
  useGetPatientProfileADTs,
  getPatientProfileAppointments,
  useGetPatientProfileAppointments,
  getPatientProfileReferrals,
  useGetPatientProfileReferrals,
  getPatientProfileNotes,
  useGetPatientProfileNotes,
  getCareProviderPartialNameSearch,
  useGetCareProviderPartialNameSearch,
  updatePatientProfileOverview,
  patientInformationSection,
  getCareManagements,
  useGetCareManagements,
  saveCareManagement,
  getCareManagementAcceptanceTypes,
  useGetCareManagementAcceptanceTypes,
  getCareManagementStatuses,
  useGetCareManagementStatuses,
  getAllUsersForHeadOrganization,
  useGetAllUsersForHeadOrganization,
  getCareManagementPrograms,
  useGetCareManagementPrograms,
  getCareManagementOverview,
  useGetCareManagementOverview,
  getCareGap,
  useGetCareGap,
  getCareGaps,
  useGetCareGaps,
  getPatientProfileNotesCount, 
  useGetPatientProfileNotesCount,
  updateSingleCareGap,
  updateMultipleCareGap,
  loadPreventiveCareHistoryData,
  getRecommendedTeams
};
