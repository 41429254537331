import React from "react";

import Button from "react-bootstrap/Button";
import styled from "styled-components";

const DropDownButton = styled(Button)`
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => (props.disabled ? "#E8E8E8" : "white")} !important;
  width: ${(props) => (props.cusWidth ? props.cusWidth : "auto")};
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  border-radius: 6px;
  margin: 0px 10px 10px 0px;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  ${(props) =>
    props.cssOpen
      ? "box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5) !important;"
      : ""}
  ${(props) =>
    props.activeClosedFilter
      ? "box-shadow: 0 0 0 0.2rem #ccffcc !important;"
      : ""}
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    background-color: ${(props) => (props.disabled ? "#E8E8E8" : "white")} !important;
    color: #495057 !important;
    border: 1px solid #ced4da !important;
  }
  :focus {
    background-color: ${(props) => (props.disabled ? "#E8E8E8" : "white")} !important;
    color: #495057 !important;
    border: 1px solid #ced4da !important;
  }

  text-align: left !important;

  ::after {
    display: none;
  }

  span {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .fa-chevron-down {
    font-size: 10px;
    padding-top: 5px;
    float: right;
  }
`;

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    );
  }
);

const CustomMenuStyled = styled(CustomMenu)`
  ${(props) => (props.custHeight ? `height: ${props.custHeight}` : "")};
  padding-top: 0px;
`;

const paramValue = (filterParam) => {
  return filterParam && filterParam.paramValue ? filterParam.paramValue : [];
};

const comparison = (filterParam) => {
  return filterParam && filterParam.comparison ? filterParam.comparison : "eq";
};

export { DropDownButton, CustomMenuStyled, paramValue, comparison };
