import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap"
import { convertToMMddYYYY, formatDate } from '../../../../../../../utils/util';

const icon_mapper = {
  IP_DISCHARGE: { name: "Hospital: IP", component: <i className="fa-regular fa-circle text-danger"></i> },
  BH_IP_DISCHARGE: { name: "Hospital: BH-IP", component: <i className="fa-solid fa-circle text-danger"></i> },
  ER_DISCHARGE: { name: "Emergency Room", component: <i className="fa-solid fa-square text-warning"></i> }
}

const AcuteCare = ({ data, dataForTimeLine }) => {
  const allUtilFunc = useCallback((month, data, type) => {
    let items = [];
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
    let dt = new Date();
    dt = new Date(dt.setMonth(dt?.getMonth() + month))
    if (type === 'getMonth') {
      return formatter.format(dt);
    }
    else if (type === 'createCol') {
      const finMonth = dt.getMonth();
      data.forEach(m => {
        if (m.dischargeDate && new Date(m.dischargeDate).getMonth() === finMonth) {
          items.push(icon_mapper[m.eventType].component)
        }
      });
      return items;
    }
    else {

      return ''
    }

  }

    , [data])

  const getMonthData = useCallback((eventTypes) => {
    let items = [];
    eventTypes.forEach(m => {
      items.push(icon_mapper[m].component)
    });
    return items;
  }

    , [dataForTimeLine])

  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 1);

  return (
    <div className={data.length ? '' : 'd-none'}>
      <Row className="mb-3">
        <Col>
          <div className="d-flex justify-content-between">
            <h5 className="fs-5 fw-bold mb-0">12 Month Care Timeline</h5>
            <div className="align-self-center">
              {Object.values(icon_mapper).map((v) => (
                <span className="ms-3">{v.component} <span className="ms-2">{v.name}</span></span>
              ))}

            </div>
          </div>
        </Col>
      </Row>
      <div className="my-5 mx-auto w-75 cpt-timeline-chart">
        <Row>
          <Col>
            <Row className="text-center flex-nowrap">
              {dataForTimeLine && dataForTimeLine.length > 0 &&
                [...Array(dataForTimeLine.length).keys()].map((e) => (
                  <Col sm={1} className="border-bottom">
                    {dataForTimeLine[e].month}
                  </Col>
                ))}
            </Row>
            <Row className="text-center align-items-start flex-nowrap">
              {dataForTimeLine && dataForTimeLine.length > 0 &&
                [...Array(dataForTimeLine.length).keys()].map((e) => (
                  <Col sm={1}>
                    {getMonthData(dataForTimeLine[e].eventTypes)}
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="d-none">
        <Col>
          <div className="d-flex flex-nowrap justify-content-around mb-3 cpt-timeline">
            {data && data
              .filter(e => {
                const dischargeDate = new Date(e?.dischargeDate);
                return (e?.eventType === 'IP_DISCHARGE' || e?.eventType === 'ER_DISCHARGE') && dischargeDate >= currentDate;
              })
              .slice(-8)
              .map((e, idx) =>
                e?.eventType === 'IP_DISCHARGE' ? (
                  <div id={`IP_DISCHARGE-${idx}`} className="d-flex flex-column align-items-center cpt-tlitem">
                    <span className="badge lh-sm bg-danger rounded-circle fs-6">H</span>
                    <small>{formatDate(e?.dischargeDate)}</small>
                  </div>
                ) : e?.eventType === 'ER_DISCHARGE' ? (
                  <div id={`ER_DISCHARGE-${idx}`} className="d-flex flex-column align-items-center cpt-tlitem">
                    <span className="badge lh-sm bg-warning rounded-circle fs-6">E</span>
                    <small>{formatDate(e?.dischargeDate)}</small>
                  </div>
                ) : (null)
              )
            }
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5 className="fs-5 fw-bold mb-0">Timeline Event Primary Diagnosis</h5>
        </Col>
      </Row>
      <div className="my-4 mx-auto w-75">
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-start">
              {data && data.length ? data.map((n) => (
                <div>{n.eventType ? icon_mapper[n.eventType]?.component : ''} <span className="ms-2">{convertToMMddYYYY(n.dischargeDate)} {n.diagnosisDescription ? n.diagnosisDescription : ''} {n.diagnosisCode ? '(' + n.diagnosisCode + ')' : ''}</span></div>
              )) : ''}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AcuteCare;