import React from 'react'
import { TestScreen } from '../screens';
import MyWorkspaceScreen from '../screens/MyWorkspaceScreen';

export const SubmitReferralContent = ({ screen, setShowSubmitReferral, showSubmitReferral }) => {

  const SelectedScreen = ({ screen }) => {
    switch (screen) {
      case 'MyWorkSpace':
        return <MyWorkspaceScreen setShowSubmitReferral={setShowSubmitReferral} showSubmitReferral={showSubmitReferral}/>;
      default:
        break;
    }
  }

  return (
    <SelectedScreen screen={screen} />
  )
}
