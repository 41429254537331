import React from 'react'
import {
  AssignPatientToNewCareProgram,
  RemovePatientFromWorklist,
  PatientAlreadyRemovedWarning,
  ExportSummary,
  SaveAsCustomWorklist,
  DeleteCustomWorklist,
  ManageReferral,
  NewAssessmentTemplateSelector,
  NewAssessmentFlow,
  ReassignCareStepModal,
  ViewAs,
  MarkAsOutreached,
  WorkOnCareStep,
  SnoozedModal
} from './modalsComponents'

const Modals = () => {
  return (
    <>
      <SnoozedModal />
      <WorkOnCareStep />
      <AssignPatientToNewCareProgram />
      <RemovePatientFromWorklist />
      <PatientAlreadyRemovedWarning />
      <ExportSummary />
      <SaveAsCustomWorklist />
      <DeleteCustomWorklist />
      <ManageReferral />
      <NewAssessmentTemplateSelector />
      <NewAssessmentFlow />
      <ReassignCareStepModal />
      <ViewAs />
      <MarkAsOutreached />
    </>
  )
}

export default Modals