import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import EQHErrorToast from "../../../../../../components/EQHErrorToast";
import {isEmpty, trim} from 'lodash'
import {useAppointmentsContext} from "../../context/Appointments.context";
import { RedAsterisk } from '../../../../../../utils/util';

const componentName = 'CancelAppointment';

function CancelAppointmentModal({
  appointment
}) {

  const {
    cancelAppointment,
    showCancelAppointment,
    toggleShowCancelAppointment,
    isCancelingAppointment,
    toggleShowAddAppointment
  } = useAppointmentsContext();

  const [note, setNote] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!showCancelAppointment) {
      resetState()
    }
  }, [showCancelAppointment])

  const areRequiredFieldsValid = () => {
    return !isEmpty(trim(note));
  }

  const submitAction = async () => {
    if (areRequiredFieldsValid()) {
      const requestBody = {
        id: appointment?.appointmentId,
        note: note,
      }
      await cancelAppointment(appointment?.patientId, requestBody);
    } else {
      setErrors(["Notes field is required"]);
    }
  }

  const resetState = () => {
    setNote('');
    setErrors([]);
  }

  const closeAction = async () => {
    resetState()
    toggleShowCancelAppointment(false);
    toggleShowAddAppointment(true)

  }

  return (
    <Modal
      id={`${componentName}-patientCancelAppointmentConfirmModal`}
      size="md"
      show={showCancelAppointment}
      onHide={() => closeAction()}
      centered
    >
      <Modal.Header>
        <Modal.Title
          id={`${componentName}-patientCancelAppointmentConfirmTitle`}>Cancel
          Appointment</Modal.Title>
        <button
          id={`${componentName}-patientCancelAppointmentConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeAction()}
        />
      </Modal.Header>
      <Modal.Body>
        <EQHErrorToast errors={errors} handleClose={() => setErrors([])}
                       isList={true}/>
        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Group>
                <Form.Label id={`${componentName}-appointmentLabel`}
                            className='mb-2'>
                  Appointment Name
                </Form.Label>
                <div id={`${componentName}-appointmentLabel`}>
                  {appointment?.name}
                </div>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group>
                <Form.Label id={`${componentName}-notesLabel`}>
                  Notes<RedAsterisk/>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  max="500"
                  onChange={e => setNote(e.target.value)}
                  id={`${componentName}-notesValue`}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-patientCancelAppointmentNoBtn`}
                disabled={isCancelingAppointment}
                variant="secondary" onClick={() => closeAction()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-patientCancelAppointmentYesBtn`}
          variant="primary"
          disabled={!areRequiredFieldsValid() || isCancelingAppointment}
          onClick={() => submitAction()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CancelAppointmentModal;