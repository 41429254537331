export const initialState = {
  hospitalEventsList: [],
  hospitalEventsRequest: {},
  addHospitalEventRequest: {},
  isEditHospitalEventModalActive: false,
  hospitalEventToEdit: {},
  editHospitalEventRequest: {},
  totalSize: 0,
};

export const actions = {
  SET_HOSPITAL_EVENTS_LIST: "SET_HOSPITAL_EVENTS_LIST",
  SET_HOSPITAL_EVENTS_REQUEST: "SET_HOSPITAL_EVENTS_REQUEST",
  SET_ADD_HOSPITAL_EVENT_REQUEST: "SET_ADD_HOSPITAL_EVENT_REQUEST",
  TOGGLE_EDIT_HOSPITAL_EVENT_MODAL_ACTIVE: "TOGGLE_EDIT_HOSPITAL_EVENT_MODAL_ACTIVE",
  SET_HOSPITAL_EVENT_TO_EDIT: "SET_HOSPITAL_EVENT_TO_EDIT",
  SET_EDIT_HOSPITAL_EVENT_REQUEST: "SET_EDIT_HOSPITAL_EVENT_REQUEST",
  SET_TOTAL_SIZE: 'SET_TOTAL_SIZE'
};
