import {actions} from "./actions";
import {
  setHidePatientDetailsModal,
  setPcpId
} from "../../../../../../actions/manageVisitActions";

export function getToggles(dispatch, globalDispatch) {
  return {
    setPatientProfileDetails: payload => {
      dispatch({type: actions.SET_PATIENT_DETAILS, payload: payload})
      globalDispatch(setPcpId(payload?.pcp));
    },
    toggleEditPatientDetailsEnabled: payload => {
      globalDispatch(setHidePatientDetailsModal(payload));
      dispatch({type: actions.TOGGLE_EDIT_PATIENT_DETAILS, payload: payload});
    },
    toggleAddInternalNoteEnabled: payload => {
      globalDispatch(setHidePatientDetailsModal(payload));
      dispatch({type: actions.TOGGLE_ADD_INTERNAL_NOTE_ENABLED, payload: payload});
    },
    setLanguages: payload => dispatch({type: actions.SET_LANGUAGES, payload: payload}),
    setSpokenLanguages: payload => dispatch({type: actions.SET_SPOKEN_LANGUAGES, payload: payload}),
    setReadingLanguages: payload => dispatch({type: actions.SET_READING_LANGUAGES, payload: payload}),
    setStates: payload => dispatch({type: actions.SET_STATES, payload: payload}),
    setPayorStatus: payload => dispatch({type: actions.SET_PAYOR_STATUS, payload: payload}),
    setSpecialistTypes: payload => dispatch({type: actions.SET_SPECIALIST_TYPES, payload: payload}),
    setPronounsDropList: payload => dispatch({type: actions.SET_PRONOUNS_DROP_LIST, payload: payload}),
    setSexAssignedBirthDropList: payload => dispatch({type: actions.SET_SEX_ASSIGNED_DROP_LIST, payload: payload}),
    setRaceEthnicityDropList: payload => dispatch({type: actions.SET_RACE_ETHNICITY_DROP_LIST, payload: payload}),
    setSexualOrientationList: payload => dispatch({type: actions.SET_SEXUAL_ORIENTATION_LIST, payload: payload}),
    setGenderIdentityList: payload => dispatch({type: actions.SET_GENDER_IDENTITY_LIST, payload: payload}),
  }
}