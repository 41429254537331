import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  DropDownButton,
  CustomMenuStyled,
  paramValue,
} from "./sharedDDL";

import { EQHDatePicker } from "../datePicker";
import _ from "lodash";
import { FilterParam } from "../../dtos/PatientSearchDTO";
import { Filter } from "../filters/SideFilters/components";

const componentName = "ScheduleApptDDL";
const SCHEDULE_DDL_TYPE = "ScheduleApptDDL";

const yyyyMMddToMMddyyyy = (strDate) => {
  return strDate
    ? `${strDate.substr(5, 2)}/${strDate.substr(8, 2)}/${strDate.substr(
      0,
      4
    )}`
    : "";
};

const MMddyyyyToyyyyMMdd = (strDate) => {
  return strDate
    ? `${strDate.substr(6, 4)}-${strDate.substr(0, 2)}-${strDate.substr(
      3,
      2
    )}`
    : "";
};

const DEFAULT_TEXT = '-Select-'

const ScheduleApptDropDownList = ({
  header,
  selected,
  errors,
  showRequired,
  handleChange,
}) => {
  const idFragment = header.replaceAll(" ", "_").toUpperCase();
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState(() => {
    if (selected?.paramValue?.length > 0) {
      const startDate = selected?.paramValue.filter(p => p.includes('startDate'));
      const endDate = selected?.paramValue.filter(p => p.includes('endDate'));
      if (startDate.length > 0 && endDate.length > 0) {
        return {
          startDate: startDate[0].replace('startDate:', '').replaceAll('-', '/'),
          endDate: endDate[0].replace('endDate:', '').replaceAll('-', '/'),
        };
      }
    }
    return { startDate: '', endDate: '' };
  });

  const [radioChecked, setRadioChecked] = useState({})
  const [options, setOptions] = useState({name:'', checked:false})
  useEffect(() => {
    if (!selected) {
      setDates({
        startDate: '',
        endDate: ''
      });
    }
  }, [selected])

 

  useEffect(() => {
    handleChange(onChange())
  }, [dates])

  const handleToggle = (ddlOpen) => {
    setIsOpen(ddlOpen);
  };

  const onChange = () => {
    let value = []
    dates.startDate !== '' && value.push(`startDate:${dates.startDate}`)
    dates.endDate !== '' && value.push(`endDate:${dates.endDate}`)

    let filterParam = new FilterParam(
      header,
      'range',
      value.map(v => ({ value: v, text: v })),
      (x) => x.value,
      (x) => x.text
    );
    return filterParam;
  }

  const getDisplayText = () => {
    let res = '';
    if (
      dates.startDate === '' &&
      dates.endDate === ''
    ) {
      res += DEFAULT_TEXT
    } else {
      let isDateSelected = dates.startDate !== '' || dates.endDate !== '';
      res = `${dates.startDate} ${isDateSelected ? '-' : ''} ${dates.endDate}`
    }
    return res
  }

  const onClearAll = () => {

    setDates({
      startDate: '',
      endDate: ''
    });
    setRadioChecked({name:'', check:'false'})
  }

  const HeaderComponent = () => {
    return (
      <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
        <span id={`${componentName}-${idFragment}-filterTitleText}`}>{header}</span>
        {showRequired && <span className="text-danger">*</span>}
        {getDisplayText() !== DEFAULT_TEXT &&
          <>
            &nbsp;
            <i id={`${componentName}-${idFragment}-filterReset`} onClick={() => onClearAll()} className="fas fa-times-circle cursor-p"></i>
          </>
        }
      </label>
    )
  }

  const calculateDateRange =(e, type)=>{
    let value = e.currentTarget.value;
    if(value==="true" || value){
      if(type==='last30Days'){
        let todayDate1 =new Date().toLocaleDateString('es-pa')
        const last30Days = (new Date()).setDate((new Date()).getDate()- 30);
        setDates({startDate:new Date(last30Days).toLocaleDateString('es-pa'), endDate:todayDate1})
        setRadioChecked({name:'last30Days', check:true})
      }
      if(type==='next30Days'){
        let todayDate =new Date().toLocaleDateString('es-pa')
        const next30Days =  (new Date()).setDate((new Date()).getDate()+ 30)
        setDates({startDate:todayDate, endDate: new Date(next30Days).toLocaleDateString('es-pa')})
        setRadioChecked({name:'next30Days', check:true})
      }
     
    }
    else{
      setRadioChecked({name:"", check:false})
    }
  }

  return (
    <Filter
      onToggle={handleToggle}
      index={`${componentName}-${idFragment}-filterDropdown`}
      header={<HeaderComponent />}
    >

      <Row className="mb-3 align-items-end">

        <Col>

          <label className="d-block">From</label>

          <EQHDatePicker
            id={`${componentName}-${idFragment}-datePicker`}
            onChange={(e) => setDates({ ...dates, startDate: yyyyMMddToMMddyyyy(e.strDate) })}
            value={MMddyyyyToyyyyMMdd(dates.startDate)}
            max={MMddyyyyToyyyyMMdd(dates?.endDate)}
          />
        </Col>

        <Col>
          <label className="d-block">To</label>
          <EQHDatePicker
            min={MMddyyyyToyyyyMMdd(dates?.startDate)}
            id={`${componentName}-${idFragment}-datePicker2`}
            onChange={(e) => setDates({ ...dates, endDate: yyyyMMddToMMddyyyy(e.strDate) })}
            value={MMddyyyyToyyyyMMdd(dates.endDate)}
          />

        </Col>

      </Row>

      <Row>

        <Col>

          <Form>

            <div className="mb-3">

              <Form.Check
                type="radio"
                name="group1"
                onChange={e => calculateDateRange(e,"last30Days")}
                label="Last 30 Days"
                checked={radioChecked.check && radioChecked.name==='last30Days'}
              />
              <Form.Check
                type="radio"
                name="group1"
                onChange={e => calculateDateRange(e,"next30Days")}
                label="Next 30 Days"
                checked={radioChecked.check && radioChecked.name==='next30Days'}
              />
            </div>
          </Form>
        </Col>
      </Row>

      {errors.length > 0 &&
        <span style={{ fontSize: '10px' }} className="text-danger fw-bold">
          {errors[0]}
        </span>
      }
      <div className="mt-4">
        <Button
          id={`${componentName}-${idFragment}-filterClearAll`}
          variant="link"
          onClick={() => onClearAll()}
          className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none"
        >
          Clear All
        </Button>
      </div>
    </Filter>
  );
};

export { ScheduleApptDropDownList, SCHEDULE_DDL_TYPE };
