import { isArray } from "lodash";
import { actions } from "./actions";
import { areCompositeKeysEquals } from "../../../CompositeKeyHelper";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SELECT_CARE_PATHS:
      return {
        ...state,
        carePaths: action.payload,
      };
    case actions.SELECT_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case actions.SET_SELECTED_PATIENTS:
      let newPatients = [];
      if (isArray(action.payload)) {
        newPatients = [...new Set(action.payload)]
      } else {
        newPatients = state.selectedPatientsEvents.findIndex((existingCompositeKey => areCompositeKeysEquals(existingCompositeKey, action.payload))) >= 0
          ? [...state.selectedPatientsEvents].filter(e => !areCompositeKeysEquals(e, action.payload))
          : [...state.selectedPatientsEvents, action.payload];
      }

      return {
        ...state,
        selectedPatientsEvents: newPatients
      };
    case actions.SET_PATIENTS_LIST:
      return {
        ...state,
        patientsList: action.payload
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload
      };
    case actions.SET_PATIENT_SEARCH:
      let patientSearch = action.payload;

      const stepState = patientSearch?.filterParams?.filter(f => f.paramName === 'stepState')?.[0];
      if (!stepState) {
        const oldFilters = patientSearch?.filterParams ? [...patientSearch.filterParams] : [];
        patientSearch['filterParams'] = [...oldFilters, {
          "paramName": "stepState",
          "paramValue": ["ACTIVE"],
          "comparison": "eq",
          "displayValue": ["ACTIVE"]
        }];
      }

      return {
        ...state,
        patientSearch: patientSearch
      };
    case actions.TOGGLE_ASSIGN_PATIENT_TO:
      return {
        ...state,
        assignPatientTo: action.payload
      };
    case actions.TOGGLE_UPDATE_REFERRAL_STATUS:
      return {
        ...state,
        updateReferralStatus: action.payload
      };
    case actions.TOGGLE_REMOVE_FROM_WORKLIST:
      return {
        ...state,
        removeFromWorklist: action.payload
      };
    case actions.DECLINE_REASONS:
      return {
        ...state,
        declineReasons: action.payload
      };
    case actions.PATIENT_HAS_ALREADY_BEEN_REMOVED:
      return {
        ...state,
        patientAlreadyRemoved: action.payload
      };
    case actions.SET_EXPORT_SUMMARY_DATA:
      return {
        ...state,
        exportSummaryData: action.payload
      };
    case actions.TOGGLE_SAVE_AS_CUSTOM_WORKLIST:
      return {
        ...state,
        isSaveAsCustomWorklistEnabled: action.payload
      };
    case actions.CUSTOM_WORKLIST_ERROR:
      return {
        ...state,
        customWorklistError: action.payload
      };
    case actions.TOGGLE_DELETE_CUSTOM_WORKLIST:
      return {
        ...state,
        isDeleteCustomWorklistEnabled: action.payload
      };
    case actions.SET_CUSTOM_WORK_LIST:
      return {
        ...state,
        customWorklist: action.payload
      };
    case actions.GET_TEAMS_CAPACITY:
      return {
        ...state,
        teamsCapacity: action.payload
      };
    case actions.GET_CARE_MANAGEMENT_TEAMS:
      return {
        ...state,
        careManagementTeams: action.payload
      };
    case actions.ASSIGN_PATIENT:
      return {
        ...state,
        isAssignedPatient: action.payload,
      };
    case actions.TOGGLE_NEW_ASSESSMENT_TEMPLATE_SELECTOR:
      return {
        ...state,
        isNewAssessmentTemplateSelectorEnabled: action.payload,
      };
    case actions.SET_ASSIGN_PAYLOAD:
      return {
        ...state,
        assignPayload: action.payload
      };
    case actions.SET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case actions.TOGGLE_REASSIGN_CARE_STEP:
      return {
        ...state,
        reassignCareStep: action.payload
      };
    case actions.TOGGLE_WORK_ON_CARE_STEP:
      return {
        ...state,
        workOnCareStep: action.payload
      };
    case actions.SET_PATIENT_TEMPLATES:
      return {
        ...state,
        patientTemplates: action.payload,
      };
    case actions.TOGGLE_NEW_ASSESSMENT_FLOW:
      return {
        ...state,
        isNewAssessmentFlowEnabled: action.payload,
      };
    case actions.SET_SELECTED_PATIENT_TEMPLATE:
      return {
        ...state,
        selectedPatientTemplate: action.payload,
      };
    case actions.TOGGLE_IS_SAVING_ASSESSMENT:
      return {
        ...state,
        isSavingAssessment: action.payload,
      };
    case actions.TOGGLE_IS_BULK:
      return {
        ...state,
        isBulk: action.payload,
      };
    case actions.SET_WORKLIST_DEFAULT_VIEW:
      return {
        ...state,
        worklistDefaultView: action.payload,
      };
    case actions.SET_CUSTOM_FILTERS:
      return {
        ...state,
        customFilters: action.payload
      }
    case actions.TOGGLE_VIEW_AS:
      return {
        ...state,
        isViewAsEnabled: action.payload,
      }
    case actions.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case actions.SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload
      };
    case actions.TOGGLE_MARK_AS_OUTREACHED:
      return {
        ...state,
        isMarkAsOutreachedEnabled: action.payload
      }
    case actions.PATIENT_HAS_ALREADY_BEEN_OUTREACHED:
      return {
        ...state,
        patientAlreadyOutreached: action.payload
      };
    case actions.SET_PATIENTS_EVENTS_TO_BE_MARKED_AS_OUTREACHED:
      return {
        ...state,
        patientsEventsToBeMarkedAsOutreached: action.payload
      };
    case actions.SET_ACTIVE_ROLE:
      return {
        ...state,
        activeRole: action.payload
      };
    case actions.SET_ADMIN_ROLES_HUB_WL:
      return {
        ...state,
        adminRolesHubWL: action.payload
      }
    default:
      return state;
  }
};