import React, {useEffect, useState} from "react";
import axios from "../utils/apiClient";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import ErrorToast from "../views/common/ErrorToast";

const componentName = "FileUpload";

const isEmpty = require("is-empty");

const StyledUpload = styled.div`
  label {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1.0")};
`;

const StyledLabel = styled.label`
  ::after {
    content: "x";
  }
`;

const FileUpload = ({
  disabled,
  accept,
  multiple = true,
  topComponent = "",
  onChange,
  saveToBackend = true,
  clearStateProp,
  //cancelUploads
  btnClass,
  showIcon = true,
  label = "Upload File(s)",
  uploadedFiles = [],
  showFileList = true
}) => {
  const [fileList, setFileList] = useState([]);
  const [clearState, setClearState] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [errors, setErrors] = useState(null);
  const [inputKey, setInputKey] = useState("inputKey");

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const files = uploadedFiles.map(file => file.fileMetadata)
      setFileList(files)
    }
  }, [uploadedFiles])

  const checkForDuplicateFiles = (fileList, newFileList) => {
    let fileNamesExisting=[];
    let fileNamesNew;
    fileList.forEach((file) => fileNamesExisting.push(file?.fileName));
    fileNamesNew = newFileList[0]?.fileName;
    return fileNamesExisting.includes(fileNamesNew);
  };

  const handleChange = (newFileList, appendFileList = true) => {
    let mergedFileList;
   if(!appendFileList) {
      mergedFileList = newFileList;
    }
    else if((newFileList.length <= 1 && fileList.length== 0) || !checkForDuplicateFiles([...fileList], [...newFileList])){
      mergedFileList = appendFileList ? [...fileList, ...newFileList] : newFileList;
    }
    else {
      mergedFileList = [...fileList];
    }
    setFileList(mergedFileList);
    onChange && onChange(mergedFileList);
  };

  const uploadFiles = (filesObj) => {
    setErrors(null)
    axios
      .post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/v1/file-upload/fileUpload/multiple`,
        filesObj,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const error = res?.data?.filter(e=> e.error && e.errorMessage)?.[0]?.errorMessage
        if(error){
          setErrors(error);
          setInputKey(`inputKey-${Math.random()}`)
        }
        else{
          handleChange(res.data);
        }
        setShowProgress(false);
      })
      .catch((err) => {
        //formatErrorMessage(err);
      });
  };

  const deleteFileUpload = (id) => {
    axios
      .delete(
        `${
          process.env.REACT_APP_REST_API_BASE_URL
        }/v1/file-upload/file?id=${encodeURIComponent(id)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        //formatErrorMessage(err);
      });
  };

  const handleFileUpload = (e) => {
    setShowProgress(true);
    let filesObj = {};
    const fileCount = e.target.files.length;
    for (let file of e.target.files) {
      const fileReader = new FileReader();
      fileReader.filename = file.name;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function (readerEvt) {
        if (
          readerEvt &&
          readerEvt.currentTarget.result &&
          !isEmpty(readerEvt.currentTarget.result)
        ) {
          filesObj[readerEvt.currentTarget.filename] = Array.from(
            new Uint8Array(readerEvt.currentTarget.result)
          );
        }
      };
    }
    e.target.value='';
    (function wait() {
      if (Object.keys(filesObj).length < fileCount) {
        setTimeout(wait, 2000);
      } else {
        if (saveToBackend) {
          uploadFiles(filesObj);
        } else {
          handleChange(filesObj);
        }
      }
    })();
  };

  if (clearState !== clearStateProp) {
    setFileList([]);
    setClearState(clearStateProp);
  }

  return (
    <StyledUpload disabled={disabled} className="d-inline-block" id='FileUploadButton'>
      {topComponent}
      <label id={`${componentName}-FileUpload`} className={`btn btn-primary me-0 ${btnClass ? btnClass : ''}`}>
        <input type="file" multiple={multiple} onChange={handleFileUpload} key={inputKey} disabled={disabled} />
        {showIcon && <i className="fa fa-cloud-upload me-2"></i>}
        {label}
      </label>
      {showProgress && (
        <div>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
      <div className="d-block">
        {(fileList && showFileList) &&
          fileList.map((fl, index) => (
            <div>
              {fl.fileName || fl.name}
              <StyledLabel
                onClick={(e) => {
                  const newFileList = [...fileList];
                  const fileUploadToDelete = newFileList.splice(index, 1);
                  handleChange(newFileList, false);
                  deleteFileUpload(fileUploadToDelete.fileId);
                }}
              ></StyledLabel>
            </div>
          ))}
      </div>
      {errors?.length > 0 &&
        <ErrorToast
          className="float-end"
          errorParam={errors}
          closeToast ={()=>setErrors(null)}
        />
      }
    </StyledUpload>
  );
};

export { FileUpload };
