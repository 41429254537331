import React, { useState, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";
import { Container, Row, Col, Button, Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropDownList, DDLDataMode, DDLMode, GENERIC_DDL_TYPE } from "../dropDownLists/genericDDL";
import { ComparisonDropDownList, ComparisonDDLKind, COMPARISON_DDL_TYPE } from "../dropDownLists/comparisonDDL";
import { FreeText, Free_Text_TYPE } from "../freeText";
import { format, parse } from "date-fns";
import EQHErrorToast from "../EQHErrorToast";
import { READMIT_DDL_TYPE, ReadmitDropDownList } from "../dropDownLists/readmitDDL";
import { SCHEDULE_DDL_TYPE, ScheduleApptDropDownList } from "../listFilters/scheduleApptDDL";

const componentName = "filterGroup";

const ModalStyled = styled(Modal)`
  .fa-times-circle {
    color: #b30000;
    font-size: 14px;
  }
`;

const FilterGroup = ({
  filters,
  handleChange,
  defaultFilterParams = [],
  filterParams,
  customWorklistName,
  additionalValidation,
  height,
}) => {
  const [errors, setErrors] = useState([]);

  //Key by Header map for the filters having different Key and Header value
  const constructKeyByHeader = () => {
    const keyByHeaderMap = new Map();
    keyByHeaderMap.set("DOB", "Birth Date");
    keyByHeaderMap.set("Patient City", "City");
    keyByHeaderMap.set("Patient Zip Code", "Zip Code");
    keyByHeaderMap.set("PCP Name", "PCP");
    keyByHeaderMap.set("Roster Status", "Patients");
    keyByHeaderMap.set("Care Opportunity Name", "Opportunity Definition");
    keyByHeaderMap.set("Care Opportunity Status", "Opportunity Status");
    keyByHeaderMap.set("Care Opportunity Year", "Measure Year");
    keyByHeaderMap.set("Validation Status", "Validation");
    keyByHeaderMap.set("Event Type", "ADT Disposition");
    keyByHeaderMap.set("Facility", "ADT by Facility");
    keyByHeaderMap.set("MemberID", "memberIds");
    keyByHeaderMap.set("Source", "source");
    keyByHeaderMap.set("Line of Business", "lineOfBusiness");
    keyByHeaderMap.set("Admit Date", "latestAdmitDate");
    keyByHeaderMap.set("Admit Date", "latestAdmissionDate");
    keyByHeaderMap.set("Creation Date", "assignDate");
    keyByHeaderMap.set("Last PCP Visit", "lastPCPVisitFilter");
    keyByHeaderMap.set("Zone", "zone");
    keyByHeaderMap.set("Engaged with PCP", "lostMember");
    keyByHeaderMap.set("Appointment Date", "ScheduleAppt");
    keyByHeaderMap.set("Quarterly Visit Eligible", "ecipEligible");
    keyByHeaderMap.set("Market", "fileinput");

    
    return keyByHeaderMap;
  };

  const [keyByHeader, setKeyByHeader] = useState(() =>
      constructKeyByHeader()
  );

  const getFilterParamsFromSelected = (hidden) => {
    const dataMap = new Map();
    const keyByHeaderValues = [...keyByHeader.values()];
    for (let filterParam of filterParams) {
      let matchFilterForChangedParamName;
      if( keyByHeader != 'undefined'
          && keyByHeader.size != 0
          && keyByHeaderValues.includes(filterParam.paramName)
      ) {
        //Find the match by comparing Key value instead of Header because the paramName is changed for certain filterParams  to accommodate backend filter logic
        matchFilterForChangedParamName = filters.find(
            (item) => item.key === filterParam.paramName
        );
        if ((hidden && !matchFilterForChangedParamName) || (!hidden && matchFilterForChangedParamName)) {
          dataMap.set(matchFilterForChangedParamName.header, filterParam);
        }
      } else {
        let matchFilter = filters.find(
            (item) => item.getHeader() === filterParam.paramName
        );
        if ((hidden && !matchFilter) || (!hidden && matchFilter)) {
          dataMap.set(filterParam.paramName, filterParam);
        }
      }
    }
    return dataMap;
  };

  const [lastFilterParamChanged, setLastFilterParamChanged] = useState();
  const [mySelected, setMySelected] = useState(filterParams);
  const [show, setShow] = useState(false);
  const [dataMap, setDataMap] = useState(() =>
    getFilterParamsFromSelected(false)
  );
  const [queuedDataMap, setQueuedDataMap] = useState(new Map());

  if (filterParams !== mySelected) {
    setDataMap(getFilterParamsFromSelected(false));
    setMySelected(filterParams);
  }

  const handleClose = () => {
    setQueuedDataMap(new Map());
    setShow(false);
    setErrors([]);
  };

  const handleShow = () => setShow(true);

  const removeFilterParam = (param) => {
    dataMap.delete(param);
    filterParams = [];
    dataMap.forEach((filterParam, key, map) => {
      filterParams.push(filterParam);
    });
  handleChange(filterParams);
  };

  const clearAllFilters = () => {
    setDataMap(new Map());
    setQueuedDataMap(new Map());
  };

  const getMergedMaps = () => {
    const mergedMap = new Map(dataMap);
    queuedDataMap.forEach((filterParam, key, map) => {
      if (filterParam.paramValue && filterParam.paramValue.length) {
        mergedMap.set(filterParam.paramName, filterParam);
      } else {
        mergedMap.delete(filterParam.paramName);
      }
    });
    return mergedMap;
  };

  const handleFilterChange = (filterParam) => {
    if (filterParam.paramName) {
      const newMap = new Map(queuedDataMap);
      newMap.set(filterParam.paramName, filterParam);
      setQueuedDataMap(newMap);
      setLastFilterParamChanged(filterParam);
    }
  };

  const validate = () => {
    const newerrors = [];
    const mergedMap = getMergedMaps();
    mergedMap.forEach((filterParam, key, map) => {
      if (filterParam?.paramName === 'Readmit' && filterParam?.comparison === 'range') {
        newerrors.push(validateReadmit(filterParam));
      } else {
        newerrors.push(validateFilters(filterParam));
      }
    });

    additionalValidation && additionalValidation(mergedMap, newerrors);
    return newerrors.filter(n => n.length > 0);
  };

  const validateFilters = (filterParam) => {
    const newerrors = [];
    if (filterParam.paramValue && filterParam.paramValue.length) {
      if (filterParam.comparison === "range") {
        const startDateTime = Date.parse(filterParam.paramValue[0]);
        const endDateTime = Date.parse(filterParam.paramValue[1]);

        if(startDateTime > endDateTime){
          newerrors.push("Start date must be less than or equal to End date.")
        }
        if (isNaN(startDateTime)) {
          newerrors.push(
            `${filterParam.paramName} Needs A Valid Date For Start Date`
          );
        }
        if (isNaN(endDateTime)) {
          newerrors.push(
            `${filterParam.paramName} Needs A Valid Date For End Date`
          );
        }
      }
    }
    return newerrors
  }

  const validateReadmit = (filterParam) => {
    let newerrors = []
    const readmitStart = filterParam?.paramValue.filter(p => p.includes('startDate'));
    const readmitEnd = filterParam?.paramValue.filter(p => p.includes('endDate'));
    if (readmitStart.length > 0 || readmitEnd.length > 0) {
      const validation = validateFilters({...filterParam, paramValue: [
        readmitStart.length > 0 ? readmitStart[0] : '',
        readmitEnd.length > 0 ? readmitEnd[0] : ''
      ]});
      if (validation.length > 0) {
        newerrors = [...validation];
      }
    }
    return newerrors
  }

  const applyFilters = () => {
    if (validate().length > 0) {
      setErrors(validate());
    } else {
      const filterParams = [
        ...Array.from(getFilterParamsFromSelected(true).values()),
      ];
      const mergedMaps = getMergedMaps();
      mergedMaps.forEach((filterParam, key, map) => {
        // Filter paramName is changed to Key value instead of Header to incorporate the backend filter logic
        if( keyByHeader.has(filterParam.paramName) ) {
          filterParam.paramName = keyByHeader.get(filterParam.paramName);
        }
        filterParams.push(filterParam);
      });
      setDataMap(mergedMaps);
      handleChange(filterParams);
      handleClose();
    }
  };

  const getConvertedTex = (displayValue) => {
    var displayValueConverted = '';

    switch (displayValue) {
      case 'ER_ADMIT':
        displayValueConverted = 'ER Readmit';
        break;
      case 'IP_ADMIT':
        displayValueConverted = 'IP Readmit';
        break;
      case 'BH_IP_ADMIT':
        displayValueConverted = 'BH-IP Readmit';
        break;
      default:
        break;
    }
    return displayValueConverted;
  }

  const ReAdmitBadge = ({tooltip, span, paramName}) => {
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p me-2 mb-1">
          <span className="me-2 text-truncate">{span}</span>
          <i className="fa-sharp fa-solid fa-xmark" onClick={() => removeFilterParam(paramName)}></i>
        </Badge>
      </OverlayTrigger>
    )
  }

  const getReadmitButton = (filterParam) => {
    if (filterParam?.paramValue?.[0].includes('startDate')) {
      const dateValue = filterParam.paramValue[0].replace('startDate:', '') + ' - ' + filterParam.paramValue[1].replace('endDate:', '');
      if (filterParam.paramValue.length === 2) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      } else if (filterParam.paramValue.length === 3) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: ${getConvertedTex(filterParam.paramValue[2])} ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      } else if (filterParam.paramValue.length > 3) {
        return (
          <ReAdmitBadge
            tooltip={`${filterParam.paramName}: ${dateValue}`}
            span={`${filterParam.paramName}: Multiple ${dateValue}`}
            paramName={filterParam?.paramName}
          />
        )
      }
    } else if (filterParam?.paramValue?.length === 1) {
      const displayValueConverted = getConvertedTex(filterParam.displayValue[0]);
      return (
        <ReAdmitBadge
          tooltip={`${filterParam.paramName}: ${displayValueConverted}`}
          span={`${filterParam.paramName}: ${displayValueConverted}`}
          paramName={filterParam?.paramName}
        />
      );
    } else {
      return (
        <ReAdmitBadge
          tooltip={`${filterParam.paramName}`}
          span={`${filterParam.paramName}: Multiple`}
          paramName={filterParam?.paramName}
        />
      );
    };
  }

  const getFilterButtons = filterParams => {
    let filterButtons = [];
    let dateValue;

    for (let filterParam of filterParams) {
      let filterName = filterParam.paramName;
      const keyByHeaderValues = [...keyByHeader.values()];
      if (keyByHeaderValues.includes(filterName)) {
        const keyValues = keyByHeader.keys();
        for (let keyValue of keyValues){
          filterName = (filterName == keyByHeader.get(keyValue))? keyValue:filterName;
        }
      }
      const selectedFilterParams = [
        ...Array.from(getFilterParamsFromSelected(true).keys()),
      ];
      if (filterParam.paramValue.length > 0
          && !selectedFilterParams.includes(filterName)
      ) {
        if (filterParam.comparison != 'month'
            && (filterParam.paramName == 'DOB'
                || filterParam.paramName == 'Birth Date'
            )|| (filterParam.paramName == 'ADT Event Date')
            || (filterParam.paramName == 'Latest Discharge Date')
            || (filterParam.paramName == 'Expired Date')
            || (filterParam.paramName == 'Last Known Visit')
            || (filterParam.paramName == 'Risk Score')
        )  {
          switch (filterParam.comparison) {
            case 'month':
              dateValue = 'Month - ' + filterParam.displayValue;
              break;
            case 'eq':
              dateValue = 'On - '+ filterParam.paramValue[0];
              if(filterParam.paramName == 'Risk Score'){
              dateValue = 'Equal to ' + filterParam.paramValue[0];}
              break;
            case 'lt':
              dateValue = 'Prior - '+ filterParam.paramValue[0];
              if(filterParam.paramName == 'Risk Score'){
                dateValue = 'Less than ' + filterParam.paramValue[0];}
              break;
            case 'gt':
              dateValue = 'Since - '+ filterParam.paramValue[0];
              if(filterParam.paramName == 'Risk Score'){
                dateValue = 'Greater than ' + filterParam.paramValue[0];}
              break;
            case 'range':
              dateValue = 'Range '+ filterParam.paramValue[0]+' - ' +filterParam.paramValue[1];
              break;
            default:
              dateValue = filterParam.paramValue;
            }
        filterButtons.push(
            <OverlayTrigger placement="top" overlay={<Tooltip>{filterName}: {dateValue}</Tooltip>}>
              <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p me-2 mb-1">
                <span className="me-2 text-truncate">{filterName}: {dateValue}</span>
                <i className="fa-sharp fa-solid fa-xmark" onClick={() => {removeFilterParam(filterName);}}></i>
              </Badge>
            </OverlayTrigger>
        );
        } else if (filterParam.paramName === 'Readmit' && filterParam.comparison === 'range') {
          filterButtons.push(getReadmitButton(filterParam));
        }else {
          {
            if(filterParam.paramValue.length>1){
              filterButtons.push(
                  <OverlayTrigger placement="top" overlay={<Tooltip>{filterName}: Multiple</Tooltip>}>
                    <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p me-2 mb-1">
                      <span className="me-2 text-truncate">{filterName}: Multiple</span>
                      <i className="fa-sharp fa-solid fa-xmark" onClick={() => {removeFilterParam(filterName);}}></i>
                    </Badge>
                  </OverlayTrigger>
              );
            }
            else
            {
              filterButtons.push(
                  <OverlayTrigger placement="top" overlay={<Tooltip>{filterName}: {filterParam.displayValue}</Tooltip>}>
                    <Badge id={`${componentName}-activeFilter`} className="btn-filter border cursor-p me-2 mb-1">
                      <span className="me-2 text-truncate">{filterName}: {filterParam.displayValue}</span>
                      <i className="fa-sharp fa-solid fa-xmark" onClick={() => {removeFilterParam(filterName);}}></i>
                    </Badge>
                  </OverlayTrigger>
              );
            }
          }
        }
      }
    }
    return filterButtons;
  };

  return (
    <>
      {customWorklistName == null && (
        <>
          <div className="d-flex align-items-start">
            <div>
              <Button variant="light" id={`${componentName}-Filters`} className="border me-2 mb-2" onClick={handleShow}>
                <span className="me-2"><i className="fa-solid fa-bars-filter me-2"></i> Filter</span>
              </Button>
            </div>
            <div className="mb-2">
              {getFilterButtons(filterParams)}
            </div>
          </div>
        </>
      )}
      <ModalStyled size="xl" show={show} backdrop="static" onHide={handleClose} centered scrollable>
        <Modal.Header>
          <Modal.Title id={`${componentName}-FiltersTitle`}>Filters</Modal.Title>
          <button id={`${componentName}-CloseFilters`} type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </Modal.Header>
        <Modal.Body>
          <EQHErrorToast id={'filterErrors'} errors={errors} handleClose={() => setErrors([])} isList/>
          {height ? (
          <Container style={{ height: "300px"}}>
            <Row xs={1} sm={1} md={3}>
              {filters.map((filter, idx) => {
                const dataMapClone = getMergedMaps();
                return (
                    <Col>
                      <Filter
                          filterDefinition={filter}
                          handleChange={handleFilterChange}
                          selected={
                              queuedDataMap.get(filter.getHeader()) ??
                              dataMap.get(filter.getHeader())
                          }
                          externalDataMap={dataMapClone}
                          onLoad={(externalDataMap, selectedData, setData) =>
                              filter.onLoad
                                  ? filter.onLoad(
                                      externalDataMap,
                                      selectedData,
                                      setData,
                                      lastFilterParamChanged
                                  )
                                  : null
                          }
                          idx={idx}
                      />
                    </Col>
                );
              })}
            </Row>
          </Container>
            ) : (
          <Container>
            <Row xs={1} sm={1} md={3}>
              {filters.map((filter, idx) => {
                const dataMapClone = getMergedMaps();
                return (
                    <Col>
                      <Filter
                          filterDefinition={filter}
                          handleChange={handleFilterChange}
                          selected={
                              queuedDataMap.get(filter.getHeader()) ??
                              dataMap.get(filter.getHeader())
                          }
                          externalDataMap={dataMapClone}
                          onLoad={(externalDataMap, selectedData, setData) =>
                              filter.onLoad
                                  ? filter.onLoad(
                                      externalDataMap,
                                      selectedData,
                                      setData,
                                      lastFilterParamChanged
                                  )
                                  : null
                          }
                          idx={idx}
                      />
                    </Col>
                );
              })}
            </Row>
          </Container>
              )}

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" id={`${componentName}-CloseFilters`} onClick={handleClose}>Close</Button> */}
          <Button variant="secondary" id={`${componentName}-ResetFilters`} onClick={clearAllFilters}>Clear All</Button>
          <Button variant="primary" id={`${componentName}-SubmitFilters`} onClick={applyFilters}>Apply</Button>
        </Modal.Footer>
      </ModalStyled>
    </>
  );
};

const Filter = ({ filterDefinition, ...other }) => {
  filterDefinition.componentProps = {
    ...filterDefinition.componentProps,
    ...other,
  };

  return (
    {
      [GENERIC_DDL_TYPE]: (
        <DropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [COMPARISON_DDL_TYPE]: (
        <ComparisonDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [READMIT_DDL_TYPE]: (
        <ReadmitDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [SCHEDULE_DDL_TYPE]: (
        <ScheduleApptDropDownList
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
      [Free_Text_TYPE]: (
        <FreeText
          {...filterDefinition.componentProps}
          header={filterDefinition.getHeader()}
        />
      ),
    }[filterDefinition.type] || <div></div>
  );
};

export { FilterGroup, Filter };
