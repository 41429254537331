import { actions } from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_HOSPITAL_EVENTS_LIST:
      return {
        ...state,
        hospitalEventsList: action.payload,
      };
    case actions.SET_HOSPITAL_EVENTS_REQUEST:
      return {
        ...state,
        hospitalEventsRequest: action.payload,
      };
    case actions.TOGGLE_NEW_HOSPITAL_EVENT_MODAL_ACTIVE:
      return {
        ...state,
        isNewHospitalEventModalActive: action.payload,
      };
    case actions.SET_ADD_HOSPITAL_EVENT_REQUEST:
      return {
        ...state,
        addHospitalEventRequest: action.payload,
      };
    case actions.TOGGLE_EDIT_HOSPITAL_EVENT_MODAL_ACTIVE:
        return {
          ...state,
          isEditHospitalEventModalActive: action.payload,
        };
    case actions.SET_HOSPITAL_EVENT_TO_EDIT:
      return {
        ...state,
        hospitalEventToEdit: action.payload,
      };
    case actions.SET_EDIT_HOSPITAL_EVENT_REQUEST:
      return {
        ...state,
        editHospitalEventRequest: action.payload
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload,
      };
    default:
      return state;
  }
};
