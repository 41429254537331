import React from "react";
import {
  useCMWorklistContext
} from "../context/CMWorklist.context";
import { Col, Row } from "react-bootstrap";
import { trim, isEmpty } from "lodash";

import {
  formatLocalDate,
  getWrittenLongDate
} from "../../../../../../utils/util";
import SnoozedColumn from "./SnoozedColumn";
import PatientRecordBoldField from "../fields/PatientRecordBoldField";
import {formatDays} from "../../../utils";
import {AppPropertiesUtil} from "../../../../../../utils/localStorageUtil";

const componentName = "SourceColumn";

const SourceColumn = ({ patient, idx }) => {

  const {
    activeRole,
  } = useCMWorklistContext();

  const { role } = activeRole;

  const getColumn = () => {
    let params = {};

    switch (role) {
      case "Admin Role":
      case "Super User Access":
      case "Care Management Admin":
      case "Hub Coordinator":
        params = {
          eventUrgencyTypeText: patient?.eventOrUrgencyType,
          eventDateLabelText: "Event Date:",
          eventDateValueText: formatLocalDate(patient.eventDate),
          recommendedTeam: patient?.recommendedTeam,
          primaryReferralServiceNeeded: patient?.primaryReferralServiceNeeded
        }
        break;
      case "Community Health Workers":
      case "Clinical Coordinators":
      case "Hospital Liaisons":
        return <SourceColumnCareManagementRoles patient={patient} idx={idx} />
      case "Care Specialists":
      case "Pharmacy Care Specialists":
        return <SourceColumnCareSpecialistsRoles patient={patient} idx={idx} />
      case "Chaplains":
        return <SourceColumnChaplain patient={patient} idx={idx} />
      default:
        params = {
          recommendedTeam: patient?.recommendedTeam,
          primaryReferralServiceNeeded: patient?.primaryReferralServiceNeeded,
          eventUrgencyTypeText: patient?.eventOrUrgencyType,
          eventDateLabelText: "Event Date:",
          eventDateValueText: getWrittenLongDate(patient.eventDate)
        }
        break;
    }

    return <SourceColumnBase idx={idx} {...params} />
  }

  return (

    <Col id={`${componentName}-${idx}`}>
      <Row>
        <Col className="d-flex flex-column">
          {patient?.carePathStepDto?.stepState !== 'SNOOZED'
            ? getColumn()
            : <SnoozedColumn patient={patient} idx={idx}/>
          }
        </Col>
      </Row>
    </Col>
  )
}

const getReferralTypeUrgency = (patient) => {
  return patient?.source === "REFERRAL" ? patient?.eventOrUrgencyType : "";
}

const SourceColumnBase = ({
  idx,
  eventUrgencyTypeText,
  eventDateLabelText,
  eventDateValueText,
  recommendedTeam,
  primaryReferralServiceNeeded,
  referralTypeValueText
}) => {
  const referralEventDescription = !isEmpty(trim(recommendedTeam)) ? recommendedTeam : primaryReferralServiceNeeded;

  return (
    <>
      <span id={`${componentName}-patientEventUrgencyType-${idx}`} className="fw-bold">{eventUrgencyTypeText}</span>

      {!isEmpty(trim(referralEventDescription)) && <span
        id={`${componentName}-patientRecommendedTeamLabel-${idx}`}
        className="text-muted">{referralEventDescription}</span>}

      <span id={`${componentName}-patientDischargeDate-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
        <span id={`${componentName}-patientEventDateLabel-${idx}`} className="fw-bold me-2">{eventDateLabelText}</span>
        <span id={`${componentName}-patientEventDateValue-${idx}`} style={{ color: (new Date(eventDateValueText) && eventDateLabelText == 'Due:') && new Date(eventDateValueText) < new Date() ? '#D12026' : 'black'}}>
          {eventDateValueText || (eventDateLabelText.includes('Phone') ? '' : 'No due date')}
        </span>
      </span>
      {referralTypeValueText &&
        <span id={`${componentName}-referralTypeValueText-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
          {referralTypeValueText}
        </span>
      }
    </>
  )
}

const SourceColumnCareSpecialistsRoles = ({ patient, idx }) => {
  return (
    <>
      <SourceColumnBase
        idx={idx}
        eventUrgencyTypeText={patient?.carePathStepDto?.description}
        eventDateLabelText="Due:"
        eventDateValueText={getWrittenLongDate(patient?.carePathStepDto?.dueDate)}
        referralTypeValueText={getReferralTypeUrgency(patient)}
      />
      <SourceCarePathElements patient={patient} idx={idx} />
    </>
  )
}

const SourceColumnCareManagementRoles = ({ patient, idx }) => {
  return (
    <>
      <SourceColumnBase
        idx={idx}
        eventUrgencyTypeText={patient?.carePathStepDto?.description}
        eventDateLabelText="Due:"
        eventDateValueText={getWrittenLongDate(patient?.carePathStepDto?.dueDate)}
      />
      <SourceCarePathElements patient={patient} idx={idx} />
    </>
  )
}

const SourceColumnChaplain = ({ patient, idx }) => {
  return (
    <>
      <SourceColumnBase
        idx={idx}
        eventUrgencyTypeText={patient?.carePathStepDto?.description}
        eventDateLabelText="Due:"
        eventDateValueText={getWrittenLongDate(patient?.carePathStepDto?.dueDate)}
      />
      <PatientRecordBoldField sourceName={`${componentName}-cp-total`} idx={idx} label={"Care Path Open Time:"} value={patient?.carePathStepDto?.carePathLastAssignedTime || ""} />
      <PatientRecordBoldField sourceName={`${componentName}-cp-step-open`} idx={idx} label={"Care Step Open Time:"} value={formatDays(patient?.carePathStepDto?.daysSinceStarted)} />
    </>
  )
}

const SourceCarePathElements = ({ patient, idx }) => {
  const carePathStatusesFeatureFlag = AppPropertiesUtil.get("newCarePathStatuses.feature.enabled");

  return (
    <>
      <PatientRecordBoldField sourceName={`${componentName}-cp-step-open`} idx={idx} label={"Care Step Open Time:"} value={formatDays(patient?.carePathStepDto?.daysSinceStarted)} />
      {carePathStatusesFeatureFlag &&
        <PatientRecordBoldField sourceName={`${componentName}-cp-status-time`} idx={idx} label={"Care Path Status Time:"} value={patient?.carePathStepDto?.carePathElapsedTime || ""} />
      }
      <PatientRecordBoldField sourceName={`${componentName}-cp-total`} idx={idx} label={"Care Path Open Time:"} value={patient?.carePathStepDto?.carePathLastAssignedTime || ""} />
    </>
  )
}

export default SourceColumn