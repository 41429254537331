import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { Row, Col, Card, Accordion, Form, Dropdown, Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../../../../../utils/apiClient";

import ErrorToast from "../../../../common/ErrorToast";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import { PlusMinusToggle } from "../../../../common/PlusMinusToggle";
import { EQHSpinner } from "../../../../../components/EQHSpinner";
import {formatDateModern, formatDateModernLocal, formatTimeMinimal} from "../../../../../utils/util";
import { useAuth } from "../../../../../context/authContext";

import CareStepDueDate from "./careStepDueDate";
import CareStepStatus from "./careStepStatus";
import CarePathStepsKebab from "./carePathStepsKebab";
import CareStepDetails from "./careStepDetails";
import { setReloadWorklist } from "../../../../../actions/manageVisitActions";
import { useDispatch } from "react-redux";
import { useEQHToastContext } from "../../../../../components/toast/EQHToast.context";

const componentName = "CarePathStep"
const CarePathStep = ({
  step,
  setstep,
  patientsSelected,
  handleChangeCarePath,
  handleStepMovement,
  handleStatusChange,
  index,
  pathsLength,
  saveNewStep,
  editExistingStep,
  notesAdded,
  setNotesAdded,
  selectedPath,
  loading,
  patientInformation,
  patient,
  careProgram,
  setBlockDrag,
  setActiveKey,
  activeKey,
  handleRefreshSteps
}) => {
  const isEmpty = require("is-empty");

  const [showBeforeForm, setShowBeforeForm] = useState(false);
  const [showAfterForm, setShowAfterForm] = useState(false);
  const [editStep, setEditStep] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showAdd, setShowAdd] = useState(true);
  const dispatch = useDispatch();
  const textBoxRefForNote = useRef();
  const { setNotification } = useEQHToastContext();

  const ERROR_NOTIFICATION_TITLE = "Error";
  const CANNOT_SNOOZE_MESSAGE = ["This care step can't be snoozed as it's triggered from the Care Path Start Date"];

  const cancelNote = () => {
    setShowAdd(false);
    textBoxRefForNote.current.value = "";
  };

  const findCustomStatusLabel = (step, selectedStatus) => {
    let statuses = ["COMPLETED", "OPEN", "PENDING", "SNOOZED", "SKIPPED", "OVERDUE", "CANCELLED"];
    let items = [];
    if (step && step.customStatuses) {
      step.customStatuses.forEach((em) => {
        items.push(<Dropdown.Item
          id={`${componentName}-carePathStepActionsCompleted-${0}`}
          eventKey={em.behavesLike}
          onClick={() => {
            if (step.trigger === "CARE_PATH_START_DATE" && em.behavesLike === "SNOOZED") {
              setNotification(ERROR_NOTIFICATION_TITLE, CANNOT_SNOOZE_MESSAGE);
            } else {
              handleStatusChange(em.behavesLike, step, true, em)
            }
          }}
          active={selectedStatus === em.behavesLike}
          className="forced-capital"
        >
          {em.status}
        </Dropdown.Item>)
        if (statuses.includes(em.behavesLike) && statuses.indexOf(em.behavesLike) >= 0) {
          const ind = statuses.indexOf(em.behavesLike)
          if (ind >= 0) {
            statuses.splice(ind, 1)
          }

        }
      })
      if (statuses.length) {
        statuses.forEach((em) => {
          items.push(<Dropdown.Item
            id={`${componentName}-carePathStepActionsCompleted-${0}`}
            eventKey={em}
            onClick={() => {
              if (step.trigger === "CARE_PATH_START_DATE" && em === "SNOOZED") {
                setNotification(ERROR_NOTIFICATION_TITLE, CANNOT_SNOOZE_MESSAGE);
              } else {
                handleStatusChange(em, step)
              }
            }}
            active={selectedStatus === em}
            className="forced-capital"
          >
            {em}
          </Dropdown.Item>)
        })
      }
    }

    return items;
  };

  useEffect(() => {
    setShowAfterForm(false);
    setEditStep(false);
    if (textBoxRefForNote.current) {
      cancelNote();
    }
  }, [selectedPath]);

  const openCreationView = (e, step) => {
    if (e === "before") {
      setShowAfterForm(false);
      setSelectedAction("before");
      setShowBeforeForm(true);
      setEditStep(false);
    } else if (e === "after") {
      setShowBeforeForm(false);
      setSelectedAction("after");
      setShowAfterForm(true);
      setEditStep(false);
    } else if (e === "edit") {
      setShowBeforeForm(false);
      setSelectedAction("edit");
      setEditStep(true);
      setShowAfterForm(true);
    }
  };

  const onAdd = (isDraft) => {
    let newName;
    if (
      textBoxRefForNote &&
      textBoxRefForNote.current &&
      textBoxRefForNote.current.value
    ) {
      setNotesAdded([...notesAdded, textBoxRefForNote.current.value]);

      let value = {
        action: "ADD_NOTE",
        careProviderId: step.assignedTo ? step.assignedTo.id : "",
        text: textBoxRefForNote.current.value,
        status: step?.carePathStepStatus || null,
        assignToId: null,
        stepCustomStatus: null,
        isDraft: isDraft,
      };
      textBoxRefForNote.current.value = "";
      updateCarePathStep(value);
    }
  };

  const [onSavingNote, setOnSavingNote] = useState(false);

  const updateCarePathStep = (value) => {
    cancelNote();
    setOnSavingNote(true);
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          step.carePathInstanceId
        )}&carePathStepId=${encodeURIComponent(step.id)}`,
        value,
        { headers: {} }
      )
      .then((res) => {
        step.notes = res.data.result.notes;
        setOnSavingNote(false);
        setNotesAdded([]);
        patientInformation(patient);
        dispatch(setReloadWorklist(true));
      })
      .catch((err) => {
        setOnSavingNote(false);
      });
  };

  const handleOpen = (idx) => {
    const hasIndex = activeKey.indexOf(idx);
    if (hasIndex !== -1) {
      setActiveKey(prev => prev.filter(num => num !== idx))
    } else {
      setActiveKey([...activeKey, idx])
    }
  }

  return (
    <>
      {loading ? null : (
        <Accordion className="mb-3" activeKey={activeKey} alwaysOpen>
          <Card className="rounded-3 d-flex flex-row">
            <div className="bg-light pt-3 px-2 rounded-start-3 draganddrop">
              <span className="d-block lh-1">
                <i className="fa-solid fa-grip-dots-vertical"></i>
              </span>
              <span className="d-block lh-1">
                <i className="fa-solid fa-grip-dots-vertical"></i>
              </span>
            </div>
            <div className="flex-fill">
              <Card.Header
                id={`${componentName}-carePathStepHead`}
                className="d-flex justify-content-between align-items-center p-3 bg-transparent border-0"
                eventKey={index}
                onClick={() => handleOpen(index)}
              >
                <PlusMinusToggle
                  id={`${componentName}-carePathStepHeader-${index}`}
                  className="w-50"
                  eventKey={index}
                >
                  <span className="d-inline-block text-start">
                    <span id={`${componentName}-carePathStepTitle-${index}`} className="fs-6 fw-bold mb-0 d-block lh-1">
                      Step {step.stepNumber}: {step.title}
                      {step.required && (
                        <span className="text-danger">*</span>
                      )}
                    </span>
                    <span id={`${componentName}-carePathStepAssigned`} className="text-muted">{step.assignedTo == null ? "Unassigned" : step.assignedTo.firstName + " " + step.assignedTo.lastName}</span>
                  </span>
                </PlusMinusToggle>
                {!(step.carePathStepStatus === "COMPLETED" || step.carePathStepStatus === "Completed") && (
                  <CareStepDueDate index={index} step={step} />
                )}
                <div className="d-flex justify-content-end align-items-center" onClick={() => setstep(step)}>
                  <CareStepStatus
                    index={index}
                    step={step}
                    findCustomStatusLabel={findCustomStatusLabel}
                    careProgram={careProgram}
                    handleStatusChange={handleStatusChange}
                  />
                  <CarePathStepsKebab
                    index={index}
                    step={step}
                    handleStepMovement={handleStepMovement}
                    pathsLength={pathsLength}
                    showAfterForm={showAfterForm}
                    showBeforeForm={showBeforeForm}
                    openCreationView={openCreationView}
                    editExistingStep={editExistingStep}
                    saveNewStep={saveNewStep}
                    handleChangeCarePath={handleChangeCarePath}
                    careProgram={careProgram}
                    patient={patient}
                  />
                </div>
              </Card.Header>
              <Accordion.Collapse
                eventKey={index}
                onMouseEnter={() => setBlockDrag(true)}
                onMouseLeave={() => setBlockDrag(false)}
              >
                <Card.Body>
                  <CareStepDetails
                    index={index}
                    step={step}
                    formatDateModern={formatDateModernLocal}
                    formatTimeMinimal={formatTimeMinimal}
                    textBoxRefForNote={textBoxRefForNote}
                    setShowAdd={setShowAdd}
                    cancelNote={cancelNote}
                    showAdd={showAdd}
                    onAdd={onAdd}
                    onSavingNote={onSavingNote}
                    EQHSpinner={EQHSpinner}
                    isEmpty={isEmpty}
                    handleRefreshSteps={handleRefreshSteps}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Card>
        </Accordion>
      )}
    </>
  );
};

export default CarePathStep