import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";

import Modal from 'react-bootstrap/Modal'
import { Button } from "../../components/buttons";

import {
  setVisibleOrderCustomListModal,
  saveOrderCustomList,
  sortCustomListAlphabetically
} from "../../actions/modal/orderCustomListActions";

import ReactDragListView from 'react-drag-listview/lib/index.js';

const OrderCustomListModal = () => {
  const orderCustomListModalState = useSelector(reduxState => reduxState.orderCustomListModal);
  const navigationState = useSelector(reduxState => reduxState.navigation);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const dragProps = {
    onDragEnd: (fromIndex, toIndex) => {
      const data = [...items];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setItems(data);
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  };

  useEffect(() => {
    if (orderCustomListModalState.isVisible === true) {
      setItems(Object.assign([], navigationState.filter((item) => item.onClick == null)));
    }
  }, [orderCustomListModalState.isVisible]);

  return (
      <Modal show={orderCustomListModalState.isVisible} onHide={() => dispatch(setVisibleOrderCustomListModal(false))}>
        <Modal.Header closeButton style={{ backgroundColor: "#F9F9FA" }}>
          <Modal.Title>Order Custom List</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-y-auto overflow-x-hidden" style={{ maxHeight: "500px" }}>
          <ReactDragListView {...dragProps}>
            <ol className="list-group">
              {items.map((item, index) => 
                <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                  <a href="#" className="text-dark">
                    <i className="fa fa-arrows-alt"></i> {item.title}
                  </a>
                </li>)}
            </ol>
          </ReactDragListView>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => dispatch(sortCustomListAlphabetically())}>
            Reset
          </Button>
          <Button variant="primary" onClick={() => dispatch(saveOrderCustomList(items))}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default OrderCustomListModal;