import {isEmpty} from "lodash";
import {formatReverseDate} from "../../../../utils/util";

export const getAttestationDDLOption = (activity) => {
  const {
    additionalInfoCaption,
    additionalInfoOptions,
  } = activity;

  if (!isEmpty(additionalInfoCaption) && !isEmpty(additionalInfoOptions)) {
    return {
      id: activity.visitId ?? activity.id,
      gapId: activity.visitId ?? activity.id,
      visitGap: false,
      abbreviation: activity.abbreviation,
      caption: activity.abbreviation + " " + additionalInfoCaption,
      options: additionalInfoOptions,
    }
  }

  return null;
}

export const normalizeDate = (dateOfService) => {
  if (isEmpty(dateOfService)) {
    return null;
  }
  return formatReverseDate(dateOfService.replaceAll('/', '-'));
}