export const initialState = {
  eventsTimelineList: [],
  userNameListFilter: [],
  availableEventTypes: [],
  pageNumber: 1,
  isLoading: false,
  hasMore: true,
  totalSize: 0,
  eventTimelineSearch: {
    filters: [], sorts: [], pageNumber: 1, pageSize: 20
  },
};

export const actions = {
  SET_EVENTS_TIMELINE_LIST: 'SET_EVENTS_TIMELINE_LIST',
  SET_TOTAL_SIZE: 'SET_TOTAL_SIZE',
  SET_USER_NAME_LIST_FILTER: 'SET_USER_NAME_LIST_FILTER',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_HAS_MORE: 'SET_HAS_MORE',
  SET_EVENT_TIMELINE_SEARCH: 'SET_EVENT_TIMELINE_SEARCH',
  SET_AVAILABLE_EVENT_TYPES: 'SET_AVAILABLE_EVENT_TYPES'
};