import React, { useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";

const componentName = "patientInformation";

const handleRedirect = () => {
  document.getElementById('patientDetailsTabs-tab-patientDetailsView').click()
  sessionStorage.setItem('redirectTab', 'patientDetailsView')
  sessionStorage.setItem('itemID', 'patientNotes')
}

export const PatientInformation = ({patientInfo, patientExtraInfo}) => {
    return (
    <>
      { patientInfo && (
      <Card className="rounded-3 info-box">
        <Card.Body>
          <Form>
            <Row>
              <Col className="col-auto">
                <p className="fs-6 fw-bold">Patient Information</p>
              </Col>
              <Col className="col-md-3">
                <p
                  style={{
                    fontWeight: '500',
                    color: '#007DB7',
                    fontSize: '12px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleRedirect()}
                >View Internal Patient Notes</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoPrimaryPhone`}>
                  <Form.Label id={`${componentName}-patientInfoPrimaryPhoneLabel`} column sm={4}>
                    Primary Phone
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoPrimaryPhoneValue`}>{patientInfo.primaryPhone ? patientInfo.primaryPhone:""}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoSecPhone`}>
                  <Form.Label id={`${componentName}-patientInfoSecPhoneLabel`} column sm={4}>
                    Secondary Phone
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoSecPhoneValue`}>{patientInfo.secondaryPhone ? patientInfo.secondaryPhone: ""}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoADTPhone`}>
                  <Form.Label id={`${componentName}-patientInfoADTPhoneLabel`} column sm={4}>
                    ADT Phone
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoADTPhoneValue`}>{patientInfo.adtPhone ? patientInfo.adtPhone:""}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientAddress`}>
                  <Form.Label id={`${componentName}-patientAddressLabel`} column sm={4}>
                    Address
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientAddressValue`}>{patientInfo?.address ? patientInfo?.address : (patientExtraInfo?.address || patientExtraInfo?.address2)}</span>
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoPayor`}>
                  <Form.Label id={`${componentName}-patientInfoPayorLabel`} column sm={4}>
                    Payor
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoPayorValue`}>{patientInfo.payor ? patientInfo.payor : patientExtraInfo?.payer}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-practiceName`}>
                  <Form.Label id={`${componentName}-practiceNameLabel`} column sm={4}>
                    Practice Name
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-practiceNameValue`}>{patientInfo.practiceName ? patientInfo.practiceName : (patientExtraInfo?.organizationName || patientExtraInfo?.payer)}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoPCP`}>
                  <Form.Label id={`${componentName}-patientInfoPCPLabel`} column sm={4}>
                    PCP
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoPCPValue`}>{patientInfo.pcp ? patientInfo.pcp : patientExtraInfo?.pcp?.name}</span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId={`${componentName}-patientInfoPCPPhone`}>
                  <Form.Label id={`${componentName}-patientInfoPCPPhoneLabel`} column sm={4}>
                    PCP Phone
                  </Form.Label>
                  <Col className="d-flex align-items-center">
                    <span id={`${componentName}-patientInfoPCPPhoneValue`}>{patientInfo.pcpPhone ? patientInfo.pcpPhone : patientExtraInfo?.pcp?.phone}</span>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Card.Body>
      </Card>
      )}
    </>
  );
};

export default PatientInformation;