import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ErrorBoundary } from "react-error-boundary";
import { declineReason } from "../../utils/textValueLists";

import {
  appointmentStatus,
  cancelledReason,
  status_referrals,
  urgency_referrals,
  visit_type_referrals,
  neededServices,
} from "../../utils/textValueLists";
import {
  formatDate,
  getDisplayValue,
  _calculateAge,
  convertDateToString,
  formatDateTime,
  getPatientProfileLink,
} from "../../utils/util";
import { useDispatch } from "react-redux";
import {
  getFileDownload,
  clearFileUploads,
} from "../../actions/fileUploadActions";
import axios from "../../utils/apiClient";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Spinner } from "react-bootstrap";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../components/dropDownLists/sharedDDL";
import { useAuth } from "../../context/authContext";
import { Button } from "../../components/buttons";
import ErrorToast from "../common/ErrorToast";
import FallBackDefault from "../../ErrorBoundaries/FallBackDefault";
import { useLocation } from "react-router";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FileUpload } from "../../components/FileUpload";
import { useCMWorklistContext } from "../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";

const componentName = "ReferralModal";
const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const ReferralModal = ({
  referralObject,
  quickListType,
  closeModal,
  updateParent,
  reloadPatientView
}) => {
  const auth = useAuth();
  const location = useLocation();
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [referralObjectCalc, setReferralObjectCalc] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const isEmpty = require("is-empty");

  const cmContext = useCMWorklistContext();

  const findObject = (list, param) => {
    let obj = list.find((o) => o.value === param);
    return obj;
  };
  const [referralObj, setReferralObj] = useState({
    status: findObject(status_referrals, referralObject.status),
    declinedReason: null,
    cancelledReason: null,
    scheduledDate: null,
    dateOfService: null,
    appointmentStatus: null,
    note: null,
  });

  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };

  const StyledStatus = styled.span`
    color: ${(props) =>
      props.type != null &&
      props.type.patient != null &&
      props.type.patient.active === true
        ? `#28a745`
        : `#dc3545`};
  `;

  const StyledRiskLevelBtn = styled.button`
    color: #fff !important;
    border-radius: 0;
    background-color: ${(props) =>
      props.riskLevel === `Very Low`
        ? `#3DCA37`
        : props.riskLevel === `Low`
        ? `#188dcd`
        : props.riskLevel === `Medium`
        ? `#FDCA6C`
        : props.riskLevel === `High`
        ? `#fd7e14`
        : props.riskLevel === `Very High`
        ? `#DD6161`
        : `#D1D3D5`};
  `;

  useEffect(() => {
    if (referralObjectCalc && referralObjectCalc.declinedReason) {
      setReferralObj({
        ...referralObj,
        declinedReason:
          referralObjectCalc &&
          referralObjectCalc.status &&
          findObject(status_referrals, referralObjectCalc.status).text ===
            "Declined"
            ? findObject(declineReason, referralObjectCalc.declinedReason)
            : null,
      });
    }
    if (referralObjectCalc && referralObjectCalc.cancelledReason) {
      setReferralObj({
        ...referralObj,
        cancelledReason:
          referralObjectCalc &&
          referralObjectCalc.status &&
          findObject(status_referrals, referralObjectCalc.status).text ===
            "Cancelled"
            ? findObject(cancelledReason, referralObjectCalc.cancelledReason)
            : null,
      });
    }
    if (referralObjectCalc && referralObjectCalc.appointmentStatus) {
      setReferralObj({
        ...referralObj,
        appointmentStatus:
          referralObjectCalc && referralObjectCalc.appointmentStatus
            ? findObject(
                appointmentStatus,
                referralObjectCalc.appointmentStatus
              )
            : null,
      });
    }
  }, [referralObjectCalc]);

  useEffect(() => {
    if (referralObject && referralObject.id) {
      getReferrals(encodeURIComponent(referralObject.id));
    }
    if (
      [
        "REFERRALS_AWAITING_COMPLETION",
        "REFERRALS_AWAITING_RESPONSE",
        "REFERRALS_RECEIVED",
      ].includes(quickListType)
    ) {
      setStatuses(statuses.concat(status_referrals));
    } else if (["REFERRALS_SENT"].includes(quickListType)) {
      setStatuses([...statuses, { text: "Cancelled", value: "10_CANCELLED" }]);
    }
  }, []);
  const getReferrals = (id) => {
    const url =
      process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-referral/";
    setIsLoading(true);
    axios
      .get(url + `find?referralId=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data && !isEmpty(response.data)) {
          setReferralObjectCalc(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
      });
  };
  const handleChange = (name, value) => {
    if (name == "dateOfService") {
      let currentDate = new Date();
      if (
        value != null &&
        value.getTime() > currentDate.getTime() &&
        referralObj.status.value == "30_COMPLETED"
      ) {
        alert("Date cannot be in the future.");
        return;
      }
    }

    setReferralObj({ ...referralObj, [name]: value });
  };

  const manageReferral = () => {
    let finalScheduledDate;
    if (typeof referralObj.scheduledDate === "number") {
      finalScheduledDate = convertDateToString();
    } else {
      finalScheduledDate = referralObj.scheduledDate;
    }
    let newState = referralObj;
    const userName = auth.getName();
    const query = {
      ...referralObjectCalc,
      referralId: referralObject.id,
      status: referralObj.status
        ? referralObj.status.value
        : referralObjectCalc.status,
      cancelledReason: referralObj.cancelledReason
        ? referralObj.cancelledReason.value
        : referralObjectCalc.cancelledReason,
      declinedReason: referralObj.declinedReason
        ? referralObj.declinedReason.value
        : referralObjectCalc.declinedReason,
      sentByFullName: userName,
      scheduledDate: finalScheduledDate
        ? finalScheduledDate
        : referralObjectCalc.scheduledDate,
      dateOfService: referralObj.dateOfService
        ? referralObj.dateOfService.value
        : referralObjectCalc.dateOfService,
      appointmentStatus: referralObj.appointmentStatus
        ? referralObj.appointmentStatus.value
        : referralObjectCalc.appointmentStatus,
      notes: referralObj.note ? referralObj.note : referralObj.note,
      documentation:
        uploadedFiles.length > 0
          ? uploadedFiles.map((file) => {
              return {
                description: null,
                fileMetadata: file,
              };
            })
          : referralObjectCalc.uploadedFiles,
    };
    const url =
      process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-referral/";
    setIsLoading(true);
    axios
      .post(url + "update", query, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        let updatedRef = response.data;
        await updateParent(updatedRef);
        newState.conditions = [{ code: [], description: [] }];
        await setReferralObj({ ...newState });
        await dispatch(clearFileUploads());
        cmContext && cmContext.sendEvent('CONFIRM_SAVE_REFERRAL');
        setFileUploadResetCounter(fileUploadResetCounter + 1);
        reloadPatientView();
        closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  const isFormValid = () => {
    let isValid;
    const {
      status,
      declinedReason,
      cancelledReason,
      scheduledDate,
      dateOfService,
      appointmentStatus,
      note,
    } = referralObj;

    if (status) {
      if (status && status.text === "Declined") {
        if (declinedReason) {
          return true;
        } else {
          return false;
        }
      } else if (status && status.text === "Completed") {
        if (dateOfService && !isEmpty(referralObj.note)) {
          return true;
        } else {
          return false;
        }
      } else if (status && status.text === "Cancelled") {
        if (cancelledReason) {
          return true;
        } else {
          return false;
        }
      } else if (
        status &&
        status.text === "Pending" &&
        isEmpty(referralObj.note)
      ) {
        return false;
      } else if (
        status &&
        status.text === "Patient Cancelled" &&
        isEmpty(referralObj.note)
      ) {
        return false;
      } else if (status && status.text === "Accepted") {
        if (
          referralObj.appointmentStatus != null &&
          (referralObj.appointmentStatus.value === "SCHEDULED_APPOINTMENT" ||
            referralObj.appointmentStatus.value ===
              "RESCHEDULED_APPOINTMENT") &&
          isEmpty(referralObj.scheduledDate)
        ) {
          return false;
        } else {
          return true;
        }
      } else if (
        status &&
        status.text === "Treatment In Progress" &&
        isEmpty(referralObj.note)
      ) {
        return false;
      } else {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };
  return (
    <ErrorBoundary FallbackComponent={FallBackDefault}>
      <div>
        {referralObjectCalc && !isLoading ? (
          <div>
            <Row className="mb-4">
              <Col sm={4}>
                <div className="d-flex">
                  {referralObjectCalc &&
                  referralObjectCalc.patient &&
                  referralObjectCalc.patient.riskLevel ? (
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip>
                          {referralObjectCalc.patient.riskLevel}
                        </Tooltip>
                      }
                    >
                      <StyledRiskLevelBtn
                        type="button"
                        className="btn fw-bold btn-sm me-3"
                        riskLevel={referralObjectCalc.patient.riskLevel}
                      >
                        {referralObjectCalc.patient.riskLevelCode}
                      </StyledRiskLevelBtn>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}
                  <div>
                    <div>
                      <b id={`${componentName}-PatientLabel`}>Manage Referral:</b>{" "}
                      {referralObjectCalc &&
                        referralObjectCalc.patient &&
                        referralObjectCalc.patient.id && (
                          <a  id={`${componentName}-PatientProfileLink`}
                            href={getPatientProfileLink(
                              referralObjectCalc.patient.id,
                              location
                            )}
                            className="fw-bold"
                          >
                            {referralObjectCalc.patient &&
                            referralObjectCalc.patient.lastName
                              ? referralObjectCalc.patient.lastName
                              : ""}
                            ,{" "}
                            {referralObjectCalc.patient &&
                            referralObjectCalc.patient.firstName
                              ? referralObjectCalc.patient.firstName
                              : ""}
                          </a>
                        )}
                    </div>
                    <span>
                      {referralObjectCalc.patient &&
                      referralObjectCalc.patient.memberId
                        ? referralObjectCalc.patient.memberId
                        : ""}{" "}
                      (
                      <StyledStatus
                        id={`${componentName}-PatientStatus`}
                        className="fw-bold"
                        type={referralObjectCalc}
                      >
                        {referralObjectCalc.patient &&
                        referralObjectCalc.patient.active
                          ? "Active"
                          : "Inactive"}
                      </StyledStatus>
                      )
                    </span>
                    <b id={`${componentName}-PatientDOBLabel`}> DOB:</b>{" "}<span id={`${componentName}-PatientDOBValue`}>
                      {referralObjectCalc.patient &&
                    referralObjectCalc.patient.dateOfBirth
                      ? `${formatDate(
                          referralObjectCalc.patient.dateOfBirth
                        )}(${_calculateAge(
                          referralObjectCalc.patient.dateOfBirth
                        )})`
                      : ""}{" "}
                    </span>
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                <span id={`${componentName}-PatientAddressLabel`} className="fw-bold d-block">ADDRESS</span>
                {referralObjectCalc.patient &&
                referralObjectCalc.patient.fullAddress ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id={`${componentName}-PatientAddressTooltip`}>
                        {referralObjectCalc.patient.fullAddress}
                      </Tooltip>
                    }
                  >
                    <div id={`${componentName}-PatientAddressValue`} className="text-truncate">
                      {referralObjectCalc.patient.fullAddress}
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <Col sm={2}>
                <span id={`${componentName}-PrimaryPayerLabel`} className="fw-bold d-block">Primary Payer</span>
                {referralObjectCalc.patient &&
                referralObjectCalc.patient.payer ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id={`${componentName}-PrimaryPayerTooltip`}>{referralObjectCalc.patient.payer}</Tooltip>
                    }
                  >
                    <div id={`${componentName}-PrimaryPayerValue`} className="text-truncate">
                      {referralObjectCalc.patient.payer}
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <Col sm={2}>
                <span id={`${componentName}-PrimaryPracticeLabel`} className="fw-bold d-block">
                  Primary Practice Name
                </span>
                {referralObjectCalc.patient &&
                referralObjectCalc.patient.organizationName ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id={`${componentName}-PrimaryPayerTooltip`}>
                        {referralObjectCalc.patient.organizationName}
                      </Tooltip>
                    }
                  >
                    <div id={`${componentName}-PrimaryPayerValue`} className="text-truncate">
                      {referralObjectCalc.patient.organizationName}
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <Col sm={2}>
                <span id={`${componentName}-LOBLabel`} className="fw-bold d-block">Line Of Business</span>
                {referralObjectCalc.patient &&
                referralObjectCalc.patient.lineOfBusiness ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id={`${componentName}-LOBTooltip`}>
                        {referralObjectCalc.patient.lineOfBusiness}
                      </Tooltip>
                    }
                  >
                    <div id={`${componentName}-LOBValue`} className="text-truncate">
                      {referralObjectCalc.patient.lineOfBusiness}
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mb-2">
                  <Col>
                    <b id={`${componentName}-ReferredByLabel`} className="d-block">Referred By</b>
                    <span id={`${componentName}-ReferredByValue`}>{referralObjectCalc.sentBy}</span>
                  </Col>
                  <Col>
                    <b id={`${componentName}-ReferredTpLabel`} className="d-block">Referred To</b>
                    <span id={`${componentName}-ReferredToValue`}>{referralObjectCalc.sentTo}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <b id={`${componentName}-UrgencyLabel`} className="d-block">Urgency</b>
                    <span id={`${componentName}-UrgencyValue`}>{getDisplayValue(
                      urgency_referrals,
                      referralObjectCalc.urgency
                    )}</span>
                  </Col>
                  <Col>
                    <b id={`${componentName}-RespondByLabel`} className="d-block">Respond By</b>
                    <span id={`${componentName}-PrimaryPayerValue`}>{referralObjectCalc && referralObjectCalc.respondBy
                      ? formatDate(referralObjectCalc.respondBy)
                        : ""}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  {referralObjectCalc.visitType != null ? (
                    <Col>
                      <b id={`${componentName}-VisitTypeLabel`} className="d-block">Visit Type</b>
                      <span id={`${componentName}-VisitTypeValue`}>{getDisplayValue(
                        visit_type_referrals,
                        referralObjectCalc.visitType
                      )}</span>
                    </Col>
                  ) : (
                    <Col>
                      <b id={`${componentName}-ServicesNeededLabel`} className="d-block">Services Needed</b>
                      <div className="me-4 mb-2 w-40">
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id={`${componentName}-ServicesNeededTooltip`} >
                              {referralObjectCalc.servicesNeeded
                                ? referralObjectCalc.servicesNeeded
                                    .map((service) =>
                                      getDisplayValue(neededServices, service)
                                    )
                                    .join(", ")
                                : ""}
                            </Tooltip>
                          }
                        >
                          <div id={`${componentName}-ServicesNeededValue`} className="text-truncate">
                            {referralObjectCalc.servicesNeeded
                              ? referralObjectCalc.servicesNeeded
                                  .map((service) =>
                                    getDisplayValue(neededServices, service)
                                  )
                                  .join(", ")
                              : ""}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="me-4 mb-2 w-40">
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id={`${componentName}-ServicesNeededOtherTooltip`}>
                              {referralObjectCalc.servicesNeededOtherText
                                ? referralObjectCalc.servicesNeededOtherText
                                : ""}
                            </Tooltip>
                          }
                        >
                          <div  id={`${componentName}-ServicesNeededOtherValue`}className="text-truncate">
                            {referralObjectCalc.servicesNeededOtherText
                              ? referralObjectCalc.servicesNeededOtherText
                              : ""}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  )}
                  <Col>
                    <b  id={`${componentName}-VisitDiagnosisLabel`}className="d-block">Visit Diagnosis</b>
                    <span id={`${componentName}-VisitDiagnosisValue`}>{referralObjectCalc &&
                      referralObjectCalc.visitDiagnosis &&
                      referralObjectCalc.visitDiagnosis.length &&
                      referralObjectCalc.visitDiagnosis.map((o) => {
                        return <>{o}</>;
                      })}</span>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col sm={6}>
                    <b id={`${componentName}-StatusLabel`} className="d-block">
                      Status {<span className="text-danger">*</span>}
                    </b>
                    <Dropdown id={`${componentName}-StatusDropdown`} value={referralObject?.status?.value}>
                      <Dropdown.Toggle
                        id={`${componentName}-StatusValue`}
                        as={DropDownButton}
                        cssOpen={isOpen}
                        cusWidth="100% !important"
                      >
                        {referralObj.status ? (
                          referralObj.status.text
                        ) : (
                          <span>-Select-</span>
                        )}
                        <i className="fa fa-chevron-down" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="w-100"
                        as={CustomMenuStyled}
                        cusHeight={""}
                      >
                        {statuses.map((status) => (
                          <Dropdown.Item
                            key={status.value}
                            onClick={() => handleChange("status", status)}
                          >
                            {status.text}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  {referralObj &&
                  referralObj.status &&
                  referralObj.status.text &&
                  referralObj.status.text === "Declined" ? (
                    <Col sm={12}>
                      <b  id={`${componentName}-ReasonLabel`} className="d-block">
                        Reason{" "}
                        {referralObj.status.value == "60_DECLINED" && (
                          <span className="text-danger">*</span>
                        )}
                      </b>
                      <Dropdown id={`${componentName}-ReasonDropdown`} value={referralObjectCalc.declinedReason}>
                        <Dropdown.Toggle
                          id={`${componentName}-ReasonValue`}
                          as={DropDownButton}
                          cssOpen={isOpen}
                          cusWidth="100% !important"
                        >
                          {referralObj.declinedReason ? (
                            referralObj.declinedReason.text
                          ) : (
                            <span>-Select-</span>
                          )}
                          <i className="fa fa-chevron-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="w-100"
                          as={CustomMenuStyled}
                          cusHeight={""}
                        >
                          {declineReason.map((reason) => (
                            <Dropdown.Item
                              key={reason.value}
                              onClick={() =>
                                handleChange("declinedReason", reason)
                              }
                            >
                              {reason.text}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : null}
                  {referralObj &&
                  referralObj.status &&
                  referralObj.status.text &&
                  referralObj.status.text === "Cancelled" ? (
                    <Col sm={12}>
                      <b  id={`${componentName}-ReasonLabel`} className="d-block">
                        Reason{" "}
                        {referralObj.status.value == "10_CANCELLED" && (
                          <span className="text-danger">*</span>
                        )}
                      </b>
                      <Dropdown id={`${componentName}-ReasonToggle`} value={referralObjectCalc.cancelledReason}>
                        <Dropdown.Toggle
                          id={`${componentName}-ReasonValue`}
                          as={DropDownButton}
                          cssOpen={isOpen}
                          cusWidth="100% !important"
                        >
                          {referralObj.cancelledReason ? (
                            referralObj.cancelledReason.text
                          ) : (
                            <span>-Select-</span>
                          )}
                          <i className="fa fa-chevron-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="w-100"
                          as={CustomMenuStyled}
                          cusHeight={""}
                        >
                          {cancelledReason.map((reason) => (
                            <Dropdown.Item
                              key={reason.value}
                              onClick={() =>
                                handleChange("cancelledReason", reason)
                              }
                            >
                              {reason.text}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : null}
                  {referralObj &&
                  referralObj.status &&
                  referralObj.status.text &&
                  referralObj.status.text === "Completed" ? (
                    <Col sm={6}>
                      <Row>
                        <Form.Group className="w-100">
                          <Row>
                            <Col>
                              <b  id={`${componentName}-DateLabel`} className="d-block">
                                {referralObj.status.value == "30_COMPLETED"
                                  ? "Date"
                                  : "Date of Service"}{" "}
                                {referralObj.status.value == "30_COMPLETED" && (
                                  <span className="text-danger">*</span>
                                )}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <DatePickerStyled>
                                <DatePicker
                                  id={`${componentName}-DateValue`}
                                  name="dateOfService"
                                  value={referralObj.dateOfService}
                                  selected={referralObj.dateOfService}
                                  onChange={(e) =>
                                    handleChange("dateOfService", e)
                                  }
                                  className="form-control"
                                  popperPlacement="auto-left"
                                  autoComplete="off"
                                  maxDate={new Date()}
                                />
                              </DatePickerStyled>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
                <Row className="mb-2">
                  <Col>
                    <b id={`${componentName}-NoteLabel`} className="d-block">
                      Add A Note{" "}
                      {(referralObj?.status?.value == "20_PATIENT_CANCELLED" ||
                        referralObj?.status?.value == "TREATMENT_IN_PROGRESS" ||
                        referralObj?.status?.value == "30_COMPLETED" ||
                        referralObj?.status?.value == "50_PENDING") && (
                        <span className="text-danger">*</span>
                      )}
                    </b>
                    <textarea
                      onChange={(e) => handleChange("note", e.target.value)}
                      className="form-control"
                      value={referralObj.note}
                      id={`${componentName}-NoteValue`}
                      rows="3"
                    ></textarea>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <div className="form-group m-b-10">
                      <table>
                        <tr>
                          <td>
                            <FileUpload
                              onChange={handleUploadFileChange}
                              clearStateProp={fileUploadResetCounter}
                              topComponent={
                                <div id={`${componentName}-AttestWarning`} className="fw-bold" style={{ color: "#C81F1F" }}>
                                  If you are required to comply with the terms
                                  of 42 CFR Part 2, you attest that you have
                                  received patient consent to share his or her
                                  information through CareEmpower.
                                </div>
                              }
                            />{" "}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <b id={`${componentName}-HistoryLabel`}>History</b>
                <Row>
                  <Col>
                    <Card className="bg-light mb-3 rounded-0">
                      <Card.Body>
                        {referralObjectCalc.historyEvents &&
                          referralObjectCalc.historyEvents &&
                          referralObjectCalc.historyEvents.map(
                            (historyObject) => {
                              return (
                                <HistoryObject historyObject={historyObject} />
                              );
                            }
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <StyledFooter>
              <Button id={`${componentName}-CloseButton`} onClick={() => closeModal()}>Close</Button>{" "}
              <Button
                id={`${componentName}-SaveButton`}
                disabled={!isFormValid()}
                onClick={() => manageReferral()}
              >
                Save Changes
              </Button>
            </StyledFooter>
          </div>
        ) : null}
        {isLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{ marginLeft: "-30px" }}
          />
        )}
        {errorMessage && <ErrorToast errorParam={errorMessage} />}
      </div>
    </ErrorBoundary>
  );
};

const HistoryObject = ({ historyObject }) => {
  const dispatch = useDispatch();

  // converts declineReason value to declineReason text
  const convertDeclineReason = (value) => {
    for (let index = 0; index < declineReason.length; index++) {
      const reason = declineReason[index];
      if (value == reason.value) {
        return reason.text;
      }
    }
  };

  const ReferralHistory = styled.span`
    .vertical-timeline {
      width: 100%;
      position: relative;
      padding: 1.5rem 0 1rem;
    }

    .vertical-timeline::before {
      content: "";
      position: absolute;
      top: 0;
      left: 97px;
      height: 100%;
      width: 4px;
      background: #e9ecef;
      border-radius: 0.25rem;
    }

    .vertical-timeline-element {
      position: relative;
      margin: 0 0 1rem;
    }

    .vertical-timeline-element-icon {
      position: absolute;
      top: 0;
      left: 92px;
    }

    .vertical-timeline-element-content {
      position: relative;
      margin-left: 120px;
      font-size: 13px;
    }

    .vertical-timeline-element-content .timeline-title {
      font-size: 0.8rem;
      text-transform: uppercase;
      margin: 0 0 0.5rem;
      padding: 2px 0 0;
      font-weight: bold;
    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
      display: block;
      position: absolute;
      left: -120px;
      top: 0;
      padding-right: 10px;
      text-align: right;
      font-size: 12px;
    }

    .vertical-timeline-element-content:after {
      content: "";
      display: table;
      clear: both;
    }
  `;

  const handleClickofLink = (file) => {
    if (file && file.fileId) {
      dispatch(getFileDownload(file.fileId, file.fileName));
    }
  };
  return (
    <ReferralHistory>
      <div className="vertical-timeline vertical-timeline--one-column">
        <div className="vertical-timeline-item vertical-timeline-element">
          <div>
            <span className="vertical-timeline-element-icon">
              <i className="badge bg-secondary p-2 rounded-circle"> </i>
            </span>
            <div className="vertical-timeline-element-content bg-white border p-2">
              <h4 id={`${componentName}-ReferralHistoryActionValue`} className="timeline-title">
                {historyObject && historyObject.initial
                  ? "Referral Sent"
                  : "Referral Updated"}
              </h4>
              <p>
                {historyObject.userName && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryUserLabel`}>User:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryUserValue`}>{historyObject.userName}</span>
                  </span>
                )}
                {historyObject.referringPractice && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryFromLabel`}>Referring Practice:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryFromValue`}>{historyObject.referringPractice}</span>
                  </span>
                )}
                {historyObject.referredToProvider && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryToLabel`}>Referred to Provider:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryToValue`}>{historyObject.referredToProvider}</span>
                  </span>
                )}
                {historyObject.status && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryStatusLabel`}>Status:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryStatusValue`}>{getDisplayValue(status_referrals, historyObject.status)}</span>
                  </span>
                )}
                {historyObject.note && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryNoteLabel`}>Note:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryNoteValue`}>{historyObject.note}</span>
                  </span>
                )}
                {historyObject.cancelledReason && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryReasonLabel`}>Reason:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryReasonValue`}>{historyObject.cancelledReason}</span>
                  </span>
                )}
                {historyObject.declinedReason && (
                  <span className="d-block">
                    <span id={`${componentName}-ReferralHistoryReasonLabel`}>Reason:{" "}</span>
                    <span id={`${componentName}-ReferralHistoryReasonValue`}>{convertDeclineReason(historyObject.declinedReason)}</span>
                  </span>
                )}
                {historyObject.uploadedFiles &&
                  historyObject.uploadedFiles.length > 0 &&
                  historyObject.uploadedFiles.map((file) => (
                    <p key={file.fileId}>
                      {file && (
                        <span>
                          <span id={`${componentName}-ReferralHistoryAttachmentLabel`}>Attachment:{" "}</span>
                          <a id={`${componentName}-ReferralHistoryAttachmentLink`}
                            href="#"
                            onClick={() => handleClickofLink(file.fileMetadata)}
                          >
                            {file.fileMetadata.fileName
                              ? file.fileMetadata.fileName
                              : ""}
                          </a>
                        </span>
                      )}
                      {"  "}
                    </p>
                  ))}
              </p>
              <span
                className="vertical-timeline-element-date text-break"
                style={{ width: "95px" }}
              >
                <span  id={`${componentName}-ReferralHistoryTimelineDateValue`} className="d-block">
                  {formatDateTime(historyObject.createdAt)}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ReferralHistory>
  );
};
const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;
