import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const MultiComponent = ({
  onChange,
  max = null,
  itemLabel = null,
  addLabel = null,
  value,
  defaultAddValue = "",
  showDeleteButtonOnSide = false,
  itemBuilder = () => "",
  idPrefix,
}) => {
  const handleChange = (i) => (item) => {
    let newValues = [...value];
    newValues[i] = item;
    onChange(newValues);
  };

  const handleDelete = (i) => (e) => {
    e.preventDefault();
    let newValues = [...value.slice(0, i), ...value.slice(i + 1)];
    onChange(newValues);
  };

  const addComponent = (e) => {
    e.preventDefault();
    let newValues = value.concat([defaultAddValue]);
    onChange(newValues);
  };

  const showAddIcon = () => {
    return !max || value.length < max;
  };

  return (
    <Container>
      {value.map((item, index) => (
        <>
          <Row>
            <Col>
              {itemLabel ? (
                <span className="fw-bold">{`${itemLabel} ${
                  index + 1
                } `}</span>
              ) : (
                ""
              )}
              {!showDeleteButtonOnSide && (
                <i
                  onClick={handleDelete(index)}
                  className="fas fa-times-circle cursor-p text-danger"
                ></i>
              )}
            </Col>
          </Row>
          <Row>
            <Col>{itemBuilder(item, handleChange(index), index)}</Col>
            {showDeleteButtonOnSide && (
              <Col>
                <i
                  id={`${idPrefix}-${index}-delete-icon`}
                  onClick={handleDelete(index)}
                  className="fas fa-times-circle cursor-p text-danger"
                ></i>
              </Col>
            )}
          </Row>
        </>
      ))}
      {showAddIcon() && (
        <Row xl={1}>
          <Col>
            <span onClick={addComponent} className="cursor-p">
              <i id={`${idPrefix}-mc-add-btn`} className="fas fa-plus-circle"></i>{" "}
              {addLabel ?? itemLabel}
            </span>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export { MultiComponent };
