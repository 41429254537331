import {
  adtTypeMap,
  hospitalEventTypeMap,
  adtToHospitalEventFieldKeyMap
} from "./constants"; 

/**
 * Formats a date string to "MMM D, YYYY" format.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} Formatted date in "MMM D, YYYY" format.
 */
export const formatDateToShortMDY = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", { timeZone: "UTC", month: "short", day: "numeric", year: "numeric" });
};

/**
 * Formats a date string to "MMM D, YYYY, HH:MM AM/PM" format, combining date and time.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} Formatted date and time in "MMM D, YYYY, HH:MM AM/PM" format.
 */
export const formatDateToMDYHM = (dateString) => {
  const date = new Date(dateString);
  const optionsDate = { timeZone: "UTC", month: "short", day: "numeric", year: "numeric" };
  const optionsTime = { timeZone: "UTC", hour: "2-digit", minute: "2-digit", hour12: true };
  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return `${formattedDate}, ${formattedTime}`;
};

/**
 * Formats the date part from a field value if it's an array, specifically the second element.
 *
 * @param {Object} field - The field containing the date to format.
 * @returns {string} Formatted date in "MMM D, YYYY" or an empty string if not applicable.
 */

const handleDateField = (field) => {
  return field.value && Array.isArray(field.value) ? formatDateToShortMDY(field.value[1]) : '';
};

/**
 * Returns the simple value of a field, ensuring a string is always returned.
 *
 * @param {Object} field - The field whose value is to be returned.
 * @returns {string} The field value or an empty string if undefined.
 */
const handleSimpleField = (field) => field.value || '';

/**
 * Maps a disposition field value to its equivalent display string based on a mapping object or returns the value directly if not mapped.
 *
 * @param {Object} field - The field containing the disposition to map.
 * @returns {string} The mapped disposition display text or the original value.
 */
const handleDispositionField = (field) => {
  return field.value && Array.isArray(field.value) ? (hospitalEventTypeMap[field.value[1]] || field.value[1]) : '';
};

/**
 * Formats the length of stay from a field's value by appending " days" to the number.
 *
 * @param {Object} field - The field containing the length of stay value.
 * @returns {string} The formatted length of stay in days or an empty string if undefined.
 */
const handleLengthOfStayField = (field) => field.value ? `${field.value} days` : '';

// Fields belongs to adt field values

/**
 * Defines handler functions for transforming ADT event fields. Used by transformData to
 * format and map event data fields to a standardized structure.
 *
 * @type {Object} A mapping of field captions to their respective handler functions.
 */
export const adtToHospitalEventsFieldHandlers = {
  disposition: handleDispositionField,
  facility: handleSimpleField,
  phone: handleSimpleField,
  admitdate: handleDateField,
  dischargedate: handleDateField,
  lengthofstay: handleLengthOfStayField,
  admitdiagnosis: handleSimpleField,
  dischargediagnosis: handleSimpleField,
  chiefcomplaint: handleSimpleField,
  unitroom: handleSimpleField,
  bed: handleSimpleField,
  language: handleSimpleField,
  notes: handleSimpleField
};

/**
 * Transforms ADT event data using adtToHospitalEventsFieldHandlers to format and map fields.
 * Iterates over data items, applying specified field handlers to standardize output.
 *
 * @param {Array} data - The array of items to transform.
 * @returns {Array} A new array of transformed items with formatted fields.
 */
export const transformData = (data) => {
  return data.map((item, idx) => {

    let transformed = Object.keys(adtToHospitalEventFieldKeyMap).reduce((acc, key) => {
      acc[adtToHospitalEventFieldKeyMap[key]] = '';
      return acc;
    }, {});


    transformed.adtType = adtTypeMap[item.adtType] || item.adtType;
    transformed.dateTime = item.dateTime;

    item.fieldValues.forEach(field => {
      const handler = adtToHospitalEventsFieldHandlers[field.caption.toLowerCase()];
      const mappedKey = adtToHospitalEventFieldKeyMap[field.caption.toLowerCase()];
      if (handler && mappedKey) {
        transformed[mappedKey] = handler(field);
      }
    });

    transformed.idx = idx;
    transformed.id = item.eventId;

    return transformed;
  });
};
