import React, { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { ActivityCell, ActivityWrapper, TPSection } from "./styles";
import { formatDate, formatDateModern } from "../../../../../../../utils/util";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

const componentName = "recommendedActivities";
const VisitActivitySubType = ({
  visitActivityType,
  subTypes,
  requiredFields,
  extraData,
  name,
  missedOpportunities,
}) => {
  return subTypes?.map((x, idx) => {
    const activities = x?.visitActivities?.filter((e) => e?.disabled == false);

    return activities.length > 0 ? (
      <div className="mt-2" key={`activitySubType-${x?.name}-${idx}`}>
        <div className="d-flex mb-2 align-items-center">
          {x?.name !== "None" && (
            <>
              <p className="fs-6 mb-0">{x?.name}</p>
            </>
          )}
        </div>
        <TPSection visitActivityType={visitActivityType}>
          <VisitActivity
            visitActivityType={visitActivityType}
            subType={x?.name}
            extraData={extraData}
            activity={activities}
            requiredFields={requiredFields}
            name={name}
            missedOpportunities={missedOpportunities}
          />
          {name === "Needed Referrals" && (
            <ActivityWrapper visitActivityType={visitActivityType}>
              {visitActivityType?.name === "Needed Referrals" && (
                <>
                  <div key={`visitActivity-referToSDOH`}>
                    <div className="d-flex align-items-start">
                      <input className="form-check-input opacity-100 me-3" type="checkbox" value="" disabled />
                      <div className="d-flex justify-content-between w-100">
                        <div className="w-100">
                          <ActivityCell visitActivityType={visitActivityType}>
                            <label className="form-check-label" for="flexCheckDefault">
                              <span className="fw-bold me-1">Refer for Social Determinants of Health Need</span>
                              <small className="fst-italic me-1 nowrap">(Open)</small>
                            </label>
                          </ActivityCell>
                        </div>
                        <div className="d-flex flex-wrap w-100">
                          {["Yes", "No"].map((e) => (
                            <label className="d-flex form-check-label me-3" for="flexCheckDefault">
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value=""
                                checked={false}
                                readOnly
                              />
                              <div className="nowrap">{e}</div>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ActivityWrapper>
          )}
        </TPSection>
      </div>
    ) : (
      <></>
    );
  });
};

const VisitActivity = ({
  visitActivityType,
  activity,
  subType,
  requiredFields,
  extraData,
  name,
  missedOpportunities,
}) => {
  const [sortedActivity, setSortedActivity] = useState([]);

  useEffect(() => {
    if (activity) {
      let retActivity = activity.filter((e) => e?.displayName && e?.currentStatus);
      retActivity = retActivity.map((m) => {
        if (m.validation && m.validation == "REJECTED") {
          m.conditionType = "REJECTED";
        } else if (!m.conditionType) {
          m.conditionType = "SUSPECTED";
        }
        return m;
      });

      retActivity.sort(sortAlgo);
      setSortedActivity(retActivity);
    }
  }, [activity]);

  const sortAlgo = (a, b) => {
    const orderSeq = ["REJECTED", "RECAPTURED", "SUSPECTED"];
    return orderSeq.indexOf(a.conditionType) - orderSeq.indexOf(b.conditionType);
  };

  const getConditionTypeLabel = useCallback((condition) => {
    let lbl = "Suspected";
    if (condition) {
      if (condition.validation && condition.validation === "REJECTED") {
        lbl = "Rejected";
      } else {
        if (condition.conditionType === "RECAPTURED") {
          lbl = "Recaptured";
        } else if (condition.conditionType === "SUSPECTED") {
          lbl = "Suspected";
        }
      }
    }
    return (
      <small className="fst-italic text-black-50">
        <span className="fw-bold">{lbl}</span> Condition
      </small>
    );
  }, []);

  const isMissedOpportunity = (activityId) => {
    return missedOpportunities.some((opportunity) => opportunity.id === activityId);
  };

  return sortedActivity?.map((y, idx) => (
    <ActivityWrapper visitActivityType={visitActivityType}>
      <div key={`visitActivity-${y.displayName}-${idx}`}>
        <div className="d-flex align-items-start">
          {subType !== "Immunizations" && (
            <input className="form-check-input opacity-100 me-3" type="checkbox" value="" disabled />
          )}
          <div className="d-flex justify-content-between w-100">
            <div className="w-100">
              <ActivityCell visitActivityType={visitActivityType}>
                <label className="form-check-label d-flex justify-content-between" for="flexCheckDefault">
                  <div>
                    {visitActivityType?.name == "Treatment Planning" && (
                      <i className="far fa-info-circle mt-1 me-2"></i>
                    )}
                    {y?.validation == "REJECTED" && (
                      <i className="fa-regular fa-circle-exclamation text-danger me-1"></i>
                    )}
                    {isMissedOpportunity(y.id) && (
                      <i className="fa-regular fa-hexagon-exclamation text-danger me-1"></i>
                    )}
                    <span className="fw-bold me-1">{y?.displayName}</span>
                    {name === "Documentation And Coding" && (
                      <span className="fw-bold me-1">
                        {y?.abbreviation ? "(" + y?.abbreviation + ")" : ""}
                        {" " + y.gapAndYear}
                      </span>
                    )}
                    {visitActivityType?.name !== "Treatment Planning" && (
                      <small className="fst-italic me-1 nowrap">{`(${y?.currentStatusDisplayName})`}</small>
                    )}
                    {requiredFields.indexOf(y?.id) > -1 && <span className="text-danger">*</span>}
                  </div>
                  {subType === "Immunizations" && (
                    <span id={`${componentName}-visitProgressText-${idx}`} className="fw-bold">
                      {y?.immunizationsDTO?.completionPercent}% Complete
                    </span>
                  )}
                </label>
              </ActivityCell>
              {name === "Documentation And Coding" && getConditionTypeLabel(y)}
              {name === "Medication Review" && (
                <>
                  {extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]?.drugName && (
                    <>
                      <small className="d-block text-black-50">
                        <span className="fw-bold">
                          {
                            extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]
                              ?.drugName
                          }
                        </span>{" "}
                        {extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]?.dosage},
                        <span className="fw-bold text-black-50">
                          {" "}
                          {
                            extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]
                              ?.daysSupply
                          }{" "}
                          Day Supply
                        </span>
                      </small>
                      <small className="d-block textb-lack-50">
                        Last Fill Date:{" "}
                        <span className="fw-bold">
                          {formatDate(
                            extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]
                              ?.fillDate
                          )}
                        </span>
                      </small>
                      <small className="d-block fw-bold text-black-50">
                        Next Expected Fill Date:{" "}
                        {formatDate(
                          extraData?.medicationCareGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]
                            ?.nextFillDate
                        )}
                      </small>
                    </>
                  )}
                </>
              )}
              {name === "Prevention and Screenings" && subType === "Immunizations" && extraData?.immunDoesInfo && (
                <div>
                  {extraData?.immunDoesInfo?.map((x, idx) => (
                    <div
                      id={`${componentName}-immunizationItem-${idx}`}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>
                        <input className="form-check-input me-2" type="checkbox" disabled={true} />
                        <span className="text-black-100">
                          {x?.immunizationDescription ? x?.immunizationDescription : ""}{" "}
                          {x?.immunizationAbbreviation ? `${x?.immunizationAbbreviation}` : ""}
                        </span>
                      </div>
                      <Badge className={"badge bg-light rounded-pill text-dark border-0 text-dark"}>
                        {x?.doesActual}/{x?.totalDoses} Doses
                      </Badge>
                    </div>
                  ))}
                </div>
              )}
              {name === "Prevention and Screenings" && subType !== "Immunizations" && (
                <small>{extraData?.careGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]?.codes}</small>
              )}
              {name === "Needed Referrals" && (
                <small>{extraData?.careGapValues?.filter((f) => f?.measure === y?.displayName)?.[0]?.codes}</small>
              )}
            </div>
            {name === "Documentation And Coding" && (
              <div className="d-flex flex-wrap w-100">
                {["Confirmed", "Pending", "Does Not Have"].map((e) => (
                  <label className="d-flex form-check-label me-3" for="flexCheckDefault">
                    <input className="form-check-input me-2" type="checkbox" value="" checked={false} readOnly />
                    <div className="nowrap">{e}</div>
                  </label>
                ))}
              </div>
            )}
            {name === "Needed Referrals" && (
              <div className="d-flex flex-wrap w-100">
                {["Ordered", "Marked for Closure", "Patient Refused", "Excluded", "Previously Completed"].map((e) => (
                  <label className="d-flex form-check-label me-3" for="flexCheckDefault">
                    <input className="form-check-input me-2" type="checkbox" value="" checked={false} readOnly />
                    <div className="nowrap">{e}</div>
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </ActivityWrapper>
  ));
};

const RecommendedActivities = ({ patient, requiredFields, extraData }) => {
  const [mvDtos, setMvDtos] = useState([]);
  const headers = {
    docandcod: <></>,
    treatandplan: <></>,
    others: <></>,
  };

  useEffect(() => {
    const sortList = ["preventionandscreenings", "documentationandcoding", "medicationreview", "neededreferrals"];
    if (patient && patient.manageVisitActivityTypeDtos && patient.manageVisitActivityTypeDtos.length > 0) {
      let mvActivityTypeDtos = cloneDeep(patient.manageVisitActivityTypeDtos);
      const findIndex = mvActivityTypeDtos.findIndex((a) => a.name === "Treatment Planning");
      if (findIndex !== -1) {
        mvActivityTypeDtos.splice(findIndex, 1);
      }

      if (hasSDOH(mvActivityTypeDtos) && !hasCertainGap(mvActivityTypeDtos, "Needed Referrals")) {
        mvActivityTypeDtos.push({
          name: "Needed Referrals",
          visitActivitySubTypeDtos: [{ visitActivities: [{ disabled: false }] }],
        });
        mvActivityTypeDtos = mvActivityTypeDtos.sort((a, b) => {
          return (
            sortList.indexOf(a.name.replace(/\s/g, "").toLowerCase()) -
            sortList.indexOf(b.name.replace(/\s/g, "").toLowerCase())
          );
        });
        setMvDtos(mvActivityTypeDtos);
      } else {
        mvActivityTypeDtos = mvActivityTypeDtos.sort((a, b) => {
          return (
            sortList.indexOf(a.name.replace(/\s/g, "").toLowerCase()) -
            sortList.indexOf(b.name.replace(/\s/g, "").toLowerCase())
          );
        });
        setMvDtos(mvActivityTypeDtos);
      }
    }
  }, [patient, patient.manageVisitActivityTypeDtos]);

  const hasSDOH = useCallback(
    (manageVisitActivityTypeDtos) => {
      return manageVisitActivityTypeDtos
        ?.flatMap((n) => n.visitActivitySubTypeDtos)
        ?.flatMap((x) => x.visitActivities)
        .some((g) => g.abbreviation);
    },
    [patient]
  );

  const hasCertainGap = useCallback(
    (manageVisitActivityTypeDtos, gap) => {
      return manageVisitActivityTypeDtos
        ?.filter((n) => n.name === gap)
        ?.flatMap((n) => n.visitActivitySubTypeDtos)
        ?.flatMap((x) => x.visitActivities)
        .some((g) => g?.disabled == false);
    },
    [patient]
  );
  return (
    <>
      {mvDtos?.map((e, idx) => {
        const activitiesFiltered = e?.visitActivitySubTypeDtos
          .flat()
          .filter((f) => f?.visitActivities.some((g) => g?.disabled == false));

        const name = {
          TreatmentPlanning: headers.treatandplan,
          DocumentationAndCoding: headers.docandcod,
          others: headers.others,
        };

        return (
          <div
            key={`${componentName}-manageVisitActivity-${e?.name}`}
            id={`${componentName}-manageVisitActivity-${idx}`}
          >
            {activitiesFiltered.length > 0 && (
              <>
                <div className="border-bottom mb-3 pb-3">
                  {e?.name !== "Documentation And Coding" && (
                    <h5 id={`${componentName}-header-${idx}`} className="fs-5 fw-bold mb-1">
                      {e?.name}
                    </h5>
                  )}
                  {e?.name === "Documentation And Coding" && (
                    <h5 id={`${componentName}-header-${idx}`} className="fs-5 fw-bold mb-1">
                      Documentation and Coding
                    </h5>
                  )}
                  {e?.name.replace(/\s+/g, "") in name ? name[e?.name.replace(/\s+/g, "")] : name.others}
                  <VisitActivitySubType
                    name={e?.name}
                    extraData={extraData}
                    subTypes={e?.visitActivitySubTypeDtos}
                    requiredFields={requiredFields}
                    visitActivityType={e}
                    missedOpportunities={patient.missedOpportunities}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default RecommendedActivities;
