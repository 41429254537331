import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from "./../../../components/buttons";

const Timer = (ms, action) => {
    let timer;
    function start() {
        timer = setTimeout(() => action(), ms);
    };
    function stop() {
        clearTimeout(timer);
    }

    return { start, stop };
}

const IdleTimeoutModalForAssessment = ({ showModal, handleSave, handleCancel, displayPopUpAfter }) => {
   const logoutTimer = Timer(displayPopUpAfter * .5, () => {
       logoutTimer.stop();
       handleSave();
   });

    if (showModal) {
        logoutTimer.start();
    }
    return (
        <Modal show={showModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>{`You have been idle for ${displayPopUpAfter / 60 / 1000} Minute.`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you Want To Save Your Work?</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    logoutTimer.stop();
                    handleCancel();
                }}>
                    Cancel
            </Button>
                <Button onClick={() => {
                    logoutTimer.stop();
                    handleSave();
                }}>
                    Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default IdleTimeoutModalForAssessment