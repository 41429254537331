export function getGetters(state) {
  return {
    hospitalEventsList: state.hospitalEventsList,
    hospitalEventsRequest: state.hospitalEventsRequest,
    isNewHospitalEventModalActive: state.isNewHospitalEventModalActive,
    isEditHospitalEventModalActive: state.isEditHospitalEventModalActive,
    hospitalEventToEdit: state.hospitalEventToEdit,
    editHospitalEventRequest: state.editHospitalEventRequest,
    totalSize: state.totalSize
  }
}
