import React, {useEffect, useRef, useState} from "react";
import { Row, Col, Nav, Tab, NavLink } from "react-bootstrap";
import { ValidationComponent } from "../../ManageEvidence/ValidationComponent";
import ManageVisit from "./manageVisit";
import { useManageVisitContext } from "../context/ManageVisitContext/ManageVisit.context";
import PreventiveCareHistory from "../../PreventiveCareHistory";
import { useDispatch, useSelector } from "react-redux";
import { setShowFullHistory, setManageVisitOpenedFromPageTitle } from "../../../../actions/manageVisitActions";
import MissedOpportunities from "../MissedOpportunities";
import { AppPropertiesUtil } from "../../../../utils/localStorageUtil";
import CarePrograms from "../careProgramsMock/carePrograms";
import { useAuth } from "../../../../context/authContext";
import {PatientDetails} from "../../PatientDetails/PatientDetails";
import { ReferralTab } from "../../Referrals";
import { EventTimelineTab } from "../../EventTimeline";
import {CollapsibleNavItem} from "./CollapsibleNavItem";
import { HospitalEventsTab } from "../../HospitalEvents";
import { AssessmentsTab } from "../Assessments";
import { AppointmentsTab } from "../Appointments";
import {NotesTab} from "../../Notes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const ManageVisitComponent = ({ setDataResponse, patient, renderValidationData, show, setShow, tocPkg, selectedSuspects, handleReferralModal, navigationParams, validationPkgResetKey, dataResponse,
  componentName, handlePkgChange, authorizedGapWrite,
  validatePatientEvidence, authorizedGapRead,
  authorizedGapValidate,
  authorizedRafValidate, careOpportunityPkg, processValidationPkgAddToSelecedItemParamsKey,
  enableButton, temporarilyDisableSubmitButton, featureFlagCIS }) => {
  const manageVisitState = useManageVisitContext();
  const openedFromPageTitle = useSelector((state) => state?.manageVisitReducer?.openedFromPageTitle);
  const listType = useSelector((state) => state?.manageVisitReducer?.listType);

  const showFullHistory = useSelector((state) => state?.manageVisitReducer?.showFullHistory);
  const dispatch = useDispatch();
  const missedOpportunities = manageVisitState?.patient_get?.missedOpportunities || [];
  const contracted = manageVisitState?.patient_get?.contracted;

  const auth = useAuth();
  const authorizedForViewPatientDetails = auth.hasAnyAuthority(["AUTH_PATIENT_DETAILS_READ_ACCESS", "AUTH_PATIENT_DETAILS_WRITE_ACCESS"]);
  const authorizedForViewPDReferrals = auth.hasAnyAuthority(["AUTH_PD_REFERRALS_WRITE_ACCESS"]);
  const authorizedForViewPDEventTimeline = auth.hasAnyAuthority(["AUTH_PD_EVENT_TIMELINE_WRITE_ACCESS"]);
  const authorizedForViewPDHospitalEvents = auth.hasAnyAuthority(["AUTH_PD_HOSPITAL_EVENTS_READ_ACCESS", "AUTH_PD_HOSPITAL_EVENTS_WRITE_ACCESS"]);
  const authorizedForViewPDAssessments = auth.hasAnyAuthority(["AUTH_PD_ASSESSMENTS_READ_ACCESS", "AUTH_PD_ASSESSMENTS_WRITE_ACCESS"]);
  const authorizedForViewPDAppointments = auth.hasAnyAuthority(["AUTH_PD_APPOINTMENTS_READ_ACCESS", "AUTH_PD_APPOINTMENTS_WRITE_ACCESS"]);
  const authorizedForViewPDNotes = auth.hasAnyAuthority(["AUTH_PD_NOTES_READ_ACCESS", "AUTH_PD_NOTES_WRITE_ACCESS"]);
  const authorizedForViewPDCareProgram = auth.hasAnyAuthority(["AUTH_PD_CARE_PROGRAMS_READ_ACCESS", "AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS"]);
  const [selectedGaps, setSelectedGaps] = useState(null);
  const [userSelectedCareOppHistoryIds, setUserSelectedCareOppHistoryIds] = useState([]);
  const location = useLocation();

  const [tabKey, setTabKey] = useState('');

  useEffect(() => {
    if (renderValidationData) {
      findLatestGapAndSusPect()
    }
  }, [renderValidationData]);

  useEffect(() => {
    if (patient?.id) {
      manageVisitState.persistPatientObject(patient?.firstName, patient?.lastName, patient?.fullName );
    }

  }, [patient?.id]);

  useEffect(() => {
    setTimeout(() => {
      setTabKey(getTabCondition());
    }, 500);
  }, [location, openedFromPageTitle]);

  const [opportunities, setOpportunities] = useState({});
  const [visitDate, setVisitDate] = useState("");
  const [reachedBottom, setReachedBottom] = useState(false);
  const listInnerRef = useRef();

  const findLatestGapAndSusPect = () => {
    if (patient?.id) {
      manageVisitState.findPatientPost(patient.id);
      manageVisitState.findPatientGet(patient.id);
    }
  }

  useEffect(() => {
    dispatch(setShowFullHistory(false))
  }, [tabKey])

  useEffect(() => {
    setDataResponse(manageVisitState.patient_post);
    setVisitDate(manageVisitState.patient_post?.visitDate);
  }, [manageVisitState.patient_post])

  useEffect(() => {
    if (!show) {
      dispatch(setManageVisitOpenedFromPageTitle(null))
    }
  }, [show])

  const handleUserSelectedCareOppHistoryIdsChange = (id) => {
    let userSelectedCareOppHistoryIdsClone = [...userSelectedCareOppHistoryIds];
    if (userSelectedCareOppHistoryIdsClone.includes(id)) {
      userSelectedCareOppHistoryIdsClone =
          userSelectedCareOppHistoryIdsClone.filter((x) => x !== id);
    } else {
      userSelectedCareOppHistoryIdsClone.push(id);
    }
    setUserSelectedCareOppHistoryIds(userSelectedCareOppHistoryIdsClone);
    setSelectedGaps(null);
  };


  const getCareOppHistoryIds = () => {
    const s = new Set();
    if (showFullHistory) {
      s.add(selectedGaps);
      careOpportunityPkg.additionalGapIdsSelected.forEach((x) => s.add(x));
      tocPkg.selectedGaps.forEach((x) => s.add(x.careGapSummary.id));
      tocPkg.additionalGapIdsSelected.forEach((x) => s.add(x));
      userSelectedCareOppHistoryIds.forEach((x) => s.add(x));
        }
    return s;
  };

  const careOppHistoryIds = getCareOppHistoryIds();

  useEffect(() => {
    if ((tabKey === 'manage' || tabKey === 'missedOpportunities')) {
      if (openedFromPageTitle === 'Missed Opportunities') {
        switch (listType) {
          case 'rejected':
            setTabKey('manage');
            break;
          case 'requiredActivities':
          case 'awaitingChart':
            setTabKey('missedOpportunities');
            break;
          default:
            setTabKey(tabKey);
            break;
        }
      } else {
        setTabKey(tabKey);
      }
    } else {
      setTabKey(tabKey);
    }
  }, [openedFromPageTitle, listType, missedOpportunities]);

  useEffect(() => {
    const listInnerElement = listInnerRef.current;
    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  const handleOffCanvas = () => {
    setTabKey(getTabCondition());
  }

  useEffect(() => {
    const offcanvasElement = document.getElementById('patientDetailsModal');
    offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffCanvas(false));
    offcanvasElement.addEventListener('shown.bs.offcanvas', () => handleOffCanvas(true));
    return () => {
        offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffCanvas);
        offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffCanvas);
    };
  }, []);

  useEffect(() => {
    if (listInnerRef.current) {
      listInnerRef.current.scrollTop = 0;
    }
    setReachedBottom(false);
  }, [tabKey])

  const onScroll = () => {
    if (listInnerRef.current) {
      const OFFSET = 100;
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - OFFSET;

      if (isNearBottom !== reachedBottom && scrollTop > OFFSET) {
        setReachedBottom(isNearBottom)
      }
    }
  };

  const getTabCondition = () => {
    if (
      window.location.pathname.includes('my_members') &&
      authorizedForViewPatientDetails &&
      openedFromPageTitle !== 'CMWorklist_update'
    ) {
      return 'patientDetailsView'
    } else if (openedFromPageTitle === 'Missed Opportunities' &&
      (listType === 'requiredActivities' ||  listType === 'awaitingChart' )) {
      return 'missedOpportunities'
    } else {
      return 'manage'
    }
  }

  return (
    <Tab.Container
      id="patientDetailsTabs"
      activeKey={tabKey}
      onSelect={key => {
        if (key !== sessionStorage.getItem('redirectTab')) {
          sessionStorage.removeItem('redirectTab');
          sessionStorage.removeItem('itemID');
        }
        setTabKey(key)
      }}
    >
      <Row className="h-100 mw-100 m-0">
        <Col sm={3} className="bg-white border-end p-3">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="manage">
                Manage Visit
              </Nav.Link>
              {missedOpportunities.length > 0 &&
                <Nav.Link eventKey="missedOpportunities" className="pe-2 text-end">
                  Missed Opportunities
                </Nav.Link>
              }
              {(authorizedGapValidate || authorizedRafValidate) &&
                <Nav.Link eventKey="validation">
                  Validation
                </Nav.Link>
              }
            </Nav.Item>

            {show &&
              <CollapsibleNavItem
                title="Full Profile"
                onOpen={(isOpen) => {
                  isOpen && setTabKey(getTabCondition())
                }}
                eventKeySelected={tabKey}
                defaultOpen={true}
              >
              {
                authorizedForViewPatientDetails &&
                <Nav.Link eventKey="patientDetailsView">
                  Patient Details
                </Nav.Link>
              }
              {
                authorizedForViewPDEventTimeline &&
                <NavLink eventKey="eventTimeline">
                  Event Timeline
                </NavLink>
              }
              {
                authorizedForViewPDHospitalEvents &&
                <NavLink eventKey="hospitalEvents">
                  Hospital Events
                </NavLink>
              }
              {
                authorizedForViewPDAppointments &&
                <NavLink eventKey="appointments">
                  Appointments
                </NavLink>
              }
              { authorizedForViewPDAssessments &&
                <NavLink eventKey="assessments">
                  Assessments
                </NavLink>
              }
              {
                authorizedForViewPDReferrals &&
                <NavLink eventKey="referrals">
                  Referrals
                </NavLink>
              }
              {authorizedForViewPDCareProgram && contracted? <Nav.Link eventKey="careprograms">
                Care Programs
              </Nav.Link>:""}
              {
                authorizedForViewPDNotes &&
                <NavLink eventKey="notes">
                  Notes
                </NavLink>
              }
            </CollapsibleNavItem>
            }

          </Nav>
        </Col>
        <Col className="bg-light p-3 h-100 overflow-y-auto tabs-wrapper" ref={listInnerRef}>
          <PreventiveCareHistory
            allOpportunitiesForHist={manageVisitState?.patient_post?.careGapsResponse?.careGapsHistory || []}
            allSuspectsForHist={manageVisitState?.patient_post?.conditionsResponse?.conditionsHistory || []}
            showFullHistory={showFullHistory}
            setShowFullHistory={payload => dispatch(setShowFullHistory(payload))}
            isLoading={manageVisitState.loading_post}
            careOppHistoryIds={careOppHistoryIds}
            handleUserSelectedCareOppHistoryIdsChange={handleUserSelectedCareOppHistoryIdsChange}
            setUserSelectedCareOppHistoryIds={setUserSelectedCareOppHistoryIds}
            setSelectedGaps={setSelectedGaps}
            featureFlagCIS={featureFlagCIS}
          />
          <Tab.Content className="pt-0">
            <Tab.Pane eventKey="manage">
              {show && <ManageVisit
                patient={patient}
                handleReferralModal={handleReferralModal}
                setPatientDetailsShow={setShow}
                setShowFullHistory={setShowFullHistory}
                setSelectedGaps={setSelectedGaps}
                featureFlag={featureFlagCIS}
              />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="missedOpportunities">
              {show && <MissedOpportunities
                patient={patient}
                setPatientDetailsShow={setShow}
                listType={listType}
              />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="validation">
              {(show && (authorizedGapValidate || authorizedRafValidate)) && (
                <ValidationComponent
                  dateOfServiceOverride={visitDate}
                  addToSelecedItemParams={navigationParams}
                  accordionKey="5" //Old code skipped 2 and had validation as 5
                  resetKeyProp={validationPkgResetKey}
                  opportunities={opportunities}
                  isSearching={manageVisitState.loading_post}
                  componentName={componentName}
                  onChange={(x) => handlePkgChange(x, "validationPkg")}
                  authorizedGapWrite={authorizedGapWrite}
                  validatePatientEvidence={validatePatientEvidence}
                  authorizedGapRead={authorizedGapRead}
                  authorizedGapValidate={authorizedGapValidate}
                  authorizedRafValidate={authorizedRafValidate}
                  activeKey="1"
                  patient={patient}
                  disableCheckList={
                    careOpportunityPkg.selectedGaps?.length ||
                      tocPkg.selectedGaps?.length ||
                      selectedSuspects.ids?.length
                      ? true
                      : false
                  }
                  processAddToSelecedItemParamsKeyProp={
                    processValidationPkgAddToSelecedItemParamsKey
                  }
                  enableButton={enableButton}
                  temporarilyDisableSubmitButton={temporarilyDisableSubmitButton}
                  show={show}
                  setShow={setShow}
                />
              )}
            </Tab.Pane>
            {authorizedForViewPDCareProgram && contracted? <Tab.Pane eventKey="careprograms">
              {tabKey === 'careprograms' && <CarePrograms patient={patient} selectedTab={show}/>}
            </Tab.Pane>:""
            }
            <Tab.Pane eventKey="patientDetailsView">
              { tabKey === 'patientDetailsView' && <PatientDetails patient={patient}/> }
            </Tab.Pane>
            <Tab.Pane eventKey="referrals">
            { tabKey ==='referrals' && <ReferralTab patient={patient}/> }
            </Tab.Pane>
            <Tab.Pane eventKey="eventTimeline">
              { tabKey ==='eventTimeline' && <EventTimelineTab
                patient={patient}
                setReachedBottom={setReachedBottom}
                reachedBottom={reachedBottom}
              />}
            </Tab.Pane>
            <Tab.Pane eventKey="hospitalEvents">
              { tabKey ==='hospitalEvents' && <HospitalEventsTab patient={patient} />}
            </Tab.Pane>
            <Tab.Pane eventKey="appointments">
              { tabKey ==='appointments' && <AppointmentsTab patient={patient} />}
            </Tab.Pane>
            <Tab.Pane eventKey="assessments">
              { tabKey ==='assessments' && <AssessmentsTab patient={patient} />}
            </Tab.Pane>
            <Tab.Pane eventKey="notes">
              { tabKey ==='notes' && <NotesTab
                patient={patient}
                setReachedBottom={setReachedBottom}
                reachedBottom={reachedBottom}
              />}
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
};
export default ManageVisitComponent;