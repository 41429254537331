import { actions } from "./actions";

export function getSetters(dispatch) {
  return {
    setHospitalEventsList: payload => dispatch({ type: actions.SET_HOSPITAL_EVENTS_LIST, payload: payload }),
    setHospitalEventsRequest: payload => dispatch({ type: actions.SET_HOSPITAL_EVENTS_REQUEST, payload: payload }),
    setAddHospitalEventRequest: payload => dispatch({ type: actions.SET_ADD_HOSPITAL_EVENT_REQUEST, payload: payload}),
    setHospitalEventToEdit: payload => dispatch({ type: actions.SET_HOSPITAL_EVENT_TO_EDIT, payload: payload}),
    setEditHospitalEventRequest: payload => dispatch({ type: actions.SET_EDIT_HOSPITAL_EVENT_REQUEST, payload: payload}),
    setTotalSize: payload => dispatch({ type: actions.SET_TOTAL_SIZE, payload: payload })
  }
}
