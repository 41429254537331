import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAuth } from "../../../context/authContext";
import {
    authorizedGetMessage,
    closeMessage,
    getMessage,
    setMessage
} from '../../../actions/broadcastMessengerActions';

import {
    MessageOutline,
    MessageOuterWrapper,
    MessageInnerWrapper,
    MessageOuterContainer,
    BroadcastMessage,
    MessageInnerContainer,
    LabelContainer,
    Label,
    Spacing,
    ExitWrapper,
    ExitBtn,
    ExitBtnWrap,
    ExitIcon,
    ExitSpace
} from '../../../components/messageStyles';

const Message = () => {
    const auth = useAuth();
    const token = auth.getAuthToken();
    const dispatch = useDispatch();
    const { message } = useSelector((state) => state.broadcastMessenger);

    const [hidden, setHidden] = useState(true);
    const [msg, setMsg] = useState(null);
    const [count, setCount] = useState(0);
    const [difference, setDifference] = useState(0);

    useEffect(() => {
        (async () => {
            const result = await checkForMessage();
            if (message && result.expires !== message.expires) {
                await dispatch(setMessage(result));
            }
            if ((msg === null && result) || (msg !== null && result.expires !== msg.expires)) {
                setMsg(result);
                setHidden(false);
                const current = new Date();
                const exp = new Date(result.expires);
                const diff = (current - exp);
                setDifference(diff);
            }
        })();
        setTimeout(() => setCount(count + 1), 60000);
    }, [message, count]);

    const hideMessage = async () => {
        if (auth.isAuthenticated()) {
            await closeMessage();
            setHidden(true);
        } else {
            setHidden(true);
        }
    }

    const checkForMessage = async () => {
        if (auth.isAuthenticated()) {
            return await authorizedGetMessage(token);
        } else {
            return await getMessage();
        }
    }

    if (difference > 0) {
        setTimeout(setHidden(true), difference);
    }

    return (
        <>
            {difference <= 0 && !hidden ? (
                <MessageOutline>
                    <MessageOuterWrapper>
                        <MessageInnerWrapper>
                            <MessageOuterContainer>
                                <BroadcastMessage broadcast={msg}>
                                    <MessageInnerContainer>
                                        <LabelContainer>
                                            <Label>{msg.message}</Label>
                                        </LabelContainer>
                                        <Spacing></Spacing>
                                        <ExitWrapper>
                                            <ExitBtn>
                                                <ExitBtnWrap onClick={hideMessage}>
                                                    <ExitIcon>X</ExitIcon>
                                                    <ExitSpace></ExitSpace>
                                                </ExitBtnWrap>
                                            </ExitBtn>
                                        </ExitWrapper>
                                    </MessageInnerContainer>
                                </BroadcastMessage>
                            </MessageOuterContainer>
                        </MessageInnerWrapper>
                    </MessageOuterWrapper>
                </MessageOutline>
            ) : null}
        </>
    );
}

export default Message;