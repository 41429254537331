import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import styled, { css } from "styled-components";
import { Line } from "../../components/Line";
import Form from "react-bootstrap/Form";
import { Button as EQHButton } from "../../components/buttons";
import {
  DropDownList,
  DDLDataMode,
  DDLMode,
  GENERIC_DDL_TYPE,
} from "../../components/dropDownLists/genericDDL";
import {
  FilterParam,
  simpleFilterParam,
  simpleOneFilterParam,
} from "../../dtos/PatientSearchDTO";
import {
  getStates,
  getLanguages,
  getRelationships,
  stateAbbrReverseMap,
} from "../../actions/constantsActions";
import {
  updatePatientProfileOverview,
  getCareProviderPartialNameSearch,
} from "../../actions/patientProfileActions";
import Modal from "react-bootstrap/Modal";
import { MultiComponent } from "../../components/MultiComponent";
import {formatPhoneNumber, isObjEmpty, isValidPhoneNumber} from "../../utils/util";
import EQHErrorToast from "../../components/EQHErrorToast";
import _ from "lodash";
import { Card } from "react-bootstrap";
import { useAuth } from "../../context/authContext";
import axios from "../../utils/apiClient";
import { Filter } from "../../components/filters/filterGroup";
import {formatDate, formatLocalDate, formatDateTime, formatTimeOnly} from "../../utils/util";
import {EQHDatePicker} from "../../components/datePicker";
import { format } from "date-fns";
import { expiredSources } from "../../utils/util";

import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import { emptyFilterParam } from "../../dtos/PatientSearchDTO";

const componentName = "EditPatientProfileModal";

const emailRegex = `^\\S+@\\S+$`;
const phoneRegex = `^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$`;
const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/file-upload";
const isEmpty = require("is-empty");

const STATE_FD = (valueAsAbbr, textAsAbbr, valueAllCaps, textAllCaps) => {
  return {
    type: GENERIC_DDL_TYPE,
    getHeader: () => "State",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.OneAPICallFilterInUI,
      showSearchTextbox: false,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (text) =>
        getStates([valueAsAbbr, textAsAbbr, valueAllCaps, textAllCaps]).then(
          (res) => res.data
        ),
    },
  };
};

const CARE_PROV_FD = (header, patientId) => {
  return {
    type: GENERIC_DDL_TYPE,
    getHeader: () => header,
    key: "Care_Provider",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      showSearchTextbox: true,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (srchTxt) =>
        getCareProviderPartialNameSearch([patientId, srchTxt]).then((res) =>
          res.data.map((careProvider) => {
            return {
              text: careProvider.name,
              value: careProvider,
            };
          })
        ),
    },
  };
};

const EditPatientModal = ({ patientId, patientOverviewPkg, showProp, handleOnClose, refreshRecentActivityPkg, setShowRecentActivitySpinner, refreshOverview }) => {
  const [show, setShow] = useState(showProp);
  const [errors, setErrors] = useState([]);
  const [patientOverviewClone, setPatientOverviewClone] = useState(() =>
    _.cloneDeep(patientOverviewPkg.state.data)
  );
  const [useSecondaryAddress, setUseSecondaryAddress] = useState(false);
  const [addImportantNote, setAddImportantNote] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const auth = useAuth()

  const cancelExpiredModal = () => {
    setShowExpiredModal(false)
  }

  const closeExpiredModal = () => {
    setShowExpiredModal(false)
    handleChange("expired", true)
  }

  //This is a reset basically since Modal close doesn't unmount
  if (showProp && !show) {
    setShow(true);
    setAddImportantNote(false);
    setUseSecondaryAddress(false);
    setPatientOverviewClone({});
  } else if (showProp !== show) {
    setShow(showProp);
  }

  if (
    !isObjEmpty(patientOverviewPkg.state.data) &&
    isObjEmpty(patientOverviewClone, { lastUpdated: true })
  ) {
    setPatientOverviewClone(_.cloneDeep(patientOverviewPkg.state.data));
    setUseSecondaryAddress(
      !isObjEmpty(patientOverviewPkg.state.data.secondaryAddress)
    );
  }

  const formValidation = (patientOverview) => {
    const errors = {};
    if (useSecondaryAddress) {
      if (patientOverview.secondaryAddress) {
        if (!patientOverview.secondaryAddress.streetAddress) {
          errors["secondaryAddress.streetAddress"] =
            "Secondary Address Street Address Is Required Or Delete Secondary Address. ";
        }
        if (!patientOverview.secondaryAddress.city) {
          errors["secondaryAddress.city"] =
            "Secondary Address City Is Required Or Delete Secondary Address. ";
        }
        if (!patientOverview.secondaryAddress.state) {
          errors["secondaryAddress.state"] =
            "Secondary Address State Is Required Or Delete Secondary Address. ";
        }
        if (!patientOverview.secondaryAddress.zipCode) {
          errors["secondaryAddress.zipCode"] =
            "Secondary Address Zip Code Is Required Or Delete Secondary Address. ";
        } else if(!postcodeValidator(patientOverview.secondaryAddress.zipCode, 'US')){
          errors["secondaryAddress.zipCode.value"] =
            "Invalid format of zip code. Please use NNNNN or NNNNN-NNNN. ";
        }
        if (!patientOverview.secondaryAddress.source) {
          errors["secondaryAddress.source"] =
            "Secondary Address Source Is Required Or Delete Secondary Address. ";
        }
        if (patientOverview.secondaryAddress.source === "OTHER" && !patientOverview.secondaryAddress.otherSource) {
          errors["secondaryAddress.source.otherSource"] =
              "Secondary Address Other Source Is Required Or Delete Secondary Address. ";
        }
      } else {
        errors.secondaryAddress =
          "Please Fill Out Secondary Address Fields Or Delete. ";
      }
    }

    if(!isEmpty(patientOverview.caregiverContact) && !isValidPhoneNumber(patientOverview.caregiverContact)){
      errors["caregiverContact"] =
        "Invalid format of phone number. Please use NNN-NNN-NNNN. ";
    }
    if(!isEmpty(patientOverview.preferredPhone)){
      let wrongPhoneFound = patientOverview.preferredPhone.find((value) => !isValidPhoneNumber(value));
      if(wrongPhoneFound){
        errors["preferredPhone"] = "Invalid format of phone number. Please use NNN-NNN-NNNN. ";
      }
    }
    if(!isEmpty(patientOverview.preferredEmail)){
      let wrongEmailFound = patientOverview.preferredEmail.find((value) => !EmailValidator.validate(value));
      if(wrongEmailFound){
        errors["preferredEmail"] = "Invalid format of email address. ";
      }
    }

    if (addImportantNote && !_.isEmpty(patientOverview.notes)) {
      const lastNote = patientOverview.notes[patientOverview.notes.length - 1];
      if (!lastNote || (lastNote && !lastNote.text))
        errors["new note"] =
          "Please Populate New Note Field Or Delete New Note. ";
    }

    return isObjEmpty(errors) ? null : errors;
  };

  const submit = () => {
    const errors = formValidation(patientOverviewClone);
    if (errors) {
      setErrors(Object.values(errors));
    } else {
      setShowRecentActivitySpinner(true);
      updatePatientProfileOverview(patientOverviewClone)
        .then((res) => {
          patientOverviewPkg.fetchData(patientId);
          patientOverviewPkg.dispatch({
            type: "update",
            payload: patientOverviewClone,
          });
          handleOnClose();
          refreshRecentActivityPkg();
          refreshOverview();
          setShowRecentActivitySpinner(false);
        })
        .catch((err) => {
          setShowRecentActivitySpinner(false);
        });
      handleOnClose();
    }
  };

  const handleChange = (key, value) => {
    let patientOverviewNewObj = { ...patientOverviewClone };
    switch (key) {
      case "firstName":
      case "middleName":
      case "lastName":
      case "memberId":
      case "address":
      case "primaryPhone":
      case "dateOfBirth":
      case "city":
      case "altPhone":
      case "gender":
      case "state":
      case "zipCode":
      case "email":
      case "preferredPhone":
      case "preferredEmail":
      case "nurse":
      case "caregiverFirstName":
      case "payer":
      case "pcp":
      case "caregiverLastName":
      case "insuranceInfo":
      case "specialists":
      case "caregiverContact":
      case "mrn":
      case "healthPlanCareManager":
      case "primaryLanguage":
      case "careCoordinator":
      case "otherLanguages":
      case "preferredStreetAddress":
      case "preferredCity":
      case "preferredState":
      case "preferredZipCode":
      case "secondaryAddress":
      case "expired":
      case "expiredDate":
      case "expiredSource":
        if(key === "caregiverContact") {
          value = formatPhoneNumber(patientOverviewNewObj[key], value);
        }

        patientOverviewNewObj[key] = value;
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      case "secondaryAddress.streetAddress":
      case "secondaryAddress.city":
      case "secondaryAddress.lastUpdated":
      case "secondaryAddress.state":
      case "secondaryAddress.zipCode":
      case "secondaryAddress.source":
      case "secondaryAddress.otherSource":
        if (key === "secondaryAddress.state") {
          value = value.replaceAll(" ", "_"); 
        }
        if (!patientOverviewNewObj.secondaryAddress) {
          //Don't know if we really need this object but existing data has it so...
          patientOverviewNewObj.secondaryAddress = {
            streetAddress: null,
            city: null,
            state: null,
            zipCode: null,
            source: null,
            otherSource: null,
            lastUpdated: null,
          };
        }
        patientOverviewNewObj.secondaryAddress[key.split(".")[1]] = value;
        setPatientOverviewClone(patientOverviewNewObj);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        onHide={handleOnClose}
        show={show}
        size="xl"
        screenbool={true}
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Edit Patient Information</Modal.Title>
          <button id={`${componentName}-ModalClose`} type="button" className="btn-close" aria-label="Close" onClick={handleOnClose}></button>
        </Modal.Header>
        <Modal.Body>
          <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
          {!isObjEmpty(patientOverviewClone) && (
            <Container>
              <Row xs={1} sm={1} md={3} className="mb-3">
                <Col>
                  <Form.Label id={`${componentName}-firstNameLabel`}>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-firstNameValue`}
                    value={patientOverviewClone.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    disabled
                  />
                </Col>
                <Col>
                  <Form.Label id={`${componentName}-middleNameLabel`}>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-middleNameValue`}
                    value={patientOverviewClone.middleName}
                    onChange={(e) => handleChange("middleName", e.target.value)}
                    disabled
                  />
                </Col>
                <Col>
                  <Form.Label id={`${componentName}-lastNameLabel`}>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-lastNameValue`}
                    value={patientOverviewClone.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    disabled
                  />
                </Col>
              </Row>
              <hr />
              <Row xs={1} sm={1} md={3}>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-memberIdLabel`}>Member Id</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-memberIdValue`}
                    value={patientOverviewClone.memberId}
                    onChange={(e) => handleChange("memberId", e.target.value)}
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-addressLabel`}>Address</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-addressValue`}
                    value={patientOverviewClone.streetAddress}
                    onChange={(e) => handleChange("address", e.target.value)}
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-primaryPhoneLabel`}>Primary Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-primaryPhoneValue`}
                    value={patientOverviewClone.primaryPhone}
                    onChange={(e) =>
                      handleChange("primaryPhone", e.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-dobLabel`}>DOB</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-dobValue`}
                    value={formatDate(patientOverviewClone.dateOfBirth)}
                    onChange={(e) =>
                      handleChange("dateOfBirth", e.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-cityLabel`}>City</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-cityValue`}
                    value={patientOverviewClone.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-altPhoneLabel`}>Alt Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-altPhoneValue`}
                    value={patientOverviewClone.altPhone}
                    onChange={(e) => handleChange("altPhone", e.target.value)}
                    disabled
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Label id={`${componentName}-genderLabel`}>Gender</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-genderValue`}
                    value={patientOverviewClone.gender}
                    onChange={(e) => handleChange("gender", e.target.value)}
                    disabled
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Filter
                    filterDefinition={STATE_FD(false, true, true, true)}
                    selected={
                      new FilterParam(
                        "",
                        "",
                        patientOverviewClone.state
                          ? [
                              {
                                value: patientOverviewClone.state,
                                text:
                                  stateAbbrReverseMap[
                                    patientOverviewClone.state
                                  ],
                              },
                            ]
                          : [],
                        (e) => e.value,
                        (e) => e.text
                      )
                    }
                    handleChange={(e) => handleChange("state", e.paramValue[0])}
                    showClearButton={false}
                    disabled
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Form.Label id={`${componentName}-zipLabel`}>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-zipValue`}
                    value={patientOverviewClone.zipCode}
                    onChange={(e) => handleChange("zipCode", e.target.value)}
                    disabled
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Label id={`${componentName}-emailLabel`}>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-emailValue`}
                    value={patientOverviewClone.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    disabled
                  />
                </Col>
              </Row>
              <hr />
              {useSecondaryAddress ? (
                <Fragment>
                  <Row className="mb-2">
                    <Col>
                      <span className="fw-bold">Secondary Address</span>
                    </Col>
                  </Row>
                  <Row xs={1} sm={1} md={3}>
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-secondaryAddressLabel`}>Address<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-secondaryAddressValue`}
                        value={
                          patientOverviewClone.secondaryAddress?.streetAddress
                        }
                        onChange={(e) =>
                          handleChange(
                            "secondaryAddress.streetAddress",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-secondaryCityLabel`}>City<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-secondaryCityValue`}
                        value={patientOverviewClone.secondaryAddress?.city}
                        onChange={(e) =>
                          handleChange("secondaryAddress.city", e.target.value)
                        }
                      />
                    </Col>
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-secondaryLastUpdateLabel`}>Last Updated</Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-secondaryLastUpdateValue`}
                        value={
                          formatDateTime(patientOverviewClone.secondaryAddress?.lastUpdated)
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row xs={1} sm={1} md={3}>
                    <Col md={2} className="mb-3">
                      <Filter
                        filterDefinition={STATE_FD(false, true, true, true)}
                        selected={
                          new FilterParam(
                            "",
                            "",
                            patientOverviewClone?.secondaryAddress?.state
                              ? [
                                  {
                                    value:
                                      patientOverviewClone?.secondaryAddress
                                        ?.state,
                                    text:
                                      stateAbbrReverseMap[
                                        patientOverviewClone?.secondaryAddress
                                          ?.state?.replaceAll("_", " ")
                                      ],
                                  },
                                ]
                              : [],
                            (e) => e.value,
                            (e) => e.text
                          )
                        }
                        handleChange={(e) =>
                          handleChange(
                            "secondaryAddress.state",
                            e.paramValue[0]
                          )
                        }
                        showRequired={true}
                      />
                    </Col>
                    <Col md={2} className="mb-3">
                      <Form.Label id={`${componentName}-secondaryZipLabel`}>Zip<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-secondaryZipValue`}
                        value={patientOverviewClone.secondaryAddress?.zipCode}
                        onChange={(e) =>
                          handleChange(
                            "secondaryAddress.zipCode",
                            e.target.value
                          )
                        }
                      />
                      {!isEmpty(patientOverviewClone.secondaryAddress?.zipCode)
                        && !postcodeValidator(patientOverviewClone.secondaryAddress.zipCode, 'US')
                        && <span className="text-danger">Invalid format of zip code. Please use NNNNN or NNNNN-NNNN. </span>
                      }
                    </Col>
                    <Col className="mb-3">
                      <DropDownList
                        mode={DDLMode.SelectOne}
                        dataMode={DDLDataMode.OneAPICallFilterInUI}
                        header="Source"
                        showRequired={true}
                        handleChange={(e) => {
                          handleChange(
                            "secondaryAddress.source",
                            e.paramValue[0]
                          )
                          handleChange(
                            "secondaryAddress.otherSource",
                            ""
                          );
                        }}
                        selected={
                          new FilterParam(
                            "Source",
                            "eq",
                            patientOverviewClone?.secondaryAddress?.source
                              ? [patientOverviewClone.secondaryAddress.source]
                              : [],
                            (e) => e,
                            (e) => e
                          )
                        }
                        getData={(srchTxt) =>
                          getRelationships().then((res) =>
                            res.data.map((r) => {
                              return {
                                text: r,
                                value: r,
                              };
                            })
                          )
                        }
                        highlightWhenHasValue={false}
                      />
                    </Col>
                    {patientOverviewClone.secondaryAddress.source === "OTHER" ? (
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-otherSourceLabel`}>Other Source<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                          type="text"
                          id={`${componentName}-otherSourceValue`}
                          value={
                            patientOverviewClone.secondaryAddress?.otherSource
                          }
                          onChange={(e) =>
                              handleChange(
                                  "secondaryAddress.otherSource",
                                  e.target.value
                              )
                          }
                      />
                    </Col>
                      ) : null }
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  <span
                    className="cursor-p"
                    onClick={() => {
                      setUseSecondaryAddress(true);
                    }}
                  >
                    <i className="fas fa-plus-circle"></i> Add Secondary Address
                  </span>
                </Fragment>
              )}
              <hr />
              <Row>
                <Col xl={4}></Col>
                <Col xl={4}>
                  <MultiComponent
                    onChange={(values) =>
                      handleChange("preferredPhone", values)
                    }
                    max={5}
                    itemLabel="Preferred Phone"
                    addLabel="Add Preferred Phone"
                    value={isEmpty(patientOverviewClone.preferredPhone) ? [] : patientOverviewClone.preferredPhone}
                    itemBuilder={(value, handleChange) => (
                      <>
                        <Form.Control
                          type="phone"
                          className="mb-3"
                          value={value}
                          onChange={(e) => handleChange(formatPhoneNumber(value, e.target.value))}
                        />
                        {!isEmpty(value) && !isValidPhoneNumber(value) && <span className="text-danger">Invalid format of phone number. Please use NNN-NNN-NNNN. </span>}
                      </>
                    )}
                  />
                </Col>
                <Col xl={4} className="mb-3">
                  <MultiComponent
                    onChange={(values) =>
                      handleChange("preferredEmail", values)
                    }
                    max={5}
                    itemLabel="Preferred Email"
                    addLabel="Add Preferred Email"
                    value={isEmpty(patientOverviewClone.preferredEmail) ? [] : patientOverviewClone.preferredEmail}
                    itemBuilder={(value, handleChange) => (
                      <>
                        <Form.Control
                          type="email"
                          className="mb-3"
                          value={value}
                          onChange={(e) => handleChange(e.target.value)}
                        />
                        {!isEmpty(value) && !EmailValidator.validate(value) && <span className="text-danger">Invalid format of email address. </span>}
                      </>
                    )}
                  />
                </Col>
              </Row>
              <hr />
              <Row xs={1} sm={1} md={3}>
                <Col className="mb-3">
                  <Filter
                    filterDefinition={CARE_PROV_FD(
                      "Nurse",
                      patientOverviewClone.id
                    )}
                    selected={
                      new FilterParam(
                        "",
                        "",
                        patientOverviewClone.nurse
                          ? [patientOverviewClone.nurse]
                          : [],
                        (e) => e,
                        (e) => e.name
                      )
                    }
                    handleChange={(e) => handleChange("nurse", e.paramValue[0])}
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-caregiverFirstNameLabel`}>Caregiver First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-caregiverFirstNameValue`}
                    value={patientOverviewClone.caregiverFirstName}
                    onChange={(e) =>
                      handleChange("caregiverFirstName", e.target.value)
                    }
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-payerLabel`}>Payer</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-payerValue`}
                    value={patientOverviewClone.payer}
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Filter
                    filterDefinition={CARE_PROV_FD(
                      "PCP",
                      patientOverviewClone.id
                    )}
                    selected={
                      new FilterParam(
                        "",
                        "",
                        patientOverviewClone.pcp
                          ? [patientOverviewClone.pcp]
                          : [],
                        (e) => e,
                        (e) => e.name
                      )
                    }
                    handleChange={(e) => handleChange("pcp", e.paramValue[0])}
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-caregiverLastNameLabel`}>Caregiver Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-caregiverLastNameValue`}
                    value={patientOverviewClone.caregiverLastName}
                    onChange={(e) =>
                      handleChange("caregiverLastName", e.target.value)
                    }
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-insuranceInfoLabel`}>Insurance Info</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-insuranceInfoValue`}
                    value={patientOverviewClone.insuranceInfo}
                    onChange={(e) =>
                      handleChange("insuranceInfo", e.target.value)
                    }
                  />
                </Col>
                <Col sm={4} md={4}>
                  <Col>
                    <MultiComponent
                      itemLabel="Specialist"
                      addLabel="Add Specialist"
                      defaultAddValue={emptyFilterParam()}
                      onChange={(values) => handleChange("specialists", values.map(x => x.paramValue.length ? x.paramValue[0] : null))}
                      value={
                        patientOverviewClone.specialists
                          ? patientOverviewClone.specialists.map(
                              (x) =>
                                x ?
                                new FilterParam(
                                  "",
                                  "",
                                  [x],
                                  (e) => e,
                                  (e) => e.name
                                ) : 
                                emptyFilterParam()
                            )
                          : []
                      }
                      itemBuilder={(value, handleChange) => (
                        <Filter
                          filterDefinition={CARE_PROV_FD(
                            "",
                            patientOverviewClone.id
                          )}
                          selected={value}
                          handleChange={(e) => handleChange(e)}
                          showClearButton={false}
                        />
                      )}
                    />
                  </Col>
                  <Col className="mb-3">
                    <Modal
                      onHide={cancelExpiredModal}
                      show={showExpiredModal}
                      size="sm"
                      centered
                      scrollable
                    >
                      <Modal.Header>
                        <Modal.Title>Expired Confirmation</Modal.Title>
                        <button id={`${componentName}-ModalClose`} type="button" className="btn-close" aria-label="Close" onClick={cancelExpiredModal}></button>
                      </Modal.Header>
                      <Modal.Body>
                        I have verified that this patient is deceased.
                      </Modal.Body>
                      <Modal.Footer>
                        <Button id={`${componentName}-closeExpired`} onClick={cancelExpiredModal} variant="secondary">Cancel</Button>
                        <Button id={`${componentName}-okExpired`} variant="primary" onClick={closeExpiredModal}>Ok</Button>
                      </Modal.Footer>
                    </Modal>
                    <Form.Check type="checkbox" id={`${componentName}-patientDeceased`} className="mt-3">
                      <Form.Check.Input
                        type="checkbox"
                        id={`${componentName}-patientDeceasedCheck`}
                        value={patientOverviewClone.expired}
                        checked={patientOverviewClone.expired}
                        disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                        onChange={(e) => {
                          if(e.target.checked) {
                            setShowExpiredModal(true)
                          } else {
                            handleChange("expired", false)
                          }
                        }}
                      />
                      <Form.Check.Label id={`${componentName}-patientDeceasedLabel`}>Patient is deceased</Form.Check.Label>
                    </Form.Check>
                  </Col>
                  {patientOverviewClone.expired ? 
                  <Col className="mb-3">
                    <Form.Label id={`${componentName}-dateExpiredLabel`}>Date Expired</Form.Label>
                    <EQHDatePicker
                        id={`${componentName}-dateExpiredValue`}
                        value={patientOverviewClone.expiredDate}
                        onChange={(e) => handleChange("expiredDate", e.strDate)}
                        max={format(new Date(), "yyyy-MM-dd")}
                        disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                    />
                  </Col>
                  : null}
                  {patientOverviewClone.expired ?
                  <Col className="mb-3">
                    <Form.Label id={`${componentName}-confirmationSourceLabel`}>Confirmation Source</Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        className="mt-1"
                        id={`${componentName}-confirmationSourceValue`}
                        onChange={(e) => handleChange("expiredSource", e.target.value)}
                        value={patientOverviewClone.expiredSource}
                        disabled={!auth.hasAuthority("AUTH_PATIENT_EXPIRE_ACCESS")}
                    >
                      <option value="Sources" selected>-Select-</option>
                      {expiredSources.map((source, idx) => (
                        <option value={source.value}>{source.value}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  : null}
                </Col>
                <Col sm={8} md={8}>
                  <Row xs={1} sm={1} md={2}>
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-caregiversContactLabel`}>Caregiver's Contact</Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-caregiversContactValue`}
                        value={patientOverviewClone.caregiverContact}
                        onChange={(e) =>
                          handleChange("caregiverContact", e.target.value)
                        }
                      />
                      {!isEmpty(patientOverviewClone.caregiverContact) && !isValidPhoneNumber(patientOverviewClone.caregiverContact) && <span className="text-danger">Invalid format of phone number. Please use NNN-NNN-NNNN. </span>}
                    </Col>
                    <Col className="mb-3">
                      <Form.Label id={`${componentName}-mrnLabel`}>MRN</Form.Label>
                      <Form.Control
                        type="text"
                        id={`${componentName}-mrnValue`}
                        value={patientOverviewClone.mrn}
                        onChange={(e) => handleChange("mrn", e.target.value)}
                      />
                    </Col>
                    <Col className="mb-3">
                      <Filter
                        filterDefinition={CARE_PROV_FD(
                          "Health Plan Manager",
                          patientOverviewClone.id
                        )}
                        selected={
                          new FilterParam(
                            "",
                            "",
                            patientOverviewClone.healthPlanCareManager
                              ? [patientOverviewClone.healthPlanCareManager]
                              : [],
                            (e) => e,
                            (e) => e.name
                          )
                        }
                        handleChange={(e) =>
                          handleChange("healthPlanCareManager", e.paramValue[0])
                        }
                      />
                    </Col>
                    <Col>
                      <DropDownList
                        mode={DDLMode.SelectOne}
                        dataMode={DDLDataMode.OneAPICallFilterInUI}
                        header="Primary Language"
                        handleChange={(e) =>
                          handleChange("primaryLanguage", e.paramValue[0])
                        }
                        selected={
                          new FilterParam(
                            "Primary Language",
                            "eq",
                            patientOverviewClone.primaryLanguage
                              ? [patientOverviewClone.primaryLanguage]
                              : [],
                            (e) => e,
                            (e) => e
                          )
                        }
                        getData={(srchTxt) =>
                          getLanguages().then((res) =>
                            res.data.map((l) => {
                              return {
                                text: l,
                                value: l,
                              };
                            })
                          )
                        }
                        highlightWhenHasValue={false}
                      />
                    </Col>
                    <Col className="mb-3">
                      <Filter
                        filterDefinition={CARE_PROV_FD(
                          "Care Coordinator",
                          patientOverviewClone.id
                        )}
                        selected={
                          new FilterParam(
                            "",
                            "",
                            patientOverviewClone.careCoordinator
                              ? [patientOverviewClone.careCoordinator]
                              : [],
                            (e) => e,
                            (e) => e.name
                          )
                        }
                        handleChange={(e) =>
                          handleChange("careCoordinator", e.paramValue[0])
                        }
                      />
                    </Col>
                    <Col className="edit-otherlang">
                      <MultiComponent
                        itemLabel="Other Language"
                        addLabel="Add Language"
                        defaultAddValue={emptyFilterParam()}
                        onChange={(values) =>
                          handleChange("otherLanguages", values.map(x => x.paramValue.length ? x.paramValue[0] : null))}
                          value={
                          patientOverviewClone.otherLanguages
                            ? patientOverviewClone.otherLanguages.map((x) =>
                                x ? 
                                simpleFilterParam("", "eq", x)
                                : emptyFilterParam()
                              )
                            : []
                        }
                        itemBuilder={(value, handleChange) => (
                          <Filter
                            filterDefinition={{
                              type: GENERIC_DDL_TYPE,
                              getHeader: () => "",
                              componentProps: {
                                mode: DDLMode.SelectOne,
                                dataMode: DDLDataMode.OneAPICallFilterInUI,
                                getData: (srchTxt) =>
                                  getLanguages().then((res) =>
                                    res.data.map((l) => {
                                      return {
                                        text: l,
                                        value: l,
                                      };
                                    })
                                  ),
                                showClearButton: false,
                                highlightWhenHasValue: false,
                                selected: value,
                                handleChange: (e) =>
                                  handleChange(e),
                              },
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row xs={1} sm={1} md={3}>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-preferredAddressLabel`}>Preferred Address</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-preferredAddressValue`}
                    value={patientOverviewClone.preferredStreetAddress}
                    onChange={(e) =>
                      handleChange("preferredStreetAddress", e.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col className="mb-3">
                  <Form.Label id={`${componentName}-preferredCityLabel`}>Preferred City</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-preferredCityValue`}
                    value={patientOverviewClone.preferredCity}
                    onChange={(e) =>
                      handleChange("preferredCity", e.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col md={2} className="mb-3">
                <Filter
                    filterDefinition={STATE_FD(false, true, true, true)}
                    selected={
                      new FilterParam(
                        "",
                        "",
                        patientOverviewClone.preferredState
                          ? [
                              {
                                value: patientOverviewClone.preferredState,
                                text:
                                  stateAbbrReverseMap[
                                    patientOverviewClone.preferredState
                                  ],
                              },
                            ]
                          : [],
                        (e) => e.value,
                        (e) => e.text
                      )
                    }
                    handleChange={(e) => handleChange("preferredState", e.paramValue[0])}
                    disabled
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <Form.Label id={`${componentName}-preferredZipLabel`} className="nowrap">
                    Preferred Zip
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-preferredZipValue`}
                    value={patientOverviewClone.preferredZipCode}
                    onChange={(e) =>
                      handleChange("preferredZipCode", e.target.value)
                    }
                    disabled
                  />
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" id={`${componentName}-closePatientEdit`} onClick={handleOnClose}>Close</Button>
          <Button id={`${componentName}-submitPatientEdit`} onClick={submit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { EditPatientModal };
