import React from 'react';
import { Accordion, Col } from "react-bootstrap";

export default function CustomAccordion({children}) {
    return (
        <Col sm={12} md={8}>
            <Accordion className="mb-3 border-0">
                {children}
            </Accordion>
        </Col>
    )
}
