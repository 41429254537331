import isEmpty from "is-empty";
import {neededServices} from "../../../utils/textValueLists";

export const mapReferralServicesToText = (referralServicesNeeded) => {
    if (isEmpty(referralServicesNeeded)) {
        return '-';
    }

    const servicesNames = referralServicesNeeded.map(service => {
        const serviceFound = neededServices.find(serviceEnum => serviceEnum.value.includes(service.service));
        if (serviceFound) {
            return serviceFound.text;
        }
        return "";
    }).join(", ");
    return servicesNames;
};