import React, { useEffect, useState } from "react";

import { theme } from "../../components/themes/theme1";
import { ThemeProvider } from "styled-components";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
    Row,
    Container,
    Col,
    Button,
    Modal,
    Form,
    Table
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import axios from "../../utils/apiClient";

import {
    createProgramLead,
    getProgramLead,
    getTeams,
    createTeam,
    updateTeam,
    getStatus, updateProgramLead
} from "../../actions/caremgmt/careAdminAction";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { createContext } from 'react';
import SectionAdmin from "../../components/SectionAdmin";
import { TeamsBodyList, TeamsModalBody } from "./careAdmin/TeamsSections";
import EQHErrorToast from "../../components/EQHErrorToast";
import CustomPagination from "../common/CustomPaginaton";
import { CustomAccordion, AccordionItem, DropDownList } from "./careAdmin/DropDownLists/components";
import { dropdowns } from "./careAdmin/DropDownLists";
import { Helmet } from 'react-helmet';
import {fetchDropDownFiltered, fetchDropDownZones} from "./careAdmin/DropDownLists/actions";
import {ZoneManager} from "./careAdmin/zone/ZoneManager";
import {DropDownList as ZoneDropDownList} from "../../components/dropDownLists/genericDDL"

export const SectionContext = createContext({});

const componentName = "CareAdmin";

export const CareAdmin = () => {

    const isEmpty = require("is-empty");

    const [programLead, setProgramLead] = useState({});

    const [showTeams, setShowTeams] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [selectedZones, setSelectedZones] = useState({});
    const [isEditingTeam, setIsEditingTeam] = useState(false);
    const [isLoadingTeams, setIsLoadingTeams] = useState(false);
    const [statusList, setStatusList] = useState([]);

    const [isEditingMember, setIsEditingMember] = useState(false);
    const [showMember, setShowMember] = useState(false);
    const [members, setMembers] = useState([]);
    const [pagination, setPagination] = useState({ pageNumber: 0, pageSize: 0, totalSize: 0 });

    const [errors, setErrors] = useState([]);
    const [zones, setZones] = useState([]);
    const [markets, setMarkets] = useState([]);


    const handleCloseMember = () => {
        setShowMember(false);
        setProgramLead({});
        setSelectedZones({});
        setErrors([]);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const contextValue = {
        teams,
        selectedTeam,
        setSelectedTeam,
        setIsEditingTeam,
        setShowTeams,
        isLoadingTeams,
        members,
        handleShowMember,
        errors,
        setErrors
    };

    const handleMemberNameChange = (e) => {
        if (e && e[0]) {
            const value = e[0];
            setProgramLead({
                ...programLead,
                "name": value.text,
                "userAccountId": value.value
            });
        }
    }
    const asyncsearch = (query) => {
        const timeout = setTimeout(() => {
            if (query) {
                handleSearch(query);
            }
        }, 1600);

        return () => clearTimeout(timeout);
    };

    const handleSearch = (query) => {
        const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management";
        if (query) {
            const url =
                baseURL +
                "/users" +
                (query ? "?text=" + query : "");
            setIsLoading(true);

            axios
                .get(url, {
                    headers: {},
                })
                .then((resp) => {
                    if (resp && resp.data) {
                        setOptions(resp.data);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    onError(error);
                });
        }
    };

    const setLoading = () => {
        setIsLoadingTeams(true);
        setTimeout(() => setIsLoadingTeams(false), 500)
    }

    const onError = (err) => {
        if (typeof err === 'string') {
            setErrors([err]);
        } else if (Array.isArray(err)) {
            setErrors([err.join(', ')]);
        } else {
            const { response } = err;
            if (response && response.data) {
                setErrors([response.data.message]);
            }
        }
    }

    const handleSaveTeam = () => {
        if (validateTeam(selectedTeam)) {
            setLoading();
            if (isEditingTeam) {
                updateTeam(selectedTeam).then(res => {
                    if (res && res.data) {
                        const index = teams.findIndex((t => t.teamId === selectedTeam.teamId));
                        teams[index] = res.data;
                        setTeams(teams);
                        fetchProgramLeads();
                    }
                }).catch((err) => {
                    onError(err);
                });
            } else {
                createTeam(selectedTeam).then(res => {
                    if (res && res.data) {
                        setTeams([...teams, res.data]);
                    }
                }).catch((err) => {
                    onError(err);
                });
            }
            setShowTeams(false);
            setErrors([]);
        }
    }

    const validateTeam = (teamObj) => {
        let teamErrors = [];
        let valid = true;
        if (teamObj.name && !isEmpty(teamObj.name)) {
            if (teamObj.name.length > 30) {
                teamErrors.push("Team name can not be longer than 30 characters");
            }
        } else {
            teamErrors.push("Team name can not be empty");
        }
        if (teamErrors.length > 0) {
            onError(teamErrors);
            valid = false;
        }
        return valid;
    }

    const handleShowTeams = () => {
        setSelectedTeam({});
        setIsEditingTeam(false);
        setShowTeams(true);
    }

    const handleShowMember = (isEditing) => {
        setIsEditingMember(isEditing);
        setShowMember(true);
    }

    const handleTeamChange = (e) => {
        const value = teams.find((t) => t.teamId == e.target?.value);
        setProgramLead({
            ...programLead,
            "teamId": value?.teamId,
            "teamName": value?.name
        });
    }

    const handleDailyCapChange = (e) => {
        const value = e.target.value;
        setProgramLead({
            ...programLead,
            "dailyCapacity": value
        });
    }

    const handleStatusChange = (e) => {
        const value = e.target.value;
        setProgramLead({
            ...programLead,
            "status": value
        });
    }

    const handleZoneChange = (zones) => {
        setSelectedZones({...zones})
        setProgramLead({
            ...programLead,
            zoneIds: zones.paramValue,
        });
    }

    const handleMarketChange = (e) => {
        const marketId = e.target.value;
        const marketSelected = markets.filter(market => market.value === marketId);
        const market = marketSelected[0]?.text;
        setProgramLead({
            ...programLead,
            marketId,
            market
        });
    }

    const handleSaveProgramLead = () => {
        if (validateProgramLead(programLead)) {
            let saveRequest;
            if (isEditingMember) {
                saveRequest = updateProgramLead(programLead);
            } else {
                saveRequest = createProgramLead(programLead);
            }
            saveRequest.then((res) => {
                if (res && res.data) {
                    setProgramLead(res.data);
                    fetchProgramLeads();
                }
            }).catch((err) => {
                onError(err);
            });
            handleCloseMember();
        }
    }

    const validateProgramLead = (programLeadObj) => {
        let plErrors = [];

        if (!programLeadObj.name || isEmpty(programLeadObj.name)) {
            plErrors.push("Program Lead can not be empty");
        }
        if (!programLeadObj.teamName || isEmpty(programLeadObj.teamName)) {
            plErrors.push("Team can not be empty");
        }
        if (programLeadObj.dailyCapacity === "") {
            plErrors.push("DailyCapacity can not be empty");
        }
        if (!programLeadObj.status || isEmpty(programLeadObj.status)) {
            plErrors.push("Status can not be empty");
        }

        if (plErrors.length > 0) {
            onError(plErrors);
            return false;
        } else {
            return true;
        }
    };

    const handleEditMember = (member) => {
        member.name = member.programLead
        setProgramLead(member);
        const zoneData = transformZoneInformation(member.zoneIds, member.zoneNames);
        if (zoneData) {
            setSelectedZones(zoneData);
        }
        handleShowMember(true);
    }

    const fetchStatus = () => {
        getStatus().then((res) => {
            if (res && res.data) {
                setStatusList(res.data);
            }
        });
    }

    const fetchProgramLeads = (pageNumber = 1, pageSize = 10) => {
        getProgramLead(pageNumber, pageSize).then(res => {
            if (res && res.data) {
                const { userList, pageNumber, pageSize, totalSize } = res.data;
                setMembers(userList);
                setPagination({ pageNumber: pageNumber, pageSize: pageSize, totalSize: totalSize });
            }
        });
    }

    const fetchTeams = () => {
        getTeams().then((res) => {
            if (res && res.data) {
                setTeams(res.data);
            }
        });
    }

    const fetchZones = () => {
        fetchDropDownZones('ACTIVE').then((res) => {
            if (res && res.data) {
                setZones(res.data);
            }
        });
    }

    const fetchMarkets = () => {
        fetchDropDownFiltered('MARKET').then((res) => {
            if (res && res.data) {
                setMarkets(res.data);
            }
        });
    }

    useEffect(() => {
        fetchProgramLeads();
        fetchTeams();
        fetchStatus();
        fetchZones();
        fetchMarkets();
    }, []);

    const paginate = (i) => {
        if (i) {
            fetchProgramLeads(i);
        }
    };

    const buildZonesData = () => {
        const zonesData = zones.map(zone => {
            return {
                text: zone.zoneName,
                value: zone.id
            }
        })
        return Promise.resolve(zonesData)
    }

    const transformZoneInformation = (zoneIds, zoneNames) => {
        if (zoneIds && zoneNames) {
            return {
                paramValue: zoneIds,
                displayValue: zoneNames
            };
        }
    }

    const reduceZoneNames = (zoneNames) => {
        if (zoneNames) {
            return zoneNames.join(", ");
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Configuration Manager | CareEmpower</title>
            </Helmet>
            <div className="overflow-inherit w-100">               
                <Container fluid className="p-4">
                    <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
                    <Row>
                        <CustomAccordion>
                            <AccordionItem
                                title='Program Leads'
                                eventKey='0'
                            >
                                <Row>
                                    <Col>
                                        <Button id={`${componentName}-addProgramLeadBtn`} variant="secondary" onClick={() => handleShowMember(false)} className="mb-3">
                                            Add Program Lead
                                        </Button>
                                    </Col>
                                    <Col>
                                        {!isEmpty(members) && Math.ceil(pagination.totalSize / pagination.pageSize) > 0 && (
                                            <CustomPagination
                                                paginateButClicked={paginate}
                                                MAX_PAGE={Math.ceil(pagination.totalSize / pagination.pageSize)}
                                                pageNumber={Math.ceil(pagination.pageNumber)}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <Table responsive bordered hover>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                </th>
                                                <th scope="col">
                                                    Program Lead
                                                </th>
                                                <th scope="col">
                                                    Team
                                                </th>
                                                <th scope="col">
                                                    Zone
                                                </th>
                                                <th scope="col">
                                                    Market
                                                </th>
                                                <th scope="col">
                                                    Daily Capacity
                                                </th>
                                                <th scope="col">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members && members.map((m, idx) => {
                                                return (
                                                    <tr key={`${m.programLead}${idx}`}>
                                                        <th>
                                                            <Button id={`${componentName}-programLeadEditBtn-${idx}`} variant="link" className="p-0 ms-2" onClick={() => handleEditMember(m)}>
                                                                <i className="fa fa-edit"></i>
                                                            </Button>
                                                        </th>
                                                        <td id={`${componentName}-program-${idx}`}>
                                                            {m.programLead}
                                                        </td>
                                                        <td id={`${componentName}-team-${idx}`}>{m.teamName}</td>
                                                        <td id={`${componentName}-zone-${idx}`} className="text-truncate" style={{maxWidth: "5rem"}}>
                                                            {reduceZoneNames(m.zoneNames)}</td>
                                                        <td id={`${componentName}-market-${idx}`}>{m.market}</td>
                                                        <td id={`${componentName}-dailyCapacity-${idx}`}>{m.dailyCapacity}</td>
                                                        <td id={`${componentName}-status-${idx}`}>
                                                            <span className={m.status === "ACTIVE" ? 'badge bg-success' : 'badge bg-secondary'}>{m.status}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>

                                <Modal size="lg" centered show={showMember} onHide={handleCloseMember}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{isEditingMember ? 'Edit' : 'Add'} Program
                                            Lead</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col className="mb-3" md={6} hidden={!isEditingMember}>
                                                <label id={`${componentName}-memberLabel`} className="d-block">Member Name</label>
                                                <Form.Control id={`${componentName}-memberValue`} type="text"
                                                    value={programLead.name}
                                                    disabled="true"
                                                    hidden={!isEditingMember}
                                                />
                                            </Col>
                                            <Col className="mb-3" md={6} hidden={isEditingMember}>
                                                <label id={`${componentName}-programLeadNameLabel`} className="d-block">Program Lead Name</label>
                                                <AsyncTypeahead
                                                    id={`${componentName}-programLeadNameValue`}
                                                    labelKey="text"
                                                    isLoading={isLoading}
                                                    onSearch={asyncsearch}
                                                    options={options}
                                                    onChange={(e) => handleMemberNameChange(e)}
                                                    placeholder="Search Program Leads by Name"

                                                />
                                            </Col>
                                            <Col className="mb-3" sm={12} md={3}>
                                                <label id={`${componentName}-teamLabel`} className="d-block">Team</label>
                                                <select id={`${componentName}-teamValue`} name="team" value={programLead.teamId}
                                                    className="form-select"
                                                    required={true}
                                                    onChange={(e) => handleTeamChange(e)}>
                                                    <option id={`${componentName}-teamItem`} value=""></option>
                                                    {teams.map((el, i) =>
                                                        <option id={`${componentName}-teamValue-${i}`} value={el.teamId}>{el.name}</option>
                                                    )}
                                                </select>
                                            </Col>
                                            <Col className="mb-2" sm={12} md={3}>
                                                <ZoneDropDownList showClearButton={false} placeholder=""
                                                                  disabled={false} showSearchTextbox={false}
                                                                  header="Zones"
                                                                  handleChange={handleZoneChange}
                                                                  showRequired={false}
                                                                  getData={buildZonesData}
                                                                  selected={selectedZones}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-1" sm={12} md={3}>
                                                <label id={`${componentName}-marketLabel`} className="d-block">Market</label>
                                                <select id={`${componentName}-marketValue`} name="market" value={programLead.marketId}
                                                        className="form-select"
                                                        required={true}
                                                        onChange={(e) => handleMarketChange(e)}>
                                                    <option id={`${componentName}-marketItem`} value=""></option>
                                                    {markets.map((el, i) =>
                                                        <option id={`${componentName}-marketItem-${i}`} value={el.value}>{el.text}</option>
                                                    )}
                                                </select>
                                            </Col>
                                            <Col className="mb-1" sm={12} md={3}>
                                                <label id={`${componentName}-dailyCapacityLabel`} className="d-block">Daily Capacity</label>
                                                <select id={`${componentName}-dailyCapacityValue`} name="dailyCapacity"
                                                    value={programLead.dailyCapacity}
                                                    className="form-select"
                                                    required={true}
                                                    onChange={(e) => handleDailyCapChange(e)}>
                                                    <option id={`${componentName}-dailyCapacityItem`} value=""></option>
                                                    {Array.from({ length: 26 }, (_, i) =>
                                                        <option id={`${componentName}-dailyCapacityItem-${i}`}>{i}</option>)}
                                                </select>
                                            </Col>
                                            <Col className="mb-1" sm={12} md={3}>
                                                <label id={`${componentName}-statusLabel`} className="d-block">Status</label>
                                                <select id={`${componentName}-statusValue`} name="status" value={programLead.status}
                                                    className="form-select"
                                                    required={true}
                                                    onChange={(e) => handleStatusChange(e)}>
                                                    <option id={`${componentName}-statusItem`} value=""></option>
                                                    {statusList.map((el, i) =>
                                                        <option id={`${componentName}-statusItem-${i}`} value={el.value}>{el.text}</option>
                                                    )}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button id={`${componentName}-programLeadsCloseBtn`} variant="secondary" onClick={handleCloseMember}>
                                            Close
                                        </Button>
                                        <Button id={`${componentName}-programLeadsSaveBtn`} variant="primary" onClick={handleSaveProgramLead}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </AccordionItem>
                            <AccordionItem
                                title='Manage Zones'
                                eventKey='zoneManager'
                            >
                                <ZoneManager refresh={fetchZones}/>
                            </AccordionItem>
                            {
                                dropdowns.map((section, index) => {
                                    return (
                                        <AccordionItem
                                            title={section.name}
                                            eventKey={section.name}
                                        >
                                            {
                                                section.content.map((dropdown, index) => (
                                                    <CustomAccordion>
                                                        <AccordionItem
                                                            title={dropdown.title}
                                                            eventKey={index + 1}
                                                        >
                                                            <DropDownList
                                                                title={dropdown.title}
                                                                dropDownListName={dropdown.dropDownListName}
                                                                moduleName={dropdown.moduleName}
                                                                onError={onError}
                                                                setErrors={setErrors}
                                                                customColumns={dropdown.customColumns}
                                                            />
                                                        </AccordionItem>
                                                    </CustomAccordion>
                                                ))
                                            }
                                        </AccordionItem>
                                    )
                                })
                            }
                        </CustomAccordion>
                        <Col sm={12} md={4}>
                            <SectionContext.Provider value={contextValue}>
                                <SectionAdmin
                                    title="Team"
                                    isLoading={isLoadingTeams}
                                    errors={errors}
                                    setErrors={setErrors}
                                    isShowModalBtnPositionUp={false}
                                    sectionBody={<TeamsBodyList />}
                                    onClickShowModal={handleShowTeams}
                                    modalShow={showTeams}
                                    modalOnHide={() => { setShowTeams(false); setErrors([]); }}
                                    modalOnCreate={handleSaveTeam}
                                    modalTitle={isEditingTeam ? "Edit Teams" : "Add Teams"}
                                    modalBody={<TeamsModalBody />}
                                />
                            </SectionContext.Provider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ThemeProvider>
    );
};
export default CareAdmin;