import React from 'react';
import { Row, Card } from 'react-bootstrap';

const componentName = 'PatientDataCard'

const PatientDataCard = ({ children, idx }) => {

  return (
    <Card id={`${componentName}-${idx}`}
      className="ps-2 pe-1 mb-3 patientCard">
      <Card.Body>
        <Row className="align-items-center">
          {children}
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PatientDataCard;