import React from "react";
import { Badge, Spinner } from "react-bootstrap";

const SpinnerLoading = () => {
  return (
    <>
        <Badge pill bg="light" text="dark" className="border align-items-center position-absolute top-50 start-50 zindex-1040">
            <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
        </Badge>
    </>
  );
};

export default SpinnerLoading;
