import {actions} from "./referralsActions";


const genders = [
    {text: "Male", value: "Male"},
    {text: "Female", value: "Female"},
    {text: "Any", value: "Any"}
];

const visitTypes = [
    {text: "Consult, Evaluate, and Treat", value: "CONSULT_EVALUATE_TREAT"},
    {text: "Consultation", value: "CONSULTATION"},
    {text: "Evaluation and Treatment", value: "EVALUATION_AND_TREATMENT"},
    {text: "Second Opinion", value: "SECOND_OPINION"},
    {text: "Established Patient Follow Up", value: "PATIENT_FOLLOW_UP"},
];

const urgencyOptions = [
    {text: "Emergent: (Same day response)", value: "40_EMERGENT",},
    {text: "Priority (4-7 day response)", value: "20_PRIORITY",},
    {text: "Routine (8-14 day response)", value: "10_ROUTINE",},
];

export function getSetters(dispatch, state) {
    const isSubstanceAbuseICD10Selected = (icd10Option) => {
        if (icd10Option) {
            let regex = /^F1[0-9]+.*$/;
            return regex.test(icd10Option.code);
        }
        return false;
    }

    const handleICD10Selection = (icd10Option, index) => {
        const icd10List = state.icd10List.map(icd10 => ({...icd10}));
        let code = [];
        let description = [];

        if (icd10Option) {
            code = [icd10Option.code];
            description = [icd10Option.description];
        }
        icd10List[index].code = code;
        icd10List[index].description = description;
        return icd10List;
    }

    const handleSubstanceAbuseSelection = (index, icd10) => {
        const icd10List = state.icd10List.map(icd10 => ({...icd10}));
        icd10List[index].code = [icd10.code];
        icd10List[index].description = [icd10.description];
        return icd10List;
    }
    return {
        setCurrentSpecialistReferralStep: payload => dispatch({
            type: actions.SET_CURRENT_SPECIALIST_REFERRAL_STEP,
            payload
        }),
        setSelectedSpeciality: payload => dispatch({type: actions.SET_SELECTED_SPECIALITY, payload}),
        setSelectedPayer: payload => dispatch({type: actions.SET_SELECTED_PAYER, payload}),
        setSelectedPractice: payload => dispatch({type: actions.SET_SELECTED_PRACTICE, payload}),
        setSelectedAssociatedProvider: payload => dispatch({type: actions.SET_SELECTED_ASSOCIATED_PROVIDER, payload}),
        setZipCode: payload => dispatch({type: actions.SET_ZIP_CODE, payload}),
        setGenders: () => dispatch({type: actions.SET_GENDERS, payload: genders}),
        setDefaultGender: () => dispatch({
            type: actions.SET_SELECTED_GENDER,
            payload: {
                paramValue: [genders[2].value],
                displayValue: [genders[2].text]
            }
        }),
        setSelectedGender: payload => dispatch({type: actions.SET_SELECTED_GENDER, payload}),
        setSelectedProvider: payload => dispatch({type: actions.SET_SELECTED_PROVIDER, payload}),
        initSpecialistReferralVars: () => dispatch({type: actions.INIT_SPECIALIST_REFERRAL_VARS}),
        setVisitTypes: () => dispatch({type: actions.SET_VISIT_TYPES, payload: visitTypes}),
        setSelectedVisitType: (payload) => dispatch({type: actions.SET_SELECTED_VISIT_TYPE, payload}),
        setUrgencyOptions: () => dispatch({type: actions.SET_URGENCY_OPTIONS, payload: urgencyOptions}),
        setSelectedUrgency: (payload) => dispatch({type: actions.SET_SELECTED_URGENCY, payload}),
        setNote: (payload) => dispatch({type: actions.SET_NOTE, payload}),
        setUploadedFiles: (payload) => {
            const files = payload.map(file => ({description: null, fileMetadata: file}))
            dispatch({type: actions.SET_UPLOADED_FILES, payload: files})
        },
        setSelectedICD10Option: (icd10SelectedList, index) => {
            let icd10Option = icd10SelectedList?.[0];
            if (isSubstanceAbuseICD10Selected(icd10Option)) {
                const payload = {icd10: icd10Option, index}
                dispatch({type: actions.SET_SUBSTANCE_ABUSE_ICD10, payload})
            } else {
                dispatch({type: actions.SET_SELECTED_ICD10_OPTION, payload: handleICD10Selection(icd10Option, index)})
            }
        },
        setSubstanceAbuseICD10: (payload) => dispatch({type: actions.SET_SUBSTANCE_ABUSE_ICD10, payload}),
        confirmSubstanceAbuseICD10: (payload) => {
            dispatch({type: actions.SET_SUBSTANCE_ABUSE_ICD10, payload: null})
            dispatch({
                type: actions.SET_SELECTED_ICD10_OPTION,
                payload: handleSubstanceAbuseSelection(payload.index, payload.icd10)
            })
        },
        addMoreICD10: () => dispatch({type: actions.ADD_MORE_ICD10, payload: {}})
    }
}