import React, { useEffect, useRef, Fragment } from "react";
import { Card } from "react-bootstrap";
import { Timeline } from "../../components/Timeline";
import { MiniCard } from "../../components/MiniCard";
import { CollapsibleCard } from "../../components/CollapsibleCard";
import { EQHSpinner } from "../../components/EQHSpinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  arrayToKVP,
  getFieldValueVal,
  getFieldValueLabel,
  handlePkgErrors,
  getDisplayValue,
  formatTimeOnly,
  formatDateTime,
  formatLocalDate,
  formatDate,
} from "../../utils/util";
import { useGetPatientProfileNotes } from "../../actions/patientProfileActions";
import { notesReducer } from "../../reducers/patientProfile/notesReducer";
import { patientProfileField } from "../../utils/textValueLists";
import { Filter } from "../../components/filters/filterGroup";
import { useState } from "react";
import { Button as OurButton } from "../../components/buttons";
import {
  DDLDataMode,
  DDLMode,
  GENERIC_DDL_TYPE,
} from "../../components/dropDownLists/genericDDL";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { AddNote } from "./AddNote";
import { useAuth } from "../../context/authContext";
import axios from "../../utils/apiClient";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modals from "../../components/modals";
import {NOTES_ACTIVITY_TYPE_MAP} from "../../utils/constants";

const componentName = "patientProfile-Note";

const isEmpty = require("is-empty");

const HIDE_NOTES_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-notes/hide-notes";
const UNHIDE_NOTES_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-notes/unhide-notes";

const NotesActivityTypeMap = NOTES_ACTIVITY_TYPE_MAP;

const DefaultCard = ({ note, idx }) => {

  if (note) {
    const fieldValuesKVP = arrayToKVP(note.fieldValues, "caption", "value");
    const openContent = (
      <Container fluid>
        <Row xl={4}>
          {note.fieldValues
            .filter((x) => x.caption !== "note")
            .map((x, idx) => {
              const miniCardlabel = getFieldValueLabel(x);
              let miniCardValue = getFieldValueVal(x);

              if (
                miniCardlabel === "start" ||
                miniCardlabel === "end" ||
                miniCardlabel === "dateofservice"
              ) {
                miniCardValue = formatDate(miniCardValue);
              }

              return (
                <Col>
                  <MiniCard
                    idx={idx}
                    label={getDisplayValue(patientProfileField, miniCardlabel)}
                    value={miniCardValue}
                  />
                </Col>
              );
            })}
        </Row>
        {fieldValuesKVP.note && (
          <Row>
            <Col>
              <MiniCard label={"Note"} idx={idx} value={fieldValuesKVP.note} />
            </Col>
          </Row>
        )}
      </Container>
    );

    const closedContent = <></>;

    return (
        <CollapsibleCard
            heading={NotesActivityTypeMap[note.activityType]}
            idx={idx}
            title={note.description}
            openContent={openContent}
            closedContent={closedContent}
        />
    );
  }
  return <></>;
};

const NoteCard = ({ note, idx }) => {

  return <DefaultCard idx={idx} note={note} />;
};

const Notes = ({ patientId, selectedTab, reloadPatientOverview, selectedItemId }) => {
  const auth = useAuth();
  const userId = auth.getUserId();

  const [queuedfilterValues, setQueuedFilterValues] = useState({
    paramValue: [],
    displayValue: [],
  });
  const [filterValues, setFilterValues] = useState({
    paramValue: [],
    displayValue: [],
  });
  const [show, setShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [userName, setUserName] = useState();
  const notesPkg = useGetPatientProfileNotes([], notesReducer);
  const [showHiddenNotes, setShowHiddenNotes] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [hideReason, setHideReason] = useState(null);
  const [showModalReason, setShowModalReason] = useState(false);
  const [subTab, setSubTab] = useState("Notes");

  const filterCount =
    (queuedfilterValues && queuedfilterValues.paramValue.length ? 1 : 0) +
    (filterValues && filterValues.paramValue.length ? 1 : 0) +
    (userName ? 1 : 0);

  useEffect(() => {
    if (selectedTab) {
      notesPkg.fetchData(patientId);
      setSubTab("Notes");
    }
  }, [patientId, selectedTab]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const applyFilters = () => {
    setFilterValues(queuedfilterValues);
    setQueuedFilterValues({
      paramValue: [],
      displayValue: [],
    });
    setShow(false);
  };

  const filterNotes = (note) => {
    return (
      (!filterValues.paramValue.length ||
        filterValues.paramValue.includes(note.activityType)) &&
      ((userName &&
        (note.author ?? "").toLowerCase().includes(userName.toLowerCase())) ||
        !userName) &&
      (showHiddenNotes ? true : !note.deleted) &&
      ((subTab !== "PHR" && note.hasNote) || (subTab === "PHR" && note.hasAttachment))
    );
  };

  const prepareNotes = (notes) => {
    [...notes].sort((firstEl, secondEl) => {
      return firstEl.dateTime.localeCompare(secondEl.dateTime);
    });
    const preparedNotes = notes.filter(filterNotes);
    return preparedNotes;
  };

  const reset = () => {
    setQueuedFilterValues({
      paramValue: [],
      displayValue: [],
    });
    setFilterValues({
      paramValue: [],
      displayValue: [],
    });
    setUserName("");
  };

  const hideNotes = () => {
    const body = {
      patientNoteIds: selectedNotes.map((selectedNote) => selectedNote.noteId),
      reason: hideReason,
    };
    setDeletedToNotes(HIDE_NOTES_URL, body);
  };

  const unhideNotes = () => {
    const selectedNoteIds = selectedNotes.map(
      (selectedNote) => selectedNote.noteId
    );
    setDeletedToNotes(UNHIDE_NOTES_URL, selectedNoteIds);
  };

  const setDeletedToNotes = (url, body) => {
    if (isEmpty(selectedNotes)) {
      return;
    }

    axios
      .post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        notesPkg.fetchData(patientId);
        setSelectedNotes([]);
        reloadPatientOverview();
      })
      .catch((error) => {
      });
  };

  const onSelectNote = (isSelected, note) => {
    if (isSelected) {
      setSelectedNotes([...selectedNotes, note]);
    } else {
      let newSelectedNotes = [...selectedNotes];
      const index = newSelectedNotes.indexOf(note);
      newSelectedNotes.splice(index, 1);

      setSelectedNotes(newSelectedNotes);
    }
  };

  const itemRef = useRef(null);
  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.parentNode.scrollTop = itemRef.current.offsetTop - 50;
    }
  });

  return (
    <Fragment>
      <Container fluid>
        <Modals
          show={showModalReason}
          onHide={() => setShowModalReason(false)}
          title={<span>Enter reason</span>}
          titleid={`${componentName}-enterReasonModal`}
          size="lg"
          hideClickButton={false}
          hideFooter={false}
          completeBtn={"Confirm"}
          completeBtnId={`${componentName}-confirmButton`}
          closeBtn={"Cancel"}
          closeBtnId={`${componentName}-cancelButton`}
          onCreate={() => {
            hideNotes();
            setShowModalReason(false);
          }}
          body={
            <Row>
              <Form.Group className="w-100">
                <Form.Control
                  as="textarea"
                  id={`${componentName}-hideReasonValue`}
                  rows={3}
                  onChange={(e) => setHideReason(e.target.value)}
                />
              </Form.Group>
            </Row>
          }
        />
        {notesPkg.state.isLoading && <EQHSpinner />}
        {notesPkg.state.error ? (
          <Card>
            <Card.Body id={`${componentName}-notesError`}>{handlePkgErrors(notesPkg.state.error)}</Card.Body>
          </Card>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Row className="mb-2">
                  <Col>
                    <h5 id={`${componentName}-phrNotesTitle`} className="fw-bold d-inline-block me-3 mb-0 align-middle">{subTab === "PHR" ? "PHR" : "Notes"}</h5>
                    {auth.hasAuthority("AUTH_FILES_WRITE_ACCESS") && (
                      <Button
                        variant="primary"
                        id={`${componentName}-addNoteButton`}
                        onClick={() => setAddModalShow(true)}
                      >
                        Add
                      </Button>
                    )}
                    <Button id={`${componentName}-filterButton`} variant="secondary" onClick={handleShow}>
                      <i className="fas fa-list me-2"></i> Filter{" "}
                      <Badge pill variant="primary" className="ms-1">
                        {filterCount}
                      </Badge>
                    </Button>
                    <Button id={`${componentName}-resetButton`} variant="secondary" onClick={reset}>
                      <i className="fas fa-redo-alt me-2"></i> Reset
                    </Button>
                  </Col>
                  <Col className="text-end">
                    <ButtonGroup id={`${componentName}-phrNotesToggle`} size="sm" className="d-inline-block bg-white align-top me-3">
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>Notes</Tooltip>}
                      >
                        <Button id={`${componentName}-notesToggleButton`} onClick={() => setSubTab("Note")} variant="outline-secondary" size="sm">
                          <i className="far fa-file-invoice fa-lg align-middle"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>PHR</Tooltip>}
                      >
                        <Button id={`${componentName}-phrToggleButton`} onClick={() => setSubTab("PHR")} variant="outline-secondary" size="sm">
                          <i className="fas fa-paperclip fa-lg align-middle"></i>
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                    <DropdownButton
                      size="sm"
                      variant="secondary"
                      id={`${componentName}-actionDropdown`}
                      className="d-inline-block bg-white align-middle me-3"
                      title="Action"
                      disabled={isEmpty(selectedNotes)}
                    >
                      <Dropdown.Item id={`${componentName}-actionHide`} onClick={() => setShowModalReason(true)}>
                        Hide
                      </Dropdown.Item>
                      <Dropdown.Item id={`${componentName}-actionShow`} onClick={unhideNotes}>
                        Unhide
                      </Dropdown.Item>
                    </DropdownButton>
                    <Form.Check id={`${componentName}-showHidden`} className="d-inline-block align-middle">
                      <Form.Check.Input
                        type="checkbox"
                        id={`${componentName}-showHiddenCheck`}
                        onChange={() => setShowHiddenNotes(!showHiddenNotes)}
                      />
                      <Form.Check.Label id={`${componentName}-showHiddenLabel`} for="showHidden">
                        Show Hidden
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
                <Timeline
                  items={prepareNotes(notesPkg.state.data)}
                  selectedItemId={selectedItemId}
                  itemRef={itemRef}
                  titleBuilder={(note, idx) =>
                    note && note.dateTime ? (
                      <div>
                        <span id={`${componentName}-tlDate-${idx}`} className="d-block fw-bold">
                          {formatLocalDate(note.dateTime)}
                        </span>
                        <span id={`${componentName}-tlTime-${idx}`} className="d-block">
                          {formatTimeOnly(note.dateTime)}
                        </span>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="auto"
                          overlay={
                            <Tooltip>
                              {note.author ? note.author : "No Author"}
                            </Tooltip>
                          }
                        >
                          <span id={`${componentName}-tlAuthor-${idx}`} className="badge bg-secondary text-truncate mw-100">
                            {note.author ? note.author : "No Author"}
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <span id={`${componentName}-tlNoMeta-${idx}`}>-</span>
                    )
                  }
                  subTitleBuilder={(note, idx) => {
                    return (
                      <>
                        {!isEmpty(note.noteId) &&
                          userId === note.noteUserId && (
                            <Form.Check
                              id={`${componentName}-noteCheck-${idx}`}
                              className="d-inline-block me-2 align-middle"
                              type="checkbox"
                              checked={selectedNotes.includes(note)}
                              onChange={(e) =>
                                onSelectNote(e.target.checked, note)
                              }
                            />
                          )}
                        {note.deleted && (
                          <i id={`${componentName}-hiddenNoteIcon-${idx}`}
                            className="fas fa-eye-slash align-middle"
                            title={"Hidden Note"}
                          ></i>
                        )}
                      </>
                    );
                  }}
                  bodyBuilder={(note, idx) => {
                    return (
                      <div>
                        <NoteCard idx={idx} note={note} />
                        {!isEmpty(note.noteId) &&
                          note.deleted &&
                          userId === note.noteUserId && (
                            <>
                              <span id={`${componentName}-noteHiddenBy-${idx}`}>
                                <span id={`${componentName}-noteHiddenByLabel-${idx}`}>Hidden by:</span>{" "}
                                <span id={`${componentName}-noteHiddenByValue-${idx}`}>{note.deletedBy?.fullName}</span>{" "}
                                <span id={`${componentName}-noteHiddenByDate-${idx}`}>{formatDateTime(note.deletionDate)}</span>
                              </span>
                              <br />
                              <span id={`${componentName}-noteHiddenReason-${idx}`}>
                                <span id={`${componentName}-noteHiddenReasonLabel-${idx}`}>Reason:</span>{" "}
                                <span id={`${componentName}-noteHiddenReasonValue-${idx}`}>{note.reasonForHiding}</span>
                              </span>
                            </>
                          )}
                      </div>
                    );
                  }}
                />
              </Card.Body>
            </Card>
          </>
        )}
        <Modal size="xl" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-filtersModal`}>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row xs={1} sm={1} md={3}>
                <Filter
                  selected={filterValues}
                  handleChange={(e) => setQueuedFilterValues(e)}
                  filterDefinition={{
                    type: GENERIC_DDL_TYPE,
                    getHeader: () => "Note Categories",
                    key: "Note",
                    componentProps: {
                      mode: DDLMode.MultipleSelect,
                      dataMode: DDLDataMode.OneAPICallFilterInUI,
                      showSearchTextbox: true,
                      highlightWhenHasValue: false,
                      showClearButton: true,
                      getData: (srchTxt) =>
                        Promise.resolve([
                          {
                            text: "All",
                            subItems: Object.entries(NotesActivityTypeMap).map(
                              ([key, value]) => {
                                return { text: value, value: key };
                              }
                            ),
                          },
                        ]),
                    },
                  }}
                />
                <Col id={`${componentName}-filterUserName`}>
                  <Form.Label id={`${componentName}-filterUserNameLabel`}>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    id={`${componentName}-filterUserNameValue`}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <OurButton id={`${componentName}-closeButton`} onClick={handleClose}>Close</OurButton>
            <OurButton id={`${componentName}-submitButton`} onClick={applyFilters}>Submit</OurButton>
          </Modal.Footer>
        </Modal>
        <AddNote
          patientId={patientId}
          show={addModalShow}
          setShow={setAddModalShow}
          handleClose={() => setAddModalShow(false)}
          reloadPatientOverview={() => {
            reloadPatientOverview();
            setSelectedNotes([]);
          }}
          notesPkg={notesPkg}
        />
      </Container>
    </Fragment>
  );
};

export { Notes };
