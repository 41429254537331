import axios from '../utils/apiClient'

const prefix = process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/'
const MEMBERS_URL =  prefix + 'assigned-members'
const NOTES_URL = prefix + 'note-types'
const SAVE_NOTES = prefix + 'program-status'
const FILE_UPLOAD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/file-upload/fileUpload/multiple";

export const getMembers = async (
    pageNumber = 1,
    pageSize = 10,
    sortParameter = 'FULL_NAME',
    sortDirection = 'asc',
    filter = '',
    userId = null
) => {
    let params = userId? {pageNumber, pageSize, sortParameter, sortDirection, filter, userId}
        : {pageNumber, pageSize, sortParameter, sortDirection, filter};

    return axios
        .get(MEMBERS_URL, {
            params: params,
            headers: {'Content-Type': 'application/json'},
        });
}

export const getNoteTypes = async () => {
    return axios
        .get(NOTES_URL, {
            params:{},
            headers: {'Content-Type': 'application/json'},
        });
}

export const saveNote = (
    patientProgramId,
    patientId,
    programLeadId,
    programId,
    programStatusId,
    noteText,
    userId,
    interactionOutcomeId,
    interactionTypeId,
    files,
) => {
    const json = {
        'patientProgramId': patientProgramId,
        'patientId': patientId,
        'programLeadId': programLeadId,
        'programId': programId,
        'programStatusId': programStatusId,
        'noteText': noteText,
        'userId': userId,
        'interactionTypeId': interactionTypeId,
        'interactionOutcomeId': interactionOutcomeId,
        'files': files
    }
    return axios
        .put(SAVE_NOTES, json, {
            headers: {'Content-Type': 'application/json'},
        });
}

export const uploadFile = (fileData, fileName) => {
    return axios
        .post(FILE_UPLOAD, {[fileName] : Array.from(fileData)});
};

