import styled from "styled-components";

export const TPSection = styled.div`
  padding: ${props => props.visitActivityType?.name == 'Treatment Planning' ? '0 1rem' : 'none'};
  width: ${props => props.visitActivityType?.name == 'Treatment Planning' ? '60%' : '100%'}
`

export const TPAction = styled.div.attrs({ className: 'fw-bold' })`
  display: ${props => props.visitActivityType?.name != 'Treatment Planning' ? 'none' : '' }
`

export const ActivityWrapper = styled.div.attrs()`
  padding: ${props => props.visitActivityType?.name == 'Treatment Planning' ? '0' : ''};
  margin-bottom: ${props => props.visitActivityType?.name == 'Treatment Planning' ? '0' : '10px'};
  width: ${props => props.visitActivityType?.name == 'Medication Review' || props.visitActivityType?.name == 'Prevention and Screenings' ? '50%' : '100%'};
  display: ${props => props.visitActivityType?.name == 'Medication Review' || props.visitActivityType?.name == 'Prevention and Screenings' ? 'inline-block' : 'block'};
`

export const ActivityCell = styled.td.attrs()`
  width: ${props => props.visitActivityType?.name == 'Treatment Planning' ? '100%' : '60%'}
`