import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import { theme } from "../../components/themes/theme1";

import {
  PatientSearchDTO,
  FilterParam,
} from "../../dtos/PatientSearchDTO";
import axios from "../../utils/apiClient";
import Modals from "../../components/modals";
import {
  formatDate,
  preventiveCarePatientFilterParams,
  getQuickListId,
  getCustomWorklistName,
    getCustomListName
} from "../../utils/util";
import queryString from "query-string";
import { FilterGroup } from "../../components/filters/filterGroup";
import _ from "lodash";
import { SessionStorageUtil } from "../../utils/localStorageUtil";
import { QuickListForCareMgmtContext } from "./QuickListForCareMgmtContext";
import { useContext } from "react";
import { CE_RESTORE_OBJ } from "../common/MainLayout";
import { RECENT_QUERY_SETTINGS_KEY } from "../quickList/QuickList";

export const PRINT_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/print";
export const EXPORT_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export";
export const SUMMARY_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
export const CUSTOM_LIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-filtered-list/custom-lists";
export const WORKLIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/custom-work-list/work-list";

let pathName = window.location.pathname;
let modifiedPathName = pathName.endsWith("/")
  ? pathName.substring(0, pathName.length - 1)
  : pathName;
export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + modifiedPathName + "?loadLastQuerySettings=true"
);

const QuickListsContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
`;

const componentName = "QuickListForCareMgmt";

export const searchPatientMain = (criteria) => {
  return axios.post(`${process.env.REACT_APP_REST_API_BASE_URL}/v1/hub-workcenter/referrals`, criteria, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const VIEW = { card: "CARD", list: "LIST" };

const QuickListForCareMgmt = ({
  titleProp,
  filters,
  pageNumberProp,
  pageSizeProp,
  defaultFilterParamsProp,
  defaultSortParamsProp,
  quickListType,
  /*
  cardViewActionObj and listViewActionObj allow the quick list page to pass in a query for the card view and list view.
  You can have 1 query for both card view and list view or you can have 2 separate queries 1 for card view and 1 for list view.  

  If page doesn't pass in a cardViewActionObj(will use default query) or passes in a custom cardViewActionObj, 
  only 1 query will execute and both card view and list view will be based of that 1 query. 

  On other hand if you pass listViewActionObj, 2 async queries will execute; cardViewActionObj for card view and listViewActionObj for list view.

  cardViewActionObj and listViewActionObj have fetchData field for making query to get data and 
  copyMap field is to define how to copy data from return set of query to 
  patientList, totalSize, pageNumber, and pageSize. copyMap field allows us to handle any return set from a query with no code change.
  */
  cardViewActionObj = {
    fetchData: searchPatientMain,
    copyMap: {
      list: "patientCardDtos",
      totalSize: "totalSize",
      pageSize: "pageSize",
      pageNumber: "pageNumber",
    },
  },
  listViewActionObj,
  startingView = VIEW.card,
  children,
  searchFromInputText,
  getPatientsData,
  isSearching,
  getFilters,
  getFilterParams = () => { }
}) => {
  const qlsSettings = useContext(QuickListForCareMgmtContext);
  let quicklistId = getQuickListId();
  let customWorklistName = getCustomWorklistName();
  let customlistName = getCustomListName();

  useEffect(() => {
    let location = window.location.pathname;
    let workingPathName = location.endsWith("/")
      ? location.substring(0, location.length - 1)
      : location;
    if (quicklistId && !isNaN(quicklistId)) {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" +
        workingPathName +
        encodeURIComponent("?loadLastQuerySettings=true&id=" + quicklistId + "&customListName=" + customlistName)
      );
    } else {
      RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
        "returnTo_" + workingPathName + "?loadLastQuerySettings=true"
      );
    }
  }, [quickListType, quicklistId]);

  const [selectedPatients, setSelectedPatients] = useState(new Map());
  const [title, setTitle] = useState(titleProp);
  const [patientsList, setPatientsList] = useState([]);
  const [cardViewData, setCardViewData] = useState({});
  const [listViewData, setListViewData] = useState({});
  const [pageNumber, setPageNumber] = useState(pageNumberProp);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [totalSize, setTotalSize] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [listViewLoadingData, setListViewLoadingData] = useState(
    listViewActionObj ? true : false
  );

  const [defaultFilterParams, setDefaultFilterParams] = useState(
    defaultFilterParamsProp
  );
  const [filterParams, setFilterParam] = useState(defaultFilterParamsProp);
  const [customListDto, setCustomListDto] = useState(null);
  const [sortParams, setSortParams] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  let [removablePatients, setRemovablePatients] = useState([]);
  const [filterButtons, setFilterButtons] = useState(false);
  const [modalSize, setModalSize] = useState(null);
  let [disableButtons, setDisableButtons] = useState(true);
  const [modalTitle, setModalTitle] = useState(null);
  const [view, setView] = useState(startingView);
  const [errorText, setErrorText] = useState();

  const LAST_QUERY_SETTINGS_KEY = `${quickListType}-QLQuerySettings`;
  const QUERY_PARAMS = new URLSearchParams(window.location.search);

  const setDefaultFilterParamHelper = (filterParams) => {
    setDefaultFilterParams(defaultFilterParamsProp);
    setFilterParam(filterParams);
  };

  const handleFilterChange = (filterParams) => {
    setFilterParam(filterParams);
    isSearching(true)
    getFilters(filterParams)
    handleRemovablePatients([]);
    setSelectedPatients(new Map());
    setPageNumber(1);
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        1,
        pageSize,
        quickListType
      ),
      title,
      view
    );
  };

  const copyToPatientListVariables = (data, inView) => {
    let copyMap = null;
    if (!inView) {
      inView = view;
    }

    if (inView === VIEW.card || (inView === VIEW.list && !listViewActionObj)) {
      copyMap = cardViewActionObj.copyMap;
    } else if (inView === VIEW.list && listViewActionObj) {
      copyMap = listViewActionObj.copyMap;
    }
    setPatientsList(_.get(data, copyMap["list"]));
    setTotalSize(_.get(data, copyMap["totalSize"]));
    setPageSize(_.get(data, copyMap["pageSize"]));
    setPageNumber(_.get(data, copyMap["pageNumber"]));
    getPatientsData(data)
  };

  const query = (patientSearchDTO, updatedTitle, updatedView) => {
    setLoadingData(false);
    SessionStorageUtil.setObj(RECENT_QUERY_SETTINGS_KEY, LAST_QUERY_SETTINGS_KEY);
    SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, {
      patientSearchDTO: patientSearchDTO,
      view: updatedView,
      title: updatedTitle,
    });
    if (listViewActionObj) {
      setListViewLoadingData(true);
    }
    cardViewActionObj
      .fetchData(patientSearchDTO)
      .then((res) => {
        if (res.data) {
          setCardViewData(res.data);
          if (!listViewActionObj) {
            setListViewData(res.data);
            copyToPatientListVariables(res.data);
          } else if (listViewActionObj && view === VIEW.card) {
            copyToPatientListVariables(res.data);
          }
          getFilterParams(patientSearchDTO);
          isSearching(false)
          setLoadingData(false);
        }
      })
      .catch((err) => {
        setErrorText("Error");
        isSearching(false)
        setLoadingData(false);
      });
    if (listViewActionObj) {
      listViewActionObj
        .fetchData(patientSearchDTO)
        .then((res) => {
          if (res.data) {
            setListViewData(res.data);
            if (view === VIEW.list) {
              copyToPatientListVariables(res.data);
            }
            isSearching(false)
            setListViewLoadingData(false);
          }
        })
        .catch((err) => {
          setErrorText("Error");
          isSearching(false)
          setListViewLoadingData(false);
        });
    }
  };

  const fetchData = async (filterParams, quicklistName, title, view) => {
    query(
      new PatientSearchDTO(
        filterParams,
        sortParams,
        pageNumber,
        pageSize,
        quicklistName
      ),
      title,
      view
    );
  };

  useEffect(() => {
    //restore from last timeout override
    const restoreObject = SessionStorageUtil.getObj(CE_RESTORE_OBJ);
    let quicklistObj = SessionStorageUtil.getObj(LAST_QUERY_SETTINGS_KEY);
    if (restoreObject) {
      if (restoreObject.recentKey === LAST_QUERY_SETTINGS_KEY) {
        quicklistObj = restoreObject.quickListObj;
        SessionStorageUtil.setObj(LAST_QUERY_SETTINGS_KEY, quicklistObj);
      }
      SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);

    }
    if ((restoreObject || QUERY_PARAMS.get("loadLastQuerySettings")) && quicklistObj && quicklistObj.patientSearchDTO) {
      if (defaultSortParamsProp) {
        quicklistObj.patientSearchDTO.sortParams = defaultSortParamsProp;
      }
      //load from back button
      setTitle(quicklistObj.title);
      setView(quicklistObj.view);
      setFilterButtons(false);
      setDefaultFilterParamHelper(quicklistObj.patientSearchDTO.filterParams);
      setSortParams(quicklistObj.patientSearchDTO.sortParams);
      query(
        quicklistObj.patientSearchDTO,
        quicklistObj.title,
        quicklistObj.view
      );
    } else if (quicklistId && !isNaN(quicklistId)) {
      //load from custom list
      axios
        .get(CUSTOM_LIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setFilterButtons(true);
          setDefaultFilterParamHelper(
            response.data.patientRequestDTO.filterParams
          );
          setTitle(response.data.name);
          fetchData(
            response.data.patientRequestDTO.filterParams,
            response.data.quickList,
            response.data.name,
            view,
          );
          setCustomListDto(response.data);
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalSize("lg");
          if (error.response != null) {
            setModalMessage(error.response.data.message);
          } else {
            setModalMessage(error.message);
          }
        });
    } else if (quicklistId && quicklistId.includes("customWorklist")) {
      //load member list
      axios
        .get(WORKLIST_URL + "?id=" + quicklistId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCustomListDto(response.data);
        })
        .catch((error) => {
        });

      setFilterButtons(true);
      let valuesAndDisplayValues = [];
      valuesAndDisplayValues.push({
        text: customWorklistName,
        value: quicklistId,
      });

      let filterParam = new FilterParam(
        "Member Worklist",
        "eq",
        valuesAndDisplayValues,
        (x) => x.value,
        (x) => x.text
      );
      const tempFilterParams = Object.assign([], filterParams);
      tempFilterParams.push(filterParam);
      setDefaultFilterParamHelper(tempFilterParams);
      setTitle(customWorklistName);
      fetchData(tempFilterParams, "ALL_PATIENTS", customWorklistName, view);
      setFilterParam(tempFilterParams);
    } else if (quicklistId && quicklistId.includes("data")) {
      let patientFilters = preventiveCarePatientFilterParams;
      axios
        .get(
          process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/patients/overview/summary?patientId=${quicklistId}`,
          {
            headers: {},
          }
        )
        .then((response) => {
          patientFilters[0].displayValue[0] = response.data.lastName;
          patientFilters[0].displayValue[1] = response.data.firstName;
          patientFilters[0].paramValue[0] =
            response.data.lastName + ", " + response.data.firstName;
          patientFilters[1].displayValue[0] =
            "On " + formatDate(response.data.dateOfBirth);
          patientFilters[1].paramValue[0] = formatDate(
            response.data.dateOfBirth
          );

          setFilterButtons(true);
          setDefaultFilterParamHelper(patientFilters);
          setTitle(titleProp);
          fetchData(patientFilters, quickListType, titleProp, view);
        })
        .catch((error) => {
        });
    } else {
      setTitle(titleProp);
      setDefaultFilterParamHelper(defaultFilterParamsProp);
      setFilterButtons(false);

      if (defaultSortParamsProp) {
        setSortParams(defaultSortParamsProp);
        query(
          new PatientSearchDTO(
            defaultFilterParamsProp,
            defaultSortParamsProp,
            pageNumber,
            pageSize,
            quickListType
          ),
          titleProp,
          view,
        );
      } else {
        fetchData(defaultFilterParamsProp, quickListType, titleProp, view);
      }
    }
  }, [quicklistId]);

  const handleRemovablePatients = (selectedPatients) => {
    if (selectedPatients.size > 0) {
      setDisableButtons(false);
      checkRemovablePatients(selectedPatients);
    } else {
      setDisableButtons(true);
      setRemovablePatients([]);
    }
  };

  const checkRemovablePatients = async (selectedPatients) => {
    let globalPass = true;
    await selectedPatients.forEach((patient, id, map) => {
      if (!patient.global) {
        globalPass = false;
      }
    });
    if (globalPass) {
      setRemovablePatients(["true"]);
    } else {
      setRemovablePatients([]);
    }
  };

  return (
    <QuickListsContainer theme={theme} className="overflow-inherit">
      <Container fluid>
        <Row className="d-flex justify-content-between sticky-top pt-3 bg-content">
          <Col>
            <FilterGroup
              filters={filters}
              handleChange={handleFilterChange}
              totalSize={totalSize}
              defaultFilterParams={defaultFilterParams}
              filterParams={filterParams}
              sortParams={sortParams}
              pageNumber={pageNumber}
              pageSize={pageSize}
              quickListType={quickListType}
              setModalMessage={setModalMessage}
              setModalSize={setModalSize}
              setModalTitle={setModalTitle}
              hideSaveCustomFilterLists={true}
              customWorklistName={customWorklistName}
              additionalValidation={
                qlsSettings.additionalFilterValidation
              }
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              placeholder="Search by Name or ID"
              aria-describedby="basic-addon2"
              name="searchText"
              onKeyUp={(e) => searchFromInputText(e)}
              onChange={(e) => searchFromInputText(e)}
              autoComplete="off"
              id={`${componentName}-input`}
            />
          </Col>
        </Row>
        {children}
      </Container>
      {(loadingData || listViewLoadingData) && (
        <>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Text>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Modals
        show={modalMessage != null}
        onHide={() => setModalMessage(null)}
        title={modalTitle}
        size={modalSize || "md"}
        hideClickButton={true}
        showExtraButton={false}
        hideFooter={false}
        closeBtnId={`${componentName}-closeButton`}
        body={modalMessage}
      />
    </QuickListsContainer>
  );
};

export { QuickListForCareMgmt, VIEW };
