import React from 'react'
import {Col, Row} from "react-bootstrap";
import {formatDateModern} from "../../../../../../utils/util";

const componentName = 'PatientInformationSummary';

const ROW_LAYOUT = {
  ONE: 1,
  TWO: 2
}

function PatientInformationSummary({
  rowLayout = ROW_LAYOUT.ONE,
  fullName,
  memberId,
  dateOfBirth,
  className
}) {

  const OneRowLayout = () => {
    return <Row
      className={componentName + "-" + ROW_LAYOUT.ONE + (className ? ' '
        + className : '')}>
      <Col className={`${componentName}-PatientName`} column sm={4}>
        <span className="d-block forced-capital">{fullName}</span>
      </Col>
      <Col className={`${componentName}-MemberId`} column sm={4}>
        <label>ID:</label>&nbsp;<span>{memberId}</span>
      </Col>
      <Col className={`${componentName}-DOB`} column sm={4}>
        <label>DOB:</label>&nbsp;<span>{formatDateModern(dateOfBirth)}</span>
      </Col>
    </Row>
  }

  const TwoRowLayout = () => {
    return <Row
      className={componentName + "-" + ROW_LAYOUT.TWO + (className ? ' '
        + className : '')}>
      <Col className={`${componentName}-PatientName d-block`} column sm={7}>
        <label className="d-block fw-bold mb-1">Patient Name</label>
        <span className="d-block forced-capital">{fullName}</span>
      </Col>
      <Col column sm={5}>
        <div className={`${componentName}-MemberId`}>
          <label className="fw-bold mb-1">ID:</label>&nbsp;
          <span>{memberId}</span>
        </div>
        <div className={`${componentName}-DOB`}>
          <label className="fw-bold mb-1">DOB:</label>&nbsp;
          <span>{formatDateModern(dateOfBirth)}</span>
        </div>
      </Col>
    </Row>
  }

  switch (rowLayout) {
    case ROW_LAYOUT.ONE:
      return <OneRowLayout/>
    case ROW_LAYOUT.TWO:
      return <TwoRowLayout/>
    default:
      return null;
  }
}

export default PatientInformationSummary