import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";

const [getStates, useGetStates] = dataFetchHelper(
  ([valueAsAbbr, textAsAbbr, valueAllCaps, textAllCaps]) => {
    if (!valueAsAbbr) {
      valueAsAbbr = false;
    }
    if (!textAsAbbr) {
      textAsAbbr = false;
    }
    if (!valueAllCaps) {
      valueAllCaps = false;
    }
    if (!textAllCaps) {
      textAllCaps = false;
    }
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/constants/states?valueAsAbbr=${valueAsAbbr}&textAsAbbr=${textAsAbbr}&valueAllCaps=${valueAllCaps}&textAllCaps=${textAllCaps}`,
      {
        headers: {},
      }
    );
  }
);

const [getProperties, useGetProperties] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL + `/v1/constants/react-properties`,
    {
      headers: {},
    }
  );
});

const [getLanguages, useGetLanguages] = dataFetchHelper(() => {
  return axios.get(
    process.env.REACT_APP_REST_API_BASE_URL + `/v1/constants/languages`,
    {
      headers: {},
    }
  );
});



const [getRelationships, useGetRelationships] = dataFetchHelper(
  () => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL + `/v1/constants/address-sources`,
      {
        headers: {},
      }
    );
  }
);

const [getDropListByName, useGetDropListByName] = dataFetchHelper(
  (dropListName) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL + `/v1/configuration/care-management-configuration-filter?dropListName=${dropListName}&status=ACTIVE&isOrdered=false`,
      {
        headers: {},
      }
    );
  }
);

const [getSamlResponseforUniteUs, useSamlResponseforUniteUs] = dataFetchHelper(
  () => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL + `/v1/saml/response/?patientId=&clientId=AZEH&type=ADMIN`,
      
      {
        headers: {},
      }
    );
  }
);

const [getSamlResponseforFindHelp, useSamlResponseforFindHelp] = dataFetchHelper(
  () => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL + `/v1/saml/response/?patientId=&clientId=TXEH&type=ADMIN`,
      {
        headers: {},
      }
    );
  }
);



const stateAbbrMap = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MH: "Marshall Islands",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  "": "Unknown",
};

const stateAbbrReverseMap = {};
for (const abbr in stateAbbrMap) {
  if (Object.hasOwnProperty.call(stateAbbrMap, abbr)) {
    stateAbbrReverseMap[stateAbbrMap[abbr].toUpperCase()] = abbr;
  }
}

export {
  getStates,
  useGetStates,
  stateAbbrMap,
  getProperties,
  useGetProperties,
  getLanguages,
  useGetLanguages,
  getRelationships,
  useGetRelationships,
  getSamlResponseforUniteUs, 
  useSamlResponseforUniteUs,
  getSamlResponseforFindHelp,
  useSamlResponseforFindHelp,
  stateAbbrReverseMap,
  getDropListByName,
  useGetDropListByName
};
