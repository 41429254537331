import React from "react";
import { Badge } from "react-bootstrap";
import { formatDate, formatDateModern } from "../../../../../utils/util";

const componentName = "carePathClosed";

export const CarePathClosed = ({closedDate}) => {
  return (
    <>
      <Badge pill bg="secondary" className="ms-2">Closed on {formatDateModern(closedDate)}</Badge>
    </>
  );
};

export default CarePathClosed;