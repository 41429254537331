export function getGetters(state) {
    return {
        assessments: state.assessments,
        patientId: state.patientId,
        mode: state.mode,
        selectedTemplate: state.selectedTemplate,
        fillAssessment: state.fillAssessment,
        individualAssessmentCard: state.individualAssessmentCard,
        exportParams: state.exportParams,
        exportData: state.exportData,
        filterParams: state.filterParams,
        sortParams: state.sortParams,
        totalSize: state.totalSize
    }
}