import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../../context/authContext";

import { Container, Row, Col, Form, Button, Modal, Spinner, InputGroup, Alert } from 'react-bootstrap';
import Modals from "./modals/modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
    showPopulationSearchForm,
    hidePopulationSearchForm,
    onChangeField,
    hideAlert,
    hidePatientsFound,
    getPatients,
    showPatient,
    searchFromInputText,
    AddMemberToUser,
    searchFromInputTextOnChange,
    temporarilyHidePGS
} from "../../../actions/populationSearch/populationSearchActions";
import PatientRow from "../../quickList/components/PatientRow";
import axios from "../../../utils/apiClient";
import ScheduledAppointment from "../../quickList/components/modals/scheduleAppointment";
import {format} from "date-fns";

const componentName = "PopulationSearch";

const isEmpty = require("is-empty");

const AUTH_POPULATION_SEARCH_ACCESS = "AUTH_POPULATION_SEARCH_ACCESS";

const PopulationSearch = () => {
    const { searchText, addedUser } = useSelector(state => state.populationSearch);
    const state = useSelector(state => state.populationSearch);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();
    const [selectedPatient, setSelectedPatient] = useState();
    const [showSchedule, setShowSchedule] = useState(false);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const handleCloseSchedule = () => setShowSchedule(false);
    const handleShowSchedule = () => setShowSchedule(true);
    const [showConfirmUnschedule, setShowConfirmUnschedule] = useState(false);
    const [showNoShow, setShowNoShow] = useState(false);
    const handleShowNoShow = () => setShowNoShow(true);
    const handleCloseNoShow = () => setShowNoShow(false);
    const handleCloseConfirmUnschedule = () => setShowConfirmUnschedule(false);
    const handleShowConfirmUnschedule = () => setShowConfirmUnschedule(true);
    const [showConfirmUnableToContact, setShowConfirmUnableToContact] = useState(false);
    const handleCloseConfirmUnableToContact = () => setShowConfirmUnableToContact(false);
    const handleShowUnableToContact = () => setShowConfirmUnableToContact(true);
    const [showConfirmLeftMessage, setShowConfirmLeftMessage] = useState(false);
    const handleCloseConfirmLeftMessage = () => setShowConfirmLeftMessage(false);
    const handleShowLeftMessage = () => setShowConfirmLeftMessage(true);
    const [showConfirmChangedPCP, setShowConfirmChangedPCP] = useState(false);
    const handleCloseConfirmChangedPCP = () => setShowConfirmChangedPCP(false);
    const handleShowChangedPCP = () => setShowConfirmChangedPCP(true);
    const [showConfirmPatientRefused, setShowConfirmPatientRefused] = useState(false);
    const handleCloseConfirmPatientRefused = () => setShowConfirmPatientRefused(false);
    const handleShowPatientRefused = () => setShowConfirmPatientRefused(true);
    const [textToShow, settextToShow] = useState('');

    const handlePatientGapStatus = (patientGapStatus, patientGapAppointmentDate) => {
        let patientId = selectedPatient.id;
        let patientGap = {}
        if(patientGapStatus && patientGapStatus ==='NO_SHOW'){
          patientGap = {
            appointmentCancelReason: "NO_SHOW",
              gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(' ', 'T') + 'Z'
          };
        }
        else{
          patientGap = {
            patientGapStatus: patientGapStatus,
            patientGapAppointmentDate: patientGapAppointmentDate,
            gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(' ', 'T') + 'Z'
          };
    
        }
        return axios.post(
            process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/patients/updatePatientGapStatus?id=${patientId}`,
            patientGap,
            {
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                handleCloseConfirmUnableToContact();
                handleCloseConfirmLeftMessage();
                handleCloseConfirmChangedPCP();
                handleCloseConfirmPatientRefused();
            })
            .catch((error) => {
            });
    };

    const submitPatientToUser = async (patient) => {
        await setSelectedPatient(patient);
        await dispatch(AddMemberToUser(patient.id));
    }

    useEffect(() => {
        if (addedUser === true) {
            dispatch(showPatient(history, selectedPatient, location));
        }
    }, [addedUser]);

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (searchText) {
    //             dispatch(searchFromInputTextOnChange(searchText, auth));
    //         }
    //     }, 1600)

    //     return () => clearTimeout(timeout)
    // }, [searchText]);

    useEffect(() => {
        if (searchText !== '') {
            settextToShow(searchText)
        } else if (state?.searchForm?.lastName !== '' && state?.searchForm?.firstName !== '') {
            settextToShow(`${state.searchForm.firstName} ${state.searchForm.lastName}`)
        } else if (state?.searchForm?.memberId !== '') {
            settextToShow(state?.searchForm?.memberId)
        }
    }, [state])

    useEffect(() => {
        const unblock = history.block(() => {
            if (state.temporaryHidePSG) return true;
            if (state.isShowingForm || !isEmpty(state.patientsFound)) {
                dispatch(hidePopulationSearchForm())
                dispatch(hidePatientsFound())
                return false
            }
        })

        return () => {
            unblock()
        }
    }, [state.isShowingForm, state.patientsFound, state.temporaryHidePSG])

    return (
        <>
            <div id={`${componentName}-search`} className="me-2">
                <div className="d-inline-block">
                    <Spinner animation="border" variant="secondary" size="sm" className="me-2" style={{ display: state.isShowingSpinner ? "block" : "none" }} />
                </div>
                <div className="d-inline-block">
                    <Form autoComplete="new-password" id="menuPatientSearch" onSubmit={(e) => { e.preventDefault(); }}>
                        <InputGroup>
                            <Form.Control
                                placeholder={state.searchPlaceHolder}
                                aria-describedby="basic-addon2"
                                name="searchText"
                                value={state.searchText}
                                onKeyDown={(e) => dispatch(searchFromInputText(e, state.searchText, auth))}
                                onChange={e => dispatch(onChangeField(e))}
                                onClick={e => dispatch(onChangeField(state.searchPlaceHolder, "searchText"))}
            
                                autoComplete="off"
                                id={`${componentName}-input`}
                                type="search"
                            />
                            <div className="d-inline-block" style={{ display: auth.hasAuthority(AUTH_POPULATION_SEARCH_ACCESS) ? "block" : "none" }}>
                                <Button hidden={!auth.hasAuthority(AUTH_POPULATION_SEARCH_ACCESS)} variant="link" id={`${componentName}-menuGlobalPatientSearch`} className="fa-regular fa-globe h-100 text-dark text-decoration-none" onClick={() => dispatch(showPopulationSearchForm())}></Button>
                            </div>
                        </InputGroup>
                    </Form>
                </div>
            </div>
            
            <Modal size="lg" show={state.isShowingForm && isEmpty(state.patientsFound)} onHide={() => dispatch(hidePopulationSearchForm())} centered>
                <Modal.Header>
                    <Modal.Title id={`${componentName}-populationSearchModalTitle`}>Search Equality Health Network</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => dispatch(hidePopulationSearchForm())}></button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label id={`${componentName}-firstNameText`}>First Name</Form.Label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id={`${componentName}-firstNameValue`}
                                    value={state.searchForm.firstName}
                                    className="form-control text-capitalize"
                                    onChange={e => dispatch(onChangeField(e))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label id={`${componentName}-lastNameText`}>Last Name</Form.Label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id={`${componentName}-lastNameValue`}
                                    value={state.searchForm.lastName}
                                    className="form-control text-capitalize"
                                    onChange={e => dispatch(onChangeField(e))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label id={`${componentName}-dateOfBirthText-1`}>Date of Birth</Form.Label>
                                <DatePicker
                                    name="dateOfBirthFirstOption"
                                    id={`${componentName}-dateOfBirthValue-1`}
                                    selected={state.searchForm.dateOfBirthFirstOption}
                                    onChange={e => dispatch(onChangeField(e, "dateOfBirthFirstOption"))}
                                    className="form-control"
                                    autoComplete="off"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={1}>OR</Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label id={`${componentName}-memberIdText`}>Member Id</Form.Label>
                                <input
                                    type="text"
                                    name="memberId"
                                    id={`${componentName}-memberIdValue`}
                                    className="form-control text-capitalize"
                                    value={state.searchForm.memberId}
                                    onChange={e => dispatch(onChangeField(e))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label id={`${componentName}-dateOfBirthText-2`}>Date of Birth</Form.Label>
                                <DatePicker
                                    name="dateOfBirthSecondOption"
                                    id={`${componentName}-dateOfBirthValue-2`}
                                    selected={state.searchForm.dateOfBirthSecondOption}
                                    onChange={e => dispatch(onChangeField(e, "dateOfBirthSecondOption"))}
                                    className="form-control"
                                    autoComplete="off"
                                    popperPlacement="top"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p id={`${componentName}-instructionsWarning`} className="text-danger">By clicking "Search" below, I attest that I will access only those records that are required for the purposes of treatment and/or healthcare management of the patient.</p>
                            <p id={`${componentName}-instructionsNote`}><b>Note:</b> Users must capture a matching combination of Member ID and Date of Birth <b>OR</b> full Name and Date of Birth in order to access a member's record.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id={`${componentName}-searchButton`} variant="secondary" onClick={() => dispatch(hidePopulationSearchForm())}>
                        Cancel
                    </Button>
                    <Button id={`${componentName}-searchButton`} variant="primary" onClick={() => dispatch(getPatients(state.searchForm, auth))}>
                        Search
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modals
                show={!isEmpty(state.error)}
                onHide={() => {
                    dispatch(hideAlert());
                    if (state.doLogout) {
                        auth.logout();
                    }
                }}
                title="Search"
                modalTitleId={`${componentName}-searchTitle`}
                modalId={`${componentName}-searchModal`}
                size="lg"
                secondaryBtnId={`${componentName}-closeButton`}
                hidePrimaryBtn={true}
                hideFooter={false}
                body={<span>{state.error}</span>}
            />
            <Modal
                show={!isEmpty(state.patientsFound)}
                onHide={() => dispatch(hidePatientsFound())}
                size={"xl"}
                style={{ visibility: state.temporaryHidePSG ? 'hidden' : 'visible'}}
                backdrop={!state.temporaryHidePSG}
            >                {!isEmpty(state.patientsFound) && state.patientsFound.count > 0 && state.patientsFound.isPatientVisible && ([
                    <Modal.Header closeButton key={`${componentName}-patientModalHeader`} id={`${componentName}-patientModalHeader`} style={{ backgroundColor: "#F9F9FA" }}>
                        <Modal.Title id={`${componentName}-patientModalTitle`} className="w-100">
                            <b>Search Results</b>
                        </Modal.Title>
                    </Modal.Header>,
                    <Modal.Body key={"patientModalBody"}>
                        <Container>
                            <b>Showing Results for "{textToShow}"</b>
                        </Container>
                        <Container>
                            {state?.patientsFound?.quickListResponseDTO?.patientCardDtos?.map((patient, index) => {
                                return (
                                    <Row key={`${componentName}-patient-${index}`} id={`${componentName}-patient-${index}`} style={{ cursor: "pointer", ...(index % 2 == 1 ? { backgroundColor: "#F9F9FA" } : {}) }}>
                                        <PatientRow
                                            patient={patient}
                                            quickListType={'ALL_PATIENTS'}
                                            idx={index}
                                            isPatientSelected={() => false}
                                            setSelectedPatient={setSelectedPatient}
                                            setShowPatientDetails={setShowPatientDetails}
                                            showPatientDetails={showPatientDetails}
                                            pageTitle={'my title'}
                                            key={index}
                                            handleShowConfirmUnschedule={handleShowConfirmUnschedule}
                                            handleShowNoShow={handleShowNoShow}
                                            handleCloseNoShow={handleCloseNoShow}
                                            showNoShow={showNoShow}
                                            showConfirmUnschedule={showConfirmUnschedule}
                                            handleCloseConfirmUnschedule={handleCloseConfirmUnschedule}
                                            handlePatientGapStatus={handlePatientGapStatus}
                                            handleShowSchedule={handleShowSchedule}
                                            handleShowUnableToContact={handleShowUnableToContact}
                                            showConfirmUnableToContact={showConfirmUnableToContact}
                                            handleCloseConfirmUnableToContact={handleCloseConfirmUnableToContact}
                                            handleShowLeftMessage={handleShowLeftMessage}
                                            showConfirmLeftMessage={showConfirmLeftMessage}
                                            handleCloseConfirmLeftMessage={handleCloseConfirmLeftMessage}
                                            handleShowChangedPCP={handleShowChangedPCP}
                                            showConfirmChangedPCP={showConfirmChangedPCP}
                                            handleCloseConfirmChangedPCP={handleCloseConfirmChangedPCP}
                                            handleShowPatientRefused={handleShowPatientRefused}
                                            showConfirmPatientRefused={showConfirmPatientRefused}
                                            handleCloseConfirmPatientRefused={handleCloseConfirmPatientRefused}
                                            isGlobalSearch={true}
                                        />
                                    </Row>
                                )
                            })}
                        </Container>
                    </Modal.Body>
                ])}

                {!isEmpty(state.patientsFound) && state.patientsFound.count > 0 && !state.patientsFound.isPatientVisible && ([
                    <Modal.Header closeButton key={"patientModalHeader"} style={{ backgroundColor: "#F9F9FA" }}>
                        <Modal.Title className="w-100">
                            <Container><Row><Col><b>Search Results</b></Col></Row></Container>
                        </Modal.Title>
                    </Modal.Header>,
                    <Modal.Body key={"patientModalBody"}>
                        <Container>
                            <b>Showing Results for "{textToShow}"</b>
                        </Container>
                        <Container>
                            {state.patientsFound.quickListResponseDTO.patientCardDtos.map((patient, index) => {
                                return (
                                    <Row key={index} style={{ cursor: "pointer", ...(index % 2 == 1 ? { backgroundColor: "#F9F9FA" } : {}) }}>
                                        <PatientRow
                                            patient={patient}
                                            quickListType={'ALL_PATIENTS'}
                                            idx={index}
                                            isPatientSelected={() => false}
                                            setSelectedPatient={setSelectedPatient}
                                            setShowPatientDetails={setShowPatientDetails}
                                            showPatientDetails={showPatientDetails}
                                            pageTitle={'my title'}
                                            key={index}
                                            handleShowConfirmUnschedule={handleShowConfirmUnschedule}
                                            handleShowNoShow={handleShowNoShow}
                                            handleCloseNoShow={handleCloseNoShow}
                                            showNoShow={showNoShow}
                                            showConfirmUnschedule={showConfirmUnschedule}
                                            handleCloseConfirmUnschedule={handleCloseConfirmUnschedule}
                                            handlePatientGapStatus={handlePatientGapStatus}
                                            handleShowSchedule={handleShowSchedule}
                                            handleShowUnableToContact={handleShowUnableToContact}
                                            showConfirmUnableToContact={showConfirmUnableToContact}
                                            handleCloseConfirmUnableToContact={handleCloseConfirmUnableToContact}
                                            handleShowLeftMessage={handleShowLeftMessage}
                                            showConfirmLeftMessage={showConfirmLeftMessage}
                                            handleCloseConfirmLeftMessage={handleCloseConfirmLeftMessage}
                                            handleShowChangedPCP={handleShowChangedPCP}
                                            showConfirmChangedPCP={showConfirmChangedPCP}
                                            handleCloseConfirmChangedPCP={handleCloseConfirmChangedPCP}
                                            handleShowPatientRefused={handleShowPatientRefused}
                                            showConfirmPatientRefused={showConfirmPatientRefused}
                                            handleCloseConfirmPatientRefused={handleCloseConfirmPatientRefused}
                                            isGlobalSearch={true}
                                        />

                                        <Col>
                                            <p id={`${componentName}-patientNote-${index}`}><strong>Note:</strong> This patient is attributed to {patient.originatingOrganization}. Clicking "Yes" will add this patient to your Roster temporarily for 14 days, after which the patient will no longer be on your roster. Would you like to add the patient to your Roster?</p>
                                            <Modal.Footer>
                                                <Button id={`${componentName}-patientAddToWorklist-${index}`} variant="primary" onClick={() => submitPatientToUser(patient)}>
                                                    Yes
                                                </Button>
                                                <Button id={`${componentName}-patientDontAddToWorklist-${index}`} variant="secondary" onClick={() => dispatch(hidePatientsFound())}>
                                                    No
                                                </Button>
                                            </Modal.Footer>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Container>
                    </Modal.Body>
                ])}
                {selectedPatient && <ScheduledAppointment
                    show={showSchedule}
                    onHide={handleCloseSchedule}
                    handlePatientGapStatus={handlePatientGapStatus}
                    patient={selectedPatient}
                    patientGapStatus="APPOINTMENT_SCHEDULED"
                />}
            </Modal>
        </>
    );
};

export default PopulationSearch;