import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { carePathStepsList, notificationDelayList, units } from '../../constants'
import { getCarePaths, getSteps } from '../../context/api'
import { DropdownComponent } from '../../../../../../components/DropdownComponent'

function SecondPage({
  setActivePage,
  setFormBody,
  formBody,
  patient
}) {
  const [carePaths, setcarePaths] = useState([])
  const [careSteps, setCareSteps] = useState([])

  useEffect(() => {
    setFormBody({ ...formBody, patientId: patient?.id })
    getCarePaths(patient?.id)
      .then(res => setcarePaths([{text: '-Select-', value: ''}, ...res.data]))
  }, [])

  useEffect(() => {
    formBody?.carePathId && getCareSteps(formBody?.carePathId)
  }, [formBody?.carePathId])

  const getCareSteps = (id) => {
    getSteps(id).then(res => {
      if (res.data) {
        setCareSteps(
          [{title: '-Select-', value: null}, ...res.data.map(c => ({
            title: c.title,
            id: c.id
          }))]
      )}
    })
  }

  return (
    <Form className="second-page-form">
      <Row>
        <span
          style={{
            color: '#007db7',
            fontWeight: '500',
            cursor: 'pointer'
          }}
          onClick={() => setActivePage(1)}
        >
          <i className="fa fa-arrow-left" style={{ marginRight: '15px' }}></i>
          Back to Appointment Details
        </span>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Form.Label className="fw-bold">
          Patient Appointment Reminder
        </Form.Label>
        <Form.Check
          type={'radio'}
          label={`Yes`}
          style={{ margin: '10px 0' }}
          onClick={() => setFormBody({ ...formBody, shouldSendReminder: true})}
          checked={formBody.shouldSendReminder}
        />
        <Form.Check
          type={'radio'}
          label={`No`}
          onClick={() => setFormBody({ ...formBody, shouldSendReminder: false})}
          checked={!formBody.shouldSendReminder}
        />
      </Row>
      <Row>
        <Col style={{ padding: 0, marginRight: '15px' }}>
          <DropdownComponent
            name="delay"
            list={notificationDelayList.map(i => ({value: i.value, text: i.text}))}
            handleInputChange={(_, type) => setFormBody({ ...formBody, notificationDelay: type.value })}
            label="Notification Delay"
            required={true}
            isOpen={true}
            val={formBody?.notificationDelay}
          />
        </Col>
        <Col style={{ padding: 0 }} md={4}>
          <DropdownComponent
            name="unit"
            list={units.map(i => ({value: i.value, text: i.text}))}
            handleInputChange={(_, type) => setFormBody({ ...formBody, notificationDelayUnit: type.value })}
            label="Unit"
            required={true}
            isOpen={true}
            val={formBody?.notificationDelayUnit}
          />
        </Col>
      </Row>
      <Row >
        <Form.Label className="fw-bold">
          Patient Needs Transportation
        </Form.Label>
        <Form.Check
          type={'radio'}
          label={`Yes`}
          style={{ margin: '10px 0' }}
          onClick={() => setFormBody({ ...formBody, needsTransportation: true})}
          checked={formBody.needsTransportation}
        />
        <Form.Check
          type={'radio'}
          label={`No`}
          onClick={() => setFormBody({ ...formBody, needsTransportation: false})}
          checked={!formBody.needsTransportation}
        />
      </Row>
      <Row>
        <DropdownComponent
          name="carePaths"
          list={carePaths.map(i => ({value: i.id, text: i.name}))}
          handleInputChange={(_, type) => setFormBody({ ...formBody, carePathId: type.value, carePathStepId: null })}
          label="Care Program"
          required={false}
          isOpen={true}
          val={formBody?.carePathId}
          disabled={carePaths?.length === 0}
        />
      </Row>
      <Row>
        <DropdownComponent
          name="carePathStepId"
          list={careSteps.map(i => ({value: i.id, text: i.title}))}
          handleInputChange={(_, type) => setFormBody({ ...formBody, carePathStepId: type.value })}
          label="Care Step"
          required={false}
          isOpen={true}
          val={formBody?.carePathStepId ? careSteps.find(o => o.id === formBody?.carePathStepId)?.id : careSteps?.[0]}
        />
      </Row>
      <Row>
      <DropdownComponent
          name="carePathEvent"
          list={carePathStepsList.map(i => ({value: i.value, text: i.text}))}
          handleInputChange={(_, type) => setFormBody({ ...formBody, carePathEvent: type.value })}
          label="Care Program Event"
          required={false}
          isOpen={true}
          val={formBody?.carePathEvent}
        />
      </Row>
      <Row>
        <Form.Label className="fw-bold">
          Add A Note
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={(e) => setFormBody({ ...formBody, note: e.target.value })}
          value={formBody?.note}
        />
      </Row>
    </Form>
  )
}

export default SecondPage