import React, { useEffect, Fragment, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NewAssessmentModal from "./NewAssessmentModal";
import { formatDateTime } from "../../../utils/util";
import axios from "../../../utils/apiClient";
import { Card } from "react-bootstrap";
import {
  getExportPatientAssessments,
  postReportExtract,
} from "../../../actions/patientProfileActions";
import { SUMMARY_URL } from "../../quickList/Appointments";
import ExportSummary from "../../quickList/ExportSummary";
import Modals from "../../../components/modals";
import { useAuth } from "../../../context/authContext";
import { EQHSpinner } from "../../../components/EQHSpinner";

const componentName = "patientProfile-Assessments";

let selectedAssessment = null;

const Assessment = ({ patientId, selectedTab, reloadPatientOverview }) => {
  const auth = useAuth();
  const [newAssessment, setnewAssessmentShow] = useState(false);
  const newAssessmentClose = () =>{
    setIsSaving(false);
    getAssessmentCards(patientId);
    setnewAssessmentShow(false);
  }

  const newAssessmentShow = () => setnewAssessmentShow(true);

  const [fillAssessment, setfillAssessmentShow] = useState(false);
  const fillAssessmentClose = () => {
    setfillAssessmentShow(false);
  };
  const fillAssessmentShow = () => setfillAssessmentShow(true);
  const [assessmentList, setAssessmentList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const isEmpty = require("is-empty");
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSize, setModalSize] = useState(null);

  const [detailAssessment, setDetailAssessment] = useState(false);

  const [mode, setMode] = useState({});

  const [individualAssessmentCard, setIndividualAssessmentCard] = useState({});

  const detailAssessmenDisplay = () => setDetailAssessment(true);
  const detailAssessmenClose = () => setDetailAssessment(false);
  // to display when automatic saving occurs
  const [isSaving, setIsSaving] = useState(false);
  const [filledAssessmentModal, setfilledAssessmentModal] = useState(false);

  const filledAssessmentModalClose = () => {
    setfilledAssessmentModal(false);
  };
  const filledAssessmentModalShow = () => setfilledAssessmentModal(true);
  const detailAssessmenOpen = () => setDetailAssessment(true);

  const [detailAssessmentDisplay, setDetailAssessmentDisplay] = useState([]);

  useEffect(() => {
    if (selectedTab) {
      getAssessmentCards(patientId);
      getTemplateList(patientId);
    }
  }, [patientId, selectedTab]);

  const getAssessmentCards = (patientId) => {
    setIsLoading(true);
    axios
      .get(
        baseURL + `/v1/assessments/assessment-cards?patientId=${patientId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data && !isEmpty(response.data)) {
          setAssessmentList(response.data);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("An error occurred while processing your request.");
      });
  };

  const getTemplateList = (patientId, fromDetail) => {
    const id = encodeURIComponent(patientId);
    axios
      .get(baseURL + `/v1/assessments/patient-templates?patientId=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data && !isEmpty(response.data)) {
          if (fromDetail) {
            detailAssessmenDisplay();
          }
          setTemplateList(response.data);
        }
      })
      .catch(() => {
        setErrorMessage("An error occurred while processing your request.");
      });
  };

  const getAssessment = (id, fromDetail) => {
    const assessmentId = encodeURIComponent(id);
    axios
      .get(
        baseURL +
        `/v1/assessments/assessment-by-id?assessmentId=${assessmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data && !isEmpty(response.data)) {
          if (fromDetail) {
            detailAssessmenDisplay();
          }
          const assess = createAssessmentDisplay(response.data);
          setIndividualAssessmentCard(response.data);
          setDetailAssessmentDisplay(assess);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("An error occurred while processing your request.");
      });
  };

  const saveNewTemplate = (payload, status, isDelete, id) => {
    let wholeUrl = baseURL + `/v1/assessments/save`;
    setIsSaving(true)
    const finalPayload = {
      id: id,
      patientId: patientId,
      templateId: selectedTemplate?.templateId,
      answers: payload,
      status: status,
    };
    if (isDelete && id) {
      wholeUrl = baseURL + `/v1/assessments/cancel-draft`;
      axios
        .post(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadPatientOverview();
          if (response.data && !isEmpty(response.data)) {
            setAssessmentList(response.data);
            fillAssessmentClose();
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsSaving(false);
          setErrorMessage("An error occurred while processing your request.");
        });
    } else if (!isDelete && id) {
      wholeUrl = baseURL + `/v1/assessments/save`;
      axios
        .put(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadPatientOverview();
          if (response.data && !isEmpty(response.data)) {
            setAssessmentList(response.data);
            fillAssessmentClose();
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsSaving(false);
          setErrorMessage("An error occurred while processing your request.");
        });
    } else if (!isDelete && !id) {
      wholeUrl = baseURL + `/v1/assessments/save`;
      axios
        .post(wholeUrl, finalPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          reloadPatientOverview();
          if (response.data && !isEmpty(response.data)) {
            setAssessmentList(response.data);
            fillAssessmentClose();
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsSaving(false);
          setErrorMessage("An error occurred while processing your request.");
        });
    }
    newAssessmentClose();
  };
  const handleChangeTemplate = (e) => {
    const { name, value } = e.target;
    setSelectedTemplate({ templateName: name, templateId: value });
    setIndividualAssessmentCard({});
    setMode("new");
    fillAssessmentShow();
  };

  const handleClickButton = (e) => {
    setSelectedTemplate({
      templateName: individualAssessmentCard?.formTemplate?.name,
      templateId: individualAssessmentCard?.templateId,
    });
    setMode("edit");
    fillAssessmentShow();
  };

  const showDetail = (assessment) => {
    getAssessment(assessment.id, true);
  };
  const createAssessmentDisplay = (assessment) => {
    let qAndA = [];
    if (assessment && !isEmpty(assessment.formTemplate.questions)) {
      assessment.formTemplate.questions.forEach((qqq) => {
        const answer = findAnswer(qqq, assessment);
        const obj = { q: qqq.questionText, a: answer };
        qAndA.push(obj);
      });
      return qAndA;
    }
  };
  function findAnswer(k, assessment) {
    let answer = [];
    for (const [key, value] of Object.entries(assessment?.answers)) {
      if (key.includes(k.id)) {
        answer = value;
      }
    }
    return answer;
  }

  const exportReport = () => {
    let mock = selectedAssessment;
    getExportPatientAssessments(patientId, selectedAssessment.id, () => {
      summary();
    });
  };

  const exportReportExtract = () => {
    postReportExtract(patientId, () => {
      summary();
    });
  };

  const summary = () => {
    axios
      .get(SUMMARY_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
      })
      .catch(() => {
        setModalTitle("Error");
        setModalMessage("An error occurred while processing your request.");
      });
  };

  return (
    <Fragment>
      <Container fluid>
        <Card>
          <Card.Body>
            {isLoading && <EQHSpinner />}
            <Row className="mb-3">
              <Col>
                {auth.hasAuthority("AUTH_ASSESSMENTS_WRITE_ACCESS") && (
                  <Button id={`${componentName}-newAssessmentButton`} variant="primary" onClick={newAssessmentShow}>
                    New Assessment
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {assessmentList && assessmentList.length ? (
                assessmentList.map((assessment, idx) => (
                  <Col md={4} className="mb-3">
                    <div id={`${componentName}-assessmentItem-${idx}`} className="bg-white shadow-sm border p-2 font-12">
                      <div className="d-block">
                        <Button
                          variant="link"
                          id={`${componentName}-assessmentName-${idx}`}
                          className="p-0 fw-bold text-start"
                          onClick={() => {
                            showDetail(assessment);
                            selectedAssessment = assessment;
                          }}
                        >
                          {assessment.name}
                        </Button>
                      </div>
                      <small id={`${componentName}-assessmentSubmitted-${idx}`} className="d-block">
                        {formatDateTime(assessment.submitted)}
                      </small>
                      <div id={`${componentName}-assessmentCoordinator-${idx}`} className="mb-2 card-title">
                        <div id={`${componentName}-assessmentCoordinatorLabel-${idx}`} className="d-block fw-bold">
                          Coordinator
                        </div>
                        <div id={`${componentName}-assessmentCoordinatorValue-${idx}`} className="d-block">
                          {assessment.coordinatorName}
                        </div>
                      </div>
                      <div id={`${componentName}-assessmentStatus-${idx}`} className="mb-2 card-title">
                        <div id={`${componentName}-assessmentStatusLabel-${idx}`} className="d-block fw-bold">Status</div>
                        <div id={`${componentName}-assessmentStatusValue-${idx}`} className="d-block">{assessment.status}</div>
                        {!isEmpty(assessment.scores) &&
                        <div className="d-block">
                          {
                            new Map(Object.entries(assessment?.scores))
                              ?.keys()
                              ?.next()?.value
                          }:{
                          new Map(Object.entries(assessment?.scores))
                            ?.values()
                            ?.next()?.value
                        }
                        </div>
                        }
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div>
                  <p id={`${componentName}-noRecords`} className="ms-3">
                    {"No Records Found"}
                  </p>
                </div>
              )}
            </Row>
            <Modal
              show={newAssessment}
              onHide={newAssessmentClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id={`${componentName}-newAssessmentModal`}>New Assessment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group id={`${componentName}-assessmentTemplate`} controlId="exampleForm.ControlSelect1">
                    <Form.Label id={`${componentName}-assessmentTemplateLabel`}>
                      Assessment Template<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id={`${componentName}-assessmentTemplateValue`}
                      className="form-control"
                      name="template"
                      onChange={(e) => handleChangeTemplate(e)}
                    >
                      <option value={null}>--Select--</option>
                      {templateList.map((template) => (
                        <option
                          id={`${componentName}-assessmentTemplateItem`}
                          key={template.templateId}
                          value={template.templateId}
                        >
                          {template.templateName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button id={`${componentName}-newAssessmentCancel`} variant="secondary" onClick={newAssessmentClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={detailAssessment}
              onHide={detailAssessmenClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id={`${componentName}-viewAssessment`}>
                  View Assessment - {individualAssessmentCard?.formTemplate?.name}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col>
                      {
                        detailAssessmentDisplay &&
                        detailAssessmentDisplay?.length &&
                        detailAssessmentDisplay.map((qAndA, idx) => (
                          //v.map((vl)=>(
                          <div id={`${componentName}-viewAssessmentQA-${idx}`} className="mb-2">
                              <span id={`${componentName}-viewAssessmentQ-${idx}`} className="fw-bold d-block">
                                {qAndA.q}
                              </span>
                            <div id={`${componentName}-viewAssessmentA-${idx}`}>
                              {qAndA?.a?.map((m) => (
                                <span id={`${componentName}-viewAssessmentAText-${idx}`} className="d-block">{m.text}</span>
                              ))}
                            </div>
                          </div>
                        ))
                        //))
                      }

                      <div className="mb-2">
                        {!isEmpty(individualAssessmentCard.scores) && (
                          <div id={`${componentName}-viewAssessmentScores`}>
                            <span id={`${componentName}-viewAssessmentScoresLabel`} className="fw-bold d-block">Scores</span>
                            <span id={`${componentName}-viewAssessmentScoresValue`} className="d-block">
                              {
                                new Map(
                                  Object.entries(
                                    individualAssessmentCard?.scores
                                  )
                                )
                                  ?.keys()
                                  ?.next()?.value
                              } :
                              {
                                new Map(
                                  Object.entries(
                                    individualAssessmentCard?.scores
                                  )
                                )
                                  ?.values()
                                  ?.next()?.value
                              }

                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {individualAssessmentCard &&
                individualAssessmentCard.status === "Draft" && (
                  <Button id={`${componentName}-viewAssessmentEditButton`} variant="secondary" onClick={handleClickButton}>
                    Edit
                  </Button>
                )}
                <Button id={`${componentName}-viewAssessmentExportButton`} variant="secondary" onClick={exportReport}>
                  Export
                </Button>
                <Button id={`${componentName}-viewAssessmentCloseButton`} variant="primary" onClick={detailAssessmenClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modals
              show={modalMessage != null}
              onHide={() => setModalMessage(null)}
              title={modalTitle}
              size={modalSize || "md"}
              hideClickButton={false}
              showExtraButton={false}
              hideFooter={true}
              body={modalMessage}
            />
            {fillAssessment && (
              <NewAssessmentModal
                saveNewTemplate={saveNewTemplate}
                fillAssessment={fillAssessment}
                fillAssessmentClose={fillAssessmentClose}
                selectedTemplate={selectedTemplate}
                individualAssessmentData={individualAssessmentCard}
                mode={mode}
                patientId={patientId}
                isSaving ={isSaving}
                detailAssessmenClose={detailAssessmenClose}
              ></NewAssessmentModal>
            )}
          </Card.Body>
        </Card>
      </Container>
    </Fragment>
  );
};

export default Assessment;
