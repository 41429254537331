import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import cignaLogoImage from "../../img/cigna/cigna-logo.jpg";
import cignaCommunityEmbraceImage from "../../img/cigna/cigna-ce-text.png";
import backgroundImage from "../../img/cigna/cigna_login.jpg";

import { Alert } from "../../components/alerts";
import {
  LoginCard,
  Header,
  LoginButton,
  LoginForm,
  ForgotPasswordLink,
  TermsAndConditionLink,
} from "./loginStyles";
import ResetPasswordView from "./ResetPassword";
import RecoverPassword from "./RecoverPassword";
import Message from '../admin/broadcastMessenger/message';
import { SESSION_HAS_EXPIRED } from "../../context/authContext";

const CignaLoginCard = styled(LoginCard)`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  }
`;

const StyledCignaLogin = styled.div`
  width: 100%;
  height: 100%;
  background: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const LoginPadding = styled.div`
  padding: 5% 0;
`;

const Logo = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 17px;

  .cigna-logo {
    width: 150px;
    margin-bottom: 17px;
  }

  .cigna-text {
    width: 300px;
  }
`;

const TermsOfUsePdf = process.env.REACT_APP_REST_API_BASE_URL + "/system_files/Terms_of_Use.pdf";
const PrivacyPolicyPdf = "/docs/Privacy_Policy.pdf";
const UserAccessAgreement = "/docs/CareEmpower_User_Access_Agreement.pdf";

const CignaLogin = ({ onLogin, error, sessionExpired }) => {
  return (
    <StyledCignaLogin>
      <Message />
      <LoginPadding>
        <CignaLoginCard>
          <CignaLoginCard.Body>
            <Logo>
              <img className="cigna-logo" src={cignaLogoImage} alt="Cigna Logo" />
              <br />
              <img
                className="cigna-text"
                src={cignaCommunityEmbraceImage}
                alt="Community Embrace"
              />
            </Logo>
            <Switch>
              <Route path="/recover-password" component={RecoverPassword} />
              <Route
                path="/reset-password/:token"
                component={ResetPasswordView}
              />
              {/* Login is the last route in this list (and without a path) to make sure it's displayed if no other matching routes */}
              <Route
                component={() => (
                  <Login
                    onLogin={onLogin}
                    sessionExpired={sessionExpired}
                    error={error}
                  />
                )}
              />
            </Switch>
          </CignaLoginCard.Body>
        </CignaLoginCard>
      </LoginPadding>
    </StyledCignaLogin>
  );
};

const Login = ({ error, onLogin, sessionExpired }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const sessionHasExpired = sessionStorage.getItem(SESSION_HAS_EXPIRED);
  const isEmpty = require("is-empty");

  useEffect(() => {
    if (!isEmpty(error)) {
      let errorMessage;
      if (error && typeof error == "string") {
        errorMessage = error;
      } else if (error && error.response && error.response.data) {
        if (error.response.data.error_description) {
          errorMessage = error.response.data.error_description;
        } else if (error.response.data.message) {
          errorMessage = error.response.data.error_description;
        }
      } else if (error && error.message && !errorMessage) {
        errorMessage = error.message;
      }
      setErrorToDisplay(errorMessage);
    } else {
      setErrorToDisplay("");
    }
  }, [error]);
  return (
    <LoginForm
      onSubmit={e => {
        e.preventDefault();
        onLogin(username, password);
      }}
    >
      <Header>Sign In</Header>
      {sessionHasExpired && (
        <Alert variant="warning">
          You were logged out due to inactivity
        </Alert>
      )}
      {errorToDisplay && <Alert variant="danger">{errorToDisplay}</Alert>}
      <LoginForm.Group>
        <LoginForm.Control
          type="text"
          autoComplete="username"
          required
          placeholder="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </LoginForm.Group>
      <LoginForm.Group>
        <LoginForm.Control
          type="password"
          autoComplete="current-password"
          required
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />
      </LoginForm.Group>
      <TermsAndConditionLink>
        By using CareEmpower, I agree to the <br/>
        <a href={TermsOfUsePdf} target="blank">Terms of Use</a>
        &#44;
        <a href={PrivacyPolicyPdf} target="blank"> Privacy Policy </a>
        and <br/>
        <a href={UserAccessAgreement} target="blank"> User Access Agreement </a>
      </TermsAndConditionLink>
      <ForgotPasswordLink to="/recover-password">
        Forgot password?
      </ForgotPasswordLink>
      <LoginButton type="submit">Log In</LoginButton>
    </LoginForm>
  );
};

export default CignaLogin;
