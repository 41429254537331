import React, {useEffect, useState} from 'react'
import {Timeline} from '../../../../../components/Timeline'
import AppointmentsTimeLineTitle from './AppointmentsTimeLineTitle'
import AppointmentsCard from './AppointmentsCard'
import FilterBar from './FilterBar'
import {Button, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {useAppointmentsContext} from '../context/Appointments.context'
import AppointmentModal from './AddAppointmentModal/AppointmentModal'
import {useAuth} from '../../../../../context/authContext'
import {initialForm} from '../constants'
import CancelAppointmentModal
  from "./CancelAppointmentModal/CancelAppointmentModal";
import { useSelector } from 'react-redux'
import axios from '../../../../../utils/apiClient'

function Appointments({patient}) {
  const {
    getAppointments,
    toggleAddNewAppointment,
    toggleShowAddAppointment,
    toggleIsEdit,
    appointments,
    totalSize,
    setAppointments,
    setTotalSize
  } = useAppointmentsContext();

  const pcp = useSelector((state) => state?.manageVisitReducer?.pcpID);
  const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/appointment";

  const auth = useAuth();
  const hasWriteAccess = auth.hasAuthority("AUTH_PD_APPOINTMENTS_WRITE_ACCESS");
  const [formBody, setFormBody] = useState(initialForm);
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [selectedPCP, setSelectedPCP] = useState({});

  const setAppointmentsHandler = (appointmentFormDtos, totalSize) => {
    setAppointments(appointmentFormDtos)
    setTotalSize(totalSize)
  }

  useEffect(() => {
    getAppointments(patient?.id)
    if (pcp?.id) {
      handleSearch()
    }
  }, [])

  const handleSearch = () => {
    const patientId =
      patient && patient.id ? encodeURIComponent(patient.id) : "";
    const url =
      baseURL +
      "/get-providers" +
      "?providerName=" + pcp?.name.split(" ")[0]  +
      (patientId ? "&patientId=" + patientId : "");
    axios.get(url, { headers: {} })
      .then((resp) => {
        const pcpData = resp?.data
        setSelectedPCP(pcpData.filter(p => p.id === pcp?.id)[0])
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    if (appointments) {
      setOpenStates(prevStates => {
        const newStates = new Array(appointments.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [appointments])

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  return (
    <>
      <AppointmentModal
        patient={patient}
        getAppointments={() => getAppointments(patient?.id)}
        formBody={formBody}
        setFormBody={setFormBody}
        selectedPCP={selectedPCP}
      />
      <CancelAppointmentModal
        appointment={formBody}
      />
      <Row className="align-items-center px-3">
        <Col>
          <FilterBar patient={patient} setAppointments={setAppointmentsHandler} />
        </Col>
        <Col style={{ textAlign: 'end' }}>
          <Button onClick={() => {
            toggleAddNewAppointment(true)
            toggleShowAddAppointment(true)
            toggleIsEdit(false)
            }}
            disabled={!hasWriteAccess}
          >Add Appointment
          </Button>
        </Col>
      </Row>
      <Row className='align-items-baseline'>
        <Col>
            <span
              bg="light"
              id={`appointmentEventsCount`}
              className="border text-dark badge badge-pill mt-4">
              {totalSize}{" "}{"Appointment"}{totalSize === 1 ? '' : 's'}
            </span>
        </Col>
        <Col md={6} className='text-end'>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip>
                Expand / Collapse All
              </Tooltip>
            }
          >
            <span
              bg="light"
              className="border text-dark badge badge-pill mt-2"
              onClick={() => toggleAll(!expandAll)}
              style={{maxWidth: 'fit-content', fontSize: '12px'}}
            >
              <i className='fas fa-angle-double-up'/>
              <i className='fas fa-angle-double-down'/>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      <Timeline
        items={appointments}
        titleBuilder={(appointment, idx) => <AppointmentsTimeLineTitle
          appointment={appointment}
          idx={idx}
        />}
        bodyBuilder={(appointment, idx) => <AppointmentsCard
          setFormBody={setFormBody}
          appointment={appointment}
          idx={idx}
          toggleIndividual={() => toggleIndividual(idx)}
          open={openStates[idx]}
        />} />
    </>
  )
}

export default Appointments