import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import './modals.css'
import { useReferralsContext } from '../context/Referrals.context';
import { setModalOpen, setReloadWorklist } from '../../../../actions/manageVisitActions';
import { useDispatch } from 'react-redux';
import { updateReferral } from '../context/api';
import { useGetPatientProfileReferrals } from '../../../../actions/patientProfileActions';
import { referralsReducer } from '../../../../reducers/patientProfile/referralsReducer';

function ReferralModal({
  title,
  patient,
  setModal,
  showModal,
  status,
  children,
  disabled
}) {
  const referralsPkg = useGetPatientProfileReferrals([], referralsReducer);

  const {
    referral,
    setReferral,
    query,
    setQuery
  } = useReferralsContext();

  const dispatch = useDispatch();

  const handleClose = () => {
    setModal(false);
    dispatch(setModalOpen(false));
  }

  const handleUpdate = () => {
    updateReferral({
      ...referral,
      ...query,
      status,
    })
      .then(() => {
        setReferral(referralsPkg.fetchData(patient?.id))
        dispatch(setReloadWorklist(true))
        handleClose()
      })
      .catch()
  }

  useEffect(() => {
    setQuery({})
  }, [showModal])

  return (
    <Modal
      onHide={() => handleClose(false)}
      show={showModal}
      size="md"
      dialogClassName="modal-90vw"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <p className="fw-bold position-absolute cursor-p"
          style={{
            top: '15px',
            right: '15px'
          }}
          onClick={() => handleClose(false)}
        >X</p>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div>
          <p className="fw-bold mb-0" style={{ fontSize: '12px' }}>Patient Name</p>
          <p className="fw-normal text-capitalize" style={{ fontSize: '12px' }}>{patient?.fullName}</p>
        </div>
        {children}
        <div>
          <p className="fw-bold mb-0" style={{ fontSize: '12px' }}>Notes</p>
          <Form.Control as="textarea" style={{ height: '150px' }} maxLength={5000} onChange={e => setQuery({ ...query, notes: e.target.value })} />
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button style={{ width: '45%' }} variant="secondary" onClick={() => handleClose(false)}>
          Close
        </Button>
        <Button style={{ width: '45%' }} variant="primary" onClick={() => handleUpdate()} disabled={disabled}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReferralModal