import React from 'react'
import { Header } from './components'
import Modals from './Modals'
import { ReferralsProvider } from './context/Referrals.context'

function ReferralTab({ patient }) {
  return (
    <ReferralsProvider>
        <Header patient={patient} />
        <Modals patient={patient} />
    </ReferralsProvider>
  )
}

export default ReferralTab