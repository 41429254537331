export const urgencyOptions = [
  {
    text: "Emergent: Must respond same day",
    value: "40_EMERGENT",
  },
  {
    text: "Priority: Must respond within 4-7 days",
    value: "20_PRIORITY",
  },
  {
    text: "Routine: Must respond within 8-14 days",
    value: "10_ROUTINE",
  },
];

export const convertIcd10 = (referralObj) => {
  const newArray = referralObj?.conditions;
  const result = [];
  newArray.forEach(function (arrayItem) {
    let newItem = arrayItem;
    newItem.code = newItem.code.toString();
    newItem.description = newItem.description.toString();
    result.push(newItem);
  });
  return result;
};

export const isFormValid = (referralObj, patient) => {
  const {
    referralType,
    urgency,
    comments,
    servicesNeeded,
  } = referralObj;
  if (referralType && patient !== undefined) {
    if (
      referralType === "CARE_COORDINATION" &&
      servicesNeeded.length && isPrimaryServiceSelected(servicesNeeded) &&
      urgency &&
      comments
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const isPrimaryServiceSelected = (servicesNeeded) => {
  if (servicesNeeded && servicesNeeded.length > 0){
    return servicesNeeded.some(service => service.primary)
  }
  return false;
}