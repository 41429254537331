import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import DropDownMenu from '../../../../caremgmt/worklist/components/DropDownMenu';
import styled from 'styled-components';

import { useAssessmentsContext } from '../context/Assessments.context';
import { useAuth } from '../../../../../context/authContext';

import { getAssessmentById, removeAssessment } from '../context/api';
import DeleteAssessment from './DeleteAssessment';

const componentName = 'AssessmentsCardClosed'

const assessmentsActions = (status, hasWriteAccess) => {
  if (status === 'Draft') {
    return [
      {
        value: "Edit",
        text: "Edit",
        hasDivider: false,
        disabled: !hasWriteAccess,
        condition: true,
      },
      {
        value: "Delete",
        text: "Delete",
        hasDivider: false,
        disabled: !hasWriteAccess,
        condition: true,
      }
    ]
  } else {
    return  [
    {
      value: "Export",
      text: "Export",
      hasDivider: false,
      disabled: !hasWriteAccess,
      condition: true,
    },
  ]
}}

const AssessmentsBadgeStyled = styled.label`
  color: ${(props) => props.status === 'Completed' ? '#9fa1a3' : '#e0791e'};
  background-color: ${(props) => props.status === 'Completed' ? '#eaebeb' : '#f9e4d1'};
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
`;

const AssessmentsBadgeStyle = ({ status }) => {
  return (
    <AssessmentsBadgeStyled
      status={status}
      className="badge rounded-pill border-0"
    >
      {status}
    </AssessmentsBadgeStyled>
  );
};

function AssessmetsCardClosed({ assessment, setOpen, opened, idx }) {
  const {
    toggleAddNewAssessment,
    setMode,
    setSelectedTemplate,
    setfillAssessment,
    setIndividualAssessmentCard,
    patientId,
    exportToCSV,
    reloadAssessments
  } = useAssessmentsContext();

  const auth = useAuth();
  const hasWriteAccess = auth.hasAuthority("AUTH_PD_ASSESSMENTS_WRITE_ACCESS");

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const handleChangeTemplate = (item) => {
    const value = item?.value
    switch (value) {
      case 'Edit':
        handleEdit()
        break;
      case 'Export':
        exportToCSV({
          patientId: patientId,
          answerSetId: assessment.id
        });
        break;
      case 'Delete':
        setshowDeleteModal(true)
      default:
        break;
    }
  };

  const handleEdit = () => {
    getAssessmentById(assessment?.id)
      .then(res => {
        setSelectedTemplate({
          templateName: res?.data?.formTemplate?.name,
          templateId: res?.data?.templateId,
        });
        setIndividualAssessmentCard(res?.data)
      })
      .finally(() => {
        toggleAddNewAssessment(true)
        setMode('edit')
        setfillAssessment(true)
      })
  }

  const handleDelete = () => {
    removeAssessment({
      patientId: patientId,
      answerSetId: assessment?.id
    }).then(() => {
      setshowDeleteModal(false)
      reloadAssessments()
    })
  }

  return (
    <>
      <DeleteAssessment
        handleDelete={handleDelete}
        showDeleteModal={showDeleteModal}
        setshowDeleteModal={setshowDeleteModal}
        name={assessment?.name}
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center card-header">
          <Button id={`${componentName}-ccTitle-${idx}`} onClick={() => setOpen(!opened)} variant="link" className="text-start text-dark p-0 text-decoration-none btn btn-link w-50">
            <div className="d-flex align-items-center">
              {!opened
                ? <i id={`${componentName}-ccPlus-${idx}`}
                  className="fa-solid fa-chevron-right me-4" />
                : <i id={`${componentName}-ccMinus-${idx}`}
                  className="fa-solid fa-chevron-down me-4" />}
              <div className="d-inline-block">
                <span id={`${componentName}-ccTitle-${idx}`} className="fs-5 fw-bold mb-1 d-block lh-1">{assessment?.name}</span>
                <span id={`${componentName}-ccSubTitle-${idx}`} className="text-muted">{assessment?.coordinatorName}</span>
              </div>
            </div>
          </Button>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-end">
              <AssessmentsBadgeStyle status={assessment?.status} />
            </div>
            <div className="text-end">
              <div id={`${componentName}-AssessmentsActions`} className="btn-group">
                <DropDownMenu
                  idx={idx}
                  items={assessmentsActions(assessment?.status, hasWriteAccess)}
                  onItemClick={(item) => handleChangeTemplate(item)} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default AssessmetsCardClosed