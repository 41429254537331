import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../utils/apiClient";
import { useAuth } from "../../context/authContext";

import { Navbar, Container, Nav } from "react-bootstrap";
import Modals from "../../components/modals";

import PopulationSearch from "./components/populationSearch";
import Support from "./components/support";
import ContactSupport from "./components/modals/contact";
import Resources from "./components/modals/resources";
import About from "./components/modals/about";
import Messages from "./components/messages";
import Account from "./components/account";
import FeedbackInfo from "./components/feedback";

import { getMessageCount } from "../../actions/admin/header/messageActions";
import { useHistory , useLocation} from "react-router-dom";
import BroadcastNewReleaseNoteMessage from "../admin/broadcastMessenger/BroadcastNewReleaseNoteMessage";
import {ROUTES} from '../../utils/navigationItems'
import queryString from "query-string";
import { Helmet } from "react-helmet";
const componentName = "Header";

const KnowledgeInfo = () => (
  <p>
    Click to access the{" "}
    <a
      href="https://confluence.healthbi.com/display/CEUG/CareEmpower+Knowledge+Center"
      target="_blank"
    >
      Knowledge Center
    </a>
    . <br></br>
    <br></br>
    First Time users must create their user account. Once completed, you have
    access to the User Guides, Job Aides, and other technical information.
  </p>
);

const Header = ({ sidebarExpanded, setSidebarExpanded }) => {
  const [modalShow, setModalShow] = useState(false); //Toggles Modal Display
  const [contactModal, setContactModal] = useState(false); //Toggles Support Modal Display
  const [knowledgeModal, setKnowledgeModal] = useState(false); //Toggles Knowledge Center Modal Display
  const [resourcesModal, setResourcesModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  const dispatch = useDispatch();
  let auth = useAuth();
  let userId = auth.getUserId();
  let history = useHistory();
  const quickListState = useLocation();

  const [orgName, setOrgName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userMarkets, setUserMarkets] = useState('');

  useEffect(() => {
    async function getData() {
      const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/feedback/";
      const filters = [{
        fieldName: "email",
        value: auth.getEmail(),
        comparisonMode: "EQUAL_TO",
      }];
      const prms = { page: 1, pageSize: 10, filters: filters };
      const users = await axios.post(URL + "user/search", prms, {
        headers: {
          "Content-Type": "application/json"
        },
      });

      const content = users?.data?.content || [];
      setOrgName(content.map((x) => x.organization.name));
      setOrgId(content.map((x) => x.organization.id));
      setUserName(content.map((x) => x.name));
      setUserEmail(content.map((x) => x.email));
      setUserRole(content.map((x) => x.userRole.name));
      setUserMarkets(content.map((x) => x.client));

      if (content.length > 0) {
        const firstOrgName = content[0].organization.name;
        const orgId = firstOrgName === "q-point-group-tin - EQUALITY HEALTH"
          ? firstOrgName.substring(0, 17)
          : firstOrgName.substring(0, 9);
        
        setOrgId(orgId);
      }
    }
    getData();
  }, [auth]);

  useEffect(() => {
    dispatch(getMessageCount());
  }, [dispatch]);

  const { count } = useSelector((state) => state.messages);

  const handleModal = (e) => {
    setModalShow(true);
  };

  const onClickNewButton = (str) => {
    history.push(str);
  };

  const handleContactModal = (e) => {
    setContactModal(true);
  };

  const handleKnowledgeModal = (e) => {
    setKnowledgeModal(true);
  };

  const handleResourcesModal = (e) => {
    setResourcesModal(true);
  };

  const handleFeedbackModal = (e) => {
    setFeedbackModal(true);
  };

  const handleAboutModal = (e) => {
    setAboutModal(true);
  };

  const IGNORE_PAGE_TITLE_LIST = ["Patient Profile"];

  window.addEventListener('worklistTitleEvent', () => {
    const title = window.localStorage.getItem('worklistTitle')
    if(pageTitle && pageTitle === 'Worklist'){
      setPageTitle("Worklist " + title)
    }
  })

  useEffect(() => {
    let localTitle = quickListState.state ? quickListState.state.pageTitle : '' ;
    const urlString = window.location.href;
    const index = urlString.indexOf("?");
    if (index >= 0) {
      const paramsMeta = urlString.substring(index);
      const params = queryString.parse(paramsMeta);
      if (params.customListName) {
        setPageTitle(params.customListName);
      }
    }

    if(!localTitle){
      const pathName = quickListState.pathname || '' ;
      Object.entries(ROUTES).map(([key,value])=>{
        if(value && pathName && value.path && value.path.split("/").join("") === pathName.split("/").join("")){
          localTitle = key;
        }
      });
    }

    const currentRoute = window.location.pathname;
    if (currentRoute !== '/patient-profile') {
      setPageTitle(localTitle);
    }

    if(!IGNORE_PAGE_TITLE_LIST.includes(pageTitle)){
      setPageTitle(localTitle)
    }
  }, [quickListState, window.location.pathname]);
 
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
          document.addEventListener('DOMContentLoaded', function() {
            var domain = window.location.hostname;
            var appId;

            switch (domain) {
              case 'qa.careempower.com':
                appId = '3962703043';
                break;
              case 'uat.careempower.com':
                appId = '3128070934';
                break;
              case 'careempower.com':
              case 'www.careempower.com':
                appId = '1879257469';
                break;
              case 'localhost':
                return;
              default:
                return;
            }

            window.heap = window.heap || [];
            heap.load = function(e, t) {
              window.heap.appid = e;
              window.heap.config = t = t || {};
              window.heap.loaded = false;
            
              var r = document.createElement("script");
              r.type = "text/javascript";
              r.async = true;
              r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
              r.onload = function() {
                window.heap.loaded = true;
              };
            
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(r, a);
            
              var n = function(e) {
                return function() {
                  heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                }
              };
              var p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"];
              for (var o = 0; o < p.length; o++) heap[p[o]] = n(p[o]);
            };

            heap.load(appId);

            function checkHeapLoaded() {
              if (window.heap && window.heap.loaded) {
                heap.identify(${userId});
                heap.addUserProperties({
                  "Organization Name": "${orgName.toString()}",
                  "Organization ID": "${orgId.toString()}",
                  "User Name": "${userName.toString()}",
                  "email": "${userEmail.toString()}",
                  "User Role": "${userRole.toString()}",
                  "User Markets": "${userMarkets.toString()}",
                });
              } else {
                setTimeout(checkHeapLoaded, 100);
              }
            }

            checkHeapLoaded();
          });
        `}</script>
      </Helmet>
      <Navbar bg="white" expand="lg" id="mainHeader" className="header">
        <BroadcastNewReleaseNoteMessage />
        <Container fluid>
          <div className="d-flex justify-content-start align-items-center">
            {!sidebarExpanded && (
              <i className="fa-regular fa-bars fa-lg cursor-p me-3" onClick={() => setSidebarExpanded(true)}></i>
            )}
            <span id={`${componentName}-pageTitle`} className="h4 fw-bold mb-0">{pageTitle}</span>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0 flex-row align-items-center">
                <Navbar.Text>
                  <PopulationSearch />
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
            <div>
              <Support toggleContactModal={handleContactModal} toggleKnowledgeModal={handleKnowledgeModal} toggleResourcesModal={handleResourcesModal} toggleFeedbackModal={handleFeedbackModal} toggleAboutModal={handleAboutModal} />
              <Messages count={count} />
              <Account />
            </div>
          </div>
        </Container>
      </Navbar>
      <Modals
        show={contactModal}
        onHide={() => setContactModal(false)}
        title="Support"
        titleid={`${componentName}-contactModal`}
        size="md"
        hideFooter={true}
        body={<ContactSupport />}
      />
      <Modals
        show={resourcesModal}
        onHide={() => setResourcesModal(false)}
        title="Resources"
        titleid={`${componentName}-resourcesModal`}
        size="md"
        hideFooter={true}
        body={<Resources />}
      />
      <Modals
        show={aboutModal}
        onHide={() => setAboutModal(false)}
        title="About"
        titleid={`${componentName}-aboutModal`}
        size="sm"
        hideFooter={true}
        body={<About />}
      />
      <Modals
        show={knowledgeModal}
        onHide={() => setKnowledgeModal(false)}
        title="CareEmpower Knowledge Center"
        titleid={`${componentName}-knowledgeCenterModal`}
        size="md"
        completeBtn={"CLOSE"}
        hideFooter={false}
        hideClickButton={true}
        showExtraButton={false}
        body={<KnowledgeInfo />}
      />
      <Modals
        modalId={`${componentName}-feedbackModal`}
        onHide={() => setFeedbackModal(false)}
        onCreate=""
        show={feedbackModal}
        size="md"
        modalTitleId={`${componentName}-feedbackModalTitle`}
        title="We'd like to hear from you"
        body={<FeedbackInfo setFeedbackModalProp={setFeedbackModal} />}
        hideFooter={true}
        hideSecondaryBtn={true}
        disabledPrimaryBtn={false}
        hidePrimaryBtn={false}
        primaryBtn="Submit"
        primaryBtnId={`${componentName}-feedbackSubmit`}        
      />
    </>
  );
};

export default Header;