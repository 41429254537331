import React from 'react'
import { NotesTimeline } from './components'
import { NotesProvider } from './context/Notes.context'

function NotesTab({ ...props }) {
  return (
    <NotesProvider>
        <NotesTimeline {...props} />
    </NotesProvider>
  )
}

export default NotesTab