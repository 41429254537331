import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
  useAccordionButton,
  Button
} from "react-bootstrap";
import { EQHDatePicker } from "../../../components/datePicker";
import styled from "styled-components";
import cloneDeep from "lodash/cloneDeep";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { format } from "date-fns";
import { logDebug } from "../../../utils/util";
import {
  getSuspectAwaitingChartContent,
  getSuspectAwaitingChartToolTip,
  getSuspectAwaitingClaimContent,
  getSuspectAwaitingClaimToolTip,
} from "./ManageEvidenceCommonCode";
import {
  gapValidationOptions,
  codeTypes,
  eyeExamTypes,
  suspectValidationOptions,
  suspectRejectionReasons,
} from "../../../utils/textValueLists";
import axios from "axios";
import { SimpleDDL } from "../../../components/dropDownLists/simpleDDL";
import { RejectionDDL } from "../../../components/dropDownLists/rejectionDDL";
import { CareOpportunitiesList } from "./CareOpportunityList";
import { CARE_OPPORTUNITIES_TYPE } from "./CareOpportunitiesAccordion";
import {useDispatch, useSelector} from "react-redux";
import {disPatchError} from "../../../actions/utils";

const baseURLICD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/icd10";

const VALIDATION_TYPE = {
  Opportunity: "Opportunity",
  Suspect: "Suspect",
};

const StyledTypeAhead = styled(AsyncTypeahead)`
  .dropdown-menu {
    max-height: 250px !important;
  }
`;

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
      <i className={isCurrentEventKey ? "fa fa-minus me-1" : "fa fa-plus me-1"}></i>{" "}
      <span className="fw-bold">{children}</span>
    </Button>
  );
}

const getId = (item) => {
  if (item.conditionSummary) {
    return item.conditionSummary.id;
  } else if (item.careGapSummary) {
    return item.careGapSummary.id;
  }
  return null;
};

const filterBy = () => true;

const DEFAULT_UPDATE_DTO = {
  ids: [],
  newStatus: {
    source: "",
    translationKey: "",
    closesCondition: true,
    name: "",
  },
  validation: {},
  icd10DiseaseDto: null,
};

const getDefaultUpdateDTO = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_UPDATE_DTO);
  dto.newDateOfService = dateOfService;
  return dto;
};

const DEFAULT_INFO = {
  requireValidation: false,
  requireRejectionReason: false,
  rejectionReasons: [],
  requireCode: false,
  requirePressureCodes: false,
  requireIcd10: false,
  requireEyeExamResult: false,
  enableRevisedDate: false,
  validationOptions: [],
};

const getDefaultInfo = () => {
  return cloneDeep(DEFAULT_INFO);
};

const DEFAULT_DATA = {
  selectedItems: [],
  validationType: null,
  visitDate: null,
  confirmationWarning: "",
  finalizeForBackend: () => false,
  info: getDefaultInfo(),
  errors: [],
};

const getDefaultData = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_DATA);
  dto.updateDto = getDefaultUpdateDTO(dateOfService);
  return dto;
};

const ValidationAccordion = ({
  opportunities,
  suspects,
  isSearching,
  componentName,
  onChange,
  title = "Validation",
  authorizedGapWrite = false,
  authorizedGapRead = false,
  authorizedGapValidate = false,
  authorizedRafValidate = false,
  disableCheckList = false,
  activeKey,
  patient,
  resetKeyProp,
  dateOfServiceOverride,
  addToSelecedItemParams,
  processAddToSelecedItemParamsKeyProp,
}) => {
  const dispatch = useDispatch();
  const { rejectCOReasons } = useSelector((state) => state.patients);
  const [rejection, setRejection] = useState([])
  const retrieveCareOppRejectionReasons = () => {
    axios
        .get(
            `${ process.env.REACT_APP_REST_API_BASE_URL}/v1/care-gaps/rejection-reasons`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        )
        .then((res) => {
          if(res.data){
            setRejection(res.data);
          }
        })
        .catch((err) => disPatchError(err, dispatch));
  };
  useEffect(() => {
      retrieveCareOppRejectionReasons();
  }, []);

  const [trackDateOfService, setTrackDateOfService] = useState(
    dateOfServiceOverride
  );
  const [data, setData] = useState(() => getDefaultData(dateOfServiceOverride));
  const [isLoading, setIsLoading] = useState(false);
  const [
    processAddToSelecedItemParamsKey,
    setProcessAddToSelecedItemParamsKey,
  ] = useState(null);
  const [optionsCode, setOptionscode] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);
  const [optionsDesc, setOptionsDesc] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);
  const [resetKey, setResetKey] = useState(null);

  const getErrors = (data) => {
    let errors = [];

    if (data.selectedItems.length) {
      if (
        data.info.requireValidation &&
        !data.updateDto.validation?.validation
      ) {
        errors.push("Validation is Required");
      }
      if (
        data.updateDto.validation?.validation === "REJECTED" &&
        !data.updateDto.validation?.rejectionReason
      ) {
        errors.push("Rejection Reason is Required");
      }
      if (
        data.info.requireCode &&
        (!data.updateDto.validation?.validationCodeType ||
          !data.updateDto.validation?.validationCode)
      ) {
        errors.push("Validation Code and Type are Required");
      }
      if (
        data.info.requireIcd10 &&
        (!data.updateDto?.icd10DiseaseDto?.code ||
          !data.updateDto?.icd10DiseaseDto?.description)
      ) {
        errors.push("Icd10 is Required");
      }
    }

    return errors;
  };

  const setDataAndCallOnChange = (data) => {
    setData(data);
    data.getId = getId;
    data.errors = getErrors(data);
    data.finalizeForBackend = function () {
      if (this.selectedItems && this.updateDto) {
        const ids = [];
        this.selectedItems.forEach((x) => {
          ids.push(this.getId(x));
        });
        this.updateDto.ids = ids;
      }
    };
    onChange(data);
  };

  const reset = () => {
    setIsLoading(false);
    setOptionscode([
      {
        id: "",
        code: "",
        description: "",
      },
    ]);
    setOptionsDesc([
      {
        id: "",
        code: "",
        description: "",
      },
    ]);

    setDataAndCallOnChange(getDefaultData(dateOfServiceOverride));
  };

  const applyConditionalRequirements = (dataClone) => {
    const obj = getDefaultInfo();

    dataClone.selectedItems.forEach((selectedItem) => {
      //if validation care gap
      if (selectedItem.careGapSummary) {
        obj.validationOptions = gapValidationOptions;
        if (selectedItem.careGapSummary.gapCategory === "ECM") {
          if (
            selectedItem.careGapSummary.status.name === "Marked for Closure"
          ) {
            if (selectedItem.careGapSummary.requireValidation) {
              obj.requireValidation = true;
            }
          } else if (
            selectedItem.careGapSummary.status.name !== "Marked for Closure" &&
            (selectedItem.careGapSummary.abbreviation === "WC15" ||
              selectedItem.careGapSummary.abbreviation === "WC30")
          ) {
            obj.requireValidation = true;
          }
        } else {
          if (
            selectedItem.careGapSummary.status.name === "Marked for Closure"
          ) {
            obj.requireValidation = true;
          }
        }
      } else if (selectedItem.conditionSummary) {
        obj.validationOptions = suspectValidationOptions;
        obj.requireValidation = true;
        if (dataClone.updateDto.validation.validation === "REJECTED") {
          obj.requireRejectionReason = true;
          obj.rejectionReasons = suspectRejectionReasons.filter(
            (reason) => reason.value !== "DOCUMENTATION_SUPPORTS_CONDITION"
          );
        }
      }

      switch (dataClone.updateDto.validation.validation) {
        case "APPROVED":
          if (dataClone.validationType === VALIDATION_TYPE.Opportunity) {
            if (selectedItem.careGapSummary.requireCode) {
              obj.requireCode = true;
            }
          }
          obj.enableRevisedDate = true;
          break;
        case "MODIFIED_AND_CONFIRMED":
          obj.requireIcd10 = true;
          //setVisitDate(originalVisitDate);
          break;
        case "REJECTED":
          if (dataClone.validationType === VALIDATION_TYPE.Opportunity) {
            obj.requireRejectionReason = true;
            obj.rejectionReasons = rejection;
          }
          break;
        default:
        //setVisitDate(originalVisitDate);
      }
    });

    dataClone.info = obj;
  };

  const updateSelected = (item, key, checked, gapType) => {
    let dataClone = { ...data };

    if (checked) {
      if (key === "careGapSummary") {
        dataClone.updateDto.newStatus = {
          closesGap: true,
          name: "Marked for Closure",
          source: "MARKED_FOR_CLOSURE",
        };
        dataClone.validationType = VALIDATION_TYPE.Opportunity;
      } else if (key === "conditionSummary") {
        dataClone.updateDto.newStatus = null;
        dataClone.validationType = VALIDATION_TYPE.Suspect;
      }
      dataClone.selectedItems = [item].concat(dataClone.selectedItems);
    } else {
      dataClone.selectedItems = dataClone.selectedItems.filter((i) => {
        if (i[key]) {
          return i[key].id !== item[key].id;
        } else {
          return i;
        }
      });
      dataClone.updateDto.newStatus = null;
      dataClone.updateDto.validation = {
        validation: null,
        rejectionReason: null,
        code: null,
        codeTyp: null,
        systolicCode: "",
        diastolicCode: "",
      };
      dataClone.validationType = null;
    }

    applyConditionalRequirements(dataClone);
    setDataAndCallOnChange(dataClone);
  };

  const handleOpportunityChange = (name, value) => {
    logDebug(
      () => "helloThere!!!" + JSON.stringify(name) + ":" + JSON.stringify(value)
    );
    let finalValue = value;
    let dataClone = { ...data };
    dataClone.updateDto.validation = {
      ...(data.updateDto.validation || {}),
    };

    switch (name) {
      case "eyeExamResult":
      case "systolicCode":
      case "diastolicCode":
        break;
      case "revisedDateOfService":
        finalValue = value.strDate;
        break;
      case "validation":
        switch (value) {
          case "MODIFIED_AND_CONFIRMED":
            dataClone.updateDto.icd10DiseaseDto = {};
            break;
          default:
            dataClone.revisedDateOfService = "";
            dataClone.updateDto.icd10DiseaseDto = null;
        }
        break;
      default:
        break;
    }

    //update state TODO
    // if (
    //   name === "validation" &&
    //   (!data.updateDto.validation ||
    //     data.updateDto.validation.name !== value.name)
    // ) {
    //   dataClone.updateDto.validation = {
    //     rejectionReason: null,
    //     codeType: null,
    //     code: "",
    //     conditions: [{ id: [], code: [], description: [] }],
    //     revisedDateOfService: null,
    //   };
    // }

    dataClone.updateDto.validation[name] = finalValue;
    switch (name) {
      case "validation":
        applyConditionalRequirements(dataClone);
        break;
      default:
        break;
    }
    setDataAndCallOnChange(dataClone);
  };

  const handleIcdChange = (e) => {
    let dataClone = { ...data };
    dataClone.updateDto.icd10DiseaseDto.id = e.length ? e[0].id : null;
    dataClone.updateDto.icd10DiseaseDto.code = e.length ? e[0].code : null;
    dataClone.updateDto.icd10DiseaseDto.description = e.length
      ? e[0].description
      : null;
    setDataAndCallOnChange(dataClone);
  };

  const asyncCodeSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD + `/find-by-code?code=${query}&offset=${1}&pageSize=${30}`,
        { headers: {} }
      )

      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        setOptionscode(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        logDebug(() => error);
        setIsLoading(false);
      });
  };

  const asyncDescSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD +
          `/find-by-description?description=${query}&offset=${1}&pageSize=${30}`,
        {
          headers: {},
        }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        setOptionsDesc(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        logDebug(() => error);
        setIsLoading(false);
      });
  };

  const checkValidationPermission = (gap) => {
    if (
      data.selectedItems.length &&
      !data.selectedItems.find((x) => getId(x) === gap.id)
    ) {
      return true;
    }
    if (gap.status.name === "Excluded") {
      return true;
    }
    if (
      (gap.abbreviation === "WC15" || gap.abbreviation === "WC30") &&
      gap.careGapSummary.visitsLowerBound >= gap.careGapSummary.visitsUpperBound
    ) {
      return true;
    }

    return false;
  };

  if (dateOfServiceOverride !== trackDateOfService) {
    setData({
      ...data,
      updateDto: { ...data.updateDto, newDateOfService: dateOfServiceOverride },
    });
    setTrackDateOfService(dateOfServiceOverride);
  }

  if (resetKeyProp !== resetKey) {
    reset();
    setResetKey(resetKeyProp);
  }

  if (
    processAddToSelecedItemParamsKeyProp !== processAddToSelecedItemParamsKey
  ) {
    setProcessAddToSelecedItemParamsKey(processAddToSelecedItemParamsKeyProp);
    if (
      addToSelecedItemParams &&
      (addToSelecedItemParams.type === "validGaps" ||
        addToSelecedItemParams.type === "validCons")
    ) {
      const isCareGap = addToSelecedItemParams.type === "validGaps";
      let item = data.selectedItems.find(
        (x) => getId(x) === addToSelecedItemParams.id
      );
      if (!item) {
        item = (addToSelecedItemParams.type === "validGaps" ? opportunities : suspects).find(
          (x) => getId(x) === addToSelecedItemParams.id
        );
        if (item) {
          updateSelected(
            item,
            isCareGap ? "careGapSummary" : "conditionSummary",
            true
          );
        }
      }
    }
  }

  return (
    <Accordion
      defaultActiveKey={activeKey}
      id={`${componentName}-AllValidation`}
      className="border-bottom mb-4"
    >
      <Card>
        <Card.Header
          eventKey="5"
          id={`${componentName}-Validation`}
          className="cursor-p"
        >
          <PlusMinusToggle eventKey="5">
            <span id={`${componentName}-ValidationHeader`}>{title}</span>
          </PlusMinusToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <Row className="mt-3">
              <Col sm={6} className="mb-3">
                {authorizedGapWrite && authorizedGapRead ? (
                  <div className="mb-3">
                    <label
                      id={`${componentName}-ValidationTitle`}
                      className="d-block"
                    >
                      {title}
                    </label>
                    <span id={`${componentName}-ValidationSelected`}>
                      {data.selectedItems.length} Selected
                    </span>
                  </div>
                ) : null}
                <div className="mb-3">
                  <label
                    id={`${componentName}-ValidationCareOpp`}
                    className="d-block"
                  >
                    Care Opportunities
                  </label>
                  {isSearching && (
                    <>
                      <Spinner
                        className="me-3"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  )}
                  {authorizedGapValidate ? (
                    <CareOpportunitiesList
                      opportunities={opportunities}
                      additionalOpportunities={[]}
                      selectedOpportunities={
                        data.validationType === VALIDATION_TYPE.Opportunity
                          ? data.selectedItems
                          : []
                      }
                      onChange={updateSelected}
                      listType={CARE_OPPORTUNITIES_TYPE.Validation}
                      itemType="careGapSummary"
                      componentName={componentName}
                      patient={patient}
                      disableCheckList={disableCheckList}
                      disableCheckbox={(gap) =>
                        checkValidationPermission(gap.careGapSummary)
                      }
                    />
                  ) : null}
                </div>
                <div className="mb-3">
                  {authorizedRafValidate ? (
                    <>
                      <label
                        id={`${componentName}-ValidationSuspects`}
                        className="d-block"
                      >
                        Suspects
                      </label>
                      {suspects.length
                        ? suspects.map((disease, idx) => (
                            <div
                              className="d-flex flex-row mb-1"
                              key={disease.conditionSummary.id}
                            >
                              <div className="d-inline-block me-2">
                                <input
                                  id={`${componentName}-ValidationSuspectsChecked-${idx}`}
                                  type="checkbox"
                                  disabled={
                                    disableCheckList ||
                                    (data.selectedItems.length &&
                                      !data.selectedItems.find(
                                        (x) =>
                                          getId(x) ===
                                          disease.conditionSummary.id
                                      ))
                                  }
                                  checked={data.selectedItems.find(
                                    (x) =>
                                      getId(x) === disease.conditionSummary.id
                                  )}
                                  onClick={(e) =>
                                    updateSelected(
                                      disease,
                                      "conditionSummary",
                                      e.target.checked
                                    )
                                  }
                                />{" "}
                              </div>
                              <div
                                id={`${componentName}-ValidationSuspectsSummary-${idx}`}
                                className="d-inline-block me-2"
                              >
                                <span
                                  id={`${componentName}-ValidationSuspectsSummaryYear-${idx}`}
                                >
                                  {disease.conditionSummary.conditionAndYear}
                                </span>{" "}
                                <span
                                  id={`${componentName}-ValidationSuspectsSummaryStatus-${idx}`}
                                >
                                  ({disease.conditionSummary.status.name})
                                </span>{" "}
                                <span
                                  id={`${componentName}-ValidationSuspectsSummarydxCode-${idx}`}
                                >
                                  {disease.conditionSummary.dxCode}
                                </span>
                              </div>
                              <div
                                id={`${componentName}-ValidationSuspectsSummaryAwaiting-${idx}`}
                                className="d-inline-block text-nowrap"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {getSuspectAwaitingChartToolTip(
                                        disease.conditionSummary
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    id={`${componentName}-ValidationSuspectsAwaitingChart-${idx}`}
                                    className={getSuspectAwaitingChartContent(
                                      disease.conditionSummary
                                    )}
                                  ></i>
                                </OverlayTrigger>{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      {getSuspectAwaitingClaimToolTip(
                                        disease.conditionSummary
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    id={`${componentName}-ValidationSuspectsAwaitingClaim-${idx}`}
                                    className={getSuspectAwaitingClaimContent(
                                      disease.conditionSummary
                                    )}
                                  ></i>
                                </OverlayTrigger>
                              </div>
                            </div>
                          ))
                        : null}
                    </>
                  ) : null}
                </div>
              </Col>
              <Col sm={6}>
                <Row className="border bg-light py-3">
                  <Col>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label
                            id={`${componentName}-ValidationText`}
                            className="d-block"
                          >
                            Validation
                            {data.info.requireValidation ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <SimpleDDL
                            id={`${componentName}-ValidationValue`}
                            list={data.info.validationOptions}
                            textSelector={(x) => x.name}
                            valueSelector={(x) => x.value}
                            onChange={(x) =>
                              handleOpportunityChange("validation", x)
                            }
                            disabled={!data.info.requireValidation}
                            selectedKey={
                              data.updateDto.validation.validation
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label
                            id={`${componentName}-ValidationRejectReasonText`}
                            className="d-block"
                          >
                            Rejection Reason
                            {data.info.requireRejectionReason ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <RejectionDDL
                            id={`${componentName}-ValidationRejectReasonValue`}
                            list={data.info.rejectionReasons}
                            textSelector={(x) => {
                              if(x.reason)
                                return x.reason
                              else
                                return x.text}}
                            valueSelector={(x) => {
                              if(x.reason)
                                return x.reason
                              else
                                return x.value}}
                            toolTipSelector={(x) => {
                              if (x.toolTipInfo)
                                return x.toolTipInfo
                              else
                                return x.text}}

                            onChange={(x) =>
                              handleOpportunityChange("rejectionReason", x)
                            }
                            disabled={!data.info.requireRejectionReason}
                            selectedKey={
                              data.updateDto.validation.rejectionReason
                            }
                          />
                        </Form.Group>
                      </Col>
                      <br />
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label
                            id={`${componentName}-ValidationCodeTypeText`}
                            className="d-block"
                          >
                            Code Type
                            {data.info.requireCode ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <SimpleDDL
                            id={`${componentName}-ValidationCodeTypeValue`}
                            list={codeTypes}
                            textSelector={(x) => x.value}
                            valueSelector={(x) => x.value}
                            onChange={(x) =>
                              handleOpportunityChange("validationCodeType", x)
                            }
                            disabled={!data.info.requireCode}
                            selectedKey={
                              data.updateDto.validation.validationCodeType
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group id={`${componentName}-ValidationCode`}>
                          <Form.Label
                            id={`${componentName}-ValidationCodeText`}
                            className="d-block"
                          >
                            Code
                            {data.info.requireCode ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <Form.Control
                            id={`${componentName}-ValidationCodeValue`}
                            type="text"
                            name="code"
                            value={data.updateDto.validation.validationCode}
                            onChange={(e) =>
                              handleOpportunityChange(
                                "validationCode",
                                e.target.value
                              )
                            }
                            disabled={!data.info.requireCode}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group id={`${componentName}-ValidationICD10Code`}>
                          <Form.Label
                            id={`${componentName}-ValidationICD10CodeText`}
                            className="d-block"
                          >
                            ICD-10 Code
                            {data.info.requireIcd10 ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <StyledTypeAhead
                            filterBy={filterBy}
                            id={`${componentName}-ICD10CodeTypeAhead`}
                            inputProps={{
                              id: `${componentName}-ICD10CodeValue`,
                            }}
                            labelKey="code"
                            isLoading={isLoading}
                            minLength={2}
                            onSearch={asyncCodeSearch}
                            options={optionsCode}
                            selected={
                              data.updateDto.icd10DiseaseDto?.code
                                ? [data.updateDto.icd10DiseaseDto?.code]
                                : []
                            }
                            onChange={(e) => handleIcdChange(e)}
                            disabled={!data.info.requireIcd10}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={5}>
                        <Form.Group id={`${componentName}-ValidationICD10Desc`}>
                          <Form.Label
                            id={`${componentName}-ValidationICD10DescText`}
                            className="d-block"
                          >
                            ICD-10 description
                            {data.info.requireIcd10 ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </Form.Label>
                          <StyledTypeAhead
                            filterBy={filterBy}
                            id={`${componentName}-ValidationICD10DescTypeAhead`}
                            inputProps={{
                              id: `${componentName}-ValidationICD10DescValue`,
                            }}
                            labelKey="description"
                            isLoading={isLoading}
                            minLength={2}
                            onSearch={asyncDescSearch}
                            options={optionsDesc}
                            selected={
                              data.updateDto.icd10DiseaseDto?.description
                                ? [data.updateDto.icd10DiseaseDto?.description]
                                : []
                            }
                            onChange={(e) => handleIcdChange(e)}
                            disabled={!data.info.requireIcd10}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={5}>
                        <Form.Group
                          id={`${componentName}-UpdatedDateOfService`}
                        >
                          <Form.Label
                            id={`${componentName}-UpdatedDateOfServiceText`}
                            className="d-block"
                          >
                            Edit Date of Service
                          </Form.Label>
                          <EQHDatePicker
                            id={`${componentName}-UpdatedDateOfServiceValue`}
                            value={
                              data.updateDto.validation.revisedDateOfService
                            }
                            onChange={(e) => {
                              handleOpportunityChange(
                                "revisedDateOfService",
                                e
                              );
                            }}
                            max={format(new Date(), "yyyy-MM-dd")}
                            disabled={!data.info.enableRevisedDate}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export { ValidationAccordion, getDefaultData };
