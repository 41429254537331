import React from "react";
import { Row, Col, Form, Badge } from "react-bootstrap";
import PatientDetailsFieldForm from './PatientDetailsFieldForm';
import { getPatientStatusStyle, cleanPatientInfo, joinWithNewLines } from '../utils';

const componentName = "InsuranceInformation";

/**
 * InsuranceInformation component displays the insurance details of a patient.
 *
 * @param {Object} patientInfo - Object containing the patient's insurance information.
 * @returns {JSX.Element} A section of the patient details view with insurance information.
 */
const InsuranceInformation = ({ patientInfo }) => {
  const {
    payer,
    secondPayer,
    memberId,
    membershipActive,
    termDate,
    originatingOrganization,
    lineOfBusiness,
    valueBasedCare
  } = cleanPatientInfo(componentName, patientInfo);

  const payorStatusStyle = getPatientStatusStyle(membershipActive);

  return (
    <Form>
      <Row>
        <Col>
          <p className="fs-6 fw-bold">Insurance Information</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <PatientDetailsFieldForm elementPrefix="payer" textLabel="PAYOR" textValue={joinWithNewLines(payer, secondPayer)} />
          <PatientDetailsFieldForm elementPrefix="memberId" textLabel="MEMBER ID" textValue={memberId} />
          <PatientDetailsFieldForm elementPrefix="termDate" textLabel="TERM DATE" textValue={termDate} />
          <PatientDetailsFieldForm elementPrefix="practiceName" textLabel="PRACTICE NAME" textValue={originatingOrganization} />
          <PatientDetailsFieldForm elementPrefix="lineOfBusiness" textLabel="LINE OF BUSINESS" textValue={lineOfBusiness} />
          <PatientDetailsFieldForm elementPrefix="valueBasedCare" textLabel="VALUE BASED CARE" textValue={valueBasedCare} />
        </Col>
        <Col>
          <Badge bg="" id={`${componentName}-`} pill style={{ ...payorStatusStyle }}
          >{payorStatusStyle.value}</Badge>
        </Col>
      </Row>
    </Form>
  );
}

export default InsuranceInformation;
