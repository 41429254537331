export function getGetters(state) {
    return {
        currentSpecialistReferralStep: state.currentSpecialistReferralStep,
        specialities: state.specialities,
        selectedSpeciality: state.selectedSpeciality,
        payers: state.payers,
        selectedPayer: state.selectedPayer,
        practices: state.practices,
        associatedProviders: state.associatedProviders,
        isLoadingPractices: state.isLoadingPractices,
        isLoadingAssociatedProviders: state.isLoadingAssociatedProviders,
        zipCode: state.zipCode,
        genders: state.genders,
        selectedGender: state.selectedGender,
        specialistReferralSteps: state.specialistReferralSteps,
        providers: state.providers,
        selectedProvider: state.selectedProvider,
        visitTypes: state.visitTypes,
        selectedVisitType: state.selectedVisitType,
        urgencyOptions: state.urgencyOptions,
        selectedUrgency: state.selectedUrgency,
        note: state.note,
        icd10List: state.icd10List,
        icd10Options: state.icd10Options,
        substanceAbuseICD10: state.substanceAbuseICD10,
        uploadedFiles: state.uploadedFiles,
        isSpecialistReferralSaved: state.isSpecialistReferralSaved,
        isLoadingSpecialities: state.isLoadingSpecialities,
        isLoadingPayers: state.isLoadingPayers,
    }
}