import { disPatchError } from "../utils"
import {
  RESET_ERRORS,
  ADD_DOCUMENT,
  GET_CLIENTS,
  GET_DOCUMENTS,
  GET_ACTIVE_DOCUMENTS,
  REMOVE_DOCUMENT,
  GET_ERRORS,
  CHANGE_CLIENT
} from "../types";
import axios from "../../utils/apiClient";
export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-admin/";
export const GAP_DOC_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/gap-documentation-admin/";
// Gets Users
export const getValues = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "clients", {
      headers: {
      },
    })
    .then((res) => {
      dispatch({
        type: GET_CLIENTS,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const addDocument = (file, clientId, fileName, documentDisplayName) => async (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });

  try {
    const response = await axios.post(GAP_DOC_URL + `addGapDocumentation?clientId=${clientId}&documentDisplayName=${documentDisplayName}`, file, {
      headers: {
        "Content-Type": "application/pdf",
        "X-Filename": fileName
      },
    });
    dispatch({
      type: ADD_DOCUMENT,
      payload: response.data,
    });
  } catch (error) {
    return {status: 500};
  }
};

export const getDocuments = (clientId) => async (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });

  try {
    const result = await axios.get(GAP_DOC_URL + `gapDocumentationByClientId?clientId=${clientId}&activeOnly=false`);
    const documents = {
      uploads: result.data.filter((val) => val.active),
      history: result.data.filter((val) => !val.active)
    };

    dispatch({
      type: GET_DOCUMENTS,
      payload: documents
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
}

export const getActiveDocuments = (clientId) => async (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });

  try {
    const result = await axios.get(GAP_DOC_URL + `gapDocumentationByClientId?clientId=${clientId}&activeOnly=true`);
    const activeDocuments = {
      activeUploads: result.data.filter((val) => val.active),
    };

    dispatch({
      type: GET_ACTIVE_DOCUMENTS,
      payload: activeDocuments
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
}

export const removeDocument = (document) => async (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });

  try {
    const documentId = document.gapDocumentationId;
    const removedDocument = await axios.patch(GAP_DOC_URL + `removeGapDocumentation?gapDocumentationId=${documentId}`);
    dispatch({
      type: REMOVE_DOCUMENT,
      payload: removedDocument.data
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
}

export const changeClient = () => (dispatch) => {
  dispatch({ type: CHANGE_CLIENT });
}