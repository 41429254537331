import axios from "../utils/apiClient";
import { dataFetchHelper } from "./baseDataFetch";


const [
    searchStaffMembers,
    useSearchStaffMembers,
    ] = dataFetchHelper(([fullNameFragment]) => {
    return axios.get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/user-profile/organization/fullName?fullNameFragment=${fullNameFragment}`,
        {
        headers: {},
        }
    );
});

export {
    searchStaffMembers,
    useSearchStaffMembers
};