export const getSuspectAwaitingChartToolTip = (suspect) => {
  if (
    (suspect.dualValidation === null ||
      suspect.dualValidation === "AWAITING_CHART") &&
    suspect.validation === null
  ) {
    return "Awaiting Chart";
  } else if (suspect.validation === "IN_REVIEW") {
    return "In Review";
  } else if (suspect.validation === "APPROVED") {
    return "Chart Approved";
  } else if (suspect.validation === "REJECTED") {
    return "Chart Rejected";
  } else {
    return "";
  }
};

export const getSuspectAwaitingChartContent = (suspect) => {
  if (
    (suspect.dualValidation === null ||
      suspect.dualValidation === "AWAITING_CHART") &&
    suspect.validation === null
  ) {
    return "fas fa-clipboard-user text-danger";
  } else if (suspect.validation === "IN_REVIEW") {
    return "fas fa-clipboard-user text-primary";
  } else if (suspect.validation === "APPROVED") {
    return "fas fa-clipboard-user text-muted";
  } else if (suspect.validation === "REJECTED") {
    return "fas fa-clipboard-user text-danger";
  } else {
    return "";
  }
};

export const getSuspectAwaitingClaimToolTip = (suspect) => {
  if (
    suspect.dualValidation === null ||
    suspect.dualValidation === "AWAITING_CLAIM"
  ) {
    return "Awaiting Claim";
  } else if (
    suspect.dualValidation === "AWAITING_CHART" ||
    suspect.dualValidation === "DUAL_APPROVED"
  ) {
    return "Verified by claim";
  } else {
    return "";
  }
};

export const getSuspectAwaitingClaimContent = (suspect) => {
  if (
    suspect.dualValidation === null ||
    suspect.dualValidation === "AWAITING_CLAIM"
  ) {
    return "fas fa-notes-medical text-danger";
  } else if (
    suspect.dualValidation === "AWAITING_CHART" ||
    suspect.dualValidation === "DUAL_APPROVED"
  ) {
    return "fas fa-notes-medical text-muted";
  } else {
    return "";
  }
};

export const getGapAwaitingChartToolTip = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "SDOH" ||
    gap.careGapSummary.abbreviation === "AAWV" ||
    gap.careGapSummary.abbreviation === "WCC-BMI" ||
    gap.careGapSummary.abbreviation === "WCC-PA" ||
    gap.careGapSummary.abbreviation === "WCC-NUT"
  ) {
    if (
      (gap.careGapSummary.dualValidation === null ||
        gap.careGapSummary.dualValidation === "AWAITING_CHART") &&
      gap.careGapSummary.validation.validation === null
    ) {
      return "Awaiting Chart";
    } else if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "In Review";
    } else if (gap.careGapSummary.validation.validation === "APPROVED") {
      return "Chart Approved";
    } else if (gap.careGapSummary.validation.validation === "REJECTED") {
      return "Chart Rejected";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getGapAwaitingChartContent = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "SDOH" ||
    gap.careGapSummary.abbreviation === "AAWV" ||
    gap.careGapSummary.abbreviation === "WCC-BMI" ||
    gap.careGapSummary.abbreviation === "WCC-PA" ||
    gap.careGapSummary.abbreviation === "WCC-NUT"
  ) {
    if (
      (gap.careGapSummary.dualValidation === null ||
        gap.careGapSummary.dualValidation === "AWAITING_CHART") &&
      gap.careGapSummary.validation.validation === null
    ) {
      return "fas fa-clipboard-user text-danger";
    } else if (gap.careGapSummary.validation.validation === "IN_REVIEW") {
      return "fas fa-clipboard-user text-primary";
    } else if (gap.careGapSummary.validation.validation === "APPROVED") {
      return "fas fa-clipboard-user text-muted";
    } else if (gap.careGapSummary.validation.validation === "REJECTED") {
      return "fas fa-clipboard-user text-danger";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getGapAwaitingClaimToolTip = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "SDOH" ||
    gap.careGapSummary.abbreviation === "AAWV" ||
    gap.careGapSummary.abbreviation === "WCC-BMI" ||
    gap.careGapSummary.abbreviation === "WCC-PA" ||
    gap.careGapSummary.abbreviation === "WCC-NUT"
  ) {
    if (
      gap.careGapSummary.dualValidation === null ||
      gap.careGapSummary.dualValidation === "AWAITING_CLAIM"
    ) {
      return "Awaiting Claim";
    } else if (
      gap.careGapSummary.dualValidation === "AWAITING_CHART" ||
      gap.careGapSummary.dualValidation === "DUAL_APPROVED"
    ) {
      return "Verified by claim";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getGapAwaitingClaimContent = (gap) => {
  if (
    gap.careGapSummary.abbreviation === "SDOH" ||
    gap.careGapSummary.abbreviation === "AAWV" ||
    gap.careGapSummary.abbreviation === "WCC-BMI" ||
    gap.careGapSummary.abbreviation === "WCC-PA" ||
    gap.careGapSummary.abbreviation === "WCC-NUT"
  ) {
    if (
      gap.careGapSummary.dualValidation === null ||
      gap.careGapSummary.dualValidation === "AWAITING_CLAIM"
    ) {
      return "fas fa-notes-medical text-danger";
    } else if (
      gap.careGapSummary.dualValidation === "AWAITING_CHART" ||
      gap.careGapSummary.dualValidation === "DUAL_APPROVED"
    ) {
      return "fas fa-notes-medical text-muted";
    } else {
      return "";
    }
  } else {
    return "";
  }
};