import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { resetSuccess } from '../../actions/successActions';

const SuccessToast = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const isEmpty = require("is-empty");
    const success = useSelector(state => state.success);

    useEffect(() => {
        if (success && success.message) {
            setShow(true)
        }
    });

    const  hideIt = ()=>{
        dispatch(resetSuccess())
        setShow(false)
    }

    return (
        <Toast onClose={() => hideIt()}  show ={show} delay={3000} autohide>
            <Toast.Header>
                <strong className="me-auto fw-bold text-success"><i className="fas fa-exclamation-triangle"></i> Success</strong>
                <small>Just Now</small>
            </Toast.Header>
    <Toast.Body>{success.message}</Toast.Body>
        </Toast>
    );
}

export default SuccessToast;