import React from "react";
import DropDownMenu from "../../../caremgmt/worklist/components/DropDownMenu";
import {useNewRRContext} from "../context/NewRR.context";
import {getCustomListName, getQuickListId} from "../../../../utils/util";
import {useCustomWorklistContext} from "../../../../components/CustomWorklist";

const GeneralActions = ({idx}) => {
  const isEmpty = require("is-empty");

  const {
    exportToCSV,
    referralsSelected,
    sendEvent
  } = useNewRRContext();

  const {
    toggleSaveAsCustomWorklist,
    toggleDeleteCustomWorklist,
  } = useCustomWorklistContext();

  let customListName = getCustomListName();
  let quickListId = getQuickListId();
  const showDeleteCustomWorklistMenuItem = ((quickListId && !isNaN(quickListId))
    && !isEmpty(customListName));
  const showDeleteCustomWorklist = showDeleteCustomWorklistMenuItem;
  const showSaveAsCustomWorklist = !showDeleteCustomWorklistMenuItem;

  const onPatientActionClicked = (menuItemClicked) => {
    switch (menuItemClicked) {
      case "Save as Custom Worklist":
        sendEvent('CLICK_SAVE_WORKLIST');
        toggleSaveAsCustomWorklist(true);
        break;
      case "Delete Custom Worklist":
        sendEvent('DELETE_WORKLIST');
        toggleDeleteCustomWorklist(true);
        break;
      case "Export to CSV":
        exportToCSV()
        break;
    }
  }

  let generalActions = [
    {
      value: "Save as Custom Worklist",
      text: "Save as Custom Worklist",
      hasDivider: false,
      disabled: false,
      condition: showSaveAsCustomWorklist
    },
    {
      value: "Delete Custom Worklist",
      text: "Delete Custom Worklist",
      hasDivider: true,
      disabled: false,
      condition: showDeleteCustomWorklist
    },
    {
      text: 'Export to CSV',
      disabled: referralsSelected.length === 0,
      condition: true
    },
  ].filter(i => i.condition);

  const onItemClick = (menuItemClicked) => {
    onPatientActionClicked(menuItemClicked.text);
  }

  return (
    <DropDownMenu idx={idx} items={generalActions} onItemClick={(item) => {
      onItemClick(item)
    }}/>
  )
}

export default GeneralActions;