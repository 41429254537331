import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../components/buttons";
import DatePicker from "react-datepicker";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import axios from "../../utils/apiClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import {
  appointmentModalStatus,
  durationList,
  notificationDelayList,
  units,
  carePathStepsList,
} from "../../utils/textValueLists";
import {
  formatDate,
  getDisplayValue,
} from "../../utils/util";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  CustomMenuStyled,
  DropDownButton,
} from "../../components/dropDownLists/sharedDDL";
import {
  DDLDataMode,
  DDLMode,
  DropDownList,
} from "../../components/dropDownLists/genericDDL";
import Modals from "../../components/modals";
import { updateFile } from "../../actions/fileUploadActions";
import { useSelector, useDispatch } from "react-redux";
import ErrorToast from "../common/ErrorToast";
import { setHours, setMinutes } from "date-fns";
import ManageEvidence from "../quickList/ManageEvidence";
import GapModal from "./GapModal";
import { FileUpload } from "../../components/FileUpload";
import { DropdownComponent } from "../../components/DropdownComponent";

export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + window.location.pathname
);
const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
const RedAsterisk = styled.span`
  color: red;
`;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;
const StyledRow = styled(Row)`
  height: 100%;
  margin-top: -16px;
`;
const StyledTypeAhead = styled(AsyncTypeahead)``;
const AppointmentModal = ({
  onHideClicked,
  patient,
  parent,
  carePath,
  updateParent,
  fromPatientProfile,
  onCancelClick
}) => {
  const isEmpty = require("is-empty");
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarePath, setSelectedCarePath] = useState(carePath);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [addressList, setAddressList] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [fileAdded, setFileAdded] = useState({});
  const [fileName, setFileName] = useState("");

  const [show, setShow] = useState(false);
  const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/appointment";

  const dispatch = useDispatch();

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };
  const handleSearch = (query) => {
    if (query) {
      const patientId =
        patient && patient.id ? encodeURIComponent(patient.id) : "";
      const url =
        baseURL +
        "/get-providers" +
        (query ? "?providerName=" + query : "") +
        (patientId ? "&patientId=" + patientId : "");
      setIsLoading(true);

      axios
        .get(url, {
          headers: {},
        })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i.id,
              text: i?.firstName + " " + i?.lastName,
              provider: i,
            }));
          }
          setOptions(options);
          setIsLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          setCurrentError(true);
        });
    }
  };

  //   const [suspectForm, setSuspectForm] = useState({
  //     status: { source: "", translationKey: "", closesCondition: true, name: "" },
  //     dateOfService: null,
  //   });
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const [formBody, setFormBody] = useState({
    provider: {
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      facility: "",
      streetAddress: "",
      specialties: [],
      userAccountId: "",
      type: "",
    },
    appointmentId: null,
    type: "NEW",
    status: "SCHEDULED",
    name: "",
    appointmentDateTime: "",
    durationInMinutes: 0,
    address: "",
    providersToSelect: [],
    shouldSendReminder: true,
    notificationDelay: 0,
    notificationDelayUnit: "HOURS",
    needsTransportation: false,
    note: "",
    files: [],
    uploadedFiles: [],
    patientId: "",
    carePathId: "",
    carePathEvent: "",
  });
  useEffect(() => {
    let newState = formBody;
    if (patient.carePaths.length > 0 || fromPatientProfile) {
      if (patient && patient.id) {
        newState.patientId = patient.id;
        setFormBody({ ...newState });
      }
      setShow(true);
    }
  }, []);

  const findAddress = (id) => {
    if (id) {
      id = encodeURIComponent(id);
      axios
        .get(baseURL + `/get-provider-address?providerId=${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i,
              text: i,
            }));
          }
          setAddressList(options);
          let newState = formBody;
              newState.address = options[0].value;
              setFormBody({ ...newState });
          
        })
        .catch((error) => {
          formatErrorMessage(error);
        });
    }
  };

  const handleUploadFileChange = (arr) => {
    let newState = formBody;
    setUploadedFiles(arr);
    newState.uploadedFiles.push(...arr);
    setFormBody({ ...newState });
  };

  const handleCPChange = (e) => {
    let newState = formBody;
    let cp = {};
    cp.id = e.id;
    cp.name = e.name;
    if (cp) {
      newState.carePathId = cp.id;
      setFormBody({ ...newState });
    }
  };
  const handleInputChange = (name, value) => {
    let newState = formBody;
    if (name === "duration") {
      newState.durationInMinutes = value.value;
    }
    if (name === "notificationDelay") {
      newState.notificationDelay = value.value;
    }
    if (name === "notificationDelayUnit") {
      newState.notificationDelayUnit = value.value;
    }
    if (name === "status") {
      newState.status = value.value;
    }
    if (name === "carePathEvent") {
      newState.carePathEvent = value.value;
    }
    if (name === "name") {
      newState.name = value.value;
    }
    if (name === "shouldSendReminder") {
      newState.shouldSendReminder = value;
    }
    if (name === "needsTransportation") {
      newState.needsTransportation = value;
    }
    if (name === "address") {
      newState.address = value.value;
    }
    if (name === "note") {
      newState.note = value.value;
    }
    setFormBody({ ...newState });
  };

  const setSelectedDateFunc = (selectedDate) => {
    setSelectedDate(selectedDate);
    let newState = formBody;
    if (selectedDate) {
      const date = new Date(selectedDate);
      newState.appointmentDateTime = date;
      setFormBody({ ...newState });
    }
  };

  const onsubmitAppointment = () => {
    let newState = formBody;
    newState.carePathId = carePath?.id;
    axios
      .post(baseURL + `/save`, newState, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        setFileUploadResetCounter(fileUploadResetCounter + 1);
        onHideClicked(null, false, "COMPLETE");
      })
      .catch((error) => {
        formatErrorMessage(error);
      });
  };
  const handleChange = (e) => {
    if (e && e[0]) {
      let prov = e[0];
      let newState = formBody;
      if (prov) {
        newState.provider = prov.provider;
        setFormBody({ ...newState });
      }
      findAddress(prov.value);
    }
  };
  const formatErrorMessage = (error) => {
    if (error?.response?.data?.message.includes("|")) {
      var errors = error?.response?.data?.message.split("|");
      setMultipleErrors(errors);
    } else {
      setErrors(error?.response?.data?.message);
    }
  };
  const [errors, setErrors] = useState(null);
  const [multipleErrors, setMultipleErrors] = useState([]);

  const getProviders = () => {
    return axios.get(baseURL + `/get-providers`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const enableButton = () => {
    if (
      formBody &&
      formBody.status &&
      formBody.name &&
      formBody.appointmentDateTime &&
      formBody.durationInMinutes &&
      formBody.address &&
      formBody.patientId &&
      formBody.provider
    ) {
      if (formBody.shouldSendReminder) {
        if (formBody.notificationDelay && formBody.notificationDelayUnit) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Container>
        <Col sm={4}>
          {errors && errors.length ? (
            <ErrorToast
              className="float-end"
              errorParam={errors}
              multipleErrors={multipleErrors}
            />
          ) : null}
        </Col>
        <Row>
          <Col xs={12}>
            <span className="fw-bold">Patient: </span>
            {patient.lastName}, {patient.firstName}
            <div className="mt-1">
              {patient.memberId} (
              {patient.active ? (
                <span className="text-success">Active</span>
              ) : (
                <span className="text-danger">Inactive</span>
              )}
              ) <span className="fw-bold">DOB: </span>{" "}
              {formatDate(patient.dateOfBirth)} ({patient.age})
            </div>
            <span>{"    "}</span>
          </Col>
          <Col xs={12}>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Row>
                  <Col xs={6}>
                    <Form.Label className="fw-bold">
                      Appointment Name <RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => handleInputChange("name", e.target)}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={4}>
                    <Form.Label className="fw-bold">
                      Date <RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <DatePickerStyled>
                      <DatePicker
                        name="dueDate"
                        filterTime={filterPassedTime}
                        value={selectedDate}
                        selected={selectedDate}
                        onChange={(e) => setSelectedDateFunc(e)}
                        showTimeSelect
                        className="form-control"
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), 0), 6)}
                        maxTime={setHours(setMinutes(new Date(), 30), 18)}
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "0px, 0px"
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "scrollParent"
                          }
                        }}
                        autoComplete="off"
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </DatePickerStyled>
                  </Col>
                  {/* <Col xs={4}>
                    <Form.Label className="fw-bold">
                      Start time<RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <TimePicker
                      start="6:00"
                      value={timeSelected}
                      onChange={handleTimeChange}
                      end="18:30"
                      step={30}
                    />
                  </Col> */}
                  <Col sm={3}>
                    <DropdownComponent
                      name="duration"
                      list={durationList}
                      handleInputChange={handleInputChange}
                      label="Duration:"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.durationInMinutes}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={4}>
                    <Form.Label className="fw-bold">
                      Search Providers by Name <RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <StyledTypeAhead
                      id="carepathcoor-typeahead-single"
                      labelKey="text"
                      isLoading={isLoading}
                      onSearch={asyncsearch}
                      options={options}
                      onChange={(e) => handleChange(e)}
                      placeholder="Search Providers by Name"
                    />
                  </Col>
                  <Col xs={6}>
                    <DropdownComponent
                      name="address"
                      list={addressList}
                      handleInputChange={handleInputChange}
                      label="Address"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.address}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={4}>
                    <Form.Label className="fw-bold">
                      Patient Reminder <RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`custom-inline-${type}`} className="mb-3">
                          <Form.Check
                            custom
                            inline
                            label="Yes"
                            type={type}
                            id={`custom-inline-${type}-1`}
                            onClick={() =>
                              handleInputChange("shouldSendReminder", true)
                            }
                            checked={formBody.shouldSendReminder}
                          />
                          <Form.Check
                            custom
                            inline
                            label="No"
                            type={type}
                            id={`custom-inline-${type}-2`}
                            onClick={() =>
                              handleInputChange("shouldSendReminder", false)
                            }
                            checked={!formBody.shouldSendReminder}
                          />
                        </div>
                      ))}
                    </Form>
                  </Col>
                  {formBody.shouldSendReminder && (
                    <Col xs={4}>
                      <DropdownComponent
                        name="notificationDelay"
                        list={notificationDelayList}
                        handleInputChange={handleInputChange}
                        label="Notification Delay:"
                        required={true}
                        isOpen={isOpen}
                        val={formBody.notificationDelay}
                      />
                    </Col>
                  )}
                  {formBody.shouldSendReminder && (
                    <Col xs={4}>
                      <DropdownComponent
                        name="notificationDelayUnit"
                        list={units}
                        handleInputChange={handleInputChange}
                        label="Units:"
                        required={true}
                        isOpen={isOpen}
                        val={formBody.notificationDelayUnit}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col xs={4}>
                    <Form.Label className="fw-bold">
                      Patient Needs Transportation <RedAsterisk>*</RedAsterisk>
                    </Form.Label>
                    <Form>
                      {["radio"].map((type) => (
                        <div
                          key={`custom-inline-transportation-${type}`}
                          className="mb-3"
                        >
                          <Form.Check
                            custom
                            inline
                            label="Yes"
                            type={type}
                            id={`custom-inline-transportation-${type}-1`}
                            onClick={() =>
                              handleInputChange("needsTransportation", true)
                            }
                            checked={formBody.needsTransportation}
                          />
                          <Form.Check
                            custom
                            inline
                            label="No"
                            type={type}
                            id={`custom-inline-transportation-${type}-2`}
                            onClick={() =>
                              handleInputChange("needsTransportation", false)
                            }
                            checked={!formBody.needsTransportation}
                          />
                        </div>
                      ))}
                    </Form>
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={6}>
                    <Form.Group disabled={parent === "CPCard"}>
                      <Form.Label className="fw-bold d-block">
                        Care Program
                      </Form.Label>
                      <Dropdown
                        disabled={parent === "CPCard"}
                        value={selectedCarePath.name}
                      >
                        <Dropdown.Toggle
                          disabled={parent === "CPCard"}
                          as={DropDownButton}
                          cusWidth="100% !important"
                        >
                          {selectedCarePath ? (
                            selectedCarePath.name
                          ) : (
                            <span>-Select-</span>
                          )}
                          <i className="fa fa-chevron-down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          as={CustomMenuStyled}
                          cusHeight={""}
                          disabled={parent === "CPCard"}
                        >
                          {patient &&
                          patient.carePaths &&
                          patient.carePaths.length > 0
                            ? patient.carePaths.map((cp) => (
                                <Dropdown.Item
                                  key={cp.id}
                                  onClick={() => handleCPChange(cp)}
                                >
                                  {cp.name}
                                </Dropdown.Item>
                              ))
                            : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <DropdownComponent
                      name="carePathEvent"
                      list={carePathStepsList}
                      handleInputChange={handleInputChange}
                      label="Care Program Event:"
                      isOpen={isOpen}
                      val={formBody.carePathEvent}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={4}>
                    <DropdownComponent
                      name={"status"}
                      list={appointmentModalStatus}
                      handleInputChange={handleInputChange}
                      label="Status:"
                      required={true}
                      isOpen={isOpen}
                      val={formBody.status}
                    />
                  </Col>
                </Row>
                <Row className="appointment-detail mt-3">
                  <Col xs={6}>
                    <Form.Label className="fw-bold">
                      Add A Note
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={(e) => handleInputChange("note", e.target)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={4}>
                  <FileUpload onChange={handleUploadFileChange} clearStateProp={fileUploadResetCounter} />{" "}
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          <StyledFooter>
            <Button onClick={() => onCancelClick()}>
              Cancel
            </Button>{" "}
            <Button
              disabled={!enableButton()}
              onClick={() => onsubmitAppointment()}
            >
              Submit
            </Button>
          </StyledFooter>
        </Row>
      </Container>
      <Modals
        show={
          show && patient.careGaps.length != null && patient.careGaps.length > 0
        }
        onHide={() => setShow(false)}
        title="Care Opportunities"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        completeBtn={"CLOSE"}
        hideFooter={true}
        hideClickButton={true}
        showExtraButton={false}
        body={
          <GapModal
            patient={patient}
            setShow={setShow}
            updateParent={updateParent}
          />
        }
      />
    </>
  );
};
export default AppointmentModal;
