import { GET_PAYERS, GET_ALL_PAYERS,  GET_PRACTICE, UPDATE_PRACTICE, RESET_UPDATE, GET_ALL_PAYERS_FROM_COLLECTION, UPDATE_PRACTICE_LEVEL  } from "../../actions/types";

const initialState = {
    payersDetails: [],
    updateSuccess: false,
    allOrganizations:[],
    allOrganizationsFromColletion:[]

};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PAYERS:
      return {
        ...state,
        payers: payload,
      };
      case GET_PRACTICE:
        return {
          ...state,
          payersDetails: payload
        };

        case GET_ALL_PAYERS:
          return {
            ...state,
            allOrganizations: payload
          };
        case GET_ALL_PAYERS_FROM_COLLECTION:
          return {
            ...state,
            allOrganizationsFromColletion: payload
          };
          
        case UPDATE_PRACTICE:
          return {
            ...state,
            payersDetails: payload,
            updateSuccess: true
          };
          case UPDATE_PRACTICE_LEVEL:
            return {
              ...state,
              allOrganizations: payload,
            };
    default:
      return state;
  }
}
