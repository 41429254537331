import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateTime} from "../../utils/util";
import { getFileDownloadGeneric } from "../../actions/fileUploadActions";
import { Button } from "react-bootstrap";

const componentName = "ExportSummary";

const ExportSummary = (exportSummaries) =>{

    return (
        <div className="container">
        {exportSummaries.exportSummary.map((exportSummary, idx) => {
            return (
                <Row id={`${componentName}-exportSummary-${idx}`} className="mb-3 border pt-3">
                    <Col sm={12}>
                        <Row className="mb-2">
                            <Col md={6}>
                                <b id={`${componentName}-exportName-${idx}`}>{exportSummary.name}</b> <span id={`${componentName}-exportOrganization-${idx}`}>{exportSummary.organizationName}</span>
                            </Col>
                            <Col md={6} className="text-end">
                                <span id={`${componentName}-exportCreatedOn-${idx}`}>{formatDateTime(exportSummary.createdOn)}</span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <p id={`${componentName}-exportText-${idx}`}>Please use following link to download the {exportSummary.type} report</p>
                                <span id={`${componentName}-exportAttachmentText-${idx}`} className="d-block">Attachments:</span>
                                {exportSummary.files.map((file, idx) => {
                                    return (
                                        <Button variant="link" onClick={() => getFileDownloadGeneric(process.env.REACT_APP_REST_API_BASE_URL + file.fileUrl, file.fileName)} id={`${componentName}-exportAttachmentLink-${idx}`} className="p-0">{file.fileName}</Button>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        })}
        </div>
    )
}
export default ExportSummary;