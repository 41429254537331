import React from 'react'

const componentName = 'VerifyPassword'

const CorrectIcon = () => <i id={`${componentName}-riskIcon`} className={`fa fa-check text-success`} />
const WrongIcon = () => <i id={`${componentName}-riskIcon`} className={`fa fa-close text-danger`} />
const specialCharacters =  " !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"
const validations = [
  {
    text: 'At least 8 characters long',
    regex: /^.{8,50}$/
  },
  {
    text: 'At least one number',
    regex: /\d/
  },
  {
    text: 'At least one uppercase letter',
    regex: /[A-Z]/
  },
  {
    text: 'At least one lowercase letter',
    regex: /[a-z]/
  },
  {
    text: `Includes special characters:  "${specialCharacters}"`,
    regex: /[!# $%&'()*+,\-.\/:;<=>?@\[\]^_`{|}~""]/
  }
]

export default function VerifyPassword({ password = '' }) {

  return (
    <div className='border rounded my-2 p-2'>
      {
        validations.map((validation, idx) => (
          <div className='d-flex align-items-center' key={idx}>
            {validation.regex.test(password) ? <CorrectIcon /> : <WrongIcon />}
            <p className='my-0 mx-2'>{validation.text}</p>
          </div>
        ))
      }
    </div>
  )
}
