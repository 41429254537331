import React, { useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCareProgramModalOpen } from "../../../../../actions/manageVisitActions";
import EditCareStep from "./modals/editCareStep";
import ReassignCareStep from "./modals/reassignCareStep";
import { useCMWorklistContext } from "../../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import { useAuth } from "../../../../../context/authContext";


const componentName = "carePathStepsKebab";

export const CarePathStepsKebab = ({
  index,
  step,
  showAfterForm,
  showBeforeForm,
  editExistingStep,
  saveNewStep,
  handleChangeCarePath,
  careProgram,
  patient,
  isNotFromPatientDrawer
 }) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const authorizedCareProgramWrite = isNotFromPatientDrawer || auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");


  const {
    selectPatient,
    toggleAssignPatientTo,
    assignPatientTo
  } = useCMWorklistContext();


  const [careStepObject, setCareStepObject] = useState({
    show: false,
    step: null,
    action: '',
    title: ''
  });

  const handleCareStepActionsShow = (e, step, action, title) => {
    switch (action) {
      case 'edit':
        dispatch(setCareProgramModalOpen(true))
        setCareStepObject({ ...careStepObject, show: true, step: step, action: action, title: 'Edit Step' })
        break;
      case 'before':
        dispatch(setCareProgramModalOpen(true))
        setCareStepObject({ ...careStepObject, show: true, step: step, action: action, title: 'Add Care Step' })
        break;
      case 'after':
        dispatch(setCareProgramModalOpen(true))
        setCareStepObject({ ...careStepObject, show: true, step: step, action: action, title: 'Add Care Step' })
        break;
      case 'delete':
        dispatch(setCareProgramModalOpen(true))
        setCareStepObject({ ...careStepObject, show: true, step: step, action: action, title: 'Delete Care Step' })
        break;
      case 'reassign':
        selectPatient(patient);
        dispatch(setCareProgramModalOpen(true))
        setCareStepObject({ ...careStepObject, show: true, step: step, action: action, title: 'Reassign Care Step' });
        default:
      break;
    }
  };

  const handleCancel = (action) => {
    setCareStepObject({ ...careStepObject, show: false })
    dispatch(setCareProgramModalOpen(false))
    if(action){
      toggleAssignPatientTo({ ...assignPatientTo,  refreshFromReassign:true });
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-end carePathKebab"
        onSelect={(eventKey, e) => handleCareStepActionsShow(e, step, eventKey)}
      >
        <Dropdown.Toggle
          variant="transparent"
          id={`${componentName}-carePathStepActions-${index}`}
          disabled={showAfterForm || showBeforeForm}
        >
          <i className="fa-solid fa-ellipsis fa-lg border-0 text-dark"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            id={`${componentName}-carePathStepActionsReassign-${index}`}
            eventKey="reassign"
            disabled={!authorizedCareProgramWrite || (!careProgram?.active || !step)}
          >
            Reassign Care Step
          </Dropdown.Item>
          <Dropdown.Item
            id={`${componentName}-carePathStepActionsEdit-${index}`}
            eventKey="edit"
            disabled={!authorizedCareProgramWrite || (!careProgram?.active || !step)}
          >
            Edit Care Step
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            id={`${componentName}-carePathStepActionsBefore-${index}`}
            eventKey="before"
            disabled={!authorizedCareProgramWrite || (!careProgram?.active || !step)}
          >
            Add Step Before
          </Dropdown.Item>
          <Dropdown.Item
            id={`${componentName}-carePathStepActionsAfter-${index}`}
            eventKey="after"
            disabled={!authorizedCareProgramWrite || (!careProgram?.active || !step)}
          >
            Add Step After
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            id={`${componentName}-carePathStepActionsRemove-${index}`}
            eventKey="delete"
            disabled={!authorizedCareProgramWrite || (!careProgram?.active || !step)}
          >
            Delete Care Step
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal id={`${componentName}-carePathStepModal`} size="md" show={careStepObject.show} onHide={handleCancel} centered>
        {careStepObject && (careStepObject.action != 'reassign') &&
          <>
            <Modal.Header>
              <Modal.Title id={`${componentName}-carePathStepModalTitle`}>{careStepObject && careStepObject.title ? careStepObject.title : ''}</Modal.Title>
              <button type="button" id={`${componentName}-carePathStepModalClose`} onClick={handleCancel} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </Modal.Header>
          </>
        }
        {careStepObject && (careStepObject.action === 'edit' || careStepObject.action === 'before' || careStepObject.action === 'after' || careStepObject.action === 'delete') &&
          <>
            <EditCareStep step={step} index={index} saveNewStep={saveNewStep}
              handleCancel={handleCancel}
              editExistingStep={editExistingStep}
              action={careStepObject.action}
            />
          </>
        }
          {careStepObject && (careStepObject.action === 'reassign') &&
          <>
            <ReassignCareStep
              step={step}
              handleCancel={handleCancel}
              handleChangeCarePath={handleChangeCarePath}
            />
          </>
        }
      </Modal>
    </>
  );
};

export default CarePathStepsKebab;