import { GET_CLIENTS, GET_DOCUMENTS, GET_ACTIVE_DOCUMENTS, REMOVE_DOCUMENT, GET_VALUES_USER_CREATE, CHANGE_CLIENT, ADD_DOCUMENT } from "../../actions/types";

const initialState = {
  clientDropDownValues: {},
  uploads: [],
  history: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CLIENTS:
      return {
        ...state,
        clientDropDownValues: payload,
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        uploads: payload.uploads,
        history: payload.history
      }
    case GET_ACTIVE_DOCUMENTS:
      return {
        ...state,
        activeUploads: payload.activeUploads,
        history: []
      }
    case REMOVE_DOCUMENT:
      return {
        ...state,
        uploads: state.uploads.filter(doc => doc.gapDocumentationId !== action.payload.gapDocumentationId),
        history: state.history.concat(payload)
      }
    case CHANGE_CLIENT:
      return {
        ...state,
        uploads: [],
        history: []
      }
    default:
      return state;
  }
}
