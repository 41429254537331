import React, { useState, useCallback } from "react";
import axios from "../../utils/apiClient";
import { AppPropertiesUtil, SessionStorageUtil } from "../../utils/localStorageUtil";
import { getProperties } from "../constantsActions";
import { useAuth } from "../../context/authContext";
import { useDispatch } from "react-redux";
import { setUserEventAction } from "../manageVisitActions";
import { convertDateToString } from "../../utils/util";
import { saveReferral } from "../../views/quickList/components/modals/Referrals/context/referralsApi";
import { Spinner } from "react-bootstrap";
const isEmpty = require("is-empty");
const useCboDropdown = (clientId, patient) => {
  const [samlResponse, setSamlResponse] = useState({})
  const [communityUrl, setCommunityUrl] = useState({})
  const auth = useAuth();
  const userName = auth.getName();
  const globalDispatch = useDispatch();
  const getSamlResponse = (thenTask) => {
    getUrls();
    let url = process.env.REACT_APP_REST_API_BASE_URL + "/v1/saml/";

    if (patient && patient.id && clientId) {
      url = `${url}response/?patientId=${patient.id}&clientId=${clientId}&type=REFERRAL&referredTo=FindHelp`
    } else {
      if (clientId) {
        url = `${url}response/?patientId=&clientId=${clientId}&type=ADMIN&referredTo=FindHelp`
      } else{
        url = `${url}response/?patientId=&clientId=AZEH&type=ADMIN&referredTo=FindHelp`
      } 
    }

    axios.get(url, {
      headers: {},
    }).then((response) => {
      thenTask(response);
    }).catch(() => {   
    });
  }

  const getUrls = () => {
    const urlForFindHelp = AppPropertiesUtil.get("ce.saml.findhelp.subject-recipient");
    if (urlForFindHelp) {
      initProperties("ce.saml.findhelp.subject-recipient")
    } else {
      setCommunityUrl(urlForFindHelp)
    }
  }

  const initProperties = async (org) => {
    let res = await getProperties();
    SessionStorageUtil.setObj("properties", res.data);
    if (res.data) {
      setCommunityUrl(res.data[org])
    }
  }

  const referComponent = useCallback(() => {
    const createReferral = () => {
      const visitExpirationDate = convertDateToString();
      const referral = {
        patientId: patient?.id,
        referrerName: userName,
        sentByFullName: userName,
        refDateUTC: new Date(Date.now()).toISOString(),
        status: "CBO_REFERRAL",
        referralType: "COMMUNITY_BASED_ORGANIZATION",
        visitExpirationDate,
        referredPractice: "",
        note: "Service Test",
        urgency: "CBO_REFERRAL",
        servicesNeeded: [
          {
            "service": "EXTERNAL_REFERRAL_TO_CBO",
            "primary": true
          }
        ],
        "diagnosisICD10dto": [
          {
            "code": "",
            "description": ""
          }
        ],

      }
      saveReferral(referral).then(() => {
        globalDispatch(setUserEventAction('REFERRAL_ADDED'))
      }).catch(error => {
      })
    }
    if (isEmpty(samlResponse)) {
      return <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }
    return (

      <form
        method="post"
        action={communityUrl}
        target="_blank">
        <input type="textarea" name="SAMLResponse" hidden id="SAMLResponse" value={samlResponse.samlResponse} readOnly />
        <input type="text" hidden name="RelayState" value={samlResponse.relayState} readOnly />
        <button className="dropdown-item" onClick={createReferral} type="submit">Refer to Community-Based Organization (HRSN)</button>
      </form>
    );

  });

  return [referComponent, getSamlResponse, setSamlResponse]
};

export default useCboDropdown;