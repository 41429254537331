import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { ThemeProvider } from "styled-components";
import EQHErrorToast from '../../../components/EQHErrorToast';
import { EQHSpinner } from '../../../components/EQHSpinner';
import Modals from '../../../components/modals';
import { theme } from '../../../components/themes/theme1';
import CustomPagination from '../../common/CustomPaginaton';
import ExportSummary from '../../quickList/ExportSummary';
import { FilterModal, FiltersResume, ReportingTable } from './components';
import { exportPatientPrograms, getExportSummary, getPatientPrograms } from './utils';
import { Helmet } from 'react-helmet';
import TaskModal from "../careTeam/TaskModal/TaskModal";
import SuccessToast from "../../common/SuccessToast";

const componentName = "ReportingAnalytics";

export const ReportingAnalytics = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [patients, setPatients] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showResume, setShowResume] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50, count: 0 });
  const [error, setError] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [followUpFilters, setFollowUpFilters] = useState([]);

  const handleOpenTaskModal = () => setShowAddTaskModal(true);

  function handleExportList() {
    setIsLoading(true);
    exportPatientPrograms(filters, followUpFilters, pagination.page).then(() => {
      summary();
    }).catch(() => setIsLoading(false));
  }

  const taskSaved = () => {
    setSuccess({message: "Task Saved Successfully"});
  }

  const summary = () => {
    getExportSummary()
      .then((response) => {
        setModalTitle("Export Summary");
        setModalSize("lg");
        setModalMessage(<ExportSummary exportSummary={response.data} />);
        setIsLoading(false);
      })
      .catch((error) => {
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
          <title>Reporting and Analytics | CareEmpower</title>
      </Helmet>
      <EQHErrorToast errors={error} handleClose={() => setError([])} />
      <div className="overflow-inherit w-100">
        <FilterModal
          show={showFilters}
          setShow={setShowFilters}
          setPatients={setPatients}
          filters={filters}
          setFilters={setFilters}
          followUpFilters={followUpFilters}
          setFollowUpFilters={setFollowUpFilters}
          setPagination={setPagination}
          pagination={pagination}
          setShowResume={setShowResume}
          setError={setError}
        />
        <Modals
          show={modalMessage != null}
          onHide={() => setModalMessage(null)}
          title={modalTitle}
          size={modalSize || "md"}
          hideClickButton={true}
          showExtraButton={false}
          hideFooter={false}
          closeBtnId={`${componentName}-closeButton`}
          body={modalMessage}
        />
        {success?.message && <SuccessToast />}
        <TaskModal
          showAddTask={showAddTaskModal}
          setShowAddTask={setShowAddTaskModal}
          taskToEdit={null}
          savedSuccessfully={taskSaved}
          currentUserByDefault={false}>
        </TaskModal>
        <Row className="sticky-top mx-0 mb-0 stickyTitle">
          <Col className="px-0">
            <Container fluid className="page-titles">
              <Row className="p-2">
                <Col sm={12}>                  
                  <button
                    id="filterGroup-Filters"
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowFilters(prev => !prev)}
                  >
                    <i className="fas fa-list me-2"></i> Filter
                  </button>
                </Col>
              </Row>
            </Container>
            <Card className="shadow-sm border-0">
              <Card.Header>
                { isLoading ?
                  <Button variant="secondary" className="d-inline-block bg-white align-top me-2 float-end">
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm" />
                  </Button> :
                  <DropdownButton
                    size="sm"
                    variant="outline-secondary"
                    className="d-inline-block bg-white align-top me-2 float-end"
                    title="Export"
                    id={`${componentName}-Export`}
                  >
                    <Dropdown.Item
                      id={`${componentName}-ExportCSV`}
                      onClick={() => handleExportList()}
                    >
                      <i className="fas fa-file-csv"></i> CSV
                    </Dropdown.Item>
                  </DropdownButton>
                }
                {showResume &&
                  <FiltersResume
                    commonFilters={filters}
                    followUpFilters={followUpFilters}
                    patients={patients}
                    pagination={pagination}
                  />
                }
                <Button variant="secondary" onClick={handleOpenTaskModal}
                        className="d-inline-block bg-white align-top me-2 float-end">
                  + Task
                </Button>
              </Card.Header>
              <Card.Body>
                <ReportingTable patients={patients} />
                <Row>
                  <Col className="mb-3">
                    {patients.length > 0 &&
                      <CustomPagination
                        paginateButClicked={page => getPatientPrograms(filters, followUpFilters, page).then(res => {
                          setPatients(res?.data?.results || []);
                          setPagination({ ...pagination, page: page });
                        })}
                        MAX_PAGE={Math.ceil(pagination.count / 50)}
                        pageNumber={Math.ceil(pagination.page)}
                      />
                    }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </ThemeProvider>
  )
}