import React from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

const EQHSpinner = () => {
  return (
    <Container fluid className="mt-2 mb-2">
      <Card>
        <Card.Body>
          <Card.Text>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export { EQHSpinner };
