import React, { useState, useEffect } from "react";
import { ToggleButtonGroup, Button } from "react-bootstrap";
import { useCMWorklistContext } from "./context/CMWorklist.context";

export const SnoozedFilters = ({
  refreshPatientList,
  filterParams=[],
  setSnoozedFilterParam,
}) => {

  const {
    isLoading,
    patientSearch,
  } = useCMWorklistContext();
  const [stepState, setStepState] = useState('')

  const defaultSnoozedFilter = [...filterParams].filter(f => f.paramName !== 'stepState')

  const filterSnoozed = (state) => {
    const filter = [{
      "paramName": "stepState",
      "paramValue": [state],
      "comparison": "eq",
      "displayValue": [ ]
    }]
    setSnoozedFilterParam(filter)
    return filter
  }

  const handleFilter = (state) =>  {
    refreshPatientList(1, [...defaultSnoozedFilter, ...filterSnoozed(state) ])
  }
  useEffect(() => {
    const filters = patientSearch?.filterParams
    if (filters) {
      setStepState(filters.filter(f => f.paramName === 'stepState')?.[0])
    }
  }, [patientSearch])


  return (
    <>
      <ToggleButtonGroup type="radio" className="careProgramFilters mt-1" name="scheduleFilters" dis>
        <Button
          id="active-filter" variant="secondary"
          className={`border px-3 text-dark rounded-start ${(stepState?.paramValue?.[0] === 'ACTIVE' || !stepState?.paramValue?.[0]) ? 'active' : ''}`}
          onClick={() => handleFilter('ACTIVE')}
          disabled={isLoading}
        >
          Active
        </Button>
        <Button
          id="snoozed-filter"
          variant="secondary"
          className={`border px-3 text-dark rounded-0 ${stepState?.paramValue?.[0] === 'SNOOZED' ? 'active' : ''}`}
          onClick={() => handleFilter('SNOOZED')}
          disabled={isLoading}
        >
          Snoozed
        </Button>
        <Button
          id="all-filter"
          variant="secondary"
          className={`border px-3 text-dark rounded-start-0 rounded-end  ${(stepState?.paramValue?.[0] === 'ALL') ? 'active' : ''}`}
          onClick={() => handleFilter('ALL')}
          disabled={isLoading}
        >
          All
        </Button>
      </ToggleButtonGroup>
    </>
  );
};

export default SnoozedFilters;