import {
  CLOSE_CARE_PATH,
  SET_CLOSE_CP_REASONS,
  INCREMENT_PATIENT_CALLS,
  SEARCH_PATIENTS,
  START_FILTER,
  UPDATE_STEP, SET_CO_REJECTION_REASONS,
} from "../types";
import axios from "../../utils/apiClient";
import { disPatchError } from "../utils";
import Axios from "axios";

const URL = process.env.REACT_APP_REST_API_BASE_URL;
let source = null

export const cancelRequest = (setCanceled) => {
  setCanceled(true);
  source && source.cancel('cancel by user');
}

export const searchPatientMain = (criteria) => {
  source = Axios.CancelToken.source();
  return axios.post(
    `${URL}/v1/patients/myPatients`, criteria,
    {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token
    }) ;
};

export const searchPatientMultiple = (criteria, endPointName) => {
  const epName = endPointName || 'myPatientsForMultipleQL'
  source = Axios.CancelToken.source();
  return axios.post(
    `${URL}/v1/patients/${epName}`, criteria,
    {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token
    }) ;
};

export const searchMissedOpportunitiesQL = (criteria) => {
    source = Axios.CancelToken.source();
    return axios.post(
        `${URL}/v1/patients/searchMissedOpportunitiesQL`, criteria,
        {
            headers: {
                "Content-Type": "application/json",
            },
            cancelToken: source.token
        }) ;
};

export const searchApppointment = (criteria) => {
  let axiosConst;
    axiosConst = axios.post(`${URL}/v1/appointment/appointment-calendar`, criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  return axiosConst
};

export const searchPatientReferrals = (criteria) => {
  return axios.post(`${URL}/v1/patients/referral-table-view`, criteria, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const updateCarePath = (provider, id, step, action) => {
  const data = {
    action: "SET_STATUS",
    careProviderId: provider,
    text: null,
    status: action,
    assignToId: null,
    stepCustomStatus: null,
  };
  return axios
      .put(process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/update-care-path/update-care-path-step?carePathInstanceId=${encodeURIComponent(id)}&carePathStepId=${step}`,
          data,
          { headers: {
              "Content-Type": "application/json"
            } }
      )
};

export const incrementPatientsCounter = (patientId) => {
  return axios.put(
    `${URL}/v1/patientCallCounter/increment?patientId=${patientId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const closeCarePath = (instanceId, reason, expired, expiredDate, expiredSource, notes) => {
  let data = {
    carePathInstanceId: instanceId,
    carePathCloseReason: "OTHER",
    comment: reason,
    commentValidForOtherReason: true,
    expired: expired,
    expiredDate: expiredDate,
    expiredSource: expiredSource,
    notes: notes ? notes : null
  };
  return axios.put(`${URL}/v1/care-paths/closeCarePath`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const retrieveCloseCpReasons = () => (dispatch) => {
  axios
    .get(
      `${URL}/v1/care-paths/closeCarepathReasons`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: SET_CLOSE_CP_REASONS,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const retrieveCareOppRejectionReasons = () => (dispatch) => {
  axios
      .get(
          `${URL}/v1/care-gaps/rejection-reasons`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
      )
      .then((res) => {
        dispatch({
          type: SET_CO_REJECTION_REASONS,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err, dispatch));
};

export const removePatientsFromList = (patientIds) => {
  return axios.post(`${URL}/v1/patients/removeUnassigned`, patientIds, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const shareUserQuicklist = (userIds, quicklistId, postUrl) => {
  let body = {
    id: quicklistId,
    sharedUsers: userIds
  };

  let url = postUrl != null ? postUrl : `${URL}/v1/custom-filtered-list/custom-list/share?id=${quicklistId}`;

  return axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteUserWorklist = (worklistId) => {
  return axios.delete(
    `${URL}/v1/custom-filtered-list/custom-list?id=${worklistId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateUserWorklist = (criteria) => {
  return axios
      .post(`${URL}/v1/custom-filtered-list/custom-list`, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      });
};

export const deleteCustomWorklist = (worklistId) => {
  return axios.delete(
    `${URL}/v1/custom-work-list/work-list?id=${worklistId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};