import React from "react";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

const componentName = "ImportSummary";

const StyledBody = styled.div`
  width: 200px;
  height: 200px;
`;

const ImportSummary = ({ notFoundRegisters }) =>{
    return (
        notFoundRegisters.length > 0
            ? <div className="container">
            <h4>Not found registers ({notFoundRegisters.length})</h4>
            <StyledBody>
                {notFoundRegisters.map((register, idx) => {
                    return (
                        <Row id={`${componentName}-importSummary-${idx}`}>
                            <b id={`${componentName}-importName-${idx}`}>{register.fullName}</b>
                        </Row>
                    )
                })}
            </StyledBody>
            </div>
            : <h4>All records were successfully uploaded</h4>
    )
}
export default ImportSummary;