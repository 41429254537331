import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { EQHDatePicker } from '../../datePicker'
import { format } from 'date-fns'
import { DropDownButton } from '../../listFilters/sharedDDL'
import { useManageVisitContext } from '../../../views/quickList/components/context/ManageVisitContext/ManageVisit.context'
import { FileUpload } from '../../FileUpload'
import { updateAction } from '../api'
import { setManageVisitOpenedFromPageTitle } from '../../../actions/manageVisitActions'
import { useDispatch } from 'react-redux'

const componentName = 'SuspectsFields'

const SuspectsFields = ({ item, setErrors }) => {
  const manageVisitState = useManageVisitContext();
  const statusOptions = manageVisitState?.patient_get?.conditionStatusesDropDownMap || {};
  const dispatch = useDispatch();

  const [files, setFiles] = useState([])

  const [updateState, setupdateState] = useState({
    forPreviousYear: false,
    icd10DiseaseDto: null,
    ids: [item?.id],
    validation: null,
    visitDate: manageVisitState?.patient_get?.visitDate
  })

  const [basisState, setbasisState] = useState({
    a1cDecimal: null,
    a1cWholes: null,
    bloodPressureDiastolic: null,
    bloodPressureSystolic: null,
    careGapAdditionalInfos: {},
    note: '',
    updateCareGapActionDto: null,
    updateTOCCareGapActionDto: null,
    updateValidationCareGapActionDto: null,
    updateValidationConditionActionDto: null,
    uploadedFiles: [],
    visit: [],
    patientId: manageVisitState?.patient_get?.patientId,
    patientLastUpdated: manageVisitState?.patient_get?.patientLastUpdated,
    visitDate: manageVisitState?.patient_get?.visitDate,
  })

  useEffect(() => {
    setbasisState({
      ...basisState,
      updateConditionActionDto: { ...updateState },
      uploadedFiles: [...files]
    })
  }, [updateState, files])

  function checkDisabled() {
    if (item?.requireDOS && !updateState?.newDateOfService) return true;
    if (checkFileRequired() && basisState?.uploadedFiles.length === 0) return true;
    if (!updateState?.newStatus) return true;
    return false
  }

  function checkFileRequired() {
    return (item?.requireFileUpload && updateState?.newStatus?.value && updateState?.newStatus?.value === 'Confirmed Diagnosis');
  }

  const handleUpdate = () => {
    setErrors([])
    updateAction(basisState)
      .then(() => {
        dispatch(setManageVisitOpenedFromPageTitle('Missed Opportunities'));
        manageVisitState.findPatientPost(manageVisitState?.patient_get?.patientId);
        manageVisitState.findPatientGet(manageVisitState?.patient_get?.patientId)
      }).catch(e => setErrors([e?.response?.data?.message]))
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Group id={`${componentName}-SuspectsStatus`}>
            <Form.Label
              id={`${componentName}-SuspectsStatusLabel`}
              className="d-block"
            >
              Status<span className="text-danger">*</span>
            </Form.Label>
            <Dropdown value={''}>
              <Dropdown.Toggle
                as={DropDownButton}
                cssOpen={true}
                id={`${componentName}-SuspectsStatusValue-${item.id}`}
                cusWidth="100% !important"
              >
                <span>
                  {updateState?.newStatus?.value
                    ? updateState?.newStatus?.value
                    : '--Select--'
                  }
                </span>
                <i className="fa fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu className='w-100 dropdown-menu-aligned'>
                {Object.keys(statusOptions).map(
                  (status) =>
                    <Dropdown.Item
                      key={statusOptions?.[status]}
                      onClick={() => setupdateState({ ...updateState, newStatus: { source: statusOptions?.[status], value: status } })}
                    >
                      {status}
                    </Dropdown.Item>
                )
                }
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col>
          <Form.Label
            id={`${componentName}-SuspectsDOSLabel-${item.id}`}
            className="d-block"
          >
            Date of Service
            <span className="text-danger">*</span>
          </Form.Label>
          <EQHDatePicker
            id={`${componentName}-SuspectsDOSValue-${item.id}`}
            onChange={(e) => setupdateState({ ...updateState, newDateOfService: e.strDate })}
            min={null}
            max="9999-12-31"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group id={`${componentName}-SuspectsFiles`}>
            <Form.Label
              id={`${componentName}-SuspectsFilesLabel`}
              className="d-block"
            >
              Evidence
              {checkFileRequired() &&
                <span className="text-danger">*</span>
              }
            </Form.Label>
            <FileUpload multiple={false} onChange={e => setFiles([...e])} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='text-end'>
          <Button
            id={`${componentName}-suspectsSubmit`}
            onClick={() => handleUpdate()}
            disabled={checkDisabled()}
          >Submit</Button>
        </Col>
      </Row>
    </>
  )
}

export default SuspectsFields