import axios from "axios/index";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { AppPropertiesUtil } from "./localStorageUtil";
import queryString from "query-string";

const isEmpty = require("is-empty");

export const reactQuickLists = [
  "all_patients",
  "active_inactive_patients",
  "my_patients",
  "requiring_action",
  "high_risk",
  "transitions_of_care",
  "referrals_sent",
  "referrals_received",
  "referrals_awaiting_response",
  "referrals_awaiting_completion",
  "chronic_conditions_asthma",
  "chronic_conditions_diabetes",
  "chronic_conditions_depression",
  "chronic_conditions_copd",
  "chronic_conditions_ckd",
  "chronic_conditions_cvd",
  "schedule",
  "preventive_care",
  "appointments",
  "custom_worklist"
];

export const carePathValues = [
  { text: "Admit", value: "ADMIT" },
  { text: "Discharge", value: "DISCHARGE" },
  { text: "PCP Appointment", value: "PCP_APPOINTMENT" },
  { text: "BH Appointment", value: "BH_APPOINTMENT" },
  { text: "Specialist Appointment", value: "SPECIALIST_APPOINTMENT" },
  { text: "Care Program Start Date", value: "CARE_PATH_START_DATE" },
  { text: "Previous Step", value: "PREVIOUS_STEP" },
  { text: "Nurse Appointment", value: "NURSE_APPOINTMENT" },
];

export const expiredSources = [
  { value: "Family/Friend" },
  { value: "Provider" },
  { value: "Documentation" },
  { value: "Other" },
];

const { format } = require("date-fns");

export const getDisplayValue = (list, param) => {
  let obj = list.find((o) => o.value === param);
  if (obj == null) {
    return;
  }
  return obj.text;
};

export const getReferredToValue = (list, param) => {
  let obj = list.find((o) => o.value === param);
  if (obj == null) {
    return param;
  }
  return obj.text;
};

const formatStringProper = (str) => {
  return str
    .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
    .replace(/^[^ ]/g, (match) => match.toUpperCase());
};

const getAllObjsByPath = (obj, path) => {
  const list = [];
  for (let i = 0; i < path.length; i++) {
    let pathObj = getObjByPath(obj, path, i + 1);
    if (!(Array.isArray(pathObj) && i + 1 < path.length)) {
      list.push(pathObj);
    }
  }
  return list;
};

const getObjByPath = (obj, path, limit) => {
  let returnObj = obj;
  if (!limit) {
    limit = path.lenght;
  }
  for (let i = 0; i < limit; i++) {
    returnObj = returnObj[path[i]];
  }
  return returnObj;
};

const getNestedValues = (obj, properties, filter) => {
  const set = new Set();
  const action = (propertyName, level, obj) => {
    if (!properties || properties.size === 0) {
      set.add(obj);
    } else {
      for (const propName of properties) {
        if (obj.hasOwnProperty(propName)) {
          set.add(obj[propName]);
        }
      }
    }
  };

  visitAllObjs(obj, action, filter);
  return set;
};

const getPathToObj = (obj, filter) => {
  const paths = [];
  const action = (propertyName, level, obj) => {
    paths.push(level);
  };

  visitAllObjs(obj, action, filter);
  return paths;
};

const visitAllObjs = (obj, action, filter) => {
  visitAllObjsHelper(null, [], obj, action, filter);
};

const visitAllObjsHelper = (propertyName, level, obj, action, filter) => {
  if (Array.isArray(obj)) {
    let i = 0;
    for (let arrObj of obj) {
      visitAllObjsHelper(propertyName, [...level, i], arrObj, action, filter);
      i++;
    }
  } else if (typeof obj === "object") {
    let filterResult = !filter || (filter && filter(propertyName, level, obj));
    if (filterResult) {
      action(propertyName, level, obj);
    }

    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] && typeof obj[prop] === "object") {
          visitAllObjsHelper(prop, [...level, prop], obj[prop], action, filter);
        }
      }
    }
  }
};

const formatRecommendedNextVisit = (nextVisit, recentVisit) => {
  return formatDate(nextVisit);
}

const formatRecommendedNextVisitInfo = (info, nextVisit, lastVisit) => {
  const date = new Date();
  const today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  const todayMilliseconds = Date.parse(today);
  const valueMilliseconds = Date.parse(nextVisit);
  const ninetyDaysAgo = todayMilliseconds - (1000 * 60 * 60 * 24 * 90);
  if (info == null) {
    info = "No information reported at this time"
  }
  if (valueMilliseconds < todayMilliseconds || lastVisit == null || lastVisit < ninetyDaysAgo) {
    if (nextVisit != null) {
      return info + " : " + formatDate(nextVisit);
    }
  }
  return info;
}

const formatLastVisit = (info) => {
  if (info == null) {
    info = "No date Available"
  }
  else{
    info =  formatDate(info)
  }
  return info
}
//) ?? "No information reported at this time"

//update for timezone
const formatDate = (value, dateFormat = "MM/dd/yyyy") => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, dateFormat);
  return output;
};

const formatDateModern = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, "MMM d, yyyy");
  return output;
};

// Displays date formatted for user TZ
const formatDateModernLocal = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  const output = format(date, "MMM d, yyyy");
  return output;
};

const formatReverseDate = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  const output = format(date, "yyyy-MM-dd");
  return output;
};

const formatTime = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const tzName = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();
  const output = format(date, "p");
  return output + " " + tzName;
};

const formatTimeMinimal = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  const tzName = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();
  const output = format(date, "p");
  return output + " ";
};


const formatDateWithTime = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, "MM/dd/yyyy hh:mm:ss");
  return output;
};

const formatLocalDate = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  const output = format(date, "MMM dd, yyyy");
  return output;
};

const convertToMMddYYYY = (value) => {
  if ((typeof value == 'undefined') || (value == null) ||
    (value.length <= 0)) {
    return '';
  }
  var year = value.substring(0, 4);
  var month = value.substring(5, 7);
  var day = value.substring(8, 10);
  return month + '/' + day + '/' + year;
};

const getWrittenDate = (date) => {
  let day = null;
  let month = null;
  const dayOfWeek = new Date(date).getDay();
  const monthOfYear = new Date(date).getMonth();
  const dayofMonth = new Date(date).getDate();
  if (!isNaN(dayOfWeek)) {
    day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][dayOfWeek];
  }
  if (!isNaN(monthOfYear)) {
    month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][monthOfYear];
  }
  return day + ", " + month + " " + dayofMonth;
};

const formatDateVanilla = (value, formatPattern, timeZoneOffset = false) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  timeZoneOffset && date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, formatPattern);

  return output;
};

const formatDuration = (date, duration) => {
  let newDate = new Date(date);
  let adjustedDate = new Date(newDate.getTime() + duration * 60 * 1000);
  let tmz = newDate
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  newDate = newDate
    .toLocaleTimeString("en-US")
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
  adjustedDate = adjustedDate
    .toLocaleTimeString("en-US")
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");

  return newDate + " " + tmz + " - " + adjustedDate + " " + tmz;
};

const addOrSubtractDate = (value, op, dateInput) => {
  let date = new Date();
  if (dateInput) {
    date = new Date(dateInput)
  }
  if (op === "add") {
    date.setDate(date.getDate() + value);
  } else {
    date.setDate(date.getDate() - value);
  }
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, "yyyy-MM-dd");
  return output;
};

const addOrSubtractDateVanilla = (value, op, dateInput) => {
  let date = new Date();
  if (dateInput) {
    date = new Date(dateInput)
  }
  if (op === "add") {
    date.setDate(date.getDate() + value);
  } else {
    date.setDate(date.getDate() - value);
  }
  return date;
};

const formatDateTime = (value) => {
  if (isEmpty(value)) {
    return "";
  }

  const date = new Date(value);
  const tzName = date
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  const output = format(date, "MM/dd/yyyy p");
  return output + " " + tzName;
};

const formatDateTimeShifted = (value) => {
  if (isEmpty(value)) {
    return "";
  }

  const date = new Date(value);
  const tzName = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();
  const output = formatDateWithPattern(date, "MM/dd/yyyy p");
  return output + " " + tzName;
};

const formatDateWithHourMinutes = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  return format(date, "MM/dd/yyyy p");
};

const formatDateWithMonthInTextAndHourMinutes = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  return format(date, 'MMM dd, yyyy p');
};

const formatTimeOnly = (value) => {
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  const tzName = date
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  const output = format(date, "p");
  return output + " " + tzName;
};

const _calculateAge = (birth) => {
  //const formattedBirth =
  // birth.monthValue + "/" + birth.dayOfMonth + "/" + birth.year;
  const birthday = new Date(birth);
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
const convertDateToString = () => {
  var date = new Date();
  date.setDate(date.getDate() + 180);

  let day = null;
  if (date.getDate().toString().length < 2) {
    day = "0" + date.getDate();
  } else {
    day = date.getDate();
  }

  var month = date.getMonth() + 1;
  month = (month < 10 ? "0" : "") + month;

  var finalDate = date.getFullYear() + "-" + month + "-" + day;
  return finalDate;
};

const arrayToKVP = (arr, keyField, valueField) => {
  if (!arr || !Array.isArray(arr)) {
    return {};
  }
  return arr.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField];
    return obj;
  }, {});
};

const getFieldValueLabel = (fieldValue) => {
  // let label = x.caption.includes(".")
  //   ? x.caption.split(".").pop()
  //   : x.caption;
  return fieldValue.caption;
};

const getFieldValueVal = (fieldValue) => {
  let value = fieldValue.value;
  if (fieldValue.value && Array.isArray(fieldValue.value)) {
    if (fieldValue.value.length === 0) {
      value = null;
    } else if (fieldValue.value.length === 1) {
      value = fieldValue.value[0];
    } else if (fieldValue.value.length === 2) {
      value = fieldValue.value[1];
    } else {
      value = fieldValue.value.toString();
    }
  } else if (
    typeof fieldValue.value === "object" &&
    fieldValue.value !== null
  ) {
    if (
      fieldValue["@class"] ===
      "com.hbi.ce.backend.common.filestorage.UploadedFileMetadataDto"
    ) {
      value = fieldValue;
    } else {
      value = JSON.stringify(fieldValue);
    }
  }
  if (!value) {
    value = null;
  }
  return value;
};

const isObjEmpty = (obj, ignore) => {
  if (!obj) {
    return true;
  }
  for (const key in obj) {
    if (
      Object.hasOwnProperty.call(obj, key) &&
      ((ignore && !Object.hasOwnProperty.call(ignore, key)) || !ignore)
    ) {
      if (obj[key]) {
        return false;
      }
    }
  }
  return true;
};

const handlePkgErrors = (...errors) => {
  return <>{errors.map((x) => (x ? "Error With Service" : ""))}</>;
};

const getPatientProfileLink = (
  paitentId,
  location,
  tab = null,
  loadPreviousSettings = true,
  itemId = null
) => {
  let url = `/patient-profile?patientId=${encodeURIComponent(paitentId)}${tab ? `&tab=${tab}` : ""}${itemId ? `&itemId=${encodeURIComponent(itemId)}` : ""}&returnTo=${encodeURIComponent(`${location.pathname}`)}`;
  if (loadPreviousSettings)
  {
    url += encodeURIComponent("?loadLastQuerySettings=true");
  }

  const urlString = window.location.href;
  const index = urlString.indexOf("?");
  if (index >= 0) {
    const paramsMeta = urlString.substring(index);
    const params = queryString.parse(paramsMeta);
    if (params.id && !isNaN(params.id)) {
      url += encodeURIComponent("&id=" + params.id + "&customListName=" + params.customListName);
    }
  }
  return url;
};

const getPatientProfileLinkRx = (
  paitentId,
  location,
  tab = null,
  loadPreviousSettings = true
) => {
  let url = `/patient-profile?patientId=${encodeURIComponent(paitentId)}${tab ? `&tab=${tab}` : ""
    }&selected=rx&returnTo=${encodeURIComponent(`${location.pathname}`)}`;
  if (loadPreviousSettings) {
    url += encodeURIComponent("?loadLastQuerySettings=true");
  }

  return url;
};

const getLandingPageURL = (landingPage) => {
  let landingPageURL = "";
  switch (landingPage ? landingPage.toLowerCase() : "") {
    case "":
      landingPage = "/all_patients";
      break;
    default:
      landingPageURL = "/" + landingPage;
      break;
  }
  return landingPageURL;
};

const getKeyAndId = (uniqueId) => {
  return { id: uniqueId, key: uniqueId };
};

const formatDateWithPattern = (value, formatPattern) => {                
  if (isEmpty(value)) {
    return "";
  }
  const date = new Date(value);
  // fix timezone issue
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const output = format(date, formatPattern);
  return output;
};

const getWrittenLongDate = (inputDate) => {
  if (inputDate) {
    const date = new Date(inputDate);
    const year = new Date(date).getFullYear();
    const day = new Date(date).getDate();
    const month = date.toLocaleString('default', {month: 'short'});
    return `${month} ${day}, ${year}`;
  }
  return ""
};

export {
  formatRecommendedNextVisit,
  formatRecommendedNextVisitInfo,
  formatLastVisit,
  convertToMMddYYYY,
  getNestedValues,
  formatDateTime,
  formatDateTimeShifted,
  formatTime,
  formatTimeMinimal,
  formatDateWithTime,
  formatDateWithMonthInTextAndHourMinutes,
  formatDate,
  formatDateModern,
  formatDateModernLocal,
  formatReverseDate,
  formatLocalDate,
  formatTimeOnly,
  getPathToObj,
  getAllObjsByPath,
  getObjByPath,
  addOrSubtractDate,
  addOrSubtractDateVanilla,
  _calculateAge,
  convertDateToString,
  formatDateVanilla,
  getWrittenDate,
  formatDuration,
  formatStringProper,
  arrayToKVP,
  getFieldValueLabel,
  getFieldValueVal,
  isObjEmpty,
  handlePkgErrors,
  getPatientProfileLink,
  getLandingPageURL,
  getPatientProfileLinkRx,
  getKeyAndId,
  formatDateWithHourMinutes,
  formatDateWithPattern,
  getWrittenLongDate
};

export const getDynamicDisplayValue = (
  list,
  param,
  filteredField,
  returnedField
) => {
  let obj = list.find((o) => o[filteredField] === param);
  if (obj == null) {
    return;
  }
  return obj[returnedField];
};

export const preventiveCarePatientFilterParams = [
  {
    comparison: "eq",
    displayValue: [],
    paramName: "Patient Name",
    paramValue: [],
  },
  {
    comparison: "eq",
    displayValue: [],
    paramName: "Birth Date",
    paramValue: [],
  },
];

const headerContentType = {
  csv: "text/csv",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  gif: "image/gif",
  jfif: "image/jpeg",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  json: "application/json",
  oxps: "application/oxps",
  pdf: "application/pdf",
  png: "image/png",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  tiff: "image/tiff",
  txt: "text/plain",
  tif: "image/tiff",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xml: "application/xml",
  xps: "application/vnd.ms-xpsdocument",
  zip: "application/zip",
};

export const downloadSecureFile = (link, fileName) => {
  let fileExtension = fileName
    .substring(fileName.lastIndexOf(".") + 1)
    .toLowerCase();
  let returnedContentType = headerContentType[fileExtension];
  if (returnedContentType === null) {
    returnedContentType = "application/octet-stream";
  }

  axios
    .get(link, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      let file = new Blob([response.data], { type: returnedContentType });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = fileName;
      link.click();
    })
    .catch((error) => {
    });
};

export const isValidPhoneNumber = (value) => {
  let pattern1 = /^\d{3}-\d{3}-\d{4}$/;
  return value.match(pattern1);
};

export const isValidEmail = (value) => {
  let pattern1 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return value.match(pattern1);
};

export const getMultipleDescToSingle = (value) => {
  let arr = []
  if (typeof value === 'string') {
    arr = value.split("\n")
  }
  else {
    arr = value
  }

  return [...new Set(arr)];
};

export const getRiskColor = (riskLevel) => {
  const riskInfo = new Map([
    ['GREEN', { color: '#3DCA37', value: 'Very Low' }],
    ['BLUE', { color: '#188DCD', value: 'Low' }],
    ['YELLOW', { color: '#FDCA6C', value: 'Medium' }],
    ['ORANGE', { color: '#FD7E14', value: 'High' }],
    ['RED', { color: '#DD6161', value: 'Very High' }],
    ['GRAY', { color: '#D1D3D5', value: 'Unknown' }]
  ]);

  return riskInfo.has(riskLevel) ? riskInfo.get(riskLevel) : riskInfo.get('GRAY');
}

export const getRiskLevelStyle = (riskLevel) => {
  const riskLevelStyle = new Map([
    ['Very Low', 'success'],
    ['Very Low Risk', 'success'],
    ['Low', 'success'],
    ['Low Risk', 'success'],
    ['Medium', 'warning'],
    ['Medium Risk', 'warning'],
    ['High', 'danger'],
    ['High Risk', 'danger'],
    ['Very High', 'danger'],
    ['Very High Risk', 'danger'],
    ['Default', 'light'],
  ]);

  return riskLevelStyle.has(riskLevel) ? riskLevelStyle.get(riskLevel) : riskLevelStyle.get('Default');
}

export const getscraScoreStyle = (scraScore) => {
  if (scraScore >= 28) {
    return 'danger';
  } else if (scraScore >= 20) {
    return 'warning';
  } else if (scraScore >= 0) {
    return 'success'
  }

  return 'success';
}

export const formatPhoneNumber = (value, newValue) => {
  if (!(newValue + "-" === value)) {
    if (newValue.match(/^\d{3}$/) || newValue.match(/^\d{3}-\d{3}$/)) {
      newValue = newValue + "-";
    }
  }

  return newValue;
};

export const isAnyTOCCareGapCreatedWithinADay = (careGaps) => {
  const arrDate = careGaps.filter(
    (e) =>
      e.abbreviation === "ADT-IP" ||
      e.abbreviation === "ADT" ||
      e.abbreviation === "FUH" ||
      e.abbreviation === "MRP"
  );
  const dt = new Date(Math.max(...arrDate.map((e) => new Date(e.createdOn))));
  const bool = addOrSubtractDateVanilla(1, "add", dt) > new Date();
  return bool;
};

export const uuid = () => {
  return uuidv4();
}

export const eqhUUID = () => {
  return `uuid://${uuidv4()}`;
}

export const logDebug = (func) => {
  if (AppPropertiesUtil.get("react.properties.logMode") === "debug") {
  }
};

export const getError = (err) => {
  let errorMessage = err + "";
  if (err?.response?.data?.message) {
    errorMessage = err.response.data.message;
  }
  return errorMessage;
};

export const getQuickListId = () => {
  const url = window.location.href;
  const index = url.indexOf("?");
  if (index >= 0) {
    const paramsMeta = url.substring(index);
    const params = queryString.parse(paramsMeta);
    return params.id;
  }
  return null;
};

export const getCustomWorklistName = () => {
  const url = window.location.href;
  const index = url.indexOf("?");
  if (index >= 0) {
    const paramsMeta = url.substring(index);
    const params = queryString.parse(paramsMeta);
    return params.name;
  }
  return null;
};

export const getCustomListName = () => {
  const url = window.location.href;
  const index = url.indexOf("?");
  if (index >= 0) {
    const paramsMeta = url.substring(index);
    const params = queryString.parse(paramsMeta);
    return params.customListName;
  }
  return null;
};

export const getCustomListNotes = () => {
  const url = window.location.href;
  const index = url.indexOf("?");
  if (index >= 0) {
    const paramsMeta = url.substring(index);
    const params = queryString.parse(paramsMeta);
    return params.customListNotes;
  }
  return null;
};

export const convertMemberToManageViewPatient = (member) => {
  const propertyMappings = {
    id: "patientId",
    programId: "patientProgramId",
    riskLevel: "customCase",
  };

  let patient = { ...member };

  for (const [newPropertyKey, memberPropertyKey] of Object.entries(propertyMappings)) {
    let memberValue = member[memberPropertyKey];

    // Custom cases
    if (memberPropertyKey == "customCase")
    {
      // Custom case for My Workspace
      if (newPropertyKey === "riskLevel" && !member.hasOwnProperty("riskLevelColour")) {
        const riskDetails = getRiskColor(member["riskLevel"]);
        memberValue = riskDetails.value;
        patient["riskLevelColour"] = riskDetails.color;
      }
    }

    if (memberValue !== undefined) { 
      patient[newPropertyKey] = memberValue;
    }
  }

  return patient;
};

export const splitDateTime = (dateTime) => {
  const lastCommaIndex = dateTime.lastIndexOf(',');
  const date = dateTime.substring(0, lastCommaIndex);
  const time = dateTime.substring(lastCommaIndex + 1).trim();
  return [date, time];
};

export const RedAsterisk = () => <span style={{ color: 'red' }}>*</span>;

export const getStatusColors = (status) => {
  const colors = {
    'SNOOZED': {
      bg: '#e1edf5',
      font: '#3987bd'
    },
    'PENDING': {
      bg: '#fbecc2',
      font: '#eba151'
    },
    'OVERDUE': {
      bg: '#f8dede',
      font:'#d12026'
    },
    'COMPLETED': {
      bg: '#eef5ec',
      font: '#519940'
    },
    'OPEN': {
      bg: '#faebdd',
      font:'#e0791e'
    },
    'SKIPPED': {
      bg: '#eaebeb',
      font:'#75797b'
    }
  }
  return colors?.[status] || colors?.SKIPPED
}

export const CARE_PATHS_COLORS = {
  'Hub Assigned': {
    bg: '#DFF0FA',
    font: '#007DB7'
  },
  'Outreach in Progress': {
    bg: '#FBECC2',
    font: '#E89740'
  },
  'Enrolled': {
    bg: '#EEF5EC',
    font:'#519940'
  },
  'Close Care Program': {
    bg: '#EAEBEB',
    font: '#86898B'
  }
};
export const getCarePathsColors = (status) => {
  return CARE_PATHS_COLORS?.[status] || CARE_PATHS_COLORS?.['Hub Assigned']
}