import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { Card, Col, Form } from "react-bootstrap";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Text,
} from "recharts";

let ctx;

export const measureText14HelveticaNeue = (text) => {
  if (!ctx) {
    ctx = document.createElement("canvas").getContext("2d");
    ctx.font = "14px 'Helvetica Neue";
  }

  return ctx.measureText(text).width;
};

const componentName = "BarCharts";

const BAR_AXIS_SPACE = 10;

const BarGraph = ({ data, idx }) => {
  const [graphData, setGraphData] = useState(null);
  const [type, setType] = useState(null);
  const [dataRows, setDataRows] = useState(null);

  useEffect(() => {
    setType(data[0]);
    let dataCopy = data;
    data.slice(1).forEach((val, index) => {
      let result = 0;
      let allClosed = 0;
      let rowValues = val.rows;
      rowValues.map((item) => {
        if (item.indicator === 'All Closed' || item.indicator === 'Open') {
          result = result + item.value;
        }
        if(item.indicator && item.indicator === 'All Closed'){
          allClosed = item.value;
        }
      });
      dataCopy[index + 1].total = result;
      dataCopy[index + 1].allClosed = allClosed;
    });
    dataCopy.shift();
    setGraphData(dataCopy);
  }, [data]);

  useEffect(() => {
    if (graphData) {
      let keys = [];
      let newObj = {};
      
      graphData.map((org) => {
        let percentageCompleted = 0;
        if(org.total && org.total > 0){
           percentageCompleted =((org.allClosed)/org.total) * 100;   
           percentageCompleted = percentageCompleted.toFixed(2)
        }
        newObj = {};
        newObj.provider = `${org.healthProvider}(${percentageCompleted}%)`;
        newObj.total = org.total;
        org.rows.map((row, index) => {
          newObj[row.indicator] = row.value;
        });
        keys.push(newObj);
      });
      setDataRows(keys);
    }
  }, [graphData]);

  const yKey = "total";
  const xKey = "provider";

  const maxTextWidth = useMemo(
    () =>
      graphData &&
      graphData.reduce((acc, cur) => {
        const value = cur[yKey];
        const width = measureText14HelveticaNeue(value);
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [graphData, yKey]
  );

  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          {graphData ? (
            <>
              <h5 id={`${componentName}-GapTitle-${idx}`} className="fw-bold text-center mb-3">{type}</h5>
              <Table id={`${componentName}-GapTable-${idx}`} striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Open</th>
                    <th>Closed by CCD</th>
                    <th>Closed by Claim/Lab/Quality</th>
                    <th>Closed by Provider</th>
                    <th>Ineligible / Excluded</th>
                    <th>All Closed</th>
                  </tr>
                </thead>
                <tbody>
                  {graphData.map((item) => (
                    <tr>
                      <td>{item.healthProvider}</td>
                      {item.rows.map((row) => (
                        <td>{row.value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : null}

          {graphData ? (
            <ResponsiveContainer
              width={"98%"}
              id={`${componentName}-GapGraph-${idx}`}
              height={50 * graphData.length}
              debounce={50}
              style={{ marginLeft: "100px" }}
            >
              <BarChart
                data={dataRows}
                layout="vertical"
                margin={{
                  left: 40,
                  right: maxTextWidth + (BAR_AXIS_SPACE - 8),
                }}
              >
                <XAxis axisLine={false} type="number" />
                <YAxis
                  yAxisId={0}
                  dataKey={xKey}
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={(value) => value.toLocaleString()}
                  tick={{
                    transform: `translate(${
                      maxTextWidth + BAR_AXIS_SPACE - 50
                    }, 0)`,
                  }}
                  width={170}
                  margin={{ right: 50 }}
                />
                <YAxis
                  orientation="right"
                  yAxisId={1}
                  dataKey={yKey}
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={(value) => value.toLocaleString()}
                  mirror
                  tick={{
                    transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
                  }}
                />
                <Tooltip />
                <Bar
                  dataKey="Open"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#4A90E2"
                ></Bar>
                <Bar
                  dataKey="Closed by CCD"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#EC2B2B"
                ></Bar>
                <Bar
                  dataKey="Closed by Claim/Lab/Quality"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#D1D3D5"
                ></Bar>
                <Bar
                  dataKey="Closed by Provider"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#FCE000"
                ></Bar>
                <Bar
                  dataKey="Ineligible / Excluded"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#3DCA37"
                ></Bar>
                <Bar
                  dataKey="All Closed"
                  stackId="provider"
                  minPointSize={2}
                  barSize={62}
                  fill="#EE7600"
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
};

export default BarGraph;
