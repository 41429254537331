import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, ProgressBar, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useCMWorklistContext } from '../../../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context';
import { useManageVisitContext } from '../../../context/ManageVisitContext/ManageVisit.context';
import axios from '../../../../../../utils/apiClient';
import ToastMessage from '../../../../../../components/Toast';
import { RedAsterisk, formatDateModern } from '../../../../../../utils/util';
import PatientInformationSummary
    from "../../../../../caremgmt/worklist/components/CMWorklistModals/modalsComponents/PatientInformationSummary";
import { useDispatch } from 'react-redux';
import { setReloadWorklist } from '../../../../../../actions/manageVisitActions';

const componentName = 'AssignPatientToNewCareProgram';

const assignCareProgramTitle = "Reassign Care Step";
const teamCapacityTitle = "Available Team Members";

const submitLabel = "Submit";
const assignLabel = "Assign"

function ReassignCareStep({step, handleCancel, handleChangeCarePath = () => {}}) {
    const {
        teamsCapacity,
        getTeamsCapacity,
        getPatientsList,
        patient,
        sendEvent
    } = useCMWorklistContext();
    const manageVisitContext = useManageVisitContext();
    const dispatch = useDispatch();

    const [note, setNote] = useState('');
    const [currentOpenPrograms, setCurrentOpenPrograms] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState();
    const [selectedTeamMember, setSelectedTeamMember] = useState();
    const [selectedTeamId, setSelectedTeamId] = useState("");
    const [modalTitle, setModalTitle] = useState(assignCareProgramTitle);
    const [isPatientInfoStep, setIsPatientInfoStep] = useState(true);
    const [isTeamCapacityStep, setIsTeamCapacityStep] = useState(false);
    const [isSelectNoteStep, setIsSelectNoteStep] = useState(false);
    const [submitButtonLabel, setSubmitButtonLabel] = useState(submitLabel);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

    useEffect(() => {
        getTeamsCapacity();
    }, [])


    const closeModal = () => {

        setCurrentOpenPrograms([]);
        setModalTitle(null);
        setIsPatientInfoStep(true);
        setIsTeamCapacityStep(false);
        setSelectedTeamId("");
        setIsSubmitButtonDisabled(true);
        setIsSelectNoteStep(false);
    }

    const cancel = () => {
        if (isPatientInfoStep) {
            closeModal();
        }
        if (isTeamCapacityStep) {
            setIsTeamCapacityStep(false);
            setIsPatientInfoStep(true);
            setModalTitle(assignCareProgramTitle);
            setSelectedTeam(null);
            setSelectedTeamId("");
            setSubmitButtonLabel(submitLabel);
            setIsSubmitButtonDisabled(true);
        }
        if (isSelectNoteStep) {
            setIsPatientInfoStep(false);
            setIsTeamCapacityStep(true);
            setIsSelectNoteStep(false);
            setModalTitle(teamCapacityTitle);
            setSubmitButtonLabel(assignLabel);
            setIsSubmitButtonDisabled(false);
        }
        handleCancel()
    }

    const selectTeam = (event) => {
        setModalTitle(teamCapacityTitle);
        const teamId = event.target.value;
        const team = teamsCapacity.filter(team => team.teamId === teamId)?.shift();
        setSelectedTeam(team);

        setSelectedTeamId(teamId)
        setIsPatientInfoStep(false);
        setIsTeamCapacityStep(true);
        setSubmitButtonLabel(assignLabel);
        setSelectedTeamMember(null);
    }

    const calculateCapacity = (programLead) => {
        let capacity = programLead.totalDailyCapacity - programLead.dailyAssignment;
        if (capacity < 0) {
            capacity = 0;
        }
        return capacity;
    }

    const getProgressBarVariant = (programLead) => {
        const totalDailyCapacity = parseInt(programLead.totalDailyCapacity);
        const dailyAssignment = parseInt(programLead.dailyAssignment);
        const halfCapacity = totalDailyCapacity / 2;
        let variant = "success";
        if (dailyAssignment > halfCapacity) {
            variant = "warning";
        }
        if (dailyAssignment >= totalDailyCapacity) {
            variant = "danger"
        }
        return variant;
    }

    const submit = () => {
        if (isTeamCapacityStep) {
            assignTeamMember();
        }
        else{
            sendEvent('CONFIRM_REASSIGN_CARE_STEP');
            const assignPayload = {
                "text": note,
                "action": "ASSIGN_STEP_NEW",
                "programLeadId": selectedTeamMember?.programLeadId,
              }
           reassignCareStep(assignPayload)
        }
    }

    const reassignCareStep =(payload)=>{
        axios
          .put(
            process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
              step.carePathInstanceId
            )}&carePathStepId=${encodeURIComponent(step.id)}`,
            payload,
            { headers: {} }
          )
          .then((res) => {
            handleChangeCarePath()
            dispatch(setReloadWorklist(true))
            if (res.data.fail && res.data.type === "FAIL") {
                manageVisitContext.persistErrorMessage("Failed to Reassign Care Step")
            } else {
              handleCancel(true);
            }
          })
          .catch((err) => {
            if (manageVisitContext?.persistErrorMessage()) {
                manageVisitContext.persistErrorMessage(err?.response?.data?.message);
              }
            });
    }
    const assignTeamMember = () => {
        sendEvent('ASSIGN_TEAM_MEMBER');
        setIsSelectNoteStep(true);
        setIsTeamCapacityStep(false);
        setIsPatientInfoStep(false);
        setModalTitle(assignCareProgramTitle);
        setSubmitButtonLabel(submitLabel);
    }

    const reassignTeamMember = () => {
        sendEvent('CLICK_REASSIGN');
        setIsSelectNoteStep(false);
        setIsTeamCapacityStep(true);
        setIsSubmitButtonDisabled(false);
        setSubmitButtonLabel(assignLabel);
    }

    const selectTeamMember = (event) => {
        sendEvent('SELECT_TEAM_MEMBER');
        const teamMemberId = event.target.value;
        const teamMember = selectedTeam?.programLeads?.filter(programLead => programLead.programLeadId === teamMemberId)?.shift();
        setSelectedTeamMember(teamMember);
        setIsSubmitButtonDisabled(false);
    }

    return (
        <>
        <Modal.Header>
        <Modal.Title id={`${componentName}-patientAssignPatientToNewCareProgramConfirmTitle`}>{modalTitle}</Modal.Title>
        <button
          id={`${componentName}-patientAssignPatientToNewCareProgramConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => cancel()}
        />
      </Modal.Header>
            <Modal.Body>
                {(isPatientInfoStep || isSelectNoteStep) &&
                    <Row className="mb-4 bg-light p-3 rounded-3">
                        <Col>
                            <PatientInformationSummary rowLayout={2}
                                                       fullName={manageVisitContext?.patient_object?.fullName || patient.fullName}
                                                       memberId={manageVisitContext?.patient_object?.memberId || patient.memberId}
                                                       dateOfBirth={manageVisitContext?.patient_object?.dateOfBirth || patient.dateOfBirth}
                                                       className='mb-3'/>
                            <Row>
                                <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
                                    <Form.Label id={`${componentName}-currentLabel`}>
                                        Current Program Step
                                    </Form.Label>
                                    <div id={`${componentName}-carePath-1`} className="d-flex flex-column">
                                        <span id={`${componentName}-careStepName-2`}>{step.title}</span>
                                    </div>
                                </Form.Group>
                                <div id={`${componentName}-carePath-1`} className="d-block">
                                 {step?.dueDate && <>Due: <span id={`${componentName}-careStepName-2`}>{formatDateModern(step?.dueDate)}</span></> }
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }
                <Row className="mb-4">
                    <Col>
                        <Row>
                            <Form.Group id={`${componentName}-team`}>
                                <Form.Label id={`${componentName}-teamLabel`}>
                                    Team<RedAsterisk/>
                                </Form.Label>
                                <select id={`${componentName}-teamOptions`} className="form-select"
                                    onChange={event => selectTeam(event)} value={selectedTeamId}>
                                    <option id="teamOption-select" value="">Select</option>
                                    {teamsCapacity.map((data, i) => (
                                        <option
                                            key={`capacity-${data.teamId}-${i}`}
                                            id={`teamOption${i}`}
                                            value={data.teamId}
                                        >
                                            {data.teamName}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
                {isTeamCapacityStep && selectedTeam?.programLeads?.length > 0 &&
                    <Row>
                        <Col>
                            <Row className="fw-bold">
                                <Col>
                                    <span>Team Member</span><RedAsterisk/>
                                </Col>
                                <Col className="text-end">
                                    <span>Capacity</span>
                                </Col>
                            </Row>
                            <Row>
                                <ToggleButtonGroup
                                    className="careTeamCapacity d-flex flex-column"
                                    name="careTeamCapacity"
                                    value={selectedTeamMember?.programLeadId}
                                >
                                    {selectedTeam.programLeads.map((programLead, index) => (
                                        <ToggleButton
                                            type="radio"
                                            id={`tbg-radio-${index}-${selectedTeamId}`}
                                            variant="team-member"
                                            className={`border p-3 text-dark rounded-3 mb-2 ${selectedTeamMember?.programLeadId == programLead?.programLeadId ? 'active' : ''}`}
                                            value={programLead.programLeadId}
                                            onChange={(event) => {selectTeamMember(event)}}
                                            disabled={calculateCapacity(programLead) === 0}
                                            key={`${programLead?.programLeadId}-${index}-${selectedTeamId}`}
                                        >
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column text-start w-50">
                                                    <span className="text-dark">{programLead.programLeadName}</span>
                                                    {(selectedTeam.teamName === 'Community Health Workers' && programLead.zone) ? (
                                                        <small
                                                            className="text-muted text-truncate">{programLead.zone}</small>
                                                    ) : (
                                                        <small className="text-muted">{programLead.market}</small>
                                                    )
                                                    }
                                                </div>
                                                <div className="d-flex flex-column text-end w-50">
                                                    <ProgressBar variant={getProgressBarVariant(programLead)} min={0} max={programLead.totalDailyCapacity} now={programLead.dailyAssignment} className="w-100" />
                                                    <small className="text-muted">{`${calculateCapacity(programLead)} of ${programLead.totalDailyCapacity} available`}</small>
                                                </div>
                                            </div>
                                        </ToggleButton>
                                    ))
                                    }
                                </ToggleButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    isSelectNoteStep && selectedTeamMember && (
                        <Row className="mb-4">
                            <Col>
                                <Row>
                                    <Form.Group>
                                        <Form.Label id={`${componentName}-assignedTeamMemberLabel`}>
                                            Assigned Team Member
                                        </Form.Label>
                                        <div className="d-flex justify-content-between align-items-center rounded-3 border p-3">
                                            <div className="d-flex flex-column">
                                                <span className="fw-bold">{selectedTeamMember.programLeadName}</span>
                                                <span>{selectedTeam.teamName}</span>
                                                <span>{selectedTeamMember.zone}</span>
                                            </div>
                                            <div>
                                                <Button variant="link" className="px-3 text-decoration-none" onClick={() => reassignTeamMember()}>
                                                    Reassign
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
                {isSelectNoteStep &&
                    <>
                        <Row>
                            <Col>
                                <Row>
                                    <Form.Group>
                                        <Form.Label id={`${componentName}-notesLabel`}>
                                            Notes
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            max="500"
                                            onChange={e => setNote(e.target.value)}
                                            id={`${componentName}-notesValue`}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                        <ToastMessage/>
                    </>   
                }
            </Modal.Body>
            <Modal.Footer>
                <Button id={`${componentName}-patientAssignPatientToNewCareProgramNoBtn`} variant="secondary"
                    onClick={() => cancel()}>
                    Cancel
                </Button>
                <Button
                    id={`${componentName}-submit`}
                    variant="primary"
                    disabled={isSubmitButtonDisabled}
                    aria-disabled={isSubmitButtonDisabled}
                    onClick={() => submit()}>
                    {submitButtonLabel}
                </Button>

            </Modal.Footer>
        </>
    )
}

export default ReassignCareStep;