import React, {useState} from "react";
import {
  useCMWorklistContext
} from "./CMWorklistModals/context/CMWorklist.context";
import DropDownMenu from "./DropDownMenu";
import {
  CHWItems,
  chaplainItems,
  clinicalCoordinatorItems,
  defaultItems,
  hospitalLiaisonsItems
} from "../constants";
import { useDispatch } from "react-redux";
import { setManageVisitOpenedFromPageTitle, setManageVisitPatient } from "../../../../actions/manageVisitActions";
import ManageVisitlModal from "../../../patientProfile/modals/ManageVisitlModal";
import {
  useGetPatientCard,
  useGetPatientProfileCareOpps,
  useGetPatientProfileSuspects
} from "../../../../actions/patientProfileActions";
import {careOppsReducer} from "../../../../reducers/patientProfile/careOppsReducer";
import {suspectsReducer} from "../../../../reducers/patientProfile/suspectsReducer";
import {patientReducer} from "../../../../reducers/patientProfile/patientReducer";

const PatientCardActions = ({ idx, patient }) => {
  const {
    selectPatient,
    toggleAssignPatientTo,
    toggleUpdateReferralStatus,
    toggleRemoveFromWorklist,
    toggleNewAssessmentTemplateSelector,
    toggleReassignCareStep,
    toggleMarkAsOutreached,
    setPatientsEventsToBeMarkedAsOutreached,
    toggleWorkOnCareStep,
    activeRole,
    sendEvent
  } = useCMWorklistContext();

  const { role } = activeRole;
  const dispatch = useDispatch();

  // Init temporary solution VEGA-11794
  const navigationItems = {
    id: null,
    type: null,
  };
  const activeKey = "0";
  const setChangesMade = () => {};
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const careOppsPkg = useGetPatientProfileCareOpps({}, careOppsReducer);
  const suspectPkg = useGetPatientProfileSuspects({}, suspectsReducer);
  const patientCardPkg = useGetPatientCard({}, patientReducer);

  const closeManageVisitModal = () => {
    setShowModal(false);
  }

  const init = () => {
    careOppsPkg.fetchData(patient.id);
    suspectPkg.fetchData(patient.id);
    patientCardPkg.fetchData(patient.id);
    dispatch(setManageVisitPatient(patient));
  };
  // Finish temporary solution VEGA-11794

  const onPatientActionClicked = (menuItemClicked) => {
    // eslint-disable-next-line default-case
    switch (menuItemClicked) {
      case "Assign Patient to...":
      case "Assign New Care Program":
        toggleAssignPatientTo({show: true, source: "worklist"});
        sendEvent('SELECT_PATIENT');
        break;
      case "Update Referral Status":
        sendEvent('SELECT_UPDATE_REFERRAL');
        toggleUpdateReferralStatus(true);
        break;
      case "Update Gap Status":
        sendEvent('SELECT_UPDATE_GAP');
        setShowModal(true);
        break;
      case "Remove from Worklist":
        sendEvent('SELECT_REMOVE_WORKLIST');
        toggleRemoveFromWorklist(true);
        break;
      case "Complete Assessment":
      case "Perform Assessment":
        sendEvent('SELECT_COMPLETE_ASSESSMENT');
        toggleNewAssessmentTemplateSelector(true);
        break;
      case "Reassign Care Step":
        sendEvent('SELECT_REASSIGN_CARE_STEP');
        toggleReassignCareStep(true);
        break;
      case "Mark as Outreached":
        sendEvent('SELECT_MARK_OUTREACHED');
        toggleMarkAsOutreached(true);
        setPatientsEventsToBeMarkedAsOutreached([{
          patientId: patient?.id,
          referralId: patient.referralDtoList[0]?.id,
          adtId: patient?.adtId
        }]);
        break;
      case "Work on Care Step":
        toggleWorkOnCareStep(true);
        break;
    }
  }

  const getItems = () => {
    let items;
    switch (role) {
      case 'Chaplains':
      case 'Care Specialists':
        items = chaplainItems(patient)
        break;
      case 'Community Health Workers':
        items = CHWItems(patient?.carePathStepDto?.carePathInstanceId, patient)
        break;
      case 'Clinical Coordinators':
        items = clinicalCoordinatorItems(patient)
        break;
      case 'Hospital Liaisons':
        items = hospitalLiaisonsItems(patient)
        break;
      default:
        items = defaultItems(patient)
        break;
    }
    return items;
  }

  const onItemClick = (menuItemClicked) => {
    selectPatient(patient);
    onPatientActionClicked(menuItemClicked.value);
  }

  return (
      <>
    <DropDownMenu
      idx={idx}
      items={getItems().filter(i => i.condition())}
      onItemClick={(item) => {
        onItemClick(item);
      }}
    />
      <ManageVisitlModal
        show={showModal}
        patient={patient}
        close={closeManageVisitModal}
        navigationItems={navigationItems}
        activeKey={activeKey}
        setChangesMade={setChangesMade}
        confirmationMessage={confirmationMessage}
        setConfirmationMessage={setConfirmationMessage}
        init={init}
      />
    </>
  )
}

export default PatientCardActions;