import React, { useEffect, useState, useContext } from "react";
import { Card } from "../../components/cards";
import { theme } from "../../components/themes/theme1";
import { ThemeProvider } from "styled-components";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
  Row,
  Container,
  Col,
  Badge,
  Button,
  ButtonGroup,
  Collapse,
  Modal,
  Dropdown,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { setHours, setMinutes } from "date-fns";
import { useAuth } from "../../context/authContext";
import {
  createTask,
  FILTER_ALL,
  getReasonsByAction,
  getTasks,
  getTaskTypes, updateTask, getTaskFilters,
  getTaskActions, handleError, putResolveTask,
  getAllAppointments, createAppointment, DUE_TODAY,
  updateAppointment, deleteAppointment, getAllProgramLeads
} from "../../actions/caremgmt/myWorkspaceActions";
import EQHErrorToast from "../../components/EQHErrorToast";
import CustomPagination from "../common/CustomPaginaton";
import { EQHSpinner } from "../../components/EQHSpinner";
import {
  _calculateAge,
  formatDateWithHourMinutes,
  getRiskColor,
  formatDateVanilla,
  formatDate,
  convertMemberToManageViewPatient,
} from "../../utils/util";
import { searchUsers } from "../../actions/caremgmt/careAdminAction";
import { getMembers, getNoteTypes, saveNote } from "../../actions/myMembersActions";
import { fetchAllNotifications, fetchNotifications, readNotification } from "../../actions/caremgmt/notificationsActions";
import ResolveTaskModal from "./ResolveTaskModal";
import { SortableTh } from "../../components/SortableTh";
import { format } from "date-fns";
import ConfirmationModal from "./ConfirmationModal";
import CalendarView, { CM_WORKSPACE_MODULE } from "../quickList/CalendarView";
import PatientSearchAsync from "../../components/PatientSearchAsync";
import styled from "styled-components";
import { DropdownComponent } from "../../components/DropdownComponent";
import { durationList } from "../../utils/textValueLists";
import DatePicker from "react-datepicker";

import { fetchDropDownFiltered } from "./careAdmin/DropDownLists/actions";
import { FilterModal } from "../../components/fitersModal";
import { formatRangeDate } from "../../components/fitersModal/utils";
import AddMemberModal from "./careTeam/AddMemberModal/components/AddMemberModal";
import CalendarDaysView from "../quickList/CalendarDaysView";
import { SubmitReferralModal } from "./careTeam/SubmitReferralModal/components";
import UpdateMember from "./careTeam/UpdateMember/UpdateMember";
import { InterventionModal } from "./careTeam/InterventionModal/interventionModal";
import { FollowUpModal } from "./careTeam/FollowUpModal/FollowUpModal";
import { ReassignMemberModal } from "./careTeam/ReassignMemberModal";
import { Helmet } from 'react-helmet';
import TaskModal from "./careTeam/TaskModal/TaskModal";
import SuccessToast from "../common/SuccessToast";
import { setManageVisitOpenedFromPageTitle, setManageVisitPatient } from "../../actions/manageVisitActions";
import { getPatientProfileOverview } from "../../actions/patientProfileActions";
import { useDispatch } from "react-redux";

const componentName = "Workspace";
const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const MONTHLY_VIEW = "MONTHLY"
export const WEEKLY_VIEW = "WEEKLY"
const DAILY_VIEW = "DAILY"
const popperModifiers = {
  offset: {
    enabled: true,
    offset: "0px, 0px"
  },
  preventOverflow: {
    enabled: true,
    escapeWithReference: false,
    boundariesElement: "scrollParent"
  }
}

export const MIN_HOUR = 7;
export const MIN_MINUTES = 0;
export const MAX_HOUR = 19;
export const MAX_MINUTES = 0;

export const CareTeam = () => {

  const [openIdx, setOpenIdx] = useState(null);
  const [openIdxMymembers, setOpenIdxMymembers] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false)
  const handleCloseAddTask = () => setShowAddTask(false);
  const handleShowAddTask = () => setShowAddTask(true);
  const [showResolveTaskModal, setShowResolveTaskModal] = useState(false)
  const [errors, setErrors] = useState([]);
  const isEmpty = require("is-empty");
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 5, totalSize: 5 });
  const [filter, setFilter] = useState(DUE_TODAY);
  const [filterOptions, setFilterOptions] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [dateObject, setDateObject] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoadingTasks, setLoadingTasks] = useState(false);
  const [taskTypes, setTaskTypes] = useState([]);
  const [task, setTask] = useState({});
  const [isMemberLoading, setMemberLoading] = useState(false);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [isAssignedLoading, setAssignedLoading] = useState(false);
  const [taskActions, setTaskActions] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const [taskSelected, setTaskSelected] = useState(null);
  const [sorting, setSorting] = useState({});
  const [sortingMembers, setSortingMembers] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  const [currentDateForAppointment, setCurrentDateForAppointment] = useState("");
  const [notifications, setNotfications] = useState({});
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [toolTipNotificationInfo, setTooltipNotificationInfo] = useState({ today: 0, overDue: 0 });
  const [membersData, setMembersData] = useState([]);
  const [membersPagination, setMembersPagination] = useState({ currentPage: 1, count: 0 });
  const [programStatusTypes, setProgramStatusTypes] = useState([]);
  const [interactionTypes, setInteractionTypes] = useState([{ value: '', text: '' }]);
  const [interactionOutcomes, setInteractionOutcomes] = useState([{ value: '', text: '' }]);
  const [appointmentTypes, setAppointmentTypes] = useState([{ value: '', text: '' }]);
  const [noteData, setNoteData] = useState({ text: '' });
  const [memberSelected, setMemberSelected] = useState();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [taskFilters, setTaskFilters] = useState([]);
  const [filtersCount, setFiltersCount] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [calendarViewType, setCalendarViewType] = useState(MONTHLY_VIEW);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showSubmitReferral, setShowSubmitReferral] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [defaultMember, setDefaultMember] = useState({});
  const [followUpMemberData, setFollowUpMemberData] = useState({});
  const [success, setSuccess] = useState(null);
  const dispatch = useDispatch();

  const auth = useAuth();

  const handleDateChange = (date) => {
    fetchAppointmentList(date, calendarViewType)
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const setSelectedDateFunc = (selectedDate) => {
    setSelectedDate(selectedDate);
    let newState = formBody;
    if (selectedDate) {
      const date = new Date(selectedDate);
      newState.appointmentDateTime = date;
      setFormBody({ ...newState });
    }
  };

  const fetchAssignedSearch = () => {
    setAssignedLoading(true);
    searchUsers().then((response) => {
      if (response.data) {
        setAssignedOptions(response.data);
      }
      setAssignedLoading(false);
    }).catch((error) => {
      onError(error);
      setAssignedLoading(false);
    });
  }

  const openTaskModal = (taskToEdit) => {
    setTask(taskToEdit);
    handleShowAddTask();
  }

  const handleSavedTaskSuccessfully = () => {
    setSuccess({ message: "Task Saved Successfully" });
    fetchTaskWithFilter();
  }

  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setFilter(newFilter);
    fetchTasks(1, pagination.pageSize, newFilter);
    setTaskFilters([]);
    setFiltersCount(0);
  }

  const setOpen = (idx) => {
    setOpenIdx(openIdx == idx ? null : idx);
  }

  const isOpen = (idx) => {
    return openIdx === idx;
  }

  const sortColl = (newSortParam, newSortDirection) => {
    const lowerCaseSortDirection = newSortDirection.toLowerCase();
    const isFiltered = taskFilters[0]?.paramValue?.length === 2;
    const newFilter = isFiltered ? 'TASKS_BETWEEN_DATES' : filter;
    let filterParams = [];

    setSorting({ sortParameter: newSortParam, sortDirection: lowerCaseSortDirection });
    setFilter(newFilter);

    if (isFiltered) {
      const rangeDate = taskFilters[0]?.paramValue.map(date => formatRangeDate(date));
      filterParams = [rangeDate[0], rangeDate[1]];
    }

    fetchTasks(
      pagination.pageNumber,
      pagination.pageSize,
      newFilter,
      newSortParam,
      lowerCaseSortDirection,
      ...filterParams
    );
  };

  const sortMembersColumns = (newSortParam, newSortDirection) => {
    const lowerCaseSortDirection = newSortDirection.toLowerCase();
    setSortingMembers({ sortParameter: newSortParam, sortDirection: lowerCaseSortDirection });
    fetchMembers(
      pagination.pageNumber,
      pagination.pageSize,
      newSortParam,
      lowerCaseSortDirection,
      ''
    );
  };

  const fetchTasks = (
    pageNumber = 1,
    pageSize = 10,
    filter = FILTER_ALL,
    sortParameter = "",
    sortDirection = "",
    fromDate = '',
    toDate = ''
  ) => {
    setLoadingTasks(true);
    getTasks(pageNumber, pageSize, filter, sortParameter, sortDirection, fromDate, toDate, viewAsPL?.userAccountId).then((resp) => {
      if (resp && resp.data) {
        const { taskList, pageNumber, pageSize, totalSize } = resp.data;
        setTaskList(taskList)
        setPagination({ pageNumber: pageNumber, pageSize: pageSize, totalSize: totalSize });
      }
      setLoadingTasks(false);
    }).catch((error) => {
      onError(error);
      setLoadingTasks(false);
    });
  }

  const fetchAppointmentList = (date = new Date(), calendarViewType = MONTHLY_VIEW) => {
    setLoadingAppointment(true);
    setDateObject(date)
    const dt = formatDateVanilla(date, "yyyy-MM-dd")
    setCurrentDateForAppointment(dt);
    getAllAppointments(dt, calendarViewType, viewAsPL?.userAccountId).then((resp) => {
      if (resp && resp.data) {
        setAppointmentList(resp.data)
      }
      setLoadingAppointment(false);
    }).catch((error) => {
      onError(error);
      setLoadingAppointment(false);
    });
  }
  const fetchTaskType = () => {
    getTaskTypes().then((resp) => {
      if (resp && resp.data) {
        setTaskTypes(resp.data);
      }
    }).catch((error) => {
      onError(error);
    });
  }

  const fetchTaskActions = () => {
    getTaskActions().then((response) => {
      if (response && response.data) {
        setTaskActions(response.data);
      }
    }).catch((error) => {
      onError(error);
    });
  }
  const fetchReasonsByAction = (action) => {
    setReasons([]);
    getReasonsByAction(action.value).then((response) => {
      if (response && response.data) {
        setReasons(response.data);
      }
    }).catch((error) => {
      setShowResolveTaskModal(false)
      onError(error);
    })
  }

  const fetchTaskFilters = () => {
    getTaskFilters().then((resp) => {
      if (resp && resp.data) {
        setFilterOptions(resp.data);
      }
    }).catch((error) => {
      onError(error);
    });
  }

  const paginate = (i) => {
    if (i) {
      if (taskFilters[0]?.paramValue) {
        const rangeDate = taskFilters[0].paramValue.map(date => formatRangeDate(date));
        fetchTasks(i, 10, 'TASKS_BETWEEN_DATES', sorting.sortParameter, sorting.sortDirection, rangeDate[0], rangeDate[1]);
      } else {
        fetchTasks(i, 10, filter, sorting.sortParameter, sorting.sortDirection, taskFilters);
      }
    }
  };

  const paginateMembers = (i) => {
    if (i) {
      fetchMembers(i, 10, sortingMembers.sortParameter, sortingMembers.sortDirection, '');
      setMembersPagination({
        ...membersPagination,
        currentPage: i
      })
    }
  };

  const formatText = (value) => {
    return value?.toLowerCase().split('_').map(capitalize).join(' ');
  }

  const formatTextAlternative = (value) => {
    return value?.toLowerCase().split(', ').map(capitalize).join(', ');
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getPatientUrl = (patientId, tab = 'Overview') => {
    return "/patient-profile?" + new URLSearchParams({
      'patientId': patientId,
      'tab': tab,
      'returnTo': '/caremgmt/workspace/?loadLastQuerySettings=true'
    });
  }

  const onError = (err) => {
    const error = handleError(err)
    setErrors(error == undefined ? [] : [error]);
  }

  const closeResolveTaskModal = () => {
    setShowResolveTaskModal(false)
  }

  const openResolveTaskModal = (action, task) => {
    setShowResolveTaskModal(true)
    setActionSelected(action)
    setTaskSelected(task)
    fetchReasonsByAction(action)
  }

  const resolveTask = (taskActionRequest, error) => {
    if (error) {
      setErrors([error])
      return
    }
    putResolveTask(taskActionRequest).then(response => {
      if (response && response.status === 200) {
        const isFiltered = taskFilters[0]?.paramValue?.length === 2;
        const newFilter = isFiltered ? 'TASKS_BETWEEN_DATES' : filter;
        let filterParams = [];

        if (isFiltered) {
          const rangeDate = taskFilters[0]?.paramValue.map(date => formatRangeDate(date));
          filterParams = ['', '', rangeDate[0], rangeDate[1]];
        }

        fetchTasks(pagination.pageNumber, pagination.pageSize, newFilter, ...filterParams);
      }
    }).catch(error => {
      onError(error)
    }).finally(closeResolveTaskModal)

  }

  const formatCurrentDate = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    setCurrentDate(currentDate)
  }

  const getNotificationsTooltipInfo = () => {
    let dueToday;
    let overDue;
    setIsLoadingNotifications(true);
    new Promise(resolve => setTimeout(resolve, 1000))
      .then(() => {
        fetchNotifications().then(res => setNotfications(res.data));

        fetchAllNotifications().then(res => {
          dueToday = res.data.notifications.filter(n => n.status === 'DUE TODAY').length;
          overDue = res.data.count - dueToday;
          setTooltipNotificationInfo({ today: dueToday, overDue: overDue });
          setIsLoadingNotifications(false);
        })
      });
  }

  const [showEditMember, setShowEditMember] = useState(false);

  const handleEditMemberClose = () => {
    setShowEditMember(false);
    setMemberSelected();
    setMemberLoading(false);
  }

  const handleEditMember = () => {
    if (validateEditMemberFields()) {
      setMemberLoading(true);
      const {
        patientProgramId,
        patientId,
        programLeadId,
        programName,
        userId
      } = memberSelected
      const {
        interactionTypeId,
        interactionOutcomeId,
        programStatusId,
        noteText,
        files,
      } = noteData
      const programId = programName;

      saveNote(
        patientProgramId,
        patientId,
        programLeadId,
        programId,
        programStatusId,
        noteText === undefined ? '' : noteText,
         viewAsPL?.userAccountId || auth?.getUserId(),
        interactionOutcomeId === undefined ? '' : interactionOutcomeId,
        interactionTypeId === undefined ? '' : interactionTypeId,
        files === undefined ? [] : files
      ).then(() => {
        fetchMembers(pagination.pageNumber, pagination.pageSize, sortingMembers.sortParameter, sortingMembers.sortDirection, '')
        setNoteData({});
        handleEditMemberClose();
      }).catch(error => {
        setMemberLoading(false)
        onError(error)
      })
    }
  }

  const handleShowEditMember = (member) => {
    setShowEditMember(true);
    setMemberSelected(member);
    setNoteData({
      programStatusId: member?.programStatusId,
      interactionTypeId: member?.interactionTypeId,
      interactionOutcomeId: member?.interactionOutcomeId
    })
  }

  const validateEditMemberFields = () => {
    if (!noteData.programStatusId) {
      setErrors(["Some mandatory fields are empty"])
      return false
    }
    return true
  }

  const [showNotifications, setShowNotifications] = useState(false);
  const handleNotificationsClose = () => setShowNotifications(false);
  const handleShowNotifications = () => setShowNotifications(true);

  const [showViewAs, setViewAsShow] = useState(false);
  const handleViewAsClose = () => setViewAsShow(false);
  const handleViewAsShow = () => {
    setSelectedPL(null);
    setViewAsShow(true);
  };

  const [showAppointment, setShowAppointment] = useState(false);
  const [isLoadingAppointment, setLoadingAppointment] = useState(false);
  const [patient, setPatient] = useState(null);

  const handleAppointmentClose = () => {
    setShowAppointment(false);
    setSelectedAppointment(null);
    setPatient(null)
  };

  const handleShowAppointment = () => {
    setFormBody({
      patientId: null,
      appointmentName: "",
      appointmentDateTime: "",
      durationInMinutes: "",
      notes: ""
    })
    setPatient(null)
    setSelectedDate(null)
    setShowAppointment(true)
  };
  const updateFromCalendarModal = (appointment) => {
    const appointmentDateTime = new Date(appointment.appointmentDateTime);
    setSelectedAppointment(appointment);
    setFormBody({
      patientId: appointment.patientId,
      appointmentTypeId: appointment.appointmentTypeId,
      appointmentName: appointment.appointmentName,
      appointmentDateTime: appointmentDateTime,
      durationInMinutes: appointment.durationInMinutes,
      notes: appointment.notes
    });
    setSelectedDate(appointmentDateTime);
    setShowAppointment(true)
  }

  const handleDeleteAppointment = () => {
    setLoadingAppointment(true)
    deleteAppointment(selectedAppointment)
      .then(async () => {
        setLoadingAppointment(false)
        handleAppointmentClose()
        fetchAppointmentList(dateObject, calendarViewType)
        fetchTaskWithFilter()
      })
      .catch((error) => {
        setLoadingAppointment(false)
        setErrors([error]);
      });
  }

  const handleUpdateAppointment = () => {
    let newState = { ...selectedAppointment, ...formBody };
    setLoadingAppointment(true)
    updateAppointment(newState)
      .then(async () => {
        setLoadingAppointment(false)
        handleAppointmentClose()
        fetchAppointmentList(dateObject, calendarViewType)
        fetchTaskWithFilter()
      })
      .catch((error) => {
        setLoadingAppointment(false)
        setErrors([error]);
      });
  }

  const [formBody, setFormBody] = useState({
    patientId: "",
    appointmentName: "",
    appointmentDateTime: "",
    durationInMinutes: "",
    notes: ""
  });

  const clearAppointmentPatient = () => {
    setSelectedAppointment({ ...selectedAppointment, patientId: "" });
  }

  const handlePatientChange = (selectedPatient) => {
    let appointmentInfo = formBody
    if (!isEmpty(selectedPatient)) {
      setPatient(selectedPatient)
      appointmentInfo.patientId = selectedPatient[0].id;
    }
    setFormBody({ ...appointmentInfo })
  }

  const handleInputChange = (name, value) => {
    let newState = formBody;
    if (name === "duration") {
      newState.durationInMinutes = value.value;
    }
    if (name === "appointNameValue") {
      newState.appointmentName = value.value;
    }
    if (name === "notes") {
      newState.notes = value.value;
    }
    if (name === "appointmentTypeId") {
      newState.appointmentTypeId = value.value
    }
    setFormBody({ ...newState });
  };

  const onsubmitAppointment = () => {
    let newState = formBody;
    setLoadingAppointment(true)
    createAppointment(newState)
      .then(async () => {
        setLoadingAppointment(false)
        handleAppointmentClose()
        fetchAppointmentList(dateObject, calendarViewType)
        fetchTaskWithFilter()
      })
      .catch((error) => {
        setLoadingAppointment(false)
        setErrors([error]);
      });
  };

  const fetchTaskWithFilter = () => {
    const isFiltered = taskFilters[0]?.paramValue?.length === 2;
    const newFilter = isFiltered ? 'TASKS_BETWEEN_DATES' : filter;
    let filterParams = [];
    if (isFiltered) {
      const rangeDate = taskFilters[0]?.paramValue.map(date => formatRangeDate(date));
      filterParams = ['', '', rangeDate[0], rangeDate[1]];
    }
    fetchTasks(pagination.pageNumber, pagination.pageSize, newFilter, ...filterParams);
    getNotificationsTooltipInfo();
  }

  const enableButton = () => {
    if (formBody.durationInMinutes &&
      formBody.appointmentName &&
      formBody.appointmentDateTime) {
      return true
    }
    else {
      return false
    }
  }
  function PlusMinusToggle({ children, eventKey, callback, idx }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none" onClick={decoratedOnClick}>
        <i id={`${componentName}-plusMinusIcon-${idx}`} className={isCurrentEventKey ? "fa fa-chevron-down me-4" : "fa fa-chevron-right me-4"}></i>{" "}
        <span id={`${componentName}-itemTitle-${idx}`} className="fs-5 fw-bold mb-0 lh-1">{children}</span>
      </Button>
    );
  }

  const getStatusDropDown = () => {
    fetchDropDownFiltered('PROGRAM_STATUS')
      .then(res => setProgramStatusTypes(res.data));
    fetchDropDownFiltered('INTERACTION_TYPES')
      .then(res => setInteractionTypes([...interactionTypes, ...res.data]));
    fetchDropDownFiltered('INTERACTION_OUTCOME')
      .then(res => setInteractionOutcomes([...interactionOutcomes, ...res.data]));
    fetchDropDownFiltered('APPOINTMENT_TYPE')
      .then(res => setAppointmentTypes([...appointmentTypes, ...res.data]));
  }

  const fetchMembers = (pageNumber = 1, pageSize, sortParameter, sortDirection, filter) => {
    getMembers(pageNumber, pageSize, sortParameter, sortDirection, filter, viewAsPL?.userAccountId).then(res => {
      setMembersData(res?.data?.members);
      setMembersPagination({
        ...membersPagination,
        count: res?.data?.count,
        currentPage: pageNumber
      });
    })
  }

  const handleFiltersReset = () => {
    setFiltersCount(0);
    fetchTasks(1, 10, DUE_TODAY);
    setFilter(DUE_TODAY);
    setTaskFilters([]);
  }

  const onChangeCalendarView = (selectedCalendarViewType) => {
    if (calendarViewType !== selectedCalendarViewType) {
      setCalendarViewType(selectedCalendarViewType)
      fetchAppointmentList(dateObject, selectedCalendarViewType)
    }
  }

  const [plLoading, setPLLoading] = useState(false);
  const [plOptions, setPLOptions] = useState([]);
  const [viewAsPL, setViewAsPL] = useState(null);
  const [selectedPL, setSelectedPL] = useState(null);

  const fetchProgramLeads = () => {
    getAllProgramLeads()
      .then((resp) => {
        if (resp && resp.data) {
          let options = resp.data.map(r => {
            return {
              value: r.value,
              text: `${r.text} - ${r.team ? r.team : 'No Team Associated'}`,
              team: `${r.team ? r.team : 'No Team Associated'}`,
              name: r.text
            }
          })
          setPLOptions(options);
        }
        setPLLoading(false);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleViewCurrentUser = () => {
    handleViewAsApply(null);
    setSelectedPL(null);
  };

  const handleViewAsApply = (plOption = selectedPL) => {
    setViewAsPL(plOption);
    handleViewAsClose();
  };

  const handleViewAsChange = (e) => {
    if (e && e[0]) {
      const value = e[0];
      setSelectedPL({
        "name": value.name,
        "userAccountId": value.value
      });
    } else {
      setSelectedPL(null);
    }
  };

  useEffect(() => {
    handleFiltersReset();
    fetchTasks(1, 10, DUE_TODAY);
    fetchMembers();
    fetchAppointmentList();
  }, [viewAsPL]);

  useEffect(() => {
    fetchTaskActions();
    fetchTasks(1, 10, DUE_TODAY);
    fetchTaskType();
    fetchTaskFilters();
    formatCurrentDate();
    fetchAssignedSearch();
    getStatusDropDown();
    getNotificationsTooltipInfo();
    fetchMembers();
    fetchAppointmentList();
    fetchProgramLeads();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Workspace | CareEmpower</title>
      </Helmet>
      {success?.message && <SuccessToast />}
      <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
      <Container fluid className="py-3">
        <Row className="sticky-top mx-0 mb-0 stickyTitle">
          <Col className="px-0">
            <Container fluid className="page-titles rounded-3 border shadow-none">
              <Row className="p-2 rounded-3">
                <Col className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold">
                    {viewAsPL
                      ?  `${viewAsPL?.name}'s workspace - ${new Date().toDateString()}`
                      :  `${new Date().toDateString()}`
                    }
                  </div>
                  <div className="d-inline-block mt-1 float-end">
                    <Button
                      id={`${componentName}-submitReferral`}
                      variant="secondary"
                      onClick={() => setShowSubmitReferral(true)}
                      className="me-2"
                    >Submit Referral</Button>
                    <InterventionModal />
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="auto"
                      overlay={
                        <Tooltip>
                          {`You have ${toolTipNotificationInfo.today} tasks due today. You have ${toolTipNotificationInfo.overDue} overdue tasks.`}
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        id={`${componentName}-notificationsButton`}
                        onClick={() => handleShowNotifications()}
                        className="me-2"
                      >Notifications
                        {
                          isLoadingNotifications
                            ? <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            : <Badge
                              id={`${componentName}-notificationsNumber`}
                              className="bg-danger rounded-circle ms-1">
                              {notifications?.count || 0}
                            </Badge>
                        }
                      </Button>
                    </OverlayTrigger>
                    {isEmpty(viewAsPL) ?
                      <Button id={`${componentName}-viewasButton`} variant="secondary"
                        onClick={handleViewAsShow}>
                        View As
                      </Button> :
                      <Button id={`${componentName}-viewasCurrentButton`} variant="secondary"
                        onClick={handleViewCurrentUser}>
                        View As Current User
                      </Button>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Modal size="lg" centered show={showNotifications} onHide={handleNotificationsClose}>
          <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-notificationsModalTitle`}>Notifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {isLoadingNotifications
                  ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> :
                  notifications?.notifications && notifications?.notifications.map((notification, idx) => {
                    return (
                      <>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="ms-2 me-auto"
                            id={`${componentName}-notificationsTitle-${idx}`}>
                            Your task {notification.taskTitle}
                            <Badge
                              pill
                              id={`${componentName}-notificationsStatus-${idx}`}
                              className={
                                notification.status == "OVERDUE" ?
                                  "bg-danger text-white border" : notification.status == "DUE TODAY" ?
                                    "bg-warning text-dark border" : "bg-light text-dark border"}
                            >{notification.status}</Badge>
                          </div>
                          <Button
                            variant="link"
                            className="me-0 p-0"
                            id={`${componentName}-notificationsDismiss-${idx}`}
                            onClick={() => {
                              readNotification(notification.id);
                              getNotificationsTooltipInfo();
                            }}>
                            <i className="fa fa-times-circle"></i>
                          </Button>
                        </div>
                        <hr />
                      </>
                    )
                  })
                }
                {
                  notifications?.count && notifications?.count > 10 &&
                  <CustomPagination
                    paginateButClicked={page => fetchNotifications(page).then(res => setNotfications(res.data))}
                    MAX_PAGE={Math.ceil(notifications?.count / notifications?.pageSize)}
                    pageNumber={Math.ceil(notifications?.pageNumber)}
                  />
                }
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button id={`${componentName}-notificationsModalClose`} variant="secondary"
              onClick={handleNotificationsClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size="md" centered show={showViewAs} onHide={handleViewAsClose}>
          <Modal.Header closeButton>
            <Modal.Title id={`${componentName}-viewasModalTitle`}>View As</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <label id={`${componentName}-viewasProgramLeadLabel`} className="d-block">Program Lead</label>
                <Typeahead
                  id={`${componentName}-viewasProgramLeadValue`}
                  options={plOptions}
                  labelKey="text"
                  onChange={(e) => handleViewAsChange(e)}
                  placeholder="Search Program Lead by Name"
                  renderMenuItemChildren={props => (
                      <p className="fw-bold">{props.name} <span className="fw-normal">{` - ${props.team}`}</span></p>
                    )
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" id={`${componentName}-viewasModalClose`}
              onClick={handleViewAsClose}>
              Close
            </Button>
            <Button variant="primary" id={`${componentName}-viewasModalSubmit`}
              onClick={() => handleViewAsApply()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <FilterModal
          setFiltersCount={setFiltersCount}
          show={showFilterModal}
          setShow={setShowFilterModal}
          storage={setTaskList}
          filters={taskFilters}
          setFilters={setTaskFilters}
          setError={setErrors}
          setQuickList={setFilter}
          setPagination={setPagination}
          userAccountId={selectedPL?.userAccountId}
        />
          <Row>
            <Col className="mb-3">
              <Accordion defaultActiveKey="0" className="mb-3">
                <Card className="rounded-3 p-2">
                  <Card.Header eventKey="0" className="d-flex align-items-center bg-transparent pt-3 border-0">
                    <PlusMinusToggle eventKey="0">
                      <span id={`${componentName}-taskTitle`}>Tasks</span>
                    </PlusMinusToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between mb-3">
                        <div>
                          <Button id={`${componentName}-addTaskBtn`} variant="primary" className="me-2"
                            onClick={(e) => openTaskModal()}>
                            + Task
                          </Button>
                          <Button id="filterGroup-Filters" type="button" variant="light"
                            className="border me-2"
                            onClick={() => setShowFilterModal(true)}>
                            <i className="fa-solid fa-bars-filter me-2"></i> Filter
                            <Badge pill bg="primary" className="ms-2 rounded-circle">{filtersCount}</Badge>
                          </Button>
                          <Button id="filterGroup-ResetFilters" type="button" variant="secondary"
                            onClick={() => handleFiltersReset()}>
                            <i className="fas fa-redo-alt me-2"></i> Reset
                          </Button>
                        </div>
                        <div>
                          <select
                            id={`${componentName}-taskDueDate`}
                            className="form-select"
                            value={filter}
                            onChange={(e) => handleFilterChange(e)}
                          >
                            {filterOptions && filterOptions.map((f, i) => {
                              return (<option id={`taskDueDate-${i}`}
                                value={f.value}>{f.text}</option>);
                            })}
                          </select>
                        </div>
                      </Card.Title>
                      <div className="table-responsive text-nowrap overflow-auto">
                        <table className="table table-bordered table-hover">
                          <thead className="table-light fixedHead">
                            <tr>
                              <th scope="col-1"
                                id={`${componentName}-tableHeader-buttons`}></th>
                              <SortableTh sortColumn={sortColl} fieldName="TASK_TYPE"
                                columnName="Type"
                                id={`${componentName}-tableHeader-type`} />
                              <th scope="col-1"
                                id={`${componentName}-tableHeader-status`}>Status
                              </th>
                              <SortableTh sortColumn={sortColl} fieldName="TASK_TITLE"
                                columnName="Description"
                                id={`${componentName}-tableHeader-description`} />
                              <SortableTh sortColumn={sortColl} fieldName="NAME"
                                columnName="Name"
                                id={`${componentName}-tableHeader-name`} />
                              <SortableTh sortColumn={sortColl} fieldName="DUE_DATE"
                                columnName="Due Date"
                                id={`${componentName}-tableHeader-dueDate`} />
                              <th scope="col"
                                id={`${componentName}-tableHeader-actions`}></th>
                            </tr>
                          </thead>
                          {isLoadingTasks && <EQHSpinner />}
                          {!isLoadingTasks &&
                            <tbody>
                              {taskList.map((task, idx) =>
                                [<tr>
                                  <th scope="row">
                                    <Button id={`${componentName}-taskEditBtn-${idx}`}
                                      variant="link"
                                      onClick={() => openTaskModal(task)}
                                      className="p-0 ms-e">
                                      <i className="fa fa-edit"></i>
                                    </Button>
                                    <Button id={`${componentName}-additionMemberNotes`}
                                      variant="link" onClick={() => setOpen(idx)}
                                      aria-controls="memberdetails-0"
                                      aria-expanded={openIdx == idx}>
                                      <i className="fal fa-plus-circle"></i>
                                    </Button>
                                  </th>
                                  <td>
                                    {formatText(task.taskType)}
                                  </td>
                                  <td>
                                    {task.taskStatus ?
                                      <Badge pill
                                        className={task.taskStatus == "OVERDUE" ?
                                          "bg-danger text-white border" : task.taskStatus == "DUE" ?
                                            "bg-warning text-dark border" : "bg-light text-dark border"}>{task.taskStatus}</Badge> : ''}
                                  </td>
                                  <td>{formatText(task.taskDescription)}</td>
                                  <td>
                                    <a
                                      data-bs-toggle={"offcanvas"}
                                      data-bs-target={"#patientDetailsModal"}
                                      aria-controls="patientDetailsModal"
                                      className="fw-bold patientName align-middle me-3"
                                      onClick={async () => {
                                        const { data: patient } = await getPatientProfileOverview([task.patientId]);
                                        dispatch(setManageVisitPatient(patient));
                                        dispatch(setManageVisitOpenedFromPageTitle(componentName))
                                      }}
                                    >
                                      <div
                                        id={`${componentName}-patientFullName-${idx}`}
                                        className="forced-capital">
                                        {task.patientFullName}
                                      </div>
                                    </a>
                                  </td>
                                  <td>{formatDateWithHourMinutes(task.dueDate)}</td>
                                  <td>
                                    <OverlayTrigger placement="top"
                                      trigger={['hover', 'focus']}
                                      overlay={(props) => (
                                        <Tooltip
                                          id="resolveTooltip" {...props}>
                                          Resolve
                                        </Tooltip>
                                      )}>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="outline-secondary"
                                          id={`${componentName}-dropDownButton-${idx}`}
                                          size="sm"
                                          className="d-inline-block bg-white align-top">
                                          <i className="fa fa-clipboard-check"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {taskActions && (taskActions.map(action =>
                                            <Dropdown.Item
                                              id={`${componentName}-dropDownItem-${idx}`}
                                              onClick={() => openResolveTaskModal(action, task)}>{action.text}
                                            </Dropdown.Item>)
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </OverlayTrigger>
                                  </td>
                                </tr>,
                                <tr>
                                  <td colSpan="8" className="p-0 border-0">
                                    <Collapse in={isOpen(idx)}>
                                      <div id={"memberdetails-" + idx}
                                        className="p-3">
                                        {task.notes}
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>]
                              )}
                            </tbody>
                          }
                        </table>
                      </div>
                      <Row>
                        <Col className="mb-3">
                          {!isEmpty(taskList) && Math.ceil(pagination.totalSize / pagination.pageSize) > 0 && (
                            <CustomPagination
                              paginateButClicked={paginate}
                              MAX_PAGE={Math.ceil(pagination.totalSize / pagination.pageSize)}
                              pageNumber={Math.ceil(pagination.pageNumber)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
          <TaskModal
            taskToEdit={task}
            setShowAddTask={setShowAddTask}
            showAddTask={showAddTask}
            currentUser={viewAsPL}
            savedSuccessfully={handleSavedTaskSuccessfully}
            defaultMember={defaultMember}
          />
          <Row>
            <Col className="mb-3">
              <Accordion defaultActiveKey="1" className="mb-3">
                <Card className="rounded-3 p-2">
                  <Card.Header eventKey="1" className="d-flex align-items-center bg-transparent pt-3 border-0">
                    <PlusMinusToggle eventKey="1">
                      <span id={`${componentName}-myMembersTitle`}>My Members</span>
                    </PlusMinusToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <Card.Title className="d-flex align-items-center mb-3">
                        <Button id={`${componentName}-addMemberBtn`} variant="primary" className="me-2"
                          onClick={() => setShowAddMemberModal(true)}>+ Member</Button>
                        {membersPagination && (<Badge bg="light" text="dark" pill className="border">{membersPagination?.count} Members Listed</Badge>)}
                      </Card.Title>
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead className="table-light fixedHead">
                            <tr>
                              <th scope="col-1" id={`${componentName}-tableHeader-buttons`}></th>
                              <SortableTh sortColumn={sortMembersColumns} fieldName="FULL_NAME" columnName="Name" id={`${componentName}-name`} />
                              <th scope="col" id={`${componentName}-tableHeader-status`}>DOB</th>
                              <th scope="col" id={`${componentName}-tableHeader-status`}>Member ID</th>
                              <SortableTh sortColumn={sortMembersColumns} fieldName="PROGRAM_NAME" columnName="Program" id={`${componentName}-program`} />
                              <SortableTh sortColumn={sortMembersColumns} fieldName="PROGRAM_STATUS" columnName="Status" id={`${componentName}-status`} />
                              <SortableTh sortColumn={sortMembersColumns} fieldName="READMIT" columnName="Readmit" id={`${componentName}-tableHeader-readmit`} />
                              <SortableTh sortColumn={sortMembersColumns} fieldName="ASSIGNMENT_DATE" columnName="Days Assigned" id={`${componentName}-status`} />
                              <SortableTh sortColumn={sortMembersColumns} fieldName="ENROLLMENT_DATE" columnName="Days Enrolled" id={`${componentName}-status`} />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              membersData.map((member, idx) => {
                                return (
                                  <>
                                    <tr className={member.reAdmission && 'table-active'}>
                                      <th scope="row">
                                        <Button
                                          id={`${componentName}-myMembersEditBtn`}
                                          onClick={() => handleShowEditMember(member)}
                                          variant="link"
                                          className="p-0"
                                        ><i className="fa fa-edit"></i>
                                        </Button>
                                        <Button
                                          id={`${componentName}-myMembersAdditionalNotes`}
                                          variant="link"
                                          onClick={() => setOpenIdxMymembers(openIdxMymembers === idx ? null : idx)}
                                          aria-controls="memberMoreDetails-0" aria-expanded={openIdxMymembers == idx}
                                        ><i className={openIdxMymembers === idx ? "fal fa-minus-circle" : "fal fa-plus-circle"}></i>
                                        </Button>
                                      </th>
                                      <td><a
                                        data-bs-toggle={"offcanvas"}
                                        data-bs-target={"#patientDetailsModal"}
                                        aria-controls="patientDetailsModal"
                                        className="fw-bold patientName align-middle me-3"
                                        onClick={() => {
                                          dispatch(setManageVisitPatient(convertMemberToManageViewPatient(member)));
                                          dispatch(setManageVisitOpenedFromPageTitle(componentName))
                                        }}
                                      >{formatTextAlternative(member.fullName)}</a></td>
                                      <td>{formatDate(member.dob)}<span className="badge rounded-pill bg-light text-dark border align-top ms-1">{_calculateAge(member.dob)}</span>
                                        <span data-toggle="tooltip" data-placement="top" title={member.gender} className="badge rounded-pill bg-secondary border align-top ms-1">{member.gender?.charAt(0)}</span></td>
                                      <td>{member.memberId}</td>
                                      <td>{member.programName}</td>
                                      <td>{member.programStatus}</td>
                                      <td className="align-middle text-center">{member.reAdmission ? 'Y' : ''}</td>
                                      <td>{member.daysAssigned}</td>
                                      <td>{member.daysEnrolled}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan="6" className="border-0 p-0">
                                        <Collapse in={openIdxMymembers === idx}>
                                          <div id="myMemberMoreDetails-0" className="p-3">
                                            <Row>
                                              <Col id={`${componentName}-myMembersRiskLevel`} className="mb-3">
                                                <label id={`${componentName}-myMembersRiskLevelLabel`} className="d-block">Risk Level</label>
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  popperConfig={{
                                                    modifiers: {
                                                      preventOverflow: { enabled: false },
                                                    },
                                                  }}
                                                  overlay={<Tooltip id="tooltip">{getRiskColor(member.riskLevel).value}</Tooltip>}
                                                >
                                                  <Badge pill style={{
                                                    ...{ "background-color": getRiskColor(member.riskLevel).color },
                                                  }}>{getRiskColor(member.riskLevel).value}</Badge>
                                                </OverlayTrigger>
                                              </Col>
                                              <Col id={`${componentName}-myMembersPayer`} className="mb-3">
                                                <label id={`${componentName}-myMembersPayerLabel`} className="d-block">Payer</label>
                                                <span id={`${componentName}-myMembersPayerValue`}>{member.payer1}</span>
                                              </Col>
                                              <Col className="mb-3" />
                                              <Col className="mb-2" />
                                            </Row>
                                            <Row>
                                              <Col id={`${componentName}-myMembersLOB`} className="mb-3">
                                                <label id={`${componentName}-myMembersLOBLabel`} className="d-block">Line of Business</label>
                                                <span id={`${componentName}-myMembersLOBValue`}>{member.lineOfBusiness}</span>
                                              </Col>
                                              <Col id={`${componentName}-myMembersCity`} className="mb-3">
                                                <label id={`${componentName}-myMembersCityLabel`} className="d-block">City</label>
                                                <span id={`${componentName}-myMembersCityValue`}>{member.city}</span>
                                              </Col>
                                              <Col id={`${componentName}-myMembersFollowUp`} className="mb-2">
                                                <Button id={`${componentName}-myMembers-FollowUpButton`}
                                                  variant="secondary" onClick={() => {
                                                    setFollowUpMemberData(member);
                                                    setShowFollowUpModal(true);
                                                  }}>
                                                  Member Follow Up
                                                </Button>
                                              </Col>
                                              <Col id={`${componentName}-myMembersLinks`} className="mb-3">
                                                <a href={`https://www.careactivator.com/members?q=${member.memberId}`} target="_blank">
                                                  <Button id={`${componentName}-myMembersActivator`} variant="link">
                                                    View in Activator
                                                  </Button>
                                                </a>
                                                <a href={getPatientUrl(member.patientId, 'Notes')} target="_blank">
                                                  <Button id={`${componentName}-myMembersProfileNotes`} variant="link">
                                                    View Notes
                                                  </Button>
                                                </a>
                                                <Button
                                                  id={`${componentName}-myMembersReassign`}
                                                  variant="link"
                                                  onClick={() => {
                                                    setShowReassignModal(true);
                                                    setMemberSelected(member);
                                                  }}>
                                                  Reassign
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Collapse>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <Row>
                        <Col className="mb-3">
                          {!isEmpty(membersData) && (
                            <CustomPagination
                              paginateButClicked={paginateMembers}
                              MAX_PAGE={Math.ceil(membersPagination?.count / 10)}
                              pageNumber={Math.ceil(membersPagination?.currentPage)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
          {memberSelected &&
            <UpdateMember
              showEditMember={showEditMember}
              handleEditMember={handleEditMember}
              handleEditMemberClose={handleEditMemberClose}
              setNoteData={setNoteData}
              noteData={noteData}
              programStatusTypes={programStatusTypes}
              memberSelected={memberSelected}
              isMemberLoading={isMemberLoading}
              interactionTypes={interactionTypes}
              interactionOutcomes={interactionOutcomes}
            />
          }
          <Row>
            <Col className="mb-3">
              <Accordion defaultActiveKey="2" className="mb-3">
                <Card className="rounded-3 p-2">
                  <Card.Header eventKey="2" className="d-flex align-items-center bg-transparent pt-3 border-0">
                    <PlusMinusToggle eventKey="2">
                      <span id={`${componentName}-calendarTitle`}>My Calendar</span>
                    </PlusMinusToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between mb-3">
                        <div>
                          <Button id={`${componentName}-addAppointmentBtn`} variant="primary"
                            onClick={handleShowAppointment} className="me-0">
                            + Appointment
                          </Button>
                        </div>
                        <div>
                          <ButtonGroup
                            size="sm"
                            className="d-none d-md-inline-block bg-white align-top mb-2 mb-md-0"
                          >
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={<Tooltip>Month View</Tooltip>}
                            >
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                id={`${componentName}-ShowMonthView`}
                                onClick={() => onChangeCalendarView(MONTHLY_VIEW)}
                              >
                                <i className="fas fa-calendar-alt fa-lg align-middle"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={<Tooltip>Week View</Tooltip>}
                            >
                              <Button
                                className="me-0"
                                variant="outline-secondary"
                                size="sm"
                                id={`${componentName}-ShowWeekView`}
                                onClick={() => onChangeCalendarView(WEEKLY_VIEW)}
                              >
                                <i className="fas fa-calendar-week fa-lg align-middle"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={<Tooltip>Day View</Tooltip>}
                            >
                              <Button
                                className="me-0"
                                variant="outline-secondary"
                                size="sm"
                                id={`${componentName}-ShowDayView`}
                                onClick={() => onChangeCalendarView(DAILY_VIEW)}
                              >
                                <i className="fas fa-calendar-day fa-lg align-middle"></i>
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </div>
                      </Card.Title>
                      {calendarViewType === MONTHLY_VIEW ?
                        <CalendarView
                          patientList={appointmentList}
                          loadingPatients={isLoadingAppointment}
                          handleDateChange={handleDateChange}
                          dateObject={dateObject}
                          setDateObject={setDateObject}
                          updateAppointment={updateFromCalendarModal}
                          returnToUrl={""}
                          viewType={calendarViewType}
                          module={CM_WORKSPACE_MODULE}
                          todayButton={true}
                        /> :
                        <CalendarDaysView
                          appointmentList={appointmentList}
                          searchDate={dateObject}
                          onSearchDateChange={handleDateChange}
                          setSearchDate={setDateObject}
                          isLoadingAppointment={isLoadingAppointment}
                          viewType={calendarViewType}
                          updateAppointment={updateFromCalendarModal}
                          todayButton={true}
                        />}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
          <AddMemberModal
            showMember={showAddMemberModal}
            setShowMember={setShowAddMemberModal}
            setMembersData={setMembersData}
            setMembersPagination={setMembersPagination}
            membersPagination={membersPagination}
            currentUser={viewAsPL}
          />
          <FollowUpModal showFollowUp={showFollowUpModal} setShowFollowUp={setShowFollowUpModal} memberData={followUpMemberData} />
          <SubmitReferralModal
            showSubmitReferral={showSubmitReferral}
            setShowSubmitReferral={setShowSubmitReferral}
          />
          <ReassignMemberModal
            showReassignModal={showReassignModal}
            setShowReassignModal={setShowReassignModal}
            memberSelected={memberSelected}
            refreshMembers={() => paginateMembers(1)}
            viewAsPL = {viewAsPL}
            onError = {onError}
          />
          <Modal size="xl" centered show={showAppointment} onHide={handleAppointmentClose}>
            {isLoadingAppointment && <EQHSpinner />}
            <Modal.Header closeButton>
              <Modal.Title id={`${componentName}-appointmentModalTitle`}>Add/Update Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col id={`${componentName}-appointmentPatient`} className="mb-3" md={6}>
                  <Form.Label id={`${componentName}-AppointmentPatientLabel`} className="fw-bold">
                    Patient
                  </Form.Label>
                  {selectedAppointment?.id && selectedAppointment.patientId ?
                    <div className="row"><Form.Control id={`${componentName}-AppointmentPatientValue`} type="text"
                      value={`${selectedAppointment.patientLastName}, ${selectedAppointment.patientFirstName} ${selectedAppointment.patientMiddleName}`}
                      disabled="true" />
                      <a onClick={(e) => clearAppointmentPatient()}>X</a>
                    </div> :
                    <PatientSearchAsync setPatient={handlePatientChange} />
                  }
                </Col>
                <Col sm={3} id={`${componentName}-appointmentTypes`}>
                  <DropdownComponent
                    name="appointmentTypeId"
                    list={appointmentTypes}
                    id={`${componentName}-appointmentTypes`}
                    handleInputChange={handleInputChange}
                    label="Appointment Type"
                    required={false}
                    isOpen={isOpen}
                    val={formBody.appointmentTypeId}
                  />
                </Col>
              </Row>
              <Row>
                <Col id={`${componentName}-appointmentName`} className="mb-3" md={6}>
                  <label id={`${componentName}-appointmentNameLabel`} className="d-block">Appointment Name<span className="text-danger">*</span></label>
                  <Form.Control id={`${componentName}-appointmentNameValue`} type="text" value={formBody.appointmentName}
                    onChange={(e) => handleInputChange("appointNameValue", e.target)} />
                </Col>
                <Col id={`${componentName}-appointmentDate`} className="mb-3" md={3}>
                  <label id={`${componentName}-appointmentDateLabel`} className="d-block">Date<span className="text-danger">*</span></label>
                  {/* <Form.Control id={`${componentName}-appointmentDateValue`} type="text" 
                  onChange={(e) => handleInputChange("appointDateValue", e.target)}/> */}
                  <DatePickerStyled>
                    <DatePicker
                      name="dueDate"
                      filterTime={filterPassedTime}
                      value={selectedDate}
                      selected={selectedDate}
                      onChange={(e) => setSelectedDateFunc(e)}
                      showTimeSelect
                      className="form-control"
                      inputProps={{
                        id: `${componentName}-appointmentDateValue`
                      }}
                      minDate={new Date()}
                      minTime={setHours(setMinutes(new Date(), MIN_MINUTES), MIN_HOUR)}
                      maxTime={setHours(setMinutes(new Date(), MAX_MINUTES), MAX_HOUR)}
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "0px, 0px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "scrollParent"
                        }
                      }}
                      autoComplete="off"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </DatePickerStyled>
                </Col>
                {/* <Col id={`${componentName}-memberNotesCat`} className="mb-3" md={3}>
                  <label id={`${componentName}-memberNotesCatLabel`} className="d-block">Duration<span className="text-danger">*</span></label>
                  <Form.Control id={`${componentName}-memberNotesValue`} max="500" as="textarea" rows={3}
                  onChange={(e) => handleInputChange("duration", e.target)} />
                </Col> */}
                <Col sm={3} id={`${componentName}-durtion`}>
                  <DropdownComponent
                    name="duration"
                    list={durationList}
                    id={`${componentName}-appointmentDuration`}
                    handleInputChange={handleInputChange}
                    label="Duration"
                    required={true}
                    isOpen={isOpen}
                    val={formBody.durationInMinutes}
                  />
                </Col>
              </Row>
              <Row>
                <Col id={`${componentName}-memberNotes`}>
                  <label id={`${componentName}-memberNotesLabel`} className="d-block">Notes</label>
                  <Form.Control id={`${componentName}-memberNotesValue`} max="500" as="textarea" rows={3}
                    onChange={(e) => handleInputChange("notes", e.target)} value={formBody.notes} />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button id={`${componentName}-appointmentCloseButton`} variant="secondary" onClick={handleAppointmentClose}>
                Cancel
              </Button>
              {selectedAppointment?.id ? <>
                <Button id={`${componentName}-appointmentSaveButton`} variant="warning"
                  onClick={() => handleDeleteAppointment()}>
                  Delete
                </Button>
                <Button id={`${componentName}-appointmentSaveButton`} variant="primary"
                  onClick={() => handleUpdateAppointment()}
                  disabled={!enableButton()}>
                  Save
                </Button>
              </> : <Button id={`${componentName}-appointmentSaveButton`} variant="primary"
                onClick={() => onsubmitAppointment()}
                disabled={!enableButton()}>
                Save
              </Button>}

            </Modal.Footer>
          </Modal>
          <ResolveTaskModal
            closeResolveTask={closeResolveTaskModal}
            showResolveTask={showResolveTaskModal}
            taskAction={actionSelected}
            task={taskSelected}
            reasons={reasons}
            save={resolveTask}
            openTaskModal={openTaskModal}
            setDefaultMember={setDefaultMember}
            filterTime={filterPassedTime}
            popperModifiers={popperModifiers}
            minMinutes={MIN_MINUTES}
            maxMinutes={MAX_MINUTES}
            minHour={MIN_HOUR}
            maxHour={MAX_HOUR}
          />
      </Container>
    </ThemeProvider>
  );
};
export default CareTeam;
