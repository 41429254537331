import React, {useContext, createContext, useReducer} from 'react';
import {actions, initialState} from './actions';
import {reducer} from './EQHToast.reducer';

const EQHToastContext = createContext();

const EQHToastProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getters = {
    notification: state.notification
  };

  const toggles = {
    setNotification: async (title, message) => {
      dispatch({type: actions.SET_NOTIFICATION, payload: {title, message}})
    },
    removeNotification: async () => {
      dispatch({type: actions.SET_NOTIFICATION, payload: {}})
    }
  };

  const value = {
    ...getters,
    ...toggles,
  };

  return (
    <EQHToastContext.Provider value={value}>
      {children}
    </EQHToastContext.Provider>
  )
};
const useEQHToastContext = () => useContext(EQHToastContext);

export {
  EQHToastProvider,
  useEQHToastContext,
}