import React from "react";
import { Row, Col, Form, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { INT_PATIENT_NOTES_INFO_MSG } from '../utils';
import PatientDetailsNoteForm from './PatientDetailsNoteForm';
import { cleanPatientInfo, convertTimestamp } from "../utils";
import {
  usePatientDetailsContext
} from "./PatientDetailsModals/context/PatientDetails.context";
import { useAuth } from "../../../../context/authContext";

const componentName = "InternalPatientNotes";

/**
 * InternalPatientNotes component displays a patient's internal notes.
 *
 * @param {Object} patientInfo - Object containing the patient's internal notes.
 * @returns {JSX.Element} A section of the patient details view with the patient's internal notes.
 */
const InternalPatientNotes = ({ patientInfo }) => {
  const {
    toggleAddInternalNoteEnabled
  } = usePatientDetailsContext();

  const auth = useAuth();
  const authorizedForAddInternalNotes = auth.hasAuthority("AUTH_PD_PATIENT_DETAILS_INTERNAL_NOTES_WRITE_ACCESS");
  const { detailsNotes: notes } = cleanPatientInfo(componentName, patientInfo);

  return (
    <Form>
      <Row>
        <Col>
          <p className="fs-6 fw-bold d-inline-block pe-2 pb-4">Internal Patient Notes</p>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{INT_PATIENT_NOTES_INFO_MSG}</Tooltip>}
          >
            <i className="fa-regular fa-circle-info border-0 text-dark"/>
          </OverlayTrigger>
        </Col>
        <Col>
          <div className="d-flex justify-content-end align-items-center mb-1 position-relative">
            <Button
              className="px-4 fw-bold bg-transparent"
              variant="secondary"
              id={`${componentName}-addNote`}
              size="sm"
              style={{
                borderColor: 'transparent',
                boxShadow: 'none'
              }}
              onClick={() => toggleAddInternalNoteEnabled(true)}
              disabled={!authorizedForAddInternalNotes}
            >
              Add Note
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {
            notes.sort((a, b) => new Date(b.time) - new Date(a.time))
              .map(({ time, author, description, text }, idx) =>
                <PatientDetailsNoteForm
                  key={`${componentName}-internalNote-${idx}`}
                  elementPrefix={`${componentName}-${idx}`}
                  note={{
                    time: convertTimestamp(time),
                    author,
                    subject: description,
                    text
                  }}
                />
              )}
        </Col>
      </Row>
    </Form>
  );
}

export default InternalPatientNotes;
