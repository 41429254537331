import React, { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { Card } from "./cards";
import Modals from "./modals";
import { EQHSpinner } from "./EQHSpinner";
import { Accordion, useAccordionButton, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import EQHErrorToast from "../components/EQHErrorToast";

const componentName = "SectionAdmin";

function PlusMinusToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Button variant="link" className="d-flex align-items-center text-dark text-decoration-none p-0" onClick={decoratedOnClick}>
            <i id={`${componentName}-plusMinusIcon-${eventKey}`} className={isCurrentEventKey ? "far fa-minus-circle me-1" : "far fa-plus-circle me-1"}></i>{" "}
            <span id={`${componentName}-itemTitle-${eventKey}`} className="fw-bold">{children}</span>
        </Button>
    );
}

const SectionAdmin = ({
    title,
    isLoading,
    errors,
    setErrors,
    sectionBody,
    isShowModalBtnPositionUp,
    onClickShowModal,
    modalShow,
    modalOnHide,
    modalOnCreate,
    modalTitle,
    modalBody,
}) => {
    return <>
        <Accordion defaultActiveKey="0" className="mb-3 border-0">
            <Card className="rounded shadow-sm border-0">
                <Card.Header eventKey="0">
                    <PlusMinusToggle eventKey="0">
                        {title}s
                    </PlusMinusToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
                        {isLoading && <EQHSpinner />}
                        {isShowModalBtnPositionUp &&
                            <Button id={`${componentName}-addNew${title}`} variant="secondary" disabled={isLoading} onClick={onClickShowModal}>
                                Add New {title}
                            </Button>
                        }
                        {!isLoading && sectionBody}
                        {!isShowModalBtnPositionUp &&
                            <Button id={`${componentName}-addNew${title}`} variant="secondary" disabled={isLoading} onClick={onClickShowModal}>
                                Add New {title}
                            </Button>
                        }

                        <Modals
                            show={modalShow}
                            onHide={modalOnHide}
                            onCreate={modalOnCreate}
                            title={modalTitle}
                            body={modalBody}
                            closeBtnId={modalTitle+"-close-btn"}
                            completeBtnId={modalTitle+"-save-btn"}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            completeBtn="Save Changes"
                            closeBtn="Close"
                            hideFooter={false}
                            hideClickButton={false}
                            showExtraButton={true}
                        />

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
}

export default SectionAdmin;