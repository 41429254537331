import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Table, Button, Alert } from "react-bootstrap";
import ErrorToast from "../common/ErrorToast";
import SuccessToast from "../common/SuccessToast";
import Upload from "../../components/Upload";
import styled, { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { theme } from "../../components/themes/theme1";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  addDocument,
  removeDocument,
  getValues,
  getDocuments,
  changeClient
} from "../../actions/admin/gapDocumentationActions";
import Modals from "../../components/modals";
import { useAuth } from "../../context/authContext";
import {getDisplayValue} from "../../utils/util";
import {stateFullName} from "../../utils/textValueLists";

const componentName="GapDocumentation";

const UploadsPadding = styled.div`
  padding: 1.25rem 0%;
`;

const InputMargin = styled.div`
  margin: 5px;
`;

const StyledTypeAhead = styled(Typeahead)`
  width: 100px;
`;
export const GapDocumentation = () => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const { clientDropDownValues, uploads, history } = useSelector((state) => state.gapDocumentation);
  const errors = useSelector((state) => state.errors);
  const success = useSelector((state) => state.success);
  const [showModal, setShowModal] = useState(false);
  const [selectedDoc, setselectedDoc] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [documentDisplayName, setDocumentDisplayName] = useState("");
  const [disableInput, setDisableInput] = useState(true);
  const [selectedClient, setSelectedClient] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const clients = clientDropDownValues;
  const auth = useAuth();
  const token = auth.getAuthToken();
  const inputSources = auth.getInputSources().sort();

  useEffect(() => {
    if (inputSources.length > 0) {
      setSelectedClient(inputSources[0]);
      (async function fetchData() {
        await dispatch(changeClient());
        await dispatch(getDocuments(inputSources[0]));
      })();
    }
  }, []);

  const setFileMethod = (file) => {
    if (file && file.name) {
      setFileName(file.name)
    }
    const fileReader = new FileReader();
    if (fileReader && file) {
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function () {
        setFile(fileReader.result)
      };
    }
  };

  const handleChangeWithinModal = async (e) => {
    const obj = e.target.value;
    await dispatch(changeClient());
    if (obj) {
      setDisableInput(false);
      setSelectedClient(obj);
      await dispatch(getDocuments(obj));
    } else {
      setDisableInput(true);
    }
  };

  const handleNameChange = (e) => {
    const { name, value, checked } = e.target;
    if(typeof value === "undefined") {
      setDocumentDisplayName("");
    } else {
      setDocumentDisplayName(value);
    }
  }

  const setDocumentToRemove = document => {
    setselectedDoc(document);
    setShowModal(true);
  }

  const removeSetDocument = () => {
    dispatch(removeDocument(selectedDoc));
    setShowModal(false);
  }

  const submitDocument = async () => {
    const result = await dispatch(addDocument(file, encodeURIComponent(selectedClient), fileName, encodeURIComponent(documentDisplayName)));
    if (result && result.status && result.status === 500) {
      setSubmitError("Unable to add more documents. Please remove one or more documents.");
      setShowAlert(true);
    }
    await dispatch(getDocuments(selectedClient));
  }

  return (
    <ThemeProvider theme={theme}>
      <Row className="container-fluid p-3">
        <Col>
          {showAlert && (
            <Alert id={`${componentName}-alertMessage`} variant="danger" onClose={() => setShowAlert(false)} dismissible>
              {submitError}
            </Alert>)
          }
          {errors && errors.message && <ErrorToast />}
          <Card>
            <Card.Body>
              <Form.Label>
                <span id={`${componentName}-clientLabel`}>Client</span>
              </Form.Label>
              <Form.Control
                  as="select"
                  name="client"
                  value={selectedClient}
                  id={`${componentName}-clientValue`}
                  className="form-select"
                  onChange={(e) => handleChangeWithinModal(e)}
              >
                {inputSources.map((r, idx) => (
                    <option id={`${componentName}-clientsItems-${idx}`} key={r} value={r}>
                      {getDisplayValue(stateFullName, r)}
                    </option>
                ))}
              </Form.Control>
              <InputMargin />
              <Upload
                fileName={fileName}
                setFile={setFileMethod}
                disabled={disableInput}
                accept="application/pdf"
              />{" "}
              <InputMargin />
              <input
                  type="text"
                  className="form-control"
                  name="documentDisplayName"
                  id={`${componentName}-addDocumentNameValue`}
                  placeholder={"Document Name"}
                  onChange={(e) => handleNameChange(e)}
              />
              <InputMargin />
              <Button
                id={`${componentName}-addDocumentButton`}
                variant="primary"
                disabled={disableInput || isEmpty(file)}
                onClick={() => submitDocument()}
              >
                Add Document
              </Button>

              <UploadsPadding>
                {uploads && uploads.length ? (
                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th id={`${componentName}-documentNameLabel`}>Document Name</th>
                        <th id={`${componentName}-uploadedByLabel`}>Uploaded By</th>
                        <th id={`${componentName}-uploadedDateLabel`}>Uploaded Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploads.map((upload, idx) => {
                        return (
                          <tr key={upload.displayName}>
                            <td id={`${componentName}-documentNameValue-${idx}`}>{upload.displayName}</td>
                            <td id={`${componentName}-uploadedByValue-${idx}`}>{upload.uploadedByUser}</td>
                            <td id={`${componentName}-documentName-${idx}`}>{upload.uploadedDate}</td>
                            <Button id={`${componentName}-removeDocumentButton-${idx}`} variant="danger" onClick={() => setDocumentToRemove(upload)}>
                              REMOVE
                            </Button>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                ) : null}
              </UploadsPadding>

              <Modals
                show={showModal}
                onHide={() => setShowModal(false)}
                title="Remove Document"
                size="md"
                completeBtn={"OK"}
                onCreate={removeSetDocument}
                body="Are you sure you want to remove this document?"
              />

              <Form.Label>
                <span id={`${componentName}-historyLabel`}>History</span>
              </Form.Label>

              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th id={`${componentName}-historyDocumentNameLabel`}>Document Name</th>
                    <th id={`${componentName}-historyUploadedByLabel`}>Uploaded By</th>
                    <th id={`${componentName}-historyUploadedDateLabel`}>Uploaded Date</th>
                    <th id={`${componentName}-historyRemovedByLabel`}>Removed By</th>
                    <th id={`${componentName}-historyRemovedDateLabel`}>Remove Date</th>
                  </tr>
                </thead>
                <tbody>
                  {history && history.length ? (
                    history.map((doc, idx) => {
                      return (
                        <tr key={doc.displayName}>
                          <td id={`${componentName}-historyDocumentNameValue-${idx}`}>{doc.displayName}</td>
                          <td id={`${componentName}-historyUploadedByValue-${idx}`}>{doc.uploadedByUser}</td>
                          <td id={`${componentName}-historyUploadedDateValue-${idx}`}>{doc.uploadedDate}</td>
                          <td id={`${componentName}-historyRemovedByValue-${idx}`}>{doc.removedByUser}</td>
                          <td id={`${componentName}-historyRemovedDateValue-${idx}`}>{doc.removedDate}</td>
                        </tr>
                      )
                    })
                  ) : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export default GapDocumentation;
