export const newUser = {
    firstName:"",
    lastName:"",
    email:"",
    client:"",
    accessType: "",
    identifier: "",
    userRole: {
        id: "",
        name: "",
        adminRole: false,
        visibilityGroupRole: false,
        carePathTemplateRole: false,
        assessmentRole: false,
        defaultRole: false,
        authorities: []
    },
    organization:{
        id: "",
        name: ""
    }
}

export const practiceInformation = {
    referralEmail1: "",
    referralEmail2: "",
    referralEmail3: "",
    participatingPayerNames:[]
}