import React from "react";
import styled from "styled-components";
import AccordionItem from "./components/AccordionItem";

const componentName = "Timeline";

const Timeline = ({
  items,
  titleBuilder,
  subTitleBuilder,
  bodyBuilder,
  selectedItemId,
  itemRef,
  type,
  setErrors
}) => {
  const ReferralHistory = styled.span``;

  return (
    <ReferralHistory>
      <div className="vertical-timeline-container">
        {items?.length > 0 ? items.map((item, idx) => {
          const ref = selectedItemId && selectedItemId == item.noteId ? itemRef : null;

          return (
            <div ref={ref} className="vertical-timeline vertical-timeline--one-column">
              <div className="vertical-timeline-item vertical-timeline-element">
                <div>
                  <span className="vertical-timeline-element-icon mt-3">
                    <i id={`${componentName}-tlMark-${idx}`} className="badge bg-white p-2 border rounded-circle"> </i>
                  </span>
                  <div id={`${componentName}-tl-${idx}`} className="vertical-timeline-element-content bg-white border rounded-3 p-3">
                    <AccordionItem
                      idx={idx}
                      subTitleBuilder={subTitleBuilder}
                      setErrors={setErrors}
                      componentName={componentName}
                      item={item}
                      type={type}
                    />
                    <p id={`${componentName}-tlInfo-${idx}`} className="mb-0">
                      {bodyBuilder && bodyBuilder(item, idx)}
                    </p>
                    <span
                      id={`${componentName}-tlMeta-${idx}`}
                      className="vertical-timeline-element-date mt-3 text-break text-start"
                      style={{ width: '95px', left: '-105px' }}
                    >
                      <span className="d-block">
                        {titleBuilder && titleBuilder(item, idx)}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        }): <div className="my-3 mx-2">No Records Found</div> }
      </div>
    </ReferralHistory>
  );
};

export { Timeline };
