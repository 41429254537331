import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import { EQHDatePicker } from "../../../../components/datePicker";
import { isEmpty } from "lodash";
import { format } from "date-fns";

const componentName = "immunizationDoses";

export const ImmunizationDoses = ({ id, show, setShow, immunizationObject, handleSave }) => {


    const [disableSaveButton, setDisableSaveButton] = useState(true)
    const [doseObjectsNew, setDoseObjectsNew] = useState({});


    useEffect(() => {
        setDoseObjectsNew(() => getDoseObjects())
    }, [show])

    const getDoseObjects = () => {

        let doses = cloneDeep(immunizationObject.careGapImmunizationDoseList);
        doses.sort(function (a, b) {
            return (!a.doseNo) - (!b.doseNo) || +(a.doseNo > b.doseNo) || -(a.doseNo < b.doseNo);
        });
        const newObject = [];
        if (immunizationObject && immunizationObject.rv5OrRv1ValCompleted && (immunizationObject.immunizationAbbreviation === "RV1" || immunizationObject.immunizationAbbreviation === "RV5")) {

            if ((immunizationObject.immunizationAbbreviation === "RV1" &&
                immunizationObject.rv5OrRv1ValCompleted === 2)
                || (immunizationObject.immunizationAbbreviation === "RV5" &&
                    immunizationObject.rv5OrRv1ValCompleted === 1)) {
                const ind = immunizationObject?.careGapImmunizationDoseList.findIndex(e => !e.immunizationStatusENUM && !e.dateOfService)
                doses.splice(ind, 1);
            }

        }
        doses.forEach(function (dose, index) {
            if (dose.immunizationStatusENUM === 'MARKED_FOR_CLOSURE' || dose.immunizationStatusENUM === 'CLOSED') {
                dose.isReadOnly = true;
                //newObject.push(dose);
            }
            else {
                dose.isReadOnly = false;
                //newObject.push(dose);
            }

            if (immunizationObject && immunizationObject.dateLowerBound && immunizationObject.daysAfterBirth) {
                let dateThreshold = new Date(immunizationObject.dateLowerBound);

                const dateLowerBound = new Date(immunizationObject.dateLowerBound)
                const today = new Date(format(new Date(), "yyyy-MM-dd"))

                if (dateLowerBound.valueOf() > today.valueOf()) {
                    dose.isReadOnly = true;
                    dose.error = true;
                    dose.errorMessage = "Eligible for vaccination only on or after " + immunizationObject.dateLowerBound;
                }
                else {
                    delete doses[index]["error"]
                    delete doses[index]["errorMessage"]
                }
            }
        });
        return doses;
    };



    const [errors, setErrors] = useState({})

    const saveImmunization = (action) => {
        let immunizationObjectNew = cloneDeep(immunizationObject)
        immunizationObjectNew.careGapImmunizationDoseList = doseObjectsNew
        handleSave(immunizationObjectNew, id, action)


        setShow(false);
        document.querySelector('.patientDetailsOffcanvas').style.visibility = 'visible';
    };

    const handleDatachange = (e, index, type, immunizationObject) => {
        let doses = cloneDeep(doseObjectsNew);
        let errCount = 0

        if (type === 'date') {
            if (e && e.strDate) {
                if (doseObjectsNew.some((m) => (m.dateOfService === e.strDate))) {
                    doses[index]["error"] = true
                    doses[index]["errorMessage"] = "Date of Service cannot be the same"
                    errCount++
                }
                if (immunizationObject &&
                    (immunizationObject.immunizationAbbreviation === 'RV1' ||
                        immunizationObject.immunizationAbbreviation === 'RV5')

                    && immunizationObject.allDateOfServices
                    && immunizationObject.allDateOfServices.length
                    && immunizationObject.allDateOfServices.includes(e.strDate)) {
                    doses[index]["error"] = true
                    doses[index]["errorMessage"] = "Date of Service of RV1 and RV5 cannot be the same"
                    errCount++
                }
                if (immunizationObject && immunizationObject.dateLowerBound) {
                    const dateLowerBound = new Date(immunizationObject.dateLowerBound)
                    const today = new Date(format(new Date(), "yyyy-MM-dd"))
                    const typedDate = new Date(e.strDate)

                    if (typedDate.valueOf() > today.valueOf() || typedDate.valueOf() < dateLowerBound.valueOf()) {
                        doses[index]["error"] = true
                        doses[index]["errorMessage"] = "Invalid date selected."
                    }
                    else {
                        if (errCount === 0) {
                            doses[index].dateOfService = e.strDate
                            delete doses[index]["error"]
                        }

                    }

                }
                doses[index].dateOfService = e.strDate
            }
            else{
                doses[index].dateOfService = ''
            }
            if (e.strDate && doses[index]?.immunizationStatusENUM) {
                
                setDisableSaveButton(false)
            }
            else {
                
                setDisableSaveButton(true)
            }
        }
        else {

            if (e && e.target && e.target.value) {
                const { value } = e.target;
                if (value && doses[index]?.dateOfService) {
                    setDisableSaveButton(false)
                }
                else {
                    setDisableSaveButton(true)
                }

                doses[index].immunizationStatusENUM = value
            }


        }
        setDoseObjectsNew(doses);
    }

    return (
        <>
            <Modal size="md" show={show} centered>
                <Modal.Header>
                    <Modal.Title>{immunizationObject?.description} ({immunizationObject?.immunizationAbbreviation})</Modal.Title>
                    <button type="button" onClick={() => saveImmunization("cancel")} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(doseObjectsNew) && doseObjectsNew.map((doseObject, idxDose) => (
                        <Row className="mb-3">
                            <Col>
                                <Row>
                                    <Form.Label id={`${componentName}-DoseDOSLabel`} className="d-block">
                                        Dose {idxDose + 1} - Date of Service
                                    </Form.Label>
                                    <InputGroup>
                                        <EQHDatePicker
                                            id={`${componentName}-DoseDOSValue`}
                                            className="border-end-0 rounded-3"
                                            value={doseObject.dateOfService}
                                            onChange={(e) => handleDatachange(e, idxDose, "date", immunizationObject)}
                                            min={immunizationObject?.dateLowerBound}
                                            max={format(new Date(), "yyyy-MM-dd")}
                                            disabled={doseObject.isReadOnly}
                                            isInvalid={doseObject.error}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {doseObject.errorMessage}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Label id={`${componentName}-DoseStatusLabel`} className="d-block">
                                        Status
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        id={`${componentName}-DoseStatusValue-${idxDose}`}
                                        className="form-select"
                                        value={doseObject && doseObject.immunizationStatusENUM ? doseObject.immunizationStatusENUM : ""}
                                        disabled={doseObject.isReadOnly || isEmpty(doseObject.dateOfService)}
                                        onChange={(e) => handleDatachange(e, idxDose, "status")}
                                    >
                                        <option value=""></option>
                                        <option value="MARKED_FOR_CLOSURE">Marked for Closure</option>
                                        <option value="PATIENT_REFUSAL">Patient Refusal</option>
                                        <option value="CLOSED" hidden>CLOSED</option>
                                    </Form.Control>
                                </Row>
                            </Col>
                        </Row>
                    ))}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => saveImmunization("cancel")}>
                        Cancel
                    </Button>
                    <Button disabled={(!isEmpty(doseObjectsNew) && (doseObjectsNew.some(m => m.error))) || disableSaveButton} variant="primary" onClick={() => saveImmunization("save")}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImmunizationDoses;