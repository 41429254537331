import React, {useEffect, Fragment, useState, useCallback} from "react";
import { Card } from "react-bootstrap";
import { EQHSpinner } from "../../components/EQHSpinner";
import { ItemsAccordion } from "../../components/ItemsAccordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { formatDateTime, handlePkgErrors } from "../../utils/util";
import { careOppsReducer } from "../../reducers/patientProfile/careOppsReducer";
import {
  useGetPatientProfileCareOpps,
  useGetPatientProfileSuspects,
  useGetPatientCard,
  loadPreventiveCareHistoryData,
} from "../../actions/patientProfileActions";
import { suspectsReducer } from "../../reducers/patientProfile/suspectsReducer";
import { patientReducer } from "../../reducers/patientProfile/patientReducer";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import ManageEvidence from "./../quickList/ManageEvidence";
import Modals from "../../components/modals";
import { opportunityRejectionReasons } from "../../utils/textValueLists";
import PreventiveCareHistory from "./../quickList/PreventiveCareHistory";
import axios from "axios/index";
import { QuickListContext } from "../quickList/QuickListContext";
import { useGetTransQuickListSettings } from "../quickList/quickListSettings";
import { QuickLists } from "../quickList/QuickList";
import { useAuth } from "../../context/authContext";
import PatientDetails from "../quickList/components/patientDetails";
import {setManageVisitPatient} from "../../actions/manageVisitActions";
import {useDispatch} from "react-redux";
import ManageVisitlModal from "./modals/ManageVisitlModal";
import {AppPropertiesUtil} from "../../utils/localStorageUtil";

const componentName = "patientProfile-PreventiveCare";
const tocAbbreviations = ["ADT", "ADT-IP", "MRP", "FUH"];

const baseURLPC =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";

export let RETURN_TO_MY_PATIENTS_URL = encodeURIComponent(
  "returnTo_" + window.location.pathname
);

const CareOpportunityCard = ({
  patient,
  careOpportunity,
  updateParent,
  filterOutActives,
  idx
}) => {
  const [navigationItems, setNavigationItems] = useState({
    id: null,
    type: null,
  });
  const [activeKey, setActiveKey] = useState("0");
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [changesMade, setChangesMade] = useState(false);


  const navigateById = async (id, type, key) => {
    let useable = await filterOutActives(careOpportunity.status.name);
    if (useable) {
      setActiveKey(key);
      setNavigationItems({ id: id, type: type });
      setShowEvidenceModal(true);
    }
  };

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
  };

  const handleClose = () => {
    if (changesMade) {
      setConfirmationMessage(true);
    } else {
      closeEvidenceModalMain();
    }
  };

  if (careOpportunity) {
    return (
      <Col sm={6} className="mb-3">
        <Modals
          scrollable={true}
          show={showEvidenceModal}
          closeEvidenceModal={() => closeEvidenceModalMain()}
          onHide={() => handleClose()}
          size="xl"
          title="Manage Visit"
          titleid={`${componentName}-manageVisitModal`}
          completeBtn="Update"
          completeBtnId={`${componentName}-updateButton`}
          hideFooter={true}
          body={
            <ManageEvidence
              patient={patient}
              closeEvidenceModal={() => closeEvidenceModalMain()}
              returnPath={""}
              updateParent={updateParent}
              navigationParams={navigationItems}
              activeKey={activeKey}
              updatesMade={setChangesMade}
              setConfirmationMessage={setConfirmationMessage}
              confirmationMessage={confirmationMessage}
            />
          }
        />
        <div id={`${componentName}-careOpp-${idx}`} className="bg-white shadow-sm border p-2 font-12 h-100">
          <Row className="h-100">
            <Col sm={6} id={`${componentName}-careOppLeft-${idx}`} className="border-end">
              <div className="mb-2 card-title">
                <div id={`${componentName}-careOppStatus-${idx}`} className="d-block fw-bold">
                  {careOpportunity.status.name}
                </div>
                <div id={`${componentName}-careOppGap-${idx}`}
                  className="d-block fw-bold"

                >

                  <span id={`${componentName}-careOppGapYear-${idx}`}>
                    {careOpportunity.gapAndYear}
                  </span>

                </div>
                <small id={`${componentName}-careOppUpdated-${idx}`} className="d-block">
                  <em>Updated {formatDateTime(careOpportunity.updated)}</em>
                </small>
              </div>
              <div className="mb-2 card-title">
                <div id={`${componentName}-careOppLongName-${idx}`} className="d-block fw-bold">
                  {careOpportunity.longName}
                </div>
              </div>
            </Col>
            <Col id={`${componentName}-careOppRight-${idx}`}>
              <div id={`${componentName}-careOppDescription-${idx}`} className="mb-2 card-title">
                <div id={`${componentName}-careOppDescriptionLabel-${idx}`} className="d-block fw-bold">Description</div>
                <div id={`${componentName}-careOppDescriptionValue-${idx}`} className="d-block">{careOpportunity.description}</div>
              </div>
              {careOpportunity.exclusionCriteria.length > 0 &&
                !(
                  careOpportunity.exclusionCriteria.length === 1 &&
                  careOpportunity.exclusionCriteria[0].name === "No Exclusions"
                ) && (
                  <div id={`${componentName}-careOppExclCriteria-${idx}`} className="mb-2 card-title">
                    <div id={`${componentName}-careOppExclCriteriaLabel-${idx}`} className="d-block fw-bold">
                      Exclusion Criteria
                    </div>
                    {careOpportunity.exclusionCriteria.map((exclusion) =>
                      exclusion.name !== "No Exclusions" ? (
                        <div id={`${componentName}-careOppExclCriteriaValue-${idx}`} className="d-block">{exclusion.name}</div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
  return <></>;
};

const SuspectCard = ({ patient, suspect, updateParent, filterOutActives, idx }) => {
  const [navigationItems, setNavigationItems] = useState({
    id: null,
    type: null,
  });
  const [activeKey, setActiveKey] = useState("0");
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const navigateById = async (id, type, key) => {
    let useable = await filterOutActives(suspect.status.name);
    if (useable) {
      setActiveKey(key);
      setNavigationItems({ id: id, type: type });
      setShowEvidenceModal(true);
    }
  };

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
  };

  const handleClose = () => {
    if (changesMade) {
      setConfirmationMessage(true);
    } else {
      closeEvidenceModalMain();
    }
  };

  if (suspect) {
    return (
      <Col sm={3} className="mb-3">
        <Modals
          scrollable={true}
          show={showEvidenceModal}
          closeEvidenceModal={() => closeEvidenceModalMain()}
          onHide={() => handleClose()}
          size="xl"
          title="Manage Visit"
          titleid={`${componentName}-manageVisitSModal`}
          completeBtn="Update"
          completeBtnId={`${componentName}-updateButton`}
          hideFooter={true}
          body={
            <ManageEvidence
              patient={patient}
              closeEvidenceModal={() => closeEvidenceModalMain()}
              returnPath={""}
              updateParent={updateParent}
              navigationParams={navigationItems}
              activeKey={activeKey}
              updatesMade={setChangesMade}
              setConfirmationMessage={setConfirmationMessage}
              confirmationMessage={confirmationMessage}
            />
          }
        />
        <div id={`${componentName}-suspects-${idx}`} className="bg-white shadow-sm border p-2 font-12 h-100">
          <Row className="h-100">
            <Col>
              <div className="mb-2 card-title">
                <div id={`${componentName}-suspectsStatus-${idx}`} className="d-block fw-bold">
                  {suspect.status.name}
                </div>
                <div
                  className="d-block fw-bold"

                >

                  <span id={`${componentName}-suspectsGapYear-${idx}`}>
                    {suspect.conditionAndYear}
                  </span>

                </div>
                <small id={`${componentName}-suspectsUpdated-${idx}`} className="d-block">
                  <em>Updated {formatDateTime(suspect.updated)}</em>
                </small>
              </div>
              <div className="mb-2 card-title">
                <div id={`${componentName}-suspectsLongName-${idx}`} className="d-block fw-bold">
                  {suspect.longName}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
  return <></>;
};

const PreventiveCare = ({ patient, selectedTab, reloadPatientOverview }) => {
  const dispatch = useDispatch();
  const featureFlagCIS =   AppPropertiesUtil.get("cis.immunizations.enabled");
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const careOppsPkg = useGetPatientProfileCareOpps({}, careOppsReducer);
  const suspectPkg = useGetPatientProfileSuspects({}, suspectsReducer);
  const patientCardPkg = useGetPatientCard({}, patientReducer);
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [
    showOnlyActiveCareOpportunities,
    setShowOnlyActiveCareOpportunities,
  ] = useState(true);
  const [showOnlyActiveSuspects, setShowOnlyActiveSuspects] = useState(true);
  const [showFullHistory, setShowFullHistory] = useState(false);

  const [allOpportunitiesForHist, setAllOpportunitiesFOrHist] = useState([]);
  const [allSuspectsForHist, setAllSuspectsForHist] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [activeKey, setActiveKey] = useState("0");
  const [navigationItems, setNavigationItems] = useState({
    id: null,
    type: null,
  });
  const [selectedGaps, setSelectedGaps] = useState(null);
  const [userSelectedCareOppHistoryIds, setUserSelectedCareOppHistoryIds] =
      useState([]);

  useEffect(() => {
    if (selectedTab && patient.id) {
      init();
    }
  }, [patient.id, selectedTab]);

  const init = () => {
    careOppsPkg.fetchData(patient.id);
    suspectPkg.fetchData(patient.id);
    patientCardPkg.fetchData(patient.id);
    dispatch(setManageVisitPatient(patient));
    reloadPatientOverview();
  };

  const genericSelector = (searchArray) => (item) => {
    if (searchArray[0] === "~~ALL~~") {
      return true;
    }

    return searchArray.includes(item["TYPE_"]);
  };

  const headerAndSelectorMap = {
    "Care Opportunities": (item) =>
      genericSelector(["CareOpp"])(item) &&
      (!showOnlyActiveCareOpportunities ||
        isActiveCareOpportunity(item.status.name)),
    Suspects: (item) =>
      genericSelector(["Suspect"])(item) &&
      (!showOnlyActiveSuspects || isActiveSuspect(item.status.name)),
  };

  const sizeAndSelectorMap = {
    "Care Opportunities": (item) =>
      genericSelector(["CareOpp"])(item) &&
      isActiveCareOpportunity(item.status.name),
    Suspects: (item) =>
      genericSelector(["Suspect"])(item) && isActiveSuspect(item.status.name),
  };

  const isActiveCareOpportunity = (value) => {
    if (
      value === "Ordered" ||
      value === "Open" ||
      value === "Patient Refused" ||
      value === "Left Message" ||
      value === "Scheduled Appointment" ||
      value === "Referred" ||
      value === "Pending Documentation" ||
      value === "Changed PCP's" ||
      value === "Unable to Contact"
    ) {
      return true;
    }
    return false;
  };

  const isActiveSuspect = (value) => {
    if (value === "Open" || value === "Pending Diagnostic Workup") {
      return true;
    }
    return false;
  };

  const handleModalShow = () => {
    setActiveKey("0");
    setNavigationItems({
      id: null,
      type: null,
    });
    setShowEvidenceModal(true);
  };

  const auth = useAuth();
  const getSettingsRef = useGetTransQuickListSettings(
    "preventiveCare",
    0,
    auth
  );
  const [changesMade, setChangesMade] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
    init();
  };

  const handleClose = () => {
    if (changesMade) {
      setConfirmationMessage(true);
    } else {
      closeEvidenceModalMain();
    }
  };

  const handleUserSelectedCareOppHistoryIdsChange = (id) => {
    let userSelectedCareOppHistoryIdsClone = [...userSelectedCareOppHistoryIds];
    if (userSelectedCareOppHistoryIdsClone.includes(id)) {
      userSelectedCareOppHistoryIdsClone =
          userSelectedCareOppHistoryIdsClone.filter((x) => x !== id);
    } else {
      userSelectedCareOppHistoryIdsClone.push(id);
    }
    setUserSelectedCareOppHistoryIds(userSelectedCareOppHistoryIdsClone);
    setSelectedGaps(null);
  };

  const getCareOppHistoryIds = () => {
    const s = new Set();
    if (showFullHistory) {
      s.add(selectedGaps);
      userSelectedCareOppHistoryIds.forEach((x) => s.add(x));
    }
    return s;
  };

  const careOppHistoryIds = getCareOppHistoryIds();

  return (
    <Fragment>
      <Container fluid>
        <QuickListContext.Provider value={getSettingsRef()}>
          {(careOppsPkg.state.isLoading ||
            suspectPkg.state.isLoading ||
            patientCardPkg.state.isLoading) && <EQHSpinner />}
          {careOppsPkg.state.error ||
            suspectPkg.state.error ||
            patientCardPkg.state.error ? (
            <Card>
              <Card.Body>
                {handlePkgErrors(
                  careOppsPkg.state.error,
                  suspectPkg.state.error,
                  patientCardPkg.state.error
                )}
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <Row className="mb-2">
                  <Col>
                    {(auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS") ||
                      auth.hasAuthority(
                        "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS"
                      )) && (!auth.hasAuthority("AUTH_PROVIDER_MANAGE_VISIT")) && (
                        <Button
                          variant="secondary"
                          id={`${componentName}-manageVisit`}
                          className="align-top"
                          onClick={() => handleModalShow()}

                        >
                          Manage
                        </Button>
                      )}
                    {(auth.hasAuthority("AUTH_PROVIDER_MANAGE_VISIT")) && (
                      <Button
                        variant="secondary"
                        id={`${componentName}-manageVisit`}
                        className="align-top"
                        data-bs-toggle="offcanvas" data-bs-target="#patientDetailsModal"
                        onClick={() => setShowPatientDetails(!showPatientDetails)}
                      >
                        Manage
                      </Button>
                    )}
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      size="sm"
                      id={`${componentName}-showHistory`}
                      className="float-end me-0"
                      onClick={() => {
                        setShowFullHistory(true);
                        loadPreventiveCareHistoryData(
                          patient.id,
                          setAllOpportunitiesFOrHist,
                          setAllSuspectsForHist,
                          baseURLPC
                        );
                      }}
                    >
                      <i id={`${componentName}-historyIcon`} className="fas fa-history fa-lg align-middle"></i>
                    </Button>
                  </Col>
                </Row>
                <PreventiveCareHistory
                  allOpportunitiesForHist={allOpportunitiesForHist}
                  allSuspectsForHist={allSuspectsForHist}
                  showFullHistory={showFullHistory}
                  setShowFullHistory={setShowFullHistory}
                  careOppHistoryIds={careOppHistoryIds}
                  handleUserSelectedCareOppHistoryIdsChange={handleUserSelectedCareOppHistoryIdsChange}
                  setUserSelectedCareOppHistoryIds={setUserSelectedCareOppHistoryIds}
                  setSelectedGaps={setSelectedGaps}
                  featureFlagCIS={featureFlagCIS}
                />

                <ItemsAccordion
                  items={[
                    ...(careOppsPkg.state.data.patientCareGaps &&
                      auth.hasAnyAuthority([
                        "AUTH_CARE_GAPS_READ_ACCESS",
                        "AUTH_CARE_GAPS_WRITE_ACCESS",
                      ])
                      ? careOppsPkg.state.data.patientCareGaps.map((x) => {
                        x["TYPE_"] = "CareOpp";
                        return x;
                      })
                      : []),
                    ...(auth.hasAnyAuthority([
                      "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS",
                      "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS",
                    ]) && suspectPkg.state.data.patientConditions
                      ? suspectPkg.state.data.patientConditions.map((x) => {
                        x["TYPE_"] = "Suspect";
                        return x;
                      })
                      : []),
                  ]}
                  headerAndSelectorMap={headerAndSelectorMap}
                  selected={"Care Opportunities"}
                  sizeAndSelectorMap={sizeAndSelectorMap}
                  bodyBuilder={(key, list) =>
                    key === "Care Opportunities" ? (
                      <>
                        <Row>
                          {list
                            .filter((item) =>
                              isActiveCareOpportunity(item.status.name)
                            )
                            .map((careOpportunity, idx) => (
                              <CareOpportunityCard
                                careOpportunity={careOpportunity}
                                patient={patient}
                                idx={idx}
                                updateParent={init}
                                filterOutActives={isActiveCareOpportunity}
                              />
                            ))}
                        </Row>
                        <Badge
                          pill
                          bg="light"
                          id={`${componentName}-careOppPlusMinus`}
                          className="border mb-3 cursor-p text-dark"
                          onClick={() =>
                            setShowOnlyActiveCareOpportunities(
                              !showOnlyActiveCareOpportunities
                            )
                          }
                        >
                          <i id={`${componentName}-careOppPlusMinusIcon`}
                            className={
                              !showOnlyActiveCareOpportunities
                                ? "fa fa-minus"
                                : "fa fa-plus"
                            }
                          ></i>{" "}
                          <span id={`${componentName}-careOppPlusMinusText`}>Show Closed/ Inactive</span>
                        </Badge>
                        <Row>
                          {!showOnlyActiveCareOpportunities &&
                            list
                              .filter(
                                (item) =>
                                  !isActiveCareOpportunity(item.status.name)
                              )
                              .map((careOpportunity, idx) => (
                                <CareOpportunityCard
                                  careOpportunity={careOpportunity}
                                  patient={patient}
                                  idx={idx}
                                  updateParent={init}
                                  filterOutActives={isActiveCareOpportunity}
                                />
                              ))}
                        </Row>
                      </>
                    ) : key === "Suspects" ? (
                      <>
                        <Row>
                          {list
                            .filter((item) => isActiveSuspect(item.status.name))
                            .map((suspect, idx) => (
                              <SuspectCard
                                suspect={suspect}
                                patient={patient}
                                idx={idx}
                                updateParent={init}
                                filterOutActives={isActiveSuspect}
                              />
                            ))}
                        </Row>
                        <Badge
                          pill
                          bg="light"
                          className="border mb-3 cursor-p text-dark"
                          id={`${componentName}-suspectPlusMinus`}
                          onClick={() =>
                            setShowOnlyActiveSuspects(!showOnlyActiveSuspects)
                          }
                        >
                          <i
                            id={`${componentName}-suspectPlusMinusIcon`}
                            className={
                              !showOnlyActiveSuspects
                                ? "fa fa-minus"
                                : "fa fa-plus"
                            }
                          ></i>{" "}
                          <span id={`${componentName}-suspectPlusMinusText`}>Show Closed/ Inactive</span>
                        </Badge>
                        <Row>
                          {!showOnlyActiveSuspects &&
                            list
                              .filter(
                                (item) => !isActiveSuspect(item.status.name)
                              )
                              .map((suspect, idx) => (
                                <SuspectCard
                                  suspect={suspect}
                                  patient={patient}
                                  idx={idx}
                                  updateParent={init}
                                  filterOutActives={isActiveSuspect}
                                />
                              ))}
                        </Row>
                      </>
                    ) : (
                      <></>
                    )
                  }
                />
              </Card.Body>
            </Card>
          )}
          <ManageVisitlModal
              show={showEvidenceModal && ! auth.hasAuthority("AUTH_PROVIDER_MANAGE_VISIT") }
              close={closeEvidenceModalMain}
              patient={patient}
              navigationItems={navigationItems}
              activeKey={activeKey}
              setChangesMade={setChangesMade}
              init={init}
              setConfirmationMessage={setConfirmationMessage}
              confirmationMessage={confirmationMessage}
              featureFlagCIS={featureFlagCIS}
          />
        <PatientDetails
          show={auth.hasAuthority("AUTH_PROVIDER_MANAGE_VISIT") && showPatientDetails }
          setShow={setShowPatientDetails}
        />

        </QuickListContext.Provider>
      </Container>
    </Fragment>
  );
};

export { PreventiveCare };
