import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { handleError } from "../../../../actions/caremgmt/myWorkspaceActions";
import { getCategories, getOutcomes, getScheduleWithOptions, getTypes, submitFollowUp } from "./util";
import EQHErrorToast from "../../../../components/EQHErrorToast";
import SuccessToast from "../../../common/SuccessToast";

const componentName = "FollowUpModal";

export const FollowUpModal = ({
    showFollowUp,
    setShowFollowUp,
    memberData
}) => {
    const [formData, setFormData] = useState({});
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [scheduledWithOptions, setScheduledWithOptions] = useState([]);
    const [outcomeOptions, setOutcomeOptions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [disableDropDowns, setDisableDropDowns] = useState(true);
    const [success, setSuccess] = useState(null);
    const isEmpty = require("is-empty");

    const onError = (err) => {
        const error = handleError(err)
        setErrors([error]);
    };

    const handleCancel = () => {
        setFormData({})
        setShowFollowUp(false);
    }

    const handleFollowUpSubmit = () => {
        if (validateForm()) {
            submitFollowUp(formData).then((resp) => {
                setSuccess({ message: "Follow Up submited successfuly" });
                handleCancel();
            }).catch((error) => {
                onError(error);
            });
        } else {
            setErrors(["Some mandatory fields are empty"]);
        }
    }

    const validateForm = () => {
        return !isEmpty(formData.patientId) &&
            !isEmpty(formData.category) &&
            !isEmpty(formData.type) &&
            !isEmpty(formData.outcome)
    }

    const handleCategoryChanged = (value) => {
        setFormData({
            ...formData,
            category: value,
            type: "",
            scheduledWith: "",
            outcome: ""
        });
        if (value) {
            getTypes().then((resp) => {
                if (resp?.data) {
                    const dataByKey = resp.data.filter(d => d?.key === value);
                    setTypes(dataByKey);
                    setDisableDropDowns(false);
                }
            }).catch((error) => {
                onError(error);
            });

            getScheduleWithOptions().then((resp) => {
                if (resp?.data) {
                    const dataByKey = resp.data.filter(d => d?.key === value);
                    setScheduledWithOptions(dataByKey);
                }
            }).catch((error) => {
                onError(error);
            });

            getOutcomes().then((resp) => {
                if (resp?.data) {
                    const dataByKey = resp.data?.filter(d => d?.key === value);
                    setOutcomeOptions(dataByKey);
                }
            }).catch((error) => {
                onError(error);
            });
        } else {
            setDisableDropDowns(true);
        }
    }

    const handleTypeChanged = (value) => {
        setFormData({ ...formData, type: value });
    }

    const handleOutcomeChanged = (value) => {
        setFormData({ ...formData, outcome: value });
    }

    const handleScheduledWithChanged = (value) => {
        setFormData({ ...formData, scheduledWith: value });
    }

    useEffect(() => {
        if (showFollowUp) {
            setFormData({
                patientId: memberData?.patientId
            });
            setDisableDropDowns(true);
            getCategories().then((resp) => {
                if (resp?.data) {
                    setCategories(resp.data);
                }
            }).catch((error) => {
                onError(error);
            });
        } else {
            setErrors([]);
        }

    }, [showFollowUp]);

    return (
        <Modal
            size="xl"
            centered show={showFollowUp}
            onHide={handleCancel}
        >
            <Modal.Header>
                <Modal.Title id={`${componentName}-FollowUp-ModalTitle`}>
                    Member Follow-Up
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
                {success?.message && <SuccessToast />}
                <Row className="d-flex justify-content-center align-itmes-end">
                    <Col lg={3} md={6} className="mb-3">
                        <label className="d-block">Category<span className="text-danger">*</span></label>
                        <select id={`${componentName}-category`}
                            onChange={(e) => handleCategoryChanged(e?.target?.value)}
                            value={formData?.category} className="form-select">
                            <option value=""></option>
                            {categories?.map((f, i) =>
                                <option id={`category-${i}`} value={f.value}>{f.value}</option>
                            )}
                        </select>
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                        <label className="d-block">Type<span className="text-danger">*</span></label>
                        <select id={`${componentName}-type`} disabled={disableDropDowns}
                            onChange={(e) => handleTypeChanged(e?.target?.value)}
                            value={formData.type} className="form-select">
                            <option value=""></option>
                            {types?.map((f, i) =>
                                <option id={`type-${i}`} value={f.value}>{f.value}</option>
                            )}
                        </select>
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                        <label className="d-block">Scheduled With</label>
                        <select id={`${componentName}-scheduled-with`} disabled={disableDropDowns || formData?.category == 'SDOH'}
                            onChange={(e) => handleScheduledWithChanged(e?.target?.value)}
                            value={formData.scheduledWith} className="form-select">
                            <option value=""></option>
                            {scheduledWithOptions?.map((f, i) =>
                                <option id={`scheduled-with-${i}`} value={f.value}>{f.value}</option>
                            )}
                        </select>
                    </Col>
                    <Col lg={3} md={6} className="mb-3">
                        <label className="d-block">Outcome<span className="text-danger">*</span></label>
                        <select id={`${componentName}-outcome`} disabled={disableDropDowns}
                            onChange={(e) => handleOutcomeChanged(e?.target?.value)}
                            value={formData.outcome} className="form-select">
                            <option value=""></option>
                            {outcomeOptions?.map((f, i) =>
                                <option id={`outcome-${i}`} value={f.value}>{f.value}</option>
                            )}
                        </select>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    id={`${componentName}-CancelBtn`}
                    variant="secondary"
                    onClick={handleCancel}
                >Cancel</Button>
                <Button id={`${componentName}-saveFollowUp`} variant="primary"
                    onClick={handleFollowUpSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}