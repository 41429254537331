import React, { useEffect, useState, useCallback } from "react";
import {useHistory} from 'react-router-dom';
import { Button, Badge, Toast, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useAuth } from "../../../context/authContext";
import axios from "../../../utils/apiClient";
import { CMWorklistProvider } from "../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import {
    formatDate,
    getPatientProfileLink,
    logDebug,
    getRiskLevelStyle,
    _calculateAge
} from "../../../utils/util";
import cloneDeep from "lodash/cloneDeep";
import { useLocation } from "react-router";
import { getDefaultData as careOppPkgDefault,
} from "../ManageEvidence/CareOpportunitiesAccordion";
import {
    getDefaultData as validationPkgDefault,
} from "../ManageEvidence/ValidationComponent";
import ManageVisitComponent from "./info/ManageVisitComponent";
import SubmitReferralBody from "../SubmitReferral";
import Modals from "../../../components/modals";
import EQHErrorToast from "../../../components/EQHErrorToast";
import { ManageVisitProvider } from "./context/ManageVisitContext/ManageVisit.context";
import { useDispatch, useSelector } from "react-redux";
import AddSuspectConditionsModal from "./info/AddSuspectConditionsModal";
import { referralTypes } from "./patientList";
import { PrevisitChart } from "./modals/PrevisitChart";
import QuarterlyVisitDueIcon from "../../../img/quarterlyvisitdue-icon.svg";
import {AppPropertiesUtil} from "../../../utils/localStorageUtil";
import CareCoordinationReferral from "./modals/Referrals/CareCoordinatorReferral/CareCoordinationReferral";
import SpecialistReferral from "./modals/Referrals/SpecialistReferral/SpecialistReferral";
import {ReferralsProvider} from "./modals/Referrals/context/Referrals.context";
import { isCBOAccessAllowed } from "../../caremgmt/worklist/constants";
import { removeMinimizedPatient, setMinimizedPatients } from "../../../actions/manageVisitActions.js";
import { temporarilyHidePGS } from "../../../actions/populationSearch/populationSearchActions.js";
import useCboDropdown from "../../../actions/hooks/useCboDropdown.js";

const DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO = {
    bloodPressureSystolic: null,
    bloodPressureDiastolic: null,
    a1cWholes: null,
    a1cDecimal: null,
    note: "",
    uploadedFiles: [],
    careGapAdditionalInfos: {},
};

const getDefaultPreventiveCareUpdateResultsDto = () => {
    return cloneDeep(DEFAULT_PREVENTIVE_CARE_UPDATE_RESULTS_DTO);
};

const componentName = "patientDetails";

export const PatientDetails = ({ show, setShow }) => {
    const enableVistDue = AppPropertiesUtil.get("react.properties.ecip.eligible.enabled");
    const auth = useAuth();
    const roles = auth.getRoles();
    const dispatch = useDispatch();
    const authorizedGapRead = auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS");
    const authorizedGapWrite = auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS");
    const patient = useSelector((state) => state?.manageVisitReducer?.patient);
    const isCareProgramModalOpen = useSelector((state) => state?.manageVisitReducer?.isCareProgramModalOpen);
    const hidePatientDetailsModal = useSelector((state) => state?.manageVisitReducer?.hidePatientDetailsModal);
    const addNewAsessmentModal = useSelector((state) => state?.manageVisitReducer?.addNewAssessment);
    const snoozedModal = useSelector((state) => state?.manageVisitReducer?.snoozedModal);
    const addNewAppointmentModal = useSelector((state) => state?.manageVisitReducer?.addNewAppointment);
    const modalOpen = useSelector((state) => state?.manageVisitReducer?.modalOpen);
    const minimizedPatients = useSelector((state) => state?.manageVisitReducer?.minimizedPatients);
    const isAddingNewNote = useSelector((state) => state?.manageVisitReducer?.isAddingNewNote);
    const showOldPatientModal = useSelector((state) => state?.manageVisitReducer?.showOldPatientModal);

    const [showCareCoordinationReferral, setShowCareCoordinationReferral] = useState(false);
    const [showSpecialistReferral, setShowSpecialistReferral] = useState(false);

    const featureForUniteUS = AppPropertiesUtil.get("react.properties.uniteUs.feature.enabled");
    const featureForFindHelp = AppPropertiesUtil.get("react.properties.findHelp.feature.enabled");

    const featureFlagCIS =  AppPropertiesUtil.get("cis.immunizations.enabled")

    useEffect(() => {
        const offcanvasElement = document.getElementById('patientDetailsModal');
        offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffcanvas(false));
        offcanvasElement.addEventListener('shown.bs.offcanvas', () => handleOffcanvas(true));
        return () => {
            offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffcanvas);
            offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffcanvas);
        };
    }, []);

    const handleOffcanvas = (state) => {
        setShow(state)
        dispatch(temporarilyHidePGS(state))
    };

    const authorizedGapValidate = auth.hasAuthority(
        "AUTH_CARE_OPPORTUNITY_VALIDATION_ACCESS"
    );
    const authorizedRafValidate = auth.hasAuthority(
        "AUTH_RAFS_SUSPECT_VALIDATION_ACCESS"
    );
    const baseURLPC =
        process.env.REACT_APP_REST_API_BASE_URL + "/v1/manage-preventive-care";

    useEffect(() => {
        setShowFullHistory(!show)
    }, [show])

    //State for suspects / rafs
    const [selectedSuspects, setSelectedSuspects] = useState({
        ids: [],
        conditions: [],
    });
    const [navigationParams, setNavigationParams] = useState({
        id: null,
        type: null,
    });
    const [suspectForm, setSuspectForm] = useState({
        status: { source: "", translationKey: "", closesCondition: true, name: "" },
        dateOfService: null,
    });

    const [validationPkgResetKey, setValidationPkgResetKey] = useState(null);

    const [attestationDDLOptions, setAttestationDDLOptions] = useState([]);
    const [requireA1c, setRequireA1c] = useState(false);
    const [requireDocumentation, setRequireDocumentation] = useState(false);
    const [requirePressure, setRequirePressure] = useState(false);
    const [visitDate, setVisitDate] = useState(null);
    const [careOpportunityPkg, setCareOpportunityPkg] = useState(() =>
        careOppPkgDefault(visitDate)
    );
    const [tocPkg, setTOCPkg] = useState(() => careOppPkgDefault(visitDate));
    const [validationPkg, setValidationPkg] = useState(() =>
        validationPkgDefault(visitDate)
    );

    const [documentation, setDocumentation] = useState([]);
    const [requireUpload, setRequireUpload] = useState(false);
    const [requireAttestation, setRequireAttestation] = useState(false);
    const [showCPT, setShowCPT] = useState(false);
    const [showFullHistory, setShowFullHistory] = useState(false);
    //State for genreal and shared functionality
    const [isSearching, setIsSearching] = useState(false);
    const [errors, setErrors] = useState([]);
    const [update, setUpdate] = useState(false);
    // const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [bloodPressureError, setBloodPressureError] = useState(false);
    const [ac1ValueError, setAc1ValueError] = useState(false);
    const [suspectDosError, setSuspectDosError] = useState(null);
    const [dataResponse, setDataResponse] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [
        processValidationPkgAddToSelecedItemParamsKey,
    ] = useState(null);

    const [isShowHistoryEnabled, setShowHistoryEnabled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const offcanvasElement = document.getElementById('patientDetailsModal');
        offcanvasElement.addEventListener('hidden.bs.offcanvas', () => handleOffCanvas(false));
        offcanvasElement.addEventListener('shown.bs.offcanvas', () => handleOffCanvas(true));
        return () => {
          offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffCanvas);
          offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffCanvas);
        };
    }, []);

    const formatErrorMessage = (error) => {
        if (error?.response?.data?.message?.includes("|")) {
            var errors = error?.response?.data?.message.split("|");
            setErrors(errors);
        } else {
            setErrors([error?.response?.data?.message]);
        }
    };

    const [preventiveCareUpdateResultsDto, setPreventiveCareUpdateResultsDto] =
        useState(() => getDefaultPreventiveCareUpdateResultsDto());

    const [renderValidationData, setRenderValidationData] = useState(false)
    const validatePatientEvidence = (clearData, ignoreConfirmationWarnings = false) => {
        setRenderValidationData(false)
        setIsUpdating(true);
        try {
            careOpportunityPkg.finalizeForBackend();
            tocPkg.finalizeForBackend();
            validationPkg.finalizeForBackend();
            const dto = {
                ...preventiveCareUpdateResultsDto,
                patientLastUpdated: dataResponse.patientLastUpdated,
                visitDate: visitDate ? visitDate : null,
                patientId: patient?.id,
                updateCareGapActionDto: careOpportunityPkg.selectedGaps.length ? careOpportunityPkg.updateCareGapDto : null
                ,
                updateTOCCareGapActionDto: tocPkg.selectedGaps.length
                    ? tocPkg.updateCareGapDto
                    : null,
                updateValidationCareGapActionDto:
                    validationPkg.selectedItems.length &&
                        validationPkg.validationType === "Opportunity"
                        ? validationPkg.updateDto
                        : null,
                updateValidationConditionActionDto:
                    validationPkg.selectedItems.length &&
                        validationPkg.validationType === "Suspect"
                        ? validationPkg.updateDto
                        : null,
                updateConditionActionDto: selectedSuspects.ids.length
                    ? {
                        ids: selectedSuspects.ids,
                        visitDate: visitDate ? visitDate : null,
                        newStatus: suspectForm.status,
                        validation: null,
                        newDateOfService: selectedSuspects.ids
                            ? suspectForm.dateOfService
                            : null,
                        icd10DiseaseDto: null,
                        forPreviousYear: false,
                    }
                    : null,
            };
            logDebug(() => dto);

            axios
                .post(baseURLPC + `/update`, dto, {
                    headers: { "Content-Type": "application/json" },
                })
                .then(async (response) => {
                    setRenderValidationData(true)
                    if (clearData) {
                        clearData();
                    }
                    setUpdate(oldValue => !oldValue)
                })
                .catch((error) => {
                    logDebug(() => error);
                    formatErrorMessage(error);
                });
        } catch (e) {

        }
    };

    const checkForAttestation = (
        careOpportunityGaps,
        careOpportunityHasMarkForClosure,
        tocGaps,
        tocHasMarkForClosure
    ) => {
    };

    const applyConditionalRequirements = (
        careOpportunityPkg,
        tocPkg,
        validationPkg,
        selectedSuspects,
        suspectForm
    ) => {
        const obj = {
            requireUpload: false,
            requireA1c: false,
            requirePressure: false,
            requireAttestation: false,
            requireDocumentation: false,
        };

        const careOpportunityHasMarkForClosure =
            careOpportunityPkg.updateCareGapDto?.newStatus?.name ===
            "Marked for Closure";
        const tocHasMarkForClosure =
            tocPkg.updateCareGapDto?.newStatus?.name === "Marked for Closure";
        const formHasMarkForClosure =
            careOpportunityHasMarkForClosure || tocHasMarkForClosure;

        const commonGapChecksAndReturnIsExclusion = (
            gap,
            exclusionCriteria,
            obj
        ) => {
            if (!exclusionCriteria.some((x) => x.gapId === gap.careGapSummary.id)) {
                if (formHasMarkForClosure) {
                    if (gap.careGapSummary.requireBloodPressure) {
                        obj.requirePressure = true;
                    }
                    if (gap.careGapSummary.requireA1C) {
                        obj.requireA1c = true;
                    }
                    if (gap.careGapSummary.requireFileUpload) {
                        obj.requireUpload = true;
                    }
                }
                return false;
            } else {
                return true;
            }
        };

        tocPkg.selectedGaps.forEach((trans) => {
            if (
                !commonGapChecksAndReturnIsExclusion(
                    trans,
                    tocPkg.updateCareGapDto.exclusionCriteria,
                    obj
                )
            ) {
                // if (trans.careGapSummary.requireFileUpload) {
                //   obj.requireUpload = true;
                // }
            }
        });

        careOpportunityPkg.selectedGaps.forEach((gap) => {
            commonGapChecksAndReturnIsExclusion(
                gap,
                careOpportunityPkg.updateCareGapDto.exclusionCriteria,
                obj
            );
        });

        //Suspects
        if (suspectForm?.status?.name === "Confirmed Diagnosis") {
            obj.requireUpload = true;
        }

        if (
            (careOpportunityPkg.selectedGaps?.length ||
                careOpportunityPkg.additionalGapIdsSelected?.length ||
                selectedSuspects.ids?.length ||
                validationPkg.selectedItems?.length ||
                tocPkg.selectedGaps?.length ||
                tocPkg.additionalGapIdsSelected?.length) &&
            (isShowHistoryEnabled || showFullHistory)
        ) {
            setShowFullHistory(true);
        } else {
            setShowFullHistory(false);
        }

        const attestationDDLOptions = checkForAttestation(
            careOpportunityPkg.selectedGaps,
            careOpportunityHasMarkForClosure,
            tocPkg.selectedGaps,
            tocHasMarkForClosure
        );
        const documentation = checkForDocumentation(
            careOpportunityPkg.selectedGaps,
            careOpportunityHasMarkForClosure,
            tocPkg.selectedGaps,
            tocHasMarkForClosure
        );
        if (attestationDDLOptions?.length) {
            obj.requireAttestation = true;
        }
        if (documentation?.length) {
            obj.requireDocumentation = true;
        }

        setRequireDocumentation(obj.requireDocumentation);
        setRequireUpload(obj.requireUpload);
        setRequireA1c(obj.requireA1c);
        setRequirePressure(obj.requirePressure);
        setRequireAttestation(obj.requireAttestation);
    };

    const checkForDocumentation = (
        careOpportunityGaps,
        careOpportunityHasMarkForClosure,
        tocGaps,
        tocHasMarkForClosure
    ) => {
        let documentation = [];
        const gaps = [
            ...(careOpportunityHasMarkForClosure ? careOpportunityGaps : []),
            ...(tocHasMarkForClosure ? tocGaps : []),
        ];
        if (gaps.length) {
            gaps.forEach((gap) => {
                if (gap.careGapSummary.requiredDocumentation) {
                    documentation.push({
                        documentation: gap.careGapSummary.requiredDocumentation,
                        description: "Required for " + gap.careGapSummary.name,
                    });
                }
            });
        }
        setDocumentation(documentation);
    };

    const callUpdatesMade = (
        careOpportunityPkg,
        tocPkg,
        validationPkg,
        selectedSuspects
    ) => {
        if (
            careOpportunityPkg.selectedGaps.length ||
            tocPkg.selectedGaps.length ||
            validationPkg.selectedItems.length ||
            selectedSuspects.ids.length
        ) {
            //updatesMade(true);
        } else {
            // updatesMade(false);
        }
    };

    const handlePkgChange = (pkg, key) => {
        setTemporarilyDisableSubmitButton(true)
        const pkgReference = {
            careOpportunityPkg,
            tocPkg,
            validationPkg,
        };
        if (key === "careOpportunityPkg") {
            pkgReference.careOpportunityPkg = pkg;
            setCareOpportunityPkg(pkg);
        } else if (key === "tocPkg") {
            pkgReference.tocPkg = pkg;
            setTOCPkg(pkg);
        } else if (key === "validationPkg") {
            pkgReference.validationPkg = pkg;
            setValidationPkg(pkg);
        }

        callUpdatesMade(
            pkgReference.careOpportunityPkg,
            pkgReference.tocPkg,
            pkgReference.validationPkg,
            selectedSuspects
        );

        applyConditionalRequirements(
            pkgReference.careOpportunityPkg,
            pkgReference.tocPkg,
            pkgReference.validationPkg,
            selectedSuspects,
            suspectForm
        );
    };

    const isEmpty = require("is-empty");
    const [temporarilyDisableSubmitButton, setTemporarilyDisableSubmitButton] = useState(false)
    const enableButton = () => {
        const suspectStatus = suspectForm.status;
        const suspectDateOfService = suspectForm.dateOfService;

        const suspectsLength = selectedSuspects.ids.length;
        const suspectErrors = [];
        const errors = [];

        if (suspectsLength) {
            if (!suspectStatus.name.length || !suspectDateOfService) {
                suspectErrors.push("Suspect Date of Service is Required");
            }
            if (suspectDosError) {
                suspectErrors.push("Suspect Date of Service is invalid");
            }
        }

        //Parent Form
        if (
            !careOpportunityPkg.selectedGaps?.length &&
            !tocPkg.selectedGaps?.length &&
            !validationPkg.selectedItems?.length &&
            !suspectsLength
        ) {
            errors.push(
                "Care Opportunity, TOC, Validation, or Suspect must be selected"
            );
        }
        if (
            requireA1c &&
            (!preventiveCareUpdateResultsDto.a1cWholes ||
                !preventiveCareUpdateResultsDto.a1cDecimal)
        ) {
            errors.push("A1C is Required");
        }
        if (ac1ValueError || bloodPressureError) {
            errors.push("Blood Pressure is Required");
        }
        if (
            attestationDDLOptions?.length &&
            !attestationDDLOptions.every(
                (x) =>
                    preventiveCareUpdateResultsDto.careGapAdditionalInfos[x.gapId]?.id || preventiveCareUpdateResultsDto.careGapAdditionalInfos[x.gapId]?.length
            )

        ) {
            errors.push("Provider Attestation is Required");
        }
        if (
            requireUpload &&
            !preventiveCareUpdateResultsDto.uploadedFiles?.length
        ) {
            errors.push("File Upload is Required");
        }

        if (
            isEmpty(careOpportunityPkg.errors) &&
            isEmpty(tocPkg.errors) &&
            isEmpty(validationPkg.errors) &&
            isEmpty(suspectErrors) &&
            isEmpty(errors)
        ) {
            return true;
        } else {
            return false;
        }
    }

    const [showReferralModal, setShowReferralModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [referComponent, getSamlResponse, setSamlResponse] = useCboDropdown(patient?.clientId, patient);
    const history= useHistory();
    const [showOverlay, setShowOverlay] = useState(false);
    const [allowMinimize, setAllowMinimize] = useState(true);

    const handleOffCanvas = (state) => {
        setShowOverlay(state)
        sessionStorage.removeItem('redirectTab');
        sessionStorage.removeItem('itemID');
    }
    const refreshReferrals = (data) => {
        setShowReferralModal(false);
        //updateParent();
    };

    const handleReferralModal = (patient, type) => {
        if (type) {
            setSelectedType(type);
        }
        setShowReferralModal(true);
    };

    const handleReferralModalOption = (referralType) => {
        if (referralTypes[0].value === referralType.value) {
            setShowSpecialistReferral(true);
        }
        if (referralTypes[1].value === referralType.value) {
            setShowCareCoordinationReferral(true);
        }
    };

    const handleProfileClick = useCallback((profileLink) => {
        history.push(profileLink);
        setShow(false);
        window.location.reload();
    }, [history]);

    useEffect(() => {
        const minimizedIds = minimizedPatients.map(m => m.id)
        const patientId = patient?.id
        setAllowMinimize(minimizedIds?.indexOf(patientId) >= 0 || minimizedPatients.length < 5)
    }, [minimizedPatients, patient?.id])

    return (
        <>
            <div
                className={`offcanvas offcanvas-end patientDetailsOffcanvas border-top`}
                tabIndex="-1"
                id="patientDetailsModal"
                aria-labelledby="patientModalLabel"
                style={{ visibility: (showReferralModal || showAddModal || isCareProgramModalOpen ||
                        hidePatientDetailsModal || modalOpen || showCareCoordinationReferral ||
                        showSpecialistReferral || addNewAsessmentModal || addNewAppointmentModal || snoozedModal || isAddingNewNote || showOldPatientModal) ? 'hidden' : 'visible' }}
            >
                <CareCoordinationReferral
                    setShowModal={setShowCareCoordinationReferral}
                    showModal={showCareCoordinationReferral}
                    patient={patient}
                />
                <ReferralsProvider showSpecialistReferral={showSpecialistReferral}
                                   toggleShowSpecialistReferral={setShowSpecialistReferral}
                                   patient={patient}>
                    <SpecialistReferral/>
                </ReferralsProvider>
                <Modals
                    scrollable={true}
                    referralHeight={true}
                    show={showReferralModal}
                    closeSubmitModal={() => setShowReferralModal(false)}
                    onHide={() => {
                        setShowReferralModal(false);
                        //updateParent();
                    }}
                    size="xl"
                    title="Submit Referral"
                    titleid={`${componentName}-SubmitReferralModal`}
                    completeBtn="SUBMIT"
                    completeBtnId={`${componentName}-completeSubmit`}
                    hideFooter={true}
                    body={
                        <SubmitReferralBody
                            patient={patient}
                            closeSubmitModal={() => setShowReferralModal(false)}
                            addNewReferral={refreshReferrals}
                            preSelectedType={selectedType}
                            showReferralModal={showReferralModal}
                        />
                    }
                />
                <div className="offcanvas-header flex-wrap-reverse gap-2">
                    <div className="d-flex flex-wrap d-md-block">
                        <div className="d-flex align-items-center me-3 me-md-0">
                            <h5 className="offcanvas-title nowrap d-inline-block me-3 forced-capital" id="patientModalLabel">
                                {patient?.fullName ? patient?.fullName : patient?.lastName + ', ' + patient?.firstName}
                            </h5>
                            <Badge pill id="patientRiskLevel" bg={getRiskLevelStyle(patient?.riskLevel)}>
                                {patient?.riskLevel}
                            </Badge>
                            {enableVistDue && patient?.ecipEligible && (
                                <OverlayTrigger placement="auto" overlay={<Tooltip>Quarterly Visit Eligible</Tooltip>}>
                                    <img src={QuarterlyVisitDueIcon} alt="Quarterly Incentive Icon" width="20px" className="ms-2"></img>
                                </OverlayTrigger>
                            )}
                            {patient?.practiceLevelEHR && (
                                <Badge pill id="patientPracticeLevelEHR" bg="success" className="ms-2">
                                    Connected to EHR
                                </Badge>
                            )}
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                            <small id="patientMemberID" className="nowrap me-3">
                                <span id="patientMemberIDLabel" className="fw-bold me-2">ID:</span> <span id="patientMemberIDValue">{patient?.memberId}</span>
                            </small>
                            <small id="patientDOB" className="nowrap me-3">
                                <span id="patientDOBLabel" className="fw-bold me-2">DOB: </span>
                                <span id="patientDOBValue">
                                    {formatDate(patient?.dateOfBirth)} <Badge pill bg="light" text="dark" className="border p-1">{_calculateAge(patient?.dateOfBirth)}</Badge>
                                </span>
                            </small>
                            <div id="patientFullProfile" className="d-inline-block">
                                <a href={getPatientProfileLink(patient?.id, location)} 
                                    onClick={(e) => { e.preventDefault(); handleProfileClick(getPatientProfileLink(patient?.id, location)); }}
                                    className="badge bg-primary rounded-pill cursor-p">
                                        Full Profile <i className="fa-regular fa-arrow-up-right-from-square ms-2"></i>
                                </a>
                            </div>
                        </div>
                        <div>
                            {errors && errors.length ? (
                                <EQHErrorToast
                                    className="float-end"
                                    errors={errors}
                                    handleClose={() => setErrors([])}
                                />
                            ) : null}
                            <Toast
                                id={`${componentName}-successToast`}
                                show={showSuccessMsg}
                                onClose={() => setShowSuccessMsg(false)}
                            >
                                <Toast.Header>
                                    <strong
                                        id={`${componentName}-successHeader`}
                                        className="me-auto text-success"
                                    >
                                        Success
                                    </strong>
                                    <small id={`${componentName}-successUpdated`}>
                                        Successfully Updated
                                    </small>
                                </Toast.Header>
                            </Toast>
                        </div>
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-between justify-content-md-end">
                        <div>
                            <div id={`${componentName}-patientActions`} className="d-inline position-relative me-3">
                                <button id={`${componentName}-patientActionsIcon`} type="button"  onClick={()=>getSamlResponse((response) => setSamlResponse(response.data))} className="d-inline border btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
                                    Refer
                                </button>
                                <ul id={`${componentName}-patientActionsDropdown`} className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                        <button id={`${componentName}-referToSpecialistAppLink`} onClick={() => handleReferralModalOption(referralTypes[1])} className="dropdown-item" eventKey="0">
                                            Care Coordination
                                        </button>
                                    </li>
                                    <li>
                                        <button id={`${componentName}-referToCareCoordinationAppLink`} onClick={() => handleReferralModalOption(referralTypes[0])} className="dropdown-item" eventKey="0">
                                            Specialist
                                        </button>
                                    </li>
                                    <li>
                                        {isCBOAccessAllowed(roles) && referComponent()}
                                    </li>
                                </ul>
                            </div>
                            <Button variant="secondary" id={`${componentName}-patientChartPrepTool`} onClick={useCallback(()=>{setShowCPT(true)},[showCPT, patient])} className="border me-3">
                                Print Chart Prep Tool <i className="fa-regular fa-print ms-2"></i>
                            </Button>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <OverlayTrigger
                                placement="left"
                                overlay={!allowMinimize ? <Tooltip>Max number of open exploration tabs has been reached.</Tooltip> : <></>}
                            >
                                <i
                                    className="fa fa-window-minimize text-reset"
                                    data-bs-dismiss={allowMinimize ? "offcanvas" : ''}
                                    aria-label="Close"
                                    onClick={() => {
                                        if (allowMinimize && minimizedPatients.length < 5) {
                                            dispatch(setMinimizedPatients(patient))
                                        }
                                    }}
                                    style={{
                                        marginBottom: '1px',
                                        opacity: '70%',
                                        height: '22px',
                                        cursor: allowMinimize ? 'pointer' : 'not-allowed'
                                    }}
                                ></i>
                            </OverlayTrigger>
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                id="close-patientDetails"
                                aria-label="Close"
                                onClick={() => {
                                    setShow(false)
                                    dispatch(removeMinimizedPatient(patient))
                                    dispatch(temporarilyHidePGS(false))
                                }}
                            ></button>
                        </div>
                    </div>
                    <PrevisitChart show={showCPT} onHide={() => setShowCPT(false)} patientLoaded={patient}/>
                </div>
                <div className="offcanvas-body border-top p-0 overflow-hidden">
                    <CMWorklistProvider>
                        <ManageVisitProvider>
                            <AddSuspectConditionsModal
                                patient={patient}
                                setShow={setShowAddModal}
                            />
                            <ManageVisitComponent
                                patient={patient}
                                renderValidationData={renderValidationData}
                                show={show}
                                setShow={setShow}
                                tocPkg={tocPkg}
                                selectedSuspects={selectedSuspects}
                                handleReferralModal={handleReferralModal}
                                navigationParams={navigationParams}
                                validationPkgResetKey={validationPkgResetKey}
                                dataResponse={dataResponse}
                                isSearching={isSearching}
                                componentName={componentName}
                                handlePkgChange={handlePkgChange}
                                authorizedGapWrite={authorizedGapWrite}
                                validatePatientEvidence={validatePatientEvidence}
                                authorizedGapRead={authorizedGapRead}
                                authorizedGapValidate={authorizedGapValidate}
                                authorizedRafValidate={authorizedRafValidate}
                                careOpportunityPkg={careOpportunityPkg}
                                processValidationPkgAddToSelecedItemParamsKey={processValidationPkgAddToSelecedItemParamsKey}
                                enableButton={enableButton}
                                temporarilyDisableSubmitButton={temporarilyDisableSubmitButton}
                                setDataResponse={setDataResponse}
                                featureFlagCIS={featureFlagCIS}
                            />
                        </ManageVisitProvider>
                    </CMWorklistProvider>
                </div>
            </div>
        </>
    );
};

export default PatientDetails;