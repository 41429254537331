import React, { useEffect, useState } from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../../utils/apiClient";
import { Button } from "../../components/buttons";
import { Row, Col, Alert, Dropdown } from "react-bootstrap";
import {CustomMenuStyled, DropDownButton,} from "../../components/dropDownLists/sharedDDL";
import Modals from "../../components/modals";
import ProviderSearch from "./ProviderSearch";
import "react-bootstrap-typeahead/css/Typeahead.css";
import styled from "styled-components";
import { updateFile, clearFileUploads } from "../../actions/fileUploadActions";
import { useAuth } from "../../context/authContext";
import { useSelector, useDispatch } from "react-redux";
import { convertDateToString } from "../../utils/util";
import Toast from "react-bootstrap/Toast";

import {neededServices} from "../../utils/textValueLists";
import {FileUpload} from "../../components/FileUpload";
import {EQHSpinner} from "../../components/EQHSpinner";
import {PrimaryDDL} from "../../components/dropDownLists/primaryDDL";
import {getRecommendedTeams} from "../../actions/patientProfileActions";
import {DDLMode, DropDownList} from "../../components/dropDownLists/genericDDL";
import {setUserEventAction} from "../../actions/manageVisitActions";
import useCboDropdown from "../../actions/hooks/useCboDropdown";
import { isCBOAccessAllowed } from "../caremgmt/worklist/constants";

const baseURLSR =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/submit-referral";

const baseURLICD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/icd10";

const componentName = "SubmitReferral";

const otherServiceValue = "OTHER";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const types = [
  { text: "Consult, Evaluate, and Treat", value: "CONSULT_EVALUATE_TREAT" },
  { text: "Consultation", value: "CONSULTATION" },
  { text: "Evaluation and Treatment", value: "EVALUATION_AND_TREATMENT" },
  { text: "Second Opinion", value: "SECOND_OPINION" },
  {
    text: "Established Patient Follow Up",
    value: "PATIENT_FOLLOW_UP",
  },
];

const urgencyOptions = [
  {
    text: "Emergent: Must respond same business day",
    value: "40_EMERGENT",
  },
  {
    text: "Priority: Must respond within 4-7 business days",
    value: "20_PRIORITY",
  },
  {
    text: "Routine: Must respond within 8-14 business days",
    value: "10_ROUTINE",
  },
];

const referralTypes = [
  { text: "Specialist", value: "SPECIALIST" },
  { text: "Care Coordination (Equality Health)", value: "CARE_COORDINATION" },
];

const SubmitReferralBody = ({
  patient,
  closeSubmitModal,
  addNewReferral,
  preSelectedType,
  showReferralModal
}) => {
  //const { fileObject } = useSelector((state) => state.fileUpload);
  const dispatch = useDispatch();
  const isEmpty = require("is-empty");

  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showProviderModal, setshowProviderModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [temporarilyDisableSubmitButton, setTemporarilyDisableSubmitButton] = useState(false);
  const [options, setOptions] = useState([]);
  const [referralObj, setReferralObj] = useState({
    referralType: "",
    type: null,
    conditions: [{ code: [], description: [] }],
    urgency: urgencyOptions[3],
    visits: 1,
    expiration: null,
    comments: "",
    servicesNeeded: [],
    servicesNeededOtherText: null,
  });
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [icdEvent, setIcdEvent] = useState(null);

  const [selectedReferralType, setSelectedReferralType] = useState(null);
  const [showEmergentMsg, setShowEmergentMsg] = useState(false);

  const auth = useAuth();
  const roles = auth.getRoles();

  const authorizedReferral = auth.hasAuthority("AUTH_REFERRAL_SENT_ACCESS");
  const authorizedCareCoordinator = auth.hasAuthority(
    "AUTH_REFERRAL_CARE_COORDINATION_SENT_ACCESS"
  );
  const [recommendedTeams, setRecommendedTeams] = useState([]);
  const [referComponent, getSamlResponse, setSamlResponse] = useCboDropdown(patient?.clientId, patient)


  useEffect(() => {
    let newState = referralObj;

    if (!preSelectedType) {
      if (authorizedCareCoordinator && !authorizedReferral) {
        newState.referralType = referralTypes[1].value;
        setSelectedReferralType(referralTypes[1]);
      } else if (!authorizedCareCoordinator && authorizedReferral) {
        newState.referralType = referralTypes[0].value;
        setSelectedReferralType(referralTypes[0]);
      }
    } else {
      newState.referralType = preSelectedType.value;
      setSelectedReferralType(preSelectedType);
    }

    var newDate = new Date();
    var numberOfDaysToAdd = 180;
    newDate = newDate.setDate(newDate.getDate() + numberOfDaysToAdd);
    newState.expiration = newDate;
    setReferralObj(newState);
    getSamlResponse((response) => setSamlResponse(response.data))
  }, []);

  useEffect(() => {
    if (showReferralModal) {
      getRecommendedTeams((response) => {
        setRecommendedTeams(response.data)
      });
    }
  }, [showReferralModal]);

  const handleTypeSelection = (type) => {
    setSelectedReferralType(type);
    setReferralObj({ ...referralObj, referralType: type.value });
  };

  const asyncCodeSearch = (query) => {
    setIsLoadingComp(true);
    axios
      .get(
        baseURLICD + `/find-by-code?code=${query}&offset=${1}&pageSize=${30}`,
        { headers: {} }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        await setOptions(newOptions);
        setIsLoadingComp(false);
      })
      .catch((error) => {
        setIsLoadingComp(false);
      });
  };

  const asyncDescSearch = (query) => {
    axios
      .get(
        baseURLICD +
          `/find-by-description?description=${query}&offset=${1}&pageSize=${30}`,
        {
          headers: {},
        }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        await setOptions(newOptions);
        setIsLoadingComp(false);
      })
      .catch((error) => {
        setIsLoadingComp(false);
      });
  };

  const handleIcdChangeImpl = (e, index) => {
    let currentIndex = index + 1;
    if (e.length) {
      let newState = referralObj;
      newState.conditions[currentIndex].code.push(e[0].code);
      newState.conditions[currentIndex].description.push(e[0].description);
      setReferralObj({ ...newState });
    } else {
      let newState = referralObj;
      newState.conditions[currentIndex].code = [];
      newState.conditions[currentIndex].description = [];
      setReferralObj({ ...newState });
    }
  };

  const handleIcdChange = (e, index) => {
    let regex = /^F1[0-9]+.*$/;
    if (e.length != null && e.length > 0 && regex.test(e[0].code)) {
      setIcdEvent({ code: e, index });
    } else {
      handleIcdChangeImpl(e, index);
    }
  };

  const handleUploadFileChange = (arr) => {
    setUploadedFiles(arr);
  };

  const handleChange = (name, value) => {
    setReferralObj({ ...referralObj, [name]: value });
    if (
      name === "urgency" &&
      selectedReferralType.value === "CARE_COORDINATION" &&
      value.value === "40_EMERGENT"
    ) {
      setShowEmergentMsg(true);
    }
  };

  // useEffect(() => {
  //   if (fileObject) {
  //     setFileAdded(fileObject);
  //   }
  // }, [fileObject]);

  const addNewCondition = () => {
    let length = referralObj.conditions.length;
    let newState = referralObj;
    if (length < 4) {
      let newCon = { code: [], description: [] };
      newState.conditions.push(newCon);
      setReferralObj({ ...newState });
    }
  };

  const removeNewCondition = (index) => {
    let currentIndex = index + 1;
    let newState = referralObj;
    newState.conditions.splice(currentIndex, 1);
    setReferralObj({ ...newState });
  };

  const isFormValid = () => {
    const {
      referralType,
      type,
      conditions,
      urgency,
      visits,
      expiration,
      comments,
      servicesNeeded,
    } = referralObj;
    if (temporarilyDisableSubmitButton) {
      return true
    }
    if (referralType) {
      if (
        referralType === "SPECIALIST" &&
        selectedProvider &&
        type &&
        conditions?.[0]?.code?.length > 0 &&
        conditions?.[0]?.description?.length > 0 &&
        urgency &&
        visits &&
        expiration &&
        uploadedFiles.length
      ) {
        return false;
      } else if (
        referralType === "CARE_COORDINATION" &&
        servicesNeeded.length &&
        isPrimaryServiceSelected(servicesNeeded) &&
        urgency &&
        comments
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const isPrimaryServiceSelected = (servicesNeeded) => {
    if (servicesNeeded && servicesNeeded.length > 0) {
      return servicesNeeded.some(service => service.primary)
    }
    return false;
  }

  const convertIcd10 = () => {
    const newArray = referralObj.conditions;
    const result = [];
    newArray.forEach(function (arrayItem) {
      let newItem = arrayItem;
      newItem.code = newItem.code.toString();
      newItem.description = newItem.description.toString();
      result.push(newItem);
    });
    return result;
  };

  const submitReferral = () => {
    let filteredIcds = convertIcd10();
    let newState = referralObj;
    const userName = auth.getName();
    let finalDate = null;
    if (typeof referralObj.expiration === "number") {
      finalDate = convertDateToString();
    } else {
      finalDate = referralObj.expiration;
    }

    const getFileObj = (file) => {
      return {
        description: null,
        fileMetadata: file,
      };
    };

    const referralType = referralObj.referralType;

    const query = {
      patientId: patient.id,
      userCreated: null,
      referredByOrg: null,
      referredBy: null,
      referredTo: null,
      referredByProvider: null,
      referrerName: userName,
      referredToOrg: null,
      referredToProvider: null,
      referralDueDate: null,
      refereeName: null,
      referredPractice:
        referralType === "SPECIALIST" && selectedProvider.parentPracticeName
          ? selectedProvider.parentPracticeName
          : referralType === "SPECIALIST"
            ? selectedProvider.name
            : null,
      note: null,
      referredToPracticeId:
        referralType === "SPECIALIST" ? selectedProvider.organizationId : null,
      referredToPCPId:
        referralType === "SPECIALIST" && selectedProvider.specialtyProviderId
          ? selectedProvider.specialtyProviderId
          : null,
      visitType: referralType === "SPECIALIST" ? referralObj.type.value : null,
      urgency: referralObj.urgency.value,
      refDateUTC: new Date(Date.now()).toISOString(),
      status: "50_PENDING",
      reason: null,
      sentByFullName: userName,
      referredPractitioner: null,
      statusUpdated: null,
      files:
        referralType === "SPECIALIST" || uploadedFiles.length
          ? uploadedFiles.map(getFileObj)
          : null,
      referredPracticePhone:
        referralType === "SPECIALIST" ? selectedProvider.phone : null,
      referredPracticeAddress:
        referralType === "SPECIALIST" ? selectedProvider.location : null,
      lastModifiedByFullName: null,
      lastModified: null,
      referralComments: referralObj.comments,
      statusComments: null,
      part2Data: true,
      diagnosisICD10dto: filteredIcds,
      senderPracticeName: null,
      recepientPracticeName: null,
      dueDate: null,
      declineSource: null,
      respondedToBy: null,
      visitCount: 0,
      visitExpirationDate: finalDate,
      referralType: referralObj.referralType,
      servicesNeeded: referralObj.servicesNeeded,
      servicesNeededOtherText: referralObj.servicesNeededOtherText,
      teamId: referralObj.recommendedTeam?.teamId
    };
    setTemporarilyDisableSubmitButton(true)
    axios
      .post(baseURLSR + "/save", query, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        newState.conditions = [{ code: [], description: [] }];
        await setReferralObj({ ...newState });
        await dispatch(clearFileUploads());
        await addNewReferral(response.data);
        setFileUploadResetCounter(fileUploadResetCounter + 1);
        closeSubmitModal();
        setTemporarilyDisableSubmitButton(false)
        dispatch(setUserEventAction('REFERRAL_ADDED'));
      })
      .catch((error) => {
        setTemporarilyDisableSubmitButton(false)
      });
  };

  const filterBy = () => true;

  const handleServiceChange = (services) => {
    let objCopy = referralObj;
    objCopy.servicesNeeded = services.map(service => {
      return { service: service.value, primary: service.isPrimary };
    });
    objCopy.servicesNeededOtherText = getOtherServiceText(services);
    setReferralObj({ ...objCopy });
  };

  const getOtherServiceText = (services) => {
    const otherService = services.find(service => service.value === otherServiceValue);
    if (otherService) {
      return otherService.otherItemText;
    }
    return null;
  }

  return (
    <>
      <Modals
        scrollable={true}
        referralHeight={true}
        show={showProviderModal}
        onHide={() => setshowProviderModal(false)}
        size="xl"
        title="Provider Search"
        titleid={`${componentName}-providerSearchModal`}
        completeBtn="SELECT"
        hideFooter={true}
        body={
          <ProviderSearch
            patient={patient}
            updateProvider={setSelectedProvider}
            onHide={() => setshowProviderModal(false)}
          />
        }
      />
      <Modals
        show={!isEmpty(icdEvent)}
        onHide={() => setIcdEvent(null)}
        title={<span>Message</span>}
        size="md"
        hideClickButton={false}
        hideFooter={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        body={
          <span>
            By clicking <b>Confirm</b>, you attest that you have received the
            member's consent to submit a referral related to{" "}
            <b>substance abuse</b> treatment.
          </span>
        }
        onCreate={() => {
          handleIcdChangeImpl(icdEvent.code, icdEvent.index);
          setIcdEvent(null);
        }}
        closeBtn={"Cancel"}
        completeBtn={"Confirm"}
      />
      <div>
        {selectedReferralType ? (
          <>
            {selectedReferralType.value === "SPECIALIST" ? (
              <>
                <p id={`${componentName}-specialistInstruction`}>
                  Identify the provider practice that should receive the referral, and indicate the priority of the visit.
                </p>
                <Row className="mb-3">
                  <Col>
                    <label id={`${componentName}-specialistProviderPractice`} className="d-block">
                      Provider Practice<span className="text-danger">*</span>
                    </label>
                    <span id={`${componentName}-specialistProviderSelected`} className="d-block mb-2">
                      {selectedProvider && selectedProvider.parentPracticeName
                        ? selectedProvider.parentPracticeName +
                        " - " +
                        selectedProvider.name
                        : selectedProvider
                          ? selectedProvider.name
                          : "None Selected"}
                    </span>
                    <span className="d-block">
                      <Button
                        variant="primary"
                        id={`${componentName}-specialistSelectAPractice`}
                        onClick={() => setshowProviderModal(true)}
                      >
                        Select A Provider
                      </Button>
                    </span>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row className="mb-3">
              {selectedReferralType.value === "SPECIALIST" ? (
                <Col sm={6}>
                  <label id={`${componentName}-specialistVisitTypeLabel`}>
                    Visit Type<span className="text-danger">*</span>
                  </label>
                  <Dropdown value={referralObj.type}>
                    <Dropdown.Toggle
                      as={DropDownButton}
                      id={`${componentName}-specialistVisitTypeValue`}
                      cssOpen={isOpen}
                      className="w-100 mb-0 me-0"
                    >
                      {referralObj.type ? (
                        referralObj.type.text
                      ) : (
                        <span>-Select-</span>
                      )}
                      <i className="fa fa-chevron-down" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
                      {types.map((type) => (
                        <Dropdown.Item
                          key={`${componentName}-specialistVisitTypeItem-${type.value}`}
                          id={`${componentName}-specialistVisitTypeItem-${type.value}`}
                          onClick={() => handleChange("type", type)}
                        >
                          {type.text}
                        </Dropdown.Item>
                      ))}

                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              ) : (
                <Col sm={6}>
                  <PrimaryDDL
                    label="Services Needed"
                    handleChange={(e) => handleServiceChange(e)}
                    data={neededServices}
                    otherServiceValue={otherServiceValue}
                    isRequired={true}
                  />
                </Col>
              )}
              <Col sm={3}>
                <label id={`${componentName}-ICD10CodeLabel`}>
                  ICD-10 Code
                  {selectedReferralType.value === "SPECIALIST" ? (
                    <span id={`${componentName}-ICD10CodeRequired`} className="text-danger">*</span>
                  ) : null}
                </label>
                <StyledTypeAhead
                  filterBy={filterBy}
                  inputProps={{
                    id: `${componentName}-ICD10CodeValue`
                  }}
                  labelKey="code"
                  isLoading={isLoadingComp}
                  minLength={2}
                  onSearch={asyncCodeSearch}
                  options={options}
                  selected={referralObj.conditions[0].code}
                  onChange={(e) => handleIcdChange(e, -1)}
                />
              </Col>
              <Col sm={3}>
                <label id={`${componentName}-ICD10DescLabel`}>
                  ICD-10 Description
                  {selectedReferralType.value === "SPECIALIST" ? (
                    <span id={`${componentName}-ICD10DescRequired`} className="text-danger">*</span>
                  ) : null}
                  <i
                    className="fas fa-plus-circle cursor-p ms-1"
                    id={`${componentName}-ICD10DescAddRemove`}
                    onClick={() => addNewCondition()}
                  ></i>
                </label>
                <StyledTypeAhead
                  filterBy={filterBy}
                  inputProps={{
                    id: `${componentName}-ICD10DescValue`
                  }}
                  labelKey="description"
                  isLoading={isLoadingComp}
                  minLength={2}
                  onSearch={asyncDescSearch}
                  options={options}
                  selected={referralObj.conditions[0].description}
                  onChange={(e) => handleIcdChange(e, -1)}
                />
              </Col>
            </Row>
            {referralObj.conditions.length > 1
              ? referralObj.conditions.slice(1).map((con, index) => (
                <Row className="mb-3">
                  <Col sm={{ span: 3, offset: 6 }}>
                    <label id={`${componentName}-ICD10CodeLabel-${index}`}>
                      ICD-10 Code
                      {selectedReferralType.value === "SPECIALIST" ? (
                        <span id={`${componentName}-ICD10CodeRequired-${index}`} className="text-danger">*</span>
                      ) : null}
                    </label>
                    <StyledTypeAhead
                      filterBy={filterBy}
                      inputProps={{
                        id: `${componentName}-ICD10CodeValue-${index}`
                      }}
                      labelKey="code"
                      isLoading={isLoadingComp}
                      minLength={2}
                      onSearch={asyncCodeSearch}
                      options={options}
                      selected={referralObj.conditions[index + 1].code}
                      onChange={(e) => handleIcdChange(e, index)}
                    />
                  </Col>
                  <Col sm={3}>
                    <label id={`${componentName}-ICD10DescLabel-${index}`}>
                      ICD-10 Description
                      {selectedReferralType.value === "SPECIALIST" ? (
                        <span id={`${componentName}-ICD10DescRequired-${index}`} className="text-danger">*</span>
                      ) : null}
                      <i
                        id={`${componentName}-ICD10DescAddRemove-${index}`}
                        className="fas fa-minus-circle cursor-p ms-1"
                        onClick={() => removeNewCondition(index)}
                      ></i>
                    </label>
                    <StyledTypeAhead
                      filterBy={filterBy}
                      inputProps={{
                        id: `${componentName}-ICD10DescValue-${index}`
                      }}
                      labelKey="description"
                      isLoading={isLoadingComp}
                      minLength={2}
                      onSearch={asyncDescSearch}
                      options={options}
                      selected={referralObj.conditions[index + 1].description}
                      onChange={(e) => handleIcdChange(e, index)}
                    />
                  </Col>
                </Row>
              ))
              : null}
            <Row className="mb-2">
              <Col sm={6}>
                <label id={`${componentName}-urgencyLabel`}>
                  Urgency<span className="text-danger">*</span>
                </label>
                <Dropdown value={referralObj.urgency}>
                  <Dropdown.Toggle
                    as={DropDownButton}
                    id={`${componentName}-urgencyValue`}
                    cssOpen={isOpen}
                    className="w-100 mb-0 me-0"
                  >
                    {referralObj.urgency ? (
                      referralObj.urgency.text
                    ) : (
                      <span>-Select-</span>
                    )}
                    <i className="fa fa-chevron-down" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
                    {urgencyOptions.map((urgencyOp) => (
                      <Dropdown.Item
                        key={`${componentName}-urgencyValue-${urgencyOp.value}`}
                        id={`${componentName}-urgencyValue-${urgencyOp.value}`}
                        onClick={() => handleChange("urgency", urgencyOp)}
                      >
                        {urgencyOp.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={3}>
                <DropDownList
                  id={`${componentName}-recommendedTeams`}
                  key={`${componentName}-recommendedTeams`}
                  mode={DDLMode.SelectOne}
                  header="Recommended Team"
                  showSearchTextbox={false}
                  getData={() => Promise.resolve(recommendedTeams.map(team => {
                    return { value: team.teamId, text: team.name }
                  }))}
                  handleChange={(team) => handleChange("recommendedTeam", team)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Alert variant="danger" show={showEmergentMsg} onClose={() => setShowEmergentMsg(false)} dismissible>
                  <p className="mb-0">
                    Do not rely on electronic communications or communication through CareEmpower for immediate, 
                    urgent medical needs. This website is not designed to facilitate medical referral needs. Please 
                    contact Equality Health via phone (833-227-3100) to ensure timely handling of the referral.
                  </p>
                </Alert>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Col>
              <label id={`${componentName}-referralTypeLabel`} className="d-block">Referral Type</label>
              <div id={`${componentName}-referralTypeDropdown`} className="d-inline-block position-relative mb-3" value={selectedReferralType} style={{ width: "15%" }}>
                  <button id={`${componentName}-referralTypeValue`} type="button" className="d-inline border btn btn-secondary w-100 d-flex justify-content-between align-items-center px-3 bg-white text-secondary fw-normal" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedReferralType ? (
                      selectedReferralType
                    ) : (
                      <span>-Select-</span>
                    )}
                    <i className="fa fa-chevron-down" />
                  </button>
                  <ul id={`${componentName}-referralTypeDropdownMenu`} className="dropdown-menu p-3">
                      {referralTypes.map((type) => (
                        <li>
                          <button 
                            key={`${componentName}-referralTypeValue-${type.text}`}
                            id={`${componentName}-referralTypeValue-${type.text}`}
                            onClick={() => handleTypeSelection(type)}
                            className="dropdown-item"
                          >
                            {type.text}
                          </button>
                        </li>
                      ))}
                      <li>
                          {isCBOAccessAllowed(roles) && referComponent()}
                      </li>
                  </ul>
              </div>
            </Col>
          </Row>
        )}

        <Row className="mb-3">
          <Col>
            <label id={`${componentName}-addANoteLabel`} className="d-block">
              Add a Note
              {selectedReferralType &&
                selectedReferralType.value === "CARE_COORDINATION" ? (
                <span id={`${componentName}-addANoteRequired`} className="text-danger">*</span>
              ) : null}{" "}
            </label>
            <textarea
              className="textarea_editor form-control"
              id={`${componentName}-addANoteValue`}
              rows="4"
              name="comments"
              value={referralObj.comments}
              onChange={(e) => handleChange("comments", e.target.value)}
            ></textarea>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label id={`${componentName}-docLabel`} className="d-block">
              Documentation
              {selectedReferralType &&
                selectedReferralType.value === "SPECIALIST" ? (
                <span id={`${componentName}-docRequired`} className="text-danger">*</span>
              ) : null}
            </label>
            {selectedReferralType &&
              selectedReferralType.value === "CARE_COORDINATION" ? (
              <p id={`${componentName}-docInstruction`}>
                Please include last progress note and labs for documentation.
              </p>
            ) : null}
            <FileUpload
              id={`${componentName}-docFileUpload`}
              onChange={handleUploadFileChange}
              clearStateProp={fileUploadResetCounter}
            />{" "}
          </Col>
        </Row>
        <StyledFooter>
          <Button id={`${componentName}-closeButton`} onClick={() => closeSubmitModal()}>Close</Button>{" "}
          {temporarilyDisableSubmitButton ? <EQHSpinner /> :
            <Button id={`${componentName}-submitButton`} disabled={isFormValid()} onClick={() => submitReferral()}>
              Submit
            </Button>}
        </StyledFooter>
      </div>
    </>
  );
};

export default SubmitReferralBody;
