export function getGetters(state) {
    return {
        carePaths: state.carePaths,
        patient: state.patient,
        patientsList: state.patientsList,
        selectedPatientsEvents: state.selectedPatientsEvents,
        isLoading: state.isLoading,
        totalSize: state.totalSize,
        patientSearch: state.patientSearch,
        assignPatientTo: state.assignPatientTo,
        updateReferralStatus: state.updateReferralStatus,
        removeFromWorklist: state.removeFromWorklist,
        declineReasons: state.declineReasons,
        patientAlreadyRemoved: state.patientAlreadyRemoved,
        exportSummaryData: state.exportSummaryData,
        isSaveAsCustomWorklistEnabled: state.isSaveAsCustomWorklistEnabled,
        customWorklistError: state.customWorklistError,
        isDeleteCustomWorklistEnabled: state.isDeleteCustomWorklistEnabled,
        customWorklist: state.customWorklist,
        teamsCapacity: state.teamsCapacity,
        careManagementTeams: state.careManagementTeams,
        isAssignedPatient: state.isAssignedPatient,
        isNewAssessmentTemplateSelectorEnabled: state.isNewAssessmentTemplateSelectorEnabled,
        patientTemplates: state.patientTemplates,
        isNewAssessmentFlowEnabled: state.isNewAssessmentFlowEnabled,
        selectedPatientTemplate: state.selectedPatientTemplate,
        isSavingAssessment: state.isSavingAssessment,
        reassignCareStep: state.reassignCareStep,
        worklistDefaultView: state.worklistDefaultView,
        customFilters: state.customFilters,
        isViewAsEnabled: state.isViewAsEnabled,
        getPage: state.page,
        isMarkAsOutreachedEnabled: state.isMarkAsOutreachedEnabled,
        campaigns: state.campaigns,
        patientsEventsToBeMarkedAsOutreached: state.patientsEventsToBeMarkedAsOutreached,
        activeRole: state.activeRole,
        asssignPayload: state.asssignPayload,
        workOnCareStep: state.workOnCareStep,
        isBulk: state.isBulk,
        adminRolesHubWL: state.adminRolesHubWL,
    }
}