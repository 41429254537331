import React, { useContext, createContext, useReducer } from 'react';
import { actions, initialState } from './actions';
import { findPatientGet, findPatientPost, findSuspectDelete } from './api';
import { reducer } from './ManageVisit.reducer';

const ManageVisitContext = createContext();

const ManageVisitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = {
    patient_post: state.patient_post,
    patient_object: state.patient_object,
    patient_get: state.patient_get,
    error_from_services: state.error_from_services,
    loading: state.loading,
    loading_post: state.loading_post,
    showAddModal: state.showAddModal,
    modalType: state.modalType,
    setModalType: (modalType) => {
      dispatch({ type: actions.SET_MODAL_TYPE, payload: modalType });
    },
    selectPatientPOST: (patient) => {
      dispatch({ type: actions.SELECT_PATIENT_POST, payload: patient });
    },
    toggleLoading: (loading) => {
      dispatch({ type: actions.TOGGLE_LOADING, payload: loading });
    },
    toggleAddModal: (show) => {
      dispatch({ type: actions.TOGGLE_SHOW_ADD_MODAL, payload: show });
    },
    findSuspectDelete: async (subVisitActivity) => await findSuspectDelete(subVisitActivity),
    findPatientPost: async (id) => {
      dispatch({ type: actions.TOGGLE_LOADING_POST, payload: true })
      await findPatientPost(id)
        .then(patient => {
          dispatch({ type: actions.SELECT_PATIENT_POST, payload: patient.data })
          dispatch({ type: actions.TOGGLE_LOADING_POST, payload: false })
        });
    },
    findPatientGet: async (id) => {
      dispatch({ type: actions.TOGGLE_LOADING, payload: true })
      await findPatientGet(id)
        .then(patient => {
          const hasDocument = patient?.data?.manageVisitActivityTypeDtos
            ?.filter(dto => dto?.name === 'Documentation And Coding')?.length > 0
          const newPatient = {...patient?.data}

          if (!hasDocument) {
            newPatient.manageVisitActivityTypeDtos = [...newPatient?.manageVisitActivityTypeDtos, {name: 'Documentation And Coding', visitActivitySubTypeDtos: []}]
          }
          dispatch({ type: actions.SELECT_PATIENT_GET, payload: newPatient })
          dispatch({ type: actions.TOGGLE_LOADING, payload: false })
        });
    },
    persistPatientObject :async (firstName, lastName, fullName) => {
      dispatch({ type: actions.PERSIST_PATIENT_OBJECT, payload: {firstName:firstName,lastName:lastName,fullName:fullName} })
    },

    persistErrorMessage :async (errorMessage) => {
      dispatch({ type: actions.ERROR_FROM_SERVICES, payload: {errorMessage} })
    }
  };


  return (
    <ManageVisitContext.Provider value={value}>
      {children}
    </ManageVisitContext.Provider>
  )
};
const useManageVisitContext = () => useContext(ManageVisitContext);

export {
  ManageVisitProvider,
  useManageVisitContext,
}