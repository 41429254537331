import React, {useContext, createContext, useReducer, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { orderBy } from 'lodash'

import {actions, initialState} from './actions';
import {reducer} from './EventTimeline.reducer';
import {getEventsTimeline} from './api';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from "./setters";
import {EVENTS_TYPE_MAPPER} from "../constants";

const EventTimelineContext = createContext();

const EventTimelineProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const callbacks = {
    getEventsTimeline: async (patientId, requestBody) => {
      toggles.setEventsTimelineList([])
      setters.setTotalSize(0)
      return await getEventsTimeline(patientId, requestBody)
        .then((eventsTimelineResponse) => {
          setters.setEventTimelineSearch(requestBody)
          const {totalSize, pageSize, pageNumber, availableEventTypes } = eventsTimelineResponse?.data
          toggles.setEventsTimelineList(eventsTimelineResponse?.data?.eventsTimelineDtos)
          setters.setTotalSize(eventsTimelineResponse?.data?.totalSize)
          toggles.setIsLoading(false)
          toggles.setHasMore(pageNumber <=(totalSize / pageSize))
          setters.setAvailableEventTypes(orderBy(availableEventTypes ?? [], [item => item.toLowerCase()], ['asc']).map(eventType => ({
            value: eventType,
            text: EVENTS_TYPE_MAPPER[eventType] ?? eventType
          })))
        })
    },
    scrollEventsTimeline: async (patientId, requestBody) => {
      const eventTimelineSearch = {
        ...requestBody,
        pageNumber: state.eventTimelineSearch?.pageNumber + 1
      };

      toggles.setIsLoading(true)
      await getEventsTimeline(patientId, eventTimelineSearch)
        .then((eventsTimelineResponse) => {
          const {totalSize, pageSize, pageNumber } = eventsTimelineResponse?.data
          toggles.setEventsTimelineList([
            ...state.eventsTimelineList,
            ...eventsTimelineResponse?.data?.eventsTimelineDtos
          ])
          setters.setEventTimelineSearch(eventTimelineSearch)
          setters.setPageNumber(eventsTimelineResponse?.data?.pageNumber + 1)
          setters.setTotalSize(eventsTimelineResponse?.data?.totalSize)
          toggles.setHasMore(pageNumber <=(totalSize / pageSize))
        })
        .finally(() => {
          toggles.setIsLoading(false)
        })
    },
    setUserNameListFilter: (id) => {
      getEventsTimeline(id, {})
        .then(res => {
          const mapped = res?.data?.userNameList.map(userName => ({
            value: userName,
            text: userName
          }))
          dispatch({type: actions.SET_USER_NAME_LIST_FILTER, payload: mapped})
        })
    }
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <EventTimelineContext.Provider value={value}>
      {children}
    </EventTimelineContext.Provider>
  )
};
const useEventTimelineContext = () => useContext(EventTimelineContext);

export {
  EventTimelineProvider,
  useEventTimelineContext,
}