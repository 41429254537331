import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { mapReferralServicesToText } from '../../Referrals/referralUtils'
import { formatDate } from '../../../../utils/util'

const componentName = "DateReceived"

const DateReceived = ({ referral, idx }) => {
  return (
    <Col id={`${componentName}-${idx}`} className="px-0">
      <Row className='justify-content-center'>
        <Col className='col-md-auto'>
          <span className='fw-bold text-muted'>Date Received: </span>
          <span>{formatDate(referral?.referralDtoList[0]?.statusUpdated)}</span>
        </Col>
      </Row>
    </Col>
  )
}

export default DateReceived