import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {resetPassword} from "../../utils/loginApiClient";
import {Button, Form, InputGroup} from "react-bootstrap";
import {ResponseAlert} from "../../components/alerts";

const componentName = "ResetPassword";
const PASSWORD_SUCCESSFULLY_CHANGED = "Your password has been changed successfully!";

const ResetPasswordView = () => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [response, setResponse] = useState(null);
  const [waitingForApiResponse, setWaitingForApiResponse] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);

  const {token} = useParams();
  const history = useHistory();

  const isPasswordValid = () => {
    return password.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])[a-zA-Z\d !"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~]{8,50}$/g);
  };

  const isRepeatPasswordValid = () => {
    return isPasswordValid() && password === repeatPassword;
  };

  const getMessageOrDefault = (response) => {
    const [, responseBody] = response;
    if (responseBody?.data?.message) {
      return responseBody.data.message;
    }
    return PASSWORD_SUCCESSFULLY_CHANGED;
  }

  const wasPasswordUpdated = (response) => {
    const [, responseBody] = (response ?? []);
    return responseBody?.data?.message === PASSWORD_SUCCESSFULLY_CHANGED;
  }

  const passwordUpdated = wasPasswordUpdated(response);

  return (
    <Form
      id={`${componentName}-resetPassword`}
      onSubmit={async e => {
        e.preventDefault();
        setWaitingForApiResponse(true);
        const res = await resetPassword(token, password);
        setResponse(res);
        setWaitingForApiResponse(false);
        setPassword("");
        setRepeatPassword("");
      }}
    >
      <h4 id={`${componentName}-resetTitle`} className="text-center">Reset
        Password</h4>
      {response && (
        <ResponseAlert id={`${componentName}-passwordChanged`}
                       response={response}
                       success={getMessageOrDefault(response)}
        ></ResponseAlert>
      )}
      <p id={`${componentName}-newPasswordText`} className="text-center">Please
        enter new password.</p>
      <div className="mb-3">
        <label>New Password</label>
        <InputGroup>
          <Form.Control id={`${componentName}-passwordValue`}
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        required
                        placeholder="Password"
                        value={password}
                        disabled={passwordUpdated}
                        onChange={e => setPassword(e.target.value)}
                        isInvalid={password !== "" && !isPasswordValid()}
          />
          <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
            <span className="input-group-addon px-2">
              {!showPassword
                ? <i className="fa-solid fa-eye"></i>
                : <i className="fa-solid fa-eye-slash"></i>
              }
            </span>
          </InputGroup.Text>
          <Form.Control.Feedback id={`${componentName}-passwordValidation`}
                                 type="invalid" tooltip>
            Must be at least 8 characters long, contains at least one letter,
            one number, one uppercase letter, one lowercase letter, and one of
            these special characters: "{" !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"}"
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <div className="mb-5">
        <label>Confirm New Password</label>
        <InputGroup>
          <Form.Control id={`${componentName}-newPasswordValue`}
                        type={showRepeat ? "text" : "password"}
                        autoComplete="new-password"
                        required
                        placeholder="Repeat Password"
                        value={repeatPassword}
                        disabled={passwordUpdated}
                        onChange={e => setRepeatPassword(e.target.value)}
                        isInvalid={repeatPassword !== ""
                          && !isRepeatPasswordValid()}
          />
          <InputGroup.Text onClick={() => setShowRepeat(!showRepeat)}>
              <span className="input-group-addon px-2">
                {!showRepeat
                  ? <i className="fa-solid fa-eye"></i>
                  : <i className="fa-solid fa-eye-slash"></i>
                }
              </span>
          </InputGroup.Text>
          <Form.Control.Feedback id={`${componentName}-matchPassword`}
                                 type="invalid" tooltip>
            Passwords must match
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <div
        className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end">

        {passwordUpdated ? (
            <Button id={`${componentName}-resetButton`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/")
                    }}
            > Go to Login </Button>
          ) :
          (
            <Button id={`${componentName}-resetButton`}
                    type="submit"
                    disabled={
                      !isPasswordValid() ||
                      !isRepeatPasswordValid() ||
                      waitingForApiResponse
                    }> Change Password </Button>
          )}
      </div>
    </Form>
  );
};

export default ResetPasswordView;
