import React from "react";
import {Toast} from "react-bootstrap";
import {isEmpty, isArray} from "lodash";
import {
  useEQHToastContext
} from "./EQHToast.context";

export const EQHToast = () => {
  const {notification, removeNotification} = useEQHToastContext();

  const onClose = () => {
    removeNotification()
  }

  const show = notification && !isEmpty(notification.message);

  return (
    <>
      <Toast onClose={() => onClose()} show={show}
             delay={4000} autohide style={{zIndex: 1060}}
             className="position-fixed bottom-0 end-0 text-bg-primary m-3"
             position="bottom-end">
        <Toast.Header className="bg-primary custom border-bottom-0">
          <strong
            className="me-auto text-white">{notification?.title}</strong>
        </Toast.Header>
        <Toast.Body className="bg-primary custom text-white">
          {isArray(notification?.message) && !isEmpty(notification?.message)
            ? <ul> {notification?.message.map((message, index) => <li key={index}>{message}</li>)} </ul> : notification?.message}
        </Toast.Body>
      </Toast>
    </>
  );
};

export default EQHToast;