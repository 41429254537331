import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../ConfirmationModal";
import { useAuth } from "../../../../context/authContext";
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { searchPatients } from "../../../../actions/caremgmt/hubWorkCenterAction";
import { createTask, getTaskTypes, handleError, updateTask } from "../../../../actions/caremgmt/myWorkspaceActions";
import { setHours, setMinutes, format } from "date-fns";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { searchUsers } from "../../../../actions/caremgmt/careAdminAction";
import EQHErrorToast from "../../../../components/EQHErrorToast";

const TaskModal = ({
    showAddTask,
    setShowAddTask,
    taskToEdit,
    savedSuccessfully,
    currentUserByDefault = true,
    currentUser = null,
    defaultMember = {}
}) => {
    const [task, setTask] = useState({});
    const [isEditingTask, setEditingTask] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [errors, setErrors] = useState([]);

    const NEW_HUB_ASSIGNMENT = "NEW_HUB_ASSIGNMENT"
    const OUTREACH = "OUTREACH"
    const [isMemberLoading, setMemberLoading] = useState(false);
    const [memberOptions, setMemberOptions] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [assignedOptions, setAssignedOptions] = useState([]);
    const DatePickerStyled = styled.div`
          .react-datepicker-wrapper {
            width: 100%;
          }
        `;
    const minHour = 7;
    const minMinutes = 0;
    const maxHour = 19;
    const maxMinutes = 0;
    const popperModifiers = {
        offset: {
            enabled: true,
            offset: "0px, 0px"
        },
        preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "scrollParent"
        }
    }
    const currentDate = format(new Date(), "yyyy-MM-dd");

    const componentName = "TaskModal";
    const handleCloseAddTask = () => {
        setTask({});
        setShowAddTask(false);
        setErrors([]);
    };
    const isEmpty = require("is-empty");
    const auth = useAuth();

    const handleTaskTitleChanged = (event) => setTask({ ...task, "taskDescription": event.target.value });
    const handleTypeChanged = (event) => setTask({ ...task, "taskType": event.target.value });
    const handleMemberChanged = (e) => {
        if (!isEmpty(e)) {
            setTask({ ...task, "patientId": e[0].value });
        }
    }
    const handleDueDateChanged = (dueDate) => {
        if (!isEmpty(dueDate) && dueDate.getHours() < minHour) {
            dueDate.setHours(minHour, minMinutes)
        }
        setTask({ ...task, dueDate });
    }
    const handleNotesChanged = (event) => setTask({ ...task, "notes": event.target.value });

    const handleAssignedChanged = (e) => {
        setTask({ ...task, "userAccountId": e.target.value });
    }

    const validateTaskRequiredFields = () => {
        const requiredFields = [task.taskDescription, task.dueDate, task.taskType, task.userAccountId]
        if (requiredFields.some(field => isEmpty(field))) {
            return "Some mandatory fields are empty"
        }
        return null
    }

    const validateDueDate = () => {
        const today = new Date(currentDate)
        const selectedDate = new Date(Date.parse(task.dueDate))
        if (selectedDate < today) {
            return "Due date can not be before today"
        }
        return null
    }

    const checkAddTaskFields = () => {
        const errorRequiredFields = validateTaskRequiredFields()
        if (errorRequiredFields) {
            setErrors([errorRequiredFields])
            return;
        }
        const errorDueDate = validateDueDate()
        if (errorDueDate) {
            setErrors([errorDueDate])
            return;
        }
        if (!isEditingTask && !task.patientId) {
            setShowConfirmationModal(true)
            return
        }
        saveTask()
    }

    const onAcceptConfirmModal = () => {
        setShowConfirmationModal(false)
        saveTask()
    }

    const saveTask = () => {
        handleCloseAddTask();
        const taskParam = task;
        const taskPromise = isEditingTask
            ? updateTask(taskParam)
            : createTask(taskParam);

        taskPromise.then(() => {
            savedSuccessfully();
        }).catch((error) => {
            onError(error);
        });
    }

    const onCancelConfirmModal = () => {
        setShowConfirmationModal(false)
    }

    const getCurrentUserAsOption = () => {
        return {
            text: auth.getName(),
            value: auth.getUserId()
        };
    }

    const getTaskUserAsOption = () => {
        return {
            text: taskToEdit.userAccountFullName,
            value: taskToEdit.userAccountId
        };
    }

    const fetchTaskType = () => {
        getTaskTypes().then((resp) => {
            if (resp && resp.data) {
                setTaskTypes(resp.data);
            }
        }).catch((error) => {
            onError(error);
        });
    }

    const fetchAssignedSearch = () => {
        const selectedUser = !isEmpty(taskToEdit) ? getTaskUserAsOption() : getCurrentUserAsOption();
        let userAssignedOptions = [];
        searchUsers().then((response) => {
            if (response?.data) {
                userAssignedOptions.push(...response.data);
                if (userAssignedOptions.findIndex(u => u.value === selectedUser.value) == -1) {
                    userAssignedOptions.push(selectedUser);
                }
                setAssignedOptions(userAssignedOptions.sort((a, b) => {
                    return a?.text?.localeCompare(b?.text);
                }));
            }
        }).catch((error) => {
            onError(error);
        });
    }

    const formatText = (value) => {
        return value?.toLowerCase().split('_').map(capitalize).join(' ');
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const specialTaskTypes = [NEW_HUB_ASSIGNMENT, OUTREACH];

    const membersearch = (query) => {
        if (query && query.length > 3) {
            const timeout = setTimeout(() => {
                setMemberLoading(true);
                searchPatients(query).then((response) => {
                    if (response && response.data) {
                        const options = response.data.patients.map(p => {
                            return { text: `${p.surnameCommaName} - ${p.memberId}`, value: p.id }
                        });
                        setMemberOptions(options);
                    }
                    setMemberLoading(false);
                }).catch((error) => {
                    onError(error);
                    setMemberLoading(false);
                });
            }, 1600);

            return () => clearTimeout(timeout);
        }
    }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const onError = (err) => {
        const error = handleError(err)
        setErrors([error]);
    }


    useEffect(() => {
        if (showAddTask) {
            setTask({});
            fetchAssignedSearch();
            fetchTaskType();
            if (!isEmpty(taskToEdit)) {
                setEditingTask(true);
                setTask({
                    ...taskToEdit,
                    dueDate: new Date(taskToEdit.dueDate)
                });
            } else {
                if (currentUserByDefault) {
                    setTask({ "userAccountId": currentUser ? currentUser.userAccountId : auth.getUserId() });
                } else {
                    setTask({});
                }
                setEditingTask(false);
            }
        }
    }, [showAddTask]);

    useEffect(() => {
      if (Object.keys(defaultMember).length > 0) {
        const {patientId, patientFullName, userAccountId} = defaultMember;
        setTask({ patientId, patientFullName, userAccountId })
      }
    }, [defaultMember])

    return (<>
        <Modal size="lg" centered show={showAddTask} onHide={handleCloseAddTask}>
            <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
            <Modal.Header closeButton>
                <Modal.Title
                    id={`${componentName}-taskModalTitle`}>{isEditingTask ? 'Edit' : 'Add'} Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="mb-3" md={6}>
                        <label className="d-block">Description<span className="text-danger">*</span></label>
                        <Form.Control
                            id={`${componentName}-task-title`}
                            value={task.taskDescription}
                            type="text"
                            onChange={(e) => handleTaskTitleChanged(e)}
                        />
                    </Col>
                    <Col className="mb-3" md={6}>
                        <label className="d-block">Member Name or ID</label>
                        {isEditingTask || Object.keys(defaultMember).length > 0
                            ? <Form.Control
                                id={`${componentName}-member-name`}
                                value={task.patientFullName}
                                type="text"
                                disabled="true"
                            />
                            : <AsyncTypeahead
                                id={`${componentName}-member-name`}
                                labelKey="text"
                                isLoading={isMemberLoading}
                                onSearch={membersearch}
                                options={memberOptions}
                                onChange={(e) => handleMemberChanged(e)}
                                placeholder="Search Members by Name or ID"
                                va
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="mb-3">
                        <label className="d-block">Task Type<span className="text-danger">*</span></label>
                        {specialTaskTypes.includes(task.taskType)
                            ? (
                                <Form.Control id={`${componentName}-task-type-special`}
                                    value={formatText(task.taskType)}
                                    type="text" disabled="true" />
                            ) : (
                                <select id={`${componentName}-task-type`}
                                    onChange={(e) => handleTypeChanged(e)}
                                    value={task.taskType} className="form-select">
                                    <option value=""></option>
                                    {taskTypes && taskTypes.map((f, i) => {
                                        return (
                                            <option id={`task-type-${i}`} value={f.value}>{f.text}</option>);
                                    })}
                                </select>

                            )}
                    </Col>
                    <Col className="mb-1" sm={12} md={3}>
                        <label className="d-block">Due Date<span className="text-danger">*</span></label>
                        <DatePickerStyled>
                            <DatePicker
                                name="taskDueDate"
                                filterTime={filterPassedTime}
                                value={task.dueDate}
                                selected={task.dueDate}
                                onChange={(date) => handleDueDateChanged(date)}
                                showTimeSelect
                                className="form-control"
                                inputProps={{
                                    id: `${componentName}-taskDateValue`
                                }}
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(), minMinutes), minHour)}
                                maxTime={setHours(setMinutes(new Date(), maxMinutes), maxHour)}
                                popperModifiers={popperModifiers}
                                autoComplete="off"
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                        </DatePickerStyled>
                    </Col>
                    <Col className="mb-1" sm={12} md={5}>
                        <label className="d-block">Assigned To<span className="text-danger">*</span></label>
                        <select
                            id={`${componentName}-assigned-to`}
                            onChange={(e) => handleAssignedChanged(e)}
                            value={task.userAccountId}
                            className="form-select"
                        >
                            <option value=""></option>
                            {assignedOptions && assignedOptions.map((f, i) => {
                                return (
                                    <option id={`assign-to-option-${i}`} value={f.value}>{f.text}</option>);
                            })}
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="d-block">Additional Notes</label>
                        <Form.Control max="500" onChange={(e) => handleNotesChanged(e)} value={task.notes} as="textarea"
                            rows={6} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddTask}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={checkAddTaskFields}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        <ConfirmationModal
            acceptButtonLabel="Continue"
            cancelButtonLabel="Go Back"
            bodyMessage="This scheduled task is not tied to a member. Do you want to continue?"
            show={showConfirmationModal}
            onAccept={onAcceptConfirmModal}
            onCancel={onCancelConfirmModal}
        />
    </>);

}

export default TaskModal;