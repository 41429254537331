import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropDownButton, CustomMenuStyled } from "./sharedDDL";

const RejectionDDL = ({
  id,
  list,
  textSelector,
  valueSelector,
  toolTipSelector,
  onChange,
  disabled,
  selectedKey,
  getKeyFromListItem = valueSelector,
  isValueSelectorUniqueForId = true
}) => {
  if (!list) {
    list = [];
  }
  let value = list.find((x, idx) => getKeyFromListItem(x, idx) === selectedKey);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id={id}
        as={DropDownButton}
        cusWidth="100% !important"
        disabled={disabled}
      >
        <span>{value == null ? "-Select-" : textSelector(value)}</span>
        <i className="fa fa-chevron-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
        <Dropdown.Item
          id={`${id}-option-select`}
          style={{}}
          onClick={() => onChange(null)}
          active={value == null}
        >
          {"-Select-"}
        </Dropdown.Item>
        {list.map((item, idx) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{toolTipSelector ? toolTipSelector(item) : textSelector(item)}</Tooltip>}
          >
            <Dropdown.Item
              id={`${id}-option-${valueSelector ? isValueSelectorUniqueForId ? valueSelector(item, idx) : idx : idx}`}
              style={{}}
              onClick={() => onChange(valueSelector(item, idx))}
              active={value == null ? false : getKeyFromListItem(value, idx) === getKeyFromListItem(item, idx)}
            >
              {textSelector(item)}
            </Dropdown.Item>
          </OverlayTrigger>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { RejectionDDL };
