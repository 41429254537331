import React, {useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useReferralsContext} from "../context/Referrals.context";
import Form from "react-bootstrap/Form";
import {DDLMode, DropDownList} from "../../../../../../components/dropDownLists/genericDDL";
import ReferralUrgency from "../components/ReferralUrgency";
import {FileUpload} from "../../../../../../components/FileUpload";
import {AsyncSearch} from "../components";

const componentName = "SpecialistReferralStep3";

const SpecialistReferralStep3 = () => {

    const {
        currentSpecialistReferralStep,
        setCurrentSpecialistReferralStep,
        specialistReferralSteps,
        setVisitTypes,
        visitTypes,
        setSelectedVisitType,
        selectedVisitType,
        urgencyOptions,
        setUrgencyOptions,
        setSelectedUrgency,
        setNote,
        setUploadedFiles,
        uploadedFiles,
        icd10List,
        asyncIcd10CodeSearch,
        asyncIcd10DescriptionSearch,
        icd10Options,
        setSelectedICD10Option,
        selectedUrgency,
        note,
        addMoreICD10
    } = useReferralsContext();

    useEffect(() => {
        if (currentSpecialistReferralStep === specialistReferralSteps.step3) {
            setVisitTypes();
            setUrgencyOptions();
        }
    }, [currentSpecialistReferralStep])

    const goBack = () => {
        setCurrentSpecialistReferralStep(specialistReferralSteps.step2)
    }
    return (
        <>
            <Row id={`${componentName}-backRow`}
                 key={`${componentName}-backRow`}>
                <Col id={`${componentName}-backCol`}
                     key={`${componentName}-backCol`}>
                    <Button variant="link"
                            className="text-decoration-none fw-bolder"
                            id={`${componentName}-backButton`}
                            key={`${componentName}-backButton`}
                            onClick={() => goBack()}>
                        <i className="fa-solid fa-arrow-left pe-3"/>
                        Back to Provider Search
                    </Button>
                </Col>
            </Row>
            <Row className="pt-3">
                <Form.Group>
                    <DropDownList
                        id={`${componentName}-visitType`}
                        key={`${componentName}-visitType`}
                        mode={DDLMode.SelectOne}
                        showSearchTextbox={false}
                        header="Visit Type"
                        getData={() => Promise.resolve(visitTypes)}
                        handleChange={setSelectedVisitType}
                        selected={selectedVisitType}
                    />
                </Form.Group>
            </Row>
            <Row className="pt-1">
                <ReferralUrgency
                    id={`${componentName}-urgency`}
                    key={`${componentName}-urgency`}
                    urgencyOptions={urgencyOptions}
                    type="radio"
                    showAdditionalMessage={false}
                    onSelect={setSelectedUrgency}
                    selectedUrgency={selectedUrgency}
                />
            </Row>
            <Row className="pt-3">
                <Form.Group>
                    <Form.Label id={`${componentName}-notesLabel`}>
                        Notes
                    </Form.Label>
                    <Form.Control
                        id={`${componentName}-notesValue`}
                        key={`${componentName}-notesValue`}
                        as="textarea"
                        rows="3"
                        onChange={e => setNote(e.target.value)}
                        maxLength="5000"
                        value={note}
                    />
                </Form.Group>
            </Row>
            <Row className="pt-4">
                <Form.Label id={`${componentName}-docLabel`}>
                    Documentation
                </Form.Label>
                <span className="pb-2" id={`${componentName}-docInstruction`}>
                            Please include last progress note and labs for documentation.
                        </span>
                <FileUpload
                    id={`${componentName}-docFileUpload`}
                    onChange={setUploadedFiles}
                    // clearStateProp={fileUploadResetCounter}
                    btnClass="text-primary bg-light border-light"
                    showIcon={false}
                    label="Upload Files"
                    uploadedFiles={uploadedFiles}
                />
            </Row>
            <Row className="pt-4 ps-2">
                <Form.Group className="bg-light">
                    {icd10List.map((icd10, index) => (
                        <Row className="pt-2"
                             id={`${componentName}-icd10Row-${index}`}
                             key={`${componentName}-icd10Row-${index}`}>
                            <Col>
                                <AsyncSearch
                                    id={`${componentName}-ICD10Code`}
                                    title="ICD-10 Code"
                                    onSearch={asyncIcd10CodeSearch}
                                    options={icd10Options}
                                    isLoading={icd10.isLoading}
                                    labelKey="code"
                                    onChange={setSelectedICD10Option}
                                    selected={icd10.code}
                                    index={index}
                                />
                            </Col>
                            <Col>
                                <AsyncSearch
                                    id={`${componentName}-ICD10Description`}
                                    title="ICD-10 Description"
                                    onSearch={asyncIcd10DescriptionSearch}
                                    options={icd10Options}
                                    isLoading={icd10.isLoading}
                                    labelKey="description"
                                    onChange={setSelectedICD10Option}
                                    selected={icd10.description}
                                    index={index}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button variant="link" className="text-decoration-none"
                            onClick={addMoreICD10}
                            hidden={icd10List.length > 3}>
                        Add More
                    </Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default SpecialistReferralStep3;