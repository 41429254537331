import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ErrorToast from "../common/ErrorToast";
import Col from "react-bootstrap/Col";
import { Button } from "../../components/buttons";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../utils/apiClient";

import styled from "styled-components";

const StyledMenuBtns = styled(Button)`
  margin-right: 10px;
  background: white !important;
  color: #188dcd;

  :hover {
    color: #188dcd;
    border: 1px solid #188dcd !important;
  }
`;

const StyledFooter = styled.span`
  background-color: #fff;
  color: #67757c;
  padding: 17px 15px;
  width:100%;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  font-size: 12px;
  text-align: right;
`;

const StyledCol = styled.span`
  background-color: #fff;
  color: #67757c;
  width:100%;
  border-top: 1px solid rgba(120, 130, 140, 0.13);  
  text-align: left;
`;

const StyledMenuBtnsOS = styled(StyledMenuBtns)`
  border: 1px solid #d2d2d2;
  padding: 0 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 30px;
  color: #404040;
  display: inline-block;
  white-space: nowrap;

  :focus {
    border: 1px solid #d2d2d2;
    color: #404040;
  }
`;

const AddToWorkList = ({ onHideClicked, patientsSelected }) => {
  const isEmpty = require("is-empty");
  const textBoxRef = useRef();
  const enableQuickList = useRef();
  const workListCheck = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [memberWorkList, setMemberWorkList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const [selectedWorkLists, setSelectedWorkLists] = useState([]);
  useEffect(() => {
    (async function fetchData() {
      if (!memberWorkList.length) {
        setIsLoadingComp(true);
        axios
          .get(
            process.env.REACT_APP_REST_API_BASE_URL +
              `/v1/quicklist/member-work-lists`,
            {
              headers: {},
            }
          )
          .then((resp) => {
            let options = resp.data;

            setMemberWorkList(options);
            setIsLoadingComp(false);
          });
      }
    })();
  }, []);

  const addNewList = () => {
    let newName;
    if (textBoxRef && textBoxRef.current && textBoxRef.current.value) {
      newName = textBoxRef.current.value;
      setMemberWorkList([
        ...memberWorkList,
        { name: newName, quicklistType: "custom_worklist" },
      ]);
    }
  };
  const addToRefs = (el, workList) => {
    if (el && !workListCheck.current.includes({ el: el, workList: workList })) {
      workListCheck.current.push({ el: el, workList: workList });
    }
  };
  const onCreate = () => {
    let worklists = []

    if (
      workListCheck &&
      workListCheck.current &&
      workListCheck.current.length
    ) {
      workListCheck.current.forEach((wl) => {
        if (wl.el.checked &&  !worklists.includes(wl.workList)) {
          worklists.push(wl.workList);
        }
      });
    }
    if (worklists.length) {
      let enableQuickL;
      if (enableQuickList && enableQuickList.current) {
        enableQuickL = enableQuickList.current.checked;
      }
      const payLoad = {
        worklists: worklists,
        patients: patientsSelected.map((e) => e.id),
        quickListAlertsEnabled: enableQuickL
      };
      const url =
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/custom-work-list/work-list/add-to-worklist`;
      setIsLoading(true);
      axios
        .post(url, payLoad, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          onHideClicked(null, true);
        })
        .catch((error) => {
          setIsLoading(false);
          if(typeof error ==='string'){
            setErrorMessage(error);
          }
          else if(error && error.message && typeof error.message ==='string'){
            const err = error.message
            setErrorMessage(err);
          }
        });
    }
  };

  return (
    <Row>
      <Col sm={12}>
        <h6 className="fw-bold">Add to New Worklist</h6>
        <p>Enter a Name of the new worklist you would like to add:</p>

        <Form>
          <Form.Group controlId="addWorklist">
            <Form.Control
              type="text"
              ref={textBoxRef}
              disabled={isLoadingComp}
              placeholder="Worklist Name"
            />
          </Form.Group>
          <StyledMenuBtnsOS
            onClick={addNewList}
            variant="outline-secondary"
            disabled={isLoadingComp}
          >
            + Create New Worklist
          </StyledMenuBtnsOS>
        </Form>

        <hr />

        <h6 className="fw-bold">Add to Existing Worklist</h6>
        <p>Pick a Worklist that is already created under your user role:</p>

        <fieldset>
          <Form.Group as={Row}>
            <Col sm={12}>
              {!isEmpty(memberWorkList) &&
                memberWorkList.map((workList) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      ref={(e) => addToRefs(e, workList)}
                      label={workList.name}
                    />
                  );
                })}
              {isLoadingComp && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Col>
            <StyledCol sm={12}>
              <Form.Check
                type="checkbox"
                ref={enableQuickList}
                label="Click to enable Quick List alerts for this Worklist"
              />
            </StyledCol>
          </Form.Group>
        </fieldset>
      </Col>
      {errorMessage && <ErrorToast errorParam={errorMessage} />}
      <StyledFooter>
        <Button onClick={(e) => onHideClicked(e, false)}>Close</Button>{" "}
        <Button  onClick={onCreate} disabled= {isEmpty(patientsSelected)}>
          Add
        </Button>
      </StyledFooter>
    </Row>
  );
};
export default AddToWorkList;
