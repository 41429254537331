export const performanceField = {
    programSnapshot: [
        {
            label: 'Program Enrollments This Year',
            key: 'programEnrollmentsThisYear'
        },
        {
            label: 'Programs Completed This Year',
            key: 'programCompletedThisYear'
        },
        {
            label: 'Closed Not Completed This Year',
            key: 'closedButNotCompletedThisYear'
        },
        {
            label: 'Currently Enrolled Members With At Least 1 Visit Scheduled',
            key: 'currentlyEnrolledMembersWithAtLeastOneVisitScheduled'
        },
        {
            label: 'Currently Enrolled Members With At Least 1 Visit Completed',
            key: 'currentlyEnrolledMembersWithAtLeastOneVisitCompleted'
        }
    ],
    programInsight: [
        {
            label: 'Open Programs',
            key: 'openPrograms'
        },
        {
            label: 'Enrolled',
            key: 'enrolled'
        },
        {
            label: 'Marked As Completed',
            key: 'completeProgram'
        },
        {
            label: 'Closed Not Completed',
            key: 'closedNotCompleted'
        },
        {
            label: 'Outreach In Progress',
            key: 'outreachInProgress'
        },
        {
            label: 'Members With At Least 1 Visit Scheduled',
            key: 'enrolledMembersWithAtLeastOneVisitScheduled'
        },
        {
            label: 'Members With At Least 1 Completed',
            key: 'enrolledMembersWithAtLeastOneVisitCompleted'
        }
    ],
    hubInsight: [
        {
            label: 'Added To Hub',
            key: 'addedToHub'
        },
        {
            label: 'Hub from ADT',
            key: 'hubFromADT'
        },
        {
            label: 'Hub from CareEmpower referral',
            key: 'hubFromCareEmpowerReferral'
        },
        {
            label: 'Hub from manual upload',
            key: 'hubFromManualUpload'
        },
        {
            label: 'Assigned To Program',
            key: 'assignedToProgram'
        },
        {
            label: 'Did Not Meet Criteria',
            key: 'doesNotMeetCriteria'
        }
    ]
}


export const performanceSections = [
    {
        title: 'Quick Snapshot',
        key: 'programSnapshot'
    },
    {
        title: 'Program Insights',
        key: 'programInsight'
    },
    {
        title: 'Hub Insights',
        key: 'hubInsight'
    }
]

export const tableHeaders = {
    mainHeaders: [
        {
            title: '',
            span: '2',
            key: 'nameTeam'
        },
        {
            title: 'Programs',
            span: '9',
            key: 'programs'
        },
        {
            title: 'Tasks',
            span: '2',
            key: 'tasks'
        },
        {
            title: 'Visits',
            span: '6',
            key: 'visits'
        }
    ],
    secondaryHeaders: [
        {
            title: '',
            key: 'name'
        },
        {
            title: 'Team',
            key: 'team'
        },
        {
            title: 'Assigned',
            key: 'assigned'
        },
        {
            title: 'Outreach In Progress',
            key: 'outreachInProgress'
        },
        {
            title: 'Enrolled',
            key: 'enrolled'
        },
        {
            title: 'Closed',
            key: 'closedButNotCompleted'
        },
        {
            title: 'Completed',
            key: 'completed'
        },
        {
            title: 'TOC',
            key: 'toc'
        },
        {
            title: 'CIA',
            key: 'cia'
        },
        {
            title: 'Monitoring',
            key: 'monitoring'
        },
        {
            title: 'Other',
            key: 'other'
        },
        {
            title: 'Due Today',
            key: 'taskDueToday'
        },
        {
            title: 'Past Due',
            key: 'taskPastDue'
        },
        {
            title: 'Hospital',
            key: 'hospital'
        },
        {
            title: 'Provider',
            key: 'provider'
        },
        {
            title: 'Home',
            key: 'home'
        },
        {
            title: 'Virtual',
            key: 'virtual'
        },
        {
            title: 'Community',
            key: 'community'
        }
    ]
}

export const insightsHeaders = [
    {
        title: 'Member Name',
        key: 'memberFullName'
    },
    {
        title: 'Member Id',
        key: 'memberId'
    },
    {
        title: 'Channel',
        key: 'interventionChannel'
    },
    {
        title: 'Source',
        key: 'interventionSource'
    },
    {
        title: 'Intervention Type',
        key: 'interventionType'
    },
    {
        title: 'Outcome',
        key: 'interventionOutcome'
    }
]
