import React from "react";
import { Badge, Col } from "react-bootstrap";
import { getRiskLevelStyle } from "../../../../../../utils/util";

const componentName = "RiskColumn"

const RiskColumn = ({ patient, idx }) => {
  return (
    <div id={`${componentName}-${idx}`} className="d-flex align-items-center">
      <Badge pill bg={getRiskLevelStyle(patient.riskLevel)} id={`${componentName}-riskPill-${idx}`}>{patient.riskLevel}</Badge>
    </div>
  )
}

export default RiskColumn;