import { disPatchError } from "../utils"
import {
  CREATE_USER,
  GET_USERS,
  RESET_ERRORS,
  UPDATE_USER,
  UPDATE_MULTIPLE_USER,
  GET_VALUES_USER_CREATE,
  ACTIVATE_DEACTIVATE_USER,
  GET_ALL_USERS,
} from "../types";
import axios from "../../utils/apiClient";
export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-admin/";


// Gets Users
export const getUsers = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  //const token = localStorage.jwtToken;
  const params = { page: 1, pageSize: 10 };
  axios
    .post(URL + "users/search", params, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};
// Gets Users
export const triggerEmail = (token, email) => (dispatch) => {
  axios
    .get(URL + `users/email-reset-password/${encodeURIComponent(email)}`, {
      headers: {
      },
    })
    .then((res) => { })
    .catch((err) => disPatchError(err, dispatch));
};

// Gets Users
export const search = (params) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + "users/search", params, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

// Chnages Users Status

// Create User
export const createUser = (userData, token) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + "users", userData, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      let newUser;
      if (res && res.data) {
        newUser = res.data;
      }
      //action to email
      if (newUser && newUser.email) {
        dispatch(triggerEmail(token, newUser.email));
      }

      dispatch({
        type: CREATE_USER,
        payload: newUser,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

export const updateUser = (userData) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .patch(URL + `users`, userData, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};
export const updateMultipleUser = (userList) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .patch(URL + `users/multiple`, userList, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_MULTIPLE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

export const activateDeactivate = (list, action) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD", payload: true });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + `${action}`, list, {
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then((res) => {
      dispatch({
        type: ACTIVATE_DEACTIVATE_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      disPatchError(err, dispatch);
    });
};

// Gets Users
export const getValues = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "create-user-form", {
      headers: {
      },
    })
    .then((res) => {
      dispatch({
        type: GET_VALUES_USER_CREATE,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getAllUsers = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "allUsers", {
      headers: {
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getAllUsersByCLients = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "allUsersByClients", {
      headers: {
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: []
      });
    });
};
