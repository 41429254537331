import {actions} from "./actions";

export function getSetters(dispatch) {
    return {
        setNotesList: payload => dispatch({ type: actions.SET_NOTES_LIST, payload: payload }),
        setTotalSize: payload => dispatch({ type: actions.SET_TOTAL_SIZE, payload: payload }),
        setNotesSearch: payload => dispatch({ type: actions.SET_NOTES_SEARCH, payload: payload }),
        setInitialState: payload => dispatch({ type: actions.SET_INITIAL_STATE, payload: payload }),
        setUserNameListFilter: payload => dispatch({ type: actions.SET_USER_NAME_LIST_FILTER, payload: payload }),
        setCategories: payload => dispatch({ type: actions.SET_CATEGORIES, payload: payload })
    }
}