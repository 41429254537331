import React from "react";
import Form from "react-bootstrap/Form";

const RolesSelect = ({onChange, options, value, className, id}) => {
    
return (
    <Form.Control id={id} as="select" multiple onChange={onChange} className={className}>
  		{options && options.map((val) => (
				<option key={val.id} value={val.id} selected={val.id === value}>{val.name}</option>                      
		 	))
		 }
  	</Form.Control>
  );
};

export { RolesSelect };