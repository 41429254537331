import React, {useEffect, useRef, useState} from "react";
import {Card, Row, Col, Badge, Button} from "react-bootstrap";
import Modals from "../../../components/modals";
import axios from "../../../utils/apiClient";
import { useLocation } from "react-router-dom";
import SubmitRecentVisitBody from "./../SubmitRecentVisit";
import ScheduledAppointment from "./modals/scheduleAppointment";
import { ROUTES } from '../../../utils/navigationItems';
import PatientRow from "./PatientRow";
import { useDispatch, useSelector } from "react-redux";
import { setReloadPatientList } from "../../../actions/manageVisitActions";

import {formatDateWithPattern} from "../../../utils/util";
import {format} from "date-fns";

const componentName = "ScheduleCardView";

const ScheduleListView = ({
                      patientList,
                      quickListType,
                      handleChangeCheckBox,
                      handleAllPatientsCheckbox,
                      isPatientSelected,
                      updateParent,
                      closeEvidenceModal,
                      handleFilterChange,
                      filterParams,
                      pageNumber,
                      print,
                      handleFilterDatesResult
                  }) => {
    const isEmpty = require("is-empty");
    const dispatch = useDispatch();
    const todayRef = useRef(null);

    const [showEvidenceModal, setShowEvidenceModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState({});
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [showRecentVisitModal, setShowRecentVisitModal] = useState(false);
    const [errorText, setErrorText] = useState();
    const [showSchedule, setShowSchedule] = useState(false);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const [showNoShow, setShowNoShow] = useState(false);
    const handleCloseSchedule = () => setShowSchedule(false);
    const handleShowSchedule = () => setShowSchedule(true);
    const handleShowNoShow = () => setShowNoShow(true);
    const handleCloseNoShow = () => setShowNoShow(false);
    const [showConfirmUnschedule, setShowConfirmUnschedule] = useState(false);
    const handleCloseConfirmUnschedule = () => setShowConfirmUnschedule(false);
    const handleShowConfirmUnschedule = () => setShowConfirmUnschedule(true);

    const visitPatient = useSelector((state) => state?.manageVisitReducer?.patient);
    const reloadPatients = useSelector((state) => state?.manageVisitReducer?.reload);

    useEffect(() => {
        if (reloadPatients) {
            handleFilterChange(filterParams, pageNumber);
            dispatch(setReloadPatientList(false))
        }
    }, [reloadPatients])

    const closeEvidenceModalMain = () => {
        setConfirmationMessage(false);
        setShowEvidenceModal(false);
        closeEvidenceModal();
    };

    const printHere = (pts) => {
        if(pts && pts.length){
        print(pts.map(e=>e.id));
        }
       
    };

    const quickListState = useLocation();
    let pageTitle = quickListState.state ? quickListState.state.pageTitle : '';
    if (!pageTitle) {
        const pathName = quickListState.pathname ? quickListState.pathname : '';
        Object.entries(ROUTES).map(([key, value]) => {
            if (value && pathName && value.path && value.path.split("/").join("") === pathName.split("/").join("")) {
                pageTitle = key;
            }
        });
    }

    const handlePatientGapStatus = (patientGapStatus, patientGapAppointmentDate) => {
        let patientId = selectedPatient.id;
        let patientGap = {}
        if(patientGapStatus && patientGapStatus ==='NO_SHOW') {
            patientGap = {
                appointmentCancelReason: "NO_SHOW",
                appointmentCancelDate: selectedPatient.patientGapAppointmentDate,
                patientGapStatus: "APPOINTMENT_UNSCHEDULED",
                gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(' ', 'T') + 'Z'

            };
        }
        else{
            patientGap = {
                patientGapStatus: patientGapStatus,
                patientGapAppointmentDate: patientGapAppointmentDate,
                gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(' ', 'T') + 'Z'
            };

        }
        return axios.post(
            process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/patients/updatePatientGapStatus?id=${patientId}`,
            patientGap,
            {
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                await handleFilterDatesResult(pageNumber);
            })
            .catch((error) => {
                setErrorText("Error");
            });
    };

    useEffect(() => {
        if (visitPatient?.id) {
            setSelectedPatient(visitPatient)
            setShowPatientDetails(true)
        }
    }, [visitPatient])

    function getDay(date) {
        const daysOfWeek = [ 'Sunday', 'Monday', "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayIndex = daysOfWeek[date.getDay()];
        return dayIndex;
    }

    function formatDate(dateString) {
        const today = new Date();
        const tomorrow = new Date();
        const date = new Date(dateString);
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        tomorrow.setDate(today.getDate() + 1);
        if(date.toDateString() === today.toDateString()){
            return 'Today';
        }
        else if(date.toDateString() === tomorrow.toDateString()){
            return 'Tomorrow';
        }
        else {
            return getDay(date);
        }
    }


    function scrollPatientList(dateString) {
        const date = new Date(dateString);
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        if (date.toDateString() === new Date().toDateString()) {
            return todayRef;
        }
        return null;
    }


    useEffect(() => { // Automatically scroll to today's element after the data is loaded
        if (todayRef.current) {
            todayRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [patientList]);



    const isAllPatientsSelected = (patientGroup) => {
        return patientGroup.every((patient) => isPatientSelected(patient));
    };

    const handleSelectAllPatients = (patientGroup) => {
        const isAllSelected = isAllPatientsSelected(patientGroup);
        handleAllPatientsCheckbox(patientGroup, !isAllSelected);
    };
    return (
        <Row className="container-fluid p-0 m-0">
            <Col className="p-0">
                {Object.keys(patientList).map((key, index) => {
                    return (
                        <>
                            {!isEmpty(patientList[key].data) ? (
                                <>
                                    <Row id={`${componentName}-patientsSection-${index}`} className="mb-5">
                                        <Col>
                                            {!isEmpty(patientList[key].data) &&
                                                <div className="d-flex flex-row justify-content-between align-items-center mb-2"  ref={scrollPatientList(patientList[key].date)}>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="d-flex flex-column align-items-start">
                                                            <small id={`${componentName}-MonthandDay-${index}`}>{formatDateWithPattern(patientList[key].date, "MMMM d")}</small>
                                                            <h5 className="mb-0">
                                                                <span id={`${componentName}-DayoftheWeek-${index}`} className="me-2">{formatDate(patientList[key].date)}</span>
                                                                <Badge pill id={`${componentName}-PatientCount-${index}`} className="bg-primary">
                                                                    {patientList[key].totalSize.toLocaleString()}
                                                                </Badge>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <Button variant="secondary" id={`${componentName}-patientChartPrepTool`} onClick={()=>printHere(patientList[key].data)} className="border">
                                                        Print Chart Prep Tool
                                                    </Button>
                                                </div>
                                            }
                                            {patientList[key].data.map((patient, idx) =>
                                                <PatientRow
                                                    patient={patient}
                                                    quickListType={'SCHEDULE'}
                                                    idx={idx}
                                                    isPatientSelected={isPatientSelected}
                                                    handleChangeCheckBox={handleChangeCheckBox}
                                                    setSelectedPatient={setSelectedPatient}
                                                    setShowPatientDetails={setShowPatientDetails}
                                                    showPatientDetails={showPatientDetails}
                                                    key={key}
                                                    handleShowConfirmUnschedule={handleShowConfirmUnschedule}
                                                    showConfirmUnschedule={showConfirmUnschedule}
                                                    handleCloseConfirmUnschedule={handleCloseConfirmUnschedule}
                                                    handlePatientGapStatus={handlePatientGapStatus}
                                                    handleShowSchedule={handleShowSchedule}
                                                    handleShowNoShow={handleShowNoShow}
                                                    handleCloseNoShow={handleCloseNoShow}
                                                    showNoShow={showNoShow}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Row id={`${componentName}-patientsSection-${index}`} className="mb-5">
                                    <Col>
                                        <div className="d-flex flex-column align-items-start mb-2">
                                            <small id={`${componentName}-MonthandDay-${index}`}>{formatDateWithPattern(patientList[key].date, "MMMM d")}</small>
                                            <h5 className="mb-0">
                                                <span id={`${componentName}-DayoftheWeek-${index}`} className="me-2">{formatDate(patientList[key].date)}</span>
                                                <Badge pill id={`${componentName}-PatientCount-${index}`} className="bg-primary">
                                                    {patientList[key].totalSize.toLocaleString()}
                                                </Badge>
                                            </h5>
                                        </div>

                                        <Card>
                                            <Card.Body>
                                                <Card.Text id={`${componentName}-NoPatients`}>
                                                    No Patients Found
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                )
                            }
                            </>
                        );
                        }
                    )
                }
                <ScheduledAppointment
                    show={showSchedule}
                    onHide={handleCloseSchedule}
                    handlePatientGapStatus={handlePatientGapStatus}
                    patient={selectedPatient}
                    patientGapStatus="APPOINTMENT_SCHEDULED"
                />
                <Modals
                    centered={true}
                    show={confirmationMessage}
                    scrollable={true}
                    closeConfirmation={() => setConfirmationMessage(false)}
                    onCreate={() => closeEvidenceModalMain()}
                    onHide={() => setConfirmationMessage(false)}
                    size="sm"
                    title=""
                    titleid={`${componentName}-CloseWithoutSavingModal`}
                    closeBtn="NO"
                    closeBtnId={`${componentName}-closeNo`}
                    completeBtn="YES"
                    completeBtnId={`${componentName}-completeYes`}
                    hideFooter={false}
                    body="Do you want to close without saving?"
                />
                <Modals
                    scrollable={true}
                    show={showRecentVisitModal}
                    closeSubmitModal={() => setShowRecentVisitModal(false)}
                    onHide={() => {
                        setShowRecentVisitModal(false);
                        updateParent();
                    }}
                    size="xl"
                    title="Update Last Wellness Visit"
                    titleid={`${componentName}-SubmitRecentVisitModal`}
                    completeBtn="SUBMIT"
                    completeBtnId={`${componentName}-completeRecentVisitSubmit`}
                    hideFooter={true}
                    body={
                        <SubmitRecentVisitBody
                            patient={selectedPatient}
                            updateParent={updateParent}
                            closeSubmitModal={() => setShowRecentVisitModal(false)}
                        />
                    }
                />
            </Col>
        </Row>
    );
};

export default ScheduleListView;