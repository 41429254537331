import React, { useRef } from "react";

import { QuickLists } from "./QuickList";
import { useGetTransQuickListSettings } from "./quickListSettings";
import { useAuth } from "../../context/authContext";
import { QuickListContext } from "./QuickListContext";
import { QuickListNew } from "./QuickListNew";

const Schedule = () => {
  const auth = useAuth();
  const getSettingsRefScheduled = useGetTransQuickListSettings("schedule", 0, auth);

  return (
      <>
        <QuickListContext.Provider value={getSettingsRefScheduled()}>
            <QuickListNew {...getSettingsRefScheduled()} />
        </QuickListContext.Provider>
      </>
  );
};

export { Schedule };
