import React from 'react'
import ReassignCareStep from '../../../../../quickList/components/careProgramsMock/components/modals/reassignCareStep'
import { useCMWorklistContext } from '../context/CMWorklist.context';
import { Modal } from 'react-bootstrap';

function ReassignCareStepModal() {
    const {
        patient,
        reassignCareStep,
        toggleReassignCareStep
    } = useCMWorklistContext();
    return (
        <Modal show={reassignCareStep}>
            <ReassignCareStep step={patient?.carePathStepDto} handleCancel={() => toggleReassignCareStep(false)} />
        </Modal>
    )
}

export default ReassignCareStepModal