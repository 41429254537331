import React, { useEffect, useState } from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";
import { _calculateAge } from "../../../utils/util";
import Modals from "../../../components/modals";
import axios from "../../../utils/apiClient";
import { useLocation } from "react-router-dom";
import SubmitRecentVisitBody from "./../SubmitRecentVisit";
import ScheduledAppointment from "./modals/scheduleAppointment";
import { ROUTES } from "../../../utils/navigationItems";
import PatientRow from "./PatientRow";
import { useDispatch, useSelector } from "react-redux";
import { setReloadPatientList } from "../../../actions/manageVisitActions";
import { AppPropertiesUtil } from "../../../utils/localStorageUtil";
import { format } from "date-fns";

const componentName = "CardView";

export const referralTypes = [
  { text: "Specialist", value: "SPECIALIST" },
  { text: "Care Coordination (Equality Health)", value: "CARE_COORDINATION" },
];

const ListView = ({
                    patientList,
                    handleChangeCheckBox,
                    handleAllPatientsCheckbox,
                    workListStatusSize,
                    loadingData,
                    listViewLoadingData,
                    quickListType,
                    isPatientSelected,
                    updateParent,
                    closeEvidenceModal,
                    handleFilterChange,
                    filterParams,
                    pageNumber,
                    filterApplied,
                    selectedFilter,
                    activeFilterView
                  }) => {
  const isEmpty = require("is-empty");
  const dispatch = useDispatch();
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [showRecentVisitModal, setShowRecentVisitModal] = useState(false);
  const [errorText, setErrorText] = useState();
  const [showSchedule, setShowSchedule] = useState(false);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);
  const [showConfirmUnschedule, setShowConfirmUnschedule] = useState(false);
  const handleCloseConfirmUnschedule = () => setShowConfirmUnschedule(false);
  const [showNoShow, setShowNoShow] = useState(false);
  const handleCloseNoShow = () => setShowNoShow(false);
  const handleShowNoShow = () => setShowNoShow(true);
  const handleShowConfirmUnschedule = () => setShowConfirmUnschedule(true);
  const [showConfirmUnableToContact, setShowConfirmUnableToContact] = useState(false);
  const handleCloseConfirmUnableToContact = () => setShowConfirmUnableToContact(false);
  const handleShowUnableToContact = () => setShowConfirmUnableToContact(true);
  const [showConfirmLeftMessage, setShowConfirmLeftMessage] = useState(false);
  const handleCloseConfirmLeftMessage = () => setShowConfirmLeftMessage(false);
  const handleShowLeftMessage = () => setShowConfirmLeftMessage(true);
  const [showConfirmChangedPCP, setShowConfirmChangedPCP] = useState(false);
  const handleCloseConfirmChangedPCP = () => setShowConfirmChangedPCP(false);
  const handleShowChangedPCP = () => setShowConfirmChangedPCP(true);
  const [showConfirmPatientRefused, setShowConfirmPatientRefused] = useState(false);
  const handleCloseConfirmPatientRefused = () => setShowConfirmPatientRefused(false);
  const handleShowPatientRefused = () => setShowConfirmPatientRefused(true);
  
  const visitPatient = useSelector((state) => state?.manageVisitReducer?.patient);
  const reloadPatients = useSelector((state) => state?.manageVisitReducer?.reload);

  useEffect(() => {
    if (reloadPatients) {
      handleFilterChange(filterParams, pageNumber);
      dispatch(setReloadPatientList(false));
    }
  }, [reloadPatients]);

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
    closeEvidenceModal();
  };

  const quickListState = useLocation();
  let pageTitle = quickListState.state ? quickListState.state.pageTitle : "";
  if (!pageTitle) {
    const pathName = quickListState.pathname ? quickListState.pathname : "";
    Object.entries(ROUTES).map(([key, value]) => {
      if (value && pathName && value.path && value.path.split("/").join("") === pathName.split("/").join("")) {
        pageTitle = key;
      }
    });
  }

  const handlePatientGapStatus = (patientGapStatus, patientGapAppointmentDate) => {
    let patientId = selectedPatient.id;
    let patientGap = {}
    if(patientGapStatus && patientGapStatus ==='NO_SHOW'){
      patientGap = {
        appointmentCancelReason: "NO_SHOW",
        appointmentCancelDate: selectedPatient.patientGapAppointmentDate,
        patientGapStatus: "APPOINTMENT_UNSCHEDULED",
        gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(" ", "T") + "Z",
      };
    } else {
      patientGap = {
        patientGapStatus: patientGapStatus,
          gapStatusChangeDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS").replace(' ', 'T') + 'Z',
        patientGapAppointmentDate: patientGapAppointmentDate,
        quickFilterName:activeFilterView,

      };
    }
    return axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + `/v1/patients/updatePatientGapStatus?id=${patientId}`,
        patientGap,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(async (response) => {
        await handleFilterChange(filterParams, pageNumber);
        handleCloseConfirmUnableToContact();
        handleCloseConfirmLeftMessage();
        handleCloseConfirmChangedPCP();
        handleCloseConfirmPatientRefused();
      })
      .catch((error) => {
        setErrorText("Error");
      });
  };

  useEffect(() => {
    if (visitPatient?.id) {
      setSelectedPatient(visitPatient);
      setShowPatientDetails(true);
    }
  }, [visitPatient]);

  const quickListMOType = (patient) => {
    if (patientList.requiredActivities && patientList.requiredActivities.includes(patient)) {
      return 'requiredActivities';
    }
    if (patientList.awaitingChart && patientList.awaitingChart.includes(patient)) {
      return 'awaitingChart';
    }
    if (patientList.rejected && patientList.rejected.includes(patient)) {
      return 'rejected';
    }
    return 'other';
  };

  const titles =
    quickListType && quickListType === "MISSED_OPPORTUNITIES"
      ? {
          requiredActivities: ["Required Activities", workListStatusSize[0]],
          awaitingChart: ["Awaiting Chart", workListStatusSize[1]],
          rejected: ["Rejected", workListStatusSize[2]],
        }
      : {
          scheduledPatients: ["Scheduled", workListStatusSize[0]],
          unScheduledPatients: ["Unscheduled", workListStatusSize[1]],
          unableToContact: ["Unable to Contact", workListStatusSize[2]],
          patientRefused: ["Patient Refused", workListStatusSize[3]],
          leftMessage: ["Left Message", workListStatusSize[4]],
          changedPCP: ["Changed PCP", workListStatusSize[5]],
        };
  const isAllPatientsSelected = (patientGroup) => {
    return patientGroup.every((patient) => isPatientSelected(patient));
  };

  const handleSelectAllPatients = (patientGroup) => {
    const isAllSelected = isAllPatientsSelected(patientGroup);
    handleAllPatientsCheckbox(patientGroup, !isAllSelected);
  };
  const convertNumber = (number) => {
    if (number !== null && !isNaN(number)) {
      return number.toLocaleString();
    } else {
      return "";
    }
  };
  return (
    <Row className="container-fluid p-0 m-0">
      <Col className="p-0">
        {Object.keys(patientList).map((key, index) => {
          return (
            <>
              {!isEmpty(patientList[key]) ? (
                <>
                  <Row
                    id={`${componentName}-patientsSection-${index}`}
                    key={`${componentName}-patientsSection-${index}`}
                    className="mb-5"
                  >
                    <Col key={`${componentName}-patientsSection-${index}`}>
                      {titles[key] && !isEmpty(patientList[key]) && (key != "scheduledPatients") && (
                        <div className="d-flex flex-row align-items-center mb-2">
                          <div className="form-check">
                            <input
                              id={`${componentName}-patientSelectAll-${index}`}
                              className="form-check-input me-3"
                              type="checkbox"
                              name={`${componentName}-patientSelectAll-${index}`}
                              checked={isAllPatientsSelected(patientList[key])}
                              onChange={() => handleSelectAllPatients(patientList[key])}
                            />
                            <label htmlFor="basic_checkbox_2"></label>
                          </div>
                          <h4 id={`${componentName}-patientsSectionTitle-${index}`} className="mb-0 me-3">
                            {titles[key][0]}
                          </h4>
                          <Badge
                            pill
                            bg="light"
                            id={`${componentName}-PatientCount-${index}`}
                            className="border text-dark"
                          >
                            {convertNumber(titles[key][1])} Patients
                          </Badge>
                        </div>
                      )}

                      {patientList[key].map((patient, idx) => (
                        <PatientRow
                          quickListType={quickListType}
                          patient={patient}
                          idx={idx}
                          isPatientSelected={isPatientSelected}
                          handleChangeCheckBox={handleChangeCheckBox}
                          setSelectedPatient={setSelectedPatient}
                          setShowPatientDetails={setShowPatientDetails}
                          showPatientDetails={showPatientDetails}
                          pageTitle={pageTitle}
                          key={key}
                          handleShowConfirmUnschedule={handleShowConfirmUnschedule}
                          handleCloseNoShow={handleCloseNoShow}
                          handleShowNoShow={handleShowNoShow}
                          showNoShow={showNoShow}
                          showConfirmUnschedule={showConfirmUnschedule}
                          handleCloseConfirmUnschedule={handleCloseConfirmUnschedule}
                          handlePatientGapStatus={handlePatientGapStatus}
                          handleShowSchedule={handleShowSchedule}
                          handleShowUnableToContact={handleShowUnableToContact}
                          showConfirmUnableToContact={showConfirmUnableToContact}
                          handleCloseConfirmUnableToContact={handleCloseConfirmUnableToContact}
                          handleShowLeftMessage={handleShowLeftMessage}
                          showConfirmLeftMessage={showConfirmLeftMessage}
                          handleCloseConfirmLeftMessage={handleCloseConfirmLeftMessage}
                          handleShowChangedPCP={handleShowChangedPCP}
                          showConfirmChangedPCP={showConfirmChangedPCP}
                          handleCloseConfirmChangedPCP={handleCloseConfirmChangedPCP}
                          handleShowPatientRefused={handleShowPatientRefused}
                          showConfirmPatientRefused={showConfirmPatientRefused}
                          handleCloseConfirmPatientRefused={handleCloseConfirmPatientRefused}
                          listType={quickListMOType(patient)}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              ) : (quickListType === "USER_WORK_LIST" ||  quickListType === "MISSED_OPPORTUNITIES") &&
                key != "default" &&
                (!filterApplied || (filterApplied && selectedFilter.includes(key))) &&
                (key != "scheduledPatients") ? (
                !loadingData &&
                !listViewLoadingData && (
                  <Row id={`${componentName}-patientsSection-${index}`} className="mb-5">
                    <Col>
                      <div className="d-flex flex-row align-items-center">
                        <h4 id={`${componentName}-patientsSectionTitle-${index}`} className="me-3">
                          {titles[key][0]}
                        </h4>
                        <h5>
                          <span
                            bg="light"
                            id={`${componentName}-patientCount-${index}`}
                            className="border text-dark badge badge-pill"
                          >
                            {titles[key][1]} {"Patients"}
                          </span>
                        </h5>
                      </div>
                      <Card>
                        <Card.Body>
                          <Card.Text id={`${componentName}-NoPatients`}>No Patients Found</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )
              ) : null}
            </>
          );
        })}
        <ScheduledAppointment
          show={showSchedule}
          onHide={handleCloseSchedule}
          handlePatientGapStatus={handlePatientGapStatus}
          patient={selectedPatient}
          patientGapStatus="APPOINTMENT_SCHEDULED"
        />
        <Modals
          centered={true}
          show={confirmationMessage}
          scrollable={true}
          closeConfirmation={() => setConfirmationMessage(false)}
          onCreate={() => closeEvidenceModalMain()}
          onHide={() => setConfirmationMessage(false)}
          size="sm"
          title=""
          titleid={`${componentName}-CloseWithoutSavingModal`}
          closeBtn="NO"
          closeBtnId={`${componentName}-closeNo`}
          completeBtn="YES"
          completeBtnId={`${componentName}-completeYes`}
          hideFooter={false}
          body="Do you want to close without saving?"
        />
        <Modals
          scrollable={true}
          show={showRecentVisitModal}
          closeSubmitModal={() => setShowRecentVisitModal(false)}
          onHide={() => {
            setShowRecentVisitModal(false);
            updateParent();
          }}
          size="xl"
          title="Update Last Wellness Visit"
          titleid={`${componentName}-SubmitRecentVisitModal`}
          completeBtn="SUBMIT"
          completeBtnId={`${componentName}-completeRecentVisitSubmit`}
          hideFooter={true}
          body={
            <SubmitRecentVisitBody
              patient={selectedPatient}
              updateParent={updateParent}
              closeSubmitModal={() => setShowRecentVisitModal(false)}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default ListView;
