import { actions } from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE_ACCEPTED:
      return {
        ...state,
        showAccepted: action.payload,
      };
    case actions.TOGGLE_CANCELLED:
      return {
        ...state,
        showCancelled: action.payload
      }
    case actions.TOGGLE_PATIENT_CANCELLED:
      return {
        ...state,
        showPatientCancelled: action.payload
      }
    case actions.TOGGLE_DECLINED:
      return {
        ...state,
        showDeclined: action.payload
      }
    case actions.TOGGLE_PENDING:
      return {
        ...state,
        showPending: action.payload
      }
    case actions.TOGGLE_COMPLETED:
      return {
        ...state,
        showCompleted: action.payload
      }
    case actions.SET_REFERRAL:
      return {
        ...state,
        referral: action.payload,
      };
    case actions.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actions.SET_REFERRALS_LIST:
      return {
        ...state,
        referralsList: action.payload,
      };
    case actions.SET_TOTAL_SIZE:
      return {
        ...state,
        totalSize: action.payload,
      };
    default:
      return state;
  }
};