export const initialState = {
  visitsDetails: {},
  patient: {},
  showClosedOrInactive: false,
  unsavedVisitsMap: {},
  isSavingActivities: false
};

export const actions = {
  SET_VISITS_DETAILS: 'SET_VISITS_DETAILS',
  SET_PATIENT: 'SET_PATIENT',
  TOGGLE_SHOW_CLOSED_OR_INACTIVE: 'TOGGLE_SHOW_CLOSED_OR_INACTIVE',
  UPDATE_UNSAVED_VISITS_MAP: 'UPDATE_UNSAVED_VISITS_MAP',
  CLEAR_UNSAVED_VISITS_MAP: 'CLEAR_UNSAVED_VISITS_MAP',
  TOGGLE_IS_SAVING_ACTIVITIES: 'TOGGLE_IS_SAVING_ACTIVITIES',
};