import React, { useState } from "react";
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { Button } from "./buttons";

const ReactAlert = ({ title, onHide, text }) => {
  return (
    <Modal
      onHide={onHide}
      show={text ? true : false}
      size="xl"
      screenbool={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <textarea
                style={{ overflowY: "auto", width: "100%", height: "72vh" }}
              >
                {text}
              </textarea>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReactAlert;
