import React from "react";
import Modals from "../../../../../../components/modals";

const SubstanceAbuseWarningModal = ({
                                        showModal, icd10Info, onConfirm, onCancel
                                    }) => {

    return (<Modals
        show={showModal}
        onHide={() => onCancel(icd10Info)}
        title={<span>Message</span>}
        size="md"
        hideClickButton={false}
        hideFooter={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        body={<span>
            By clicking <b>Confirm</b>, you attest that you have received the
            member's consent to submit a referral related to <b>substance abuse</b> treatment.
          </span>}
        onCreate={() => onConfirm(icd10Info)}
        closeBtn={"Cancel"}
        completeBtn={"Confirm"}
    />)
}

export default SubstanceAbuseWarningModal;