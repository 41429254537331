import {
  ADD_USER_TO_CONVERSATION,
  CREATE_NEW_CONVERSATION,
  CREATE_NEW_MESSAGE,
  DELETE_MESSAGES,
  GET_USER_MESSAGES,
  GET_ALL_USER_MESSAGES,
  GET_USER_MESSAGES_COUNT,
  SEND_MESSAGE,
  MARK_READ,
  GET_MESSAGES_FROM_CONVO,
  MESSAGE_TYPES
} from "../../../actions/types";

const initialState = {
  messages: [],
  count: "",
  conversationId: null,
  conversationMessages: [],
  replyMessageSuccess:false,
  messageTypes: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_MESSAGES:
      return {
        ...state,
        messages: payload && payload.messages && payload.messages.length ? payload.messages : ["Empty"],
        totalNumberOfMessages: payload && payload.totalNumberOfMessages ? payload.totalNumberOfMessages : 0,
      };
    case GET_ALL_USER_MESSAGES:
      return {
        ...state,
        messages: payload && payload.messages && payload.messages.length ? payload.messages : ["Empty"],
        totalNumberOfMessages: payload && payload.totalNumberOfMessages ? payload.totalNumberOfMessages : 0,
      };
    case GET_USER_MESSAGES_COUNT:
      return {
        ...state,
        count: payload,
      };
    case GET_MESSAGES_FROM_CONVO:
      return {
        ...state,
        conversationMessages: payload,
      };

    case CREATE_NEW_MESSAGE:
      return {
        ...state,
        newMessage: state.conversationMessages && state.conversationMessages.messages && state.conversationMessages.messages.length && state.conversationMessages.messages.push(payload),
        replyMessageSuccess: true
      };
    case MARK_READ:
      return {
        ...state
      };
    case CREATE_NEW_CONVERSATION:
      return {
        ...state,
        newConversation: payload
      }
    case ADD_USER_TO_CONVERSATION:
      return {
        ...state,
        addedUser: payload
      }
    case DELETE_MESSAGES:
      return {
        ...state
      }
    case "RESET_BOOLEAN_REPLY":
      return {
        ...state,
        replyMessageSuccess: false
      }
    case MESSAGE_TYPES:
      return {
        ...state,
        messageTypes: payload
      }
      
    default:
      return state;
  }
}
