import React from 'react'
import { VisitContainer } from './components'
import { VisitProvider } from './context/Visit.context'

function VisitTab({ ...props }) {
  return (
    <VisitProvider>
        <VisitContainer {...props} />
    </VisitProvider>
  )
}

export default VisitTab