import React, { useState, useEffect } from "react";
import { Card, Col, Row, Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { declineReason } from "../utils/textValueLists";
import { Button as EQHButton } from "../components/buttons";
import { useLocation } from "react-router-dom";
import {
  declineReferral,
  useGetIsDeclineReferralTokenValid,
} from "../actions/declineReferralActions";
import styled from "styled-components";
import backgroundImage from "../img/ce-authbg.jpg";
import { EQHSpinner } from "../components/EQHSpinner";

const BackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  background: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const componentName = "DeclineReferral";
const SUCCESSFUL_MSG =
  "Successful! A decline email notification has been sent to the referring practice.";
const INVALID_TOKEN_MSG =
  "Referral has already been declined or token is invalid.";

const closeCurrentTab = () => {
  window.open("about:blank", "_self");
  window.close();
};

export const DeclineReferral = ({}) => {
  const location = useLocation();
  const [reason, setReason] = useState("CURRENTLY_NOT_ACCEPTING_NEW_REFERRALS");
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState("");
  const tokenValidPkg = useGetIsDeclineReferralTokenValid({});
  const token = new URLSearchParams(location.search).get("token");

  const showResultToast = (msg) => {
    setResult(msg);
    setShowResult(true);
  };

  useEffect(() => {
    tokenValidPkg.fetchData(token);
  }, [token]);

  const submit = () => {
    declineReferral({
      token,
      reason: reason,
      source: "EMAIL",
    })
      .then((res) => {
        showResultToast(res.data?.error ?? SUCCESSFUL_MSG);
      })
      .catch((err) => showResultToast(err.message));
  };

  return (
    <BackgroundDiv>
      {tokenValidPkg.state.isLoading ? (
        <EQHSpinner />
      ) : tokenValidPkg.state.data.error ? (
        <Card
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Card.Body>{INVALID_TOKEN_MSG}</Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Toast
              onClose={() => setShowResult(false)}
              show={showResult}
              delay={4000}
              autohide
          >
            <Toast.Header>
              <strong className="me-auto fw-bold text-danger">
                <i
                    id={`${componentName}-Response`}
                    className="fas fa-exclamation-triangle"
                ></i>{" "}
              </strong>
              <small id={`${componentName}-ResponseTime`}>now</small>
            </Toast.Header>
            <Toast.Body>
                  <span
                      className="className"
                      id={`${componentName}-ResponseText`}
                  >
                    {result}
                  </span>
            </Toast.Body>
          </Toast>
          {result !== SUCCESSFUL_MSG && (
          <Card.Body>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  id={`${componentName}-ReasonDDL`}
                >
                  {declineReason.map((x) => (
                    <option
                      key={`${componentName}-${x.value}-ReasonDDLItem`}
                      id={`${componentName}-${x.value}-ReasonDDLItem`}
                      value={x.value}
                    >
                      {x.text}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Card.Footer>
                <>
                  <EQHButton id={`${componentName}-ok-btn`} onClick={submit}>
                    Ok
                  </EQHButton>
                  <EQHButton
                    id={`${componentName}-cancel-btn`}
                    onClick={closeCurrentTab}
                  >
                    Cancel
                  </EQHButton>
                </>
            </Card.Footer>
          </Card.Body>
          )}
        </Card>
      )}
    </BackgroundDiv>
  );
};
