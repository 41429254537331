import React from 'react'
import {NewRRProvider} from './context/NewRR.context'
import Main from './Main'
import ExportSummaryModal from './components/ExportSummaryModal'
import SaveAsCustomWorklist from './components/SaveAsCustomWorklist'
import DeleteCustomWorklist from './components/DeleteCustomWorklist'
import {CustomWorklistProvider} from "../../../components/CustomWorklist";

const NewReferralsReceived = () => {
  return (
    <CustomWorklistProvider>
      <NewRRProvider>
        <Main/>
        <ExportSummaryModal/>
        <SaveAsCustomWorklist/>
        <DeleteCustomWorklist/>
      </NewRRProvider>
    </CustomWorklistProvider>
  )
}

export {NewReferralsReceived}