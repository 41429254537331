import React from "react";
import styled from "styled-components";
import Alert from "react-bootstrap/Alert";

const StyledAlert = styled(Alert)`
  border-radius: 0;
`;

const ResponseAlert = ({ response, success, error }) => {
  if (!response) {
    return null;
  }
  const [errorResponse] = response;
  const variant = errorResponse ? "danger" : "success";
  const message = errorResponse?.response?.data?.message;
  const errorMessage = message ? message : error;
  return <StyledAlert variant={variant}>{errorResponse ? errorMessage : success}</StyledAlert>;
};

ResponseAlert.defaultProps = {
  success: "Success!",
  error: "Something went wrong"
};

export { StyledAlert as Alert, ResponseAlert };
