import axios from "axios";
import { format } from "date-fns";
import { FILTER_DEFINITIONS_MAP } from "../../../components/filters/filterDefinition";
import { formatDateVanilla } from "../../../utils/util";
import { DDLMode } from "../../../components/dropDownLists/genericDDL";
import { getOutcomes, getScheduleWithOptions, getTypes } from "../careTeam/FollowUpModal/util";

export const componentName = "ReportingAnalytics";
export const SUMMARY_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";
export const READMISSIONS_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/configuration/care-management-configuration";
const DROPDOWN_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/configuration/care-management-configuration-filter";

export const columns = [
  'Member name',
  'EHNID',
  'Member id',
  'Program name',
  'Program Lead',
  'Current status',
  'Payer',
  'Line of business',
  'Risk Level',
  'Intervention type',
  'Outcome',
  'Channel',
  'Source',
  'Provider TIN',
  'Provider Name',
  'Practice Name'
]

export const filtersList = [
  [
    { filterName: 'Time Range', key: 'rangeDate', required: true },
    {
      filterName: 'Status Type',
      key: 'isMostRecent',
      required: true,
      mode: DDLMode.SelectOne,
    },
    {
      filterName: 'Line of Business',
      key: 'lineOfBusiness',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
  ],
  [
    {
      filterName: 'Payer SOO',
      key: 'payer',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Teams',
      key: 'teamName',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Intervention type',
      key: 'interventionTypeId',
      required: false,
      mode: DDLMode.SelectOne,
    },
  ],
  [
    {
      filterName: 'Intervention channel',
      key: 'interventionChannelId',
      required: false,
      mode: DDLMode.SelectOne,
    },
    {
      filterName: 'Intervention source',
      key: 'interventionSourceId',
      required: false,
      mode: DDLMode.SelectOne,
    },
    {
      filterName: 'Intervention outcome',
      key: 'interventionOutcomeId',
      required: false,
      mode: DDLMode.SelectOne,
    },
  ],
  [
    {
      filterName: 'Program Status',
      key: 'programStatus',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Care Program',
      key: 'careProgram',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Referral Source',
      key: 'referralSource',
      required: false,
      mode: DDLMode.MultipleSelect,
    }
  ],
  [
    {
      filterName: 'Program Lead',
      key: 'programLeads',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Interaction type',
      key: 'interactionTypeId',
      required: false,
      mode: DDLMode.MultipleSelect,
    },
    {
      filterName: 'Interaction outcome',
      key: 'interactionOutcomeId',
      required: false,
      mode: DDLMode.MultipleSelect,
    }
  ],
  [
    {
      filterName: 'FollowUp category',
      key: 'followUpCategories',
      required: false,
      mode: DDLMode.SelectOne,
    },
    {
      filterName: 'reAdmission',
      key: 'reAdmission',
      required: false,
      mode: DDLMode.SelectOne,
    },
    {
      filterName: 'Risk Level',
      key: 'riskLevel',
      required: false,
      mode: DDLMode.MultipleSelect,
    }
  ]
]

const bodyFilters = {
  isMostRecent: {
    key: 'isMostRecent',
    storage: 'paramValue'
  },
  lineOfBusiness: {
    key: 'lineOfBusiness',
    storage: 'displayValue',
  },
  payer: {
    key: 'payer',
    storage: 'displayValue',
  },
  teamName: {
    key: 'teamName',
    storage: 'displayValue',
  },
  interventionTypeId: {
    key: 'interventionTypeId',
    storage: 'paramValue'
  },
  interventionChannelId: {
    key: 'interventionChannelId',
    storage: 'paramValue'
  },
  interventionSourceId: {
    key: 'interventionSourceId',
    storage: 'paramValue'
  },
  interventionOutcomeId: {
    key: 'interventionOutcomeId',
    storage: 'paramValue'
  },
  programStatus: {
    key: 'programStatus',
    storage: 'displayValue',
  },
  careProgram: {
    key: 'programName',
    storage: 'displayValue',
  },
  referralSource: {
    key: 'referralSource',
    storage: 'displayValue',
  },
  programLeads: {
    key: 'programLeadIds',
    storage: 'paramValue',
    isJoined: true
  },
  interactionTypeId: {
    key: 'interactionTypeId',
    storage: 'paramValue'
  },
  interactionOutcomeId: {
    key: 'interactionOutcomeId',
    storage: 'paramValue'
  },
  reAdmission: {
    key: 'reAdmission',
    storage: 'paramValue'
  },
  riskLevel: {
    key: 'riskLevel',
    storage: 'paramValue',
    isJoined: true
  }
}

export const getOptFilters = (followUpCategory) => {
  if (followUpCategory === '') {
    return []
  }

  const optFilters = [
    [
      {
        filterName: 'FollowUp type',
        key: 'followUpTypes',
        required: true,
        mode: DDLMode.MultipleSelect,
        getData: () => getTypes()
          .then(res => res.data
            .filter(x => x.key === followUpCategory)
            .map(x => { return { value: x.value, text: x.value } })
          )
      },
      {
        filterName: 'FollowUp Schedule With',
        key: 'followUpScheduledWith',
        required: true,
        mode: DDLMode.MultipleSelect,
        getData: () => getScheduleWithOptions()
          .then(res => res.data
            .filter(x => x.key === followUpCategory)
            .map(x => { return { value: x.value, text: x.value } })
          ),
      },
      {
        filterName: 'FollowUp Outcome',
        key: 'followUpOutcomes',
        required: true,
        mode: DDLMode.MultipleSelect,
        getData: () => getOutcomes()
          .then(res => res.data
            .filter(x => x.key === followUpCategory)
            .map(x => { return { value: x.value, text: x.value } })
          ),
      }
    ]
  ]

  followUpCategory === 'SDOH' && delete optFilters?.[0]?.[1]

  return optFilters
}

function buildBody(filters) {
  let body = {};

  Object.keys(filters).forEach(k => {
    if (bodyFilters?.[k]) {
      const { key, storage } = bodyFilters?.[k];
      if (storage == 'displayValue') {
        body[key] = filters?.[k]?.displayValue?.join('|') || ''
      } else {
        let value = filters?.[k]?.paramValue;
        body[key] = bodyFilters?.[k]?.isJoined
          ? value?.join('|') || ''
          : value[0]
      }
    }
  })

  return body
}

export const getPatientPrograms = (filters, followUpFilters, page) => {
  const currentDate = new Date();
  const firstDayDate = formatDateVanilla(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
  const fromDate = filters?.rangeDate?.paramValue?.[0]
    ? formatDateVanilla(filters?.rangeDate?.paramValue?.[0], "yyyy-MM-dd")
    : firstDayDate;
  const toDate = filters?.rangeDate?.paramValue?.[1]
    ? formatDateVanilla(filters?.rangeDate?.paramValue?.[1], "yyyy-MM-dd")
    : formatDateVanilla(currentDate, "yyyy-MM-dd");

  const body = {
    pageNumber: page,
    pageSize: 50,
    fromDate,
    toDate,
    followUpCategories: filters?.followUpCategories?.paramValue[0],
    followUpOutcomes: followUpFilters?.followUpOutcomes?.paramValue?.join("|") || '',
    followUpTypes: followUpFilters?.followUpTypes?.paramValue?.join("|") || '',
    followUpScheduledWith: followUpFilters?.followUpScheduledWith?.paramValue?.join("|") || '',
    ...buildBody(filters)
  }

  return axios.post(
     process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-management/patient-programs', body
  );
}

export const exportPatientPrograms = (filters, followUpFilters, page) => {
  const currentDate = new Date();
  const firstDayDate = formatDateVanilla(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
  const fromDate = filters?.rangeDate?.paramValue?.[0]
    ? formatDateVanilla(filters?.rangeDate?.paramValue?.[0], "yyyy-MM-dd")
    : firstDayDate;
  const toDate = filters?.rangeDate?.paramValue?.[1]
    ? formatDateVanilla(filters?.rangeDate?.paramValue?.[1], "yyyy-MM-dd")
    : formatDateVanilla(currentDate, "yyyy-MM-dd");

  const body = {
    pageNumber: page,
    pageSize: 50,
    fromDate,
    toDate,
    followUpCategories: filters?.followUpCategories?.paramValue[0],
    followUpOutcomes: followUpFilters?.followUpOutcomes?.paramValue?.join("|") || '',
    followUpTypes: followUpFilters?.followUpTypes?.paramValue?.join("|") || '',
    followUpScheduledWith: followUpFilters?.followUpScheduledWith?.paramValue?.join("|") || '',
    ...buildBody(filters)
  }

  return axios.post(
    process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-management/patient-programs/export', body
  );
}

export const getFilterDef = (key, componentProps, replaceHeader) => {
  const fd = FILTER_DEFINITIONS_MAP[key];
  const fdClone = componentProps ? { ...fd, componentProps: { ...fd.componentProps, ...componentProps } } : { ...fd };

  if (replaceHeader) {
    fdClone.getHeader = () => replaceHeader;
  }

  return fdClone;
};


export const formatRangeDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd');
}

export const checkRange = (range) => {
  return new Date(range[0]) <= new Date(range[1]);
}

export const getExportSummary = () => {
  return axios
    .get(SUMMARY_URL, {
      headers: {
        "Content-Type": "application/json",
      },
    });
}

export const getInteractionOutcomeDropDown = async () => {
  return axios.get(DROPDOWN_URL, {
    params: {
      "dropListName": "INTERACTION_OUTCOME"
    }
  });
}
export const getInteractionTypeDropDown = async () => {
  return axios.get(DROPDOWN_URL, {
    params: {
      "dropListName": "INTERACTION_TYPES"
    }
  });
}
export const getReAdmissionDropDown = async () => {
  return axios.get(READMISSIONS_URL, {
    params: {
      "dropListName": "RE_ADMISSION_DAYS",
      "status": "ACTIVE"
    }
  });
}