import axios from "axios";

export const defaultState = {
    action: 'REASSIGN',
    programLeadId: '',
    notes: '',
    origin: 'MY_WORKSPACE'
}

export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/hub-workcenter/reassign-member";

export const reassignMember = (params) => {
    return axios
        .put(URL, [{
            ...params,
        }]);
}