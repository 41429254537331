import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import axios from "../../utils/apiClient";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Button as OurButton } from "../../components/buttons";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  DDLDataMode,
  DDLMode,
  GENERIC_DDL_TYPE,
} from "../../components/dropDownLists/genericDDL";
import { Filter } from "../../components/filters/filterGroup";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import { FileUpload } from "../../components/FileUpload";

const componentName = "patientProfile-AddNote";

const isEmpty = require("is-empty");
const FILE_UPLOAD_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/file-upload";
const SAVE_NOTE_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-notes/save";

const RedAsterisk = styled.span`
  color: red;
`;

const NOTE_TYPES = [
  {
    text: "Care Team", 
    value: "Care Team",
    subItems: [
      {text: "Care Team - Visit: Face to Face", value: "CARE_TEAM_VISIT_FACE_TO_FACE"},
      {text: "Care Team - Visit: Hospital", value: "CARE_TEAM_VISIT_HOSPITAL"},
      {text: "Care Team - Visit: Provider", value: "CARE_TEAM_VISIT_PROVIDER"},
      {text: "Care Team - Chart Prep", value: "CARE_TEAM_CHART_PREP"},
      {text: "Care Team - Telephonic Outreach", value: "CARE_TEAM_TELEPHONIC_OUTREACH"},
      {text: "Care Team - Email", value: "CARE_TEAM_EMAIL"},
      {text: "Care Team - Text", value: "CARE_TEAM_TEXT"},
      {text: "Care Team - General", value: "CARE_TEAM_GENERAL"}
    ]
  },
  {text: "Assessments", value: "ASSESSMENT"},
  {text: "Attachments", value: "ATTACHMENT"},
  {text: "Behavioral Health Record", value: "BEHAVIORAL_HEALTH_RECORD",},
  {text: "Care Plan", value: "CARE_PLAN"},
  {text: "Consult Notes", value: "CONSULT_NOTES"},
  {text: "Discharge Summary", value: "DISCHARGE_SUMMARY",},
  {text: "Health Plan Notification", value: "HEALTH_PLAN_NOTIFICATION",},
  {text: "Important Notes", value: "IMPORTANT_NOTES",},
  {text: "Inbound Call", value: "INBOUND_CALL"},
  {text: "Lab Record", value: "LAB_RECORD"},
  {text: "Medication Record", value: "MEDICATION_RECORD"},
  {text: "Member Communication", value: "MEMBER_COMMUNICATION",},
  {text: "OP Report", value: "OP_REPORT"},
  {text: "Other", value: "OTHER"},
  {text: "Outbound Call", value: "OUTBOUND_CALL"},
  {text: "Physical Health Record", value: "PHYSICAL_HEALTH_RECORD",},
  {text: "Provider Plan Notification", value: "PROVIDER_PLAN_NOTIFICATION",},
  {text: "Radiology Record", value: "RADIOLOGY_RECORD",},
];

const AddNote = ({patientId, show, setShow, handleClose, handleChange, reloadPatientOverview, notesPkg}) => {
  const auth = useAuth();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [note, setNote] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileUploadResetCounter, setFileUploadResetCounter] = useState(0);

  const clear = () => {
    setUploadedFiles([]);
    setFileUploadResetCounter(fileUploadResetCounter + 1);
    setNote(null);
    setNoteType(null);
  }

  const handleUploadFileChange = (fileList) => {
    //handleChange("addImportantNote-files", arr);
    setUploadedFiles(fileList);
  };

  const getAddNoteRequestBody = () => {
    return {
      patientId: patientId,
      files: uploadedFiles.map(file => {
        return {
          fileName: file.fileName,
          id: file.fileId
        };
      }),
      noteText: note,
      noteType: noteType,
      createdOn: new Date()
    };
  }

  const addNote = () => {
    setShowSpinner(true);

    let body = getAddNoteRequestBody();
    axios
      .post(SAVE_NOTE_URL, body, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
        setShowSpinner(false);
        clear();
        notesPkg.fetchData(patientId);
        reloadPatientOverview();
        setShow(false);
      })
      .catch((err) => {
        setShowSpinner(false);
      });
  };

  const isSubmitDisabled = () => {
    if(showSpinner){
      return true;
    }
    if(isEmpty(noteType)){
      return true;
    }
    if(!isEmpty(uploadedFiles) || !isEmpty(note)){
      return false;
    }

    return true;
  }

  return (
    <Modal size="lg" show={show} onHide={() => {handleClose(); clear()}}>
      <Modal.Header>
        <Modal.Title>Add Note</Modal.Title>
        <button id={`${componentName}-ModalClose`} type="button" className="btn-close" aria-label="Close" onClick={() => {handleClose(); clear();}}></button>
      </Modal.Header>
      <Modal.Body>
        {/*<EQHErrorToast errors={errors} handleClose={() => setErrors([])} />*/}
        <Row className="mb-2">
          <Col>
            <div id={`${componentName}-noteWarning`} className="text-danger fw-bold">
              For Part 2 Providers: By submitting this note, you attest that you
              have received patient consent to share through CareEmpower any
              information containing patient Substance Use Disorder, as defined in
              42 CFR Part 2.
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Control
              as="textarea"
              id={`${componentName}-noteValue`}
              placeholder="Add Note"
              value={note}
              onChange={(e) => {
                setNote(e.target.value)
              }}
              rows={4}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FileUpload onChange={handleUploadFileChange} clearStateProp={fileUploadResetCounter} />
          </Col>
            <Col>
            <Filter
                handleChange={(e) => setNoteType(e.paramValue[0])}
                filterDefinition={{
                  type: GENERIC_DDL_TYPE,
                  getHeader: () => "Category",
                  key: "Note",
                  componentProps: {
                    mode: DDLMode.SelectOne,
                    dataMode: DDLDataMode.OneAPICallFilterInUI,
                    showSearchTextbox: false,
                    highlightWhenHasValue: false,
                    showClearButton: true,
                    getData: (srchTxt) =>
                      Promise.resolve(NOTE_TYPES),
                  },
                }}
              />
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <OurButton id={`${componentName}-cancelButton`} onClick={() => {handleClose(); clear();}}>Cancel</OurButton>
        <OurButton id={`${componentName}-submitButton`} onClick={addNote} disabled={isSubmitDisabled()}>Submit</OurButton>
        {showSpinner && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export { AddNote };
