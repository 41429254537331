import React from "react";
import Toast from "react-bootstrap/Toast";

const EQHErrorToast = ({ id, errors, handleClose, styleRemove, style, isList }) => {
  const newStyle = style ? style : styleRemove ? {}: { position: "fixed" , top: "70px" , right: "30px" , zIndex: "1060" }
  return (
    <Toast  id={`${id}-toast`} onClose={handleClose} show={errors?.length ? true : false} style={newStyle}>
      <Toast.Header id={`${id}-toast-header`}>
        <strong className="me-auto fw-bold text-danger">
          <i className="fas fa-exclamation-triangle"></i> Error
        </strong>
        <small>Just Now</small>
      </Toast.Header>
      <Toast.Body id={`${id}-toast-body`}>
        { (errors?.length && !isList) && errors.map((err) => <>{err}</>) }
        { (errors?.length && isList) && errors.map((err) => <li>{err}</li>) }
      </Toast.Body>
    </Toast>
  );
};

export default EQHErrorToast;
