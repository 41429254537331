export const filters = [
    'Services Needed',
    'Visit Type',
    "Payor",
    "Patient Risk Level",
    'PCP Practice',
    'Sender PCP',
    'Patient Name',
    'Birth Date Comp',
    'States'
]

export const sortItems = [
    {
      value: "Default Sort",
      text: "Default Sort",
      hasDivider: false,
      condition: true
    },
    {
      value: "Name (A-Z)",
      text: "Name (A-Z)",
      hasDivider: false,
      condition: true
    },
    {
      value: "Name (Z-A)",
      text: "Name (Z-A)",
      hasDivider: false,
      condition: true
    },
    {
      value: "Risk Level (lowest first)",
      text: "Risk Level (lowest first)",
      hasDivider: false,
      condition: true
    },
    {
      value: "Risk Level (highest first)",
      text: "Risk Level (highest first)",
      hasDivider: false,
      condition: true
    }
  ]

  export const defaultSortParams = [
    {
      "paramName": "statusUpdatedDateOnly",
      "direction": "DESC",
      "priority": 1
    },
    {
      "paramName": "lastName",
      "direction": "ASC",
      "priority": 1
    }
  ];
