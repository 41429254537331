import React, { useEffect, useState } from "react";

import styled, { css } from "styled-components";
import { theme } from "../../components/themes/theme1";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  useGetPatientProfileOverview,
  useGetCareManagementOverview,
  useGetPatientProfileNotesCount,
  useGetPatientProfileOverviewTabHeaderCount,
} from "../../actions/patientProfileActions";
import { HospitalEvents } from "./HospitalEvents";
import { PreventiveCare } from "./PreventiveCare";
import { Overview } from "./Overview";
import { Appointments } from "./Appointment";
import { Notes } from "./Note";
import { CareManagement } from "./CareManagement";
import { Card } from "react-bootstrap";
import { EQHSpinner } from "../../components/EQHSpinner";
import { PatientProfileHeader } from "./PatientProfileHeader";
import { patientOverviewReducer } from "../../reducers/patientProfile/patientOverviewReducer";
import { Referrals } from "./Referral";
import Assessment from "./assessment/Assessment";
import { History } from "./History";
import { CarePath } from "./CarePath";
import { useAuth } from "../../context/authContext";
import { patProfHasAccessTo } from "../../utils/patientProfilePermissions";
import { Helmet } from 'react-helmet';
import { PrevisitChart } from "../quickList/components/modals/PrevisitChart";
import { getPatient } from "../quickList/components/modals/PrevisitChart/utils";

const componentName = "PatientProfile";

const PatientProfileContainer = styled.div`
  width: ${(props) => props.theme.themeBase.divWidth};
  > * {
    margin: ${(props) => props.theme.themeBase.divMargin};
  }
`;

export const getTabCount = (patientOverview, propName) => {
  if (
    patientOverview.state.data &&
    patientOverview.state.data[propName]
  ) {
    return patientOverview.state.data[propName];
  }
  return 0;
};

const PatientProfile = ({ patientId, tab, itemId }) => {
  const [selectedTab, setSelectedTab] = useState(tab ? tab : "Overview");
  const [selectedItemId, setSelectedItemId] = useState(itemId);
  const [showCPT, setShowCPT] = useState(false);
  const [patientProfile, setPatientProfile] = useState({});

  const allDataPkgs = {
    patientOverview: useGetPatientProfileOverview({}, patientOverviewReducer),
    patientOverviewTabHeaderCount: useGetPatientProfileOverviewTabHeaderCount({}, patientOverviewReducer),

    //revert to enable note count call
    patientNoteCount: {},
    //    patientNoteCount: useGetPatientProfileNotesCount({}),

    // careManagementOverviewPkg: useGetCareManagementOverview(
    //   {},
    //   careManagementOverviewReducer
    // ),
  };

  //remove to enable note count call
  allDataPkgs.patientNoteCount.state = null;

  const auth = useAuth();

  const authorizedGapRead = auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS");
  const authorizedGapWrite = auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS");
  const authorizedRafsRead = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS"
  );
  const authorizedRafsWrite = auth.hasAuthority(
    "AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS"
  );

  const authorizedReferral = auth.hasAuthority("AUTH_SUBMIT_REFERRAL_ACCESS");
  const authorizedCareCoordinator = auth.hasAuthority(
    "AUTH_REFERRAL_MANAGEMENT_CARE_COORDINATION_ACCESS"
  );
  const authorizedForViewPDCareProgram = auth.hasAnyAuthority(["AUTH_PD_CARE_PROGRAMS_READ_ACCESS", "AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS"]);

  useEffect(() => {
    reloadPatientOverview();
  }, [patientId]);

  const reloadPatientOverview = () => {
    allDataPkgs.patientOverview.fetchData(patientId);
    allDataPkgs.patientOverviewTabHeaderCount.fetchData(patientId);
    getPatient(patientId).then(res => setPatientProfile(res.data));
    //revert to enable note count call
    //allDataPkgs.patientNoteCount.fetchData(patientId);
  };

  const getTotalCount = (patientOverview) => {
    let gapCount = 0;
    let rafCount = 0;
    if (authorizedGapRead || authorizedGapWrite) {
      gapCount = getTabCount(patientOverview, "caregaps");
    }
    if (authorizedRafsRead || authorizedRafsWrite) {
      rafCount = getTabCount(patientOverview, "raf");
    }

    return gapCount + rafCount;
  };

  return (
    <>
      {allDataPkgs.patientOverview.state.error ? (
        <Card>
          <Card.Body>{allDataPkgs.patientOverview.state.error}</Card.Body>
        </Card>
      ) : !allDataPkgs.patientOverview.state.data ? (
          <PatientProfileContainer theme={theme} className="overflow-inherit">
            <Helmet>
              <title>Patient Profile | CareEmpower</title>
            </Helmet>
            <Card>
              <Card.Body id={`${componentName}-NoPatientFound`}>No Patient Found</Card.Body>
            </Card>
          </PatientProfileContainer>
      ) : (
        <PatientProfileContainer theme={theme} className="overflow-inherit">
          <PrevisitChart show={showCPT} onHide={() => setShowCPT(false)} patientLoaded={allDataPkgs.patientOverview.state.data} />
          <Helmet>
            <title>Patient Profile | CareEmpower</title>
          </Helmet>
          <PatientProfileHeader
            patientOverview={allDataPkgs.patientOverview.state.data}
            setShowCPT={setShowCPT}
          />
          {allDataPkgs.patientOverview.state.isLoading && <EQHSpinner />}
          <div className="nav-wrapper">
            <Tabs
              activeKey={selectedTab}
              onSelect={(k) => setSelectedTab(k)}
              id={`${componentName}-patientNav`}
              className="list"
            >
              {patProfHasAccessTo("Overview", auth) && (
                <Tab
                  eventKey="Overview"
                  title={<CustomTabStyled title="Overview" />}
                >
                  <Overview
                    patientOverviewPkg={allDataPkgs.patientOverview}
                    selectedTab={selectedTab === "Overview"}
                    patientId={patientId}
                    setSelectedTab={setSelectedTab}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("HospitalEvents", auth) && (
                <Tab
                  eventKey="HospitalEvents"
                  title={
                    <CustomTabStyled
                      title="Hospital Events"
                      count={getTabCount(allDataPkgs.patientOverviewTabHeaderCount, "adt")}
                    />
                  }
                >
                  <HospitalEvents
                    selectedTab={selectedTab === "HospitalEvents"}
                    patientId={patientId}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("PreventiveCare", auth) && (
                <Tab
                  eventKey="PreventiveCare"
                  title={
                    <CustomTabStyled
                      title="Preventive Care"
                      count={getTotalCount(allDataPkgs.patientOverviewTabHeaderCount)}
                    />
                  }
                >
                  <PreventiveCare
                    selectedTab={selectedTab === "PreventiveCare"}
                    patient={allDataPkgs.patientOverview.state.data}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("Appointments", auth) && (
                <Tab
                  eventKey="Appointments"
                  title={
                    <CustomTabStyled
                      title="Appointments"
                      count={getTabCount(
                        allDataPkgs.patientOverviewTabHeaderCount,
                        "appointments"
                      )}
                    />
                  }
                >
                  <Appointments
                    selectedTab={selectedTab === "Appointments"}
                    patient={allDataPkgs.patientOverview.state.data}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("Assessments", auth) && (
                <Tab
                  eventKey="Assessments"
                  title={
                    <CustomTabStyled
                      title="Assessments"
                      count={getTabCount(
                        allDataPkgs.patientOverviewTabHeaderCount,
                        "assessmentSummary"
                      )}
                    />
                  }
                >
                  <Assessment
                    selectedTab={selectedTab === "Assessments"}
                    patientId={patientId}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("Referrals", auth) && allDataPkgs.patientOverview.state.data.contracted === true && (
                <Tab
                  eventKey="Referrals"
                  title={
                    <CustomTabStyled
                      title="Referrals"
                      count={getTabCount(
                        allDataPkgs.patientOverviewTabHeaderCount,
                        "referrals"
                      )}
                    />
                  }
                >
                  <Referrals
                    selectedTab={selectedTab === "Referrals"}
                    patient={allDataPkgs.patientOverview.state.data}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("Notes", auth) && (
                <Tab
                  eventKey="Notes"
                  title={
                    <CustomTabStyled
                      title="Notes"
                      count={allDataPkgs.patientNoteCount.state?.data?.uniqueCountAll ?? "-"}
                    />
                  }
                >
                  <Notes
                    selectedTab={selectedTab === "Notes"}
                    patientId={patientId}
                    reloadPatientOverview={reloadPatientOverview}
                    selectedItemId={selectedItemId}
                  />
                </Tab>
              )}
              {patProfHasAccessTo("History", auth) && (
                <Tab
                  eventKey="History"
                  title={<CustomTabStyled title="History" />}
                >
                  <History
                    selectedTab={selectedTab === "History"}
                    patient={allDataPkgs.patientOverview.state.data}
                  />
                </Tab>
              )}

              {patProfHasAccessTo("CarePath", auth) && allDataPkgs.patientOverview.state.data.contracted === true && authorizedForViewPDCareProgram && (
                <Tab
                  eventKey="CarePath"
                  title={<CustomTabStyled title="Care Program" />}
                >
                  <CarePath
                    patient={allDataPkgs.patientOverview.state.data}
                    selectedTab={selectedTab === "CarePath"}
                    reloadPatientOverview={reloadPatientOverview}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        </PatientProfileContainer>
      )}
    </>
  );
};

const CustomTab = ({ title, count }) => {
  return (
    <>
      <span id={`${componentName}-tabTitle`} className="me-2">{title}</span>
      {<span id={`${componentName}-tabCount`} className="badge bg-secondary">count</span> && (
        <span id={`${componentName}-tabCount`} className="badge bg-secondary">{count}</span>
      )}
    </>
  );
};

const CustomTabStyled = styled(CustomTab)``;

// const componentPlus = (component, label, onChange) => {
//   const addNewItem = () => {
//     const newList = [...list];
//     newList.push({...component});
//     setList(newList)
//   }

//   const handleChange = (idx, value) =>
//   {

//   }

//   const [list, setList] = useState([]);
//   const comp = <Row xlg={1}>{list.map(x => <Form.Control
//     type="text"
//   />)}<div onClick={addNewItem()}><i className="fas fa-plus-circle"></i> {label}</div></Row>;

// }

export { PatientProfile };
