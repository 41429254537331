import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  createRef,
} from "react";

import { Accordion, AccordionContext, useAccordionButton, Card, Dropdown, Badge, Button, Spinner, OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CarePathPrimary from "./carePathPrimary";
import CarePathOverdue from "./carePathOverdue";
import CarePathClosed from "./carePathClosed";
import CarePathKebab from "./carePathKebab";

import CarePathStep from "./CarePathStep";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import axios from "../../../../../utils/apiClient"; 
import { useDispatch, useSelector } from "react-redux";
import { setCareProgramModalOpen, setReloadWorklist } from "../../../../../actions/manageVisitActions";
import { useManageVisitContext } from "../../context/ManageVisitContext/ManageVisit.context";
import { useCMWorklistContext } from "../../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import { formatDateModernLocal, getCarePathsColors } from "../../../../../utils/util";
import { useAuth } from "../../../../../context/authContext";
import { AppPropertiesUtil } from "../../../../../utils/localStorageUtil";
import isEmpty from "is-empty";

const componentName = "careProgram";
const CareProgram = ({
  careManagementsPkg,
  patient,
  selectedTab,
  careProgram,
  patientInformation,
  selectedFilter,
  setRefreshFilter,
  open,
  setOpenStates,
  idx,
  filterResult
}) => {
  const [notesAdded, setNotesAdded] = useState([]);
  const [selectedCarePath, setSelectedCarePath] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [completedHidden, setCompletedHidden] = useState(false);
  const [carePathSteps, setCarePathSteps] = useState([]);
  const [isLoadingComp, setIsLoadingComp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [newSteps, setNewSteps] = useState([]);
  const [newCarePath, setNewCarePath] = useState(null);
  const [loadingPath, setLoadingPath] = useState(false);
  const dispatch = useDispatch();
  const manageVisitState = useManageVisitContext();
  const [customKey, setCustomKey] = useState('');
  const [blockDrag, setBlockDrag] = useState(false);

  const auth = useAuth();

  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");
  const [selectedExpired, setSelectedExpired] = useState(false);
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(null);
  const [selectedExpiredSource, setSelectedExpiredSource] = useState(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [expandAll, setExpandAll] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [pendingTransaction, setPendingTransaction] = useState(null);

  const [careProgramObject, setCareProgrammObject] = useState({
    show: false,
    careProgram: null,
    action: '',
    title: ''
  });

  const {
    toggleSnoozedModal,
    setstep,
    toggleCustomStatus
  } = useCMWorklistContext();
  const snoozedModal = useSelector((state) => state?.manageVisitReducer?.snoozedModal);

  useEffect(() => {
    if (selectedTab && patient?.id != null) {
      setNotesAdded([]);
    }

  }, [patient.id, selectedTab]);

  useEffect(() => {
    if (!snoozedModal) {
      handleChangeCarePath(careProgram)
    }
  }, [snoozedModal])

  useEffect(() => {
    setCustomKey(Math.random())
  }, [selectedFilter])

  useEffect(() => {
    setNewCarePath(null);
  }, [careManagementsPkg.state.data]);

  const ref = React.createRef();
  const redirectedRef = useRef(null);
  const redirectID = sessionStorage.getItem('itemID');

  useEffect(() => {
    if (redirectedRef.current ) {
      redirectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      setTimeout(() => {
        const element = document.getElementById(`${componentName}-carePathButton-${careProgram.id}`)
        element.click()
      }, 1000);
    }
  }, [careProgram.id])

  const handleChangeCarePath = (cp = selectedCarePath, idx) => {
    setLoadingPath(true);
    setIsSearching(true);
    const hasIndex = open.indexOf(idx);
    if (hasIndex !== -1) {
      setOpenStates(prev => prev.filter(num => num !== idx))
    } else {
      setOpenStates([...open, idx])
    }

    if (ref && ref.current) {
      ref.current.clear();
    }
    setSelectedCarePath(cp);
    axios
      .get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          cp?.id
        )}`,
        {
          headers: {},
        }
      )
      .then((resp) => {
        let options = addSteps(resp.data);
        setCarePathSteps(options);
        setIsLoadingComp(false);
        setLoadingPath(false);
        setIsSearching(false);
      })
      .catch((error) => {
        manageVisitState.persistErrorMessage(error?.response?.data?.message)
      });
  };

  const handleRefreshSteps = (cp) => {
    setLoadingPath(true);
    axios
      .get(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          cp.id
        )}`,
        {
          headers: {},
        }
      )
      .then((resp) => {
        let options = addSteps(resp.data);
        setCarePathSteps(options);
        setIsLoadingComp(false);
        setLoadingPath(false);
        patientInformation(patient);
      })
      .catch((error) => {
        manageVisitState.persistErrorMessage(error?.response?.data?.message)
      });
  };

  const addSteps = (list) => {
    let subStep = 0;
    let mainStep = 0;
    let finalList = [];
    list.forEach((cp, index) => {
      if (!cp.subStep) {
        mainStep++;
        cp.stepNumber = mainStep;
        subStep = 1;
      } else {
        const subSTepNum = mainStep + "." + subStep;
        cp.stepNumber = Number(subSTepNum);
        subStep++;
      }
      finalList.push(cp);
    });
    return finalList;
  };

  const handleStepMovement = (result) => {
    // Do nothing if there's no destination
    if (!result.destination) {
      return;
    }
  
    // Create a copy of the current state
    const newState = Array.from(carePathSteps);
  
    // Get the item being moved
    const [movedItem] = newState.splice(result.source.index, 1);
  
    // Insert the moved item at its new location
    newState.splice(result.destination.index, 0, movedItem);
  
    // Update the stepNumber property for all items
    newState.forEach((step, index) => {
      step.stepNumber = index + 1;
    });
  
    // Access the 'instance' or 'carePathInstanceId'
    const instance = movedItem.carePathInstanceId;
  
    // Update the state
    setCarePathSteps([...newState]);
  
    // If the moved item has a carePathInstanceId, call updateStepAction
    if (instance) {
      updateStepAction(newState, instance);
    }
  };

  const handleStepRemoval = (step) => {
    let newState = carePathSteps;
    let instance = step.carePathInstanceId;
    newState.forEach((stp, i) => {
      if (stp.stepNumber === step.stepNumber) {
        stp.carePathStepDeleteReason = step.deletedReason
        stp.deleted = step.deleted

      }
    });
    removeStepAction(newState, instance);
    setCarePathSteps([...newState]);
  }

  const submitNewStep = (stepObj, carePathSteps) => {
    let carePathStepDto = stepObj;
    const index = carePathSteps.findIndex(object => {
      return stepObj.stepNumber === object.stepNumber;
    });
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/add-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          selectedCarePath.id
        )}&carePathStepNumber=${index}`,
        carePathStepDto,
        { headers: {} }
      )
      .then(async (res) => {
        await handleRefreshSteps(selectedCarePath);
      })
      .catch((error) => {
        manageVisitState.persistErrorMessage(error?.response?.data?.message)
      });
  };

  const saveNewStep = async (stepObj, index, action, number) => {
    let newState = carePathSteps;
    if (action === "before") {
      if (index === 0) {
        for (let i = 0; i < newState.length; i++) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
        stepObj.stepNumber = number;
        newState.unshift(stepObj);
      } else {
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].stepNumber >= number) {
            newState[i].stepNumber =
              Math.round((newState[i].stepNumber + 1) * 10) / 10;
          }
        }
        stepObj.stepNumber = number;
        newState.splice(index, 0, stepObj);
      }
    } else if (action === "addCarePathStep") {
      newState.splice(newState.length + 1, 0, stepObj);
    }
    else if (action === 'delete') {
      handleStepRemoval(stepObj);
    }
    else {
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > number) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
      }
      stepObj.stepNumber = Math.floor(number + 1);
      newState.splice(index + 1, 0, stepObj);
    }
    if (action !== "delete") {
      await submitNewStep(stepObj, newState);
    }
    dispatch(setCareProgramModalOpen(false))
    //setCarePathSteps([...newState]);
  };

  const editExistingStep = (step, stepObj, index) => {
    let newState = carePathSteps;
    let oldObj = newState[index];
    let newObj = {};
    let instance = step.carePathInstanceId;
    Object.keys(oldObj).forEach((key) =>
      key in stepObj
        ? (newObj[key] = stepObj[key])
        : (newObj[key] = oldObj[key])
    );
    newState[index] = newObj;
    setCarePathSteps([...newState]);
    updateStepAction(newState, instance);
    dispatch(setCareProgramModalOpen(false))
  };

  const handleStatusChange = (e, value, isCustomStatus, customStatus) => {
    let newState = [...carePathSteps];

    newState.forEach((stp) => {
      if (stp.stepNumber === value.stepNumber) {
        value.carePathStepStatus = e;
      }
    });

    const isLastActiveStep = newState.every(e =>
      e.carePathStepStatus === 'COMPLETED' ||
      e.carePathStepStatus === 'CANCELLED' ||
      e.carePathStepStatus === 'SKIPPED'
    )

    if (isLastActiveStep) {
      checkCanCloseCarePath(careProgram, 'closeCareProgram', 'Close Care Program');
      setPendingTransaction(() => () => changeStatus(e, value, isCustomStatus, customStatus, newState, false))
    } else {
      changeStatus(e, value, isCustomStatus, customStatus, newState)
    }
  };

  const changeStatus = (e, value, isCustomStatus, customStatus, newState, reload = true) => {
    const body = {
      action: "SET_STATUS",
      careProviderId: value.assignedTo ? value.assignedTo.id : "",
      text: null,
      status: e,
      assignToId: null,
      stepCustomStatus: isCustomStatus ? customStatus : null,
    };
    if (e !== 'SNOOZED') {
      axios
        .put(
          process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
            value.carePathInstanceId
          )}&carePathStepId=${value.id}`,
          body,
          { headers: {} }
        )
        .then((res) => {
          newState.forEach((stp) => {
            if (stp.stepNumber === res.data.result.stepNumber) {
              stp.stepCustomState = res.data.result.stepCustomState;
            }
          });
          setCarePathSteps([...newState]);
          reload && dispatch(setReloadWorklist(true));
        })
        .catch((error) => {
          manageVisitState.persistErrorMessage(error?.response?.data?.message)
        });
    } else {
      toggleCustomStatus(customStatus)
      !snoozedModal && toggleSnoozedModal(true)
    }
    setPendingTransaction(null)
  }

  const removeStepAction = (newObj, instance) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then((res) => {
        handleRefreshSteps(selectedCarePath);
      })
      .catch((error) => {
        manageVisitState.persistErrorMessage(error?.response?.data?.message)
      });
  };

  const updateStepAction = (newObj, instance) => {
    axios
      .put(
        `${process.env.REACT_APP_REST_API_BASE_URL}/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then((res) => {
        handleRefreshSteps(selectedCarePath);
      })
      .catch((error) => {
        console.error('API Error:', error); // Debugging log
        manageVisitState.persistErrorMessage(error?.response?.data?.message);
      });
  };

  function PlusMinusToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey.findIndex(e => e === eventKey) >= 0;
    return (
      <Button
        variant="link"
        className="d-flex align-items-center text-dark text-decoration-none"
        id={`${componentName}-carePathButton-${careProgram.id}`}
        onClick={decoratedOnClick}
      >
        <i
          className={
            isCurrentEventKey
              ? "fa fa-chevron-down me-4"
              : "fa fa-chevron-right me-4"
          }
        ></i>
        {children}
      </Button>
    );
  }

  const openCloseProgram = (careProgram, action, title) => {
    dispatch(setCareProgramModalOpen(true));

    setCareProgrammObject({
      ...careProgramObject,
      show: true,
      careProgram: careProgram,
      action: action,
      title: title
    });
  };

  const handleCheckClose = (careProgram, action, title) => {
    checkCanCloseCarePath(careProgram, action, title);
  };

  const handleChangeOutReach = () => {
    try {
      axios.patch(process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/care-path-status', {
        carePathInstanceId: selectedCarePath.id,
        patientProgramId: selectedCarePath.patientProgramId,
        patientId: patient.id,
        userId: auth.getUserId(),
        carePathStatus: "OUTREACH_IN_PROGRESS"
      }, {
        headers: {
          "Content-Type": "application/json"
        },
      }).then(() => {
        careManagementsPkg.fetchData(patient.id)
        dispatch(setReloadWorklist(true))
      });
    } catch (error) {
    }
  }

  const handleModal = () => {
    setSelectedReason(selectedReason);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
  };

  const checkCanCloseCarePath = (selectedCarePath, action, title) => {
    const baseURLCP = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-paths";
    const payLoad = {
      carePathInstanceId: selectedCarePath.id,
    };
    const url = baseURLCP + "/check-close-care-path";
    axios
      .post(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response && response.data && !isEmpty(response.data.errorMessage)) {

          useManageVisitContext.persistErrorMessage(response.data.errorMessage);
        } else {
          await openCloseProgram(selectedCarePath, action, title);
          handleModal();
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const toggleAll = (state) => {
    setExpandAll(state)
    if (state) {
      setActiveKey(carePathSteps.map((_,idx) => idx));
    } else {
      setActiveKey([])
    }
  };

  const carePathStatusesFeatureFlag = AppPropertiesUtil.get("newCarePathStatuses.feature.enabled");

  return (
    <div ref = {redirectID && careProgram.id == redirectID ? redirectedRef : null} key={customKey}>
      <Accordion className="mb-3" activeKey={open} alwaysOpen>
        <Card className="rounded-3 p-2">
          <Card.Header
            id={`${componentName}-carePathHead-${careProgram.id}`}
            onClick={() => handleChangeCarePath(careProgram, idx)}
            className="d-flex justify-content-between align-items-center bg-transparent border-0"
            eventKey={idx}
          >
            <PlusMinusToggle className="text-dark p-0" eventKey={idx}>
              <span className="d-inline-block text-start">
                <span id={`${componentName}-carePathTitleLabel`} className="fs-5 fw-bold mb-1 d-block lh-1">{careProgram?.name}</span>
                <span id={`${componentName}-carePathAssigned`} className="text-muted">{careProgram.assignedTo == null ? "Unassigned" : careProgram.assignedTo.firstName + " " + careProgram.assignedTo.lastName}</span>
                <span id={`${componentName}-carePathDaysOpened`} className="text-muted ms-lg-5">
                  {careProgram.isClosed ? null : "Days Opened: " + (careProgram.carePathElapsedTime??'')}
                </span>
              </span>
            </PlusMinusToggle>
            {isSearching &&
              <Spinner
                className="me-3"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            }
            <div className="d-flex justify-content-end align-items-center">
              {(!careProgram?.overdue && !careProgram?.closed && careProgram?.isActive) &&
                   <Dropdown>
                      <Dropdown.Toggle
                        className='badge rounded-pill border-0 me-4 status-pill cursor-p'
                        as={Badge}
                        id="dropdown-basic"
                        style={{
                          color: getCarePathsColors(careProgram?.carePathStatus).font,
                          opacity: !authorizedCareProgramWrite ? 0.5 : 1,
                          cursor: !authorizedCareProgramWrite ? 'not-allowed' : 'pointer',
                          pointerEvents: !authorizedCareProgramWrite ? 'none' : 'auto',
                        }}
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              'background-color',
                              getCarePathsColors(careProgram.carePathStatus).bg,
                              'important'
                            )
                          }
                        }}
                      >
                        {careProgram?.carePathStatus && `${careProgram?.carePathStatus} on ${formatDateModernLocal(careProgram?.carePathStatusDate)}`}
                        {!careProgram?.carePathStatus && `Hub Assigned on ${formatDateModernLocal(careProgram?.startedAt)}`}
                        <i className={`fa fa-angle-down`} style={{ marginLeft:'5px' }}/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {carePathStatusesFeatureFlag && <>
                          <Dropdown.Item onClick={() => openCloseProgram(careProgram, 'enroll', 'Enroll Member in Care Program')}>Enroll Member</Dropdown.Item> 
                          <Dropdown.Item onClick={() => handleChangeOutReach()}>Outreach In Progress</Dropdown.Item>
                        </>}
                        <Dropdown.Item onClick={() => handleCheckClose(careProgram, 'closeCareProgram', 'Close Care Program')}>Close Care Program</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              }
              {careProgram?.primary && <CarePathPrimary />}
              {careProgram?.isActive && (
                  careProgram?.overdue
                  || careProgram?.carePathStepsAggregatorDto?.currentStep?.carePathStepStatus === "OVERDUE"
                  || careProgram?.carePathStepsAggregatorDto?.nextStep?.carePathStepStatus === "OVERDUE"
                  || careProgram?.carePathStepsAggregatorDto?.prevStep?.carePathStepStatus === "OVERDUE"
                  || careProgram?.carePathStepsAggregatorDto?.earliestDueDateStep?.carePathStepStatus === "OVERDUE"
              ) && <CarePathOverdue />}
              {(careProgram?.closed || !careProgram?.isActive) && careProgram?.closedDate && <CarePathClosed closedDate={careProgram?.closedDate} />}
              {(careProgram?.closed || !careProgram?.isActive) && !careProgram?.closedDate && <CarePathClosed closedDate={formatDateModernLocal(careProgram?.carePathStatusDate)} />}
              <CarePathKebab
                careProgram={careProgram}
                patient={patient}
                selectedCarePath={selectedCarePath}
                setSelectedCarePath={setSelectedCarePath}
                careManagementsPkg={careManagementsPkg}
                saveNewStep={saveNewStep}
                step={carePathSteps}
                patientInformation={patientInformation}
                setRefreshFilter={setRefreshFilter}
                setCareProgrammObject={setCareProgrammObject}
                careProgramObject={careProgramObject}
                selectedExpired={selectedExpired}
                setSelectedExpired={setSelectedExpired}
                selectedExpiredDate={selectedExpiredDate}
                setSelectedExpiredDate={setSelectedExpiredDate}
                selectedExpiredSource={selectedExpiredSource}
                setSelectedExpiredSource={setSelectedExpiredSource}
                pendingTransaction={pendingTransaction}
                filterResult={filterResult}
              />
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey={idx}>
            <Card.Body>
              <Col md={12} className='text-end mb-3'>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip>
                      Expand / Collapse All
                    </Tooltip>
                  }
                >
                  <span
                    bg="light"
                    className="border text-dark badge badge-pill mt-2"
                    onClick={() => toggleAll(!expandAll)}
                    style={{maxWidth: 'fit-content', fontSize: '12px'}}
                  >
                    <i className='fas fa-angle-double-up'/>
                    <i className='fas fa-angle-double-down'/>
                  </span>
                </OverlayTrigger>
              </Col>
            {carePathSteps && carePathSteps.length ? (
            <DragDropContext onDragEnd={handleStepMovement}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {carePathSteps.map((step, index) => (
                      <Draggable
                        key={step.id}
                        draggableId={step.id}
                        index={index}
                        isDragDisabled={!authorizedCareProgramWrite || blockDrag}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {!completedHidden ? (
                              <CarePathStep
                                step={step}
                                setstep={setstep}
                                newStep={setNewSteps}
                                handleStepMovement={handleStepMovement}
                                handleStatusChange={handleStatusChange}
                                index={index}
                                pathsLength={carePathSteps.length}
                                saveNewStep={saveNewStep}
                                editExistingStep={editExistingStep}
                                notesAdded={notesAdded}
                                setNotesAdded={setNotesAdded}
                                selectedPath={selectedCarePath}
                                loading={loadingPath}
                                handleChangeCarePath={handleChangeCarePath}
                                errorMessage={errorMessage}
                                patientInformation={patientInformation}
                                patient={patient}
                                careProgram={careProgram}
                                setBlockDrag={setBlockDrag}
                                setActiveKey={setActiveKey}
                                activeKey={activeKey}
                                toggleAll={toggleAll}
                                expandAll={expandAll}
                                handleRefreshSteps={() => handleRefreshSteps(
                                  {id: step.carePathInstanceId})}
                              />
                            ) : completedHidden &&
                              step.carePathStepStatus != "COMPLETED" ? (
                              <CarePathStep
                                step={step}
                                setstep={setstep}
                                newStep={setNewSteps}
                                handleStepMovement={handleStepMovement}
                                handleStatusChange={handleStatusChange}
                                index={index}
                                pathsLength={carePathSteps.length}
                                saveNewStep={saveNewStep}
                                editExistingStep={editExistingStep}
                                notesAdded={notesAdded}
                                handleChangeCarePath={handleChangeCarePath}
                                setNotesAdded={setNotesAdded}
                                selectedPath={selectedCarePath}
                                errorMessage={errorMessage}
                                loading={loadingPath}
                                patientInformation={patientInformation}
                                patient={patient}
                                setBlockDrag={setBlockDrag}
                                setActiveKey={setActiveKey}
                                activeKey={activeKey}
                                toggleAll={toggleAll}
                                expandAll={expandAll}
                              />
                            ) : null}
                          </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default CareProgram;