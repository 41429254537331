import React from 'react';

const componentName = "PatientRecordBoldField";

const PatientRecordBoldField = ({sourceName, idx, label, value}) => {
  return (
    <span id={`${componentName}-${sourceName}-field-${idx}`} className="text-muted" style={{ fontSize: '12px' }}>
      <span id={`${componentName}-${sourceName}-label-${idx}`} className="fw-bold me-2">
        {label}
      </span>
      <span id={`${componentName}-${sourceName}-value-${idx}`}>
        {value}
      </span>
    </span>
  );
};

export default PatientRecordBoldField;