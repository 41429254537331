import React, {useEffect, useState} from "react";
import { Row, Col, Button, Badge, Form, Modal } from "react-bootstrap";
import {useDispatch} from "react-redux";
import {neededServices, urgency_referrals_options} from "../../../../../../utils/textValueLists";
import {PrimaryDDL} from "../../../../../../components/dropDownLists/primaryDDL";
import {getRecommendedTeams} from "../../../../../../actions/patientProfileActions";
import {DDLMode, DropDownList} from "../../../../../../components/dropDownLists/genericDDL";
import {FileUpload} from "../../../../../../components/FileUpload";
import {ReferralUrgency, AsyncSearch, SubstanceAbuseWarningModal} from "../components";
import {useAuth} from "../../../../../../context/authContext";
import { RedAsterisk, convertDateToString } from "../../../../../../utils/util";
import isEmpty from "is-empty";
import {useEQHToastContext} from "../../../../../../components/toast/EQHToast.context";
import {
    setUserEventAction
} from "../../../../../../actions/manageVisitActions";
import {saveReferral, searchICD10ByCode, searchICD10ByDescription} from "../context/referralsApi";

const componentName = "CareCoordinationReferral";

const CareCoordinationReferral = ({
                                      showModal, setShowModal, patient
                                  }) => {
    const globalDispatch = useDispatch();
    const auth = useAuth();
    const [referral, setReferral] = useState({});
    const [recommendedTeams, setRecommendedTeams] = useState([]);
    const [icd10Options, setIcd10Options] = useState([]);
    const [icd10List, setIcd10List] = useState([{isLoading: false}]);
    const [substanceAbuseICD10, setSubstanceAbuseICD10] = useState(null);
    const { setNotification } = useEQHToastContext();

    useEffect(() => {
        if (showModal) {
            getRecommendedTeams((response) => setRecommendedTeams(response.data));
            initReferral();
            initVariables();
        }
    }, [showModal]);

    const initVariables = () => {
        setIcd10List([{isLoading: false}]);
        setSubstanceAbuseICD10(null);
        setIcd10Options([]);
    }

    const initReferral = () => {
        const userName = auth.getName();
        let visitExpirationDate = convertDateToString();
        setReferral({
            patientId: patient?.id,
            referrerName: userName,
            sentByFullName: userName,
            refDateUTC: new Date(Date.now()).toISOString(),
            status: "50_PENDING",
            referralType: "CARE_COORDINATION",
            diagnosisICD10dto: [],
            visitExpirationDate
        });
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const onSelectServicesNeeded = (services) => {
        const servicesNeeded = services.map(service => {
            return {service: service.value, primary: service.isPrimary};
        });
        setReferral({...referral, servicesNeeded})
    }

    const onSelectUrgency = (urgencyValue) => {
        setReferral({...referral, urgency: urgencyValue})
    }

    const onSelectRecommendedTeam = (team) => {
        setReferral({...referral, teamId: team.paramValue[0]})
    }

    const onChangeNote = (note) => {
        setReferral({...referral, referralComments: note})
    }

    const handleUploadFile = (uploadedFiles) => {
        const files = uploadedFiles.map(file => {
            return {
                description: null,
                fileMetadata: file,
            }
        })
        setReferral({...referral, files})
    };

    const asyncIcd10CodeSearch = (query, index) => {
        icd10List[index].isLoading = true;
        setIcd10List([...icd10List]);
        searchICD10ByCode(query).then(response => {
            const options = response?.data?.map(icd10Code => ({
                id: icd10Code.id,
                code: icd10Code.code,
                description: icd10Code.description
            }));
            setIcd10Options(options);
            icd10List[index].isLoading = false;
            setIcd10List([...icd10List]);
        }).catch(error => {
            icd10List[index].isLoading = false;
            setIcd10List([...icd10List]);
        })
    }

    const asyncIcd10DescriptionSearch = (query, index) => {
        icd10List[index].isLoading = true;
        setIcd10List([...icd10List]);
        searchICD10ByDescription(query).then(response => {
            const options = response?.data?.map(icd10Code => ({
                id: icd10Code.id,
                code: icd10Code.code,
                description: icd10Code.description
            }));
            setIcd10Options(options);
            icd10List[index].isLoading = false;
            setIcd10List([...icd10List]);
        }).catch(error => {
            icd10List[index].isLoading = false;
            setIcd10List([...icd10List]);
        })
    }

    const onSelectIcd = (icd10SelectedList, index) => {
        if (isSubstanceAbuseICD10Selected(icd10SelectedList, index)) {
            setSubstanceAbuseICD10({icd10: icd10SelectedList, index});
        } else {
            addICD10Selected(icd10SelectedList, index);
        }
    }

    const addICD10Selected = (icd10SelectedList, index) => {
        let code = [];
        let description = [];
        if (icd10SelectedList && icd10SelectedList.length > 0) {
            icd10SelectedList.forEach(icd10 => {
                code = [icd10.code];
                description = [icd10.description];
            })
        }
        icd10List[index].code = code;
        icd10List[index].description = description;
        setIcd10List([...icd10List]);
        setSubstanceAbuseICD10(null);
    }

    const isSubstanceAbuseICD10Selected = (icd10SelectedList) => {
        if (icd10SelectedList && icd10SelectedList.length > 0) {
            let regex = /^F1[0-9]+.*$/;
            return regex.test(icd10SelectedList[0].code);
        }
        return false;
    }

    const submitReferral = async () => {
        if (!hasDuplicatedICD10()) {
            try {
                const diagnosisICD10dto = getICD10Filled();
                const payload = {...referral, diagnosisICD10dto}
                await saveReferral(payload);
                globalDispatch(setUserEventAction('REFERRAL_ADDED'));
                closeModal();
            } catch (error) {
            }
        }
    }

    const getICD10Filled = () => {
        const diagnosisICD10dto = [];
        icd10List.forEach(icd10 => {
            if (icd10.code) {
                diagnosisICD10dto.push({
                    code: icd10.code[0],
                    description: icd10.description[0]
                })
            }
        });
        return diagnosisICD10dto;
    }

    const hasDuplicatedICD10 = () => {
        let isDuplicated = false;
        if (icd10List && icd10List.length > 1) {
            const icd10Codes = icd10List.filter(icd10 => icd10.code ? true : false).map(icd10 => icd10.code[0]);
            isDuplicated = icd10Codes.some((code, index) => icd10Codes.indexOf(code) !== index)
        }
        if (isDuplicated) {
            addError("duplicatedICD10", "ICD-10 codes must not be duplicated.")
        }
        return isDuplicated;
    }

    const addError = (key, message) => {
        const errors = {};
        errors[key] = message;
        setNotification("Error", Object.values(errors));
    }

    const areRequiredFieldsFilled = () => {
        if (referral && referral.servicesNeeded &&
            referral.servicesNeeded.length &&
            isPrimaryServiceSelected(referral.servicesNeeded) &&
            referral.urgency && referral.referralComments) {
            return false;
        }
        return true;
    }

    const isPrimaryServiceSelected = (servicesNeeded) => {
        if (servicesNeeded && servicesNeeded.length > 0) {
            return servicesNeeded.some(service => service.primary)
        }
        return false;
    }

    const addMoreICD10 = () => {
        setIcd10List([...icd10List, {isLoading: false}])
    }

    return (
        <>
            <Modal
                id={`${componentName}-referModal`}
                size="md"
                show={showModal}
                onHide={() => closeModal()}
                centered>
                <Modal.Header>
                    <Modal.Title id={`${componentName}-title`}>Care Coordination Referral</Modal.Title>
                    <button id={`${componentName}-close`} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close" onClick={() => closeModal()}></button>
                </Modal.Header>
                <Modal.Body className="pb-2 overflow-auto" style={{maxHeight: '75vh'}}>
                    <Row className="mb-3">
                        <Col>
                            <PrimaryDDL
                                id={`${componentName}-servicesNeeded`}
                                key={`${componentName}-servicesNeeded`}
                                label="Services Needed"
                                handleChange={(e) => onSelectServicesNeeded(e)}
                                data={neededServices}
                                otherServiceValue="OTHER"
                                isRequired={true}
                        />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <ReferralUrgency
                                id={`${componentName}-urgency`}
                                key={`${componentName}-urgency`}
                                urgencyOptions={urgency_referrals_options}
                                type="radio"
                                onSelect={onSelectUrgency}
                                isRequired={true}
                        />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <DropDownList
                                id={`${componentName}-recommendedTeams`}
                                key={`${componentName}-recommendedTeams`}
                                mode={DDLMode.SelectOne}
                                header="Recommended Team"
                                showSearchTextbox={false}
                                getData={() => Promise.resolve(recommendedTeams.map(team => {
                                    return {value: team.teamId, text: team.name}
                                }))}
                                handleChange={(e) => onSelectRecommendedTeam(e)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group>
                                <Form.Label id={`${componentName}-notesLabel`}>
                                    Notes<RedAsterisk/>
                                </Form.Label>
                                <Form.Control
                                    id={`${componentName}-notesValue`}
                                    key={`${componentName}-notesValue`}
                                    as="textarea"
                                    rows="3"
                                    onChange={e => onChangeNote(e.target.value)}
                                    maxLength="5000"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label id={`${componentName}-docLabel`}>
                                Documentation
                            </Form.Label>
                            <p id={`${componentName}-docInstruction`}>
                                Please include last progress note and labs for documentation.
                            </p>
                            <FileUpload
                                id={`${componentName}-docFileUpload`}
                                onChange={handleUploadFile}
                                showIcon={false}
                                label="Upload Files"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="bg-light p-3">
                                <Form.Label id={`${componentName}-docLabel`} className="fw-normal fst-italic mb-2">
                                    Optional
                                </Form.Label>
                                {icd10List.map((icd10, index) => (
                                    <Row id={`${componentName}-icd10Row-${index}`} key={`${componentName}-icd10Row-${index}`} className="mb-3">
                                        <Col>
                                            <AsyncSearch
                                                id={`${componentName}-ICD10Code`}
                                                title="ICD-10 Code"
                                                onSearch={asyncIcd10CodeSearch}
                                                options={icd10Options}
                                                isLoading={icd10.isLoading}
                                                labelKey="code"
                                                onChange={onSelectIcd}
                                                selected={icd10.code}
                                                index={index}
                                            />
                                        </Col>
                                        <Col>
                                            <AsyncSearch
                                                id={`${componentName}-ICD10Description`}
                                                title="ICD-10 Description"
                                                onSearch={asyncIcd10DescriptionSearch}
                                                options={icd10Options}
                                                isLoading={icd10.isLoading}
                                                labelKey="description"
                                                onChange={onSelectIcd}
                                                selected={icd10.description}
                                                index={index}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Badge pill bg="light" text="dark" className="border cursor-p" onClick={() => addMoreICD10()} hidden={icd10List.length > 3}>
                                    <i className="fa fa-plus"></i> Add More
                                </Badge>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id={`${componentName}-cancelButton`}
                        bg="light"
                        onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    <Button
                        id={`${componentName}-submitButton`}
                        bg="primary"
                        disabled={areRequiredFieldsFilled()}
                        onClick={() => submitReferral()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <SubstanceAbuseWarningModal
                showModal={!isEmpty(substanceAbuseICD10)}
                icd10Info={substanceAbuseICD10}
                onConfirm={(icd10Info) => addICD10Selected(icd10Info.icd10, icd10Info.index)}
                onCancel={() => setSubstanceAbuseICD10(null)}
            />
        </>)
};

export default CareCoordinationReferral;