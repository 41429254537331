import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { Filter } from '../../../../components/filters/filterGroup';
import { dateRangeError, incompleteFilterError } from '../../../../components/fitersModal/errors';
import { formatDateVanilla } from '../../../../utils/util';
import { checkRange, componentName, filtersList, getFilterDef, getOptFilters, getPatientPrograms } from '../utils';

export const FilterModal = ({
  show,
  setShow,
  setPatients,
  filters,
  setFilters,
  followUpFilters,
  setFollowUpFilters,
  setPagination,
  pagination,
  setShowResume,
  setError
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [followUpCategory, setFollowUpCategory] = useState('');
  const [followUpFilterList, setFollowUpFilterList] = useState([]);  const [validations, setValidations] = useState({
    hasIsMostRecent: false,
    hasRangeDate: false,
    hasFollowUpRequired: true,
  });

  const onFilter = () => {
    let hasError = false;

    setIsLoading(true);
    setIsDisabled(true);

    if (filters?.rangeDate?.paramValue.length === 1) {
      setError([incompleteFilterError]);
      hasError = true;
    }

    if (filters?.rangeDate?.paramValue.length === 2) {
      const fromDate = formatDateVanilla(filters?.rangeDate?.paramValue?.[0], "yyyy-MM-dd");
      const toDate = formatDateVanilla(filters?.rangeDate?.paramValue?.[1], "yyyy-MM-dd");
      if (!checkRange([fromDate, toDate])) {
        setError([dateRangeError]);
        hasError = true;
      }
    }

    !hasError && getPatientPrograms(filters, followUpFilters, 1).then(res => {
      setShow(false);
      setShowResume(true);
      setPatients(res?.data?.results || []);
      setPagination({ ...pagination, count: res?.data?.count, page: 1 });
      setError([]);
      setIsLoading(false);
      setIsDisabled(false);
    }).catch(() => {
      setIsLoading(false);
      setIsDisabled(false);
    });
  }

  useEffect(() => {
    setValidations(prev => ({
      ...prev,
      hasRangeDate: filters?.rangeDate?.paramValue.length >= 2,
      hasIsMostRecent: filters?.isMostRecent?.paramValue.length >= 1,
    }))
    setFollowUpCategory(filters?.followUpCategories?.paramValue[0] || '');
  }, [filters])

  useEffect(() => {
    setIsDisabled(!(
      validations?.hasRangeDate &&
      validations?.hasIsMostRecent &&
      validations?.hasFollowUpRequired
    ))
  }, [validations])

  useEffect(() => {
    setFollowUpFilterList(getOptFilters(followUpCategory));
    if (followUpCategory === '') {
      setFollowUpFilterList([])
      setFollowUpFilters([])
      setValidations(prev => ({
        ...prev,
        hasFollowUpRequired: true
      }))
    } else {
      setFollowUpFilters([])
      setValidations(prev => ({
        ...prev,
        hasFollowUpRequired: false
      }))
    }
  }, [followUpCategory])

  useEffect(() => {
    if (Object.keys(followUpFilters).length > 0) {
      if (followUpCategory === 'SDOH') {
        setValidations(prev => ({
          ...prev,
          hasFollowUpRequired:
            followUpFilters?.followUpOutcomes?.displayValue.length > 0 &&
            followUpFilters?.followUpTypes?.displayValue.length > 0
        }))
      } else {
        setValidations(prev => ({
          ...prev,
          hasFollowUpRequired:
            followUpFilters?.followUpOutcomes?.displayValue.length > 0 &&
            followUpFilters?.followUpTypes?.displayValue.length > 0
        }))
      }
    }
  }, [followUpFilters])

  return (
    <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title id={`${componentName}-filterModalTitle`}>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body id={`${componentName}-filterModalBody`}>
        <Row className='d-flex'>
          {
            filtersList.map((filterGroup, index) => (
              <Row className='d-flex' key={`filterlist-${index}`}>
                {filterGroup.map(f =>
                  <Col xs={12} md={4} lg={4} className="mb-3" key={f.filterName}>
                    <Filter
                      selected={filters[f.key]}
                      filterDefinition={getFilterDef(f.filterName, { showRequired: f.required, mode: f.mode })}
                      handleChange={(e) => setFilters(Object.assign([], filters, { [f.key]: e }))}
                      refreshDataOnOpen={true}
                    />
                  </Col>
                )}
              </Row>
            ))
          }
          {
            followUpFilterList.length > 0 && followUpFilterList.map((filterGroup, index) => (
              index === 0 &&
              <>
                <div className="w-100 mb-3">
                  <p className="fw-bold mb-0 text-decoration-underline" style={{ color: '#2274A5', fontSize: '14px' }}>Follow-Up Filters</p>
                </div>
                <Row className="d-flex" key={`followUpFilterList-${index}`}>
                {filterGroup.map(f => <Col xs={12} md={4} lg={4} className="mb-3" key={f.filterName}>
                  <Filter
                    selected={followUpFilters[f.key]}
                    filterDefinition={getFilterDef(f.filterName, { showRequired: f.required, mode: f.mode, getData: f.getData })}
                    handleChange={(e) => setFollowUpFilters(Object.assign([], followUpFilters, { [f.key]: e }))}
                    refreshDataOnOpen={true} />
                </Col>
                )}
                </Row>
              </>
            ))
          }
        </Row>
      </Modal.Body>
      <Modal.Footer id={`${componentName}-filterModalFooter`}>
        <Button disabled={isDisabled} onClick={onFilter} id={`${componentName}-filterModalButtonFilter`}>
          {
            isLoading ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : 'Filter'
          }
        </Button>
        <Button onClick={() => setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}