import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {Tab, Nav, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {isEmpty} from "lodash";
import TimelineReferral from './TimelineReferral';
import {useReferralsContext} from '../context/Referrals.context';
import SortMenu from "./SortMenu";
import {sortItems} from "../constants";
import {SortParam} from "../../../../dtos/PatientSearchDTO";

function HeaderTabs({patient}) {
  const {
    referral,
    getMyMembersReferrals,
    referralsList,
    totalSize
  } = useReferralsContext();

  const userEventAction = useSelector(
    (state) => state?.manageVisitReducer?.userEventAction);
  const [sortParams, setSortParams] = useState([])
  const [selectedTab, setSelectedTab] = useState([])
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (patient?.memberId) {
      getMyMembersReferrals(patient?.id, getFiltersParamsByTab(selectedTab), getSortParamsOrDefault());
    }
  }, [patient, referral])

  useEffect(() => {
    if (referralsList) {
      setOpenStates(prevStates => {
        const newStates = new Array(referralsList.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [referralsList])

  useEffect(() => {
    if (userEventAction?.data === 'REFERRAL_ADDED') {
      getMyMembersReferrals(patient?.id, getFiltersParamsByTab(selectedTab), getSortParamsOrDefault());
    }
  }, [userEventAction])

  const onSelectTab = (tab) => {
    // we update the state to force the rendering of the tabs and thus meet the acceptance criteria:
    // AC: any expanded card must return to its original state (contracted)
    setSelectedTab(tab);

    if (tab === 'sent') {
      getMyMembersReferrals(patient?.id, getFiltersParamsByTab('sent'), getSortParamsOrDefault());
    } else if (tab === 'received') {
      getMyMembersReferrals(patient?.id, getFiltersParamsByTab('received'), getSortParamsOrDefault());
    } else if (tab === 'all') {
      getMyMembersReferrals(patient?.id, getFiltersParamsByTab('all'), getSortParamsOrDefault());
    }
  }

  const onSelectSort = (selectedSort) => {
    setSortParams(selectedSort);
    getMyMembersReferrals(patient?.id, getFiltersParamsByTab(selectedTab), selectedSort);
  }

  const getSortParamsOrDefault = () => {
    return !isEmpty(sortParams) ? sortParams : [new SortParam("statusupdated",
      "DESC", 1)];
  }

  const getFiltersParamsByTab = (tab) => {
    if (tab === 'sent') {
      return [{
        paramName: "Data Type",
        paramValue: ["sent"],
        comparison: "eq",
        displayValue: ["Sent"]
      }];
    } else if (tab === 'received') {
      return [{
        paramName: "Data Type",
        paramValue: ["received"],
        comparison: "eq",
        displayValue: ["Received"]
      }];
    }
    return [];
  }

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  const toggleIndividualOpen = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? 'open' : state));
  };

  return (
    <Tab.Container defaultActiveKey="all">
      <div className="stickyscroll bg-light">
        <Nav variant="tabs" as="nav" className="mb-3 patientdrawer-tabs align-items-center" onSelect={onSelectTab}>
          <Nav.Link eventKey="all" className="nav-item rounded-start">All</Nav.Link>
          <Nav.Link eventKey="sent" className="nav-item rounded-0">Sent</Nav.Link>
          <Nav.Link eventKey="received" className="nav-item rounded-start-0 rounded-end">Received</Nav.Link>
          <SortMenu
            items={sortItems}
            sortParams={getSortParamsOrDefault()}
            setSortParams={onSelectSort}
          />
        </Nav>
        <Row className='align-items-baseline'>
          <Col>
              <span
                bg="light"
                id={`assessmentsEventsCount`}
                className="border text-dark badge badge-pill mt-2">
                {totalSize}{" "}{"Referral"}{totalSize === 1 ? '' : 's'}
              </span>
          </Col>
          <Col md={6} className='text-end'>
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Expand / Collapse All
                </Tooltip>
              }
            >
              <span
                bg="light"
                className="border text-dark badge badge-pill mt-2"
                onClick={() => toggleAll(!expandAll)}
                style={{maxWidth: 'fit-content', fontSize: '12px'}}
              >
                <i className='fas fa-angle-double-up'/>
                <i className='fas fa-angle-double-down'/>
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      </div>
      <Tab.Content>
        <Tab.Pane eventKey="all">
          <TimelineReferral
            items={referralsList?.filter(e => e?.showAll)}
            patient={patient}
            toggleIndividual={toggleIndividual}
            openStates={openStates}
            toggleIndividualOpen={toggleIndividualOpen}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="sent">
          <TimelineReferral
            items={referralsList?.filter(e => e?.dataType === 'sent')}
            patient={patient}
            toggleIndividual={toggleIndividual}
            openStates={openStates}
            toggleIndividualOpen={toggleIndividualOpen}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="received">
          <TimelineReferral
            items={referralsList?.filter(e => e?.dataType === 'received')}
            patient={patient}
            toggleIndividual={toggleIndividual}
            openStates={openStates}
            toggleIndividualOpen={toggleIndividualOpen}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default HeaderTabs