import React from 'react'
import { useState } from 'react'
import { Badge, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import axios from '../../../../../../utils/apiClient'
import { useAuth } from '../../../../../../context/authContext'
import { useDispatch } from 'react-redux'
import { setReloadWorklist } from '../../../../../../actions/manageVisitActions'

const componentName = 'enrollMemberModal'

const CarePrograms = ({ patient, careManagementsPkg }) => {
  return (
    <Row className="mb-4 bg-light p-3 rounded-3 overflow-auto mb-3" style={{ maxHeight: '350px' }}>
      <Form.Group id={`${componentName}-patientName-group`} className="d-flex flex-column">
        <Form.Label id={`${componentName}-patientName-label`}>
          Patient Name
        </Form.Label>
        <span
          id={`${componentName}-patientName-value`}
          className='forced-capital'
        >
          {patient.fullName}
        </span>
      </Form.Group>
      <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
        <Form.Label id={`${componentName}-currentLabel`}>
          Current Care Programs
        </Form.Label>
        {careManagementsPkg.state.data &&
          careManagementsPkg.state.data.map((cp, idx) => cp.isActive ? (
              <>
                  <div id={`${componentName}-carePath-${idx}`} className="d-block">
                      <span id={`${componentName}-carePathName-${idx}`}>{cp.name}</span>
                      {cp.primary && (
                          <Badge id={`${componentName}-carePathPrimary-${idx}`} variant="secondary" className="ms-2">
                              Primary
                          </Badge>
                      )}
                  </div>
              </>
          ) : null
        )}
      </Form.Group>
    </Row>
  )
}

function EnrollMember({ patient, handleCancel, handleModal, carePath, careManagementsPkg, cb = () => { } }) {
  const [isPending, setisPending] = useState(false);
  const [note, setNote] = useState('');
  const auth = useAuth();
  const dispatch = useDispatch();

  const handleSend = () => {
    try {
      axios.patch(process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/care-path-status', {
        carePathInstanceId: carePath.id,
        patientProgramId: carePath.patientProgramId,
        patientId: patient.id,
        userId: auth.getUserId(),
        noteText: note,
        carePathStatus: "ENROLLED",
        reason: isPending ? '2205_103 change to Enrolled' : null
      }, {
        headers: {
          "Content-Type": "application/json"
        },
      }).then(() => {
        careManagementsPkg.fetchData(patient.id)
        dispatch(setReloadWorklist(true))
        cb()
        handleModal()
        handleCancel()
      });
    } catch (error) {
    }
  }

  return (
    <>
      <Modal.Body>
        <CarePrograms patient={patient} careManagementsPkg={careManagementsPkg}/>
        <Row>
          <Form.Check id={`${componentName}-checkbox`} type="checkbox" className='mb-5'>
            <Form.Check.Input
              type="checkbox"
              value={isPending}
              checked={isPending}
              onChange={(e) => setisPending(e?.target.checked)}
            />
            <Form.Check.Label id={`${componentName}-checkboxLabel`}>Patient Pending Discharge?</Form.Check.Label>
          </Form.Check>
        </Row>
        <Row>
          <Form.Label id={`${componentName}-notesLabel`}>
            Notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            max="1000"
            onChange={e => setNote(e.target.value)}
            id={`${componentName}-notesValue`}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleSend()}>
          Add Step
        </Button>
      </Modal.Footer>
    </>
  )
}

export default EnrollMember