import React from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { DDLMode } from '../../../../components/dropDownLists/genericDDL';
import { getFilterDef } from '../../../../components/filters/filterDefinition';
import { Filter } from '../../../../components/filters/filterGroup';
import { insightsHeaders, tableHeaders } from '../utils';

const componentName = "PerformanceManager";

export default function PerformaceSection({ children }) {
  return (
    <Row>
      <Col className="mb-3 mt-3">
        {children}
      </Col>
    </Row>
  )
}

const Body = ({ field, data }) => {
  return (
    <Row xs={1} md={5} className="g-4 text-center">
      {field?.map(f => {
        return (
          <Col>
            <Card>
              <Card.Body>
                <Card.Title id={`${componentName}-${f.key}Value`}>
                  {data?.[f.key]}
                </Card.Title>
                <Card.Text id={`${componentName}-${f.key}Label`}>
                  {f.label}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
}

const Table = ({ data }) => {
  return (
    <div className="table-responsive text-nowrap overflow-auto">
      <table className="table table-bordered table-hover">
        <thead className="table-light fixedHead">
          <tr>
            {tableHeaders.mainHeaders.map(header => {
              return (
                <th
                  scope="col"
                  id={`${componentName}-tableHeader-${header.key}`}
                  colspan={header.span}
                  className="text-center"
                >{header.title}</th>
              )
            })
            }
          </tr>
          <tr>
            {tableHeaders.secondaryHeaders.map(header => {
              return (
                <th scope="col" className="text-center" id={`${componentName}-tableHeader-${header.key}`}>{header.title}</th>
              )
            })
            }
          </tr>
        </thead>
        <tbody>
          {data?.map(c => {
            return (
              <tr>
                <th scope="row">{c?.name}</th>
                <td>{c?.team}</td>
                {
                  tableHeaders.secondaryHeaders?.map((f, idx) => {
                    return idx > 1 && <td className="text-center">{c?.[f.key]}</td>
                  })
                }
              </tr>
            )
          })
          }
        </tbody>
      </table>
    </div>
  )
}

const InsightsFilters = ({ filters, setFilters, handleFilter, isLoading }) => {
  return(
    <Row className='align-items-center'>
      <Col xs={12} md={4} lg={2} className="mb-3">
        <Filter
          selected={filters['interventionTypeId']}
          filterDefinition={getFilterDef('Intervention type', { mode: DDLMode.MultipleSelect })}
          handleChange={(e) => setFilters(Object.assign([], filters, { ['interventionTypeId']: e }))}
          refreshDataOnOpen={true}
        />
      </Col>
      <Col xs={12} md={4} lg={2} className="mb-3">
        <Filter
          selected={filters['interventionChannelId']}
          filterDefinition={getFilterDef('Intervention channel', { mode: DDLMode.MultipleSelect })}
          handleChange={(e) => setFilters(Object.assign([], filters, { ['interventionChannelId']: e }))}
          refreshDataOnOpen={true}
        />
      </Col>
      <Col xs={12} md={4} lg={2} className="mb-3">
        <Filter
          selected={filters['interventionSourceId']}
          filterDefinition={getFilterDef('Intervention source', { mode: DDLMode.MultipleSelect })}
          handleChange={(e) => setFilters(Object.assign([], filters, { ['interventionSourceId']: e }))}
          refreshDataOnOpen={true}
        />
      </Col>
      <Col xs={12} md={4} lg={2} className="mb-3">
        <Filter
          selected={filters['Intervention outcome']}
          filterDefinition={getFilterDef('Intervention outcome', { mode: DDLMode.MultipleSelect })}
          handleChange={(e) => setFilters(Object.assign([], filters, { ['interventionOutcomeId']: e }))}
          refreshDataOnOpen={true}
        />
      </Col>
      <Col xs={12} md={4} lg={2} className="mb-3">
        <Button onClick={handleFilter} id={`${componentName}-filterInsightsButton`} disabled={isLoading}>
          {
            isLoading ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : 'Filter'
          }
        </Button>
      </Col>
    </Row>
  )
}

const InsightsTable = ({ data }) => {
  return (
    <div className="table-responsive text-nowrap overflow-auto">
      <table className="table table-bordered">
        <thead className="table-light fixedHead">
          <tr>
            {insightsHeaders.map(header => {
              return (
                <th
                  scope="col"
                  id={`${componentName}-tableHeader-${header.key}`}
                >{header.title}</th>
              )
            })
            }
          </tr>
        </thead>
        <tbody>
          {data?.map(c =>
            <tr>
              {
                insightsHeaders?.map(f => <td>{c?.[f.key]}</td>)
              }
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

PerformaceSection.Body = Body;
PerformaceSection.Table = Table;
PerformaceSection.InsightsFilters = InsightsFilters;
PerformaceSection.InsightsTable = InsightsTable;