import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { searchPatients } from '../../../../../actions/caremgmt/hubWorkCenterAction';
import { getMembers } from '../../../../../actions/myMembersActions';
import { EQHSpinner } from '../../../../../components/EQHSpinner';
import { assignMember } from '../utils';
import HandlePatientSearch from './HandlePatientSearch';
import ProgramSelect from './ProgramSelect';
import EQHErrorToast from "../../../../../components/EQHErrorToast";

const componentName = "AddMemberModal";

export default function AddMemberModal({
  showMember,
  membersPagination,
  setShowMember,
  setMembersData,
  setMembersPagination,
  currentUser = null
}) {
  const [showMemberList, setShowMemberList] = useState(false);
  const [isAddingMember, setAddingMember] = useState(false);
  const [addMemberDisabled, setAddMemberDisabled] = useState(true);
  const [patientSearchParam, setPatientSearchParam] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [results, setResults] = useState([]);
  const [patientSelected, setPatientSelected] = useState({});
  const [isEmpty, setisEmpty] = useState(true);
  const [isMemberDisabled, setMemberDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    team: [],
    name: [],
    programs: [],
    programSelected: [],
    openCarePath: true,
  });

  const onError = (err) => {
    if (typeof err === 'string') {
      setErrors([err]);
    } else if (Array.isArray(err)) {
      setErrors([err.join(', ')]);
    }
    else {
      const { response } = err;
      if (response && response.data) {
        setErrors([response.data.message]);
      }
    }
  }

  const handleCancel = () => {
    setMemberDisabled(false);
    setButtonDisabled(true);
    setAddMemberDisabled(true);
    setPatientSearchParam('');
    setPatientSelected({});
    setResults([]);
    setFormData({
      ...formData,
      name: '',
      programSelected: '',
      openCarePath: true,
    })
    setShowMember(false);
  }

  const handlePatientSearchParamChange = (event) => {
    setPatientSearchParam(event.target.value);
    if (event.target.value.trim() === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleCloseMemberList = () => {
    setPatientSearchParam('');
    setPatientSelected({});
    setResults([]);
    setButtonDisabled(true);
    setAddingMember(false);
    setShowMemberList(false);
    setAddMemberDisabled(true);
  };

  const handleAddMember = () => {
    setAddingMember(false);
    setMemberDisabled(true);
    setShowMemberList(false);
  }

  const handlePatientSelected = (patient, event) => {
    if (event.target.checked) {
      setAddMemberDisabled(false);
      setPatientSelected(patient);
      setPatientSearchParam(patient.surnameCommaName);
    } else {
      setPatientSelected({});
    }
  }

  const handleShowMemberList = () => {
    setAddingMember(true);
    setShowMemberList(true);
    searchPatients(patientSearchParam).then((response) => {
      setResults(response.data?.patients || []);
      setisEmpty(response.data?.patients.length === 0);
      setAddingMember(false);
      setButtonDisabled(true);
    }).catch(() => setAddingMember(false));
  }

  const handleAssignMember = () => {
    assignMember({
      action: 'ASSIGN',
      programLeadId: formData.name?.[1],
      programId: formData.programSelected,
      patientId: patientSelected?.id,
      openCarePath: formData.openCarePath,
      origin: 'MY_WORKSPACE',
      programName: formData.programName
    }).then(() => {
      getMembers(1, 10, 'FULL_NAME', 'asc', '', currentUser?.userAccountId || '')
        .then(res => {
          setMembersData(res.data.members);
          handleCancel();

          setMembersPagination({
            ...membersPagination,
            count: res?.data?.count,
            currentPage: 1
          });
        }).catch(() => handleCancel());
    }).catch(error => {
      onError(error);
    })
  }

  const handleOpenCarePathChanged = (value) => {
    setFormData({...formData, openCarePath: value});
  }

  return (
    <>
      <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
      <Modal
        size="xl"
        centered show={showMember}
        onHide={() => handleCancel()}
      >
        <Modal.Header>
          <Modal.Title id={`${componentName}-addMemberModalTitle`}>
            Enroll Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center align-items-end">
            <Col xs={8} className="mb-3">
              <label id={`${componentName}-addMemberNameLabel`} className="d-block">Member Name or ID</label>
              <Form.Control
                id={`${componentName}-addMemberNameValue`}
                onChange={(e) => handlePatientSearchParamChange(e)}
                type="text"
                value={patientSearchParam}
                disabled={isMemberDisabled}
              />
            </Col>
            <Col className="mb-3">
              <Button
                id={`${componentName}-addMemberSearchBtn`}
                variant="primary"
                onClick={() => handleShowMemberList()}
                disabled={buttonDisabled}
              >Search Members</Button>
            </Col>
            <Col className="mb-3">
              <Form.Check id={`${componentName}-openCarePath`} type="checkbox">
                <Form.Check.Input
                    type="checkbox"
                    value={formData.openCarePath}
                    checked={formData.openCarePath}
                    onChange={(e) => handleOpenCarePathChanged(e?.target.checked)}
                />
                <Form.Check.Label id={`${componentName}-openCarePathLabel`}>Open Care Path</Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
          <ProgramSelect formData={formData} setFormData={setFormData} currentUser={currentUser}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={`${componentName}-addMemberCancelBtn`}
            variant="secondary"
            onClick={() => handleCancel()}
          >Cancel</Button>
          <Button id={`${componentName}-addMemberAddBtn`} variant="primary" onClick={() => handleAssignMember()}>
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered show={showMemberList} onHide={handleCloseMemberList}>
        <Modal.Header closeButton>
          <Modal.Title id={`${componentName}-memberSearchModalTitle`}>Member Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAddingMember && <EQHSpinner />}
          {!isAddingMember &&
            <HandlePatientSearch
              handlePatientSelected={handlePatientSelected}
              patientSelected={patientSelected}
              results={results}
              isEmpty={isEmpty}
            />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button id={`${componentName}-memberSearchCancelBtn`} variant="secondary" onClick={handleCloseMemberList}>
            Cancel
          </Button>
          <Button id={`${componentName}-memberSearchAddBtn`} disabled={addMemberDisabled} variant="primary" onClick={handleAddMember}>
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}