import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { Timeline as GenericTimeline } from '../../../../components/Timeline';
import {
  splitDateTime,
  formatDateVanilla,
  formatDateWithTime,
  formatLocalDate,
} from '../../../../utils/util';
import EventTimelineCard from './EventTimelineCard';
import { useEventTimelineContext } from "../context/EventTimeline.context";
import { FilterGroupNew } from '../../../../components/filters/filterGroupNew';
import { getFilterDef } from '../../../../components/filters/filterDefinition';
import { isEmpty } from "lodash";

const componentName = 'eventTimeline';

function EventTimeline({ patient, reachedBottom, setReachedBottom }) {
  const {
    getEventsTimeline,
    eventsTimelineList,
    setUserNameListFilter,
    userNameListFilter,
    pageNumber,
    scrollEventsTimeline,
    isLoading,
    hasMore,
    totalSize,
    eventTimelineSearch,
    availableEventTypes
  } = useEventTimelineContext();

  const [filterParams, setFilterParams] = useState([]);
  const [filters, setFilters] = useState([]);
  const [openStates, setOpenStates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (patient?.memberId) {
      getEventsTimeline(patient?.id, eventTimelineSearch);
      setUserNameListFilter(patient?.id)
    }
  }, [patient])

  useEffect(() => {
    if (eventsTimelineList?.length > 0 && reachedBottom && !isLoading && hasMore) {
      scrollEventsTimeline(patient?.id, eventTimelineSearch)
        .then(() => setReachedBottom(false))
    }
  }, [reachedBottom])

  useEffect(() => {
    if (eventsTimelineList) {
      setOpenStates(prevStates => {
        const newStates = new Array(eventsTimelineList.length).fill(expandAll);
        prevStates.forEach((state, index) => {
          if (index < newStates.length) {
            newStates[index] = state;
          }
        });
        return newStates;
      });
    }
  }, [eventsTimelineList])

  const generatedAtAlreadySeenSet = new Set();

  useEffect(() => {
    const filterNames = [
      'eventType',
      'Date',
      'User Name',
      'Has Attachment'
    ]

    setFilters(filterNames.map(f => {
      let filterProps = getFilterDef(f);
      const filtersValueByFilter = {'User Name': userNameListFilter, 'eventType': availableEventTypes};
      if (filtersValueByFilter[f]) {
        filterProps = {
          ...filterProps,
          componentProps: {
            ...filterProps.componentProps,
            getData: () => Promise.resolve(filtersValueByFilter[f])
          }
        }
      }
      return filterProps;
    }));
  }, [userNameListFilter, availableEventTypes])

  const millisecondsRemover = (generatedAtFormatted) => {
    if (!generatedAtFormatted) {
      return null;
    }

    const date = generatedAtFormatted.split(":");
    date.pop();
    return date.join('-')
  }

  const toggleAll = (state) => {
    setExpandAll(state)
    setOpenStates(prev => prev.map(() => state));
  };

  const toggleIndividual = (index) => {
    setOpenStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  return (
    <Row className={componentName + "-event-timeline-view"}>
      <Row className="stickyscroll bg-light">
        <Col md={12}>
          <FilterGroupNew
            filters={filters}
            handleChange={params => {
              setReachedBottom(false)
              setFilterParams(params)
              getEventsTimeline(patient?.id, {...eventTimelineSearch, pageNumber: 1, filters: params })
            }}
            defaultFilterParams={[]}
            filterParams={filterParams}
            isManageVisit={true}
          />
        </Col>
        <Row className='align-items-baseline'>
          <Col md={6}>
            <span
              bg="light"
              id={`${componentName}-patientCount`}
              className="border text-dark badge badge-pill mt-2">
              {totalSize}{" "}{"Event"}{totalSize === 1 ? '' : 's'}
            </span>
          </Col>
          <Col md={6} style={{ textAlign: 'right' }}>
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Expand / Collapse All
                </Tooltip>
              }
            >
              <span
                bg="light"
                className="border text-dark badge badge-pill mt-2"
                onClick={() => toggleAll(!expandAll)}
                style={{maxWidth: 'fit-content', fontSize: '12px', marginRight: '-40px' }}
              >
                <i className='fas fa-angle-double-up'/>
                <i className='fas fa-angle-double-down'/>
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      </Row>
      <GenericTimeline
        items={eventsTimelineList ?? []}
        titleBuilder={(eventTimeline, idx) => {
          // by definition, events with the same date (excluding milliseconds) must be grouped
          const generatedAtWithoutMilliseconds = millisecondsRemover(
            formatDateWithTime(eventTimeline.generatedAt)
          );
          const shouldShowGeneratedAt = !generatedAtAlreadySeenSet.has(generatedAtWithoutMilliseconds);

          if (shouldShowGeneratedAt) {
            generatedAtAlreadySeenSet.add(generatedAtWithoutMilliseconds)
          }

          const [, time] = splitDateTime(formatDateVanilla(eventTimeline?.generatedAt, "MMM dd, yyyy, hh:mm a"));
          const isEncounterOrLabData = ['Encounter', 'Lab Data'].includes(eventTimeline.type);

          return eventTimeline.generatedAt && shouldShowGeneratedAt ? (
            <div>
              <span id={`${componentName}-tlDate-${idx}`}
                className="d-block fw-bold">
                {isEncounterOrLabData ? formatDateVanilla(eventTimeline?.generatedAt, "MMM dd, yyyy", true) : formatLocalDate(
                    eventTimeline.generatedAt)}
              </span>
              <span id={`${componentName}-tlTime-${idx}`} className="d-block">
                {isEncounterOrLabData ? '' : time}
              </span>
            </div>
          ) : (
            !isEmpty(generatedAtWithoutMilliseconds) ? "" : "-"
          )
        }
        }
        bodyBuilder={(eventTimeline, idx) => (
          <EventTimelineCard
            section={'all'}
            idx={idx}
            eventTimeline={eventTimeline}
            toggleIndividual={() => toggleIndividual(idx)}
            open={openStates[idx]}
          />
        )}
      />
      {isLoading &&
        <div className="d-flex w-100 justify-content-center">
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {
        !hasMore &&
        <div className="d-flex w-100 justify-content-center">
          <p>You have reached the bottom</p>
        </div>
      }
    </Row>
  )
}

export default EventTimeline