import React, { useEffect, useState } from "react";
import {useCMWorklistContext} from "../context/CMWorklist.context";
import {Col, Dropdown, Modal, Row} from "react-bootstrap";
import PatientCardActions from "../../PatientCardActions";
import {getDisplayValue} from "../../../../../../utils/util";
import {status_referrals} from "../../../../../../utils/textValueLists";
import {get, isEmpty} from "lodash";
import {StatusLabel} from "../../../../../quickList/Referrals/constants";
import PatientRecordBoldField from "../fields/PatientRecordBoldField";
import { isRoleInCMTeam } from "../../../utils";
import { patientInformationSection, useGetCareManagements } from "../../../../../../actions/patientProfileActions";
import { careManagementsReducer } from "../../../../../../reducers/patientProfile/careManagementsReducer";
import EnrollMember from "../../../../../quickList/components/careProgramsMock/components/modals/EnrollMember";
import { useAuth } from "../../../../../../context/authContext";
import axios from "../../../../../../utils/apiClient";
import CloseCareProgram from "../../../../../quickList/components/careProgramsMock/components/modals/closeCareProgram";

const componentName = "ZoneColumn"

const ZoneColumn = ({ patient, idx }) => {
  const [displayReferralStatusPill, setDisplayReferralStatusPill] = useState(false);
  const careManagementsPkg = useGetCareManagements([], careManagementsReducer);
  const [selectedExpired, setSelectedExpired] = useState(false);
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(null);
  const [selectedExpiredSource, setSelectedExpiredSource] = useState(null);
  const [primaryCloseError, setPrimaryCloseError] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [careProgramObject, setCareProgrammObject] = useState({
    show: false,
    careProgram: null,
    action: '',
    title: ''
  });

  const {
    activeRole,
    careManagementTeams
  } = useCMWorklistContext();

  const referralStatus = get(patient.referralDtoList, '0.status');

  const { role } = activeRole;
  const auth = useAuth();

  useEffect(()=> {
    let displayReferralPill = false;

    if(!isRoleInCMTeam(role, careManagementTeams)) {
      displayReferralPill = true;
    }

    setDisplayReferralStatusPill(displayReferralPill)
  }, careManagementTeams)

  const getColumn = () => {
    switch (role) {
      case 'Chaplains':
        return <ZoneColumnChaplains patient={patient} idx={idx} />
      case 'Care Specialists':
        return <ZoneColumnCareSpecialist patient={patient} idx={idx} />
      case 'Community Health Workers':
        return <ZoneColumnCHW patient={patient} idx={idx} />
      case 'Clinical Coordinators':
        return <ZoneColumnClinical patient={patient} idx={idx} />
      case "Hospital Liaisons":
        return <ZoneColumnHospitalLiaisons patient={patient} idx={idx} />
      default:
        return <ZoneColumnDefault patient={patient} idx={idx} />
    }
  }

  const handleCancel = (action) => {
    setCareProgrammObject({ ...careProgramObject, show: false })
    setSelectedReason("")
  };

  const handleModal = () => {

  };

  const handleEnroll = () => {
    patientInformationSection(patient.id)
      .then(res => {
        setCareProgrammObject({
          ...careProgramObject,
          careProgram: res.data?.carePaths.filter(cp => cp.id === patient?.referralDtoList[0]?.id)[0],
          action: 'enroll',
          show: true
        })
      })
  }

  const handleOutreach = () => {
    try {
      axios.patch(process.env.REACT_APP_REST_API_BASE_URL + '/v1/care-manager-workspace/care-path-status', {
        carePathInstanceId: careProgramObject.id,
        patientProgramId: careProgramObject.patientProgramId,
        patientId: patient.id,
        userId: auth.getUserId(),
        carePathStatus: "OUTREACH_IN_PROGRESS"
      }, {
        headers: {
          "Content-Type": "application/json"
        },
      }).then(() => {
        careManagementsPkg.fetchData(patient.id)
      });
    } catch (error) {
    }
  }

  const handleRemovePath = () => {
    setCareProgrammObject({
      ...careProgramObject,
      careProgram: null
    })
    careManagementsPkg.fetchData(patient.id);
  };

  const handleClose = () => {
    setCareProgrammObject({ ...careProgramObject, action: 'closeCareProgram', show: true })
    setSelectedReason("")
  }

  const disableQuickAction = () => {
    return (
      role === 'Admin Role' ||
      role === 'Hub Coordinator'
    )
  }

  return (
    <>
    {(
      displayReferralStatusPill
    ) &&
      <Col id={`${componentName}-${idx}-referral-status`}>
        <div>
          {!isEmpty(referralStatus) &&
            <Dropdown>
              <Dropdown.Toggle
                className={`badge rounded-pill border-0 me-4 status-pill cursor-p ${disableQuickAction() ? 'pe-none' : ''}`}
                as={StatusLabel}
                id="dropdown-basic"
                urgency={referralStatus}
              >
                {getDisplayValue(status_referrals, referralStatus)}
                {!disableQuickAction() && <i className={`fa fa-angle-down`} style={{ marginLeft:'5px', position: 'absolute', top: '7px' }}/>}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleEnroll()}
                >Enroll Member</Dropdown.Item>
                <Dropdown.Item onClick={() => handleOutreach()}>Outreach In Progress</Dropdown.Item>
                <Dropdown.Item onClick={() => handleClose()}>Close Care Program</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
        <Modal id={`${componentName}-modal`} show={careProgramObject.show} onHide={handleCancel}>
          <Modal.Header>
            <Modal.Title id={`${componentName}-carePathTitle`}>{careProgramObject.title}</Modal.Title>
            <button id={`${componentName}-carePathClose`} type="button" onClick={handleCancel} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </Modal.Header>
          {careProgramObject && careProgramObject?.action === 'enroll' &&
            <EnrollMember
              patient={patient}
              careManagementsPkg={careManagementsPkg}
              handleCancel={handleCancel}
              carePath={careProgramObject.careProgram}
              handleModal = {handleModal}
            />
          }
          {careProgramObject && careProgramObject?.action === 'closeCareProgram'  &&
            <CloseCareProgram
              removePath={handleRemovePath}
              careManagementsPkg={careManagementsPkg}
              patient={patient}
              selectedCarePath={careProgramObject.careProgram}
              openPrimaryModal={() => {}}
              selectedReason={selectedReason}
              setSelectedReason={setSelectedReason}
              selectedExpired={selectedExpired}
              setSelectedExpired={setSelectedExpired}
              selectedExpiredDate={selectedExpiredDate}
              setSelectedExpiredDate={setSelectedExpiredDate}
              selectedExpiredSource={selectedExpiredSource}
              setSelectedExpiredSource={setSelectedExpiredSource}
              close={handleCancel}
              primaryCloseError={primaryCloseError}
              patientInformation={() => {}}
            />
          }
        </Modal>
      </Col>
    }
    <Col
      id={`${componentName}-columnActions-${idx}-lastColumn`}
      key={`${componentName}-columnActions-key-${idx}-lastColumns`}
    >
        <Row className="text-align-end justify-content-end align-items-center">
          <Col
            id={`${componentName}-patientZone-${idx}`}
            className="d-inline-block text-muted text-end"
            style={{
              maxWidth: 'fit-content',
              fontSize: '12px'
            }}
          >
            {patient?.carePathStepDto?.stepState !== 'SNOOZED' &&
              getColumn()}
          </Col>
          <Col
            id={`${componentName}-columnActions-${idx}`}
            key={`${componentName}-columnActions-key-${idx}`}
            style={{ maxWidth: 'fit-content' }}
          >
            <PatientCardActions
              idx={idx}
              id={`${componentName}-menuActions-${idx}`}
              key={`${componentName}-menuActions-key-${idx}`}
              patient={patient} />
          </Col>
        </Row>
      </Col>
    </>
  )
}

const ZoneColumnDefault = ({ idx, patient }) => {
  const referralByName = get(patient.referralDtoList, '0.referredBy.name');

  return (
    <div className="d-flex justify-content-start align-items-center text-start">
      <div style={{ minWidth: '150px', maxWidth: '150px' }}>
        <div>
          <span id={`${componentName}-patientZoneLabel-${idx}`} className="fw-bold me-2">Zone:</span>
          <span id={`${componentName}-patientZoneValue-${idx}`}>{patient.zone}</span>
        </div>
        {patient?.practiceName &&
          <span
            id={`${componentName}-patientPracticeLabelAddress-${idx}`}
            className="fw-bolder me-2"
          >
            Practice: <span>&nbsp;&nbsp;</span>
            <span className="fw-normal text-capitalize">{` ${patient?.practiceName}`}</span>
          </span>
        }
        {!isEmpty(referralByName) &&
          <div>
            <span id={`${componentName}-patientSenderLabel-${idx}`} className="fw-bold me-2">Sender:</span>
            <span id={`${componentName}-patientSenderValue-${idx}`}>{referralByName}</span>
          </div>
        }
      </div>

    </div>
  )
}

const ZoneColumnChaplains = ({ idx, patient }) => {
  return (
    <div className="d-flex flex-column">
    </div>
  )
}

const ZoneColumnCHW = ({ idx, patient }) => {
  return (
    <div
      className="d-flex flex-column"
    >
      {patient?.payer &&
        <span
          id={`${componentName}-patientPayorAddress-${idx}`}
          className="fw-bolder me-2"
          style={{
            maxWidth: '245px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          Payor:
          <span className="fw-normal text-capitalize">{` ${patient?.payer}`}</span>
        </span>
      }
      {patient?.practiceName &&
        <span
          id={`${componentName}-patientPracticeLabelAddress-${idx}`}
          className="fw-bolder me-2"
        >
          Practice:
          <span className="fw-normal text-capitalize">{` ${patient?.practiceName}`}</span>
        </span>
      }
      {patient?.practicePhoneNumber &&
          <span
              id={`${componentName}-patientPracticePhoneLabel-${idx}`}
              className="fw-bolder me-2"
          >
        PCP Phone:
        <span className="fw-normal text-capitalize">{` ${patient?.practicePhoneNumber}`}</span>
      </span>
      }
    </div>
  )
}

const ZoneColumnClinical = ({ idx, patient }) => {
    return (
      <div className="d-flex flex-column">
        <span id={`${componentName}-patientZoneLabelPayor-${idx}`} className="fw-bolder me-2 mt-1">{"Payor: "}
          <span className="fw-normal">{patient?.payer}</span>
        </span>
        <span id={`${componentName}-patientPracticeName-${idx}`} className="fw-bolder me-2 mt-1">{"Practice: "}
          <span className="fw-normal">{patient?.practiceName}</span>
        </span>
        <span id={`${componentName}-patientZoneLabelPhoneNumber-${idx}`} className="fw-bolder me-2 mt-1">PCP Phone:
          <span className="fw-normal">{patient?.practicePhoneNumber}</span>
        </span>
      </div>
    )
}

const ZoneColumnCareSpecialist = ({ idx, patient }) => {
  return (
      <div className="d-flex flex-column">
        <span id={`${componentName}-patientZoneLabelPhone-${idx}`} className="fw-bolder me-2 mt-1">Practice:
          <span className="fw-normal">{` ${patient?.practiceName ?? ''}`}</span>
        </span>
        <span id={`${componentName}-patientZoneLabel-${idx}`} className="fw-bolder me-2 mt-1">PCP Phone:
          <span className="fw-normal">{` ${patient?.practicePhoneNumber ?? ''}`}</span>
        </span>
      </div>
  )
}

const ZoneColumnHospitalLiaisons = ({ patient, idx }) => {
  return (
    <div className="d-flex flex-column">
      <PatientRecordBoldField sourceName={`${componentName}-payor`} idx={idx} label={"Payor:"} value={patient?.payer}/>
      <PatientRecordBoldField sourceName={`${componentName}-practice`} idx={idx} label={"Practice:"} value={patient?.originatingOrganization}/>
      <PatientRecordBoldField sourceName={`${componentName}-hospital`} idx={idx} label={"Hospital:"} value={patient?.hospitalName}/>
    </div>
  )
}

export default ZoneColumn;