import React, {createContext, useContext, useReducer, useEffect} from 'react';
import {useQuery} from 'react-query';
import {actions, initialState} from './actions';
import {reducer} from './NewRR.reducer';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getCustomFilterKeys} from '../FiltersMapper';
import {
  searchPatientReferrals
} from '../../../../actions/patientSearch/patientSearchActions';
import {exportToCSV} from './api';
import {defaultSortParams} from '../const';
import sendGAEvent from "../../../../utils/googleAnalytics";
import {eventActions} from "../../../caremgmt/worklist/constants";
import {useAuth} from "../../../../context/authContext";

const NewRRContext = createContext();

const NewRRProvider = ({ children }) => {
  const auth = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getters = getGetters(state);
  const toggles = getToggles(dispatch);

  const sendEvent = async (action) => {
    await sendGAEvent('Referrals Received | CareEmpower', {
      event_category: state.activeRole?.role || auth.getRoles()[0],
      action_performed: eventActions[action],
      send_to: 'G-5QKH8J5J9X'
    });
  }

  const { refetch: getReferrals } = useQuery({
    queryKey: ['referralsSearch'],
    queryFn: () => {

      dispatch({ type: actions.SET_LOADING, payload: true })
      dispatch({ type: actions.SET_REFERRALS_SELECTED, payload: [] })

      let payload = {
        filterParams: [],
        pageNumber: 1,
        pageSize: 20,
        sortParams: defaultSortParams,
        previousTotalSize: 0,
        ...state.referralsSearch,
        quickListName: "REFERRALS_RECEIVED"
      };

      payload.filterParams = getCustomFilterKeys(payload.filterParams);

      toggles.setReferralsSearch({ ...payload })

      searchPatientReferrals(payload).then(res => {
        dispatch({type: actions.SET_REFERRALS, payload: res?.data})
        dispatch({type: actions.SET_LOADING, payload: false})
      })
      .catch(err => dispatch({type: actions.SET_LOADING, payload: false}))
    },
    refetchOnWindowFocus: false,
    enabled: false,
    staleTime: 10000
  })

  useEffect(() => {

    if (state?.referralsSearch?.timestamp) {
      getReferralsAsync()
    }
  }, [
    state?.referralsSearch?.timestamp
  ])

  const getReferralsAsync = async () => {
    getReferrals()
  }

  const callbacks = {
    getReferrals: () => getReferralsAsync(),
    setReferralsSelected: (params) => {
      let index = state.referralsSelected.map(r => r[1]).indexOf(params[1])
      let crr = [...state.referralsSelected]
      let payload
      if (index >= 0) {
        crr.splice(index, 1)
        payload = [...crr]
      } else {
        payload = [...state.referralsSelected, params]
      }
      dispatch({ type: actions.SET_REFERRALS_SELECTED, payload: payload })
    },
    selectAll: (params) => {
      dispatch({ type: actions.SET_REFERRALS_SELECTED, payload: params })
    },
    clearSelected: () => {
      dispatch({ type: actions.SET_REFERRALS_SELECTED, payload: [] })
    },
    exportToCSV: async () => {
      const parsedReferrals = state.referralsSelected.map(r => r[1])
      const res = await exportToCSV({
        selectedItems: parsedReferrals,
        quickList: "REFERRALS_RECEIVED",
        patientRequest: {
          filterParams: [],
          sortParams: [],
          pageNumber: 1,
          pageSize: 20,
          quickListName: "REFERRALS_RECEIVED"
        }
      })
      dispatch({ type: actions.SET_EXPORT_DATA, payload: res?.data })
    },
    clearExportData: () => {
      dispatch({ type: actions.SET_EXPORT_DATA, payload: [] })
    },
    sendEvent
  }

  const value = {
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <NewRRContext.Provider value={value}>
      {children}
    </NewRRContext.Provider>
  )
};
const useNewRRContext = () => useContext(NewRRContext);

export {
  NewRRProvider,
  useNewRRContext,
}