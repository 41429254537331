import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCareProgramModalOpen } from "../../../../../actions/manageVisitActions";
import CloseCareProgram from "./modals/closeCareProgram";
import axios from "axios";
import { EQHSpinner } from "../../../../../components/EQHSpinner";
import EditCareStep from "./modals/editCareStep";
import { useCMWorklistContext } from "../../../../caremgmt/worklist/components/CMWorklistModals/context/CMWorklist.context";
import NewCareProgram from "./newCareProgram";
import ToastMessage from "../../../../../components/Toast";
import EnrollMember from "./modals/EnrollMember";
import { useAuth } from "../../../../../context/authContext";

const componentName = "carePathKebab";

export const CarePathKebab = ({
  careProgram,
  patient,
  setSelectedCarePath,
  careManagementsPkg,
  saveNewStep,
  step ,
  patientInformation,
  setRefreshFilter,
  setCareProgrammObject,
  careProgramObject,
  selectedExpired,
  setSelectedExpired,
  selectedExpiredDate,
  setSelectedExpiredDate,
  selectedExpiredSource,
  setSelectedExpiredSource,
  pendingTransaction
}) => {
  const dispatch = useDispatch();
  const {
    selectPatient,
    toggleAssignPatientTo,
    assignPatientTo
  } = useCMWorklistContext();


  const [primaryCloseError, setPrimaryCloseError] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");

  const auth = useAuth();
  const authorizedCareProgramWrite = auth.hasAuthority("AUTH_PD_CARE_PROGRAMS_WRITE_ACCESS");

  const assignAsPrimary = (cp) => {
    setRefreshFilter(false);
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-primary-care-path?carePathInstanceId=${cp.id}`,
        {
          headers: {},
        }
      )
      .then(async () => {
        toggleAssignPatientTo({ ...assignPatientTo, refreshFromReassign:true });
        patientInformation(patient);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (assignPatientTo?.refreshFromReassign) {
      handleCancel()
    }

  }, [assignPatientTo?.refreshFromReassign])


  const handleModal = () => {
    setSelectedReason(selectedReason);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
  };

  const handleRemovePath = () => {
    setSelectedCarePath(null);
    //setCarePathSteps([]);
    careManagementsPkg.fetchData(patient.id);
    pendingTransaction()
  };

  const openPrimaryModal = () => {
    setPrimaryCloseError(true);
  };

  const handleCancel = () => {
    dispatch(setCareProgramModalOpen(false))
    setCareProgrammObject({ ...careProgramObject, show: false })
    setSelectedReason("")
  };

  const handleCarePathOptionChange = (e, careProgram, action, title) => {
    switch (action) {
      case 'addCarePathStep':
        dispatch(setCareProgramModalOpen(true));
        setCareProgrammObject({ ...careProgramObject, show: true, careProgram: careProgram, action: action, title: 'Add Care Step' });
        break;
      case 'makePrimary':
        assignAsPrimary(careProgram);
        break;
      case 'reassign':
        selectPatient(patient);
        dispatch(setCareProgramModalOpen(true));
        toggleAssignPatientTo({ show: true, source: "mangeVisitReassignCareProgram", careProgram: careProgram });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown className="dropdown-end carePathKebab" onSelect={(eventKey, event) => handleCarePathOptionChange(event, careProgram, eventKey)}>
        <Dropdown.Toggle variant="transparent" id={`${componentName}-carePathActions`} className="border-0">
          <i className="fa-solid fa-ellipsis fa-lg border-0 text-dark"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            id={`${componentName}-carePathMakePrimary`}
            eventKey="makePrimary"
            disabled={!authorizedCareProgramWrite || (careProgram?.primary || !careProgram?.active)}
          >
            Make Primary
          </Dropdown.Item>
          <Dropdown.Item
            id={`${componentName}-carePathReassignProgram`}
            eventKey="reassign"
            disabled={!authorizedCareProgramWrite || !careProgram?.active}
          >
            Reassign Care Program
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            id={`${componentName}-carePathAddStep`}
            eventKey="addCarePathStep"
            disabled={!authorizedCareProgramWrite || !careProgram?.active}
          >
            Add Care Step
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {careProgramObject && careProgramObject.action === 'reassign' &&
        <NewCareProgram handleCancel={handleCancel} />
      }
      <ToastMessage />
      <Modal id={`${componentName}-modal`} show={careProgramObject.show} onHide={handleCancel}>
        <Modal.Header>
          <Modal.Title id={`${componentName}-carePathTitle`}>{careProgramObject.title}</Modal.Title>
          <button id={`${componentName}-carePathClose`} type="button" onClick={handleCancel} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </Modal.Header>
        {careProgramObject && careProgramObject?.action === 'addCarePathStep' &&
          <EditCareStep
            step={step}
            saveNewStep={saveNewStep}
            action={careProgramObject.action}
            handleCancel={handleCancel}
          />}
        {careProgramObject && careProgramObject?.action === 'enroll' &&
          <EnrollMember
            patient={patient}
            careManagementsPkg={careManagementsPkg}
            handleCancel={handleCancel}
            carePath={careProgramObject.careProgram}
            handleModal = {handleModal}
          />
        }
        {careProgramObject && careProgramObject?.action === 'closeCareProgram'  &&
          <CloseCareProgram
            removePath={handleRemovePath}
            careManagementsPkg={careManagementsPkg}
            patient={patient}
            selectedCarePath={careProgramObject.careProgram}
            openPrimaryModal={openPrimaryModal}
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
            selectedExpired={selectedExpired}
            setSelectedExpired={setSelectedExpired}
            selectedExpiredDate={selectedExpiredDate}
            setSelectedExpiredDate={setSelectedExpiredDate}
            selectedExpiredSource={selectedExpiredSource}
            setSelectedExpiredSource={setSelectedExpiredSource}
            close={handleCancel}
            primaryCloseError={primaryCloseError}
            patientInformation={patientInformation}
          />
        }

        {careManagementsPkg.state.isLoading && <EQHSpinner />}
      </Modal>
    </>
  );
};

export default CarePathKebab;