import { GET_ERRORS } from "./types";
export const getErrorMsg = (err) => {
  let strErr = "";
  if (err) {
    if (err.response) {
      if (err.response.data) {
        strErr = err.response.data;
      } else {
        strErr = err.response;
      }
    } else {
      strErr = err;
    }
  }

  return strErr;
};

export const disPatchError = (err, dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: getErrorMsg(err),
  });
};
