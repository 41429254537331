import React from "react";
import { formatDateVanilla } from "../../../../../utils/util";

const componentName = 'AppointmentsTimelineTitle';

const AppointmentsTimelineTitle = ({ appointment, idx }) => {
  const splitDateTime = (dateTime) => {
    const lastCommaIndex = dateTime.lastIndexOf(',');
    const date = dateTime.substring(0, lastCommaIndex);
    const time = dateTime.substring(lastCommaIndex + 1).trim();
    return [date, time];
  };

  const [date, time] = splitDateTime(formatDateVanilla(appointment?.lastModified, "MMM dd, yyyy, hh:mm a"));

  return (
    <div>
      <span
        id={`${componentName}-date-${idx}`}
        className="d-block fw-bold"
      >
        {date}
      </span>
      <span
        id={`${componentName}-time-${idx}`}
      >
        {time}
      </span>
    </div>
  );
};

export default AppointmentsTimelineTitle;
