import axios from "../../../../../utils/apiClient";

const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-profiles/";

export const getPatient = (id) => {
    return  axios
        .get(process.env.REACT_APP_REST_API_BASE_URL + `/v1/manage-visit/find?patientId=${id}`, {
            headers: { "Content-Type": "application/json" },
        })
};

export const getProfile = (patientId) => {
    return axios
        .post(URL, {
            patientRequest: {
                filterParams: [
                    {
                        paramName: "Patient Status",
                        paramValue: [
                            "inactive",
                            "active"
                        ],
                        comparison: "eq",
                        displayValue: [
                            "Inactive",
                            "Active"
                        ]
                    }
                ],
                sortParams: [],
                pageNumber: 1,
                pageSize: 20,
                quickListName: "ACTIVE_INACTIVE_PATIENTS"
            },
            selectedItems: [
                patientId
            ],
            quickList: "ACTIVE_INACTIVE_PATIENTS"
        }, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}