import { GET_ERRORS, RESET_ERRORS } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ERRORS:
      return payload;
    case RESET_ERRORS:
      return {
        state: {}
      }
    default:
      return state;
  }
}