import { disPatchError } from "../../utils";
import {
  ADD_USER_TO_CONVERSATION,
  CREATE_NEW_CONVERSATION,
  CREATE_NEW_MESSAGE,
  GET_USER_MESSAGES,
  GET_USER_MESSAGES_COUNT,
  RESET_ERRORS,
  SEND_REPLY,
  MARK_READ,
  GET_MESSAGES_FROM_CONVO,
  DELETE_MESSAGES,
  MESSAGE_TYPES, GET_ALL_USER_MESSAGES
} from "../../types";
import axios from "../../../utils/apiClient";
export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/messaging/";

export const getUserMessages = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "conversation/messages/latest", {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: GET_USER_MESSAGES,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getAllUserMessages = (task) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
      .get(URL + "allUserMessages", {
        headers: {},
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_USER_MESSAGES,
          payload: res.data,
        });
        if(task){
          task();
        }
      })
      .catch((err) => disPatchError(err, dispatch));
};

export const getMessageCount = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + "messages/count", {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: GET_USER_MESSAGES_COUNT,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getMessagesFromConvo = (convoId) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .get(URL + `conversation/?conversationDtoId=${convoId}`, {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: GET_MESSAGES_FROM_CONVO,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const createMessage = (newMessage) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + "message", newMessage, {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: CREATE_NEW_MESSAGE,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const markAsRead = (convId, readUnRead) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  const markAsReadObj = {
    conversationIds: convId,
    readStatus: readUnRead,
  };
  axios
    .put(URL + "conversations", markAsReadObj, {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: MARK_READ,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const addUser = (reqObj) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + "messages/add-participants", reqObj, {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: ADD_USER_TO_CONVERSATION,
        payload: true,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const getMessageTypes = () => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
      .get(URL + `messages/types`, {
        headers: {},
      })
      .then((res) => {
        dispatch({
          type: MESSAGE_TYPES,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err, dispatch));
};

export const createConversation = (conversation) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .post(URL + "conversations", conversation, {
      headers: {},
    })
    .then((res) => {
      dispatch({
        type: CREATE_NEW_CONVERSATION,
        payload: res.data,
      });
    })
    .catch((err) => disPatchError(err, dispatch));
};

export const deleteMessages = (ids, task) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });
  axios
    .delete(URL + `conversations`, {
      headers: {'Content-Type': 'application/json'},
      data: ids
    }).then((res) => {
      dispatch({
        type: DELETE_MESSAGES,
        payload: ids
      });
      if(task){
        task();
      }
    }).catch(err => disPatchError(err, dispatch));
}

export const sendReply = (files, reply, urgent) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });

  dispatch({ type: SEND_REPLY });
};

export const createFileForEmail = (file) => (dispatch) => {
  dispatch({ type: "TOGGLE_LOAD" });
  dispatch({ type: RESET_ERRORS });

  dispatch({ type: SEND_REPLY });
};



export const resetReplyBoolean = (file) => (dispatch) => {
  dispatch({ type: "RESET_BOOLEAN_REPLY" });
};
