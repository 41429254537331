export const initialState = {
    showAddAppointment: false,
    selectedDate: null,
    options: [],
    addressList: [],
    isEdit: false,
    showCancelAppointment: false,
    isCancelingAppointment: false,
    appointments: [],
    totalSize: 0,
};

export const actions = {
    TOGGLE_SHOW_ADD_APPOINTMENT: 'TOGGLE_SHOW_ADD_APPOINTMENT',
    SET_SELECTED_DATE: 'SET_SELECTED_DATE',
    SET_OPTIONS: 'SET_OPTIONS',
    SET_ADDRESS_LIST: 'SET_ADDRESS_LIST',
    TOGGLE_IS_EDIT: 'TOGGLE_IS_EDIT',
    TOGGLE_SHOW_CANCEL_APPOINTMENT: 'TOGGLE_SHOW_CANCEL_APPOINTMENT',
    TOGGLE_IS_CANCELING_APPOINTMENT: 'TOGGLE_IS_CANCELING_APPOINTMENT',
    SET_APPOINTMENTS: 'SET_APPOINTMENTS',
    SET_TOTAL_SIZE: 'SET_TOTAL_SIZE'
};