import React, { useEffect, Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import Row from 'react-bootstrap/Row'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "../../components/buttons";
import axios from "../../utils/apiClient";
import Toast from "react-bootstrap/Toast";

const componentName = "MessageSignature";
const MESSAGE_SIGNATURE_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/account-settings/user-profile/message-signature";

const initial = {
  signature: false,
  signatureText: ""
}

const MessageSignature = () => {
  const [showToast, setShowToast] = useState(false);
  const [messageSignature, setMessageSignature] = useState(initial);

  const onChangeField = (e) => {
    let value = e.currentTarget.value;
    if(e.currentTarget.name === "signature"){
      value = (value === "true");
    }

    const newState = {...messageSignature, [e.currentTarget.name] : value};
    setMessageSignature(newState);
  };

  const submit = () => {
    let dataRequest = messageSignature.signature ? messageSignature.signatureText : "";

    axios.post(MESSAGE_SIGNATURE_URL + "?text=" + dataRequest, null, {
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        setShowToast(true);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    axios.get(MESSAGE_SIGNATURE_URL, {
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        if(response.data != null && response.data.length > 0){
          const newState = {...messageSignature, signature: true, signatureText: response.data};
          setMessageSignature(newState);
        } else {
          const newState = {...messageSignature, signature: false, signatureText: ""};
          setMessageSignature(newState);
        }
      })
      .catch((error) => {
      });
  }, []);

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="w-100">
                <div className="mb-3">
                  <Form.Check id={`${componentName}-noSignature`} name={"signature"} type={"radio"} value={false} checked={messageSignature.signature === false} onChange={onChangeField} label="No signature"/>
                  <Form.Check id={`${componentName}-specificSignature`} name={"signature"} type={"radio"} value={true} checked={messageSignature.signature === true} onChange={onChangeField} label=""/>
                  <Form.Control id={`${componentName}-specificSignatureValue`} name={"signatureText"} as="textarea" rows={3} value={messageSignature.signatureText} onChange={onChangeField}/>
                </div>
              </Form.Group>
            </Col>            
          </Row>
          <hr/>
          <Row>
            <Col sm={2}>
              <Button id={`${componentName}-saveButton`} variant="primary" onClick={submit}>Save</Button>
            </Col>
            <Col sm={10}>
              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <i className="fas fa-check-square text-success"></i>
                  <strong className="me-auto">
                    Personal information updated
                  </strong>
                </Toast.Header>
              </Toast>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default MessageSignature;