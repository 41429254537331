import styled from 'styled-components';

/**
 * Maps component view sections of a patient's details view to their respective fields.
 */
const patientDetailsFieldsMapping = {
  "PersonalInformation": [
    "active",
    "primaryPhone",
    "detailsPhones",
    "address",
    "secondaryAddress",
    "email",
    "detailsEmails",
    "dateOfBirth",
    "gender",
    "primaryLanguage",
    "detailsOtherLanguages"
  ],
  "InsuranceInformation": [
    "payer",
    "secondPayer",
    "memberId",
    "membershipActive",
    "termDate",
    "originatingOrganization",
    "lineOfBusiness",
    "valueBasedCare"
  ],
  "CareTeamInformation": [
    "pcp",
    "caregiverFirstName",
    "caregiverLastName",
    "caregiverContact",
    "careCoordinator",
    "nurse",
    "healthPlanCareManager",
    "specialists"
  ],
  "InternalPatientNotes": [
    "detailsNotes"
  ]
}

/**
 * Cleans a simple text field by performing necessary checks and transformations.
 * 
 * @param {any} value - The value to be cleaned.
 * @returns {any} - The cleaned value.
 */
const cleanSimpleTextField = (value) => value || '';

/**
 * Cleans a simple boolean field by performing necessary checks and transformations.
 * 
 * @param {any} value - The value to be cleaned.
 * @returns {any} - The cleaned value.
 */
const cleanSimpleBooleanField = (value) => value || false;

/**
 * Cleans an object field by performing necessary checks and transformations.
 * Returns null if the input is not an object or is null, otherwise it returns a new object
 * with only the specified fields, cleaned as necessary.
 * 
 * @param {Object} object - The object to be cleaned.
 * @param {string[]} fields - The text fields within the object to clean.
 * @param {boolean[]} fields - The boolean fields within the object to clean.
 * @returns {Object} - The cleaned object.
 */
const cleanObjectField = (object, textFields, booleanFields = []) => {
  if (typeof object !== 'object' || object === null) return null;
  const cleanedObject = {};
  textFields.forEach((field) => {
    cleanedObject[field] = cleanSimpleTextField(object[field]);
  });
  booleanFields.forEach((field) => {
    cleanedObject[field] = cleanSimpleBooleanField(object[field]);
  });

  return cleanedObject;
};

/**
 * Cleans an array of objects by performing necessary checks and transformations.
 * Returns an empty array if the input is not an array, otherwise it returns a new array
 * with only the specified object fields, cleaned as necessary.
 * 
 * @param {Object[]} array - The array of objects to be cleaned.
 * @param {string[]} textObjectFields - The text fields within each object to clean.
 * @param {string[]} booleanObjectFields - The boolean fields within each object to clean.
 * @param {boolean[]} fields - The text fields within the object to clean.
 * @returns {Object[]} - The array of cleaned objects.
 */
const cleanArrayObjectField = (array, textObjectFields, booleanObjectFields = []) => {
  if (!Array.isArray(array)) return [];
  return array.map(item => cleanObjectField(item, textObjectFields, booleanObjectFields));
};

/**
 * Cleans patient information specific to a component view.
 * 
 * Processes and cleans patient information based on the specified component's requirements.
 * This function dynamically selects fields to clean from the patient information object
 * based on a predefined mapping for each component. It handles different types of fields
 * including simple fields, objects, and arrays of objects. If a field mapping for the 
 * given component name is not found, it logs an error and returns an empty object.
 * 
 * @param {string} componentName - The name of the component the data is intended for.
 * @param {Object} patientInfo - The patient information object to be cleaned.
 * @returns {Object} - The cleaned patient information.
 */
export const cleanPatientInfo = (componentName, patientInfo) => {
  const fieldsToClean = patientDetailsFieldsMapping[componentName];
  if (!fieldsToClean) {
    console.error(`No field mapping found for component: ${componentName}`);
    return {};
  }

  const cleanedInfo = {};
  fieldsToClean.forEach((field) => {
    const info = patientInfo[field];

    let tempField;

    switch (field) {
      case "secondaryAddress":
        tempField = cleanObjectField(info, ["streetAddress", "city", "state", "zipCode"]);
        break;
      case "pcp":
      case "nurse":
      case "healthPlanCareManager":
      case "careCoordinator":
        tempField = cleanObjectField(info, ["phone", "name"]);
        break;
      case "detailsNotes":
        tempField = cleanArrayObjectField(info, ["author", "time", "description", "text"]);
        break;
      case "specialists":
        tempField = cleanArrayObjectField(info, ["phone", "name"]);
        break;
      case "detailsPhones":
      case "detailsEmails":
      case "detailsOtherLanguages":
        tempField = cleanArrayObjectField(info, ["detail"], ["preferred"]);
        break;
      default:
        tempField = cleanSimpleTextField(info);
    }

    cleanedInfo[field] = tempField;
  });

  return cleanedInfo;
};

/**
 * Determines the style to apply to a patient status label based on the status value.
 * 
 * @param {string} status - The current status of the patient.
 * @returns {string} - The class name representing the style.
 */
export const getPatientStatusStyle = (status) => {
  const patientStatus = new Map([
    ["GREEN", { color: "#3DCA37", value: "Active" , "backgroundColor": "#EEF5EC"}],
    ["GRAY", { color: "#64686B", value: "Inactive" , "backgroundColor": "#D5DCE3"}]
  ]);

  return status ? patientStatus.get("GREEN"): patientStatus.get("GRAY");
}

/**
 * Internal patient notes message information.
 */
export const INT_PATIENT_NOTES_INFO_MSG = "This information is not included in the patient's record and can be seen only by internal Equality Health employees."

/**
 * Checks if the input is an array and if is not empty.
 * 
 * @param {Array} array - The array to check.
 * @returns {boolean} - Returns true if the array is not empty, false otherwise.
 */
export const isArrayNonEmpty = (array) => Array.isArray(array) && array.length > 0;

/**
 * Converts an ISO 8601 timestamp to a formatted string representation.
 * The function uses the 'en-US' locale to format the date into a readable
 * form such as "Month Day, Year Hour:Minute AM/PM".
 * 
 * @param {string} timestamp - The ISO 8601 timestamp string to convert.
 * @returns {string} The formatted date and time string.
 */
export const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);

  return formatter.format(date).replace(" at ", " ");
};

/**
 * Formats an address object into a string with special formatting rules.
 * Concatenates address fields, separating them by a comma only when both street address and city are present.
 *
 * @param {Object} address - Object containing address fields.
 * @returns {string} Formatted address string.
 */
export const formatAddress = (address) => {
  if (!address) return "";

  const { streetAddress, city, state, zipCode } = address;

  const leftAddressFields = [
    streetAddress ? streetAddress : "",
    city ? city : "",
  ].filter(field => field.length > 0).join(" ");

  const rightAddressFields = [
    state ? state : "",
    zipCode ? zipCode : ""
  ].filter(field => field.length > 0).join(" ");

  const formattedAddress = leftAddressFields && rightAddressFields
    ? `${leftAddressFields}, ${rightAddressFields}`
    : `${leftAddressFields}${rightAddressFields}`;

  return formattedAddress;
};

/**
 * Parses a formatted address string into its individual components.
 * Splits the address into left and right parts based on the comma separator.
 *
 * @param {string} formattedAddress - The address string to be parsed.
 * @returns {Object} An object containing the streetAddress, city, state, and zipCode, or null if the input is invalid.
 */
export const parseAddress = (formattedAddress) => {
  if (!formattedAddress) return "";

  const [leftPart, rightPart] = formattedAddress.split(',').map(part => part.trim());

  const leftParts = leftPart.split(' ');
  const streetAddress = leftParts.slice(0, -1).join(' ');
  const city = leftParts.slice(-1).join(' ');

  const [state, zipCode] = rightPart ? rightPart.split(' ').map(part => part.trim()) : ["", ""];

  return { streetAddress, city, state, zipCode };
}

/**
 * Maps key identifiers from RelationType to Care Provider type
 */
export const careProviderRelationTypeMapping = {
  'PCP': 'Primary Care',
  'CAREGIVER': 'Caregiver',
  'CARE_COORDINATOR': 'Care Coordinator',
  'NURSE': 'Nurse',
  'HEALTH_PLAN_CARE_MANAGER': 'Health Plan Manager',
  'SPECIALIST': 'Specialist'
}

/**
 * Maps key identifiers from Care Provider type to RelationType
 */
export const careProviderTypeMapping = {
  'Primary Care': 'PCP',
  'Caregiver': 'CAREGIVER',
  'Care Coordinator': 'CARE_COORDINATOR',
  'Nurse': 'NURSE',
  'Health Plan Manager': 'HEALTH_PLAN_CARE_MANAGER',
  'Specialist': 'SPECIALIST',
  'Other Specialist': 'SPECIALIST'
};

/**
 * Maximum amount of characters permitted for internal note subject
 */
export const MAX_LENGTH_NOTE_SUBJECT = 100;

/**
 * Maximum amount of characters permitted for internal note detail
 */
export const MAX_LENGTH_NOTE_DETAIL = 1000;

/**
 * Internal note patient type
 */
export const INTERNAL_NOTE_TYPE = "INTERNAL_NOTES";

/**
 * PD field free input prefixes.
 */
export const PREFER_TO_SELF_DESCRIBE_PRONOUN_TEXT = "Prefer to self-describe";
export const OTHER_PLEASE_SPECIFY_RACE_ETHNICITY_TEXT = "Other, please specify";

/**
 * Extracts field information from a given value, handling cases where the input may include free text descriptions.
 * It checks if the value starts with a specified prefix indicating the need for special handling (free text input).
 *
 * It splits the value at the colon, treating the first part as the predefined field and the second part as the free text input.
 * If there's no colon, the assumption is that the entire value should be treated as belonging to the specified field.
 *
 * @param {string} value - The input string to be analyzed, potentially containing free text input.
 * @param {string} prefix - The prefix used to indicate that the input might include free text beyond a simple identifier.
 * @returns {Object} An object containing the field identified, a boolean flag indicating if free text input is present,
 * and the free text input itself (if any).
 */
export const PDExtractFreeInputFieldInfo = (value, prefix) => {
  let field = value;
  let isFreeInput = false;
  let freeInputTextValue = "";

  if (value && prefix && value.startsWith(prefix)) {
    isFreeInput = true;
    if (value.includes(":")) {
      [field, freeInputTextValue] = value.split(":").map(part => part.trim());
    } else {
      field = prefix;
    }
  }

  return { field, isFreeInput, freeInputTextValue };
};

/**
 * Custom styled component for DropDownDiv. 
 * It applies a CSS rule to any element with an Id starting with 'GenericDDL-' and ending with '-filter',
 * setting its margin-bottom to 0 with importance, ensuring no margin is applied at the bottom of these elements.
 */
export const DropDownDiv = styled.div`
  [id^='GenericDDL-'][id$='-filter'] { margin-bottom: 0 !important; }
`;

/**
 * Joins multiple provided strings into a single string, separated by newline characters, excluding any false values.
 *
 * @param {...string} strings - An arbitrary number of string arguments to be concatenated.
 * @returns {string} A string that represents the concatenated values with newline characters as separators.
 */
export const joinWithNewLines = (...strings) => {
  return strings.filter(Boolean).join("\n");
}