import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { SESSION_HAS_EXPIRED, useAuth } from "../../context/authContext";
import { useLoginInfoContext } from "./context/LoginInfoContext";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../../utils/useLocalOrSessionStorage";

const componentName = "DefaultLogin";

const TermsOfUsePdf = process.env.REACT_APP_REST_API_BASE_URL + "/system_files/Terms_of_Use.pdf";
const PrivacyPolicyPdf = "/docs/Privacy_Policy.pdf";
const UserAccessAgreement = "/docs/CareEmpower_User_Access_Agreement.pdf";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const sessionHasExpired = sessionStorage.getItem(SESSION_HAS_EXPIRED);
    const [errorToDisplay, setErrorToDisplay] = useState("");
    const isEmpty = require("is-empty");
    const auth = useAuth();
    const isAuthenticated = auth.isAuthenticated()
    const loginInfo = useLoginInfoContext();
   
   
   const  onSubmitHandler=(e) => {
        const clickedFrom = document.activeElement.name
        if(clickedFrom === 'logIn'){
            sessionStorage.removeItem(SESSION_HAS_EXPIRED);
            e.preventDefault();
            loginInfo.onLogin();
        }
        else{
            sessionStorage.removeItem(SESSION_HAS_EXPIRED);
            e.preventDefault();
            loginInfo.onRedirect();
        } 
    }
    const fromCBO = document.referrer && !isEmpty(document.referrer) && 
    ((document.referrer.indexOf("uniteus") !== -1 && document.referrer.indexOf("uniteUS/logout") === -1) ||
    (document.referrer.indexOf("findhelp") !== -1 && document.referrer.indexOf("findhelp/logout") === -1))

    useEffect(() => {
        const error = loginInfo.error;

        if (!isEmpty(error)) {
            let errorMessage;
            if (error && typeof error == "string") {
                errorMessage = error;
            } else if (error && error.response && error.response.data) {
                if (error.response.data.error_description) {
                    errorMessage = error.response.data.error_description;
                } else if (error.response.data.message) {
                    errorMessage = error.response.data.error_description;
                }
            } else if (error && error.message && !errorMessage) {
                errorMessage = error.message;
            }
            setErrorToDisplay(errorMessage);
        } else {
            setErrorToDisplay("");
        }
    }, [isEmpty, loginInfo.error]);

    return (
        <Form id={`${componentName}-loginForm`}
            onSubmit={onSubmitHandler}
        >
            <h4 id={`${componentName}-signin`} className="text-center">Sign In</h4>
            {sessionHasExpired && (
                <Alert id={`${componentName}-inactivity`} variant="warning">You were logged out due to inactivity</Alert>
            )}
            {errorToDisplay && <Alert id={`${componentName}-loginError`} variant="danger">{errorToDisplay}</Alert>}
            <div>
                <div className="mb-3" id={`${componentName}-loginUsername`}>
                    <label id={`${componentName}-usernameLabel`}>Username</label>
                    <Form.Control
                        type="text"
                        id={`${componentName}-usernameValue`}
                        autoComplete="username"
                        required
                        placeholder="Username"
                        value={loginInfo.username}
                        onChange={e => loginInfo.setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-3" id={`${componentName}-loginPassword`}>
                    <label id={`${componentName}-passwordLabel`}>Password</label>
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            id={`${componentName}-passwordValue`}
                            autoComplete="current-password"
                            required
                            placeholder="Password"
                            value={loginInfo.password}
                            onChange={e => loginInfo.setPassword(e.target.value)}
                        />
                        <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                            <span className="input-group-addon px-2">
                                {!showPassword
                                    ? <i className="fa-solid fa-eye"></i>
                                    : <i className="fa-solid fa-eye-slash"></i>
                                }
                            </span>
                        </InputGroup.Text>
                    </InputGroup>
                </div>
            </div>
            <div id={`${componentName}-termsText`} className="fw-bold mb-5">
                <p>
                    By using CareEmpower, I agree to the <a href={TermsOfUsePdf} target="blank">Terms of Use</a>, <a href={PrivacyPolicyPdf} target="blank">Privacy Policy</a>,
                    and <a href={UserAccessAgreement} target="blank">User Access Agreement</a>
                </p>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end">
                <Link id={`${componentName}-forgotPassword`} className="btn btn-secondary mb-3 mb-sm-0 me-0 me-sm-2" to="/recover-password">
                    Forgot password?
                </Link>
                {fromCBO ? <Button id={`${componentName}-loginButtonRedirect`} type="submit" name="redirect">Log In</Button> :
                    <Button id={`${componentName}-loginButton`} type="submit" name='logIn'>Log In</Button>}
            </div>
        </Form>
    );
};

export default Login