import axios from "../../../../utils/apiClient";

export const getEventsTimeline = (patientId, {filters = [], sorts = [], pageNumber = 1, pageSize = 20}) => {
  const criteria = {
    "filterParams": filters.map(e => e.paramName === 'ADT Disposition' ? {...e, paramName: 'eventType'} : e),
    "sortParams": sorts,
    "pageNumber": pageNumber,
    "pageSize": pageSize
  }

  return axios
  .post(process.env.REACT_APP_REST_API_BASE_URL
    + `/v1/patient-profile/events-timeline?patientId=${patientId}`,
    criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}