import React, { useEffect } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { SortParam } from "../../../../dtos/PatientSearchDTO";

const componentName = "SortMenu"

const SortMenu = ({
  items,
  filterParams = [],
  setSortParams,
  refreshPatientList,
  snoozedFilterParam = []
}) => {
  const onSortItemClick = (menuItemSortClicked) => {
    onPatientActionSortClicked(menuItemSortClicked.text);
  }

  const onPatientActionSortClicked = (menuItemSortClicked) => {
    let sortParams = [];

    switch (menuItemSortClicked) {
      case "Name (A-Z)":
        setSortParams(sortParams = [new SortParam("fullName", "ASC", 1)]);
        break;
      case "Name (Z-A)":
        setSortParams(sortParams = [new SortParam("fullName", "DESC", 1)]);
        break;
      case "Assigned To (A-Z)":
        setSortParams(sortParams = [new SortParam("assignedTo", "ASC", 1)]);
        break;
      case "Assigned To (Z-A)":
        setSortParams(sortParams = [new SortParam("assignedTo", "DESC", 1)]);
        break;
      case "Due Date (Most Recent)":
        setSortParams(sortParams = [new SortParam("stepDueDate", "DESC", 1)]);
        break;
      case "Due Date (Oldest)":
        setSortParams(sortParams = [new SortParam("stepDueDate", "ASC", 1)]);
        break;
      case "Risk Level (Lowest First)":
        setSortParams(sortParams = [new SortParam("riskLevel", "ASC", 1)]);
        break;
      case "Risk Level (Highest First)":
        setSortParams(sortParams = [new SortParam("riskLevel", "DESC", 1)]);
        break;
      case "Event Type (A-Z)":
        setSortParams(sortParams = [new SortParam("eventOrUrgencyType", "ASC", 1)]);
        break;
      case "Event Type (Z-A)":
        setSortParams(sortParams = [new SortParam("eventOrUrgencyType", "DESC", 1)]);
        break;
      case "Next Upcoming Visit Date (Most Recent)":
        setSortParams(sortParams = [new SortParam("nextUpcomingVisitDate", "DESC", 1)]);
        break;
      case "Next Upcoming Visit Date (Oldest)":
        setSortParams(sortParams = [new SortParam("nextUpcomingVisitDate", "ASC", 1)]);
        break;
      case "Days Care Path Assigned (Most Recent)":
        setSortParams(sortParams = [new SortParam("carePathLastAssignedAt", "DESC", 1)]);
        break;
      case "Days Care Path Assigned (Oldest)":
        setSortParams(sortParams = [new SortParam("carePathLastAssignedAt", "ASC", 1)]);
        break;
      case "Days Care Path Enrolled (Most Recent)":
        setSortParams(sortParams = [new SortParam("daysCarePathEnrolled", "DESC", 1)]);
        break;
      case "Days Care Path Enrolled (Oldest)":
        setSortParams(sortParams = [new SortParam("daysCarePathEnrolled", "ASC", 1)]);
        break;
      case "Event Date (Most Recent)":
        setSortParams(sortParams = [new SortParam("eventDate", "DESC", 1)]);
        break;
      case "Event Date (Oldest)":
        setSortParams(sortParams = [new SortParam("eventDate", "ASC", 1)]);
        break;
      default:
        setSortParams(sortParams = []);
    }
    refreshPatientList(1, [...filterParams.filter(f => f.paramName !== 'stepState'), ...snoozedFilterParam], sortParams);
  }

  return (
    <Dropdown id={`${componentName}-dropDown`} key={`${componentName}-dropDown-key`} className="me-2">
      <Dropdown.Toggle
        variant="light"
        id={`${componentName}-memberAction`}
        className="border"
      >
        <i className="fa fa-arrow-up-arrow-down me-2" /> Sort
      </Dropdown.Toggle>
      <Dropdown.Menu id={`${componentName}-dropDownMenu`} key={`${componentName}-dropDownMenu-key`}>
        {items && items.map((item, itemIdx) => {
          return (
            <Dropdown.Item
              id={`${componentName}-item-${itemIdx}`}
              key={`${componentName}-${item.value}-${itemIdx}`}
              onClick={() => onSortItemClick(item)}
            >{item.text}</Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SortMenu;