export function getGetters(state) {
    return {
        patientProfileInformation: state.patientProfileInformation,
        isEditPatientDetailsEnabled: state.isEditPatientDetailsEnabled,
        isAddInternalNoteEnabled: state.isAddInternalNoteEnabled,
        languages: state.languages,
        spokenLanguages: state.spokenLanguages,
        readingLanguages: state.readingLanguages,
        states: state.states,
        payorStatus: state.payorStatus,
        specialistTypes: state.specialistTypes,
        pronounsDropList: state.pronounsDropList,
        sexAssignedBirthDropList: state.sexAssignedBirthDropList,
        raceEthnicityDropList: state.raceEthnicityDropList,
        sexualOrientationList: state.sexualOrientationList,
        genderIdentityList: state.genderIdentityList
    }
}