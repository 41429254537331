import { useAuth } from '../../../../context/authContext';
import axios from '../../../../utils/apiClient'

export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/configuration/care-management-configuration";
export const URL_ZONE = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/configuration/zone";
export const URL_ZIP_CODE = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/configuration/zip-code";
export const MANAGERS_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/care-management/managers";
export const REFERRAL_TYPE_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/patient-filter/referral-type";
export const STATES_TYPE_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/patient-filter/states";
export const Market_TYPE_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/patient-filter/market";
export const NOTE_CATEGORIES_TYPE_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/patients/notes-activity-types";
export const CARE_PATH_STATUS_URL = process.env.REACT_APP_REST_API_BASE_URL+ "/v1/patient-filter/care-path-statuses";


export const getProgramLeads = () => {
    return axios.get(MANAGERS_URL, {
        params: {
            pageNumber: 1,
            pageSize: 999
        },
        headers: {"Content-Type": "application/json"},
    });
}

export const fetchDropDown = (dropListName, pageNumber) => {
    if (dropListName === "ZIP_CODE") {
        return axios
            .get(URL_ZIP_CODE, {
                params:{
                    pageNumber,
                    pageSize: 20
                },
                headers: {"Content-Type": "application/json"},
            });
    }
    return axios
        .get(URL, {
            params:{
                dropListName,
                pageNumber,
                pageSize: 20,
                paging: true
            },
            headers: {"Content-Type": "application/json"},
        });
}

export const fetchDropDownFiltered = (dropListName, status = "") => {
    return axios
        .get(URL + '-filter', {
            params:{dropListName, status},
            headers: {"Content-Type": "application/json"}
        });
}

export const fetchDropDownZones = (status) => {
    return axios
        .get(URL_ZONE, {
            params:{ status },
            headers: {"Content-Type": "application/json"},
        });
}

export const addItem = (module, dropListName, item) => {
    Object.keys(item).forEach(key => {
        if (item[key] === null) {
          delete item[key];
        }
    });
    const {value, description, status, stepStatus} = item;

    const json = {
        "module": module,
        "dropListName": dropListName,
        "value": value || description,
        status,
        stepStatus
    }
    let url = URL;
    if (dropListName === 'ZIP_CODE') {
        json['description'] = description || ''
        url = URL_ZIP_CODE;
    }
    return axios
        .post(url, json, {
            headers: {"Content-Type": "application/json"},
        });
}

export const editItem = (item) => {
    Object.keys(item).forEach(key => {
        if (item[key] === null) {
          delete item[key];
        }
    });

    const {id, status, description, value, stepStatus, dropListName} = item
    const json = {
        "configurationId": id,
        "value": value || description,
        "stepStatus": stepStatus?.toUpperCase(),
        status,
        "dropListName": dropListName
    }
    let url = URL;
    if (dropListName === 'ZIP_CODE') {
        json['zipCodeId'] = id
        json['description'] = description || ''
        url = URL_ZIP_CODE;
    }
    return axios
        .put(url, json, {
            headers: {"Content-Type": "application/json"},
        });
}

export const fetchReferralTypes = () => {
    return axios
        .get(REFERRAL_TYPE_URL, {
            headers: {"Content-Type": "application/json"},
        });
}

export const fetchStates = () => {
    return axios
        .get(STATES_TYPE_URL, {
            headers: {"Content-Type": "application/json"},
        });
}

export const findMarket = () => {
  
    return axios
    .get(Market_TYPE_URL, {
        headers: {"Content-Type": "application/json"},
    });
}

export const findNoteCategories = () => {

    return axios
    .get(NOTE_CATEGORIES_TYPE_URL, {
        headers: {"Content-Type": "application/json"},
    });
}

export const fetchCarePathStatuses = () => {
    return axios
        .get(CARE_PATH_STATUS_URL, {
            headers: {"Content-Type": "application/json"},
        });
}