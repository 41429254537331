import {actions} from "./referralsActions";

export function getToggles(dispatch) {
    return {
        toggleLoadingPractices: payload => {
            dispatch({type: actions.TOGGLE_LOADING_PRACTICES, payload})
        },
        toggleLoadingAssociatedProviders: payload => {
            dispatch({type: actions.TOGGLE_LOADING_ASSOCIATED_PROVIDERS, payload})
        },
    }
}