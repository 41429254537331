import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Filter } from "../../../components/filters/filterGroup";
import { DDLDataMode, DDLMode, GENERIC_DDL_TYPE } from "../../../components/dropDownLists/genericDDL";
import { FilterParam } from "../../../dtos/PatientSearchDTO";
import { clientToStateMap, stateFullName } from "../../../utils/textValueLists";

const componentName = "UserModalForm";
const StyledSpan = styled.span`
    color: #ff0000;
`;
const ModalLabel = styled(Form.Label)`
  font-weight: 600;
`;
const RequiredModalLabel = styled(Form.Label)`
  font-weight: 600;
  :after {
    content: "  *";
    color: RED;
    font-size: 20px;
  }
`;
const FormGroup = ({ hidden, required, pattern, name, type, value, onChange, className, label }) => {
  const [error, setError] = useState()

  const handleChange = (e) => {
    let hasError
    if (required && !value) {
      setError(`${name.toUpperCase()} is required`);
      hasError = `${name.toUpperCase()} is required`
    }
    if (pattern) {
      if (type === "text" && value && value.match(pattern)) {
        setError(`Invalid entry in  field ${name.toUpperCase()}`);
        hasError = `Invalid entry in  field ${name.toUpperCase()}`
      }
      else if (type === "email" && value && !value.match(pattern)) {
        setError(`Invalid entry in  field ${name.toUpperCase()}`);
        hasError = `Invalid entry in  field ${name.toUpperCase()}`
      }
      else {
        setError('');
        hasError = ''
      }
    }
    e.error = hasError
    onChange(e)
  }

  useEffect(() => {
    const e = { "target": { "name": name, "value": value } }
    e.error = error
    handleChange(e)
  }, [value]);

  return (
    <Form.Group hidden={hidden}>
      <RequiredModalLabel>
        <span id={`${componentName}-${name}Label`}>{label}</span>
      </RequiredModalLabel>
      <Form.Control
        type={type}
        className={className}
        hidden={hidden}
        name={name}
        id={`${componentName}-${name}Value`}
        required={required}
        value={value}
        error={error}
        onChange={(e) => handleChange(e)}
      />
      <StyledSpan>{error}</StyledSpan>
    </Form.Group>
  )
}

export const UserModalForm = ({
  type,
  handleChange,
  user,
  roles,
  organizations,
  accessTypes,
  clients,
  error
}) => {
  const isEmpty = require("is-empty");
  const errors = useSelector((state) => state.errors);
  const { loading } = useSelector((state) => state.userManagement);
  const formInputsStateRef = useRef({})

  const [accesses, setAccesses] = useState([
    {
      accessIdReference: "c26a09c5-1b79-49f2-aa6f-cb2609a898c9",
      accessName: "ABC Group",
      accessId: "ABC Group",
    },
    {
      accessIdReference: "a9d660ae-bfd0-4856-bbad-525a1b8de2d8",
      accessName: "Network",
      accessId: "Network",
    },
    {
      accessIdReference: "69d2bc0b-86ef-432b-8c7e-905378cfed9b",
      accessName: "Group",
      accessId: "1234ABCD0Z",
    },
    {
      accessIdReference: "974e55fe-8bd1-437a-96cc-ea6d693de43b",
      accessName: "HP",
      accessId: "HP",
    },
  ]);

  let getSelectedOrganization = (user, organizations) => {
    if (user.organization != null) {
      var selected = null;
      for (var i = 0; i < organizations.length; i++) {
        if (organizations[i].id == user.organization.id) {
          selected = organizations[i];
          break;
        }
      }

      if (selected != null) {
        var out = [];
        out.push(selected);
        return out;
      }
    }

    return [];
  };

  const [filterValues, setFilterValues] = useState({
    paramValue: user && user.client && user.client.length ? user.client:[],
    displayValue: user && user.client && user.client.length ? user.client.map(e=>clientToStateMap[e]):[],
  });

  const handleChangeWithinModal = (e, error) => {
    handleChange(e, error)
  }


  const handleChangeWithFilter = (e, gapId, abb) => {
    setFilterValues(e);
    handleChange(e, error)
  }

  return (
    <>
      {loading && isEmpty(errors) && <ProgressBar animated now={95} />}
      <Row>

        <div className="col-6">
          <FormGroup label={"FIRST NAME"} hidden={type === "BULK UPDATE USER"}
            type="text"
            className={"form-control"}
            name="firstName"
            pattern={/[^0-9a-z]/i}
            value={user.firstName}
            required={true}
            onChange={(e) => handleChangeWithinModal(e)}
            error={formInputsStateRef}
          />
          <FormGroup label={"LAST NAME"} hidden={type === "BULK UPDATE USER"}
            type="text"
            className={"form-control"}
            name="lastName"
            pattern={/[^0-9a-z]/i}
            value={user.lastName}
            required={true}
            onChange={(e) => handleChangeWithinModal(e)}
            error={formInputsStateRef}

          />
          <FormGroup label={"EMAIL (USER ID)"} hidden={type === "BULK UPDATE USER"}
            type="email"
            className="form-control"
            disabled={type === "UPDATE USER"}
            name="email"
            pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
            value={user.email}
            required={true}
            onChange={(e) => handleChangeWithinModal(e, error)}
            error={formInputsStateRef}
          />
          <Filter
            handleChange={(e) => handleChangeWithFilter(e)}
            selected={filterValues}
            filterDefinition={{
              type: GENERIC_DDL_TYPE,
              getHeader: () => "CLIENTS",
              key: "Clients",
              componentProps: {
                mode: DDLMode.MultipleSelect,
                dataMode: DDLDataMode.OneAPICallFilterInUI,
                showSearchTextbox: false,
                highlightWhenHasValue: false,
                showClearButton: true,
                showRequired: true,
                getData: (srchTxt) =>
                  Promise.resolve(clients),
              },
            }}
          />
          <Form.Group>
            <RequiredModalLabel>
              <span id={`${componentName}-organizationLabel`}>ORGANIZATION</span>
            </RequiredModalLabel>
            <Typeahead
              id={`${componentName}-organizationValue`}
              inputProps={{
                id: `${componentName}-organizationValue`
              }}
              labelKey="name"
              multiple={false}
              defaultSelected={getSelectedOrganization(user, organizations)}
              clearButton={true}
              positionFixed={true}
              flip={true}
              onChange={(e) => {
                e.target = {};
                e.target.name = "organization";
                e.target.value = e[0];

                handleChangeWithinModal(e);
              }}
              options={organizations}
              placeholder="Choose an organization"
            />
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group>
            <RequiredModalLabel>
              <span id={`${componentName}-roleLabel`}>ROLE</span>
            </RequiredModalLabel>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={user.userRole ? user.userRole : ""}
              name="userRole"
              id={`${componentName}-roleValue`}
              // defaultValue={user.roles.length ? user.roles[0].roleName : user.roles[0].role}
              onChange={(e) => handleChangeWithinModal(e)}
            >
              {user.userRole ? (
                <option value={user.userRole.name}>{user.userRole.name}</option>
              ) : (
                <option value="">--Select</option>
              )}
              {roles.map((role) =>
                user.userRole?.name != role.name ? (
                  <option
                    key={role.id}
                    value={JSON.stringify({ name: role.name, id: role.id })}
                  >
                    {role.name}
                  </option>
                ) : (
                  ""
                )
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <RequiredModalLabel>
              <span id={`${componentName}-accessTypeLabel`}>ACCESS TYPE</span>
            </RequiredModalLabel>
            <Form.Control
              as="select"
              className="form-control"
              defaultValue={user.accessType ? user.accessType : ""}
              name="accessType"
              id={`${componentName}-accessTypeValue`}
              onChange={(e) => handleChangeWithinModal(e)}
            >
              {user.accessType ? (
                <option value={user.accessType}>{user.accessType}</option>
              ) : (
                <option value="">--Select</option>
              )}
              {accessTypes.map((access) =>
                user.accessType != access ? (
                  <option key={access} value={access}>
                    {access}
                  </option>
                ) : (
                  ""
                )
              )}
            </Form.Control>
          </Form.Group>

          {user.accessType !== "NETWORK" && (
            <Form.Group>
              <RequiredModalLabel>
                <span id={`${componentName}-identifierLabel`}>IDENTIFIER</span>
              </RequiredModalLabel>
              <input
                type="text"
                className="form-control"
                name="identifier"
                id={`${componentName}-identifierLabel`}
                value={user.identifier}
                onChange={(e) => handleChangeWithinModal(e)}
              />
            </Form.Group>
          )}
          <Form.Group hidden={type === "BULK UPDATE USER"}>
            <ModalLabel>
              <span id={`${componentName}-providerNPILabel`}>PROVIDER NPI</span>
            </ModalLabel>
            <input
              type="text"
              className="form-control"
              disabled={type === "UPDATE USER"}
              hidden={type === "BULK UPDATE USER"}
              name="providerNpi"
              id={`${componentName}-providerNPIValue`}
              value={user.npi}
              onChange={(e) => handleChangeWithinModal(e)}
            />
          </Form.Group>
          {type === "UPDATE USER" && (
            <Form.Group>
              <ModalLabel>
                <span id={`${componentName}-statusLabel`}>STATUS</span>
              </ModalLabel>
              <Form.Control
                as="select"
                className="form-control"
                name="accountActive"
                id={`${componentName}-statusValue`}
                onChange={(e) => handleChangeWithinModal(e)}
              >
                <option value={user.accountActive}>
                  {user.accountActive ? "Active" : "Inactive"}
                </option>
                {user.accountActive ? (
                  <option value={false}>Inactive</option>
                ) : (
                  <option value={true}>Active</option>
                )}
              </Form.Control>
            </Form.Group>
          )}
        </div>
      </Row>
    </>
  );
};
