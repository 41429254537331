import React, { useEffect, useState } from "react";
import styled from "styled-components";

const componentName = "MultipleUploads";

const StyledUpload = styled.div`
  label {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
  }
  opacity: ${(props) => props.disabled ? "0.5" : "1.0"};
`;

const StyledLabel = styled.label`
::after {
  content:"x";
}
`;
const MultipleUploads = ({
  fileName,
  setFile,
  disabled,
  accept,
  multiple,
  delectFile,
  updateFiles,
  fileAdded,
  topComponent = ""
}) => {
  /*
  ****** USE THIS METHOD AS REFERENCE IN THE CLASS WHERE YOU IMPLEMENT THE COMPONENT
  DEPENDING IF YOU USE it as single upload or multi-upload
  const setFileMethod2 = async (filesList) => {
    if (filesList) {
      let fl = "";
      if (filesList.length && filesList.length > 1) {
        filesList.forEach((e) => {
          fl += e.name;
          fl += ", ";
          const fileReader = new FileReader();
          if (fileReader && e) {
            fileReader.readAsArrayBuffer(e);
            fileReader.onload = async function () {
              await files.push(fileReader.result);
              await setFileName(fileName + fl);
            };
          }
        })
      } else {
        fl += filesList.name;
        fl += ", ";
        const fileReader = new FileReader();
        if (fileReader) {
          fileReader.readAsArrayBuffer(filesList);
          fileReader.onload = async function () {
            await files.push(fileReader.result);
            await setFileName(fileName + fl);
          }
        }
      }
    }
  };
  ***/
 const [listFiles, setListFiles] = useState([])
 const isEmpty = require("is-empty");
 useEffect(() => {
   if(fileAdded && !isEmpty(fileAdded)){
    setListFiles([...listFiles, fileAdded])
   } else {
     setListFiles([]);
   }
}, [fileAdded]);

useEffect(() => {
   updateFiles(listFiles)
}, [listFiles]);

useEffect(() => {
   setListFiles([])
}, []);
  return (
    <StyledUpload disabled={disabled}>
      {topComponent}
      <label id={`${componentName}-UploadFiles`} className="btn btn-primary">
        <input type="file" disabled={disabled} multiple={multiple} onChange={(e) =>{
            setFile(
              e.currentTarget.files && e.currentTarget.files.length > 1
                ? e.currentTarget.files
                : e.currentTarget.files[0]
            );
          }

          }/>        
        <i className="fa fa-cloud-upload me-2"></i> Upload File(s)
      </label>
      <div className="d-block">
          {listFiles && listFiles.map((fl,index)=>
          <span id={`${componentName}-UploadFile-${index}`}>{fl.fileName || fl.name}<StyledLabel onClick={(e)=>{
            listFiles.splice(index,1)
            setListFiles([...listFiles])
            }
          }></StyledLabel></span>
          )}
      </div>
    </StyledUpload>
  );
};

export default MultipleUploads;
