import React from "react";
import {
  OverlayTrigger, Tooltip
} from "react-bootstrap";

const Mark = ({ text, customClassName }) => {
  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={<Tooltip className="white-tooltip">{text}</Tooltip>}>
        <span className={customClassName} />
      </OverlayTrigger>
    </>)
}

export default Mark
