import React, {useState} from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../utils/apiClient";

const PatientSearchAsync = ({ setPatient, setErrorMessage, setCurrentError }) => {
    const componentName = "PatientSearchAsync";
    const [isLoadingPatient, setIsLoadingPatient] = useState(false);
    const [optionsPatient, setOptionsPatients] = useState([]);
    const [patientList, setPatientList] = useState([])

    const asyncsearchPatient = (query) => {
        const timeout = setTimeout(() => {
          if (query) {
            handleSearchPatient(query);
          }
        }, 1600);
    
        return () => clearTimeout(timeout);
      };
    
      const handleSearchPatient = (query) => {
        if (query) {
          const SEARCH_TEXT_URL =
              process.env.REACT_APP_REST_API_BASE_URL +
              "/v1/global-search/findPatientInfo?searchString=" +
              query;
          setIsLoadingPatient(true);
          axios
              .get(SEARCH_TEXT_URL, {
                headers: {},
              })
              .then((resp) => {
                let options = [];
                if (resp && resp.data && resp.data.patients) {
                    setPatientList(resp?.data?.patients)
                    options = resp?.data?.patients?.map((i) => ({
                      value: i?.id,
                      text: i?.fullName,
                    }));
                }
                setOptionsPatients(options);
                setIsLoadingPatient(false);
              })
              .catch((error) => {
                setErrorMessage(error.response.data.message);
                setCurrentError(true);
                setIsLoadingPatient(false);
              });
        }
      };
      const handleChangePatient = (e) => {
        if (e && e[0]) {
            const patientFiltered = patientList.filter(patient => patient.id === e[0].value)
            setPatient(patientFiltered)
        }
      };

        return (
            <AsyncTypeahead
                inputProps={{
                    id: `${componentName}-PatientSearchAsync`
                }}
                labelKey="text"
                isLoading={isLoadingPatient}
                onSearch={asyncsearchPatient}
                options={optionsPatient}
                onChange={(e) => handleChangePatient(e)}
                placeholder="Search Patients by Name"
            />

        )
}

export default PatientSearchAsync;