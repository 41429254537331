import React, { useEffect, useState, useRef } from 'react'
import { Modal, Dropdown, Form, Button } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';
import axios from '../../../../../../utils/apiClient';
import CareStepStatus from '../../../../../quickList/components/careProgramsMock/components/careStepStatus';
import isEmpty from 'is-empty';
import { formatDateModern, formatDateVanilla, formatTimeMinimal } from '../../../../../../utils/util';
import { Col, Row } from 'react-bootstrap/esm';
import CarePathStepsKebab from '../../../../../quickList/components/careProgramsMock/components/carePathStepsKebab';
import { patientInformationSection, useGetCareManagements } from '../../../../../../actions/patientProfileActions';
import { useSelector, useDispatch } from 'react-redux';
import { FileUpload } from '../../../../../../components/FileUpload';
import { getFileDownload } from '../../../../../../actions/fileUploadActions';
import { setCareProgramModalOpen, setReloadWorklist } from '../../../../../../actions/manageVisitActions';
import CloseCareProgram from '../../../../../quickList/components/careProgramsMock/components/modals/closeCareProgram';
import { careManagementsReducer } from '../../../../../../reducers/patientProfile/careManagementsReducer';
import { useEQHToastContext } from '../../../../../../components/toast/EQHToast.context';
import { useAuth } from "../../../../../../context/authContext";
import Modals from "../../../../../../components/modals";

const componentName = 'WorkOnCareStep';

function WorkOnCareStep() {
  const {
    toggleWorkOnCareStep,
    workOnCareStep,
    patient,
    getPatientsList,
    toggleSnoozedModal,
    setstep,
    toggleCustomStatus
  } = useCMWorklistContext();
  const step = useSelector((state) => state?.manageVisitReducer?.step);
  const snoozedModal = useSelector((state) => state?.manageVisitReducer?.snoozedModal);
  const careManagementsPkg = useGetCareManagements([], careManagementsReducer);
  const dispatch = useDispatch();
  const { removeNotification, setNotification } = useEQHToastContext();

  const [notes, setnotes] = useState('');
  const [carePathSteps, setCarePathSteps] = useState([]);
  const [careProgram, setCareProgram] = useState({});
  const [index, setIndex] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedExpired, setSelectedExpired] = useState(false);
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(null);
  const [primaryCloseError, setPrimaryCloseError] = useState(false);
  const [selectedExpiredSource, setSelectedExpiredSource] = useState(null);
  const [pendingTransaction, setPendingTransaction] = useState(null);
  const [careProgramObject, setCareProgrammObject] = useState({
    show: false,
    careProgram: {
      id: patient?.carePathStepDto?.carePathInstanceId,
      patientProgramId: patient?.id,
    },
    action: '',
    title: ''
  });
  const [editingNoteIndex, setEditingNoteIndex] = useState(null);
  const [editingNoteValue, setEditingNoteValue] = useState("");
  const [disabledEditNoteIconIndex, setDisabledEditNoteIconIndex] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeletingAttachment, setIsDeletingAttachment] = useState(null);
  const [removedFilesIdsMap, setRemovedFilesIdsMap] = useState({});
  const noteRefs = useRef([]);
  const auth = useAuth();

  const ERROR_NOTIFICATION_TITLE = "Error";
  const CANNOT_SNOOZE_MESSAGE = ["This care step can't be snoozed as it's triggered from the Care Path Start Date"];

  useEffect(() => {
    if (workOnCareStep) {
      handleChangeCarePath();
      patientInformationSection(patient.id).then(res => {
        setCareProgram(res.data?.carePaths.filter(cp => cp.id === patient?.carePathStepDto?.carePathInstanceId)[0])
      });
    }
  }, [workOnCareStep])

  useEffect(() => {
    setCareProgrammObject ({
      show: false,
      careProgram: {
        id: patient?.carePathStepDto?.carePathInstanceId,
        patientProgramId: patient?.id,
      },
      action: '',
      title: ''
    });
  }, [patient])

  useEffect(() => {
    if (step?.files) {
      setFiles([...step?.files]);
    }
  }, [step])

  const handleClose = () => {
    setnotes("");
    setEditingNoteValue("");
    setEditingNoteIndex(null);
    setDisabledEditNoteIconIndex(null);
    toggleWorkOnCareStep(false);
  }

  const handleModal = () => {
    setSelectedReason(selectedReason);
    setSelectedExpired(false);
    setSelectedExpiredDate(null);
    setSelectedExpiredSource(null);
  };

  const openCloseProgram = (careProgram, action, title) => {
    dispatch(setCareProgramModalOpen(true));

    setCareProgrammObject({
      ...careProgramObject,
      show: true,
      careProgram: careProgram,
      action: action,
      title: title
    });
  };

  const handleRemovePath = () => {
    setCareProgrammObject({
      ...careProgramObject,
      careProgram: null
    })
    careManagementsPkg.fetchData(patient.id);
    toggleWorkOnCareStep(false)
  };

  const updateStepAction = (newObj, instance) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then((res) => {
        handleChangeCarePath()
      })
  };

  const editExistingStep = (step, stepObj, index) => {
    let newState = carePathSteps;
    let oldObj = newState[index];
    let newObj = {};
    let instance = step.carePathInstanceId;
    Object.keys(oldObj).forEach((key) =>
      key in stepObj
        ? (newObj[key] = stepObj[key])
        : (newObj[key] = oldObj[key])
    );
    newState[index] = newObj;
    setCarePathSteps([...newState]);
    updateStepAction(newState, instance);
    handleChangeCarePath()
  };

  const removeStepAction = (newObj, instance) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/set-care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
          instance
        )}`,
        newObj,
        { headers: {} }
      )
      .then(() => {
        toggleWorkOnCareStep(false)
        getPatientsList()
      })
  };

  const submitNewStep = (stepObj, carePathSteps) => {
    let carePathStepDto = stepObj;
    const newIndex = carePathSteps.findIndex(object => {
      return stepObj.stepNumber === object.stepNumber;
    });
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/add-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          patient?.carePathStepDto?.carePathInstanceId
        )}&carePathStepNumber=${newIndex}`,
        carePathStepDto,
        { headers: {} }
      )
      .then(async () => {
        handleChangeCarePath()
        getPatientsList()
      })
  };

  const handleStepRemoval = (step) => {
    let newState = carePathSteps;
    let instance = step.carePathInstanceId;
    newState.forEach((stp, i) => {
      if (stp.stepNumber === step.stepNumber) {
        stp.carePathStepDeleteReason = step.deletedReason
        stp.deleted = step.deleted

      }
    });
    removeStepAction(newState, instance);
  }

  const handleChangeCarePath = () => {
    const carePathInstanceId = patient?.carePathStepDto?.carePathInstanceId;
    if (carePathInstanceId) {
      axios
        .get(
          process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/care-paths/care-path-instance-steps?carePathInstanceId=${encodeURIComponent(
            carePathInstanceId
          )}`,
          {
            headers: {},
          }
        )
        .then((resp) => {
          setCarePathSteps(resp.data)
          setstep(resp.data.filter((cs, idx) => {
            const condition = cs.id === patient?.carePathStepDto?.id
            condition && setIndex(idx)
            return condition
          }
          )[0])
        });
    }
  }

  const handleCancel = () => {
    setCareProgrammObject({ ...careProgramObject, show: false })
    setSelectedReason("")
    handleChangeCarePath()
  };

  const handleStatusChange = (e, value, isCustomStatus, customStatus) => {
    let newState = [...carePathSteps];

    newState.forEach((stp) => {
      if (stp.stepNumber === value.stepNumber) {
        if(customStatus){
          value.carePathStepStatus = customStatus.status;
        } else {
          value.carePathStepStatus = e;
        }
      }
    });

    const isLastActiveStep = newState.every(e =>
      e.carePathStepStatus === 'COMPLETED' ||
      e.carePathStepStatus === 'CANCELLED' ||
      e.carePathStepStatus === 'SKIPPED'
    )
    if (isLastActiveStep) {
      checkCanCloseCarePath(careProgramObject?.careProgram, 'closeCareProgram', 'Close Care Program');
      setPendingTransaction(() => () => statusChangeRequest(e, value, isCustomStatus, customStatus, newState, false))
    } else {
      statusChangeRequest(e, value, isCustomStatus, customStatus)
    }
  };

  const checkCanCloseCarePath = (selectedCarePath, action, title) => {
    removeNotification();
    careManagementsPkg.fetchData(patient.id)
    const baseURLCP = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-paths";
    const payLoad = {
      carePathInstanceId: selectedCarePath?.id,
    };
    const url = baseURLCP + "/check-close-care-path";
    axios
      .post(url, payLoad, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response && response.data && !isEmpty(response.data.errorMessage)) {
          setNotification('Error', response.data.errorMessage)
        } else {
          await openCloseProgram(selectedCarePath, action, title);
          handleModal();
        }
      })
  };

  const statusChangeRequest = (e, value, isCustomStatus, customStatus) => {
    if (e !== 'SNOOZED') {
      const body = {
        action: "SET_STATUS",
        careProviderId: value.assignedTo ? value.assignedTo.id : "",
        text: null,
        status: e,
        assignToId: null,
        stepCustomStatus: isCustomStatus ? customStatus : null,
      };

      axios
        .put(
          process.env.REACT_APP_REST_API_BASE_URL +
          `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
            value.carePathInstanceId
          )}&carePathStepId=${value.id}`,
          body,
          { headers: {} }
        )
        .then(() => {
          handleChangeCarePath()
          findCustomStatusLabel(step)
          getPatientsList()
        })
    } else {
      toggleCustomStatus(customStatus)
      toggleSnoozedModal(true)
    }
    setPendingTransaction(null)
  }

  useEffect(() => {
    if (!snoozedModal) {
      findCustomStatusLabel(step)
      handleChangeCarePath()
    }
  }, [snoozedModal])

  const onAdd = (isDraft) => {
    let value = {
      action: "ADD_NOTE",
      careProviderId: step.assignedTo ? step.assignedTo.id : "",
      text: notes,
      status: step?.carePathStepStatus || null,
      assignToId: null,
      stepCustomStatus: null,
      isDraft: isDraft,
    };
    updateCarePathStep(value);
    handleChangeCarePath();
    dispatch(setReloadWorklist(true))
  };

  const findCustomStatusLabel = (step, selectedStatus) => {
    let statuses = ["COMPLETED", "OPEN", "PENDING", "SNOOZED", "SKIPPED", "OVERDUE", "CANCELLED"];
    let items = [];
    if (step && step.customStatuses) {
      step.customStatuses.forEach((em) => {
        items.push(<Dropdown.Item
          id={`${componentName}-carePathStepActionsCompleted-${0}`}
          eventKey={em.behavesLike}
          onClick={() => {
            if (step.trigger === "CARE_PATH_START_DATE" && em.behavesLike === "SNOOZED") {
              setNotification(ERROR_NOTIFICATION_TITLE, CANNOT_SNOOZE_MESSAGE);
            } else {
              handleStatusChange(em.behavesLike, step, true, em)
            }
          }}
          active={selectedStatus === em.behavesLike}
        >
          {em.status}
        </Dropdown.Item>)
        if (statuses.includes(em.behavesLike) && statuses.indexOf(em.behavesLike)  >= 0) {
          const ind = statuses.indexOf(em.behavesLike)
          if (ind >= 0) {
            statuses.splice(ind, 1)
          }

        }
      })
      if (statuses.length) {
        statuses.forEach((em) => {
          items.push(<Dropdown.Item
            id={`${componentName}-carePathStepActionsCompleted-${0}`}
            eventKey={em}
            onClick={() => {
              if (step.trigger === "CARE_PATH_START_DATE" && em === "SNOOZED") {
                setNotification(ERROR_NOTIFICATION_TITLE, CANNOT_SNOOZE_MESSAGE);
              } else {
                handleStatusChange(em, step)
              }
            }}
            active={selectedStatus === em}
          >
            {em}
          </Dropdown.Item>)
        })
      }
    }

    return items;
  };

  const updateCarePathStep = (value) => {
    axios
      .put(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/care-paths/update-care-path-instance-step?carePathInstanceId=${encodeURIComponent(
          step.carePathInstanceId
        )}&carePathStepId=${encodeURIComponent(step.id)}`,
        value,
        { headers: {} }
      )
      .then((res) => {
        step.notes = res.data.result.notes;
        setnotes('');
        handleChangeCarePath();
        dispatch(setReloadWorklist(true))
      })
  };

  const saveNewStep = async (stepObj, index, action, number) => {
    let newState = carePathSteps;
    if (action === "before") {
      if (index === 0) {
        for (let i = 0; i < newState.length; i++) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
        stepObj.stepNumber = number;
        newState.unshift(stepObj);
      } else {
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].stepNumber >= number) {
            newState[i].stepNumber =
              Math.round((newState[i].stepNumber + 1) * 10) / 10;
          }
        }
        stepObj.stepNumber = number;
        newState.splice(index, 0, stepObj);
      }
    } else if (action === "addCarePathStep") {
      newState.splice(newState.length + 1, 0, stepObj);
    }
    else if (action === 'delete') {
      handleStepRemoval(stepObj);
    }
    else {
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].stepNumber > number) {
          newState[i].stepNumber =
            Math.round((newState[i].stepNumber + 1) * 10) / 10;
        }
      }
      stepObj.stepNumber = Math.floor(number + 1);
      newState.splice(index + 1, 0, stepObj);
    }
    if (action !== "delete") {
      await submitNewStep(stepObj, newState);
    }
  };

  const handleUploadFile = (e) => {
    const fileIds = [...e]?.map(filter => ({ fileId: filter.fileId }))
    const oldFileIds = [...step.files]?.map(filter => ({ fileId: filter?.metadata?.fileId }))
    axios.post(
      process.env.REACT_APP_REST_API_BASE_URL +
      `/v1/care-path-step/upload-documentation`,
      {
        carePathStepId: step?.id,
        uploadedFiles: [...oldFileIds, ...fileIds]
      },
      {
        headers: {},
      }
    ).then(() => {
      setFiles([
        ...e.map(f => ({ metadata: { fileId: f.fileId, fileName: f.fileName, userId: auth.getUserId() } })),
        ...step.files
      ])
    });
  }

  const handleClickOnLink = (event, fileId, fileName) => {
    event.preventDefault();
    dispatch(getFileDownload(fileId, fileName));
  };

  const handleEditNoteClick = (idx) => {
    setEditingNoteIndex(idx);
    setEditingNoteValue(step.notes[idx].note);
    setDisabledEditNoteIconIndex(idx);
    setTimeout(() => {
      if(noteRefs.current[idx]){
        noteRefs.current[idx].focus();
      } 
    }, 0);
  };

  const handleSaveEditNoteClick = (isDraft) => {
    const noteId = step.notes[editingNoteIndex].id;
    const updatedDate = new Date().toISOString();
    
    try {
        axios.patch(
          process.env.REACT_APP_REST_API_BASE_URL +
            `/v1/care-path-step/note`,
          {
            carePathStepNoteId: noteId,
            note: editingNoteValue,
            editionDate: updatedDate,
            isDraft: isDraft
          },
          {
            headers: {},
          });   

        const updatedNotes = [...step.notes];
        updatedNotes[editingNoteIndex].note = editingNoteValue;
        updatedNotes[editingNoteIndex].creationDate = updatedDate;
        updatedNotes[editingNoteIndex].isDraft = isDraft;
        setEditingNoteIndex(null);
        setEditingNoteValue("");
        setDisabledEditNoteIconIndex(null);
    } catch (error) {
    }
  };

  const handleCancelEditNoteClick = () => {
    setEditingNoteIndex(null);
    setEditingNoteValue("");
    setDisabledEditNoteIconIndex(null);
  };


  /**
   * Check if the logged-in user can delete files
   * @param event
   * @param file
   * @return true if the logged-in user can delete files. otherwise, false
   */
  const isAllowedToRemoveAttachment = (file) => {
    return file?.metadata?.userId === auth.getUserId();
  };

  const handleClickOnRemoveAttachment = (event, file) => {
    event.preventDefault();
    setShowDeleteConfirmation(true);
    setSelectedFile(file);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedFile(null);
    setIsDeletingAttachment(false);
  };

  const removeAttachment = () => {
    removeNotification();
    setIsDeletingAttachment(true);

    axios.delete(process.env.REACT_APP_REST_API_BASE_URL + `/v1/care-path-step/delete-documentation`, {
      data: {carePathStepId: step?.id, uploadedFiles: [{fileId: selectedFile?.metadata?.fileId}]},
      headers: {"Content-Type": "application/json"}
    }).then(response => {
      setRemovedFilesIdsMap({...removedFilesIdsMap, [selectedFile?.metadata?.fileId]: selectedFile?.metadata?.fileId})
      setNotification('Successful', 'Attachment deleted successfully.');
      hideDeleteConfirmation();
    }).catch(err => {
      setIsDeletingAttachment(false);
      setNotification("Error", 'There was an error deleting the attachment')
    });
  };

  return (
    <>
      <Modals
        show={showDeleteConfirmation}
        size="sm"
        hideFooter={false}
        onCreate={removeAttachment}
        onHide={() => hideDeleteConfirmation()}
        centered={true}
        title="Confirm"
        titleid={`${componentName}-removeConfirm`}
        completeBtn="Confirm"
        disabledClickButton={isDeletingAttachment}
        completeBtnId={`${componentName}-removeConfirmButton`}
        closeBtn="Cancel"
        disabledClickCloseButton={isDeletingAttachment}
        closeBtnId={`${componentName}-removeCloseButton`}
        body={<span>Are you sure you want to delete the attachment?</span>}
      />

      <Modal
        id={`${componentName}-patientSendToDigitalOutreachConfirmModal`}
        size="lg"
        show={workOnCareStep}
        onHide={() => handleClose()}
        centered
      >
        <Modal.Header>
          <Modal.Title id={`${componentName}-patientSendToDigitalOutreachConfirmTitle`} style={{ fontStyle: 'italic' }}>
            {patient.carePathStepDto?.title}
          </Modal.Title>
          <button
            id={`${componentName}-patientSendToDigitalOutreachConfirmModalClose`}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => handleClose()}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex mb-3' style={{ marginTop: '-30px' }}>
            <small className='forced-capital me-3'>{patient?.fullName}</small>
            <small id="patientMemberID" className="me-3">
              <span id="patientMemberIDLabel"
                className="fw-bold me-2">ID:</span>
              <span id="patientMemberIDValue">{patient?.memberId}</span>
            </small>
            <small id="patientMemberID" className="me-3">
              <span id="patientMemberIDLabel"
                className="fw-bold me-2">DOB:</span>
              <span id="patientMemberIDValue">{formatDateVanilla(patient?.dateOfBirth, "MMM dd, yyyy")}</span>
            </small>
          </div>
          <div className='mb-3 ms-1 d-flex justify-content-between align-items-center'>
            <p
              style={{
                fontWeight: '600',
                margin: '0',
                padding: '0',
                height: 'fit-content',
              }}
            >{`${step?.assignedTo?.firstName || ''} ${step?.assignedTo?.lastName || ''}`}</p>
            <div className='d-flex justify-content-end align-items-center'>
              {step &&
                <CareStepStatus
                  index={index}
                  step={step}
                  findCustomStatusLabel={findCustomStatusLabel}
                  handleStatusChange={handleStatusChange}
                  careProgram={{ active: true }}
                  isNotFromPatientDrawer={true}
                />
              }
              <CarePathStepsKebab
                index={index}
                step={step}
                showAfterForm={false}
                showBeforeForm={false}
                editExistingStep={editExistingStep}
                saveNewStep={saveNewStep}
                handleChangeCarePath={handleChangeCarePath}
                careProgram={careProgram}
                patient={patient}
                isNotFromPatientDrawer={true}
              />
            </div>
          </div>
          <div>
            <Form.Group id={`${componentName}-carePathStepDescription-${0}`} as={Row} className="mb-4 ms-3 d-flex">
              <Form.Label id={`${componentName}-carePathStepDescriptionLabel-${0}`} column sm={3} style={{ opacity: '0.6', padding: 0, width: '150px' }}>
                Description
              </Form.Label>
              {!isEmpty(step?.description) &&
                <Col className="d-flex align-items-center" style={{ padding: 0 }}>
                  <span id={`${componentName}-carePathStepDescriptionValue-${0}`}>{step.description}</span>
                </Col>
              }
            </Form.Group>
            <Form.Group id={`${componentName}-carePathStepAttachments-${0}`} as={Row} className="mb-4 ms-3 d-flex">
              <Form.Label id={`${componentName}-carePathStepAttachmentsLabel-${0}`} column sm={3} style={{ opacity: '0.6', padding: 0, width: '150px' }}>
                Attachments
              </Form.Label>
              {files.filter(file => !removedFilesIdsMap[file?.metadata?.fileId]).map((file, idx) =>
                <div>
                  { isAllowedToRemoveAttachment(file) &&
                    <i className="fa-sharp fa-regular fa-square-xmark me-3 cursor-p" onClick={(event) => {
                      handleClickOnRemoveAttachment(event, file)
                    }} />
                  }
                  <a id={`${componentName}-carePathStepFile-${idx}`} href="#"
                    onClick={(event) => {
                      handleClickOnLink(event, file?.metadata?.fileId, file?.metadata?.fileName)
                    }}>
                    {file?.metadata?.fileName ?? ""}
                  </a>
                </div>
              )}
            </Form.Group>
            <Form.Group id={`${componentName}-carePathStepNotes-${0}`} as={Row} className="mb-4 ms-3 d-flex">
              <Form.Label id={`${componentName}-carePathStepNotesLabel-${0}`} column sm={3} style={{ padding: 0, width: '150px', opacity: '0.6' }}>
                Notes
              </Form.Label>
              <Col style={{
                minHeight: '150px',
                maxHeight: '200px',
                overflowY: 'auto',
                width: '290px'
              }}>
                <div>
                  {!isEmpty(step?.notes) &&
                    step.notes.filter(notes => notes.note !== '' && notes.notes !== null).map((note, idx) => {
                      const isEditing = editingNoteIndex === idx;
                      const isDisabled = disabledEditNoteIconIndex === idx;
                      const canEditNote = auth.getUserId() === note.author.userAccountId;
                      return (
                        <Row  key={idx} id={`${componentName}-Note-${idx}`} className="mb-2">
                          <Col>
                            <div className="d-flex align-items-start">
                              {note.isDraft && (
                                <span id={`${componentName}-NotesDraft-${idx}`}
                                      data-placement="top" 
                                      className="badge rounded-pill bg-warning border me-2"
                                      style={{color: "#d68729"}}> Draft
                                </span>
                              )}
                              {canEditNote && ( 
                                <i 
                                  className="fa-thin fa-pen cursor-p me-1" 
                                  style={isDisabled ? { opacity: 0.5, pointerEvents: 'none', filter: 'grayscale(100%)' } : {}}
                                  onClick={() => !isDisabled && handleEditNoteClick(idx)} 
                                  aria-disabled={isDisabled}>
                                </i>
                              )}
                              <div className="d-flex align-items-start" style={{ minWidth: '150px' }}>
                                <div className="d-flex flex-column me-3">
                                  <span id={`${componentName}-NotesName-${idx}`} className="fw-bold d-block lh-1 mt-1">
                                    {formatDateModern(new Date(note.creationDate).toISOString())}
                                  </span>
                                  <small id={`${componentName}-NotesDate-${idx}`}>
                                    {formatTimeMinimal(new Date(note.creationDate).toISOString())}
                                  </small>
                                </div>
                                {note.isSnoozed && <i className="fas fa-snooze text-muted me-1 ms-2"></i>}
                              </div>
                            </div>
                          </Col>
                          <Col id={`${componentName}-NotesText-${idx}`} className="d-flex flex-column">
                          {isEditing ? (
                            <>
                              <Form.Control
                                as="textarea"
                                ref={el => noteRefs.current[idx] = el}
                                value={editingNoteValue}
                                onChange={(e) => setEditingNoteValue(e.target.value)}
                                rows={3}
                              />
                              <div className="d-flex justify-content-end mt-2">
                                <Button variant="secondary" onClick={handleCancelEditNoteClick} className="me-2">Cancel</Button>
                                <Button variant="primary" onClick={() => handleSaveEditNoteClick(true)} disabled={!editingNoteValue.trim()} className="me-2">Save As Draft</Button>
                                <Button variant="primary" onClick={() => handleSaveEditNoteClick(false)} disabled={!editingNoteValue.trim()}>Save</Button>
                              </div>
                            </>
                          ) : (
                            <>
                              {note.note}
                            </>
                          )}
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </Col>
              <div style={{
                position: 'absolute',
                bottom: '60px',
                left: '30px',
                maxWidth: '150px'
              }}>
                <FileUpload
                  label="Upload Files"
                  showIcon={false}
                  btnClass="text-primary bg-light border-light"
                  onChange={e => handleUploadFile(e)}
                  clearStateProp={0}
                  showFileList={false}
                />
              </div>
              <Row className='d-flex justify-content-end'>
                <Form.Control
                  as="textarea"
                  onChange={e => setnotes(e.target.value)}
                  value={notes}
                  id={`${componentName}-carePathNotesBox-${0}`}
                  rows={3}
                  className="mt-2"
                  style={{ width: '550px' }}
                />
                <div className="mt-3 text-end cursor-p">
                  <Button id={`${componentName}-carePathSaveNotesAsDraft-${index}`}
                    variant="primary" 
                    onClick={() => onAdd(true)} 
                    disabled={notes.length === 0}
                    className="me-2">
                    Save As Draft
                  </Button>

                  <Button
                    id={`${componentName}-carePathAddNotes-${0}`}
                    onClick={() => onAdd(false)}
                    variant="primary"
                    disabled={notes.length === 0}
                  >
                    Add Note
                  </Button>
                </div>
              </Row>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
      <Modal id={`${componentName}-modal`} show={careProgramObject.show} onHide={handleCancel}>
        <Modal.Header>
          <Modal.Title id={`${componentName}-carePathTitle`}>{careProgramObject.title}</Modal.Title>
          <button id={`${componentName}-carePathClose`} type="button" onClick={handleCancel} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </Modal.Header>
      {careProgramObject && careProgramObject?.action === 'closeCareProgram' &&
        <CloseCareProgram
          removePath={handleRemovePath}
          careManagementsPkg={careManagementsPkg}
          patient={patient}
          selectedCarePath={careProgramObject.careProgram}
          openPrimaryModal={() => { }}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          selectedExpired={selectedExpired}
          setSelectedExpired={setSelectedExpired}
          selectedExpiredDate={selectedExpiredDate}
          setSelectedExpiredDate={setSelectedExpiredDate}
          selectedExpiredSource={selectedExpiredSource}
          setSelectedExpiredSource={setSelectedExpiredSource}
          close={handleCancel}
          primaryCloseError={primaryCloseError}
          patientInformation={() => { }}
        />
      }
      </Modal>
    </>
  )
}

export default WorkOnCareStep