import React from "react";
import Form from "react-bootstrap/Form";

const componentName = "InputNumber";

const InputNumber = ({
                         label,
                         onChange,
                         maxLength,
                         disabled,
                         value
                     }) => {
    return (
        <Form.Group>
            <label className="d-block" id={`${componentName}-label`}>{label}</label>
            <Form.Control
                id={`${componentName}-input`}
                disabled={disabled}
                type="number"
                onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength)
                }}
                onChange={(e) => onChange(e.target.value)}
                value={value}
            />
        </Form.Group>
    )
}

export default InputNumber;