import React from 'react'
import { EventTimeline } from './components'
import { EventTimelineProvider } from './context/EventTimeline.context'

function EventTimelineTab({ ...props }) {
  return (
    <EventTimelineProvider>
        <EventTimeline {...props} />
    </EventTimelineProvider>
  )
}

export default EventTimelineTab