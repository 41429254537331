import { disPatchError } from "../utils"
import {
  GET_REFERRALS, RESET_ERRORS, GET_EVENTS, UPDATE_SUCCESS, UPDATE_REFERRAL
  } from "../types";
  import axios from "../../utils/apiClient";
  export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/referral-email-admin/";


  export const getReferrals = () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "referral-email-settings", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_REFERRALS,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };

  export const getEvents = () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "events", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_EVENTS,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };
  export const updateReferralSettings = (referralSettingsData) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD", payload: true });
    dispatch({ type: RESET_ERRORS });
    axios
      .put(URL + `referral-email-settings`, referralSettingsData, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_REFERRAL,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SUCCESS,
          payload: "Successfully Updated",
        });
      })
      .catch((err) => {
        disPatchError(err,dispatch);
      });
  };


