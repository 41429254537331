import axios from "../../utils/apiClient";

export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/";
export const WORKSPACE_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-manager-workspace";
export const TEAMS_URL = `${URL}teams`;
export const PROGRAM_LEAD_URL = `${URL}managers`;

export const createProgramLead = (programLead) => {
    return axios
        .post(URL + "managers", programLead, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const updateProgramLead = (programLead) => {
    return axios
        .put(URL + "managers", programLead, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}

export const getProgramLead = (pageNumber = 1, pageSize = 1000) => {
    const params = { pageNumber, pageSize };
    return axios
        .get(PROGRAM_LEAD_URL, {
            params: params,
            headers: {
            },
        });
}

export const searchUsers = (name) => {
        const url = WORKSPACE_URL + "/users" + (name ? "?name=" + name : "");
        return axios
            .get(url, {
                headers: {},
            });
}

export const getStatus = () => {
    return axios
        .get(URL + "status", {
            headers: {
            },
        });
}

export const getTeams = () => {
    return axios
        .get(TEAMS_URL, {
            headers: {
            },
        });
}

export const createTeam = ({ name, priority }) => {
    return axios
        .post(TEAMS_URL, {
            teamName: name,
            priority: priority,
            headers: {},
        });
}

export const updateTeam = ({ teamId, name, priority=0 }) => {
    const putParams = { teamId: teamId, teamName: name, priority: priority };
    return axios
        .put(TEAMS_URL, {
            ...putParams,
            headers: {
            },
        });
}

