import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PersonalInformation from "./PersonalInformation";
import InsuranceInformation from "./InsuranceInformation";
import CareTeamInformation from "./CareTeamInformation";
import InternalPatientNotes from "./InternalPatientNotes";
import {
  usePatientDetailsContext
} from "./PatientDetailsModals/context/PatientDetails.context";
import { useAuth } from "../../../../context/authContext";

const componentName = "Main";

/**
 * Main component aggregates all patient information sections.
 * 
 * @param {Object} patient - Object containing detailed patient information.
 * @returns {JSX.Element} A composite view of various patient information sections.
 */
const Main = () => {
  const patient = useSelector((state) => state?.manageVisitReducer?.patient);
  const redirectID = sessionStorage.getItem('itemID');

  useEffect(() => {
    if (redirectID == 'patientNotes') {
      const objDiv = document.getElementsByClassName("tabs-wrapper")[0];
      setTimeout(function(){
        objDiv.scrollTop = 10000
    }, 500);
    }
  }, [redirectID])

  const {
    getPatientProfileDetails,
    patientProfileInformation,
    getLanguages,
    getSpokenReadingLanguages,
    getStates,
    getPayorStatus,
    getSpecialistTypes,
    getPronounsDropList,
    getSexAssignedBirthDropList,
    getRaceEthnicityDropList,
    getSexualOrientationList,
    getGenderIdentityList,
  } = usePatientDetailsContext();

  const auth = useAuth();
  const authorizedForViewInternalNotes = auth.hasAnyAuthority([
    "AUTH_PD_PATIENT_DETAILS_INTERNAL_NOTES_READ_ACCESS",
    "AUTH_PD_PATIENT_DETAILS_INTERNAL_NOTES_WRITE_ACCESS"
  ]);

  useEffect(() => {
    getSpecialistTypes();
    getPayorStatus();
    getLanguages();
    getSpokenReadingLanguages();
    getStates(false, true, true, true);
    if(patient && patient.id != null) {
      getPatientProfileDetails(patient?.id);
      getPronounsDropList();
      getSexAssignedBirthDropList();
      getRaceEthnicityDropList();
      getSexualOrientationList();
      getGenderIdentityList();
    }
  }, [patient]);

  return (
    <div className="tabs-wrapper">
      <div className="bg-white border p-3 rounded-3 mb-3">
        <PersonalInformation patientInfo={patientProfileInformation} />
      </div>
      <div className="bg-white border p-3 rounded-3 mb-3">
        <InsuranceInformation patientInfo={patientProfileInformation} />
      </div>
      <div className="bg-white border p-3 rounded-3 mb-3">
        <CareTeamInformation patientInfo={patientProfileInformation} />
      </div>
      {authorizedForViewInternalNotes &&
        <div className="border p-3 rounded-3" style={{backgroundColor: "#FFFEC2"}}>
          <InternalPatientNotes patientInfo={patientProfileInformation} />
        </div>}
    </div>
  );
}

export default Main;
