import React, { useEffect, useRef, useState } from "react";

import { CollapsibleCard } from "./CollapsibleCard";
import HospitalEventCardClosed from "./HospitalEventCardClosed";
import HospitalEventCardOpened from "./HospitalEventCardOpened";

/**
 * Displays a single hospital event as a card.
 *
 * @param {Object} hospitalEvent - The hospital event data to display.
 * @param {number} idx - Index of the event in the list, used for unique keys.
 */
const HospitalEventCard = ({ hospitalEvent, idx, toggleIndividual, open}) => {
  const ref = useRef(null);
  const redirectID = sessionStorage.getItem('itemID');

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      setTimeout(() => {
        toggleIndividual()
      }, 1000);
      sessionStorage.removeItem('itemID');
    }
  }, [hospitalEvent.id])

  const ClosedContent = (
    <HospitalEventCardClosed
      hospitalEvent={hospitalEvent}
      idx={idx}
      opened={open}
      setOpen={toggleIndividual}
    />
  );

  const OpenContent = (
    <HospitalEventCardOpened
      hospitalEvent={hospitalEvent}
    />
  );

  return (
    <div ref = {redirectID && hospitalEvent.id == redirectID ? ref : null}>
      <CollapsibleCard
        openContent={OpenContent}
        open={open}
        idx={idx}
        closedContent={ClosedContent}
      />
    </div>
  );
};

export default HospitalEventCard;
