import axios from "axios";

const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/performance-manager";
const PATIENT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-management/patient-programs";
const EXPORT_URL = `${process.env.REACT_APP_REST_API_BASE_URL}/v1/care-management/performance-manager/export`;

export const getPerformanceData = (rangeDate) => {
    const params = {
        ...rangeDate
    }
    return axios
        .get(URL, {
            params,
            headers: {},
        });
}

export const getPatientsData = (rangeDate = [], filters = [], page) => {
    const params = {
        ...rangeDate,
        interventionChannelId: filters?.interventionChannelId?.paramValue?.join("|"),
        interventionSourceId: filters?.interventionSourceId?.paramValue?.join("|"),
        interventionTypeId: filters?.interventionTypeId?.paramValue?.join("|"),
        interventionOutcomeId: filters?.interventionOutcomeId?.paramValue?.join("|"),
        pageNumber: page || 1,
        pageSize: 20
    };
    return axios
        .post(PATIENT_URL, params);
}

export const exportDO = (rangeDate) => {
    const params = {
        ...rangeDate
    }
    return axios
        .get(EXPORT_URL, {
            params,
            headers: {
                "Content-Type": "application/json",
            },
        });
}
