import React, { useState, Fragment, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  paramValue,
} from "./sharedDDL";
import {
  FilterParam,
  emptyFilterParam,
} from "../../dtos/PatientSearchDTO";
import { EQHDatePicker } from "../datePicker";
import _ from "lodash";
import { Filter } from "../filters/SideFilters/components";
import '../filters/SideFilters/styles/DDL.css';
import DateDDL from "./dateDDL";

const monthByNameMap = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const monthByNumberMap = Object.fromEntries(
  monthByNameMap.map((kvp) => [kvp.value, kvp.text])
);

const componentName = "ComparisonDDL";
const COMPARISON_DDL_TYPE = "ComparisonDDL";

const ComparisonDDLKind = {
  DatePicker: "DatePicker",
  FreeText: "FreeText",
};

const ComparisonDropDownList = ({
  header,
  handleChange,
  selected,
  type = ComparisonDDLKind.DatePicker,
  externalDataMap,
  onLoad,
  onlyShowRange = false,
  isDateDLL = false,
  allowRange = false,
  allowMonth = false,
  showClear = true,
  showRequired,
  errors,
  insideErrors = [],
}) => {
  const idFragment = header.replaceAll(" ", "_").toUpperCase();

  const getComparisonText = (operator) => {
    if (operator) {
      operator = operator.toLowerCase();
    }
    switch (operator) {
      case "lt":
      case "less_than":
        return operators[1];
      case "gt":
      case "greater_than":
        return operators[2];
      case "range":
        return operators[3] ?? operators[0];
      case "month":
        return (allowRange ? operators[4] : operators[3]) ?? operators[0];
      case "eq":
      case "equals_to":
      default:
        return operators[0];
    }
  };
  let operators =
    type === ComparisonDDLKind.DatePicker
      ? ["On", "Prior to", "Since"]
      : ["Equal to", "Less than", "Greater than"];

  if (type === ComparisonDDLKind.DatePicker && allowRange) {
    operators.push("Range");
  }

  if (type === ComparisonDDLKind.DatePicker && allowMonth) {
    operators.push("Month");
  }

  if (onlyShowRange) {
    operators = ["Range"];
    allowRange = true;
  }

  const comparisonValues = paramValue(selected);
  const [selectedOperator, setSelectedOperator] = useState(() =>
    selected && selected.comparison
      ? getComparisonText(selected.comparison)
      : operators[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  const isRange = (operator) => (operator ?? selectedOperator) === "Range" || isDateDLL;
  const isMonth = (operator) => (operator ?? selectedOperator) === "Month";

  if (isMonth()) {
    for (let i = 0; i < comparisonValues.length; i++) {
      if (typeof comparisonValues[i] === "string") {
        comparisonValues[i] = parseInt(comparisonValues[i]);
      }
    }
  }

  const getOnChangeDTO = (comparisonValues, operator) => {
    let filterParamOperator = "eq";

    if (_.isEmpty(comparisonValues) || comparisonValues.every((x) => x === "")) {
      return emptyFilterParam(header);
    }

    if (operator) {
      switch (operator) {
        case "Less than":
        case "Prior to":
          filterParamOperator = "lt";
          break;
        case "Greater than":
        case "Since":
          filterParamOperator = "gt";
          break;
        case 'period30':
        case 'period60':
        case 'period90':
        case 'Range':
          filterParamOperator = "range";
          break;
        case "Month":
          filterParamOperator = "month";
          break;
        case "Equal to":
        case "On":
        default:
          filterParamOperator = "eq";
      }
    }
    let filterParam = new FilterParam(
      header,
      filterParamOperator,
      comparisonValues.map(x => { return { text: monthByNumberMap[x], value: x } }),
      (e) => e?.value,
      (e) => e?.text
    );
    return filterParam;
  };

  const onDataChange = (comparisonValues, selectedOperator) => {
    if (!Array.isArray(comparisonValues)) {
      comparisonValues = [comparisonValues];
    }
    setSelectedOperator(selectedOperator);
    handleChange(getOnChangeDTO(comparisonValues, selectedOperator));
  };

  const onDateChange = (date, idx) => {
    let comparisonValuesClone = [...comparisonValues];
    comparisonValuesClone[idx] = date;

    onDataChange(comparisonValuesClone, selectedOperator);
  };

  const onMonthChange = (month, selected) => {
    let comparisonValuesClone = [...comparisonValues];
    if (selected) {
      comparisonValuesClone.push(month);
    } else {
      var index = comparisonValuesClone.indexOf(month);
      if (index > -1) {
        comparisonValuesClone.splice(index, 1);
      }
    }
    onDataChange(comparisonValuesClone, selectedOperator);
  };

  const onFreeTextChange = (text) => {
    onDataChange(text, selectedOperator);
  };

  if (onLoad) {
    onLoad(
      externalDataMap,
      getOnChangeDTO(comparisonValues, selectedOperator),
      onDataChange
    );
  }

  const closedAndHasSelectedData = () => {
    return !_.isEmpty(comparisonValues);
  };

  const handleToggle = (ddlOpen, event, metadata) => {
    setIsOpen(ddlOpen);
  };

  const onRadioClick = (operator) => {
    onDataChange([], operator);
  };

  const onClearClick = () => {
    onDataChange([], onlyShowRange ? "Range" : "On");
  };

  const yyyyMMddToMMddyyyy = (strDate) => {
    return strDate
      ? `${strDate.substr(5, 2)}/${strDate.substr(8, 2)}/${strDate.substr(
        0,
        4
      )}`
      : "";
  };

  const MMddyyyyToyyyyMMdd = (strDate) => {
    return strDate
      ? `${strDate.substr(6, 4)}-${strDate.substr(0, 2)}-${strDate.substr(
        3,
        2
      )}`
      : "";
  };

  const HeaderComponent = () => {
    return (
      <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
        <span id={`${componentName}-${idFragment}-filterTitleText}`}>{header?.replace(/\d+/g, '')}</span>
        {showRequired && <span className="text-danger">*</span>}
        {closedAndHasSelectedData() ? (
          <i id={`${componentName}-${idFragment}-filterReset`} className="fas fa-circle fa-2xs cursor-p d-inline-block ms-2 me-0 text-primary"></i>
        ) : (
          ""
        )}
      </label>
    )
  }

  function removeNumbersAndCheck(word, sentence) {
    const cleanedWord = word.replace(/\d/g, '');
    return sentence.includes(cleanedWord);
  }

  return (

    <Filter
      index={`${componentName}-${idFragment}-filterDropdown`}
      onToggle={handleToggle}
      header={<HeaderComponent />}
      componentName={componentName}
      idFragment={idFragment}
    >
      {selectedOperator !== "Month" &&
        (type === ComparisonDDLKind.DatePicker ? (
          <>
            <EQHDatePicker
              id={`${componentName}-${idFragment}-datePicker`}
              onChange={(e) => onDateChange(yyyyMMddToMMddyyyy(e.strDate), 0)}
              value={MMddyyyyToyyyyMMdd(comparisonValues[0])}
            />
            {isRange() ? (
              <EQHDatePicker
                id={`${componentName}-${idFragment}-datePicker2`}
                onChange={(e) => onDateChange(yyyyMMddToMMddyyyy(e.strDate), 1)}
                style={{ marginTop: '5px' }}
                value={MMddyyyyToyyyyMMdd(comparisonValues[1])}
              />
            ) : null}
            {(errors.filter(err => removeNumbersAndCheck(header, err)).length > 0 || insideErrors.filter(err => removeNumbersAndCheck(header, err)).length > 0) &&
              <span style={{ fontSize: '10px'}} className="text-danger fw-bold">
                {errors[0] || insideErrors[0]}
              </span>
            }
          </>
        ) : (
          <Form.Control
            type="text"
            value={_.isEmpty(comparisonValues) ? "" : comparisonValues[0]}
            onChange={(e) => onFreeTextChange(e.target.value)}
          />
        ))}

        {showClear &&
          <Button id={`${componentName}-${idFragment}-clearValue`} variant="link" onClick={onClearClick} className="badge text-uppercase rounded-pill bg-light border text-dark text-decoration-none mb-3">
            Clear
          </Button>
        }
      {!onlyShowRange &&
        operators.map((x, idx) => (
          <Form.Check
            key={`${componentName}-${x}-${idx}`}
            id={`${componentName}-${x}-${idx}`}
          >
            <Form.Check.Input id={`${componentName}-${x}-${idx}`} type="radio" onChange={() => onRadioClick(x)} checked={x === selectedOperator} />
            <Form.Check.Label id={`${componentName}-${x}Label-${idx}`} onChange={() => onRadioClick(x)}>{x}</Form.Check.Label>
          </Form.Check>
        ))}
      {isMonth() && (
        <>
          {" "}
          {monthByNameMap.map((x, idx) => (
            <Form.Check
              id={`${componentName}-months-${idFragment}-${idx}`}
            >
              <Form.Check.Input id={`${componentName}-months-${idFragment}-${idx}`} type="checkbox" onChange={(e) => onMonthChange(x.value, e.target.checked)} checked={comparisonValues.includes(x.value)} />
              <Form.Check.Label id={`${componentName}-months-${idFragment}Label-${idx}`} onChange={(e) => onMonthChange(x.value, e.target.checked)}>{x.text}</Form.Check.Label>
            </Form.Check>
          ))}{" "}
        </>
      )}
      {
        isDateDLL &&
          <DateDDL
            selectedOperator={selectedOperator}
            onDataChange={onDataChange}
            comparisonValues={comparisonValues}
          />
      }
    </Filter>
  );
};

export { ComparisonDropDownList, ComparisonDDLKind, COMPARISON_DDL_TYPE };
