import React, {useEffect} from "react";
import {Col, Row, Button} from 'react-bootstrap';
import SpecialistReferralStep1 from "./SpecialistReferralStep1";
import {Modal} from "react-bootstrap";
import {useReferralsContext} from "../context/Referrals.context";
import SpecialistReferralStep2 from "./SpecialistReferralStep2";
import SpecialistReferralStep3 from "./SpecialistReferralStep3";
import isEmpty from "is-empty";
import {SubstanceAbuseWarningModal} from "../components";
import {useAuth} from "../../../../../../context/authContext";
import {useDispatch} from "react-redux";

const componentName = "SpecialistReferral";

const SpecialistReferral = () => {
    const {
        initSpecialistReferralVars,
        showSpecialistReferral,
        toggleShowSpecialistReferral,
        setCurrentSpecialistReferralStep,
        searchProviders,
        currentSpecialistReferralStep,
        selectedSpeciality,
        selectedPayer,
        selectedPractice,
        selectedAssociatedProvider,
        zipCode,
        selectedGender,
        patient,
        specialistReferralSteps,
        selectedProvider,
        substanceAbuseICD10,
        setSubstanceAbuseICD10,
        confirmSubstanceAbuseICD10,
        uploadedFiles,
        selectedVisitType,
        icd10List,
        selectedUrgency,
        saveSpecialistReferral,
        isSpecialistReferralSaved,
    } = useReferralsContext();
    const auth = useAuth();
    const userName = auth.getName();
    const globalDispatch = useDispatch();

    useEffect(() => {
        if (showSpecialistReferral) {
            setCurrentSpecialistReferralStep(specialistReferralSteps.step1);
        }
    }, [showSpecialistReferral])

    useEffect(() => {
        if (isSpecialistReferralSaved) {
            closeModal();
        }
    }, [isSpecialistReferralSaved])

    const getCurrentStepComponent = () => {
        switch (currentSpecialistReferralStep) {
            case specialistReferralSteps.step1:
                return <SpecialistReferralStep1/>;
            case  specialistReferralSteps.step2:
                return <SpecialistReferralStep2/>;
            case  specialistReferralSteps.step3:
                return <SpecialistReferralStep3/>;
        }
    }

    const areRequiredFieldsFilled = () => {
        if (currentSpecialistReferralStep) {
            switch (currentSpecialistReferralStep) {
                case specialistReferralSteps.step1:
                    return !!selectedSpeciality?.paramValue?.[0] && !!selectedPayer?.paramValue?.[0];
                case  specialistReferralSteps.step2:
                    return !!selectedProvider
                case  specialistReferralSteps.step3:
                    let isICD10Selected = false;
                    if (icd10List && icd10List.length > 0) {
                        isICD10Selected = icd10List.some(icd10 => icd10.code && icd10.code.length > 0)
                    }
                    return !!uploadedFiles && uploadedFiles.length > 0 && !!selectedVisitType?.paramValue?.[0] && isICD10Selected && !!selectedUrgency
            }
        }
    }

    const closeModal = () => {
        toggleShowSpecialistReferral(false);
        initSpecialistReferralVars();
    }

    const onSubmit = () => {
        if (currentSpecialistReferralStep) {
            switch (currentSpecialistReferralStep) {
                case specialistReferralSteps.step1:
                    getProviders();
                    break;
                case specialistReferralSteps.step2:
                    setCurrentSpecialistReferralStep(specialistReferralSteps.step3);
                    break;
                case specialistReferralSteps.step3:
                    saveSpecialistReferral(userName, globalDispatch);
                    break;
            }
        }
    }

    const getLabel = () => {
        if (currentSpecialistReferralStep) {
            switch (currentSpecialistReferralStep) {
                case specialistReferralSteps.step1:
                    return "Search";
                case specialistReferralSteps.step2:
                    return "Select";
                case specialistReferralSteps.step3:
                    return "Submit";
            }
        }
    }

    const getProviders = () => {
        const providerLocation = zipCode ? String(zipCode) : null;
        const filters = [
            {
                fieldName: "searchProviderSpecialty",
                value: "",
                valuePresentation: selectedSpeciality?.paramValue?.[0],
            },
            {
                fieldName: "searchProviderPayer",
                value: "",
                valuePresentation: selectedPayer?.paramValue?.[0],
            },
            {
                fieldName: "searchProviderPracticeName",
                value: "",
                valuePresentation: selectedPractice?.[0]?.practiceGroupName
            },
            {
                fieldName: "searchProviderAssociatedProviderName",
                value: "",
                valuePresentation: selectedAssociatedProvider?.[0]?.associatedProviderName
            },
            {
                fieldName: "searchProviderLocation",
                value: providerLocation,
                valuePresentation: providerLocation,
            },
            {
                fieldName: "searchProviderGender",
                value: "",
                valuePresentation: selectedGender?.paramValue?.[0],
            },
            {
                fieldName: "searchProviderPatientName",
                value: "",
                valuePresentation: patient.id,
            }
        ]
        searchProviders(filters, specialistReferralSteps.step2);
    }

    return (
        <>
            <Modal
                id={`${componentName}`}
                size="md"
                show={showSpecialistReferral}
                onHide={() => closeModal()}
                centered>
                <Modal.Header>
                    <Modal.Title id={`${componentName}-title`}>Specialist Referral</Modal.Title>
                    <button id={`${componentName}-close`} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => closeModal()}
                    />
                </Modal.Header>
                <Modal.Body className="pb-2 overflow-auto" style={{"height": "70vh"}}>
                    {getCurrentStepComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <Button
                                id={`${componentName}-cancelButton`}
                                className="text-primary"
                                variant={"light"}
                                onClick={() => closeModal()}>
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => onSubmit()}
                                disabled={!areRequiredFieldsFilled()}
                                id={`${componentName}-submitButton`}>
                                {getLabel()}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <SubstanceAbuseWarningModal
                showModal={!isEmpty(substanceAbuseICD10)}
                icd10Info={substanceAbuseICD10}
                onConfirm={(icd10Info) => confirmSubstanceAbuseICD10(icd10Info)}
                onCancel={() => setSubstanceAbuseICD10(null)}
            />
        </>
    )
}

export default SpecialistReferral;