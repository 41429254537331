import React, {useRef} from "react";
import {VIEW} from "./QuickList";
import {searchPatientReferrals} from "../../actions/patientSearch/patientSearchActions";
import {FilterParam} from "../../dtos/PatientSearchDTO";
import {formatDateVanilla} from "../../utils/util";
import {FILTER_DEFINITIONS_MAP} from "../../components/filters/filterDefinition";
import _ from "lodash";
import {AppPropertiesUtil} from "../../utils/localStorageUtil";
import ECIPDashboard from "../reports/ECIPDashboard";

export const EXPORT_TRANSITIONS_OF_CARE =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/export-patients/adt-events";

export const EXPORT_ALL_PATIENTS =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/export-patients/all-patients";

export const EXPORT_INACTIVE_PATIENTS =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/export-patients/inactive-patients";

export const EXPORT_MY_PATIENTS =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/export-patients/mypatients";

export const EXPORT_APPOINTMENTS =
  process.env.REACT_APP_REST_API_BASE_URL + "/v1/export-patients/appointments";

export const EXPORT_PREVENTIVE_CARE =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/export-patients/preventive-care";
export const EXPORT_MISSED_OPPORTUNITIES =
    process.env.REACT_APP_REST_API_BASE_URL +
    "/v1/export-patients/missed-opportunities";

export const EXPORT_REQUIRING_ACTION =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/export-patients/requiring-action";

export const EXPORT_CHRONIC_CONDITIONS =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/export-patients/chronic-conditions";

export const EXPORT_WORKLIST=
    process.env.REACT_APP_REST_API_BASE_URL +
    "/v1/export-patients/work-list"

const getDefaultListViewColumns = (auth) => {
  const defaultListViewColumns = [
    "PatientListChkbox",
    "FullName",
    "DateOfBirth",
    "MemberID",
    "Risk",
    "Worklists",
    "EDVisits",
    "ActiveGaps",
    "PCPPractice",
    "SDOH",
    "Disposition",
    "CarePath",
    "CurrentStep",
    "StepDueDate",
    "StepAssignedTo",
  ];
  if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
    defaultListViewColumns.push("RecentVisitDate");
    defaultListViewColumns.push("NextVisitDate");
  }
  return defaultListViewColumns;
};

const getAllPatientsListViewColumns = (auth) => {
  const defaultListViewColumnsFirstHalf = [
    "PatientListChkbox",
    "ManageEvidencebutton",
    "FullName",
    "DateOfBirth",
    "MemberID",
    "Risk",
    "Worklists",
    "PCPPractice",
    "Disposition",
    "Hospitalizations",
    "EDVisits",
    "LatestAdmitDate",
    "LatestDischargeDate",
    "Diagnosis",
    "CompletedReferralsCount", // Completed Referrals. count
    "PendingReferralsCount"
  ];

  if ( auth.hasAuthority("CHRONIC_CONDITION_QUICK_LIST_ACCESS")) {
    defaultListViewColumnsFirstHalf.push("ChronicConditions");
  }

  const defaultListViewColumnsSecondHalf = [
  "ActiveGaps",
  "SDOH",
  "CarePath",
  "CurrentStep",
  "StepDueDate",
  "StepAssignedTo"
];

const defaultListViewColumns = [...defaultListViewColumnsFirstHalf, ...defaultListViewColumnsSecondHalf];
  if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
    defaultListViewColumns.push("RecentVisitDate");
    defaultListViewColumns.push("NextVisitDate");
  }
  return defaultListViewColumns;
};

const getPreventiveListViewColumns = (auth) => {
const preventiveListViewColumns = [
  "PatientListChkbox",
  "ManageEvidencebutton",
  "FullName",
  "DateOfBirth",
  "MemberID",
  "PCPPractice",
  "CareTeam",
  "Risk",
  "ActiveCarePath",
  "Hospitalizations",
  "EDVisits",
  "CareOpportunities",
  "Suspects",
];
if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  preventiveListViewColumns.push("RecentVisitDate");
  preventiveListViewColumns.push("NextVisitDate");
}
return preventiveListViewColumns;
}

const getPreventiveListViewColumnsGap = (auth) => {
const preventiveListViewColumnsGap = [
  "PatientListChkbox",
  "ManageEvidencebutton",
  "FullName",
  "DateOfBirth",
  "MemberID",
  "Risk",
  "Worklists",
  "CareOpportunities",
  "PCPPractice",
];
if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  preventiveListViewColumnsGap.push("RecentVisitDate");
  preventiveListViewColumnsGap.push("NextVisitDate");
}
return preventiveListViewColumnsGap;
}

const getPreventiveListViewColumnsRaf = (auth) => {
const preventiveListViewColumnsRaf = [
  "PatientListChkbox",
  "ManageEvidencebutton",
  "FullName",
  "DateOfBirth",
  "MemberID",
  "Risk",
  "Worklists",
  "Suspects",
  "PCPPractice",
];
if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  preventiveListViewColumnsRaf.push("RecentVisitDate");
  preventiveListViewColumnsRaf.push("NextVisitDate");
}
return preventiveListViewColumnsRaf;
}
const getRaAndHEListViewColumns = (auth) => {
const raAndHEListViewColumns = [
  "PatientListChkbox",
  "ManageButtonTOC",
  "FullName",
  "DateOfBirth",
  "MemberID",
  "PCPPractice",
  "CareTeam",
  "Risk",
  "ActiveCarePath",
  "Hospitalizations",
  "EDVisits",
  "LatestAdmitDate",
  "LatestDischargeDate",
  "DaysSincePriorDischarge",
  "DischargeDisposition",
  "FacilityName",
  "Diagnosis",
];
if ( auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  raAndHEListViewColumns.push("RecentVisitDate");
  raAndHEListViewColumns.push("NextVisitDate");
}
return raAndHEListViewColumns;
}

const getReferralListViewColumns = (auth) => {
const referralListViewColumns = [
  "ReferralListChkbox",
  "ReferralFullName",
  "DateOfBirth",
  "MemberID",
  "Risk",
  "SentDate",
  "Sender",
  "Recipient",
  "Urgency",
  "RespondBy",
  "ReferralType",
  "Status",
];
if (auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  referralListViewColumns.push("RecentVisitDate");
  referralListViewColumns.push("NextVisitDate");
}
return referralListViewColumns;
}

const getReferralListNewViewColumns = (auth) => {
  const referralListViewColumns = [
    "ReferralCheckBox",
    "ReferralListChkbox",
    "ReferralFullName",
    "ReferralAction",
    "DateOfBirth",
    "MemberID",
    "RiskText",
    "ProgramStatus",
    "SentDate",
    "Sender",
    "Recipient",
    "Urgency",
    "RespondBy",
    "ReferralType",
    "Status",
  ];
  if (auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
    referralListViewColumns.push("RecentVisitDate");
    referralListViewColumns.push("NextVisitDate");
  }
  return referralListViewColumns;
  }

const getChronicListViewColumns = (auth) => {
const chronicListViewColumns = [
  "PatientListChkbox",
  "ManageEvidencebutton",
  "FullName",
  "DateOfBirth",
  "MemberID",
  "PCPPractice",
  "CareTeam",
  "Risk",
  "ActiveCarePath",
  "Hospitalizations",
  "EDVisits",
  "ChronicConditions",
];
if (auth.hasAuthority("AUTH_RECENT_VISIT_DATE_ACCESS") ||  auth.hasAuthority("AUTH_RECOMMENDED_VISIT_DATE_ACCESS")) {
  chronicListViewColumns.push("RecentVisitDate");
  chronicListViewColumns.push("NextVisitDate");
}
return chronicListViewColumns;
}

const defaultFilters = [
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Latest Discharge Date",
  "ADT Disposition",
  "ADT Event Date",
  "ADT by Facility",
  "Days Since Last ADT",
  "Care Coordinator",
  "Patient Name",
  "Birth Date",
  "City",
  "Care Program Coordinator",
  "Care Program",
  "Patients",
  "Risk Score",
  "State",
  "Zip Code",
  "Member Worklist",
  "Last Known Visit",
  "fileinput"
];

const trannsitionsFilters = [
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "ADT Disposition",
  "Birth Date",
  "City",
  "Patients",
  "Zip Code",
  "ADT Event Date",
  "ADT by Facility",
  "Days Since Last ADT",
  "lostMember",
  "Readmit",
  "fileinput"
];

const referralSentFilters = [
  "Referral Type",
  "Visit Type",
  "Services Needed",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Referral Sent Status",
  "Patient Name",
  "Birth Date",
  "Last Known Visit",
  "fileinput"
];

const referralReceivedFilters = [
  "Referral Type",
  "Visit Type",
  "Services Needed",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Referral Received Status",
  "Patient Name",
  "Birth Date",
  "Last Known Visit",
  "fileinput"
];

const careReferralFilters = [
  "Visit Type",
  "Services Needed",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Patient Name",
  "Birth Date",
  "Last Known Visit",
  "Referral Status"
];

const referralAwaitingFilters = [
  "Referral Type",
  "Visit Type",
  "Services Needed",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Patient Name",
  "Birth Date",
  "Last Known Visit",
  "fileinput"
];

const preventiveCareFilters = [
  "Opportunity Status",
  "Suspect Status",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Opportunity Definition",
  "Validation",
  "Measure Year",
  "WC15 Approved",
  "Birth Date",
  "City",
  "Zip Code",
  "Patients",
  "ADT Disposition",
  "ADT Event Date",
  "Latest Discharge Date",
  "Days Since Last ADT",
  "ADT by Facility",
  "Chronic Condition",
  "lostMember",
  "fileinput"
];
const missedOpp = [
  "missedOppStatus",
  "Payor",
  "PCP Practice",
  "Opportunity Definition",
  "lostMember",
  "fileinput",
];

const appointmentsFilters = [
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Latest Discharge Date",
  "ADT Disposition",
  "ADT Event Date",
  "ADT by Facility",
  "Patient Name",
  "Birth Date",
  "City",
  "Care Program Coordinator",
  "Last Known Visit",
  "fileinput"
];

const chronicConditionsFilters = [
  "Risk Level",
  "Chronic Condition",
  "Payor",
  "PCP Practice",
  "PCP",
  "Patient Name",
  "Birth Date",
  "City",
  "State",
  "Zip Code",
  "Patients",
  "Care Coordinator",
  "Care Program",
  "Care Program Coordinator",
  "Care Program Step Coordinator",
  "Last Known Visit",
  "Provider MSA",
  "fileinput"
];

const hubCenterFilters = [
  "Patient Name",
  "Birth Date",
  "Status",
  "Payor",
  "Zip Code",
  "Latest Discharge Date",
  "Risk Level",
  "Risk Score",
  "State",
  "City",
  "ADT Disposition",
  "Program",
  "memberIds",
  "source",
  "lineOfBusiness",
  "latestAdmissionDate",
  "assignDate",
  "lastPCPVisitFilter",
  "readmitYN",
  "zone",
  "lostMember"
];

const allPatientFilters = [
  "Care Program Coordinator",
  "Opportunity Status",
  "Risk Level",
  "Payor",
  "PCP Practice",
  "PCP",
  "Opportunity Definition",
  "Validation",
  "Measure Year",
  "Birth Date",
  "City",
  "Zip Code",
  "Patients",
  "ADT Disposition",
  "ADT Event Date",
  "ADT by Facility",
  "Days Since Last ADT",
  "Chronic Condition",
  "lostMember",
  "fileinput"
];

const requiringActionFilters =[
  "Payor",
  "PCP Practice",
  "PCP",
  "Validation",
  "Birth Date",
  "City",
  "Zip Code",
  "Patients",
  "Chronic Condition",
  "lostMember",
  "fileinput"
]

const quickListSettings = {
  myPatients: {
    default: {
      titleProp: "MY PATIENTS",
      filters: [
        ...defaultFilters,
        "Provider MSA"
      ],
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Patients",
          selected: [{ text: "Assigned to me", value: "mine" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_MY_PATIENTS,
      quickListType: "MY_PATIENTS",
      listViewColumns: (auth) => getAllPatientsListViewColumns(auth),
    },
  },
  allPatients: {
    default: {
      titleProp: "ALL PATIENTS",
      filters: allPatientFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: EXPORT_ALL_PATIENTS,
      quickListType: "ALL_PATIENTS",
      listViewColumns: (auth) => getAllPatientsListViewColumns(auth),
    },
  },

  inactivePatients: {
    default: {
      titleProp: "ACTIVE/INACTIVE PATIENTS",
      filters: [
        ...defaultFilters.slice(0, defaultFilters.length - 3),
        "Care Program Step Coordinator",
        "Last Known Visit",
        "Provider MSA",
        "Patient Status",
        "Patient Expired",
        "Expired Date",
        "lostMember",
        "fileinput"
      ],
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Patient Status",
          selected: [
            { text: "Inactive", value: "inactive" },
            { text: "Active", value: "active" },
          ],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_INACTIVE_PATIENTS,
      quickListType: "ACTIVE_INACTIVE_PATIENTS",
      listViewColumns: (auth) => getDefaultListViewColumns(auth),
      additionalFilterValidation: (map, errors) => {
        const patientStatus = map.get("Patient Status");
        if (
          !patientStatus ||
          !patientStatus.paramValue ||
          !patientStatus.paramValue.length
        ) {
          errors.push("Please Select Patient Status.");
        }
      },
    },
  },
  adtEvents: {
    default: {
      titleProp: "TRANSITIONS OF CARE",
      filters: trannsitionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "ADT Disposition",
          selected: [
            { text: "BH IP Admit", value: "BH_IP_ADMIT" },
            { text: "BH IP Discharge", value: "BH_IP_DISCHARGE" },
            { text: "ER Admit", value: "ER_ADMIT" },
            { text: "ER Discharge", value: "ER_DISCHARGE" },
            { text: "IP Admit", value: "IP_ADMIT" },
            { text: "IP Discharge", value: "IP_DISCHARGE" },
          ],
          comparison: "eq",
        },
        {
          key: "Days Since Last ADT",
          selected: [{ text:"30", value: 30 }],
          comparison: "gte",
        },
      ],
      exportPatientsUrl: EXPORT_TRANSITIONS_OF_CARE,
      quickListType: "TRANSITIONS_OF_CARE",
      listViewColumns: (auth) => getRaAndHEListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  requiringActions: {
    default: {
      titleProp: "REQUIRING ACTION",
      filters: requiringActionFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: EXPORT_REQUIRING_ACTION,
      quickListType: "REQUIRING_ACTION",
      listViewColumns: (auth) => getRaAndHEListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  awaitingresponse: {
    default: {
      titleProp: "Needs RESPONSE",
      filters: referralAwaitingFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_AWAITING_RESPONSE",
      listViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => getReferralListViewColumns(auth),
      startingView: VIEW.list,
    },
  },
  awaitingcompletion: {
    default: {
      titleProp: "Needs COMPLETION",
      filters: referralAwaitingFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_AWAITING_COMPLETION",
      listViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => getReferralListViewColumns(auth),
      startingView: VIEW.list,
    },
  },
  referralsreceived: {
    default: {
      titleProp: "REFERRALS RECEIVED",
      filters: referralReceivedFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_RECEIVED",
      listViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => getReferralListViewColumns(auth),
      startingView: VIEW.list,
    },
  },
  referralssent: {
    default: {
      titleProp: "REFERRALS SENT",
      filters: referralSentFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_SENT",
      listViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => getReferralListViewColumns(auth),
      startingView: VIEW.list,
    },
  },
  preventiveCare: {
    default: {
      titleProp: "PREVENTIVE CARE",
      filters: preventiveCareFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => {
        let suspectStatus = {
          key: "Suspect Status",
          selected: [
            { text: "Active", value: "Active" },
            { text: "Open", value: "OPEN" },
            {
              text: "Pending Diagnostic Workup",
              value: "PENDING_DIAGNOSTIC_WORKUP",
            },
          ],
          comparison: "eq",
        };
        let measureYear = {
          key: "Measure Year",
          selected: [{ text: new Date().getFullYear().toString(), value: new Date().getFullYear().toString() }],
          comparison: "eq",
        };
        let opportunityStatus = {
          key: "Opportunity Status",
          selected: [
            { text: "Active", value: "Active" },
            { text: "Open", value: "OPEN" },
            { text: "Patient Refused", value: "PATIENT_REFUSAL" },
            { text: "Left Message", value: "LEFT_MESSAGE" },
            { text: "Scheduled Appointment", value: "APPOINTMENT_SCHEDULED" },
            { text: "Changed PCP's", value: "CHANGED_PCPS" },
            { text: "Unable to Contact", value: "UNABLE_TO_CONTACT" },
            { text: "Ordered", value: "ORDERED" }
          ],
          comparison: "eq",
        };
        return [measureYear, suspectStatus, opportunityStatus];
      },
      exportPatientsUrl: EXPORT_PREVENTIVE_CARE,
      quickListType: "PREVENTIVE_CARE",
      listViewColumns: (auth) => {
        let columnValue;
        if (
          auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS") ||
          auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS")
        ) {
          columnValue = getPreventiveListViewColumnsGap(auth);
        }
        if (
          auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS") ||
          auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS")
        ) {
          columnValue = getPreventiveListViewColumnsRaf(auth);
        }
        if (
          (auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS") ||
            auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS")) &&
          (auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS") ||
            auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS"))
        ) {
          columnValue = getPreventiveListViewColumns(auth);
        }
        return columnValue;
      },
      displayExtraRows: true,
    },
  },
  missedOpportunities: {
    default: {
      titleProp: "MISSED OPPORTUNITIES",
      filters: missedOpp,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: EXPORT_MISSED_OPPORTUNITIES,
      quickListType: "MISSED_OPPORTUNITIES",
      listViewColumns: (auth) => {
        let columnValue;
        if (
          auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS") ||
          auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS")
        ) {
          columnValue = getPreventiveListViewColumnsGap(auth);
        }
        if (
          auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS") ||
          auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS")
        ) {
          columnValue = getPreventiveListViewColumnsRaf(auth);
        }
        if (
          (auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_READ_ACCESS") ||
            auth.hasAuthority("AUTH_RISK_ADJUSMENTS_FACTORS_WRITE_ACCESS")) &&
          (auth.hasAuthority("AUTH_CARE_GAPS_READ_ACCESS") ||
            auth.hasAuthority("AUTH_CARE_GAPS_WRITE_ACCESS"))
        ) {
          columnValue = getPreventiveListViewColumns(auth);
        }
        return columnValue;
      },
      displayExtraRows: true,
    },
  },
  customWorklist: {
    default: {
      titleProp: "CUSTOM WORKLIST",
      filters: [
        ...defaultFilters.slice(0, defaultFilters.length - 2),
        "Care Program Step Coordinator",
        "Member Worklist",
        "Last Known Visit",
        "Provider MSA"
      ],
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: EXPORT_ALL_PATIENTS,
      quickListType: "ALL_PATIENTS",
      listViewColumns: (auth) => getDefaultListViewColumns(auth),
    },
  },
  appointments: {
    default: {
      titleProp: "APPOINTMENTS",
      filters: appointmentsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "appointment date",
          selected: [
            { text: "", value: formatDateVanilla(new Date(), "yyyy-MM-dd") },
          ],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_APPOINTMENTS,
      quickListType: "APPOINTMENTS",
      listViewColumns: (auth) => getDefaultListViewColumns(auth),
    },
  },
  chronicConditionsAsthma: {
    default: {
      titleProp: "CHRONIC CONDITIONS: ASTHMA",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Asthma", value: "ASTHMA" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_ASTHMA",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  chronicConditionsDepression: {
    default: {
      titleProp: "CHRONIC CONDITIONS: DEPRESSION",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Depression", value: "DEPRESSION" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_DEPRESSION",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  chronicConditionsDiabetes: {
    default: {
      titleProp: "CHRONIC CONDITIONS: DIABETES",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Diabetes", value: "DIABETES" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_DIABETES",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  chronicConditionsCvd: {
    default: {
      titleProp:
        "CHRONIC CONDITIONS: <span className='text-notransform'>Cardiovascular disease</span>",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Cardiovascular disease", value: "CVD" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_CVD",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  chronicConditionsCkd: {
    default: {
      titleProp:
        "CHRONIC CONDITIONS: <span className='text-notransform'>Chronic kidney disease</span>",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Chronic Kidney Disease", value: "CKD" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_CKD",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  hubCenter: {
    default: {
      titleProp: "Hub Workcenter",
      filters:hubCenterFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      quickListType: "HUB_WORKSPACE",
      listViewColumns: (auth) => getAllPatientsListViewColumns(auth),
    }
  },
  workList: {
    default: {
      titleProp: "Work List",
      filters: ["worklistStatus",
        "Payor",
        "PCP Practice",
        "lostMember",
        "fileinput"
      ],
      pageNumberProp: 1,
      pageSizeProp: 5,
      exportPatientsUrl: EXPORT_WORKLIST,
      defaultFilterParamsProp: (auth) => [
        {
          key: "lostMember",
          selected: [
            {value: "yes", text: "Yes"},
          ],
          comparison: "eq"
        },
        {
          key: "worklistStatus",
          selected: [
            {value: "APPOINTMENT_UNSCHEDULED", text: "Unscheduled"},
            {value: "LEFT_MESSAGE", text: "Left Message"},
          ],
          comparison: "eq"
        },
      ],
      quickListType: "USER_WORK_LIST",
      listViewColumns: (auth) => getAllPatientsListViewColumns(auth),
    }
  },
  schedule: {
    default: {
      titleProp: "Schedule",
      filters: [ "Patient Name",
      "Birth Date",
      "PCP Practice",
      "ScheduleAppt",
      "fileinput"],
      pageNumberProp: 1,
      pageSizeProp: 20,
      exportPatientsUrl: EXPORT_WORKLIST,
       //CHANGES AFTER BACK END SERVICE
      defaultFilterParamsProp: (auth) => [
        {
          key: "schedule",
          selected: [
            { text: "APPOINTMENT_SCHEDULED", value: "APPOINTMENT_SCHEDULED" },
            {text: (new Date(new Date- new Date().getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]),
              value: (new Date(new Date- new Date().getTimezoneOffset()* 60 *1000).toISOString().split('T')[0])},
          ],
          comparison: "eq",
        }
      ],
      //CHANGES AFTER BACK END SERVICE
      quickListType: "SCHEDULE",
      listViewColumns: (auth) => getAllPatientsListViewColumns(auth),
    }
  },
  chronicConditionsCopd: {
    default: {
      titleProp:
        "CHRONIC CONDITIONS: <span className='text-notransform'>Chronic obstructive pulmonary disease</span>",
      filters: chronicConditionsFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Chronic Condition",
          selected: [{ text: "Chronic obstructive pulmonary disease", value: "COPD" }],
          comparison: "eq",
        },
      ],
      exportPatientsUrl: EXPORT_CHRONIC_CONDITIONS,
      quickListType: "CHRONIC_CONDITIONS_COPD",
      listViewColumns: (auth) => getChronicListViewColumns(auth),
      displayExtraRows: true,
    },
  },
  careReferrals: {
    default: {
      titleProp: "CareEmpower Referrals",
      filters: careReferralFilters,
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [
        {
          key: "Referral Type",
          selected: [{ text: "Care Coordination", value: "CARE_COORDINATION" }],
          comparison: "eq",
        },
        {
          key: "RequestSource",
          selected: [{ text: "CARE_EMPOWER_REFERRAL", value: "CARE_EMPOWER_REFERRAL" }],
          comparison: "eq",
        }
      ],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_RECEIVED",
      cardViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => getReferralListNewViewColumns(auth),
      startingView: VIEW.list,
      quickListId: "REFERRALS_CARE_MANAGEMENT",
      titleTextCapitalized: true,
      showCount: true
    },
  },
  referralsList: {
    default: {
      titleProp: "REFERRALS",
      filters: [
        "Risk Level",
        "PCP Practice",
        "Referrals Program Status"
      ],
      pageNumberProp: 1,
      pageSizeProp: 20,
      defaultFilterParamsProp: (auth) => [],
      exportPatientsUrl: null,
      quickListType: "REFERRALS_BY_USER",
      quickListId: "REFERRALS_LIST",
      startingView: VIEW.card,
      cardViewActionObj: {
        fetchData: searchPatientReferrals,
        copyMap: {
          list: "patientCardDtos",
          totalSize: "totalSize",
          pageSize: "pageSize",
          pageNumber: "pageNumber",
        },
      },
      listViewColumns: (auth) => [],
    },
  },
};

const getSettings = (quickListName, clientId) => {
  const defaultSettings = quickListSettings[quickListName].default;
  const clientSettings = quickListSettings[quickListName][clientId];
  const mergedSettings = _.cloneDeep({ ...defaultSettings, ...clientSettings });
  return mergedSettings;
};

const getTransformedSettings = (quickListName, clientId, auth) => {
  const settings = getSettings(quickListName, clientId);
  settings.filters = getFilters(quickListName, settings.filters);
  settings.defaultFilterParamsProp = getDefaultFilterParams(
    settings.defaultFilterParamsProp(auth)
  );
  settings.listViewColumns = settings.listViewColumns(auth);
  return settings;
};

export const getDefaultFilterParams = (defaultFilters) => {
  return defaultFilters.map(
    (x) =>
      new FilterParam(
        x.key,
        x.comparison,
        x.selected,
        (e) => e.value,
        (e) => e.text
      )
  );
};

const getFilters = (quickListName, filterKeys) => {
  const featureEnable = AppPropertiesUtil.get("react.properties.ecip.eligible.enabled");
  if(featureEnable && ["inactivePatients","allPatients","workList", "transitionsOfCare",
   "preventiveCare","missedOpportunities", "adtEvents"].includes(quickListName)){
    filterKeys.push("ecipEligible")
   }
  const filters = [];
  for (const filterKey of filterKeys) {
    const filterDefinition = FILTER_DEFINITIONS_MAP[filterKey];
    if (filterDefinition) {
      filters.push({ ...filterDefinition });
    }
  }
  return filters;
};

const useGetTransQuickListSettings = (
  quickListName,
  inputSourceIndex,
  auth
) => {
  const settingsRef = useRef(null);

  const getSettingsRef = () => {
    if (settingsRef.current === null) {
      settingsRef.current = getTransformedSettings(
        quickListName,
        auth.getInputSources(inputSourceIndex),
        auth
      );
    }
    return settingsRef.current;
  };

  return getSettingsRef;
};

export { getSettings, getTransformedSettings, useGetTransQuickListSettings };
