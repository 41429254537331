import React, { useContext } from 'react'
import { Accordion, AccordionContext, useAccordionButton, Button, Badge, Card, Col, Form, Row } from 'react-bootstrap'
import SuspectsFields from './SuspectsFields';
import GapsFields from './GapsFields';
import AwaitingChartFields from './AwaitingChartFields';
import { formatDate } from "../../../utils/util";

function AccordionItem({
  idx,
  subTitleBuilder,
  componentName,
  item,
  type,
  setErrors
}) {
  function ArrowToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <Button variant="link" className="d-flex align-items-center justify-content-between text-dark text-decoration-none w-100" onClick={decoratedOnClick}>
        <div className="d-flex align-items-center gap-3">
          {!isCurrentEventKey
            ? <i className='fa fa-chevron-right' aria-hidden='true'></i>
            : <i className='fa fa-chevron-down' aria-hidden='true'></i>
          }
          <h5 id={`${componentName}-tlTitle-${idx }`} className="d-flex justify-content-start align-items-center gap-2 m-0">
            <i className={`text-danger fa-regular fa-circle-exclamation ${item?.validation === "REJECTED" && item?.status?.toUpperCase() === "OPEN" ? "" : "d-none"}`}></i>
            <span className="text-start">{subTitleBuilder && subTitleBuilder(item, idx)}</span>
          </h5>
        </div>
        {children}
      </Button>
    );
  }

  return (
    <Accordion eventKey={idx + 1}>
      <Card.Header eventKey={idx + 1}>
        <ArrowToggle componentName={componentName} eventKey={idx + 1} idx={idx + 1}>
          {subTitleBuilder && <Badge id={`${componentName}-itemStatus-${idx}`} pill bg="success">{item?.status}{type === 'AWAITING_CHART' ? ": Awaiting Chart" : ""}</Badge>}
        </ArrowToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={idx + 1}>
        <Col>
          {item?.visitName &&
          <Row className="m-1 p-3">
            <div className="d-block">
              <Col className='d-flex justify-content-start'>
                <span id={`${componentName}-visitLabel-${idx}`} className='me-5 fw-bold'>VISIT</span> <span id={`${componentName}-visitName-${idx}`} className='text-primary fw-bold'>{item?.visitName}</span>
              </Col>
            </div>
            <div className="d-block">
              <Col className='d-flex justify-content-start'>
                <span id={`${componentName}-dosLabel-${idx}`} className='me-5 fw-bold'>Date of Service</span> <span id={`${componentName}-dateOfService-${idx}`} className='text-primary fw-bold'>{item?.dateOfService ? formatDate(item?.dateOfService) : 'No date available'}</span>
              </Col>
            </div>
            <div className="d-block">
              <Col className='d-flex justify-content-start'>
                <span id={`${componentName}-practiceLabel-${idx}`} className='me-5 fw-bold'>Rendering Practice</span> <span id={`${componentName}-practiceName-${idx}`} className='text-primary fw-bold'>{item?.practiceName}</span>
              </Col>
            </div>
          </Row>
          }
          <Row className="m-1 p-3">
            <Col>
              <Form.Group id={`${componentName}-DOS`}>
                {type === 'SUSPECTS'
                    ? <SuspectsFields item={item} setErrors={setErrors} />
                  : type === 'GAPS'
                    ? <GapsFields item={item} setErrors={setErrors} />
                  : type === 'AWAITING_CHART'
                    ? <AwaitingChartFields item={item} setErrors={setErrors} />
                  : null
                }
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default AccordionItem