import React, { useState, useEffect } from 'react'
import { Modal, Col, Row, Button, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';
import { useAuth } from '../../../../../../context/authContext';
import { viewAsNonMembersTeam, isTeamInViewAsNonMembersList } from '../../../constants'
import {
  getDefaultActiveRoleObject,
  getQuickListNameByRole,
  mappingCareTeamConfiguredRoles
} from '../../../utils';
const componentName = 'ViewAs';

function ViewAs() {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [selectedTeamMember, setSelectedTeamMember] = useState();

  const {
    isViewAsEnabled,
    toggleViewAs,
    teamsCapacity,
    getTeamsCapacity,
    setActiveRole,
    patientSearch,
    setPatientSearch,
    adminRolesHubWL,
    careManagementTeams,
    setPage,
    getPage
  } = useCMWorklistContext();

  const auth = useAuth();
  const viewAsTeamsCapacity = [...teamsCapacity, ...viewAsNonMembersTeam];

  useEffect(() => {
    if (isViewAsEnabled === true) {
      getTeamsCapacity();
    }
  }, [isViewAsEnabled])

  const closeModal = () => {
    setSelectedTeamId("");
    setSelectedTeam(null);
    setSelectedTeamMember(null);
    toggleViewAs(false);
  }

  const selectTeam = (event) => {
    const teamId = event.target.value;
    const team = viewAsTeamsCapacity.filter(team => team.teamId === teamId)?.shift();

    setSelectedTeam(team);
    setSelectedTeamId(teamId);
    handleNonMembersTeam(team);
  }

  const selectTeamMember = (event) => {
    const teamMemberId = event.target.value;
    const teamMember = selectedTeam?.programLeads?.filter(programLead => programLead.programLeadId === teamMemberId)?.shift();
    setSelectedTeamMember(teamMember);
  }

  const shouldDisplayTeamMemberSelector = () => {
    return !isTeamInViewAsNonMembersList(selectedTeam?.teamName);
  }

  const handleNonMembersTeam = (selectedTeam) => {
    let teamMember = null
    const teamName = selectedTeam?.teamName;

    if (isTeamInViewAsNonMembersList(teamName)) {
      teamMember = {
        programLeadUserAccountId: auth?.getUserId(),
        programLeadName: "",
        teamName: teamName
      }
    }

    setSelectedTeamMember(teamMember);
  };

  return (
    <Modal
      id={`${componentName}-viewAsModal`}
      size="md"
      show={isViewAsEnabled}
      onHide={() => closeModal()}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-viewAsModalTitle`}>View As</Modal.Title>
        <button
          id={`${componentName}-viewAsModalTitleConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        />
      </Modal.Header>
      <Modal.Body className="overflow-y-auto" style={{ "height": "35rem" }} >
        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Group id={`${componentName}-team`}>
                <Form.Label id={`${componentName}-teamLabel`}>
                  Team
                </Form.Label>
                <select id={`${componentName}-teamOptions`}
                  className="form-select"
                  onChange={event => selectTeam(event)}
                  value={selectedTeamId}
                >
                  <option id="teamOption-select" value="">Select</option>
                  {viewAsTeamsCapacity.map((data, i) => (
                    <option
                      id={`teamOption${i}`}
                      key={`teamOption${i}`}
                      value={data.teamId}>{data.teamName}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {(selectedTeam?.programLeads?.length > 0 && shouldDisplayTeamMemberSelector()) &&
          <Row>
            <Col>
              <Row className="fw-bold">
                <Col>
                  <span>Team Member</span>
                </Col>
              </Row>
              <Row>
                <ToggleButtonGroup type="radio" className="careTeamCapacity d-flex flex-column"
                  name="careTeamCapacity" value={selectedTeamMember?.programLeadId}>
                  {selectedTeam.programLeads.map((programLead, index) => (
                    <ToggleButton
                      id={`tbg-radio-${programLead?.programLeadId}-${index}-${programLead.market}`}
                      key={`tbg-radio-${programLead?.programLeadId}-${index}-${programLead.market}`}
                      variant="team-member"
                      className={`border p-3 text-dark rounded-3 mb-2 ${selectedTeamMember?.programLeadId == programLead?.programLeadId ? 'active' : ''}`}
                      value={programLead.programLeadId}
                      onChange={(event) => selectTeamMember(event)}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column text-start w-100">
                          <span className="text-dark">{programLead.programLeadName}</span>
                          {(selectedTeam.teamName === 'Community Health Workers' && programLead.zone) ? (
                            <small
                              className="text-muted text-truncate">{programLead.zone}</small>
                          ) : (
                            <small className="text-muted">{programLead.market}</small>
                          )
                          }
                        </div>
                      </div>
                    </ToggleButton>
                  ))
                  }
                </ToggleButtonGroup>
              </Row>
            </Col>
          </Row>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="px-4"
          variant="secondary"
          id={`${componentName}-viewAsModalClose`}
          onClick={() => closeModal()}
          size="sm"
        >
          Cancel
        </Button>
        <Button
          className="px-4"
          variant="secondary"
          id={`${componentName}-viewAsModalDefaultView`}
          size="sm"
          onClick={() => {
            closeModal();
            setActiveRole(
              getDefaultActiveRoleObject(
                auth.getRoles()[0],
                Object.values(auth.getTeam())[0],
                adminRolesHubWL,
                careManagementTeams,
                false
              )
            );
          }}
        >
          Default View
        </Button>
        <Button
          className="px-4"
          variant="primary"
          id={`${componentName}-viewAsModalSubmit`}
          disabled={!selectedTeamMember}
          size="sm"
          onClick={() => {
            const selectedTeamMemberRole = {
              leadId: selectedTeamMember.programLeadUserAccountId,
              leadName: selectedTeamMember.programLeadName,
              teamName: selectedTeam.teamName,
              role: mappingCareTeamConfiguredRoles(selectedTeam.teamName)
            }
            closeModal();
            setActiveRole(selectedTeamMemberRole);
            setPage(1)
            setPatientSearch({
              ...patientSearch,
              pageNumber: 1,
              filterParams: [],
              quickListName: getQuickListNameByRole(selectedTeamMemberRole.role)
            })
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ViewAs;
