import React, { useEffect, useState } from "react";
import axios from "../../../utils/apiClient";
import { useAuth } from "../../../context/authContext";

import Select from 'react-select';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { isValidEmail, isValidPhoneNumber } from "../../../utils/util";

const componentName = "Feedback";

const FeedbackInfo = ({ setFeedbackModalProp }) => {
  const auth = useAuth();
  const isEmpty = require("is-empty");

  const [organizations, setOrganizations] = useState([""]);

  useEffect(() => {
    async function getData() {
      const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/feedback/";
      let filters = [];
      filters.push({
        fieldName: "email",
        value: auth.getEmail(),
        comparisonMode: "EQUAL_TO",
      });
      const prms = { page: 1, pageSize: 10, filters: filters };
      const options = await axios
        .post(URL + "user/search", prms, {
          headers: {
            "Content-Type": "application/json"
          },
        })
      let organizationsData = options?.data?.content?.map((x) => x.organization.name);
      let finalData = []
      organizationsData.forEach(m => {
        finalData.push({ "label": m, "value": m })
      })

      setOrganizations(finalData);
      setPayloadData({
        ...payloadData, "practice_name_feedback": finalData[0].value
      });
    }
    getData();
  }, [])
  const [payloadData, setPayloadData] = useState({

    name: "",

    description: "",

    created_by: auth.getEmail(),

    product_id: "CE",

    your_name: "",

    phone_number: "",

    practice_name_feedback: "",

    net_promoter_score: "",
  })
  const handleChangeFeedback = (e, txt) => {
    setPayloadData({
      ...payloadData,
      [txt]: e
    });
  };

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e);
    setPayloadData({
      ...payloadData,
      "phone_number": formattedPhoneNumber
    });
  }

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const disableButton = () => {
    if (payloadData && payloadData.name && payloadData.description && payloadData.your_name
      && payloadData.practice_name_feedback && payloadData.net_promoter_score && payloadData.phone_number && payloadData.phone_number.length == 14 && isValidEmail(payloadData.created_by)) {
      return false
    }
    return true
  }

  const onSubmitFeedBack = () => {
    const finalPayload = {
      "name": payloadData.name,
      "description": payloadData.description,
      "created_by": payloadData.created_by,
      "product_id": payloadData.product_id,
      "custom_fields": {
        "your_name": payloadData.your_name,
        "phone_number": payloadData.phone_number,
        "net_promoter_score": payloadData.net_promoter_score,
        "practice_name_feedback": payloadData.practice_name_feedback
      }
    }
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/feedback/create`, { "idea": finalPayload },
        {
          headers: {},
        }
      )
      .then(async (res) => {
        setFeedbackModalProp(false)
      })
      .catch((error) => {

      });
  };

  let getSelectedOrganization = (user, organizations) => {
    if (user.organization != null) {
      var selected = null;
      for (var i = 0; i < organizations.length; i++) {
        if (organizations[i].id == user.organization.id) {
          selected = organizations[i];
          break;
        }
      }

      if (selected != null) {
        var out = [];
        out.push(selected);
        return out;
      }
    }

    return [];
  };
  return (
    <>
      <Container>
        <Row id={`${componentName}-feadbackIdea`} className="mb-3">
          <Col>
            <label id={`${componentName}-feedbackIdeaLabel`} className="d-block">Your Feedback or Idea<span className="text-danger">*</span></label>
            <Form.Control id={`${componentName}-feedbackIdeaValue`} type="text"
              onChange={(e) => {
                handleChangeFeedback(e.target.value, "name");
              }} />
          </Col>
        </Row>
        <Row id={`${componentName}-feedbackDetails`} className="mb-3">
          <Col>
            <label id={`${componentName}-feedbackDetailsLabel`} className="d-block">Please Add More Details<span className="text-danger">*</span></label>
            <Form.Control id={`${componentName}-feedbackDetailsValue`} as="textarea" rows={3}
              onChange={(e) => {
                handleChangeFeedback(e.target.value, "description");
              }} />
            <small>*Please do not include any PHI information</small>
          </Col>
        </Row>
        <Row id={`${componentName}-feedbackName`} className="mb-3">
          <Col>
            <label id={`${componentName}-feedbackNameLabel`} className="d-block">What's Your Name?<span className="text-danger">*</span></label>
            <Form.Control id={`${componentName}-feedbackNameValue`} type="text"
              onChange={(e) => {
                handleChangeFeedback(e.target.value, "your_name");
              }} />
          </Col>
        </Row>
        <Row id={`${componentName}-feedbackPracticeName`} className="mb-3">
          <Form.Group>
            <label id={`${componentName}-feedbackPracticeNameLabel`} className="d-block">Organization Name<span className="text-danger">*</span></label>
            <Select
              id={`${componentName}-organization`}
              options={organizations}
              value={{ value: organizations[0].value, label: organizations[0].label }}
              onChange={(e) => handleChangeFeedback(e.value, "practice_name_feedback")}
            />
          </Form.Group>
        </Row>
        <Row id={`${componentName}-feedbackPracticePhone`} className="mb-3">
          <Col>
            <label id={`${componentName}-feedbackPracticePhoneLabel`} className="d-block">Practice Phone Number<span className="text-danger">*</span></label>
            <Form.Control id={`${componentName}-feedbackPracticePhoneValue`} type="text"
              value={payloadData.phone_number}
              onChange={(e) => {
                handlePhoneInput(e.target.value);
              }} />

          </Col>
        </Row>
        <Row id={`${componentName}-feedbackemail`} className="mb-3">
          <Col>
            <label id={`${componentName}-feedbackemail`} className="d-block">Confirm Email<span className="text-danger">*</span></label>
            <Form.Control id={`${componentName}-feedbackemail`} type="text"
              value={payloadData.created_by}
              onChange={(e) => {
                handleChangeFeedback(e.target.value, "created_by");
              }} />
            {!isEmpty(payloadData.created_by) && !isValidEmail(payloadData.created_by) && <span className="text-danger">Invalid format of email. </span>}
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <label id={`${componentName}-feedbackRecommendLabel`} className="d-block">How likely are you to recommend CareEmpower to colleagues?<span className="text-danger">*</span></label>
            <Form>
              <div key={`${componentName}-feedbackRecommendRadio`}>
                <Form.Check inline label="1" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-1`} onChange={() => handleChangeFeedback('1', 'net_promoter_score')} />
                <Form.Check inline label="2" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-2`} onChange={() => handleChangeFeedback('2', 'net_promoter_score')} />
                <Form.Check inline label="3" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-3`} onChange={() => handleChangeFeedback('3', 'net_promoter_score')} />
                <Form.Check inline label="4" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-4`} onChange={() => handleChangeFeedback('4', 'net_promoter_score')} />
                <Form.Check inline label="5" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-5`} onChange={() => handleChangeFeedback('5', 'net_promoter_score')} />
                <Form.Check inline label="6" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-6`} onChange={() => handleChangeFeedback('6', 'net_promoter_score')} />
                <Form.Check inline label="7" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-7`} onChange={() => handleChangeFeedback('7', 'net_promoter_score')} />
                <Form.Check inline label="8" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-8`} onChange={() => handleChangeFeedback('8', 'net_promoter_score')} />
                <Form.Check inline label="9" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-9`} onChange={() => handleChangeFeedback('9', 'net_promoter_score')} />
                <Form.Check inline label="10" name={`${componentName}-feedbackRecommendRadio`} type="radio" id={`${componentName}-recommend-10`} onChange={() => handleChangeFeedback('10', 'net_promoter_score')} />
              </div>
            </Form>
            <small className="mb-3">1 = Not likely, 10 = Very likely</small>
          </Col>
        </Row>
        <Row className="mb-3 text-end">
          <Col>
            <Button disabled={disableButton()} id={`${componentName}-feedbackSubmitValue`} variant="primary" onClick={() => onSubmitFeedBack()} className="me-0">Submit</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FeedbackInfo;
