import React, { useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import {isEmpty, trim} from 'lodash'
import { Container, Col, Form, Row, Spinner, Button, Badge } from 'react-bootstrap';
import { PatientSearchDTO } from "../../../../dtos/PatientSearchDTO";
import CustomPagination from "../../../common/CustomPaginaton";
import { FULL_NAME_COLUMN, getColumnByName, SOURCE_COLUMN, STATUS_COLUMN, ZONE_COLUMN } from "./CMWorklistModals/worklistColumns";
import GeneralActions from "./GeneralActions";
import PatientDataCard from "./PatientDataCard";
import { FilterGroupNew } from "../../../../components/filters/filterGroupNew";
import { getFilterDef } from "../../../../components/filters/filterDefinition";
import { getFilters, sortItems, getActions, alternativeFilters } from "../constants";
import { useCMWorklistContext } from "./CMWorklistModals/context/CMWorklist.context";
import SortMenu from "./SortMenu";
import {getQuickListId} from "../../../../utils/util";
import { SelectAll } from "./SelectAll";
import { useAuth } from "../../../../context/authContext";
import { buildCompositeKey, areCompositeKeysEquals } from "../CompositeKeyHelper";
import { useDispatch, useSelector } from "react-redux";
import { setReloadWorklist } from "../../../../actions/manageVisitActions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PatientListOffCanvas from "../../PatientListOffCanvas";
import BulkActions from "./CMWorklistModals/BulkActions";
import {useCustomWorklistContext} from "../../../../components/CustomWorklist";
import {mapFiltersToSearch, mapFiltersSelected} from "./FiltersMapper";
import SnoozedFilters from "./CMWorklistModals/SnoozedFilters";
import { getDefaultActiveRoleObject, isRoleInAdminHubCoordinator, isRoleInCMTeam } from "../utils";
import { Helmet } from "react-helmet";

const componentName = 'CMWorklist'
const columns = [FULL_NAME_COLUMN, SOURCE_COLUMN, STATUS_COLUMN, ZONE_COLUMN]

const SOURCE = {
  FILTER: 'FILTER'
}

const Main = () => {
  const isEmpty = require("is-empty")
  const auth = useAuth()
  const dispatch = useDispatch()
  const location = useLocation()
  const ref = useRef()

  const [filterParams, setFilterParams] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [sortParams, setSortParams] = useState([])
  const [quickListId, setQuickListId] = useState(null)
  const [teams, setTeams] = useState([])
  const [stuck, setStuck] = useState(false)
  const [snoozedFilterParam, setSnoozedFilterParam] = useState([])
  // by keeping the last valid active role, we ensure that resolveWorklistCall is called once
  const [lastValidActiveRole, setLastValidActiveRole] = useState(null)

  const quickListName = "CM_WORKLIST_HUB_COORDINATOR";
  const pageSize = 20;

  const reloadWorklist = useSelector((state) => state?.manageVisitReducer?.reloadWorklist);

  const {
    patientsList,
    isLoading,
    totalSize,
    setPatientSearch,
    getDeclineReasons,
    selectPatientEventAction,
    selectedPatientsEvents,
    patientSearch,
    worklistDefaultView,
    getWorklistDefaultView,
    customFilters,
    toggleViewAs,
    toggleMarkAsOutreached,
    setPatientsEventsToBeMarkedAsOutreached,
    getCampaigns,
    activeRole,
    setActiveRole,
    sendEvent,
    getAdminRolesHubWL,
    adminRolesHubWL,
    getCareManagementTeams,
    careManagementTeams,
    getPage,
    setPage
  } = useCMWorklistContext();

  const {
    getCustomWorklist,
    patientSearchCustomWorklist
  } = useCustomWorklistContext();

  const { role, leadName, leadId, teamName } = activeRole;
  let filters = getFilters(role);
  const { showMarkAsOutreached } = getActions(role);

  const resolveWorklistCall = () => {
    if (leadId && getQuickListId() && activeRole && quickListId) {
      document.getElementById('Navigation-cmWorklist').click()
      getCustomWorklist(quickListId, activeRole)
      setPage(1)
    } else if (!leadId && getQuickListId() && quickListId) {
      getCustomWorklist(quickListId, activeRole)
      setPage(1)
    } else if(role && (!getQuickListId() || !quickListId)) {
      getWorklistDefaultView();
    }
  }

  const setDefaultActiveRole = (isCustom = false) => {
    setActiveRole(
      getDefaultActiveRoleObject(
        auth.getRoles()[0],
        Object.values(auth.getTeam() || {})[0],
        adminRolesHubWL,
        careManagementTeams,
        isCustom
      )
    );
  }

  const refreshPatientList = (pageNumber, filters = patientSearch?.filterParams || filterParams, sorts, reload, source) => {
    const newFilters = mapFiltersToSearch(filters ?? []);
    let currentFilters = newFilters.length === 0 ? patientSearch?.filterParams : newFilters

    if (source === SOURCE.FILTER && newFilters.length === 0) {
      currentFilters = [];
    }

    let newPatientSearch = new PatientSearchDTO(currentFilters, sorts || sortParams, pageNumber, pageSize, quickListName);
    newPatientSearch.reload = reload
    setPatientSearch(newPatientSearch)
    setPageNumber(pageNumber)
    setPage(pageNumber)
  }

  useEffect(() => {
    getCareManagementTeams();
    getAdminRolesHubWL();
  }, [])

  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => {
        setStuck(e.intersectionRatio < 1 && e.intersectionRatio !== 0)
      },
      { threshold: [1] }
    )
    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref])

  useLayoutEffect(() => {
    if(careManagementTeams.length !== 0 && adminRolesHubWL !== 0) {
      getDeclineReasons();
      getCampaigns();
      setDefaultActiveRole();
      resolveWorklistCall();
    }
  }, [careManagementTeams, adminRolesHubWL])

  useLayoutEffect(() => {
    setQuickListId(getQuickListId())
  }, [location])

  useEffect(() => {
    resolveWorklistCall();
  }, [quickListId])

  useEffect(() => {
    if (activeRole?.role) {
      sendEvent('LANDS_WORKLIST');
    }

    resolveWorklistCall();
  }, [activeRole])

  useEffect(() => {
    if (reloadWorklist) {
      const snoozedFilter = filterParams.findIndex(e => e.paramName === 'stepState')
      let newFilters = [...filterParams]
      if (snoozedFilterParam[0]?.paramName === 'stepState' && snoozedFilter >= 0) {
        newFilters[snoozedFilter] = snoozedFilterParam[0]
      }
      refreshPatientList(pageNumber, newFilters, null, !patientSearch.reload)
      dispatch(setReloadWorklist(false));
    }
  }, [reloadWorklist])

  useEffect(() => {
    if (patientSearchCustomWorklist && patientSearchCustomWorklist.type) {
      setPatientSearch(patientSearchCustomWorklist);
    }
  }, [patientSearchCustomWorklist])

  useEffect(() => {
    const invert = Object.fromEntries(Object.entries(alternativeFilters).map(([k, v]) => [v, k]));
    if (filterParams.length === 0 && quickListId) {
      const newFilters = customFilters.map(f =>
        invert[f.paramName]
         ? { ...f, paramName: invert[f.paramName]}
         : f
      )
      setFilterParams(newFilters)
    }
  }, [customFilters])

  useEffect(() => {
    if (!quickListId && ((worklistDefaultView && !isEmpty(worklistDefaultView.quickList)
        && !isEmpty(worklistDefaultView.quickList) && !isEmpty(
            worklistDefaultView.patientRequestDTO)))) {
      setFilterParams(worklistDefaultView.patientRequestDTO.filterParams);

      const patientSearch = new PatientSearchDTO(
          worklistDefaultView.patientRequestDTO.filterParams,
          worklistDefaultView.patientRequestDTO.sortParams || [],
          1,
          20,
          worklistDefaultView.quickList
      );
      setPatientSearch(patientSearch)

    } else if (!getQuickListId() && worklistDefaultView === null) {
       refreshPatientList(pageNumber);
    }
  }, [worklistDefaultView])

  const handlePaginationChange = (pageNumber) => {
    setPageNumber(pageNumber);
    setPage(pageNumber)
    refreshPatientList(pageNumber, null, null, true)
    selectPatientEventAction([])
  };

  const isChecked = (patientEvent) => {
    let compositeKey = buildCompositeKey(patientEvent);
    if (compositeKey) {
      return selectedPatientsEvents.findIndex(
          (existingCompositeKey => areCompositeKeysEquals(existingCompositeKey,
              compositeKey))) >= 0
    }
    return false;
  }

  const onCheckedPatientEventHandler = (patientEvent) => {
    let compositeKey = buildCompositeKey(patientEvent);
    if (compositeKey) {
      selectPatientEventAction({ ...compositeKey, data: patientEvent});
    }
  }

  const renderColumnByName = (columnName, patient, idx) => {
    const WorklistColumn = getColumnByName(columnName)
    if (WorklistColumn) {
      return <WorklistColumn
        patient={patient}
        idx={idx}
        id={`${componentName}-${columnName}-${idx}`}
        key={`${componentName}-${columnName}-key-${idx}`}
      />
    }
    return null;
  }

  const getPatientsToBeMarkedAsOutreached = () => {
    return selectedPatientsEvents.map(item => {
      let patientEvent = {patientId: item.patientId};
      if (item.source === 'REFERRAL') {
        patientEvent.referralId = item.eventId;
      } else if (item.source === 'ADT') {
        patientEvent.adtId = item.eventId;
      }
      return patientEvent;
    });
  }

  const showBulkActions = () => {
    return (activeRole?.role === 'Hub Coordinator' ||
      isRoleInAdminHubCoordinator(activeRole?.role, adminRolesHubWL) ||
      activeRole?.role === 'Clinical Coordinators')
  }

  const showSnoozedFilter = () => {
    return isRoleInCMTeam(activeRole?.role, careManagementTeams);
  }

  const isHospitalLiaisons = auth.getRoles()[0] === 'Hospital Liaisons';
  const isCareSpecialist = auth.getRoles()[0] === 'Care Specialist';
  const isCHW = auth.getRoles()[0] === 'Community Health Worker';
  const isChaplain = auth.getRoles()[0] === 'Chaplain';

  const showViewAsButton = (
    auth.isAdmin() ||
    isRoleInAdminHubCoordinator(auth.getRoles()[0], adminRolesHubWL) ||
    isRoleInCMTeam(Object.values(auth.getTeam() || {})[0], careManagementTeams)
  );

  const FiltersView = useMemo( () =>  <FilterGroupNew
    filters={filters.map(f => getFilterDef(f))}
    handleChange={params => {
      let filterParams = params;
      if(showSnoozedFilter()) {
        filterParams = [...params.filter(f => f.paramName !== 'stepState'), ...snoozedFilterParam];
      }
      refreshPatientList(1, filterParams, undefined, undefined, SOURCE.FILTER)
      setFilterParams(filterParams)
    }}
    defaultFilterParams={[]}
    filterParams={mapFiltersSelected(patientSearch?.filterParams ?? []) || []}
    customWorklistName={''}
  />, [patientSearch, filters] );

  return (
    <Container fluid id={`${componentName}-view`}>
      <Helmet>
        <title>My Members | CareEmpower</title>
      </Helmet>
      <PatientListOffCanvas
        teams={teams}
        setTeams={setTeams}
        onError={() => {}}
        top= {stuck ? '115px' : '199px'}
      />
      <Row id={`${componentName}-header`} ref={ref} className="py-3 sticky-top bg-content">
        <Col>
          <Row>
            <Col>
              {FiltersView}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start align-items-center">
              <SelectAll />
              {isLoading
                ? <Spinner
                  className="align-middle ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : <Badge pill bg="light" text="dark" id={`${componentName}-patientCount`} className="border ms-2">
                  {totalSize} {"Patients"}
                </Badge>
              }
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {showViewAsButton &&
                <>
                  {leadId &&
                    <Badge id={`${componentName}-activeViewAsUser`} className="btn-filter border cursor-p me-2 py-2">
                      <span className="me-2 text-truncate">{leadName && `${leadName}: `}{teamName}</span>
                      <i className="fa-sharp fa-solid fa-xmark"
                        onClick={() => {
                          setDefaultActiveRole(false);
                          setPage(1);
                          setPageNumber(1);
                          setPatientSearch({
                            ...patientSearch,
                            pageNumber: 1,
                          })
                        }}>
                      </i>
                    </Badge>
                  }

                  <Button
                    id={`${componentName}-view-as`}
                    variant="primary"
                    className="me-2 px-2"
                    onClick={() => {
                      sendEvent('CLICK_VIEW_AS')
                      toggleViewAs(true)
                    }}
                  >
                    <i className="fal fa-user-friends"></i> View As
                  </Button>
                </>
              }
              {showMarkAsOutreached &&
                <Button id={`${componentName}-submit`} variant="primary" className="me-2"
                  disabled={selectedPatientsEvents.length === 0}
                  aria-disabled={true}
                  onClick={() => {
                    sendEvent('SELECT_BULK_MARK');
                    toggleMarkAsOutreached(true);
                    setPatientsEventsToBeMarkedAsOutreached(getPatientsToBeMarkedAsOutreached());
                  }}>
                  Mark as Outreached
                </Button>
              }

              {(!isHospitalLiaisons && !isCareSpecialist && !isCHW && !isChaplain) &&
                <Button
                  variant="primary"
                  className="me-2"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTeamList"
                  aria-controls="offcanvasTeamList">
                    <i className="fa fa-users me-2"></i>
                    Team List
                </Button>
              }

              {showBulkActions() &&
                <BulkActions />
              }
              <SortMenu
                  items={sortItems(role, adminRolesHubWL).filter(i => i.condition)}
                  filterParams={patientSearch?.filterParams || filterParams}
                  sortParams={sortParams}
                  setSortParams={setSortParams}
                  refreshPatientList={refreshPatientList}
                  snoozedFilterParam={snoozedFilterParam}
              />
              <CustomPagination
                paginateButClicked={handlePaginationChange}
                MAX_PAGE={Math.ceil(totalSize / pageSize)}
                pageNumber={Math.ceil(getPage)}
              />
              <GeneralActions id={`${componentName}-menuActions`} key={`${componentName}-menuActions-key`} />
            </Col>
          </Row>
          {showSnoozedFilter() &&
            <Row style={{ maxWidth: "fit-content" }}>
              <SnoozedFilters
                refreshPatientList={refreshPatientList}
                filterParams={filterParams}
                setSnoozedFilterParam={setSnoozedFilterParam}
              />
            </Row>
          }
        </Col>
      </Row>
      <Row id={`${componentName}-body`} className="align-items-center">
        <Col>
          {isLoading
            ?
              <Spinner
                className="align-middle mb-4"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            : patientsList?.length > 0 ? patientsList?.map((patientEvent, idx) =>
              <PatientDataCard
                idx={idx}
                id={`${componentName}-patientCard-${idx}`}
                key={`${componentName}-patientCard-key-${idx}`}
              >
                <Col id={`${componentName}-${idx}`} className="col-md-auto px-4">
                  <Row>
                    <Form.Check
                      type="checkbox"
                      id={`${componentName}-createCarePathCheckbox`}
                      onChange={() => onCheckedPatientEventHandler(patientEvent)}
                      checked={isChecked(patientEvent)}
                    />
                  </Row>
                </Col>
                {columns.map(columnName => renderColumnByName(columnName, patientEvent, idx))}
              </PatientDataCard>
            ) : <PatientDataCard>No Patients Found</PatientDataCard>
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
