import {
  LOAD_CUSTOM_FILTERED_LIST,
  UPDATE_CUSTOM_LISTS,
} from "../../actions/types";

import {
  NAVIGATION_ITEMS,
  getCustomFilteredListObject,
} from "../../utils/navigationItems";

const initialState = [];

const updateCustomFilteredList = (state, payload) => {
  const newState = Object.assign([], state);
  let customFilteredList = getCustomFilteredListObject(newState);
  customFilteredList.items = payload;

  return newState;
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_CUSTOM_FILTERED_LIST:
      return payload;
    case UPDATE_CUSTOM_LISTS:
      return state.filter((x) => x.to !== payload);
    default:
      return state;
  }
}
