import React, {useRef} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import styled from "styled-components";
import isEmpty from "is-empty";

const componentName = "AsyncSearch";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const AsyncSearch = ({
 id,
 title,
 labelKey,
 isLoading,
 onSearch,
 options,
 onChange,
 selected,
 index,
 disabled = false,
 minLength = 2,
}) => {
    const asyncTypeAheadRef = useRef(null);

    const handleChange = (item) => {
        onChange(item, index);
        if (!isEmpty(item)) {
            asyncTypeAheadRef.current.blur();
        }
    }

    return (<>
        <label id={`${componentName}-${id}Label`} className="d-block">
            {title}
        </label>
        <StyledTypeAhead
            filterBy={() => true}
            inputProps={{
                id: `${componentName}-${id}`
            }}
            labelKey={labelKey}
            isLoading={isLoading}
            minLength={minLength}
            onSearch={(e) => onSearch(e, index)}
            options={options}
            selected={selected}
            onChange={handleChange}
            disabled={disabled}
            ref={asyncTypeAheadRef}
        />
    </>)
}

export default AsyncSearch;