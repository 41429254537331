import axios from "../../../../utils/apiClient";

const URL = process.env.REACT_APP_REST_API_BASE_URL;
export const SUMMARY_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/patient-card/export-summary";


const getSummary = async () => {
  let data
  await axios.get(SUMMARY_URL, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => data = res)
  return data
}

export const exportToCSV = async (criteria) => {
  let data
  await axios.post(`${URL}/v1/export-patients/referrals-received`, criteria, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async() => data = await getSummary())
  return data
}