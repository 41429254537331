import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Badge, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Table } from "../../components/tables";
import { SortableTh } from "../../components/SortableTh";
import styled from "styled-components";
import {
  formatDate,
  getPatientProfileLink,
  _calculateAge,
  isAnyTOCCareGapCreatedWithinADay,
} from "../../utils/util";
import { RETURN_TO_MY_PATIENTS_URL } from "./QuickList";
import { ReferralModal } from "./ReferraModal";
import Modals from "../../components/modals";

import {
  referral_types,
  status_referrals,
  urgency_referrals,
} from "../../utils/textValueLists";
import { getDisplayValue, getRiskLevelStyle } from "../../utils/util";
import { Button } from "../../components/buttons";
import ManageEvidence from "./ManageEvidence";
import { useLocation } from "react-router";
import { setManageVisitOpenedFromPageTitle, setManageVisitPatient } from "../../actions/manageVisitActions";

const componentName = "PatientListView";

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
`;

const ToolTipText = styled.p`
  padding-left: 3em;
  padding-right: 1em;
  text-indent: -3em;
  text-align: left;
  max-width: 100%;
  white-space: nowrap;
  margin: 0;
  background-color: black;
  color: #fff;
`;
const StyledLabel = styled.label`
  color: ${(props) =>
    props.scraScore >= 28
      ? `#DD6161`
      : props.scraScore >= 20
      ? `#FDCA6C`
      : props.scraScore >= 0
      ? `#3DCA37`
      : `#3DCA37`};
  font-weight: bold;
`;
const StatusLabel = styled.label`
  color: ${(props) =>
    props.status == "60_DECLINED"
      ? `#DD6161`
      : props.status == "50_PENDING"
      ? `#FDCA6C`
      : props.status == "40_ACCEPTED"
      ? `#4A90E2`
      : props.status == "30_COMPLETED"
      ? `#3DCA37`
      : props.status == "20_PATIENT_CANCELLED"
      ? `#EE7600`
      : props.status == "10_CANCELLED"
      ? `#EE7600`
      : `#8955E7`};
  font-weight: bold;
`;
const UrgencyLabel = styled.label`
  color: ${(props) =>
    props.urgency == "10_ROUTINE"
      ? `#3DCA37`
      : props.urgency == "20_PRIORITY"
      ? `#4A90E2`
      : props.urgency == "30_URGENT"
      ? `#FDCA6C`
      : `#DD6161`};
  font-weight: bold;
`;

const PatientListView = ({
  patientList,
  sortCol,
  handleChangeCheckBox,
  selectOrUnSelectAll,
  isPatientSelected,
  quickListType,
  displayExtraRows,
  columnList,
  updateParent,
  closeEvidenceModal,
  reloadPatientView = () => {},
  onShowAssignCareManagerModal,
  careManagementActions,
  allPatientsChecked,
  setSelectedName
}) => {
  const isEmpty = require("is-empty");
  const location = useLocation();
  const [sortOrder, setSortOrder] = useState({});
  const [manageEvidencePatient, setManageEvidencePatient] = useState({});

  const [priority, setPriority] = useState(1);
  const [sortColArray, setSortColArray] = useState([]);
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [activeKey, setActiveKey] = useState("-1");
  const dispatch = useDispatch();

  const handleModalClose = () => {
    if (changesMade) {
      setConfirmationMessage(true);
    } else {
      closeEvidenceModalMain();
    }
  };

  const closeEvidenceModalMain = () => {
    setConfirmationMessage(false);
    setShowEvidenceModal(false);
    closeEvidenceModal();
  };

  const sortColl = (el, so) => {
    if (quickListType === "PREVENTIVE_CARE") {
      sortColArray.forEach((e) => {
        if (e.attr === el) {
          e.desc = so;
        }
      });
      if (sortColArray.filter((e) => e.attr === el).length === 0) {
        sortColArray.push({ attr: el, desc: so, priority: priority });
        setPriority(priority + 1);
      }
    }
    setSortOrder(so === "DESC" ? "ASC" : "DESC");
    sortCol(sortColArray, el, so);
  };

  const showManageEvidenceModal = (patient) => {
    setShowEvidenceModal(true);
    setManageEvidencePatient(patient);
  };

  const isReferral = [
    "REFERRALS_AWAITING_COMPLETION",
    "REFERRALS_AWAITING_RESPONSE",
    "REFERRALS_SENT",
    "REFERRALS_RECEIVED",
  ].includes(quickListType);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [refToPopUp, setRefToPopUp] = useState(false);
  const handleShow = (o) => {
    //
    setShow(true);
    setRefToPopUp(o);
  };

  const handleClickManageEvidence = (e) => {};

  const _getDischargeLabel = (discharge) => {
    let str;
    if (discharge.dischargeDate) {
      if (discharge.adtDisposition) {
        str = discharge.adtDisposition + " ";
      }
      str += formatDate(discharge.dischargeDate) + " ";
      if (discharge.facility) {
        str += discharge.facility;
      }
    }
    return str;
  };

  const transformToSingleList = (list) => {
    let finalList = [];
    if (!isReferral) {
      if (displayExtraRows) {
        list.forEach((e) => {
          finalList.push(e);
        });
      } else if (list && list.length) {
        list.forEach((e) => {
          if (e && e.carePaths && e.carePaths.length === 1) {
            e.carePath = e.carePaths[0];
            finalList.push(e);
          } else if (e && e.carePaths && e.carePaths.length > 1) {
            e.carePaths.forEach((cp, index) => {
              if (index === 0) {
                e.carePath = cp;
                finalList.push(e);
              } else {
                cp.id = Math.random();
                const newObj = { carePath: cp };
                finalList.push(newObj);
              }
            });
          } else if (isEmpty(e.carePaths)) {
            finalList.push(e);
          }
        });
      }
    } else {
      if (list && list.length) {
        list.forEach((e) => {
          if (e && e.referralDtoList && e.referralDtoList.length) {
            e.referralDtoList.forEach((cp, index) => {
              e.referral = cp;
              finalList.push(e);
            });
          }
        });
      }
    }
    return finalList;
  };

  const PATIENT_MAP_DEFINITIONS = {
    Hospitalizations: {
      sortColumn: sortColl,
      fieldName: "allADTsCount",
      headerBody: () => "Hospitalizations",
      className: "text-center",
      body: (patient, idx) => (
        <>{patient.allADTsCount != null && <div id={`${componentName}-allADTsCount-${idx}`}>{patient.allADTsCount}</div>}</>
      ),
    },
    ActiveCarePath: {
      headerBody: () => "Active Care Programs",
      className: "text-center",
      body: (patient, idx) =>
        patient.carePaths && patient.carePaths.length > 0 ? (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id="tooltip">
                {patient.carePaths
                  .filter((carePath) => carePath.active === true)
                  .map((carePath) => {
                    return (
                      <ToolTipText>
                        {carePath.name}
                        {carePath.assignedTo != null && (
                          <>
                            {": " +
                              carePath.assignedTo.firstName +
                              " " +
                              carePath.assignedTo.lastName}
                          </>
                        )}
                      </ToolTipText>
                    );
                  })}
              </Tooltip>
            }
          >
            <div id={`${componentName}-carePaths-${idx}`}>{patient.carePaths ? patient.carePaths.length : "0"}</div>
          </OverlayTrigger>
        ) : (
          0
        ),
    },
    PatientListChkbox: {
      scope: "col",
      dataTablesawPriority: "persist",
      headerBody: () => (
        <input id="PatientSelect" type="checkbox" onChange={(e) => selectOrUnSelectAll(e.target.checked)} />
      ),
      body: (patient, idx) => (
        <>
          {" "}
          {patient.firstName ? (
            <input id={`${componentName}-patientCheck-${idx}`} type="checkbox" name={patient.id} checked={isPatientSelected(patient)} onChange={(e) => handleChangeCheckBox(patient, e.target.checked)} />
          ) : (
            ""
          )}
          <label htmlFor="basic_checkbox_2"></label>
        </>
      ),
    },
    ReferralListChkbox: {
      scope: "col",
      dataTablesawPriority: "persist",
      headerBody: () => <>EDIT</>, //Saurav this is header cell body for referral edit
      body: (patient, idx) => (
        <>
          {" "}
          <a onClick={() => handleShow(patient.referral)}>
            <i id={`${componentName}-editIcon-${idx}`} className="fa fa-edit"></i>
          </a>
          <label htmlFor="basic_checkbox_2"></label>
        </>
      ),
    },
    FullName: {
      scope: "col",
      sortColumn: sortColl,
      fieldName: "fullName",
      headerBody: () => "Name",
      body: (patient, idx) => (
        <div className="d-flex align-items-center justify-content-between">
          <a
            data-bs-toggle={"offcanvas"}
            data-bs-target={"#patientDetailsModal"}
            aria-controls="patientDetailsModal"
            className="fw-bold patientName"
            onClick={() => {
              dispatch(setManageVisitPatient(patient));
              dispatch(setManageVisitOpenedFromPageTitle(componentName))
            }}
          >
            <div id={`${componentName}-patientFullName-${idx}`} className="forced-capital">{patient.fullName}</div>
          </a>
          {patient.expired ?
            <Badge pill bg="" text="light" id={`${componentName}-patientExpired-${idx}`} 
            className="border ms-2"
            style={{ backgroundColor: "#E75334"}}>
              Expired
            </Badge>
          : null}
        </div>
      ),
    },
    DateOfBirth: {
      scope: "col",
      sortColumn: sortColl,
      fieldName: "dateOfBirth",
      headerBody: () => "DOB",
      body: (patient, idx) => (
          <>
            <span id={`${componentName}-dateOfBirth-${idx}`}>
          {patient.dateOfBirth
              ? `${formatDate(patient.dateOfBirth)} (${_calculateAge(
                  patient.dateOfBirth
              )})`
              : ""}
        </span>
            {!isEmpty(patient?.gender) &&
                <span data-toggle="tooltip" data-placement="top" title={patient.gender}
                      className="badge rounded-pill bg-secondary border align-top ms-1">{patient.gender?.charAt(0)}</span>
            }
          </>
      ),
    },
    LatestDischargeDate: {
      scope: "col",
      sortColumn: sortColl,
      fieldName: "latestDischargeDate",
      headerBody: () => "Discharge Date",
      body: (patient, idx) => (
        <span id={`${componentName}-latestDischargeTime-${idx}`}>
          {formatDate(patient.latestDischargeTime)}
        </span>
      ),
    },
    DaysSincePriorDischarge: {
      scope: "col",
      sortColumn: sortColl,
      fieldName: "daysSincePriorDischarge",
      headerBody: () => "Days Since Prior Discharge",
      body: (patient, idx) => (
        <span id={`${componentName}-daysSinceDischarge-${idx}`}>
          {patient.latestDischargeTime
            ? (", ", patient.daysSinceLatestDischarge)
            : null}
        </span>
      ),
    },
    LatestAdmitDate: {
      scope: "col",
      sortColumn: sortColl,
      fieldName: "latestAdmitDate",
      headerBody: () => "Admit Date",
      body: (patient, idx) => {
        let admitDate = patient.latestAdmitTime ? formatDate(patient.latestAdmitTime) : '';
        let alertIcon = patient.careGaps && isAnyTOCCareGapCreatedWithinADay(patient.careGaps) &&
          <i className="fa fa-exclamation-square fa-lg text-danger ms-2"></i>
        return (
            <>
              <span id={`${componentName}-AdmitDate-${idx}`}>{admitDate}</span>
              <OverlayTrigger
                  overlay={
                    <Tooltip>New Hospital Event Past 24 Hours</Tooltip>
                  }
              >
                <span id={`${componentName}-AdmitDateAlertIcon-${idx}`}>{alertIcon}</span>
              </OverlayTrigger>
            </>
        );
      }
    },
    NextVisitDate: {
      scope: "col",
      fieldName: "nextVisit",
      headerBody: () => "Recommended Next Visit",
      body: (patient, idx) => (
        <span id={`${componentName}-nextVisitDate-${idx}`}>
          {formatDate(patient.nextVisit)}
        </span>
      ),
    },
    RecentVisitDate: {
      scope: "col",
      fieldName: "recentVisit",
      headerBody: () => "Last Wellness Visit",
      body: (patient, idx) => (
        <span id={`${componentName}-recentVisitDate-${idx}`}>
          {patient.recentVisit? formatDate(patient.recentVisit):"No Date available"}
        </span>
      ),
    },
    MemberID: {
      key: "memberId",
      headerBody: () => "Member ID",
      body: (patient, idx) => (
        <span id={`${componentName}-memberID-${idx}`}>
          {patient.memberId}
        </span>
      ),
    },
    Risk: {
      sortColumn: sortColl,
      fieldName: "risk",
      headerBody: () => "Risk",
      className: "text-center",
      body: (patient, idx) => (
        <>
          {" "}
          {patient.riskLevel && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">{patient.riskLevel}</Tooltip>}
            >
              <i
                id={`${componentName}-riskIcon-${idx}`}
                className={`fa fa-circle fa-xs me-1`}
                style={{
                  ...{ color: patient.riskLevelColour },
                }}
              />
            </OverlayTrigger>
          )}
        </>
      ),
    },
    RiskText: {
      sortColumn: sortColl,
      fieldName: "risk",
      headerBody: () => "Risk",
      className: "text-center",
      body: (patient, idx) => (
        <>
          <Badge pill bg={getRiskLevelStyle(patient.riskLevel)} id={`${componentName}-risk-level-${idx}`}>
            {patient && patient.riskLevel}
          </Badge>
        </>
      ),
    },
    ProgramStatus: {
      fieldName: "programStatus",
      headerBody: () => "Program Status",
      className: "text-center",
      body: (patient, idx) => (
          <span id={`${componentName}-program-status-${idx}`} status={patient.referral.status}>
                {patient && patient.programStatus}
              </span>
      ),
    },
    ChronicConditions: {
      fieldName: "chronicConditions",
      headerBody: () => "Chronic Conditions",
      className: "text-center",
      body: (patient, idx) => patient.chronicConditionDetailDtos && patient.chronicConditionDetailDtos.length > 0 && (
        <>
          {hasChronicCondition(patient, "Asthma") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">Asthma</Tooltip>}
            >
              <i id={`${componentName}-chronicAsthmaIcon-${idx}`}
                className={`fas fa-inhaler me-2 ${hasChronicConditionLevel(patient, "Asthma", "Controlled") ? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}{" "}
          {hasChronicCondition(patient, "CKD") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">CKD</Tooltip>}
            >
              <i id={`${componentName}-chronicCKDIcon-${idx}`}
                className={`fas fa-kidneys me-2 ${hasChronicConditionLevel(patient, "CKD", "Controlled") ? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}{" "}
          {hasChronicCondition(patient, "COPD") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">COPD</Tooltip>}
            >
              <i id={`${componentName}-chronicCOPDIcon-${idx}`}
                className={`fas fa-lungs me-2 ${hasChronicConditionLevel(patient, "COPD", "Controlled") ? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}{" "}
          {hasChronicCondition(patient, "CVD") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">CVD</Tooltip>}
            >
              <i id={`${componentName}-chronicCVDIcon-${idx}`}
                className={`fas fa-heartbeat me-2 ${hasChronicConditionLevel(patient, "CVD", "Controlled") ? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}{" "}
          {hasChronicCondition(patient,"Depression") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">Depression</Tooltip>}
            >
              <i id={`${componentName}-chronicConditionsIcon-${idx}`}
                className={`fas fa-head-side-medical me-2 ${hasChronicConditionLevel(patient, "Depression", "Controlled")? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}{" "}
          {hasChronicCondition(patient, "Diabetes") && (
            <OverlayTrigger
              popperConfig={{
                modifiers: {
                  preventOverflow: { enabled: false },
                },
              }}
              overlay={<Tooltip id="tooltip">Diabetes</Tooltip>}
            >
              <i id={`${componentName}-chronicDiabetesIcon-${idx}`}
                className={`fas fa-tint ${hasChronicConditionLevel(patient, "Diabetes", "Controlled") ? "text-dark" : "text-danger" }`}
                onClick={() => {
                  setActiveKey("3");
                  showManageEvidenceModal(patient);
                }}
              />
            </OverlayTrigger>
          )}
        </>
      ),
    },
    ReferralType: {
      fieldName: "referralType",
      headerBody: () => "Referral Type",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-referralType-${idx}`}>
          {" "}
          {patient.referral.referralType
            ? getDisplayValue(referral_types, patient.referral.referralType)
            : "-"}
        </span>
      ),
    },
    CompletedReferralsCount: {
      sortColumn: sortColl,
      fieldName: "completedReferralsCount",
      headerBody: () => "Completed Referrals",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-completedReferralsCount-${idx}`}>
          {patient.completedReferralsCount ? patient.completedReferralsCount : ''}
        </span>
      ),
    },
    PendingReferralsCount: {
      sortColumn: sortColl,
      fieldName: "pendingReferralsCount",
      headerBody: () => "Pending Referrals",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-pendingReferralsCount-${idx}`}>
          {patient.pendingReferralsCount ? patient.pendingReferralsCount : ''}
        </span>
      ),
    },
    Worklists: {
      headerBody: () => "Worklist(s)",
      className: "text-center",
      body: (patient, idx) =>
        patient.workLists && patient.workLists.length > 0 ? (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id="tooltip">Worklist(s): {patient.workLists}</Tooltip>
            }
          >
            <i id={`${componentName}-Worklists-${idx}`} className="fa fa-list-ul bg-danger text-white p-2 cursor-p"></i>
          </OverlayTrigger>
        ) : null,
    },
    EDVisits: {
      sortColumn: sortColl,
      fieldName: "ed_visits",
      headerBody: () => "ED Visits",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-EDVisits-${idx}`}>
          {patient.frequentEdVisitsCount}
        </span>
      ),
    },
    CareTeam: {
      headerBody: () => "Treating Providers",
      className: "text-center",
      body: (patient, idx) =>
        patient.specialistsCount && patient.specialistNames.length > 0 ? (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id="tooltip">
                {patient.specialistNames.map((name) => {
                  return <ToolTipText>{name}</ToolTipText>;
                })}
              </Tooltip>
            }
          >
            <div>
              <span id={`${componentName}-careTeamValue-${idx}`}>{patient.specialistsCount ? patient.specialistsCount : "0"}{" "} Participants</span>
              <i id={`${componentName}-careTeamIcon-${idx}`} className="fas fa-hand-holding-medical ms-1" />{" "}
            </div>
          </OverlayTrigger>
        ) : (
          <div id={`${componentName}-careTeamValue-${idx}`}> 0 Participants </div>
        ),
    },
    ActiveGaps: {
      sortColumn: sortColl,
      fieldName: "openCareGapsCount",
      headerBody: (patient) => "Active Care Opportunities",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-activeGaps-${idx}`}>
          {patient.openCareGapsCount}
        </span>
      ),
    },
    DaysSinceBeforeLastDischarge: {
      sortColumn: sortColl,
      fieldName: "daysSinceBeforeLastDischarge",
      headerBody: (patient) => "Days Since Prior Discharge",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-DaysSincePriorDischarge-${idx}`}>
          {patient.daysSinceBeforeLatestDischarge}
        </span>
      ),
    },
    PCPPractice: {
      sortColumn: sortColl,
      fieldName: "pcp_practice",
      headerBody: () => "PCP Practice",
      className: "truncate",
      body: (patient, idx) =>
        patient.originatingOrganization && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            popperConfig={{
              modifiers: {
                preventOverflow: { enabled: false },
              },
            }}
            overlay={
              <Tooltip id="tooltip">
                {patient.originatingOrganization
                  ? patient.originatingOrganization
                  : ""}
              </Tooltip>
            }
          >
            <div id={`${componentName}-PCPPractice-${idx}`}>{patient.originatingOrganization}</div>
          </OverlayTrigger>
        ),
    },
    SDOH: {
      sortColumn: sortColl,
      fieldName: "sdoh",
      headerBody: () => "SDOH",
      className: "text-center",
      body: (patient, idx) => (
        <>
          {" "}
          <StyledLabel id={`${componentName}-SDOH-${idx}`} scraScore={patient.scraScore}>
            {patient.scraScore}
          </StyledLabel>
        </>
      ),
    },
    AllADTsCount: {
      sortColumn: sortColl,
      fieldName: "allADTsCount",
      headerBody: (patient) => "adt",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-AllADTsCount-${idx}`}>
          {patient.allADTsCount}
        </span>
      ),
    },
    Disposition: {
      sortColumn: sortColl,
      fieldName: "disposition",
      headerBody: () => "Disposition",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-Disposition-${idx}`}>
          {patient.disposition}
        </span>
      ),
    },
    Diagnosis: {
      fieldName: "diagnosis",
      headerBody: () => "Diagnosis",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-Diagnosis-${idx}`}>
          {patient.diagnosis}
        </span>
      ),
    },
    DischargeDisposition: {
      sortColumn: sortColl,
      fieldName: "disposition",
      headerBody: () => "Discharge Disposition",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-DischargeDisposition-${idx}`}>
          {patient.disposition}
        </span>
      ),
    },
    FacilityName: {
      sortColumn: sortColl,
      fieldName: "facilityName",
      headerBody: () => "Facility Name",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-FacilityName-${idx}`}>
          {patient.latestDischarge ? patient.latestDischarge.facility : ""}
        </span>
      ),
    },
    CarePath: {
      headerClassName: "nested-th",
      headerBody: () => "Care Program",
      className: "truncate",
      body: (patient, idx) =>
        patient.carePath && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            popperConfig={{
              modifiers: {
                preventOverflow: { enabled: false },
              },
            }}
            overlay={
              <Tooltip id="tooltip">
                {patient.carePath ? patient.carePath.name : ""}
              </Tooltip>
            }
          >
            <div id={`${componentName}-CarePathName-${idx}`}>{patient.carePath ? patient.carePath.name : ""}</div>
          </OverlayTrigger>
        ),
    },
    CurrentStep: {
      headerClassName: "nested-th",
      headerBody: () => "Current Step",
      className: "truncate",
      body: (patient, idx) =>
        patient.carePath &&
        patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep &&
        patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep
          .title && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            popperConfig={{
              modifiers: {
                preventOverflow: { enabled: false },
              },
            }}
            overlay={
              <Tooltip id="tooltip">
                {patient.carePath &&
                patient.carePath.carePathStepsAggregatorDto
                  .earliestDueDateStep &&
                patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep
                  .title
                  ? patient.carePath.carePathStepsAggregatorDto
                      .earliestDueDateStep.title
                  : ""}
              </Tooltip>
            }
          >
            <div id={`${componentName}-CarePathCurrentStep-${idx}`}>
              {patient.carePath &&
              patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep &&
              patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep
                .title
                ? patient.carePath.carePathStepsAggregatorDto
                    .earliestDueDateStep.title
                : ""}{" "}
            </div>
          </OverlayTrigger>
        ),
    },
    StepDueDate: {
      sortColumn: sortColl,
      fieldName: "step_due_date",
      headerBody: () => "Step Due Date",
      body: (patient, idx) => (
        <span id={`${componentName}-CarePathDueDate-${idx}`}>
          {patient.carePath &&
          patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep &&
          patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep
            .dueDate
            ? formatDate(
                patient.carePath.carePathStepsAggregatorDto.earliestDueDateStep
                  .dueDate
              )
            : ""}{" "}
        </span>
      ),
    },
    StepAssignedTo: {
      headerClassName: "nested-th",
      headerBody: () => "Step Assigned To",
      body: (patient, idx) => (
        <span id={`${componentName}-CarePathAssignedTo-${idx}`}>
          {patient.carePath && patient.carePath.assignedTo
            ? `${patient.carePath.assignedTo.lastName}, ${patient.carePath.assignedTo.firstName}`
            : ""}{" "}
          &nbsp;
        </span>
      ),
    },
    TotalGaps: {
      sortColumn: sortColl,
      fieldName: "allCareGapsCount",
      headerBody: () => "Total Care Opportunities",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-TotalGaps-${idx}`}>
          {patient.allCareGapsCount}
        </span>
      ),
    },
    MedicationsCount: {
      sortColumn: sortColl,
      fieldName: "medicationsCount",
      headerBody: () => "Rx",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-MedicationsCount-${idx}`}>
          {patient.medicationsCount}
        </span>
      ),
    },
    AllRafsCount: {
      sortColumn: sortColl,
      fieldName: "allRafsCount",
      headerBody: () => "Suspects",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-AllRafsCount-${idx}`}>
          {patient.allRafsCount}
        </span>
      ),
    },
    Notes: {
      sortColumn: sortColl,
      fieldName: "notesCount",
      headerBody: () => "Notes",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-Notes-${idx}`}>
          {patient.notesCount}
        </span>
      ),
    },
    SentDate: {
      sortColumn: sortColl,
      fieldName: "sentDate",
      headerBody: (patient) => "Sent Date",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-SentDate-${idx}`}>
          {patient.referral ? formatDate(patient.referral.refDateUTC) : ""}
        </span>
      ),
    },
    Sender: {
      sortColumn: sortColl,
      fieldName: "sender",
      headerBody: () => "Sender",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-Sender-${idx}`}>
          {patient.referral ? patient.referral.referredBy.name : ""}
        </span>
      ),
    },
    Recipient: {
      sortColumn: sortColl,
      fieldName: "recipient",
      headerBody: () => "Recipient",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-Recipient-${idx}`}>
          {patient.referral ? patient.referral.referredTo.name : ""}
        </span>
      ),
    },
    Urgency: {
      sortColumn: sortColl,
      fieldName: "urgency",
      headerBody: () => "Urgency",
      className: "text-center",
      body: (patient, idx) => {
        return patient.referral ? (
          <UrgencyLabel id={`${componentName}-Urgency-${idx}`} urgency={patient.referral.urgency}>
            {getDisplayValue(urgency_referrals, patient.referral.urgency)}
          </UrgencyLabel>
        ) : (
          ""
        );
      },
    },
    RespondBy: {
      sortColumn: sortColl,
      fieldName: "respondBy",
      headerBody: () => "Respond By",
      className: "text-center",
      body: (patient, idx) => (
        <span id={`${componentName}-RespondBy-${idx}`}>
          {patient.referral ? formatDate(patient.referral.referralDueDate) : ""}
        </span>
      ),
    },
    Status: {
      sortColumn: sortColl,
      fieldName: "status",
      headerBody: () => "Status",
      className: "text-center",
      body: (patient, idx) =>
        patient.referral ? (
          <StatusLabel id={`${componentName}-Status-${idx}`} status={patient.referral.status}>
            {getDisplayValue(status_referrals, patient.referral.status)}
          </StatusLabel>
        ) : (
          ""
        ),
    },
    CareOpportunities: {
      sortColumn: sortColl,
      fieldName: "openCareGapsCount",
      headerBody: () => "Active Care Opportunities",
      className: "text-center",
      body: (patient, idx) => (
        <OverlayTrigger
          placement="left"
          trigger={['hover', 'focus']}
          popperConfig={{
            modifiers: {
              preventOverflow: { enabled: false },
            },
          }}
          overlay={
            <Tooltip id="tooltip">
              {patient && patient.careGaps && patient.careGaps.length
                ? patient.careGaps.map((gap) =>
                    gap.abbreviation ? (
                      <ToolTipText>
                        {gap.abbreviation} {gap.measureYear}
                      </ToolTipText>
                    ) : (
                      ""
                    )
                  )
                : "No Opportunities to list"}
            </Tooltip>
          }
        >
          <div id={`${componentName}-CareOpportunities-${idx}`}>
            {patient.openCareGapsCount ? patient.openCareGapsCount : "0"}
          </div>
        </OverlayTrigger>
      ),
    },
    Suspects: {
      sortColumn: sortColl,
      fieldName: "activeRafsCount",
      headerBody: () => "Active Suspects",
      className: "text-center",
      body: (patient, idx) => (
        <OverlayTrigger
          placement="left"
          trigger={['hover', 'focus']}
          popperConfig={{
            modifiers: {
              preventOverflow: { enabled: false },
            },
          }}
          overlay={
            <div className="tooltip">
              {patient && patient.diseases && patient.diseases.length ? (
                patient.diseases.map((disease) =>
                  disease.labResult ? (
                    <ToolTipText>
                      {disease.measureYear}
                      {" : "}
                      {disease.labResult}
                    </ToolTipText>
                  ) : (
                    <ToolTipText>
                      {disease.measureYear}
                      {" : "}
                      {disease.dxCodeDescription}{" "}
                    </ToolTipText>
                  )
                )
              ) : (
                <ToolTipText>{"No Suspects to list"}</ToolTipText>
              )}
            </div>
          }
        >
          <div id={`${componentName}-Suspects-${idx}`}>{patient.activeRafsCount ? patient.activeRafsCount : "0"}</div>
        </OverlayTrigger>
      ),
    },
    ManageEvidencebutton: {
      scope: "col",
      dataTablesawPriority: "persist",
      headerBody: () => "Manage",
      body: (patient, idx) => (patient.id &&
        <>
          {" "}
          <Button
            id={`${componentName}-manageVisit-${idx}`}
            name={patient.id}
            enabled={true}
            onClick={() => {
              setActiveKey("-1");
              showManageEvidenceModal(patient);
            }}
          >
            Manage
          </Button>
          <label htmlFor="basic_checkbox_2"></label>
        </>
      ),
    },
    ManageButtonTOC: {
      scope: "col",
      dataTablesawPriority: "persist",
      headerBody: () => "Manage",
      body: (patient, idx) => (
        <>
          {" "}
          <Button
            id={`${componentName}-manageVisit-${idx}`}
            name={patient.id}
            enabled={true}
            onClick={() => {
              setActiveKey("-1");
              showManageEvidenceModal(patient);
            }}
          >
            Manage
          </Button>
          <label htmlFor="basic_checkbox_2"></label>
        </>
      ),
    },
    ReferralAction : {
      scope: "col",
      headerBody: () => "Actions",
      body: (patient, idx) => (
        <OverlayTrigger placement="top" overlay={(props) => (
            <Tooltip id="actionTooltip" {...props}>
              Actions
            </Tooltip>
        )} >
          <Dropdown>
            <Dropdown.Toggle variant="light" text="dark" id={`${componentName}-referralAction-${idx}`} className="border py-1 px-2 border">
              <i className="fa fa-arrow-alt-from-left"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {careManagementActions && careManagementActions.map(action => (
                  <Dropdown.Item
                    id={`${componentName}-referralActionItem-${idx}`}
                    key={`${componentName}-${action.value}-${idx}-key`}
                    onClick={() => {
                      onShowAssignCareManagerModal([patient.id])
                      setSelectedName(patient.fullName)
                    }}
                  >
                    {action.text}
                  </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
      ),
    },
    ReferralCheckBox : {
      scope: "col",
      headerBody: () => (
        <input id={`${componentName}-referralCheck`} type="checkbox" className="form-check-input" checked={allPatientsChecked} onChange={event => selectOrUnSelectAll(event.target.checked)} />
      ),
      body: (patient, idx) => (
        <input id={`${componentName}-referralCheck-${idx}`} type="checkbox" className="form-check-input" name={patient.id} checked={isPatientSelected(patient)} onChange={event => handleChangeCheckBox(patient, event.target.checked)} />
      ),
    },
  };
  PATIENT_MAP_DEFINITIONS.ReferralFullName = {
    ...PATIENT_MAP_DEFINITIONS.FullName,
  };

  const showManageEvidence = (patient) => {
    setSelectedPatient(patient);
    setShowEvidenceModal(true);
  };

  return (
    <Row className="listView">
      <Col>
        <Card>
          <Card.Body>
            {(patientList?.default && patientList?.default[0] != "Empty") && (
              <Table className="table-bordered mb-0 table-hover">
                <thead className="table-light fixedHead">
                  <tr>
                    {columnList.map((column) =>
                      PATIENT_MAP_DEFINITIONS[column].sortColumn ? (
                        <SortableTh
                          className={
                            PATIENT_MAP_DEFINITIONS[column].headerClassName
                          }
                          scope={PATIENT_MAP_DEFINITIONS[column].scope}
                          sortColumn={
                            PATIENT_MAP_DEFINITIONS[column].sortColumn
                          }
                          fieldName={PATIENT_MAP_DEFINITIONS[column].fieldName}
                          columnName={PATIENT_MAP_DEFINITIONS[
                            column
                          ].headerBody()}
                        />
                      ) : (
                        <th
                          className={
                            PATIENT_MAP_DEFINITIONS[column].headerClassName
                          }
                          scope={
                            PATIENT_MAP_DEFINITIONS[column].scope
                              ? PATIENT_MAP_DEFINITIONS[column].scope
                              : ""
                          }
                          data-tablesaw-priority={
                            PATIENT_MAP_DEFINITIONS[column].dataTablesawPriority
                              ? PATIENT_MAP_DEFINITIONS[column]
                                  .dataTablesawPriority
                              : ""
                          }
                        >
                          {PATIENT_MAP_DEFINITIONS[column].headerBody()}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {transformToSingleList(patientList.default).map((patient, idx) => {
                    return (
                      <tr>
                        {columnList.map((column) => (
                          <td className={PATIENT_MAP_DEFINITIONS[column].className}>
                            {PATIENT_MAP_DEFINITIONS[column]
                              ? PATIENT_MAP_DEFINITIONS[column].body(patient, idx)
                              : ""}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Modals
        show={show}
        onHide={() => setShow(false)}
        onCreate={() => setShow(false)}
        title={"Manage Referral"}
        titleid={"ManageReferral"}
        size="xl"
        hideFooter={true}
        completeBtn={"SAVE CHANGES"}
        body={
          <ReferralModal
            closeModal={() => setShow(false)}
            referralObject={refToPopUp}
            quickListType={quickListType}
            updateParent={updateParent}
            reloadPatientView={reloadPatientView}
          />
        }
      ></Modals>
      <Modals
        scrollable={true}
        show={showEvidenceModal}
        closeEvidenceModal={() => closeEvidenceModalMain()}
        onHide={() => handleModalClose()}
        size="xl"
        title="Manage Visit"
        completeBtn="UPDATE"
        hideFooter={true}
        body={
          <ManageEvidence
            patient={manageEvidencePatient}
            closeEvidenceModal={() => closeEvidenceModalMain()}
            returnPath={RETURN_TO_MY_PATIENTS_URL}
            activeKey={activeKey}
            updateParent={updateParent}
            updatesMade={setChangesMade}
            setConfirmationMessage={setConfirmationMessage}
            confirmationMessage={confirmationMessage}
          />
        }
      />
      <Modals
        centered={true}
        show={confirmationMessage}
        scrollable={true}
        closeConfirmation={() => setConfirmationMessage(false)}
        onCreate={() => closeEvidenceModalMain()}
        onHide={() => setConfirmationMessage(false)}
        size="sm"
        title=""
        closeBtn="NO"
        completeBtn="YES"
        hideFooter={false}
        body="Do you want to close without saving?"
      />
    </Row>
  );
};

function hasChronicCondition(patient, condition)  {
  if (patient.chronicConditionDetailDtos !== null) {
    for(var i = 0; i < patient.chronicConditionDetailDtos.length; i++){
      if(patient.chronicConditionDetailDtos[i].name.toUpperCase() == condition.toUpperCase()){
        return true;
      }
    }
  }
  return false;
}

function hasChronicConditionLevel(patient, condition, level)  {
  if (patient.chronicConditionDetailDtos !== null) {
    for(var i = 0; i < patient.chronicConditionDetailDtos.length; i++){
      if(patient.chronicConditionDetailDtos[i].name.toUpperCase() == condition.toUpperCase() &&
          patient.chronicConditionDetailDtos[i].level.toUpperCase() == level.toUpperCase()){
        return true;
      }
    }
  }
  return false;
}

export default PatientListView;
