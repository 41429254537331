import { disPatchError } from "../utils"
import {
  GET_BRAND_ORG, RESET_ERRORS, GET_BRAND_ORG_LOGO,UPDATE_BRAND_ORG, UPDATE_SUCCESS
  } from "../types";
  import axios from "../../utils/apiClient";
  export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/organization-admin/";


  export const getOrganizations= () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "organizationLogos", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_BRAND_ORG,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };
  export const getOrganizationLogo= () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "organizations", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_BRAND_ORG_LOGO,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };

  
  export const updateBrandOrg = (file, id, fileName) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD", payload: true });
    dispatch({ type: RESET_ERRORS });
    axios.post(URL + `organizationLogos?orgId=${id}`, file, {
        headers: {
          "Accept" : "application/json",
          "Content-Type": "application/octet-stream",
          "X-Filename" : fileName
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_BRAND_ORG,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SUCCESS,
          payload: "Successfully Updated",
        });
      })
      .catch((err) => {
        disPatchError(err,dispatch);
      });
  };


