import { disPatchError } from "../utils"
import {
    GET_PAYERS, RESET_ERRORS, GET_PRACTICE, UPDATE_PRACTICE, UPDATE_SUCCESS, GET_ALL_PAYERS, GET_ALL_PAYERS_FROM_COLLECTION, UPDATE_PRACTICE_LEVEL
  } from "../types";
  import axios from "../../utils/apiClient";
  export const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/practice-information-admin/";
  export const URL2 = process.env.REACT_APP_REST_API_BASE_URL + "/v1/organization-admin/";


  export const getPayers = () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "payers", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_PAYERS,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };

  export const getPractice = () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL + "practice-information", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_PRACTICE,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };

  export const getAllPayers = () => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(URL2 + "organizations", {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_PAYERS,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };

  export const getAllPayersFromColl = (name) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD" });
    dispatch({ type: RESET_ERRORS });
    axios
      .get(`${URL2}getPracticeLevel?name=${name}`, {
        headers: {
        },
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_PAYERS_FROM_COLLECTION,
          payload: res.data,
        });
      })
      .catch((err) => disPatchError(err,dispatch));
  };
  export const updatePracticeInformation = (practiceData) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD", payload: true });
    dispatch({ type: RESET_ERRORS });
    axios
      .put(URL + `practice-information`, practiceData, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_PRACTICE,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SUCCESS,
          payload: "Successfully Updated",
        });
      })
      .catch((err) => {
        disPatchError(err,dispatch);
      });
  };

  export const updatePracticeLevel = (data, pracLevel) => (dispatch) => {
    dispatch({ type: "TOGGLE_LOAD", payload: true });
    dispatch({ type: RESET_ERRORS });
    axios
      .put(URL2 + `updatePracticeLevel?name=${pracLevel}`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_PRACTICE_LEVEL,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SUCCESS,
          payload: "Successfully Updated",
        });
      })
      .catch((err) => {
        disPatchError(err,dispatch);
      });
  };


