import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
  useAccordionButton,
} from "react-bootstrap";
import { EQHDatePicker } from "../../../components/datePicker";
import styled from "styled-components";
import cloneDeep from "lodash/cloneDeep";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { format } from "date-fns";
import { logDebug } from "../../../utils/util";
import {
  getSuspectAwaitingChartContent,
  getSuspectAwaitingChartToolTip,
  getSuspectAwaitingClaimContent,
  getSuspectAwaitingClaimToolTip,
} from "./ManageEvidenceCommonCode";
import {
  gapValidationOptions,
  codeTypes,
  eyeExamTypes,
  suspectValidationOptions,
  suspectRejectionReasons,
} from "../../../utils/textValueLists";
import axios from "axios";
import { SimpleDDL } from "../../../components/dropDownLists/simpleDDL";
import { RejectionDDL } from "../../../components/dropDownLists/rejectionDDL";
import { CareOpportunitiesList } from "./CareOpportunityList";
import { CARE_OPPORTUNITIES_TYPE } from "./CareOpportunitiesAccordion";
import { useDispatch, useSelector } from "react-redux";
import { disPatchError } from "../../../actions/utils";
import { Button } from "../../../components/buttons";
import { useManageVisitContext } from "../components/context/ManageVisitContext/ManageVisit.context";
import { setShowFullHistory } from "../../../actions/manageVisitActions";

const baseURLICD = process.env.REACT_APP_REST_API_BASE_URL + "/v1/icd10";

const VALIDATION_TYPE = {
  Opportunity: "Opportunity",
  Suspect: "Suspect",
};

const StyledTypeAhead = styled(AsyncTypeahead)`
  .dropdown-menu {
    max-height: 250px !important;
  }
`;

const getId = (item) => {
  if (item.conditionSummary) {
    return item.conditionSummary.id;
  } else if (item.careGapSummary) {
    return item.careGapSummary.id;
  }
  return null;
};

const filterBy = () => true;

const DEFAULT_UPDATE_DTO = {
  ids: [],
  newStatus: {
    source: "",
    translationKey: "",
    closesCondition: true,
    name: "",
  },
  validation: {},
  icd10DiseaseDto: null,
};

const getDefaultUpdateDTO = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_UPDATE_DTO);
  dto.newDateOfService = dateOfService;
  return dto;
};

const DEFAULT_INFO = {
  requireValidation: false,
  requireRejectionReason: false,
  rejectionReasons: [],
  requireCode: false,
  requirePressureCodes: false,
  requireIcd10: false,
  requireEyeExamResult: false,
  enableRevisedDate: false,
  validationOptions: [],
};

const getDefaultInfo = () => {
  return cloneDeep(DEFAULT_INFO);
};

const DEFAULT_DATA = {
  selectedItems: [],
  validationType: null,
  visitDate: null,
  confirmationWarning: "",
  finalizeForBackend: () => false,
  info: getDefaultInfo(),
  errors: [],
};

const getDefaultData = (dateOfService) => {
  const dto = cloneDeep(DEFAULT_DATA);
  dto.updateDto = getDefaultUpdateDTO(dateOfService);
  dto.updateDto.validation.validationCode = "";
  dto.updateDto.validation.systolicCode = "";
  dto.updateDto.validation.diastolicCode = "";
  dto.updateDto.validation.note = "";
  return dto;
};

const ValidationComponent = ({
  isSearching,
  componentName,
  onChange,
  title = "Validation",
  authorizedGapWrite = false,
  authorizedGapRead = false,
  authorizedGapValidate = false,
  authorizedRafValidate = false,
  disableCheckList = false,
  activeKey,
  patient,
  resetKeyProp,
  dateOfServiceOverride,
  addToSelecedItemParams,
  processAddToSelecedItemParamsKeyProp,
  validatePatientEvidence,
  enableButton,
  temporarilyDisableSubmitButton,
  show,
  setShow
}) => {
  const dispatch = useDispatch();
  const visitManageContext = useManageVisitContext();
  const isEmpty = require("is-empty");

  useEffect(() => {
    setData(() => getDefaultData(dateOfServiceOverride))
  }, [show]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [trackDateOfService, setTrackDateOfService] = useState(
    dateOfServiceOverride
  );
  const [data, setData] = useState(() => getDefaultData(dateOfServiceOverride));
  const [isLoading, setIsLoading] = useState(false);
  const [
    processAddToSelecedItemParamsKey,
    setProcessAddToSelecedItemParamsKey,
  ] = useState(null);
  const [optionsCode, setOptionscode] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);
  const [optionsDesc, setOptionsDesc] = useState([
    {
      id: "",
      code: "",
      description: "",
    },
  ]);
  const [resetKey, setResetKey] = useState(null);
  const[reasons, setReasons] = useState([]);


  const getErrors = (data) => {
    let errors = [];

    if (data.selectedItems.length) {
      if (
        data.info.requireValidation &&
        !data.updateDto.validation?.validation
      ) {
        errors.push("Validation is Required");
      }
      if (
        data.updateDto.validation?.validation === "REJECTED" &&
        !data.updateDto.validation?.rejectionReason
      ) {
        errors.push("Rejection Reason is Required");
      }
      if (
        data.info.requireCode &&
        (!data.updateDto.validation?.validationCodeType ||
          !data.updateDto.validation?.validationCode)
      ) {
        errors.push("Validation Code and Type are Required");
      }
      if (
        data.info.requireIcd10 &&
        (!data.updateDto?.icd10DiseaseDto?.code ||
          !data.updateDto?.icd10DiseaseDto?.description)
      ) {
        errors.push("Icd10 is Required");
      }
      // if (requireValidationWc15 && !validationVisit.careGapVisit) {
      //   errors.push("Validation is Required");
      // }
    }

    return errors;
  };

  const setDataAndCallOnChange = (data) => {
    setData(data);
    data.getId = getId;
    data.errors = getErrors(data);
    data.finalizeForBackend = function () {
      if (this.selectedItems && this.updateDto) {
        const ids = [];
        this.selectedItems.forEach((x) => {
          ids.push(this.getId(x));
        });
        this.updateDto.ids = ids;
      }
    };
    onChange(data);
  };


  useEffect(() => {
    retrieveRejectionReasons();
  }, []);

  const retrieveRejectionReasons = () => {
    axios
        .get(
            `${process.env.REACT_APP_REST_API_BASE_URL}/v1/icd10/get-rejection-reasons`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        )
        .then(async (res) => {
          if (res && res.data) {
            await setReasons(res.data);
          }
        })
        .catch((error) => {
        });
  };


  const reset = () => {
    setIsLoading(false);
    setOptionscode([
      {
        id: "",
        code: "",
        description: "",
      },
    ]);
    setOptionsDesc([
      {
        id: "",
        code: "",
        description: "",
      },
    ]);

    setDataAndCallOnChange(getDefaultData(dateOfServiceOverride));
  };

  const applyConditionalRequirements = (dataClone) => {
    const obj = getDefaultInfo();

    dataClone.selectedItems.forEach((selectedItem) => {
      //if validation care gap
      if (selectedItem.careGapSummary) {
        obj.validationOptions = gapValidationOptions;
        if (selectedItem.careGapSummary.gapCategory === "ECM") {
          if (
            selectedItem.careGapSummary.status.name === "Marked for Closure"
          ) {
            if (selectedItem.careGapSummary.requireValidation) {
              obj.requireValidation = true;
            }
          } else if (
            selectedItem.careGapSummary.status.name !== "Marked for Closure" &&
            (selectedItem.careGapSummary.abbreviation === "WC15" ||
              selectedItem.careGapSummary.abbreviation === "WC30")
          ) {
            obj.requireValidation = true;
          }
        } else {
          if (
            selectedItem.careGapSummary.status.name === "Marked for Closure"
          ) {
            obj.requireValidation = true;
          }
        }
      } else if (selectedItem.conditionSummary) {
        obj.validationOptions = suspectValidationOptions;
        obj.requireValidation = true;
        if (dataClone.updateDto.validation.validation === "REJECTED") {
          obj.requireRejectionReason = true;
          obj.rejectionReasons = reasons;
        }
      }

      switch (dataClone.updateDto.validation.validation) {
        case "APPROVED":
          if (dataClone.validationType === VALIDATION_TYPE.Opportunity) {
            if (selectedItem.careGapSummary.requireCode) {
              obj.requireCode = true;
            }
          }
          obj.enableRevisedDate = true;
          break;
        case "MODIFIED_AND_CONFIRMED":
          obj.requireIcd10 = true;
          //setVisitDate(originalVisitDate);
          break;
        case "REJECTED":
          if (dataClone.validationType === VALIDATION_TYPE.Opportunity) {
            obj.requireRejectionReason = true;
            obj.rejectionReasons = reasons;
          }
          break;
        default:
        //setVisitDate(originalVisitDate);
      }
    });

    dataClone.info = obj;
  };

  const updateSelected = (item, key, checked, gapType) => {
    let dataClone = { ...data };

    if (checked) {
      if (key === "careGapSummary") {
        dataClone.updateDto.newStatus = {
          closesGap: true,
          name: "Marked for Closure",
          source: "MARKED_FOR_CLOSURE",
        };
        dataClone.validationType = VALIDATION_TYPE.Opportunity;
      } else if (key === "conditionSummary") {
        dataClone.updateDto.newStatus = null;
        dataClone.validationType = VALIDATION_TYPE.Suspect;
      }
      dataClone.selectedItems = [item].concat(dataClone.selectedItems);
    } else {
      dataClone.selectedItems = dataClone.selectedItems.filter((i) => {
        if (i[key]) {
          return i[key].id !== item[key].id;
        } else {
          return i;
        }
      });
      dataClone.updateDto.newStatus = null;
      dataClone.updateDto.validation = {
        validation: null,
        rejectionReason: null,
        code: null,
        codeTyp: null,
        systolicCode: "",
        diastolicCode: "",
      };
      dataClone.validationType = null;
    }

    applyConditionalRequirements(dataClone);
    setDataAndCallOnChange(dataClone);
  };

  const handleOpportunityChange = (name, value) => {
    logDebug(
      () => "helloThere!!!" + JSON.stringify(name) + ":" + JSON.stringify(value)
    );
    let finalValue = value;
    let dataClone = { ...data };
    dataClone.updateDto.validation = {
      ...(data.updateDto.validation || {}),
    };

    switch (name) {
      case "eyeExamResult":
      case "systolicCode":
      case "diastolicCode":
      case "note":
        break;
      case "revisedDateOfService":
        finalValue = value.strDate;
        break;
      case "validation":
          switch (value) {
          case "MODIFIED_AND_CONFIRMED":
            dataClone.updateDto.icd10DiseaseDto = {};
            dataClone.updateDto.validation.note="";
            dataClone.updateDto.validation.rejectionReason=null;
            dataClone.updateDto.validation.revisedDateOfService = "";
            break;
          case "APPROVED":
            dataClone.updateDto.validation.note="";
            dataClone.updateDto.validation.rejectionReason=null;
            break;
          default:
            dataClone.updateDto.validation.revisedDateOfService = "";
            dataClone.updateDto.icd10DiseaseDto = null;
        }
        break;
      default:
        break;
    }

    dataClone.updateDto.validation[name] = finalValue;
    let selectedReasonData = [];
    if(name === "rejectionReason") {
      const selectedValue = value;
      selectedReasonData = reasons.find((reason) => reason.rejection_reason === selectedValue);
      dataClone.updateDto.validation.note = selectedReasonData?.recommendation
      dataClone.updateDto.validation.rejectionReason = selectedReasonData?.rejection_reason;
    }
    switch (name) {
      case "validation":
        applyConditionalRequirements(dataClone);
        break;
      default:
        break;
    }
    setDataAndCallOnChange(dataClone);
  };

  const handleIcdChange = (e) => {
    let dataClone = { ...data };
    dataClone.updateDto.icd10DiseaseDto.id = e.length ? e[0].id : null;
    dataClone.updateDto.icd10DiseaseDto.code = e.length ? e[0].code : null;
    dataClone.updateDto.icd10DiseaseDto.description = e.length
      ? e[0].description
      : null;
    setDataAndCallOnChange(dataClone);
  };

  const asyncCodeSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD + `/find-by-code?code=${query}&offset=${1}&pageSize=${30}`,
        { headers: {} }
      )

      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        setOptionscode(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        logDebug(() => error);
        setIsLoading(false);
      });
  };

  const asyncDescSearch = (query) => {
    setIsLoading(true);
    axios
      .get(
        baseURLICD +
        `/find-by-description?description=${query}&offset=${1}&pageSize=${30}`,
        {
          headers: {},
        }
      )
      .then(async (response) => {
        let newOptions = [];
        if (response && response.data) {
          newOptions = response.data.map((i) => ({
            id: i.id,
            code: i.code,
            description: i.description,
          }));
        }
        setOptionsDesc(newOptions);
        setIsLoading(false);
      })
      .catch((error) => {
        logDebug(() => error);
        setIsLoading(false);
      });
  };

  const checkValidationPermission = (gap) => {
    if (
      data.selectedItems.length &&
      !data.selectedItems.find((x) => getId(x) === gap.id)
    ) {
      return true;
    }
    if (gap.status.name === "Excluded") {
      return true;
    }
    if (
      (gap.abbreviation === "WC15" || gap.abbreviation === "WC30") &&
      gap.careGapSummary.visitsLowerBound >= 6
    ) {
      return true;
    }

    return false;
  };

  if (dateOfServiceOverride !== trackDateOfService) {
    setData({
      ...data,
      updateDto: { ...data.updateDto, newDateOfService: dateOfServiceOverride },
    });
    setTrackDateOfService(dateOfServiceOverride);
  }

  if (resetKeyProp !== resetKey) {
    reset();
    setResetKey(resetKeyProp);
  }

  if (
    processAddToSelecedItemParamsKeyProp !== processAddToSelecedItemParamsKey
  ) {
    setProcessAddToSelecedItemParamsKey(processAddToSelecedItemParamsKeyProp);
    if (
      addToSelecedItemParams &&
      (addToSelecedItemParams.type === "validGaps" ||
        addToSelecedItemParams.type === "validCons")
    ) {
      const isCareGap = addToSelecedItemParams.type === "validGaps";
      const suspects = visitManageContext?.patient_post?.conditionsResponse?.validationConditions || [];
      const opportunities = [
        ...(visitManageContext?.patient_post.careGapsResponse?.validationCareGaps || []),
        ...(visitManageContext?.patient_post?.careGapsResponse?.validationTOCGaps || [])
      ]

      let item = data.selectedItems.find(
        (x) => getId(x) === addToSelecedItemParams.id
      );
      if (!item) {
        item = (addToSelecedItemParams.type === "validGaps" ? opportunities : suspects).find(
          (x) => getId(x) === addToSelecedItemParams.id
        );
        if (item) {
          updateSelected(
            item,
            isCareGap ? "careGapSummary" : "conditionSummary",
            true
          );
        }
      }
    }
  }

  return (
    <Card.Body>
      <Row>
        <Col className="text-end">
          <Button
            variant="secondary"
            id={`${componentName}-patientHistory`}
            className="border"
            onClick={() => dispatch(setShowFullHistory(true))}
          >
            History
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col sm={6} className="mb-3">
          {authorizedGapWrite && authorizedGapRead ? (
            <div className="mb-3">
              <label
                id={`${componentName}-ValidationTitle`}
                className="d-block"
              >
                {title}
              </label>
              <span id={`${componentName}-ValidationSelected`}>
                {data.selectedItems.length} Selected
              </span>
            </div>
          ) : null}
          <div className="mb-3">
            {authorizedGapValidate && <label
              id={`${componentName}-ValidationCareOpp`}
              className="d-block"
            >
              Care Opportunities
            </label>}
            {isSearching ?
              <>
                <Spinner
                  className="me-3"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
              : authorizedGapValidate ? (
                <CareOpportunitiesList
                  opportunities={[
                    ...(visitManageContext?.patient_post.careGapsResponse?.validationCareGaps || []),
                    ...(visitManageContext?.patient_post?.careGapsResponse?.validationTOCGaps || [])
                  ]}
                  additionalOpportunities={[]}
                  selectedOpportunities={
                    data.validationType === VALIDATION_TYPE.Opportunity
                      ? data.selectedItems
                      : []
                  }
                  onChange={updateSelected}
                  listType={CARE_OPPORTUNITIES_TYPE.Validation}
                  itemType="careGapSummary"
                  componentName={componentName}
                  patient={patient}
                  disableCheckList={disableCheckList}
                  disableCheckbox={(gap) =>
                    checkValidationPermission(gap.careGapSummary)
                  }
                />
              ) : null}
          </div>
          <div className="mb-3">
            {authorizedRafValidate ? (
              <>
                <label
                  id={`${componentName}-ValidationSuspects`}
                  className="d-block"
                >
                  Suspects
                </label>
                {isSearching ?
                  <>
                    <Spinner
                      className="me-3"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                  : visitManageContext.patient_post.conditionsResponse?.validationConditions && visitManageContext.patient_post.conditionsResponse?.validationConditions.map((disease, idx) => (
                    <div
                      className="d-flex flex-row mb-1"
                      key={disease.conditionSummary.id}
                    >
                      <div className="d-inline-block me-2">
                        <input
                          id={`${componentName}-ValidationSuspectsChecked-${idx}`}
                          type="checkbox"
                          disabled={
                            disableCheckList ||
                            (data.selectedItems.length &&
                              !data.selectedItems.find(
                                (x) =>
                                  getId(x) ===
                                  disease.conditionSummary.id
                              ))
                          }
                          checked={data.selectedItems.find(
                            (x) =>
                              getId(x) === disease.conditionSummary.id
                          )}
                          onClick={(e) =>
                            updateSelected(
                              disease,
                              "conditionSummary",
                              e.target.checked
                            )
                          }
                        />{" "}
                      </div>
                      <div
                        id={`${componentName}-ValidationSuspectsSummary-${idx}`}
                        className="d-inline-block me-2"
                      >
                        <span
                          id={`${componentName}-ValidationSuspectsSummaryYear-${idx}`}
                        >
                          {disease.conditionSummary.conditionAndYear}
                        </span>{" "}
                        <span
                          id={`${componentName}-ValidationSuspectsSummaryStatus-${idx}`}
                        >
                          ({disease.conditionSummary.status.name})
                        </span>{" "}
                        <span
                          id={`${componentName}-ValidationSuspectsSummarydxCode-${idx}`}
                        >
                          {disease.conditionSummary.dxCode}
                        </span>
                      </div>
                      <div
                        id={`${componentName}-ValidationSuspectsSummaryAwaiting-${idx}`}
                        className="d-inline-block text-nowrap"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {getSuspectAwaitingChartToolTip(
                                disease.conditionSummary
                              )}
                            </Tooltip>
                          }
                        >
                          <i
                            id={`${componentName}-ValidationSuspectsAwaitingChart-${idx}`}
                            className={getSuspectAwaitingChartContent(
                              disease.conditionSummary
                            )}
                          ></i>
                        </OverlayTrigger>{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {getSuspectAwaitingClaimToolTip(
                                disease.conditionSummary
                              )}
                            </Tooltip>
                          }
                        >
                          <i
                            id={`${componentName}-ValidationSuspectsAwaitingClaim-${idx}`}
                            className={getSuspectAwaitingClaimContent(
                              disease.conditionSummary
                            )}
                          ></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))
                }
              </>
            ) : null}
          </div>
        </Col>
        <Col sm={6}>
          <div className="bg-white border p-3 rounded-3">
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <label id={`${componentName}-ValidationText`} className="d-block">
                    Validation
                    {data.info.requireValidation ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <SimpleDDL
                    id={`${componentName}-ValidationValue`}
                    list={data.info.validationOptions}
                    textSelector={(x) => x.name}
                    valueSelector={(x) => x.value}
                    onChange={(x) =>
                      handleOpportunityChange("validation", x)
                    }
                    disabled={!data.info.requireValidation}
                    selectedKey={
                      data.updateDto.validation.validation
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <label id={`${componentName}-ValidationRejectReasonText`} className="d-block">
                    Rejection Reason
                    {data.info.requireRejectionReason ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <RejectionDDL
                      id={`${componentName}-ValidationRejectReasonValue`}
                      list={data.info.rejectionReasons}
                      textSelector={(x) => {
                        if (x.rejection_reason)
                          return x.rejection_reason
                      }}
                      valueSelector={(x) => {
                        if (x.rejection_reason)
                          return x.rejection_reason
                      }}
                      toolTipSelector={(x) => {
                        if (x.rejection_reason)
                          return x.rejection_reason
                      }}

                      onChange={(x) =>
                          handleOpportunityChange("rejectionReason", x)
                      }
                      disabled={!data.info.requireRejectionReason}
                      selectedKey={
                        data.updateDto.validation.rejectionReason
                      }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <label id={`${componentName}-ValidationCodeTypeText`} className="d-block">
                    Code Type
                    {data.info.requireCode ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <SimpleDDL
                    id={`${componentName}-ValidationCodeTypeValue`}
                    list={codeTypes}
                    textSelector={(x) => x.value}
                    valueSelector={(x) => x.value}
                    onChange={(x) =>
                      handleOpportunityChange("validationCodeType", x)
                    }
                    disabled={!data.info.requireCode}
                    selectedKey={
                      data.updateDto.validation.validationCodeType
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group id={`${componentName}-ValidationCode`}>
                  <label id={`${componentName}-ValidationCodeText`} className="d-block">
                    Code
                    {data.info.requireCode ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <Form.Control
                    id={`${componentName}-ValidationCodeValue`}
                    type="text"
                    name="code"
                    value={data.updateDto.validation.validationCode}
                    onChange={(e) =>
                      handleOpportunityChange(
                        "validationCode",
                        e.target.value
                      )
                    }
                    disabled={!data.info.requireCode}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group id={`${componentName}-ValidationICD10Code`}>
                  <label id={`${componentName}-ValidationICD10CodeText`} className="d-block">
                    ICD-10 Code
                    {data.info.requireIcd10 ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <StyledTypeAhead
                    filterBy={filterBy}
                    id={`${componentName}-ICD10CodeTypeAhead`}
                    inputProps={{
                      id: `${componentName}-ICD10CodeValue`,
                    }}
                    labelKey="code"
                    isLoading={isLoading}
                    minLength={2}
                    onSearch={asyncCodeSearch}
                    options={optionsCode}
                    selected={
                      data.updateDto.icd10DiseaseDto?.code
                        ? [data.updateDto.icd10DiseaseDto?.code]
                        : []
                    }
                    onChange={(e) => handleIcdChange(e)}
                    disabled={!data.info.requireIcd10}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6}>
                <Form.Group id={`${componentName}-ValidationICD10Desc`}>
                  <label id={`${componentName}-ValidationICD10DescText`} className="d-block">
                    ICD-10 description
                    {data.info.requireIcd10 ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                  <StyledTypeAhead
                    filterBy={filterBy}
                    id={`${componentName}-ValidationICD10DescTypeAhead`}
                    inputProps={{
                      id: `${componentName}-ValidationICD10DescValue`,
                    }}
                    labelKey="description"
                    isLoading={isLoading}
                    minLength={2}
                    onSearch={asyncDescSearch}
                    options={optionsDesc}
                    selected={
                      data.updateDto.icd10DiseaseDto?.description
                        ? [data.updateDto.icd10DiseaseDto?.description]
                        : []
                    }
                    onChange={(e) => handleIcdChange(e)}
                    disabled={!data.info.requireIcd10}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  id={`${componentName}-UpdatedDateOfService`}
                >
                  <label id={`${componentName}-UpdatedDateOfServiceText`} className="d-block">
                    Edit Date of Service
                  </label>
                  <EQHDatePicker
                    id={`${componentName}-UpdatedDateOfServiceValue`}
                    value={
                      data.updateDto.validation.revisedDateOfService
                    }
                    onChange={(e) => {
                      handleOpportunityChange(
                        "revisedDateOfService",
                        e
                      );
                    }}
                    max={"9999-12-31"}
                    disabled={!data.info.enableRevisedDate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {data.updateDto.validation.validation === "REJECTED" ? (<label className="d-block">Recommendation</label>)
                : (<label className="d-block">Add Notes</label>)}
                <textarea id={`${componentName}-addNotesValue`} 
                 onChange={(e) =>handleOpportunityChange("note", e.target.value)}
                 className="form-control" rows="3"
                 disabled={isEmpty(data.updateDto.validation.validation)}
                 value={data.updateDto.validation.note}></textarea>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal.Footer>
        <Row>
          <Col className="text-end">
            <Button id={`${componentName}-cancelValidationBtn`} variant="secondary" data-bs-dismiss="offcanvas" onClick={() => setShow(false)} className="me-3">Cancel</Button>
            <Button id={`${componentName}-updateValidationBtn`} variant="primary" disabled={!enableButton() || false} onClick={() => { validatePatientEvidence(() => setData(getDefaultData(dateOfServiceOverride))); }}>
              {isUpdating ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Updating...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Card.Body>
  );
};

export { ValidationComponent, getDefaultData };
