import React from 'react'
import Modals from '../../../../../../components/modals'
import { ReferralModal } from '../../../../../quickList/ReferraModal'
import { useCMWorklistContext } from '../context/CMWorklist.context';

const ManageReferral = () => {
  const {
    toggleUpdateReferralStatus,
    updateReferralStatus,
    setPatientSearch,
    patient,
    patientSearch,
    getPatientsList
  } = useCMWorklistContext();

  return (
    <Modals
      show={updateReferralStatus}
      onHide={() => toggleUpdateReferralStatus(false)}
      title={"Manage Referral"}
      titleid={"ManageReferral"}
      size="xl"
      hideFooter={true}
      completeBtn={"SAVE CHANGES"}
      body={
        <ReferralModal
          closeModal={() => toggleUpdateReferralStatus(false)}
          quickListType={'REFERRALS_RECEIVED'}
          updateParent={getPatientsList}
          reloadPatientView={() => setPatientSearch({
            ...patientSearch,
            pageNumber: 1,
            pageSize: 20,
            previousTotalSize: 0
          })}
          referralObject={{
            id: patient?.referralDtoList?.[0]?.id,
            status: patient?.referralDtoList?.[0]?.status
          }}
        />
      }
    ></Modals>
  )
}

export default ManageReferral