export function getGetters(state) {
  return {
    isSaveAsCustomWorklistEnabled: state.isSaveAsCustomWorklistEnabled,
    customWorklistError: state.customWorklistError,
    isDeleteCustomWorklistEnabled: state.isDeleteCustomWorklistEnabled,
    customWorklist: state.customWorklist,
    customFilters: state.customFilters,
    saveAsCustomWorklistRequest: state.saveAsCustomWorklistRequest,
    patientSearchCustomWorklist: state.patientSearchCustomWorklist,
    getCustomWorklistRequest: state.getCustomWorklistRequest
  }
}