import axios from "axios";

const DROPDOWN_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/configuration/care-management-configuration";
const URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/care-manager-workspace/";
const FOLLOWUP_URL = `${URL}follow-up`;
const MODULE = "CONFIGURATION";
const PREFIX = "FOLLOW_UP_";


export const getCategories = async () => {
    let params = {
        "module": MODULE,
        "dropListName": `${PREFIX}CATEGORY`
    }
    return axios.get(DROPDOWN_URL, {params});
}

export const getScheduleWithOptions = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "module": MODULE,
            "dropListName": `${PREFIX}SCHEDULED_WITH`
        }});
}

export const getTypes = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "module": MODULE,
            "dropListName": `${PREFIX}TYPE`
        }});
}

export const getOutcomes = async () => {
    return axios.get(DROPDOWN_URL, { params: {
            "module": MODULE,
            "dropListName": `${PREFIX}OUTCOME`
        }});
}

export const submitFollowUp = (data)=> {
    return axios
        .post(FOLLOWUP_URL, data, {
            headers: {
                "Content-Type": "application/json"
            },
        });
}