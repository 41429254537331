import {
  patientFilterRiskLevel,
  patientFilterPayer,
  patientFilterPCPPractice,
  patientFilterPCP,
  patientFilterADTDisposition,
  patientFilterADTByFacility,
  patientFilterCareCoordinator,
  patientFilterCity,
  patientFilterCarePathCoordinator,
  patientFilterCarePath,
  patientFilterPatients,
  patientFilterPatientStatus,
  patientFilterState,
  patientFilterZipCode,
  patientFilterMemberWorklist,
  patientFilterReferralSentStatus,
  patientFilterReferralReceivedStatus,
  patientFilterReferralUrgency,
  patientFilterValidation,
  patientFilterOpportunityDefinition,
  patientFilterOpportunityStatus,
  patientFilterSuspectStatus,
  patientFilterMeasureYear,
  patientFilterWC15Approved,
  patientFilterChronicCondition,
  patientFilterConditionStatus,
  patientFilterMsaRegions,
  patientFilterBoolean,
  patientFilterLineOfBusiness,
  patientFilterEmpowerReferralStatus,
  patientFilterProvider
} from "../../actions/patientFilterActions";

import {
  getStates,
} from "../../actions/constantsActions";

import {
  DDLDataMode,
  DDLMode,
  GENERIC_DDL_TYPE,
} from "../dropDownLists/genericDDL";

import {
  ComparisonDDLKind,
  COMPARISON_DDL_TYPE,
} from "../dropDownLists/comparisonDDL";

import { Free_Text_TYPE } from "../freeText";
import { searchStaffMembers } from "../../actions/reportsActions";
import { getTeams } from "../../actions/caremgmt/careAdminAction";
import {
  fetchDropDownFiltered,
  fetchDropDownZones,
  fetchReferralTypes,
  getProgramLeads,
  fetchStates,
  findMarket,
  findNoteCategories,
  fetchCarePathStatuses
} from "../../views/caremgmt/careAdmin/DropDownLists/actions";
import {
  getDOChannel,
  getDONoteTypes,
  getDOSource,
  getDOType,
  getTaskTypes
} from "../../actions/caremgmt/myWorkspaceActions";
import {
  getIntactionOutcomeDropDown,
  getInteractionOutcomeDropDown,
  getInteractionTypeDropDown, getReAdmissionDropDown
} from "../../views/caremgmt/reportingAnalytics/utils";
import { getCategories, getOutcomes, getScheduleWithOptions, getTypes } from "../../views/caremgmt/careTeam/FollowUpModal/util";
import { getStepNameFilter } from "../../views/reports/utils";
import { READMIT_DDL_TYPE } from "../dropDownLists/readmitDDL";
import { orderBy } from 'lodash'
import {AppPropertiesUtil} from "../../utils/localStorageUtil";
import { fetchPrograms } from "../../views/caremgmt/careTeam/AddMemberModal/utils";

const SCHEDULE_DDL_TYPE = "ScheduleApptDDL";
const FILTER_DEFINITIONS = [
  {
    type: GENERIC_DDL_TYPE,
    key: "Risk Level",
    componentProps: {
      header: "Hello There",
      getData: (text) => patientFilterRiskLevel(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Patient Risk Level",
    header: 'Patient Risk Level',
    componentProps: {
      header: "Hello There",
      getData: (text) => patientFilterRiskLevel(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Risk Level new",
    header: 'Patient Risk Level',
    componentProps: {
      paramName: 'Risk Level',
      getData: (text) => patientFilterRiskLevel(text).then(x => x.data.map(r => {return {text: r.text, value: r.text}})),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "sourceNew",
    header: "Source",
    componentProps: {
      paramName: 'source',
      getData: () => fetchDropDownFiltered('WORKLIST_SOURCE').then(res => res.data.map(d=>{return{"text": d.text, "value": d.text}})),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Provider",
    componentProps: {
      paramName: 'provider',
      getData: (text) => patientFilterProvider(text, true).then((res) => res.data && orderBy(res.data, [item => item.text.toLowerCase()], ['asc'])),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Chronic Condition",
    componentProps: {
      header: "Hello There",
      getData: (text) => patientFilterChronicCondition(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Condition Status",
    componentProps: {
      header: "Hello There",
      getData: (text) => patientFilterConditionStatus(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Last Known Visit",
    componentProps: { allowRange: true },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Next upcoming Visit Date",
    componentProps: {
      allowRange: true,
      allowMonth: true,
      onlyShowRange: true,
      showClear: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Payor",
    componentProps: {
      getData: (text) => patientFilterPayer(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Care Step Type",
    header:'Care Step Name',
    componentProps: {
      getData: (text) => getStepNameFilter(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Assigned To",
    componentProps: {
      getData: (text) => getProgramLeads(text).then((res) => res.data.userList.map(r => {
        return {
          value: r?.userAccountId,
          text: r?.programLead,
        }
      })),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "PCP Practice",
    componentProps: {
      getData: (text) => patientFilterPCPPractice(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Sender PCP",
    header: "Sender - PCP Name",
    componentProps: {
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) => patientFilterPCP(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "PCP Filter On Full Name",
    header: "PCP Name2",
    componentProps: {
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) => patientFilterPCP(text, true).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "PCP Filter",
    header: "PCP Name1",
    componentProps: {
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) => patientFilterPCP(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "PCP",
    header: "PCP Name",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) => patientFilterPCP(text).then((res) => res.data),
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Latest Discharge Date",
    componentProps: {},
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "ADT Disposition",
    header: "Event Type",
    componentProps: {
      getData: (text) =>
        patientFilterADTDisposition(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "eventType",
    header: "Event Type1",
    componentProps: {
      getData: () => Promise.resolve([
      ])
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "ADT by Facility",
    header: "Facility",
    componentProps: {
      getData: (text) =>
        patientFilterADTByFacility(text).then((res) => res.data),
      showSearchTextbox: true,
    },
  },
  {
    type: Free_Text_TYPE,
    key: "Days Since Last ADT",
    componentProps: {
      comparisonValue: "gte",
      onlyNumbers: true
    },
  },
  {
    type: Free_Text_TYPE,
    header: "Patient Name",
    key: "Member Name",
    componentProps: {
      showSearchIcon: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Provider MSA",
    componentProps: {
      getData: (text) =>
        patientFilterMsaRegions(text).then((res) => res.data),
      showSearchTextbox: true,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Care Coordinator",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) =>
        patientFilterCareCoordinator(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,

    key: "Care Program Step Coordinator",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) =>
        patientFilterCareCoordinator(text).then((res) => res.data),
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "ADT Event Date",
    componentProps: {},
  },
  {
    type: Free_Text_TYPE,
    key: "Patient Name",
    componentProps: {},
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Birth Date",
    header: "DOB",
    componentProps: { allowRange: true, allowMonth: true },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Birth Date Comp",
    header: "DOB1",
    componentProps: {
      allowRange: true,
      allowMonth: true,
      onlyShowRange: true,
      showClear: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "City",
    header: "Patient City",
    componentProps: {
      getData: (text) => patientFilterCity(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Care Program Coordinator",
    componentProps: {
      mode: DDLMode.SelectOne,
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) =>
        patientFilterCareCoordinator(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Care Program",
    componentProps: {
      getData: (text) => patientFilterCarePath(text).then((res) => res.data),
      checkboxFilters: [
        {
          text: "Only Primary Care Program",
          filter: (item) => item.metadata && item.metadata.primary,
        },
      ],
    },
  },
  {
    type: READMIT_DDL_TYPE,
    key: "Readmit",
    header: "Readmit",
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Date",
    header: "Date",
    componentProps: { isDateDLL: true, onlyShowRange: true, showClear: false },
  },
  {
    type: SCHEDULE_DDL_TYPE,
    key: "ScheduleAppt",
    header: "Appointment Date",
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Patients",
    header: "Roster Status",
    componentProps: {
      getData: (text) => patientFilterPatients(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Patient Status",
    componentProps: {
      getData: (text) => patientFilterPatientStatus(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Patient Status Unique Selection",
    header: "Patient Status",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (text) => patientFilterPatientStatus(text).then((res) => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Risk Score",
    componentProps: {
      subType: ComparisonDDLKind.FreeText,
      type: ComparisonDDLKind.FreeText,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "State",
    componentProps: {
      getData: (text) => patientFilterState(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "State",
    key: "State_1_Select",
    componentProps: {
      mode: DDLMode.SelectOne,
      showSearchTextbox: false,
      highlightWhenHasValue: false,
      showClearButton: false,
      getData: (text) => getStates([true]).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Zip Code",
    header: "Patient Zip Code",
    componentProps: {
      getData: (text) => patientFilterZipCode(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Member Worklist",
    componentProps: {
      getData: (text) =>
        patientFilterMemberWorklist(text).then((res) => res.data),
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Due Date",
    header: "Care Program Step Due Date",
    componentProps: { allowRange: true, onlyShowRange: true},
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Care Program Step Due Date",
    header: "Care Program Step Due Date",
    componentProps: {
      allowRange: true,
      allowMonth: true,
      onlyShowRange: true,
      showClear: false,
    }
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Referral Received Status",
    header: "Referral Status",
    componentProps: {
      getData: (text) =>
        patientFilterReferralReceivedStatus(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Referral Sent Status",
    header: "Referral Status",
    componentProps: {
      getData: (text) =>
        patientFilterReferralSentStatus(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Referral Urgency",
    componentProps: {
      getData: (text) =>
        patientFilterReferralUrgency(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Validation",
    header: "Validation Status",
    componentProps: {
      getData: (text) => patientFilterValidation(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Opportunity Definition",
    header: "Care Opportunity Name",
    onLoad: (externalDataMap, selectedData, setData, lastFilterParamChanged) => {
      const ssFilterParam = externalDataMap.get("Suspect Status");
      if (
        ssFilterParam &&
        ssFilterParam.paramValue &&
        ssFilterParam.paramValue.length &&
        selectedData.size > 0 &&
        lastFilterParamChanged.paramName !== "Care Opportunity Name" &&
        lastFilterParamChanged.paramName !== "Opportunity Definition"

      ) {
        setData(new Map());
      }
    },
    componentProps: {
      getData: (text) =>
        patientFilterOpportunityDefinition(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Opportunity Status",
    header: "Care Opportunity Status",
    componentProps: {
      getData: (text) =>
        patientFilterOpportunityStatus(text).then((res) => {
          return res.data;
        }),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Suspect Status",
    onLoad: (externalDataMap, selectedData, setData, lastFilterParamChanged) => {
      const ssFilterParam = externalDataMap.get("Opportunity Definition")?
          externalDataMap.get("Opportunity Definition"):externalDataMap.get("Care Opportunity Name");
      if (
        ssFilterParam &&
        ssFilterParam.paramValue &&
        ssFilterParam.paramValue.length &&
        selectedData.size > 0 &&
        lastFilterParamChanged?.paramName !== "Suspect Status"
      ) {
        setData(new Map());
      }
    },
    componentProps: {
      getData: (text) =>
        patientFilterSuspectStatus(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Measure Year",
    header: "Care Opportunity Year",
    componentProps: {
      getData: (text) => patientFilterMeasureYear(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "WC15 Approved",
    componentProps: {
      getData: (text) => patientFilterWC15Approved(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    getHeader: () => "Referral Type",
    key: "Referral Type",
    onLoad: (externalDataMap, selectedData, setData, lastFilterParamChanged) => {
      const ssFilterParam = externalDataMap.get("Visit Type");
      if (
          ssFilterParam &&
          ssFilterParam.paramValue &&
          ssFilterParam.paramValue.length &&
          selectedData.size > 0 &&
          lastFilterParamChanged.paramName === "Visit Type"
      ) {
        setData(new Map());
      }

      const ssFilterParamServices = externalDataMap.get("Services Needed");
      if (
          ssFilterParamServices &&
          ssFilterParamServices.paramValue &&
          ssFilterParamServices.paramValue.length &&
          selectedData.size > 0 &&
          lastFilterParamChanged.paramName === "Services Needed"
      ) {
        setData(new Map());
      }
    },
    componentProps: {
      mode: DDLMode.MultipleSelect,
      dataMode: DDLDataMode.OneAPICallFilterInUI,
      showSearchTextbox: true,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (srchTxt) =>
          Promise.resolve([
            {
              text: "All",
              subItems: [
                { text: "Specialist", value: "SPECIALIST" },
                { text: "Care Coordination", value: "CARE_COORDINATION" }
              ],
            },
          ]),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    getHeader: () => "Visit Type",
    key: "Visit Type",
    onLoad: (externalDataMap, selectedData, setData, lastFilterParamChanged) => {
      const ssFilterParam = externalDataMap.get("Referral Type");
      if (
          ssFilterParam &&
          ssFilterParam.paramValue &&
          ssFilterParam.paramValue.length &&
          selectedData.size > 0 &&
          lastFilterParamChanged.paramName === "Referral Type"
      ) {
        setData(new Map());
      }
    },
    componentProps: {
      mode: DDLMode.MultipleSelect,
      dataMode: DDLDataMode.OneAPICallFilterInUI,
      showSearchTextbox: true,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (srchTxt) =>
        Promise.resolve([
          { text: "Consult, Evaluate, and Treat", value: "CONSULT_EVALUATE_TREAT" },
          { text: "Consultation", value: "CONSULTATION" },
          { text: "Evaluation and Treatment", value: "EVALUATION_AND_TREATMENT" },
          { text: "Second Opinion", value: "SECOND_OPINION" },
          { text: "Established Patient Follow Up", value: "PATIENT_FOLLOW_UP" },
        ]),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    getHeader: () => "Services Needed",
    key: "Services Needed",
    onLoad: (externalDataMap, selectedData, setData, lastFilterParamChanged) => {
      const ssFilterParam = externalDataMap.get("Referral Type");
      if (
          ssFilterParam &&
          ssFilterParam.paramValue &&
          ssFilterParam.paramValue.length &&
          selectedData.size > 0 &&
          lastFilterParamChanged.paramName === "Referral Type"
      ) {
        setData(new Map());
      }
    },
    componentProps: {
      mode: DDLMode.MultipleSelect,
      dataMode: DDLDataMode.OneAPICallFilterInUI,
      showSearchTextbox: true,
      highlightWhenHasValue: false,
      showClearButton: true,
      getData: (srchTxt) =>
      Promise.resolve([
        { text: "Transportation", value: "TRANSPORTATION" },
        { text: "Food Support", value: "FOOD_SUPPORT" },
        { text: "Housing Support", value: "HOUSING_SUPPORT" },
        { text: "Disease Education & Management", value: "DISEASE_EDUCATION_MANAGEMENT" },
        { text: "Medication Education", value: "MEDICATION_EDUCATION" },
        { text: "Behavioral Health Referral Assistance", value: "BEHAVIORAL_REFERRAL_ASSISTANCE" },
        { text: "Spiritual Support", value: "SPIRITUAL_SUPPORT" },
        { text: "Complex Care", value: "COMPLEX_CARE" },
        { text: "Annual Comprehensive Visit", value: "ANNUAL_COMPREHENSIVE_VISIT" },
      ]),
    },
  },
  {
    key: "Reports Time Range",
    header: "Time Range",
    type: COMPARISON_DDL_TYPE,
    componentProps: { allowRange: true, highlightWhenHasValue: false, onlyShowRange: true, showRequired: true },
  },
  {
    key: "Time Range",
    header: "Date Range",
    type: COMPARISON_DDL_TYPE,
    componentProps: { allowRange: true, highlightWhenHasValue: false, onlyShowRange: true, showRequired: true },
  },
  {
    key: "Staff Member",
    type: GENERIC_DDL_TYPE,
    componentProps: {
      mode: DDLMode.SelectOne,
      highlightWhenHasValue: false,
      showRequired: true,
      getData: (srchTxt) => searchStaffMembers([""]).then(res => res.data.map(x => {return {text: x.fullName, value: x.id}}))
    },
  },
  {
    type: Free_Text_TYPE,
    key: "Member Id",
    componentProps: { showRequired: true, highlightWhenHasValue: false}
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Reports Status",
    header: "Status",
    componentProps: {
      highlightWhenHasValue: false,
      showRequired: true,
      mode: DDLMode.SelectOne,
      showSearchTextbox: false,
      getData: (srchTxt) => Promise.resolve([
        { text: "Open Care Program", value: "OPEN_CARE_PATHS" },
        { text: "Closed Care Programs", value: "CLOSED_CARE_PATHS" },
        { text: "Both Open And Closed Care Programs", value: "BOTH" },
      ]),
    }
  },
  {
    type: GENERIC_DDL_TYPE,
    getHeader: () => "Assessment Template",
    key: "assessmentTemplate",
    componentProps: {
      mode: DDLMode.SelectOne,
      highlightWhenHasValue: false,
      showClearButton: false,
    }
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Patient Expired",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (srchTxt) => Promise.resolve([
        { text: "True", value: "true" },
        { text: "False", value: "false" }
      ]),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Status Type",
    header: "Query Type",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (srchTxt) => Promise.resolve([
        { text: "Historical", value: false },
        { text: "Most recent", value: true }
      ]),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Team",
    key: "Teams",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (srchTxt) => getTeams().then(res => {
        let dataParsed = []
        res.data.forEach(e => {
          dataParsed.push({
            text: e.name,
            value: e.teamId
          })
        });

        return (
          Promise.resolve(dataParsed)
        )
      }),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Care Program Status",
    key: "Program Status",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => fetchDropDownFiltered('PROGRAM_STATUS_FILTER', 'ALL').then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Source",
    key: "Referral Source",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => fetchDropDownFiltered('REFERRAL_SOURCE').then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Program Lead",
    key: "Program Lead",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getProgramLeads().then(res => {
        const data = res?.data?.userList?.map(user => ({
          text: user?.programLead || '',
          value: user?.careManagementAssignmentId || ''
        }));
        return data;
      }),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Intervention Type",
    key: "Intervention type",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getDOType().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Intervention Channel",
    key: "Intervention channel",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getDOChannel().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Intervention Source",
    key: "Intervention source",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getDOSource().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Intervention Outcome",
    key: "Intervention outcome",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getDONoteTypes().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Follow-Up Category",
    key: "FollowUp category",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getCategories().then(res => res.data.map(x => {
        return {value: x?.value, text: x?.value}
      })),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Follow-Up Type",
    key: "FollowUp type",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getTypes().then(res => res.data.map(x => {
        return {value: x?.value, text: x?.value}
      })),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Follow-Up Schedule With",
    key: "FollowUp Schedule With",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getScheduleWithOptions().then(res => res.data.map(x => {
        return {value: x?.value, text: x?.value}
      })),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Follow-Up Outcome",
    key: "FollowUp Outcome",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => getOutcomes().then(res => res.data.map(x => {
        return {value: x?.value, text: x?.value}
      })),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Payer",
    key: "Payer SOO",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (text) => patientFilterPayer(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Care Program",
    key: "Care Program",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: (srchTxt) => fetchDropDownFiltered('CARE_PROGRAMS').then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Care Program Name",
    key: "Care Program Name",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      getData: () => fetchPrograms().then(res => res.data.map(d=>{return{"text": d.text, "value": d.text}})),
      showSearchTextbox: true,
      showClearButton: false,
      showSelectClearAllButton: false,
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    key: "Expired Date",
    componentProps: { allowRange: true},
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Line of Business",
    componentProps: {
      dataMode: DDLDataMode.APICallOnEveryFilter,
      getData: (text) => patientFilterLineOfBusiness(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Interaction Type",
    key: "Interaction type",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      getData: () => getInteractionTypeDropDown().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Interaction Outcome",
    key: "Interaction outcome",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      getData: () => getInteractionOutcomeDropDown().then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Referral Status",
    header: "Referral Status",
    componentProps: {
      getData: (text) =>
          patientFilterEmpowerReferralStatus(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Readmit",
    key: "reAdmission",
    componentProps: {
      getData: () =>
          getReAdmissionDropDown().then((res) => res.data.map(d=>{return{"text": d.value, "value": d.key}})),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Step Name",
    key: "Step Name",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () =>
          getStepNameFilter().then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Program",
    header: "Program",
    componentProps: {
      showSearchTextbox: false,
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "1", text: "Yes"}, {value: "0", text: "No"}])
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "existingProgram",
    header: "Existing Program",
    componentProps: {
      showSearchTextbox: false,
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "1", text: "Yes"}, {value: "0", text: "No"}])
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Source",
    key: "source",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => fetchDropDownFiltered('REFERRAL_SOURCE').then(res => res.data.map(d=>{return{"text": d.text, "value": d.text}})),
      showSearchTextbox: false,
    },
  },
  {
    type: COMPARISON_DDL_TYPE,
    header: "Admit Date",
    key: "latestAdmitDate",
    componentProps: { allowRange: false, allowMonth: false },
  },  {
    type: COMPARISON_DDL_TYPE,
    header: "Admit Date",
    key: "latestAdmissionDate",
    componentProps: { allowRange: false, allowMonth: false },
  },
  {
    type: COMPARISON_DDL_TYPE,
    header: "Creation Date",
    key: "assignDate",
    componentProps: { allowRange: false, allowMonth: false },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "lastPCPVisitFilter",
    header: "Last PCP Visit",
    componentProps: {
      showSearchTextbox: false,
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "Y", text: "Yes"}, {value: "N", text: "No"}])
    }
  },
  {
    type: Free_Text_TYPE,
    key: "memberIds",
    header: "MemberID",
    componentProps: { showRequired: false, highlightWhenHasValue: false}
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "lineOfBusiness",
    header: "Line of Business",
    componentProps: {
      dataMode: DDLMode.SelectOne,
      getData: (text) => patientFilterLineOfBusiness(text).then((res) => res.data),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "Task State",
    header: "Status",
    componentProps: {
      showSearchTextbox: false,
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "OPEN", text: "Open"},{value: "CLOSED", text: "Closed"}])
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    key: "passwordChangedStatus",
    header: "Status",
    componentProps: {
      showSearchTextbox: false,
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "MIGRATED", text: "Migrated"},{value: "PENDING", text: "Pending"}])
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Readmit",
    key: "readmitYN",
    componentProps: {
      getData: () => Promise.resolve([{value: 1, text: "Yes"}, {value: 0, text: "No"}]),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Zone",
    key: "zone",
    componentProps: {
      showSearchTextbox: true,
      dataMode: DDLMode.SelectOne,
      getData: () => fetchDropDownZones('ACTIVE').then((res) => res.data.map(d=>{return{"text": d.zoneName, "value": d.zoneName}})),
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Status",
    key: "worklistStatus",
    componentProps: {
      getData: () => {
        const values = [
          {value: "APPOINTMENT_UNSCHEDULED", text: "Unscheduled"},
          {value: "LEFT_MESSAGE", text: "Left Message"},
          {value: "UNABLE_TO_CONTACT", text: "Unable to Contact"},
          {value: "PATIENT_REFUSAL", text: "Patient Refused"},
          {value: "CHANGED_PCPS", text: "Changed PCP"},
        ]
        return Promise.resolve(values)
      },
      showSearchTextbox: false
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Missed Opportunity",
    key: "missedOppStatus",
    componentProps: {
      getData: () => {
        const values = [
          {value: "REQUIRED_ACTIVITIES", text: "Required Activities"},
          {value: "AWAITING_CHART", text: "Awaiting Chart"},
          {value: "REJECTED", text: "Rejected"},
        ]
        return Promise.resolve(values)
      },
      showSearchTextbox: false
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Engaged with PCP",
    key: "lostMember",
    componentProps: {
      getData: () => Promise.resolve([{value: "yes", text: "Yes"},{value: "no", text: "No"}]),
      showSearchTextbox: false
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Program Status",
    key: "Referrals Program Status",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => fetchDropDownFiltered('PROGRAM_STATUS_FILTER', 'ALL').then(res => res.data),
      showSearchTextbox: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Quarterly Visit Eligible",
    key: "ecipEligible",
    componentProps: {
      getData: () => Promise.resolve([{value: "1", text: "Yes"}, {value: "0", text: "No"}]),
      showSearchTextbox: false
    },
  },
  {
    type:GENERIC_DDL_TYPE ,
    header: "User Name",
    key: "User Name",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: true,
      showClearButton: false,
      showSelectClearAllButton: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Type of Referral",
    key: "Type of Referral",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: false,
      showSelectClearAllButton: false,
      getData: () => fetchReferralTypes().then(response => response.data)
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Market",
    key: "States",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: false,
      showSelectClearAllButton: false,
      getData: () => fetchStates().then(response => response.data)
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Market",
    key: "fileinput",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: false,
      showSelectClearAllButton: false,
      getData: () => findMarket().then(response => response.data)
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Category",
    key: "Note Category",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: true,
      showSelectClearAllButton: false,
      getData: () => findNoteCategories().then(
        response => response.data && orderBy(response.data,
          [item => item.description.toLowerCase()], ['asc']).map(
          category => ({text: category.description, value: category.name})))
    },
  },
  {
    type:GENERIC_DDL_TYPE ,
    header: "Note Author",
    key: "Note Author",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: true,
      showClearButton: false,
      showSelectClearAllButton: false,
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Has Attachment",
    key: "Has Attachment",
    componentProps: {
      mode: DDLMode.SelectOne,
      getData: () => Promise.resolve([{value: "yes", text: "Yes"},{value: "no", text: "No"}]),
      showSearchTextbox: false
    },
  },
  {
    type: GENERIC_DDL_TYPE,
    header: "Care Program Status",
    key: "Care Path Status",
    componentProps: {
      mode: DDLMode.MultipleSelect,
      showSearchTextbox: false,
      showSelectClearAllButton: false,
      getData: () => fetchCarePathStatuses().then(response => response.data)
    },
  },
  {
    type: Free_Text_TYPE,
    header: "Current Hospital",
    key: "Hospital",
    componentProps: {
      showSearch: false,
    },
  },
];

const FILTER_DEFINITIONS_MAP = FILTER_DEFINITIONS.reduce(function (r, e) {
  r[e.key] = e;
  e["getHeader"] = function () {
    return this.header ?? this.key;
  };
  e["getKey"] = function () {
    return this.key;
  };
  return r;
}, {});

const getFilterDef = (key, componentProps, replaceHeader) => {
  const fd = FILTER_DEFINITIONS_MAP[key];
  const fdClone = componentProps ? {...fd, componentProps: {...fd.componentProps, ...componentProps}} : {...fd};

  if (replaceHeader) {
    fdClone.getHeader = () => replaceHeader;
  }

  return fdClone;
};

export { FILTER_DEFINITIONS_MAP, getFilterDef };
