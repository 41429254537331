import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useAppointmentsContext } from '../context/Appointments.context';

const FilterBar = ({ patient, setAppointments }) => {
  const {
    getAppointments,
  } = useAppointmentsContext();
  const [activeFilter, setactiveFilter] = useState('ALL');

  const handleFilter = (filter) => {
    setactiveFilter(filter)
    const param = [{
      "paramName": "dataType",
      "paramValue": [
        filter
      ],
      "comparison": "eq",
      "displayValue": [
        filter
      ]
    }]
    getAppointments(patient?.id, param)
  }

  return (
    <Row className="filterBar flex-nowrap me-3">
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0 rounded-start ${activeFilter === 'ALL' ? 'custom-active' : ''}`}
          onClick={() => handleFilter('ALL')}
        >All</Button>
      </Col>
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0  ${activeFilter === 'UPCOMING' ? 'custom-active' : ''}`}
          onClick={() => handleFilter('UPCOMING')}
        >Upcoming</Button>
      </Col>
      <Col className="col-md-auto p-0">
        <Button
          variant='light'
          className={`border rounded-0 rounded-end  ${activeFilter === 'PAST' ? 'custom-active' : ''}`}
          onClick={() => handleFilter('PAST')}
        >Past</Button>
      </Col>
    </Row>
  )
}

export default FilterBar