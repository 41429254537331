import React, { useState } from "react";
import { Container, Button, Row, Col, OverlayTrigger, Tooltip, Spinner, Badge } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { formatDate, getRiskLevelStyle, getscraScoreStyle } from "../../utils/util";
import Modals from "../../components/modals";
import EmailComponent from "../header/message/EmailComponent";
import { PrevisitChart } from "../quickList/components/modals/PrevisitChart";
import QuarterlyVisitDueIcon from "../../img/quarterlyvisitdue-icon.svg";
import {AppPropertiesUtil, SessionStorageUtil} from "../../utils/localStorageUtil";

const componentName = "PatientProfileHeader";

const LAST_QUERY_USAGE_KEY = `ReloadQL`;
const PatientProfileHeader = ({ patientOverview, setShowCPT }) => {
  const enableVistDue = AppPropertiesUtil.get("react.properties.ecip.eligible.enabled");
  const history = useHistory();

  const handleBackClick = () => {
    SessionStorageUtil.setObj(LAST_QUERY_USAGE_KEY, true);
    const params = new URLSearchParams(window.location.search);
    const allParams = {};
    for (const [key, value] of params.entries()) {
      allParams[key] = value;
    }
    history.goBack();
    setTimeout(() => {
     if (allParams?.returnTo?.includes("patient-profile")) {
       window.location.reload();
     }
    }, 100);
  };
  const [showPrintSpinner, setShowPrintSpinner] = useState(false);

  const [modalSize, setModalSize] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalMessage, setModalMessage] = useState(null)

  return (
    <>
      <Modals
        show={modalMessage != null}
        onHide={() => setModalMessage(null)}
        title={modalTitle}
        size={modalSize || "md"}
        hideClickButton={false}
        showExtraButton={false}
        hideFooter={true}
        body={modalMessage}
      />
    <Container id={`${componentName}-patientPageTitle`} fluid className="patient-header page-titles mb-0">
      <Row className="g-0 pt-2">
        <Col lg={5} md={11} sm={11}>
          <div className="d-flex flex-wrap align-items-center">
            <div className="me-3">
              <a href={handleBackClick} onClick={handleBackClick}>
                <i id={`${componentName}-patientBack`} className="fa fa-chevron-left fa-3x cursor-p" />
              </a>
            </div>
            <div className="me-3">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{patientOverview.riskLevel}</Tooltip>}
              >
                <Badge pill bg={getRiskLevelStyle(patientOverview.riskLevel)} id={`${componentName}-patientRiskLevel`} className="me-2">
                  {patientOverview.riskLevelCode}
                </Badge>
              </OverlayTrigger>
              <span id={`${componentName}-patientFullName`} className="h4 fw-bold align-middle me-2 patientName">
                <span id={`${componentName}-patientLastName`}>{patientOverview.lastName}</span>,{" "}
                <span id={`${componentName}-patientFirstName`}>{patientOverview.firstName}</span>{" "}
                <span id={`${componentName}-patientMiddleName`}>{patientOverview.middleName}</span>
              </span>
              <Badge pill id={`${componentName}-patientGender`} bg="light" text="dark" className="border forced-capital me-2">
                {patientOverview.gender}
              </Badge>
              <Badge pill bg={getscraScoreStyle(patientOverview.scraScore)} id={`${componentName}-patientSDOH`}>
                SDOH: {patientOverview.scraScore != null ? patientOverview.scraScore : "0"}
              </Badge>
              {enableVistDue && patientOverview.ecipEligible && (
                  <OverlayTrigger placement="auto" overlay={<Tooltip>Quarterly Visit Eligible</Tooltip>}>
                      <img src={QuarterlyVisitDueIcon} alt="Quarterly Incentive Icon" width="25px" className="ms-2"></img>
                  </OverlayTrigger>
              )}
            </div>
            <div className="ms-auto me-4">
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{display: showPrintSpinner ? "inline-block" : "none"}}
              />
              <li className="nav-item d-inline-block align-top">
              </li>
              <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip>Send Secure Message</Tooltip>}
              >
                <EmailComponent patientSpecific={true} selectedPatient={patientOverview.id} />
              </OverlayTrigger>
              <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip>Chart Prep Tool</Tooltip>}
              >
                <Button variant="primary" id={`${componentName}-patientPrint`} disabled={!patientOverview.id} size="sm" onClick={() => setShowCPT(true)} className="border-0 py-1 px-2">
                  <i className="fas fa-print"></i>
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <div className="d-flex flex-wrap">
            <div id={`${componentName}-patientDOB`} className="me-4 mb-2">
              <span id={`${componentName}-patientDOBLabel`} className="fw-bold d-block">DOB</span>
              <span id={`${componentName}-patientDOBValue`}>{patientOverview.dateOfBirth != null
                ? formatDate(patientOverview.dateOfBirth)
                : ""}</span>
            </div>
            <div id={`${componentName}-patientMemberID`} className="me-4 mb-2">
              <span id={`${componentName}-patientMemberIDText`} className="fw-bold d-block">Member ID</span>
              <span id={`${componentName}-patientMemberIDValue`}>{patientOverview.memberId}</span>
            </div>
            <div id={`${componentName}-patientPrimaryPhone`} className="me-4 mb-2">
              <span id={`${componentName}-patientPrimaryPhoneLabel`} className="fw-bold d-block">Primary Phone</span>
              <span id={`${componentName}-patientPrimaryPhoneValue`}>{patientOverview.primaryPhone}</span>
            </div>
            <div id={`${componentName}-patientAddress`} className="me-4 mb-2 w-25">
              <span id={`${componentName}-patientAddressLabel`} className="fw-bold d-block">Address</span>
              <OverlayTrigger
                placement="auto"
                overlay={<Tooltip>{patientOverview.address}</Tooltip>}
              >
                <div id={`${componentName}-patientAddressValue`} className="text-truncate">{patientOverview.address}</div>
              </OverlayTrigger>
            </div>
            <div id={`${componentName}-patientAltPhone`} className="mb-2">
              <span id={`${componentName}-patientAltPhoneLabel`} className="fw-bold d-block">Alt Phone</span>
              <span id={`${componentName}-patientAltPhoneValue`}>{patientOverview.altPhone}</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="bg-light pt-2">
        <Col lg={5} md={11} sm={10}>
          <Row>
            <Col id={`${componentName}-patientPayor`} className="mb-2">
              <span id={`${componentName}-patientPayorLabel`} className="fw-bold d-block">Payor</span>
              <OverlayTrigger
                placement="auto"
                overlay={<Tooltip>{patientOverview.payer}</Tooltip>}
              >
                <div id={`${componentName}-patientPayorValue`} className="text-truncate">{patientOverview.payer}</div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="auto"
                overlay={<Tooltip>{patientOverview.secondPayer}</Tooltip>}
              >
                <div id={`${componentName}-patientSecPayorValue`} className="text-truncate">
                  {patientOverview.secondPayer}
                </div>
              </OverlayTrigger>
            </Col>
            <Col id={`${componentName}-patientPayorStatus`} className="mb-2">
              <span id={`${componentName}-patientPayorStatusLabel`} className="fw-bold d-block">Payor Status</span>
              <span id={`${componentName}-patientPayorStatusValue`}>{patientOverview.membershipActive ? "Active" : "Inactive"}</span>
              <span id={`${componentName}-patientSecPayorStatusValue`} className="d-block">
                {patientOverview.secondPayer
                  ? patientOverview.secondMembershipActive
                    ? "Active"
                    : "Inactive"
                  : null}
              </span>
            </Col>
            {/*VEGA-3528: removed Primary Term Date. It will be added back when data is added to intake file*/}
          </Row>
        </Col>
        <Col lg={6} md={12}>
          <Row>
            <Col id={`${componentName}-patientPCP`} className="mb-2">
              <span id={`${componentName}-patientPCPLabel`} className="fw-bold d-block">PCP</span>
              <span id={`${componentName}-patientPCPValue`}>{patientOverview.pcp ? patientOverview.pcp.name : "-"}</span>
              <span id={`${componentName}-patientSecPCPValue`} className="d-block">
                {patientOverview.secondPcp
                  ? patientOverview.secondPcp.name
                  : null}
              </span>
            </Col>
            <Col id={`${componentName}-patientPCPPhone`} className="mb-2">
              <span id={`${componentName}-patientPCPPhoneLabel`} className="fw-bold d-block">PCP Phone</span>
              <span id={`${componentName}-patientPCPPhoneValue`}>{patientOverview.pcp ? patientOverview.pcp.phone : "-"}</span>
              <span id={`${componentName}-patientSecPCPPhoneValue`} className="d-block">
                {patientOverview.secondPcp
                  ? patientOverview.secondPcp.phone
                  : null}
              </span>
            </Col>
            <Col id={`${componentName}-patientPracticeName`} className="mb-2">
              <span id={`${componentName}-patientPracticeNameLabel`} className="fw-bold d-block">Practice Name</span>
              <OverlayTrigger
                placement="auto"
                overlay={<Tooltip>{patientOverview.originatingOrganization}</Tooltip>}
              >
                <div id={`${componentName}-patientPracticeNameValue`} className="text-truncate">
                  {patientOverview.originatingOrganization}
                </div>
              </OverlayTrigger>
              {(patientOverview.secondMembershipActive || patientOverview.expired) && (
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip>{patientOverview.secondOrganizationName}</Tooltip>
                  }
                >
                  <div id={`${componentName}-patientSecPracticeNameValue`} className="text-truncate">
                    {patientOverview.secondOrganizationName}
                  </div>
                </OverlayTrigger>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export { PatientProfileHeader, LAST_QUERY_USAGE_KEY };
