import React, { useEffect } from 'react'
import ExportSummary from '../../ExportSummary';
import Modals from '../../../../components/modals';
import { useNewRRContext } from '../context/NewRR.context';

const componentName = 'ExportSummary';

function ExportSummaryModal() {
  const {
    exportData,
    clearExportData
  } = useNewRRContext();

  return (
    <Modals
      show={exportData.length > 0}
      onHide={() => clearExportData()}
      title='Export Summary'
      size="lg"
      hideClickButton={true}
      showExtraButton={false}
      hideFooter={false}
      closeBtnId={`${componentName}-closeButton`}
      body={<ExportSummary exportSummary={exportData || []} />}
    />
  )
}

export default ExportSummaryModal