import { setHours, setMinutes } from 'date-fns'
import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FileUpload } from '../../../../../../components/FileUpload'
import DatePicker from "react-datepicker"
import { durationList } from '../../constants'
import axios from '../../../../../../utils/apiClient'
import { useAppointmentsContext } from '../../context/Appointments.context'
import { DropdownComponent } from '../../../../../../components/DropdownComponent'

import "../styles.css"
import { RedAsterisk } from '../../../../../../utils/util'

const baseURL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/appointment";

const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

function FirstPage({
  setFormBody,
  formBody,
  appointmentModalStatus,
  isLoading,
  patient,
  setIsLoading,
  fileUploadResetCounter,
  selectedPCP
}) {
  const {
    options,
    setOptions,
    selectedDate,
    setSelectedDate,
    addressList,
    setAddressList,
  } = useAppointmentsContext();

  useEffect(() => {
    if (formBody?.provider?.id) {
      findAddress(formBody?.provider?.id)
    }
  }, [])

  useEffect(() => {
    if (selectedPCP?.id) {
      handleChange([{
        provider: selectedPCP,
        text: `${selectedPCP?.firstName} ${selectedPCP?.lastName}`,
        value: selectedPCP?.id
      }])
    }
  }, [selectedPCP])

  const handleUploadFileChange = (arr) => {
    let newState = formBody;
    newState.uploadedFiles = [];
    newState.uploadedFiles.push(...arr);
    setFormBody({ ...newState });
  };

  const handleChange = (e) => {
    if (e && e[0]) {
      let prov = e[0];
      let newState = formBody;
      if (prov) {
        newState.provider = prov.provider;
        setFormBody({ ...newState });
      }
      findAddress(prov.value);
    }
  };

  const setSelectedDateFunc = (selectedDate) => {
    setSelectedDate(selectedDate);
    let newState = formBody;
    if (selectedDate) {
      const date = new Date(selectedDate);
      newState.appointmentDateTime = date;
      setFormBody({ ...newState });
    }
  };

  const asyncsearch = (query) => {
    const timeout = setTimeout(() => {
      if (query) {
        handleSearch(query);
      }
    }, 1600);

    return () => clearTimeout(timeout);
  };

  const handleSearch = (query) => {
    if (query) {
      const patientId =
        patient && patient.id ? encodeURIComponent(patient.id) : "";
      const url =
        baseURL +
        "/get-providers" +
        (query ? "?providerName=" + query : "") +
        (patientId ? "&patientId=" + patientId : "");
      setIsLoading(true);

      axios.get(url, { headers: {} })
        .then((resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i.id,
              text: i?.firstName + " " + i?.lastName,
              provider: i,
            }));
          }
          setOptions(options);
          setIsLoading(false);
        })
        .catch((error) => {
        });
    }
  }

  const findAddress = (id) => {
    if (id) {
      id = encodeURIComponent(id);
      axios
        .get(baseURL + `/get-provider-address?providerId=${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (resp) => {
          let options = [];
          if (resp && resp.data) {
            options = resp?.data?.map((i) => ({
              value: i,
              text: i,
            }));
          }
          setAddressList(options);
          let newState = formBody;
          newState.address = options[0].value;
          setFormBody({ ...newState });

        })
        .catch((error) => {
        });
    }
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} onClick={props.onClick} className="datePicker-container">
        <input
          {...props}
          type="text"
          value={props.value}
          className="form-control"
        />
        <div className="fa-regular fa-calendar calendar-icon"></div>
      </div>
    );
  });

  return (
    <Form className="first-page-form">
      <Form.Group controlId="formBasicEmail">
        <Row>
          <Form.Label className="fw-bold">
            Appointment Name<RedAsterisk/>
          </Form.Label>
          <Form.Control
            onChange={e => setFormBody({ ...formBody, name: e.target.value })}
            type="text"
            value={formBody?.name}
          />
        </Row>
        <Row>
          <DropdownComponent
            name="Status"
            list={appointmentModalStatus.map(i => ({value: i.value, text: i.text}))}
            handleInputChange={(_, type) => setFormBody({ ...formBody, status: type.value })}
            label="Appointment Status"
            required={true}
            isOpen={true}
            val={formBody?.status}
            disabled={false}
          />
        </Row>
        <Row>
          <Col style={{ padding: 0, marginRight: '15px' }}>
            <Form.Label className="fw-bold">
              Date of Appointment<RedAsterisk/>
            </Form.Label>
              <DatePicker
                name="dueDate"
                filterTime={filterPassedTime}
                value={selectedDate}
                selected={selectedDate}
                onChange={(e) => setSelectedDateFunc(e)}
                showTimeSelect
                className="form-control"
                minDate={new Date()}
                minTime={setHours(setMinutes(new Date(), 0), 6)}
                maxTime={setHours(setMinutes(new Date(), 30), 18)}
                autoComplete="off"
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<CustomInput />}
              />
          </Col>
          <Col style={{ padding: 0 }} md={4}>
            <DropdownComponent
              name="Duration"
              list={durationList.map(i => ({value: i.value, text: i.text}))}
              handleInputChange={(_, type) => setFormBody({ ...formBody, durationInMinutes: type.value })}
              label="Duration"
              required={true}
              isOpen={true}
              val={formBody?.durationInMinutes}
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Form.Label className="fw-bold">
            Provider<RedAsterisk/>
          </Form.Label>
          <AsyncTypeahead
            id="carepathcoor-typeahead-single"
            labelKey="text"
            isLoading={isLoading}
            onSearch={asyncsearch}
            options={options}
            onChange={(e) => handleChange(e)}
            placeholder={formBody?.provider?.firstName
              ? (formBody?.provider?.firstName + ' ' + formBody?.provider?.lastName)
              : "Search Providers by Name"
            }
            style={{ padding: 0 }}
          />
        </Row>
        <Row>
          <DropdownComponent
            name="address"
            list={addressList.map(i => ({value: i.value, text: i.text}))}
            handleInputChange={(_, type) => setFormBody({ ...formBody, address: type.value })}
            label="Provider Address"
            required={true}
            isOpen={true}
            val={formBody?.address}
            disabled={addressList?.length < 0}
          />
        </Row>
        <Row>
          <Form.Label>Optional Documentation</Form.Label>
          <div style={{ minHeight: '200px', padding: '0' }}>
            <FileUpload
              onChange={handleUploadFileChange}
              clearStateProp={fileUploadResetCounter}
              customCSS={{ padding: '0 !important' }}
            />
          </div>
        </Row>
      </Form.Group>
    </Form >
  )
}

export default FirstPage