import {actions} from "./actions";

export function getSetters(dispatch) {
  return {
    saveAsCustomWorklistRequest: payload => dispatch(
      {type: actions.SET_SAVE_CUSTOM_WORK_LIST_REQUEST, payload: payload}),
    setPatientSearch: payload => dispatch(
      {type: actions.SET_PATIENT_SEARCH, payload: payload}),
    setGetCustomWorklistRequest: payload => dispatch(
      {type: actions.SET_GET_CUSTOM_WORK_LIST_REQUEST, payload: payload}),
    setCustomWorklist: payload => dispatch(
      {type: actions.SET_CUSTOM_WORK_LIST, payload: payload}),
    setCustomFilters: payload => dispatch(
      {type: actions.SET_CUSTOM_FILTERS, payload: payload}),
    setCustomWorkListError: payload => dispatch(
      {type: actions.CUSTOM_WORKLIST_ERROR, payload: payload}),
  }
}