import React, { useState, Fragment } from "react";

import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { emptyFilterParam, simpleFilterParam } from "../../../dtos/PatientSearchDTO";
import { paramValue } from "../../dropDownLists/sharedDDL";
import { Filter } from "./components";
import { InputGroup } from "react-bootstrap";

const componentName = "FreeText";
const Free_Text_TYPE = "FreeText";

const FreeTextStyle = styled(Form.Control)`
  width: ${(props) => (props.cusWidth ? props.cusWidth : "auto")};
`;

const FreeText = ({
  header,
  handleChange,
  selected,
  width = "100%",
  highlightWhenHasValue = true,
  comparisonValue = "eq",
  externalDataMap,
  onLoad,
  showRequired,
  onlyNumbers,
  showSearch = true,
  showSearchIcon = true
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const text = paramValue(selected)[0];
  const idFragment = header.replaceAll(" ", "_").toUpperCase();

  const onChange = (value) => {
    handleChange(simpleFilterParam(header, comparisonValue, value));
  };

  const onTextChange = (e) => {
    const value = e.target.value ? e.target.value : "";

    if(onlyNumbers){
      const re = /^\d*(\.\d+)?$/;
      if(re.test(value)){
        onChange(value);
      }
    }else{
      onChange(value);
    }
  };

  if (onLoad) {
    onLoad(
      externalDataMap,
      simpleFilterParam(header, comparisonValue, text),
      onChange
    );
  }

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const notFocusedAndHashData = () => {
    return !isFocused && text;
  };

  const HeaderComponent = () => {
    return (
      <label id={`${componentName}-${idFragment}-filterTitle`} className="d-block">
        <span id={`${componentName}-${idFragment}-filterTitleText`}>{header}</span>
        {showRequired && <span className="text-danger">*</span>}
        {notFocusedAndHashData() ? (
          <>
            <i id={`${componentName}-${idFragment}-filterReset`} className="fas fa-circle fa-2xs cursor-p d-inline-block ms-2 me-0 text-primary"></i>
          </>
        ) : (
          ""
        )}
      </label>
    )
  }
  return (
    <Row lg={1}>
      <Col>
        <Filter index={`${componentName}-${idFragment}-filter`} header={<HeaderComponent />}>
          <InputGroup>
            {
              (showSearch && showSearchIcon) && ((!text || text?.length <= 0) && !onlyNumbers) &&
                <div className="search-icon" style={{ padding: "5px" }}>
                  <i className="fas fa-search" style={{ color: "#6c757d" }}/>
                </div>
            }
            <FreeTextStyle
              id={`${componentName}-${idFragment}-filterValue`}
              type="text"
              value={text ? text : ""}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onTextChange}
              activeClosedFilter={highlightWhenHasValue && notFocusedAndHashData()}
              cusWidth={width}
              placeholder={onlyNumbers ? "" : showSearch ? "Search" : ""}
            />
          </InputGroup>
        </Filter>
      </Col>
    </Row>
  );
};

export { FreeText, Free_Text_TYPE };
