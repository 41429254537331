import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useCMWorklistContext } from '../context/CMWorklist.context';
import EQHErrorToast from "../../../../../../components/EQHErrorToast";
import { trim } from 'lodash'
import PatientInformationSummary from "./PatientInformationSummary";
import { RedAsterisk } from '../../../../../../utils/util';

const componentName = 'RemovePatientFromWorklist';

function RemovePatientFromWorklist() {
  const isEmpty = require("is-empty");

  const {
    removeFromWorklist,
    toggleRemoveFromWorklist,
    selectedPatientsEvents,
    patient,
    declineReasons,
    removePatient,
    sendEvent,
    isBulk,
    toggleIsBulk
  } = useCMWorklistContext();

  const [note, setNote] = useState('');
  const [reason, setReason] = useState('');
  const [errors, setErrors] = useState([]);
  const [listPatients, setListPatients] = useState([]);
  const [currentOpenPrograms, setCurrentOpenPrograms] = useState([]);

  useEffect(() => {
    if (removeFromWorklist){
      setListPatients(isBulk ? [...selectedPatientsEvents.map(p => p.data)] : [patient])
      loadCurrentOpenPrograms()
    }
  }, [removeFromWorklist])

  const getPatientData = () => {
    return listPatients.map(patient => (
      {
        reason: reason,
        notes: note,
        action: 'DECLINE',
        origin: 'CM_WORKLIST',
        patientId: patient?.id,
        programLeadId: '',
        programId: '',
        programName: '',
        referralId: patient?.referralDtoList?.[0]?.id,
        adtId: patient?.adtId,
        carePathInstanceId: patient?.carePathStepDto?.carePathInstanceId,
        carePathStepId: patient?.carePathStepDto?.id
      }
    ))
  }

  const areRequiredFieldsValid = () => {
    return !isEmpty(reason) && !isEmpty(trim(note));
  }

  const submitAction = async () => {
    if (areRequiredFieldsValid()) {
      sendEvent('CONFIRM_REMOVE_WORKLIST');
      await removePatient(getPatientData());
      setNote('');
      setReason('');
      setErrors([]);
    } else {
      setErrors(["All fields are required"]);
    }
  }

  const closeAction = async () => {
    setNote('');
    setReason('');
    setErrors([]);
    toggleRemoveFromWorklist(false);
    toggleIsBulk(false);
  }

  const splitPrograms = (patient) => {
    return patient?.openProgramList?.split(",")
  }

  const loadCurrentOpenPrograms = () => {
    if (patient && patient.openProgramList) {
      setCurrentOpenPrograms(splitPrograms(patient));
    }
  }

  const UpperComponent = ({ patient }) => {
    return (
      <>
        <PatientInformationSummary rowLayout={2}
                                   fullName={patient.fullName}
                                   memberId={patient.memberId}
                                   dateOfBirth={patient.dateOfBirth}
                                   className='mb-3'/>
        {isBulk
          ? splitPrograms(patient)?.length > 0 && <CarePrograms currentOpenPrograms={splitPrograms(patient)}/>
          : currentOpenPrograms?.length > 0 && <CarePrograms currentOpenPrograms={currentOpenPrograms}/>
        }
      </>
    )
  }

  const CarePrograms = ({ currentOpenPrograms }) => {
    return (
      <Row className='mb-5'>
        <Form.Group id={`${componentName}-current`} className="d-flex flex-column">
          <Form.Label id={`${componentName}-currentLabel`}>
            Current Care Programs
          </Form.Label>
          <span
            id={`${componentName}-currentValue`}>{currentOpenPrograms.map((programName, index) => (
              <span id={`programName-${index}`} key={`programName-${index}`} className="d-block">
                {programName}
              </span>
            ))}
          </span>
        </Form.Group>
      </Row>
    )
  }

  return (
    <Modal
      id={`${componentName}-patientRemovePatientFromWorklistConfirmModal`}
      size="md"
      show={removeFromWorklist}
      onHide={() => closeAction()}
      centered
    >
      <Modal.Header>
        <Modal.Title id={`${componentName}-patientRemovePatientFromWorklistConfirmTitle`}>Remove Patient</Modal.Title>
        <button
          id={`${componentName}-patientRemovePatientFromWorklistConfirmModalClose`}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeAction()}
        />
      </Modal.Header>
      <Modal.Body>
        <EQHErrorToast errors={errors} handleClose={() => setErrors([])} isList={true} />
        <Row className="mb-4 bg-light p-3 rounded-3" style={{ maxHeight: '200px', overflow: 'auto' }}>
          <Col>
            {listPatients.map((p, idx) =>
                <UpperComponent patient={p} key={`${p?.id}-${idx}-upper`} />
              )
            }
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Group>
                <Form.Label id={`${componentName}-reasonLabel`}>
                  Reason<RedAsterisk/>
                </Form.Label>
                <Form.Control as="select" value={reason} onChange={(e) => setReason(e.target.value)}
                  id={`${componentName}-removePatientFromWorklistReasonLabel`}>
                  <option
                    disabled={true}
                    key={`${componentName}-select-removePatientFromWorklistReasonItem`}
                    id={`${componentName}-select-removePatientFromWorklistReasonItem`}
                    value=''
                  >
                    Select
                  </option>
                  {declineReasons?.map((x) => (
                    <option
                      key={`${componentName}-${x.value}-removePatientFromWorklistReasonItem`}
                      id={`${componentName}-${x.value}-removePatientFromWorklistReasonItem`}
                      value={x.value}
                    >
                      {x.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group>
                <Form.Label id={`${componentName}-notesLabel`}>
                  Notes<RedAsterisk/>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  max="500"
                  onChange={e => setNote(e.target.value)}
                  id={`${componentName}-notesValue`}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button id={`${componentName}-patientRemovePatientFromWorklistNoBtn`} variant="secondary" onClick={() => closeAction()}>
          Cancel
        </Button>
        <Button
          id={`${componentName}-patientRemovePatientFromWorklistYesBtn`}
          variant="primary"
          disabled={!areRequiredFieldsValid()}
          onClick={() => submitAction()}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemovePatientFromWorklist;