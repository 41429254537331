import React, { useContext, useState } from "react";
import { Card, Accordion, AccordionContext, useAccordionButton, Toast, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  conditionValidations,
  opportunityValidationOptions,
  opportunityRejectionReasons,
  suspectRejectionReasons,
  suspectHistoryStatuses,
  opportunityDualValidationOptions,
} from "../../../utils/textValueLists";
import {
  formatDate,
  formatDateTime,
  getDynamicDisplayValue,
  downloadSecureFile,
  getPatientProfileLink,
  uuid,
  getMultipleDescToSingle,
} from "../../../utils/util";

import isEmpty from "is-empty";

function PlusMinusToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      <i
        className={isCurrentEventKey ? "fa fa-minus me-1" : "fa fa-plus me-1"}
      ></i>{" "}
      {children}
    </>
  );
}

const FullHistory = ({
  componentName,
  show,
  allSuspectsForHist,
  allOpportunitiesForHist,
  authorizedGapRead,
  authorizedGapWrite,
  authorizedRafsRead,
  authorizedRafsWrite,
  handleUserSelectedCareOppHistoryIdsChange,
  handleUserSelectedSuspectHistoryIdsChange,
  careOppHistoryIds,
  suspectHistoryIds,
  toggleShowHistory,
}) => {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        minHeight: "100px",
        top: "0",
        right: "0",
        zIndex: "1040",
      }}
    >
      <Toast
        onClose={() => toggleShowHistory(false)}
        show={show}
        animation={false}
        id={`${componentName}-historyToast`}
      >
        <Toast.Header>
          <strong id={`${componentName}-historyToastTitle`} className="me-auto">
            History
          </strong>
        </Toast.Header>
        <Toast.Body className="overflow-y-auto overflow-x-hidden"
          style={{
            maxHeight: "500px"
          }}
        >
          {authorizedGapRead || authorizedGapWrite ? (
            <>
              <label
                id={`${componentName}-historyToastCareOppTitle`}
                className="d-block mb-1"
              >
                Care Opportunities
              </label>
              {allOpportunitiesForHist.length &&
                allOpportunitiesForHist.map((gap, i) => {
                  return (
                    <Accordion
                      className="mb-0"
                      activeKey={
                        careOppHistoryIds.has(gap.careGapSummary.id)
                          ? "key"
                          : ""
                      }
                    >
                      <Card id={`${componentName}-historyToastCareOpp-${i}`}>
                        <Card.Header
                          id={`${componentName}-historyToastCareOppLabel-${i}`}
                          onClick={() =>
                            handleUserSelectedCareOppHistoryIdsChange(
                              gap.careGapSummary.id
                            )
                          }
                          eventKey="key"
                          className="cursor-p"
                        >
                          <PlusMinusToggle eventKey="key">
                            <span
                              id={`${componentName}-historyToastCareOppLabelText-${i}`}
                            >
                              {gap.careGapSummary.gapAndYear}{" "}
                              {gap.careGapSummary.visitsUpperBound
                                ? `${gap.careGapSummary.visitsLowerBound}/${gap.careGapSummary.visitsUpperBound} Approved Visits`
                                : null}
                            </span>
                          </PlusMinusToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="key">
                          <Card.Body
                            id={`${componentName}-historyToastCareOppBody-${i}`}
                          >
                            {gap.history.length &&
                              gap.history
                                .map((x) => x)
                                .reverse()
                                .map((history, j) => {
                                  return (
                                    <div>
                                      <span
                                        id={`${componentName}-historyToastCOStatus-${i}-${j}`}
                                        className="d-block fw-bold"
                                      >
                                        {history.status}
                                      </span>
                                      <span
                                        id={`${componentName}-historyToastCOCreatedAt-${i}-${j}`}
                                        className="d-block"
                                      >
                                        {formatDateTime(history.createdAt)}
                                      </span>
                                      <span
                                        id={`${componentName}-historyToastCOUser-${i}-${j}`}
                                        className="d-block"
                                      >
                                        User: {history.userName}
                                      </span>
                                      {history.additionalInfoOptionValue.length != null &&
                                      history.additionalInfoOptionValue.length >0 && (
                                        <span
                                          id={`${componentName}-historyToastCOAddInfo-${i}-${j}`}
                                          className="d-block"
                                        >
                                          {history.additionalInfoCaption}:{" "}
                                          {history.additionalInfoOptionValue.join(", ")}
                                        </span>
                                      )}
                                      {history.reviewed === true &&
                                        history.careGapName !== null &&
                                        ((history.careGapName !== "WC15" &&
                                          history.careGapName !== "WC30") ||
                                          history.source !== null) && (
                                          <span
                                            id={`${componentName}-historyToastCODocReviewed-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Documentation reviewed
                                          </span>
                                        )}
                                      {history.hideReason !== null && (
                                        <span
                                          id={`${componentName}-historyToastCOHideReason-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Hide reason: {history.hideReason}
                                        </span>
                                      )}
                                     {getMultipleDescToSingle(history.description).map((str) => (
                                          <span
                                            id={`${componentName}-historyToastSHistoryDesc-${i}-${j}`}
                                            className="d-block my-2"
                                          >
                                            {str}
                                          </span>
                                        ))}
                                      { history.uploadedFiles.length != null &&
                                          history.uploadedFiles.length > 0 && [
                                          <span
                                            id={`${componentName}-historyToastCOUploadedLabel-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Uploaded Files:
                                          </span>,
                                            history.uploadedFiles.map(
                                            (uploadedFile) => {
                                              return (
                                                <span
                                                  id={`${componentName}-historyToastCOUploadedFile-${i}-${j}`}
                                                  className="d-block"
                                                >
                                                  <a
                                                    href={"#"}
                                                    onClick={() =>
                                                      downloadSecureFile(
                                                        process.env
                                                          .REACT_APP_REST_API_BASE_URL +
                                                          `/v1/file-upload/file?id=${encodeURIComponent(
                                                              uploadedFile
                                                              .fileMetadata
                                                              .fileId
                                                          )}`,
                                                          uploadedFile
                                                          .fileMetadata.fileName
                                                      )
                                                    }
                                                  >
                                                    { uploadedFile.fileMetadata
                                                      .fileName !== null
                                                      ?  uploadedFile
                                                          .fileMetadata.fileName
                                                      : ""}
                                                  </a>
                                                </span>
                                              );
                                            }
                                          ),
                                        ]}
                                      {history.visitNumber !== 0 && (
                                        <span
                                          id={`${componentName}-historyToastCOVisit-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Visit: {history.visitNumber}
                                        </span>
                                      )}
                                      {history.applicableCode !== null && (
                                        <span
                                          id={`${componentName}-historyToastCOAppCode-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Applicable Code:{" "}
                                          {history.applicableCode}
                                        </span>
                                      )}
                                      {history.revisedDateOfService !==
                                        null && (
                                        <span
                                          id={`${componentName}-historyToastCODOS-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Revised Date of service:{" "}
                                          {formatDate(
                                            history.revisedDateOfService
                                          )}
                                        </span>
                                      )}
                                      {history.revisedDateOfService === null &&
                                        history.dateOfService !== null && (
                                          <span
                                            id={`${componentName}-historyToastCODOS-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Date of service:{" "}
                                            {formatDate(history.dateOfService)}
                                          </span>
                                        )}
                                      {history.dateOfService === null &&
                                        history.status === "Closed" && (
                                          <span
                                            id={`${componentName}-historyToastCODOSNAD-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Date of service: No Available Date
                                          </span>
                                        )}
                                      {(history.careGapName === "WC15" ||
                                        history.careGapName === "WC30") &&
                                      history.visitStatus ===
                                        "VERIFIED_BY_CLAIM" &&
                                      history.userId === null ? (
                                        <span
                                          id={`${componentName}-historyToastCOVerifiedClaims-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Verified by Claims
                                        </span>
                                      ) : (history.careGapName === "WC15" ||
                                          history.careGapName === "WC30") &&
                                        history.visitStatus ===
                                          "CLOSED_BY_CLAIM" &&
                                        history.userId === null ? (
                                        <span
                                          id={`${componentName}-historyToastCOVerifiedClaims-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Verified by Claims
                                        </span>
                                      ) : history.validation ||
                                        history.dualValidation ? (
                                        <div>
                                          <span
                                            id={`${componentName}-historyToastCOValidation-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Validation:{" "}
                                            {history.validation && (
                                              <>
                                                {getDynamicDisplayValue(
                                                  opportunityValidationOptions,
                                                  history.validation,
                                                  "class",
                                                  "name"
                                                )}
                                              </>
                                            )}
                                            {history.dualValidation && (
                                              <>
                                                {" "}
                                                {getDynamicDisplayValue(
                                                  opportunityDualValidationOptions,
                                                  history.dualValidation,
                                                  "class",
                                                  "name"
                                                )}
                                              </>
                                            )}
                                          </span>
                                          {history.rejectionReason !== null && (
                                            <span
                                              id={`${componentName}-historyToastCORejectReason-${i}-${j}`}
                                              className="d-block"
                                            >
                                              Rejection Reason:{" "}
                                              <OverlayTrigger
                                                trigger="click"
                                                rootClose
                                                overlay={
                                                  <Tooltip>
                                                    {" "}
                                                    {history.rejectionToolTipInfo
                                                      ? history.rejectionToolTipInfo
                                                      : "No Description Available"}{" "}
                                                  </Tooltip>
                                                }
                                              >
                                                  <span className="text-decoration-underline cursor-p" style={{ textDecorationColor: "#188dcd" }}>
                                                    {history.rejectionReason}
                                                  </span>
                                              </OverlayTrigger>
                                            </span>
                                          )}
                                          {history.approvalBycode === true && (
                                            <span
                                              id={`${componentName}-historyToastCOValnCode-${i}-${j}`}
                                              className="d-block"
                                            >
                                              Applicable Code:{" "}
                                              {history.validationCode
                                                ? history.validationCode
                                                : null}
                                            </span>
                                          )}
                                        </div>
                                      ) : null}
                                      {history.diastolicCode !== null ? (
                                        <span
                                          id={`${componentName}-historyToastCODiastolicCode-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Diastolic Code:{" "}
                                          {history.diastolicCode}
                                        </span>
                                      ) : null}
                                      {history.systolicCode !== null ? (
                                        <span
                                          id={`${componentName}-historyToastCOSystolicCode-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Systolic Code: {history.systolicCode}
                                        </span>
                                      ) : null}
                                      {history.validationNote !== null && history.validationNote !== ""? (
                                          <span
                                              id={`${componentName}-historyToastCOValidationNote-${i}-${j}`}
                                              className="d-block"
                                          >
                                          Validation Note: {history.validationNote}
                                        </span>
                                      ) : null}
                                      {history.source !== null ? (
                                        <span
                                          id={`${componentName}-historyToastCOSource-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Source: {history.source}
                                        </span>
                                      ) : (
                                        (history.userId !== null ||
                                          ((history.careGapName === "WC15" ||
                                            history.careGapName === "WC30") &&
                                            history.visitNumber !== 0)) && (
                                          <span
                                            id={`${componentName}-historyToastCOSourceUI-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Source: UI
                                          </span>
                                        )
                                      )}
                                      {gap.history.length - 1 !== j && <hr />}
                                    </div>
                                  );
                                })}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  );
                })}
            </>
          ) : null}
          {authorizedRafsRead || authorizedRafsWrite ? (
            <>
              <label
                id={`${componentName}-historyToastSuspectsTitle`}
                className="d-block mb-1 mt-3"
              >
                Suspects
              </label>
              {allSuspectsForHist.length &&
                allSuspectsForHist.map((suspect, i) => {
                  return (
                    <Accordion
                      className="mb-0"
                      activeKey={
                        suspectHistoryIds.has(suspect.conditionSummary.id)
                          ? "key"
                          : ""
                      }
                    >
                      <Card id={`${componentName}-historyToastSuspect-${i}`}>
                        <Card.Header
                          id={`${componentName}-historyToastSuspectLabel-${i}`}
                          onClick={() =>
                            handleUserSelectedSuspectHistoryIdsChange(
                              suspect.conditionSummary.id
                            )
                          }
                          eventKey="key"
                          className="cursor-p"
                        >
                          <PlusMinusToggle eventKey="key">
                            <span
                              id={`${componentName}-historyToastSuspectLabelText-${i}`}
                            >
                              {suspect.conditionSummary.conditionAndYear}
                            </span>
                          </PlusMinusToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="key">
                          <Card.Body
                            id={`${componentName}-historyToastSuspectBody-${i}`}
                          >
                            {suspect.history.length &&
                              suspect.history
                                .map((x) => x)
                                .reverse()
                                .map((history, j) => {
                                  return (
                                    <div>
                                      <span
                                        id={`${componentName}-historyToastSStatus-${i}-${j}`}
                                        className="d-block"
                                      >
                                        <b>
                                          {getDynamicDisplayValue(
                                            suspectHistoryStatuses,
                                            history.status,
                                            "value",
                                            "text"
                                          )}
                                          {"(Applied to: " +
                                            suspect.conditionSummary
                                              .measureYear +
                                            " " +
                                            history.conditionName +
                                            ")"}
                                        </b>
                                      </span>
                                      <span
                                        id={`${componentName}-historyToastSCreatedAt-${i}-${j}`}
                                        className="d-block"
                                      >
                                        {formatDateTime(history.createdAt)}
                                      </span>
                                      {!isEmpty(history.isDuplicate) && (
                                        <div>
                                          {!isEmpty(history.newHealthPlan) && (
                                            <span
                                              id={`${componentName}-historyToastSOpenSource-${i}-${j}`}
                                              className="d-block"
                                            >
                                              Open Source:{" "}
                                              {history.newHealthPlan}
                                            </span>
                                          )}
                                          {!isEmpty(history.fileName) && (
                                            <span
                                              id={`${componentName}-historyToastSFileName-${i}-${j}`}
                                              className="d-block"
                                            >
                                              File Name: {history.fileName}
                                            </span>
                                          )}
                                          {!isEmpty(history.datePulled) && (
                                            <span
                                              id={`${componentName}-historyToastSOpenService-${i}-${j}`}
                                              className="d-block"
                                            >
                                              Open Received service:{" "}
                                              {formatDate(history.datePulled)}
                                            </span>
                                          )}
                                          {!isEmpty(history.icd10Code) && (
                                            <span
                                              id={`${componentName}-historyToastSICD10-${i}-${j}`}
                                              className="d-block"
                                            >
                                              ICD-10: [{history.icd10Code}]{" "}
                                              {history.icd10Description}
                                            </span>
                                          )}
                                          {isEmpty(history.icd10Code) &&
                                            (!isEmpty(history.hccNumber) ||
                                              !isEmpty(
                                                history.hccDescription
                                              )) && (
                                              <span
                                                id={`${componentName}-historyToastSCC-${i}-${j}`}
                                                className="d-block"
                                              >
                                                HCC: [{history.hccNumber}]{" "}
                                                {history.hccDescription}
                                              </span>
                                            )}
                                        </div>
                                      )}
                                      <span
                                        id={`${componentName}-historyToastSUser-${i}-${j}`}
                                        className="d-block"
                                      >
                                        User: {history.userName}
                                      </span>
                                      {history.hideReason !== null && (
                                        <span
                                          id={`${componentName}-historyToastSHideReason-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Hide reason: {history.hideReason}
                                        </span>
                                      )}
                                      {j === suspect.history.length - 1 && (
                                        <span
                                          id={`${componentName}-historyToastSAppCode-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Applicable Code:{" "}
                                          {history.hccNumber
                                            ? history.hccNumber
                                            : suspect.conditionSummary.dxCode}
                                        </span>
                                      )}
                                      {getMultipleDescToSingle(history.description).map((str) => (
                                          <span
                                            id={`${componentName}-historyToastSHistoryDesc-${i}-${j}`}
                                            className="d-block my-2"
                                          >
                                            {str}
                                          </span>
                                        ))}
                                      {history.uploadedFiles.length != null &&
                                        history.uploadedFiles.length > 0 && [
                                          <span
                                            id={`${componentName}-historyToastSUploadedLabel-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Uploaded Files:
                                          </span>,
                                          history.uploadedFiles.map(
                                            (uploadedFile) => {
                                              return (
                                                <span
                                                  id={`${componentName}-historyToastSUploadedFile-${i}-${j}`}
                                                  className="d-block"
                                                >
                                                  <a
                                                    href={"#"}
                                                    onClick={() =>
                                                      downloadSecureFile(
                                                        process.env
                                                          .REACT_APP_REST_API_BASE_URL +
                                                          `/v1/file-upload/file?id=${encodeURIComponent(
                                                            uploadedFile
                                                              .fileMetadata
                                                              .fileId
                                                          )}`,
                                                        uploadedFile
                                                          .fileMetadata.fileName
                                                      )
                                                    }
                                                  >
                                                    {uploadedFile.fileMetadata
                                                      .fileName !== null
                                                      ? uploadedFile
                                                          .fileMetadata.fileName
                                                      : ""}
                                                  </a>
                                                </span>
                                              );
                                            }
                                          ),
                                        ]}
                                      {history.dateOfService !== null &&
                                        history.validation !== null &&
                                        getDynamicDisplayValue(
                                          conditionValidations,
                                          history.validation,
                                          "class",
                                          "name"
                                        ) === "Approved" && (
                                          <span
                                            id={`${componentName}-historyToastSDOS-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Revised Date of service:{" "}
                                            {formatDate(history.dateOfService)}
                                          </span>
                                        )}
                                      {history.dateOfService !== null &&
                                        history.validation !== null &&
                                        getDynamicDisplayValue(
                                          conditionValidations,
                                          history.validation,
                                          "class",
                                          "name"
                                        ) !== "Approved" && (
                                          <span
                                            id={`${componentName}-historyToastSDOS-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Date of service:{" "}
                                            {formatDate(history.dateOfService)}
                                          </span>
                                        )}
                                      {history.validation ||
                                      history.dualValidation ? (
                                        <div>
                                          <span
                                            id={`${componentName}-historyToastSValidation-${i}-${j}`}
                                            className="d-block"
                                          >
                                            Validation:{" "}
                                            {history.validation && (
                                              <>
                                                {getDynamicDisplayValue(
                                                  conditionValidations,
                                                  history.validation,
                                                  "class",
                                                  "name"
                                                )}
                                              </>
                                            )}
                                            {history.dualValidation && (
                                              <>
                                                {" "}
                                                {getDynamicDisplayValue(
                                                  opportunityDualValidationOptions,
                                                  history.dualValidation,
                                                  "class",
                                                  "name"
                                                )}
                                              </>
                                            )}
                                          </span>
                                          {history.rejectionReason != null && (
                                            <span
                                              id={`${componentName}-historyToastSRejectionReason-${i}-${j}`}
                                              className="d-block"
                                            >
                                              Rejection Reason:{" "}
                                              {getDynamicDisplayValue(
                                                suspectRejectionReasons,
                                                history.rejectionReason,
                                                "class",
                                                "text"
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      ) : null}
                                      {history.validationNote !== null && history.validationNote !== ""? (
                                          <span
                                              id={`${componentName}-historyToastsValidationNote-${i}-${j}`}
                                              className="d-block"
                                          >
                                          Validation Note: {history.validationNote}
                                        </span>
                                      ) : null}
                                      {history.source !== null && (
                                        <span
                                          id={`${componentName}-historyToastSSource-${i}-${j}`}
                                          className="d-block"
                                        >
                                          Source: {history.source}
                                        </span>
                                      )}
                                      {suspect.history.length - 1 !== j && (
                                        <hr />
                                      )}
                                    </div>
                                  );
                                })}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  );
                })}
            </>
          ) : null}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export { FullHistory };
