import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  getMinimizedPatients,
  removeMinimizedPatient,
  setManageVisitOpenedFromPageTitle,
  setManageVisitPatient
} from '../../actions/manageVisitActions';
import axios from '../../utils/apiClient';
import { useMutation } from 'react-query';

const componentName = "MinimizedPatients";

const Patient = ({ patient }) => {
  const dispatch = useDispatch();

  const getPatientProfile = (patientId) => {
    return axios.get(
      process.env.REACT_APP_REST_API_BASE_URL +
        `/v1/patients/overview?patientId=${patientId}`,
      {
        headers: {},
      }
    );
  }

  const getPatient = useMutation(getPatientProfile, {
    onSuccess: (res) => {
      dispatch(setManageVisitPatient(res.data));
      dispatch(setManageVisitOpenedFromPageTitle('MinimizedPatient'))
    },
    onError: (err) => {
    },
  });

  return (
    <div id={`${componentName}-patient`} className="patientDetailsOffcanvas-min d-flex align-items-center px-3 rounded shadow-sm justify-content-between bg-white border">
      <span id={`${componentName}-patientName`} className="fw-bold forced-capital cursor-p text-primary" 
        data-bs-toggle={"offcanvas"} data-bs-target={"#patientDetailsModal"} aria-controls="patientDetailsModal" 
        onClick={async () => { getPatient.mutate(patient.id); }} >
        {patient.fullName}
      </span>
      <i id={`${componentName}-closeMinimizedPatient`} className='fa-regular fa-xmark cursor-p' onClick={() => dispatch(removeMinimizedPatient(patient))}></i>
    </div>
  )
}

function MinimizedPatients() {
  const url = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-profile/open-patient-drawers";
  const { minimizedPatients } = useSelector((state) => state?.manageVisitReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(url, { headers: {} })
      .then(res => {
          dispatch(getMinimizedPatients(res.data))
      })
  }, [])

  return (
    <div className="d-flex flex-wrap justify-content-end position-absolute bottom-0 end-0 me-4 mb-3 gap-3 zindex-1040">
      { minimizedPatients.map((p, i) => <Patient patient={p} key={i} />) }
    </div>
  )
}

export default MinimizedPatients