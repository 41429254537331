import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../../context/authContext";
import { useGetTransQuickListSettings } from "../quickList/quickListSettings";
import { QuickListContext } from "../quickList/QuickListContext";
import { QuickLists } from "../quickList/QuickList";
import {
  getCareManagementActions,
  getCareManagementPrograms,
} from "../../actions/caremgmt/hubWorkCenterAction";
import EQHErrorToast from "../../components/EQHErrorToast";
import { Helmet } from 'react-helmet';
import PatientListOffCanvas from "./PatientListOffCanvas";

const CareEmpowerReferrals = () => {
  const [errors, setErrors] = useState([]);
  const [actions, setActions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [teams, setTeams] = useState([]);

  const auth = useAuth();
  const getSettingsRef = useGetTransQuickListSettings("careReferrals", 0, auth);

  useEffect(() => {
    fetchPrograms();
    fetchCareManagementActions();
  }, [])

  const onError = (err) => {
    if (typeof err === 'string') {
      setErrors([err]);
    } else if (Array.isArray(err)) {
      setErrors([err.join(', ')]);
    }
    else {
      const { response } = err;
      if (response && response.data) {
        setErrors([response.data.message]);
      }
    }
  }

  const fetchPrograms = () => {
    getCareManagementPrograms()
      .then(response => setPrograms(response.data || []))
      .catch(error => onError(error))
  }

  const fetchCareManagementActions = () => {
    getCareManagementActions()
      .then(response => {
        let assignAction = response?.data?.filter(action => action.text.toLowerCase().includes("assign"))
        setActions(assignAction)
      })
      .catch(error => onError(error))
  }

  return (
    <>
      <EQHErrorToast errors={errors} handleClose={() => setErrors([])} />
      <Helmet>
        <title>CareEmpower Referrals | CareEmpower</title>
      </Helmet>
      <PatientListOffCanvas
        onError={onError}
        teams={teams}
        setTeams={setTeams}
      />
      <QuickListContext.Provider value={getSettingsRef()}>
        <QuickLists {...getSettingsRef()}
          careManagementActions={actions}
          careManagerPrograms={programs}
          careManagerTeams={teams}
          onCareManagerAssigmentError={onError}>
          <Button variant="primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTeamList" aria-controls="offcanvasTeamList">
            <i className="fa fa-users me-2"></i> Team List
          </Button>
        </QuickLists>
      </QuickListContext.Provider>
    </>
  );
};

export { CareEmpowerReferrals };