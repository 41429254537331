import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { formatDateVanilla } from "../../../../../utils/util";;

const componentName = 'AppointmentCard'

const formatDuration = (date, duration) => {
  let newDate = new Date(date);
  let adjustedDate = new Date(newDate.getTime() + duration * 60 * 1000);
  let tmz = newDate
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  newDate = newDate
    .toLocaleTimeString("en-US")
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
  adjustedDate = adjustedDate
    .toLocaleTimeString("en-US")
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");

  return newDate + " " + tmz + " - " + adjustedDate + " " + tmz;
};

const AppointmentsCardOpen = ({ appointment }) => {
  return (
    <Container className="p-3">
      <Row>
        <Col className="ps-5">
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-locationLabel`}>Location</span>
            </Col>
            <Col className="d-flex align-items-center">
              <span id={`${componentName}-locationValue`}>{appointment?.address || '-'}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-dateLabel`}>Date</span>
            </Col>
            <Col>
              <span id={`${componentName}-dateValue`}>{formatDateVanilla(appointment?.appointmentDateTime, "MMM dd, yyyy") + ', ' +
                formatDuration(appointment?.appointmentDateTime, appointment?.durationInMinutes)}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-reminderLabel`}>Reminder</span>
            </Col>
            <Col>
              <span id={`${componentName}-reminderValue`}>{appointment?.shouldSendReminder ? 'Yes - ' : 'No - '} {appointment?.notificationDelay} {appointment?.notificationDelayUnit?.toLowerCase()} delay</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-transportationLabel`}>Transportation</span>
            </Col>
            <Col>
              <span id={`${componentName}-transportationValue`}>{appointment?.needsTransportation ? 'Yes' : 'No'}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-careProgramLabel`}>Care Program</span>
            </Col>
            <Col>
              <span id={`${componentName}-careProgramValue`}>{appointment?.carePathName || '-'}</span>
            </Col>
          </Row>
          <Row>
            <Col className="form-label col-form-label" sm={3}>
              <span id={`${componentName}-careProgramLabel`}>Cancellation Note</span>
            </Col>
            <Col>
              <span id={`${componentName}-careProgramValue`}>{appointment?.note || '-'}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AppointmentsCardOpen;
