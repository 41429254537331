import React, {createContext, useContext, useReducer} from 'react';
import {useDispatch} from "react-redux";

import {initialState} from './actions';
import {getGetters} from './getters';
import {getToggles} from './toggles';
import {getSetters} from "./setters";
import {reducer} from './Appointments.reducer';
import {cancelAppointment, getAppointments} from './api';
import {
  useEQHToastContext
} from "../../../../../components/toast/EQHToast.context";

const AppointmentsContext = createContext();

const AppointmentsProvider = ({children}) => {
  const globalDispatch = useDispatch();
  const {removeNotification, setNotification} = useEQHToastContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setters = getSetters(dispatch)
  const getters = getGetters(state);
  const toggles = getToggles(dispatch, globalDispatch);

  const getAppointmentsAsync = async (id, filters) => getAppointments(id, filters)
  .then(res => {
    setters.setAppointments(res?.data?.appointmentFormDtos)
    setters.setTotalSize(res?.data?.totalSize)
  })

  const callbacks = {
    getAppointments: getAppointmentsAsync,
    cancelAppointment: async (patientId, requestBody) => {
      removeNotification();
      toggles.toggleIsCancelingAppointment(true)
      return await cancelAppointment(requestBody)
      .then((res) => {
        getAppointmentsAsync(patientId)
        toggles.toggleIsCancelingAppointment(false)
        toggles.toggleShowCancelAppointment(false)
        toggles.toggleShowAddAppointment(false)
        toggles.toggleAddNewAppointment(false)
        setNotification('Successful', 'Cancelled successfully');
      })
      .catch((err) => {
        toggles.toggleIsCancelingAppointment(false)
        setNotification('Error',
          (err?.response?.data?.message
            || "Error while cancelling the appointment"));
      });
    },
  }

  const value = {
    ...setters,
    ...getters,
    ...toggles,
    ...callbacks
  }

  return (
    <AppointmentsContext.Provider value={value}>
      {children}
    </AppointmentsContext.Provider>
  )
};
const useAppointmentsContext = () => useContext(AppointmentsContext);

export {
  AppointmentsProvider,
  useAppointmentsContext,
}