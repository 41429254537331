export const initialState = {
  isSaveAsCustomWorklistEnabled: false,
  customWorklistError: [],
  isDeleteCustomWorklistEnabled: false,
  customWorklist: {},
  activeRole: {},
  saveAsCustomWorklistRequest: {},
  patientSearchCustomWorklist: {},
  getCustomWorklistRequest: {},
};

export const actions = {
  TOGGLE_SAVE_AS_CUSTOM_WORKLIST: 'TOGGLE_SAVE_AS_CUSTOM_WORKLIST',
  CUSTOM_WORKLIST_ERROR: 'CUSTOM_WORKLIST_ERROR',
  TOGGLE_DELETE_CUSTOM_WORKLIST: 'TOGGLE_DELETE_CUSTOM_WORKLIST',
  SET_CUSTOM_WORK_LIST: 'SET_CUSTOM_WORK_LIST',
  SET_CUSTOM_FILTERS: 'SET_CUSTOM_FILTERS',
  SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
  SET_SAVE_CUSTOM_WORK_LIST_REQUEST: 'SET_SAVE_CUSTOM_WORK_LIST_REQUEST',
  SET_PATIENT_SEARCH: 'SET_PATIENT_SEARCH',
  SET_GET_CUSTOM_WORK_LIST_REQUEST: 'SET_GET_CUSTOM_WORK_LIST_REQUEST',
};