import React, { useEffect, Fragment, useState } from "react";
import { Card, InputGroup } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import { saveUserPassword } from "../../actions/accountSettingsAction";
import Toast from "react-bootstrap/Toast";
import VerifyPassword from "../login/VerifyPassword";

const componentName = "ChangePassword";
const isEmpty = require("is-empty");

const CustomInputGroup = styled(InputGroup)`
  .input-group-append {
    .input-group-text {
      border: none;
      background-color: white
    }
  }
`;

const ChangePassword = () => {
  const [warning, setWarning] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [show, setShow] = useState(false);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPass, setShowRepeatPass] = useState(false);

  const save = () => {
    if (newPassword === repeatPassword) {
      saveUserPassword([currentPassword, newPassword])
        .then((response) => {
          setWarning("");
          setShow(true);
        }).catch((error) => {
          setWarning(error.response.data.message);
          setTimeout(() => setWarning(), 5000);
        });
    } else {
      setWarning("New Password and Confirm Password do not match.");
      setTimeout(() => setWarning(), 5000);
    }
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <p id={`${componentName}-passwordReq`} className="fw-bold">
                Password must meet the following requirements:
              </p>
              <p id={`${componentName}-passwordReq1`}>1. Alphanumeric</p>
              <p id={`${componentName}-passwordReq2`}>2. At least 1 uppercase character</p>
              <p id={`${componentName}-passwordReq3`}>3. At least 1 lowercase character</p>
              <p id={`${componentName}-passwordReq3`}>4. 8 characters long or more</p>
              <p id={`${componentName}-passwordReq3`}>5. Includes special characters: "{" !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"}"</p>
              <div className="text-danger">{warning}</div>
            </Col>
            <Form.Group className="mb-3 w-100">
              <Form.Label id={`${componentName}-currentPassLabel`}>Current Password</Form.Label>
              <CustomInputGroup>
                <Form.Control
                  id={`${componentName}-currentPassValue`}
                  type={showCurrentPass ? "text" : "password"}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                />
                <CustomInputGroup.Text onClick={() => setShowCurrentPass(!showCurrentPass)}>
                  <span className="input-group-addon px-2">
                    {!showCurrentPass
                      ? <i className="fa-solid fa-eye"></i>
                      : <i className="fa-solid fa-eye-slash"></i>
                    }
                  </span>
                </CustomInputGroup.Text>
              </CustomInputGroup>
            </Form.Group >
            <Form.Group className="mb-3 w-100">
              <Form.Label id={`${componentName}-newPassLabel`}>New Password</Form.Label>
              <CustomInputGroup>
                <Form.Control
                  id={`${componentName}-newPassValue`}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <CustomInputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                  <span className="input-group-addon px-2">
                    {!showPassword
                      ? <i className="fa-solid fa-eye"></i>
                      : <i className="fa-solid fa-eye-slash"></i>
                    }
                  </span>
                </CustomInputGroup.Text>
              </CustomInputGroup>
              {newPassword?.length > 0 && <VerifyPassword password={newPassword}/>}
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label id={`${componentName}-confirmPassLabel`}>Confirm Password</Form.Label>
              <CustomInputGroup>
                <Form.Control
                  id={`${componentName}-confirmPassValue`}
                  type={showRepeatPass ? "text" : "password"}
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                  }}
                  isInvalid={repeatPassword !== "" && newPassword !== repeatPassword}
                />
                <CustomInputGroup.Text onClick={() => setShowRepeatPass(!showRepeatPass)}>
                  <span className="input-group-addon px-2">
                    {!showRepeatPass
                      ? <i className="fa-solid fa-eye"></i>
                      : <i className="fa-solid fa-eye-slash"></i>
                    }
                  </span>
                </CustomInputGroup.Text>
                <Form.Control.Feedback id={`${componentName}-matchPassword`} type="invalid" tooltip>
                  Passwords must match
                </Form.Control.Feedback>
              </CustomInputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row>
            <Col sm={2}>
              <Button id={`${componentName}-saveButton`} variant="primary" onClick={() => save()}>
                Save
              </Button>
            </Col>
            <Col sm={10}>
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <i className="fas fa-check-square text-success"></i>
                  <strong className="me-auto">Password updated</strong>
                </Toast.Header>
              </Toast>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ChangePassword;
