import React, {useEffect} from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Button } from "../../../../../components/buttons";
import { Row, Col, Dropdown, Form, Modal, Alert } from "react-bootstrap";
import {
  DropDownButton,
  CustomMenuStyled,
} from "../../../../../components/dropDownLists/sharedDDL";
import Modals from "../../../../../components/modals";

import "react-bootstrap-typeahead/css/Typeahead.css";
import styled from "styled-components";

import {
  neededServices,
} from "../../../../../utils/textValueLists";

import {
  DropDownList,
  DDLMode,
} from "../../../../../components/dropDownLists/genericDDL";
import ProviderSearch from "../../../../quickList/ProviderSearch";

import { FileUpload } from "../../../../../components/FileUpload";
import { isFormValid, urgencyOptions } from "../utils";
import { EQHSpinner } from "../../../../../components/EQHSpinner";
import HandlePatientSearch from "../../AddMemberModal/components/HandlePatientSearch";

import SubmitReferralHook  from "../SubmitReferralHook";
import {PrimaryDDL} from "../../../../../components/dropDownLists/primaryDDL";


const componentName = "SubmitReferral";

const otherServiceValue = "OTHER";

const StyledTypeAhead = styled(AsyncTypeahead)``;

const StyledFooter = styled.span`
  display: block;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  width: 99.9%;
  text-align: right;
  bottom: 0;
`;

const MyWorkspaceScreen = ({
  patient,
  setShowSubmitReferral,
  addNewReferral,
  showSubmitReferral
}) => {

  const isEmpty = require("is-empty");
  const {
    handleCloseMemberList,
    handleAddMember,
    showProviderModal,
    setshowProviderModal,
    setSelectedProvider,
    icdEvent,
    setIcdEvent,
    handleIcdChangeImpl,
    showMemberList,
    isAddingMember,
    handlePatientSelected,
    handlePatientSearchParamChange,
    results,
    isEmptyResult,
    patientSelected,
    patientSearchParam,
    isMemberDisabled,
    handleShowMemberList,
    buttonDisabled,
    handleServiceChange,
    filterBy,
    isLoadingComp,
    asyncCodeSearch,
    options,
    referralObj,
    handleIcdChange,
    addNewCondition,
    asyncDescSearch,
    removeNewCondition,
    isOpen,
    handleChange,
    showEmergentMsg,
    setShowEmergentMsg,
    handleUploadFileChange,
    fileUploadResetCounter,
    closeSubmitModal,
    submitReferral,
    recommendedTeams,
    loadRecommendedTeams
  } = SubmitReferralHook(setShowSubmitReferral, patient, addNewReferral);

  useEffect(() => {
    if (showSubmitReferral) {
      loadRecommendedTeams();
    }
  }, [showSubmitReferral])

  return (
    <>
      <Modal size="lg" centered show={showMemberList} onHide={handleCloseMemberList}>
        <Modal.Header closeButton>
          <Modal.Title id={`${componentName}-memberSearchModalTitle`}>Member Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAddingMember && <EQHSpinner />}
          {!isAddingMember &&
            <HandlePatientSearch
              handlePatientSelected={handlePatientSelected}
              patientSelected={patientSelected}
              results={results}
              isEmpty={isEmptyResult}
            />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button id={`${componentName}-memberSearchCancelBtn`} variant="secondary" onClick={handleCloseMemberList}>
            Cancel
          </Button>
          <Button id={`${componentName}-memberSearchAddBtn`} variant="primary" onClick={handleAddMember}>
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals
        scrollable={true}
        referralHeight={true}
        show={showProviderModal}
        onHide={() => setshowProviderModal(false)}
        size="xl"
        title="Provider Search"
        titleid={`${componentName}-providerSearchModal`}
        completeBtn="SELECT"
        hideFooter={true}
        body={
          <ProviderSearch
            patient={patient}
            updateProvider={setSelectedProvider}
            onHide={() => setshowProviderModal(false)}
          />
        }
      />
      <Modals
        show={!isEmpty(icdEvent)}
        onHide={() => setIcdEvent(null)}
        title={<span>Message</span>}
        size="md"
        hideClickButton={false}
        hideFooter={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        body={
          <span>
            By clicking <b>Confirm</b>, you attest that you have received the
            member's consent to submit a referral related to{" "}
            <b>substance abuse</b> treatment.
          </span>
        }
        onCreate={() => {
          handleIcdChangeImpl(icdEvent.code, icdEvent.index);
          setIcdEvent(null);
        }}
        closeBtn={"Cancel"}
        completeBtn={"Confirm"}
      />
      <div>
        <>
          <Row className="d-flex justify-content-center align-items-end">
            <Col md={8} className="mb-3">
              <label id={`${componentName}-addMemberNameLabel`}>Member Name or ID</label>
              <Form.Control
                id={`${componentName}-addMemberNameValue`}
                onChange={(e) => handlePatientSearchParamChange(e)}
                type="text"
                value={patientSearchParam}
                disabled={isMemberDisabled}
              />
            </Col>
            <Col className="mb-3">
              <Button
                id={`${componentName}-addMemberSearchBtn`}
                variant="primary"
                onClick={() => handleShowMemberList()}
                disabled={buttonDisabled}
              >Search Members</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <PrimaryDDL
                  label="Services Needed:"
                  handleChange={(e) => handleServiceChange(e)}
                  data={neededServices}
                  otherServiceValue={otherServiceValue}
                  isRequired={true}
              />
            </Col>
            <Col sm={3} className="mb-3">
              <label id={`${componentName}-ICD10CodeLabel`}>
                ICD-10 Code
              </label>
              <StyledTypeAhead
                filterBy={filterBy}
                inputProps={{
                  id: `${componentName}-ICD10CodeValue`
                }}
                labelKey="code"
                isLoading={isLoadingComp}
                minLength={2}
                onSearch={asyncCodeSearch}
                options={options}
                selected={referralObj?.conditions[0].code}
                onChange={(e) => handleIcdChange(e, -1)}
              />
            </Col>
            <Col sm={3} className="mb-3">
              <label id={`${componentName}-ICD10DescLabel`}>
                ICD-10 Description
                <i className="fas fa-plus-circle cursor-p ms-2" id={`${componentName}-ICD10DescAddRemove`} onClick={() => addNewCondition()}></i>
              </label>
              <StyledTypeAhead
                filterBy={filterBy}
                inputProps={{
                  id: `${componentName}-ICD10DescValue`
                }}
                labelKey="description"
                isLoading={isLoadingComp}
                minLength={2}
                onSearch={asyncDescSearch}
                options={options}
                selected={referralObj?.conditions[0].description}
                onChange={(e) => handleIcdChange(e, -1)}
              />
            </Col>
          </Row>
          {referralObj?.conditions.length > 1
            ? referralObj?.conditions.slice(1).map((con, index) => (
              <Row>
                <Col sm={{ span: 3, offset: 6 }} className="mb-3">
                  <label id={`${componentName}-ICD10CodeLabel-${index}`}>
                    ICD-10 Code
                  </label>
                  <StyledTypeAhead
                    filterBy={filterBy}
                    inputProps={{
                      id: `${componentName}-ICD10CodeValue-${index}`
                    }}
                    labelKey="code"
                    isLoading={isLoadingComp}
                    minLength={2}
                    onSearch={asyncCodeSearch}
                    options={options}
                    selected={referralObj?.conditions[index + 1].code}
                    onChange={(e) => handleIcdChange(e, index)}
                  />
                </Col>
                <Col sm={3} className="mb-3">
                  <label id={`${componentName}-ICD10DescLabel-${index}`}>
                    ICD-10 Description
                    <i id={`${componentName}-ICD10DescAddRemove-${index}`} className="fas fa-minus-circle cursor-p ms-2" onClick={() => removeNewCondition(index)}></i>
                  </label>
                  <StyledTypeAhead
                    filterBy={filterBy}
                    inputProps={{
                      id: `${componentName}-ICD10DescValue-${index}`
                    }}
                    labelKey="description"
                    isLoading={isLoadingComp}
                    minLength={2}
                    onSearch={asyncDescSearch}
                    options={options}
                    selected={referralObj?.conditions[index + 1].description}
                    onChange={(e) => handleIcdChange(e, index)}
                  />
                </Col>
              </Row>
            ))
            : null}
          <Row>
            <Col sm={6} className="mb-3">
              <label id={`${componentName}-urgencyLabel`}>
                Urgency<span className="text-danger">*</span>
              </label>
              <Dropdown value={referralObj?.urgency}>
                <Dropdown.Toggle
                  as={DropDownButton}
                  id={`${componentName}-urgencyValue`}
                  cssOpen={isOpen}
                  cusWidth="100% !important"
                >
                  {referralObj?.urgency ? (
                    referralObj?.urgency.text
                  ) : (
                    <span>-Select-</span>
                  )}
                  <i className="fa fa-chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenuStyled} cusHeight={""}>
                  {urgencyOptions.map((urgencyOp) => (
                    <Dropdown.Item
                      key={`${componentName}-urgencyValue-${urgencyOp.value}`}
                      id={`${componentName}-urgencyValue-${urgencyOp.value}`}
                      onClick={() => handleChange("urgency", urgencyOp)}
                    >
                      {urgencyOp.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={3}>
              <DropDownList
                  id={`${componentName}-recommendedTeams`}
                  key={`${componentName}-recommendedTeams`}
                  mode={DDLMode.SelectOne}
                  header="Recommended Team"
                  showSearchTextbox={false}
                  getData={() => Promise.resolve(recommendedTeams.map(team => {
                    return {value: team.teamId, text: team.name}
                  }))}
                  handleChange={(team) => handleChange("recommendedTeam", team)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert variant="danger" show={showEmergentMsg} onClose={() => setShowEmergentMsg(false)} dismissible>
                <p className="mb-0" id={`${componentName}-emergentLabel`}>
                  Care Coordination 602-313-8006
                </p>
              </Alert>
            </Col>
          </Row>
        </>
        <Row>
          <Col className="mb-3">
            <label id={`${componentName}-addANoteLabel`}>
              Add a Note
              <span id={`${componentName}-addANoteRequired`} className="text-danger">*</span>
            </label>
            <textarea
              className="textarea_editor form-control"
              id={`${componentName}-addANoteValue`}
              rows="4"
              name="comments"
              value={referralObj?.comments}
              onChange={(e) => handleChange("comments", e.target.value)}
            ></textarea>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <label id={`${componentName}-docLabel`}>
              Documentation
            </label>
            <p id={`${componentName}-docInstruction`}>
              Please include last progress note and labs for documentation.
            </p>
            <FileUpload
              id={`${componentName}-docFileUpload`}
              onChange={handleUploadFileChange}
              clearStateProp={fileUploadResetCounter}
            />{" "}
          </Col>
        </Row>
        <StyledFooter>
          <Button id={`${componentName}-closeButton`} onClick={closeSubmitModal}>Close</Button>{" "}
          <Button id={`${componentName}-submitButton`}  disabled={isFormValid(referralObj, patientSelected)} onClick={e => submitReferral(e)}>
            Submit
          </Button>
        </StyledFooter>
      </div>
    </>
  );
};

export default MyWorkspaceScreen;
