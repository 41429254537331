import React, { useEffect } from 'react';
import { useAuth } from '../../../context/authContext';

const AhaWidget = ({ appId }) => {
  const auth = useAuth();

  useEffect(() => {
    (function(w, d, s, o, f, js, fjs) {
      w['aha-widget'] = o;
      w[o] = w[o] || function() {(w[o].q = w[o].q || []).push(arguments);};
      js = d.createElement(s);
      fjs = d.getElementsByTagName(s)[0];
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(window, document, 'script', 'aha', 'https://cdn.aha.io/assets/feedback/feedback.js');

    window.aha('initialize', {
      account: 'eqhealth',
      applicationId: appId,
      user: {
        id: auth.getEmail(),
        name: auth.getName(),
        email: auth.getEmail(),
      },
    });
  }, []);

  return <div></div>;
};

export default AhaWidget;
