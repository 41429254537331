import axios from "../../../../utils/apiClient";

const URL = process.env.REACT_APP_REST_API_BASE_URL;

export const getHospitalEvents = (patientId,
  { filters = [], sorts = [], pageNumber = 1, pageSize = 20 } = {}) => {
  const criteria = {
    "patientsIds": [patientId],
    "filterParams": filters,
    "sortParams": sorts,
    "pageNumber": pageNumber,
    "pageSize": 100
  }

  return axios
    .post(URL
      + `/v1/patients/hospital-events`,
      criteria, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const addHospitalEvent = (hospitalEvent) => {
  return axios
    .post(URL
      + `/v1/adt/addEvent`,
      hospitalEvent, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const editHospitalEvent = (hospitalEvent) => {
  return axios
    .put(URL
      + `/v1/adt/editEvent`,
      hospitalEvent, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}
