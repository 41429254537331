import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../components/buttons";
import { PatientSearchDTO } from "../../dtos/PatientSearchDTO";
import axios from "../../utils/apiClient";
import { useDispatch } from "react-redux";
import { loadCustomFilteredList } from "../../actions/navigation/navigationActions";

export const CUSTOM_FILTER_LIST_URL =
  process.env.REACT_APP_REST_API_BASE_URL +
  "/v1/custom-filtered-list/custom-filtered-lists";

const componentName = "CustomFilterList";

const CustomFilterList = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const onHide = () => {
    props.setShowCustomFilterList(false);
  };

  const onSubmit = () => {
    /*let pattern = /^[a-zA-Z0-9 ]+/g;
        if(!name.match(pattern)){
            props.setModalTitle("Error");
            props.setModalSize("lg");
            props.setModalMessage("Invalid characters detected");
            return;
        }*/

    let tempFilterParams = props.filterParams.filter(param => param.paramName != "appointment date");

    const filterData = new PatientSearchDTO(
      tempFilterParams,
      props.sortParams,
      props.pageNumber,
      props.pageSize
    );

    let criteria = {};
    criteria.id = null;
    criteria.name = name;
    criteria.quickList = props.quickList;
    criteria.patientRequestDTO = filterData;
    criteria.ownerId = null;
    criteria.sharedWithUsers = null;

    axios
      .post(CUSTOM_FILTER_LIST_URL, criteria, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(loadCustomFilteredList());
        onHide();
      })
      .catch((error) => {
        let message = error?.response?.data?.message;
        if (error?.response?.data?.message
            === 'CUSTOM_WORKLIST_NAME_ALREADY_EXISTS') {
          message =  "A custom list with this name already exists."
        }
        props.setModalTitle("Error");
        props.setModalSize("lg");
        props.setModalMessage(message);
      });
  };

  return (
    <Modal show={props.showCustomFilterList} onHide={() => onHide()}>
      <Modal.Header closeButton style={{ backgroundColor: "#F9F9FA" }}>
        <Modal.Title id={`${componentName}-createCustomFilteredListModal`}>Create a Custom Filtered List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form>
              <Form.Group controlId="addCustomFilteredList" id={`${componentName}-customFilteredList`}>
                <Form.Label id={`${componentName}-customFilteredListName`} className="fw-bold d-block">
                  Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id={`${componentName}-customFilteredListNameValue`}
                  placeholder="Custom Filtered List Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Form>
            <p></p>
            <p id={`${componentName}-filterSettings`}>
              <b>List Filter Settings:</b>
            </p>
            {props.filters.filter(filter => filter.filterValue !== 'All').map((filter, idx) => {
              return (
                <span key={`${componentName}-customFilters-${idx}`} id={`${componentName}-customFilters-${idx}`} className="d-block">
                  <span id={`${componentName}-customFiltersLabel-${idx}`}>{filter.filterName}:</span> <span id={`${componentName}-customFiltersValue-${idx}`}><b>{filter.filterValue}</b></span>
                </span>
              );
            })}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          id={`${componentName}-customFiltersSubmit`}
          onClick={() => onSubmit()}
          disabled={name.length < 1}
        >
          Submit
        </Button>
        <Button id={`${componentName}-customFiltersClose`} onClick={() => onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomFilterList;
