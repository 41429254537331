import React from "react";
import Collapse from "react-bootstrap/Collapse";

const componentName = "CollapsibleCard";

const CollapsibleCard = ({ heading, openContent, closedContent, idx, open }) => {
    return (
        <div>
            {heading && <div id={`${componentName}-ccHeader-${idx}`} className="fw-bold">{heading}</div>}
            <div id={`${componentName}-ccClosedInfo-${idx}`}>{closedContent}</div>
            <Collapse in={open}>
                <div id={`${componentName}-ccOpenInfo-${idx}`} className="mt-3">{openContent}</div>
            </Collapse>
        </div>
    );
};

export { CollapsibleCard };
