import React, { createContext, useContext } from "react";
import jwt_decode from "jwt-decode";
import { login as doLogin } from "../utils/loginApiClient";
import {AppPropertiesUtil, LocalStorageUtil, SessionStorageUtil} from "../utils/localStorageUtil";
import { useLocalStorage } from "../utils/useLocalOrSessionStorage";
import { useHistory } from "react-router-dom";
import { getProperties, getSamlResponseforFindHelp, getSamlResponseforUniteUs } from "../actions/constantsActions";
import { getLandingPageURL } from "../utils/util";
import axios from "../utils/apiClient";
import {GET_PATIENTS, SHOW_REQUEST_ALERT} from "../actions/types";
import {CE_RESTORE_OBJ} from "../views/common/MainLayout";

const AUTHENTICATION_KEY = "ce-a";
const SESSION_TIMEOUT_SHOW_MODAL_MS = 10 * 60 * 1000; // After 10 minutes, Idle Session Modal
const SESSION_TIMEOUT_MODAL_TIMEOUT_MS = 5 * 60 * 1000; // Modal will logout after 5 minutes if not addressed.  Timeout is a total of 15 mintues.
const SESSION_HAS_EXPIRED = "ce-session-expired";

const LOG_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-profile/logUserLogin";
const LOGOUT_URL = process.env.REACT_APP_REST_API_BASE_URL + "/v1/user-profile/logUserLogout";

const authContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  // This is to track if the loginIn action is  SSO or actual loggin
  const [authentication, setAuthentication] =
    useLocalStorage(AUTHENTICATION_KEY);
  const history = useHistory();

  const isAuthenticated = () => {
    try {
      if (authentication) {
        jwt_decode(authentication.access_token);
        return true;
      }
    } catch (e) {}
    return false;
  };

  const getUsername = () => {
    try {
      return jwt_decode(authentication.access_token).user_name;
    } catch (e) {}
    return null;
  };

  const getName = () => {
    try {
      return jwt_decode(authentication.access_token).name;
    } catch (e) {}
    return null;
  };

  const getLandingPage = (auth) => {
    try {
      let jwt = jwt_decode((auth ?? authentication).access_token);
      let lp = jwt.landing_page;
      return lp;
    } catch (e) {
      return null;
    }
  };

  const isAdmin = () => {
    try {
      return jwt_decode(authentication.access_token).is_admin;
    } catch (e) {}
    return false;
  };

  const hasAuthority = (authority) => {
    if (!authority) {
      return true;
    }

    try {
      return jwt_decode(authentication.access_token).authorities.includes(
        authority
      );
    } catch (e) {}
    return false;
  };

  const hasAnyAuthority = (authorities) =>
    authorities.some((x) => hasAuthority(x));

  const anyAuthorityContainsText = (text) => {
    try {
      return jwt_decode(authentication.access_token).authorities.some((x) =>
        x.includes(text)
      );
    } catch (e) {}
    return false;
  };

  const clearAuthAndProperties = () => {
    setAuthentication(null);
    AppPropertiesUtil.set(null);
  };

  const setAuthAndProperties = async (auth) => {
    setAuthentication(auth);
    try {
      let res = await getProperties();
      AppPropertiesUtil.set(res.data);
    } catch (e) {
    }
  };
  const login = async (username, password, termsAccepted = false) => {
    SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);
    const [err, res] = await doLogin(username, password, termsAccepted);
    if (err) {
      clearAuthAndProperties(null);
      return err;
    } else if (res && res.data) {
      setAuthAndProperties(res.data);
      logUserLoginTime();
      //check for last page viewed
      const restoreObj = LocalStorageUtil.getObj(CE_RESTORE_OBJ);
      if (restoreObj) {
        SessionStorageUtil.setObj(CE_RESTORE_OBJ, restoreObj);
        LocalStorageUtil.setObj(CE_RESTORE_OBJ, null);
        history.push(restoreObj.restoreUrl);
      }
      else if (history.location.pathname == "/") {
        let lp = getLandingPage(res.data);
        let lpurl=getLandingPageURL(lp);
        history.push(lpurl);
      }
      return true;
    }
  };
 
  const loginForRedirect = async (username, password, termsAccepted = false) => {
    SessionStorageUtil.setObj(CE_RESTORE_OBJ, null);
    const [err, res] = await doLogin(username, password, termsAccepted);
    if (err) {
      clearAuthAndProperties(null);
      return err;
    } else if (res && res.data) {
      setAuthentication(res.data);
      let resFromProp 
      try {
        resFromProp = await getProperties();
        AppPropertiesUtil.set(resFromProp.data);
      } catch (e) {
      }
      logUserLoginTime();
      let samlR;
      let url;
      const fromUniteUs =  document && document.referrer ? document.referrer.indexOf("uniteus") !== -1 : false
      const fromFindHelp = document && document.referrer ? document.referrer.indexOf("findhelp") !== -1 : false
      if(fromUniteUs && resFromProp && resFromProp.data){
        url = resFromProp.data['ce.saml.uniteus.subject-recipient']
         samlR = await getSamlResponseforUniteUs();
      }
      if(fromFindHelp && resFromProp && resFromProp.data){
        url = resFromProp.data['ce.saml.findhelp.subject-recipient']
        samlR = await getSamlResponseforFindHelp();
      }
     
      if(samlR && samlR.data && url){
        
        var myForm = document.createElement('form');
          myForm.setAttribute('action', url);
          myForm.setAttribute('method', 'post');
          myForm.setAttribute('hidden', 'true');
          var SAMLResponse = document.createElement('input');
          SAMLResponse.setAttribute('type', 'text');
          SAMLResponse.setAttribute('name', 'SAMLResponse');
          SAMLResponse.setAttribute('id', 'SAMLResponse');
          SAMLResponse.setAttribute('hidden', 'true');
          SAMLResponse.setAttribute('value', samlR.data.samlResponse);


          var RelayState = document.createElement('input');
          RelayState.setAttribute('type', 'text');
          RelayState.setAttribute('name', 'RelayState');
          RelayState.setAttribute('id', 'RelayState');
          RelayState.setAttribute('hidden', 'true');
          RelayState.setAttribute('value', samlR.data.relayState);
         
          myForm.appendChild(SAMLResponse);
          myForm.appendChild(RelayState);
          document.body.appendChild(myForm);
          myForm.submit();
      }
    }
  };

  const logUserLoginTime = () => {
    axios.post(LOG_URL, null, {
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  }

  const getRefreshToken = () => {
    return authentication ? authentication.refresh_token : null;
  };

  const getAuthToken = () => {
    return authentication ? authentication.access_token : null;
  };

  const logout = () => {
    try {
      logUserLogoutTime();
    } catch (e) {
      try {
        clearAuthAndProperties();
      } catch (e) {}
    }
  };

  const logUserLogoutTime = (token) => {
    axios.post(LOGOUT_URL, null, {
      headers: {
        "Content-Type": "application/json"
      },
    })
        .then((response) => {
          clearAuthAndProperties();
          window.location = "/";
        })
        .catch((error) => {
          clearAuthAndProperties();
          window.location = "/";
        });
  }

  const getUserId = () => {
    return authentication ? authentication.user_id : null;
  };

  const getInputSources = () => {
    return authentication ? authentication.input_sources : null;
  };

  const getEmail = () => {
    return authentication ? authentication.email : null;
  };

  const getEvidenceRequired = () => {
    return authentication && authentication.evidenceRequired ? authentication.evidenceRequired : false;
  };

  const getRoles = () => {
    return authentication  ? authentication.roles : [];
  }

  const getInputSource = (idx) => {
    return authentication
      ? authentication.input_sources
        ? authentication.input_sources[idx]
        : null
      : null;
  };

  const isInternalUser = () => {
    return authentication ? authentication.is_internal_user : false;
  }

  const getTeam = () => {
    return authentication?.team;
  }

  return {
    isAuthenticated,
    getUsername,
    getName,
    isAdmin,
    hasAuthority,
    getRefreshToken,
    getAuthToken,
    login,
    loginForRedirect,
    logout,
    getUserId,
    getInputSources,
    getInputSource,
    getLandingPage,
    anyAuthorityContainsText,
    hasAnyAuthority,
    getEmail,
    getEvidenceRequired,
    getRoles,
    isInternalUser,
    getTeam
  };
}

export {
  AuthProvider,
  useAuth,
  AUTHENTICATION_KEY,
  SESSION_TIMEOUT_SHOW_MODAL_MS,
  SESSION_TIMEOUT_MODAL_TIMEOUT_MS,
  SESSION_HAS_EXPIRED,
};
